import * as React from 'react';

export interface WindowScrollProps {
  children: React.ReactNode;
}

export const WindowScroll = ({ children }: WindowScrollProps) => (
  <div className="window-scroll" style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
    {children}
  </div>
);
