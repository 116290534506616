import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import classNames from 'classnames';
import BuildingsPanelView from '../../business/panels/buildings/BuildingsPanel';
import { CasesPanel } from '../../business/panels/cases/CasesPanel';
import ProjectPanelView from '../../business/panels/project/ProjectPanelView';
import { SectionsPanelView } from '../../business/panels/sections/SectionsPanelView';
import { SmartRequestsPanelViewController } from '../../business/panels/smartRequests/SmartRequestsPanelViewController';
import { DeliverablesPanel } from '../../business/panels/deliverables/DeliverablesPanel';
import { Breadcrumbs } from './Breadcrumbs/Breadcrumbs';
import { ToolBoxMenu } from './ToolBoxMenu';
import { buildingsRoute } from '../../../routes/toolbox/buildingsPanel/buildingsRoute';
import { sectionsRoute } from '../../../routes/toolbox/sectionsPanel/sectionsRoute';
import { useSelector } from 'react-redux';
import { selectFirstBuildingId } from '../../../store/selectors/specification/firstBuildingId.selector';
import { selectCurrentPanel } from '../../../store/selectors/navigation';
import { selectFirstCaseId } from '../../../store/selectors/specification/firstCaseId.selector';
import { selectProjectId } from '../../../store/selectors/project';
import { Panel } from '../../../interfaces/Panel';
import { selectHasBeenCalculatedOnce } from '../../../store/selectors/project/hasBeenCalculatedOnce.selector';
import { caseRoute } from '../../../routes/toolbox/casesPanel/caseRoute';
import { casesRoute } from '../../../routes/toolbox/casesPanel/casesRoute';
import { buildingRoute } from '../../../routes/toolbox/buildingsPanel/buildingRoute';
import { deliverablesRoute } from '../../../routes/toolbox/deliverablesPanel/deliverablesRoute';
import { projectDetailsRoute } from '../../../routes/toolbox/projectPanel/projectDetailsRoute';
import { smartRequestsRoute } from '../../../routes/toolbox/smartRequestsPanel/smartRequestsRoute';

export const ToolBox = () => {
  const firstBuildingId = useSelector(selectFirstBuildingId);
  const calculatedOnce = useSelector(selectHasBeenCalculatedOnce);
  const currentPanel = useSelector(selectCurrentPanel);
  const projectId = useSelector(selectProjectId);
  const firstCaseId = useSelector(selectFirstCaseId);

  const [isToolboxEnlarged, setIsToolboxEnlarged] = React.useState<boolean>(false);

  if (!firstCaseId) return null;

  return projectId ? (
    <div
      className={classNames({
        toolbox: true,
        'panel-hidden': !currentPanel,
        enlarged: isToolboxEnlarged && currentPanel === Panel.Cases
      })}>
      {currentPanel && <Breadcrumbs />}
      <ToolBoxMenu currentPanel={currentPanel} disabled={!calculatedOnce} />
      <Switch>
        <Route path={projectDetailsRoute.path} component={ProjectPanelView} />
        {firstBuildingId && (
          <Redirect
            exact
            path={buildingsRoute.path}
            to={buildingRoute.makePath({ projectId, buildingId: firstBuildingId })}
          />
        )}
        <Route path={buildingRoute.path} component={BuildingsPanelView} />
        <Redirect
          exact
          from={casesRoute.path}
          to={caseRoute.makePath({ projectId, caseId: firstCaseId })}
        />
        <Route path={caseRoute.path}>
          <CasesPanel
            isToolboxEnlarged={isToolboxEnlarged}
            setIsToolboxEnlarged={setIsToolboxEnlarged}
          />
        </Route>
        <Route path={sectionsRoute.path} component={SectionsPanelView} />
        <Route path={deliverablesRoute.path} component={DeliverablesPanel} />
        <Route path={smartRequestsRoute.path} component={SmartRequestsPanelViewController} />
      </Switch>
    </div>
  ) : null;
};
