import * as React from 'react';
import { DataBarSegment } from './DataBarSegment';
import { DataBarItem } from './DataBarItem';
import { useSelector } from 'react-redux';
import { selectBuildingMapFocus } from '../../../store/selectors/buildingMap/buildingMapFocus.selector';
import { getValueForFocus } from './DataBar';
import { selectCostByProject } from '../../../store/selectors/dataBar/costByProject.selector';
import { selectCostByBuilding } from '../../../store/selectors/dataBar/costByBuilding.selector';
import { selectCostByCase } from '../../../store/selectors/dataBar/costByCase.selector';
import { selectProjectLodgmentsSurface } from '../../../store/selectors/granulometry/projectLodgmentsSurface.selector';
import { selectLodgmentsSurfaceByBuilding } from '../../../store/selectors/granulometry/lodgmentsSurfaceByBuilding.selector';
import { selectLodgmentsSurfaceByCase } from '../../../store/selectors/granulometry/lodgmentsSurfaceByCase.selector';
import { selectIsFetchingReport } from '../../../store/selectors/report/isFetchingReport.selector';
import { selectFocusedEnvironmentalIndicators } from '../../../store/selectors/cstb/focusedEnvironmentalIndicators.selector';
import classNames from 'classnames';

export const DataBarCostSegment = () => {
  const isReportFetching = useSelector(selectIsFetchingReport);

  const buildingMapFocus = useSelector(selectBuildingMapFocus);

  const projectLodgmentsSurface = useSelector(selectProjectLodgmentsSurface);
  const lodgmentsSurfaceByBuilding = useSelector(selectLodgmentsSurfaceByBuilding);
  const lodgmentsSurfaceByCase = useSelector(selectLodgmentsSurfaceByCase);
  const surface = React.useMemo(
    () =>
      getValueForFocus(
        buildingMapFocus,
        projectLodgmentsSurface,
        lodgmentsSurfaceByBuilding,
        lodgmentsSurfaceByCase
      ),
    [buildingMapFocus, projectLodgmentsSurface, lodgmentsSurfaceByBuilding, lodgmentsSurfaceByCase]
  );

  const projectCost = useSelector(selectCostByProject);
  const costByBuilding = useSelector(selectCostByBuilding);
  const costByCase = useSelector(selectCostByCase);
  const costValue = React.useMemo(
    () => getValueForFocus(buildingMapFocus, projectCost, costByBuilding, costByCase),
    [buildingMapFocus, projectCost, costByBuilding, costByCase]
  );

  const costPerSquareMeter =
    costValue && surface ? (surface.value !== 0 ? costValue / surface.value : 0) : undefined;

  const isComEthIndicatorsSegmentDisplayed = useSelector(selectFocusedEnvironmentalIndicators);

  if (isReportFetching) {
    return null;
  }

  return (
    <DataBarSegment
      className={classNames('cost', {
        comEthIndicatorsSegmentDisplayed: isComEthIndicatorsSegmentDisplayed
      })}>
      <DataBarItem title="Coût total" iconName="cost" value={costValue} unit="€" />
      <div className="lineBreak" />
      <DataBarItem
        title={'Côut au m\u00B2 de SHab'}
        value={costPerSquareMeter}
        unit={'€/m\u00B2'}
      />
    </DataBarSegment>
  );
};
