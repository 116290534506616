import { createSelector, Selector } from 'reselect';
import * as R from 'ramda';
import { LodgmentsSurfaceByType } from '../../../../domain/granulometry/levels/queries/topLevels/sections/surfaces/getTopLevelLodgmentsSurfaceByType';
import { Surface } from '../../../../domain/specification/Surface';
import { selectCurrentCaseIdFromRoute } from '../../navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { selectLodgmentsSurfaceByBuildingCaseLevelAndType } from '../lodgmentsSurfaceByBuildingCaseLevelAndType.selector';
import { selectParentBuildingFromCaseRoute } from '../../navigation/toolbox/casesPanel/parentBuildingFromCaseRoute.selector';
import { LodgmentsSurfaceByLevelAndType } from '../../../../domain/granulometry/cases/queries/sections/surfaces/getCaseLodgmentsSurfaceByLevelAndType';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';

const sumByLodgmentType = (
  lodgmentsSurfacesByType: LodgmentsSurfaceByType[]
): LodgmentsSurfaceByType =>
  R.reduce(
    R.mergeWith((s1, s2) => Surface.sum([s1, s2])),
    {} as LodgmentsSurfaceByType,
    lodgmentsSurfacesByType
  );

type CurrentCaseLodgmentsSurfacesByType = Maybe<LodgmentsSurfaceByType>;

export const selectCurrentCaseLodgmentsSurfacesByType: Selector<
  State,
  CurrentCaseLodgmentsSurfacesByType
> = createSelector(
  [
    selectLodgmentsSurfaceByBuildingCaseLevelAndType,
    selectParentBuildingFromCaseRoute,
    selectCurrentCaseIdFromRoute
  ],
  (lodgmentsSurfaceByBuildingCaseLevelAndType, parentBuilding, currentCaseId) =>
    parentBuilding && currentCaseId && lodgmentsSurfaceByBuildingCaseLevelAndType
      ? R.pipe<[LodgmentsSurfaceByLevelAndType], LodgmentsSurfaceByType[], LodgmentsSurfaceByType>(
          R.values,
          sumByLodgmentType
        )(lodgmentsSurfaceByBuildingCaseLevelAndType[parentBuilding.id][currentCaseId])
      : undefined
);
