import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/ui/Button';
import { createNewProject } from 'store/actions/createNewProject.action';
import userState from 'store/reducers/user.slice';
import { ProjectDialog } from './ProjectDialog';

export const ProjectsView: React.FC = () => {
  const dispatch = useDispatch();

  const handleCreateNewProject = React.useCallback(() => {
    dispatch(createNewProject());
  }, [dispatch]);

  const onLogoutClick = React.useCallback(() => {
    dispatch(userState.actions.logout());
  }, [dispatch]);

  const { t } = useTranslation();

  return (
    <ProjectDialog className="projects-card">
      <div className="form-group">
        <Button
          fullWidth
          appearance="outline"
          content={t('Start new project')}
          handleClick={handleCreateNewProject}
        />
      </div>
      <div className="form-group">
        <Button fullWidth appearance="outline" content={t('Open project')} link="/open-project" />
      </div>
      <div className="form-group">
        <Button
          fullWidth
          appearance="outline"
          content={t('Create a new project by copy')}
          link="/duplicate-project"
        />
      </div>
      {/* <div className="form-group">
        <Button fullWidth appearance="outline" content={t('Manage projects and data')} disabled />
      </div> */}
      <div className="form-group">
        <Button fullWidth appearance="outline" content={t('Logout')} handleClick={onLogoutClick} />
      </div>
    </ProjectDialog>
  );
};

export default ProjectsView;
