import i18next from 'i18next';
import {
  BalconySpecification,
  BodyguardType
} from '../../specification/rooms/BalconySpecification';

const DEFAULT_BALCONY_DEPTH = 1.8;

const balcony = (): BalconySpecification =>
  ({
    name: 'balcony',
    depth: undefined,
    defaultDepth: DEFAULT_BALCONY_DEPTH,
    title: i18next.t('Balcony'),
    bodyguardType: BodyguardType.Steel,
    icon: {
      type: 'object',
      iconName: 'balcony',
      strokeStyle: 'opened'
    }
  } as const);

export default balcony;
