import { createSelector, Selector } from 'reselect';
import { State } from '../../reducers';
import { LevelSpecification } from '../../../domain/specification/levels/LevelSpecification';
import { selectAllCasesSpecifications } from './allCasesSpecifications.selector';

export const selectAllLevelsSpecifications: Selector<State, LevelSpecification[]> = createSelector(
  [selectAllCasesSpecifications],
  (cases) => {
    return cases.reduce<LevelSpecification[]>((acc, c) => [...acc, ...c.levels], []);
  }
);
