import * as React from 'react';
import { BasementLevelSpecification } from '../../../../../domain/specification/levels/BasementLevelSpecification';
import { Input } from '../../../../ui/input/Input';
import { ParkingsCountByType } from '../../../../../domain/granulometry/levels/queries/basementLevels/counts/getBasementLevelParkingsCountByType';

interface Column3Props {
  basementLevelSpecification: BasementLevelSpecification;
  parkingsCountByType: ParkingsCountByType;
}

export const Column3 = ({ basementLevelSpecification, parkingsCountByType }: Column3Props) => (
  <div className="column-3">
    {!parkingsCountByType ? (
      <Input
        disabled={true}
        name={'basementLevel' + basementLevelSpecification.level + 'ParkingSpotCount'}
        size="small"
        type="text"
        value="-"
      />
    ) : (
      <Input
        disabled={true}
        name={'basementLevel' + basementLevelSpecification.level + 'ParkingSpotCount'}
        size="small"
        type="number"
        value={(parkingsCountByType.carBoxInfra || 0) + (parkingsCountByType.carParkInfra || 0)}
      />
    )}
  </div>
);
