import * as R from 'ramda';
import { CaseGranulometry } from '../../CaseGranulometry';
import { getCaseLevels } from './getCaseLevels';
import { LevelGranulometry } from '../../../levels/LevelGranulometry';

export const getCaseLowestBasementLevel = (caseGranulometry: CaseGranulometry) =>
  R.pipe<[CaseGranulometry], LevelGranulometry[], LevelGranulometry[], LevelGranulometry>(
    getCaseLevels,
    R.init,
    R.last
  )(caseGranulometry);
