import {
  LevelGranulometry,
  LevelGranulometryFullFilled,
  LevelGranulometryInitialEntries
} from '../../LevelGranulometry';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';

export const isHighestLevel = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry:
    | LevelGranulometryInitialEntries
    | LevelGranulometryFullFilled
    | LevelGranulometry
): boolean =>
  levelGranulometry.level === caseGranulometry.initialSpecifications.projectedTopLevelsCount - 1;
