import {
  LevelGranulometryFullFilled,
  LevelGranulometryWithLodgments
} from '../../../../LevelGranulometry';
import { mustHaveARampSection } from '../../../mustHave/mustHaveARampSection';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsLinear } from '../includedInSurfaceForSaleSectionWalls/getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsLinear';
import { mustHaveAHallSection } from '../../../mustHave/mustHaveAHallSection';
import { getHallSectionTheoreticalWallsLinear } from '../../../../../sections/queries/walls/theoreticalWalls/hallSection/getHallSectionTheoreticalWallsLinear';
import { getHallSectionTheoreticalExposureRate } from '../../../../../sections/queries/exposureRate/getHallSectionTheoreticalExposureRate';
import { getRampSectionTheoreticalWallsLinear } from '../../../../../sections/queries/walls/theoreticalWalls/rampSection/getRampSectionTheoreticalWallsLinear';
import { getRampSectionExposureRate } from '../../../../../sections/queries/exposureRate/getRampSectionExposureRate';
import { getLevelTechnicalPermiseSections } from '../../../sections/getLevelTechnicalPermiseSections';
import { getSectionTheoreticalOutsideWallsLinear } from '../../../../../sections/queries/walls/theoreticalWalls/outsideWalls/getSectionTheoreticalOutsideWallsLinear';

export const getTopLevelTheoreticalOutsideWallsLinear = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled
) => {
  let linear = 0;

  // Ramp section
  if (mustHaveARampSection(caseGranulometry, levelGranulometry)) {
    linear +=
      getRampSectionTheoreticalWallsLinear(caseGranulometry) *
      getRampSectionExposureRate(caseGranulometry);
  }

  // Included in surface for sale sections
  linear += getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsLinear(
    caseGranulometry,
    levelGranulometry as LevelGranulometryWithLodgments
  );

  // Technical premise sections
  linear += getLevelTechnicalPermiseSections(levelGranulometry).reduce(
    (acc, technicalPermiseSection) =>
      acc +
      getSectionTheoreticalOutsideWallsLinear(
        caseGranulometry,
        levelGranulometry,
        technicalPermiseSection
      ),
    0
  );

  // Hall section
  if (mustHaveAHallSection(caseGranulometry, levelGranulometry)) {
    linear +=
      getHallSectionTheoreticalWallsLinear(caseGranulometry) *
      getHallSectionTheoreticalExposureRate(caseGranulometry);
  }

  return linear;
};
