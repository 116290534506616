import { CaseGranulometry } from '../../../CaseGranulometry';
import { getCaseTopLevels } from '../../levels/getCaseTopLevels';
import { getTopLevelLodgmentSectionsCount } from '../../../../levels/queries/topLevels/counts/getTopLevelLodgmentSectionsCount';
import { getLevelTechnicalPermiseSections } from '../../../../levels/queries/sections/getLevelTechnicalPermiseSections';
import {
  TECHNICAL_SHEATHS_COUNT_PER_COMMON_PREMISE,
  TECHNICAL_SHEATHS_COUNT_PER_LODGMENT,
  TECHNICAL_SHEATHS_COUNT_PER_OFFICE,
  TECHNICAL_SHEATHS_COUNT_PER_SHOP,
  TECHNICAL_SHEATHS_COUNT_PER_TECHNICAL_PREMISE
} from '../getCaseRealTechnicalSheathsCountByLevel/getCaseTheoricalTechnicalSheathsCountByLevel';
import { getTopLevelSectionsWithTechnicalSheaths } from '../../../../levels/queries/topLevels/sections/getTopLevelSectionsWithTechnicalSheaths';
import { getLevelCommonPremiseSections } from '../../../../levels/queries/sections/getLevelCommonPremiseSections';
import { getLevelShopSections } from '../../../../levels/queries/sections/getLevelShopSections';
import { getLevelOfficeSections } from '../../../../levels/queries/sections/getLevelOfficeSections';

type SectionWithSheathsCountAndTheoricalTechnicalSheathCountByTopLevel = {
  levelIndex: number;
  sectionWithSheathsCount: number;
  theoricalSheathCount: number;
}[];

export const getCaseSectionsWithTSCountAndTheoricalTechnicalSheathCountByTopLevel = (
  caseGranulometry: CaseGranulometry
): SectionWithSheathsCountAndTheoricalTechnicalSheathCountByTopLevel =>
  getCaseTopLevels(caseGranulometry).reduce(
    (acc, levelGranulometry) => [
      ...acc,
      {
        levelIndex: levelGranulometry.level,
        sectionWithSheathsCount: getTopLevelSectionsWithTechnicalSheaths(levelGranulometry).length,
        theoricalSheathCount:
          getTopLevelLodgmentSectionsCount(levelGranulometry) *
            TECHNICAL_SHEATHS_COUNT_PER_LODGMENT +
          getLevelTechnicalPermiseSections(levelGranulometry).length *
            TECHNICAL_SHEATHS_COUNT_PER_TECHNICAL_PREMISE +
          getLevelCommonPremiseSections(levelGranulometry).length *
            TECHNICAL_SHEATHS_COUNT_PER_COMMON_PREMISE +
          getLevelShopSections(levelGranulometry).length * TECHNICAL_SHEATHS_COUNT_PER_SHOP +
          getLevelOfficeSections(levelGranulometry).length * TECHNICAL_SHEATHS_COUNT_PER_OFFICE
      }
    ],
    [] as SectionWithSheathsCountAndTheoricalTechnicalSheathCountByTopLevel
  );
