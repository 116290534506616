import { Section } from './Section';
import { isSectionARamp } from './circulationSections/ramp';
import { isSectionARampInfra } from './circulationSections/rampInfra';
import { isSectionAElevatorShaft } from './circulationSections/elevatorShaft';
import { isSectionAElevatorShaftInfra } from './circulationSections/elevatorShaftInfra';
import { isSectionAStairsShaft } from './circulationSections/stairsShaft';
import { isSectionAStairsShaftInfra } from './circulationSections/stairsShaftInfra';
import { isSectionAVentilationGroupInfra } from './circulationSections/ventilationGroupInfra';
import { isSectionAnOutsideStairsShaft } from './outsideSections/outsideStairShaft';
import { isSectionAnOutsideStairsShaftInfra } from './outsideSections/outsideStairShaftInfra';
import { isSectionASmokeExtractionDuctsInfra } from './circulationSections/smokeExtractionDuctsInfra';

export interface SectionWithLength extends Section {
  length: number;
}

export const isSectionWithLength = (section: Section): section is SectionWithLength =>
  isSectionARamp(section) ||
  isSectionARampInfra(section) ||
  isSectionAElevatorShaft(section) ||
  isSectionAElevatorShaftInfra(section) ||
  isSectionAStairsShaft(section) ||
  isSectionAStairsShaftInfra(section) ||
  isSectionAnOutsideStairsShaft(section) ||
  isSectionAnOutsideStairsShaftInfra(section) ||
  isSectionASmokeExtractionDuctsInfra(section) ||
  isSectionAVentilationGroupInfra(section);
