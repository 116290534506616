import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import * as R from 'ramda';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import { Property } from '../../specification/cases/Property';

export const migrateProject26To27 = (project: Project): Project =>
  mapCases((caseSpecification) =>
    R.pipe<[CaseSpecification], CaseSpecification>((cS) =>
      R.over(
        R.lensPath(['properties']),
        R.map((property: Property) =>
          R.pipe<[Property], Property, Property, Property, Property, Property, Property>(
            (p) =>
              p.name === 'carParkWidth' ? { ...p, defaultValue: 2.6, min: 2.5, max: 3.5 } : p,
            (p) => (p.name === 'carParkDepth' ? { ...p, defaultValue: 5, min: 5, max: 10 } : p),
            (p) =>
              p.name === 'carParkBeamWidth' ? { ...p, defaultValue: 50, min: 20, max: 120 } : p,
            (p) => (p.name === 'carBoxWidth' ? { ...p, defaultValue: 2.6, min: 2.5, max: 3.5 } : p),
            (p) => (p.name === 'carBoxDepth' ? { ...p, defaultValue: 5, min: 5, max: 10 } : p),
            (p) =>
              p.name === 'carBoxBeamWidth' ? { ...p, defaultValue: 50, min: 20, max: 120 } : p
          )(property)
        ),
        cS
      )
    )(caseSpecification)
  )(project);
