import { CaseGranulometry } from '../../../domain/granulometry/cases/CaseGranulometry';
import { getLevelTitle } from '../../../domain/granulometry/levels/queries/getLevelTitle';
import { roundWith2Decimal } from '../../../utils/round/roundWith2Decimal';
import { isTopLevel } from '../../../domain/granulometry/levels/queries/is/isTopLevel';
import { getCasePropertyConvertedValue } from '../../../domain/specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { isBasementLevel } from '../../../domain/granulometry/levels/queries/is/isBasementLevel';
import { keepOnlyKeysWithValues } from '../../../utils/keepOnlyKeysWithValues';
import { generateLevelContent } from './generateLevelContent';
import { getTopLevelGrossFloorSurface } from '../../../domain/granulometry/levels/queries/topLevels/surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurface';
import { getTopLevelGrossFloorSurfaceEff } from '../../../domain/granulometry/levels/queries/topLevels/surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurfaceEff';
import { getTopLevelRealOutsideInsulatedWallsInternalLinear } from '../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealOutsideInsulatedWallsInternalLinear';
import { getTopLevelRealOutsideWallsSurface } from '../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealOutsideWallsSurface';
import { getTopLevelRealInsideWallsLinear } from '../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealInsideWallsLinear';
import { getTopLevelRealInsideWallsSurface } from '../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealInsideWallsSurface';
import { getTopLevelTheoreticalOutsideWallsSurface } from '../../../domain/granulometry/levels/queries/topLevels/walls/theoreticalWalls/getTopLevelTheoreticalOutsideWallsSurface';
import { getTopLevelTheoreticalOutsideWallsLinear } from '../../../domain/granulometry/levels/queries/topLevels/walls/theoreticalWalls/getTopLevelTheoreticalOutsideWallsLinear';
import { getTopLevelTheoreticalInsideWallsLinear } from '../../../domain/granulometry/levels/queries/topLevels/walls/theoreticalWalls/getTopLevelTheoreticalInsideWallsLinear';
import { isHighestLevel } from '../../../domain/granulometry/levels/queries/is/isHighestLevel';
import { isRoofingLevel } from '../../../domain/granulometry/levels/queries/is/isRoofingLevel';
import { getLevelFullFilledContent } from '../../../domain/granulometry/levels/queries/content/getLevelFullFilledContent';
import { isSectionABearing } from '../../../domain/granulometry/sections/circulationSections/bearing';
import { getTopLevelRealOutsideStructuralWallsInternalLinear } from '../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealOutsideStructuralWallsInternalLinear';
import { getTopLevelRealOutsideStructuralWallsExternalLinear } from '../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealOutsideStructuralWallsExternalLinear';
import { getTopLevelRealOutsideInsulatedWallsExternalLinear } from '../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealOutsideInsulatedWallsExternalLinear';

export const adaptLevels = (caseGranulometry: CaseGranulometry) =>
  caseGranulometry.levels.map((levelGranulometry) =>
    keepOnlyKeysWithValues({
      level: levelGranulometry.level,
      title: getLevelTitle(levelGranulometry, caseGranulometry),
      surface: roundWith2Decimal(levelGranulometry.displayedSurface),
      bearingSurface:
        getLevelFullFilledContent(levelGranulometry).find(isSectionABearing)?.displayedSurface,
      ceilingNetHeight: roundWith2Decimal(levelGranulometry.ceilingHeight),
      realBuiltSurface: levelGranulometry.realBuiltSurface
        ? roundWith2Decimal(levelGranulometry.realBuiltSurface)
        : 0,
      surfaceForSale: levelGranulometry.surfaceForSale
        ? roundWith2Decimal(levelGranulometry.surfaceForSale)
        : 0,
      content: generateLevelContent(levelGranulometry, caseGranulometry),
      ...(isTopLevel(caseGranulometry, levelGranulometry)
        ? {
            grossFloorSurface: roundWith2Decimal(
              getTopLevelGrossFloorSurface(caseGranulometry, levelGranulometry)
            ),
            grossFloorSurfaceEff: roundWith2Decimal(
              getTopLevelGrossFloorSurfaceEff(caseGranulometry, levelGranulometry)
            ),
            concreteSlabThickness: roundWith2Decimal(levelGranulometry.concreteSlabThickness || 0),
            cementScreedThickness: roundWith2Decimal(levelGranulometry.cementScreedThickness || 0),
            concreteSlabHighAdhesionSteelRate: getCasePropertyConvertedValue(
              caseGranulometry.initialSpecifications,
              'topLevelConcreteSlabHighAdhesionSteelRate'
            ),
            concreteSlabWeldedMeshSteelRate: getCasePropertyConvertedValue(
              caseGranulometry.initialSpecifications,
              'topLevelConcreteSlabWeldedMeshSteelRate'
            ),
            concretePillarHighAdhesionSteelRate: getCasePropertyConvertedValue(
              caseGranulometry.initialSpecifications,
              'topLevelConcretePillarHighAdhesionSteelRate'
            ),
            internalConcreteSlabThickness: getCasePropertyConvertedValue(
              caseGranulometry.initialSpecifications,
              'topLevelInternalConcreteSlabThickness'
            ),
            theoreticalOutsideWallsLinear: roundWith2Decimal(
              getTopLevelTheoreticalOutsideWallsLinear(caseGranulometry, levelGranulometry)
            ),
            theoreticalOutsideWallsSurface: roundWith2Decimal(
              getTopLevelTheoreticalOutsideWallsSurface(caseGranulometry, levelGranulometry)
            ),
            theoreticalInsideWallsLinear: roundWith2Decimal(
              getTopLevelTheoreticalInsideWallsLinear(caseGranulometry, levelGranulometry)
            ),
            theoreticalInsideWallsSurface: roundWith2Decimal(
              getTopLevelTheoreticalInsideWallsLinear(caseGranulometry, levelGranulometry)
            ),
            realOutsideWallsLinear: roundWith2Decimal(
              getTopLevelRealOutsideInsulatedWallsInternalLinear(
                caseGranulometry,
                levelGranulometry
              )
            ), // TODO : to rename realOutsideInsulatedWallsInternalLinear on backend
            realOutsideInsulatedWallsInternalLinear: roundWith2Decimal(
              getTopLevelRealOutsideInsulatedWallsInternalLinear(
                caseGranulometry,
                levelGranulometry
              )
            ), // TODO : realOutsideWallsLinear = realOutsideInsulatedWallsInternalLinear
            realOutsideStructuralWallsInnerLinear: roundWith2Decimal(
              getTopLevelRealOutsideStructuralWallsInternalLinear(
                caseGranulometry,
                levelGranulometry
              )
            ), // TODO : to rename realOutsideStructuralWallsInternalLinear on backend
            realOutsideStructuralWallsInternalLinear: roundWith2Decimal(
              getTopLevelRealOutsideStructuralWallsInternalLinear(
                caseGranulometry,
                levelGranulometry
              )
            ), // TODO : realOutsideStructuralWallsInnerLinear = realOutsideStructuralWallsInternalLinear
            realOutsideStructuralWallsExternalLinear: roundWith2Decimal(
              getTopLevelRealOutsideStructuralWallsExternalLinear(
                caseGranulometry,
                levelGranulometry
              )
            ),
            realOutsideInsulatedWallsExternalLinear: roundWith2Decimal(
              getTopLevelRealOutsideInsulatedWallsExternalLinear(
                caseGranulometry,
                levelGranulometry
              )
            ),
            realOutsideWallsSurface: roundWith2Decimal(
              getTopLevelRealOutsideWallsSurface(caseGranulometry, levelGranulometry)
            ),
            realInsideWallsLinear: roundWith2Decimal(
              getTopLevelRealInsideWallsLinear(caseGranulometry, levelGranulometry)
            ),
            realInsideWallsSurface: roundWith2Decimal(
              getTopLevelRealInsideWallsSurface(caseGranulometry, levelGranulometry)
            ),
            waterBearingTechnicalSheathEnabled:
              levelGranulometry.waterBearingTechnicalSheathEnabled !== false ? 1 : 0,
            generalBearingTechnicalSheathEnabled:
              levelGranulometry.generalBearingTechnicalSheathEnabled !== false ? 1 : 0,
            gasBearingTechnicalSheathEnabled:
              levelGranulometry.gasBearingTechnicalSheathEnabled !== false ? 1 : 0,
            electricityBearingTechnicalSheathEnabled:
              levelGranulometry.electricityBearingTechnicalSheathEnabled !== false ? 1 : 0,
            communicationBearingTechnicalSheathEnabled:
              levelGranulometry.communicationBearingTechnicalSheathEnabled !== false ? 1 : 0
          }
        : {}),
      ...(isBasementLevel(caseGranulometry, levelGranulometry)
        ? {
            concreteSlabThickness: roundWith2Decimal(levelGranulometry.concreteSlabThickness || 0),
            cementScreedThickness: roundWith2Decimal(levelGranulometry.cementScreedThickness || 0),
            concreteSlabHighAdhesionSteelRate: getCasePropertyConvertedValue(
              caseGranulometry.initialSpecifications,
              'basementLevelConcreteSlabHighAdhesionSteelRate'
            ),
            concreteSlabWeldedMeshSteelRate: getCasePropertyConvertedValue(
              caseGranulometry.initialSpecifications,
              'basementLevelConcreteSlabWeldedMeshSteelRate'
            ),
            concretePillarHighAdhesionSteelRate: getCasePropertyConvertedValue(
              caseGranulometry.initialSpecifications,
              'basementLevelConcretePillarHighAdhesionSteelRate'
            ),
            outsideWallInfraLinear: roundWith2Decimal(
              levelGranulometry.outsideWallsLinear as number
            ), // TODO : Rename in backend with outsideWallsLinear
            formworkBenchLength: roundWith2Decimal(levelGranulometry.formworkBenchLength as number),
            contiguousAgainstBuiltLinear: roundWith2Decimal(
              levelGranulometry.contiguousAgainstBuiltLinear as number
            ),
            contiguousUnderBuiltLinear: roundWith2Decimal(
              levelGranulometry.contiguousUnderBuiltLinear as number
            ),
            contiguousNotBuiltLinear: roundWith2Decimal(
              levelGranulometry.contiguousNotBuiltLinear as number
            )
          }
        : {}),
      isHighestLevel: isHighestLevel(caseGranulometry, levelGranulometry) ? 1 : 0,
      isRoofingLevel: isRoofingLevel(caseGranulometry, levelGranulometry) ? 1 : 0
    })
  );
