import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import { renameCaseProperties } from '../utils/renameCaseProperties';
import { MasonryTypeName } from '../../specification/cases/MasonryType';
import { WoodEnvelopeTypeName } from '../../specification/cases/WoodEnvelopeType';

export const migrateProject17To19 = (project: Project): Project =>
  mapCases((caseSpecification) => {
    return renameCaseProperties(
      {
        ...caseSpecification,
        masonryType: caseSpecification.masonryType || MasonryTypeName.ConcreteBlock,
        woodEnvelopeType: caseSpecification.woodEnvelopeType || WoodEnvelopeTypeName.CLTSolidPanel
      },
      {
        outsideWallChipboardRate: 'outsideWallMasonryRate'
      },
      {
        outsideWallChipboardRate:
          'Taux d’enveloppe structurelle verticale en éléments de maçonnerie'
      }
    );
  })(project);
