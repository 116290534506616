import * as React from 'react';
import { Column } from 'react-table';
import { ReportEntry, reportEntryLabels } from '../../../../../../domain/report/ReportEntry';
import { createFilter } from '../createFilter';
import { filterRowsAndPivotsThroughSelect } from '../filters/filterRowsAndPivotsThroughSelect';
import { reportUnitList } from '../reportEntries/reportUnitList';
import { getTotalQuantity } from '../totals/getTotalQuantity';
import { formatThousands } from '../../../../../../utils/formatThousands';
import classNames from 'classnames';

import { fitterRowsAndPivotsThroughInput } from '../filters/fitterRowsAndPivotsThroughInput';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';

export const getQuantityColumns = (
  report: ReportEntry[],
  subRowsIndex: number[],
  foldedColumns: string[]
): Column => ({
  Header: 'Quantitatif',
  columns: [
    {
      Header: reportEntryLabels.quantity,
      accessor: 'quantity',
      filterMethod: fitterRowsAndPivotsThroughInput(subRowsIndex),
      aggregate: (values) => formatThousands(values), // roundWith2Decimal(R.sum(values)),
      Aggregated: (cellInfo) =>
        cellInfo.groupedByPivot && cellInfo.level < 4 ? (
          <b>&nbsp;</b>
        ) : (
          <b>
            {cellInfo.value ? formatThousands(roundWith2Decimal(getTotalQuantity(cellInfo))) : 0}
          </b>
        ),
      Cell: (cellInfo) => <b>{roundWith2Decimal(cellInfo.value)}</b>,
      sortable: false,
      resizable: false,
      width: 70,
      className: 'right',
      headerClassName: 'noFold'
    },
    {
      Header: reportEntryLabels.unit,
      accessor: 'unit',
      filterMethod: filterRowsAndPivotsThroughSelect,
      aggregate: (values) => values[0],
      Aggregated: (cellInfo) =>
        cellInfo.groupedByPivot && cellInfo.level < 4 ? '' : cellInfo.value,
      Filter: createFilter(reportUnitList(report)),
      sortable: false,
      resizable: false,
      width: 40,
      className: classNames('separator', 'right', {
        folded: foldedColumns.indexOf('Unité') > -1
      }),
      headerClassName: classNames('separator', 'right', {
        folded: foldedColumns.indexOf('Unité') > -1
      })
    }
  ]
});
