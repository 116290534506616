import { LodgmentType } from '../../domain/specification/lodgmentTypes/LodgmentType';
import { HeatingSystem } from '../../domain/specification/lodgmentTypes/HeatingSystem';

export const SET_LODGMENT_TYPES_HEATING_SYSTEM = 'SET_LODGMENT_TYPES_HEATING_SYSTEM';

export const setLodgmentTypesHeatingSystem = (
  caseId: string,
  lodgmentTypes: LodgmentType[],
  heatingSystem: HeatingSystem | undefined
) => ({
  type: SET_LODGMENT_TYPES_HEATING_SYSTEM,
  payload: {
    caseId,
    lodgmentTypes,
    heatingSystem
  }
});

export type SetLodgmentTypesHeatingSystemAction = ReturnType<typeof setLodgmentTypesHeatingSystem>;
