import { LodgmentType } from '../../domain/specification/lodgmentTypes/LodgmentType';

export const SET_LODGMENT_TYPE_EXPOSURE_RATE = 'SET_LODGMENT_TYPE_EXPOSURE_RATE';

export const setLodgmentTypeExposureRate = (
  caseId: string,
  lodgmentType: LodgmentType,
  exposureRate: number | undefined
) => ({
  type: SET_LODGMENT_TYPE_EXPOSURE_RATE,
  payload: { caseId, lodgmentType, exposureRate }
});

export type SetLodgmentTypeExposureRateAction = ReturnType<typeof setLodgmentTypeExposureRate>;
