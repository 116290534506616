import * as R from 'ramda';
import { CaseGranulometry } from '../../cases/CaseGranulometry';
import { LevelGranulometry } from '../../levels/LevelGranulometry';
import { createRoofingLevel } from '../../levels/queries/roofingLevels/createRoofingLevel';
import { getCaseLevels } from './levels/getCaseLevels';
import { getCaseLargestTopLevel } from './levels/getCaseLargestTopLevel';
import { getTopLevelGrossFloorSurfaceEff } from '../../levels/queries/topLevels/surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurfaceEff';
import { createLevelRoofingSection } from '../../levels/queries/sections/create/createLevelRoofingSection';
import { RoofingName } from '../../sections/roofingSections/RoofingSection';
import { getLevelBelow } from '../../levels/queries/getLevelBelow';
import { isUpperLevel } from '../../levels/queries/is/isUpperLevel';

export const createCaseRoofings = (caseGranulometry: CaseGranulometry): CaseGranulometry =>
  R.pipe<[CaseGranulometry], CaseGranulometry, CaseGranulometry>(
    R.over<CaseGranulometry, LevelGranulometry[]>(
      R.lensPath(['levels']),
      R.insert(
        0,
        createRoofingLevel(
          caseGranulometry,
          getCaseLevels(caseGranulometry)[0],
          caseGranulometry.initialSpecifications.roofingType
        )
      )
    ),
    (caseGranulometryWithRoofingLevel) =>
      R.over<CaseGranulometry, LevelGranulometry[]>(
        R.lensPath(['levels']),
        R.pipe<
          [LevelGranulometry[]],
          LevelGranulometry[],
          LevelGranulometry[],
          LevelGranulometry[]
        >(
          R.reverse,
          R.map((levelGranulometry: LevelGranulometry) => {
            if (isUpperLevel(caseGranulometryWithRoofingLevel, levelGranulometry)) {
              const largestTopLevelSurface =
                getCaseLargestTopLevel(caseGranulometry).displayedSurface;
              if (largestTopLevelSurface > levelGranulometry.displayedSurface) {
                const levelBelow = getLevelBelow(caseGranulometry, levelGranulometry);
                if (levelBelow) {
                  const levelBelowGfsEff = getTopLevelGrossFloorSurfaceEff(
                    caseGranulometry,
                    levelBelow
                  );
                  const levelGfsEff = getTopLevelGrossFloorSurfaceEff(
                    caseGranulometry,
                    levelGranulometry
                  );
                  const roofingDisplayedSurface = levelBelowGfsEff - levelGfsEff;
                  const roofingDrawnSurface = levelBelow.surface - levelGranulometry.surface;
                  if (roofingDisplayedSurface > 0.1) {
                    // need 0.1 not 0 to prevent bug
                    return {
                      ...levelGranulometry,
                      roofing: [
                        createLevelRoofingSection(
                          roofingDisplayedSurface,
                          roofingDrawnSurface,
                          RoofingName.FlatRoofing
                        )
                      ]
                    };
                  }
                }
              }
            }
            return levelGranulometry;
          }),
          R.reverse
        )
      )(caseGranulometryWithRoofingLevel)
  )(caseGranulometry);
