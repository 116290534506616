import { uniqId } from '../../../../../infra/uniqId';
import { defaultLodgmentTypesDistribution } from '../../../lodgmentTypes/LodgmentTypesDistribution';
import { DEFAULT_PARKING_SPECIFICATION } from '../../../lodgmentTypes/ParkingsSpecification';
import { defaultCaseProperties } from '../../../../../resources/defaultCaseProperties';
import { RoofingName } from '../../../../granulometry/sections/roofingSections/RoofingSection';
import { CaseEditingStageName, CaseId, CaseSpecification } from '../../CaseSpecification';
import { t1 } from '../../../../granulometry/sections/lodgmentSections/t1';
import { t2c } from '../../../../granulometry/sections/lodgmentSections/t2c';
import { t2 } from '../../../../granulometry/sections/lodgmentSections/t2';
import { t3c } from '../../../../granulometry/sections/lodgmentSections/t3c';
import { t3 } from '../../../../granulometry/sections/lodgmentSections/t3';
import { t4c } from '../../../../granulometry/sections/lodgmentSections/t4c';
import { t4 } from '../../../../granulometry/sections/lodgmentSections/t4';
import { t5 } from '../../../../granulometry/sections/lodgmentSections/t5';
import { DEFAULT_HEATING_DISTRIBUTION } from '../../HeatingDistribution';
import { CarpentryTypeName } from '../../CarpentryType';
import { MasonryTypeName } from '../../MasonryType';
import { WoodEnvelopeTypeName } from '../../WoodEnvelopeType';
import { DEFAULT_CARPENTRY_OCCULTATIONS_TYPE } from '../../CarpentryOccultationsType';
import { CarpentryOccultationsMotorisationTypeName } from '../../CarpentryOccultationsMotorisationType';
import { ProjectSurfaceInputMode } from '../../../../project/Project';

interface CreateCaseSpecificationParams {
  projectSurfaceInputMode: ProjectSurfaceInputMode;
  withRamp?: boolean;
  id?: CaseId;
}

export const createCaseSpecification = ({
  projectSurfaceInputMode,
  withRamp = true,
  id = uniqId()
}: CreateCaseSpecificationParams): CaseSpecification => ({
  id,
  name: 'case',
  builtInRamp: withRamp,
  hall: true,
  levels: [],
  lodgmentsTypesSpecifications: [t1(), t2c(), t2(), t3c(), t3(), t4c(), t4(), t5()],
  distribution: defaultLodgmentTypesDistribution,
  parking: DEFAULT_PARKING_SPECIFICATION,
  surfaces: {},
  properties: defaultCaseProperties,
  roofingType: RoofingName.TileRoofing,
  carpentryType: CarpentryTypeName.PVC,
  carpentryDayTimeOccultationsType: DEFAULT_CARPENTRY_OCCULTATIONS_TYPE,
  carpentryDayTimeOccultationsTypeIsMonobloc: true,
  carpentryNightTimeOccultationsType: DEFAULT_CARPENTRY_OCCULTATIONS_TYPE,
  carpentryNightTimeOccultationsTypeIsMonobloc: true,
  carpentryOccultationsMotorisationType: CarpentryOccultationsMotorisationTypeName.None,
  masonryType: MasonryTypeName.ConcreteBlock,
  woodEnvelopeType: WoodEnvelopeTypeName.CLTSolidPanel,
  heatingDistribution: DEFAULT_HEATING_DISTRIBUTION,
  customDistribution: {},
  editingStage: CaseEditingStageName.Granulation,
  projectSurfaceInputMode
});
