import { createSelector, Selector } from 'reselect';
import { State } from '../../../../store/reducers';
import { Maybe } from '../../../../utils/Maybe';
import { ParkingsCountByType } from '../../../../domain/granulometry/levels/queries/basementLevels/counts/getBasementLevelParkingsCountByType';

import { selectCurrentCaseParkingsCountByBasementLevelAndType } from '../../granulometry/parkings/parkingsCountByBasementLevelAndType.selector';
import { selectCurrentlyEditedLevelSpecification } from '../../navigation/toolbox/casesPanel/currentlyEditedLevelSpecification.selector';

export const selectCurrentLevelParkingsCountByBasementLevelAndType: Selector<
  State,
  Maybe<ParkingsCountByType>
> = createSelector(
  [selectCurrentCaseParkingsCountByBasementLevelAndType, selectCurrentlyEditedLevelSpecification],
  (parkingsCountByLevelAndType, currentlyEditedLevel) =>
    parkingsCountByLevelAndType && currentlyEditedLevel
      ? parkingsCountByLevelAndType[currentlyEditedLevel.level.toString(10)]
      : undefined
);
