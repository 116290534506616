import * as React from 'react';
import classNames from 'classnames';
import { hexToRgba } from 'utils/hexToRbga';
import { IconObject, IconObjectProps } from './Icons/iconObject';
import { IconFont } from './Icons/IconFont';
import { useSelector } from 'react-redux';
import { selectCurrentCaseLabel } from '../../store/selectors/project/currentCaseLabel.selector';
import { LodgmentTypeSpecification } from '../../domain/specification/lodgmentTypes/LodgmentTypeSpecification';
import { RoomSpecification } from '../../domain/specification/rooms/RoomSpecification';
import { getCaseGranulometryEditLodgmentTypeRoomTriggerId } from '../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditLodgmentTypeRoomTriggerId';

interface SectionMapProps {
  className?: string;
  id: string;
  infoList?: string[];
  lodgmentType?: LodgmentTypeSpecification;
  rooms?: RoomSpecification[];
  sectionIconProps: IconObjectProps;
  mainIconHandleClick?: (id: string) => void;
  roomIconHandleClick?: (event: React.MouseEvent, room: RoomSpecification, index: number) => void;
}

const SectionMap = (props: SectionMapProps) => {
  const {
    className,
    id,
    infoList,
    lodgmentType,
    rooms,
    sectionIconProps,
    mainIconHandleClick,
    roomIconHandleClick
  } = props;
  const color = sectionIconProps.color as string;
  const bgColor = hexToRgba(color, 0.2);
  const classes = classNames(
    'section-map',
    { dashed: sectionIconProps.strokeStyle === 'dashed' },
    className
  );
  const caseLabel = useSelector(selectCurrentCaseLabel) || '';

  return (
    <div
      id={id}
      className={classes}
      style={{
        backgroundColor: bgColor,
        borderColor: color
      }}>
      <div className="main-icon-wrapper">
        <IconObject
          customCss=".iconObjectStroke { display: none; } .iconObjectBackground { display: none; }"
          id={'sectionMap' + id + 'MainIcon'}
          onClick={() => {
            if (mainIconHandleClick) {
              mainIconHandleClick('sectionMap' + id + 'MainIcon');
            }
          }}
          {...sectionIconProps}
        />
      </div>
      {lodgmentType &&
        rooms &&
        rooms.map((room, key: number) => {
          return (
            <div key={key} className="room-list-item">
              <IconObject
                id={
                  roomIconHandleClick
                    ? getCaseGranulometryEditLodgmentTypeRoomTriggerId(
                        caseLabel,
                        lodgmentType.lodgmentType,
                        key
                      )
                    : ''
                }
                color={color}
                onClick={(event: React.MouseEvent) => {
                  if (roomIconHandleClick) {
                    roomIconHandleClick(event, room, key);
                  }
                }}
                {...room.icon}
              />
            </div>
          );
        })}
      {infoList && (
        <div className="info-list">
          {infoList.map((info: string, key: number) => {
            return <IconFont key={key} returnString={info} color={color} lineSpacing={2} />;
          })}
        </div>
      )}
    </div>
  );
};

export default SectionMap;
