import * as R from 'ramda';
import { LevelGranulometry } from '../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { getTopLevelOutsideStructuralWallsThickness } from './wallsThickness/getTopLevelOutsideStructuralWallsThickness';
import { getTopLevelStructuralFacadeInternalLinears } from './getTopLevelStructuralFacadeInternalLinears';

export const getTopLevelStructuralFacadeExternalLinears = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): number[] =>
  R.reduce<number, number[]>(
    (acc, structuralFacadeInternalLinear) => [
      ...acc,
      structuralFacadeInternalLinear + getTopLevelOutsideStructuralWallsThickness(caseGranulometry)
    ],
    [] as number[]
  )(getTopLevelStructuralFacadeInternalLinears(caseGranulometry, levelGranulometry));
