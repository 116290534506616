import { DEFAULT_RBS_TO_CBS_RATIO } from '../../../../../constants/appConstants';
import { Surface } from '../../../../specification/Surface';

export const getCaseProjectedMinRbs = (
  theoreticalRealBuiltSurface: Surface,
  technicalPremiseSectionsSurface: Surface,
  cbsToSfsRatio: number
): Surface =>
  new Surface(
    theoreticalRealBuiltSurface.value +
      (technicalPremiseSectionsSurface.value !== 0
        ? technicalPremiseSectionsSurface.value / (cbsToSfsRatio * DEFAULT_RBS_TO_CBS_RATIO)
        : 0)
  );
