import Decimal from 'decimal.js';

const returnDecimal = (
  dec1: number | Decimal,
  dec2: number | Decimal
): { dec1: Decimal; dec2: Decimal } => {
  if (typeof dec1 === 'number') {
    dec1 = new Decimal(dec1);
  }
  if (typeof dec2 === 'number') {
    dec2 = new Decimal(dec2);
  }
  return {
    dec1,
    dec2
  };
};

export const divide =
  (number1: number | Decimal) =>
  (number2: number | Decimal): Decimal => {
    const { dec1, dec2 } = returnDecimal(number1, number2);
    return dec1.dividedBy(dec2);
  };

export const multiply =
  (number1: number | Decimal) =>
  (number2: number | Decimal): Decimal => {
    const { dec1, dec2 } = returnDecimal(number1, number2);
    return dec1.mul(dec2);
  };
