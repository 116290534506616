import * as R from 'ramda';
import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';

export const migrateProject19To20 = (project: Project): Project =>
  mapCases((caseSpecification) =>
    R.pipe<[CaseSpecification], CaseSpecification, CaseSpecification>(
      R.assoc(
        'floorSpaceFeature',
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        caseSpecification.featureFloorSpace || {
          id: 'floorSpaceFeature_' + caseSpecification.id
        }
      ),
      R.dissoc('featureFloorSpace')
    )(caseSpecification)
  )(project);
