import { ReportEntry } from '../../domain/report/ReportEntry';
import { DataType } from '../../domain/DataType';
import { Maybe } from '../../utils/Maybe';
import { roundWith2Decimal } from '../../utils/round/roundWith2Decimal';

interface ExcelJsonEntry extends ReportEntry {
  project: string;
}

export const makeExcelJson = (
  report: Maybe<ReportEntry[]>,
  dataType: DataType,
  projectName: string
): Partial<ReportEntry>[] => {
  if (!report) return [];
  let columns: (keyof ExcelJsonEntry)[] = [
    'refLot',
    'lot',
    'refSsLot1',
    'ssLot1',
    'refSsLot2',
    'ssLot2',
    'refSsLot3',
    'ssLot3',
    'project',
    'building',
    'case',
    'level',
    'section',
    'room',
    'product'
  ];
  if (
    dataType.value === 'quantity' ||
    dataType.value === 'quantity-cost' ||
    dataType.value === 'quantity-cost-co2'
  ) {
    columns = [...columns, 'quantity', 'unit'];
  }
  if (dataType.value === 'quantity-cost' || dataType.value === 'quantity-cost-co2') {
    columns = [...columns, 'price', 'unitPrice'];
  }
  if (dataType.value === 'wood-cubature') {
    columns = [...columns, 'compositionValue', 'unit'];
  }
  if (dataType.value === 'co2' || dataType.value === 'quantity-cost-co2') {
    columns = [
      ...columns,
      'co2Id',
      'co2Title',
      'co2Type',
      'co2Quantity',
      'co2QuantityUnit',
      // 'co2Lifetime',
      // 'co2WastageRate',
      // 'co2TotalLifeCycleCarbon',
      // 'co2ReportingOrganization',
      'comEthIc'
    ];
  }
  return report.reduce((acc, reportEntry) => {
    const hasToBeSkipped =
      (dataType.value === 'co2' && reportEntry.comEthIc === 0) ||
      (dataType.value === 'wood-cubature' && reportEntry.compositionValue === 0);
    return !hasToBeSkipped
      ? [
          ...acc,
          columns.reduce((obj, key) => {
            let value: string | number | null;
            if (key === 'project') {
              value = projectName;
            } else if (
              key === 'quantity' ||
              key === 'price' ||
              key === 'co2Quantity' ||
              key === 'comEthIc'
            ) {
              value = roundWith2Decimal(reportEntry[key] || 0);
            } else {
              value = reportEntry[key];
            }
            // TODO : @Jalil to type please
            obj[key] = value;
            return obj;
          }, {} as ExcelJsonEntry)
        ]
      : acc;
  }, [] as ExcelJsonEntry[]);
};
