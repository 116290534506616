import { CaseId } from '../../../../../domain/specification/cases/CaseSpecification';
import { LevelId } from '../../../../../domain/granulometry/levels/LevelGranulometry';
import { Action } from 'redux';
import { push } from 'connected-react-router';
import { caseGranulometryEditLevelDetailsRoute } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditLevelDetailsRoute';

export const goToCaseGranulometryEditLevelDetails = (
  projectId: string,
  caseId: CaseId,
  levelId: LevelId
): Action => push(caseGranulometryEditLevelDetailsRoute.makePath({ projectId, caseId, levelId }));
