import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { ShopSection } from '../../../../shopSection/shopSection';
import { getShopSectionTheoreticalWallsLinear } from './getShopSectionTheoreticalWallsLinear';
import { EXPONENT, MULTIPLIER_COEFFICIENT } from '../../getSectionWallsLinear';

export const getShopSectionTheoreticalWallsLinearDetails = (shopSection: ShopSection): string => {
  let details: string[] = [];
  details = [
    ...details,
    '<b>' +
      roundWith2Decimal(shopSection.surface || shopSection.defaultSurface) +
      ' m\u00B2</b> de surface du ' +
      shopSection.title.toLowerCase()
  ];
  const hideNumbers = !(process.env.GB_ENV === 'dev' || process.env.GB_ENV === 'recette');
  details = [
    ...details,
    '<b>* ' +
      (hideNumbers
        ? 'coefficient multiplicateur'
        : MULTIPLIER_COEFFICIENT + '<sup>' + EXPONENT + '</sup>') +
      '</b>'
  ];
  details = [
    ...details,
    '<b>= ' + roundWith2Decimal(getShopSectionTheoreticalWallsLinear(shopSection)) + ' mL</b>'
  ];
  return details.join('<br />');
};
