import { LevelGranulometry } from '../../domain/granulometry/levels/LevelGranulometry';
import { LevelSpecification } from '../../domain/specification/levels/LevelSpecification';
import { Maybe } from '../../utils/Maybe';
import { FeatureParcel } from '../../domain/Parcel';
import { BuildingSpecification } from '../../domain/specification/buildings/BuildingSpecification';
import { CaseSpecificationWithIndexes } from '../../domain/specification/cases/CaseSpecification';
import { ProjectSurfaceInputMode } from '../../domain/project/Project';
import { InitializeLayersRouting } from '../../store/selectors/navigation/toolbox/casesPanel/selectInitializeLayersRouting.selector';
import {
  CaseLayer,
  formatBuildingSpecToLayer,
  formatCaseLayersWithoutGroundLevelToLevelLayersWithoutGeometry,
  formatCaseSpecToLayer,
  formatLevelSpecToLevelLayersWithGeometry
} from './layer/layer.model';
import * as R from 'ramda';

export const initializeLayers = (
  levelsGranulometry: LevelGranulometry[],
  levelsSpecification: LevelSpecification[],
  parcels: Maybe<FeatureParcel[]>,
  buildings: Maybe<BuildingSpecification[]>,
  cases: CaseSpecificationWithIndexes[],
  projectSurfaceInputMode: Maybe<ProjectSurfaceInputMode>,
  initializeLayersRouting: InitializeLayersRouting
) => {
  const isLoading = projectSurfaceInputMode === 'gfsEff' ? false : levelsGranulometry?.length === 0;
  const caseLayers = formatCaseSpecToLayer(cases, initializeLayersRouting.routeMatch?.caseId);
  if (caseLayers?.length > 0) {
    const levelLayersWithGeometry = formatLevelSpecToLevelLayersWithGeometry(
      levelsSpecification,
      initializeLayersRouting,
      caseLayers as CaseLayer[]
    );

    const levelLayersWithoutGeometry =
      formatCaseLayersWithoutGroundLevelToLevelLayersWithoutGeometry(cases);

    const selectedCase = caseLayers.find(
      (caseLayer) => caseLayer.id === initializeLayersRouting.routeMatch?.caseId
    );
    const buildingLayers =
      buildings && buildings?.length > 0
        ? formatBuildingSpecToLayer(buildings, selectedCase?.parent)
        : [];

    return {
      parcels,
      layers: [
        ...buildingLayers,
        ...caseLayers,
        ...levelLayersWithoutGeometry,
        ...levelLayersWithGeometry
      ],
      isMapInitialized: parcels && parcels.length > 0 && parcels.some(R.has('geometry')),
      isLoading
    };
  }
};
