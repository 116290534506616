import { CaseGranulometry } from '../../cases/CaseGranulometry';
import { LevelGranulometry } from '../LevelGranulometry';
import { getCaseLevels } from '../../cases/queries/levels/getCaseLevels';
import { Maybe } from '../../../../utils/Maybe';

export const getLowerLevel = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): Maybe<LevelGranulometry> =>
  getCaseLevels(caseGranulometry).find((level) => level.level === levelGranulometry.level - 1);
