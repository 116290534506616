import { CellInfo, DerivedDataObject } from 'react-table';

export const getTotalPrice = (cellOrRow: CellInfo | DerivedDataObject): number =>
  (cellOrRow as CellInfo).subRows || (cellOrRow as DerivedDataObject)._subRows
    ? cellOrRow.subRows
      ? cellOrRow.subRows.reduce(
          (acc: number, row: DerivedDataObject) => acc + getTotalPrice(row),
          0
        )
      : (cellOrRow as DerivedDataObject)._subRows.reduce(
          (acc: number, row: DerivedDataObject) => acc + getTotalPrice(row),
          0
        )
    : (cellOrRow as DerivedDataObject).price || 0;
