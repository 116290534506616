import * as React from 'react';
import * as R from 'ramda';
import { BuildingMapLevelView } from './BuildingMapLevelView';
import { RoofingSection } from '../../../../domain/granulometry/sections/roofingSections/RoofingSection';
import { FundationsSection } from '../../../../domain/granulometry/sections/fundationsSections/FundationsSection';
import { SectionInLevelGranulometryFullFilledContent } from '../../../../domain/granulometry/levels/LevelGranulometry';

export interface BuildingMapLevelProps {
  buildingId: string;
  caseId: string;
  level: any;
  direction?: 'ltr' | 'rtl';
  content?: SectionInLevelGranulometryFullFilledContent[];
  roofing?: RoofingSection[];
  fundations?: FundationsSection[];
  surface: number;
  ceilingHeight?: number;
  floorThickness?: number;
  wallThickness?: number;
  id: string;
  prev?: any;
  next?: any;
  parent?: any;
  outsideLevel?: boolean;
  sectionWrapper?: any;
  outsideRulerWidth?: number;
  outsideSurface?: number;
  forwardRef?: React.Ref<HTMLDivElement>;
  surfaceForSale: number;
  realBuiltSurface: number;
}

export class BuildingMapLevelViewController extends React.Component<BuildingMapLevelProps, any> {
  /**
   * Constructor
   * @param props React props
   */
  constructor(props: any) {
    super(props);

    this.manageSectionWrapper = this.manageSectionWrapper.bind(this);
  }

  public UNSAFE_componentWillMount() {
    this.manageSectionWrapper();
  }

  public componentDidUpdate(prevProps: any) {
    if (R.equals(this.props.sectionWrapper, prevProps.sectionWrappers)) {
      this.manageSectionWrapper();
    }
  }

  private manageSectionWrapper() {
    const { content } = this.props;

    if (content && content.length !== 0) {
      content.forEach((contentElem: any, index: any) => {
        content[index].parent = this.props;
        if (content[index - 1]) {
          contentElem.prev = content[index - 1];
        }
        if (content[index + 1]) {
          contentElem.next = content[index + 1];
        }
      });
    }
  }

  /**
   * Rendering method
   */
  public render() {
    return <BuildingMapLevelView {...this.props} ref={this.props.forwardRef} />;
  }
}
