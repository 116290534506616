import { Surface } from '../../specification/Surface';
import { MAXIMUM_PROJECT_GFS, MINIMUM_PROJECT_GFS } from '../../project/Project';

export const projectGfsEffIsUndefined = (projectGfsEff?: Surface): boolean =>
  projectGfsEff === undefined;
export const projectGfsEffIsEmpty = (projectGfsEff: Surface): boolean =>
  projectGfsEff === Surface.EMPTY;
export const projectGfsEffIsSmallerThanTheMinimum = (projectGfsEff: Surface): boolean =>
  projectGfsEff.value < MINIMUM_PROJECT_GFS;
export const projectGfsEffIsLargerThanTheMaximum = (projectGfsEff: Surface): boolean =>
  projectGfsEff.value > MAXIMUM_PROJECT_GFS;

export const projectGfsEffIsValid = (projectGfsEff?: Surface): boolean =>
  !projectGfsEffIsUndefined(projectGfsEff) &&
  !projectGfsEffIsEmpty(projectGfsEff as Surface) &&
  !projectGfsEffIsSmallerThanTheMinimum(projectGfsEff as Surface) &&
  !projectGfsEffIsLargerThanTheMaximum(projectGfsEff as Surface);
