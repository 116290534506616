import classNames from 'classnames';
import * as React from 'react';
import { IconObject } from '../../../../components/ui/Icons/iconObject';
import { BuildingMapLevelViewController } from '../level/BuildingMapLevelViewController';

export interface BuildingMapLevelInterface {
  outsideLevels: any[];
  outsideAmeneties: any[];
  topLevelsHeight: number;
}

export const BuildingMapOutSideLevelView = (props: BuildingMapLevelInterface) => {
  const { outsideAmeneties, outsideLevels, topLevelsHeight } = props;

  const classes = classNames('outsideLevels', 'levels', {
    withAmeneties: outsideAmeneties.length !== 0
  });

  const outsideLevelsContentStyle = {
    minHeight: topLevelsHeight + 'px'
  };

  return (
    <div className={classes}>
      <div className="levelsContent" style={outsideLevelsContentStyle}>
        <div className="levelsBackground" />
        <div className="levelsBorders" />
        {outsideLevels.map((outsideLevel: any, key: number) => (
          <BuildingMapLevelViewController key={key} {...outsideLevel} />
        ))}
        {outsideAmeneties.length !== 0 && (
          <div className="outsideAmeneties">
            {outsideAmeneties.map((outsideAmenetie: any, key: number) => (
              <IconObject key={key} {...outsideAmenetie} id={'outsideAmenetie' + key} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
