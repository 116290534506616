import { Surface } from '../../../../specification/Surface';
import { CaseSpecification } from '../../../../specification/cases/CaseSpecification';
import { getCaseBasementLevelsDefaultCount } from '../../../../granulometry/cases/queries/levels/counts/getCaseBasementLevelsDefaultCount';
import { isBasementLevel } from '../../../../specification/levels/queries/is/isBasementLevel';

export const getCaseProjectedBasementLevelCount = (
  caseSpecification: CaseSpecification,
  projectedRealBuiltSurface: Surface
): number =>
  caseSpecification.projectSurfaceInputMode === 'gfsEff'
    ? caseSpecification.levels.reduce(
        (acc, levelSpecification) => (isBasementLevel(levelSpecification) ? acc + 1 : acc),
        0
      )
    : caseSpecification.basementLevelsCount ??
      getCaseBasementLevelsDefaultCount(projectedRealBuiltSurface);
