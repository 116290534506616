import * as R from 'ramda';
import { createSelector, Selector } from 'reselect';
import { ifDefined } from '../../../utils/ifDefined';
import { Surface } from '../../../domain/specification/Surface';
import { BuildingId } from '../../../domain/specification/buildings/BuildingSpecification';
import { selectLodgmentsSurfaceByBuilding } from './lodgmentsSurfaceByBuilding.selector';
import { State } from '../../../store/reducers';
import { Maybe } from '../../../utils/Maybe';

export const selectProjectLodgmentsSurface: Selector<State, Maybe<Surface>> = createSelector(
  [selectLodgmentsSurfaceByBuilding],
  ifDefined(R.pipe<[Record<BuildingId, Surface>], Surface[], Surface>(R.values, Surface.sum))
);
