import { createSelector, Selector } from 'reselect';
import { ifDefined } from '../../../utils/ifDefined';
import { selectLotsReport } from '../lotsReport.selector';
import { getWoodCubatureByBuildingAndCase } from '../../../domain/report/queries/getWoodCubatureByBuildingAndCase';
import { State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';

type WoodCubatureByBuildingAndCase = Maybe<Record<string, Record<string, number>>>;

export const selectWoodCubatureByBuildingAndCase: Selector<State, WoodCubatureByBuildingAndCase> =
  createSelector([selectLotsReport], ifDefined(getWoodCubatureByBuildingAndCase));
