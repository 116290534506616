import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../ui/Card';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { Window } from '../../../../ui/Window';
import { FormGroup } from '../../../../ui/FormGroup';
import { IconObject } from '../../../../ui/Icons/iconObject';
import { Note } from '../../../../ui/Note';
import { selectCurrentlyEditedProperty } from '../../../../../store/selectors/navigation/toolbox/casesPanel/currentlyEditedProperty.selector';
import { casePropertyChanged } from '../../../../../store/actions/casePropertyChanged.action';
import { useParams } from 'react-router';
import { selectCurrentlyDisplayedCasePropertiesNames } from '../../../../../store/selectors/navigation/toolbox/casesPanel/currentlyDisplayedCasePropertiesNames.selector';
import { Form } from '../../../../ui/Form';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { Input } from '../../../../ui/input/Input';
import { Maybe } from '../../../../../utils/Maybe';
import { secureInputChange } from '../../../../../utils/secureInputChange';
import { CaseGranulometryEditOtherPropertiesRouteParams } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditOtherPropertiesRoute';
import { getCaseGranulometryEditOtherPropertiesPropertyTriggerId } from '../../../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditOtherPropertiesPropertyTriggerId';
import { goToCase } from '../../../../../store/actions/navigations/toolbox/casesPanel/goToCase.action';
import { goToCaseGranulometryEditOtherPropertiesProperty } from '../../../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditOtherPropertiesProperty.action';

/* const useKeyPress = targetKey => {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = React.useState(false);

  // If pressed key is our target key then set to true
  const downHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  };

  // If released key is our target key then set to false
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  // Add event listeners
  React.useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return keyPressed;
};
*/

const getPreviousFilteredPropertyName = (
  filteredPropertiesNames: string[],
  currentPropertyName: string
): Maybe<string> => {
  const currentIndex = filteredPropertiesNames.findIndex((name) => name === currentPropertyName);
  return filteredPropertiesNames[currentIndex - 1];
};

const getNextFilteredPropertyName = (
  filteredPropertiesNames: string[],
  currentPropertyName: string
): Maybe<string> => {
  const currentIndex = filteredPropertiesNames.findIndex((name) => name === currentPropertyName);
  return filteredPropertiesNames[currentIndex + 1];
};

export const PropertyWindow = () => {
  const { projectId, caseId } = useParams<CaseGranulometryEditOtherPropertiesRouteParams>();
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const currentlyEditedProperty = useSelector(selectCurrentlyEditedProperty);
  const filteredPropertiesNames = useSelector(selectCurrentlyDisplayedCasePropertiesNames);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const dispatchCasePropertyChanged = React.useCallback(
    (value: number | null) => {
      if (caseId && currentlyEditedProperty) {
        dispatch(
          casePropertyChanged(caseId, {
            ...currentlyEditedProperty,
            value:
              value === null
                ? undefined
                : secureInputChange(value, currentlyEditedProperty.min, currentlyEditedProperty.max)
          })
        );
      }
    },
    [dispatch, caseId, currentlyEditedProperty]
  );

  const previousPropertyName = currentlyEditedProperty
    ? getPreviousFilteredPropertyName(filteredPropertiesNames, currentlyEditedProperty.name)
    : undefined;
  const handleClickPrev = React.useCallback(() => {
    if (projectId && caseId && previousPropertyName) {
      dispatch(
        goToCaseGranulometryEditOtherPropertiesProperty(projectId, caseId, previousPropertyName)
      );
    }
  }, [dispatch, projectId, caseId, previousPropertyName]);

  const nextPropertyName = currentlyEditedProperty
    ? getNextFilteredPropertyName(filteredPropertiesNames, currentlyEditedProperty.name)
    : undefined;
  const handleClickNext = React.useCallback(() => {
    if (projectId && caseId && nextPropertyName) {
      dispatch(
        goToCaseGranulometryEditOtherPropertiesProperty(projectId, caseId, nextPropertyName)
      );
    }
  }, [dispatch, projectId, caseId, nextPropertyName]);

  const handleClickClose = React.useCallback(() => {
    if (projectId && caseId) {
      dispatch(goToCase(projectId, caseId));
    }
  }, [dispatch, projectId, caseId]);

  // TODO : add key press control
  /*
  const isUpPressed = useKeyPress('ArrowUp');
  const isDownPressed = useKeyPress('ArrowDown');
  // const isLeftPressed = useKeyPress('ArrowLeft');
  // const isRightPressed = useKeyPress('ArrowRight');

  if (isDownPressed) {
    if (hasNextProperty(currentlyEditedProperty, currentlyDisplayedCasePropertiesNames)) {
      goToCaseGranulometryEditProperty(
        getNextProperty(currentlyEditedProperty, currentlyDisplayedCasePropertiesNames).name
      );
    }
  }
  if (isUpPressed) {
    if (hasPreviousProperty(currentlyEditedProperty, currentlyDisplayedCasePropertiesNames)) {
      goToCaseGranulometryEditProperty(
        getPreviousProperty(currentlyEditedProperty, currentlyDisplayedCasePropertiesNames).name
      );
    }
  }
  */

  // TODO : on refresh #casePropertiesTable is not visible : need to scroll down
  /* React.useEffect(() => {
    document
      .querySelector('.granulometry > div > div')
      .scrollTo(0, document.getElementById('casePropertiesTable').getBoundingClientRect().y);
  }); */

  if (!caseId || !caseLabel || !currentlyEditedProperty || !filteredPropertiesNames) return null;

  return (
    <Window
      title={`${t('Property')} : ${currentlyEditedProperty.title}`}
      topButtons={
        <>
          <IconObject
            iconName="arrowTop"
            type="menu"
            disabled={!previousPropertyName}
            onClick={handleClickPrev}
          />
          <IconObject
            iconName="arrowBottom"
            type="menu"
            disabled={!nextPropertyName}
            onClick={handleClickNext}
          />
          <IconObject iconName="close" type="menu" onClick={handleClickClose} />
        </>
      }
      triggerId={getCaseGranulometryEditOtherPropertiesPropertyTriggerId(
        caseLabel,
        currentlyEditedProperty.name
      )}>
      <Form>
        <Card>
          <SpecialLabel label={t('Property value')} />
          <FormGroup horizontal={true}>
            <div style={{ width: '50%' }}>
              <label htmlFor="surface">{t('Property value')}</label>
              <Input
                name="propertyValue"
                type="number"
                min={currentlyEditedProperty.min}
                max={currentlyEditedProperty.max}
                value={currentlyEditedProperty.value}
                placeholder={currentlyEditedProperty.defaultValue}
                handleChange={dispatchCasePropertyChanged}
                suffix={currentlyEditedProperty.unit}
                disabled={currentlyEditedProperty.min === currentlyEditedProperty.max}
              />
            </div>
            <div style={{ width: '50%', display: 'flex', alignItems: 'flex-end' }}>
              {currentlyEditedProperty.min !== undefined &&
                currentlyEditedProperty.max !== undefined && (
                  <Note style={{ marginBottom: '5px', padding: '3px 6px' }}>
                    min = {currentlyEditedProperty.min + ' ' + currentlyEditedProperty.unit} <br />
                    max = {currentlyEditedProperty.max + ' ' + currentlyEditedProperty.unit}
                  </Note>
                )}
              &nbsp;
            </div>
          </FormGroup>
          {currentlyEditedProperty.notes && <Note>{currentlyEditedProperty.notes}</Note>}
        </Card>
      </Form>
    </Window>
  );
};

export default PropertyWindow;
