import * as React from 'react';
import { IconObject } from 'components/ui/Icons/iconObject';

export interface BuildingMapSectionMapProps {
  /** the Section models to display */
  rooms: any[];
  /** the section icon parameters */
  icon: any;
}

export class BuildingMapSectionMapViewController extends React.Component<
  BuildingMapSectionMapProps,
  any
> {
  /**
   * Constructor
   * @param props React props
   */
  constructor(props: any) {
    super(props);
  }

  /**
   * Rendering method
   */
  public render() {
    const { icon } = this.props;

    return (
      <div className="sectionMap">
        <div className="icons">
          <IconObject {...icon} />
        </div>
        <div className="rooms" />
      </div>
    );
  }
}
