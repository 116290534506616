import { createSelector, Selector } from 'reselect';
import { State } from '../../reducers';
import { Position } from '@turf/helpers/dist/js/lib/geojson';
import { selectProjectLocation } from '../project/projectLocation.selector';

export const selectProjectPosition: Selector<State, Position | undefined> = createSelector(
  [selectProjectLocation],
  (location) =>
    location.latitude && location.longitude ? [location.latitude, location.longitude] : undefined
);
