import i18next from 'i18next';
import { BasicRoomSpecification } from '../../specification/rooms/BasicRoomSpecification';

const kitchen = (): BasicRoomSpecification =>
  ({
    name: 'kitchen',
    title: i18next.t('Kitchen'),
    defaultLength: 0,
    width: undefined,
    defaultWidth: 0,
    surface: undefined,
    icon: {
      type: 'object',
      iconName: 'kitchen',
      strokeStyle: 'opened'
    }
  } as const);

export default kitchen;
