import { SECTION_WRAPPERS } from '../../constants/appConstants';
import { CaseGranulometry } from '../../domain/granulometry/cases/CaseGranulometry';
import { getLevelAllButVerticalCirculationSectionsSurface } from '../../domain/granulometry/levels/queries/surfaces/getLevelAllButVerticalCirculationSectionsSurface';
import { isAnyCaseLevelSectionWrapped } from './isAnyCaseLevelSectionWrapped';
import { LevelGranulometry } from '../../domain/granulometry/levels/LevelGranulometry';
import { getCaseGroundLevel } from '../../domain/granulometry/cases/queries/levels/getCaseGroundLevel';
import { isBasementLevel } from '../../domain/granulometry/levels/queries/is/isBasementLevel';

export const shouldThisLevelBeSectionWrapped = (
  levelGranulometry: LevelGranulometry,
  caseGranulometry: CaseGranulometry
): boolean => {
  // The constant SECTION_WRAPPERS must be true
  // The level must be a parkings
  // The level not grouped sections total surface must be bigger than the ground level not grouped sections total surface + 100
  // OR the previous level is section wrapped (note : it may be if one level wrapped all should be)
  return !!(
    SECTION_WRAPPERS &&
    isBasementLevel(caseGranulometry, levelGranulometry) &&
    (getLevelAllButVerticalCirculationSectionsSurface(levelGranulometry) >
      getLevelAllButVerticalCirculationSectionsSurface(getCaseGroundLevel(caseGranulometry)) +
        100 ||
      isAnyCaseLevelSectionWrapped(caseGranulometry))
  );
};
