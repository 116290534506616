import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../levels/LevelGranulometry';
import { getCaseAverageHeatingSystem } from '../../../cases/queries/get/getCaseAverageHeatingSystem';
import { HeatingSystem } from '../../../../specification/lodgmentTypes/HeatingSystem';
import {
  BEARING_TECHNICAL_SHEATH_PARTITION_WALL_WIDTH,
  ELECTRICITY_BEARING_TECHNICAL_SHEATH_WIDTH,
  GENERAL_BEARING_TECHNICAL_SHEATH_WIDTH,
  INDIVIDUAL_GAS_BEARING_TECHNICAL_SHEATH_WIDTH,
  OTHER_HEATING_SYSTEM_BEARING_TECHNICAL_SHEATH_WIDTH,
  WATER_BEARING_TECHNICAL_SHEATH_WIDTH
} from './getBearingTechnicalSheathsWidth';
import { mustHaveGasBearingTechnicalSheaths } from './mustHaveGasBearingTechnicalSheaths';

export const getBearingTechnicalSheathsWidthDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry | LevelGranulometryWithBearingRelativeSections
): string => {
  let details: string[] = [];
  // Water
  if (levelGranulometry.waterBearingTechnicalSheathEnabled !== false) {
    details = [
      ...details,
      '&nbsp;&nbsp;-> <b>' +
        WATER_BEARING_TECHNICAL_SHEATH_WIDTH +
        ' m</b> de gaine AEF (Alimentation Eau Froide)'
    ];
  }
  // General
  if (levelGranulometry.generalBearingTechnicalSheathEnabled !== false) {
    details = [
      ...details,
      '&nbsp;&nbsp;-> <b>' +
        GENERAL_BEARING_TECHNICAL_SHEATH_WIDTH +
        ' m</b> de gaine SG (Services Généraux)'
    ];
  }
  // Gaz – Heating system
  if (
    levelGranulometry.gasBearingTechnicalSheathEnabled !== false &&
    mustHaveGasBearingTechnicalSheaths(caseGranulometry)
  ) {
    details = [
      ...details,
      '&nbsp;&nbsp;-> <b>' +
        (getCaseAverageHeatingSystem(caseGranulometry) === HeatingSystem.IndividualGasHeating
          ? INDIVIDUAL_GAS_BEARING_TECHNICAL_SHEATH_WIDTH
          : OTHER_HEATING_SYSTEM_BEARING_TECHNICAL_SHEATH_WIDTH) +
        ' m</b> de gaine ' +
        (getCaseAverageHeatingSystem(caseGranulometry) === HeatingSystem.IndividualGasHeating
          ? 'gaz individuel'
          : 'chauffage collectif')
    ];
  }
  // Electricity
  if (levelGranulometry.electricityBearingTechnicalSheathEnabled !== false) {
    details = [
      ...details,
      '&nbsp;&nbsp;-> <b>' +
        ELECTRICITY_BEARING_TECHNICAL_SHEATH_WIDTH +
        ' m</b> de gaine ENEDIS (colonne électrique)'
    ];
  }
  // Communication
  if (levelGranulometry.communicationBearingTechnicalSheathEnabled !== false) {
    details = [
      ...details,
      '&nbsp;&nbsp;-> <b>' +
        ELECTRICITY_BEARING_TECHNICAL_SHEATH_WIDTH +
        ' m</b> de gaine FT (France Télécom)'
    ];
  }
  // Partition walls
  details = [
    ...details,
    '&nbsp;&nbsp;-> <b>max(0, ' +
      details.length +
      '</b> gaine' +
      (details.length > 1 ? 's' : '') +
      '<b>- 1) * ' +
      BEARING_TECHNICAL_SHEATH_PARTITION_WALL_WIDTH +
      '</b> m de largeur de cloisons séparatives'
  ];

  return details.join('<br />');
};
