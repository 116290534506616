import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectCurrentCaseIdFromRoute } from '../../../store/selectors/navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { setCaseTopLevelsCount } from '../../../store/actions/setCaseTopLevelsCount.action';
import { setCaseBasementLevelsCount } from '../../../store/actions/setCaseBasementLevelsCount.action';
import { selectCurrentCaseSpecifiedBasementLevelsCount } from '../../../store/selectors/specification/currentCase/basementLevelsCount.selector';
import { selectCurrentCaseBasementLevelsCount } from '../../../store/selectors/granulometry/currentCase/basementLevelsCount.selector';
import { secureInputChange } from '../../../utils/secureInputChange';
import { FormGroup } from '../../ui/FormGroup';
import { CaseEditingStageName } from '../../../domain/specification/cases/CaseSpecification';
import { selectCurrentCaseEditingStage } from '../../../store/selectors/specification/currentCase/editingStage.selector';
import { Input } from '../../ui/input/Input';
import { selectCurrentCaseGranulometryTopLevelsCount } from '../../../store/selectors/granulometry/currentCase/currentCaseGranulometryTopLevelsCount.selector';
import { selectCurrentCaseSpecifiedTopLevelsCount } from '../../../store/selectors/specification/currentCase/specifiedTopLevelsCount.selector';
import {
  MAXIMUM_BASEMENT_LEVEL_NUMBER,
  MAXIMUM_TOP_LEVEL_NUMBER,
  MINIMUM_BASEMENT_LEVEL_NUMBER,
  MINIMUM_TOP_LEVEL_NUMBER
} from '../../../constants/appConstants';
import { selectCurrentCaseSpecification } from '../../../store/selectors/specification/currentCase/specification.selector';
import { isProjectInputModeGfsEff } from '../../../domain/specification/project/queries/is/isProjectInputModeGfsEff';

export const TopAndBasementLevelsCount = () => {
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const caseSpecification = useSelector(selectCurrentCaseSpecification);
  const specifiedTopLevelCount = useSelector(selectCurrentCaseSpecifiedTopLevelsCount);
  const topLevelsCountInGranulometry = useSelector(selectCurrentCaseGranulometryTopLevelsCount);
  const specifiedBasementLevelCount = useSelector(selectCurrentCaseSpecifiedBasementLevelsCount);
  const basementLevelsCount = useSelector(selectCurrentCaseBasementLevelsCount);
  const editingStage = useSelector(selectCurrentCaseEditingStage);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  if (!caseId) return null;

  return (
    <div className="line level-and-basement-line" style={{ justifyContent: 'center' }}>
      <FormGroup horizontal={true} wrap={true} style={{ maxWidth: '150px' }}>
        <Input
          name="topLevelsCount"
          label={t('topLevelsCount.label')}
          info={t('topLevelsCount.infos')}
          style={{ marginTop: '3px', width: '63%', flex: '0 0 63%' }}
          type="number"
          minMax={{
            min: MINIMUM_TOP_LEVEL_NUMBER,
            max: MAXIMUM_TOP_LEVEL_NUMBER
          }}
          value={specifiedTopLevelCount}
          placeholder={topLevelsCountInGranulometry}
          handleChange={(value: number | null) =>
            dispatch(
              setCaseTopLevelsCount(
                caseId,
                value === null
                  ? undefined
                  : secureInputChange(
                      Math.round(value),
                      MINIMUM_TOP_LEVEL_NUMBER,
                      MAXIMUM_TOP_LEVEL_NUMBER
                    )
              )
            )
          }
          disabled={
            isProjectInputModeGfsEff(caseSpecification) ||
            editingStage === CaseEditingStageName.Distribution
          }
        />
      </FormGroup>
      <FormGroup horizontal={true} wrap={true} style={{ maxWidth: '150px' }}>
        <Input
          name="basementLevelsCount"
          label={t('basementLevelsCount.label')}
          info={t('basementLevelsCount.infos')}
          style={{ marginTop: '3px', width: '635%', flex: '0 0 63%' }}
          type="number"
          minMax={{
            min: MINIMUM_BASEMENT_LEVEL_NUMBER,
            max: MAXIMUM_BASEMENT_LEVEL_NUMBER
          }}
          value={specifiedBasementLevelCount}
          placeholder={basementLevelsCount}
          handleChange={(value: number | null) =>
            dispatch(
              setCaseBasementLevelsCount(
                caseId,
                value === null
                  ? undefined
                  : secureInputChange(
                      Math.round(value),
                      MINIMUM_BASEMENT_LEVEL_NUMBER,
                      MAXIMUM_BASEMENT_LEVEL_NUMBER
                    )
              )
            )
          }
          disabled={isProjectInputModeGfsEff(caseSpecification)}
        />
      </FormGroup>
    </div>
  );
};
