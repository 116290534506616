import { LevelGranulometryFullFilled } from '../../../LevelGranulometry';
import { isSectionAElevatorShaft } from '../../../../sections/circulationSections/elevatorShaft';
import { isSectionARamp } from '../../../../sections/circulationSections/ramp';
import { isSectionAStairsShaft } from '../../../../sections/circulationSections/stairsShaft';
import { isGroundLevel } from '../../is/isGroundLevel';
import { getTopLevelHoppersSurface } from './getTopLevelHoppersSurface';
import { isSectionASmokeExtractionDucts } from '../../../../sections/circulationSections/smokeExtractionDucts';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { mustHaveSmokeExtractionDuctSections } from '../../mustHave/mustHaveSmokeExtractionDuctSections';
import { getSectionDisplayedSurface } from '../../../../sections/queries/surfaces/getSectionDisplayedSurface';
import { mustHaveElevatorShaftSections } from '../../mustHave/mustHaveElevatorShaftSections';
import { mustHaveStairShaftSections } from '../../mustHave/mustHaveStairShaftSections';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';
import { getLevelFullFilledContent } from '../../content/getLevelFullFilledContent';

export const getTopLevelHoppersSurfaceDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled
): string => {
  let details: string[] = [];
  if (mustHaveSmokeExtractionDuctSections(caseGranulometry)) {
    getLevelFullFilledContent(levelGranulometry)
      .filter(isSectionASmokeExtractionDucts)
      .forEach((section) => {
        details = [
          ...details,
          '<b>' +
            roundWith2Decimal(getSectionDisplayedSurface(section)) +
            ' m\u00B2</b> de <i>gaines de désenfumage</i>'
        ];
      });
  }

  if (mustHaveElevatorShaftSections(caseGranulometry)) {
    getLevelFullFilledContent(levelGranulometry)
      .filter(isSectionAElevatorShaft)
      .forEach((section) => {
        details = [
          ...details,
          '<b>' +
            roundWith2Decimal(getSectionDisplayedSurface(section)) +
            ' m\u00B2</b> de <i>Cage d’ascenseur</i>'
        ];
      });
  }

  if (mustHaveStairShaftSections(caseGranulometry) && !isGroundLevel(levelGranulometry)) {
    getLevelFullFilledContent(levelGranulometry)
      .filter(isSectionAStairsShaft)
      .forEach((section) => {
        const stairShaftSurface = getSectionDisplayedSurface(section);
        const stairShaftBearingSurface =
          (section?.bearingWidth || 0) * (section?.bearingLength || 0);
        details = [
          ...details,
          '<b>' +
            roundWith2Decimal(stairShaftSurface - stairShaftBearingSurface) +
            ' m\u00B2</b> de <i>Cage d’escalier (sans le palier)</i>'
        ];
      });
  }

  const rampSurface =
    getLevelFullFilledContent(levelGranulometry).find(isSectionARamp)?.displayedSurface || 0;
  if (rampSurface) details = [...details, roundWith2Decimal(rampSurface) + ' m\u00B2 de rampe'];

  return (
    details.join('<br />+ ') +
    '<br /><b>= ' +
    roundWith2Decimal(getTopLevelHoppersSurface(caseGranulometry, levelGranulometry)) +
    ' m\u00B2</b>'
  );
};
