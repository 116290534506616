import * as R from 'ramda';
import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import { Property } from '../../specification/cases/Property';

export const migrateProject20To21 = (project: Project): Project =>
  mapCases((caseSpecification) =>
    R.over(
      R.lensPath(['properties']),
      R.map((property: Property) =>
        property.name === 'groundSlabThickness' ? { ...property, max: 80 } : property
      ),
      caseSpecification
    )
  )(project);
