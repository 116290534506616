export class ApiError extends Error {
  protected readonly _requestedUrl: string;
  protected readonly _statusCode: number;
  protected readonly _statusText: string;

  constructor(message: string, requestedUrl: string, statusCode: number, statusText: string) {
    super(message);
    this._requestedUrl = requestedUrl;
    this._statusCode = statusCode;
    this._statusText = statusText;
  }

  get statusText(): string {
    return this._statusText;
  }

  public isApiError = true;
}
