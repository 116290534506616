import { getHeight } from './getHeight';

export const getHighestChildCase = (element: Element): Element => {
  let highestHeight = 0;
  let highestCase;
  // Element must have "buildings" or "building" class (it must be checked before)
  const cases: NodeListOf<HTMLElement> = element.querySelectorAll('.case');
  for (let i = 0; i < cases.length; i++) {
    const caseHeight = getHeight(cases[i]);
    if (highestHeight < caseHeight) {
      highestHeight = caseHeight;
      highestCase = cases[i];
    }
  }
  return highestCase as Element;
};
