import { AdminReportEntry, ReportEntry } from './ReportEntry';
import { Nomenclature } from '../Nomenclature';
import { getNomenclatureElementTitleFromRef } from './getNomenclatureElementTitleFromRef';

export const addNomenclatureTitlesInReport = (
  report: AdminReportEntry[] | undefined,
  nomenclature: Nomenclature | undefined
): ReportEntry[] | undefined => {
  if (!report || !nomenclature) {
    return undefined;
  } else {
    return [...report].reduce((acc: ReportEntry[], reportEntry) => {
      const refN = ('refN' + nomenclature.id) as 'refN1' | 'refN2';
      const refSplited = reportEntry[refN].split('.');
      const hasLot = refSplited[0];
      const hasSsLot1 = hasLot && refSplited[1];
      const hasSsLot2 = hasLot && hasSsLot1 && refSplited[2];
      const hasSsLot3 = hasLot && hasSsLot1 && hasSsLot2 && refSplited[3];

      const newReportEntry: ReportEntry = {
        ...reportEntry,
        refLot: hasLot ? refSplited[0] : '',
        lot: hasLot ? getNomenclatureElementTitleFromRef(nomenclature, refSplited[0]) : '',
        refSsLot1: hasSsLot1 ? refSplited[0] + '.' + refSplited[1] : '',
        ssLot1: hasSsLot1
          ? getNomenclatureElementTitleFromRef(nomenclature, refSplited[0] + '.' + refSplited[1])
          : '',
        refSsLot2: hasSsLot2 ? refSplited[0] + '.' + refSplited[1] + '.' + refSplited[2] : '',
        ssLot2: hasSsLot2
          ? getNomenclatureElementTitleFromRef(
              nomenclature,
              refSplited[0] + '.' + refSplited[1] + '.' + refSplited[2]
            )
          : '',
        refSsLot3: hasSsLot3 ? reportEntry[refN] : '',
        ssLot3: hasSsLot3 ? getNomenclatureElementTitleFromRef(nomenclature, reportEntry[refN]) : ''
      };
      return refSplited[0] !== '' ? [...acc, newReportEntry] : acc;
    }, []);
  }
};
