import * as React from 'react';
import {
  MINIMUM_OUTSIDE_RULER_WIDTH,
  SMALL_SECTION_SURFACE,
  SQUARE_METER_SCALE
} from '../../../../constants/appConstants';
import { MapPositionContextConsumer } from '../../../../contexts/MapPositionContext';
import { BuildingMapSectionViewController } from '../section/BuildingMapSectionViewController';
import { getLevelAllButVerticalCirculationSectionsSurface } from '../../../../domain/granulometry/levels/queries/surfaces/getLevelAllButVerticalCirculationSectionsSurface';
import { getCaseFirstBasementLevel } from '../../../../domain/granulometry/cases/queries/levels/getCaseFirstBasementLevel';
import { getLevelGroupedSectionsDrawnSurface } from '../../../../domain/granulometry/levels/queries/surfaces/getLevelGroupedSectionsDrawnSurface';
import { getSectionsTotalDrawnSurface } from '../../../../domain/granulometry/sections/queries/surfaces/getSectionsTotalDrawnSurface';
import { isThisLevelSectionWrapped } from '../../../../utils/buildingMap/isThisLevelSectionWrapped';
import { SectionWrapper } from '../../../../domain/granulometry/levels/LevelGranulometry';
import { getCaseGroundLevel } from '../../../../domain/granulometry/cases/queries/levels/getCaseGroundLevel';
import { FundationsSection } from '../../../../domain/granulometry/sections/fundationsSections/FundationsSection';

export interface BuildingMapFundationsProps {
  fundations: FundationsSection[];
  prev: any;
  next: any;
  parent: any;
  sectionWrapper?: SectionWrapper;
  surface: number;
  direction: 'ltr' | 'rtl';
  outsideRulerWidth?: number;
  outsideRulerLeft?: number;
}

export const BuildingMapFundationsView = (props: BuildingMapFundationsProps) => {
  const { direction, fundations, prev, next, parent, sectionWrapper } = props;
  // let outsideRulerWidth = props.outsideRulerWidth;

  if (fundations) {
    const isPrevLevelSectionWrapped = !!(prev && isThisLevelSectionWrapped(prev));
    // For each fundation section :
    fundations.forEach((fundationElem: any) => {
      if (fundationElem.surface !== 0) {
        if (isPrevLevelSectionWrapped) {
          const groundLevel = { ...getCaseGroundLevel(parent) };
          const prevLevelAllButVerticalCirculationSectionsWidth =
            getLevelAllButVerticalCirculationSectionsSurface(prev) * SQUARE_METER_SCALE;
          const prevLevelVerticalCirculationSectionsWidth =
            prev.surface * SQUARE_METER_SCALE - prevLevelAllButVerticalCirculationSectionsWidth;
          const prevLevelGroupedSectionsWidth =
            getLevelGroupedSectionsDrawnSurface(prev) * SQUARE_METER_SCALE;
          // ground level width + outside ruler
          const minimumBasementAllButVerticalCirculationSectionsWidth =
            (groundLevel.rbsRulerWidth || 0) + MINIMUM_OUTSIDE_RULER_WIDTH;
          // Check if ground level width + outside ruler > section wrapped lowest basement width and previous level has no fundations
          const isLevelWidthLowerThanGroundLevelWidth =
            minimumBasementAllButVerticalCirculationSectionsWidth > prev.sectionWrapper.realWidth &&
            (!prev.fundations || getSectionsTotalDrawnSurface(prev.fundations) === 0);
          let fundationSuitedWidth = 0;
          // Update the surface if the previous level is section wrapped
          if (sectionWrapper) {
            // If it is parkings level with section wrapper
            if (isLevelWidthLowerThanGroundLevelWidth) {
              // suit with ground level + outside ruler - ventilationGroupInfra with
              fundationSuitedWidth =
                minimumBasementAllButVerticalCirculationSectionsWidth -
                sectionWrapper.realWidth -
                SMALL_SECTION_SURFACE * SQUARE_METER_SCALE;
            } else {
              fundationSuitedWidth = prev.sectionWrapper.realWidth - sectionWrapper.realWidth;
            }
          } else {
            // If it is the fundation level
            if (isLevelWidthLowerThanGroundLevelWidth) {
              // suit with ground level + outside ruler
              fundationSuitedWidth =
                prevLevelVerticalCirculationSectionsWidth +
                minimumBasementAllButVerticalCirculationSectionsWidth;
            } else {
              // If ground level width < section wrapped lowest basement width : suit with lowest basement
              fundationSuitedWidth =
                prevLevelVerticalCirculationSectionsWidth +
                prev.sectionWrapper.realWidth +
                prevLevelGroupedSectionsWidth -
                prevLevelVerticalCirculationSectionsWidth;
            }
          }
          fundationElem.surface = fundationSuitedWidth / SQUARE_METER_SCALE;
        } else {
          // Previous level is not section wrapper
          if (!next) {
            // It is the fundation level
            const groundLevel = { ...getCaseGroundLevel(parent) };
            const firstBasementLevel = { ...getCaseFirstBasementLevel(parent) };
            // It is first basement level is larger than ground level
            if (groundLevel.surface < firstBasementLevel.surface) {
              const prevLevelAllButVerticalCirculationSectionsWidth =
                getLevelAllButVerticalCirculationSectionsSurface(prev) * SQUARE_METER_SCALE;
              const minimumBasementAllButVerticalCirculationSectionsWidth =
                (groundLevel.rbsRulerWidth || 0) + MINIMUM_OUTSIDE_RULER_WIDTH;
              // If ground level width + outside ruler > minimum basement width :
              // -> suit with ground level + outside ruler
              if (
                minimumBasementAllButVerticalCirculationSectionsWidth >
                  prevLevelAllButVerticalCirculationSectionsWidth &&
                (!prev.fundations || getSectionsTotalDrawnSurface(prev.fundations) === 0)
              ) {
                const prevLevelVerticalCirculationSectionsWidth =
                  prev.surface * SQUARE_METER_SCALE -
                  prevLevelAllButVerticalCirculationSectionsWidth;
                const fundationSuitedWidth =
                  prevLevelVerticalCirculationSectionsWidth +
                  minimumBasementAllButVerticalCirculationSectionsWidth;
                fundationElem.surface = fundationSuitedWidth / SQUARE_METER_SCALE;
              }
            }
          }
        }
      }
    });
  }

  parent.levels.forEach((level: any) => {
    if (level.level === -1) {
      const beforeSectionWrapperContent: any[] = [];
      const inFirstSectionWrapperContent: any[] = [];
      const inSecondSectionWrapperContent: any[] = [];
      const afterSectionWrapperContent: any[] = [];

      if (level.content) {
        level.content.forEach((contentElem: any) => {
          if (!contentElem.inSectionWrappers && inFirstSectionWrapperContent.length === 0) {
            // If the content element is BEFORE the section wrappers
            beforeSectionWrapperContent.push(contentElem);
          } else if (!contentElem.inSectionWrappers && inFirstSectionWrapperContent.length !== 0) {
            // If the content element is AFTER the section wrappers
            afterSectionWrapperContent.push(contentElem);
          } else {
            if (contentElem.inFirstSectionWrapper === true) {
              // If the content element is INSIDE the FIRST section wrappers
              inFirstSectionWrapperContent.push(contentElem);
            }
            if (contentElem.inSecondSectionWrapper === true) {
              // If the content element is INSIDE the SECOND section wrappers
              inSecondSectionWrapperContent.push(contentElem);
            }
          }
        });
      }
    }
  });

  return (
    <MapPositionContextConsumer>
      {(mapPositionContext) => (
        <>
          {fundations && fundations.length !== 0 && (
            <div className="fundations">
              {fundations.map((fundationElem, key) => {
                if (fundationElem.surface !== 0) {
                  return (
                    <BuildingMapSectionViewController
                      key={key}
                      direction={direction}
                      sectionGroupRecorder={[]}
                      focusOn={mapPositionContext.focusOn}
                      {...fundationElem}
                    />
                  );
                }
              })}
            </div>
          )}
        </>
      )}
    </MapPositionContextConsumer>
  );
};
