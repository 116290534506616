import {
  HeatingSystem,
  isHeatingSystemCollective
} from '../../../../domain/specification/lodgmentTypes/HeatingSystem';
import { getCaseAverageHeatingSystem } from '../../../../domain/granulometry/cases/queries/get/getCaseAverageHeatingSystem';
import { CaseGranulometry } from '../../../../domain/granulometry/cases/CaseGranulometry';

export type ComEthHeatingSystem =
  | 'Boiler_biomass' // Chaudière bois ou biomasse
  | 'Boiler_gas_low_temp' // Chaudière gaz basse température
  | 'Boiler_gas_condensation' // Chaudière gaz à condensation
  | 'Boiler_biogas_low_temp' // Chaudière biogaz basse température
  | 'Boiler_biogas_condensation' // Chaudière biogaz à condensation
  | 'Joule_emitter' // Convecteurs électriques
  | 'HeatPump_elec_air_air' // PAC air/air
  | 'HeatPump_elec_air_water' // PAC air/eau
  | 'HeatPump_elec_water_water' // PAC eau/eau
  | 'Stove_insert_wood' // Poele ou insert bois
  | 'HeatPump_gas' // PAC gaz
  | 'District_network' // Réseau urbain // TODO : en dev
  | null;

const adaptHeatingSystemToComEthHeatingSystem = (
  heatingSystem: HeatingSystem
): ComEthHeatingSystem => {
  if (heatingSystem === HeatingSystem.IndividualElectricHeating) return 'Joule_emitter';
  if (heatingSystem === HeatingSystem.IndividualGasHeating) return 'Boiler_gas_low_temp'; // TODO : à supprimer ?
  if (heatingSystem === HeatingSystem.CollectiveWoodHeating) return 'Boiler_biomass';
  if (heatingSystem === HeatingSystem.CollectiveGasHeating) return 'Boiler_gas_low_temp';
  if (heatingSystem === HeatingSystem.DistrictHeating) return 'Boiler_biomass'; // return 'District_network'; // TODO : en dev
  if (heatingSystem === HeatingSystem.CollectiveHeatPumpOnGroundWaterHeating)
    return 'HeatPump_elec_water_water';
  return null;
};

export type ComEthHeatingSystemProductionModeH =
  | 'Individual_production'
  | 'Building_mutualised_production'
  | null;

const getProductionModeHFromHeatingSystem = (
  heatingSystem: HeatingSystem
): ComEthHeatingSystemProductionModeH =>
  isHeatingSystemCollective(heatingSystem)
    ? 'Building_mutualised_production'
    : 'Individual_production';

export type ComEthHeatingSystemEnergyFuelH = 'Biomass' | 'Gas' | 'Biogas' | 'Electricity' | null;

const getEnergyFuelHFromHeatingSystem = (
  heatingSystem: HeatingSystem
): ComEthHeatingSystemEnergyFuelH => {
  if (heatingSystem === HeatingSystem.IndividualElectricHeating) return 'Electricity';
  if (heatingSystem === HeatingSystem.IndividualGasHeating) return 'Gas';
  if (heatingSystem === HeatingSystem.CollectiveWoodHeating) return 'Biomass';
  if (heatingSystem === HeatingSystem.CollectiveGasHeating) return 'Gas';
  if (heatingSystem === HeatingSystem.DistrictHeating) return 'Electricity'; // TODO : en dev quelle valeur ?
  if (heatingSystem === HeatingSystem.CollectiveHeatPumpOnGroundWaterHeating) return 'Electricity';
  return null;
};

export interface ComEthJsonFeatureHeatingSystems {
  production_mode_h: ComEthHeatingSystemProductionModeH; // average heating is individual or collective
  energy_fuel_h: ComEthHeatingSystemEnergyFuelH;
  h_main_production_system: ComEthHeatingSystem;
  h_backup_production_system: ComEthHeatingSystem; // set null for now
  h_main_district_source_identifier: '3001' | null; // on a neighbourhood scale : set null for now
  h_main_distant_source_identifier: '4001' | null; // on a neighbourhood scale : set null for now
  heating_max_load: null; // maximum capacity : set null for now
}

export type ComEthCoolingSystems =
  | 'None' // Aucun
  | 'HeatPump_elec_air_air' // PAC air/air
  | 'HeatPump_elec_air_water' // PAC air/eau
  | 'HeatPump_elec_water_water' // PAC eau/eau
  | 'HeatPump_elec_ground_water' // PAC sol/eau ou géothermique
  | 'HeatPump_gas' // PAC gaz
  | 'District_network' // Réseau de froid
  | null; // set null for now

export interface ComEthJsonFeatureCoolingSystems {
  production_mode_c: 'Individual_production' | 'District_mutualised_production' | '...' | null; // TODO: Antoine à remplir
  energy_fuel_c: 'Electricity' | 'Gas' | '...' | null; // 'Electricity' by default // TODO: Antoine à remplir
  c_main_production_system: ComEthCoolingSystems; // TODO: Antoine à remplir
  c_backup_production_system: ComEthCoolingSystems; // set null for now
  c_main_district_source_identifier: '3001' | null; // on a neighbourhood scale : set null for now
  c_main_distant_source_identifier: '4001' | null; // on a neighbourhood scale : set null for now
  cooling_max_load: null; // maximum capacity : set null for now
}

export type ComEthDHWSystems =
  | 'Joule_balloons' // Ballons effet joule individuels
  | 'HeatPump_balloons_elec' // Ballons thermodynamiques électrique individuels
  | 'HeatPump_balloons_gas' // Ballons thermodynamiques gaz individuels
  | 'Boiler_biogas_low_temp' // Chaudière biogas basse température
  | 'Boiler_biogas_condensation' // Chaudière biogas à condensation
  | 'Boiler_gas_low_temp' // Chaudière gaz basse température
  | 'Boiler_gas_condensation' // Chaudière gaz à condensation
  | 'Boiler_biomass' // Chaudière biomasse
  | 'Stove_biomass' // Poele bouilleur bois
  | 'Distant_dhw' // Réseau de chaleur hors quartier
  | 'District_dhw' // Réseau de chaleur local (échelle quartier)
  | 'HeatPumplective_elec' // PAC  électrique collective ou double service
  | 'HeatPumplective_gas' // PAC gaz collective ou double service
  | 'Solarlective' // Systèmes solaires collectifs
  | 'Solarividual' // Systèmes solaires individuels
  | null;

export interface ComEthJsonFeatureDHWSystems {
  production_mode_dhw: 'Individual_production' | '...' | null; // TODO Antoine: A remplir meme principe que HeatingSystem (à discuter par défaut:Individual_production)
  energy_fuel_dhw: 'Electricity' | '...' | null; // TODO Antoine: A remplir meme principe que HeatingSystem (défaut: Electricity)
  dhw_main_production_system: ComEthDHWSystems; // TODO Antoine: A remplir avec les valeurs au dessus (par défaut à voir avec Philippe mais je mettrais HeatPump_balloons_elec)
  dhw_backup_production_system: ComEthDHWSystems; // set null for now
  dhw_main_district_source_identifier: '3001' | null; // on a neighbourhood scale : set null for now
  dhw_main_distant_source_identifier: '4001' | null; // on a neighbourhood scale : set null for now
  dhw_max_load: null; // maximum capacity : set null for now
}

export type ComEthSolarProductionSystems =
  | 'None' // Aucune
  | 'Photovoltaic' // Photovoltaïque
  | 'Thermal Solar' // Solaire thermique
  | 'Photovoltaic and Thermal Solar' // Photovoltaïque et Solaire thermique
  | null;

export interface ComEthJsonFeatureSolarProductionSystems {
  data_status_solar_production_systems: 'specification'; // leave as it is
  solar_production_type: ComEthSolarProductionSystems; // set null for now
  roof_share_PV: null; // set null for now
  roof_share_thermal: null; // set null for now
  type_PV: null; // set null for now
  pose_mode_PV: null; // set null for now
  PV_power_peak: number | null; // (ex: 0) set null for now
}

export type ComEthLightingSystems =
  | 'fluo' // Fluo
  | 'incandescent' // Incandescent
  | 'led' // LED
  | 'halogen' // Halogène
  | 'other' // Autre
  | null;

export interface ComEthJsonFeatureSolarLightingSystems {
  parking_lighting_system: ComEthLightingSystems; // set null for now
  silo_parking_lighting_system: ComEthLightingSystems; // set null for now
}

export interface ComEthJsonFeatureVentilationSystems {
  parking_ventilation_system: 'regulated' | '...' | null; // set null for now
  ventilation_system: 'None' | '...' | null; // set null for now
  // TODO: De notre coté c'est par défaut: Ventilation mécanique double flux avec échangeur
  // A discuter si besoin d'autre choix
}

export interface ComEthJsonFeatureEnergeticSystems {
  heating_systems: ComEthJsonFeatureHeatingSystems;
  cooling_systems: ComEthJsonFeatureCoolingSystems;
  dhw_systems: ComEthJsonFeatureDHWSystems;
  solar_production_systems: ComEthJsonFeatureSolarProductionSystems;
  lighting_systems: ComEthJsonFeatureSolarLightingSystems;
  ventilation_systems: ComEthJsonFeatureVentilationSystems;
}

export const getComEthJsonFeatureEnergeticSystems = (
  caseGranulometry: CaseGranulometry
): ComEthJsonFeatureEnergeticSystems => ({
  heating_systems: {
    production_mode_h: getProductionModeHFromHeatingSystem(
      getCaseAverageHeatingSystem(caseGranulometry)
    ),
    energy_fuel_h: getEnergyFuelHFromHeatingSystem(getCaseAverageHeatingSystem(caseGranulometry)),
    h_main_production_system: adaptHeatingSystemToComEthHeatingSystem(
      getCaseAverageHeatingSystem(caseGranulometry)
    ),
    h_backup_production_system: null,
    h_main_district_source_identifier: null,
    h_main_distant_source_identifier: null,
    heating_max_load: null
  },
  cooling_systems: {
    production_mode_c: null,
    energy_fuel_c: 'Electricity',
    c_main_production_system: null,
    c_backup_production_system: null,
    c_main_district_source_identifier: null,
    c_main_distant_source_identifier: null,
    cooling_max_load: null
  },
  dhw_systems: {
    production_mode_dhw: null,
    energy_fuel_dhw: null,
    dhw_main_production_system: null,
    dhw_backup_production_system: null,
    dhw_main_district_source_identifier: null,
    dhw_main_distant_source_identifier: null,
    dhw_max_load: null
  },
  solar_production_systems: {
    data_status_solar_production_systems: 'specification',
    solar_production_type: null,
    roof_share_PV: null,
    roof_share_thermal: null,
    type_PV: null,
    pose_mode_PV: null,
    PV_power_peak: null
  },
  lighting_systems: {
    parking_lighting_system: null,
    silo_parking_lighting_system: null
  },
  ventilation_systems: {
    parking_ventilation_system: null,
    ventilation_system: null // double_flow | single_flow | none
  }
});
