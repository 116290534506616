import { CaseGranulometry } from '../../../../domain/granulometry/cases/CaseGranulometry';
import { getCaseAverageInsulationPosition } from '../../../../domain/granulometry/cases/queries/get/getCaseAverageInsulationPosition';
import { getCaseAverageRoofingType } from '../../../../domain/granulometry/cases/queries/get/getCaseAverageRoofingType';
import { RoofingName } from '../../../../domain/granulometry/sections/roofingSections/RoofingSection';

export interface ComEthJsonFeatureSuperstructureRoof {
  id_typy_roof: string | null; // ID TyPy (set null for now)
  average_U_value_roof: number | null; // TODO: Antoine à remplir (c'est mieux de votre coté: 0.15?)
  insulation_position_roof: 'NI' | 'ITI' | 'ETI' | 'DTI' | null; // NI = Pas d'isolatio ; ITI = Intérieure (ITI) ; ETI = Extérieure (ITE) ; DTI = Répartie (ITR)
  inertia_level_roof: 'low' | 'mid' | 'high' | null; // 'mid' by default // TODO: Antoine à remplir
  roof_type: 'pitched' | 'terrace' | null;
  roof_constructive_system: 'charpente en bois reconstitué' | '...' | null; // set null for now
  is_roof_renovated: boolean; // renovation : set false for now
}

export const getComEthJsonFeatureSuperstructureRoof = (
  caseGranulometry: CaseGranulometry
): ComEthJsonFeatureSuperstructureRoof => ({
  id_typy_roof: null,
  average_U_value_roof: null,
  insulation_position_roof: getCaseAverageInsulationPosition(caseGranulometry),
  inertia_level_roof: 'mid',
  roof_type:
    getCaseAverageRoofingType(caseGranulometry) === RoofingName.TileRoofing ? 'pitched' : 'terrace',
  roof_constructive_system: null,
  is_roof_renovated: false
});
