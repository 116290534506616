import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { CaseProjection } from '../../projection/cases/CaseProjection';
import { caseCuttedBuiltSurfaceIsSmallerThanTheMinimum } from './caseCuttedBuiltSurfaceIsSmallerThanTheMinimum';
import { caseCuttedBuiltSurfaceIsLargerThanTheMaximum } from './caseCuttedBuiltSurfaceIsLargerThanTheMaximum';

export const caseCuttedBuiltSurfaceIsValid = (
  projectProjection: ProjectProjection,
  caseProjection: CaseProjection
): boolean =>
  !caseCuttedBuiltSurfaceIsSmallerThanTheMinimum(projectProjection, caseProjection) &&
  !caseCuttedBuiltSurfaceIsLargerThanTheMaximum(projectProjection, caseProjection);
