import { Action } from 'redux';

export const PROJECT_CREATED = 'PROJECT_CREATED';

export interface ProjectCreatedAction extends Action {
  type: typeof PROJECT_CREATED;
  payload: {
    projectId: string;
  };
}

export const projectCreated = (projectId: string): ProjectCreatedAction => ({
  type: PROJECT_CREATED,
  payload: { projectId }
});
