import * as R from 'ramda';
import { CountByLodgmentType } from './redistributeCaseWithCustomDistribution';
import { LevelGranulometry } from '../../levels/LevelGranulometry';
import { getLevelDistributionFromGranulometry } from './getLevelDistributionFromGranulometry';
import { getLevelCustomDistribution } from './getLevelCustomDistribution';
import { CaseGranulometry } from '../../cases/CaseGranulometry';

export const getLevelDistributionFromDistribution = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): CountByLodgmentType =>
  R.mergeRight(
    getLevelDistributionFromGranulometry(levelGranulometry),
    getLevelCustomDistribution(caseGranulometry, levelGranulometry.level)
  );
