import { getWidth } from './getWidth';

export const getSectionGroupWidth = (group: string) => {
  let width = 0;
  const elementsInGroup: NodeListOf<HTMLElement> = document.querySelectorAll(
    '.buildingMap .buildings [data-group="' + group + '"]'
  );
  for (let i = 0; i < elementsInGroup.length; i++) {
    const elementWidth = getWidth(elementsInGroup[i], true);
    if (width < elementWidth) width = elementWidth;
  }
  return width;
};
