export const SET_CURRENTLY_DISPLAYED_CASE_PROPERTIES_NAMES =
  'SET_CURRENTLY_DISPLAYED_CASE_PROPERTIES_NAMES';

export const setCurrentlyDisplayedCasePropertiesNames = (
  currentlyDisplayedCasePropertiesNames: string[]
) => ({
  type: SET_CURRENTLY_DISPLAYED_CASE_PROPERTIES_NAMES,
  payload: { currentlyDisplayedCasePropertiesNames }
});

export type SetCurrentlyDisplayedCasePropertiesNamesAction = ReturnType<
  typeof setCurrentlyDisplayedCasePropertiesNames
>;
