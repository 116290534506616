import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../../../../ui/FormGroup';
import { RE2020_THRESHOLD_LIST, RE2020Threshold } from '../../../../../../domain/project/Project';
import { Option, Select } from '../../../../../ui/Select';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectRE2020Threshold } from '../../../../../../store/selectors/project/projectRE2020ThresholdMode';
import { projectRE2020ThresholdChanged } from '../../../../../../store/actions/projectRE2020TypeChanged.action';

// Return the year threshold of RE2020 with the year of the day
export const activatedValueOfRE2020 = (): RE2020Threshold => {
  // Get Currently Year
  const today: any = new Date(Date.now());
  const currentlyYear = today.getFullYear();
  // Initialize as a string variable
  let threshold = '';
  const arrayYearsRE2020 = RE2020_THRESHOLD_LIST;
  const arrayNumber: number[] = [];
  // Sort the array passed by for getting all the years chronologically sorted
  arrayYearsRE2020.forEach((element, index) => (arrayNumber[index] = parseInt(element)));
  arrayNumber.sort(function (a, b) {
    return a - b;
  });

  // Verify if the currently year is lower than the first year on the array or higher than the last year on the array
  if (currentlyYear < arrayNumber[0]) threshold = String(arrayNumber[0]);
  if (currentlyYear > arrayNumber[arrayNumber.length - 1])
    threshold = String(arrayNumber[arrayNumber.length - 1]);
  // If it's not, we search on the array
  if (threshold === '') {
    for (let i = 0; i < arrayNumber.length; i++) {
      // If the array contain the currently year we take it
      // But if the currently year is higher than the reading value and lower than the next reading value, we take the value currently readed
      if (
        arrayNumber[i] === currentlyYear ||
        (currentlyYear > arrayNumber[i] && currentlyYear < arrayNumber[i + 1])
      ) {
        threshold = String(arrayNumber[i+1]);
        break;
      }
    }
  }
  // Return the value as a RE2020Thereshold type
  return threshold as RE2020Threshold;
};

export const ProjectRE2020Threshold = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const projectRE2020ThresholdOptions = RE2020_THRESHOLD_LIST.reduce(
    (acc, threshold) => [...acc, { label: threshold, value: threshold }],
    [] as Option[]
  );

  const updateThreshold = React.useCallback(() => {
    dispatch(projectRE2020ThresholdChanged("2025"));
  }, [dispatch]);

  const handleProjectRE2020ThresholdChange = (RE2020ModeOption: Option) => {
    const RE2020ThresholdMode = RE2020ModeOption.value as RE2020Threshold;
    setSelectedProjectRE2020Mode(RE2020ThresholdMode);
    dispatch(projectRE2020ThresholdChanged(RE2020ThresholdMode), [dispatch]);
  };

  const projectRE2020ThresholdMode = useSelector(selectProjectRE2020Threshold);

  //Patch pour les projets antérieur à la mise à jour RE2020
  let selectedProjectRE2020ThresholdMode;
  let setSelectedProjectRE2020Mode;
  if(projectRE2020ThresholdMode === undefined){
    const valueRE2020 = activatedValueOfRE2020() as RE2020Threshold;
    [selectedProjectRE2020ThresholdMode, setSelectedProjectRE2020Mode] =
      React.useState<RE2020Threshold>(valueRE2020);
    React.useEffect(()=>{
      dispatch(projectRE2020ThresholdChanged(valueRE2020), [dispatch]);
    });
  }else{
    [selectedProjectRE2020ThresholdMode, setSelectedProjectRE2020Mode] =
      React.useState<RE2020Threshold>(projectRE2020ThresholdMode.threshold || activatedValueOfRE2020());
    React.useEffect(()=>{
      dispatch(projectRE2020ThresholdChanged(projectRE2020ThresholdMode.threshold || activatedValueOfRE2020()));
    }, [dispatch]);
  }

  return (
    <div>
      <FormGroup>
        <label htmlFor={`projectRE2020Type`}>{t('projectRE2020Threshold.label')}</label>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
            marginTop: '4px',
            marginBottom: '4px'
          }}>
          <Select
            id={`projectRE2020Type`}
            options={projectRE2020ThresholdOptions}
            handleChange={handleProjectRE2020ThresholdChange}
            value={projectRE2020ThresholdOptions.find(
              (option) => option.value === selectedProjectRE2020ThresholdMode
            )}
            style={{ flexBasis: '200px' }}
          />
        </div>
      </FormGroup>
    </div>
  );
};
