import * as R from 'ramda';
import { BuildingSpecification } from '../specification/buildings/BuildingSpecification';
import { Surface } from '../specification/Surface';
import { NonEmptyArray } from '../../utils/NonEmptyArray';
import { ProjectLocation, ProjectWithLocation } from '../ProjectLocation';
import { ProjectCstbIndicatorsParameters } from './ProjectCstbIndicatorsParameters';
import { CustomPriceList } from '../Account';

export type ProjectSurfaceInputMode = 'cbs' | 'gfsEff'; // cbs = Sdp PC ; gfsEff = SHOB VPP (drawn surface)

export type RE2020Threshold = '2020' | '2025' | '2028' | '2031';
export const RE2020_THRESHOLD_LIST: RE2020Threshold[] = ['2020', '2025', '2028', '2031'];
export const RE2020_THRESHOLD: RE2020Threshold = '2025';

export const CURRENT_MIGRATION_VERSION = 38;
export const DEFAULT_PROJECT_SURFACE_INPUT_MODE: ProjectSurfaceInputMode = 'gfsEff';
export const DEFAULT_PROJECT_CBS = 1000;
export const MINIMUM_PROJECT_CBS = 100;
export const MAXIMUM_PROJECT_CBS = 10000;
export const MINIMUM_PROJECT_GFS = 125;
export const MAXIMUM_PROJECT_GFS = 20000;

export interface UnsavedProject extends ProjectLocation, ProjectCstbIndicatorsParameters {
  name: string;
  description?: string;
  surfaceInputMode: ProjectSurfaceInputMode;
  cuttedBuiltSurface?: Surface;
  projectedCuttedBuiltSurface: Surface;
  buildings: NonEmptyArray<BuildingSpecification>;
  migrationVersion: number;
  revision: string;
  reportRevision: string;
  customPriceListId: CustomPriceList['id'] | 'none';
  RE2020Threshold: RE2020Threshold;
}

export interface ExistingProjectBasicInfo {
  name: string;
  description?: string;
  id: string;
  createdAt: string;
  modifiedAt: string;
}

export interface ExistingProject extends UnsavedProject, ExistingProjectBasicInfo {}

export type Project = UnsavedProject | ExistingProject;

type IsNewProject = (project: Project) => project is UnsavedProject;
export const isNewProject = R.compose(R.isNil, R.propOr(null, 'id')) as IsNewProject;

type IsExistingProject = (project: Project) => project is ExistingProject;
export const isExistingProject = R.complement(isNewProject) as IsExistingProject;

export const toString = (project: Project): string =>
  isNewProject(project) ? 'new project' : `project ${(project as ExistingProject).id}`;

export const projectHasLocation = (project: Project): project is ProjectWithLocation =>
  typeof project.latitude === 'number' && typeof project.longitude === 'number';
