import * as R from 'ramda';
import { NonEmptyArray } from '../../utils/NonEmptyArray';
import {
  BuildingId,
  BuildingSpecification
} from '../specification/buildings/BuildingSpecification';
import { checkThatBuildingExists } from '../checks/buildings/buildingExists';
import { getBuildingIndexGivenBuildingId } from '../core/queries/getBuildingIndexGivenBuildingId';
import { Project } from '../project/Project';
import { overBuildings } from '../project/over/overBuildings';

export const moveABuilding =
  (buildingId: BuildingId, newBuildingIndex: number) =>
  (project: Project): Project => {
    checkThatBuildingExists(buildingId)(project);
    const currentIndex = getBuildingIndexGivenBuildingId(project, buildingId);

    return overBuildings(
      (buildings) =>
        R.move(currentIndex, newBuildingIndex, buildings) as NonEmptyArray<BuildingSpecification>
    )(project); // TODO : Move the current building with the new index at (index + 1) instead just swapping
  };
