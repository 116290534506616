import { getSectionTheoreticalInsideWallsSurface } from '../../../../../sections/queries/walls/theoreticalWalls/insideWalls/getSectionTheoreticalInsideWallsSurface';
import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../../LevelGranulometry';
import { getTopLevelIncludedInSurfaceForSaleSections } from '../../sections/getTopLevelIncludedInSurfaceForSaleSections';
import { IncludedInSurfaceForSaleSection } from '../../../../../sections/SectionIncludedInSurfaceForSale';
import { Surface } from '../../../../../../specification/Surface';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry | LevelGranulometryWithBearingRelativeSections
): Surface =>
  new Surface(
    getTopLevelIncludedInSurfaceForSaleSections(levelGranulometry).reduce(
      (acc, includedInSurfaceForSaleSection: IncludedInSurfaceForSaleSection) =>
        acc +
        getSectionTheoreticalInsideWallsSurface(
          caseGranulometry,
          levelGranulometry,
          includedInSurfaceForSaleSection
        ),
      0
    )
  );
