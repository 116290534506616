import { isSectionWithTheoreticalSurface } from '../../SectionWithTheoreticalSurface';
import {
  LevelGranulometry,
  LevelGranulometryFullFilled,
  LevelGranulometryWithBearingRelativeSections
} from '../../../levels/LevelGranulometry';
import { SectionWithExposureRate } from '../../SectionWithExposureRate';
import { isSectionAHall } from '../../circulationSections/hall';
import { getHallSectionTheoreticalWallsLinear } from './theoreticalWalls/hallSection/getHallSectionTheoreticalWallsLinear';
import { isSectionARamp } from '../../circulationSections/ramp';
import { getRampSectionTheoreticalWallsLinear } from './theoreticalWalls/rampSection/getRampSectionTheoreticalWallsLinear';
import { getTechnicalPremiseSectionTheoreticalWallsLinear } from './theoreticalWalls/technicalPremiseSection/getTechnicalPremiseSectionTheoreticalWallsLinear';
import { isSectionAnTechnicalPremise } from '../../technicalPremiseSections/TechnicalPremiseSection';
import { isSectionACommonPremise } from '../../commonPremiseSections/CommonPremiseSection';
import { getCommonPremiseSectionTheoreticalWallsLinear } from './theoreticalWalls/commonPremiseSection/getCommonPremiseSectionTheoreticalWallsLinear';
import { isSectionAShop } from '../../shopSection/shopSection';
import { getShopSectionTheoreticalWallsLinear } from './theoreticalWalls/shopSection/getShopSectionTheoreticalWallsLinear';
import { isSectionAnOffice } from '../../officeSection/officeSection';
import { getOfficeSectionTheoreticalWallsLinear } from './theoreticalWalls/officeSection/getOfficeSectionTheoreticalWallsLinear';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';

export const MULTIPLIER_COEFFICIENT = 3.0982; // before : 3.7554
export const EXPONENT = -0.435;

export const getSectionWallsLinear = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry:
    | LevelGranulometryWithBearingRelativeSections
    | LevelGranulometryFullFilled
    | LevelGranulometry,
  section: SectionWithExposureRate
): number => {
  if (isSectionAHall(section)) {
    return getHallSectionTheoreticalWallsLinear(caseGranulometry);
  } else if (isSectionAnTechnicalPremise(section)) {
    return getTechnicalPremiseSectionTheoreticalWallsLinear(section);
  } else if (isSectionACommonPremise(section)) {
    return getCommonPremiseSectionTheoreticalWallsLinear(section);
  } else if (isSectionAShop(section)) {
    return getShopSectionTheoreticalWallsLinear(section);
  } else if (isSectionAnOffice(section)) {
    return getOfficeSectionTheoreticalWallsLinear(section);
  } else if (isSectionARamp(section)) {
    return getRampSectionTheoreticalWallsLinear(caseGranulometry);
  } else if (isSectionWithTheoreticalSurface(section)) {
    return section.displayedSurface !== 0
      ? section.displayedSurface *
          MULTIPLIER_COEFFICIENT *
          Math.pow(section.displayedSurface, EXPONENT)
      : 0;
  } else {
    return 0;
  }
};
