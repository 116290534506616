import { createSelector, Selector } from 'reselect';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';
import { selectCurrentCaseProjection } from '../../projection/currentCase/projection.selector';
import { CarpentryOccultationsMotorisationTypeName } from '../../../../domain/specification/cases/CarpentryOccultationsMotorisationType';

export const selectCurrentCaseCarpentryOccultationsMotorisationType: Selector<
  State,
  Maybe<CarpentryOccultationsMotorisationTypeName>
> = createSelector(
  [selectCurrentCaseProjection],
  (caseSpecification) => caseSpecification?.carpentryOccultationsMotorisationType
);
