import { Action } from 'redux';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { State } from '../reducers';
import {
  BuildingMapFocus,
  isFocusBuilding,
  isFocusCase,
  isFocusSection
} from '../../domain/buildingMap/BuildingMapFocus';
import {
  BUILDING_MAP_FOCUSED,
  buildingMapFocused,
  BuildingMapFocusedAction
} from '../actions/buildingMap/buildingMapFocused.action';
import { selectProjectId } from '../selectors/project';
import { EMPTY, merge, of } from 'rxjs';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { selectBuildingMapFocus } from '../selectors/buildingMap/buildingMapFocus.selector';
import { BuildingId } from '../../domain/specification/buildings/BuildingSpecification';
import { selectPath } from '../selectors/navigation';
import { CaseId } from '../../domain/specification/cases/CaseSpecification';
import { SectionId } from '../../domain/granulometry/sections/Section';
import { caseRoute } from '../../routes/toolbox/casesPanel/caseRoute';
import { buildingRoute } from '../../routes/toolbox/buildingsPanel/buildingRoute';
import { deliverablesRoute } from '../../routes/toolbox/deliverablesPanel/deliverablesRoute';
import { projectDetailsRoute } from '../../routes/toolbox/projectPanel/projectDetailsRoute';
import { sectionsEditRoute } from '../../routes/toolbox/sectionsPanel/sectionsEditRoute';
import { sectionsIndividualRoute } from '../../routes/toolbox/sectionsPanel/sectionsIndividualRoute';
import { goToBuilding } from '../actions/navigations/toolbox/buildingsPanel/goToBuilding.action';
import { goToCase } from '../actions/navigations/toolbox/casesPanel/goToCase.action';
import { goToSectionsIndividual } from '../actions/navigations/toolbox/sectionsPanel/goToSectionsIndividual.action';

type FocusActionWithState = [BuildingMapFocusedAction, State];

const changeLocationOnBuildingMapFocusEpic: Epic<Action, Action, State> = (action$, state$) =>
  action$.pipe(
    ofType<Action, BuildingMapFocusedAction>(BUILDING_MAP_FOCUSED),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { focus }
        },
        state
      ]: FocusActionWithState) => {
        const path = selectPath(state);
        const currentBuildingId = buildingRoute.matchesPath(path).params?.buildingId;
        const currentCaseId = caseRoute.matchesPath(path).params?.caseId;
        /* const currentLevelId =
          caseGranulometryEditLevelDetailsRoute.matchesPath(path).params?.levelId; */
        const currentSectionId = sectionsIndividualRoute.matchesPath(path).params?.sectionId;
        const projectId = selectProjectId(state);

        if (projectId) {
          if (isFocusBuilding(focus) && currentBuildingId !== focus.building) {
            return of(goToBuilding(projectId, focus.building));
          }
          if (isFocusCase(focus) && currentCaseId !== focus.case) {
            return of(goToCase(projectId, focus.case));
          }
          /* if (isFocusLevel(focus) && currentLevelId !== focus.levelId) {
            // TODO : To finish - replace levelIndex by levelId (like section nav) BUT IF 3 CASES = WITCH ONE ?
            return of(goToCaseGranulometryEditLevelDetails(projectId, focus.levelId));
          } */
          if (isFocusSection(focus) && currentSectionId !== focus.section) {
            return of(goToSectionsIndividual(projectId, focus.section));
          }
          return EMPTY;
        }
        return EMPTY;
      }
    )
  );

type LocationChangeActionWithState = [LocationChangeAction, State];

const isFocusOnBuilding = (buildingId: BuildingId, focus: BuildingMapFocus) =>
  typeof focus === 'object' && 'building' in focus && focus.building === buildingId;
const isFocusOnCase = (caseId: CaseId, focus: BuildingMapFocus) =>
  typeof focus === 'object' && 'case' in focus && focus.case === caseId;
const isFocusOnSection = (sectionId: SectionId, focus: BuildingMapFocus) =>
  typeof focus === 'object' && 'section' in focus && focus.section === sectionId;

const changeBuildingMapFocusOnLocationChange: Epic<Action, Action, State> = (action$, state$) =>
  action$.pipe(
    ofType<Action, LocationChangeAction>(LOCATION_CHANGE),
    withLatestFrom(state$),
    switchMap(([action, state]: LocationChangeActionWithState) => {
      const path = action.payload.location.pathname;
      const currentFocus = selectBuildingMapFocus(state);

      if (
        projectDetailsRoute.matchesPath(path).matches ||
        sectionsEditRoute.matchesPath(path).matches ||
        deliverablesRoute.matchesPath(path).matches
      ) {
        return of(buildingMapFocused('project'));
      }

      const buildingId = buildingRoute.matchesPath(path).params?.buildingId;
      if (buildingId && !isFocusOnBuilding(buildingId, currentFocus)) {
        return of(buildingMapFocused({ building: buildingId }));
      }

      const caseId = caseRoute.matchesPath(path).params?.caseId;
      if (caseId && !isFocusOnCase(caseId, currentFocus)) {
        return of(buildingMapFocused({ case: caseId }));
      }

      const sectionId = sectionsIndividualRoute.matchesPath(path).params?.sectionId;
      if (sectionId && !isFocusOnSection(sectionId, currentFocus)) {
        return of(buildingMapFocused({ section: sectionId }));
      }

      return EMPTY;
    })
  );

export const syncBuildingMapFocusWithNavigationEpic: Epic<Action, Action, State> = (
  action$,
  state$
) =>
  merge(
    changeLocationOnBuildingMapFocusEpic(action$, state$, {}),
    changeBuildingMapFocusOnLocationChange(action$, state$, {})
  );
