import { matchesPath } from '../../utils/matchesPath';
import { orCreate } from '../../utils/orCreate';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type BuildingsRouteParams = { projectId: string };
export const buildingsRoute: RouteWithMakePath<BuildingsRouteParams> = {
  path: `/project/:projectId/buildings`,
  makePath: ({ projectId }) => `/project/${orCreate(projectId)}/buildings`,
  matchesPath: matchesPath<BuildingsRouteParams>(/\/project\/([^/]+)\/buildings/, {
    projectId: 1
  })
};
