import * as R from 'ramda';
import { createSelector, Selector } from 'reselect';
import { ifDefined } from '../../../utils/ifDefined';
import { Surface } from '../../../domain/specification/Surface';
import { CaseId } from '../../../domain/specification/cases/CaseSpecification';
import { selectLodgmentsSurfaceByBuildingAndCase } from './lodgmentsSurfaceByBuildingAndCase.selector';
import { BuildingId } from '../../../domain/specification/buildings/BuildingSpecification';
import { State } from '../../../store/reducers';
import { Maybe } from '../../../utils/Maybe';

type LodgmentsSurfaceByCase = Maybe<Record<string, Surface>>;

export const selectLodgmentsSurfaceByCase: Selector<State, LodgmentsSurfaceByCase> = createSelector(
  [selectLodgmentsSurfaceByBuildingAndCase],
  ifDefined(
    (byBuildingAndCase: Record<BuildingId, Record<CaseId, Surface>>): Record<CaseId, Surface> =>
      R.pipe<
        [Record<BuildingId, Record<CaseId, Surface>>],
        Record<CaseId, Surface>[],
        Record<CaseId, Surface>
      >(
        R.values,
        R.mergeAll
      )(byBuildingAndCase)
  )
);
