import { Action } from 'redux';

export const CREATE_NEW_PROJECT = 'CREATE_NEW_PROJECT';

export interface CreateNewProjectAction extends Action {
  type: typeof CREATE_NEW_PROJECT;
}

export const createNewProject = (): CreateNewProjectAction => ({
  type: CREATE_NEW_PROJECT
});
