import classNames from 'classnames';
import * as React from 'react';
import {
  APP_COLOR,
  LINEAR_METER_SCALE,
  SQUARE_METER_SCALE
} from '../../../../constants/appConstants';
import { IconFont } from '../../../../components/ui/Icons/IconFont';
import { captureException } from '../../../../infra/sentry';
import { Tooltip } from '../../../../components/ui/Tooltip';
import { BuildingMapSectionMapViewController } from '../sectionMap/BuildingMapSectionMapViewController';
import { LevelGranulometry } from '../../../../domain/granulometry/levels/LevelGranulometry';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';
import { RoomSpecification } from '../../../../domain/specification/rooms/RoomSpecification';
import { useDispatch, useSelector } from 'react-redux';
import { buildingMapFocused } from '../../../../store/actions/buildingMap/buildingMapFocused.action';
import { SectionId } from '../../../../domain/granulometry/sections/Section';
import { CirculationName } from '../../../../domain/granulometry/sections/circulationSections/CirculationSection';
import { roundWith2Decimal } from '../../../../utils/round/roundWith2Decimal';
import { selectHasBeenCalculatedOnce } from '../../../../store/selectors/project/hasBeenCalculatedOnce.selector';

export interface BuildingMapSectionViewInterface {
  ceilingHeight: number;
  direction: 'ltr' | 'rtl';
  id: string;
  openedAbove: boolean;
  openedBelow: boolean;
  openedNext: boolean;
  openedPrev: boolean;
  parent: LevelGranulometry;
  surface: number;
  displayedSurface: number;
  wallThickness: number;
  clickable: boolean;
  color?: string;
  inSectionWrappers: boolean;
  next: boolean;
  prev: boolean;
  type: string;
  firstInGroup: boolean;
  lastInGroup: boolean;
  group: string;
  icon: IconObjectProps;
  rooms: RoomSpecification[];
  name: string;
  title: string;
}

const BuildingMapSectionView: React.FC<BuildingMapSectionViewInterface> = ({
  direction,
  id,
  next,
  prev,
  openedAbove,
  openedBelow,
  openedNext,
  openedPrev,
  parent,
  surface,
  type,
  group,
  icon,
  rooms,
  title,
  ceilingHeight: ceilingHeightFromProps,
  wallThickness: wallThicknessFromProps,
  inSectionWrappers,
  clickable: clickableFromProps,
  color = APP_COLOR,
  firstInGroup,
  lastInGroup,
  name,
  displayedSurface
}) => {
  const calculatedOnce = useSelector(selectHasBeenCalculatedOnce);
  const dispatch = useDispatch();

  // see also : addElementOnMask.ts
  const handleSectionClick = React.useCallback(
    (sectionId: SectionId) => {
      if (calculatedOnce) {
        dispatch(buildingMapFocused({ section: sectionId }));
      }
    },
    [dispatch, calculatedOnce]
  );

  // Error :
  if (displayedSurface === undefined || displayedSurface < 0) {
    color = 'red';
    icon.color = 'red';
    if (displayedSurface === undefined) displayedSurface = 999;
  }

  const scaledSurface = surface * SQUARE_METER_SCALE;
  const ceilingHeight = ceilingHeightFromProps || parent.ceilingHeight;
  const scaledCellingHeight = (ceilingHeight || 0) * LINEAR_METER_SCALE;
  let wallThickness =
    wallThicknessFromProps || wallThicknessFromProps === 0
      ? wallThicknessFromProps
      : parent.displayedWallThickness;
  if (
    name === CirculationName.StairsShaft ||
    name === CirculationName.ElevatorShaft ||
    name === CirculationName.SmokeExtractionDucts ||
    name === CirculationName.Ramp
  ) {
    wallThickness = 0;
  }
  const scaledWallThickness = (wallThickness || 0) * LINEAR_METER_SCALE;
  const clickable = clickableFromProps || clickableFromProps === false ? clickableFromProps : true;

  const parentScaledFloorThickness = (parent.floorThickness || 0) * LINEAR_METER_SCALE;
  const parentPrevScaledFloorThickness = parent.prev
    ? (parent.prev.floorThickness || 0) * LINEAR_METER_SCALE
    : 0; // TODO : Replace prev by getLevelBelow
  let height = scaledCellingHeight;
  if (inSectionWrappers) {
    height = height / 2;
  }
  if (openedAbove) {
    height += parentPrevScaledFloorThickness / 2;
  }
  if (openedBelow) {
    height += parentScaledFloorThickness / 2;
  }
  // margin-top
  let marginTop = 0;
  if (openedAbove) {
    marginTop -= parentPrevScaledFloorThickness / 2;
  }

  // margin-right
  let marginRight = 0;

  if (direction === 'ltr' && next && !openedNext && wallThickness && scaledWallThickness > 0) {
    marginRight = scaledWallThickness;
  }
  // margin-left
  let marginLeft = 0;
  if (direction === 'rtl' && next && !openedNext && wallThickness && scaledWallThickness > 0) {
    marginLeft = scaledWallThickness;
  }
  let marginBottom = 0;
  if (openedBelow) {
    marginBottom -= parentScaledFloorThickness / 2;
  }
  if (type === 'fundations') {
    marginBottom = ((parent.ceilingHeight || 0) - (ceilingHeight || 0)) * LINEAR_METER_SCALE;
  }

  const isOpenedAbove = openedAbove;
  const isOpenedBelow = openedBelow;
  const isClickable = clickable;

  const sectionStyle: React.CSSProperties = {
    borderColor: color,
    width: scaledSurface + 'px',
    height: height + 'px',
    margin: marginTop + 'px ' + marginRight + 'px ' + marginBottom + 'px ' + marginLeft + 'px'
  };

  const sectionBackgroundStyle: React.CSSProperties = {
    backgroundColor: color
  };

  const sectionBordersStyle: React.CSSProperties = {
    borderColor: color
  };

  const sectionClasses = classNames('section', {
    openedAbove: isOpenedAbove,
    openedBelow: isOpenedBelow,
    ltr: direction === 'ltr',
    rtl: direction === 'rtl',
    openedNext: (openedNext && direction === 'ltr') || (openedPrev && direction === 'rtl'),
    openedPrev: (openedPrev && direction === 'ltr') || (openedNext && direction === 'rtl'),
    first:
      (!prev && next) ||
      (parent.content &&
        (type === 'roofing' || type === 'fundations') &&
        !prev &&
        !next &&
        direction === 'rtl'),
    last:
      (prev && !next) ||
      (parent.content &&
        (type === 'roofing' || type === 'fundations') &&
        !prev &&
        !next &&
        direction === 'ltr'),
    clickable: isClickable,
    firstInGroup,
    lastInGroup
  });

  const sectionContentClasses = classNames('sectionContent', {
    large: surface > 10
  });

  const sectionDivAttributes: {
    'data-type'?: string;
    'data-group'?: string;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
  } = {};

  if (type) {
    sectionDivAttributes['data-type'] = type;
  }

  if (group) {
    sectionDivAttributes['data-group'] = group;
  }

  if (group !== undefined && group !== '') {
    sectionDivAttributes.onMouseEnter = () => {
      const groupElements = document.querySelectorAll('.section[data-group="' + group + '"]');
      for (let i = 0; i < groupElements.length; i = i + 1) {
        groupElements[i].classList.add('hovered');
      }
    };
    sectionDivAttributes.onMouseLeave = () => {
      const groupElements = document.querySelectorAll('.section[data-group="' + group + '"]');
      for (let i = 0; i < groupElements.length; i = i + 1) {
        groupElements[i].classList.remove('hovered');
      }
    };
  }

  const idSection = 'section' + id;

  const tooltip = title
    ? title.replace(/\s/g, '\u00A0') +
      '\u00A0: ' +
      roundWith2Decimal(displayedSurface) +
      '\u00A0m\u00B2'
    : 'NO TITLE !';

  if (title === undefined) {
    captureException(new Error('undefined title for section (props)'), {});
  }

  let displayedSurfaceEnabled = true;
  if (inSectionWrappers && surface < 30) {
    displayedSurfaceEnabled = false;
  } else if (!inSectionWrappers && surface < 10) {
    displayedSurfaceEnabled = false;
  }
  if (group && !lastInGroup) {
    displayedSurfaceEnabled = false;
  }

  return (
    <div
      id={idSection}
      className={
        sectionClasses +
        ' tooltipped' +
        (name === CirculationName.SmokeExtractionDuctsInfra ? ' hiddenSection' : '')
      }
      style={sectionStyle}
      onClick={() => handleSectionClick(id)}
      {...sectionDivAttributes}>
      {type === 'lodgment' && (
        <div className="sectionBgName">
          <IconFont id={idSection + '-bgName'} returnString={name} color={color} />
        </div>
      )}
      <div className="sectionBackground" style={sectionBackgroundStyle} />
      <div className="sectionBorders" style={sectionBordersStyle} />
      <div className={sectionContentClasses}>
        {(firstInGroup === true || !group) && (
          <BuildingMapSectionMapViewController icon={icon} rooms={rooms} />
        )}
      </div>
      {displayedSurfaceEnabled && (
        <div className="displayedSurface">
          <IconFont
            id={idSection + '-displayedSurface'}
            returnString={roundWith2Decimal(displayedSurface) + 'M2'}
            color={color}
          />
        </div>
      )}

      <Tooltip color={color}>{tooltip}</Tooltip>
    </div>
  );
};

export default BuildingMapSectionView;
