import { LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { getTopLevelOutsideInsulatedWallsThickness } from '../wallsThickness/getTopLevelOutsideInsulatedWallsThickness';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelTheoreticalOutsideWallsLinear } from './getTopLevelTheoreticalOutsideWallsLinear';

export const getTopLevelTheoreticalOutsideWallsSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled
): number =>
  getTopLevelTheoreticalOutsideWallsLinear(caseGranulometry, levelGranulometry) *
  getTopLevelOutsideInsulatedWallsThickness(caseGranulometry);
