import mapboxgl from 'mapbox-gl';
import { MapSource } from '../../types';
import { Polygon } from 'geojson';
import { ParcelId, ParcelProperties } from '../../../../domain/Parcel';

const removeOneParcel = (
  map: mapboxgl.Map,
  parcelId: ParcelId,
  layers: MapSource<Polygon, ParcelProperties>
): ParcelId | undefined => {
  const newParcels = layers._data.features.filter((feat) => feat.id !== parcelId);

  layers.setData({
    type: 'FeatureCollection',
    features: newParcels
  });

  return parcelId;
};
export default removeOneParcel;
