import { createSelector, Selector } from 'reselect';
import { State } from '../../reducers';
import { Surface } from '../../../domain/specification/Surface';
import { Maybe } from '../../../utils/Maybe';
import { getCaseGrossFloorSurfaceEff } from '../../../domain/granulometry/cases/queries/surfaces/getCaseGrossFloorSurfaceEff';
import { selectAllCasesInGranulometry } from '../granulometry/allCasesGranulometries.selector';
import { selectProjectSurfaceInputMode } from './projectSurfaceInputMode.selector';
import { selectProject } from './project.selector';
import { getProjectGfsEffFromLevelGeometries } from '../../../domain/specification/project/queries/getProjectGfsEffFromLevelGeometries';
import { getProjectGfsEffFromCaseGranulometries } from '../../../domain/specification/project/queries/getProjectGfsEffFromCaseGranulometries';

export const selectProjectGfsEff: Selector<State, Maybe<Surface>> = createSelector(
  [selectProjectSurfaceInputMode, selectAllCasesInGranulometry, selectProject],
  (projectSurfaceInputMode, caseGranulometries, project) =>
    projectSurfaceInputMode === 'cbs'
      ? caseGranulometries
        ? getProjectGfsEffFromCaseGranulometries(caseGranulometries)
        : undefined
      : project
      ? getProjectGfsEffFromLevelGeometries(project)
      : undefined
);
