import * as R from 'ramda';
import { CarParkInfraSection } from './carParkInfra';
import { CarBoxInfraSection } from './carBoxInfra';
import { getSectionName, Section } from '../Section';

export enum ParkingName {
  // CarBox = 'carBox',
  CarBoxInfra = 'carBoxInfra',
  // CarPark = 'carPark',
  CarParkInfra = 'carParkInfra'
}

export type ParkingSection = CarBoxInfraSection | CarParkInfraSection;

type GetParkingName = (parkingSection: ParkingSection) => ParkingName;
export const getParkingName: GetParkingName = R.prop('name');

export const isSectionAParking = (section: Section): section is ParkingSection =>
  (R.values(ParkingName) as string[]).includes(getSectionName(section));

export const filterParkings = (sections: Section[]): ParkingSection[] =>
  sections.filter(isSectionAParking);
