import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '../../../../src/components/ui/Button';
import { ActionModal } from '../../ui/ActionModal';
import { FormGroup } from '../../ui/FormGroup';
import { Input } from '../../ui/input/Input';
import { Form } from '../../ui/Form';
import { asEmail, asPassword, Email, Password } from '../../../domain/User';
import userSlice from '../../../store/reducers/user.slice';
import { getUserState } from '../../../store/reducers';

export const RenewUserSession = () => {
  const { t } = useTranslation();

  const [password, setPassword] = React.useState<Password>(asPassword(''));
  const [email, setUserEmail] = React.useState<Email>(asEmail(''));

  const session = useSelector(getUserState);

  const dispatch = useDispatch();

  const onEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setUserEmail(asEmail(e.currentTarget.value));
  };

  const onPasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setPassword(asPassword(e.currentTarget.value));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (email && password) {
      dispatch(userSlice.actions.authenticate({ email, password, auto: false }));
    }
  };

  const handleLogout = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(userSlice.actions.logout());
  };

  return (
    <ActionModal
      title={t('user.renewUserSession.title')}
      content={
        <>
          {t('user.renewUserSession.content')}
          <Form>
            <FormGroup>
              <Input
                label={t('user.renewUserSession.userInput')}
                name="email"
                type="text"
                value={email}
                handleChange={onEmailChange}
              />
            </FormGroup>
            <FormGroup>
              <Input
                label={t('user.renewUserSession.passwordInput')}
                name="password"
                type="password"
                value={password}
                handleChange={onPasswordChange}
              />
            </FormGroup>
          </Form>
          {session.sessionState === 'DENIED' && (
            <div className="error">{session.auth.errorMessage}</div>
          )}
        </>
      }
      buttons={[
        <Button
          key="0"
          content={t('user.renewUserSession.loginButton')}
          handleClick={handleSubmit as unknown as ButtonProps['handleClick']}
          disabled={session.sessionState === 'RENEWING'}
          withLoader={session.sessionState === 'RENEWING'}
        />,
        <Button
          key="1"
          color="grey"
          content={t('user.renewUserSession.quitButton')}
          handleClick={handleLogout as unknown as ButtonProps['handleClick']}
          disabled={session.sessionState === 'RENEWING'}
        />
      ]}
    />
  );
};
