import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelExternalInsulationThickness } from './getTopLevelExternalnalInsulationThickness';

export const getTopLevelExternalInsulationThicknessDetails = (
  caseGranulometry: CaseGranulometry
): string => {
  const externalInsulationRate = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'externalInsulationRate'
  ) as number;
  const externalInsulationThickness = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'externalInsulationThickness'
  ) as number;
  return (
    '<b>' +
    externalInsulationThickness * 100 +
    ' cm</b> d’épaisseur d’isolation ext. <b>* ' +
    externalInsulationRate * 100 +
    '%</b> de taux d’isolation ext.<br /><b>= ' +
    roundWith2Decimal(getTopLevelExternalInsulationThickness(caseGranulometry) * 100) +
    ' cm</b>'
  );
};
