import mapboxgl, { ImageSource, RasterLayer } from 'mapbox-gl';
import { Position } from 'geojson';
import { DrawHelperMetaData } from '../drawHelper/drawHelper.model';
import {
  getParamsAddRasterLayer,
  getParamsZIndexMovingLayer
} from '../drawHelper/drawHelper.utils';
import { normalizeSourceCoordinates } from '../utils/geometry/coordinates';

export class MapRepository {
  map: mapboxgl.Map;

  constructor(map: mapboxgl.Map) {
    this.map = map;
  }

  findLayers(filter?: (layer: mapboxgl.AnyLayer) => boolean): mapboxgl.AnyLayer[] {
    const layers = this.map.getStyle().layers;
    if (!filter) {
      return layers;
    }
    return layers.filter(filter);
  }

  isHide(layerId: string): boolean {
    return this.map.getLayoutProperty(layerId, 'visibility') === 'none';
  }

  toggleHideLayoutProperty(layerId: string) {
    if (this.isHide(layerId)) return this.revealLayout(layerId);
    return this.hideLayout(layerId);
  }

  hideLayout(layerId: string) {
    if (!this.isHide(layerId)) return this.map.setLayoutProperty(layerId, 'visibility', 'none');
  }

  revealLayout(layerId: string) {
    this.map.setLayoutProperty(layerId, 'visibility', 'visible');
  }

  addSourceImage(loadImageOptions: LoadImageOptions, coordinates: Position[]) {
    this.map.addSource(loadImageOptions.sourceName, {
      type: 'image',
      url: loadImageOptions.imagePath,
      coordinates
    });
  }

  zIndexLayer(params: { id: string; beforeId?: string }[]) {
    params.forEach((param) => this.map.moveLayer(param.id, param.beforeId));
  }

  addRasterLayer(loadImageOptions: Omit<LoadImageOptions, 'imagePath'>) {
    this.map.addLayer(getParamsAddRasterLayer(loadImageOptions));
    this.zIndexLayer(getParamsZIndexMovingLayer(loadImageOptions.layerName));
  }

  addRasterLayerAndSource(
    loadImageOptions: LoadImageOptions,
    coordinates: Position[]
  ): RasterLayer {
    this.addSourceImage(loadImageOptions, coordinates);
    this.addRasterLayer(loadImageOptions);
    return this.map.getLayer(loadImageOptions.layerName) as RasterLayer;
  }

  getPosition(): Position {
    return [this.map.getCenter()?.lng, this.map.getCenter()?.lat];
  }

  async loadImage(imagePath: string): Promise<HTMLImageElement | ImageBitmap> {
    return new Promise((resolve) =>
      this.map.loadImage(imagePath, (error, image) => {
        if (error) {
          throw error;
        }
        if (!image) {
          throw new Error('No image found');
        }
        resolve(image);
      })
    );
  }

  removeLayer(id: string) {
    this.map.removeLayer(id);
  }

  removeSource(id: string) {
    this.map.removeSource(id);
  }

  findOneLayer(id: string) {
    return this.map.getLayer(id);
  }

  getSource(id: string) {
    return this.map.getSource(id);
  }

  moveImageSource(id: string, coordinates: Position[]) {
    const source = this.map.getSource(id) as ImageSource;
    const normalizedCoordinates = normalizeSourceCoordinates(coordinates);
    return source.setCoordinates(normalizedCoordinates);
  }

  addMarker(coordinates: Position, options: mapboxgl.MarkerOptions) {
    new mapboxgl.Marker(options).setLngLat(coordinates as [number, number]).addTo(this.map);
  }

  addPolygon(coordinates: Position[], sourceId: string) {
    this.map.addSource(sourceId, {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'Polygon',
          coordinates: [[...coordinates, coordinates[0]]]
        }
      }
    });
    this.map.addLayer({
      id: 'layer_' + sourceId,
      type: 'fill',
      source: sourceId, // reference the data source
      layout: {},
      paint: {
        'fill-color': '#0080ff', // blue color fill
        'fill-opacity': 0.5
      }
    });
  }
}

export interface LoadImageOptions {
  layerName: string;
  imagePath: string;
  sourceName: string;
  metadata?: DrawHelperMetaData;
}
