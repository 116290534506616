import { Feature, Polygon } from 'geojson';
import { Position } from '@turf/helpers/dist/js/lib/geojson';
import { getCenter } from './getCenter';
import * as turf from '@turf/turf';

const getCloserFeatureFromPoint = (
  features: Feature<Polygon>[],
  point: Position
): Feature<Polygon> => {
  const featuresWithCenter = features.map((feature) => {
    const center = getCenter(feature.geometry.coordinates[0]);
    const pointDistanceCenter = turf.distance(turf.point(center), turf.point(point), {
      units: 'meters'
    });
    return Object.assign(feature, {
      properties: {
        ...feature.properties,
        pointDistanceCenter
      }
    });
  });
  featuresWithCenter.sort(
    (a, b) => a.properties.pointDistanceCenter - b.properties.pointDistanceCenter
  );
  return featuresWithCenter[0];
};
export default getCloserFeatureFromPoint;
