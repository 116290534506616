import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { ReportEntry } from '../../../../../../domain/report/ReportEntry';
import { reportRefLot } from '../reportEntries/reportRefLot';
import { reportRefSsLot1 } from '../reportEntries/reportRefSsLot1';
import { reportRefSsLot2 } from '../reportEntries/reportRefSsLot2';
import { reportRefSsLot3 } from '../reportEntries/reportRefSsLot3';

// TODO : @Jalil : type please
const groupByLevelField = (columnPresenter: (entry: ReportEntry) => string) =>
  R.pipe<
    [ReportEntry[]],
    Record<string, ReportEntry[]>,
    Record<string, { list: ReportEntry[]; idx: number }>,
    { list: ReportEntry[]; idx: number }[],
    Record<number, { list: ReportEntry[]; idx: number }>,
    Record<number, ReportEntry>
  >(
    R.groupBy(columnPresenter),
    RA.mapIndexed((list, idx) => ({ list, idx })),
    R.values,
    R.indexBy(R.prop('idx')),
    R.pluck('list')
  );
const groupByLevel1 = groupByLevelField(reportRefLot);
const groupByLevel2 = groupByLevelField(reportRefSsLot1);
const groupByLevel3 = groupByLevelField(reportRefSsLot2);
const groupByLevel4 = groupByLevelField(reportRefSsLot3);

const setAllFalse = R.pipe(
  R.length,
  R.times(R.identity),
  R.map((idx) => ({ [idx]: false })),
  R.mergeAll
);

const andInside = (fn, next) => R.map(R.pipe(fn, next || R.identity));

export const getDefaultExpandedRows = (report: ReportEntry[]): {} => {
  return R.pipe(
    groupByLevel1,
    andInside(
      groupByLevel2,
      andInside(groupByLevel3, andInside(groupByLevel4, andInside(setAllFalse)))
    )
  )(report);
};
