import { LevelGranulometry } from '../../LevelGranulometry';
import { isSectionAGroupedSection } from '../../../sections/GroupedSection';
import { getLevelFullFilledContent } from '../content/getLevelFullFilledContent';
import { Surface } from '../../../../specification/Surface';

export const getLevelNotGroupedSectionsSurface = (levelGranulometry: LevelGranulometry): Surface =>
  new Surface(
    getLevelFullFilledContent(levelGranulometry).reduce(
      (surface, section) =>
        !isSectionAGroupedSection(section) ? surface + section.displayedSurface : surface,
      0
    )
  );
