import { BasementLevelSpecification } from '../../../../specification/levels/BasementLevelSpecification';

export const getBasementLevelParkingRate = (
  levelIndex: number,
  projectedBasementLevels: BasementLevelSpecification[]
): number => {
  if (projectedBasementLevels.length < 2) {
    return 100;
  } else {
    let remainingParkingRate = 100;
    let unforcedSurfacesCount = 0;
    let forcedValue = 0;
    projectedBasementLevels.forEach((basementLevelsSpecification, index) => {
      if (basementLevelsSpecification.parkingRate) {
        if (index + 1 !== levelIndex * -1) {
          remainingParkingRate -= basementLevelsSpecification.parkingRate;
        } else {
          forcedValue = basementLevelsSpecification.parkingRate;
        }
      } else {
        unforcedSurfacesCount++;
      }
    });
    return forcedValue || remainingParkingRate / unforcedSurfacesCount;
  }
};
