import { LevelGranulometryFullFilled } from '../../../LevelGranulometry';
import { SQUARE_METER_SCALE } from '../../../../../../constants/appConstants';
import { getLevelDisplayedWallThickness } from '../../getLevelDisplayedWallThickness';
import { getTopLevelAlreadyCalculatedSurfaceForSale } from '../surfaces/surfaceForSale/getTopLevelAlreadyCalculatedSurfaceForSale';
import { getTopLevelIncludedInSurfaceForSaleSectionsCount } from '../counts/getTopLevelIncludedInSurfaceForSaleSectionsCount';

export const getTopLevelSfsRulerWidth = (
  levelGranulometry: LevelGranulometryFullFilled
): number => {
  const includedInSurfaceForSaleSectionsCount =
    getTopLevelIncludedInSurfaceForSaleSectionsCount(levelGranulometry);
  if (includedInSurfaceForSaleSectionsCount !== 0) {
    const displayedWallThickness = getLevelDisplayedWallThickness(levelGranulometry);
    const levelSurfaceForSale = getTopLevelAlreadyCalculatedSurfaceForSale(levelGranulometry).value;
    return (
      (levelSurfaceForSale +
        (displayedWallThickness > 0
          ? displayedWallThickness * 10 * (includedInSurfaceForSaleSectionsCount - 1)
          : 0)) *
      SQUARE_METER_SCALE
    );
  }
  return 100; // minimum to display
};
