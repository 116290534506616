import { Action } from 'redux';

export const PROJECT_FETCH_FAILED = 'PROJECT_FETCH_FAILED';

export interface ProjectFetchFailedAction extends Action {
  type: typeof PROJECT_FETCH_FAILED;
}

export const projectFetchFailed = (): ProjectFetchFailedAction => ({
  type: PROJECT_FETCH_FAILED
});
