import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/ui/Button';

interface NewBackButtonProps {
  onClick: () => void;
}

const NewBackButton = ({ onClick }: NewBackButtonProps) => {
  const { t } = useTranslation();
  return <Button color="grey" content={t('Back')} handleClick={onClick} />;
};

export default NewBackButton;
