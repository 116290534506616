import { TopLevelSpecification } from '../../../../levels/TopLevelSpecification';
import * as R from 'ramda';
import { LevelSpecification } from '../../../../levels/LevelSpecification';
import { CaseSpecification } from '../../../CaseSpecification';
import { isTopLevel } from '../../../../levels/queries/is/isTopLevel';
import { sortLevels } from '../../../../levels/queries/sortLevels';

export const setCaseTopLevelsSpecifications =
  (levels: TopLevelSpecification[]) =>
  (caseSpecification: CaseSpecification): CaseSpecification =>
    R.over(
      R.lensProp('levels'),
      R.pipe<
        [LevelSpecification[]],
        LevelSpecification[],
        LevelSpecification[],
        LevelSpecification[]
      >(R.reject(isTopLevel), R.concat(levels), sortLevels),
      caseSpecification
    );
