import { matchesPath } from '../../utils/matchesPath';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type DeliverablesExportRouteParams = { projectId: string };
export const deliverablesExportRoute: RouteWithMakePath<DeliverablesExportRouteParams> = {
  path: `/project/:projectId/deliverables/export`,
  makePath: ({ projectId }) => `/project/${projectId}/deliverables/export`,
  matchesPath: matchesPath<DeliverablesExportRouteParams>(
    /\/project\/([^/]+)\/deliverables\/export/,
    {
      projectId: 1
    }
  )
};
