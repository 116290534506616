import { ProjectSurfaceInputMode } from '../../domain/project/Project';

export const PROJECT_SURFACE_INPUT_MODE_CHANGED = 'project/surface_input_mode_changed';

export const projectSurfaceInputModeChanged = (surfaceInputMode: ProjectSurfaceInputMode) => ({
  type: PROJECT_SURFACE_INPUT_MODE_CHANGED,
  payload: { surfaceInputMode }
});

export type ProjectSurfaceInputModeChangedAction = ReturnType<
  typeof projectSurfaceInputModeChanged
>;
