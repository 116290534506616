import { createSelector, Selector } from 'reselect';
import { State } from '../../../store/reducers';
import { selectGranulometry } from './current.selector';
import { getLevelFullFilledContent } from '../../../domain/granulometry/levels/queries/content/getLevelFullFilledContent';
import { getCaseLevels } from '../../../domain/granulometry/cases/queries/levels/getCaseLevels';

export const selectGranulometryObjectCount: Selector<State, number> = createSelector(
  [selectGranulometry],
  (granulometry) =>
    granulometry
      ? granulometry.reduce(
          (buildingCount, building) =>
            buildingCount +
            1 +
            building.cases.reduce(
              (casesCount, caseGranulometry) =>
                casesCount +
                1 +
                getCaseLevels(caseGranulometry).reduce(
                  (levelCount, levelGranulometry) =>
                    levelCount + 1 + getLevelFullFilledContent(levelGranulometry).length,
                  0
                ),
              0
            ),
          0
        )
      : 0
);
