import { getSectionWallsLinear } from '../getSectionWallsLinear';
import { SectionWithExposureRate } from '../../../SectionWithExposureRate';
import { LevelGranulometry } from '../../../../levels/LevelGranulometry';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';
import { getSectionRealOutsideWallsLinear } from './getSectionRealOutsideWallsLinear';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { getSectionRealInsideWallsLinear } from './getSectionRealInsideWallsLinear';

export const getSectionRealInsideWallsLinearDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry,
  section: SectionWithExposureRate
): string => {
  let details: string[] = [];
  details = [
    ...details,
    '<b>' +
      roundWith2Decimal(getSectionWallsLinear(caseGranulometry, levelGranulometry, section)) +
      ' m</b> de linéaire de voiles exposés et non-exposés'
  ];
  details = [
    ...details,
    '<b>- ' +
      roundWith2Decimal(
        getSectionRealOutsideWallsLinear(caseGranulometry, levelGranulometry, section)
      ) +
      ' m</b> de linéaire réel de voiles exposés '
  ];
  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(
        getSectionRealInsideWallsLinear(caseGranulometry, levelGranulometry, section)
      ) +
      ' mL</b>'
  ];
  return details.join('<br />');
};
