import * as R from 'ramda';
import { createSelector, Selector } from 'reselect';
import { TopLevelSurfaces } from '../../../../domain/granulometry/cases/queries/surfaces/getCaseTopLevelsSurfaces';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';
import { selectCurrentCaseTopLevelsSurfaces } from './topLevelsSurfaces.selector';
import { Surface } from '../../../../domain/specification/Surface';

export const selectCurrentCaseTopLevelsTotalSurfaces: Selector<
  State,
  Maybe<TopLevelSurfaces>
> = createSelector([selectCurrentCaseTopLevelsSurfaces], (topLevelsSurfaces) =>
  topLevelsSurfaces
    ? R.reduce(
        (acc, surfaces: TopLevelSurfaces) => ({
          realBuiltSurface: Surface.sum([acc.realBuiltSurface, surfaces.realBuiltSurface]),
          surfaceForSale: Surface.sum([acc.surfaceForSale, surfaces.surfaceForSale]),
          grossFloorSurface: Surface.sum([acc.grossFloorSurface, surfaces.grossFloorSurface]),
          grossFloorSurfaceEff: Surface.sum([
            acc.grossFloorSurfaceEff,
            new Surface(Math.max(0, surfaces.grossFloorSurfaceEff.value))
          ])
        }),
        {
          realBuiltSurface: new Surface(0),
          surfaceForSale: new Surface(0),
          grossFloorSurface: new Surface(0),
          grossFloorSurfaceEff: new Surface(0)
        } as TopLevelSurfaces
      )(topLevelsSurfaces)
    : undefined
);
