import classNames from 'classnames';
import * as React from 'react';
import {
  DEFAULT_CEILING_HEIGHT,
  DEFAULT_FLOOR_THICKNESS,
  DEFAULT_LEVEL_WALL_THICKNESS,
  ERROR_COLOR,
  LINEAR_METER_SCALE,
  SQUARE_METER_SCALE
} from 'constants/appConstants';
import { BuildingMapFundationsView } from './BuildingMapFundationsView';
import BuildingMapLevelContentView from './BuildingMapLevelContentView';
import { BuildingMapRoofingView } from './BuildingMapRoofingView';
import { isThisLevelSectionWrapped } from '../../../../utils/buildingMap/isThisLevelSectionWrapped';
import { manageLevelSectionWrapper } from '../../../../utils/buildingMap/manageLevelSectionWrapper';
import { FundationsSection } from '../../../../domain/granulometry/sections/fundationsSections/FundationsSection';
import { SectionInLevelGranulometryFullFilledContent } from '../../../../domain/granulometry/levels/LevelGranulometry';

export interface BuildingMapLevelInterface {
  buildingId: string;
  caseId: string;
  id: string;
  content: SectionInLevelGranulometryFullFilledContent[];
  fundations: FundationsSection[];
  level: any;
  outsideLevel: any;
  roofing: any;
  surface: number;
  direction: string;
  ceilingHeight: number;
  floorThickness: number;
  wallThickness: number;
  prev: any;
  next: any;
  parent: any;
  displayedWallThickness?: number;
}

export const BuildingMapLevelView = React.forwardRef<HTMLDivElement, BuildingMapLevelInterface>(
  (props, ref) => {
    const { id, content, fundations, level, outsideLevel, roofing, surface, next, prev, parent } =
      props;

    const direction = props.direction ? props.direction : props.parent.direction;

    const ceilingHeight = props.ceilingHeight ? props.ceilingHeight : DEFAULT_CEILING_HEIGHT;
    const floorThickness = props.floorThickness ? props.floorThickness : DEFAULT_FLOOR_THICKNESS;
    const wallThickness =
      props.wallThickness || props.wallThickness === 0
        ? props.wallThickness
        : DEFAULT_LEVEL_WALL_THICKNESS;
    const scaledSurface = surface * SQUARE_METER_SCALE;
    const scaledCellingHeight = ceilingHeight * LINEAR_METER_SCALE;
    const scaledFloorThickness = floorThickness * LINEAR_METER_SCALE;
    const scaledWallThickness = wallThickness * LINEAR_METER_SCALE;

    const height = scaledCellingHeight;
    const prevScaledFloorThickness = props.prev
      ? props.prev.floorThickness * LINEAR_METER_SCALE
      : 0;

    let paddingTop = prevScaledFloorThickness / 2;
    let paddingBottom = props.next ? scaledFloorThickness / 2 : 0;

    const outsideWallThickness = props.parent.wallThickness
      ? props.parent.wallThickness * LINEAR_METER_SCALE
      : scaledWallThickness;
    const paddingRight = outsideWallThickness;
    const paddingLeft = outsideWallThickness;

    if (level === -1) {
      paddingTop = prevScaledFloorThickness;
    }
    if (level === 0) {
      paddingBottom = 0;
    }

    const endlineStyle: any = {};
    const exceededStyle: any = {};
    let exceededSurface = 0;
    const emptyStyle: any = {};
    let emptyWidth = 0;

    const levelPartialObj: any = { level, content, prev }; // !!! DIRTY

    if (content && content.length !== 0) {
      manageLevelSectionWrapper(levelPartialObj, parent);

      let contentTotalSurface = 0;
      let contentTotalNegativeSurface = 0;
      let contentTotalWallThickness = 0;
      content.forEach((contentElem: any, index: any) => {
        content[index].parent = props;
        if (content[index - 1]) {
          contentElem.prev = content[index - 1];
        }
        if (content[index + 1]) {
          contentElem.next = content[index + 1];
        }
        contentTotalSurface += Math.max(0, parseFloat(contentElem.surface));
        if (contentElem.surface < 0) contentTotalNegativeSurface += contentElem.surface * -1;
        contentTotalWallThickness += Math.max(
          0,
          contentElem.wallThickness || contentElem.wallThickness === 0
            ? contentElem.wallThickness
            : contentElem.next && !contentElem.openedNext
            ? wallThickness
            : 0
        );
      });
      // contentTotalSurface -= 2; // border adjustment (?)

      // Level end line positioning :
      let endLinePosition = scaledSurface;
      if (
        isThisLevelSectionWrapped(levelPartialObj) &&
        levelPartialObj.sectionWrapper.theoricalWidth
      ) {
        // Calculation of the divided (by 2) surface rate on the total level surface
        const dividedSurfaceRate =
          (levelPartialObj.sectionWrapper.theoricalWidth * 2) / scaledSurface;
        // Calculation of the new end line position applying the rate
        endLinePosition = endLinePosition - (endLinePosition * dividedSurfaceRate) / 2;
      }
      endLinePosition -= 2; // border adjustment
      if (surface) {
        endlineStyle[direction === 'rtl' ? 'right' : 'left'] = endLinePosition + 'px';
      }

      // Level exceeding or empty part management :
      const totalLevelWidth =
        contentTotalSurface * SQUARE_METER_SCALE +
        contentTotalWallThickness * LINEAR_METER_SCALE +
        outsideWallThickness * 2;
      if (totalLevelWidth > scaledSurface) {
        endlineStyle.backgroundColor = ERROR_COLOR;
        const excededWidth = totalLevelWidth - scaledSurface;
        exceededStyle[direction === 'rtl' ? 'right' : 'left'] = scaledSurface - 2 + 'px';
        exceededStyle.width = excededWidth + 'px';
        exceededSurface = surface - contentTotalSurface + contentTotalNegativeSurface;
      } else if (!props.displayedWallThickness && totalLevelWidth < scaledSurface) {
        emptyWidth = scaledSurface - totalLevelWidth - scaledWallThickness;
        emptyStyle.width = emptyWidth + 'px';
        emptyStyle.height = scaledCellingHeight + 'px';
        if (direction === 'rtl') {
          emptyStyle.marginRight = scaledWallThickness + 'px';
        } else {
          emptyStyle.marginLeft = scaledWallThickness + 'px';
        }
      }
    }

    // Roofing
    if (roofing && roofing.length !== 0) {
      roofing.forEach((roofingElem: any, index) => {
        roofingElem.parent = props;
        if (roofing[index - 1]) {
          roofingElem.prev = roofing[index - 1];
        }
        if (roofing[index + 1]) {
          roofingElem.next = roofing[index + 1];
        }
      });
    }

    // Fundations
    if (fundations && fundations.length !== 0) {
      fundations.forEach((fundationElem: any, index: number) => {
        fundationElem.parent = props;
        if (fundations[index - 1]) {
          fundationElem.prev = fundations[index - 1];
        }
        if (fundations[index + 1]) {
          fundationElem.next = fundations[index + 1];
        }
      });
    }

    // Level classes
    const levelClasses = classNames('level', {
      ltr: direction === 'ltr',
      rtl: direction === 'rtl',
      first: !prev,
      last: !next
    });

    // Styles
    const levelStyle = {
      height: height + 'px',
      padding: paddingTop + 'px 0 ' + paddingBottom + 'px 0'
    };
    const levelContentStyle = {
      padding: '0 ' + paddingRight + 'px 0 ' + paddingLeft + 'px'
    };
    const ceilingLineStyle: any = {};
    if (level === -1) {
      ceilingLineStyle.marginTop = prevScaledFloorThickness / 2 + 'px';
    }

    // Render
    return (
      <div
        id={'level' + id}
        className={levelClasses}
        data-level={level}
        style={levelStyle}
        ref={ref}>
        {prev && <div className="ceilingLine" style={ceilingLineStyle} />}
        {parent.direction === 'rtl' ? (
          <>
            {fundations && fundations.length !== 0 && (
              <BuildingMapFundationsView
                fundations={fundations}
                direction={direction}
                sectionWrapper={levelPartialObj.sectionWrapper}
                {...props}
              />
            )}
            {roofing && roofing.length !== 0 && (
              <BuildingMapRoofingView roofing={roofing} direction={direction} {...props} />
            )}
            <BuildingMapLevelContentView
              content={content}
              direction={direction}
              outsideLevel={outsideLevel}
              levelContentStyle={levelContentStyle}
              endlineStyle={endlineStyle}
              exceededStyle={exceededStyle}
              exceededSurface={exceededSurface}
              emptyStyle={emptyStyle}
              emptyWidth={emptyWidth}
              outsideRulerTop={paddingTop * -1}
              {...props}
            />
          </>
        ) : (
          <>
            <BuildingMapLevelContentView
              content={content}
              direction={direction}
              outsideLevel={outsideLevel}
              levelContentStyle={levelContentStyle}
              endlineStyle={endlineStyle}
              exceededStyle={exceededStyle}
              exceededSurface={exceededSurface}
              emptyStyle={emptyStyle}
              emptyWidth={emptyWidth}
              outsideRulerTop={paddingTop * -1}
              {...props}
            />
            {roofing && roofing.length !== 0 && (
              <BuildingMapRoofingView roofing={roofing} direction={direction} {...props} />
            )}
            {fundations && fundations.length !== 0 && (
              <BuildingMapFundationsView
                fundations={fundations}
                direction={direction}
                sectionWrapper={levelPartialObj.sectionWrapper}
                {...props}
              />
            )}
          </>
        )}
      </div>
    );
  }
);
