import { Route } from '../../Route';
import { matchesPath } from '../../utils/matchesPath';

export type CaseGranulometryEditRouteParams = {
  projectId: string;
  caseId: string;
};
export const caseGranulometryEditRoute: Route<CaseGranulometryEditRouteParams> = {
  path: `/project/:projectId/cases/:caseId/granulometry/edit`,
  matchesPath: matchesPath<CaseGranulometryEditRouteParams>(
    /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit/,
    {
      projectId: 1,
      caseId: 2
    }
  )
};
