import { matchesPath } from '../../utils/matchesPath';
import { orCreate } from '../../utils/orCreate';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type BuildingRouteParams = { projectId: string; buildingId: string };
export const buildingRoute: RouteWithMakePath<BuildingRouteParams> = {
  path: `/project/:projectId/buildings/:buildingId`,
  makePath: ({ projectId, buildingId }) =>
    `/project/${orCreate(projectId)}/buildings/${buildingId}`,
  matchesPath: matchesPath<BuildingRouteParams>(/\/project\/([^/]+)\/buildings\/(\w+)/, {
    projectId: 1,
    buildingId: 2
  })
};
