import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../LevelGranulometry';
import { getTopLevelInsideWallsThickness } from './wallsThickness/getTopLevelInsideWallsThickness';
import { getTopLevelRealInsideWallsLinear } from './realWalls/getTopLevelRealInsideWallsLinear';
import { getTopLevelTheoreticalInsideWallsLinear } from './theoreticalWalls/getTopLevelTheoreticalInsideWallsLinear';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';

export const getTopLevelRealTheoreticalInsideWallsSurfaceDiff = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled | LevelGranulometry
): number =>
  (getTopLevelTheoreticalInsideWallsLinear(caseGranulometry, levelGranulometry) -
    getTopLevelRealInsideWallsLinear(caseGranulometry, levelGranulometry)) *
  (getTopLevelInsideWallsThickness(caseGranulometry) / 2);
