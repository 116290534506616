import { ReportEntry } from '../../domain/report/ReportEntry';

export const sumCo2QuantitiesInReportBy =
  (keys: (keyof ReportEntry)[]) =>
  (reportEntries: ReportEntry[]): ReportEntry[] =>
    reportEntries.reduce((acc, reportEntry) => {
      const index = acc.findIndex((x) => keys.every((k) => x[k] === reportEntry[k]));
      if (index === -1) {
        return [...acc, reportEntry];
      } else {
        const updatedCo2Quantity = (acc[index].co2Quantity || 0) + (reportEntry.co2Quantity || 0);
        return [
          ...acc.slice(0, index),
          { ...acc[index], co2Quantity: updatedCo2Quantity },
          ...acc.slice(index + 1)
        ];
      }
    }, [] as ReportEntry[]);
