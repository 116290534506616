import { caseGranulometryRoute } from './caseGranulometryRoute';
import { matchesPath } from '../../utils/matchesPath';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type CaseRouteParams = { projectId: string; caseId: string };
export const caseRoute: RouteWithMakePath<CaseRouteParams> = {
  path: `/project/:projectId/cases/:caseId`,
  makePath: caseGranulometryRoute.makePath,
  matchesPath: matchesPath<CaseRouteParams>(/\/project\/([^/]+)\/cases\/([\w]+)/, {
    projectId: 1,
    caseId: 2
  })
};
