import * as React from 'react';

export interface MapPositionContextInterface {
  focusOn?: (elem: string) => void;
}

const initialMapPosition = {
  focusOn: undefined
};

const ctxt = React.createContext<MapPositionContextInterface>(initialMapPosition);

export const MapPositionContextProvider = ctxt.Provider;

export const MapPositionContextConsumer = ctxt.Consumer;
