import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../ui/SpecialLabel';
import { FormGroup } from '../../../ui/FormGroup';
import { Card } from '../../../ui/Card';
import { Maybe } from '../../../../utils/Maybe';
import { BuildingSpecification } from '../../../../domain/specification/buildings/BuildingSpecification';
import { Input } from '../../../ui/input/Input';
import { getBuildingDefaultTitle } from '../../../../domain/specification/buildings/queries/getBuildingDefaultTitle';
import { getBuildingTitle } from '../../../../domain/specification/buildings/queries/getBuildingTitle';
import { useDispatch } from 'react-redux';
import { setBuildingTitle } from '../../../../store/actions/setBuildingTitle.action';

interface PositioningBuildingCardProps {
  buildingSpecification: Maybe<BuildingSpecification>;
  buildingIndex: number;
}

const BuildingTitleCard = ({
  buildingSpecification,
  buildingIndex
}: PositioningBuildingCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleBuildingTitleChange = React.useCallback(
    (newBuildingTitle: string | null) => {
      if (
        buildingSpecification &&
        (typeof newBuildingTitle === 'string' || newBuildingTitle === null)
      ) {
        dispatch(setBuildingTitle(buildingSpecification.id, newBuildingTitle || undefined));
      }
    },
    [dispatch, buildingSpecification]
  );

  if (!buildingSpecification || buildingIndex === undefined) return null;

  return (
    <Card>
      <SpecialLabel label={t('Name')} />
      <FormGroup horizontal={true} wrap={true}>
        <Input
          name="buildingTitle"
          label={t('buildingTitle.label')}
          info={t('buildingTitle.infos')}
          style={{ width: '50%', flex: '0 0 50%' }}
          type="text"
          value={getBuildingTitle(buildingSpecification)}
          handleChange={(event) => handleBuildingTitleChange(event.target.value)}
          placeholder={getBuildingDefaultTitle(buildingIndex)}
        />
      </FormGroup>
    </Card>
  );
};

export default BuildingTitleCard;
