import * as React from 'react';
import { CaseId } from '../../../../../../domain/specification/cases/CaseSpecification';
import { Option } from '../../../../../ui/Select';
import { SpecialLabel } from '../../../../../ui/SpecialLabel';
import { CarpentryDayTimeOccultationsTypeForm } from './carpentryOccultations/CarpentryDayTimeOccultationsTypeForm';
import { CarpentryNightTimeOccultationsTypeForm } from './carpentryOccultations/CarpentryNightTimeOccultationsTypeForm';
import {
  CarpentryOccultationsMotorisationTypeForm,
  refreshCarpentryOccultationsMotorisationTypeOptionsState
} from './carpentryOccultations/CarpentryOccultationsMotorisationTypeForm';
import { CarpentryOccultationsTypeName } from '../../../../../../domain/specification/cases/CarpentryOccultationsType';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import {
  CarpentryOccultationsMotorisationType,
  isCarpentryOccultationsMotorisationTypeSetForDay,
  isCarpentryOccultationsMotorisationTypeSetForNight
} from '../../../../../../domain/specification/cases/CarpentryOccultationsMotorisationType';
import { useSelector } from 'react-redux';
import { selectCurrentCaseCarpentryOccultationsMotorisationType } from '../../../../../../store/selectors/specification/currentCase/carpentryOccultationsMotorisationType.selector';

interface CarpentryOccultationsProps {
  caseId: CaseId;
  isSelectedCarpentryTypeUsePVC: boolean;
  selectedCarpentryDayTimeOccultationTypeOption?: Option;
  setSelectedCarpentryDayTimeOccultationTypeOption: React.Dispatch<
    React.SetStateAction<Option | undefined>
  >;
  isDayTimeMonoblocDisplayed: boolean;
  setIsDayTimeMonoblocDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCarpentryNightTimeOccultationTypeOption?: Option;
  setSelectedCarpentryNightTimeOccultationTypeOption: React.Dispatch<
    React.SetStateAction<Option | undefined>
  >;
  isNightTimeMonoblocDisplayed: boolean;
  setIsNightTimeMonoblocDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CarpentryOccultations = ({
  caseId,
  isSelectedCarpentryTypeUsePVC,
  selectedCarpentryDayTimeOccultationTypeOption,
  setSelectedCarpentryDayTimeOccultationTypeOption,
  isDayTimeMonoblocDisplayed,
  setIsDayTimeMonoblocDisplayed,
  selectedCarpentryNightTimeOccultationTypeOption,
  setSelectedCarpentryNightTimeOccultationTypeOption,
  isNightTimeMonoblocDisplayed,
  setIsNightTimeMonoblocDisplayed
}: CarpentryOccultationsProps) => {
  const carpentryOccultationsMotorisationType = useSelector(
    selectCurrentCaseCarpentryOccultationsMotorisationType
  ) as CarpentryOccultationsMotorisationType;

  const { t } = useTranslation();

  const [
    isSelectedCarpentryOccultationsMotorisationTypeSetForDay,
    setIsSelectedCarpentryOccultationsMotorisationTypeSetForDay
  ] = React.useState<boolean>(
    isCarpentryOccultationsMotorisationTypeSetForDay(carpentryOccultationsMotorisationType)
  );

  const [
    isSelectedCarpentryOccultationsMotorisationTypeSetForNight,
    setIsSelectedCarpentryOccultationsMotorisationTypeSetForNight
  ] = React.useState<boolean>(
    isCarpentryOccultationsMotorisationTypeSetForNight(carpentryOccultationsMotorisationType)
  );

  const [
    carpentryOccultationsMotorisationTypeOptionsState,
    setCarpentryOccultationsMotorisationTypeOptionsState
  ] = React.useState<Option[]>(
    refreshCarpentryOccultationsMotorisationTypeOptionsState(
      selectedCarpentryDayTimeOccultationTypeOption,
      selectedCarpentryNightTimeOccultationTypeOption
    )
  );

  if (process.env.GB_FEAT_CARPENTRY_OCCULTATION_TYPES === 'false') return null;

  return (
    <div style={{ paddingTop: '8px' }}>
      <SpecialLabel
        label={t('carpentrySpecifications.carpentryOccultationsType.specialLabel')}
        small
      />
      <CarpentryDayTimeOccultationsTypeForm
        caseId={caseId}
        isSelectedCarpentryTypeUsePVC={isSelectedCarpentryTypeUsePVC}
        selectedCarpentryDayTimeOccultationTypeOption={
          selectedCarpentryDayTimeOccultationTypeOption
        }
        setSelectedCarpentryDayTimeOccultationTypeOption={
          setSelectedCarpentryDayTimeOccultationTypeOption
        }
        selectedCarpentryNightTimeOccultationTypeOption={
          selectedCarpentryNightTimeOccultationTypeOption
        }
        isDayTimeMonoblocDisplayed={isDayTimeMonoblocDisplayed}
        setIsDayTimeMonoblocDisplayed={setIsDayTimeMonoblocDisplayed}
        isSelectedCarpentryOccultationsMotorisationTypeSetForNight={
          isSelectedCarpentryOccultationsMotorisationTypeSetForNight
        }
        setCarpentryOccultationsMotorisationTypeOptionsState={
          setCarpentryOccultationsMotorisationTypeOptionsState
        }
      />
      <CarpentryNightTimeOccultationsTypeForm
        caseId={caseId}
        isSelectedCarpentryTypeUsePVC={isSelectedCarpentryTypeUsePVC}
        selectedCarpentryNightTimeOccultationTypeOption={
          selectedCarpentryNightTimeOccultationTypeOption
        }
        setSelectedCarpentryNightTimeOccultationTypeOption={
          setSelectedCarpentryNightTimeOccultationTypeOption
        }
        selectedCarpentryDayTimeOccultationTypeOption={
          selectedCarpentryDayTimeOccultationTypeOption
        }
        isNightTimeMonoblocDisplayed={isNightTimeMonoblocDisplayed}
        setIsNightTimeMonoblocDisplayed={setIsNightTimeMonoblocDisplayed}
        isSelectedCarpentryOccultationsMotorisationTypeSetForDay={
          isSelectedCarpentryOccultationsMotorisationTypeSetForDay
        }
        setCarpentryOccultationsMotorisationTypeOptionsState={
          setCarpentryOccultationsMotorisationTypeOptionsState
        }
      />
      <CarpentryOccultationsMotorisationTypeForm
        caseId={caseId}
        setIsSelectedCarpentryOccultationsMotorisationTypeSetForDay={
          setIsSelectedCarpentryOccultationsMotorisationTypeSetForDay
        }
        setIsSelectedCarpentryOccultationsMotorisationTypeSetForNight={
          setIsSelectedCarpentryOccultationsMotorisationTypeSetForNight
        }
        carpentryOccultationsMotorisationTypeOptionsState={
          carpentryOccultationsMotorisationTypeOptionsState
        }
      />
    </div>
  );
};

export const carpentryOccultationsTypeOptions: Option[] = [
  {
    label: i18next.t('carpentrySpecifications.carpentryOccultationsType.options.pvcRollerShutter'),
    value: CarpentryOccultationsTypeName.PVCRollerShutter
  },
  {
    label: i18next.t(
      'carpentrySpecifications.carpentryOccultationsType.options.aluminiumRollerShutter'
    ),
    value: CarpentryOccultationsTypeName.AluminiumRollerShutter
  },
  {
    label: i18next.t('carpentrySpecifications.carpentryOccultationsType.options.sunBreaker'),
    value: CarpentryOccultationsTypeName.SunBreaker
  },
  {
    label: i18next.t('carpentrySpecifications.carpentryOccultationsType.options.fabricBlind'),
    value: CarpentryOccultationsTypeName.FabricBlind
  },
  {
    label: i18next.t('carpentrySpecifications.carpentryOccultationsType.options.woodenShutter'),
    value: CarpentryOccultationsTypeName.WoodenShutter
  },
  {
    label: i18next.t('carpentrySpecifications.carpentryOccultationsType.options.metalShutter'),
    value: CarpentryOccultationsTypeName.MetalShutter
  },
  {
    label: i18next.t('carpentrySpecifications.carpentryOccultationsType.options.none'),
    value: CarpentryOccultationsTypeName.None
  }
];
