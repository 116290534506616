import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { selectCurrentCaseEditingStage } from '../../../../../store/selectors/specification/currentCase/editingStage.selector';
import { TopLevelSpecification } from '../../../../../domain/specification/levels/TopLevelSpecification';
import { setTopLevelsSpecifications } from '../../../../../store/actions/setTopLevelsSpecifications.action';
import { selectCurrentCaseIdFromRoute } from '../../../../../store/selectors/navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { selectCurrentCaseProjection } from '../../../../../store/selectors/projection/currentCase/projection.selector';
import { selectCurrentCaseTopLevelsSurfaces } from '../../../../../store/selectors/granulometry/currentCase/topLevelsSurfaces.selector';
import { TopLevelSurfaceName } from '../../../../../domain/granulometry/cases/queries/surfaces/getCaseTopLevelsSurfaces';
import { selectProjectId } from '../../../../../store/selectors/project';
import { GrossFloorSurfaceEffButton } from '../GrossFloorSurfaceEffButton';
import { TopLevelSurfaceInput } from './TopLevelSurfaceInput';
import { caseTopLevelsSpecificationsRepartitionIsValid } from '../../../../../domain/checks/cases/caseTopLevelsSpecificationsRepartitionIsValid';
import { selectCurrentCaseGrossFloorSurfaceEff } from '../../../../../store/selectors/granulometry/currentCase/grossFloorSurfaceEff.selector';
import { selectCurrentCaseGranulometry } from '../../../../../store/selectors/granulometry/currentCase/currentCaseGranulometry.selector';
import { CaseGranulometry } from '../../../../../domain/granulometry/cases/CaseGranulometry';
import { selectIsCaseGranulometryEditLevelGeometryOpened } from '../../../../../store/selectors/navigation/toolbox/casesPanel/isCaseGranulometryEditLevelGeometryOpened.selector';
import { selectCurrentlyEditedLevelSpecification } from '../../../../../store/selectors/navigation/toolbox/casesPanel/currentlyEditedLevelSpecification.selector';
import { hasDrawnGeometry } from '../../../../../domain/specification/levels/queries/has/hasDrawnGeometry';
import { isProjectInputModeGfsEff } from '../../../../../domain/specification/project/queries/is/isProjectInputModeGfsEff';
import { isProjectInputModeCbs } from '../../../../../domain/specification/project/queries/is/isProjectInputModeCbs';

export interface LocalLevelsSurfaceRepartition {
  levels: TopLevelSpecification[];
  isValid: boolean;
}

interface Column4Props {
  topLevelSpecification: TopLevelSpecification;
  selectedLevelSurface: TopLevelSurfaceName;
}

export const Column4Inner = ({ topLevelSpecification, selectedLevelSurface }: Column4Props) => {
  const projectId = useSelector(selectProjectId);
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const caseProjection = useSelector(selectCurrentCaseProjection);
  const caseRealBuiltSurface = caseProjection.projectedRealBuiltSurface.value;
  const grossFloorSurfaceEff = useSelector(selectCurrentCaseGrossFloorSurfaceEff);
  const caseGranulometry = useSelector(selectCurrentCaseGranulometry) as CaseGranulometry;
  const projectedTopLevels = caseGranulometry.initialSpecifications.projectedTopLevels; // useSelector(selectCurrentCaseSpecifiedTopLevels);
  const editingStage = useSelector(selectCurrentCaseEditingStage);
  const topLevelsSurfaces = useSelector(selectCurrentCaseTopLevelsSurfaces);
  const currentlyEditedLevelSpecification = useSelector(selectCurrentlyEditedLevelSpecification);
  const isCaseGranulometryEditLevelGeometryOpened = useSelector(
    selectIsCaseGranulometryEditLevelGeometryOpened
  );

  const dispatch = useDispatch();

  const [localLevelsSpecification, setLocalLevelsSpecification] = React.useState({
    levels: projectedTopLevels,
    isValid:
      !!grossFloorSurfaceEff &&
      caseTopLevelsSpecificationsRepartitionIsValid(
        caseRealBuiltSurface,
        grossFloorSurfaceEff.value,
        projectedTopLevels
      )
  } as LocalLevelsSurfaceRepartition);

  const handleLevelSurfaceChange = React.useCallback(
    (level: number | string, surfaceName: TopLevelSurfaceName, surface: number | null) => {
      if (caseId) {
        // To prevent infinity bug
        if (surface === null || surface > 10) {
          const levelIndex = localLevelsSpecification.levels.findIndex((l) => l.level === level);
          const newRepartition = R.over(
            R.lensIndex(levelIndex),
            R.assoc(surfaceName, surface || undefined),
            localLevelsSpecification.levels
          );
          const isValid =
            !!grossFloorSurfaceEff &&
            caseTopLevelsSpecificationsRepartitionIsValid(
              caseRealBuiltSurface,
              grossFloorSurfaceEff.value,
              newRepartition
            );
          setLocalLevelsSpecification({ levels: newRepartition, isValid });
          dispatch(setTopLevelsSpecifications(caseId, newRepartition));
        }
      }
    },
    [dispatch, localLevelsSpecification, caseRealBuiltSurface, setLocalLevelsSpecification, caseId]
  );

  React.useEffect(() => {
    setLocalLevelsSpecification({
      levels: projectedTopLevels,
      isValid:
        !!grossFloorSurfaceEff &&
        caseTopLevelsSpecificationsRepartitionIsValid(
          caseRealBuiltSurface,
          grossFloorSurfaceEff.value,
          projectedTopLevels
        )
    });
  }, [projectedTopLevels]);

  if (!caseLabel || !topLevelsSurfaces) return null;

  const levelIndex = topLevelSpecification.level;

  const isRbsSelected = selectedLevelSurface === 'realBuiltSurface';
  const isGfsEffSelected = selectedLevelSurface === 'grossFloorSurfaceEff';
  /* const isSfsSelected = selectedLevelSurface === 'surfaceForSale'; */

  const currentlyEditedLevelHasDrawnGeometry =
    !!currentlyEditedLevelSpecification && hasDrawnGeometry(currentlyEditedLevelSpecification);

  const levelFromSpec = localLevelsSpecification.levels.find(
    (l) => l.id === topLevelSpecification.id
  );
  const levelSurfacesFromGranulo = topLevelsSurfaces.find((l) => l.level === levelIndex);

  const levelSurfacePlaceholder =
    levelSurfacesFromGranulo && Math.max(0, levelSurfacesFromGranulo[selectedLevelSurface].value);

  const levelSurfaceValue =
    isRbsSelected && !currentlyEditedLevelHasDrawnGeometry
      ? levelFromSpec?.realBuiltSurface
      : isGfsEffSelected &&
        ((currentlyEditedLevelHasDrawnGeometry && levelIndex !== 0) ||
          isProjectInputModeGfsEff(caseProjection))
      ? levelFromSpec?.grossFloorSurfaceEff
      : levelSurfacePlaceholder;

  return (
    <>
      {levelFromSpec?.id &&
      isGfsEffSelected &&
      caseId &&
      isProjectInputModeGfsEff(caseProjection) ? (
        <GrossFloorSurfaceEffButton
          projectId={projectId}
          caseId={caseId}
          levelId={levelFromSpec.id}
          isSelected={
            isCaseGranulometryEditLevelGeometryOpened &&
            currentlyEditedLevelSpecification?.id === levelFromSpec.id
          }
          grossFloorSurfaceEff={levelSurfaceValue}
          dispatch={dispatch}
          isFilled={true}
        />
      ) : (
        <TopLevelSurfaceInput
          isRbsSelected={isRbsSelected}
          isGfsEffSelected={isGfsEffSelected}
          handleLevelSurfaceChange={handleLevelSurfaceChange}
          caseLabel={caseLabel}
          levelIndex={levelIndex}
          selectedLevelSurface={selectedLevelSurface}
          localLevelsSpecification={localLevelsSpecification}
          levelSurfaceValue={levelSurfaceValue}
          levelSurfacePlaceholder={levelSurfacePlaceholder}
          editingStage={editingStage}
          isDisabled={
            /*! isSfsSelected &&
            ( */ (currentlyEditedLevelHasDrawnGeometry && !isGfsEffSelected) ||
            (!currentlyEditedLevelHasDrawnGeometry && !isRbsSelected) /* ) */
          }
          asCustomValue={
            (isGfsEffSelected &&
              (currentlyEditedLevelHasDrawnGeometry || isProjectInputModeGfsEff(caseProjection)) &&
              !!levelSurfaceValue) ||
            (isRbsSelected &&
              !currentlyEditedLevelHasDrawnGeometry &&
              isProjectInputModeCbs(caseProjection) &&
              !!levelSurfaceValue)
          }
        />
      )}
    </>
  );
};

export const Column4 = ({ topLevelSpecification, selectedLevelSurface }: Column4Props) => (
  <div className="column-4">
    <div className="cell">
      <Column4Inner
        topLevelSpecification={topLevelSpecification}
        selectedLevelSurface={selectedLevelSurface}
      />
    </div>
  </div>
);
