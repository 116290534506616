import * as React from 'react';
import { useSelector } from 'react-redux';
import { DeliverablesPanelInner } from './DeliverablesPanelInner';
import { selectRoute } from '../../../../store/selectors/navigation/getRoute.selector';
import { ReportTableModal } from './ReportTableModal';

export const DeliverablesPanel = () => {
  const currentRoute = useSelector(selectRoute);

  return (
    <div className="panel panel-deliverables">
      {currentRoute.isDeliverablesExportReport && <ReportTableModal />}
      {currentRoute.isDeliverables && !currentRoute.isDeliverablesExportReport && (
        <DeliverablesPanelInner />
      )}
    </div>
  );
};
