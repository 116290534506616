import classNames from 'classnames';
import * as React from 'react';
import { IconFont } from '../../../../components/ui/Icons/IconFont';
import { getHeight } from '../../../../utils/buildingMap/getHeight';
import { getPaddingTop } from '../../../../utils/buildingMap/getPaddingTop';
import { getPaddingBottom } from '../../../../utils/buildingMap/getPaddingBottom';
import { LevelId } from '../../../../domain/granulometry/levels/LevelGranulometry';

export interface BuildingMapLabelProps {
  element: any;
  levelIndex: number;
  levelId: LevelId;
  type: string;
  bgColor?: string;
  topPos?: number;
  labelsRefs?: any;
}

const highlightLevel = (props: BuildingMapLabelProps) => {
  const buildingMap = document.querySelector('.buildingMap');
  if (buildingMap) {
    buildingMap.classList.add('levelHighlighted');
    const groupElements = document.querySelectorAll(
      '.level[data-level="' + props.levelIndex + '"]'
    );
    // tslint:disable-next-line
    for (let i = 0; i < groupElements.length; i = i + 1) {
      groupElements[i].classList.add('highlighted');
    }
  }
};

const removeHighlightLevel = (props: BuildingMapLabelProps) => {
  const buildingMap = document.querySelector('.buildingMap');
  if (buildingMap) {
    buildingMap.classList.remove('levelHighlighted');
    const groupElements = document.querySelectorAll(
      '.level[data-level="' + props.levelIndex + '"]'
    );
    // tslint:disable-next-line
    for (let i = 0; i < groupElements.length; i = i + 1) {
      groupElements[i].classList.remove('highlighted');
    }
  }
};

export const BuildingMapLabelViewController = (props: BuildingMapLabelProps) => {
  // const dispatch = useDispatch();
  // const focusOnLevel = (levelId: LevelId) => dispatch(buildingMapFocused({ level: levelId }));

  const { element, levelIndex, levelId, type, labelsRefs } = props;

  if (element) {
    const classes = classNames('label', {
      levelLabel: type === 'level',
      groundLabel: type === 'ground'
    });

    let returnString = '';
    const rest: any = {};

    const labelAttributes: any = {};

    if (type === 'level') {
      returnString = (
        levelIndex !== 0 ? (levelIndex > 0 ? '+' + levelIndex : levelIndex) : 'RC'
      ).toString();
    } else if (type === 'ground') {
      returnString = '-' + levelIndex;
      rest.color = 'white';
    }

    const labelStyle: any = {};
    let top = 20000;
    labelStyle.transform = 'translateY(-50%)';

    if (type === 'level' && levelIndex >= 0) {
      // Top levels
      labelAttributes.onMouseEnter = () => highlightLevel(props);
      labelAttributes.onMouseLeave = () => removeHighlightLevel(props);
      // labelAttributes.onClick = () => focusOnLevel(levelId);
      let recordedTop = top;
      for (let i = 0; i <= levelIndex; i = i + 1) {
        const levelElement = labelsRefs.get(i).current;
        const levelHeight =
          getHeight(levelElement) + getPaddingTop(levelElement) + getPaddingBottom(levelElement);
        top = recordedTop - levelHeight;
        if (i === levelIndex) {
          top += levelHeight / 2;
        }
        recordedTop = top;
      }
      labelStyle.top = top + 'px';
    } else if (type === 'level' && levelIndex < 0) {
      // Basement levels
      labelAttributes.onMouseEnter = () => highlightLevel(props);
      labelAttributes.onMouseLeave = () => removeHighlightLevel(props);
      let recordedTop = top;
      for (let i = -1; i >= levelIndex; i = i - 1) {
        const levelElement = labelsRefs.get(i).current;
        const levelHeight =
          getHeight(levelElement) + getPaddingTop(levelElement) + getPaddingBottom(levelElement);
        top = recordedTop + levelHeight;
        if (i === levelIndex) {
          top -= levelHeight / 2;
        }
        recordedTop = top;
      }
      labelStyle.top = top + 'px';
    } else {
      // Ground labels
      labelStyle.cursor = 'default';
      if (props.topPos) {
        labelStyle.top = top + props.topPos + 'px';
      }
      if (props.bgColor) {
        labelStyle.backgroundColor = props.bgColor;
      }
    }

    return (
      <div
        className={classes}
        style={labelStyle}
        // onClick={() => focusOnLevel(levelId)}
        {...labelAttributes}>
        <IconFont returnString={returnString} {...rest} />
      </div>
    );
  }
  return <>&nbsp;</>;
};
