import { Reducer } from 'redux';
import * as R from 'ramda';
import {
  BUILDING_MAP_FOCUSED,
  BuildingMapFocusedAction
} from '../actions/buildingMap/buildingMapFocused.action';
import { CLOSE_PROJECT } from '../actions/closeProject.action';
import { BuildingMapFocus } from '../../domain/buildingMap/BuildingMapFocus';
import { PROJECT_FETCHED } from '../actions/projectFetched.action';

export interface BuildingMapState {
  focus: BuildingMapFocus;
}

const initialState: BuildingMapState = { focus: 'project' };

export const buildingMapReducer: Reducer<BuildingMapState> = (state = initialState, action) => {
  if (action.type === BUILDING_MAP_FOCUSED) {
    return R.assoc('focus', (action as BuildingMapFocusedAction).payload.focus, state);
  }
  if (action.type === CLOSE_PROJECT || action.type === PROJECT_FETCHED) {
    return initialState;
  }

  return state;
};
