import { createSelector, Selector } from 'reselect';
import { ifDefined } from '../../../utils/ifDefined';
import { selectReportDataTypeValueFromRoute } from './reportDataTypeValueFromRoute.selector';
import { dataTypes } from '../../../domain/DataType';
import { State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';

const getDataTypeLabels = (reportDataType: string): string | undefined =>
  dataTypes.find((type) => type.value === reportDataType)?.label;

export const selectReportDataTypeLabel: Selector<State, Maybe<string>> = createSelector(
  [selectReportDataTypeValueFromRoute],
  ifDefined(getDataTypeLabels)
);
