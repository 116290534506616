import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { isGroundLevel } from '../../../is/isGroundLevel';
import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../../LevelGranulometry';
import { getLevelAlreadyCalculatedRealBuiltSurface } from '../../../surfaces/getLevelAlreadyCalculatedRealBuiltSurface';
import { getTopLevelAlreadyCalculatedSurfaceForSale } from '../../surfaces/surfaceForSale/getTopLevelAlreadyCalculatedSurfaceForSale';
import { mustHaveAHallSection } from '../../../mustHave/mustHaveAHallSection';
import { HallSection, isSectionAHall } from '../../../../../sections/circulationSections/hall';
import { ceilWith2Decimal } from '../../../../../../../utils/round/ceilWith2Decimal';
import { getTopLevelStairsShafWallsSurface } from '../../walls/stairsShaftWalls/getTopLevelStairsShafWallsSurface';
import { getSectionDisplayedSurface } from '../../../../../sections/queries/surfaces/getSectionDisplayedSurface';
import { mustHaveStairShaftSections } from '../../../mustHave/mustHaveStairShaftSections';
import { mustHaveElevatorShaftSections } from '../../../mustHave/mustHaveElevatorShaftSections';
import { getTopLevelElevatorShafWallsSurface } from '../../walls/elevatorShaftWalls/getTopLevelElevatorShafWallsSurface';
import { getTopLevelInsideWallsThickness } from '../../walls/wallsThickness/getTopLevelInsideWallsThickness';
import { getCasePropertyValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyValue';
import { getLevelTechnicalPermiseSections } from '../../../sections/getLevelTechnicalPermiseSections';
import { getTopLevelIncludedInSurfaceForSaleSectionsCount } from '../../counts/getTopLevelIncludedInSurfaceForSaleSectionsCount';
import { getSectionTheoreticalInsideWallsSurface } from '../../../../../sections/queries/walls/theoreticalWalls/insideWalls/getSectionTheoreticalInsideWallsSurface';
import { getBearingTechnicalSheathsSurface } from '../../../../../sections/queries/bearingTechnicalSheaths/getBearingTechnicalSheathsSurface';
import { getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface } from '../../surfaces/sectionsWithTechnicalSheaths/getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface';
import { getTopLevelMinimumBearingSurface } from '../../surfaces/getTopLevelMinimumBearingSurface';
import { BEARING_EMERGENCY_STAIRS_SURFACE } from '../../../../../../../constants/appConstants';
import { getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface } from '../../walls/includedInSurfaceForSaleSectionWalls/getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface';
import { getTopLevelLodgmentsPartitionWallsSurface } from '../../walls/lodgmentWalls/getTopLevelLodgmentsPartitionWallsSurface';
import { getLevelFullFilledContent } from '../../../content/getLevelFullFilledContent';

/* fr : calcul de la surface du palier d'étage d'un niveau */
export const getTopLevelBearingSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithBearingRelativeSections | LevelGranulometry,
  withMinimumBearingSurface: boolean
) => {
  const bearingWidth = getCasePropertyValue(
    caseGranulometry.initialSpecifications,
    'bearingWidth'
  ) as number;

  if (bearingWidth === 0) {
    return 0;
  }
  // Bearing surface calculation
  let bearingSurface = getLevelAlreadyCalculatedRealBuiltSurface(levelGranulometry).value;

  if (getTopLevelIncludedInSurfaceForSaleSectionsCount(levelGranulometry) !== 0) {
    bearingSurface -= getTopLevelAlreadyCalculatedSurfaceForSale(levelGranulometry).value;
    bearingSurface -= getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface(
      caseGranulometry,
      levelGranulometry
    ).value;
    bearingSurface -= getTopLevelLodgmentsPartitionWallsSurface(
      caseGranulometry,
      levelGranulometry
    ).value;
  }

  bearingSurface -= getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface(
    caseGranulometry,
    levelGranulometry
  ).value;
  bearingSurface -= BEARING_EMERGENCY_STAIRS_SURFACE;
  bearingSurface -= getBearingTechnicalSheathsSurface(caseGranulometry, levelGranulometry).value;

  // For ground level only
  if (isGroundLevel(levelGranulometry)) {
    if (mustHaveAHallSection(caseGranulometry, levelGranulometry)) {
      const hallSection = getLevelFullFilledContent(levelGranulometry).filter(
        isSectionAHall
      )[0] as HallSection;
      bearingSurface -= getSectionDisplayedSurface(hallSection);
      bearingSurface -= getSectionTheoreticalInsideWallsSurface(
        caseGranulometry,
        levelGranulometry,
        hallSection
      );
    }
  }

  // Technical permises
  bearingSurface -= getLevelTechnicalPermiseSections(levelGranulometry).reduce(
    (acc, technicalPermise) => acc + getSectionDisplayedSurface(technicalPermise),
    0
  );

  if (mustHaveStairShaftSections(caseGranulometry)) {
    bearingSurface -= getTopLevelStairsShafWallsSurface(caseGranulometry, levelGranulometry) / 2;
  }

  if (mustHaveElevatorShaftSections(caseGranulometry)) {
    bearingSurface -= getTopLevelElevatorShafWallsSurface(caseGranulometry, levelGranulometry) / 2;
  }

  // Remove bearing walls surface
  const bearingLength = bearingSurface / bearingWidth;

  const insideWallsThickness = getTopLevelInsideWallsThickness(caseGranulometry);
  const bearingInsideWallsSurface = insideWallsThickness * (bearingLength + bearingWidth) * 2 * 0.5;
  bearingSurface -= bearingInsideWallsSurface;

  bearingSurface = ceilWith2Decimal(bearingSurface);

  const minimumBearingSurface = getTopLevelMinimumBearingSurface(
    caseGranulometry,
    levelGranulometry
  ).value;

  if (
    (withMinimumBearingSurface && bearingSurface < minimumBearingSurface) ||
    caseGranulometry.initialSpecifications.projectedMaxSurfaceForSaleHasBeenForced
  ) {
    bearingSurface = minimumBearingSurface;
  }

  return bearingSurface;
};
