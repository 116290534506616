import { LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getBasementLevelGrossFloorSurfaceEff } from './getBasementLevelGrossFloorSurfaceEff';
import { getBasementLevelHoppersSurface } from '../getBasementLevelHoppersSurface';

export const getBasementLevelGrossFloorSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled
): number =>
  getBasementLevelGrossFloorSurfaceEff(caseGranulometry, levelGranulometry) -
  getBasementLevelHoppersSurface(caseGranulometry, levelGranulometry);
