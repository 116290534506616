import * as R from 'ramda';
import { LevelGranulometry } from '../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { hasCaseGranulometryGroundLevelDrawnGeometry } from '../../../../cases/queries/has/hasCaseGranulometryGroundLevelDrawnGeometry';
import { getCaseGroundLevelGeometryFacades } from '../../../../cases/queries/get/getCaseGroundLevelGeometryFacades';
import { FacadeSpecification } from '../../../../../../services/mapBox/domain/facades/FacadeSpecification';
import { getTopLevelDefaultWidth } from './getTopLevelDefaultWidth';
import { getTopLevelDefaultLength } from './getTopLevelDefaultLength';
import { getTopLevelOutsideInsulatedWallsThickness } from './wallsThickness/getTopLevelOutsideInsulatedWallsThickness';

export const getTopLevelInsulatedFacadeInternalLinears = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): number[] => {
  if (hasCaseGranulometryGroundLevelDrawnGeometry(caseGranulometry)) {
    return R.reduce<FacadeSpecification, number[]>(
      (acc, facade) => [
        ...acc,
        (facade.distance || 0) - getTopLevelOutsideInsulatedWallsThickness(caseGranulometry) * 2
      ],
      [] as number[]
    )(getCaseGroundLevelGeometryFacades(caseGranulometry) as FacadeSpecification[]);
  } else {
    const defaultWidth = getTopLevelDefaultWidth(caseGranulometry, levelGranulometry);
    const defaultLength = getTopLevelDefaultLength(caseGranulometry, levelGranulometry);
    return [defaultWidth, defaultLength, defaultWidth, defaultLength];
  }
};
