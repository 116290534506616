import { LevelGranulometry } from '../../../../LevelGranulometry';
import { isGroundLevel } from '../../../is/isGroundLevel';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getSectionDisplayedSurface } from '../../../../../sections/queries/surfaces/getSectionDisplayedSurface';
import { getLevelMaxSurfaceForSaleFromCaseProjection } from './getLevelMaxSurfaceForSaleFromCaseProjection';
import { getLevelTechnicalPermiseSections } from '../../../sections/getLevelTechnicalPermiseSections';
import { getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface } from '../sectionsWithTechnicalSheaths/getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface';
import { getTopLevelMinimumBearingSurface } from '../getTopLevelMinimumBearingSurface';
import { BEARING_EMERGENCY_STAIRS_SURFACE } from '../../../../../../../constants/appConstants';
import { getInFullFilledTopLevelHallSectionSurface } from '../../sections/surfaces/getInFullFilledTopLevelHallSectionSurface';
import { getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface } from '../../walls/includedInSurfaceForSaleSectionWalls/getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface';
import { getTopLevelLodgmentsPartitionWallsSurface } from '../../walls/lodgmentWalls/getTopLevelLodgmentsPartitionWallsSurface';

export const getLevelMaxSurfaceForSale = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): number => {
  if (caseGranulometry.initialSpecifications.projectedMaxSurfaceForSaleHasBeenForced) {
    return getLevelMaxSurfaceForSaleFromCaseProjection(
      caseGranulometry.initialSpecifications,
      levelGranulometry
    );
  } else {
    return (
      (levelGranulometry.realBuiltSurface as number) -
      getTopLevelLodgmentsPartitionWallsSurface(caseGranulometry, levelGranulometry).value -
      getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface(
        caseGranulometry,
        levelGranulometry
      ).value -
      getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface(
        caseGranulometry,
        levelGranulometry
      ).value -
      BEARING_EMERGENCY_STAIRS_SURFACE -
      getTopLevelMinimumBearingSurface(caseGranulometry, levelGranulometry).value -
      getLevelTechnicalPermiseSections(levelGranulometry).reduce(
        (acc, technicalPermise) => acc + getSectionDisplayedSurface(technicalPermise),
        0
      ) -
      (isGroundLevel(levelGranulometry)
        ? getInFullFilledTopLevelHallSectionSurface(levelGranulometry).value
        : 0)
    );
  }
};
