import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import classNames from 'classnames';
import { WindowInner } from './WindowInner';

export const getArrowTopPosition = (trigger: HTMLElement) => {
  if (trigger) {
    const triggerRect = trigger.getBoundingClientRect();
    return triggerRect.top + triggerRect.height / 2 - 17;
  }
  return 0;
};

export interface WindowProps {
  title?: string;
  topButtons?: ReactElement;
  className?: string;
  hidden?: boolean;
  triggerId?: string;
  large?: boolean;
  noScroll?: boolean;
  children: ReactNode | ReactNode[];
}

export const Window = ({
  title,
  topButtons,
  className,
  hidden,
  triggerId,
  large,
  noScroll,
  children
}: WindowProps) => {
  const [arrowPosition, setArrowPosition] = React.useState<number>(0);
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (triggerId) {
      const triggerElement = document.getElementById(triggerId);
      if (triggerElement !== null) {
        const newPosition = getArrowTopPosition(triggerElement);
        if (arrowPosition !== newPosition) {
          setArrowPosition(newPosition);
        }
      }
    }
  });

  React.useEffect(() => {
    const rootInner = document.getElementById('rootInner');
    if (rootInner) {
      rootInner.classList.toggle('windowsExpanded', isExpanded);
    }
    window.dispatchEvent(new Event('resize'));
  }, [isExpanded]);

  return (
    <div
      className={classNames('window', className, {
        'window-hidden': hidden,
        'window-locked-scroll': false,
        'window-large': large,
        'window-expanded': isExpanded
      })}>
      {!isExpanded && <div className="window-arrow" style={{ top: `${arrowPosition}px` }} />}
      <WindowInner
        title={title}
        topButtons={topButtons}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        noScroll={noScroll}>
        {children}
      </WindowInner>
    </div>
  );
};
