import { Polygon, Position } from 'geojson';
import distance from '@turf/distance';
import bearing from '@turf/bearing';
import destination from '@turf/destination';
import { EventEmitter } from 'events';
import MapboxDraw, { DrawCustomMode, DrawPolygon } from '@mapbox/mapbox-gl-draw';
import { LayerWithLevelGeometry } from '../layer/layer.model';
import { LayerRepo } from '../layer/layer.repo';
import { fireDrawEvents } from '../events';

const emitter = new EventEmitter();

export const ROTATE_LEVEL_MODE = 'rotateLevelMode';

type RotateFeature = DrawPolygon & { geometry: Polygon };
interface RotateState {
  selectedFeature: RotateFeature | null;
  originalFeature: RotateFeature | null;
  newFeature: RotateFeature | null;
  selectedLayers?: LayerWithLevelGeometry[];
  lastMouseDownLngLat: Position | null;
  originalMouseCenter: Position | null;
  originalFeatureCenter: Position | null;
  mouseLastDirection: { x: string; y: string };
  mode: 'rotate' | false;
}

interface RotateOptions {
  selectedFeature: RotateState['selectedFeature'];
  selectedLayers?: LayerWithLevelGeometry[];
}
export const RotateLevelMode: DrawCustomMode<RotateState, RotateOptions> = {
  onSetup: function (opts) {
    const state = initState(opts);
    if (state.selectedLayers && state.selectedLayers.length) {
      state.selectedLayers.forEach((layer) =>
        this._ctx.api.setFeatureProperty(layer.id, 'isRotating', true)
      );
    } else {
      this.onStop(state);
    }
    emitter.addListener('rotatestart', () => {
      return null;
    });
    emitter.addListener('rotating', () => {
      return null;
    });
    emitter.addListener('rotateend', () => {
      return null;
    });

    return state;
  },

  onMouseDown: function (state, e) {
    e.target.dragPan.disable();
    // state.selectedFeature = this._ctx.api.get(e.featureTarget?.properties?.id);
    // const centr = centroid(e.featureTarget as RotateFeature);
    // state.originalFeature = e.featureTarget as RotateFeature;
    // state.originalFeatureCenter = centr.geometry.coordinates;
    state.originalMouseCenter = [e.lngLat.lng, e.lngLat.lat];
    state.lastMouseDownLngLat = [e.lngLat.lng, e.lngLat.lat];
    emitter.emit('rotatestart');
    return state;
  },

  toDisplayFeatures: function (state, geojson, display) {
    display(geojson);
  },

  onDrag: function (state, e) {
    if (!state || !state.selectedLayers || !state.selectedLayers.length) return;
    if (state.selectedFeature?.properties?.user_deactivate) return null;

    if (state.selectedLayers && state.selectedLayers.length && state.mode) {
      if (state.mode === 'rotate') {
        // Get the current coordinates
        const currentCoords = [e.lngLat.lng, e.lngLat.lat];

        // Determine the direction of the drag
        const direction = state.lastMouseDownLngLat
          ? {
              x: currentCoords[0] > state.lastMouseDownLngLat[0] ? 'right' : 'left',
              y: currentCoords[1] > state.lastMouseDownLngLat[1] ? 'up' : 'down'
            }
          : state.mouseLastDirection;

        // Check if the direction has changed
        if (
          state.mouseLastDirection &&
          (direction.x !== state.mouseLastDirection.x || direction.y !== state.mouseLastDirection.y)
        ) {
          state.originalMouseCenter = currentCoords;
        }
        // Store the current direction for the next drag event
        state.mouseLastDirection = direction;

        // Store the current coordinates for the next drag event
        state.lastMouseDownLngLat = currentCoords;

        const draggedBearing = bearing(
          state.originalMouseCenter as Position,
          state.lastMouseDownLngLat
        );
        // if (!state.originalFeature || !state.originalFeatureCenter) return;
        (state.selectedLayers as LayerWithLevelGeometry[]).forEach((layer) => {
          const rotateLayer = layer.draw as unknown as RotateFeature;
          const polyCoords = rotate(
            rotateLayer,
            layer.draw.properties.center as Position,
            draggedBearing / 10
          );

          emitter.emit('rotating');
          state.newFeature = {
            ...rotateLayer,
            properties: { ...rotateLayer.properties, isRotating: true }
          };
          state.newFeature.geometry.coordinates = [polyCoords];
          this._ctx.api.add(state.newFeature);
        });
      }
    }
  },

  onClick(state: RotateState, e: MapboxDraw.MapMouseEvent) {
    if (!e.featureTarget) {
      fireDrawEvents(this.map).mode.change({ mode: 'simple_select' });
      fireDrawEvents(this.map).layer.unSelectAll();
    }
  },

  onStop(state) {
    if (state && state.selectedLayers && state.selectedLayers.length) {
      state.selectedLayers.forEach((layer) => {
        this._ctx.api.setFeatureProperty(layer.id, 'isRotating', false);
        this.map.fire('draw.update', { features: [layer.draw] });
      });
    }
  }
};

const rotate = (feature: RotateFeature, originalCenter: Position, draggedBearing: number) => {
  return feature.geometry?.coordinates[0].map((coords: Position) => {
    const distanceFromCenter = distance(originalCenter, coords);
    const bearingFromCenter = bearing(originalCenter, coords);

    const newPoint = destination(
      originalCenter,
      distanceFromCenter,
      bearingFromCenter + draggedBearing
    );
    return newPoint.geometry.coordinates;
  });
};
const initState = (opts?: RotateOptions): RotateState => ({
  lastMouseDownLngLat: null,
  mode: 'rotate',
  originalFeatureCenter: null,
  originalMouseCenter: null,
  mouseLastDirection: { x: 'right', y: 'down' },
  selectedLayers: opts?.selectedLayers,
  selectedFeature: opts?.selectedFeature || null,
  newFeature: opts?.selectedFeature || null,
  originalFeature: opts?.selectedFeature || null
});

export const getRotateLevelMode = (draw: MapboxDraw, layerRepo: LayerRepo) => () => {
  const isRotateLevelMode = draw.getMode() === ROTATE_LEVEL_MODE;
  if (isRotateLevelMode) return draw.changeMode('simple_select');
  const selectedLayers = layerRepo.getSelectedLayerWithLevelGeometry();
  if (!selectedLayers || selectedLayers.length === 0) return;
  draw.changeMode(ROTATE_LEVEL_MODE, { selectedLayers });
  layerRepo.notifyAll();
};
