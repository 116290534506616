import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { FormGroup } from '../../../../ui/FormGroup';
import { Card } from '../../../../ui/Card';
import { useParams } from 'react-router';
import { Checkbox } from '../../../../ui/Checkbox';
import { caseSpecificationChanged } from '../../../../../store/actions/changeCaseSpecification.action';
import { selectCurrentCaseHasBuiltInRamp } from '../../../../../store/selectors/specification/currentCase/hasBuiltInRamp.selector';
import { selectCurrentCaseHasHall } from '../../../../../store/selectors/specification/currentCase/hasHall.selector';
import { IconObject } from '../../../../ui/Icons/iconObject';
import { HALL_ICON } from '../../../../../domain/granulometry/sections/circulationSections/hall';
import { RAMP_ICON } from '../../../../../domain/granulometry/sections/circulationSections/ramp';
import { CaseGranulometryEditSuperstructureRouteParams } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditSuperstructureRoute';

export const GroundLevel = () => {
  const { caseId } = useParams<CaseGranulometryEditSuperstructureRouteParams>();
  const builtInRamp = useSelector(selectCurrentCaseHasBuiltInRamp) as boolean;
  const hall = useSelector(selectCurrentCaseHasHall) as boolean;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!caseId) return null;

  return (
    <Card>
      <SpecialLabel label={t('Ground level')} />
      <FormGroup>
        <Checkbox
          label={t('Hall')}
          selected={hall}
          checkboxName="hall"
          handleCheck={() => dispatch(caseSpecificationChanged(caseId, { hall: !hall }))}
          icon={<IconObject {...HALL_ICON} clickable={false} />}
        />
        <Checkbox
          label={t('Built-in ramp')}
          selected={builtInRamp}
          checkboxName="builtInRamp"
          handleCheck={() =>
            dispatch(caseSpecificationChanged(caseId, { builtInRamp: !builtInRamp }))
          }
          icon={<IconObject {...RAMP_ICON} clickable={false} />}
        />
      </FormGroup>
    </Card>
  );
};
