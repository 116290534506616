import { createSelector, Selector } from 'reselect';
import { LodgmentTypeRate } from '../../../../domain/specification/lodgmentTypes/LodgmentTypesDistribution';
import { LodgmentTypeSpecification } from '../../../../domain/specification/lodgmentTypes/LodgmentTypeSpecification';
import { selectCurrentCaseProjection } from '../../projection/currentCase/projection.selector';
import { State } from '../../../reducers';

export const selectCurrentCaseLodgmentsTypesDistributionWithSpecifications: Selector<
  State,
  (LodgmentTypeRate & LodgmentTypeSpecification)[]
> = createSelector([selectCurrentCaseProjection], (caseSpecification) => {
  const { distribution, lodgmentsTypesSpecifications } = caseSpecification || {};
  if (!distribution || !lodgmentsTypesSpecifications) {
    return [];
  }
  return distribution.map(
    (lodgmentTypeRate: LodgmentTypeRate): LodgmentTypeRate & LodgmentTypeSpecification => {
      const specification = lodgmentsTypesSpecifications.find(
        (spec) => spec.lodgmentType === lodgmentTypeRate.lodgmentType
      ) as LodgmentTypeSpecification;
      return {
        ...lodgmentTypeRate,
        ...specification
      };
    }
  );
});
