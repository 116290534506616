import { LevelGranulometry } from '../../LevelGranulometry';
import { getSectionPerimeter } from '../../../sections/queries/getSectionPerimeter';
import { getSectionTheoreticalExposureRate } from '../../../sections/queries/exposureRate/getSectionTheoreticalExposureRate';
import { isSectionACirculationLaneInfra } from '../../../sections/circulationSections/circulationLaneInfra';
import { isSectionABearingInfra } from '../../../sections/circulationSections/bearingInfra';
import { isSectionAParking } from '../../../sections/parkingSections/ParkingSection';
import { isSectionARampInfra } from '../../../sections/circulationSections/rampInfra';

// TODO : Duplicate with getBasementLevelOutsideWallsLinear ?
export const getBasementLevelOutsideWallsLinear2 = (basementLevel: LevelGranulometry): number =>
  basementLevel.content
    ? basementLevel.content.reduce((linear, section) => {
        if (isSectionAParking(section)) {
          linear += section.width ? section.width * 1.5 : 0;
        } else if (isSectionACirculationLaneInfra(section)) {
          linear += section.width ? section.width * 6 : 0;
        } else if (isSectionABearingInfra(section)) {
          const perimeter = getSectionPerimeter(section);
          linear += perimeter ? perimeter / 4 : 0;
        } else if (isSectionARampInfra(section)) {
          const perimeter = getSectionPerimeter(section);
          const exposureRate = getSectionTheoreticalExposureRate(section) * 4;
          linear += perimeter && exposureRate ? perimeter * exposureRate : 0;
        }
        return linear;
      }, 0)
    : 0;
