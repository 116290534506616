import React from 'react';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import mapboxgl from 'mapbox-gl';
import { LevelGeometry } from '../../../../../../services/mapBox/mapboxDraw/levelGeometry';
import { FacadeSpecification } from '../../../../../../services/mapBox/domain/facades/FacadeSpecification';
import { SpecialLabel } from '../../../../../ui/SpecialLabel';
import { Input } from '../../../../../ui/input/Input';
import { FormGroup } from '../../../../../ui/FormGroup';
import { Button } from '../../../../../ui/Button';
import { onFormUpdateLevelGeometry } from '../../../../../../services/mapBox/domain/floorSpace/onFormUpdateLevelGeometry';
import { MaybeUiGeometry } from '../../Geometry';
import { useTranslation } from 'react-i18next';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';

interface FacadeFormProps {
  floorSpace: LevelGeometry;
  facade: FacadeSpecification;
  index: number;
  ui: MaybeUiGeometry;
  dispatchChange: (feature: LevelGeometry) => {
    type: string;
    payload: { levelGeometry: LevelGeometry };
  };
}

export const FacadeForm = ({ floorSpace, facade, index, ui, dispatchChange }: FacadeFormProps) => {
  const { t } = useTranslation();

  const handleFacadeChange = (
    value: number | null,
    levelGeometry: LevelGeometry,
    facade: FacadeSpecification,
    name: 'angle' | 'distance' | 'adjoiningRate'
  ) => {
    if (levelGeometry.properties) {
      let updatedLevelGeometry: LevelGeometry = {
        ...levelGeometry,
        properties: {
          ...levelGeometry.properties,
          facades:
            levelGeometry.properties.facades?.map((f) =>
              facade.id === f.id ? { ...facade, [name]: value === null ? undefined : value } : f
            ) || []
        }
      };
      if (value !== null)
        updatedLevelGeometry = onFormUpdateLevelGeometry(
          updatedLevelGeometry,
          ui.draw as MapboxDraw,
          ui.map as mapboxgl.Map
        );
      dispatchChange(updatedLevelGeometry);
    }
  };

  const selectComputed = (
    facadeSpecification: FacadeSpecification,
    levelGeometry: LevelGeometry
  ) => {
    if (levelGeometry.properties) {
      const updatedFloorSpaceFeature: LevelGeometry = {
        ...levelGeometry,
        properties: {
          ...levelGeometry.properties,
          facades: [
            ...(levelGeometry.properties.facades as FacadeSpecification[]).map((f) => {
              if (f.id === facadeSpecification.id) {
                return {
                  ...f,
                  computed: true
                };
              }
              return {
                ...f,
                computed: false
              };
            })
          ]
        }
      };
      dispatchChange(updatedFloorSpaceFeature);
    }
  };

  return (
    <div className="facadeForm">
      <SpecialLabel
        label={`${t('floorSpace.facadeName')} ${index + 1} : ${t(facade.orientation)}`}
        small
      />
      <FormGroup horizontal={true} flexEnd>
        <div>
          <Input
            label={t('geometryWindow.facadeForm.angle.label')}
            name="angle"
            type="number"
            suffix="°"
            placeholder={t('geometryWindow.facadeForm.angle.placeholder') as string}
            value={facade.angle === null ? undefined : roundWith2Decimal(facade.angle)}
            disabled={facade.computed}
            handleChange={(value: number) => handleFacadeChange(value, floorSpace, facade, 'angle')}
            size="small"
          />
        </div>
        <div>
          <Input
            label={t('geometryWindow.facadeForm.distance.label')}
            name="distance"
            type="number"
            suffix="m"
            placeholder={t('geometryWindow.facadeForm.distance.placeholder') as string}
            value={facade.distance === null ? undefined : roundWith2Decimal(facade.distance)}
            disabled={facade.computed}
            handleChange={(value: number) =>
              handleFacadeChange(value, floorSpace, facade, 'distance')
            }
            size="small"
          />
        </div>
        <div>
          <Button
            content={t('geometryWindow.facadeForm.computed.label') as string}
            handleClick={() => selectComputed(facade, floorSpace)}
            appearance="outline"
            selected={facade.computed || undefined}
            disabled={facade.computed}
            size="small"
          />
        </div>
      </FormGroup>
      {/* <FormGroup horizontal={true}>
        <div>
          <Input
            label={t('geometryWindow.facadeForm.adjoiningRate.label')}
            name="adjoiningRate"
            type="number"
            suffix="%"
            placeholder={'0'}
            value={facade.adjoiningRate ? roundWith2Decimal(facade.adjoiningRate) : undefined}
            handleChange={value => handleFacadeChange(value, floorSpace, facade, 'adjoiningRate')}
            size="small"
            style={{ width: '74px' }}
          />
        </div>
        <div style={{ width: '100px' }}>&nbsp;</div>
      </FormGroup> */}
      {/* <Note>
        <div>Axe : {facade.axis}</div>
        <div>Orientation : {facade.orientation}</div>
        <div>Point 1 : {(facade.point1 as number[]).join(' | ')}</div>
        <div>Point 2 : {(facade.point2 as number[]).join(' | ')}</div>
      </Note> */}
    </div>
  );
};
