import { createSelector, Selector } from 'reselect';
import { selectCurrentCaseProjection } from '../../projection/currentCase/projection.selector';
import { State } from '../../../reducers';
import { CaseCustomDistribution } from '../../../../domain/specification/cases/CaseSurfaceSpecification';

export const selectCurrentCaseCustomDistribution: Selector<State, CaseCustomDistribution> =
  createSelector(
    [selectCurrentCaseProjection],
    (caseProjection) => caseProjection?.customDistribution || {}
  );
