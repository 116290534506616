import * as React from 'react';
import { SpecialLabel } from '../../../ui/SpecialLabel';
import { Note } from '../../../ui/Note';
import { FormGroup } from '../../../ui/FormGroup';
import { Button } from '../../../ui/Button';
import { useSelector } from 'react-redux';
import { selectIsProjectDetailsGingerAvailable } from '../../../../store/selectors/navigation/toolbox/projectPanel/isProjectDetailsGingerAvailable.selector';
import { useTranslation } from 'react-i18next';

export const GeotechnicalInformationReport = () => {
  const { t } = useTranslation();
  const isEnabled = useSelector(selectIsProjectDetailsGingerAvailable);

  return (
    <>
      <SpecialLabel label={t('geotechnicalInformationReport.label')} />
      <Note strong={true}>
        Afin de cerner vos risques majeurs le <b>RIG</b>, présente une synthèse du sol fondée sur
        les connaissances et expériences à l’échelle du quartier, du Groupe Ginger qui comprend la
        géologie, la géotechnique, l’hydrogéologie et la pollution, à l’échelle du quartier où est
        prévu votre projet.
        <i>Lien direct et préférentiel.</i>
        <FormGroup flexEnd>
          {isEnabled ? (
            <a
              href={
                'https://www.ginger-cebtp.com/nous-contacter?subject=commande-rig-gobuild'
              }
              target="_blank"
              rel="noreferrer noopener">
              <Button size="small" content={t('geotechnicalInformationReport.buttonContent')} />
            </a>
          ) : (
            <Button
              size="small"
              content={t('geotechnicalInformationReport.buttonContent')}
              disabled={true}
            />
          )}
        </FormGroup>
      </Note>
    </>
  );
};
