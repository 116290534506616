import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import * as R from 'ramda';
import { Property } from '../../specification/cases/Property';

export const renameCaseProperties = (
  caseSpecification: CaseSpecification,
  newNames: { [key: string]: string },
  newTitles: { [key: string]: string } = {}
): CaseSpecification =>
  R.over(
    R.lensProp('properties'),
    R.map((property: Property) => ({
      ...property,
      name: newNames[property.name] || property.name,
      title: newTitles[property.name] || property.title
    })),
    caseSpecification
  );
