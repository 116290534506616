import { createSelector, Selector } from 'reselect';
import * as R from 'ramda';
import { ifDefined } from '../../../utils/ifDefined';
import { selectCostByBuildingAndCase } from './costByBuildingAndCase.selector';
import { State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';

type CostByBuilding = Record<string, number>;

export const selectCostByBuilding: Selector<State, Maybe<CostByBuilding>> = createSelector(
  [selectCostByBuildingAndCase],
  ifDefined(R.mapObjIndexed(R.pipe(R.values, R.sum)))
);
