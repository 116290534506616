import { createSelector, Selector } from 'reselect';
import { State } from '../reducers';
import { Notification } from '../../domain/notifications/Notification';
import { selectProjectProjection } from './project/projectProjection.selector';
import { selectIsNewProject } from './project';
import { selectGranulometry } from './granulometry/current.selector';
import { generateNotifications } from '../../domain/notifications/generateNotifications';

export const selectNotifications: Selector<State, Notification[]> = createSelector(
  [selectProjectProjection, selectIsNewProject, selectGranulometry],
  (project, isNewProject, granulometry): Notification[] =>
    generateNotifications(project, isNewProject, granulometry)
);
