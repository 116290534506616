import { Section } from './Section';
import { isSectionACommonPremise } from './commonPremiseSections/CommonPremiseSection';
import { isSectionAShop } from './shopSection/shopSection';

export interface SectionWithGlazedSurface extends Section {
  glazedLinear: number | undefined;
  defaultGlazedRate: number;
}

export const isSectionWithGlazedSurface = (section: Section): section is SectionWithGlazedSurface =>
  isSectionACommonPremise(section) || isSectionAShop(section);

export const filterSectionWithGlazedSurface = (sections: Section[]): SectionWithGlazedSurface[] =>
  sections.filter(isSectionWithGlazedSurface);
