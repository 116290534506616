import { Card } from './Card';
import { CardTitle } from './CardTitle';
import { WindowScroll } from './WindowScroll';
import * as React from 'react';
import { ReactElement } from 'react';
import { IconObject } from './Icons/iconObject';

interface WindowInnerProps {
  title?: string;
  topButtons?: ReactElement;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  noScroll?: boolean;
  children?: any;
}

export const WindowInner = ({
  title,
  topButtons,
  children,
  isExpanded,
  setIsExpanded,
  noScroll
}: WindowInnerProps) => (
  <div className="window-inner">
    {title && (
      <div className="window-title">
        <Card>
          <CardTitle>{title}</CardTitle>
          {topButtons && (
            <div className="topButtons">
              <IconObject
                iconName={isExpanded ? 'fold' : 'expand'}
                type="menu"
                onClick={() => setIsExpanded(!isExpanded)}
              />
              {topButtons}
            </div>
          )}
        </Card>
      </div>
    )}
    {noScroll ? children : <WindowScroll>{children}</WindowScroll>}
  </div>
);
