import { BuildingGranulometry } from '../granulometry/buildings/BuildingGranulometry';
import { LevelGranulometry } from '../granulometry/levels/LevelGranulometry';
import { CaseGranulometry } from '../granulometry/cases/CaseGranulometry';
import { getCaseLevels } from '../granulometry/cases/queries/levels/getCaseLevels';

export const getCaseHighestLevel = (caseGranulometry: CaseGranulometry): number =>
  getCaseLevels(caseGranulometry).reduce(
    (highest: number, levelGranulometry: LevelGranulometry) =>
      levelGranulometry.level > highest && levelGranulometry.realBuiltSurface
        ? levelGranulometry.level
        : highest,
    0
  );

export const getBuildingHighestLevel = (buildingGranulometry: BuildingGranulometry): number =>
  [...buildingGranulometry.cases].reduce((highest, caseGranulometry) => {
    const caseHighestLevel = getCaseHighestLevel(caseGranulometry);
    return caseHighestLevel > highest ? caseHighestLevel : highest;
  }, 0);
