import { LevelGranulometryInitialEntries } from '../../../../LevelGranulometry';
import { getTopLevelRealBuiltSurface } from '../realBuiltSurface/getTopLevelRealBuiltSurface';
import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { getLevelMaxSurfaceForSaleFromCaseProjection } from './getLevelMaxSurfaceForSaleFromCaseProjection';
import { getSectionDisplayedSurface } from '../../../../../sections/queries/surfaces/getSectionDisplayedSurface';
import { getLevelTechnicalPermiseSections } from '../../../sections/getLevelTechnicalPermiseSections';
import { CaseProjection } from '../../../../../../projection/cases/CaseProjection';

export const getGroundLevelSurfaceForSale = (
  caseProjection: CaseProjection,
  groundLevel: LevelGranulometryInitialEntries
): number => {
  if (caseProjection.projectedMaxSurfaceForSaleHasBeenForced) {
    return getLevelMaxSurfaceForSaleFromCaseProjection(caseProjection, groundLevel);
  } else {
    const levelRealBuiltSurface = getTopLevelRealBuiltSurface(groundLevel, caseProjection);
    const ratioRbsToCbs = caseProjection.projectedRatioRbsToCbs;
    const ratioCbsToSfs = caseProjection.projectedRatioCbsToSfs;
    const caseRealBuiltSurface = caseProjection.projectedRealBuiltSurface.value;

    let groundLevelSFS = levelRealBuiltSurface;
    groundLevelSFS *= ratioRbsToCbs * ratioCbsToSfs;

    // Hall : the hall surface is redistributed in the upperLevels
    if (caseProjection.hall && caseProjection.projectedTopLevels.length > 1) {
      groundLevelSFS -= getCasePropertyConvertedValue(caseProjection, 'hallSurface') as number;
    }

    // Technical premise sections
    if (caseProjection.projectedTopLevels.length > 1) {
      groundLevelSFS -= getLevelTechnicalPermiseSections(groundLevel).reduce(
        (acc, technicalPremiseSection) => acc + getSectionDisplayedSurface(technicalPremiseSection),
        0
      );

      // TODO : check if level exist
      groundLevelSFS +=
        caseProjection.projectedTopLevels.reduce(
          (acc, level) =>
            acc +
            (level.technicalPremiseSections || []).reduce(
              (accTPS, technicalPremiseSection) =>
                accTPS + getSectionDisplayedSurface(technicalPremiseSection),
              0
            ),
          0
        ) *
        (levelRealBuiltSurface / (caseRealBuiltSurface || 1));
    }

    return groundLevelSFS;
  }
};
