import { LevelGranulometry } from '../../../../LevelGranulometry';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelInsulatedFacadeInternalLinears } from '../getTopLevelInsulatedFacadeInternalLinears';
import { getTopLevelRealOutsideInsulatedWallsInternalLinear } from './getTopLevelRealOutsideInsulatedWallsInternalLinear';

export const getTopLevelRealOutsideInsulatedWallsInternalLinearDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): string => {
  let details: string[] = [];
  getTopLevelInsulatedFacadeInternalLinears(caseGranulometry, levelGranulometry).forEach(
    (facadeLinear, index) => {
      details = [
        ...details,
        (index !== 0 ? '<b>+</b> ' : '') +
          '<b>' +
          roundWith2Decimal(facadeLinear) +
          ' m</b> de linéaire intérieur isolé pour la façade ' +
          (index + 1)
      ];
    }
  );
  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(
        getTopLevelRealOutsideInsulatedWallsInternalLinear(caseGranulometry, levelGranulometry)
      ) +
      ' m</b>'
  ];
  return details.join('<br />');
};
