import { json } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { from } from 'rxjs';
import * as OE from 'fp-ts-rxjs/ObservableEither';
import addDays from 'date-fns/fp/addDays';
import { getObservable } from './admin.api';
import { AuthToken } from '../../AuthToken';
import { User } from '../../domain/User';
import { Account } from '../../domain/Account';
import { Auth } from '../../store/reducers/user.slice';
import { ApiAuthError } from './errors/auth.error';
import { DateString } from '../../DateString';

export interface LoginResponse {
  csrfToken: AuthToken;
  id: User['id'];
  username: User['userName'];
  firstName: User['firstName'];
  lastName: User['lastName'];
  accountId: Account['id'];
  accountName: Account['name'];
  licenseType: Account['licence']['type'];
  licenseStart: Account['licence']['start'];
  licenseEnd: Account['licence']['end'];
  customPriceLists: Account['customPriceLists'];
}

const SESSION_DURATION_IN_DAYS = 6;

export const login = (
  email: string,
  password: string
): OE.ObservableEither<Error, { auth: Auth; user: User }> => {
  const endpoint = `/Users/login/${email}/${password}`;

  return pipe(
    endpoint,
    getObservable,
    OE.chain((response) => OE.fromObservable<Error, unknown>(from(response.json()))),
    OE.filterOrElse<Error, unknown, LoginResponse>(
      (json: unknown): json is LoginResponse => typeof json === 'object',
      () => new ApiAuthError(endpoint, 200, JSON.stringify(json))
    ),
    OE.map(
      ({
        csrfToken,
        id,
        username,
        firstName,
        lastName,
        accountId,
        accountName,
        licenseType,
        licenseStart,
        licenseEnd,
        customPriceLists
      }) => ({
        auth: {
          token: csrfToken,
          expiration: addDays(SESSION_DURATION_IN_DAYS, new Date()).toISOString() as DateString
        },
        user: {
          id,
          userName: username,
          firstName,
          lastName,
          email: email as User['email'],
          account: {
            id: accountId,
            name: accountName,
            licence: {
              type: licenseType,
              start: licenseStart,
              end: licenseEnd
            },
            customPriceLists
          }
        }
      })
    )
  );
};
