import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { FormGroup } from '../../../../ui/FormGroup';
import { selectCurrentlyEditedLevelSpecification } from '../../../../../store/selectors/navigation/toolbox/casesPanel/currentlyEditedLevelSpecification.selector';
import { getLevelLabel } from '../../../../../domain/granulometry/levels/LevelGranulometry';
import { IconObject } from '../../../../ui/Icons/iconObject';
import { OTHERS_COLOR } from '../../../../../constants/appConstants';
import { selectCurrentLevelCarBoxInfraCount } from '../../../../../store/selectors/specification/currentLevel/currentLevelCarBoxInfraCount.selector';
import { selectCurrentLevelCarParkInfraCount } from '../../../../../store/selectors/specification/currentLevel/currentLevelCarParkInfraCount.selector';
import { Input } from '../../../../ui/input/Input';

export const TotalLevelParking = () => {
  const currentlyEditedLevel = useSelector(selectCurrentlyEditedLevelSpecification);
  const carBoxInfraCount = useSelector(selectCurrentLevelCarBoxInfraCount);
  const carParkInfraCount = useSelector(selectCurrentLevelCarParkInfraCount);

  const { t } = useTranslation();

  if (!currentlyEditedLevel) return null;

  return (
    <div className="total" style={{ marginTop: '8px', paddingTop: '8px', borderTop: '1px dashed' }}>
      <SpecialLabel
        label={
          t('Distribution of parking spaces in the level') +
          ' ' +
          getLevelLabel(parseInt(currentlyEditedLevel.level.toString()))
        }
        small={true}
      />
      <FormGroup horizontal={true}>
        <div style={{ height: '32px', width: '32px', marginRight: '5px' }}>
          <Input
            name="levelCarBoxInfraCount"
            disabled={true}
            type="number"
            min={0}
            step={1}
            value={carBoxInfraCount || 0}
            style={{ margin: '0' }}
          />
        </div>
        <div style={{ height: '32px', width: '32px', marginRight: '5px' }}>
          <IconObject
            color={OTHERS_COLOR}
            iconName="carBox"
            title="Car box"
            type="object"
            clickable={false}
          />
        </div>
        <div
          style={{
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            marginRight: '5px'
          }}>
          +
        </div>
        <div style={{ height: '32px', width: '32px', marginRight: '5px' }}>
          <Input
            name="levelCarParkInfraCount"
            disabled={true}
            type="number"
            min={0}
            step={1}
            value={carParkInfraCount || 0}
            style={{ margin: '0' }}
          />
        </div>
        <div style={{ height: '32px', width: '32px', marginRight: '5px' }}>
          <IconObject
            color={OTHERS_COLOR}
            iconName="carPark"
            title="Car park"
            type="object"
            clickable={false}
          />
        </div>
        <div
          style={{
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            marginRight: '5px'
          }}>
          =
        </div>
        <div
          style={{
            height: '32px',
            width: '32px'
          }}>
          <Input
            name="totalCarParks"
            type="number"
            disabled
            step={1}
            value={(carParkInfraCount || 0) + (carBoxInfraCount || 0)}
            style={{ margin: '0' }}
            inputStyle={{ textAlign: 'center' }}
          />
        </div>
        <div
          style={{
            width: '50px'
          }}>
          &nbsp;
        </div>
      </FormGroup>
    </div>
  );
};
