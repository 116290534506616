import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormGroup } from 'components/ui/FormGroup';
import { Button } from '../../../../../ui/Button';
import { selectIsProjectDetailsFloorSpaceOpened } from '../../../../../../store/selectors/navigation/toolbox/projectPanel/isProjectDetailsGeometryOpened.selector';
import { selectProjectId } from '../../../../../../store/selectors/project';
import { Input } from '../../../../../ui/input/Input';
import { projectGfsEffIsValid } from '../../../../../../domain/checks/project/projectGfsEffIsValid';
import { selectProjectGfsEff } from '../../../../../../store/selectors/project/projectGfsEff.selector';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';
import { getProjectDetailsGeometryTriggerId } from '../../../../../../routes/toolbox/projectPanel/triggers/getProjectDetailsGeometryTriggerId';
import { goToProjectDetailsEditGeometry } from '../../../../../../store/actions/navigations/toolbox/projectPanel/goToProjectDetailsEditGeometry.action';

export const ProjectGfsEffForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isProjectDetailsFloorSpaceOpened = useSelector(selectIsProjectDetailsFloorSpaceOpened);
  const projectId = useSelector(selectProjectId);
  const projectGfsEff = useSelector(selectProjectGfsEff);

  return (
    <FormGroup>
      <div id={getProjectDetailsGeometryTriggerId()}>
        <label htmlFor={`projectGfs`}>{t('projectSurface.gfsEff.inputLabel')}</label>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'center',
            marginTop: '-2px'
          }}>
          <Input
            style={{ width: '40%', flex: '0 0 40%', marginRight: '8px', marginTop: '5px' }}
            name={`projectGfsEff`}
            type="number"
            value={projectGfsEff ? roundWith2Decimal(projectGfsEff.value) : undefined}
            disabled={true}
            className="gb-input--highlighted"
            asCustomValue={!!projectGfsEff}
            color={projectGfsEffIsValid(projectGfsEff) ? undefined : 'orange'}
            suffix="m<sup>2</sup>"
          />
          <Button
            content={t('projectSurface.gfsEff.buttonContent')}
            selected={isProjectDetailsFloorSpaceOpened}
            appearance="outline"
            handleClick={() => {
              if (projectId) {
                dispatch(goToProjectDetailsEditGeometry(projectId));
              }
            }}
          />
        </div>
      </div>
    </FormGroup>
  );
};
