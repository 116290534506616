import ramp, { RampSection } from '../../../../../sections/circulationSections/ramp';
import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createTopLevelRampSection = (
  caseGranulometry: CaseGranulometry,
  group: string
): RampSection => {
  const length = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'rampLength'
  ) as number;
  const width = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'rampWidth'
  ) as number;
  const surface = length * width;
  const exposureRate =
    (getCasePropertyConvertedValue(
      caseGranulometry.initialSpecifications,
      'rampExposureRate'
    ) as number) * 4;
  const garageDoorHeight = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'rampGarageDoorHeight'
  ) as number;
  const garageDoorWidth = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'rampGarageDoorWidth'
  ) as number;
  return ramp({
    surface,
    length,
    width,
    exposureRate,
    garageDoorWidth,
    garageDoorHeight,
    group
  });
};
