import { CaseId } from '../../../domain/specification/cases/CaseSpecification';
import { matchesPath } from '../../utils/matchesPath';
import { orCreate } from '../../utils/orCreate';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type CaseGranulometryEditOutdoorsRouteParams = {
  projectId: string;
  caseId: CaseId;
};
export const caseGranulometryEditOutdoorsRoute: RouteWithMakePath<CaseGranulometryEditOutdoorsRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/outdoors`,
    makePath: ({ projectId, caseId }) =>
      `/project/${orCreate(projectId)}/cases/${caseId}/granulometry/edit/outdoors`,
    matchesPath: matchesPath<CaseGranulometryEditOutdoorsRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/outdoors/,
      {
        projectId: 1,
        caseId: 2
      }
    )
  };
