import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { FormGroup } from '../../../../ui/FormGroup';
import { Card } from '../../../../ui/Card';
import { selectCurrentCaseProjection } from '../../../../../store/selectors/projection/currentCase/projection.selector';
import { useParams } from 'react-router';
import { Input } from '../../../../ui/input/Input';
import { casePropertyChanged } from '../../../../../store/actions/casePropertyChanged.action';
import { getCaseProperty } from '../../../../../domain/specification/cases/queries/get/properties/getCaseProperty';
import { IconObject } from '../../../../ui/Icons/iconObject';
import { CaseGranulometryEditSuperstructureRouteParams } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditSuperstructureRoute';

export const InsulationRates = () => {
  const { caseId } = useParams<CaseGranulometryEditSuperstructureRouteParams>();
  const currentlyEditedCase = useSelector(selectCurrentCaseProjection);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const internalInsulationRateProperty =
    currentlyEditedCase && getCaseProperty(currentlyEditedCase, 'internalInsulationRate');
  const handleInternalInsulationRateChange = React.useCallback(
    (newInternalInsulationRate: number | null) => {
      if (
        caseId &&
        internalInsulationRateProperty &&
        (typeof newInternalInsulationRate === 'number' || newInternalInsulationRate === null)
      ) {
        dispatch(
          casePropertyChanged(caseId, {
            ...internalInsulationRateProperty,
            value: newInternalInsulationRate ?? undefined
          })
        );
      }
    },
    [dispatch, caseId, internalInsulationRateProperty]
  );

  const externalInsulationRateProperty =
    currentlyEditedCase && getCaseProperty(currentlyEditedCase, 'externalInsulationRate');
  const handleExternalInsulationRateChange = React.useCallback(
    (newExternalInsulationRate: number | null) => {
      if (
        caseId &&
        externalInsulationRateProperty &&
        (typeof newExternalInsulationRate === 'number' || newExternalInsulationRate === null)
      ) {
        dispatch(
          casePropertyChanged(caseId, {
            ...externalInsulationRateProperty,
            value: newExternalInsulationRate ?? undefined
          })
        );
      }
    },
    [dispatch, caseId, externalInsulationRateProperty]
  );

  const balconyInsulatingBreakRateProperty =
    currentlyEditedCase && getCaseProperty(currentlyEditedCase, 'balconyInsulatingBreakRate');
  const handleBalconyInsulatingBreakRateChange = React.useCallback(
    (newBalconyInsulatingBreakRate: number | null) => {
      if (
        caseId &&
        balconyInsulatingBreakRateProperty &&
        (typeof newBalconyInsulatingBreakRate === 'number' ||
          newBalconyInsulatingBreakRate === null)
      ) {
        dispatch(
          casePropertyChanged(caseId, {
            ...balconyInsulatingBreakRateProperty,
            value: newBalconyInsulatingBreakRate ?? undefined
          })
        );
      }
    },
    [dispatch, caseId, balconyInsulatingBreakRateProperty]
  );

  if (
    process.env.GB_FEAT_INSULATION_RATES === 'false' ||
    !currentlyEditedCase ||
    !internalInsulationRateProperty ||
    !externalInsulationRateProperty ||
    !balconyInsulatingBreakRateProperty
  )
    return null;

  return (
    <Card>
      <SpecialLabel label={t('Insulating envelope')} />
      <FormGroup horizontal={true} wrap={true}>
        <Input
          style={{ width: '40%', flex: '0 0 40%' }}
          name={`case${caseId}InternalInsulationRate`}
          label={`${t('Internal insulation rate')} (${t('ITI')})`}
          info={t('internalInsulationRateInfos')}
          type="number"
          min={0}
          max={100}
          minMax={{ min: 0, max: 100, unit: '%' }}
          placeholder={internalInsulationRateProperty.defaultValue}
          value={internalInsulationRateProperty.value}
          handleChange={handleInternalInsulationRateChange}
          suffix={externalInsulationRateProperty.unit}
          icon={<IconObject type="menu" iconName="internalInsulation" clickable={false} />}
        />
      </FormGroup>
      <FormGroup horizontal={true} wrap={true}>
        <Input
          style={{ width: '40%', flex: '0 0 40%' }}
          name={`case${caseId}ExternalInsulationRate`}
          label={`${t('External insulation rate')} (${t('ETI')})`}
          info={t('externalInsulationRate.infos')}
          type="number"
          min={0}
          max={100}
          minMax={{
            min: externalInsulationRateProperty.min,
            max: externalInsulationRateProperty.max,
            unit: externalInsulationRateProperty.unit
          }}
          placeholder={externalInsulationRateProperty.defaultValue}
          value={externalInsulationRateProperty.value}
          handleChange={handleExternalInsulationRateChange}
          suffix={externalInsulationRateProperty.unit}
          icon={<IconObject type="menu" iconName="externalInsulation" clickable={false} />}
        />
      </FormGroup>
      <FormGroup horizontal={true} wrap={true}>
        <Input
          style={{ width: '40%', flex: '0 0 40%' }}
          name={`case${caseId}BalconyInsulatingBreakRate`}
          label={t('Balcony insulating break rate')}
          info={t('balconyInsulatingBreakRateInfos')}
          type="number"
          min={0}
          max={100}
          minMax={{
            min: balconyInsulatingBreakRateProperty.min,
            max: balconyInsulatingBreakRateProperty.max,
            unit: balconyInsulatingBreakRateProperty.unit
          }}
          placeholder={balconyInsulatingBreakRateProperty.defaultValue}
          value={balconyInsulatingBreakRateProperty.value}
          handleChange={handleBalconyInsulatingBreakRateChange}
          suffix={balconyInsulatingBreakRateProperty.unit}
          icon={<IconObject type="menu" iconName="balconyInsulatingBreak" clickable={false} />}
        />
      </FormGroup>
    </Card>
  );
};
