import { createSelector, Selector } from 'reselect';
import { selectCurrentCaseProjection } from '../../projection/currentCase/projection.selector';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';
import { RoofingType } from '../../../../domain/specification/cases/RoofingType';

export const selectCurrentCaseRoofingType: Selector<State, Maybe<RoofingType>> = createSelector(
  [selectCurrentCaseProjection],
  (caseSpecification) => caseSpecification?.roofingType
);
