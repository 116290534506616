import * as React from 'react';

export const BracketVertical = () => (
  <div className="bracket vertical">
    <div className="closing">
      <svg version="1.1" viewBox="0 0 122 85.2">
        <path d="M0,5.2h48c17.6,0,32,14.3,32,32v48" />
      </svg>
    </div>
    <div className="line">
      <svg version="1.1" viewBox="0 0 122 5.2" preserveAspectRatio="none">
        <line x1="80" y1="0" x2="80" y2="5.2" />
      </svg>
    </div>
    <div className="middle">
      <svg version="1.1" viewBox="0 0 122 85.2">
        <path d="M80,0v8.1l35.2,35.2L80,78.6v6.6" />
      </svg>
    </div>
    <div className="line">
      <svg version="1.1" viewBox="0 0 122 5.2" preserveAspectRatio="none">
        <line x1="80" y1="0" x2="80" y2="5.2" />
      </svg>
    </div>
    <div className="closing">
      <svg version="1.1" viewBox="0 0 122 85.2">
        <path d="M80,0v48c0,17.7-14.4,32-32,32H0" />
      </svg>
    </div>
  </div>
);
