export enum CarpentryOccultationsMotorisationTypeName {
  DayTime = 'dayTime',
  NightTime = 'nightTime',
  DayAndNightTime = 'dayAndNightTime',
  None = 'none'
}

export type CarpentryOccultationsMotorisationType =
  | CarpentryOccultationsMotorisationTypeName.DayTime
  | CarpentryOccultationsMotorisationTypeName.NightTime
  | CarpentryOccultationsMotorisationTypeName.DayAndNightTime
  | CarpentryOccultationsMotorisationTypeName.None;

export const isCarpentryOccultationsMotorisationTypeSetForNight = (
  carpentryOccultationsMotorisationType: CarpentryOccultationsMotorisationType
): boolean =>
  carpentryOccultationsMotorisationType === CarpentryOccultationsMotorisationTypeName.NightTime ||
  carpentryOccultationsMotorisationType ===
    CarpentryOccultationsMotorisationTypeName.DayAndNightTime;

export const isCarpentryOccultationsMotorisationTypeSetForDay = (
  carpentryOccultationsMotorisationType: CarpentryOccultationsMotorisationType
): boolean =>
  carpentryOccultationsMotorisationType === CarpentryOccultationsMotorisationTypeName.DayTime ||
  carpentryOccultationsMotorisationType ===
    CarpentryOccultationsMotorisationTypeName.DayAndNightTime;
