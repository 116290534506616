import * as React from 'react';

interface ArrowProps {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
}

const Arrow = ({ setIsOpen, isOpen }: ArrowProps) => (
  <div
    className="arrow"
    onClick={() => {
      setIsOpen(!isOpen);
    }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 282.6 175.4">
      <path d="M40.9 7C31.6-2.3 16.3-2.3 7 7c-9.3 9.3-9.3 24.6 0 33.9l134.3 134.5L275.7 40.9c9.3-9.3 9.3-24.6 0-33.9-9.3-9.3-24.6-9.3-33.9 0L141.4 107.3 40.9 7z" />
    </svg>
  </div>
);

export default Arrow;
