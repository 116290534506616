import { CaseId } from '../../domain/specification/cases/CaseSpecification';

export const CASE_REMOVED = 'core/caseRemoved';

export const caseRemoved = (caseId: CaseId, redirect = true) => ({
  type: CASE_REMOVED,
  payload: {
    caseId,
    redirect
  }
});

export type CaseRemovedAction = ReturnType<typeof caseRemoved>;
