import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { CommonPremiseSection } from '../../../../commonPremiseSections/CommonPremiseSection';
import { getCommonPremiseSectionTheoreticalWallsLinear } from './getCommonPremiseSectionTheoreticalWallsLinear';
import { EXPONENT, MULTIPLIER_COEFFICIENT } from '../../getSectionWallsLinear';

export const getCommonPremiseSectionTheoreticalWallsLinearDetails = (
  commonPermise: CommonPremiseSection
): string => {
  let details: string[] = [];
  details = [
    ...details,
    '<b>' +
      roundWith2Decimal(commonPermise.surface || commonPermise.defaultSurface) +
      ' m\u00B2</b> de surface du ' +
      commonPermise.title.toLowerCase()
  ];
  const hideNumbers = !(process.env.GB_ENV === 'dev' || process.env.GB_ENV === 'recette');
  details = [
    ...details,
    '<b>* ' +
      (hideNumbers
        ? 'coefficient multiplicateur'
        : MULTIPLIER_COEFFICIENT + '<sup>' + EXPONENT + '</sup>') +
      '</b>'
  ];
  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(getCommonPremiseSectionTheoreticalWallsLinear(commonPermise)) +
      ' mL</b>'
  ];
  return details.join('<br />');
};
