import { Surface } from '../../../../specification/Surface';
import { CaseSpecification } from '../../../../specification/cases/CaseSpecification';

export const getCaseProjectedCbsFromSpecifications = (
  caseSpecification: CaseSpecification,
  cuttedBuiltSurface: Surface
): Surface =>
  new Surface(
    Math.max(0, caseSpecification.surfaces.cuttedBuiltSurface?.value || cuttedBuiltSurface.value)
  );
