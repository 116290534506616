import { createSelector, Selector } from 'reselect';
import { ifDefined } from '../../../../utils/ifDefined';
import { selectCurrentCaseProjection } from '../../projection/currentCase/projection.selector';
import { getCaseSpecifiedParkingsRate } from '../../../../domain/specification/cases/queries/get/getCaseSpecifiedParkingsRate';
import { State } from '../../../../store/reducers';
import { Maybe } from '../../../../utils/Maybe';

export const selectCurrentCaseSpecifiedParkingsRate: Selector<
  State,
  Maybe<number>
> = createSelector([selectCurrentCaseProjection], ifDefined(getCaseSpecifiedParkingsRate));
