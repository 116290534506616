import { APP_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { getCasePropertyDefaultValue } from '../../../specification/cases/queries/get/properties/getCasePropertyDefaultValue';
import { CirculationName } from './CirculationSection';
import { SectionWithTheoreticalSurface } from '../SectionWithTheoreticalSurface';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { BEARING_ICON } from './bearing';
import { SectionWithWidth } from '../SectionWithWidth';

interface BearingInfraProps {
  surface: number;
  exposureRate?: number;
  width: number;
}

export interface BearingInfraSection
  extends Section,
    SectionDrawn,
    SectionWithWidth,
    SectionWithTheoreticalSurface,
    SectionWithExposureRate {
  name: CirculationName.BearingInfra;
}

const bearingInfra = ({
  surface,
  width,
  exposureRate
}: BearingInfraProps): BearingInfraSection => ({
  // Section
  id: 'NOID',
  name: CirculationName.BearingInfra,
  title: i18next.t('bearingInfra.title'),
  type: 'circulation',
  displayedSurface: surface,
  // SectionDrawn
  surface,
  color: APP_COLOR,
  icon: BEARING_ICON,
  // SectionWithTheoreticalSurface
  theoreticalSurface: surface,
  defaultTheoreticalSurface: getCasePropertyDefaultValue('bearingInfraSurface'),
  // SectionWithExposureRate
  exposureRate,
  defaultExposureRate: getCasePropertyDefaultValue('bearingInfraExposureRate'),
  // BearingInfraSection
  width
});

export default bearingInfra;

export const isSectionABearingInfra = (section: Section): section is BearingInfraSection =>
  getSectionName(section) === CirculationName.BearingInfra;
