import { ValidationRule } from '../ValidationsRule';
import { Notification } from '../Notification';
import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { Granulometry } from '../../granulometry/Granulometry';
import { getProjectCasesGranulometries } from '../../specification/project/queries/cases/getProjectCasesGranulometries';
import { getLevelLabel } from '../../granulometry/levels/LevelGranulometry';
import { getBasementLevelRealBuiltSurfaceIsLargeEnoughValidation } from '../../granulometry/levels/queries/basementLevels/surfaces/getBasementLevelRealBuiltSurfaceIsLargeEnoughValidation';
import { getCaseBasementLevels } from '../../granulometry/cases/queries/levels/getCaseBasementLevels';

export const caseBasementLevelRealBuiltSurfaceMustBeLargeEnough: ValidationRule = (
  projectProjection: ProjectProjection,
  isNewProject?: boolean,
  granulometry?: Granulometry
) => {
  if (granulometry) {
    const notifications: Notification[] = getProjectCasesGranulometries(granulometry).reduce(
      (accNotifications, caseGranulometry) => [
        ...accNotifications,
        ...getCaseBasementLevels(caseGranulometry).reduce((accL, levelGranulometry) => {
          const validation = getBasementLevelRealBuiltSurfaceIsLargeEnoughValidation(
            caseGranulometry,
            levelGranulometry
          );
          if (!validation) {
            const caseLabel = caseGranulometry.labelIndex;
            const levelLabel = getLevelLabel(levelGranulometry.level);
            const specifiedRealBuiltSurface =
              caseGranulometry.initialSpecifications.projectedBasementLevels.find(
                (l) => l.level === levelGranulometry.level
              )?.realBuiltSurface;
            const notification: Notification = {
              title:
                'La SdP réelle saisit pour le niveau ' +
                levelLabel +
                ' de la cage ' +
                caseLabel +
                ' ne permet pas d’assurer la distribution de l’ensemble des places de stationnement incluses',
              explanation:
                'La saisie d’une SdP réelle sur un niveau en infrastructure influe sur la surface de la voie de circulation qui y est contenue. Sachant la surface calculée par défaut pour la voie de circulation correspond à un minimum permettant de distribuer l’ensemble de places de stationnement, il n’est pas possible de réduire cette surface. Dès lors, la valeur saisie pour la SdP réelle (ici ' +
                specifiedRealBuiltSurface +
                '\u00A0m\u00B2) ne peut être inférieure à la valeur calculée par défaut (ici ' +
                (levelGranulometry.basementSurfaceBeforeSpecifiedRBS?.value || 'ERR!') +
                '\u00A0m\u00B2)',
              type: 'warning'
            };
            return [notification, ...accL];
          }
          return accL;
        }, [] as Notification[])
      ],
      [] as Notification[]
    );
    return notifications.length !== 0 ? notifications : true;
  }
  return true;
};
