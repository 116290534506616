import { getSectionGroupWidth } from './getSectionGroupWidth';

export const getWidth = (element: Element, fromGroup?: boolean) => {
  let width = 0;
  if (element) {
    const group = element.attributes.getNamedItem('data-group');
    if (group && group.nodeValue && fromGroup !== true) {
      // If the element is part of a group :
      width = getSectionGroupWidth(group.nodeValue);
    } else {
      const goodWidth = getComputedStyle(element).width;
      if (goodWidth) {
        width = Math.ceil(parseFloat(goodWidth));
      }
    }
  }
  return width;
};
