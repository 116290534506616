import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { DEFAULT_RBS_TO_CBS_RATIO } from '../../../../../constants/appConstants';
import { Project } from '../../../../project/Project';
import { Surface } from '../../../../specification/Surface';
import { CaseGranulometry } from '../../../../granulometry/cases/CaseGranulometry';
import { letterFromIndex } from '../../../../../utils/letterFromIndex';
import { getProjectCasesWithUnforcedCbsCount } from '../../../../specification/project/queries/cases/getProjectCasesWithUnforcedCbsCount';
import { getProjectCasesWithForcedCbsCount } from '../../../../specification/project/queries/cases/getProjectCasesWithForcedCbsCount';
import { isProjectInputModeGfsEff } from '../../../../specification/project/queries/is/isProjectInputModeGfsEff';
import { getCaseTechnicalPremiseSectionsSurface } from '../../../../specification/cases/queries/get/surfaces/getCaseTechnicalPremiseSectionsSurface';

export const getCaseCuttedBuiltSurfaceDetails = (
  project: Project,
  caseGranulometry: CaseGranulometry
): string => {
  let details: string[] = [];

  if (caseGranulometry.initialSpecifications.surfaces.cuttedBuiltSurface) {
    details = [...details, '<b>SdP PC saisie pour la cage :</b>'];
  } else if (isProjectInputModeGfsEff(caseGranulometry.initialSpecifications)) {
    // see : projectCasesSurfaces.ts
    details = [...details, '<b>Calcul de la SdP PC :</b>'];

    details = [...details, '---'];

    details = [
      ...details,
      '<b>' +
        roundWith2Decimal(caseGranulometry.initialSpecifications.projectedRealBuiltSurface.value) +
        ' m\u00B2</b> de SdP réelle'
    ];

    details = [
      ...details,
      '<b>* ' + DEFAULT_RBS_TO_CBS_RATIO + '</b> de ratio Sdp réelle/Sdp PC (par défaut)'
    ];

    const technicalPremiseSectionsSurface = getCaseTechnicalPremiseSectionsSurface(
      caseGranulometry.initialSpecifications
    ).value;
    if (technicalPremiseSectionsSurface) {
      details = [
        ...details,
        '- <b>' +
          roundWith2Decimal(technicalPremiseSectionsSurface) +
          ' m\u00B2</b> de surface total de locaux techniques'
      ];
    }
  } else {
    // projectSurfaceInputMode === 'cbs'
    details = [
      ...details,
      '<i>Calcul de la SdP PC en divisant la SdP PC du projet par le nombre de cages :</i>'
    ];

    details = [...details, '---'];

    const forcedCaseCount = getProjectCasesWithForcedCbsCount(project);

    details = [
      ...details,
      '<b>' +
        (forcedCaseCount ? '(' : '') +
        (project.cuttedBuiltSurface as Surface).value +
        ' m\u00B2</b> de SdP PC saisie à l’échelle du projet'
    ];

    if (forcedCaseCount) {
      details = [
        ...details,
        project.buildings
          .reduce(
            (accB, buildingSpecification, buildingIndex) => [
              ...accB,
              buildingSpecification.cases
                .reduce(
                  (accC, caseSpecification, caseIndex) => [
                    ...accC,
                    caseSpecification.surfaces.cuttedBuiltSurface !== undefined
                      ? '<b>- ' +
                        caseSpecification.surfaces.cuttedBuiltSurface.value +
                        'm\u00B2</b> de SdP PC saisie par la cage ' +
                        (buildingIndex + 1) +
                        letterFromIndex(caseIndex)
                      : ''
                  ],
                  [] as string[]
                )
                .join('')
            ],
            [] as string[]
          )
          .join('') + '<b>)</b>'
      ];
    }

    const unforcedCaseCount = getProjectCasesWithUnforcedCbsCount(project);

    details = [
      ...details,
      '<b>/ ' +
        unforcedCaseCount +
        ' cage' +
        (unforcedCaseCount > 1 ? 's' : '') +
        '</b> sans SdP PC saisie'
    ];
  }

  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(caseGranulometry.initialSpecifications.projectedCuttedBuiltSurface.value) +
      ' m\u00B2</b> de SdP PC'
  ];

  return details.join('<br />');
};
