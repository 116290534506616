import * as R from 'ramda';
import { CONTRIBUTEURS_NOMENCLATURE_ID, Nomenclature } from '../../domain/Nomenclature';
import { createSelector, Selector } from 'reselect';
import { State } from '../reducers';
import { selectNomenclatures } from './nomenclatures.selector';
import { Maybe } from '../../utils/Maybe';

export const selectContributeursNomenclature: Selector<State, Maybe<Nomenclature>> = createSelector(
  [selectNomenclatures],
  R.find((nomenclature) => nomenclature.id === CONTRIBUTEURS_NOMENCLATURE_ID)
);
