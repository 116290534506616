import { CaseGranulometry } from '../../CaseGranulometry';
import { getCaseTopLevels } from '../levels/getCaseTopLevels';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { getLevelLabel } from '../../../levels/LevelGranulometry';
import { getTopLevelGrossFloorSurface } from '../../../levels/queries/topLevels/surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurface';
import { getCaseGrossFloorSurface } from './getCaseGrossFloorSurface';
import { getCaseTopLevelsHoppersSurfaceDetails } from '../../../../specification/cases/queries/get/surfaces/getCaseTopLevelsHoppersSurface.details';
import { getCaseTopLevelsHoppersSurface } from '../../../../specification/cases/queries/get/surfaces/getCaseTopLevelsHoppersSurface';
import { isProjectInputModeCbs } from '../../../../specification/project/queries/is/isProjectInputModeCbs';

export const getCaseGrossFloorSurfaceDetails = (caseGranulometry: CaseGranulometry): string => {
  let details: string[] = [];

  if (isProjectInputModeCbs(caseGranulometry.initialSpecifications)) {
    details = getCaseTopLevels(caseGranulometry).reduce(
      (acc, levelGranulometry, currentIndex) => [
        ...acc,
        '<b>' +
          (currentIndex !== 0 ? '+ ' : '') +
          roundWith2Decimal(getTopLevelGrossFloorSurface(caseGranulometry, levelGranulometry)) +
          ' m\u00B2</b> de SHOB au ' +
          getLevelLabel(levelGranulometry.level)
      ],
      details
    );
  } else {
    details = [...details, '<i>Calcul des trémies de la cage:</i>'];
    details = [
      ...details,
      getCaseTopLevelsHoppersSurfaceDetails(caseGranulometry.initialSpecifications)
    ];
    details = [...details, '---'];

    details = [...details, '<i>Calcul de la SHOB Réelle de la cage :</i>'];
    const caseGfsEff = caseGranulometry.levels.reduce(
      (acc, levelGranulometry) => acc + (levelGranulometry.grossFloorSurfaceEff || 0),
      0
    );
    details = [
      ...details,
      '<b>' + roundWith2Decimal(caseGfsEff) + ' m\u00B2</b> de SHOB VPP (dessinée)'
    ];
    const caseHoppersSurface = getCaseTopLevelsHoppersSurface(
      caseGranulometry.initialSpecifications
    );
    details = [
      ...details,
      '<b>- ' + roundWith2Decimal(caseHoppersSurface) + ' m\u00B2</b> de trémies'
    ];
  }

  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(getCaseGrossFloorSurface(caseGranulometry).value) +
      ' m\u00B2</b> de SHOB réelle'
  ];

  return details.join('<br />');
};
