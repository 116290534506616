import * as R from 'ramda';
import { getSectionName, Section } from '../Section';
import { OutsideCarParkSection } from './outsideCarPark';
import { OutsideCirculationLaneSection } from './outsideCirculationLane';
import { PlantationSection } from './plantation';
import { OutsideStairsShaftSection } from './outsideStairShaft';
import { OutsideStairsShaftInfraSection } from './outsideStairShaftInfra';

export enum OutsideSectionName {
  OutsideStairShaft = 'outsideStairsShaftSection',
  OutsideStairShaftInfra = 'outsideStairsShaftInfraSection',
  OutsideCarPark = 'outsideCarPark',
  OutsideCirculationLane = 'outsideCirculationLane',
  Plantation = 'plantation'
}

export type OutsideSection =
  | OutsideStairsShaftSection
  | OutsideStairsShaftInfraSection
  | OutsideCarParkSection
  | OutsideCirculationLaneSection
  | PlantationSection;

type GetOutsideSectionName = (outsideSection: OutsideSection) => OutsideSectionName;
export const getOutsideSectionName: GetOutsideSectionName = R.prop('name');

export const isSectionAnOutsideSection = (section: Section): section is OutsideSection =>
  (R.values(OutsideSectionName) as string[]).includes(getSectionName(section));

export const filterOutsideSection = (sections: Section[]): OutsideSection[] =>
  sections.filter(isSectionAnOutsideSection);
