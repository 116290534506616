import * as R from 'ramda';
import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import { DEFAULT_CARPENTRY_OCCULTATIONS_TYPE } from '../../specification/cases/CarpentryOccultationsType';
import { CarpentryOccultationsMotorisationTypeName } from '../../specification/cases/CarpentryOccultationsMotorisationType';

export const migrateProject21To22 = (project: Project): Project =>
  mapCases((caseSpecification) =>
    R.pipe<
      [CaseSpecification],
      CaseSpecification,
      CaseSpecification,
      CaseSpecification,
      CaseSpecification,
      CaseSpecification
    >(
      R.assoc(
        'carpentryDayTimeOccultationsType',
        caseSpecification.carpentryDayTimeOccultationsType || DEFAULT_CARPENTRY_OCCULTATIONS_TYPE
      ),
      R.assoc(
        'carpentryDayTimeOccultationsTypeIsMonobloc',
        caseSpecification.carpentryDayTimeOccultationsTypeIsMonobloc !== false
      ),
      R.assoc(
        'carpentryNightTimeOccultationsType',
        caseSpecification.carpentryNightTimeOccultationsType || DEFAULT_CARPENTRY_OCCULTATIONS_TYPE
      ),
      R.assoc(
        'carpentryNightTimeOccultationsTypeIsMonobloc',
        caseSpecification.carpentryNightTimeOccultationsTypeIsMonobloc !== false
      ),
      R.assoc(
        'carpentryOccultationsMotorisationType',
        caseSpecification.carpentryOccultationsMotorisationType ||
          CarpentryOccultationsMotorisationTypeName.None
      )
    )(caseSpecification)
  )(project);
