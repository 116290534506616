import * as R from 'ramda';
import {
  BuildingId,
  BuildingSpecification,
  overCases
} from '../specification/buildings/BuildingSpecification';
import { CaseId } from '../specification/cases/CaseSpecification';
import { buildingHasNoCase } from '../core/queries/buildingHasNoCase';
import { Project } from '../project/Project';
import { overBuilding } from '../project/over/overBuilding';

export const removeACase =
  (buildingId: BuildingId) =>
  (caseId: CaseId) =>
  (project: Project): Project =>
    R.pipe<[Project], Project, Project>(
      overBuilding(buildingId)(overCases(R.reject(R.propEq('id', caseId)))),
      R.over(R.lensProp('buildings'), R.reject<BuildingSpecification>(buildingHasNoCase))
    )(project);
