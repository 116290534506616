import { CaseProjection } from '../CaseProjection';
import { makeCaseProjectionTopLevels } from './levels/makeCaseProjectionTopLevels';
import { makeBasementLevels } from '../../../specification/levels/queries/make/makeBasementLevels';

export const setCaseProjectedLevels = (caseProjection: CaseProjection): CaseProjection => ({
  ...caseProjection,
  projectedTopLevels: makeCaseProjectionTopLevels(caseProjection),
  projectedBasementLevels: makeBasementLevels(
    caseProjection.projectedBasementLevelsCount,
    caseProjection.levels
  )
});
