import { LodgmentType } from '../../../domain/specification/lodgmentTypes/LodgmentType';
import { matchesPath } from '../../utils/matchesPath';
import { orCreate } from '../../utils/orCreate';
import { RouteWithMakePath } from '../../RouteWithMakePath';
import { CaseId } from '../../../domain/specification/cases/CaseSpecification';

export type CaseGranulometryEditLodgmentTypeRouteParams = {
  projectId: string;
  caseId: CaseId;
  lodgmentType: LodgmentType;
};
export const caseGranulometryEditLodgmentTypeRoute: RouteWithMakePath<CaseGranulometryEditLodgmentTypeRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/lodgment-type/:lodgmentType`,
    makePath: ({ projectId, caseId, lodgmentType }) =>
      `/project/${orCreate(
        projectId
      )}/cases/${caseId}/granulometry/edit/lodgment-type/${lodgmentType}`,
    matchesPath: matchesPath<CaseGranulometryEditLodgmentTypeRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/lodgment-type\/(\w+)/,
      {
        projectId: 1,
        caseId: 2,
        lodgmentType: 3
      }
    )
  };
