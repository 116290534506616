import * as R from 'ramda';
import { hasAnyTopLevelSpecificationWithUnsetRealBuiltSurface } from '../../specification/cases/queries/levels/has/hasAnyTopLevelSpecificationWithUnsetRealBuiltSurface';
import { getTopLevelsSpecificationsTotalForcedRealBuiltSurface } from '../../specification/cases/queries/levels/surfaces/getTopLevelsSpecificationsTotalForcedRealBuiltSurface';
import { roundWith1Decimal } from '../../../utils/round/roundWith1Decimal';
import { TopLevelSpecification } from '../../specification/levels/TopLevelSpecification';
import { hasAnyTopLevelSpecificationWithUnsetGrossFloorSurfaceEff } from '../../specification/cases/queries/levels/has/hasAnyTopLevelSpecificationWithUnsetGrossFloorSurfaceEff';
import { getTopLevelsSpecificationsTotalForcedGrossFloorSurfaceEff } from '../../specification/cases/queries/levels/surfaces/getTopLevelsSpecificationsTotalForcedGrossFloorSurfaceEff';

export const caseTopLevelsSpecificationsRepartitionIsValid = (
  caseRealBuiltSurface: number,
  caseGrossFloorSurfaceEff: number,
  topLevelsSpecifications: TopLevelSpecification[]
) => {
  if (!topLevelsSpecifications) {
    return false;
  }

  // Real built surface :
  if (
    R.any(
      (l) => !!(l.realBuiltSurface !== undefined && l.realBuiltSurface < 10),
      topLevelsSpecifications
    )
  ) {
    return false;
  }
  const hasAnyUnsetRbs =
    hasAnyTopLevelSpecificationWithUnsetRealBuiltSurface(topLevelsSpecifications);

  const totalRbsSet =
    getTopLevelsSpecificationsTotalForcedRealBuiltSurface(topLevelsSpecifications);
  if (hasAnyUnsetRbs) {
    if (totalRbsSet >= caseRealBuiltSurface) {
      return false;
    }
  } else if (roundWith1Decimal(totalRbsSet) !== roundWith1Decimal(caseRealBuiltSurface)) {
    return false;
  }

  // Gross floor surface EFF :
  if (
    R.any(
      (l) => !!(l.grossFloorSurfaceEff !== undefined && l.grossFloorSurfaceEff < 10),
      topLevelsSpecifications
    )
  ) {
    return false;
  }
  const hasAnyUnsetGfsEff =
    hasAnyTopLevelSpecificationWithUnsetGrossFloorSurfaceEff(topLevelsSpecifications);

  const totalGfsEff =
    getTopLevelsSpecificationsTotalForcedGrossFloorSurfaceEff(topLevelsSpecifications);
  if (hasAnyUnsetGfsEff) {
    if (totalGfsEff >= caseRealBuiltSurface) {
      return false;
    }
  } else if (roundWith1Decimal(totalGfsEff) !== roundWith1Decimal(caseGrossFloorSurfaceEff)) {
    return false;
  }

  return true;
};
