import classNames from 'classnames';
import * as React from 'react';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { IconObjectProps } from './Icons/iconObject';

export type ButtonSize = 'small' | 'large';
export type ButtonApperance = 'plain' | 'outline';
export type ButtonColor = 'blue' | 'grey' | 'orange' | 'red' | 'green' | 'white';

export interface ButtonProps {
  disabled?: boolean;
  fullWidth?: boolean;
  selected?: boolean;
  link?: string;
  id?: string;
  size?: ButtonSize;
  content?: ReactNode | string | number;
  details?: string;
  isSubmit?: boolean;
  isReset?: boolean;
  withLoader?: boolean;
  withoutContent?: boolean;
  className?: string;
  appearance?: ButtonApperance;
  color?: ButtonColor;
  style?: React.CSSProperties;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon?: IconObjectProps;
  asCustomValue?: boolean;
}

export const Button = ({
  disabled,
  fullWidth,
  selected,
  link,
  id,
  size,
  content,
  details,
  isSubmit,
  isReset,
  withLoader,
  withoutContent,
  className,
  appearance,
  color,
  style,
  handleClick,
  icon,
  asCustomValue
}: ButtonProps) => {
  const button = (
    <button
      id={id}
      role={'button'}
      type={!isSubmit && !isReset ? 'button' : isSubmit ? 'submit' : isReset ? 'reset' : undefined}
      className={classNames(
        'gb-button',
        'gb-button--color--' + (color || 'blue'),
        {
          'gb-button--full-width': fullWidth,
          'gb-button--outlined': appearance === 'outline',
          'gb-button--small': size === 'small',
          'gb-button--selected': selected,
          'gb-button--with-loader': withLoader,
          'gb-button--without-content': withoutContent,
          'gb-button--as-custom-value': asCustomValue
        },
        className
      )}
      onClick={handleClick}
      disabled={disabled}
      style={style}>
      <>
        {icon && icon}
        {!withoutContent && content && (
          <span className="content">
            {typeof content === 'string' ? unescape(content) : content}
            {details && <span>{details}</span>}
          </span>
        )}
      </>
    </button>
  );

  return link ? <Link to={link}>{button}</Link> : button;
};
