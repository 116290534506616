import * as R from 'ramda';
import { CaseProjection } from '../../CaseProjection';
import { getCaseTopLevels } from '../../../../specification/cases/queries/get/levels/getCaseTopLevels';
import { TopLevelSpecification } from '../../../../specification/levels/TopLevelSpecification';
import { uniqId } from '../../../../../infra/uniqId';
import { getEmptyLevelGeometry } from '../../../../specification/levels/queries/get/getEmptyLevelGeometry';

export const makeCaseProjectionTopLevels = (
  caseProjection: CaseProjection
): TopLevelSpecification[] =>
  R.times((i) => {
    const levelIndex = caseProjection.projectedTopLevelsCount - i - 1;
    const found = getCaseTopLevels(caseProjection).find((l) => l.level === levelIndex);
    const def = { id: uniqId(), level: levelIndex } as TopLevelSpecification;
    let levelProjection = found || def;
    if (levelProjection.geometry === undefined) {
      levelProjection = {
        ...levelProjection,
        geometry: getEmptyLevelGeometry(levelProjection.id, null, levelIndex, null, null)
      };
    }
    return levelProjection;
  }, caseProjection.projectedTopLevelsCount);
