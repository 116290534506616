import * as React from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import { DataTable } from '../../../../ui/DataTable';
import { LodgmentType } from '../../../../../domain/specification/lodgmentTypes/LodgmentType';
import { lodgmentStaticElements } from '../../../../../domain/granulometry/sections/lodgmentSections/LodgmentStaticElements';
import { RoomName } from '../../../../../domain/specification/rooms/RoomSpecification';
import {
  Column,
  ComponentPropsGetterC,
  ComponentPropsGetterR,
  ComponentPropsGetterRC,
  DerivedDataObject
} from 'react-table';

interface RoomElementsTableProps {
  lodgmentType: LodgmentType;
  roomName: RoomName;
}

const RoomElementsTable = (props: RoomElementsTableProps) => {
  const { lodgmentType, roomName } = props;

  const columns: Column[] = [
    {
      Header: '',
      width: 30,
      filterable: false,
      resizable: false,
      sortable: false,
      Aggregated: (cellInfo) => {
        const needsExpander = !!(cellInfo.subRows && cellInfo.subRows.length > 1);
        const expanderEnabled = !cellInfo.column.expander;
        return needsExpander && expanderEnabled && cellInfo.row.quantity === 1 ? (
          <div className={classNames('rt-expander', cellInfo.isExpanded && '-open')}>&bull;</div>
        ) : null;
      },
      Cell: null
    },
    { pivot: true },
    {
      Header: 'Éléments',
      accessor: 'e1',
      id: 'e1',
      Pivot: (row) => <span>{row.value.charAt(0).toUpperCase() + row.value.slice(1)}</span>,
      aggregate: (values) => R.uniq(values).join(', '),
      Aggregated: (props) => {
        return props.value;
      },
      expander: false
    },
    {
      Header: 'hideMe',
      accessor: 'e2',
      id: 'e2',
      Cell: (props) =>
        props.original.e3 ? '' : props.value.charAt(0).toUpperCase() + props.value.slice(1),
      aggregate: (values) => R.uniq(values).join(', '),
      Aggregated: () => ''
    },
    {
      Header: 'hideMe',
      accessor: 'e3',
      id: 'e3',
      Cell: (props) => props.value.charAt(0).toUpperCase() + props.value.slice(1),
      aggregate: (values) => R.uniq(values).join(', '),
      Aggregated: () => ''
    },
    {
      Header: 'Qt.',
      accessor: 'quantity',
      aggregate: (values, rows) =>
        rows.reduce(
          (acc: number, row: DerivedDataObject) => (row.e2 === '' ? acc + row.quantity : acc),
          0
        ),
      Aggregated: (row) => {
        return <div style={{ textAlign: 'center' }}>{row.value}</div>;
      },
      Cell: (row) => {
        return <div style={{ textAlign: 'center' }}>{row.value}</div>;
      },
      width: 25
    }
  ];

  const getTheadThProps: ComponentPropsGetterC = (finalState, rowInfo, column) => {
    if (column && column.Header === 'hideMe') {
      return {
        style: { display: 'none' } // override style for 'myHeaderTitle'.
      };
    }
    // However you should return an object, if there is no styles you want to override do
    return {}; // no styles.
  };

  const getTrGroupProps: ComponentPropsGetterR = (finalState, rowInfo) => {
    if (rowInfo && rowInfo.level === 1 && rowInfo.original.e2 === '') {
      return {
        style: { display: 'none' }
      };
    }
    return {};
  };

  const [expanded, setExpanded] = React.useState<{ [key: string]: boolean }>({});

  // see : https://codesandbox.io/s/z2v5p0w6rp
  const handleTableCellClick: ComponentPropsGetterRC = (state, rowInfo, column) => {
    if (typeof rowInfo !== 'undefined' && typeof column !== 'undefined') {
      // added :
      let visibilityStyle = {};
      if (
        rowInfo.level === 0 &&
        rowInfo.groupedByPivot &&
        (column.id === 'e2' || column.id === 'e3')
      ) {
        visibilityStyle = { display: 'none' };
      }
      if (rowInfo.level === 1) {
        if (
          (column.id === 'e1' && (rowInfo.original.e2 !== '' || rowInfo.original.e3 !== '')) ||
          (column.id === 'e2' && rowInfo.original.e2 === '') ||
          (column.id === 'e3' && rowInfo.original.e3 === '') ||
          (column.id === 'e2' && rowInfo.original.e3 !== '')
        ) {
          visibilityStyle = { display: 'none' };
        }
        if (column.id === 'e2' && rowInfo.original.e3 === '') {
          visibilityStyle = { ...visibilityStyle, paddingLeft: '20px' };
        } else if (column.id === 'e3' && rowInfo.original.e3 !== '') {
          visibilityStyle = { ...visibilityStyle, paddingLeft: '40px' };
        }
      }
      const needsExpander = !!(rowInfo.subRows && rowInfo.subRows.length > 1);
      const expanderEnabled = !column.expander;
      const expandedRows = Object.keys(expanded)
        .filter((expandedIndex) => {
          return expanded[expandedIndex] !== false;
        })
        .map(Number);

      const rowIsExpanded = !!(expandedRows.includes(rowInfo.nestingPath[0]) && needsExpander);
      const newExpanded = !needsExpander
        ? expanded
        : rowIsExpanded && expanderEnabled
        ? {
            ...expanded,
            [rowInfo.nestingPath[0]]: false
          }
        : {
            ...expanded,
            [rowInfo.nestingPath[0]]: {}
          };

      return {
        style:
          needsExpander && expanderEnabled
            ? { ...visibilityStyle, cursor: 'pointer' }
            : { ...visibilityStyle, cursor: 'auto' },
        onClick: () => {
          setExpanded(newExpanded);
        }
      };
    } else {
      return {
        onClick: (e: any, handleOriginal: any) => {
          if (handleOriginal) {
            handleOriginal();
          }
        }
      };
    }
  };

  // Return data table
  return lodgmentStaticElements[lodgmentType] && lodgmentStaticElements[lodgmentType][roomName] ? (
    <DataTable
      data={lodgmentStaticElements[lodgmentType][roomName]}
      columns={columns}
      pivotBy={['e1']}
      onExpandedChange={(newExpanded) => {
        setExpanded(newExpanded);
      }}
      expanded={expanded}
      defaultPageSize={100}
      filterable={false}
      showPaginationTop={false}
      showPaginationBottom={false}
      getTheadThProps={getTheadThProps}
      getTrGroupProps={getTrGroupProps}
      getTdProps={handleTableCellClick}
    />
  ) : (
    <>...</>
  );
};

export default RoomElementsTable;
