import { LevelGranulometry } from '../../../domain/granulometry/levels/LevelGranulometry';
import { CaseGranulometry } from '../../../domain/granulometry/cases/CaseGranulometry';
import { roundWith2Decimal } from '../../../utils/round/roundWith2Decimal';
import { isGroundLevel } from '../../../domain/granulometry/levels/queries/is/isGroundLevel';
import groundLevelMetaSection from '../../../domain/granulometry/sections/metaSections/groundLevelMetaSection';
import { getHigherLevel } from '../../../domain/granulometry/levels/queries/getHigherLevel';
import { getTopLevelLodgmentsSurfaceForSale } from '../../../domain/granulometry/levels/queries/topLevels/sections/surfaces/getTopLevelLodgmentsSurfaceForSale';
import { isSectionAElevatorShaft } from '../../../domain/granulometry/sections/circulationSections/elevatorShaft';
import { isSectionAStairsShaft } from '../../../domain/granulometry/sections/circulationSections/stairsShaft';
import { isSectionASmokeExtractionDucts } from '../../../domain/granulometry/sections/circulationSections/smokeExtractionDucts';
import { isHighestLevel } from '../../../domain/granulometry/levels/queries/is/isHighestLevel';
import highestLevelMetaSection from '../../../domain/granulometry/sections/metaSections/highestLevelMetaSection';
import { isFirstBasementLevel } from '../../../domain/granulometry/levels/queries/is/isFirstBasementLevel';
import firstBasementLevelMetaSection from '../../../domain/granulometry/sections/metaSections/firstBasementLevelMetaSection';
import {
  CirculationLaneInfraSection,
  isSectionACirculationLaneInfra
} from '../../../domain/granulometry/sections/circulationSections/circulationLaneInfra';
import { isLowestBasementLevel } from '../../../domain/granulometry/levels/queries/is/isLowestBasementLevel';
import lowestLevelMetaSection from '../../../domain/granulometry/sections/metaSections/lowestLevelMetaSection';
import * as RA from 'ramda-adjunct';
import { adaptSection } from './adaptSection';
import { isFirstCaseInProject } from '../../../domain/granulometry/cases/queries/is/isFirstCaseInProject';
import projectMetaSection from '../../../domain/granulometry/sections/metaSections/projectMetaSection';
import { OutsideSection } from '../../../domain/granulometry/sections/outsideSections/OutsideSection';
import { getLevelFullFilledContent } from '../../../domain/granulometry/levels/queries/content/getLevelFullFilledContent';

export const generateLevelContent = (
  levelGranulometry: LevelGranulometry,
  parentCase: CaseGranulometry
) =>
  [
    ...(levelGranulometry.roofing || []).map((section) => ({
      name: section.name,
      title: section.title,
      surface: roundWith2Decimal(section.displayedSurface)
    })),
    levelGranulometry.offsetSlab
      ? {
          name: levelGranulometry.offsetSlab.name,
          title: levelGranulometry.offsetSlab.title,
          offsetSlabSurface: roundWith2Decimal(levelGranulometry.offsetSlab.displayedSurface)
        }
      : undefined,
    levelGranulometry.outsideSlab
      ? {
          name: levelGranulometry.outsideSlab.name,
          title: levelGranulometry.outsideSlab.title,
          outsideSlabSurface: roundWith2Decimal(levelGranulometry.outsideSlab.displayedSurface)
        }
      : undefined,
    ...getLevelFullFilledContent(levelGranulometry).map((section) =>
      adaptSection(parentCase, levelGranulometry, section)
    ),
    isGroundLevel(levelGranulometry)
      ? adaptSection(
          parentCase,
          levelGranulometry,
          groundLevelMetaSection({
            extendedSurface:
              levelGranulometry.surface +
              (getHigherLevel(parentCase, levelGranulometry)?.outsideSlab?.displayedSurface || 0),
            lodgmentSurfaceForSale: getTopLevelLodgmentsSurfaceForSale(levelGranulometry).value,
            ceilingNetHeight: levelGranulometry.ceilingHeight,
            elevatorShaftCount:
              getLevelFullFilledContent(levelGranulometry).filter(isSectionAElevatorShaft).length ||
              0,
            stairsShaftCount:
              getLevelFullFilledContent(levelGranulometry).filter(isSectionAStairsShaft).length ||
              0,
            smokeExtractionDuctsCount:
              getLevelFullFilledContent(levelGranulometry).filter(isSectionASmokeExtractionDucts)
                .length || 0
          })
        )
      : undefined,
    isGroundLevel(levelGranulometry) && isFirstCaseInProject(parentCase)
      ? adaptSection(parentCase, levelGranulometry, projectMetaSection())
      : undefined,
    isHighestLevel(parentCase, levelGranulometry)
      ? adaptSection(
          parentCase,
          levelGranulometry,
          highestLevelMetaSection({
            ceilingNetHeight: levelGranulometry.ceilingHeight
          })
        )
      : undefined,
    isFirstBasementLevel(levelGranulometry)
      ? adaptSection(
          parentCase,
          levelGranulometry,
          firstBasementLevelMetaSection({
            circulationLaneInfraLength:
              ((lane) => lane && lane.width && lane.displayedSurface / lane.width)(
                (
                  getLevelFullFilledContent(levelGranulometry).filter(
                    isSectionACirculationLaneInfra
                  ) as CirculationLaneInfraSection[]
                )?.[0]
              ) || 0,
            ceilingNetHeight: levelGranulometry.ceilingHeight
          })
        )
      : undefined,
    isLowestBasementLevel(levelGranulometry, parentCase)
      ? adaptSection(
          parentCase,
          levelGranulometry,
          lowestLevelMetaSection({
            circulationLaneInfraLength:
              ((lane) => lane && lane.width && lane.displayedSurface / lane.width)(
                (
                  getLevelFullFilledContent(levelGranulometry).filter(
                    isSectionACirculationLaneInfra
                  ) as CirculationLaneInfraSection[]
                )?.[0]
              ) || 0,
            ceilingNetHeight: levelGranulometry.ceilingHeight
          })
        )
      : undefined,
    ...(levelGranulometry.fundations || []).map((fundationSection) => ({
      name: fundationSection.name,
      title: fundationSection.title,
      surface: roundWith2Decimal(fundationSection.displayedSurface)
    })),
    ...(parentCase.outsideLevels?.find((l) => l.level === levelGranulometry.level) !== undefined
      ? (
          parentCase.outsideLevels?.find((l) => l.level === levelGranulometry.level)
            ?.content as OutsideSection[]
        ).map((outsideSection) => ({
          name: outsideSection.name,
          title: outsideSection.title,
          surface: roundWith2Decimal(outsideSection.displayedSurface)
        }))
      : [undefined])
  ].filter(RA.isNotNil);
