import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { CaseProjection } from '../../projection/cases/CaseProjection';
import { caseSurfaceForSaleIsSmallerThanTheMinimum } from './caseSurfaceForSaleIsSmallerThanTheMinimum';
import { caseSurfaceForSaleIsLargerThanTheMaximum } from './caseSurfaceForSaleIsLargerThanTheMaximum';

export const caseSurfaceForSaleIsValid = (
  projectProjection: ProjectProjection,
  caseProjection: CaseProjection
): boolean =>
  !caseSurfaceForSaleIsSmallerThanTheMinimum(projectProjection, caseProjection) &&
  !caseSurfaceForSaleIsLargerThanTheMaximum(projectProjection, caseProjection);
