import { createSelector, Selector } from 'reselect';
import { selectCurrentCaseProjection } from '../../projection/currentCase/projection.selector';
import { State } from '../../../../store/reducers';
import { Maybe } from '../../../../utils/Maybe';
import { LodgmentTypeSpecification } from '../../../../domain/specification/lodgmentTypes/LodgmentTypeSpecification';

export const selectCurrentCaseLodgmentsTypesSpecifications: Selector<
  State,
  Maybe<LodgmentTypeSpecification[]>
> = createSelector(
  [selectCurrentCaseProjection],
  (caseSpecification) => caseSpecification?.lodgmentsTypesSpecifications
);
