import { LoadImageOptions, MapRepository } from '../map.repository';
import { Position } from 'geojson';
import { RasterLayer, RasterSource } from 'mapbox-gl';
import { getCoordinatesFromLines } from '../../utils/geometry/coordinates';
import { translateCoordinatesToNewCenter } from '../../utils/polygon/translatePolygonOnPoint';
import { getLinesFromWidthHeight } from '../../utils/geometry/lineDistance';

export class RasterServices {
  repo: MapRepository;
  constructor(mapRepo: MapRepository) {
    this.repo = mapRepo;
  }

  moveImageSource(sourceId: string, coordinates: Position[]) {
    this.repo.moveImageSource(sourceId, coordinates);
  }

  removeRaster(sourceId: string, layerId: string) {
    this.repo.removeLayer(layerId);
    this.repo.removeSource(sourceId);
  }

  recreateRaster(raster: RasterLayer, coordinates: Position[]): RasterLayer {
    const source = this.repo.getSource(raster.source as string) as RasterSource;
    this.removeRaster(raster.source as string, raster.id);
    return this.repo.addRasterLayerAndSource(
      {
        layerName: raster.id,
        sourceName: source.id as string,
        imagePath: source.url as string,
        metadata: raster.metadata
      },
      coordinates
    );
  }

  updateRasterLayer(id: string, params: Omit<LoadImageOptions, 'imagePath'>) {
    this.repo.removeLayer(id);
    this.repo.addRasterLayer(params);
  }

  async addRasterOnCurrentPosition(options: LoadImageOptions): Promise<RasterLayer> {
    const coordinates = await this.getNewImageCoordinates(options.imagePath);
    if (!coordinates) throw new Error("Coordonées de l'image non trouvée");
    return this.repo.addRasterLayerAndSource(options, coordinates);
  }

  private async getNewImageCoordinates(imagePath: string): Promise<Position[]> {
    const image = await this.repo.loadImage(imagePath);
    const center = this.repo.getPosition();
    const coords = getCoordinatesFromLines(center, getLinesFromWidthHeight(image));
    const newCoordinates = translateCoordinatesToNewCenter(coords, center);
    return newCoordinates.slice(0, 4);
  }
}
