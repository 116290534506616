import { LevelGeometry } from './levelGeometry';
import { getFacadeSpecifications } from '../domain/facades/getFacadeSpecifications';
import { getArea } from '../utils/geometry/getArea';
import { getPerimeter } from '../utils/geometry/getPerimeter';
import { getCenter } from '../utils/geometry/getCenter';
import { FeaturePolygonWithId } from './draw.repo';
import { Layer } from '../layer/layer.model';

export const FACADE_DRAW_LAYER = 'facades_markers_layer_';
export const FACADE_DRAW_SOURCE = 'facades_markers_';

export const isDrawHelperFeatureEnabled = () => process.env.GB_FEAT_DRAW_HELPER === 'true';

export interface GenerateFloorSpaceFeatureParams {
  parent: Layer;
  floor?: number;
}

export const generateNameLevelFromCurrentCaseLabel = (currentCaseLabel: string, floor: number) =>
  'C' + currentCaseLabel + 'L' + floor;

export const generateLevelGeometry = (
  polygon: FeaturePolygonWithId,
  params?: GenerateFloorSpaceFeatureParams
): LevelGeometry => {
  const points = polygon.geometry.coordinates[0];
  const facades = getFacadeSpecifications(points);
  const floor = params?.floor || 0;

  const name = params ? params.parent.title + 'L' + params.floor : polygon.properties?.name;
  const parentCase = params ? params.parent.id : polygon.properties?.parent;
  const parentBuilding = params ? params.parent.parent : polygon.properties?.buildingParent;
  return {
    ...polygon,
    properties: {
      ...polygon.properties,
      area: getArea(polygon),
      perimeter: getPerimeter(facades),
      facades,
      center: getCenter(points),
      origin: polygon.properties?.origin || points[0],
      shape: { isSelected: true },
      name,
      floor,
      parentCaseId: parentCase,
      parentBuildingId: parentBuilding
    }
  };
};
