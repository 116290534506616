import { Project } from '../../../project/Project';
import { Surface } from '../../Surface';

export const getProjectGfsEffFromLevelGeometries = (project: Project): Surface =>
  new Surface(
    project.buildings.reduce(
      (accBuilding, buildingSpecification) =>
        accBuilding +
        buildingSpecification.cases.reduce(
          (accCases, caseSpecification) =>
            accCases +
            caseSpecification.levels.reduce(
              (accLevels, levelSpecification) =>
                accLevels + (levelSpecification.geometry.properties.area || 0),
              0
            ),
          0
        ),
      0
    )
  );
