import * as React from 'react';
import { Column4Inner } from './Column4';
import { TopLevelSpecification } from '../../../../../domain/specification/levels/TopLevelSpecification';

interface Column4EnlargedProps {
  topLevelSpecification: TopLevelSpecification;
}

export const Column4Enlarged = ({ topLevelSpecification }: Column4EnlargedProps) => {
  return (
    <div className="column-4">
      <div className="cell">
        {process.env.GB_FEAT_GFS === 'true' && (
          <Column4Inner
            topLevelSpecification={topLevelSpecification}
            selectedLevelSurface={'grossFloorSurfaceEff'}
          />
        )}
      </div>
      <div className="cell">
        {process.env.GB_FEAT_GFS === 'true' && (
          <Column4Inner
            topLevelSpecification={topLevelSpecification}
            selectedLevelSurface={'grossFloorSurface'}
          />
        )}
      </div>
      <div className="cell">
        <Column4Inner
          topLevelSpecification={topLevelSpecification}
          selectedLevelSurface={'realBuiltSurface'}
        />
      </div>
      <div className="cell">
        <Column4Inner
          topLevelSpecification={topLevelSpecification}
          selectedLevelSurface={'surfaceForSale'}
        />
      </div>
    </div>
  );
};
