import { UnsavedProject } from '../../domain/project/Project';

export const PROJECT_COPIED = 'PROJECT_COPIED';

export const projectCopied = (project: UnsavedProject) => ({
  type: PROJECT_COPIED,
  payload: { project }
});

export type ProjectCopiedAction = ReturnType<typeof projectCopied>;
