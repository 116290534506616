import { createSelector, Selector } from 'reselect';
import { projectHasSufficientRemainingSurfaceForANewCase } from '../../../domain/checks/project/projectHasSufficientRemainingSurfaceForANewCase';
import { Maybe } from '../../../utils/Maybe';
import { State } from '../../../store/reducers';
import { selectProject } from '../project/project.selector';
import { Project } from '../../../domain/project/Project';

export const selectCanAddACase: Selector<State, Maybe<boolean>> = createSelector(
  [selectProject],
  (p: Project | undefined) => (p ? projectHasSufficientRemainingSurfaceForANewCase(p) : undefined)
);
