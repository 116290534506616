export const countPageNumbers = (file: File): Promise<number> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(file);

    fileReader.onload = () => {
      if (!fileReader.result) return;
      const result = fileReader.result as string;
      const count = result.match(/\/Type[\s]*\/Page[^s]/g)?.length;
      resolve(count);
    };

    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};
