import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ComEthReport } from '../../api/cstb/comEth/comEthReport/ComEthReport';

export interface CstbState {
  fetching: boolean;
  report?: ComEthReport;
}

const initialState: CstbState = {
  fetching: false,
  report: undefined
};

const cstbSlice = createSlice({
  name: 'cstb',
  initialState,
  reducers: {
    reportFetch(state) {
      state.fetching = true;
    },
    reportFetched(state, action: PayloadAction<{ report: ComEthReport }>) {
      state.report = action.payload.report;
      state.fetching = false;
    },
    reportFetchFailed(state, _: PayloadAction<{ error: Error }>) {
      state.report = undefined;
      state.fetching = false;
    }
  }
});

export default cstbSlice;

export const cstbStateReconciler = (saved: CstbState): CstbState => ({
  ...saved,
  fetching: false
});
