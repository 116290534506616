import { CaseProjection } from '../../CaseProjection';
import { BuildingGranulometry } from '../../../../granulometry/buildings/BuildingGranulometry';
import { renderBuilding } from '../../../../granulometry/buildings/queries/renderBuilding';
import { BuildingProjection } from '../../../buildings/BuildingProjection';

export const runFakeGranulo = (caseProjection: CaseProjection): BuildingGranulometry => {
  // FIXME This is called more than 10 times on simple refresh !
  // console.log('running fake granulo');
  const fakeBuildingProjection = {
    id: 'fakeGranuloBuildingId',
    title: 'fakeGranuloBuildingTitle',
    cases: [caseProjection]
  } as BuildingProjection;
  return renderBuilding(fakeBuildingProjection, 0, [caseProjection]);
};
