import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { EXPONENT, MULTIPLIER_COEFFICIENT } from '../../getSectionWallsLinear';

export const getHallSectionTheoreticalWallsLinear = (
  caseGranulometry: CaseGranulometry
): number => {
  const surface = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'hallSurface'
  ) as number;
  return surface !== 0 ? surface * MULTIPLIER_COEFFICIENT * Math.pow(surface, EXPONENT) : 0;
};
