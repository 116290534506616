import * as R from 'ramda';
import { CaseGranulometry } from '../../../domain/granulometry/cases/CaseGranulometry';
import { getCasePropertyConvertedValue } from '../../../domain/specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { Property } from '../../../domain/specification/cases/Property';

export const adaptProperties = (eachCase: CaseGranulometry) =>
  R.pipe<[Property[]], Record<string, Property>, Record<string, string | number | undefined>>(
    R.indexBy(R.prop('name')),
    R.mapObjIndexed((property: Property) =>
      getCasePropertyConvertedValue(eachCase.initialSpecifications, property.name)
    )
  )(eachCase.properties);
