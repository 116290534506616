import * as React from 'react';

type CustomPricesOnlySelectOptions = 'allPrices' | 'customPricesOnly';

interface CustomPricesOnlySelectProps {
  selectedOption: CustomPricesOnlySelectOptions;
  handleCustomPricesFilterChange: (value: CustomPricesOnlySelectOptions) => void;
  disabled: boolean;
}

export const CustomPricesOnlySelect = ({
  selectedOption,
  handleCustomPricesFilterChange,
  disabled
}: CustomPricesOnlySelectProps) => (
  <select
    onChange={(value) => {
      handleCustomPricesFilterChange(
        value.target.selectedOptions[0].value as CustomPricesOnlySelectOptions
      );
    }}
    value={selectedOption}
    disabled={disabled}>
    <option value={'allPrices'}>Tous les prix</option>
    <option value={'customPricesOnly'}>Prix personnalisés uniquement</option>
  </select>
);
