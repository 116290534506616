import { getBalconySpecificationDepth } from './getBalconySpecificationDepth';
import { BalconySpecification } from '../../BalconySpecification';
import { getBalconySpecificationCalculatedSurface } from './getBalconySpecificationCalculatedSurface';

type GetBalconySpecificationCalculatedLength = (
  lodgmentSurface: number,
  balconySurfaceOnRealLivingSurfaceRate: number,
  roomSpecification: BalconySpecification
) => number;

export const getBalconySpecificationCalculatedLength: GetBalconySpecificationCalculatedLength = (
  lodgmentSurface,
  balconySurfaceOnRealLivingSurfaceRate,
  roomSpecification
) =>
  getBalconySpecificationCalculatedSurface(lodgmentSurface, balconySurfaceOnRealLivingSurfaceRate) /
  getBalconySpecificationDepth(roomSpecification);
