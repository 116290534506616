import { cleanFileName } from '../utils/cleanFileName';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { PleiadesJsonEntry } from '../../../../../api/pleiades/makePleiadesJson';

export const downloadPleiades = async (
  report: Partial<PleiadesJsonEntry>[],
  projectName: string
) => {
  const fileName =
    'gobuild-' +
    cleanFileName(projectName) +
    '-pleiades-' +
    new Date().toISOString().slice(0, 10) +
    '.txt';
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(projectName.replace(/[*?:\\/[\]]/g, ' ') + ' – Pleiades');
  worksheet.columns = [
    { header: 'Nom', key: 'product', style: { alignment: { wrapText: true } } },
    { header: 'Lot', key: 'refSsLot1', style: { alignment: { wrapText: true } } },
    { header: 'Quantité', key: 'co2Quantity', style: { alignment: { wrapText: true } } },
    { header: 'Unité fiche', key: 'co2QuantityUnit', style: { alignment: { wrapText: true } } },
    { header: 'Coefficient', key: 'coefficient', style: { alignment: { wrapText: true } } },
    { header: 'Fiche', key: 'fiche', style: { alignment: { wrapText: true } } },
    { header: 'Num. fiche', key: 'numFiche', style: { alignment: { wrapText: true } } },
    { header: 'Durée de vie', key: 'dureeDeVie', style: { alignment: { wrapText: true } } },
    {
      header: 'Durée de vie Fiche',
      key: 'dureeDeVieFiche',
      style: { alignment: { wrapText: true } }
    },
    {
      header: 'Impact CO2 dyn.',
      key: 'impactCo2Dyn',
      style: { alignment: { wrapText: true } }
    },
    {
      header: 'Impact CO2 total dyn.',
      key: 'impactCo2TotalDyn',
      style: { alignment: { wrapText: true } }
    },
    {
      header: 'Impact CO2',
      key: 'impactCo2',
      style: { alignment: { wrapText: true } }
    },
    {
      header: 'Impact CO2 total',
      key: 'impactCo2Total',
      style: { alignment: { wrapText: true } }
    },
    {
      header: 'Bibliothèque',
      key: 'bibliotheque',
      style: { alignment: { wrapText: true } }
    },
    {
      header: 'Lot personnalisé',
      key: 'lotPersonnalise',
      style: { alignment: { wrapText: true } }
    },
    {
      header: 'Commentaire',
      key: 'commentaire',
      style: { alignment: { wrapText: true } }
    },
    {
      header: 'Bâtiment',
      key: 'batiment',
      style: { alignment: { wrapText: true } }
    },
    {
      header: 'Zone',
      key: 'zone',
      style: { alignment: { wrapText: true } }
    }
  ];
  worksheet.addRows(report, 'i');
  workbook.csv
    .writeBuffer({
      formatterOptions: {
        delimiter: '\u0009', // /t
        rowDelimiter: '\u000D\u000A', // /r/n
        writeBOM: true,
        quote: false
      }
    })
    .then(function (buffer) {
      FileSaver.saveAs(
        new Blob([/* new Uint8Array([0xef, 0xbb, 0xbf]), */ buffer], {
          type: 'text/plain;charset=utf-8'
        }),
        fileName
      );
    });
};
