import React, { useState } from 'react';
import { FormGroup } from '../FormGroup';
import { Button } from '../Button';

interface FileInputProps {
  addFile: (file: File) => void;
  handleChange: (file: File) => void;
  disabled: boolean;
  setDisabled: (a: boolean) => void;
}
export const FileInput = ({ addFile, handleChange, disabled, setDisabled }: FileInputProps) => {
  const [selectedFile, setSelectedFile] = useState<File>();

  const handleClick = () => {
    if (selectedFile) {
      setDisabled(true);
      addFile(selectedFile);
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target?.files && event.target.files.length) {
      setSelectedFile(event.target.files[0]);
      handleChange(event.target.files[0]);
    }
  };

  return (
    <FormGroup>
      <div>
        <input type="file" name="file" onChange={onChange} />
      </div>
      {selectedFile && (
        <div>
          <Button
            disabled={disabled}
            content={'Ajouter le fichier'}
            handleClick={handleClick}
            appearance="outline"
          />
        </div>
      )}
    </FormGroup>
  );
};
