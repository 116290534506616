import { LevelGranulometry } from '../../../../LevelGranulometry';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelStructuralFacadeExternalLinears } from '../getTopLevelStructuralFacadeExternalLinears';
import { getTopLevelExternalInsulationThickness } from '../wallsThickness/getTopLevelExternalnalInsulationThickness';
import { getTopLevelRealOutsideInsulatedWallsExternalLinear } from './getTopLevelRealOutsideInsulatedWallsExternalLinear';

export const getTopLevelRealOutsideInsulatedWallsExternalLinearDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): string => {
  let details: string[] = [];
  getTopLevelStructuralFacadeExternalLinears(caseGranulometry, levelGranulometry).forEach(
    (facadeLinear, index) => {
      details = [
        ...details,
        (index !== 0 ? '<b>+</b> ' : '') +
          '<b>' +
          roundWith2Decimal(facadeLinear) +
          ' m</b> de linéaire exterieur structurel pour la façade ' +
          (index + 1) +
          ' <b>+ ' +
          getTopLevelExternalInsulationThickness(caseGranulometry) +
          ' m</b> d’épaisseur d’isolation ext. (ITE)'
      ];
    }
  );
  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(
        getTopLevelRealOutsideInsulatedWallsExternalLinear(caseGranulometry, levelGranulometry)
      ) +
      ' m</b>'
  ];
  return details.join('<br />');
};
