import * as R from 'ramda';
import { createSelector, Selector } from 'reselect';
import { selectPath } from '../../navigation/getPath.selector';
import { selectCurrentCaseLodgmentsTypesSpecifications } from './lodgementsTypesSpecifications.selector';
import { State } from '../../../../store/reducers';
import { Maybe } from '../../../../utils/Maybe';
import { LodgmentTypeSpecification } from '../../../../domain/specification/lodgmentTypes/LodgmentTypeSpecification';
import { caseGranulometryEditLodgmentTypeRoute } from '../../../../routes/toolbox/casesPanel/caseGranulometryEditLodgmentTypeRoute';

export const selectCurrentlyEditedLodgmentType: Selector<
  State,
  Maybe<LodgmentTypeSpecification>
> = createSelector(
  [selectCurrentCaseLodgmentsTypesSpecifications, selectPath],
  (lodgmentsTypes, path) =>
    lodgmentsTypes?.find(
      R.propEq(
        'lodgmentType',
        caseGranulometryEditLodgmentTypeRoute.matchesPath(path).params?.lodgmentType
      )
    )
);
