import { ROOF_COLOR } from '../../../../constants/appConstants';
import i18next from 'i18next';
import { RoofingName } from './RoofingSection';
import { Section, SectionDrawn } from '../Section';

interface FlatRoofingProps {
  displayedSurface: number;
  drawnSurface: number;
}

export interface FlatRoofingSection extends Section, SectionDrawn {
  name: RoofingName.FlatRoofing;
}

const flatRoofing = ({ displayedSurface, drawnSurface }: FlatRoofingProps): FlatRoofingSection => ({
  // Section
  id: 'NOID',
  name: RoofingName.FlatRoofing,
  title: i18next.t('flatRoofing.title'),
  type: 'roofing',
  displayedSurface,
  // SectionDrawn
  surface: drawnSurface,
  color: ROOF_COLOR,
  icon: {
    type: 'object',
    iconName: RoofingName.FlatRoofing,
    color: ROOF_COLOR
  },
  ceilingHeight: 1
});

export default flatRoofing;
