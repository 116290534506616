import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectCurrentCaseGrossFloorSurfaceEff } from '../../../../store/selectors/granulometry/currentCase/grossFloorSurfaceEff.selector';
import { Input } from '../../../ui/input/Input';
import { roundForSurfacesInputs } from '../../../../utils/round/roundForSurfacesInputs';
import { selectCurrentCaseGranulometry } from '../../../../store/selectors/granulometry/currentCase/currentCaseGranulometry.selector';
import { selectProjectId } from '../../../../store/selectors/project';
import { selectCurrentCaseIdFromRoute } from '../../../../store/selectors/navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { selectCurrentCaseLabel } from '../../../../store/selectors/project/currentCaseLabel.selector';
import { goToCaseGranulometryEditGeometry } from '../../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditGeometry.action';

export const CaseGrossFloorSurfaceEffInput = () => {
  const projectId = useSelector(selectProjectId);
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const caseGranulometry = useSelector(selectCurrentCaseGranulometry);
  const grossFloorSurfaceEff = useSelector(selectCurrentCaseGrossFloorSurfaceEff);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  if (!projectId || !caseLabel || !caseId || !caseGranulometry) return null;

  const isInputModeCbs = caseGranulometry.initialSpecifications.projectSurfaceInputMode === 'cbs';

  return (
    <Input
      label={t('grossFloorSurfaceEff.label')}
      info={t('grossFloorSurfaceEff.infos')}
      name="caseGrossFloorSurfaceEff"
      suffix="m&#178;"
      style={{ marginTop: '3px' }}
      type={isInputModeCbs ? 'number' : 'text'}
      value={
        grossFloorSurfaceEff
          ? roundForSurfacesInputs(grossFloorSurfaceEff.value)
          : isInputModeCbs
          ? ''
          : 'ERR!'
      }
      asCustomValue={!isInputModeCbs}
      disabled={isInputModeCbs}
      handleClick={
        isInputModeCbs
          ? undefined
          : () => {
              dispatch(goToCaseGranulometryEditGeometry(projectId, caseId));
            }
      }
    />
  );
};
