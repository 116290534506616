import { ROTATE_LEVEL_MODE } from '../../mode/RotateLevelMode';
import { HARDROCKS_COLOR } from '../../../../constants/appConstants';
import { DRAW_COLORS } from './colors';
import { DRAW_HELPER_MOVING_FEATURE } from '../../drawHelper/drawHelper.model';

export const notMovingLayer = ['!=', 'user_type', DRAW_HELPER_MOVING_FEATURE];

export const polygonStyle = [
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      notMovingLayer,
      ['!=', 'user_type', 'overlay'],
      ['!=', 'mode', 'static']
    ],
    paint: {
      'fill-color': [
        'case',
        ['==', ['get', 'user_deactivate'], true],
        DRAW_COLORS.LEVEL_DEACTIVATE_COLOR.fill,
        ['==', ['get', 'user_isRotating'], true],
        DRAW_COLORS.ROTATE_MODE_COLOR.fill,
        ['==', ['get', 'user_basement'], true],
        DRAW_COLORS.LEVEL_BASEMENT_COLOR.fill,
        DRAW_COLORS.LEVEL_TOP_COLOR.fill
      ],
      'fill-outline-color': [
        'case',
        ['==', ['get', 'user_deactivate'], true],
        DRAW_COLORS.LEVEL_DEACTIVATE_COLOR.line,
        ['==', ['get', 'user_isRotating'], true],
        DRAW_COLORS.ROTATE_MODE_COLOR.line,
        ['==', ['get', 'user_basement'], true],
        DRAW_COLORS.LEVEL_BASEMENT_COLOR.fill,
        DRAW_COLORS.LEVEL_TOP_COLOR.fill
      ],
      'fill-opacity': 0.5
    }
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'user_type', 'overlay'],
      ['!=', 'mode', 'static']
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': [
        'case',
        ['==', ['get', 'user_deactivate'], true],
        DRAW_COLORS.LEVEL_DEACTIVATE_COLOR.line,
        ['==', ['get', 'user_isRotating'], true],
        DRAW_COLORS.ROTATE_MODE_COLOR.fill,
        ['==', ['get', 'user_basement'], true],
        DRAW_COLORS.LEVEL_BASEMENT_COLOR.line,
        DRAW_COLORS.LEVEL_TOP_COLOR.line
      ],
      'line-width': 2
    }
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', notMovingLayer, ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': [
        'case',
        ['==', ['get', 'mode'], ROTATE_LEVEL_MODE],
        DRAW_COLORS.ROTATE_MODE_COLOR.line,
        DRAW_COLORS.POLYGON_ACTIVE_COLOR.line
      ],
      'line-dasharray': [0.2, 2],
      'line-width': 2
    }
  },
  // OTHERS
  {
    id: 'gl-draw-polygon-rotate-point-icon',
    type: 'symbol',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'meta', 'midpoint'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static']
    ],
    layout: {
      'icon-image': 'rotate',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-rotation-alignment': 'map',
      'icon-rotate': ['get', 'heading']
    },
    paint: {
      'icon-opacity': 1.0,
      'icon-opacity-transition': {
        delay: 0,
        duration: 0
      }
    }
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-scale-icon',
    type: 'symbol',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
      ['has', 'heading']
    ],
    layout: {
      'icon-image': 'scale',
      'icon-allow-overlap': true,
      'icon-ignore-placement': true,
      'icon-rotation-alignment': 'map',
      'icon-rotate': ['get', 'heading']
    },
    paint: {
      'icon-opacity': 1.0,
      'icon-opacity-transition': {
        delay: 0,
        duration: 0
      }
    }
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', notMovingLayer, ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#404040',
      'line-width': 2
    }
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'active', 'true'],
      ['==', '$type', 'Polygon'],
      ['!=', 'user_type', 'overlay']
    ],
    paint: {
      'fill-color': DRAW_COLORS.POLYGON_ACTIVE_COLOR.fill,
      'fill-outline-color': DRAW_COLORS.POLYGON_ACTIVE_COLOR.line,
      'fill-opacity': 0.7
    }
  },
  {
    id: 'gl-draw-overlay-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['==', 'user_type', 'overlay'],
      ['!=', 'mode', 'static']
    ],
    paint: {
      'fill-color': '#3bb2d0',
      'fill-outline-color': '#3bb2d0',
      'fill-opacity': 0.01
    }
  },
  {
    id: 'gl-draw-overlay-polygon-fill-active',
    type: 'fill',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'active', 'true'],
      ['==', '$type', 'Polygon'],
      ['==', 'user_type', 'overlay']
    ],
    paint: {
      'fill-color': DRAW_COLORS.POLYGON_ACTIVE_COLOR.fill,
      'fill-outline-color': DRAW_COLORS.POLYGON_ACTIVE_COLOR.fill,
      'fill-opacity': 0.01
    }
  },
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', notMovingLayer, ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#404040',
      'fill-outline-color': '#404040',
      'fill-opacity': 0.1
    }
  }
];
