import { Action } from 'redux';
import { Nomenclature } from '../../domain/Nomenclature';

export const NOMENCLATURES_FETCHED = 'NOMENCLATURES_FETCHED';

export interface NomenclaturesFetchedAction extends Action {
  type: typeof NOMENCLATURES_FETCHED;
  payload: {
    nomenclatures: Nomenclature[];
  };
}

export const nomenclaturesFetched = (
  nomenclatures: Nomenclature[]
): NomenclaturesFetchedAction => ({
  type: NOMENCLATURES_FETCHED,
  payload: { nomenclatures }
});
