import { createSelector, Selector } from 'reselect';
import { State } from '../../../reducers';
import { selectCurrentSectionIdFromRoute } from '../../navigation/toolbox/sectionsPanel/sectionIdFromRoute.selector';
import { selectGranulometry } from '../current.selector';
import { LevelGranulometry } from '../../../../domain/granulometry/levels/LevelGranulometry';
import { Maybe } from '../../../../utils/Maybe';
import { getLevelFullFilledContent } from '../../../../domain/granulometry/levels/queries/content/getLevelFullFilledContent';

export const selectCurrentSectionLevelGranulometry: Selector<
  State,
  Maybe<LevelGranulometry>
> = createSelector(
  [selectGranulometry, selectCurrentSectionIdFromRoute],
  (granulometry, sectionId) => {
    if (granulometry && sectionId) {
      for (const buildingGranulometry of granulometry) {
        for (const caseGranulometry of buildingGranulometry.cases) {
          for (const levelGranulometry of caseGranulometry.levels) {
            if (
              getLevelFullFilledContent(levelGranulometry).find(
                (section) => section.id === sectionId
              )
            ) {
              return levelGranulometry;
            }
          }
        }
      }
    }
    return undefined;
  }
);
