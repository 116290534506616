import { LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { getTopLevelWallsLinear } from '../getTopLevelWallsLinear';
import { getTopLevelRealOutsideInsulatedWallsInternalLinear } from './getTopLevelRealOutsideInsulatedWallsInternalLinear';
import { getTopLevelRealInsideWallsLinear } from './getTopLevelRealInsideWallsLinear';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelRealInsideWallsLinearDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled
): string =>
  '<b>' +
  roundWith2Decimal(getTopLevelWallsLinear(caseGranulometry, levelGranulometry)) +
  ' mL</b> de linéaire total de voiles<br /><b>- ' +
  roundWith2Decimal(
    getTopLevelRealOutsideInsulatedWallsInternalLinear(caseGranulometry, levelGranulometry)
  ) +
  ' mL</b> de linéaire réel de voiles exposés<br /><b>= ' +
  roundWith2Decimal(getTopLevelRealInsideWallsLinear(caseGranulometry, levelGranulometry)) +
  ' mL</b>';
