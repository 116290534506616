import { CaseGranulometry } from '../../cases/CaseGranulometry';
import { LevelGranulometry } from '../../levels/LevelGranulometry';
import { createBasementLevelCarBoxInfra } from '../../levels/queries/basementLevels/sections/create/createBasementLevelCarBoxInfra';
import { getBasementLevelCarBoxesCount } from '../../levels/queries/basementLevels/counts/getBasementLevelCarBoxesCount';
import { CarBoxInfraSection } from '../../sections/parkingSections/carBoxInfra';

export const addCarBoxesInBasementLevelGranulometry = (
  levelGranulometry: LevelGranulometry,
  caseGranulometry: CaseGranulometry
): LevelGranulometry => {
  if (levelGranulometry.content) {
    let carBoxesToAdd: CarBoxInfraSection[] = [];
    for (let i = 0; i < getBasementLevelCarBoxesCount(levelGranulometry, caseGranulometry); i++) {
      const carBox = createBasementLevelCarBoxInfra(caseGranulometry);
      if (levelGranulometry.level < -1) {
        carBox.beamHeight = 0;
        carBox.beamWidth = 0;
      }
      carBoxesToAdd = [...carBoxesToAdd, carBox];
    }
    return { ...levelGranulometry, content: levelGranulometry.content.concat(carBoxesToAdd) };
  }
  return levelGranulometry;
};
