import { Action } from 'redux';

export const RENAME_PROJECT = 'RENAME_PROJECT';

export interface RenameProjectAction extends Action {
  type: typeof RENAME_PROJECT;
  payload: string;
}

export const renameProject = (newName: string): RenameProjectAction => ({
  type: RENAME_PROJECT,
  payload: newName
});
