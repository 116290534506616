import * as R from 'ramda';
import { ExistingProject, UnsavedProject } from './Project';

export const markSaved =
  (id: string) =>
  (project: UnsavedProject): ExistingProject =>
    R.pipe(
      R.assoc<true, 'existing'>('existing', true),
      R.assoc('id', id),
      R.assoc('createdAt', new Date().toISOString()),
      R.assoc('modifiedAt', new Date().toISOString())
    )(project) as ExistingProject;
