import { createSelector, Selector } from 'reselect';
import { selectPath } from '../../getPath.selector';
import { State } from '../../../../reducers';
import { caseGranulometryEditLevelGeometryRoute } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditLevelGeometryRoute';
import { RouteMatch } from '../../../../../routes/utils/matchesPath';
import { LayerType } from '../../../../../services/mapBox/layer/layer.model';
import { caseGranulometryEditGeometryRoute } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditGeometryRoute';

export interface InitializeLayersRouting {
  type: LayerType;
  routeMatch: RouteMatch<{ levelId?: string; projectId: string; caseId: string }>['params'];
}
export const selectInitializeLayersRouting: Selector<State, InitializeLayersRouting> =
  createSelector([selectPath], (path) => {
    const caseRoute = caseGranulometryEditGeometryRoute.matchesPath(path)?.params;
    const levelRoute = caseGranulometryEditLevelGeometryRoute.matchesPath(path)?.params;
    const type = levelRoute ? 'level' : caseRoute ? 'case' : 'building';
    const routeMatch = caseRoute || levelRoute;
    return {
      type,
      routeMatch
    };
  });
