/* eslint-disable no-console */
import * as Sentry from '@sentry/browser';
import { CaptureContext, Scope } from '@sentry/types';
import { User } from '../domain/User';
import { SeverityLevel } from '@sentry/types/types/severity';

export const BREADCRUMB_CATEGORY = {
  API: 'api',
  AUTH: 'auth'
};

if (process.env.SENTRY_ENABLED === 'true') {
  try {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      environment: process.env.GB_ENV,
      release: process.env.SENTRY_RELEASE
    });
  } catch (error) {
    console.warn('Could not init Sentry', error);
  }
}

type ScopeCallback = (scope: Scope) => void;
export const configureSentryScope = (scopeCallback: ScopeCallback): void => {
  if (process.env.SENTRY_ENABLED === 'true') {
    Sentry.configureScope(scopeCallback);
  }
};

export const setUser = ({ id, userName, email }: User): void => {
  if (process.env.SENTRY_ENABLED === 'true') {
    Sentry.setUser({ email, id, username: userName });
  }
};

const toConsole = (message: string, level?: SeverityLevel): void => {
  switch (level) {
    case 'fatal':
      console.error(message);
      break;
    case 'error':
      console.error(message);
      break;
    case 'warning':
      console.warn(message);
      break;
    case 'info':
      console.log(message);
      break;
    case 'debug':
      console.log(message);
      break;
    case 'log':
    default:
      console.log(message);
      break;
  }
};

export const captureMessage = (message: string, context?: CaptureContext): string | undefined => {
  if (process.env.SENTRY_ENABLED === 'true') {
    return Sentry.captureMessage(message, context);
  } else {
    toConsole(message);
  }
};

export const captureException = (
  exception: Error,
  context?: CaptureContext
): string | undefined => {
  if (process.env.SENTRY_ENABLED === 'true') {
    return Sentry.captureException(exception, context);
  } else {
    console.error(`CatchedError: ${exception.message}`);
  }
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const addBreadcrumb = (
  message: string,
  severity: SeverityLevel,
  category?: string,
  data?: Record<string, string>
): void => {
  if (process.env.SENTRY_ENABLED === 'true') {
    return Sentry.addBreadcrumb({
      message,
      level: severity,
      category,
      data
    });
  } else {
    toConsole(message, severity);
  }
};
