import { BuildingId } from '../../domain/specification/buildings/BuildingSpecification';

export const BUILDING_REMOVED = 'core/buildingRemoved';

export const buildingRemoved = (buildingId: BuildingId, redirect = true) => ({
  type: BUILDING_REMOVED,
  payload: {
    buildingId,
    redirect
  }
});

export type BuildingRemovedAction = ReturnType<typeof buildingRemoved>;
