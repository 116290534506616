import { LevelGeometry } from '../../services/mapBox/mapboxDraw/levelGeometry';

export const UPDATE_CASE_LEVEL_SPECIFICATION_GEOMETRY = 'UPDATE_CASE_LEVEL_SPECIFICATION_GEOMETRY';

export const updateCaseLevelSpecificationGeometry = (payload: {
  levelGeometry: LevelGeometry;
}) => ({
  type: UPDATE_CASE_LEVEL_SPECIFICATION_GEOMETRY,
  payload
});

export type UpdateCaseLevelSpecification = ReturnType<typeof updateCaseLevelSpecificationGeometry>;
