import { createSelector, Selector } from 'reselect';
import * as R from 'ramda';
import { selectCurrentCaseCustomDistribution } from '../../specification/currentCase/customDistribution.selector';
import { selectCurrentCaseLodgmentsCountByLevelAndType } from './lodgmentsCountByTopLevelAndType.selector';
import { CaseCustomDistributionByType } from '../../../../domain/specification/cases/CaseSurfaceSpecification';
import { State } from '../../../reducers';

type CurrentCaseLodgmentsCountByTypeWithCustomDistribution = {} | CaseCustomDistributionByType;

export const selectCurrentCaseLodgmentsCountByTypeWithCustomDistribution: Selector<
  State,
  CurrentCaseLodgmentsCountByTypeWithCustomDistribution
> = createSelector(
  [selectCurrentCaseLodgmentsCountByLevelAndType, selectCurrentCaseCustomDistribution],
  (lodgmentsCountByType, customDistribution): CaseCustomDistributionByType | {} =>
    R.pipe(
      R.valuesIn,
      R.reduce(R.mergeWith(R.add), {})
    )(R.mergeDeepRight(lodgmentsCountByType || {}, customDistribution))
);
