import { Surface } from '../../../../specification/Surface';
import { CaseSpecification } from '../../../../specification/cases/CaseSpecification';
import { DEFAULT_CBS_TO_SFS_RATIO } from '../../../../../constants/appConstants';

export const getCaseProjectedSfsFromSpecifications = (
  caseSpecification: CaseSpecification,
  projectedCuttedBuiltSurface: Surface
): Surface =>
  caseSpecification.surfaces.surfaceForSale ||
  new Surface(projectedCuttedBuiltSurface.value * DEFAULT_CBS_TO_SFS_RATIO);
