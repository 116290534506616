import sasInfra, { SasInfraSection } from '../../../../../sections/circulationSections/sasInfra';
import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createBasementLevelSasInfra = (
  caseGranulometry: CaseGranulometry
): SasInfraSection => {
  const surface = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'sasSurface'
  ) as number;
  const width = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'sasWidth'
  ) as number;
  return sasInfra({ surface, width });
};
