import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import { MapboxDrawOptions, MapMouseEvent } from '@mapbox/mapbox-gl-draw';
import { LevelGeometry } from '../mapboxDraw/levelGeometry';
import { FeatureTarget } from '../mapboxDraw/draw.repo';
import { RotateLevelMode } from './RotateLevelMode';
import { fireDrawEvents } from '../events';

export const getCustomModes = (modes: MapboxDraw.Modes): MapboxDrawOptions['modes'] => {
  const allModes = { ...modes, rotateLevelMode: RotateLevelMode };
  // const originalClickOnFeature = modes.simple_select.clickOnFeature;
  const originalSimpleSelectOnClick = allModes.simple_select.onClick;
  const originalDirectSelectOnClick = allModes.direct_select.onClick;

  allModes.simple_select.onClick = function (state: any, e: MapMouseEvent) {
    if (!e.featureTarget) {
      fireDrawEvents(this.map).layer.unSelectAll();
    }
    return originalSimpleSelectOnClick?.call(this, state, e);
  };
  allModes.direct_select.onClick = function (state: any, e: MapMouseEvent) {
    if (!e.featureTarget) {
      fireDrawEvents(this.map).layer.unSelectAll();
    }
    return originalDirectSelectOnClick?.call(this, state, e);
  };

  allModes.simple_select.clickOnFeature = function (
    state: object,
    e: { featureTarget: FeatureTarget }
  ) {
    if (e.featureTarget.properties.user_deactivate) return null;

    fireDrawEvents(this.map).layer.selectTree([e.featureTarget.properties.id]);
    // return originalClickOnFeature.call(this, state, e);
  };
  allModes.direct_select.onTrash = function (state: {
    feature: LevelGeometry;
    featureId: LevelGeometry['id'];
  }) {
    if (state.feature) {
      this.map.fire('draw.delete', {
        features: [state.feature]
      });
    }
    return state.feature;
  };

  return allModes;
};
