import { SectionWithTheoreticalSurface } from '../../SectionWithTheoreticalSurface';
import { LodgmentTypeSpecification } from '../../../../specification/lodgmentTypes/LodgmentTypeSpecification';

export const getSectionTheoreticalSurface = (
  section: SectionWithTheoreticalSurface | LodgmentTypeSpecification
): number =>
  section.theoreticalSurface
    ? section.theoreticalSurface
    : section.defaultTheoreticalSurface
    ? section.defaultTheoreticalSurface
    : 0;
