import { BuildingMapFocus } from '../../../domain/buildingMap/BuildingMapFocus';

export const BUILDING_MAP_FOCUSED = 'BUILDING_MAP_FOCUSED';

export const buildingMapFocused = (focus: BuildingMapFocus) => ({
  type: BUILDING_MAP_FOCUSED,
  payload: { focus }
});

export type BuildingMapFocusedAction = ReturnType<typeof buildingMapFocused>;
