import { RoomStaticElement } from '../../rooms/staticElements/RoomStaticElement';
import { LodgmentType } from '../../../specification/lodgmentTypes/LodgmentType';
import { RoomName } from '../../../specification/rooms/RoomSpecification';
import { balconyElements } from '../../rooms/staticElements/balconyElements';
import { bathroomElements } from '../../rooms/staticElements/bathroomElements';
import { bathroomTElements } from '../../rooms/staticElements/bathroomTElements';
import { bedroomElements } from '../../rooms/staticElements/bedroomElements';
import { clearanceElements } from '../../rooms/staticElements/clearanceElements';
import { entranceElements } from '../../rooms/staticElements/entranceElements';
import { livingRoomKitchenCElements } from '../../rooms/staticElements/livingRoomKitchenCElements';
import { livingRoomKitchenElements } from '../../rooms/staticElements/livingRoomKitchenElements';
import { livingRoomKitchenKElements } from '../../rooms/staticElements/livingRoomKitchenKElements';
import { showerRoomElements } from '../../rooms/staticElements/showerRoomElements';
import { toiletElements } from '../../rooms/staticElements/toiletElements';

export type LodgmentStaticElements = { [roomName in RoomName]?: RoomStaticElement[] };
export type LodgmentStaticElementsList = {
  [lodgmentType in LodgmentType]: LodgmentStaticElements;
};

export const lodgmentStaticElements: LodgmentStaticElementsList = {
  t1: {
    balcony: balconyElements,
    livingRoomKitchenK: livingRoomKitchenKElements,
    bathroom: bathroomTElements
  },
  t2c: {
    balcony: balconyElements,
    livingRoomKitchenC: livingRoomKitchenCElements,
    bathroom: bathroomTElements,
    bedroomPMR: bedroomElements
  },
  t2: {
    balcony: balconyElements,
    livingRoomKitchenC: livingRoomKitchenCElements,
    clearance: clearanceElements,
    toilet: toiletElements,
    bathroom: bathroomElements,
    bedroomPMR: bedroomElements
  },
  t3c: {
    balcony: balconyElements,
    livingRoomKitchenC: livingRoomKitchenCElements,
    clearance: clearanceElements,
    toilet: toiletElements,
    bathroom: bathroomElements,
    bedroomPMR: bedroomElements,
    bedroom2: bedroomElements
  },
  t3: {
    balcony: balconyElements,
    entrance: entranceElements,
    livingRoomKitchen: livingRoomKitchenElements,
    clearance: clearanceElements,
    toilet: toiletElements,
    bathroom: bathroomElements,
    bedroomPMR: bedroomElements,
    bedroom2: bedroomElements
  },
  t4c: {
    balcony: balconyElements,
    livingRoomKitchenC: livingRoomKitchenCElements,
    clearance: clearanceElements,
    toilet: toiletElements,
    bathroom: bathroomElements,
    bedroomPMRC: bedroomElements,
    bedroom2: bedroomElements,
    bedroom3: bedroomElements,
    showerRoom: showerRoomElements
  },
  t4: {
    balcony: balconyElements,
    entrance: entranceElements,
    livingRoomKitchen: livingRoomKitchenElements,
    clearance: clearanceElements,
    toilet: toiletElements,
    bathroom: bathroomElements,
    bedroomPMRC: bedroomElements,
    bedroom2: bedroomElements,
    bedroom3: bedroomElements,
    showerRoom: showerRoomElements
  },
  t5: {
    balcony: balconyElements,
    entrance: entranceElements,
    livingRoomKitchen: livingRoomKitchenElements,
    clearance: clearanceElements,
    toilet: toiletElements,
    bathroom: bathroomElements,
    bedroomPMRC: bedroomElements,
    bedroom2: bedroomElements,
    bedroom3: bedroomElements,
    bedroom4: bedroomElements,
    showerRoom: showerRoomElements
  }
};
