import { CountByLodgmentType } from './redistributeCaseWithCustomDistribution';
import { CaseGranulometry } from '../../cases/CaseGranulometry';
import * as R from 'ramda';
import { LevelGranulometry } from '../../levels/LevelGranulometry';
import { getLevelDistributionFromDistribution } from './getLevelDistributionFromDistribution';
import { getCaseLevels } from '../../cases/queries/levels/getCaseLevels';

export const getCaseDistributionWithCustomDistribution = (
  caseGranulometry: CaseGranulometry
): CountByLodgmentType =>
  R.pipe(
    R.reduce(
      (acc, levelGranulometry: LevelGranulometry) => [
        ...acc,
        getLevelDistributionFromDistribution(caseGranulometry, levelGranulometry)
      ],
      [] as CountByLodgmentType[]
    ),
    R.reduce(R.mergeWith(R.add), {})
  )(getCaseLevels(caseGranulometry));
