import { isSectionAVerticalCirculation } from '../../../sections/VerticalCirculationSection';
import { LevelGranulometry } from '../../LevelGranulometry';
import { getLevelFullFilledContent } from '../content/getLevelFullFilledContent';

export const getLevelVerticalCirculationSectionsSurface = (
  levelGranulometry: LevelGranulometry
): number =>
  getLevelFullFilledContent(levelGranulometry).reduce(
    (surface, section) =>
      isSectionAVerticalCirculation(section) ? surface + section.displayedSurface : surface,
    0
  ) || 0;
