import { LevelSpecification } from '../../LevelSpecification';
import i18next from 'i18next';
import { isGroundLevel } from '../is/isGroundLevel';
import { isTopLevel } from '../is/isTopLevel';

export const getLabel = (levelSpecification: LevelSpecification): string =>
  isTopLevel(levelSpecification)
    ? isGroundLevel(levelSpecification)
      ? i18next.t('GF')
      : i18next.t('G+') + levelSpecification.level
    : i18next.t('G-') + levelSpecification.level * -1;
