import { LodgmentType } from '../../domain/specification/lodgmentTypes/LodgmentType';
import { BalconySpecification } from '../../domain/specification/rooms/BalconySpecification';
import { LivingRoomKitchenSpecification } from '../../domain/specification/rooms/LivingRoomKitchenSpecification';
import { BasicRoomSpecification } from '../../domain/specification/rooms/BasicRoomSpecification';

export const SET_LODGMENT_TYPE_ROOM = 'SET_LODGMENT_TYPE_ROOM';

export const setLodgmentTypeRoom = (
  caseId: string,
  lodgmentType: LodgmentType,
  roomIndex: number,
  roomSpecification:
    | Partial<BalconySpecification>
    | Partial<LivingRoomKitchenSpecification>
    | Partial<BasicRoomSpecification>
) => ({
  type: SET_LODGMENT_TYPE_ROOM,
  payload: {
    caseId,
    lodgmentType,
    roomIndex,
    roomSpecification
  }
});

export type SetLodgmentTypeRoomAction = ReturnType<typeof setLodgmentTypeRoom>;
