import { createSelector, Selector } from 'reselect';
import * as R from 'ramda';
import { Maybe } from '../../../utils/Maybe';
import { State } from '../../../store/reducers';
import { BuildingSpecification } from '../../../domain/specification/buildings/BuildingSpecification';
import { selectProject } from '../project';

export const selectFirstBuilding: Selector<State, Maybe<BuildingSpecification>> = createSelector(
  [selectProject],
  R.path(['buildings', 0])
);
