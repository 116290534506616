import { get } from './admin.api';
import { User } from '../../domain/User';
import { AuthToken } from '../../AuthToken';

export const deleteProject = async (
  projectId: string,
  userId?: User['id'],
  userToken?: AuthToken
): Promise<void> => {
  await get(
    `/Projects/Delete/${userId || 'noUserId'}/${userToken || 'noUserToken'}/${projectId}`,
    userToken || 'noUserToken'
  );
};
