import { getSectionWallsLinear } from '../../getSectionWallsLinear';
import { getSectionTheoreticalOutsideWallsLinear } from '../outsideWalls/getSectionTheoreticalOutsideWallsLinear';
import { SectionWithExposureRate } from '../../../../SectionWithExposureRate';
import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../../../levels/LevelGranulometry';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getSectionTheoreticalInsideWallsLinear = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithBearingRelativeSections | LevelGranulometry,
  section: SectionWithExposureRate
): number => {
  const wallsLinear = getSectionWallsLinear(caseGranulometry, levelGranulometry, section);
  const lodgmentsOutsideWallsLinear = getSectionTheoreticalOutsideWallsLinear(
    caseGranulometry,
    levelGranulometry,
    section
  );
  return wallsLinear - lodgmentsOutsideWallsLinear;
};
