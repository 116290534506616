/* fr : retourne les données de surface de planché réelles forcées des niveaux d'une cage */
import { CaseProjection } from '../../../../../projection/cases/CaseProjection';

export type LevelsForcedRealBuiltSurfaces = {
  [key: number]: number;
  totalSurface: number;
  count: number;
};

export const getCaseDataLevelsForcedRealBuiltSurfaces = (
  caseProjection: CaseProjection
): LevelsForcedRealBuiltSurfaces => {
  const levelsForcedRealBuiltSurfaces: LevelsForcedRealBuiltSurfaces = {
    totalSurface: 0,
    count: 0
  };
  caseProjection.projectedTopLevels.forEach((level) => {
    if (level.realBuiltSurface) {
      levelsForcedRealBuiltSurfaces[level.level] = level.realBuiltSurface;
      levelsForcedRealBuiltSurfaces.totalSurface += level.realBuiltSurface;
      levelsForcedRealBuiltSurfaces.count++;
    }
  });
  return levelsForcedRealBuiltSurfaces;
};
