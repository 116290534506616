import { Epic, ofType } from 'redux-observable';
import { Action } from 'redux';
import { defer, from } from 'rxjs';
import {
  catchError,
  delay,
  first,
  map,
  retryWhen,
  startWith,
  switchMap,
  take,
  withLatestFrom
} from 'rxjs/operators';
import { State } from '../reducers';
import { fetchNomenclatures } from '../../api/v1/fetchNomenclatures.api';
import { selectUserId } from '../selectors/user/userId.selector';
import { selectUserToken } from '../selectors/user/userToken.selector';
import { nomenclaturesFetched } from '../actions/nomenclaturesFetched.action';
import { User } from '../../domain/User';
import { AuthToken } from '../../AuthToken';
import { selectIsLoggedIn } from '../selectors/user/isLoggedIn.selector';
import userState from '../reducers/user.slice';

type ActionWithState = [Action, State];

export const fetchNomenclaturesOnLoadEpic: Epic<Action, Action, State> = (actions$, state$) =>
  actions$.pipe(
    withLatestFrom(state$),
    first(([, state]) => selectIsLoggedIn(state)),
    switchMap(([, state]: ActionWithState) => {
      const userId = selectUserId(state) as User['id'];
      const userToken = selectUserToken(state) as AuthToken;

      return defer(() => fetchNomenclatures(userId, userToken)).pipe(
        map(nomenclaturesFetched),
        catchError(() => {
          // if (error instanceof ApiAuthError || error instanceof ApiRedirectError) {
          return actions$.pipe(
            ofType(userState.actions.authenticated.type),
            withLatestFrom(state$),
            switchMap(([action, stateAfterLogin]: ActionWithState) => {
              const newUserId = selectUserId(stateAfterLogin) as User['id'];
              const newUserToken = selectUserToken(stateAfterLogin) as AuthToken;
              // type AuthenticatedAction = userState.actions.authenticated;
              // console.log(
              //  `Authenticated with ${newUserToken} (${
              //    (action as AuthenticatedAction).payload.token
              // })`
              // );
              return from(fetchNomenclatures(newUserId, newUserToken)).pipe(
                map(nomenclaturesFetched)
              );
            }),
            startWith(userState.actions.sessionExpired())
          );
          // }
          //
          // return throwError(error);
        }),
        retryWhen((errors$) => errors$.pipe(delay(5000), take(10)))
      );
    })
  );
