import { getCaseInitialLodgmentsData } from './getCaseInitialLodgmentsData';
import { getInitialFilledLevelSpecifications } from './getInitialFilledLevelSpecifications';
import { LevelSFSAdjusment } from './fillCaseLevels';
import { LodgmentType } from '../../../../specification/lodgmentTypes/LodgmentType';
import { LodgmentSection } from '../../../../granulometry/sections/lodgmentSections/LodgmentSection';
import { LodgmentTypeSpecification } from '../../../../specification/lodgmentTypes/LodgmentTypeSpecification';
import { getSectionTheoreticalSurface } from '../../../../granulometry/sections/queries/surfaces/getSectionTheoreticalSurface';
import { roundForSurfacesInputs } from '../../../../../utils/round/roundForSurfacesInputs';
import { TopLevelSpecification } from '../../../../specification/levels/TopLevelSpecification';
import { CaseProjection } from '../../CaseProjection';
import { BasementLevelSpecification } from '../../../../specification/levels/BasementLevelSpecification';

export interface FilledLevelSpecification
  extends TopLevelSpecification,
    BasementLevelSpecification {
  surfaceForSale?: number;
  remainingSurfaceForSale: number;
  // filledSurface: number;
  lodgmentCount: number;
  lodgments: { [key in LodgmentType]: (LodgmentSection & LodgmentTypeSpecification)[] };
  parkingRate?: number;
}

/* Get the filled levels data in the given case */
export const getFilledLevelSpecifications = (
  caseProjection: CaseProjection,
  levelsSFSAdjustments?: LevelSFSAdjusment[]
): FilledLevelSpecification[] => {
  const filledLodgmentsData = getCaseInitialLodgmentsData(caseProjection);
  const filledLevelSpecifications = getInitialFilledLevelSpecifications(
    caseProjection,
    levelsSFSAdjustments
  ) as FilledLevelSpecification[];

  // For each lodgment type
  for (let h = 0; h < filledLodgmentsData.length; h++) {
    if (filledLodgmentsData[h].count !== 0) {
      const lodgmentType = filledLodgmentsData[h].type;
      // console.log('Fill case with lodgment type :', lodgmentType);
      const maxTryLoop = 20; // TODO : DURTY !
      for (let i = 0; i <= maxTryLoop; i++) {
        if (filledLodgmentsData[h].remaining > 0) {
          // For each level
          for (let j = 0; j < filledLevelSpecifications.length; j++) {
            // We skip basement levels
            if (filledLevelSpecifications[j].level < 0) continue;
            // console.log('Level :', filledLevelsData[j].level);
            const lodgmentDefaultData = { ...filledLodgmentsData[h].defaultData };
            const lodgmentTheoreticalSurface = getSectionTheoreticalSurface(lodgmentDefaultData);
            if (
              lodgmentTheoreticalSurface <=
              roundForSurfacesInputs(filledLevelSpecifications[j].remainingSurfaceForSale)
            ) {
              // console.log('Add a ' + lodgmentType + '!');
              // Add default theoreticalSurface entry in cloned default data
              // lodgmentDefaultData.theoreticalSurface = lodgmentDefaultData.surface;
              // Add the lodgment in level lodgments data
              filledLevelSpecifications[j].lodgments[lodgmentType].push(lodgmentDefaultData);
              // Remove lodgment surface form the current level surface left
              filledLevelSpecifications[j].remainingSurfaceForSale -= lodgmentTheoreticalSurface;
              // console.log(
              //  'Remaining surface for sale :',
              //  filledLevelsData[j].remainingSurfaceForSale.toFixed(2) + 'm2'
              // );
              // Increment lodgment surface to the current level surface filled value
              // filledLevelSpecifications[j].filledSurface += lodgmentTheoreticalSurface;
              // Increment lodgment count
              filledLevelSpecifications[j].lodgmentCount++;
              // Remove 1 from remaining lodgment
              filledLodgmentsData[h].remaining--;
              // console.log('Remaining ' + lodgmentType + ':', filledLodgmentsData[h].remaining);
              if (filledLodgmentsData[h].remaining === 0) {
                // console.log('No more remaining ' + lodgmentType + '!');
                break;
              }
            }
          }
        }
      }
    }
  }

  return filledLevelSpecifications;
};
