import * as R from 'ramda';
import { LevelGranulometry } from '../../../LevelGranulometry';
import { Section } from '../../../../sections/Section';
import {
  getParkingName,
  ParkingSection,
  ParkingName,
  filterParkings
} from '../../../../sections/parkingSections/ParkingSection';
import { getLevelSections } from '../../sections/getLevelSections';

export type ParkingsCountByType = { [parkingType in ParkingName]: number };
type ReduceByParkingType = (parkings: ParkingSection[]) => ParkingsCountByType;

type GetBasementLevelParkingsCountByType = (level: LevelGranulometry) => ParkingsCountByType;
export const getLevelParkingsCountByType: GetBasementLevelParkingsCountByType = R.pipe<
  [LevelGranulometry],
  Section[],
  ParkingSection[],
  ParkingsCountByType
>(
  getLevelSections,
  filterParkings,
  R.reduceBy(R.inc, 0, getParkingName) as unknown as ReduceByParkingType
);
