import * as R from 'ramda';
import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelInsulatedFacadeInternalLinears } from '../getTopLevelInsulatedFacadeInternalLinears';

export const getTopLevelRealOutsideInsulatedWallsInternalLinear = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled
): number =>
  R.sum(
    getTopLevelInsulatedFacadeInternalLinears(
      caseGranulometry,
      levelGranulometry as LevelGranulometry
    )
  );
