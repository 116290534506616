import { ApiError } from './apiError';

export class ApiRedirectError extends ApiError {
  private readonly _location: string;

  constructor(requestedUrl: string, statusCode: number, statusText: string, location: string) {
    super(
      `Received redirect (${statusCode}) to ${location} when requested ${requestedUrl}`,
      requestedUrl,
      statusCode,
      statusText
    );
    this._location = location;
  }

  public isApiRedirectError = true;
}
