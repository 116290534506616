import { getSectionTheoreticalOutsideWallsLinear } from './getSectionTheoreticalOutsideWallsLinear';
import { getSectionOutsideWallsThickness } from '../../wallsThickness/getSectionOutsideWallsThickness';
import {
  LevelGranulometry,
  LevelGranulometryWithLodgments
} from '../../../../../levels/LevelGranulometry';
import { SectionWithExposureRate } from '../../../../SectionWithExposureRate';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getSectionTheoreticalOutsideWallsSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithLodgments | LevelGranulometry,
  lodgmentSection: SectionWithExposureRate
): number => {
  const outsideWallsLinear = getSectionTheoreticalOutsideWallsLinear(
    caseGranulometry,
    levelGranulometry,
    lodgmentSection
  );
  const outsideWallsThickness = getSectionOutsideWallsThickness(caseGranulometry);
  return outsideWallsLinear * outsideWallsThickness;
};
