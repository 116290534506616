import i18next from 'i18next';
import { BasicRoomSpecification } from '../../specification/rooms/BasicRoomSpecification';

const SHOWER_ROOM_LENGTH = 2.2;
const SHOWER_ROOM_WIDTH = 1;

const showerRoom = (): BasicRoomSpecification =>
  ({
    name: 'showerRoom',
    title: i18next.t('Shower room'),
    defaultLength: SHOWER_ROOM_LENGTH,
    width: undefined,
    defaultWidth: SHOWER_ROOM_WIDTH,
    surface: undefined,
    icon: {
      type: 'object',
      iconName: 'showerRoom',
      strokeStyle: 'opened'
    }
  } as const);

export default showerRoom;
