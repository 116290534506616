import * as R from 'ramda';
import { LevelGranulometry } from '../../../LevelGranulometry';
import { getLevelSections } from '../../sections/getLevelSections';
import {
  filterParkings,
  ParkingSection
} from '../../../../sections/parkingSections/ParkingSection';
import { Section } from '../../../../sections/Section';

type GetBasementLevelParkingsCount = (level: LevelGranulometry) => number;
export const getLevelParkingsCount: GetBasementLevelParkingsCount = R.pipe<
  [LevelGranulometry],
  Section[],
  ParkingSection[],
  number
>(getLevelSections, filterParkings, R.reduce(R.inc, 0));
