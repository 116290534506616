import { defer, EMPTY, from, of, timer } from 'rxjs';
import { Action } from 'redux';
import {
  catchError,
  delayWhen,
  filter,
  map,
  retryWhen,
  switchMap,
  take,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { addBreadcrumb, BREADCRUMB_CATEGORY, captureException } from '../../infra/sentry';
import { fetchProjects } from '../../api/v1/fetchProjects.api';
import { matchesOpenProject } from '../../routes/utils/matches/matchesOpenProject';
import { matchesDuplicateProject } from '../../routes/utils/matches/matchesDuplicateProject';
import { State } from '../reducers';
import userState from '../reducers/user.slice';
import { selectUserToken } from '../selectors/user/userToken.selector';
import { selectUserId } from '../selectors/user/userId.selector';
import { projectsFetched } from '../actions/projectsFetched.action';
import { ApiAuthError } from '../../api/v1/errors/auth.error';
import { ApiRedirectError } from '../../api/v1/errors/redirect.error';

type LocationActionWithState = [LocationChangeAction, State];

export const fetchProjectsEpic: Epic<Action, Action, State> = (actions$, state$) =>
  actions$.pipe(
    ofType(LOCATION_CHANGE),
    filter(
      ({ payload: { location } }: LocationChangeAction) =>
        matchesOpenProject(location.pathname).matches ||
        matchesDuplicateProject(location.pathname).matches
    ),
    withLatestFrom(state$),
    switchMap(([, state]: LocationActionWithState) => {
      const userId = selectUserId(state);
      const userToken = selectUserToken(state);

      return from(defer(() => fetchProjects(userId, userToken))).pipe(
        map(projectsFetched),
        catchError((error: Error) => {
          if (error instanceof ApiAuthError || error instanceof ApiRedirectError)
            return of(userState.actions.sessionExpired());

          throw error;
        }),
        retryWhen((errors) => {
          return errors.pipe(
            tap((error: Error) => {
              addBreadcrumb(
                `Could not fetch projects (${error.message})`,
                'error',
                BREADCRUMB_CATEGORY.API
              );
            }),
            take(2),
            delayWhen(() => timer(2000)),
            tap(() => {
              addBreadcrumb(`Retrying to fetch projects …`, 'log', BREADCRUMB_CATEGORY.API);
            })
          );
        }),
        catchError((error) => {
          captureException(error);
          alert(
            'Impossible de parcourir vos projets.' +
              'Notre équipe a été informée du problème. Veuillez réessayer ultérieurement.'
          );
          return EMPTY;
        })
      );
    })
  );
