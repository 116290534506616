import { Report } from '../../../../domain/report/ReportEntry';
import { CaseGranulometry } from '../../../../domain/granulometry/cases/CaseGranulometry';
import { roundWith2Decimal } from '../../../../utils/round/roundWith2Decimal';
import { getNomenclatureElementTitleFromRef } from '../../../../domain/report/getNomenclatureElementTitleFromRef';
import { Nomenclature } from '../../../../domain/Nomenclature';

export interface ComEthJsonFeatureProduct {
  gobuildProductInstanceId: string;
  nomenclatureRef: string;
  nomenclatureTitle: string;
  product: string;
  quantity: number;
  unit: string;
  inesId: string;
  level: string;
  section: string;
  room: string;
}

export const getComEthJsonFeatureProducts = (
  caseGranulometry: CaseGranulometry,
  report: Report,
  nomenclature: Nomenclature
): ComEthJsonFeatureProduct[] =>
  report.reduce(
    (
      buildingProducts,
      { id, caseId, refN2, level, section, room, product, co2Quantity, unit, co2Id }
    ) => {
      return caseId === caseGranulometry.id && co2Quantity > 0 && refN2 !== ''
        ? ([
            ...buildingProducts,
            {
              gobuildProductInstanceId: id,
              nomenclatureRef: refN2,
              nomenclatureTitle: getNomenclatureElementTitleFromRef(nomenclature, refN2),
              product,
              quantity: roundWith2Decimal(co2Quantity),
              unit,
              inesId: co2Id,
              level,
              section,
              room
            }
          ] as ComEthJsonFeatureProduct[])
        : buildingProducts;
    },
    [] as ComEthJsonFeatureProduct[]
  );
