import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { Card } from '../../../../ui/Card';
import { useParams } from 'react-router';
import { IconObject } from '../../../../ui/Icons/iconObject';
import { RoofingType } from '../../../../../domain/specification/cases/RoofingType';
import { ROOF_COLOR } from '../../../../../constants/appConstants';
import { Option, Select } from '../../../../ui/Select';
import { caseSpecificationChanged } from '../../../../../store/actions/changeCaseSpecification.action';
import { selectCurrentCaseRoofingType } from '../../../../../store/selectors/specification/currentCase/caseRoofingType.selector';
import { CaseGranulometryEditFifthFacadeRouteParams } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditFifthFacadeRoute';

export const RoofingTypes = () => {
  const { caseId } = useParams<CaseGranulometryEditFifthFacadeRouteParams>();
  const roofingType = useSelector(selectCurrentCaseRoofingType) as RoofingType;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectRoofingTypeOptions = [
    { label: t('Tiles'), value: 'tileRoofing' },
    { label: t('Flat'), value: 'flatRoofing' }
  ];

  const selectedRoofingType = selectRoofingTypeOptions.find((props) => props.value === roofingType);

  if (!caseId || !selectedRoofingType) return null;

  return (
    <Card>
      <SpecialLabel label={t('Roofing')} />
      <label htmlFor="naming">{t('Roofing type')}</label>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
        <IconObject
          type="object"
          iconName={selectedRoofingType.value as RoofingType}
          color={ROOF_COLOR}
          clickable={false}
        />
        <Select
          id={`case${caseId}RoofingType`}
          options={selectRoofingTypeOptions}
          handleChange={(props: Option) => {
            dispatch(caseSpecificationChanged(caseId, { roofingType: props.value as RoofingType }));
          }}
          value={selectedRoofingType}
          style={{ marginLeft: '6px', flexBasis: '150px' }}
        />
      </div>
    </Card>
  );
};
