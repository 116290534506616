import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelInternalInsulationThickness = (
  caseGranulometry: CaseGranulometry
): number => {
  const internalInsulationRate = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'internalInsulationRate'
  ) as number;
  const internalInsulationThickness = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'internalInsulationThickness'
  ) as number;
  return internalInsulationRate * internalInsulationThickness;
};
