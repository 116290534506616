import { LINEAR_METER_SCALE } from '../../constants/appConstants';
import { CaseGranulometry } from '../../domain/granulometry/cases/CaseGranulometry';

export const setAllBasementBasementHeight = (caseObj: CaseGranulometry): number => {
  let allBasementsHeight = 0;
  caseObj.levels.forEach((level: any, index: number) => {
    if (level.level < 0) {
      allBasementsHeight += level.ceilingHeight * LINEAR_METER_SCALE;
      if (caseObj.levels[index + 1]) {
        allBasementsHeight += level.floorThickness * LINEAR_METER_SCALE;
      }
    } else if (level.level === 0) {
      allBasementsHeight += level.floorThickness * LINEAR_METER_SCALE;
    }
  });
  return allBasementsHeight;
};
