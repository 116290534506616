import { matchesPath } from '../../utils/matchesPath';
import { orCreate } from '../../utils/orCreate';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type ProjectDetailsEditGeometryRouteParams = { projectId: string };
export const projectDetailsEditGeometryRoute: RouteWithMakePath<ProjectDetailsEditGeometryRouteParams> =
  {
    path: `/project/:projectId/details/edit/geometry`,
    makePath: ({ projectId }) => `/project/${orCreate(projectId)}/details/edit/geometry`,
    matchesPath: matchesPath<ProjectDetailsEditGeometryRouteParams>(
      /\/project\/([^/]+)\/details\/edit\/geometry/,
      {
        projectId: 1
      }
    )
  };
