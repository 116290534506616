import * as React from 'react';
import classNames from 'classnames';
import { IconObject } from '../../../ui/Icons/iconObject';
import { Panel } from '../../../../interfaces/Panel';
import { useSelector } from 'react-redux';
import { isProjectInputModeGfsEff } from '../../../../domain/specification/project/queries/is/isProjectInputModeGfsEff';
import { selectProject } from '../../../../store/selectors/project';

interface BareToolBoxMenuProps {
  currentPanel?: Panel;
  disabled: boolean;
  goToProject: () => void;
  goToProjectDetails: () => void;
  goToFirstBuilding: () => void;
  goToFirstCase: () => void;
  goToSections: () => void;
  goToSmartRequests: () => void;
  goToDeliverables: () => void;
}

export const BareToolBoxMenu = ({
  currentPanel,
  disabled,
  goToProject,
  goToProjectDetails,
  goToFirstBuilding,
  goToFirstCase,
  goToSections,
  goToSmartRequests,
  goToDeliverables
}: BareToolBoxMenuProps) => {
  const project = useSelector(selectProject);
  return (
    <div className="icon-menu">
      <div className="icon-menu-inner">
        <div
          className={classNames('icon-menu-item', {
            'icon-menu-item--enabled': currentPanel === Panel.ProjectDetails
          })}>
          <IconObject
            disabled={disabled && currentPanel !== Panel.ProjectDetails}
            iconName="lands"
            id="lands"
            type="menu"
            customText={isProjectInputModeGfsEff(project) ? 'SHOB' : 'PC'}
            customTextPosition={'bottom right'}
            customCss={
              '.iconObjectCustomText { overflow: visible; top: initial; bottom: -2px; right: -5px; left: initial; } .iconFont { padding: 2px 2px 0 2px; background: white; width: max-content;  }'
            }
            onClick={currentPanel !== Panel.ProjectDetails ? goToProjectDetails : goToProject}
          />
        </div>
        <div
          className={classNames('icon-menu-item', {
            'icon-menu-item--enabled': currentPanel === Panel.Buildings
          })}>
          <IconObject
            disabled={disabled && currentPanel !== Panel.Buildings}
            iconName="buildings"
            id="buildings"
            type="menu"
            onClick={currentPanel !== Panel.Buildings ? goToFirstBuilding : goToProject}
          />
        </div>
        <div
          className={classNames('icon-menu-item', {
            'icon-menu-item--enabled': currentPanel === Panel.Cases
          })}>
          <IconObject
            disabled={disabled && currentPanel !== Panel.Cases}
            iconName="cases"
            id="cases"
            type="menu"
            onClick={currentPanel !== Panel.Cases ? goToFirstCase : goToProject}
          />
        </div>
        <div
          className={classNames('icon-menu-item', {
            'icon-menu-item--enabled': currentPanel === Panel.Sections
          })}>
          <IconObject
            disabled={disabled && currentPanel !== Panel.Sections}
            iconName="sections"
            id="sections"
            type="menu"
            onClick={currentPanel !== Panel.Sections ? goToSections : goToProject}
          />
        </div>
        <div
          className={classNames('icon-menu-item', {
            'icon-menu-item--enabled': currentPanel === Panel.SmartRequests
          })}>
          <IconObject
            disabled={disabled && currentPanel !== Panel.SmartRequests}
            iconName="smartRequests"
            id="smartRequests"
            type="menu"
            onClick={currentPanel !== Panel.SmartRequests ? goToSmartRequests : goToProject}
          />
        </div>
        <div
          className={classNames('icon-menu-item', {
            'icon-menu-item--enabled': currentPanel === Panel.Deliverables
          })}>
          <IconObject
            disabled={disabled && currentPanel !== Panel.Deliverables}
            iconName="deliverables"
            id="deliverables"
            type="menu"
            onClick={currentPanel !== Panel.Deliverables ? goToDeliverables : goToProject}
          />
        </div>
      </div>
    </div>
  );
};
