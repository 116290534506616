import * as R from 'ramda';

export class Surface {
  readonly value: number;

  constructor(value: number) {
    if (typeof value !== 'number' || isNaN(value) /* || value < 0 */)
      throw new Error(`Incorrect value "${value}" for a surface`);
    this.value = value;
  }

  static EMPTY = new Surface(0);

  static fromNumber(surface: number) {
    return new Surface(surface);
  }

  static sum(surfaces: Surface[]): Surface {
    return R.compose<[Surface[]], number[], number, Surface>(
      Surface.fromNumber,
      R.sum,
      R.map((surface: Surface) => surface.value)
    )(surfaces);
  }
}
