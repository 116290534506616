import { ExistingProject } from '../../domain/project/Project';

export const COPY_PROJECT = 'COPY_PROJECT';

export const copyProject = (sourceProjectId: ExistingProject['id'], saveAsName: string) => ({
  type: COPY_PROJECT,
  payload: { sourceProjectId, saveAsName }
});

export type CopyProjectAction = ReturnType<typeof copyProject>;
