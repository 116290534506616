import {
  DEFAULT_ZOOM_VALUE,
  MAXIMUM_ZOOM_VALUE,
  MINIMUM_ZOOM_VALUE
} from '../../constants/appConstants';
import { getHeight } from './getHeight';
import { getWidth } from './getWidth';
import { getWindowHeight } from './getWindowHeight';
import { getWindowWidth } from './getWindowWidth';
import { roundWith2Decimal } from '../round/roundWith2Decimal';

export const getAdjustedZoomRate = (elementSelector: string) => {
  const targetElement = document.querySelector(elementSelector);
  if (targetElement) {
    // Get the size of element or group of elements
    const elementHeight = getHeight(targetElement);
    const elementWidth = getWidth(targetElement);
    // Zoom rate adjustment
    const elementMarginTopBottom = 130; // px
    const windowCroppedHeight = getWindowHeight() - elementMarginTopBottom * 2;
    const elementMarginLeftRight = 130; // px
    const windowCroppedWidth = getWindowWidth() - elementMarginLeftRight * 2;
    const ajustedZoomValueForHeight = 1 / (elementHeight / windowCroppedHeight);
    const ajustedZoomValueForWidth = 1 / (elementWidth / windowCroppedWidth);
    let ajustedZoomValue = roundWith2Decimal(
      ajustedZoomValueForHeight >= ajustedZoomValueForWidth
        ? ajustedZoomValueForWidth
        : ajustedZoomValueForHeight
    );
    if (ajustedZoomValue < MINIMUM_ZOOM_VALUE) {
      ajustedZoomValue = MINIMUM_ZOOM_VALUE;
    } else if (ajustedZoomValue > MAXIMUM_ZOOM_VALUE) {
      ajustedZoomValue = MAXIMUM_ZOOM_VALUE;
    }
    return ajustedZoomValue;
  } else {
    return DEFAULT_ZOOM_VALUE;
  }
};
