import * as React from 'react';
import { EnlargeGranulometryButton } from './EnlargeGranulometryButton';

interface Column1Props {
  isToolboxEnlarged: boolean;
  setIsToolboxEnlarged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Column1 = ({ isToolboxEnlarged, setIsToolboxEnlarged }: Column1Props) => {
  return (
    <div className="column-1">
      <div className="cell">
        <EnlargeGranulometryButton
          isToolboxEnlarged={isToolboxEnlarged}
          setIsToolboxEnlarged={setIsToolboxEnlarged}
        />
      </div>
    </div>
  );
};
