import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initMapBox } from '../../../../services/mapBox/init';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { selectInitializeLayers } from '../../../../store/selectors/map/initializeLayers.selector';
import { selectProjectPosition } from '../../../../store/selectors/map/projectPosition.selector';
import classNames from 'classnames';
import { selectCurrentCaseLabel } from '../../../../store/selectors/project/currentCaseLabel.selector';
import {
  PROJECT_LOCATION_DEFAULT_LATITUDE,
  PROJECT_LOCATION_DEFAULT_LONGITUDE
} from '../../../../domain/ProjectLocation';
import { Position } from '@turf/helpers/dist/js/lib/geojson';
import { fireDrawHelperEvents } from '../../../../services/mapBox/drawHelper/drawHelper.events';
import { RightPanel } from './Geometry/RightPanel';
import { DrawServices } from '../../../../services/mapBox/mapboxDraw/draw.services';
import { LayerRepo } from '../../../../services/mapBox/layer/layer.repo';
import { LeftPanel } from './Geometry/LeftPanel';
import { MiddlePanel } from './Geometry/MiddlePanel';
import { selectProjectId } from '../../../../store/selectors/project';

export interface UiGeometry {
  map: mapboxgl.Map;
  draw: MapboxDraw;
  drawServices: DrawServices;
  layerRepo: LayerRepo;
}

export interface MaybeUiGeometry extends Partial<UiGeometry> {
  map: mapboxgl.Map | undefined;
  draw: MapboxDraw | undefined;
}
export const isUiLevelGeometry = (ui: MaybeUiGeometry): ui is UiGeometry => {
  return ui.map !== undefined && ui.draw !== undefined;
};
export const Geometry = () => {
  const dispatch = useDispatch();
  const projectId = useSelector(selectProjectId);

  const initializeLayers = useSelector(selectInitializeLayers);
  const projetPosition = useSelector(selectProjectPosition);
  const caseLabel = useSelector(selectCurrentCaseLabel);

  const [drawHelperMode, setDrawHelperMode] = useState(false);
  const [ui, setUi] = useState<MaybeUiGeometry>({
    map: undefined,
    draw: undefined,
    drawServices: undefined,
    layerRepo: undefined
  });
  const mapContainer = useRef() as React.MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    if (initializeLayers.isLoading) return;
    if (!ui.map) {
      initMapBox(
        mapContainer,
        projetPosition ||
          ([
            PROJECT_LOCATION_DEFAULT_LATITUDE,
            PROJECT_LOCATION_DEFAULT_LONGITUDE
          ] as unknown as Position),
        dispatch,
        initializeLayers,
        caseLabel,
        projectId
      ).then((res) => setUi(res));
    }
  }, [projetPosition, initializeLayers.isLoading]);

  useEffect(() => {
    if (ui.map) fireDrawHelperEvents(ui.map).hide();
  }, [drawHelperMode]);

  if (!initializeLayers) return null;

  const withSideCards = initializeLayers.parcels;
  const toggleSetDrawHelperMode = () => setDrawHelperMode((state) => !state);

  return (
    <div className={classNames('geometry-wrapper', { withSideCards })}>
      <MiddlePanel
        mapContainer={mapContainer}
        ui={ui as UiGeometry}
        setMode={toggleSetDrawHelperMode}
        drawHelperMode={drawHelperMode}
      />
      <LeftPanel ui={ui as UiGeometry} />
      <RightPanel
        drawHelperMode={drawHelperMode}
        toggleSetDrawHelperMode={toggleSetDrawHelperMode}
        ui={ui as UiGeometry}
      />
    </div>
  );
};
