import { ProjectProjection } from '../../../project/ProjectProjection';
import { CaseProjection } from '../../CaseProjection';
import { Surface } from '../../../../specification/Surface';
import { DEFAULT_RBS_TO_CBS_RATIO } from '../../../../../constants/appConstants';
import { getProjectSumOfCasesRealBuiltSurfaces } from '../../../project/queries/cases/getProjectSumOfCasesRealBuiltSurfaces';
import { getProjectSumOfCasesTechnicalSectionsSurfaces } from '../../../project/queries/cases/getProjectSumOfCasesTechnicalSectionsSurfaces';
import { MAXIMUM_PROJECT_CBS } from '../../../../project/Project';

export const getCaseRealBuiltSurfaceMaximum = (
  projectProjection: ProjectProjection,
  caseProjection: CaseProjection
): number => {
  const cbs = (caseProjection.projectedCuttedBuiltSurface as Surface).value;
  const sfs = (caseProjection.projectedSurfaceForSale as Surface).value;
  const rbs = (caseProjection.projectedRealBuiltSurface as Surface).value;
  const sumOfCasesTechnicalSectionsSurfaces =
    getProjectSumOfCasesTechnicalSectionsSurfaces(projectProjection);
  const cbsToSfsRatio = sfs !== 0 && cbs !== 0 ? sfs / cbs : 0;
  return (
    MAXIMUM_PROJECT_CBS / DEFAULT_RBS_TO_CBS_RATIO +
    (sumOfCasesTechnicalSectionsSurfaces
      ? sumOfCasesTechnicalSectionsSurfaces / (cbsToSfsRatio * DEFAULT_RBS_TO_CBS_RATIO)
      : 0) -
    getProjectSumOfCasesRealBuiltSurfaces(projectProjection) +
    rbs
  );
};
