import { getCaseOutsideWallConcreteRateMaxValue } from './getCaseOutsideWallConcreteRateMaxValue';

export const getCaseOutsideWallTypeRatesValidity = (
  outsideWallConcreteRate: number | undefined,
  outsideWallMasonryRate: number | undefined,
  outsideWallWoodRate: number | undefined
): boolean =>
  (outsideWallConcreteRate === undefined
    ? getCaseOutsideWallConcreteRateMaxValue({
        outsideWallMasonryRate,
        outsideWallWoodRate
      })
    : outsideWallConcreteRate) +
    (outsideWallMasonryRate || 0) +
    (outsideWallWoodRate || 0) ===
  100;
