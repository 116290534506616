import { getSectionPartitionWallsLinear } from './getSectionPartitionWallsLinear';
import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../../levels/LevelGranulometry';
import { LodgmentSection } from '../../../lodgmentSections/LodgmentSection';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';

export const getSectionPartitionWallsSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithBearingRelativeSections | LevelGranulometry,
  lodgmentSection: LodgmentSection
): number => {
  const partitionWallsLinear = getSectionPartitionWallsLinear(
    caseGranulometry,
    levelGranulometry,
    lodgmentSection
  );
  const partitionWallsThickness = 0.05;
  return partitionWallsLinear * partitionWallsThickness;
};
