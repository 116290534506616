import i18next from 'i18next';
import { LodgmentSection } from './LodgmentSection';
import { LodgmentTypeSpecification } from '../../../specification/lodgmentTypes/LodgmentTypeSpecification';
import { LodgmentType } from '../../../specification/lodgmentTypes/LodgmentType';
import { T3_COLOR } from '../../../../constants/appConstants';
import livingRoomKitchenC from '../../rooms/livingRoomKitchenC';
import kitchen from '../../rooms/kitchen';
import livingRoom from '../../rooms/livingRoom';
import bedroomPMR from '../../rooms/bedroomPMR';
import bedroom2 from '../../rooms/bedroom2';
import bathroom from '../../rooms/bathroom';
import balcony from '../../rooms/balcony';
import clearance from '../../rooms/clearance';
import toilet from '../../rooms/toilet';
import { HeatingSystem } from '../../../specification/lodgmentTypes/HeatingSystem';

export const t3c = (): LodgmentSection & LodgmentTypeSpecification => ({
  // Section
  id: 'NOID',
  name: LodgmentType.T3C,
  title: i18next.t('t3c.title'),
  type: 'lodgment',
  displayedSurface: 55,
  // SectionDrawn
  surface: 55,
  color: T3_COLOR,
  icon: {
    type: 'object',
    customText: 'T3C',
    customTextPosition: 'bottom right',
    iconName: 'lodgment',
    color: T3_COLOR
  },
  // SectionWithTheoreticalSurface
  theoreticalSurface: undefined,
  defaultTheoreticalSurface: 55,
  // SectionWithMinimumSurface
  minimumDisplayedSurface: 53,
  // SectionWithExposureRate
  exposureRate: undefined,
  defaultExposureRate: 1.5,
  // LodgmentSection
  lodgmentType: LodgmentType.T3C,
  rooms: [
    { ...livingRoomKitchenC(), icon: { ...livingRoomKitchenC().icon, color: T3_COLOR } },
    { ...kitchen(), icon: { ...kitchen().icon, color: T3_COLOR } },
    { ...livingRoom(), icon: { ...livingRoom().icon, color: T3_COLOR } },
    { ...bedroomPMR(), icon: { ...bedroomPMR().icon, color: T3_COLOR } },
    { ...bedroom2(), icon: { ...bedroom2().icon, color: T3_COLOR } },
    { ...bathroom(), icon: { ...bathroom().icon, color: T3_COLOR } },
    { ...toilet(), icon: { ...toilet().icon, color: T3_COLOR } },
    { ...clearance(), icon: { ...clearance().icon, color: T3_COLOR } },
    { ...balcony(), icon: { ...balcony().icon, color: T3_COLOR } }
  ],
  heatingSystem: undefined,
  defaultHeatingSystem: HeatingSystem.CollectiveHeatPumpOnGroundWaterHeating,
  // SectionWithTechnicalSheaths
  technicalSheathCount: 0,
  // LodgmentTypeSpecification
  prevType: LodgmentType.T2,
  nextType: LodgmentType.T3
});
