import { APP_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { getCasePropertyDefaultValue } from '../../../specification/cases/queries/get/properties/getCasePropertyDefaultValue';
import { CirculationName } from './CirculationSection';
import { SectionWithTheoreticalSurface } from '../SectionWithTheoreticalSurface';
import { SMOCK_EXTRACTION_DUCTS_ICON } from './smokeExtractionDucts';
import { SectionWithWidth } from '../SectionWithWidth';
import { SectionWithLength } from '../SectionWithLength';

interface SmokeExtractionDuctsInfraProps {
  surface: number;
  length: number;
  width: number;
  group: string;
}

export interface SmokeExtractionDuctsInfraSection
  extends Section,
    SectionDrawn,
    SectionWithLength,
    SectionWithWidth,
    SectionWithTheoreticalSurface {
  name: CirculationName.SmokeExtractionDuctsInfra;
}

const smokeExtractionDuctsInfra = ({
  surface,
  length,
  width,
  group
}: SmokeExtractionDuctsInfraProps): SmokeExtractionDuctsInfraSection => {
  return {
    // Section
    id: 'NOID',
    name: CirculationName.SmokeExtractionDuctsInfra,
    title: i18next.t('smokeExtractionDuctsInfra.title'),
    type: 'circulation',
    // SectionDrawn
    surface,
    displayedSurface: surface,
    color: APP_COLOR,
    icon: SMOCK_EXTRACTION_DUCTS_ICON,
    group,
    // SectionWithTheoreticalSurface
    theoreticalSurface: surface,
    defaultTheoreticalSurface:
      getCasePropertyDefaultValue('smokeExtractionDuctsDepth') *
      getCasePropertyDefaultValue('smokeExtractionDuctsWidth'),
    // SmokeExtractionDuctsSection
    length,
    width
  };
};

export default smokeExtractionDuctsInfra;

export const isSectionASmokeExtractionDuctsInfra = (
  section: Section
): section is SmokeExtractionDuctsInfraSection =>
  getSectionName(section) === CirculationName.SmokeExtractionDuctsInfra;
