import { CaseProjection } from '../../../../../projection/cases/CaseProjection';
import { getLevelOutsideWallsSurfaceFromLevelsGeometry } from '../../../../../specification/cases/queries/levels/surfaces/getLevelOutsideWallsSurfaceFromLevelsGeometry';
import { BasementLevelSpecification } from '../../../../../specification/levels/BasementLevelSpecification';
import { getLevelHoppersSurface } from '../../../../../specification/cases/queries/levels/surfaces/getLevelHoppersSurface';

export const getBasementLevelRealBuiltSurface = (
  basementLevelSpecification: BasementLevelSpecification,
  caseProjection: CaseProjection
): number => {
  // 1 : If there is a manually forced real built surface :
  if (basementLevelSpecification.realBuiltSurface !== undefined) {
    return basementLevelSpecification.realBuiltSurface;
  }
  // 2 : If the level has a filled geometry
  else if (basementLevelSpecification.geometry?.properties?.area) {
    // TODO : Remove levelSpecification !== undefined (it's only to make test works)
    return (
      basementLevelSpecification.geometry.properties.area -
      getLevelHoppersSurface(caseProjection, basementLevelSpecification) -
      getLevelOutsideWallsSurfaceFromLevelsGeometry(
        caseProjection,
        basementLevelSpecification,
        false
      )
    );
  } else {
    return 0;
  }
};
