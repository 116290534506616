import { createSelector, Selector } from 'reselect';
import { getBuildingIdFromCaseId } from '../../../domain/core/queries/getBuildingIdFromCaseId';
import { selectBuildingIdFromRoute } from '../navigation/toolbox/buildingsPanel/buildingIdFromRoute.selector';
import { selectCurrentCaseIdFromRoute } from '../navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { selectProject } from './project.selector';
import { BuildingId } from '../../../domain/specification/buildings/BuildingSpecification';
import { State } from '../../../store/reducers';
import { Maybe } from '../../../utils/Maybe';

export const selectCurrentBuildingId: Selector<State, Maybe<string>> = createSelector(
  [selectProject, selectBuildingIdFromRoute, selectCurrentCaseIdFromRoute],
  (project, buildingIdFromRoute, caseIdFromRoute) => {
    if (buildingIdFromRoute) return buildingIdFromRoute;
    if (project && caseIdFromRoute) {
      return getBuildingIdFromCaseId(project, caseIdFromRoute) as BuildingId;
    }
    return undefined;
  }
);
