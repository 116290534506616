import { getSectionName, Section } from '../Section';
import i18next from 'i18next';
import { MetaSectionName } from './MetaSection';

interface LowestLevelMetaSectionProps {
  circulationLaneInfraLength: number;
  ceilingNetHeight: number;
}

export interface LowestLevelMetaSection extends Section {
  name: MetaSectionName.LowestLevelMetaSection;
  circulationLaneInfraLength: number;
  ceilingNetHeight: number;
}

const lowestLevelMetaSection = ({
  circulationLaneInfraLength,
  ceilingNetHeight
}: LowestLevelMetaSectionProps): LowestLevelMetaSection => ({
  // Section
  id: 'NOID',
  name: MetaSectionName.LowestLevelMetaSection,
  title: i18next.t('lowestLevelMetaSection.title'),
  type: 'meta',
  displayedSurface: 0,
  circulationLaneInfraLength,
  ceilingNetHeight
});

export default lowestLevelMetaSection;

export const isSectionALowestLevelMetaSection = (
  section: Section
): section is LowestLevelMetaSection =>
  getSectionName(section) === MetaSectionName.LowestLevelMetaSection;
