import { LevelGranulometry } from '../../levels/LevelGranulometry';
import { SectionWithGlazedSurface } from '../SectionWithGlazedSurface';
import { CaseGranulometry } from '../../cases/CaseGranulometry';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { getSectionRealOutsideWallsLinear } from './walls/realWalls/getSectionRealOutsideWallsLinear';

export const getSectionDefaultGlazedLinear = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry,
  section: SectionWithGlazedSurface & SectionWithExposureRate
): number =>
  getSectionRealOutsideWallsLinear(caseGranulometry, levelGranulometry, section) *
  section.defaultGlazedRate;
