import { LevelGranulometryWithLodgments } from '../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { createTopLevelHallSection } from './create/createTopLevelHallSection';
import { mustHaveAHallSection } from '../../mustHave/mustHaveAHallSection';
import { HallSection } from '../../../../sections/circulationSections/hall';

export const getTopLevelHallSection = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithLodgments
): HallSection[] | [] => {
  if (mustHaveAHallSection(caseGranulometry, levelGranulometry)) {
    return [createTopLevelHallSection(caseGranulometry)];
  }
  return [];
};
