import i18next from 'i18next';
import { BasicRoomSpecification } from '../../specification/rooms/BasicRoomSpecification';

const DEFAULT_BATHROOM_LENGTH = 2.4;
const DEFAULT_BATHROOM_WIDTH = 2.2;

const bathroom = (): BasicRoomSpecification =>
  ({
    name: 'bathroom',
    title: i18next.t('Bathroom'),
    defaultLength: DEFAULT_BATHROOM_LENGTH,
    width: undefined,
    defaultWidth: DEFAULT_BATHROOM_WIDTH,
    surface: undefined,
    icon: {
      type: 'object',
      iconName: 'bathroom',
      strokeStyle: 'opened'
    }
  } as const);

export default bathroom;
