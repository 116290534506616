import { CaseGranulometry } from '../../../../domain/granulometry/cases/CaseGranulometry';
import { getCaseBasementLevelsCount } from '../../../../domain/granulometry/cases/queries/levels/counts/getCaseBasementLevelsCount';
import { getCaseBasementLevelsWithParkingsCount } from '../../../../domain/granulometry/cases/queries/levels/counts/getCaseBasementLevelsWithParkingsCount';
import { getCaseParkingsSurface } from '../../../../domain/granulometry/cases/queries/sections/surfaces/getCaseParkingsSurface';
import { getCaseParkingSectionsCount } from '../../../../domain/granulometry/cases/queries/sections/counts/getCaseParkingSectionsCount';
import { getCaseBasementLevelsRealBuiltSurface } from '../../../../domain/granulometry/cases/queries/levels/surfaces/getCaseBasementLevelsRealBuiltSurface';
import { roundWith2Decimal } from '../../../../utils/round/roundWith2Decimal';

export interface ComEthJsonFeatureInfrastructure {
  storeys_below_ground: number; // number of basements (il faudra qu'on regarde les parkings quand on sera plus avance)
  parking_storeys: number; // number of basements with parking
  infrastructure_floor_area: number; // all basements total surface (SHOB)
  parking_floor_area: number; // all parkings total surface
  nb_parking_place: number; // total number of parking
}

export const getComEthJsonFeatureInfrastructure = (
  caseGranulometry: CaseGranulometry
): ComEthJsonFeatureInfrastructure => ({
  storeys_below_ground: getCaseBasementLevelsCount(caseGranulometry),
  parking_storeys: getCaseBasementLevelsWithParkingsCount(caseGranulometry),
  infrastructure_floor_area: roundWith2Decimal(
    getCaseBasementLevelsRealBuiltSurface(caseGranulometry).value
  ),
  parking_floor_area: roundWith2Decimal(getCaseParkingsSurface(caseGranulometry).value),
  nb_parking_place: getCaseParkingSectionsCount(caseGranulometry)
});
