import { LevelGranulometry } from '../../levels/LevelGranulometry';
import { CaseGranulometry } from '../../cases/CaseGranulometry';
import { createFundationsLevel } from '../../levels/queries/fundationsLevels/createFundationsLevel';
import { getLevelAbove } from '../../levels/queries/getLevelAbove';

export const updateFoundationLevel = (
  caseGranulometry: CaseGranulometry,
  foundationLevel: LevelGranulometry
): LevelGranulometry =>
  createFundationsLevel(
    caseGranulometry,
    getLevelAbove(caseGranulometry, foundationLevel) as LevelGranulometry
  );
