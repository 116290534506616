import { Property } from '../../domain/specification/cases/Property';

export const SET_CASE_PROPERTY = 'SET_CASE_PROPERTY';

export const casePropertyChanged = (caseId: string, property: Property) => ({
  type: SET_CASE_PROPERTY,
  payload: { caseId, property }
});

export type CasePropertyChangedAction = ReturnType<typeof casePropertyChanged>;
