import { CaseCustomDistribution } from '../../domain/specification/cases/CaseSurfaceSpecification';

export const CASE_CUSTOM_DISTRIBUTION_CHANGED = 'CASE_CUSTOM_DISTRIBUTION_CHANGED';

export const caseCustomDistributionChanged = (
  caseId: string,
  customDistribution: CaseCustomDistribution
) => ({
  type: CASE_CUSTOM_DISTRIBUTION_CHANGED,
  payload: { caseId, customDistribution }
});

export type CaseCustomDistributionChangedAction = ReturnType<typeof caseCustomDistributionChanged>;
