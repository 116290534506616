import { CaseGranulometry } from '../../CaseGranulometry';
import { getCasePropertyConvertedValue } from '../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';

export type InsulationPosition = 'ETI' | 'ITI';

export const getCaseAverageInsulationPosition = (
  caseGranulometry: CaseGranulometry
): InsulationPosition =>
  (getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'internalInsulationRate'
  ) as number) >
  (getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'externalInsulationRate'
  ) as number)
    ? 'ETI'
    : 'ITI';
