import { APP_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { CirculationName } from './CirculationSection';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';
import { SectionWithWidth } from '../SectionWithWidth';

interface CirculationLaneInfraProps {
  surface: number;
  width: number;
  color?: string;
}

export interface CirculationLaneInfraSection extends Section, SectionDrawn, SectionWithWidth {
  name: CirculationName.CirculationLaneInfra;
}

export const CIRCULATION_LANE_ICON = {
  type: 'object',
  iconName: 'lane',
  color: APP_COLOR,
  title: 'Circulation lane'
} as IconObjectProps;

const circulationLaneInfra = ({
  surface,
  width,
  color
}: CirculationLaneInfraProps): CirculationLaneInfraSection => ({
  // Section
  id: 'NOID',
  name: CirculationName.CirculationLaneInfra,
  title: i18next.t('circulationLaneInfra.title'),
  type: 'circulation',
  displayedSurface: surface,
  // SectionDrawn
  surface,
  color: color || APP_COLOR,
  icon: { ...CIRCULATION_LANE_ICON, color: color || APP_COLOR },
  width
});

export default circulationLaneInfra;

export const isSectionACirculationLaneInfra = (
  section: Section
): section is CirculationLaneInfraSection =>
  getSectionName(section) === CirculationName.CirculationLaneInfra;
