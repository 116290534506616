import { createSelector, Selector } from 'reselect';
import { State } from '../../../../store/reducers';
import { Maybe } from '../../../../utils/Maybe';

import { selectCurrentLevelParkingsCountByBasementLevelAndType } from './currentLevelParkingsCountByBasementLevelAndType.selector';

export const selectCurrentLevelCarBoxInfraCount: Selector<State, Maybe<number>> = createSelector(
  [selectCurrentLevelParkingsCountByBasementLevelAndType],
  (currentLevelParkingsCountByBasementLevelAndType) =>
    currentLevelParkingsCountByBasementLevelAndType?.carBoxInfra
);
