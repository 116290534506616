import * as React from 'react';

export const PivotComponent: React.ElementType = (props) => {
  return (
    <div className={'pivotInner'}>
      <div className={'rt-expander' + (props.isExpanded ? ' -open' : '')}>•</div>
      {props.row._pivotVal}
    </div>
  );
};
