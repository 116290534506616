import i18next from 'i18next';
export type DataTypeValue =
  | 'quantity'
  | 'quantity-cost'
  | 'co2'
  | 'wood-cubature'
  | 'quantity-cost-co2';

export interface DataType {
  value: DataTypeValue;
  label: string;
}

export const dataTypes: DataType[] = [
  {
    value: 'quantity',
    label: i18next.t('Quantity')
  },
  {
    value: 'quantity-cost',
    label: i18next.t('Quantity and cost')
  },
  {
    value: 'quantity-cost-co2',
    label: i18next.t('Quantity, cost and carbon weight')
  },
  {
    value: 'co2',
    label: i18next.t('Carbon weight')
  },
  {
    value: 'wood-cubature',
    label: i18next.t('Wood cubature')
  }
];
