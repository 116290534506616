import { SectionWithExposureRate } from '../../../SectionWithExposureRate';
import { LevelGranulometry } from '../../../../levels/LevelGranulometry';
import { getSectionWallsLinear } from '../getSectionWallsLinear';
import { getSectionRealOutsideWallsLinear } from './getSectionRealOutsideWallsLinear';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';

export const getSectionRealInsideWallsLinear = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry,
  section: SectionWithExposureRate
): number =>
  getSectionWallsLinear(caseGranulometry, levelGranulometry, section) -
  getSectionRealOutsideWallsLinear(caseGranulometry, levelGranulometry, section);
