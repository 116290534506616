import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../ui/input/Input';
import { roundForSurfacesInputs } from '../../../../utils/round/roundForSurfacesInputs';
import { selectCurrentCaseGrossFloorSurface } from '../../../../store/selectors/granulometry/currentCase/grossFloorSurface.selector';

export const CaseGrossFloorSurfaceInput = () => {
  const grossFloorSurface = useSelector(selectCurrentCaseGrossFloorSurface);
  const { t } = useTranslation();
  return (
    <Input
      label={t('grossFloorSurface.label')}
      info={t('grossFloorSurface.infos')}
      name="caseGrossFloorSurface"
      suffix="m&#178;"
      style={{ marginTop: '3px' }}
      type="number"
      value={grossFloorSurface ? roundForSurfacesInputs(grossFloorSurface.value) : ''}
      disabled={true}
    />
  );
};
