export interface ComEthJsonFeatureSuperstructureIntermediateFloor {
  id_typy_intermediate_floor: string | null; // ID TyPy (set null for now)
  intermediate_floor_type: 'girder-slab' | '...' | null; // set null for now
  intermediate_floor_constructive_system: 'système poutrelles-hourdis en béton' | '...' | null; // set null for now
}

export const getComEthJsonFeatureSuperstructureIntermediateFloor =
  (): ComEthJsonFeatureSuperstructureIntermediateFloor => ({
    id_typy_intermediate_floor: null,
    intermediate_floor_type: null,
    intermediate_floor_constructive_system: null
  });
