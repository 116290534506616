import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { getInFullFilledTopLevelHallSections } from '../getInFullFilledTopLevelHallSections';
import { getSectionDisplayedSurface } from '../../../../../sections/queries/surfaces/getSectionDisplayedSurface';
import { Surface } from '../../../../../../specification/Surface';

export const getInFullFilledTopLevelHallSectionSurface = (
  levelGranulometry: LevelGranulometry | LevelGranulometryFullFilled
): Surface => {
  const halls = getInFullFilledTopLevelHallSections(levelGranulometry);
  return new Surface(halls[0] ? getSectionDisplayedSurface(halls[0]) : 0);
};
