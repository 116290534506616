import { RoomStaticElement } from './RoomStaticElement';

export const showerRoomElements: RoomStaticElement[] = [
  { e1: 'chape ciment', e2: '', e3: '', quantity: 1 },
  { e1: 'revêtement de sol carrelage', e2: '', e3: '', quantity: 1 },
  { e1: 'résilient acoustique', e2: '', e3: '', quantity: 1 },
  { e1: 'plinthe carrelage', e2: '', e3: '', quantity: 1 },
  { e1: "revêtement mural étanche salle d'eau", e2: '', e3: '', quantity: 1 },
  { e1: 'finition murale', e2: '', e3: '', quantity: 1 },
  { e1: 'enduit pelliculaire horizontal', e2: '', e3: '', quantity: 1 },
  { e1: 'finition plafond', e2: '', e3: '', quantity: 1 },
  { e1: 'porte de distribution', e2: '', e3: '', quantity: 1 },
  { e1: 'porte de distribution', e2: 'seuil bois passage de porte', e3: '', quantity: 1 },
  { e1: 'porte de distribution', e2: 'peinture sur bois prépeint', e3: '', quantity: 1 },
  { e1: 'porte de distribution', e2: 'huisserie porte de distribution', e3: '', quantity: 1 },
  {
    e1: 'porte de distribution',
    e2: 'huisserie porte de distribution',
    e3: 'peinture sur métal',
    quantity: 1
  },
  { e1: 'douche', e2: '', e3: '', quantity: 1 },
  { e1: 'douche', e2: 'socle pour receveur de douche', e3: '', quantity: 1 },
  { e1: 'meuble vasque en 80', e2: '', e3: '', quantity: 1 },
  { e1: 'sèche serviette a eau chaude', e2: '', e3: '', quantity: 1 },
  { e1: 'point centre', e2: '', e3: '', quantity: 1 },
  { e1: 'interrupteur', e2: '', e3: '', quantity: 1 },
  { e1: 'éclairage applique', e2: '', e3: '', quantity: 1 },
  { e1: 'interrupteur', e2: '', e3: '', quantity: 1 },
  { e1: 'prise de courant 16A', e2: '', e3: '', quantity: 1 },
  { e1: "bouche d'extraction VMC", e2: '', e3: '', quantity: 1 }
];
