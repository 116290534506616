import { Property } from '../../../Property';
import { CaseSpecification } from '../../../CaseSpecification';
import { CaseProjection } from '../../../../../projection/cases/CaseProjection';
import { CaseGranulometry } from '../../../../../granulometry/cases/CaseGranulometry';

export const getCaseProperties = (
  anyTypeOfCase: CaseSpecification | CaseProjection | CaseGranulometry
): Property[] =>
  (anyTypeOfCase as CaseProjection | CaseGranulometry).projectedProperties ||
  (anyTypeOfCase as CaseSpecification).properties;
