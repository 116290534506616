import * as React from 'react';
import { MapPositionContextConsumer } from '../../../../contexts/MapPositionContext';
import { BuildingMapSectionViewController } from '../section/BuildingMapSectionViewController';
import { RoofingSection } from '../../../../domain/granulometry/sections/roofingSections/RoofingSection';

export interface BuildingMapRoofingInterface {
  roofing: RoofingSection[];
  direction: 'ltr' | 'rtl';
}

export const BuildingMapRoofingView = (props: BuildingMapRoofingInterface) => {
  const { roofing, direction } = props;

  return (
    <MapPositionContextConsumer>
      {(mapPositionContext) => (
        <div className="roofing">
          {roofing.map((roofingElem, key) => (
            <BuildingMapSectionViewController
              key={key}
              direction={direction}
              sectionGroupRecorder={[]}
              focusOn={mapPositionContext.focusOn}
              {...roofingElem}
            />
          ))}
        </div>
      )}
    </MapPositionContextConsumer>
  );
};
