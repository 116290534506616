import { createSelector, Selector } from 'reselect';
import { selectIsSavingProject } from './project/isSavingProject.selector';
import { selectIsLoadingProject } from './project/isLoadingProject.selector';
import { selectIsFetchingReport } from './report/isFetchingReport.selector';
import { State } from '../../store/reducers';

export const selectIsAppReady: Selector<State, boolean> = createSelector(
  [selectIsSavingProject, selectIsLoadingProject, selectIsFetchingReport],
  (saving, loading, fetchingReport) => !saving && !loading && !fetchingReport
);
