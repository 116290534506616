import { FUNDATIONS_COLOR } from '../../../../constants/appConstants';
import { Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { FundationsName } from './FundationsSection';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';

interface SuperficialFundationsProps {
  surface: number;
}

export interface SuperficialFundationsSection extends Section, SectionDrawn {
  name: FundationsName.SuperficialFundations;
}

export const SUPERFICIAL_FUNDATIONS_ICON = {
  type: 'object',
  iconName: FundationsName.SuperficialFundations,
  color: FUNDATIONS_COLOR
} as IconObjectProps;

const superficialFundations = ({
  surface
}: SuperficialFundationsProps): SuperficialFundationsSection => ({
  // Section
  id: 'NOID',
  name: FundationsName.SuperficialFundations,
  title: i18next.t('superficialFundations.title'),
  type: 'fundations',
  displayedSurface: surface,
  // SectionDrawn
  surface,
  color: FUNDATIONS_COLOR,
  icon: SUPERFICIAL_FUNDATIONS_ICON
});

export default superficialFundations;
