import * as R from 'ramda';
import { getSectionName, Section } from '../Section';
import { OffsetSlabSection } from './offsetSlab';
import { OutsideSlabSection } from './outsideSlab';

export enum SlabSectionName {
  OffsetSlab = 'offsetSlab',
  OutsideSlab = 'outsideSlab'
}

export type SlabSection = OffsetSlabSection | OutsideSlabSection;

type GetSlabSectionName = (slabSection: SlabSection) => SlabSectionName;
export const getSlabSectionName: GetSlabSectionName = R.prop('name');

export const isSectionASlabSection = (section: Section): section is SlabSection =>
  (R.values(SlabSectionName) as string[]).includes(getSectionName(section));

export const filterSlabSection = (sections: Section[]): SlabSection[] =>
  sections.filter(isSectionASlabSection);
