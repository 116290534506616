import { DEFAULT_PROJECT_SURFACE_INPUT_MODE, Project } from '../../project/Project';
import { createBuildingSpecification } from '../../specification/buildings/queries/createBuildingSpecification';

export const migrateProject0To1 = (project: Project): Project =>
  project && project.buildings
    ? project
    : {
        ...project,
        buildings: [
          createBuildingSpecification({
            projectSurfaceInputMode: DEFAULT_PROJECT_SURFACE_INPUT_MODE
          })
        ]
      };
