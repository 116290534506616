export enum CarpentryOccultationsTypeName {
  PVCRollerShutter = 'pvcRollerShutter',
  AluminiumRollerShutter = 'aluminiumRollerShutter',
  SunBreaker = 'sunBreaker',
  FabricBlind = 'fabricBlind',
  WoodenShutter = 'woodenShutter',
  MetalShutter = 'metalShutter',
  None = 'none'
}

export type CarpentryOccultationsType =
  | CarpentryOccultationsTypeName.PVCRollerShutter
  | CarpentryOccultationsTypeName.AluminiumRollerShutter
  | CarpentryOccultationsTypeName.SunBreaker
  | CarpentryOccultationsTypeName.FabricBlind
  | CarpentryOccultationsTypeName.WoodenShutter
  | CarpentryOccultationsTypeName.MetalShutter
  | CarpentryOccultationsTypeName.None;

export const DEFAULT_CARPENTRY_OCCULTATIONS_TYPE = CarpentryOccultationsTypeName.PVCRollerShutter;

export const isCarpentryOccultationsTypeUsePVC = (
  carpentryOccultationsType: CarpentryOccultationsType
): boolean => carpentryOccultationsType === CarpentryOccultationsTypeName.PVCRollerShutter;

export const isCarpentryOccultationsTypeUseWoodOrMetal = (
  carpentryOccultationsType: CarpentryOccultationsType
): boolean =>
  carpentryOccultationsType === CarpentryOccultationsTypeName.WoodenShutter ||
  carpentryOccultationsType === CarpentryOccultationsTypeName.MetalShutter;
