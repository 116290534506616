import { Property } from '../../specification/cases/Property';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';

const insert = (arr: Property[], index: number, newItem: Property) => [
  // part of the array before the specified index
  ...arr.slice(0, index),
  // inserted item
  newItem,
  // part of the array after the specified index
  ...arr.slice(index)
];

export const addCaseProperty = (
  caseSpecification: CaseSpecification,
  newProperty: Property,
  insertBeforeIndex: number
): CaseSpecification => ({
  ...caseSpecification,
  properties: caseSpecification.properties.find((property) => property.name === newProperty.name)
    ? caseSpecification.properties
    : insert(caseSpecification.properties, insertBeforeIndex, newProperty)
});
