import { LodgmentType } from '../../../specification/lodgmentTypes/LodgmentType';
import { CountByLodgmentType } from './redistributeCaseWithCustomDistribution';
import { LevelGranulometry } from '../../levels/LevelGranulometry';
import { isSectionALodgment } from '../../sections/lodgmentSections/LodgmentSection';
import { getLevelFullFilledContent } from '../../levels/queries/content/getLevelFullFilledContent';

export const getLevelDistributionFromGranulometry = (
  levelGranulometry: LevelGranulometry
): CountByLodgmentType =>
  getLevelFullFilledContent(levelGranulometry)
    .filter(isSectionALodgment)
    .reduce(
      (acc, lodgment) => ({
        ...acc,
        [lodgment.lodgmentType]: (acc[lodgment.lodgmentType] || 0) + 1
      }),
      {} as { [key in LodgmentType]: number }
    );
