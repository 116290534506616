import elevatorShaft, {
  ElevatorShaftSection
} from '../../../../../sections/circulationSections/elevatorShaft';
import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createTopLevelElevatorShaftSection = (
  caseGranulometry: CaseGranulometry,
  group: string
): ElevatorShaftSection => {
  const length = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'elevatorShaftLength'
  ) as number;
  const width = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'elevatorShaftWidth'
  ) as number;
  const surface = length * width;
  const doorHeight = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'elevatorDoorHeight'
  ) as number;
  const doorWidth = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'elevatorDoorWidth'
  ) as number;
  return elevatorShaft({
    surface,
    length,
    width,
    doorHeight,
    doorWidth,
    group
  });
};
