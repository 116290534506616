import { Section } from './Section';
import { isSectionAParking } from './parkingSections/ParkingSection';
import { isSectionALodgment } from './lodgmentSections/LodgmentSection';
import { isSectionABearingInfra } from './circulationSections/bearingInfra';
import { isSectionAElevatorShaftInfra } from './circulationSections/elevatorShaftInfra';
import { isSectionAElevatorShaft } from './circulationSections/elevatorShaft';
import { isSectionAHall } from './circulationSections/hall';
import { isSectionARamp } from './circulationSections/ramp';
import { isSectionARampInfra } from './circulationSections/rampInfra';
import { isSectionASasInfra } from './circulationSections/sasInfra';

export interface SectionWithTheoreticalSurface extends Section {
  theoreticalSurface?: number;
  defaultTheoreticalSurface: number;
}

export const isSectionWithTheoreticalSurface = (
  section: Section
): section is SectionWithTheoreticalSurface =>
  isSectionALodgment(section) ||
  isSectionAParking(section) ||
  isSectionABearingInfra(section) ||
  isSectionAElevatorShaft(section) ||
  isSectionAElevatorShaftInfra(section) ||
  isSectionAHall(section) ||
  isSectionARamp(section) ||
  isSectionARampInfra(section) ||
  isSectionASasInfra(section);
