import { SectionsWithSheathsCountByLevel } from '../getCaseRealTechnicalSheathsCountByLevel';

export const TECHNICAL_SHEATHS_COUNT_PER_LODGMENT = 2;
export const TECHNICAL_SHEATHS_COUNT_PER_TECHNICAL_PREMISE = 2;
export const TECHNICAL_SHEATHS_COUNT_PER_COMMON_PREMISE = 2;
export const TECHNICAL_SHEATHS_COUNT_PER_SHOP = 2;
export const TECHNICAL_SHEATHS_COUNT_PER_OFFICE = 2;

type TheoricalTechnicalSheathsCountByLevel = {
  levelIndex: number;
  theoricalSheathsCount: number;
};

export const getCaseTheoricalTechnicalSheathsCountByLevel = (
  sectionsWithSheathsCountByLevels: SectionsWithSheathsCountByLevel[]
): TheoricalTechnicalSheathsCountByLevel[] =>
  sectionsWithSheathsCountByLevels.reduce(
    (acc, l) => [
      ...acc,
      {
        levelIndex: l.level,
        theoricalSheathsCount:
          l.lodgmentsCount * TECHNICAL_SHEATHS_COUNT_PER_LODGMENT +
          l.technicalPremisesCount * TECHNICAL_SHEATHS_COUNT_PER_TECHNICAL_PREMISE +
          l.commonPremisesCount * TECHNICAL_SHEATHS_COUNT_PER_COMMON_PREMISE +
          l.shopsCount * TECHNICAL_SHEATHS_COUNT_PER_SHOP +
          l.officesCount * TECHNICAL_SHEATHS_COUNT_PER_OFFICE
      }
    ],
    [] as TheoricalTechnicalSheathsCountByLevel[]
  );
