import { Section } from './Section';
import { isSectionALodgment } from './lodgmentSections/LodgmentSection';
import { isSectionAnTechnicalPremise } from './technicalPremiseSections/TechnicalPremiseSection';
import { isSectionACommonPremise } from './commonPremiseSections/CommonPremiseSection';
import { isSectionAShop } from './shopSection/shopSection';
import { isSectionAnOffice } from './officeSection/officeSection';

export interface SectionWithTechnicalSheaths extends Section {
  technicalSheathCount: number;
}

export const isSectionWithTechnicalSheaths = (
  section: Section
): section is SectionWithTechnicalSheaths =>
  isSectionALodgment(section) ||
  isSectionAnTechnicalPremise(section) ||
  isSectionACommonPremise(section) ||
  isSectionAShop(section) ||
  isSectionAnOffice(section);

export const filterSectionWithTechnicalSheaths = (
  sections: Section[]
): SectionWithTechnicalSheaths[] => sections.filter(isSectionWithTechnicalSheaths);
