import * as React from 'react';
import * as R from 'ramda';
import { formatThousands } from '../../../../../utils/formatThousands';
import { getTotalPrice } from './totals/getTotalPrice';
import { useSelector } from 'react-redux';
import { selectReportDataTypeValueFromRoute } from '../../../../../store/selectors/deliverables/reportDataTypeValueFromRoute.selector';
import { getTotalCompostionValue } from './totals/getTotalCompositionValue';
import { getTotalIc } from './totals/getTotalIc';
import { hasCostColumns } from './columns/hasCostColumns';
import { hasCo2Columns } from './columns/hasCo2Columns';
import { hasWoodCubatureColumns } from './columns/hasWoodCubatureColumns';

const mergeRows = (r: any) =>
  r._subRows ? r._subRows.reduce((acc: number, row: any) => [...acc, mergeRows(row)], []) : r;

interface PaginationComponentProps {
  data: any;
  sortedData: any;
}

export const PaginationComponent = ({ data, sortedData }: PaginationComponentProps) => {
  const dataTypeValue = useSelector(selectReportDataTypeValueFromRoute);

  let total = '';
  if (hasCostColumns(dataTypeValue))
    total += formatThousands(Math.round(getTotalPrice({ _subRows: sortedData }))) + ' €';
  if (dataTypeValue === 'quantity-cost-co2') total += '\u00A0\u00A0|\u00A0\u00A0';
  if (hasCo2Columns(dataTypeValue))
    total +=
      formatThousands(Math.round(getTotalIc({ _subRows: sortedData }))) +
      'kg\u00A0éq\u00A0CO\u2082/m\u00B2 de SHab';
  if (hasWoodCubatureColumns(dataTypeValue))
    total +=
      formatThousands(Math.round(getTotalCompostionValue({ _subRows: sortedData }))) +
      '\u00A0m\u00B3';

  // TODO : Find a way to lighten these calculations
  const mergedSortedData = React.useMemo(
    () => R.flatten(mergeRows({ _subRows: sortedData })),
    [sortedData]
  );
  const linesInSortedDataCount = React.useMemo(
    () => R.length(mergedSortedData),
    [mergedSortedData]
  );
  const linesInDataCount = React.useMemo(() => R.length(data), [data]);
  const uniqProductIdInSortedDataCount = React.useMemo(
    () => R.length(R.uniqWith(R.eqBy(R.prop('productId')))(mergedSortedData)),
    [mergedSortedData]
  );
  const uniqProductIdInDataCount = React.useMemo(
    () => R.length(R.uniqWith(R.eqBy(R.prop('productId')))(data)),
    [data]
  );
  return (
    <div className="gb-rt-top">
      <div className="gb-rt-top-left">
        <span className="gb-rt-total large">
          <b>{linesInSortedDataCount}</b> sur
          {' ' + linesInDataCount} ligne{linesInDataCount > 1 ? 's' : ''}
        </span>
        &nbsp;{' | '}&nbsp;
        <span className="gb-rt-total large">
          <b>{uniqProductIdInSortedDataCount}</b> sur
          {' ' + uniqProductIdInDataCount} reférence
          {uniqProductIdInDataCount > 1 ? 's produit' : ''}
        </span>
      </div>
      {total !== '' && (
        <div className="gb-rt-top-right">
          <span className="gb-rt-total large">Total : {total}</span>
        </div>
      )}
    </div>
  );
};
