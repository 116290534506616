import { LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { getTopLevelRealBuiltSurfaceEff } from '../realBuiltSurface/getTopLevelRealBuiltSurfaceEff';
import { getTopLevelRealOutsideWallsSurface } from '../../walls/realWalls/getTopLevelRealOutsideWallsSurface';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { getTopLevelGrossFloorSurfaceEff } from './getTopLevelGrossFloorSurfaceEff';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { isProjectInputModeCbs } from '../../../../../../specification/project/queries/is/isProjectInputModeCbs';

export const getTopLevelGrossFloorSurfaceEffDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled
): string => {
  let details: string[] = [];
  if (isProjectInputModeCbs(caseGranulometry.initialSpecifications)) {
    details = [
      ...details,
      '<b>' +
        roundWith2Decimal(getTopLevelRealBuiltSurfaceEff(caseGranulometry, levelGranulometry)) +
        ' m\u00B2</b> de SdP réelle VPP'
    ];
    details = [
      ...details,
      '<b>+ ' +
        roundWith2Decimal(getTopLevelRealOutsideWallsSurface(caseGranulometry, levelGranulometry)) +
        ' m\u00B2</b> d’emprise réelle de voiles exposés'
    ];
  } else {
    details = [
      ...details,
      '<b> ' + (levelGranulometry.grossFloorSurfaceEff || 0) + ' m\u00B2</b> de SHOB dessinée'
    ];
  }

  details = [...details, ''];
  return (
    details.join('<br />') +
    '<br /><b>= ' +
    roundWith2Decimal(getTopLevelGrossFloorSurfaceEff(caseGranulometry, levelGranulometry)) +
    ' m\u00B2</b>'
  );
};
