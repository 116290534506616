import { Project } from '../../project/Project';

export const migrateProject3To4 = (project: Project): Project =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  project && project.surface && project.projectedSurface === undefined
    ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      { ...project, projectedSurface: project.surface }
    : project;
