import * as React from 'react';
import { Column } from 'react-table';
import { ReportEntry } from '../../../../../domain/report/ReportEntry';
import { DataTypeValue } from '../../../../../domain/DataType';
import { getProductColumns } from './columns/getProductColumns';
import { getQuantityColumns } from './columns/getQuantityColumns';
import { getCo2Columns } from './columns/getCo2Columns';
import { getWoodCubatureColumns } from './columns/getWoodCubatureColumns';
import { getPositionColumns } from './columns/getPositionColumns';
import { getNomenclatureColumns } from './columns/getNomenclatureColumns';
import { getCostColumns } from './columns/getCostColumns';
import { getCostPercentsColumns } from './columns/getCostPercentsColumns';
import { getCo2PercentsColumns } from './columns/getCo2PercentsColumns';
import { CustomPriceList } from '../../../../../domain/Account';
import { Project } from '../../../../../domain/project/Project';
import { hasQuantityColumns } from './columns/hasQuantityColumns';
import { hasWoodCubatureColumns } from './columns/hasWoodCubatureColumns';
import { hasCostColumns } from './columns/hasCostColumns';
import { hasCo2Columns } from './columns/hasCo2Columns';

export const getColumns = (
  report: ReportEntry[],
  dataTypeValue: DataTypeValue | undefined,
  subRowsIndex: number[],
  foldedColumns: string[],
  currentCustomPriceList: CustomPriceList | undefined,
  handleCustomPriceChange: (productId: string, price: number | null) => void,
  handleCustomPriceListIdChange: (newCustomPriceListId: Project['customPriceListId']) => void,
  customPriceLists: CustomPriceList[]
): Column[] => {
  let columns: Column[] = [
    getNomenclatureColumns(report, foldedColumns),
    getProductColumns(report, foldedColumns)
  ];
  if (hasQuantityColumns(dataTypeValue) || hasWoodCubatureColumns(dataTypeValue)) {
    columns = [...columns, getQuantityColumns(report, subRowsIndex, foldedColumns)];
  }
  if (hasCostColumns(dataTypeValue)) {
    columns = [
      ...columns,
      getCostColumns(
        report,
        subRowsIndex,
        foldedColumns,
        currentCustomPriceList,
        handleCustomPriceChange,
        handleCustomPriceListIdChange,
        customPriceLists
      )
    ];
    columns = [...columns, getCostPercentsColumns(report, subRowsIndex, foldedColumns)];
  }

  if (hasCo2Columns(dataTypeValue)) {
    columns = [...columns, getCo2Columns(report, subRowsIndex, foldedColumns)];
    columns = [...columns, getCo2PercentsColumns(report, foldedColumns)];
  }
  if (hasWoodCubatureColumns(dataTypeValue)) {
    columns = [...columns, getWoodCubatureColumns(report, subRowsIndex)];
  }
  columns = [...columns, getPositionColumns(report, foldedColumns)];

  return columns;
};
