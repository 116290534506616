import { createSelector, Selector } from 'reselect';
import { selectProject } from './project.selector';
import { State } from '../../reducers';
import { Surface } from '../../../domain/specification/Surface';
import { Maybe } from '../../../utils/Maybe';

export const selectProjectCbs: Selector<State, Maybe<Surface>> = createSelector(
  [selectProject],
  (project) => project?.cuttedBuiltSurface
);
