import i18next from 'i18next';
import { ValidationRule } from '../ValidationsRule';
import { Notification } from '../Notification';
import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { Granulometry } from '../../granulometry/Granulometry';
import { getProjectCasesGranulometries } from '../../specification/project/queries/cases/getProjectCasesGranulometries';
import { getLevelPeripheralReinforcementValidation } from '../../granulometry/levels/queries/peripheralReinforcements/getLevelPeripheralReinforcementValidation';
import { getLevelFormworkBenchLengthMaxValue } from '../../granulometry/levels/queries/peripheralReinforcements/getLevelFormworkBenchLengthMaxValue';
import { getLevelContiguousAgainstBuiltLinearMaxValue } from '../../granulometry/levels/queries/peripheralReinforcements/getLevelContiguousAgainstBuiltLinearMaxValue';
import { getLevelContiguousUnderBuiltLinearMaxValue } from '../../granulometry/levels/queries/peripheralReinforcements/getLevelContiguousUnderBuiltLinearMaxValue';
import { getLevelContiguousNotBuiltLinearMaxValue } from '../../granulometry/levels/queries/peripheralReinforcements/getLevelContiguousNotBuiltLinearMaxValue';
import { getCaseBasementLevels } from '../../granulometry/cases/queries/levels/getCaseBasementLevels';

export const projectCasesPeripheralReinforcementInputsMustBeValid: ValidationRule = (
  projectProjection: ProjectProjection,
  isNewProject?: boolean,
  granulometry?: Granulometry
) => {
  if (granulometry) {
    const notifications: Notification[] = getProjectCasesGranulometries(granulometry).reduce(
      (accNotifications, caseGranulometry) => [
        ...accNotifications,
        ...getCaseBasementLevels(caseGranulometry).reduce((accL, levelGranulometry) => {
          const validation = getLevelPeripheralReinforcementValidation(levelGranulometry);
          const caseLabel = caseGranulometry.labelIndex;
          const levelLabel = i18next.t('G-') + levelGranulometry.level;
          let levelNotifications: Notification[] = [];
          // Formwork Bench Length :
          if (!validation.formworkBenchLengthIsValid) {
            levelNotifications = [
              ...levelNotifications,
              {
                title:
                  'La longueur de banquette de coffrage pour le niveau ' +
                  levelLabel +
                  ' de la cage ' +
                  caseLabel +
                  ' n’est pas valide.',
                explanation:
                  'La longueur de banquette maximale admise est de ' +
                  getLevelFormworkBenchLengthMaxValue() +
                  'm.',
                type: 'error'
              }
            ];
          }
          // Contiguous Against Built Linear :
          if (!validation.contiguousAgainstBuiltLinearIsValid) {
            levelNotifications = [
              ...levelNotifications,
              {
                title:
                  'Le linéaire mitoyenneté contre bâti pour le niveau ' +
                  levelLabel +
                  ' de la cage ' +
                  caseLabel +
                  ' n’est pas valide.',
                explanation:
                  'Le linéaire mitoyenneté contre bâti maximum pour le niveau ' +
                  levelLabel +
                  ' de la cage ' +
                  caseLabel +
                  ' est de ' +
                  getLevelContiguousAgainstBuiltLinearMaxValue(levelGranulometry) +
                  'm.',
                type: 'error'
              }
            ];
          }
          // Contiguous Under Built Linear :
          if (!validation.contiguousUnderBuiltLinearIsValid) {
            levelNotifications = [
              ...levelNotifications,
              {
                title:
                  'Le linéaire mitoyenneté sous bâti pour le niveau ' +
                  levelLabel +
                  ' de la cage ' +
                  caseLabel +
                  ' n’est pas valide.',
                explanation:
                  'Le linéaire mitoyenneté sous bâti maximum pour le niveau ' +
                  levelLabel +
                  ' de la cage ' +
                  caseLabel +
                  ' est de ' +
                  getLevelContiguousUnderBuiltLinearMaxValue(levelGranulometry) +
                  'm.',
                type: 'error'
              }
            ];
          }
          // Contiguous Not Built Linear :
          if (!validation.contiguousNotBuiltLinearIsValid) {
            levelNotifications = [
              ...levelNotifications,
              {
                title:
                  'Le linéaire mitoyenneté non bâti pour le niveau ' +
                  levelLabel +
                  ' de la cage ' +
                  caseLabel +
                  ' n’est pas valide.',
                explanation:
                  'Le linéaire mitoyenneté non bâti maximum pour le niveau ' +
                  levelLabel +
                  ' de la cage ' +
                  caseLabel +
                  ' est de ' +
                  getLevelContiguousNotBuiltLinearMaxValue(levelGranulometry) +
                  'm.',
                type: 'error'
              }
            ];
          }
          return [...accL, ...levelNotifications];
        }, [] as Notification[])
      ],
      [] as Notification[]
    );
    return notifications.length !== 0 ? notifications : true;
  }
  return true;
};
