import { BuildingProjection } from '../../../projection/buildings/BuildingProjection';
import { BuildingGranulometry } from '../BuildingGranulometry';
import { getBuildingDefaultName } from '../../../specification/buildings/queries/getBuildingDefaultName';
import { getBuildingDefaultTitle } from '../../../specification/buildings/queries/getBuildingDefaultTitle';

export const createBuildingGranulometry = (
  buildingProjection: BuildingProjection,
  buildingIndex: number
): BuildingGranulometry => ({
  id: buildingProjection.id,
  labelIndex: buildingIndex,
  name: getBuildingDefaultName(buildingIndex),
  title: buildingProjection.title || getBuildingDefaultTitle(buildingIndex),
  cases: []
});
