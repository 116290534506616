import { CaseGranulometry } from '../../../CaseGranulometry';
import { getCasePropertyConvertedValue } from '../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';

export const getCaseSmokeExtractionDuctSectionsCount = (
  caseGranulometry: CaseGranulometry
): number =>
  getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'smokeExtractionDuctsCount'
  ) as number;
