import { getSectionName, Section } from '../Section';
import i18next from 'i18next';
import { MetaSectionName } from './MetaSection';

interface FirstBasementLevelMetaSectionProps {
  circulationLaneInfraLength: number;
  ceilingNetHeight: number;
}

export interface FirstBasementLevelMetaSection extends Section {
  name: MetaSectionName.FirstBasementLevelMetaSection;
  circulationLaneInfraLength: number;
  ceilingNetHeight: number;
}

const firstBasementLevelMetaSection = ({
  circulationLaneInfraLength,
  ceilingNetHeight
}: FirstBasementLevelMetaSectionProps): FirstBasementLevelMetaSection => ({
  // Section
  id: 'NOID',
  name: MetaSectionName.FirstBasementLevelMetaSection,
  title: i18next.t('firstBasementLevelMetaSection.title'),
  type: 'meta',
  displayedSurface: 0,
  circulationLaneInfraLength,
  ceilingNetHeight
});

export default firstBasementLevelMetaSection;

export const isSectionAFirstBasementLevelMetaSection = (
  section: Section
): section is FirstBasementLevelMetaSection =>
  getSectionName(section) === MetaSectionName.FirstBasementLevelMetaSection;
