import {
  BuildingId,
  BuildingSpecification
} from '../../domain/specification/buildings/BuildingSpecification';

export const BUILDING_SPECIFICATION_CHANGED = 'BUILDING_SPECIFICATION_CHANGED';

export const buildingSpecificationChanged = (
  buildingId: BuildingId,
  buildingSpecification: Partial<BuildingSpecification>
) => ({
  type: BUILDING_SPECIFICATION_CHANGED,
  payload: { buildingId, buildingSpecification }
});

export type BuildingSpecificationChangedAction = ReturnType<typeof buildingSpecificationChanged>;
