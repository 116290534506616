import { ReportEntry, reportEntryLabels } from '../../../../../domain/report/ReportEntry';
import { DataType } from '../../../../../domain/DataType';
import ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { cleanFileName } from '../utils/cleanFileName';

export const downloadExcel = (
  report: Partial<ReportEntry>[],
  dataType: DataType,
  projectName: string,
  callback: () => void
) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(
    projectName.replace(/[*?:\\/[\]]/g, ' ') + ' – ' + dataType.label
  );
  worksheet.columns = Object.keys(report[0]).reduce((acc, column) => {
    return [...acc, { header: reportEntryLabels[column] || column, key: column /*, width: 10 */ }];
  }, [] as Partial<ExcelJS.Column>[]);
  worksheet.addRows(report, 'i');
  worksheet.getRow(1).font = { bold: true };
  const fileName =
    'gobuild-' +
    cleanFileName(projectName) +
    '-' +
    cleanFileName(dataType.label) +
    '-' +
    new Date().toISOString().slice(0, 10) +
    '.xlsx';
  workbook.xlsx.writeBuffer().then(function (buffer) {
    FileSaver.saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
    callback();
  });
};
