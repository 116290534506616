import { matchesPath } from '../../utils/matchesPath';
import { orCreate } from '../../utils/orCreate';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type CaseGranulometryRouteParams = {
  projectId: string;
  caseId: string;
};
export const caseGranulometryRoute: RouteWithMakePath<CaseGranulometryRouteParams> = {
  path: `/project/:projectId/cases/:caseId/granulometry`,
  makePath: ({ projectId, caseId }) =>
    `/project/${orCreate(projectId)}/cases/${caseId}/granulometry`,
  matchesPath: matchesPath<CaseGranulometryRouteParams>(
    /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry/,
    {
      projectId: 1,
      caseId: 2
    }
  )
};
