import { LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { getTopLevelHoppersSurface } from '../getTopLevelHoppersSurface';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelRealBuiltSurface } from './getTopLevelRealBuiltSurface';

export const getTopLevelRealBuiltSurfaceEff = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled
): number => {
  return (
    getTopLevelRealBuiltSurface(levelGranulometry, caseGranulometry.initialSpecifications) +
    getTopLevelHoppersSurface(caseGranulometry, levelGranulometry)
  );
};
