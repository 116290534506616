import { Action } from 'redux';

export const UNDO = 'UNDO';

export interface UndoAction extends Action {
  type: typeof UNDO;
}

export const undo = (): UndoAction => ({
  type: UNDO
});
