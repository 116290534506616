import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'components/ui/Tooltip';
import { useSelector } from 'react-redux';
import { selectGranulometry } from '../../../../store/selectors/granulometry/current.selector';
import { SQUARE_METER_SCALE } from '../../../../constants/appConstants';
import { getTopLevelGrossFloorSurface } from '../../../../domain/granulometry/levels/queries/topLevels/surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurface';
import { roundWith2Decimal } from '../../../../utils/round/roundWith2Decimal';

export interface BuildingMapLevelSurfaceRulersProps {
  buildingId: string;
  caseId: string;
  id: string;
  level: number;
  direction: string;
  realBuiltSurface: number;
  surfaceForSale: number;
  rbsRulerWidth?: number;
  rbsRulerLeft?: number;
  sfsRulerWidth?: number;
  sfsRulerLeft?: number;
}

const BuildingMapLevelSurfaceRulers: React.FC<BuildingMapLevelSurfaceRulersProps> = (props) => {
  const {
    buildingId,
    caseId,
    level,
    direction,
    realBuiltSurface,
    surfaceForSale,
    rbsRulerWidth,
    rbsRulerLeft,
    sfsRulerWidth,
    sfsRulerLeft
  } = props;

  const { t } = useTranslation();

  const granulometry = useSelector(selectGranulometry);
  const buildingGranulometry = granulometry?.find((bG) => bG.id === buildingId);
  const caseGranulometry = buildingGranulometry?.cases.find((cG) => cG.id === caseId);
  const levelGranulometry = caseGranulometry?.levels.find((lG) => lG.level === level);

  if (!levelGranulometry) return null;

  /* const grossFloorSurfaceEff = levelGranulometry.grossFloorSurfaceEff || 0;
  const gfsEffRulerWidth = grossFloorSurfaceEff * SQUARE_METER_SCALE;
  const gfsEffRulerLeft = (levelGranulometry.surface - grossFloorSurfaceEff) * SQUARE_METER_SCALE; */

  const grossFloorSurface = getTopLevelGrossFloorSurface(caseGranulometry, levelGranulometry);
  const gfsRulerWidth = grossFloorSurface * SQUARE_METER_SCALE;
  const gfsRulerLeft = (levelGranulometry.surface - grossFloorSurface) * SQUARE_METER_SCALE;

  // Tooltips ans styles :

  /* const gfsEffRulerTooltip: string =
    t('GFS EFF') + '\u00A0: ' + roundWith2Decimal(grossFloorSurfaceEff) + '\u00A0m\u00B2';
  const gfsEffRulerStyle: React.CSSProperties = { width: gfsEffRulerWidth + 'px' };
  if (direction === 'ltr') gfsEffRulerStyle.left = gfsEffRulerLeft + 'px';
  if (direction === 'rtl') gfsEffRulerStyle.right = gfsEffRulerLeft + 'px'; */

  const gfsRulerTooltip: string =
    t('GFS') + '\u00A0: ' + roundWith2Decimal(grossFloorSurface) + '\u00A0m\u00B2';
  const gfsRulerStyle: React.CSSProperties = { width: gfsRulerWidth + 'px' };
  if (direction === 'ltr') gfsRulerStyle.left = gfsRulerLeft + 'px';
  if (direction === 'rtl') gfsRulerStyle.right = gfsRulerLeft + 'px';

  const rbsRulerTooltip: string =
    t('RBS') + '\u00A0: ' + roundWith2Decimal(realBuiltSurface) + '\u00A0m\u00B2';
  const rbsRulerStyle: React.CSSProperties = { width: rbsRulerWidth + 'px' };
  if (direction === 'ltr') rbsRulerStyle.left = rbsRulerLeft + 'px';
  if (direction === 'rtl') rbsRulerStyle.right = rbsRulerLeft + 'px';

  const sfsRulerTooltip: string =
    t('SFS') + '\u00A0: ' + roundWith2Decimal(surfaceForSale) + '\u00A0m\u00B2';
  const sfsRulerStyle: React.CSSProperties = { width: sfsRulerWidth + 'px' };
  if (direction === 'ltr') sfsRulerStyle.left = sfsRulerLeft + 'px';
  if (direction === 'rtl') sfsRulerStyle.right = sfsRulerLeft + 'px';

  return (
    <>
      {/* grossFloorSurfaceEff && (
        <div className="surfaceRuler surfaceRulerGfsEff tooltipped" style={gfsEffRulerStyle}>
          <Tooltip>{gfsEffRulerTooltip}</Tooltip>
        </div>
      ) */}
      {process.env.GB_FEAT_GFS === 'true' && grossFloorSurface && (
        <div className="surfaceRuler surfaceRulerGfs tooltipped" style={gfsRulerStyle}>
          <Tooltip>{gfsRulerTooltip}</Tooltip>
        </div>
      )}
      {realBuiltSurface > 0 && (
        <div className="surfaceRuler surfaceRulerRbs tooltipped" style={rbsRulerStyle}>
          <Tooltip>{rbsRulerTooltip}</Tooltip>
        </div>
      )}
      {surfaceForSale > 0 && (
        <div className="surfaceRuler surfaceRulerSfs tooltipped" style={sfsRulerStyle}>
          <Tooltip>{sfsRulerTooltip}</Tooltip>
        </div>
      )}
    </>
  );
};

export default BuildingMapLevelSurfaceRulers;
