import { CaseSpecification } from '../../../CaseSpecification';
import { getCaseOutsideWallsThickness } from '../../get/walls/getCaseOutsideWallsThickness';
import { LevelSpecification } from '../../../../levels/LevelSpecification';

export const getLevelOutsideWallsSurfaceFromLevelsGeometry = (
  caseSpecification: CaseSpecification,
  levelSpecification: LevelSpecification,
  includeInsulation: boolean
): number => {
  const outsideWallsThickness = getCaseOutsideWallsThickness(caseSpecification, includeInsulation);
  return levelSpecification.geometry
    ? levelSpecification.geometry.properties.facades.reduce(
        (acc, facade) =>
          acc + Math.max(0, facade.distance || 0 - outsideWallsThickness) * outsideWallsThickness,
        0
      )
    : 0;
};
