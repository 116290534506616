import classNames from 'classnames';
import * as React from 'react';
import { CSSProperties } from 'react';
import { Button, ButtonApperance, ButtonColor, ButtonProps, ButtonSize } from './Button';

export interface ButtonGroupProps {
  id?: string;
  buttons: ButtonProps[];
  className?: string;
  style?: React.CSSProperties;
  fullWidth?: boolean;
  disabled?: boolean;
  appearance?: ButtonApperance;
  color?: ButtonColor;
  size?: ButtonSize;
  initialSelectedKey?: number;
  forcedSelectedKey?: number;
  vertical?: boolean;
}

export const ButtonGroup = (props: ButtonGroupProps) => {
  const {
    id,
    buttons,
    className,
    style,
    fullWidth,
    disabled,
    appearance,
    color,
    size,
    initialSelectedKey,
    forcedSelectedKey,
    vertical
  } = props;

  const [selectedKey, setSelectedKey] = React.useState(initialSelectedKey);

  const classes = classNames(
    'gb-button-group',
    {
      'gb-button-group--full-width': fullWidth
    },
    {
      'gb-button-group--vertical': vertical
    },
    {
      'gb-button-group--horizontal': !vertical
    },
    className
  );

  const buttonStyle: any = {};
  if (fullWidth) {
    buttonStyle.width = 100 / buttons.length + '%';
  }

  const handleButtonClick =
    (key: number, buttonProps: ButtonProps) =>
    (...args: any[]) => {
      setSelectedKey(key);
      if (buttonProps.handleClick) {
        buttonProps.handleClick(...args);
      }
    };

  return (
    <div id={id} className={classes} style={style}>
      {buttons.map((buttonProps: ButtonProps, key: number) => (
        <Button
          key={key}
          size={size || 'large'}
          disabled={disabled || buttonProps.disabled}
          appearance={appearance || 'outline'}
          color={color || buttonProps.color}
          fullWidth={false}
          selected={key === (forcedSelectedKey || selectedKey)}
          {...buttonProps}
          style={buttonProps.style ? { ...buttonStyle, ...buttonProps.style } : buttonStyle}
          handleClick={handleButtonClick(key, buttonProps)}
        />
      ))}
    </div>
  );
};
