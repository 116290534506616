import { createSelector, Selector } from 'reselect';
import { State } from '../../../../reducers';
import { selectIsCaseGranulometryEditBasementsOpened } from '../casesPanel/isCaseGranulometryEditBasementsOpened.selector';
import { selectIsCaseGranulometryEditEnergyAndNetworksOpened } from '../casesPanel/isCaseGranulometryEditEnergyAndNetworksOpened.selector';
import { selectIsCaseGranulometryEditFifthFacadeOpened } from '../casesPanel/isCaseGranulometryEditFifthFacadeOpened.selector';
import { selectIsCaseGranulometryEditGroundAdaptationsOpened } from '../casesPanel/isCaseGranulometryEditGroundAdaptationsOpened.selector';
import { selectIsCaseGranulometryEditInfrastructureOpened } from '../casesPanel/isCaseGranulometryEditInfrastructureOpened.selector';
import { selectIsCaseGranulometryEditOtherPropertiesOpened } from '../casesPanel/isCaseGranulometryEditOtherPropertiesOpened.selector';
import { selectIsCaseGranulometryEditSuperstructureOpened } from '../casesPanel/isCaseGranulometryEditSuperstructureOpened.selector';
import { selectIsProjectDetailsFloorSpaceOpened } from '../projectPanel/isProjectDetailsGeometryOpened.selector';
import { selectIsProjectDetailsGingerOpened } from '../projectPanel/isProjectDetailsGingerOpened.selector';
import { selectIsCaseGranulometryEditGeometryOpened } from '../casesPanel/isCaseGranulometryEditGeometryOpened.selector';
import { selectIsCaseGranulometryEditLevelGeometryOpened } from '../casesPanel/isCaseGranulometryEditLevelGeometryOpened.selector';

export const selectIsAnyWindowsOpened: Selector<State, boolean> = createSelector(
  [
    selectIsCaseGranulometryEditBasementsOpened,
    selectIsCaseGranulometryEditGeometryOpened,
    selectIsCaseGranulometryEditLevelGeometryOpened,
    selectIsCaseGranulometryEditEnergyAndNetworksOpened,
    selectIsCaseGranulometryEditFifthFacadeOpened,
    selectIsCaseGranulometryEditGroundAdaptationsOpened,
    selectIsCaseGranulometryEditInfrastructureOpened,
    selectIsCaseGranulometryEditOtherPropertiesOpened,
    selectIsCaseGranulometryEditSuperstructureOpened,
    selectIsProjectDetailsFloorSpaceOpened,
    selectIsProjectDetailsGingerOpened
  ],
  (
    isBasementsOpened,
    isCaseFloorSpaceOpened,
    isCaseLevelGeometryOpened,
    isCaseEnergyAndNetworksOpened,
    isCaseFifthFacadeOpened,
    isCaseGroundAdaptationsOpened,
    isCaseInfrastructureOpened,
    isCaseOtherPropertiesOpened,
    isCaseSuperstructureOpened,
    isProjectDetailsFloorSpaceOpened,
    isProjectDetailsGingerOpened
  ) =>
    isBasementsOpened ||
    isCaseFloorSpaceOpened ||
    isCaseLevelGeometryOpened ||
    isCaseEnergyAndNetworksOpened ||
    isCaseFifthFacadeOpened ||
    isCaseGroundAdaptationsOpened ||
    isCaseInfrastructureOpened ||
    isCaseOtherPropertiesOpened ||
    isCaseSuperstructureOpened ||
    isProjectDetailsFloorSpaceOpened ||
    isProjectDetailsGingerOpened
);
