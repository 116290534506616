import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { CaseProjection } from '../../projection/cases/CaseProjection';
import { caseRealBuiltSurfaceIsSmallerThanTheMinimum } from './caseRealBuiltSurfaceIsSmallerThanTheMinimum';
import { caseRealBuiltSurfaceIsLargerThanTheMaximum } from './caseRealBuiltSurfaceIsLargerThanTheMaximum';

export const caseRealBuiltSurfaceIsValid = (
  projectProjection: ProjectProjection,
  caseProjection: CaseProjection
): boolean =>
  !caseRealBuiltSurfaceIsSmallerThanTheMinimum(projectProjection, caseProjection) &&
  !caseRealBuiltSurfaceIsLargerThanTheMaximum(projectProjection, caseProjection);
