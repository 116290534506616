import { SectionWithExposureRate } from '../../../SectionWithExposureRate';
import {
  LevelGranulometry,
  LevelGranulometryWithLodgments
} from '../../../../levels/LevelGranulometry';
import { getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsLinear } from '../../../../levels/queries/topLevels/walls/includedInSurfaceForSaleSectionWalls/getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsLinear';
import { getSectionTheoreticalOutsideWallsLinear } from '../theoreticalWalls/outsideWalls/getSectionTheoreticalOutsideWallsLinear';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';

export const getLodgmentSectionOutsideWallsLinearLevelRatio = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithLodgments | LevelGranulometry,
  section: SectionWithExposureRate
): number =>
  getSectionTheoreticalOutsideWallsLinear(caseGranulometry, levelGranulometry, section) /
  getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsLinear(
    caseGranulometry,
    levelGranulometry
  );
