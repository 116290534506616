import * as R from 'ramda';
import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import { removeCaseProperty } from '../utils/removeCaseProperty';

export const migrateProject14To15 = (project: Project): Project =>
  mapCases((caseSpecification) =>
    R.pipe<[CaseSpecification], CaseSpecification>((cS) =>
      removeCaseProperty(cS, 'minimumBearingSurface')
    )(caseSpecification)
  )(project);
