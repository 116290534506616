import { createSelector, Selector } from 'reselect';
import { ifDefined } from '../../../utils/ifDefined';
import { selectGranulometry } from './current.selector';
import { getLodgmentsSurfaceByBuildingCaseLevelAndType } from '../../../domain/granulometry/queries/getLodgmentsSurfaceByBuildingCaseLevelAndType';
import { State } from '../../../store/reducers';
import { Maybe } from '../../../utils/Maybe';
import { LodgmentsSurfaceByLevelAndType } from '../../../domain/granulometry/cases/queries/sections/surfaces/getCaseLodgmentsSurfaceByLevelAndType';

type LodgmentsSurfaceByBuildingCaseLevelAndType = Maybe<
  Record<string, Record<string, LodgmentsSurfaceByLevelAndType>>
>;

export const selectLodgmentsSurfaceByBuildingCaseLevelAndType: Selector<
  State,
  LodgmentsSurfaceByBuildingCaseLevelAndType
> = createSelector([selectGranulometry], ifDefined(getLodgmentsSurfaceByBuildingCaseLevelAndType));
