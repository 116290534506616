import classNames from 'classnames';
import * as React from 'react';

interface NoteProps {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  strong?: boolean;
  separatorBefore?: boolean;
}

export const Note = ({ children, className, style, strong, separatorBefore }: NoteProps) => (
  <div
    className={classNames('note', className, {
      strong,
      'separator-before': separatorBefore
    })}
    style={style}>
    {children}
  </div>
);
