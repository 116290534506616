import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectIsReportOutdated } from 'store/selectors/report/isReportOutdated.selector';
import { selectBuildingMapFocusLabel } from 'store/selectors/buildingMap/buildingMapFocusLabel.selector';
import { GoBuildIcons } from 'utils/icons/iconObjectIcons';
import { roundWithNoDecimal } from 'utils/round/roundWithNoDecimal';
import { IconObject } from '../../ui/Icons/iconObject';
import { APP_COLOR, ERROR_COLOR, WARNING_COLOR } from '../../../constants/appConstants';
import { Tooltip } from '../../ui/Tooltip';
import { formatNumber, getFocusLabel } from './DataBar';
import { selectProjectRE2020Threshold } from '../../../store/selectors/project/projectRE2020ThresholdMode';
import {
  activatedValueOfRE2020
} from '../../business/panels/project/ProjectPanelView/ProjectToleranceRate/ProjectRE2020Thresold';
import {
  selectFocusedEnvironmentalIndicators
} from '../../../store/selectors/cstb/focusedEnvironmentalIndicators.selector';
import {
  COM_ETH_IC_CONSTRUCTION_DEFAULT_TOLERANCE_RATE
} from '../../../domain/project/ProjectCstbIndicatorsParameters';
import {
  selectProjectComEthIcConstructionToleranceRate
} from '../../../store/selectors/project/projectComEthIcConstructionToleranceRate.selector';
import { projectRE2020ThresholdChanged } from '../../../store/actions/projectRE2020TypeChanged.action';

interface DataBarItemProps {
  title: string;
  shortenedTitle?: string;
  description?: string;
  iconName?: GoBuildIcons;
  iconColor?: string;
  iconColor2?: string;
  value?: number;
  maxValue?: number | '?';
  toleratedMaxValue?: number;
  hiddenValue?: boolean;
  unit?: string;
  shortenedUnit?: string;
  addressIsNeeded?: boolean;
}

export const DataBarItem = ({
  title,
  shortenedTitle,
  description,
  iconName,
  iconColor,
  iconColor2,
  value,
  maxValue,
  toleratedMaxValue,
  hiddenValue,
  unit,
  shortenedUnit,
  addressIsNeeded
}: DataBarItemProps) => {
  const outdated = useSelector(selectIsReportOutdated);
  const buildingMapLabelledFocus = useSelector(selectBuildingMapFocusLabel);
  let projectRE2020ThresholdMode = useSelector(selectProjectRE2020Threshold);
  const { t } = useTranslation();

  const intituleRE2020 = () => {
    //Patch pour les projets ne contenant pas de referentiel RE2020
    if(projectRE2020ThresholdMode === undefined){
      projectRE2020ThresholdMode= {
        threshold:activatedValueOfRE2020()
      };
    }

    if(projectRE2020ThresholdMode.threshold === undefined){
      return "RE"+activatedValueOfRE2020();
    }else{
      const REChoice = projectRE2020ThresholdMode.threshold;
      return "RE"+REChoice;
    }
  }

  return (
    <div className="item">
      {iconName && (
        <IconObject
          type="menu"
          iconName={iconName}
          color={iconColor || APP_COLOR}
          color2={iconColor2}
          clickable={false}
        />
      )}
      <span className="value">
        {shortenedTitle ? shortenedTitle + (!hiddenValue ? '\u00A0:\u00A0' : '') : ''}
        {value === undefined || outdated ? '?' : !hiddenValue ? formatNumber(value) : ''}
      </span>
      {(!hiddenValue || unit === 'DH') && (shortenedUnit || unit) && (
        <span className="unit">{shortenedUnit || unit}</span>
      )}
      <Tooltip>
        {title} <span>[{getFocusLabel(buildingMapLabelledFocus, t)}]</span>
        {'\u00A0'}:<br />
        {value === undefined || outdated ? '?' : formatNumber(value)}
        {unit ? '\u00A0' + unit : ''}
        {description && <div className="description">{description}</div>}
        {maxValue && (
          <div>
            {intituleRE2020()} : {maxValue === '?' ? maxValue : formatNumber(maxValue)}
            {unit ? '\u00A0' + unit : ''}
          </div>
        )}
        {(value === undefined || outdated) && (
          <div className="outdated">
            {'Cette donnée n’est pas encore disponible pour cette modélisation. Il vous faut ' +
              (addressIsNeeded ? 'renseigner une adresse et ' : '') +
              'relancer un calcul du projet pour pouvoir l’afficher.'}
          </div>
        )}
        {maxValue !== undefined && maxValue !== '?' && toleratedMaxValue !== undefined && (
          <div className={iconColor === WARNING_COLOR ? 'alert' : undefined}>
            {iconColor === WARNING_COLOR
              ? toleratedMaxValue > maxValue
                ? `Valeur maximale dépassée mais toujours sous le seuil de tolérance calculé à ${
                    roundWithNoDecimal(toleratedMaxValue) + (unit ? '\u00A0' + unit : '')
                  }`
                : `Seuil de tolérance calculé à ${
                    roundWithNoDecimal(toleratedMaxValue) + (unit ? '\u00A0' + unit : '')
                  } dépassé mais toujours sous la valeur maximale.`
              : `Seuil de tolérance : ${
                  roundWithNoDecimal(toleratedMaxValue) + (unit ? '\u00A0' + unit : '')
                }`}{' '}
            (Tolérance paramétrée à {roundWithNoDecimal((toleratedMaxValue * 100) / maxValue) - 100}
            %)
          </div>
        )}
        {maxValue !== undefined && maxValue !== '?' && iconColor === ERROR_COLOR && (
          <div className="error">
            {toleratedMaxValue && toleratedMaxValue > maxValue
              ? `Valeur maximale et seuil de tolérance dépassés !`
              : `Valeur maximale dépassée !`}
          </div>
        )}
      </Tooltip>
    </div>
  );
};
