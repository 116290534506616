import * as React from 'react';
import Draggable from 'react-draggable';
import ReactResizeDetector from 'react-resize-detector';
import BuildingMap from './BuildingMap';

interface Props {
  positionX: number;
  positionY: number;
  emptyPosition: () => void;
  refreshFocus: () => void;
  reMoveOn: () => void;
  opacity: 0 | 1;
  zoom: number;
}

export const BuildingMapViewPort: React.FC<Props> = React.memo(
  ({ emptyPosition, opacity, positionX, positionY, refreshFocus, reMoveOn, zoom }) => {
    const isPositionForced = positionX || positionY;

    return (
      <div className="buildingMapWrapper">
        <ReactResizeDetector
          handleWidth={true}
          handleHeight={false}
          refreshMode="debounce"
          refreshRate={600}
          onResize={refreshFocus}>
          <div style={{ transform: `scale(${zoom})` }}>
            <Draggable
              position={isPositionForced ? { x: positionX, y: positionY } : undefined}
              onStart={isPositionForced ? emptyPosition : undefined}>
              <div className="buildingMap" style={{ opacity }}>
                <BuildingMap refreshFocus={refreshFocus} reMoveOn={reMoveOn} />
              </div>
            </Draggable>
          </div>
        </ReactResizeDetector>
      </div>
    );
  }
);
