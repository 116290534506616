import { Adapter } from '../../../utils/Adapter';
import { BuildingGranulometry } from '../../../domain/granulometry/buildings/BuildingGranulometry';
import { getBuildingLodgmentsCount } from '../../../domain/granulometry/buildings/queries/getBuildingLodgmentsCount';
import { getBuildingParkingsCount } from '../../../domain/granulometry/buildings/queries/getBuildingParkingsCount';
import { adaptCase } from './adaptCase';

export const buildingGranulometryToBackendGranulometry: Adapter<BuildingGranulometry, object> = (
  buildingGranulometry
) => ({
  id: buildingGranulometry.id,
  name: buildingGranulometry.name,
  title: buildingGranulometry.title,
  lodgmentCount: {
    name: 'Logements',
    value: getBuildingLodgmentsCount(buildingGranulometry),
    unit: ''
  },
  parkingCount: {
    name: 'Places de stationnement',
    value: getBuildingParkingsCount(buildingGranulometry),
    unit: ''
  },
  cases: buildingGranulometry.cases.map((caseGranulometry) =>
    adaptCase(buildingGranulometry, caseGranulometry)
  )
});
