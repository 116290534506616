import { ProjectProjection } from '../../ProjectProjection';
import { getProjectCasesProjections } from './getProjectCasesProjections';

export const getProjectSumOfCasesCuttedBuiltSurfaces = (
  projectProjection: ProjectProjection
): number =>
  getProjectCasesProjections(projectProjection).reduce(
    (acc, eachCase) => acc + (eachCase.projectedCuttedBuiltSurface?.value || 0),
    0
  );
