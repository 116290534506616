import * as React from 'react';
import { Input, InputColor } from '../../../../../ui/input/Input';
import { roundForSurfacesInputs } from '../../../../../../utils/round/roundForSurfacesInputs';

interface TopLevelTotalSurfaceInputProps {
  caseLabel: string;
  color: InputColor | undefined;
  totalValue: number;
}

export const TopLevelTotalSurfaceInput = ({
  caseLabel,
  color,
  totalValue
}: TopLevelTotalSurfaceInputProps) => (
  <Input
    name={`${caseLabel}TotalTopLevelSurface`}
    type="number"
    size="small"
    color={color}
    value={roundForSurfacesInputs(totalValue)}
    disabled
  />
);
