import { LevelGranulometry } from '../LevelGranulometry';
import { CaseGranulometry } from '../../cases/CaseGranulometry';
import { isRoofingLevel } from './is/isRoofingLevel';
import { isBasementLevel } from './is/isBasementLevel';
import { isTopLevel } from './is/isTopLevel';
import { isGroundLevel } from './is/isGroundLevel';
import i18next from 'i18next';

export const getLevelTitle = (
  levelGranulometry: LevelGranulometry,
  caseGranulometry: CaseGranulometry
): string => {
  if (isRoofingLevel(caseGranulometry, levelGranulometry)) {
    return i18next.t('Roofing');
  } else if (isTopLevel(caseGranulometry, levelGranulometry)) {
    return i18next.t('G+') + levelGranulometry.level;
  } else if (isGroundLevel(levelGranulometry)) {
    return i18next.t('GF');
  } else if (isBasementLevel(caseGranulometry, levelGranulometry)) {
    return i18next.t('G-') + levelGranulometry.level * -1;
  } else {
    return i18next.t('Fundations');
  }
};
