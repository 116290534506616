// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../img/background.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "html,\nbody,\n.content,\n.App,\n#root,\n.fullscreen,\n#rootInner {\n  height: 100%;\n  margin: 0;\n  width: 100%;\n}\n\nbody:not(.sb-show-main) #root,\nbody:not(.sb-show-main) #rootInner {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: bottom right;\n  background-size: cover;\n  color: rgb(var(--font-color));\n  /* background: linear-gradient(225deg, rgb(143, 177, 186) 0%, rgb(190, 211, 216) 100%); */\n  display: flex;\n  flex: 1 0 auto;\n  font-family: Barlow-Medium, sans-serif;\n  font-size: 12px;\n  overflow: hidden;\n}\n\nbody:not(.sb-show-main) #root a, body:not(.sb-show-main) #rootInner a {\n    text-decoration: none;\n  }\n\nbody:not(.sb-show-main) #root p a,\n  body:not(.sb-show-main) #root span a,\n  body:not(.sb-show-main) #rootInner p a,\n  body:not(.sb-show-main) #rootInner span a {\n    text-decoration: underline;\n  }\n", ""]);
// Exports
module.exports = exports;
