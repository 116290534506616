import { ValidationRule } from '../ValidationsRule';
import { projectCbsIsValid } from '../../checks/project/projectCbsIsValid';

import { roundWith2Decimal } from '../../../utils/round/roundWith2Decimal';
import {
  getDifferenceBetweenAllCasesCuttedBuiltSurfacesAndProjectSurface,
  sumOfAllCasesCBSIsLessThanProjectSurface,
  sumOfAllCasesCBSIsMoreThanProjectSurface
} from '../../checks/project/projectSurfaceIsEqualToSumOfCasesCuttedBuiltSurfaces';
import { getProjectSumOfCasesCuttedBuiltSurfaces } from '../../projection/project/queries/cases/getProjectSumOfCasesCuttedBuiltSurfaces';
import { ProjectProjection } from '../../projection/project/ProjectProjection';

export const sumOfAllCasesCBSMustBeEqualToProjectSurface: ValidationRule = (
  projectProjection: ProjectProjection
) => {
  if (
    projectCbsIsValid(projectProjection.cuttedBuiltSurface) &&
    sumOfAllCasesCBSIsLessThanProjectSurface(projectProjection)
  ) {
    return {
      title: 'Surface de projet non utilisée',
      explanation: `La surface définie pour le projet est de ${roundWith2Decimal(
        projectProjection.projectedCuttedBuiltSurface.value
      )}m\u00B2 mais la somme des SdP PC de l’ensemble des cages est de ${roundWith2Decimal(
        getProjectSumOfCasesCuttedBuiltSurfaces(projectProjection)
      )}m\u00B2. Il reste ${getDifferenceBetweenAllCasesCuttedBuiltSurfacesAndProjectSurface(
        projectProjection
      )}m\u00B2 de SdP PC non utilisée à l'échelle du projet. Il convient de réduire votre surface de projet ou de réduire votre surface par bâtiment.`,
      type: 'error'
    };
  } else if (
    projectCbsIsValid(projectProjection.cuttedBuiltSurface) &&
    sumOfAllCasesCBSIsMoreThanProjectSurface(projectProjection)
  ) {
    return {
      title: 'Surface de projet insuffisante',
      explanation: `La surface définie pour le projet est de ${roundWith2Decimal(
        projectProjection.projectedCuttedBuiltSurface.value
      )}m\u00B2 mais la somme des SdP PC de l’ensemble des cages est de ${roundWith2Decimal(
        getProjectSumOfCasesCuttedBuiltSurfaces(projectProjection)
      )}m\u00B2. Il manque ${
        getDifferenceBetweenAllCasesCuttedBuiltSurfacesAndProjectSurface(projectProjection) * -1
      }m\u00B2 de SdP PC à l'échelle du projet. Il convient d'augmenter votre surface de projet ou de réduire votre surface par bâtiment.`,
      type: 'error'
    };
  }
  return true;
};
