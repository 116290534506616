import { CaseGranulometry } from '../../../../domain/granulometry/cases/CaseGranulometry';
import { getCaseLodgmentSectionsCount } from '../../../../domain/granulometry/cases/queries/sections/counts/getCaseLodgmentSectionsCount';
import { getCaseLodgmentsCountByType } from '../../../../domain/granulometry/cases/queries/sections/counts/getCaseLodgmentSectionsCountByType';
import { roundWith1Decimal } from '../../../../utils/round/roundWith1Decimal';

type ComEthDwellingTypeRatio = [number, number, number, number, number];
type ComEthDwellingTypeNumber = [number, number, number, number, number];
type ComEthDwellingTypeInhabitant = [number, number, number, number, number];

export interface ComEthJsonFeatureDwellingComposition {
  dwelling_type_ratio: ComEthDwellingTypeRatio; // Ratio de T1/T2/T3/T4/T5+ (ex: [0.2, 0.2, 0.4, 0.2, 0])
  dwelling_type_number: ComEthDwellingTypeNumber; // Nombre de T1/T2/T3/T4/T5+ (ex: [2, 2, 4, 2, 0])
  dwelling_number_inhabitant: ComEthDwellingTypeInhabitant; // Nombre d’habitants par type de T1/T2/T3/T4/T5+ (ex: [2, 2, 8, 6, 0])
}

export const getComEthDwellingTypeNumber = (
  caseGranulometry: CaseGranulometry
): ComEthDwellingTypeNumber => {
  const lodgmentsCountByType = getCaseLodgmentsCountByType(caseGranulometry);
  return [
    lodgmentsCountByType.t1 || 0,
    (lodgmentsCountByType.t2 || 0) + (lodgmentsCountByType.t2c || 0),
    (lodgmentsCountByType.t3 || 0) + (lodgmentsCountByType.t3c || 0),
    (lodgmentsCountByType.t4 || 0) + (lodgmentsCountByType.t4c || 0),
    lodgmentsCountByType.t5 || 0
  ];
};

export const getComEthDwellingTypeRatio = (
  caseGranulometry: CaseGranulometry
): ComEthDwellingTypeRatio => {
  const lodgmentCount = getCaseLodgmentSectionsCount(caseGranulometry);
  const dwellingTypeNumber = getComEthDwellingTypeNumber(caseGranulometry);
  return [
    roundWith1Decimal(dwellingTypeNumber[0] / lodgmentCount),
    roundWith1Decimal(dwellingTypeNumber[1] / lodgmentCount),
    roundWith1Decimal(dwellingTypeNumber[2] / lodgmentCount),
    roundWith1Decimal(dwellingTypeNumber[3] / lodgmentCount),
    roundWith1Decimal(dwellingTypeNumber[4] / lodgmentCount)
  ];
};

export const getComEthDwellingTypeInhabitant = (
  caseGranulometry: CaseGranulometry
): ComEthDwellingTypeInhabitant => {
  const dwellingTypeNumber = getComEthDwellingTypeNumber(caseGranulometry);
  const inhabitantByType = [1, 1, 2, 3, 4];
  return [
    dwellingTypeNumber[0] * inhabitantByType[0],
    dwellingTypeNumber[1] * inhabitantByType[1],
    dwellingTypeNumber[2] * inhabitantByType[2],
    dwellingTypeNumber[3] * inhabitantByType[3],
    dwellingTypeNumber[4] * inhabitantByType[4]
  ];
};

export const getComEthJsonFeatureDwellingComposition = (
  caseGranulometry: CaseGranulometry
): ComEthJsonFeatureDwellingComposition => ({
  dwelling_type_ratio: getComEthDwellingTypeRatio(caseGranulometry),
  dwelling_type_number: getComEthDwellingTypeNumber(caseGranulometry),
  dwelling_number_inhabitant: getComEthDwellingTypeInhabitant(caseGranulometry)
});
