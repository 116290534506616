import { getSectionPartitionWallsSurface } from '../../../../../sections/queries/walls/partitionWalls/getSectionPartitionWallsSurface';
import { LodgmentSection } from '../../../../../sections/lodgmentSections/LodgmentSection';
import { LevelGranulometry, LevelGranulometryWithLodgments } from '../../../../LevelGranulometry';
import { getTopLevelLodgmentSections } from '../../sections/getTopLevelLodgmentSections';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { getTopLevelLodgmentsPartitionWallsSurface } from './getTopLevelLodgmentsPartitionWallsSurface';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelLodgmentsPartitionWallsSurfaceDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithLodgments | LevelGranulometry
): string =>
  '<b>' +
  getTopLevelLodgmentSections(levelGranulometry)
    .reduce(
      (acc, lodgmentSection: LodgmentSection) => [
        ...acc,
        roundWith2Decimal(
          getSectionPartitionWallsSurface(caseGranulometry, levelGranulometry, lodgmentSection)
        ) +
          ' m\u00B2</b> d’emprise de cloisons du <i>' +
          lodgmentSection.title.replace('Logement ', '') +
          '</i>'
      ],
      [] as string[]
    )
    .join('<br /><b>+ ') +
  '<br /><b>= ' +
  roundWith2Decimal(
    getTopLevelLodgmentsPartitionWallsSurface(caseGranulometry, levelGranulometry).value
  ) +
  ' m\u00B2</b>';
