import { CaseId } from '../../domain/specification/cases/CaseSpecification';
import { LevelId } from '../../domain/granulometry/levels/LevelGranulometry';
export const REMOVE_CASE_LEVEL_SPECIFICATION = 'REMOVE_CASE_LEVEL_SPECIFICATION';

export const removeCaseLevelSpecificationAction = ({
  caseId,
  levelId
}: {
  caseId: CaseId;
  levelId: LevelId;
}) => ({
  type: REMOVE_CASE_LEVEL_SPECIFICATION,
  payload: { caseId, levelId }
});

export type RemoveCaseLevelSpecification = ReturnType<typeof removeCaseLevelSpecificationAction>;
