import styled from 'styled-components';

/* Inspired by : https://jsfiddle.net/greypants/zgCb7/ */
export const Tooltip = styled.div.attrs(() => ({
  className: 'tooltip'
}))`
  background: ${({ color }) => color || 'rgb(var(--app-color))'};
  border-radius: 7px;
  bottom: 100%;
  box-sizing: border-box;
  color: #fff;
  display: block;
  font-size: 15px;
  left: 50%;
  line-height: 1.3;
  margin-bottom: 8px;
  max-width: 300px;
  opacity: 0;
  padding: 7px 9px 8px 9px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  transform-origin: bottom;
  transition: all 0.2s ease-out;
  z-index: 999;

  &::before {
    bottom: -20px;
    content: ' ';
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &::after {
    border-left: solid transparent 12px;
    border-right: solid transparent 12px;
    border-top: solid ${({ color }) => color || 'rgb(var(--app-color)) '} 12px;
    bottom: -10px;
    content: ' ';
    height: 0;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 0;
  }
`;
