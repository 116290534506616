import { createSelector, Selector } from 'reselect';
import { selectCurrentCaseGranulometry } from './currentCaseGranulometry.selector';
import { getCaseGranulometryTopLevelsCount } from '../../../../domain/granulometry/cases/queries/levels/counts/getCaseGranulometryTopLevelsCount';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';

export const selectCurrentCaseGranulometryTopLevelsCount: Selector<
  State,
  Maybe<number>
> = createSelector([selectCurrentCaseGranulometry], (caseGranulometry) =>
  caseGranulometry ? getCaseGranulometryTopLevelsCount(caseGranulometry) : undefined
);
