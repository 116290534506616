import { CaseId } from '../../domain/specification/cases/CaseSpecification';
import { BuildingId } from '../../domain/specification/buildings/BuildingSpecification';

export const CASE_MOVED = 'core/caseMoved';

export const caseMoved = (caseId: CaseId, buildingId: BuildingId, newCaseIndex: number) => ({
  type: CASE_MOVED,
  payload: {
    caseId,
    buildingId,
    newCaseIndex
  }
});

export type CaseMovedAction = ReturnType<typeof caseMoved>;
