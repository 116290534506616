import { matchesPath } from '../../utils/matchesPath';
import { orCreate } from '../../utils/orCreate';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type ProjectDetailsEditGingerRouteParams = { projectId: string };
export const projectDetailsEditGingerRoute: RouteWithMakePath<ProjectDetailsEditGingerRouteParams> =
  {
    path: `/project/:projectId/details/edit/ginger`,
    makePath: ({ projectId }) => `/project/${orCreate(projectId)}/details/edit/ginger`,
    matchesPath: matchesPath<ProjectDetailsEditGingerRouteParams>(
      /\/project\/([^/]+)\/details\/edit\/ginger/,
      {
        projectId: 1
      }
    )
  };
