import { createSelector, Selector } from 'reselect';
import { selectCurrentCaseGranulometry } from './currentCaseGranulometry.selector';
import { getCaseGrossFloorSurface } from '../../../../domain/granulometry/cases/queries/surfaces/getCaseGrossFloorSurface';
import { State } from '../../../reducers';
import { Surface } from '../../../../domain/specification/Surface';
import { Maybe } from '../../../../utils/Maybe';

export const selectCurrentCaseGrossFloorSurface: Selector<State, Maybe<Surface>> = createSelector(
  [selectCurrentCaseGranulometry],
  (caseGranulometry) => (caseGranulometry ? getCaseGrossFloorSurface(caseGranulometry) : undefined)
);
