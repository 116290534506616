import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { DEFAULT_RBS_TO_CBS_RATIO } from '../../../../../constants/appConstants';
import { getCaseTopLevelsOutsideWallsSurfaceFromLevelsGeometryDetails } from '../../../../specification/cases/queries/get/surfaces/getCaseTopLevelsOutsideWallsSurfaceFromLevelsGeometry.details';
import { getCaseTopLevelsOutsideWallsSurfaceFromLevelsGeometry } from '../../../../specification/cases/queries/get/surfaces/getCaseTopLevelsOutsideWallsSurfaceFromLevelsGeometry';
import { CaseGranulometry } from '../../../../granulometry/cases/CaseGranulometry';
import { getCaseGrossFloorSurface } from '../../../../granulometry/cases/queries/surfaces/getCaseGrossFloorSurface';
import { isProjectInputModeGfsEff } from '../../../../specification/project/queries/is/isProjectInputModeGfsEff';

export const getCaseRealBuiltSurfaceDetails = (caseGranulometry: CaseGranulometry): string => {
  let details: string[] = [];
  if (caseGranulometry.initialSpecifications.surfaces.realBuiltSurface) {
    details = [...details, '<i>SdP réelle saisie pour la cage:</i>'];
  } else {
    if (isProjectInputModeGfsEff(caseGranulometry.initialSpecifications)) {
      // see : projectCasesSurfaces.ts
      details = [
        ...details,
        '<b>Calcul avec le mode de saisie de la surface du projet en <i>Surface dessinée (SHOB VPP)</i> :</b>'
      ];
      details = [...details, '---'];

      details = [...details, '<i>Calcul de l’emprise réelle des voiles exposés :</i>'];
      details = [
        ...details,
        getCaseTopLevelsOutsideWallsSurfaceFromLevelsGeometryDetails(
          caseGranulometry.initialSpecifications
        )
      ];
      const caseOutsideWallsSurface = getCaseTopLevelsOutsideWallsSurfaceFromLevelsGeometry(
        caseGranulometry.initialSpecifications
      );
      details = [
        ...details,
        '<b>= ' +
          roundWith2Decimal(caseOutsideWallsSurface) +
          ' m\u00B2</b> d’emprise réelle de voiles exposés'
      ];
      details = [...details, '---'];

      details = [...details, '<i>Calcul de la SdP réelle :</i>'];
      details = [
        ...details,
        '<b>' +
          roundWith2Decimal(getCaseGrossFloorSurface(caseGranulometry).value) +
          ' m\u00B2</b> de SHOB Réelle'
      ];
      details = [
        ...details,
        '<b>- ' +
          roundWith2Decimal(caseOutsideWallsSurface) +
          ' m\u00B2</b> d’emprise réelle de voiles exposés'
      ];
    } else {
      details = [
        ...details,
        '<b>Calcul avec le mode de saisie de la surface du projet en <i>Surface saisie (SdP PC)</i> :</b>'
      ];

      details = [...details, '---'];

      details = [...details, '<i>Calcul de la SdP réelle théorique :</i>'];

      details = [
        ...details,
        '<b>' +
          roundWith2Decimal(
            caseGranulometry.initialSpecifications.projectedCuttedBuiltSurface.value
          ) +
          ' m\u00B2</b> de SdP PC'
      ];

      details = [
        ...details,
        '<b>/ ' + DEFAULT_RBS_TO_CBS_RATIO + '</b> de ratio Sdp réelle/Sdp PC (par défaut)'
      ];

      details = [
        ...details,
        '<b>= ' +
          roundWith2Decimal(
            caseGranulometry.initialSpecifications.projectedTheoreticalRealBuiltSurface.value
          ) +
          ' m\u00B2</b> de SdP réelle théorique'
      ];

      details = [...details, '---'];

      details = [...details, '<i>Calcul de la SdP réelle minimale :</i>'];

      details = [
        ...details,
        '<b>' +
          roundWith2Decimal(
            caseGranulometry.initialSpecifications.projectedTheoreticalRealBuiltSurface.value
          ) +
          ' m\u00B2</b> de SdP réelle théorique'
      ];

      details = [
        ...details,
        '<b>+ ' +
          roundWith2Decimal(
            caseGranulometry.initialSpecifications.projectedTechnicalPremiseSectionsSurface.value
          ) +
          ' m\u00B2</b> de surface de locaux techniques (tous niveaux inclus)'
      ];

      details = [
        ...details,
        '<b>/ (' +
          roundWith2Decimal(caseGranulometry.initialSpecifications.projectedRatioCbsToSfs) +
          '</b> de ratio Sdp PC/SHab <b>* ' +
          roundWith2Decimal(caseGranulometry.initialSpecifications.projectedRatioRbsToCbs) +
          '</b> de ratio Sdp réelle/Sdp PC)<b>)</b>'
      ];

      details = [
        ...details,
        '<b>= ' +
          roundWith2Decimal(
            caseGranulometry.initialSpecifications.projectedMinRealBuiltSurface.value
          ) +
          ' m\u00B2</b> de SdP réelle minimale'
      ];

      details = [...details, '---'];

      details = [...details, '<i>SdP réelle minimale utilisée si aucune valeur saisie :</i>'];
    }
  }

  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(caseGranulometry.initialSpecifications.projectedRealBuiltSurface.value) +
      ' m\u00B2</b> de SdP réelle'
  ];

  return details.join('<br />');
};
