import { ROOF_COLOR } from '../../../../constants/appConstants';
import i18next from 'i18next';
import { RoofingName } from './RoofingSection';
import { Section, SectionDrawn } from '../Section';

interface TileRoofingProps {
  displayedSurface: number;
  drawnSurface: number;
}

export interface TileRoofingSection extends Section, SectionDrawn {
  name: RoofingName.TileRoofing;
}

const tileRoofing = ({ displayedSurface, drawnSurface }: TileRoofingProps): TileRoofingSection => ({
  // Section
  id: 'NOID',
  name: RoofingName.TileRoofing,
  title: i18next.t('tileRoofing.title'),
  type: 'roofing',
  displayedSurface,
  // SectionDrawn
  surface: drawnSurface,
  color: ROOF_COLOR,
  icon: {
    type: 'object',
    iconName: 'tileRoofing',
    color: ROOF_COLOR
  },
  ceilingHeight: 2.5
});

export default tileRoofing;
