import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelExternalInsulationThickness = (
  caseGranulometry: CaseGranulometry
): number => {
  const externalInsulationRate = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'externalInsulationRate'
  ) as number;
  const externalInsulationThickness = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'externalInsulationThickness'
  ) as number;
  return externalInsulationRate * externalInsulationThickness;
};
