import { createSelector, Selector } from 'reselect';
import { selectBuildingMapFocus } from './buildingMapFocus.selector';
import { selectProject } from '../project';
import { getBuildingLabelGivenBuildingId } from '../../../domain/core/queries/getBuildingLabelGivenBuildingId';
import { getCaseLabelGivenCaseId } from '../../../domain/core/queries/getCaseLabelGivenCaseId';
import { State } from '../../reducers';
import { BuildingMapFocus } from '../../../domain/buildingMap/BuildingMapFocus';
import { LevelId } from '../../../domain/granulometry/levels/LevelGranulometry';
import { SectionId } from '../../../domain/granulometry/sections/Section';

type BuildingMapFocusLabel =
  | string
  | { building: string }
  | { case: string }
  | { level: LevelId }
  | { section: SectionId };

export const selectBuildingMapFocusLabel: Selector<State, BuildingMapFocusLabel> = createSelector(
  [selectBuildingMapFocus, selectProject],
  (focus: BuildingMapFocus, project) => {
    if (focus === 'project' || !project) return focus;
    if ('building' in focus) {
      return { building: getBuildingLabelGivenBuildingId(project, focus.building) };
    }
    if ('case' in focus) {
      return { case: getCaseLabelGivenCaseId(project, focus.case) };
    }
    return focus;
  }
);
