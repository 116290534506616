import { BuildingId } from '../../domain/specification/buildings/BuildingSpecification';
import { CaseSpecification } from '../../domain/specification/cases/CaseSpecification';

export const CASE_ADDED = 'core/caseAdded';

/**
 * Action déclenchée lorsque l’utilisateur demande une cage supplémentaire dans un bâtiment
 * @param buildingId
 * @param caseSpec
 */
export const caseAdded = (buildingId: BuildingId, caseSpec?: CaseSpecification) => ({
  type: CASE_ADDED,
  payload: { buildingId, caseSpec }
});

export type CaseAddedAction = ReturnType<typeof caseAdded>;
