import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { FormGroup } from '../../../../ui/FormGroup';
import { selectCurrentlyEditedLevelSpecification } from '../../../../../store/selectors/navigation/toolbox/casesPanel/currentlyEditedLevelSpecification.selector';
import { BasementLevelSpecification } from '../../../../../domain/specification/levels/BasementLevelSpecification';
import { Card } from '../../../../ui/Card';
import { setCaseLevelSpecification } from '../../../../../store/actions/setCaseLevelSpecification.action';
import { selectCurrentCaseProjection } from '../../../../../store/selectors/projection/currentCase/projection.selector';
import { selectCurrentLevelOutsideWallInfraLinear } from '../../../../../store/selectors/specification/currentLevel/currentLevelOutsideWallInfraLinear.selector';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { getLevelContiguousNotBuiltLinearValidity } from '../../../../../domain/granulometry/levels/queries/peripheralReinforcements/getLevelContiguousNotBuiltLinearValidity';
import { getLevelFormworkBenchLengthMaxValue } from '../../../../../domain/granulometry/levels/queries/peripheralReinforcements/getLevelFormworkBenchLengthMaxValue';
import { getLevelFormworkBenchLengthValidity } from '../../../../../domain/granulometry/levels/queries/peripheralReinforcements/getLevelFormworkBenchLengthValidity';
import { getLevelContiguousAgainstBuiltLinearMaxValue } from '../../../../../domain/granulometry/levels/queries/peripheralReinforcements/getLevelContiguousAgainstBuiltLinearMaxValue';
import { getLevelContiguousAgainstBuiltLinearValidity } from '../../../../../domain/granulometry/levels/queries/peripheralReinforcements/getLevelContiguousAgainstBuiltLinearValidity';
import { getLevelContiguousUnderBuiltLinearMaxValue } from '../../../../../domain/granulometry/levels/queries/peripheralReinforcements/getLevelContiguousUnderBuiltLinearMaxValue';
import { getLevelContiguousUnderBuiltLinearValidity } from '../../../../../domain/granulometry/levels/queries/peripheralReinforcements/getLevelContiguousUnderBuiltLinearValidity';
import { getLevelContiguousNotBuiltLinearMaxValue } from '../../../../../domain/granulometry/levels/queries/peripheralReinforcements/getLevelContiguousNotBuiltLinearMaxValue';
import { Input } from '../../../../ui/input/Input';

export const PeripheralReinforcements = () => {
  const currentlyEditedLevel = useSelector(
    selectCurrentlyEditedLevelSpecification
  ) as BasementLevelSpecification;
  const currentlyEditedCase = useSelector(selectCurrentCaseProjection);
  const outsideWallsLinear = useSelector(selectCurrentLevelOutsideWallInfraLinear);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Formwork Bench Length
  const handleFormworkBenchLengthChange = React.useCallback(
    (newFormworkBenchLength: number | null) => {
      if (currentlyEditedCase && currentlyEditedLevel) {
        dispatch(
          setCaseLevelSpecification(currentlyEditedCase.id, {
            ...currentlyEditedLevel,
            formworkBenchLength: newFormworkBenchLength ?? undefined
          })
        );
      }
    },
    [dispatch, currentlyEditedCase, currentlyEditedLevel]
  );
  const maxFormworkBenchLengthMaxValue = getLevelFormworkBenchLengthMaxValue();

  // Against :
  const handleContiguousAgainstBuiltLinearChange = React.useCallback(
    (newContiguousAgainstBuiltLinear: number | null) => {
      if (currentlyEditedCase && currentlyEditedLevel) {
        dispatch(
          setCaseLevelSpecification(currentlyEditedCase.id, {
            ...currentlyEditedLevel,
            contiguousAgainstBuiltLinear: newContiguousAgainstBuiltLinear ?? undefined
          })
        );
      }
    },
    [dispatch, currentlyEditedCase, currentlyEditedLevel]
  );
  const contiguousAgainstBuiltLinearMaxValue = getLevelContiguousAgainstBuiltLinearMaxValue({
    outsideWallsLinear,
    contiguousUnderBuiltLinear: currentlyEditedLevel.contiguousUnderBuiltLinear,
    contiguousNotBuiltLinear: currentlyEditedLevel.contiguousNotBuiltLinear
  });

  // Under :
  const handleContiguousUnderBuiltLinearChange = React.useCallback(
    (newContiguousUnderBuiltLinear: number | null) => {
      if (currentlyEditedCase && currentlyEditedLevel) {
        dispatch(
          setCaseLevelSpecification(currentlyEditedCase.id, {
            ...currentlyEditedLevel,
            contiguousUnderBuiltLinear: newContiguousUnderBuiltLinear ?? undefined
          })
        );
      }
    },
    [dispatch, currentlyEditedCase, currentlyEditedLevel]
  );
  const contiguousUnderBuiltLinearMaxValue = getLevelContiguousUnderBuiltLinearMaxValue({
    outsideWallsLinear,
    contiguousAgainstBuiltLinear: currentlyEditedLevel.contiguousAgainstBuiltLinear,
    contiguousNotBuiltLinear: currentlyEditedLevel.contiguousNotBuiltLinear
  });

  // Not :
  const handleContiguousNotBuiltLinearChange = React.useCallback(
    (newContiguousNotBuiltLinear: number | null) => {
      if (currentlyEditedCase && currentlyEditedLevel) {
        dispatch(
          setCaseLevelSpecification(currentlyEditedCase.id, {
            ...currentlyEditedLevel,
            contiguousNotBuiltLinear: newContiguousNotBuiltLinear ?? undefined
          })
        );
      }
    },
    [dispatch, currentlyEditedCase, currentlyEditedLevel]
  );
  const contiguousNotBuiltLinearMaxValue = getLevelContiguousNotBuiltLinearMaxValue({
    outsideWallsLinear,
    contiguousUnderBuiltLinear: currentlyEditedLevel.contiguousUnderBuiltLinear,
    contiguousAgainstBuiltLinear: currentlyEditedLevel.contiguousAgainstBuiltLinear
  });

  return (
    <Card>
      <SpecialLabel label={t('Management of peripheral reinforcements')} />
      <FormGroup horizontal={true} wrap={true}>
        <Input
          disabled={true}
          style={{ width: '50%', flex: '0 0 50%' }}
          name="outsideWallsLinear"
          label={t('outsideWallLinearInfra.label')}
          info={t('outsideWallLinearInfra.infos')}
          type="number"
          value={roundWith2Decimal(outsideWallsLinear)}
          suffix="m"
        />
      </FormGroup>
      <SpecialLabel label={t('Set back of the earthwork face')} small={true} />
      <FormGroup horizontal={true} wrap={true}>
        <Input
          name="formworkBenchLength"
          label={t('formworkBenchLength.label')}
          info={t('formworkBenchLength.infos')}
          style={{ width: '50%', flex: '0 0 50%' }}
          type="number"
          min={0}
          minMax={{ min: 0, max: maxFormworkBenchLengthMaxValue, unit: 'm' }}
          color={
            getLevelFormworkBenchLengthValidity(
              currentlyEditedLevel.formworkBenchLength,
              maxFormworkBenchLengthMaxValue
            )
              ? undefined
              : 'orange'
          }
          value={
            currentlyEditedLevel.formworkBenchLength
              ? roundWith2Decimal(currentlyEditedLevel.formworkBenchLength)
              : undefined
          }
          handleChange={handleFormworkBenchLengthChange}
          placeholder={'1'}
          suffix="m"
        />
      </FormGroup>
      <SpecialLabel label={t('Contiguous linears')} small={true} />
      <FormGroup horizontal={true} wrap={true}>
        <Input
          name="contiguousAgainstBuiltLinear"
          label={t('contiguousAgainstBuiltLinear.label')}
          info={t('contiguousAgainstBuiltLinear.infos')}
          style={{ width: '50%', flex: '0 0 50%' }}
          type="number"
          min={0}
          minMax={{
            min: 0,
            max: contiguousAgainstBuiltLinearMaxValue,
            unit: 'm'
          }}
          color={
            getLevelContiguousAgainstBuiltLinearValidity(
              currentlyEditedLevel.contiguousAgainstBuiltLinear,
              contiguousAgainstBuiltLinearMaxValue
            )
              ? undefined
              : 'orange'
          }
          value={
            currentlyEditedLevel.contiguousAgainstBuiltLinear
              ? roundWith2Decimal(currentlyEditedLevel.contiguousAgainstBuiltLinear)
              : undefined
          }
          handleChange={handleContiguousAgainstBuiltLinearChange}
          placeholder={'0'}
          suffix="m"
        />
      </FormGroup>
      <FormGroup horizontal={true} wrap={true}>
        <Input
          name="contiguousUnderBuiltLinear"
          label={t('contiguousUnderBuiltLinear.label')}
          info={t('contiguousUnderBuiltLinear.infos')}
          style={{ width: '50%', flex: '0 0 50%' }}
          type="number"
          min={0}
          minMax={{ min: 0, max: contiguousUnderBuiltLinearMaxValue, unit: 'm' }}
          color={
            getLevelContiguousUnderBuiltLinearValidity(
              currentlyEditedLevel.contiguousUnderBuiltLinear,
              contiguousUnderBuiltLinearMaxValue
            )
              ? undefined
              : 'orange'
          }
          value={
            currentlyEditedLevel.contiguousUnderBuiltLinear
              ? roundWith2Decimal(currentlyEditedLevel.contiguousUnderBuiltLinear)
              : undefined
          }
          handleChange={handleContiguousUnderBuiltLinearChange}
          placeholder={'0'}
          suffix="m"
        />
      </FormGroup>
      <FormGroup horizontal={true} wrap={true}>
        <Input
          name="contiguousNotBuiltLinear"
          label={t('contiguousNotBuiltLinear.label')}
          info={t('contiguousNotBuiltLinear.infos')}
          style={{ width: '50%', flex: '0 0 50%' }}
          type="number"
          min={0}
          minMax={{ min: 0, max: contiguousNotBuiltLinearMaxValue, unit: 'm' }}
          color={
            getLevelContiguousNotBuiltLinearValidity(
              currentlyEditedLevel.contiguousNotBuiltLinear,
              contiguousNotBuiltLinearMaxValue
            )
              ? undefined
              : 'orange'
          }
          value={
            currentlyEditedLevel.contiguousNotBuiltLinear
              ? roundWith2Decimal(currentlyEditedLevel.contiguousNotBuiltLinear)
              : undefined
          }
          handleChange={handleContiguousNotBuiltLinearChange}
          placeholder={'0'}
          suffix="m"
        />
      </FormGroup>
    </Card>
  );
};
