import { ComponentPropsGetterRC } from 'react-table';

export const getTdProps: ComponentPropsGetterRC = (state, rowInfo) => {
  if (rowInfo && rowInfo.row) {
    // Add special css class to lot pivot cells (all but product pivot cells)
    if (rowInfo.groupedByPivot && rowInfo.nestingPath.length !== 5) {
      return { className: 'td-pivotLot' };
    }
  }
  return {};
};
