import * as React from 'react';
import { UseTranslationResponse } from 'react-i18next';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import {
  BuildingMapFocus,
  isFocusBuilding,
  isFocusCase,
  isFocusProject
} from '../../../domain/buildingMap/BuildingMapFocus';
import { selectBuildingMapFocusLabel } from '../../../store/selectors/buildingMap/buildingMapFocusLabel.selector';
import { selectUserLicenceType } from '../../../store/selectors/user/userLicenceType.selector';
import { BuildingId } from '../../../domain/specification/buildings/BuildingSpecification';
import { CaseId } from '../../../domain/specification/cases/CaseSpecification';
import { DataBarQuantitativeSegment } from './DataBarQuantitativeSegment';
import { DataBarCostSegment } from './DataBarCostSegment';
import { DataBarComEthIndicatorsSegment } from './DataBarComEthIndicatorsSegment';
import { selectIsDataBarDisplayed } from '../../../store/selectors/dataBar/isDataBarDisplayed';

export const formatNumber = (num: number) =>
  num
    .toFixed(0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0')
    .replace('.', ',');

export const getFocusLabel = (
  buildingMapFocus: ReturnType<typeof selectBuildingMapFocusLabel>,
  t: UseTranslationResponse['t']
): string => {
  if (!buildingMapFocus) return '';
  if (buildingMapFocus === 'project' || !buildingMapFocus) return t('project');
  if (typeof buildingMapFocus === 'object' && 'building' in buildingMapFocus)
    return `${t('Building')}\u00A0${buildingMapFocus.building}`;
  if (typeof buildingMapFocus === 'object' && 'case' in buildingMapFocus)
    return `${t('Case')}\u00A0${buildingMapFocus.case}`;
  return '';
};

export const getValueForFocus = <T,>(
  buildingMapFocus: BuildingMapFocus,
  projectValue: T | undefined,
  valueByBuilding: Record<BuildingId, T> | undefined,
  valueByCase: Record<CaseId, T> | undefined
) => {
  if (isFocusProject(buildingMapFocus)) return projectValue as T;
  if (isFocusBuilding(buildingMapFocus))
    return valueByBuilding
      ? ((valueByBuilding as Record<BuildingId, T>)[buildingMapFocus.building] as T)
      : undefined;
  if (isFocusCase(buildingMapFocus) && valueByCase)
    return valueByCase ? (valueByCase as Record<CaseId, T>)[buildingMapFocus.case] : undefined;
  return projectValue as T;
};

export const DataBar = () => {
  const licenseType = useSelector(selectUserLicenceType);
  const isDataBarDisplayed = useSelector(selectIsDataBarDisplayed);

  return isDataBarDisplayed ? (
    <div className={classNames('bar', 'data-bar')}>
      <div className="data-bar-inner">
        <DataBarQuantitativeSegment />
        {licenseType === 'Advanced' && <DataBarCostSegment />}
        {process.env.GB_FEAT_CSTB_API !== 'false' && <DataBarComEthIndicatorsSegment />}
      </div>
    </div>
  ) : null;
};
