import classNames from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../ui/Button';
import { Card } from '../../../ui/Card';
import { CardsList } from '../../../ui/CardsList';
import { Input } from '../../../ui/input/Input';
import { Note } from '../../../ui/Note';
import { SpecialLabel } from '../../../ui/SpecialLabel';
import { Form } from '../../../ui/Form';
import PanelTitle from '../PanelTitle';

interface SmartRequestsPanelViewProps {
  queryValue: any;
  updateQueryValue: any;
  onSubmit: any;
}

const SmartRequestsPanelView: React.FC<SmartRequestsPanelViewProps> = ({
  queryValue,
  updateQueryValue,
  onSubmit
}) => {
  const { t } = useTranslation();

  return (
    <div className="panel panel-smart-requests">
      <CardsList className={classNames('smartRequests-panel')}>
        <PanelTitle title={t('Smart Requests')} />
        <Card>
          <Form style={{ display: 'flex' }} onSubmit={onSubmit}>
            <Input
              name="smartRequest"
              type="text"
              value={queryValue}
              handleChange={updateQueryValue}
              style={{ flex: 'auto', marginRight: '8px' }}
              disabled={true}
            />
            <Button
              content={t('Search') as string}
              style={{ flexGrow: '0', flexShrink: '0' }}
              disabled={true}
            />
          </Form>
        </Card>
        <Card>
          <SpecialLabel label={t('Smart action results')} />
          <Note>
            Cet espace permettra de lister des données à partir d’un mot clé ou d’une référence
            d’objet.
          </Note>
          <SpecialLabel label={t('Smart data results')} />
          <Note>
            Cet espace permettra de lister des actions à partir d’un mot clé ou d’une référence
            d’objet.
          </Note>
        </Card>
      </CardsList>
    </div>
  );
};

export default SmartRequestsPanelView;
