import { LevelGranulometry } from '../LevelGranulometry';
import { CaseGranulometry } from '../../cases/CaseGranulometry';
import { isRoofingLevel } from './is/isRoofingLevel';
import { isBasementLevel } from './is/isBasementLevel';
import { isTopLevel } from './is/isTopLevel';

export const getLevelAccronym = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): string => {
  if (isRoofingLevel(caseGranulometry, levelGranulometry)) {
    return 'RL';
  } else if (isTopLevel(caseGranulometry, levelGranulometry)) {
    return 'TL' + levelGranulometry.level;
  } else if (isBasementLevel(caseGranulometry, levelGranulometry)) {
    return 'BL' + levelGranulometry.level * -1;
  } else {
    return 'FL';
  }
};
