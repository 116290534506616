export const iconFontCharacters: { [key: string]: string } = {
  '%21':
    '<path d="M250,425.05a50,50,0,0,0,50-50v-325a50,50,0,0,0-100,0v325A50,50,0,0,0,250,425.05ZM250,500a50,50,0,1,0,50,50A50,50,0,0,0,250,500Z"/>',
  '%23':
    '<path d="M450,375H400V225h50a50,50,0,0,0,0-100H400V50a50,50,0,0,0-100,0v75H200V50a50,50,0,0,0-100,0v75H50a50,50,0,0,0,0,100h50V375H50.1a50,50,0,0,0,0,100H100v75a50,50,0,0,0,100,0V475H300v75a50,50,0,0,0,100,0V475h50a50,50,0,0,0,0-100Zm-150,0H200V225H300Z"/>',
  '%25':
    '<path d="M125,250A125,125,0,1,0,0,125,125.09,125.09,0,0,0,125,250Zm0-150a25,25,0,0,1,25,25c0,27.53-50,27.53-50,0A25,25,0,0,1,125,100Zm250,250a125,125,0,1,0,125,125A125.09,125.09,0,0,0,375,349.94Zm-25,125a25,25,0,0,1,50,0C400,502.46,350,502.46,350,474.92ZM481.16,11a50.19,50.19,0,0,0-70.3,7.81L10.94,518.76a50,50,0,0,0,78.11,62.49L489,81.33A50.09,50.09,0,0,0,481.16,11Z"/>',
  '%2A':
    '<path d="M320.67,300,426.72,194a50,50,0,0,0-70.7-70.7L250,229.3l-106.06-106A50,50,0,0,0,73.21,194L179.26,300l-106,106.06a50,50,0,1,0,70.7,70.7L250,370.7,356,476.76a50,50,0,0,0,70.7-70.7Z"/>',
  '%2B':
    '<path d="M450,249.91H300v-150a50,50,0,1,0-100,0v150H50a50,50,0,0,0,0,100H200v150a50,50,0,1,0,100,0v-150H450a50,50,0,0,0,0-100Z"/>',
  '%2e':
    '<path d="M250,500c-27.6,0-50,22.4-50,50s22.4,50,50,50s50-22.4,50-50l0,0C300,522.4,277.6,500,250,500z"/>',
  '%5B':
    '<path d="M200,600H50A50,50,0,0,1,0,550V50A50,50,0,0,1,50,0H200a50,50,0,0,1,0,100H100V500H200a50,50,0,0,1,0,100Z"/>',
  '%5D':
    '<path d="M450,600H300a50,50,0,0,1,0-100H400V100H300A50,50,0,0,1,300,0H450a50,50,0,0,1,50,50V550A50,50,0,0,1,450,600Z"/>',
  '%7C': '<path d="M250,600a50,50,0,0,1-50-50V50a50,50,0,0,1,100,0V550A50,50,0,0,1,250,600Z"/>',
  '-': '<path d="M450,249.91H50a50,50,0,0,0,0,100H450a50,50,0,0,0,0-100Z"/>',
  '.': '',
  '..': '',
  '0': '<path d="M300,0H200C89.75,0,0,89.75,0,200V400C0,510.25,89.75,600,200,600H300c110.25,0,200-89.75,200-200V200C500,89.75,410.25,0,300,0ZM100,400V200A100.06,100.06,0,0,1,200,100H300a99.32,99.32,0,0,1,39.38,8.19L101.2,405.92C101.07,403.85,100,402.1,100,400Zm300,0A100.07,100.07,0,0,1,300,500H200a99.36,99.36,0,0,1-39.39-8.19L398.8,194.07c.13,2.07,1.2,3.83,1.2,5.93Z"/>',
  '1': '<path d="M450,500H400V50A50,50,0,0,0,327.63,5.28l-300,150a50,50,0,0,0,44.73,89.45L300,130.86V500H150a50,50,0,0,0,0,100H450a50,50,0,0,0,0-100Z"/>',
  '2': '<path d="M450,600H50a50,50,0,0,1-33.2-87.41L387.51,183.3c7.32-7.42,12.5-19.92,12.5-33.3a50.1,50.1,0,0,0-50-50H150a50.1,50.1,0,0,0-50,50A50,50,0,0,1,0,150C0,67.28,67.29,0,150,0H350c82.71,0,150,67.28,150,150a149.07,149.07,0,0,1-43.95,106.05L181.55,500H450a50,50,0,0,1,0,100Z"/>',
  '2sup':
    '<path d="M450,400H210a50,50,0,0,1-33.2-87.4L399.22,115.05c.78-10.54-3.71-15-9.28-15H270A10,10,0,0,0,260,110a50,50,0,0,1-100,0C160,49.33,209.38,0,270,0H389.94C450.59,0,500,49.33,500,110a109.35,109.35,0,0,1-32.23,77.84L341.51,300H450a50,50,0,0,1,0,100Z"/>',
  '3': '<path d="M419.94,240A149.48,149.48,0,0,0,450,150C450,67.28,382.74,0,300,0H100a50,50,0,0,0,0,100H300a50,50,0,0,1,0,100H200a50,50,0,0,0,0,100H300a100,100,0,0,1,0,200H50a50,50,0,0,0,0,100H300c110.23,0,200-89.75,200-200A199.72,199.72,0,0,0,419.94,240Z"/>',
  '3sup':
    '<path d="M360,400H210a50,50,0,0,1,0-100H360a40,40,0,1,0,0-80,50,50,0,0,1,0-100,10,10,0,0,0,0-20H240A50,50,0,0,1,240,0H360a110.07,110.07,0,0,1,110,110,108.82,108.82,0,0,1-12,50A139.6,139.6,0,0,1,500,260C500,337.22,437.21,400,360,400Z"/>',
  '4': '<path d="M450,0a50,50,0,0,0-50,50V300H200A100.08,100.08,0,0,1,100,200V50A50,50,0,0,0,0,50V200C0,310.25,89.76,400,200,400H400V550a50,50,0,0,0,100,0V50A50,50,0,0,0,450,0Z"/>',
  '5': '<path d="M300,200H150a50.09,50.09,0,0,1-50-50V100H400A50,50,0,0,0,400,0H50A50,50,0,0,0,0,50V150c0,82.71,67.27,150,150,150H300a100,100,0,0,1,0,200H50a50,50,0,0,0,0,100H300c110.23,0,200-89.75,200-200S410.27,200,300,200Z"/>',
  '6': '<path d="M300,200.1H200c-36.55,0-70.39,10.56-99.9,27.75V200.1a100.06,100.06,0,0,1,100-100l175-.1a25,25,0,0,1,17.68,7.32,50,50,0,1,0,70.7-70.7A124.1,124.1,0,0,0,375.09,0L200.1.1C89.84.1.1,89.84.1,200.1V399c0,.33-.1.64-.1,1C0,510.35,89.75,600,200,600H300c110.25,0,200-89.65,200-199.91S410.25,200.1,300,200.1ZM300,500H200a100,100,0,0,1-99.95-99.66c0-.09,0-.16,0-.25v-.48A100,100,0,0,1,200,300.1H300A100,100,0,1,1,300,500Z"/>',
  '7': '<path d="M150.1,600a49.1,49.1,0,0,1-25.68-7.13,49.88,49.88,0,0,1-17.19-68.56L361.72,100H50A50,50,0,0,1,50,0H450a50,50,0,0,1,42.87,75.68L193,575.68A50,50,0,0,1,150.1,600Z"/>',
  '8': '<path d="M419.94,240A149.48,149.48,0,0,0,450,150C450,67.28,382.74,0,300,0H200C117.26,0,50,67.28,50,150a149.48,149.48,0,0,0,30.07,90A199.72,199.72,0,0,0,0,400C0,510.25,89.73,600,200,600H300c110.23,0,200-89.75,200-200A199.72,199.72,0,0,0,419.94,240ZM200,100H300a50,50,0,0,1,0,100H200a50,50,0,0,1,0-100ZM300,500H200a100,100,0,0,1,0-200H300a100,100,0,0,1,0,200Z"/>',
  '9': '<path d="M300,0H200C89.76,0,0,89.75,0,200S89.76,400,200,400H300c36.59,0,70.47-10.6,100-27.83V400A100.08,100.08,0,0,1,300,500h-175a24.91,24.91,0,0,1-17.68-7.33,50,50,0,1,0-70.72,70.7A124.12,124.12,0,0,0,124.93,600H300c110.28,0,200-89.75,200-200V200C500,89.75,410.24,0,300,0Zm0,300H200a100,100,0,1,1,0-200H300a100,100,0,1,1,0,200Z"/>',
  A: '<path d="M300,0H200C89.7,0,0,89.75,0,200V550a50,50,0,0,0,100,0V447.79c3.62.84,7,2.21,10.94,2.21H389.06c3.88,0,7.31-1.37,10.94-2.21V550a50,50,0,0,0,100,0V200C500,89.75,410.3,0,300,0ZM400,352.2c-3.63-.84-7.06-2.2-10.94-2.2H110.94c-3.89,0-7.32,1.36-10.94,2.2V200A100.09,100.09,0,0,1,200,100H300A100.1,100.1,0,0,1,400,200Z"/>',
  B: '<path d="M300,600H50A50,50,0,0,1,0,550V50A50,50,0,0,1,50,0H300c82.71,0,150,67.29,150,150a149.15,149.15,0,0,1-30.07,90A199.84,199.84,0,0,1,500,400C500,510.25,410.31,600,300,600ZM100,500H300a100,100,0,0,0,0-200,50,50,0,1,1,0-100,50,50,0,0,0,0-100H100Z"/>',
  C: '<path d="M300,600H200C89.71,600,0,510.25,0,400V200C0,89.75,89.71,0,200,0H300C410.29,0,500,89.75,500,200a50,50,0,0,1-100,0A100.1,100.1,0,0,0,300,100H200A100.1,100.1,0,0,0,100,200V400A100.11,100.11,0,0,0,200,500H300A100.11,100.11,0,0,0,400,400a50,50,0,0,1,100,0C500,510.25,410.29,600,300,600Z"/>',
  D: '<path d="M300,600H50A50,50,0,0,1,0,550V50A50,50,0,0,1,50,0H300C410.3,0,500,89.75,500,200V400C500,510.25,410.3,600,300,600ZM100,500H300A100.11,100.11,0,0,0,400,400V200A100.1,100.1,0,0,0,300,100H100Z"/>',
  E: '<path d="M450,500H100V350H250a50,50,0,0,0,0-100H100V100H450A50,50,0,0,0,450,0H50A50,50,0,0,0,0,50V550a50,50,0,0,0,50,50H450a50,50,0,0,0,0-100Z"/>',
  F: '<path d="M450,0H50A50,50,0,0,0,0,50V550a50,50,0,0,0,100,0V350H250a50,50,0,0,0,0-100H100V100H450A50,50,0,0,0,450,0Z"/>',
  G: '<path d="M300,600H200C89.71,600,0,510.25,0,400V200C0,89.75,89.71,0,200,0H300A198.63,198.63,0,0,1,441.45,58.59a50,50,0,0,1-70.81,70.71A98.92,98.92,0,0,0,300,100H200A100.1,100.1,0,0,0,100,200V400A100.11,100.11,0,0,0,200,500H300A100.11,100.11,0,0,0,400,400V350H250a50,50,0,1,1,0-100H450a50,50,0,0,1,50,50V400C500,510.25,410.29,600,300,600Z"/>',
  H: '<path d="M450,0a50,50,0,0,0-50,50V250l-300,0V50A50,50,0,0,0,0,50L0,550a50,50,0,1,0,100,0l0-200,300,0,0,200a50,50,0,0,0,100,0l0-500A50,50,0,0,0,450,0Z"/>',
  I: '<path d="M450,500H300V100H450A50,50,0,0,0,450,0H50a50,50,0,0,0,0,100H200V500H50a50,50,0,0,0,0,100H450a50,50,0,0,0,0-100Z"/>',
  J: '<path d="M300,600H200C89.71,600,0,510.25,0,400a50,50,0,0,1,100,0A100.11,100.11,0,0,0,200,500H300A100.11,100.11,0,0,0,400,400V100H200A50,50,0,1,1,200,0H450a50,50,0,0,1,50,50V400C500,510.25,410.29,600,300,600Z"/>',
  K: '<path d="M282.81,262.69,482.91,87.61a50,50,0,1,0-65.82-75.2L100,289.86V50A50,50,0,0,0,0,50V550a50,50,0,0,0,100,0V422.65l107.48-94L411,581.35A50,50,0,0,0,489,518.66Z"/>',
  L: '<path d="M450,600H50A50,50,0,0,1,0,550V50a50,50,0,0,1,100,0V500H450a50,50,0,0,1,0,100Z"/>',
  M: '<path d="M450,600a50,50,0,0,1-50-50V215.13L291.62,377.73c-18.55,27.74-64.64,27.74-83.19,0L100,215.13V550A50,50,0,1,1,0,550V50A50,50,0,0,1,91.59,22.26L250,259.86,408.41,22.26A50,50,0,0,1,500,50V550A50,50,0,0,1,450,600Z"/>',
  N: '<path d="M450,600A50,50,0,0,1,411,581.26L100,192.59V550A50,50,0,0,1,0,550V50.15A50,50,0,0,1,33.44,3c20-7.42,42.24-.68,55.61,15.91L400,407.57V50.15a50,50,0,0,1,100,0V550a50,50,0,0,1-33.44,47.16A48.88,48.88,0,0,1,450,600Z"/>',
  O: '<path d="M300,600H200C89.71,600,0,510.25,0,400V200C0,89.75,89.71,0,200,0H300C410.29,0,500,89.75,500,200V400C500,510.25,410.29,600,300,600ZM200,100A100.1,100.1,0,0,0,100,200V400A100.11,100.11,0,0,0,200,500H300A100.11,100.11,0,0,0,400,400V200A100.1,100.1,0,0,0,300,100Z"/>',
  P: '<path d="M50,600A50,50,0,0,1,0,550V50A50,50,0,0,1,50,0H325c96.47,0,175,78.52,175,175S421.49,350,325,350H100V550A50,50,0,0,1,50,600Zm50-350H325a75,75,0,0,0,0-150H100Z"/>',
  Q: '<path d="M500,200C500,89.75,410.29,0,300,0H200C89.71,0,0,89.75,0,200V400C0,510.25,89.71,600,200,600H300c37.64,0,72.48-11.08,102.58-29.22l8.37,10.47a50,50,0,0,0,78.13-62.5l-16.12-20.16C489.67,469.37,500,436,500,400ZM400,400c0,2.09-1.07,3.84-1.2,5.9L289.09,268.75A50,50,0,0,0,211,331.25L339.37,491.79A99.2,99.2,0,0,1,300,500H200A100.11,100.11,0,0,1,100,400V200A100.1,100.1,0,0,1,200,100H300A100.1,100.1,0,0,1,400,200Z"/>',
  R: '<path d="M351.9,347.28c83.67-13,148.1-85,148.1-172.28C500,78.52,421.49,0,325,0H50A50,50,0,0,0,0,50V550a50,50,0,1,0,100,0V350H226l185,231.25a50,50,0,1,0,78.12-62.5ZM100,250V100H325a75,75,0,0,1,0,150Z"/>',
  S: '<path d="M325,600H125a124,124,0,0,1-88.37-36.63,50,50,0,0,1,70.69-70.7A24.93,24.93,0,0,0,125,500H325a75,75,0,0,0,0-150H175C78.51,350,0,271.48,0,175S78.51,0,175,0H375a124.08,124.08,0,0,1,88.37,36.62,50,50,0,0,1-70.69,70.7A25,25,0,0,0,375,100H175a75,75,0,0,0,0,150H325c96.47,0,175,78.51,175,175S421.49,600,325,600Z"/>',
  T: '<path d="M450,0H50a50,50,0,0,0,0,100H200V550a50,50,0,0,0,100,0V100H450A50,50,0,0,0,450,0Z"/>',
  U: '<path d="M300,600H200C89.7,600,0,510.25,0,400V50a50,50,0,0,1,100,0V400A100.1,100.1,0,0,0,200,500H300A100.11,100.11,0,0,0,400,400V50a50,50,0,0,1,100,0V400C500,510.25,410.3,600,300,600Z"/>',
  V: '<path d="M250,600a49.93,49.93,0,0,1-46.43-31.45l-200-500A50,50,0,0,1,96.45,31.43L250,415.33,403.55,31.43a50,50,0,0,1,92.87,37.11l-200,500A49.93,49.93,0,0,1,250,600Z"/>',
  W: '<path d="M150,600c-1.52,0-3-.1-4.59-.2a50,50,0,0,1-44.43-40L1,59.83A50,50,0,1,1,99,40.3l67,335,37.5-93.83c15.23-38.08,77.62-38.08,92.86,0L334,375.31l67-335a50,50,0,1,1,98,19.53L399,559.75a50,50,0,0,1-95.45,8.79l-53.6-134-53.56,134A50,50,0,0,1,150,600Z"/>',
  X: '<path d="M314.06,300l175-218.76a50,50,0,0,0-78.12-62.5L250,219.91,89.06,18.72a50,50,0,1,0-78.12,62.5L185.94,300l-175,218.77a50,50,0,0,0,78.12,62.5L250,380.06,410.94,581.25a50,50,0,1,0,78.12-62.5Z"/>',
  Y: '<path d="M450,0a50,50,0,0,0-50,50V200A100.08,100.08,0,0,1,300,300H200A100.08,100.08,0,0,1,100,200V50A50,50,0,0,0,0,50V200C0,310.25,89.76,400,200,400H300c36.59,0,70.47-10.6,100-27.83V400A100.08,100.08,0,0,1,300,500H50a50,50,0,1,0,0,100H300c110.28,0,200-89.75,200-200V50A50,50,0,0,0,450,0Z"/>',
  Z: '<path d="M450,600H50A50,50,0,0,1,11,518.75L346,100H50A50,50,0,0,1,50,0H450a50,50,0,0,1,39.06,81.25L154,500H450a50,50,0,0,1,0,100Z"/>',
  unknown:
    '<path d="M450,0H50A50,50,0,0,0,0,50V550a50,50,0,0,0,50,50H450a50,50,0,0,0,50-50V50A50,50,0,0,0,450,0ZM346,100,250,219.93,154.05,100ZM100,192.59,185.94,300,100,407.4ZM154.05,500,250,380.07,346,500Zm246-92.6L314.06,300,400,192.59Z"/>'
};
