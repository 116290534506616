import { createSelector, Selector } from 'reselect';
import { selectCurrentCaseIdFromRoute } from '../../navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { selectParentBuildingFromCaseRoute } from '../../navigation/toolbox/casesPanel/parentBuildingFromCaseRoute.selector';
import { selectLodgmentsCountByBuildingCaseTopLevelAndType } from '../lodgmentsCountByBuildingCaseTopLevelAndType.selector';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';
import { LodgmentsCountByTopLevelAndType } from '../../../../domain/granulometry/cases/queries/sections/counts/getCaseLodgmentSectionsCountByTopLevelAndType';

export const selectCurrentCaseLodgmentsCountByLevelAndType: Selector<
  State,
  Maybe<LodgmentsCountByTopLevelAndType>
> = createSelector(
  [
    selectCurrentCaseIdFromRoute,
    selectParentBuildingFromCaseRoute,
    selectLodgmentsCountByBuildingCaseTopLevelAndType
  ],
  (caseId, building, lodgmentsCountByBuildingCaseLevelAndType) =>
    caseId && building && lodgmentsCountByBuildingCaseLevelAndType
      ? lodgmentsCountByBuildingCaseLevelAndType[building.id][caseId]
      : undefined
);
