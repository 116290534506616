import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';

export const getTopLevelLodgmentsTechnicalSheathsCount = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry | LevelGranulometryWithBearingRelativeSections
): number =>
  caseGranulometry.technicalSheathCountByLevel.find((l) => l.levelIndex === levelGranulometry.level)
    ?.realSheathCount || 0;
