import { Action, applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { persistStore } from 'redux-persist';
import reduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import * as Sentry from '@sentry/react';
import { rootReducer, State } from './reducers';
import epics from './epics';
import * as R from 'ramda';

export const history = createBrowserHistory();

const epicMiddleware = createEpicMiddleware<Action, Action, State>();

const addReduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION__
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION__()
  : (f: any): any => f;

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const logger = createLogger({
  // predicate, // if specified this function will be called before each action is processed with this middleware.
  collapsed: true, // takes a Boolean or optionally a Function that receives `getState` function for accessing current store state and `action` object as parameters. Returns `true` if the log group should be collapsed, `false` otherwise.
  duration: true, // = false: Boolean, // print the duration of each action?
  timestamp: true // : Boolean, // print the timestamp with each action?

  // level = 'log': 'log' | 'console' | 'warn' | 'error' | 'info', // console's level
  // colors: ColorsObject, // colors for title, prev state, action and next state: https://github.com/evgenyrodionov/redux-logger/blob/master/src/defaults.js#L12-L18
  // titleFormatter, // Format the title used when logging actions.

  // stateTransformer, // Transform state before print. Eg. convert Immutable object to plain JSON.
  // actionTransformer, // Transform action before print. Eg. convert Immutable object to plain JSON.
  // errorTransformer, // Transform error before print. Eg. convert Immutable object to plain JSON.

  // logger = console: LoggerObject, // implementation of the `console` API.
  // logErrors = true: Boolean, // should the logger catch, log, and re-throw errors?

  // diff: // : Boolean, // (alpha) show diff between states?
  // diffPredicate // (alpha) filter function for showing states diff, similar to `predicate`
});

export const store = createStore(
  rootReducer(history),
  compose(
    applyMiddleware(reduxThunk),
    applyMiddleware(epicMiddleware),
    applyMiddleware(routerMiddleware(history)),
    process.env.NODE_ENV === 'production' ? R.identity : applyMiddleware(logger),
    process.env.SENTRY_ENABLED ? sentryReduxEnhancer : R.identity,
    addReduxDevTools
  )
);

export const persistor = persistStore(store);

epicMiddleware.run(epics);
