import * as React from 'react';
import { IconObject } from '../../../../ui/Icons/iconObject';

export const ThComponent: React.ElementType = ({
  children,
  className,
  style,
  foldedColumns,
  setFoldedColumns
}) => {
  if (!children) return null;

  let addProductHeader = false;
  if (children.type) {
    const typeToString = children.type.toString();
    if (typeToString.includes('Pivoted')) {
      children = 'Nomenclature';
      style = { ...style, width: '241px', maxWidth: '241px' };
      addProductHeader = true;
    }
  }
  const columnName = children[0]?.props ? children[0]?.props.children : undefined;
  const i = foldedColumns.indexOf(columnName);
  return (
    <>
      <div role="columnheader" tabIndex={-1} className={'rt-th ' + className} style={style}>
        <span className="columnName">{children}</span>
        {columnName !== undefined && className.match(new RegExp('noFold', 'g')) === null && (
          <div
            className="showHide"
            title={(i > -1 ? 'Afficher' : 'Masquer') + ' la colonne : ' + columnName}
            onClick={() => {
              const newFoldedColumns = [...foldedColumns];
              i > -1 ? newFoldedColumns.splice(i, 1) : newFoldedColumns.push(columnName);
              setFoldedColumns(newFoldedColumns);
            }}>
            <IconObject iconName="add" type="menu" />
          </div>
        )}
      </div>
      {addProductHeader && (
        <div
          role="columnheader"
          tabIndex={-1}
          className={'rt-th ' + className}
          style={{ ...style, width: '250px', maxWidth: '250px' }}>
          <span className="columnName">Produits</span>
        </div>
      )}
    </>
  );
};
