import { createSelector, Selector } from 'reselect';
import { selectProject } from './project.selector';
import { ifDefined } from '../../../utils/ifDefined';
import { projectCasesSurfaces } from '../../../domain/project/projectCasesSurfaces';
import { ProjectProjection } from '../../../domain/projection/project/ProjectProjection';
import { State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';

export const selectProjectProjection: Selector<State, Maybe<ProjectProjection>> = createSelector(
  [selectProject],
  ifDefined(projectCasesSurfaces)
);
