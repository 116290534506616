import { Epic, ofType } from 'redux-observable';
import { Action } from 'redux';
import { of } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { push } from 'connected-react-router';
import { addBreadcrumb, BREADCRUMB_CATEGORY } from '../../infra/sentry';
import publicRoutes from '../../routes/publicRoutes';
import { State } from '../reducers';
import userState from '../reducers/user.slice';

export const redirectToLoginOnLogoutEpic: Epic<Action, Action, State> = (actions$) =>
  actions$.pipe(
    ofType(userState.actions.logout.type),
    switchMap(() => {
      addBreadcrumb('Logged out manually', 'info', BREADCRUMB_CATEGORY.AUTH);
      return of(push(publicRoutes.Login.path)).pipe(delay(100));
    })
  );
