import { LevelGranulometryInitialEntries } from '../../../../LevelGranulometry';
import { getTopLevelRealBuiltSurface } from '../realBuiltSurface/getTopLevelRealBuiltSurface';
import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { getCaseUpperLevelsRealBuiltSurface } from '../../../../../../specification/cases/queries/levels/surfaces/getCaseDataUpperLevelsRealBuiltSurfaces';
import { getLevelMaxSurfaceForSaleFromCaseProjection } from './getLevelMaxSurfaceForSaleFromCaseProjection';
import { getSectionDisplayedSurface } from '../../../../../sections/queries/surfaces/getSectionDisplayedSurface';
import { getLevelTechnicalPermiseSections } from '../../../sections/getLevelTechnicalPermiseSections';
import { CaseProjection } from '../../../../../../projection/cases/CaseProjection';

export const getUpperLevelSurfaceForSale = (
  caseProjection: CaseProjection,
  upperLevel: LevelGranulometryInitialEntries
): number => {
  if (caseProjection.projectedMaxSurfaceForSaleHasBeenForced) {
    return getLevelMaxSurfaceForSaleFromCaseProjection(caseProjection, upperLevel);
  } else {
    const levelRealBuiltSurface = getTopLevelRealBuiltSurface(upperLevel, caseProjection);
    const ratioRbsToCbs = caseProjection.projectedRatioRbsToCbs;
    const ratioCbsToSfs = caseProjection.projectedRatioCbsToSfs;
    const caseUpperLevelsRealBuiltSurface = getCaseUpperLevelsRealBuiltSurface(caseProjection);
    const caseRealBuiltSurface = caseProjection.projectedRealBuiltSurface.value;

    // Redistribution of the negative SFS in the upper levels
    /*
    const groundLevelData = caseData.projectedTopLevels.find(l => l.level === 0) as TopLevelDataLegacy;
    const groundLevelSurfaceForSale = getGroundLevelSurfaceForSale(caseData, {
      level: 0,
      technicalPremiseSections: groundLevelData.technicalPremiseSections
    });
    */

    let upperLevelSFS = levelRealBuiltSurface;

    upperLevelSFS *= ratioRbsToCbs * ratioCbsToSfs;

    // Hall : the hall surface is redistributed in the upperLevels
    if (caseProjection.hall) {
      upperLevelSFS +=
        (getCasePropertyConvertedValue(caseProjection, 'hallSurface') as number) *
        (levelRealBuiltSurface / (caseUpperLevelsRealBuiltSurface || 1));
    }

    // Technical premise sections
    upperLevelSFS -= getLevelTechnicalPermiseSections(upperLevel).reduce(
      (acc, technicalPremiseSection) => acc + getSectionDisplayedSurface(technicalPremiseSection),
      0
    );

    upperLevelSFS +=
      caseProjection.projectedTopLevels.reduce(
        (acc, level) =>
          acc +
          (level.technicalPremiseSections || []).reduce(
            (accTP, technicalPremiseSection) =>
              accTP + getSectionDisplayedSurface(technicalPremiseSection),
            0
          ),
        0
      ) *
      (levelRealBuiltSurface / (caseRealBuiltSurface || 1));

    // Redistribution of the negative SFS in the upper levels
    /* if (groundLevelSurfaceForSale < 0) {
      upperLevelSFS += groundLevelSurfaceForSale / getCaseDataProjectedUpperLevelCount(caseData);
    } */

    return upperLevelSFS;
  }
};
