import * as R from 'ramda';
import { LOTS_NOMENCLATURE_ID, Nomenclature } from '../../domain/Nomenclature';
import { createSelector, Selector } from 'reselect';
import { State } from '../reducers';
import { selectNomenclatures } from './nomenclatures.selector';

export const selectLotsNomenclature: Selector<State, Nomenclature | undefined> = createSelector(
  [selectNomenclatures],
  R.find((nomenclature) => nomenclature.id === LOTS_NOMENCLATURE_ID)
);
