import { createSelector, Selector } from 'reselect';
import { selectPath } from '../../getPath.selector';
import { State } from '../../../../reducers';
import { Maybe } from '../../../../../utils/Maybe';
import { caseGranulometryEditLodgmentTypeRoomRoute } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditLodgmentTypeRoomRoute';

export const selectCurrentlyEditedLodgmentTypeRoom: Selector<State, Maybe<number>> = createSelector(
  [selectPath],
  (path) => Number(caseGranulometryEditLodgmentTypeRoomRoute.matchesPath(path).params?.roomIndex)
);
