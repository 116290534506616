import * as React from 'react';
import classNames from 'classnames';
import { IconObject } from 'components/ui/Icons/iconObject';
import { BracketHorizontal } from '../../../ui/BracketHorizontal';

interface ProjectIconProps {
  locked: boolean;
  onClick: () => void;
}

export const ProjectIcon: React.FC<ProjectIconProps> = ({ locked, onClick }) => (
  <div className={classNames('project', { locked })} onClick={onClick}>
    <IconObject iconName="project" title="Project" type="menu" />
    <div className="project-brace">
      <BracketHorizontal />
    </div>
  </div>
);
