import * as R from 'ramda';
import { Project } from '../../../../project/Project';
import { BuildingSpecification } from '../../../buildings/BuildingSpecification';

export const getProjectCasesWithUnforcedCbsCount = (project: Project): number =>
  R.pipe(
    R.prop('buildings'),
    R.reduce(
      (accB, buildingSpecification: BuildingSpecification) =>
        accB +
        R.reduce(
          (accC, caseSpecification) =>
            accC +
            (R.path(['surfaces', 'cuttedBuiltSurface'], caseSpecification) === undefined ? 1 : 0),
          0,
          buildingSpecification.cases
        ),
      0
    )
  )(project);
