import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import outsideStairShaftInfra, {
  OutsideStairsShaftInfraSection
} from '../../../../../sections/outsideSections/outsideStairShaftInfra';

export const createBasementLevelOutsideStairsShaftInfraSection = (
  caseGranulometry: CaseGranulometry,
  group: string,
  id: string
): OutsideStairsShaftInfraSection => {
  const length = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'outsideStairShaftLength'
  ) as number;
  const width = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'outsideStairShaftWidth'
  ) as number;
  const surface = length * width;
  const bearingLength = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'outsideStairsShaftBearingLength'
  ) as number;
  const bearingWidth = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'outsideStairsShaftBearingWidth'
  ) as number;
  return outsideStairShaftInfra({
    id,
    surface,
    length,
    width,
    bearingLength,
    bearingWidth,
    group
  });
};
