import { polygon } from '@turf/turf';
import { Polygon, Position } from 'geojson';

export const createPolygonFromCoordinates = (
  coordinates: Position[]
): GeoJSON.Feature<GeoJSON.Polygon> => {
  const positionClosed = [...coordinates, coordinates[0]] as Position[];
  return polygon([positionClosed]);
};
export const createPolygon = (coordinates: Position[]): Polygon => {
  return {
    coordinates: [coordinates],
    type: 'Polygon'
  };
};
