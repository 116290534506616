import * as R from 'ramda';
import { TopLevelSpecification } from '../../../../levels/TopLevelSpecification';

export const getTopLevelsSpecificationsTotalForcedGrossFloorSurfaceEff = (
  levels: TopLevelSpecification[]
): number =>
  R.compose(
    R.sum,
    R.map<TopLevelSpecification, number>(R.propOr(0, 'grossFloorSurfaceEff'))
  )(levels);
