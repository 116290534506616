import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import { renameCaseProperties } from '../utils/renameCaseProperties';

export const migrateProject1To2 = (project: Project): Project =>
  mapCases((caseSpecification) => {
    return renameCaseProperties(caseSpecification, {
      notExposedWallFormworkRate: 'insideWallsFormworkRate',
      exposedWallFormworkRate: 'outsideWallsFormworkRate',
      notExposedWallThickness: 'insideWallsThickness',
      exposedWallThickness: 'outsideWallsThickness',
      notExposedWallHighAdhesionSteelRate: 'insideWallsHighAdhesionSteelRate',
      exposedWallHighAdhesionSteelRate: 'outsideWallsHighAdhesionSteelRate',
      notExposedWallWeldedMeshSteelRate: 'insideWallsWeldedMeshSteelRate',
      exposedWallWeldedMeshSteelRate: 'outsideWallsWeldedMeshSteelRate',
      lodgmentNotExposedWallPartitionRate: 'lodgmentInsideWallsPartitionRate',
      lodgmentExposedWallConcreteRatio: 'lodgmentOutsideWallsConcreteRatio',
      lodgmentExposedWallThickness: 'lodgmentOutsideWallsThickness',
      carParkExposedWallConcreteRatio: 'carParkOutsideWallsConcreteRatio',
      carBoxExposedWallConcreteRatio: 'carBoxOutsideWallsConcreteRatio'
    });
  })(project);
