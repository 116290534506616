import { CellInfo, DerivedDataObject } from 'react-table';

export const getTotalCompostionValue = (cellOrRow: CellInfo | DerivedDataObject) =>
  (cellOrRow as CellInfo).subRows || (cellOrRow as DerivedDataObject)._subRows
    ? cellOrRow.subRows
      ? cellOrRow.subRows.reduce(
          (acc: number, row: DerivedDataObject) => acc + getTotalCompostionValue(row),
          0
        )
      : (cellOrRow as DerivedDataObject)._subRows.reduce(
          (acc: number, row: DerivedDataObject) => acc + getTotalCompostionValue(row),
          0
        )
    : (cellOrRow as DerivedDataObject).compositionValue || 0;
