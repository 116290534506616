import * as React from 'react';

const defaultButton = (props) => <button {...props}>{props.children}</button>;

interface Props {
  page: number;
  pages: number;
  PageButtonComponent: any;
  onPageChange: (page: number) => void;
  previousText: string;
  nextText: string;
  gobuildCustomProps?: any;
}
interface State {
  visiblePages: any[];
}

export default class PaginationBottom extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages)
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.pages !== nextProps.pages) {
      this.setState({
        visiblePages: this.getVisiblePages(null, nextProps.pages)
      });
    }
    this.changePage(nextProps.page + 1);
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter((page) => page <= totalPages);
  };

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total);
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total];
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total];
      } else {
        return [1, 2, 3, 4, 5, total];
      }
    }
  };

  changePage = (page: number) => {
    const activePage = this.props.page + 1;
    if (page === activePage) {
      return;
    }
    const visiblePages = this.getVisiblePages(page, this.props.pages);
    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    });
    this.props.onPageChange(page - 1);
  };

  render() {
    const { PageButtonComponent = defaultButton } = this.props;
    const { visiblePages } = this.state;
    const activePage = this.props.page + 1;

    const ActionButton = this.props.gobuildCustomProps?.actionButton;

    return (
      <div className="gb-rt-bottom">
        <div className="gb-rt-bottom-left">
          <div className="gb-rt-bottom-pagination">
            <div className="gb-rt-bottom-prevPageWrapper">
              <PageButtonComponent
                className="gb-rt-bottom-pageButton"
                onClick={(event) => {
                  event.preventDefault();
                  if (activePage === 1) {
                    return;
                  }
                  this.changePage(activePage - 1);
                }}
                disabled={activePage === 1}>
                {this.props.previousText}
              </PageButtonComponent>
            </div>
            <div className="gb-rt-bottom-visiblePagesWrapper">
              {visiblePages.map((page, index, array) => {
                return (
                  <PageButtonComponent
                    key={page}
                    className={
                      activePage === page
                        ? 'gb-rt-bottom-pageButton gb-rt-bottom-pageButton--active'
                        : 'gb-rt-bottom-pageButton'
                    }
                    onClick={(event) => {
                      event.preventDefault();
                      this.changePage(page);
                    }}>
                    {array[index - 1] + 2 < page ? `...${page}` : page}
                  </PageButtonComponent>
                );
              })}
            </div>
            <div className="gb-rt-bottom-nextPageWrapper">
              <PageButtonComponent
                className="gb-rt-bottom-pageButton"
                onClick={(event) => {
                  event.preventDefault();
                  if (activePage === this.props.pages) {
                    return;
                  }
                  this.changePage(activePage + 1);
                }}
                disabled={activePage === this.props.pages}>
                {this.props.nextText}
              </PageButtonComponent>
            </div>
          </div>
        </div>
        {this.props.gobuildCustomProps && this.props.gobuildCustomProps.actionButton && (
          <div className="gb-rt-bottom-right">
            <ActionButton table={this} />
          </div>
        )}
      </div>
    );
  }
}
