import * as React from 'react';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { TopLevelSurfaceName } from '../../../../domain/granulometry/cases/queries/surfaces/getCaseTopLevelsSurfaces';
import { selectCurrentCaseGranulometry } from '../../../../store/selectors/granulometry/currentCase/currentCaseGranulometry.selector';
import { TopLevelLine } from './TopLevelLines/TopLevelLine';
import { getCaseTopLevels } from '../../../../domain/granulometry/cases/queries/levels/getCaseTopLevels';

interface TopLevelLinesProps {
  isToolboxEnlarged: boolean;
  selectedLevelSurface: TopLevelSurfaceName;
}

export const TopLevelLines = ({ isToolboxEnlarged, selectedLevelSurface }: TopLevelLinesProps) => {
  const caseGranulometry = useSelector(selectCurrentCaseGranulometry);

  if (!caseGranulometry) return null;

  return (
    <>
      {getCaseTopLevels(caseGranulometry).reduce(
        (acc, topLevelSpecification, currentIndex) => [
          ...acc,
          <TopLevelLine
            key={currentIndex}
            topLevelSpecification={topLevelSpecification}
            isToolboxEnlarged={isToolboxEnlarged}
            selectedLevelSurface={selectedLevelSurface}
          />
        ],
        [] as ReactNode[]
      )}
    </>
  );
};
