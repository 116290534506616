import * as React from 'react';
import { FormHTMLAttributes } from 'react';

type acceptedAttributes = Pick<
  FormHTMLAttributes<HTMLFormElement>,
  'children' | 'autoComplete' | 'className' | 'style' | 'onSubmit'
>;

type Props = acceptedAttributes;

export const Form: React.FC<Props> = ({
  children,
  autoComplete = 'off',
  onSubmit,
  className,
  style
}) => (
  <form autoComplete={autoComplete} onSubmit={onSubmit} className={className} style={style}>
    {children}
  </form>
);
