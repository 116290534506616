import * as React from 'react';
import { Input } from '../../../../ui/input/Input';
import { CustomPrice } from '../../../../../domain/Account';
import { CellInfo } from 'react-table';

interface CustomPriceInputProps {
  cellInfo: CellInfo;
  price?: CustomPrice['price'];
  handleCustomPriceChange: (productId: string, price: number | null) => void;
  disabled: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CustomPriceInput = React.memo(
  ({ cellInfo, price, handleCustomPriceChange, disabled }: CustomPriceInputProps) => {
    const [currentValue, setCurrentValue] = React.useState<number | undefined>(price);

    const handleChange = (value: number | null) => {
      if (value !== currentValue) {
        setCurrentValue(value as number);
        handleCustomPriceChange(cellInfo.row.productId[0], value === undefined ? null : value);
      }
    };

    return (
      <Input
        name={'customPriceForProduct' + cellInfo.row.productId[0]}
        min={0}
        size="small"
        type="number"
        style={{ margin: '-4px -2px' }}
        placeholder={cellInfo.row.unitPrice}
        suffix="€"
        value={currentValue}
        handleChange={handleChange}
        disabled={disabled}
      />
    );
  },
  () => true
);
