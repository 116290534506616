import { Granulometry } from '../../../../granulometry/Granulometry';
import { CaseGranulometry } from '../../../../granulometry/cases/CaseGranulometry';
import { BuildingId } from '../../../buildings/BuildingSpecification';

export interface CaseGranulometryWithIndexes extends CaseGranulometry {
  buildingIndex: number;
  buildingId: BuildingId;
  indexInBuilding: number;
}
export const getProjectCasesGranulometries = (
  granulometry: Granulometry
): CaseGranulometryWithIndexes[] =>
  granulometry.reduce(
    (casesGranulometries, buildingGranulometry, buildingIndex) =>
      buildingGranulometry.cases.reduce(
        (acc, eachCase, indexInBuilding) => [
          ...casesGranulometries,
          {
            ...eachCase,
            buildingIndex,
            buildingId: buildingGranulometry.id,
            indexInBuilding
          } as CaseGranulometryWithIndexes
        ],
        casesGranulometries
      ),
    [] as CaseGranulometryWithIndexes[]
  );
