import { filter, map, pairwise, withLatestFrom } from 'rxjs/operators';
import { Action } from 'redux';
import { Epic, ofType } from 'redux-observable';
import { BuildingSpecification } from '../../domain/specification/buildings/BuildingSpecification';
import { State } from '../reducers';
import { BUILDING_REMOVED, BuildingRemovedAction } from '../actions/buildingRemoved.action';
import { selectBuildings } from '../selectors/specification/buildings.selector';
import { selectProjectId } from '../selectors/project';
import { CASE_ADDED } from '../actions/caseAdded.action';
import { CASE_REMOVED } from '../actions/caseRemoved.action';
import { BUILDING_ADDED } from '../actions/buildingAdded.action';
import { goToBuilding } from '../actions/navigations/toolbox/buildingsPanel/goToBuilding.action';

type ActionWithState = [Action, State];

type Pair = [ActionWithState, ActionWithState];

export const moveToPreviousBuildingAfterDeletionEpic: Epic<Action, Action, State> = (
  actions$,
  state$
) =>
  actions$.pipe(
    ofType(CASE_ADDED, CASE_REMOVED, BUILDING_ADDED, BUILDING_REMOVED),
    withLatestFrom(state$),
    pairwise(),
    filter(([, [action]]: Pair) => action.type === BUILDING_REMOVED),
    filter(([, [action]]: Pair) => (action as BuildingRemovedAction).payload?.redirect),
    map(([[, stateBeforeRemoval], [action, state]]: Pair): Action => {
      const projectId = selectProjectId(state) as string;
      const buildingsBeforeRemoval = selectBuildings(stateBeforeRemoval) as BuildingSpecification[];

      const indexOfRemovedBuilding = buildingsBeforeRemoval?.findIndex(
        (b) => b.id === (action as BuildingRemovedAction).payload.buildingId
      ) as number;

      const buildingIndexToGoTo = indexOfRemovedBuilding === 0 ? 1 : indexOfRemovedBuilding - 1;
      const buildingIdToGoTo = buildingsBeforeRemoval[buildingIndexToGoTo].id;

      return goToBuilding(projectId, buildingIdToGoTo);
    })
  );
