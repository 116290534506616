import { matchesPath } from '../../utils/matchesPath';
import { sectionsEditRoute } from './sectionsEditRoute';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type SectionsRouteParams = { projectId: string };
export const sectionsRoute: RouteWithMakePath<SectionsRouteParams> = {
  path: `/project/:projectId/sections`,
  makePath: sectionsEditRoute.makePath,
  matchesPath: matchesPath<SectionsRouteParams>(/\/project\/([^/]+)\/sections/, {
    projectId: 1
  })
};
