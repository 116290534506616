import { Reducer } from 'redux';
import { CLOSE_PROJECT } from '../actions/closeProject.action';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import privateRoutes from '../../routes/privateRoutes';
import { AdminReportEntry } from '../../domain/report/ReportEntry';
import { reportFailed, reportFetched, reportFetching } from '../actions/report.actions';
import { PROJECT_CREATED } from '../actions/projectCreated.action';

export interface ReportState {
  report?: AdminReportEntry[];
  fetchingReport: boolean;
}

const initialState: ReportState = { fetchingReport: false };

export const reportReducer: Reducer<ReportState> = (
  state: ReportState = initialState,
  action
): ReportState => {
  if (reportFetching.match(action)) return { ...state, fetchingReport: true };
  if (reportFailed.match(action)) return { ...state, fetchingReport: false };
  if (reportFetched.match(action)) {
    return {
      ...state,
      report: action.payload.report,
      fetchingReport: false
    };
  }
  if (action.type === CLOSE_PROJECT) {
    return initialState;
  }
  if (action.type === PROJECT_CREATED) {
    return initialState;
  }
  if (action.type === LOCATION_CHANGE) {
    const {
      payload: {
        location: { pathname }
      }
    } = action as LocationChangeAction;

    if (pathname === privateRoutes.Projects.path) {
      return initialState;
    }
  }

  return state;
};

export const reportStateReconciler = (saved: ReportState): ReportState => ({
  ...saved,
  fetchingReport: false
});
