import { Epic } from 'redux-observable';
import userState from '../reducers/user.slice';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { User } from '../../domain/User';
import { selectUserToken } from '../selectors/user/userToken.selector';
import { AuthToken } from '../../AuthToken';
import { defer, EMPTY } from 'rxjs';
import { saveCustomPriceLists } from '../../api/v1/saveCustomPriceLists.api';
import { selectUser } from '../selectors/user';

export const saveCustomPriceListsEpic: Epic = (actions$, state$) =>
  actions$.pipe(
    // debounceTime(SAVE_CUSTOM_PRICE_LISTS_IDLE_TIME),
    filter(userState.actions.saveCustomPriceLists.match),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { newCustomPriceLists }
        },
        state
      ]) => {
        const user = selectUser(state) as User;
        const authToken = selectUserToken(state) as AuthToken;

        return defer(() =>
          saveCustomPriceLists(user.id, authToken, user.account.id, newCustomPriceLists)
        ).pipe(
          map(() => userState.actions.customPriceListsSaved()),
          // TODO : Update state before the save request to prevent delay
          catchError((err) => {
            console.warn(err);
            return EMPTY;
          })
        );
      }
    )
  );
