import { DataTypeValue } from '../../../domain/DataType';
import { matchesPath } from '../../utils/matchesPath';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type DeliverablesExportReportRouteParams = {
  projectId: string;
  reportId: string;
  reportDataType: DataTypeValue;
};
export const deliverablesExportReportRoute: RouteWithMakePath<DeliverablesExportReportRouteParams> =
  {
    path: `/project/:projectId/deliverables/export/:reportId/:reportDataType`,
    makePath: ({ projectId, reportId, reportDataType }) =>
      `/project/${projectId}/deliverables/export/${reportId}/${reportDataType}`,
    matchesPath: matchesPath<DeliverablesExportReportRouteParams>(
      /\/project\/([^/]+)\/deliverables\/export\/([^/])\/([^/]+)/,
      { projectId: 1, reportId: 2, reportDataType: 3 }
    )
  };
