import * as React from 'react';
import UserCard from './UserCard';
import { Card } from '../../ui/Card';
import { CardsList } from '../../ui/CardsList';
import { deleteProject } from '../../../api/v1/deleteProject.api';
import { push } from 'connected-react-router';
import { selectUserId } from '../../../store/selectors/user/userId.selector';
import { selectUserToken } from '../../../store/selectors/user/userToken.selector';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button } from '../../ui/Button';
import AppMenuWindows from '../../business/windows/AppMenuWindows';
import { ProjectInitRouteParams } from '../../../routes/toolbox/projectPanel/projectInitRoute';
import { appMenuEditUserRoute } from '../../../routes/appMenu/appMenuEditUserRoute';

export const AppMenu = () => {
  const { projectId } = useParams<ProjectInitRouteParams>();
  const userId = useSelector(selectUserId);
  const userToken = useSelector(selectUserToken);

  const dispatch = useDispatch();

  const backToOpenProjects = React.useCallback(() => {
    dispatch(push('/projects'));
  }, [dispatch]);

  const { t } = useTranslation();

  const handleDeleteProject = React.useCallback(() => {
    if (projectId && confirm(t('Are you sure you want to delete this project?'))) {
      deleteProject(projectId, userId, userToken).then(() => dispatch(push('/projects')));
    }
  }, [dispatch, projectId, userId, userToken]);

  return (
    <div className="panel">
      <Route path={appMenuEditUserRoute.path} component={AppMenuWindows} />
      <CardsList>
        <Card>
          <Button
            fullWidth
            appearance="outline"
            content={t('Projects menu')}
            handleClick={backToOpenProjects}
          />
          <Button
            fullWidth
            appearance="outline"
            color="red"
            content={t('Delete project')}
            handleClick={handleDeleteProject}
          />
        </Card>
        <UserCard />
      </CardsList>
    </div>
  );
};

export default AppMenu;
