import { ReportEntry } from '../../domain/report/ReportEntry';
import { sumCo2QuantitiesInReportBy } from '../utils/sumCo2QuantitiesInReportBy';
import * as R from 'ramda';
import { sortReportByRefSsLot2 } from '../utils/sortReportByRefSsLot2';

export interface PleiadesJsonEntry {
  product: string;
  refSsLot1: string;
  co2Quantity: number;
  co2QuantityUnit: string;
  co2Id: string;
  coefficient: string;
  fiche: string;
  numFiche: string;
  dureeDeVie: string;
  dureeDeVieFiche: string;
  impactCo2Dyn: string;
  impactCo2TotalDyn: string;
  impactCo2: string;
  impactCo2Total: string;
  typeDeFiche: string;
  bibliotheque: string;
  lotPersonnalise: string;
  commentaire: string;
  batiment: string;
  zone: string;
}

export type PleiadesJson = PleiadesJsonEntry[];

export const makePleiadesJson = (reportEntries: ReportEntry[]): PleiadesJson =>
  R.pipe<[ReportEntry[]], ReportEntry[], ReportEntry[], PleiadesJson>(
    sumCo2QuantitiesInReportBy(['ssLot2', 'co2Id', 'co2Title']),
    sortReportByRefSsLot2,
    R.reduce<ReportEntry, PleiadesJson>((acc, reportEntry) => {
      const hasToBeSkipped =
        reportEntry.co2Quantity === 0 ||
        isNaN(reportEntry.co2Quantity) ||
        !!reportEntry.co2Id.match(/[a-zA-Z]/);
      return !hasToBeSkipped
        ? [
            ...acc,
            {
              product: reportEntry.product,
              refSsLot1: reportEntry.refSsLot1,
              co2Quantity: reportEntry.co2Quantity,
              co2QuantityUnit: reportEntry.co2QuantityUnit,
              co2Id: reportEntry.co2Id,
              coefficient: '-',
              fiche: reportEntry.co2Title + ' - DONNEE ENVIRONNEMENTALE PAR DEFAUT',
              numFiche: reportEntry.co2Id,
              dureeDeVie: '-',
              dureeDeVieFiche: '-',
              impactCo2Dyn: '-',
              impactCo2TotalDyn: '-',
              impactCo2: '-',
              impactCo2Total: '-',
              typeDeFiche: '-',
              bibliotheque: '-',
              lotPersonnalise: '-',
              commentaire: '-',
              batiment: reportEntry.building,
              zone: reportEntry.case
            }
          ]
        : acc;
    }, [])
  )(reportEntries);
