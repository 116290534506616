import { createSelector, Selector } from 'reselect';
import { selectCurrentCaseLodgmentsSurfacesByType } from './lodgmentsSurfacesByType.selector';
import { selectCurrentCaseLodgmentsCountByType } from './lodgmentsCountByType.selector';
import * as R from 'ramda';
import { State } from '../../../reducers';

// TODO specify type
export const selectCurrentCaseLodgmentsAverageSurfaceByType: Selector<State, any> = createSelector(
  [selectCurrentCaseLodgmentsCountByType, selectCurrentCaseLodgmentsSurfacesByType],
  R.mergeWith((count, surface) => surface.value / count)
);
