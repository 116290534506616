import { Reducer } from 'redux';
import { Nomenclature } from '../../domain/Nomenclature';
import {
  NOMENCLATURES_FETCHED,
  NomenclaturesFetchedAction
} from '../actions/nomenclaturesFetched.action';

export const nomenclaturesReducer: Reducer<Nomenclature[]> = (
  state: Nomenclature[] = [],
  action
) => {
  if (action.type === NOMENCLATURES_FETCHED) {
    return (action as NomenclaturesFetchedAction).payload.nomenclatures;
  }

  return state;
};
