export enum HeatingSystem {
  DistrictHeating = 'districtHeating',
  CollectiveGasHeating = 'collectiveGasHeating',
  CollectiveHeatPumpOnGroundWaterHeating = 'collectiveHeatPumpOnGroundWaterHeating',
  CollectiveWoodHeating = 'collectiveWoodHeating',
  IndividualGasHeating = 'individualGasHeating',
  IndividualElectricHeating = 'individualElectricHeating'
}

export const HeatingSystemsInArray = [
  HeatingSystem.DistrictHeating,
  HeatingSystem.CollectiveGasHeating,
  HeatingSystem.CollectiveHeatPumpOnGroundWaterHeating,
  HeatingSystem.CollectiveWoodHeating,
  HeatingSystem.IndividualGasHeating,
  HeatingSystem.IndividualElectricHeating
];

export const isHeatingSystemACollectiveGasHeating = (heatingSystem: HeatingSystem) =>
  heatingSystem === HeatingSystem.CollectiveGasHeating;

export const isHeatingSystemADistrictHeating = (heatingSystem: HeatingSystem) =>
  heatingSystem === HeatingSystem.DistrictHeating;

export const isHeatingSystemACollectiveHeatPumpOnGroundWaterHeating = (
  heatingSystem: HeatingSystem
) => heatingSystem === HeatingSystem.CollectiveHeatPumpOnGroundWaterHeating;

export const isHeatingSystemACollectiveWoodHeating = (heatingSystem: HeatingSystem) =>
  heatingSystem === HeatingSystem.CollectiveWoodHeating;

export const isHeatingSystemAnIndividualGasHeating = (heatingSystem: HeatingSystem) =>
  heatingSystem === HeatingSystem.IndividualGasHeating;

export const isHeatingSystemAnElectricHeating = (heatingSystem: HeatingSystem) =>
  heatingSystem === HeatingSystem.IndividualElectricHeating;

export const isHeatingSystemCollective = (heatingSystem: HeatingSystem) =>
  isHeatingSystemACollectiveGasHeating(heatingSystem) ||
  isHeatingSystemADistrictHeating(heatingSystem) ||
  isHeatingSystemACollectiveHeatPumpOnGroundWaterHeating(heatingSystem) ||
  isHeatingSystemACollectiveWoodHeating(heatingSystem);

export const isHeatingSystemIndividual = (heatingSystem: HeatingSystem) =>
  isHeatingSystemAnIndividualGasHeating(heatingSystem) ||
  isHeatingSystemAnElectricHeating(heatingSystem);
