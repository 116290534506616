import * as R from 'ramda';
import { CaseGranulometry } from '../../../CaseGranulometry';
import { LevelGranulometry } from '../../../../levels/LevelGranulometry';
import {
  getLevelParkingsCountByType,
  ParkingsCountByType
} from '../../../../levels/queries/basementLevels/counts/getBasementLevelParkingsCountByType';

export type ParkingsCountByLevelAndType = {
  [level: string]: ParkingsCountByType;
};

type GetCaseParkingSectionsCountByLevelAndType = (
  caseGranulometry: CaseGranulometry
) => ParkingsCountByLevelAndType;

export const getCaseParkingsCountByLevelAndType: GetCaseParkingSectionsCountByLevelAndType = R.pipe<
  [CaseGranulometry],
  LevelGranulometry[],
  { [l: string]: Record<'level', number> },
  ParkingsCountByLevelAndType
>(
  R.prop('levels'),
  R.indexBy(R.compose(R.toString, R.prop('level'))),
  R.mapObjIndexed(getLevelParkingsCountByType)
);
