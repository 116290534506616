import * as R from 'ramda';
import { BuildingId, overCases } from '../specification/buildings/BuildingSpecification';
import { checkThatBuildingExists } from '../checks/buildings/buildingExists';
import { createCaseSpecification } from '../specification/cases/queries/create/createCaseSpecification';
import { overBuilding } from '../project/over/overBuilding';
import { Project } from '../project/Project';
import { CaseSpecification } from '../specification/cases/CaseSpecification';

export const addACase =
  (buildingId: BuildingId) =>
  (project: Project): Project => {
    checkThatBuildingExists(buildingId)(project);

    return overBuilding(buildingId)(
      overCases(
        R.append(
          createCaseSpecification({
            projectSurfaceInputMode: project.surfaceInputMode,
            withRamp: false
          })
        )
      )
    )(project);
  };

export const addACaseWithSpec =
  (buildingId: BuildingId, caseSpecification: CaseSpecification) =>
  (project: Project): Project => {
    checkThatBuildingExists(buildingId)(project);
    const caseSpectWithRampFalse = {
      ...caseSpecification,
      builtInRamp: false
    };

    return overBuilding(buildingId)(overCases(R.append(caseSpectWithRampFalse)))(project);
  };
