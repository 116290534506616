import { ExistingProjectBasicInfo } from '../../../domain/project/Project';
import { APIProject } from '../fetchProjects.api';

export const projectFromApiAdapter = (project: APIProject): ExistingProjectBasicInfo => ({
  id: project.id,
  name: project.title,
  description: project.description,
  createdAt: new Date(project.created).toISOString(),
  modifiedAt: new Date(project.modified).toISOString()
});
