import * as R from 'ramda';
import ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { cleanFileName } from '../utils/cleanFileName';
import { NoocoJson } from '../../../../../api/nooco/makeNoocoJson';

const noocoHeaders: { [key: string]: string } = {
  product: 'Description',
  co2Quantity: 'Quantité',
  co2QuantityUnit: 'Unité',
  co2Id: 'ID INIES',
  refLot: 'Lot',
  refSsLot1: 'Sous-lot'
};

export const downloadNooco = (noocoJson: NoocoJson, projectName: string) => {
  const workbook = new ExcelJS.Workbook();
  const columns = Object.keys(noocoJson[0]).reduce((acc, column) => {
    return column === 'case'
      ? acc
      : [...acc, { header: noocoHeaders[column] || column, key: column, width: 10 }];
  }, [] as Partial<ExcelJS.Column>[]);
  R.collectBy(R.prop('case'), noocoJson).forEach((caseEntries: NoocoJson) => {
    const worksheet = workbook.addWorksheet(caseEntries[0].case);
    worksheet.columns = columns;
    worksheet.addRows(caseEntries, 'i');
    worksheet.getRow(1).font = { bold: true };
  });
  const fileName =
    'gobuild-' +
    cleanFileName(projectName) +
    '-nooco-' +
    new Date().toISOString().slice(0, 10) +
    '.xlsx';
  workbook.xlsx.writeBuffer().then(function (buffer) {
    FileSaver.saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
  });
};
