import { Polygon } from 'geojson';
import { Position } from '@turf/helpers/dist/js/lib/geojson';
import { MaybeLine } from '../facades/FacadeSpecification';
import { getCoordinatesFromLines } from '../../utils/geometry/coordinates';
import { translateCoordinatesToNewCenter } from '../../utils/polygon/translatePolygonOnPoint';

const getBuildingGeometry = (origin: Position, lines: MaybeLine[]): Polygon => {
  const coords = getCoordinatesFromLines(origin, lines);
  const newCoordinates = translateCoordinatesToNewCenter(coords, origin);
  return {
    type: 'Polygon',
    coordinates: [newCoordinates]
  };
};
export default getBuildingGeometry;
