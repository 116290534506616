import { Property } from '../domain/specification/cases/Property';

export const defaultCaseProperties: Property[] = [
  // Case
  {
    name: 'outsideWallsFormworkRate',
    title: 'Ratio coffrage de voile exposé',
    defaultValue: 200,
    unit: '%',
    groups: ['Cage', 'Superstructure', 'Surface verticale extérieure']
  },
  {
    name: 'glazedSurfaceOnRealLivingSurfaceRate',
    title: 'Taux surface vitrée sur surface habitable',
    defaultValue: 16.7,
    min: 16.7,
    max: 25,
    unit: '%',
    groups: ['Cage', 'Superstructure', 'Composition'],
    notes: 'Calcul thermique non garantie en deçà de 16.7%'
  },
  {
    name: 'balconySurfaceOnRealLivingSurfaceRate',
    title: 'Taux surface balcon sur surface habitable',
    defaultValue: 15,
    min: 10,
    max: 50,
    unit: '%',
    groups: ['Cage', 'Superstructure', 'Composition']
  },
  // Walls thickness
  {
    name: 'insideWallsThickness',
    title: 'Épaisseur de voiles non exposés en superstructure',
    defaultValue: 18,
    min: 18,
    max: 25,
    unit: 'cm',
    groups: ['Cage', 'Superstructure', 'Surface verticale intérieure'],
    notes: 'Calcul acoustique non garantie en deçà de 18cm'
  },
  {
    name: 'outsideWallsThickness',
    title: 'Épaisseur de voiles exposés en superstructure',
    defaultValue: 16,
    min: 16,
    max: 25,
    unit: 'cm',
    groups: ['Cage', 'Superstructure', 'Surface verticale intérieure'],
    notes: 'Calcul acoustique non garantie en deçà de 16cm'
  },
  {
    name: 'insideWallsInfraThickness',
    title: 'Épaisseur de voiles non exposés en infrastructure',
    defaultValue: 20,
    min: 18,
    max: 30,
    unit: 'cm',
    groups: ['Cage', 'Infrastructure', 'Surface verticale intérieure']
  },
  {
    name: 'outsideWallsInfraThickness',
    title: 'Épaisseur de voiles exposés en infrastructure',
    defaultValue: 20,
    min: 16,
    max: 30,
    unit: 'cm',
    groups: ['Cage', 'Infrastructure', 'Surface verticale intérieure']
  },
  {
    name: 'insideWallsFormworkRate',
    title: 'Ratio coffrage de voile non exposé',
    defaultValue: 100,
    unit: '%',
    groups: ['Cage', 'Superstructure', 'Surface verticale intérieure']
  },
  {
    name: 'insideWallsHighAdhesionSteelRate',
    title: 'Ratio acier haute adhérence de voile non exposé',
    defaultValue: 30,
    min: 5,
    max: 60,
    unit: 'kg/m3',
    groups: ['Cage', 'Superstructure', 'Surface verticale intérieure']
  },
  {
    name: 'insideWallsWeldedMeshSteelRate',
    title: 'Ratio acier treillis soudé de voile non exposé',
    defaultValue: 40,
    min: 30,
    max: 60,
    unit: 'kg/m3',
    groups: ['Cage', 'Superstructure', 'Surface verticale intérieure']
  },
  {
    name: 'outsideWallsHighAdhesionSteelRate',
    title: 'Ratio acier haute adhérence de voile exposé',
    defaultValue: 20,
    min: 15,
    max: 40,
    unit: 'kg/m3',
    groups: ['Cage', 'Superstructure', 'Surface verticale extérieure']
  },
  {
    name: 'outsideWallsWeldedMeshSteelRate',
    title: 'Ratio acier treillis soudé de voile exposé',
    defaultValue: 40,
    min: 30,
    max: 60,
    unit: 'kg/m3',
    groups: ['Cage', 'Superstructure', 'Surface verticale extérieure']
  },
  {
    name: 'insideWallInfraHighAdhesionSteelRate',
    title: 'Ratio acier haute adhérence de voile intérieur infrastructure',
    defaultValue: 50,
    min: 30,
    max: 100,
    unit: 'kg/m3',
    groups: ['Cage', 'Infrastructure', 'Surface verticale intérieure']
  },
  {
    name: 'outsideWallInfraHighAdhesionSteelRate',
    title: 'Ratio acier haute adhérence de voile extérieur infrastructure',
    defaultValue: 50,
    min: 30,
    max: 100,
    unit: 'kg/m3',
    groups: ['Cage', 'Infrastructure', 'Surface verticale extérieure']
  },
  {
    name: 'insideWallInfraWeldedMeshSteelRate',
    title: 'Ratio acier treillis soudé de voile intérieur infrastructure',
    defaultValue: 30,
    min: 30,
    max: 100,
    unit: 'kg/m3',
    groups: ['Cage', 'Infrastructure', 'Surface verticale intérieure']
  },
  {
    name: 'outsideWallInfraWeldedMeshSteelRate',
    title: 'Ratio acier treillis soudé de voile extérieur infrastructure',
    defaultValue: 30,
    min: 30,
    max: 100,
    unit: 'kg/m3',
    groups: ['Cage', 'Infrastructure', 'Surface verticale extérieure']
  },
  // Outside wall types rates
  {
    name: 'outsideWallConcreteRate',
    title: 'Taux d’enveloppe structurelle verticale béton',
    defaultValue: 0,
    min: 0,
    max: 100,
    unit: '%',
    groups: ['Cage', 'Superstructure', 'Surface verticale extérieure']
  },
  {
    name: 'outsideWallMasonryRate',
    title: 'Taux d’enveloppe structurelle verticale en éléments de maçonnerie',
    defaultValue: 0,
    min: 0,
    max: 100,
    unit: '%',
    groups: ['Cage', 'Superstructure', 'Surface verticale extérieure']
  },
  {
    name: 'outsideWallWoodRate',
    title: 'Taux d’enveloppe structurelle verticale bois',
    defaultValue: 0,
    min: 0,
    max: 100,
    unit: '%',
    groups: ['Cage', 'Superstructure', 'Surface verticale extérieure']
  },
  // Insulating envelope rates and options
  {
    name: 'internalInsulationRate',
    title: 'Taux d’isolation intérieure',
    defaultValue: 100,
    min: 0,
    max: 100,
    unit: '%',
    groups: ['Cage', 'Superstructure', 'Composition']
  },
  {
    name: 'externalInsulationRate',
    title: 'Taux d’isolation extérieure',
    defaultValue: 0,
    min: 0,
    max: 100,
    unit: '%',
    groups: ['Cage', 'Superstructure', 'Composition']
  },
  {
    name: 'balconyInsulatingBreakRate',
    title: 'Taux de rupteur sur linéaire balcon',
    defaultValue: 0,
    min: 0,
    max: 100,
    unit: '%',
    groups: ['Cage', 'Superstructure', 'Composition']
  },
  {
    name: 'currentInsulatingBreakRate',
    title: 'Taux de rupteur courant',
    defaultValue: 100,
    min: 0,
    max: 100,
    unit: '%',
    groups: ['Cage', 'Superstructure', 'Composition']
  },
  {
    name: 'hasDoubledLodgmentInsideInsulating',
    title: 'Doublage isolant logement contre paliers, cages d’escalier et cages d’ascenseur',
    defaultValue: 0,
    min: 0,
    max: 1,
    unit: '',
    groups: ['Cage', 'Superstructure', 'Composition'],
    notes:
      'Ajout d’isolant sur les voiles de logements non-exposés localisés contre des locaux dit non chauffés lorsque cette propriété vaut 1.'
  },
  // Installation cost ratio
  {
    name: 'installationCostRatio',
    title: 'Ratio du coût installation chantier',
    defaultValue: 5,
    min: 0,
    max: 100,
    unit: '%',
    groups: ['Cage', 'Superstructure', 'Composition'],
    notes:
      'Forfait de coût d’installation du chantier calculé sur la base d’un pourcentage du total gros œuvre'
  },
  // Stairs, elevator and smokeExtractionDucts shafts count
  {
    name: 'stairsShaftCount',
    title: 'Nombre de cages d’escalier',
    defaultValue: 1,
    min: 0,
    max: 2,
    unit: 'u',
    groups: ['Cage', 'Superstructure', 'Composition']
  },
  {
    name: 'outsideStairShaftCount',
    title: 'Nombre de cages d’escalier externes',
    defaultValue: 0,
    min: 0,
    max: 2,
    unit: 'u',
    groups: ['Cage', 'Superstructure', 'Composition']
  },
  {
    name: 'elevatorShaftCount',
    title: 'Nombre de cages d’ascenseur',
    defaultValue: 1,
    min: 0,
    max: 2,
    unit: 'u',
    groups: ['Cage', 'Superstructure', 'Composition']
  },
  {
    name: 'smokeExtractionDuctsCount',
    title: 'Nombre de cages de désenfumage',
    defaultValue: 0,
    min: 0,
    max: 2,
    unit: 'u',
    groups: ['Cage', 'Superstructure', 'Composition']
  },
  // Level
  {
    name: 'topLevelCeilingHeight',
    title: 'Hauteur finie sous plafond d’un niveau en superstructure',
    defaultValue: 2.5,
    min: 2.4,
    max: 3.5,
    unit: 'm',
    groups: ['Niveau', 'Superstructure', 'Composition'],
    notes: 'Type de cloison de distribution non garantie au dessus de 2.5m'
  },
  {
    name: 'basementLevelCeilingHeight',
    title: 'Hauteur finie sous plafond d’un niveau en infrastructure',
    defaultValue: 2,
    min: 2,
    max: 3.5,
    unit: 'm',
    groups: ['Niveau', 'Infrastructure', 'Composition']
  },
  {
    name: 'topLevelConcreteSlabThickness',
    title: 'Épaisseur dalle béton d’un niveau en superstructure',
    defaultValue: 20,
    min: 20,
    max: 25,
    unit: 'cm',
    groups: ['Niveau', 'Superstructure', 'Surface horizontale'],
    notes: 'Calcul structurel non garantie en deçà de 20cm'
  },
  {
    name: 'basementLevelConcreteSlabThickness',
    title: 'Épaisseur dalle béton d’un niveau en infrastructure',
    defaultValue: 20,
    min: 20,
    max: 25,
    unit: 'cm',
    groups: ['Niveau', 'Infrastructure', 'Surface horizontale'],
    notes: 'Calcul structurel non garantie en deçà de 20cm'
  },
  {
    name: 'topLevelInternalConcreteSlabThickness',
    title: 'Épaisseur dalle béton intérieure d’un niveau en superstructure',
    defaultValue: 20,
    min: 20,
    max: 25,
    unit: 'cm',
    groups: ['Niveau', 'Superstructure', 'Surface horizontale'],
    notes: 'Calcul structurel non garantie en deçà de 20cm'
  },
  {
    name: 'topLevelCementScreedThickness',
    title: 'Épaisseur chape ciment d’un niveau en superstructure',
    defaultValue: 5,
    min: 5,
    max: 7,
    unit: 'cm',
    groups: ['Niveau', 'Superstructure', 'Surface horizontale'],
    notes: 'Calcul acoustique non garantie en deçà de 5cm'
  },
  {
    name: 'basementLevelCementScreedThickness',
    title: 'Épaisseur chape ciment d’un niveau en infrastructure',
    defaultValue: 5,
    min: 5,
    max: 7,
    unit: 'cm',
    groups: ['Niveau', 'Infrastructure', 'Surface horizontale'],
    notes: 'Calcul acoustique non garantie en deçà de 5cm'
  },
  {
    name: 'topLevelConcreteSlabHighAdhesionSteelRate',
    title: 'Ratio acier haute adhérence dalle béton d’un niveau en superstructure',
    defaultValue: 20,
    min: 15,
    max: 40,
    unit: 'kg/m3',
    groups: ['Niveau', 'Superstructure', 'Surface horizontale'],
    notes: 'Calcul acoustique non garantie en deçà de 5cm'
  },
  {
    name: 'basementLevelConcreteSlabHighAdhesionSteelRate',
    title: 'Ratio acier haute adhérence dalle béton d’un niveau en infrastructure',
    defaultValue: 47,
    unit: 'kg/m3',
    groups: ['Niveau', 'Infrastructure', 'Surface horizontale'],
    notes: 'Calcul acoustique non garantie en deçà de 5cm'
  },
  {
    name: 'topLevelConcreteSlabWeldedMeshSteelRate',
    title: 'Ratio acier treillis soudés dalle béton d’un niveau en superstructure',
    defaultValue: 40,
    min: 30,
    max: 60,
    unit: 'kg/m3',
    groups: ['Niveau', 'Superstructure', 'Surface horizontale'],
    notes: 'Calcul acoustique non garantie en deçà de 5cm'
  },
  {
    name: 'basementLevelConcreteSlabWeldedMeshSteelRate',
    title: 'Ratio acier treillis soudés dalle béton d’un niveau en infrastructure',
    defaultValue: 35,
    min: 30,
    max: 100,
    unit: 'kg/m3',
    groups: ['Niveau', 'Infrastructure', 'Surface horizontale'],
    notes: 'Calcul acoustique non garantie en deçà de 5cm'
  },
  {
    name: 'topLevelConcretePillarHighAdhesionSteelRate',
    title: 'Ratio acier haute adhérence poteau béton d’un niveau en superstructure',
    defaultValue: 100,
    unit: 'kg/m3',
    groups: ['Niveau', 'Superstructure', 'Surface verticale intérieure']
  },
  {
    name: 'basementLevelConcretePillarHighAdhesionSteelRate',
    title: 'Ratio acier haute adhérence poteau béton d’un niveau en infrastructure',
    defaultValue: 100,
    unit: 'kg/m3',
    groups: ['Niveau', 'Infrastructure', 'Surface verticale intérieure']
  },
  // Sections
  {
    name: 'bearingWidth',
    title: 'Largeur du palier en superstructure',
    defaultValue: 1.3,
    min: 0,
    max: 10,
    unit: 'm',
    groups: ['Section', 'Superstructure', 'Composition']
  },
  {
    name: 'bearingExposureRate',
    title: 'Taux d’exposition du palier en superstructure',
    defaultValue: 25,
    min: 0,
    max: 100,
    unit: '%',
    groups: ['Section', 'Infrastructure', 'Composition']
  },
  {
    name: 'bearingInfraWidth',
    title: 'Largeur du palier en infrastructure',
    defaultValue: 3,
    unit: 'm',
    groups: ['Section', 'Infrastructure', 'Composition']
  },
  {
    name: 'bearingInfraExposureRate',
    title: 'Taux d’exposition du palier en infrastructure',
    defaultValue: 25,
    min: 0,
    max: 100,
    unit: '%',
    groups: ['Section', 'Infrastructure', 'Composition']
  },
  {
    name: 'bearingInfraSurface',
    title: 'Surface du palier en infrastructure',
    defaultValue: 10,
    unit: 'm2',
    groups: ['Section', 'Infrastructure', 'Composition']
  },
  {
    name: 'stairsShaftLength',
    title: 'Longueur de la cage d’escalier interne',
    defaultValue: 3,
    min: 3,
    max: 4,
    unit: 'm',
    groups: ['Section', 'Superstructure', 'Composition'],
    notes: 'Norme PMR non garantie en deçà de 3m'
  },
  {
    name: 'stairsShaftWidth',
    title: 'Largeur de la cage d’escalier interne',
    defaultValue: 3,
    min: 3,
    max: 4,
    unit: 'm',
    groups: ['Section', 'Superstructure', 'Composition'],
    notes: 'Norme PMR non garantie en deçà de 3m'
  },
  {
    name: 'stairsShaftBearingLength',
    title: 'Longueur du palier d’escalier interne',
    defaultValue: 1.5,
    min: 1.5,
    max: 2,
    unit: 'm',
    groups: ['Section', 'Superstructure', 'Composition'],
    notes: 'Norme PMR non garantie en deçà de 1.5m'
  },
  {
    name: 'stairsShaftBearingWidth',
    title: 'Largeur du palier d’escalier interne',
    defaultValue: 1.5,
    min: 1.5,
    max: 2,
    unit: 'm',
    groups: ['Section', 'Superstructure', 'Composition'],
    notes: 'Norme PMR non garantie en deçà de 1.5m'
  },
  {
    name: 'outsideStairShaftLength',
    title: 'Longueur de la cage d’escalier externe',
    defaultValue: 3,
    min: 3,
    max: 4,
    unit: 'm',
    groups: ['Section', 'Superstructure', 'Composition'],
    notes: 'Norme PMR non garantie en deçà de 3m'
  },
  {
    name: 'outsideStairShaftWidth',
    title: 'Largeur de la cage d’escalier externe',
    defaultValue: 3,
    min: 3,
    max: 4,
    unit: 'm',
    groups: ['Section', 'Superstructure', 'Composition'],
    notes: 'Norme PMR non garantie en deçà de 3m'
  },
  {
    name: 'outsideStairsShaftBearingLength',
    title: 'Longueur du palier d’escalier externe',
    defaultValue: 1.5,
    min: 1.5,
    max: 2,
    unit: 'm',
    groups: ['Section', 'Superstructure', 'Composition'],
    notes: 'Norme PMR non garantie en deçà de 1.5m'
  },
  {
    name: 'outsideStairsShaftBearingWidth',
    title: 'Largeur du palier d’escalier externe',
    defaultValue: 1.5,
    min: 1.5,
    max: 2,
    unit: 'm',
    groups: ['Section', 'Superstructure', 'Composition'],
    notes: 'Norme PMR non garantie en deçà de 1.5m'
  },
  {
    name: 'lodgmentInsideWallsPartitionRate',
    title: 'Ratio voile-cloison en voile non exposé d’un logement',
    defaultValue: 100,
    unit: '%',
    groups: ['Section', 'Superstructure', 'Composition']
  },
  {
    name: 'lodgmentConcreteSlabConcreteRatio',
    title: 'Ratio béton dalle béton d’un logement',
    defaultValue: 100,
    unit: '%',
    groups: ['Section', 'Superstructure', 'Surface horizontale']
  },
  {
    name: 'lodgmentConcreteSlabFormworkRate',
    title: 'Ratio coffrage dalle béton d’un logement',
    defaultValue: 100,
    unit: '%',
    groups: ['Section', 'Superstructure', 'Surface horizontale']
  },
  {
    name: 'lodgmentOutsideWallsConcreteRatio',
    title: 'Ratio béton voile exposé d’un logement',
    defaultValue: 100,
    unit: '%',
    groups: ['Section', 'Superstructure', 'Surface verticale extérieure']
  },
  {
    name: 'internalInsulationThickness',
    title: 'Épaisseur de l’isolant thermique intérieur (ITI)',
    defaultValue: 12,
    min: 5,
    max: 20,
    unit: 'cm',
    groups: ['Section', 'Superstructure', 'Surface verticale intérieure'],
    notes: 'Épaisseur de l’isolant thermique intérieur dans le voile exposé d’un logement'
  },
  {
    name: 'externalInsulationThickness',
    title: 'Épaisseur de l’isolant thermique extérieur (ITE)',
    defaultValue: 10,
    min: 5,
    max: 15,
    unit: 'cm',
    groups: ['Section', 'Superstructure', 'Surface verticale intérieure'],
    notes: 'Épaisseur de l’isolant thermique extérieur dans le voile exposé d’un logement'
  },
  {
    name: 'hallWidth',
    title: 'Largeur du hall',
    defaultValue: 7,
    unit: 'm',
    groups: ['Section', 'Superstructure', 'Composition']
  },
  {
    name: 'hallSurface',
    title: 'Surface théorique du hall',
    defaultValue: 30,
    min: 1,
    unit: 'm2',
    groups: ['Section', 'Superstructure', 'Composition']
  },
  {
    name: 'hallExposureRate',
    title: 'Taux d’exposition du hall',
    defaultValue: 25,
    min: 0,
    max: 100,
    unit: '%',
    groups: ['Section', 'Superstructure', 'Composition']
  },
  {
    name: 'hallEntranceHeight',
    title: 'Hauteur de l’ensemble métallique d’entrée du hall',
    defaultValue: 3,
    unit: 'm',
    groups: ['Section', 'Superstructure', 'Équipement']
  },
  {
    name: 'hallEntranceLength',
    title: 'Longueur de l’ensemble métallique d’entrée du hall',
    defaultValue: 3,
    unit: 'm',
    groups: ['Section', 'Superstructure', 'Équipement']
  },
  {
    name: 'sasWidth',
    title: 'Largeur du sas piéton',
    defaultValue: 1.2,
    unit: 'm',
    groups: ['Section', 'Infrastructure', 'Composition']
  },
  {
    name: 'sasSurface',
    title: 'Surface du sas piéton',
    defaultValue: 5,
    unit: 'm2',
    groups: ['Section', 'Infrastructure', 'Composition']
  },
  {
    name: 'rampWidth',
    title: 'Largeur de la rampe sous-sol',
    defaultValue: 5.5,
    min: 5,
    max: 6,
    unit: 'm',
    groups: ['Section', 'Infrastructure', 'Composition']
  },
  {
    name: 'rampLength',
    title: 'Longueur de la rampe sous-sol',
    defaultValue: 20,
    min: 10,
    max: 30,
    unit: 'm',
    groups: ['Section', 'Infrastructure', 'Composition']
  },
  {
    name: 'rampExposureRate',
    title: 'Taux d’exposition de la rampe sous-sol',
    defaultValue: 50,
    min: 0,
    max: 100,
    unit: '%',
    groups: ['Section', 'Superstructure', 'Composition']
  },
  {
    name: 'rampGarageDoorHeight',
    title: 'Hauteur de la porte de garage pour la rampe sous-sol',
    defaultValue: 2.1,
    min: 2.1,
    max: 2.5,
    unit: 'm',
    groups: ['Section', 'Infrastructure', 'Équipement']
  },
  {
    name: 'rampGarageDoorWidth',
    title: 'Largeur de la porte de garage pour la rampe sous-sol',
    defaultValue: 3.5,
    min: 3.5,
    max: 4,
    unit: 'm',
    groups: ['Section', 'Infrastructure', 'Équipement']
  },
  {
    name: 'elevatorShaftWidth',
    title: 'Largeur de la gaine ascenseur',
    defaultValue: 1.6,
    min: 1.6,
    max: 2,
    unit: 'm',
    groups: ['Section', 'Superstructure', 'Composition']
  },
  {
    name: 'elevatorShaftLength',
    title: 'Profondeur de la gaine ascenseur',
    defaultValue: 1.8,
    min: 1.6,
    max: 2,
    unit: 'm',
    groups: ['Section', 'Superstructure', 'Composition']
  },
  {
    name: 'elevatorDoorHeight',
    title: 'Hauteur de la porte d’ascenseur',
    defaultValue: 2,
    unit: 'm',
    groups: ['Section', 'Superstructure', 'Équipement']
  },
  {
    name: 'elevatorDoorWidth',
    title: 'Largeur de la porte d’ascenseur',
    defaultValue: 1.6,
    unit: 'm',
    groups: ['Section', 'Superstructure', 'Équipement']
  },
  {
    name: 'smokeExtractionDuctsWidth',
    title: 'Largeur de la gaine de désenfumage',
    defaultValue: 0.4,
    min: 0.4,
    max: 0.4,
    unit: 'm',
    groups: ['Section', 'Superstructure', 'Composition']
  },
  {
    name: 'smokeExtractionDuctsDepth',
    title: 'Profondeur de la gaine de désenfumage',
    defaultValue: 0.5,
    min: 0.5,
    max: 0.5,
    unit: 'm',
    groups: ['Section', 'Superstructure', 'Composition']
  },
  {
    name: 'carParkWidth',
    title: 'Largeur d’une place de stationnement',
    defaultValue: 2.6,
    min: 2.5,
    max: 3.5,
    unit: 'm',
    groups: ['Section', 'Infrastructure', 'Composition']
  },
  {
    name: 'carParkDepth',
    title: 'Profondeur d’une place de stationnement',
    defaultValue: 5,
    min: 5,
    max: 10,
    unit: 'm',
    groups: ['Section', 'Infrastructure', 'Composition']
  },
  {
    name: 'carParkOutsideWallsConcreteRatio',
    title: 'Ratio béton voile exposé d’une place de stationnement',
    defaultValue: 100,
    unit: '%',
    groups: ['Section', 'Infrastructure', 'Surface verticale extérieure']
  },
  {
    name: 'carParkBeamHeight',
    title: 'Hauteur poutre d’une place de stationnement',
    defaultValue: 60,
    min: 50,
    max: 80,
    unit: 'cm',
    groups: ['Section', 'Infrastructure', 'Surface horizontale']
  },
  {
    name: 'carParkBeamWidth',
    title: 'Largeur poutre d’une place de stationnement',
    defaultValue: 50,
    min: 20,
    max: 120,
    unit: 'cm',
    groups: ['Section', 'Infrastructure', 'Surface horizontale']
  },
  {
    name: 'carParkPillarThickness',
    title: 'Épaisseur du poteau d’une place de stationnement',
    defaultValue: 25,
    min: 20,
    max: 30,
    unit: 'cm',
    groups: ['Section', 'Infrastructure', 'Surface verticale intérieure']
  },
  {
    name: 'carParkPillarWidth',
    title: 'Largeur du poteau d’une place de stationnement',
    defaultValue: 150,
    min: 80,
    max: 200,
    unit: 'cm',
    groups: ['Section', 'Infrastructure', 'Surface verticale intérieure']
  },
  {
    name: 'carBoxWidth',
    title: 'Largeur d’un box de stationnement',
    defaultValue: 2.6,
    min: 2.5,
    max: 3.5,
    unit: 'm',
    groups: ['Section', 'Infrastructure', 'Composition']
  },
  {
    name: 'carBoxDepth',
    title: 'Profondeur d’un box de stationnement',
    defaultValue: 5,
    min: 5,
    max: 10,
    unit: 'm',
    groups: ['Section', 'Infrastructure', 'Composition']
  },
  {
    name: 'carBoxOutsideWallsConcreteRatio',
    title: 'Ratio béton voile exposé d’un box de stationnement',
    defaultValue: 100,
    unit: '%',
    groups: ['Section', 'Infrastructure', 'Surface verticale extérieure']
  },
  {
    name: 'carBoxBeamHeight',
    title: 'Hauteur poutre d’un box de stationnement',
    defaultValue: 60,
    min: 50,
    max: 80,
    unit: 'cm',
    groups: ['Section', 'Infrastructure', 'Surface horizontale']
  },
  {
    name: 'carBoxBeamWidth',
    title: 'Largeur poutre d’un box de stationnement',
    defaultValue: 50,
    min: 20,
    max: 120,
    unit: 'cm',
    groups: ['Section', 'Infrastructure', 'Surface horizontale']
  },
  {
    name: 'carBoxPillarThickness',
    title: 'Épaisseur du poteau d’un box de stationnement',
    defaultValue: 25,
    min: 20,
    max: 30,
    unit: 'cm',
    groups: ['Section', 'Infrastructure', 'Surface verticale intérieure']
  },
  {
    name: 'carBoxPillarWidth',
    title: 'Largeur du poteau d’un box de stationnement',
    defaultValue: 150,
    min: 80,
    max: 200,
    unit: 'cm',
    groups: ['Section', 'Infrastructure', 'Surface verticale intérieure']
  },
  {
    name: 'carBoxPartitionThickness', // old = thickness
    title: 'Épaisseur de la cloison séparative d’un box de stationnement',
    defaultValue: 100,
    min: 100,
    max: 100,
    unit: 'mm',
    groups: ['Section', 'Infrastructure', 'Surface verticale intérieure']
  },
  {
    name: 'circulationLaneWidth',
    title: 'Largeur de voie de circulation',
    defaultValue: 5.5,
    min: 5.5,
    max: 6,
    unit: 'm',
    groups: ['Section', 'Infrastructure', 'Composition']
  },
  {
    name: 'firstBasementReinforcementLength',
    title: 'Longueur des confortements périphériques pour le 1er niveau de sous-sol',
    defaultValue: 0,
    min: 0,
    max: 0, // calculated (see : getBasementLevelOutsideWallsLinear2)
    unit: 'm',
    groups: ['Section', 'Infrastructure', 'Surface verticale'],
    notes:
      'Lorsque la longeur des confortements périphériques est égale à 0 (valeur par défaut), des talus périphériques sont intégrés. La valeur maximale pour la longeur des confortements périphérique est calculée en fonction du linéaire de voiles extérieurs du 1er niveau de sous-sol.'
  },
  {
    name: 'secondBasementReinforcementLength',
    title: 'Longueur des confortements périphériques pour le 2nd niveau de sous-sol',
    defaultValue: 0,
    min: 0,
    max: 0, // calculated (see : getBasementLevelOutsideWallsLinear2)
    unit: 'm',
    groups: ['Section', 'Infrastructure', 'Surface verticale'],
    notes:
      'Lorsque la longeur des confortements périphériques est égale à 0 (valeur par défaut), des talus périphériques sont intégrés. La valeur maximale pour la longeur des confortements périphérique est calculée en fonction du linéaire de voiles extérieurs du 2nd niveau de sous-sol.'
  },
  {
    name: 'ventilationGroupInfraLength',
    title: 'Longueur du groupe de ventilation en infrastructure',
    defaultValue: 5,
    min: 5,
    max: 10,
    unit: 'm',
    groups: ['Section', 'Infrastructure', 'Surface verticale']
  },
  {
    name: 'ventilationGroupInfraWidth',
    title: 'Largeur du groupe de ventilation en infrastructure',
    defaultValue: 1,
    min: 1,
    max: 2,
    unit: 'm',
    groups: ['Section', 'Infrastructure', 'Surface verticale']
  },
  {
    name: 'weldedMeshSteelRateGroundSlab',
    title: 'Ratio acier treillis soudé dallage béton',
    defaultValue: 40,
    min: 35,
    max: 50,
    unit: 'kg/m3',
    groups: ['Cage', 'Infrastructure', 'Surface horizontale']
  },
  {
    name: 'highAdhesionSteelRateGroundSlab',
    title: 'Ratio acier haute adhérence dallage béton',
    defaultValue: 20,
    min: 20,
    max: 70,
    unit: 'kg/m3',
    groups: ['Cage', 'Infrastructure', 'Surface horizontale']
  },
  {
    name: 'groundSlabThickness',
    title: 'Épaisseur dallage béton',
    defaultValue: 15,
    min: 13,
    max: 80,
    unit: 'cm',
    groups: ['Cage', 'Infrastructure', 'Surface horizontale']
  }
];
