import {
  LevelGranulometry,
  LevelGranulometryFullFilled,
  LevelGranulometryWithBearingRelativeSections,
  LevelGranulometryWithLodgments
} from '../../LevelGranulometry';
import { CommonPremiseSection } from '../../../sections/commonPremiseSections/CommonPremiseSection';

export const getLevelCommonPremiseSections = (
  levelGranulometry:
    | LevelGranulometryWithLodgments
    | LevelGranulometryWithBearingRelativeSections
    | LevelGranulometryFullFilled
    | LevelGranulometry
): CommonPremiseSection[] =>
  levelGranulometry.commonPremiseSections?.map((commonPremiseSection) => ({
    ...commonPremiseSection,
    surface: commonPremiseSection.displayedSurface || commonPremiseSection.defaultSurface,
    displayedSurface: commonPremiseSection.displayedSurface || commonPremiseSection.defaultSurface,
    width: commonPremiseSection.width || commonPremiseSection.defaultWidth,
    exposureRate: commonPremiseSection.exposureRate || commonPremiseSection.defaultExposureRate
  })) || [];
