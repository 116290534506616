import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../../../../ui/FormGroup';
import { Input } from '../../../../../ui/input/Input';
import { casePropertyChanged } from '../../../../../../store/actions/casePropertyChanged.action';
import { IconObject } from '../../../../../ui/Icons/iconObject';
import { CaseId } from '../../../../../../domain/specification/cases/CaseSpecification';
import { Property } from '../../../../../../domain/specification/cases/Property';

interface WoodOutsideWallTypeRatesProps {
  caseId: CaseId;
  outsideWallWoodRateProperty: Property;
  outsideWallWoodRateMaxValue: number;
  outsideWallTypeRatesValidity: boolean;
}

export const WoodOutsideWallTypeRates = ({
  caseId,
  outsideWallWoodRateProperty,
  outsideWallWoodRateMaxValue,
  outsideWallTypeRatesValidity
}: WoodOutsideWallTypeRatesProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Wood :
  const handleOutsideWallWoodRateChange = React.useCallback(
    (newOutsideWallWoodRate: number | null) => {
      if (
        caseId &&
        outsideWallWoodRateProperty &&
        (typeof newOutsideWallWoodRate === 'number' || newOutsideWallWoodRate === null)
      ) {
        dispatch(
          casePropertyChanged(caseId, {
            ...outsideWallWoodRateProperty,
            value: newOutsideWallWoodRate ?? undefined
          })
        );
      }
    },
    [dispatch, caseId, outsideWallWoodRateProperty]
  );

  return (
    <FormGroup horizontal={true} wrap={true}>
      <Input
        style={{ width: '40%', flex: '0 0 40%' }}
        name={`case${caseId}OutsideWallWoodRate`}
        label={t('outsideWallTypeRates.woodRate.label')}
        info={t('outsideWallTypeRates.woodRate.info')}
        type="number"
        min={0}
        max={100}
        minMax={{
          min: outsideWallWoodRateProperty.min,
          max: outsideWallWoodRateMaxValue,
          unit: outsideWallWoodRateProperty.unit
        }}
        color={outsideWallTypeRatesValidity ? undefined : 'orange'}
        placeholder={outsideWallWoodRateProperty.defaultValue}
        value={outsideWallWoodRateProperty.value}
        handleChange={handleOutsideWallWoodRateChange}
        suffix={outsideWallWoodRateProperty.unit}
        icon={<IconObject type="menu" iconName="wood" clickable={false} />}
      />
    </FormGroup>
  );
};
