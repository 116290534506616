import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../LevelGranulometry';
import { getSectionWallsLinear } from '../../../../sections/queries/walls/getSectionWallsLinear';
import { isSectionIncludedInSurfaceForSale } from '../../../../sections/SectionIncludedInSurfaceForSale';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { getLevelFullFilledContent } from '../../content/getLevelFullFilledContent';

export const getTopLevelWallsLinear = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry | LevelGranulometryFullFilled
): number =>
  getLevelFullFilledContent(levelGranulometry).reduce(
    (acc, section) =>
      isSectionIncludedInSurfaceForSale(section)
        ? acc + getSectionWallsLinear(caseGranulometry, levelGranulometry, section)
        : acc,
    0
  );
