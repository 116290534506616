import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelOutsideStructuralWallsThicknessDetails = (
  caseGranulometry: CaseGranulometry
): string => {
  const outsideWallsThickness = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'outsideWallsThickness'
  ) as number;
  return (
    '<b>' +
    outsideWallsThickness * 100 +
    ' cm</b> d’épaisseur paramétrés dans les propriétés de la cage.'
  );
};
