import { createSelector, Selector } from 'reselect';
import { ifDefined } from '../../../../utils/ifDefined';
import { selectGranulometry } from '../current.selector';
import {
  getParkingsCountByBuildingAndCase,
  ParkingsCountByBuildingAndCase
} from '../../../../domain/granulometry/queries/getParkingsCountByBuildingAndCase';
import { State } from '../../../../store/reducers';
import { Maybe } from '../../../../utils/Maybe';

export const selectParkingsCountByBuildingAndCase: Selector<
  State,
  Maybe<ParkingsCountByBuildingAndCase>
> = createSelector([selectGranulometry], ifDefined(getParkingsCountByBuildingAndCase));
