import { getSectionWallsLinear } from '../../getSectionWallsLinear';
import { getSectionTheoreticalExposureRate } from '../../../exposureRate/getSectionTheoreticalExposureRate';
import { SectionWithExposureRate } from '../../../../SectionWithExposureRate';
import {
  LevelGranulometry,
  LevelGranulometryFullFilled,
  LevelGranulometryWithBearingRelativeSections
} from '../../../../../levels/LevelGranulometry';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getSectionTheoreticalOutsideWallsLinear = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry:
    | LevelGranulometryWithBearingRelativeSections
    | LevelGranulometryFullFilled
    | LevelGranulometry,
  section: SectionWithExposureRate
): number => {
  const wallsLinear = getSectionWallsLinear(caseGranulometry, levelGranulometry, section);
  const exposureRate = getSectionTheoreticalExposureRate(section);
  return wallsLinear * exposureRate;
};
