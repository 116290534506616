import { ProjectProjection } from '../projection/project/ProjectProjection';
import { Notification } from './Notification';
import { ValidationRule } from './ValidationsRule';
import { projectNameMustBeFilled } from './rules/projectNameMustBeFilled';
import { projectCbsMustBeValid } from './rules/projectCbsMustBeValid';
import { sumOfAllCasesCBSMustBeEqualToProjectSurface } from './rules/sumOfAllCasesCuttedBuiltSurfacesMustBeEqualToProjectSurface';
import { projectCasesSurfacesMustBeValid } from './rules/projectCasesSurfacesMustBeValid';
import { projectCasesTopLevelsSurfacesRepartitionMustBeValid } from './rules/projectCasesTopLevelsSurfacesRepartitionMustBeValid';
import { adjustedHallMustBeWarned } from './rules/adjustedHallMustBeWarned';
import { bearingSurfacesMustBeLargeEnough } from './rules/bearingSurfacesMustBeLargeEnough';
import { caseTopLevelRealBuiltSurfacesMustBeLargeEnough } from './rules/caseTopLevelRealBuiltSurfacesMustBeLargeEnough';
import { projectCasesDistributionMustBeValid } from './rules/projectCasesDistributionMustBeValid';
import { projectCasesPeripheralReinforcementInputsMustBeValid } from './rules/projectCasesPeripheralReinforcementInputsMustBeValid';
import { Maybe } from '../../utils/Maybe';
import { Granulometry } from '../granulometry/Granulometry';
import { projectCasesOutsideWallTypeRatesMustBeValid } from './rules/projectCasesOutsideWallTypeRatesMustBeValid';
import { caseSurfaceForSaleMustBeExactlyFilled } from './rules/caseSurfaceForSaleMustBeExactlyFilled';
import { caseBasementLevelRealBuiltSurfaceMustBeLargeEnough } from './rules/caseBasementLevelRealBuiltSurfaceMustBeLargeEnough';

const rules: { numberOfParams: 1 | 2 | 3; validationRule: ValidationRule }[] = [
  { numberOfParams: 1, validationRule: projectCasesDistributionMustBeValid },
  { numberOfParams: 1, validationRule: projectCasesOutsideWallTypeRatesMustBeValid },
  { numberOfParams: 1, validationRule: projectCasesSurfacesMustBeValid },
  { numberOfParams: 1, validationRule: sumOfAllCasesCBSMustBeEqualToProjectSurface },
  {
    numberOfParams: 3,
    validationRule: projectCasesTopLevelsSurfacesRepartitionMustBeValid
  },
  { numberOfParams: 2, validationRule: projectNameMustBeFilled },
  { numberOfParams: 2, validationRule: projectCbsMustBeValid },
  {
    numberOfParams: 3,
    validationRule: projectCasesPeripheralReinforcementInputsMustBeValid
  },
  { numberOfParams: 3, validationRule: adjustedHallMustBeWarned },
  { numberOfParams: 3, validationRule: caseTopLevelRealBuiltSurfacesMustBeLargeEnough },
  { numberOfParams: 3, validationRule: caseBasementLevelRealBuiltSurfaceMustBeLargeEnough },
  { numberOfParams: 3, validationRule: bearingSurfacesMustBeLargeEnough },
  { numberOfParams: 3, validationRule: caseSurfaceForSaleMustBeExactlyFilled }
];

export const generateNotifications = (
  project: ProjectProjection | undefined,
  isNewProject: boolean,
  granulometry: Maybe<Granulometry>
): Notification[] =>
  rules.reduce((acc, rule) => {
    const result =
      project !== undefined
        ? rule.numberOfParams === 2
          ? rule.validationRule(project, isNewProject)
          : rule.numberOfParams === 3
          ? granulometry !== undefined
            ? rule.validationRule(project, isNewProject, granulometry)
            : true
          : rule.validationRule(project)
        : true;
    if (Array.isArray(result)) {
      return result.reduce((resultAcc, resultRule) => {
        return [...resultAcc, resultRule];
      }, acc);
    }
    return result === true ? acc : [...acc, result];
  }, [] as Notification[]);
