import { createSelector, Selector } from 'reselect';
import { State } from '../../reducers';
import { selectReportNomenclatureIdFromRoute } from './reportNomenclatureIdFromRoute.selector';
import { selectNomenclatures } from '../nomenclatures.selector';

export const selectReportNomenclatureTitle: Selector<State, string> = createSelector(
  [selectReportNomenclatureIdFromRoute, selectNomenclatures],
  (nomenclatureId, nomenclatures) =>
    nomenclatureId && nomenclatures
      ? nomenclatures.find((nomenclature) => nomenclature.id === nomenclatureId)?.title || 'ERR !'
      : 'ERR !'
);
