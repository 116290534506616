import * as R from 'ramda';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../levels/LevelGranulometry';
import { getCaseAverageHeatingSystem } from '../../../cases/queries/get/getCaseAverageHeatingSystem';
import { HeatingSystem } from '../../../../specification/lodgmentTypes/HeatingSystem';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { mustHaveGasBearingTechnicalSheaths } from './mustHaveGasBearingTechnicalSheaths';

export const WATER_BEARING_TECHNICAL_SHEATH_WIDTH = 0.6; // m : AEF (Alimentation Eau Froide)
export const GENERAL_BEARING_TECHNICAL_SHEATH_WIDTH = 0.4; // m : SG (Services Généraux)
export const INDIVIDUAL_GAS_BEARING_TECHNICAL_SHEATH_WIDTH = 0.7; // m : CHF - GAZ
export const OTHER_HEATING_SYSTEM_BEARING_TECHNICAL_SHEATH_WIDTH = 0.9; // m : CHF - GAZ
export const ELECTRICITY_BEARING_TECHNICAL_SHEATH_WIDTH = 1.03; // m : ENEDIS (colonne électrique)
export const COMMUNICATION_BEARING_TECHNICAL_SHEATH_WIDTH = 0.4; // m : FT (France Télécom)
export const BEARING_TECHNICAL_SHEATH_PARTITION_WALL_WIDTH = 0.05; // m : Separative partition

export const getBearingTechnicalSheathsWidth = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry | LevelGranulometryWithBearingRelativeSections
): number => {
  let bearingTechnicalSheathsWidths: number[] = [];
  // Water
  if (levelGranulometry.waterBearingTechnicalSheathEnabled !== false) {
    bearingTechnicalSheathsWidths = [
      ...bearingTechnicalSheathsWidths,
      WATER_BEARING_TECHNICAL_SHEATH_WIDTH
    ];
  }
  // General
  if (levelGranulometry.generalBearingTechnicalSheathEnabled !== false) {
    bearingTechnicalSheathsWidths = [
      ...bearingTechnicalSheathsWidths,
      GENERAL_BEARING_TECHNICAL_SHEATH_WIDTH
    ];
  }
  // Gaz – Heating system
  if (
    levelGranulometry.gasBearingTechnicalSheathEnabled !== false &&
    mustHaveGasBearingTechnicalSheaths(caseGranulometry)
  ) {
    bearingTechnicalSheathsWidths = [
      ...bearingTechnicalSheathsWidths,
      getCaseAverageHeatingSystem(caseGranulometry) === HeatingSystem.IndividualGasHeating
        ? INDIVIDUAL_GAS_BEARING_TECHNICAL_SHEATH_WIDTH
        : OTHER_HEATING_SYSTEM_BEARING_TECHNICAL_SHEATH_WIDTH
    ];
  }
  // Electricity
  if (levelGranulometry.electricityBearingTechnicalSheathEnabled !== false) {
    bearingTechnicalSheathsWidths = [
      ...bearingTechnicalSheathsWidths,
      ELECTRICITY_BEARING_TECHNICAL_SHEATH_WIDTH
    ];
  }
  // Communication
  if (levelGranulometry.communicationBearingTechnicalSheathEnabled !== false) {
    bearingTechnicalSheathsWidths = [
      ...bearingTechnicalSheathsWidths,
      COMMUNICATION_BEARING_TECHNICAL_SHEATH_WIDTH
    ];
  }
  // Partition walls
  const partitionWallsWidth =
    Math.max(0, bearingTechnicalSheathsWidths.length - 1) *
    BEARING_TECHNICAL_SHEATH_PARTITION_WALL_WIDTH;

  return roundWith2Decimal(R.sum(bearingTechnicalSheathsWidths) + partitionWallsWidth);
};
