import { LevelGranulometry } from '../../../../levels/LevelGranulometry';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';
import { SectionWithExposureRate } from '../../../SectionWithExposureRate';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { getSectionRealOutsideWallsLinear } from './getSectionRealOutsideWallsLinear';
import { getSectionOutsideWallsThickness } from '../wallsThickness/getSectionOutsideWallsThickness';
import { getSectionRealOutsideWallsSurface } from './getSectionRealOutsideWallsSurface';

export const getSectionRealOutsideWallsSurfaceDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry,
  section: SectionWithExposureRate
): string => {
  let details: string[] = [];
  details = [
    ...details,
    '<b>' +
      roundWith2Decimal(
        getSectionRealOutsideWallsLinear(caseGranulometry, levelGranulometry, section)
      ) +
      ' mL</b> de linéaire réel de voiles exposés'
  ];
  details = [
    ...details,
    '<b>* (' +
      roundWith2Decimal(getSectionOutsideWallsThickness(caseGranulometry)) +
      ' m</b> d’épaisseur de voiles exposés <b>/ 2)</b>'
  ];

  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(
        getSectionRealOutsideWallsSurface(caseGranulometry, levelGranulometry, section)
      ) +
      ' m\u00B2</b>'
  ];
  return details.join('<br />');
};
