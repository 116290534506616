import { RoomStaticElement } from './RoomStaticElement';

export const livingRoomKitchenCElements: RoomStaticElement[] = [
  { e1: 'chape ciment', e2: '', e3: '', quantity: 1 },
  { e1: 'revêtement de sol carrelage', e2: '', e3: '', quantity: 1 },
  { e1: 'résilient acoustique', e2: '', e3: '', quantity: 1 },
  { e1: 'plinthe carrelage', e2: '', e3: '', quantity: 1 },
  { e1: 'revêtement mural étanche cuisine', e2: '', e3: '', quantity: 1 },
  { e1: 'finition murale', e2: '', e3: '', quantity: 1 },
  { e1: 'enduit pelliculaire horizontal', e2: '', e3: '', quantity: 1 },
  { e1: 'finition plafond', e2: '', e3: '', quantity: 1 },
  { e1: 'gaine technique logement', e2: '', e3: '', quantity: 1 },
  { e1: 'gaine technique logement', e2: 'film polyéthylène sous cloison', e3: '', quantity: 1 },
  { e1: 'gaine technique logement', e2: 'colonne eaux usées', e3: '', quantity: 1 },
  { e1: 'gaine technique logement', e2: 'piquage eaux usées', e3: '', quantity: 1 },
  { e1: 'gaine technique logement', e2: 'colonne ventilation', e3: '', quantity: 1 },
  { e1: 'gaine technique logement', e2: 'raccord de colonne de ventilation', e3: '', quantity: 1 },
  { e1: 'gaine tableau électrique logement', e2: '', e3: '', quantity: 1 },
  { e1: 'tableau electrique', e2: '', e3: '', quantity: 1 },
  { e1: 'placard pivotant', e2: '', e3: '', quantity: 1 },
  { e1: 'placard pivotant', e2: 'tapée', e3: '', quantity: 1 },
  { e1: 'placard pivotant', e2: 'tapée', e3: 'peinture sur bois brut', quantity: 1 },
  { e1: 'porte de distribution', e2: '', e3: '', quantity: 1 },
  { e1: 'porte de distribution', e2: 'seuil bois passage de porte', e3: '', quantity: 1 },
  { e1: 'porte de distribution', e2: 'peinture sur bois prépeint', e3: '', quantity: 1 },
  { e1: 'porte de distribution', e2: 'huisserie porte de distribution', e3: '', quantity: 1 },
  {
    e1: 'porte de distribution',
    e2: 'huisserie porte de distribution',
    e3: 'peinture sur métal',
    quantity: 1
  },
  { e1: 'placard coulissant', e2: '', e3: '', quantity: 1 },
  { e1: 'placard coulissant', e2: 'tapée', e3: '', quantity: 1 },
  { e1: 'placard coulissant', e2: 'tapée', e3: 'peinture sur bois brut', quantity: 1 },
  { e1: 'porte palière', e2: '', e3: '', quantity: 1 },
  { e1: 'menuiserie extérieure', e2: '', e3: '', quantity: 1 },
  { e1: 'menuiserie extérieure', e2: "grille d'entrée d'air", e3: '', quantity: 1 },
  { e1: 'menuiserie extérieure', e2: "grille d'entrée d'air", e3: '', quantity: 1 },
  { e1: 'point centre', e2: '', e3: '', quantity: 1 },
  { e1: 'interrupteur', e2: '', e3: '', quantity: 1 },
  { e1: 'éclairage applique', e2: '', e3: '', quantity: 1 },
  { e1: 'interrupteur', e2: '', e3: '', quantity: 1 },
  { e1: 'prise de courant 16A', e2: '', e3: '', quantity: 1 },
  { e1: 'prise de courant 16A', e2: '', e3: '', quantity: 1 },
  { e1: 'prise RJ45', e2: '', e3: '', quantity: 1 },
  { e1: 'prise RJ45', e2: '', e3: '', quantity: 1 },
  { e1: 'prise TV', e2: '', e3: '', quantity: 1 },
  { e1: 'prise de courant 20A', e2: '', e3: '', quantity: 1 },
  { e1: 'prise de courant 20A', e2: '', e3: '', quantity: 1 },
  { e1: 'prise de courant 20A', e2: '', e3: '', quantity: 1 },
  { e1: 'prise de courant 16A', e2: '', e3: '', quantity: 1 },
  { e1: 'prise de courant 32A', e2: '', e3: '', quantity: 1 },
  { e1: "thermostat d'ambiance", e2: '', e3: '', quantity: 1 },
  { e1: 'sonnette', e2: '', e3: '', quantity: 1 },
  { e1: 'évier', e2: '', e3: '', quantity: 1 },
  { e1: 'chaudière individuelle gaz', e2: '', e3: '', quantity: 1 },
  { e1: 'chaudière individuelle gaz', e2: 'conduit 3Cep', e3: '', quantity: 1 },
  { e1: 'chaudière individuelle gaz', e2: 'piquage gaz', e3: '', quantity: 1 },
  { e1: 'chaudière individuelle gaz', e2: 'coffret protection gaz', e3: '', quantity: 1 },
  { e1: 'chaudière individuelle gaz', e2: 'qualigaz', e3: '', quantity: 1 },
  { e1: 'chaudière individuelle gaz', e2: "bouche d'extraction VMC gaz", e3: '', quantity: 1 },
  { e1: 'radiateur eau chaude 1000W', e2: '', e3: '', quantity: 1 },
  { e1: 'radiateur eau chaude 1500W', e2: '', e3: '', quantity: 1 }
];
