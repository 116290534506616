import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { Card } from '../../../../ui/Card';
import { selectCurrentCaseProjection } from '../../../../../store/selectors/projection/currentCase/projection.selector';
import { getCaseProperty } from '../../../../../domain/specification/cases/queries/get/properties/getCaseProperty';
import { getCaseOutsideWallConcreteRateMaxValue } from '../../../../../domain/specification/cases/queries/get/outsideWallTypeRates/getCaseOutsideWallConcreteRateMaxValue';
import { getCaseOutsideWallMasonryRateMaxValue } from '../../../../../domain/specification/cases/queries/get/outsideWallTypeRates/getCaseOutsideWallMasonryRateMaxValue';
import { getCaseOutsideWallWoodRateMaxValue } from '../../../../../domain/specification/cases/queries/get/outsideWallTypeRates/getCaseOutsideWallWoodRateMaxValue';
import { getCaseOutsideWallTypeRatesValidity } from '../../../../../domain/specification/cases/queries/get/outsideWallTypeRates/getCaseOutsideWallTypeRatesValidity';
import { selectCurrentCaseIdFromRoute } from '../../../../../store/selectors/navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { ConcreteOutsideWallTypeRates } from './outsideWallTypeRates/ConcreteOutsideWallTypeRate';
import { MasonryOutsideWallTypeRates } from './outsideWallTypeRates/MasonryOutsideWallTypeRate';
import { WoodOutsideWallTypeRates } from './outsideWallTypeRates/WoodOutsideWallTypeRate';
import { MasonryTypes } from './outsideWallTypeRates/MasonryTypes';
import { WoodEnvelopeTypes } from './outsideWallTypeRates/WoodEnvelopeTypes';
import { Note } from 'components/ui/Note';

export const OutsideWallTypeRates = () => {
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const currentlyEditedCase = useSelector(selectCurrentCaseProjection);
  const { t } = useTranslation();

  const outsideWallConcreteRateProperty =
    currentlyEditedCase && getCaseProperty(currentlyEditedCase, 'outsideWallConcreteRate');
  const outsideWallMasonryRateProperty =
    currentlyEditedCase && getCaseProperty(currentlyEditedCase, 'outsideWallMasonryRate');
  const outsideWallWoodRateProperty =
    currentlyEditedCase && getCaseProperty(currentlyEditedCase, 'outsideWallWoodRate');

  // Max value
  const outsideWallConcreteRateMaxValue = getCaseOutsideWallConcreteRateMaxValue({
    outsideWallMasonryRate: outsideWallMasonryRateProperty?.value,
    outsideWallWoodRate: outsideWallWoodRateProperty?.value
  });
  const outsideWallMasonryRateMaxValue = getCaseOutsideWallMasonryRateMaxValue({
    outsideWallConcreteRate: outsideWallConcreteRateProperty?.value,
    outsideWallWoodRate: outsideWallWoodRateProperty?.value
  });
  const outsideWallWoodRateMaxValue = getCaseOutsideWallWoodRateMaxValue({
    outsideWallConcreteRate: outsideWallConcreteRateProperty?.value,
    outsideWallMasonryRate: outsideWallMasonryRateProperty?.value
  });

  // Validity
  const outsideWallTypeRatesValidity = getCaseOutsideWallTypeRatesValidity(
    outsideWallConcreteRateProperty?.value,
    outsideWallMasonryRateProperty?.value,
    outsideWallWoodRateProperty?.value
  );

  if (
    process.env.GB_FEAT_OUTSIDE_WALL_TYPE_RATES === 'false' ||
    !caseId ||
    !currentlyEditedCase ||
    !outsideWallConcreteRateProperty ||
    !outsideWallMasonryRateProperty ||
    !outsideWallWoodRateProperty
  )
    return null;

  return (
    <Card>
      <SpecialLabel label={t('Distribution of vertical structural envelope types')} />
      <SpecialLabel label={t('outsideWallTypeRates.concreteRate.specialLabel')} small />
      <ConcreteOutsideWallTypeRates
        caseId={caseId}
        outsideWallConcreteRateProperty={outsideWallConcreteRateProperty}
        outsideWallConcreteRateMaxValue={outsideWallConcreteRateMaxValue}
        outsideWallTypeRatesValidity={outsideWallTypeRatesValidity}
      />
      <SpecialLabel label={t('outsideWallTypeRates.masonryRate.specialLabel')} small />
      <MasonryOutsideWallTypeRates
        caseId={caseId}
        outsideWallMasonryRateProperty={outsideWallMasonryRateProperty}
        outsideWallMasonryRateMaxValue={outsideWallMasonryRateMaxValue}
        outsideWallTypeRatesValidity={outsideWallTypeRatesValidity}
      />
      <MasonryTypes caseId={caseId} />
      <SpecialLabel label={t('outsideWallTypeRates.woodRate.specialLabel')} small />
      <WoodOutsideWallTypeRates
        caseId={caseId}
        outsideWallWoodRateProperty={outsideWallWoodRateProperty}
        outsideWallWoodRateMaxValue={outsideWallWoodRateMaxValue}
        outsideWallTypeRatesValidity={outsideWallTypeRatesValidity}
      />
      <WoodEnvelopeTypes caseId={caseId} />
      <Note separatorBefore>{t('outsideWallTypeRates.note')}</Note>
    </Card>
  );
};
