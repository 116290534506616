import * as R from 'ramda';
import { LevelGranulometry } from '../../../LevelGranulometry';
import { getLevelSections } from '../../sections/getLevelSections';
import {
  filterSectionWithTechnicalSheaths,
  SectionWithTechnicalSheaths
} from '../../../../sections/SectionWithTechnicalSheaths';

export const getTopLevelSectionsWithTechnicalSheaths = (
  levelGranulometry: LevelGranulometry
): SectionWithTechnicalSheaths[] =>
  R.pipe(getLevelSections, filterSectionWithTechnicalSheaths)(levelGranulometry);
