import { LevelGranulometryInitialEntries } from '../../../../LevelGranulometry';
import { isGroundLevel } from '../../../is/isGroundLevel';
import { getUpperLevelSurfaceForSale } from './getUpperLevelSurfaceForSale';
import { getGroundLevelSurfaceForSale } from './getGroundLevelSurfaceForSale';
import { CaseProjection } from '../../../../../../projection/cases/CaseProjection';

export const getTopLevelSurfaceForSale = (
  caseProjection: CaseProjection,
  levelGranulometry: LevelGranulometryInitialEntries
): number =>
  isGroundLevel(levelGranulometry)
    ? getGroundLevelSurfaceForSale(caseProjection, levelGranulometry)
    : getUpperLevelSurfaceForSale(caseProjection, levelGranulometry);
