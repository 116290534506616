import { createSelector, Selector } from 'reselect';
import * as R from 'ramda';
import { Maybe } from '../../../utils/Maybe';
import { State } from '../../../store/reducers';
import { BuildingId } from '../../../domain/specification/buildings/BuildingSpecification';
import { ifDefined } from '../../../utils/ifDefined';
import { selectFirstBuilding } from './firstBuilding.selector';

export const selectFirstBuildingId: Selector<State, Maybe<BuildingId>> = createSelector(
  [selectFirstBuilding],
  ifDefined(R.prop('id'))
);
