export enum LodgmentType {
  T1 = 't1',
  T2C = 't2c',
  T2 = 't2',
  T3C = 't3c',
  T3 = 't3',
  T4C = 't4c',
  T4 = 't4',
  T5 = 't5'
}

export const LodgmentTypesInArray = [
  LodgmentType.T1,
  LodgmentType.T2C,
  LodgmentType.T2,
  LodgmentType.T3C,
  LodgmentType.T3,
  LodgmentType.T4C,
  LodgmentType.T4,
  LodgmentType.T5
];
