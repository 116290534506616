import { LevelSpecification } from '../../domain/specification/levels/LevelSpecification';

export const SET_TOP_LEVELS_SPECIFICATIONS = 'SET_TOP_LEVELS_SPECIFICATIONS';

export const setTopLevelsSpecifications = (caseId: string, levels: LevelSpecification[]) => ({
  type: SET_TOP_LEVELS_SPECIFICATIONS,
  payload: { caseId, levels }
});

export type SetTopLevelsSpecificationsAction = ReturnType<typeof setTopLevelsSpecifications>;
