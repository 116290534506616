import * as R from 'ramda';
import { BuildingId } from '../../specification/buildings/BuildingSpecification';
import { CaseId } from '../../specification/cases/CaseSpecification';
import { Granulometry } from '../Granulometry';
import { getParkingsCountByBuildingCaseLevelAndType } from './getParkingsCountByBuildingCaseLevelAndType';
import { ParkingsCountByType } from '../levels/queries/basementLevels/counts/getBasementLevelParkingsCountByType';
import { ParkingsCountByLevelAndType } from '../cases/queries/sections/counts/getCaseParkingSectionsCountByLevelAndType';

export type GetParkingsCountByBuildingAndCase = (
  granulometry: Granulometry
) => Record<BuildingId, Record<CaseId, number>>;

export type ParkingsCountByBuildingAndCase = Record<string, Record<string, number>>;

export const getParkingsCountByBuildingAndCase: GetParkingsCountByBuildingAndCase = R.compose(
  R.mapObjIndexed(
    R.mapObjIndexed(
      R.compose<[ParkingsCountByLevelAndType], ParkingsCountByType[], number[], number>(
        R.sum,
        R.chain(R.values),
        R.values
      )
    )
  ),
  getParkingsCountByBuildingCaseLevelAndType
);
