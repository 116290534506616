import {
  LevelGranulometry,
  LevelGranulometryFullFilled,
  LevelGranulometryWithBearingRelativeSections,
  LevelGranulometryWithLodgments
} from '../../LevelGranulometry';
import { OfficeSection } from '../../../sections/officeSection/officeSection';

export const getLevelOfficeSections = (
  levelGranulometry:
    | LevelGranulometryWithLodgments
    | LevelGranulometryWithBearingRelativeSections
    | LevelGranulometryFullFilled
    | LevelGranulometry
): OfficeSection[] =>
  levelGranulometry.officeSections?.map((officeSection) => ({
    ...officeSection,
    surface: officeSection.displayedSurface || officeSection.defaultSurface,
    displayedSurface: officeSection.displayedSurface || officeSection.defaultSurface,
    width: officeSection.width || officeSection.defaultWidth,
    exposureRate: officeSection.exposureRate || officeSection.defaultExposureRate
  })) || [];
