import { State } from '../reducers';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { filter, startWith, switchMap } from 'rxjs/operators';
import { makeComEthJson } from '../../api/cstb/comEth/makeComEthJson';
import { postComEthToCstbApi } from '../../api/cstb/comEth/postComEthToCstbApi';
import { selectGranulometry } from '../selectors/granulometry/current.selector';
import { selectProject } from '../selectors/project';
import { defer, EMPTY, of } from 'rxjs';
import cstbSlice from '../reducers/cstb.slice';
import { ExistingProject } from '../../domain/project/Project';
import { Granulometry } from '../../domain/granulometry/Granulometry';
import { selectContributeursNomenclature } from '../selectors/contributeursNomenclature.selector';
import { Nomenclature } from '../../domain/Nomenclature';
import { reportFetched } from '../actions/report.actions';
import { getComEthReportFromCstbApi } from '../../api/cstb/comEth/getComEthReportFromCstbApi';

export const fetchCstbWhenReportIsFetchedEpic: Epic<Action, Action, State> = (action$, state$) =>
  action$.pipe(
    filter(reportFetched.match),
    switchMap((action) => {
      if (process.env.GB_FEAT_CSTB_API !== 'false') {
        const report = action.payload.report;

        const state = state$.value;
        const project = selectProject(state) as ExistingProject;
        const granulometry = selectGranulometry(state) as Granulometry;
        const nomenclature = selectContributeursNomenclature(state);
        if (nomenclature) {
          // getComEthReportFromCstbApi(taskId),
          const cstbComEth = makeComEthJson(project, report, granulometry, nomenclature);
          if (process.env.GB_ENV === 'dev' || process.env.GB_ENV === 'recette') {
            // eslint-disable-next-line no-console
            console.info('CSTB JSON SENDED', cstbComEth);
          }
          // see : https://dev.service-gobuild.dimn-cstb.fr/ui/
          return defer(() => postComEthToCstbApi(cstbComEth)).pipe(
            switchMap((taskId) => {
              if (process.env.GB_ENV === 'dev' || process.env.GB_ENV === 'recette') {
                // eslint-disable-next-line no-console
                console.info('CSTB API – TASK ID RECEIVED', taskId);
              }
              return defer(() => getComEthReportFromCstbApi(taskId)).pipe(
                switchMap((report) => {
                  if (process.env.GB_ENV === 'dev' || process.env.GB_ENV === 'recette') {
                    // eslint-disable-next-line no-console
                    console.info('CSTB API –  JSON REPORT RECEIVED', report);
                  }
                  return of(cstbSlice.actions.reportFetched({ report }));
                }),
                // catchError(error => of(cstbSlice.actions.reportFetchFailed(error))),
                startWith(cstbSlice.actions.reportFetch())
              );
            })
          );
        } else {
          return EMPTY;
        }
      } else {
        return EMPTY;
      }
    })
  );
