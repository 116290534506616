import i18next from 'i18next';
import { Section } from '../Section';
import { SlabSectionName } from './SlabSection';

interface OffsetSlabProps {
  surface: number;
}

export interface OffsetSlabSection extends Section {
  name: SlabSectionName.OffsetSlab;
}

const offsetSlab = ({ surface }: OffsetSlabProps): OffsetSlabSection => ({
  // Section
  id: 'NOID',
  name: SlabSectionName.OffsetSlab,
  title: i18next.t('offsetSlab.title'),
  type: 'slab',
  displayedSurface: surface
});

export default offsetSlab;
