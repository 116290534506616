import React, { useEffect, useState } from 'react';
import { UiGeometry } from '../../../Geometry';
import { UploadCard } from './UploadCard';
import { fireDrawHelperEvents } from '../../../../../../../services/mapBox/drawHelper/drawHelper.events';
import { fireDrawEvents } from '../../../../../../../services/mapBox/events';
import { ResizeCard } from './ResizeCard';
import { NewDistanceForLine } from '../../../../../../../services/mapBox/utils/polygon/resizePolygon';
import { DrawCard } from './DrawCard';
import { Button } from '../../../../../../ui/Button';
import { LoadImageOptions } from '../../../../../../../services/mapBox/map/map.repository';
import {
  FloorSpaceCollection,
  getFloorSpaceByDrawHelperId,
  isFloorSpaceFeature,
  LevelGeometry
} from '../../../../../../../services/mapBox/mapboxDraw/levelGeometry';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { getLoadImageOptions } from '../../../../../../../services/mapBox/drawHelper/drawHelper.model';
import { createFile } from '../../../../../../../services/pdfToImages/create.pdfToImages';
import { deleteImage } from '../../../../../../../services/pdfToImages/delete.pdfToImages';

interface FloorSpaceSideDrawModeProps {
  ui: UiGeometry;
  toggleSetDrawHelperMode: () => void;
}

export interface StepState {
  upload?: LoadImageOptions;
  step2: { draw?: LevelGeometry; displayDrawButton: boolean };
  resize?: NewDistanceForLine;
  number: number;
}

export const SideDrawMode = ({ ui, toggleSetDrawHelperMode }: FloorSpaceSideDrawModeProps) => {
  const floorSpaceCollection = ui.draw?.getAll() as FloorSpaceCollection;

  const [step, setStep] = useState<StepState>({ number: 1, step2: { displayDrawButton: true } });

  useEffect(() => {
    if (!step.upload) return;
    const feature = getFloorSpaceByDrawHelperId(step.upload.layerName, floorSpaceCollection);
    if (feature && !step.step2.draw) {
      setStep({ ...step, step2: { ...step.step2, draw: feature, displayDrawButton: false } });
    }
  }, [floorSpaceCollection]);

  const goStepBack = () => setStep({ ...step, number: step.number - 1 >= 1 ? step.number - 1 : 1 });
  const goStepForward = () =>
    setStep({ ...step, number: step.number + 1 < 3 ? step.number + 1 : 3 });

  const removeImage = () => {
    if (!step.upload) return;
    fireDrawHelperEvents(ui.map).remove({ layerName: step.upload.layerName });
    setStep({ ...step, upload: undefined });
    deleteImage(step.upload.imagePath);
  };

  const addImage = async (selectedFile: File, pageToConvert: number) => {
    const fileUrl = await createFile(selectedFile, pageToConvert);
    //await createFile(selectedFile, pageToConvert);
    const options = getLoadImageOptions(fileUrl);
    fireDrawHelperEvents(ui.map).addDrawHelper(options);
    setStep({ ...step, upload: options });
  };

  const removeDraw = () => {
    if (!isFloorSpaceFeature(step.step2.draw)) return;
    fireDrawEvents(ui.map).draw.delete({ features: [step.step2.draw], drawHelperMode: true });
    setStep({ ...step, step2: { displayDrawButton: true } });
  };

  const onClickResize = (size: NewDistanceForLine) => {
    try {
      if (!isFloorSpaceFeature(step.step2.draw)) return;
      fireDrawEvents(ui.map).resize({
        selectedFeatures: { type: 'FeatureCollection', features: [step.step2.draw] },
        size
      });
    } catch (e) {
      console.error(e);
    }
  };

  const validate = () => {
    if (!isFloorSpaceFeature(step.step2.draw)) return;
    fireDrawEvents(ui.map).layer.lock(step.step2.draw);
    fireDrawHelperEvents(ui.map).validate({ featureId: step.step2.draw.id });
    toggleSetDrawHelperMode();
    if (!step.upload) return;
    deleteImage(step.upload.imagePath);
  };

  const facadeCount = step.step2?.draw?.properties.facades?.length || 0;

  return (
    <>
      {step.number === 1 && (
        <UploadCard
          addImage={addImage}
          onClickRemoveImage={removeImage}
          step={step}
          goStepForward={goStepForward}
        />
      )}
      {step.number === 2 && (
        <DrawCard
          drawPolygon={() => (ui.draw as MapboxDraw).changeMode('draw_polygon')}
          removePolygon={removeDraw}
          step={step}
          goStepBack={goStepBack}
          goStepForward={goStepForward}
        />
      )}
      {step.number === 3 && (
        <ResizeCard
          resize={onClickResize}
          setStep={setStep}
          step={step}
          goStepBack={goStepBack}
          facadeCount={facadeCount}
        />
      )}
      {step.resize && (
        <div>
          <Button content={'Terminer'} handleClick={validate} />
        </div>
      )}
    </>
  );
};
