import { createFilter } from '../createFilter';
import { ReportEntry, reportEntryLabels } from '../../../../../../domain/report/ReportEntry';
import { Column } from 'react-table';
import { filterRowsAndPivotsThroughSelect } from '../filters/filterRowsAndPivotsThroughSelect';
import { reportProductList } from '../reportEntries/reportProductList';
import classNames from 'classnames';
import * as React from 'react';

export const getProductColumns = (report: ReportEntry[], foldedColumns: string[]): Column => ({
  Header: 'Produits',
  columns: [
    {
      Header: reportEntryLabels.product,
      accessor: 'product',
      filterMethod: filterRowsAndPivotsThroughSelect,
      Filter: createFilter(reportProductList(report)),
      Cell: (cellInfo) => '<b>' + cellInfo.row.product + '</b>',
      PivotValue: (cellInfo) => cellInfo.value,
      /* Pivot: row =>
        row.value.charAt(0).toUpperCase() + row.value.slice(1) + ' (' + row.row.productId[0] + ')', */
      Aggregated: () => '',
      sortable: false,
      resizable: false,
      width: 250,
      className: classNames('separator', {
        folded: foldedColumns.indexOf(reportEntryLabels.product) > -1
      }),
      headerClassName: classNames('separator', {
        folded: foldedColumns.indexOf(reportEntryLabels.product) > -1
      })
    }
    /* {
      Header: reportEntryLabels.productId,
      accessor: 'productId',
      filterMethod: filterRowsAndPivotsThroughSelect,
      Filter: createFilter(reportProductIdList(report)),
      Cell: props => <b>{props.row.productId}</b>,
      aggregate: values => values[0],
      Aggregated: cellInfo => (cellInfo.groupedByPivot && cellInfo.level < 4 ? '' : cellInfo.value),
      sortable: false,
      resizable: false,
      width: 100,
      className: classNames({
        folded: foldedColumns.indexOf('ID Produit') > -1
      }),
      headerClassName: classNames({
        folded: foldedColumns.indexOf('ID Produit') > -1
      })
    } */
  ]
});
