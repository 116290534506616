import stringify from 'json-stringify-safe';
import { ApiServerError } from './errors/server.error';
import { ApiAuthError } from './errors/auth.error';
import { ApiRedirectError } from './errors/redirect.error';

const endpoint = (path: string) => `${process.env.GB_BACKEND_URL}${path}`;

const fetchAndHandle = async (
  path: string,
  method: string,
  body?: object,
  additionalHeaders: Record<string, string> = {}
): Promise<Response> => {
  const headers: HeadersInit = {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
    ...additionalHeaders
  };

  const response = await fetch(endpoint(path), {
    method,
    headers,
    mode: 'cors',
    credentials: 'include',
    body: body ? stringify(body, null, 2) : undefined
  });

  if (response.status === 401 || response.status === 403)
    throw new ApiAuthError(path, response.status, response.statusText);
  if (response.status >= 400) throw new ApiServerError(path, response.status, response.statusText);
  if (response.status >= 300 || response.redirected)
    throw new ApiRedirectError(
      path,
      response.status,
      response.statusText,
      response.headers.get('location') || 'other URL'
    );

  return response;
};

export const get = (path: string) => fetchAndHandle(path, 'GET');

export const post = (path: string, body: object): Promise<Response> =>
  fetchAndHandle(path, 'POST', body, { 'Content-Type': 'application/json' });

export const put = (path: string, body: object): Promise<Response> =>
  fetchAndHandle(path, 'PUT', body, { 'Content-Type': 'application/json' });

export const patch = (path: string, body: object): Promise<Response> =>
  fetchAndHandle(path, 'PATCH', body, {
    'Content-Type': 'application/merge-patch+json'
  });
