import * as R from 'ramda';
import { CaseGranulometry } from '../../../CaseGranulometry';
import { getCaseSectionsWithTSCountAndTheoricalTechnicalSheathCountByTopLevel } from './getCaseSectionsWithTSCountAndTheoricalTechnicalSheathCountByTopLevel';

type TechnicalSheathsDistributionByTopLevel = {
  levelIndex: number;
  sheathsDistribution: number[];
}[];

export const getCaseTechnicalSheathsDistributionByTopLevel = (
  caseGranulometry: CaseGranulometry
): TechnicalSheathsDistributionByTopLevel => {
  const countsByTopLevel =
    getCaseSectionsWithTSCountAndTheoricalTechnicalSheathCountByTopLevel(caseGranulometry);
  return countsByTopLevel.reduce((acc, counts, index) => {
    let previousLevelsTechnicalSheathMaxCount = 0;
    for (let i = index; i >= 0; i--) {
      previousLevelsTechnicalSheathMaxCount = Math.max(
        previousLevelsTechnicalSheathMaxCount,
        countsByTopLevel[i].theoricalSheathCount
      );
    }
    const realSheathsCount = Math.max(
      counts.theoricalSheathCount,
      previousLevelsTechnicalSheathMaxCount
    );
    const sheathsCountPerLodgment = Math.floor(realSheathsCount / counts.sectionWithSheathsCount);
    const extraSheathsCount = realSheathsCount % counts.sectionWithSheathsCount;
    const sheathsDistribution = R.times(
      (i) => Math.max(sheathsCountPerLodgment + (i < extraSheathsCount ? 1 : 0), 2),
      counts.sectionWithSheathsCount
    );
    return [
      ...acc,
      {
        levelIndex: counts.levelIndex,
        sheathsDistribution
      }
    ];
  }, [] as TechnicalSheathsDistributionByTopLevel);
};
