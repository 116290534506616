import * as R from 'ramda';
import { LevelGranulometry } from '../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { getTopLevelExternalInsulationThickness } from './wallsThickness/getTopLevelExternalnalInsulationThickness';
import { getTopLevelStructuralFacadeExternalLinears } from './getTopLevelStructuralFacadeExternalLinears';

export const getTopLevelInsulatedFacadeExternalLinears = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): number[] =>
  R.reduce<number, number[]>(
    (acc, structuralFacadeExternalLinear) => [
      ...acc,
      structuralFacadeExternalLinear + getTopLevelExternalInsulationThickness(caseGranulometry)
    ],
    [] as number[]
  )(getTopLevelStructuralFacadeExternalLinears(caseGranulometry, levelGranulometry));
