import * as R from 'ramda';
import {
  LevelGranulometry,
  LevelGranulometryFullFilled,
  LevelGranulometryWithBearingRelativeSections,
  LevelGranulometryWithLodgments
} from '../../../LevelGranulometry';
import { getTopLevelLodgmentSections } from '../sections/getTopLevelLodgmentSections';

export const getTopLevelLodgmentSectionsCount = (
  levelGranulometry:
    | LevelGranulometryWithLodgments
    | LevelGranulometryWithBearingRelativeSections
    | LevelGranulometryFullFilled
    | LevelGranulometry
): number => R.pipe(getTopLevelLodgmentSections, R.reduce(R.inc, 0))(levelGranulometry);
