import * as R from 'ramda';
import { LodgmentTypeSpecification } from '../../../lodgmentTypes/LodgmentTypeSpecification';
import { RoomSpecification } from '../../RoomSpecification';

export const setLodgmentTypeSpecificationRoom =
  () =>
  (roomIndex: number, roomSpecification: RoomSpecification) =>
  (lodgmentTypeSpecification: LodgmentTypeSpecification): LodgmentTypeSpecification =>
    R.over(
      R.lensPath(['rooms', roomIndex]),
      R.mergeLeft(roomSpecification)
    )(lodgmentTypeSpecification);
