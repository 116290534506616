import { LevelGranulometry } from '../../../../LevelGranulometry';
import {
  isSectionAStairsShaft,
  StairsShaftSection
} from '../../../../../sections/circulationSections/stairsShaft';
import { mustHaveStairShaftSections } from '../../../mustHave/mustHaveStairShaftSections';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelInsideWallsThickness } from '../wallsThickness/getTopLevelInsideWallsThickness';
import { getTopLevelStairsShafWallsSurface } from './getTopLevelStairsShafWallsSurface';
import { getLevelFullFilledContent } from '../../../content/getLevelFullFilledContent';

export const getTopLevelStairsShaftWallsSurfaceDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): string =>
  mustHaveStairShaftSections(caseGranulometry)
    ? '<b>' +
      getTopLevelInsideWallsThickness(caseGranulometry) +
      ' m</b> d’épaisseur de voile intérieur<br />' +
      '<b>* (</b>' +
      (
        getLevelFullFilledContent(levelGranulometry).filter(
          isSectionAStairsShaft
        ) as StairsShaftSection[]
      )
        .reduce(
          (acc, stairsShaftSection, index) => [
            ...acc,
            '<b>(' +
              stairsShaftSection.length +
              ' m</b> de longueur de <i>Cage d’escalier ' +
              (index + 1) +
              '</i><br /><b>+ ' +
              stairsShaftSection.width +
              ' m</b> de largeur de <i>Cage d’escalier ' +
              (index + 1) +
              '</i><b>) * 2</b>'
          ],
          [] as string[]
        )
        .join('<br />+ ') +
      ')<br /><b>= ' +
      getTopLevelStairsShafWallsSurface(caseGranulometry, levelGranulometry) +
      ' m\u00B2</b>'
    : 'Il n’y a pas de cage d’escalier dans cette cage';
