import { createSelector, Selector } from 'reselect';
import { ifDefined } from '../../../../utils/ifDefined';
import { selectCurrentCaseGranulometry } from './currentCaseGranulometry.selector';
import { getCaseBasementLevelsRealBuiltSurface } from '../../../../domain/granulometry/cases/queries/levels/surfaces/getCaseBasementLevelsRealBuiltSurface';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';
import { Surface } from '../../../../domain/specification/Surface';

export const selectCurrentCaseBasementsRealBuiltSurface: Selector<
  State,
  Maybe<Surface>
> = createSelector(
  [selectCurrentCaseGranulometry],
  ifDefined(getCaseBasementLevelsRealBuiltSurface)
);
