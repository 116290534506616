import { createSelector, Selector } from 'reselect';
import { State } from '../../../store/reducers';
import { Maybe } from '../../../utils/Maybe';
import * as R from 'ramda';
import { ifDefined } from '../../../utils/ifDefined';
import { selectLodgmentsCountByBuildingAndCase } from './lodgmentsCountByBuildingAndCase.selector';

type LodgmentsCountByBuilding = Maybe<Record<string, number>>;

export const selectLodgmentsCountByBuilding: Selector<State, LodgmentsCountByBuilding> =
  createSelector(
    [selectLodgmentsCountByBuildingAndCase],
    ifDefined(R.mapObjIndexed(R.pipe(R.values, R.sum)))
  );
