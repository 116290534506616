import {
  GRAY_COLOR,
  HARDROCKS_COLOR,
  OTHERS_COLOR,
  WARNING_COLOR
} from '../../../../constants/appConstants';

export const DRAW_COLORS = {
  LEVEL_DEACTIVATE_COLOR: { fill: GRAY_COLOR, line: HARDROCKS_COLOR },
  LEVEL_BASEMENT_COLOR: { fill: '#a8defa', line: '#a8defa' },
  LEVEL_TOP_COLOR: { fill: OTHERS_COLOR, line: OTHERS_COLOR },
  ROTATE_MODE_COLOR: { fill: '#00ff00', line: '#008000' },
  POLYGON_ACTIVE_COLOR: { fill: '#fbb03b', line: WARNING_COLOR }
};
