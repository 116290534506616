import { cleanFileName } from '../utils/cleanFileName';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { ClimaWinJsonEntry } from '../../../../../api/climaWin/makeClimaWinJson';

export const downloadClimaWin = async (
  report: Partial<ClimaWinJsonEntry>[],
  projectName: string
) => {
  const fileName =
    'gobuild-' +
    cleanFileName(projectName) +
    '-climawin-' +
    new Date().toISOString().slice(0, 10) +
    '.csv';
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(projectName.replace(/[*?:\\/[\]]/g, ' ') + ' – ClimaWin');

  worksheet.columns = [
    { header: 'Libellé ClimaWin', key: 'element', style: { alignment: { wrapText: true } } },
    {
      header: 'Référence Inies',
      key: 'co2Title',
      style: { alignment: { wrapText: true } }
    },
    {
      header: 'IdFIche',
      key: 'co2Id',
      style: { alignment: { wrapText: true } }
    },
    { header: 'Lot', key: 'refLot', style: { alignment: { wrapText: true } } },
    { header: 'Sous-lot', key: 'refSsLot1', style: { alignment: { wrapText: true } } },
    { header: 'Quantité', key: 'co2Quantity', style: { alignment: { wrapText: true } } },
    { header: 'UF', key: 'unit', style: { alignment: { wrapText: true } } },
    {
      header: 'UniqueId ClimaWin',
      key: 'ClimaWin',
      style: { alignment: { wrapText: true } }
    }
  ];
  worksheet.addRows(report, 'i');
  workbook.csv
    .writeBuffer({
      formatterOptions: {
        delimiter: ';',
        rowDelimiter: '\u000D\u000A', // /r/n
        writeBOM: true
      }
    })
    .then(function (buffer) {
      FileSaver.saveAs(
        new Blob([/* new Uint8Array([0xef, 0xbb, 0xbf]), */ buffer], {
          type: 'text/plain;charset=utf-8'
        }),
        fileName
      );
    });
};
