import * as R from 'ramda';
import { CaseGranulometry } from '../cases/CaseGranulometry';
import { getCaseTopLevels } from '../cases/queries/levels/getCaseTopLevels';
import { OutsideLevelGranulometry } from '../levels/OutsideLevelGranulometry';
import { isGroundLevel } from '../levels/queries/is/isGroundLevel';
import { isHighestLevel } from '../levels/queries/is/isHighestLevel';
import { createTopLevelOutsideStairsShaftSection } from '../levels/queries/topLevels/sections/create/createTopLevelOutsideStairsShaftSection';
import { getCaseOutsideStairShaftSectionsCount } from '../cases/queries/sections/counts/getCaseOutsideStairShaftSectionsCount';
import { OutsideSection } from '../sections/outsideSections/OutsideSection';

export const addAndFillOutsideLevels = (caseGranulometry: CaseGranulometry): CaseGranulometry => {
  return {
    ...caseGranulometry,
    outsideLevels: getCaseTopLevels(caseGranulometry).reduce(
      (acc, levelGranulometry, levelIndex) =>
        [
          ...acc,
          {
            level: levelGranulometry.level,
            ceilingHeight: levelGranulometry.ceilingHeight,
            floorThickness: levelGranulometry.floorThickness,
            content: R.times(
              R.identity,
              getCaseOutsideStairShaftSectionsCount(caseGranulometry)
            ).reduce(
              (acc, l, sectionIndex) => [
                ...acc,
                {
                  ...createTopLevelOutsideStairsShaftSection(
                    caseGranulometry,
                    'outsideStairsShaft' + sectionIndex + caseGranulometry.labelIndex,
                    'C' + caseGranulometry.labelIndex + 'OL' + levelIndex + 'S' + sectionIndex
                  ),
                  firstInGroup: isHighestLevel(caseGranulometry, levelGranulometry),
                  lastInGroup: isGroundLevel(levelGranulometry),
                  openedAbove: !isHighestLevel(caseGranulometry, levelGranulometry),
                  openedBelow: !isGroundLevel(levelGranulometry)
                }
              ],
              [] as OutsideSection[]
            )
          }
        ] as OutsideLevelGranulometry[],
      [] as OutsideLevelGranulometry[]
    ) as unknown as OutsideLevelGranulometry[]
  };
};
