import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Email, Password, User } from '../../domain/User';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import PublicRoutes from '../../routes/publicRoutes';
import { CustomPriceList } from '../../domain/Account';
import { AuthToken } from '../../AuthToken';
import { DateString } from '../../DateString';

export type Auth = {
  token: AuthToken;
  expiration: DateString;
};

export interface AnonymousState {
  sessionState: 'UNAUTHENTICATED';
}

export interface AuthenticatingState {
  sessionState: 'AUTHENTICATING';
}

export interface AccessDeniedState {
  sessionState: 'DENIED';
  auth: {
    errorMessage: string;
  };
}

export interface AuthenticatedState {
  sessionState: 'AUTHENTICATED';
  auth: Auth;
  details: User;
}

export interface RenewingAuthenticationState {
  sessionState: 'RENEWING' | 'RENEWING_AUTOMATICALLY';
  auth: Auth;
  details: User;
}

export interface ExpiredAuthenticationState {
  sessionState: 'EXPIRED';
  auth: Auth;
  details: User;
}

export interface ChangingPasswordState {
  sessionState: 'CHANGING_PASSWORD';
  auth: Auth;
  details: User;
}

export interface ChangingCustomPriceListState {
  sessionState: 'CHANGING_CUSTOM_PRICE_LIST';
  auth: Auth;
  details: User;
}

export type UserState =
  | AnonymousState
  | AuthenticatingState
  | AccessDeniedState
  | AuthenticatedState
  | RenewingAuthenticationState
  | ExpiredAuthenticationState
  | ChangingPasswordState
  | ChangingCustomPriceListState;

export type SessionState = UserState['sessionState'];

const initialState: UserState = {
  sessionState: 'UNAUTHENTICATED'
} as UserState;

const isLoginLocationAction = (action: AnyAction): action is LocationChangeAction =>
  action.type === LOCATION_CHANGE &&
  PublicRoutes.Login.path === (action as LocationChangeAction).payload.location.pathname;

const userState = createSlice({
  name: 'user',
  initialState,
  reducers: {
    authenticate(
      state,
      action: PayloadAction<{ email: Email; password: Password; auto: boolean }>
    ) {
      if (isLoggedIn(state)) {
        if (action.payload.auto) {
          state.sessionState = 'RENEWING_AUTOMATICALLY';
        } else {
          state.sessionState = 'RENEWING';
        }
      } else {
        state.sessionState = 'AUTHENTICATING';
      }
    },
    renewAuthentication(state, _: PayloadAction<{ email: Email; password: Password }>) {
      state.sessionState = 'AUTHENTICATING';
    },
    authenticated(state, action: PayloadAction<{ auth: Auth; user: User }>) {
      state.sessionState = 'AUTHENTICATED';
      (state as AuthenticatedState).auth = action.payload.auth;
      (state as AuthenticatedState).details = action.payload.user;
    },
    authenticationFailed(state, action: PayloadAction<{ errorMessage: string }>) {
      state.sessionState = 'DENIED';
      (state as AccessDeniedState).auth = { errorMessage: action.payload.errorMessage };
    },
    logout() {
      return initialState;
    },
    changePassword(state, _: PayloadAction<{ newPassword: Password }>) {
      state.sessionState = 'CHANGING_PASSWORD';
    },
    passwordChanged(state) {
      state.sessionState = 'AUTHENTICATED';
    },
    sessionExpired(state) {
      state.sessionState = 'EXPIRED';
    },
    saveCustomPriceLists(state, action: PayloadAction<{ newCustomPriceLists: CustomPriceList[] }>) {
      (state as ChangingCustomPriceListState).details.account.customPriceLists =
        action.payload.newCustomPriceLists;
      state.sessionState = 'CHANGING_CUSTOM_PRICE_LIST';
    },
    customPriceListsSaved(state) {
      state.sessionState = 'AUTHENTICATED';
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(isLoginLocationAction, () => initialState);
  }
});

export const isLoggedIn = (
  userState: UserState
): userState is
  | AuthenticatedState
  | RenewingAuthenticationState
  | ChangingPasswordState
  | ExpiredAuthenticationState =>
  userState.sessionState === 'AUTHENTICATED' ||
  userState.sessionState === 'RENEWING' ||
  userState.sessionState === 'RENEWING_AUTOMATICALLY' ||
  userState.sessionState === 'CHANGING_PASSWORD' ||
  userState.sessionState === 'CHANGING_CUSTOM_PRICE_LIST' ||
  userState.sessionState === 'EXPIRED';

export default userState;

export type SessionExpiredAction = ReturnType<typeof userState.actions.sessionExpired>;
