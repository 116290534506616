import { BuildingGranulometry } from '../../../domain/granulometry/buildings/BuildingGranulometry';
import { CaseGranulometry } from '../../../domain/granulometry/cases/CaseGranulometry';
import { roundWith2Decimal } from '../../../utils/round/roundWith2Decimal';
import { roundWith4Decimal } from '../../../utils/round/roundWith4Decimal';
import { adaptProperties } from './adaptProperties';
import { adaptLevels } from './adaptLevels';
import { getCaseMaxLodgmentSectionsCountInTopLevels } from '../../../domain/granulometry/cases/queries/sections/counts/getCaseMaxLodgmentSectionsCountInTopLevels';
import { hasCaseLodgmentsWithCollectiveGasHeating } from '../../../domain/granulometry/cases/queries/has/hasCaseLodgmentsWithCollectiveGasHeating';
import { hasCaseLodgmentsWithDistrictHeating } from '../../../domain/granulometry/cases/queries/has/hasCaseLodgmentsWithDistrictHeating';
import { hasCaseLodgmentsWithCollectiveHeatPumpOnGroundWaterHeating } from '../../../domain/granulometry/cases/queries/has/hasCaseLodgmentsWithCollectiveHeatPumpOnGroundWaterHeating';
import { hasCaseLodgmentsWithCollectiveWoodHeating } from '../../../domain/granulometry/cases/queries/has/hasCaseLodgmentsWithCollectiveWoodHeating';
import { getCaseGranulometryTopLevelsCount } from '../../../domain/granulometry/cases/queries/levels/counts/getCaseGranulometryTopLevelsCount';
import { getCaseAdjoiningsCount } from '../../../domain/granulometry/cases/queries/get/getCaseAdjoiningsCount';
import { getCaseTopLevelsHeight } from '../../../domain/granulometry/cases/queries/levels/getCaseTopLevelsHeight';

export const adaptCase = (
  buildingGranulometry: BuildingGranulometry,
  caseGranulometry: CaseGranulometry
) => ({
  id: caseGranulometry.id,
  name: caseGranulometry.name,
  title: caseGranulometry.title,
  adjoiningsCount: getCaseAdjoiningsCount(buildingGranulometry, caseGranulometry),
  realBuiltSurface: roundWith2Decimal(
    caseGranulometry.initialSpecifications.projectedRealBuiltSurface.value
  ),
  cuttedBuiltSurfaceRate: roundWith4Decimal(
    caseGranulometry.initialSpecifications.projectedRatioRbsToCbs
  ),
  cuttedBuiltSurface: roundWith2Decimal(
    caseGranulometry.initialSpecifications.projectedCuttedBuiltSurface.value
  ),
  surfaceForSaleRate: roundWith4Decimal(
    caseGranulometry.initialSpecifications.projectedRatioCbsToSfs
  ),
  surfaceForSale: roundWith2Decimal(
    caseGranulometry.initialSpecifications.projectedSurfaceForSale.value
  ),
  surfaceForSaleRealRate: roundWith4Decimal(
    caseGranulometry.initialSpecifications.projectedRatioRbsToSfs
  ),
  topLevelCount: getCaseGranulometryTopLevelsCount(caseGranulometry),
  basementLevelCount: caseGranulometry.initialSpecifications.projectedBasementLevelsCount,
  builtInRamp: caseGranulometry.initialSpecifications.builtInRamp ? 1 : 0,
  hall: caseGranulometry.initialSpecifications.hall ? 1 : 0,
  topLevelsHeight: roundWith2Decimal(getCaseTopLevelsHeight(caseGranulometry)),
  levels: adaptLevels(caseGranulometry),
  maxLodgmentCountByLevel: getCaseMaxLodgmentSectionsCountInTopLevels(caseGranulometry),
  carpentryType: caseGranulometry.initialSpecifications.carpentryType,
  carpentryDayTimeOccultationsType:
    caseGranulometry.initialSpecifications.carpentryDayTimeOccultationsType,
  carpentryDayTimeOccultationsTypeIsMonobloc: caseGranulometry.initialSpecifications
    .carpentryDayTimeOccultationsTypeIsMonobloc
    ? 1
    : 0,
  carpentryNightTimeOccultationsType:
    caseGranulometry.initialSpecifications.carpentryNightTimeOccultationsType,
  carpentryNightTimeOccultationsTypeIsMonobloc: caseGranulometry.initialSpecifications
    .carpentryNightTimeOccultationsTypeIsMonobloc
    ? 1
    : 0,
  carpentryOccultationsMotorisationType:
    caseGranulometry.initialSpecifications.carpentryOccultationsMotorisationType,
  masonryType: caseGranulometry.initialSpecifications.masonryType,
  woodEnvelopeType: caseGranulometry.initialSpecifications.woodEnvelopeType,
  heatingDistribution: caseGranulometry.initialSpecifications.heatingDistribution,
  hasLodgmentsWithCollectiveGasHeating: hasCaseLodgmentsWithCollectiveGasHeating(caseGranulometry)
    ? 1
    : 0,
  hasLodgmentsWithDistrictHeating: hasCaseLodgmentsWithDistrictHeating(caseGranulometry) ? 1 : 0,
  hasLodgmentsWithCollectiveHeatPumpOnGroundWaterHeating:
    hasCaseLodgmentsWithCollectiveHeatPumpOnGroundWaterHeating(caseGranulometry) ? 1 : 0,
  hasLodgmentsWithCollectiveWoodHeating: hasCaseLodgmentsWithCollectiveWoodHeating(caseGranulometry)
    ? 1
    : 0,
  ...adaptProperties(caseGranulometry)
});
