import { matchesPath } from '../../utils/matchesPath';

import { RouteWithMakePath } from '../../RouteWithMakePath';

export type SmartRequestRouteParams = { projectId: string; request: string };
export const smartRequestRoute: RouteWithMakePath<SmartRequestRouteParams> = {
  path: `/project/:projectId/smart-requests/:request`,
  makePath: ({ projectId, request }) => `/project/${projectId}/smart-requests/${request}`,
  matchesPath: matchesPath<SmartRequestRouteParams>(
    /\/project\/([^/]+)\/smart-requests\/([\w ]+)/,
    {
      projectId: 1,
      request: 2
    }
  )
};
