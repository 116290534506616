import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScrollSync } from 'react-scroll-sync';
import { useTranslation } from 'react-i18next';
import { Card } from 'components/ui/Card';
import { SpecialLabel } from 'components/ui/SpecialLabel';
import { HighlightOverlay } from 'components/layouts/HighlightOverlay';
import { selectCurrentCaseIdFromRoute } from 'store/selectors/navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { selectProjectId } from 'store/selectors/project';
import { selectCurrentlyEditedLevelSpecification } from 'store/selectors/navigation/toolbox/casesPanel/currentlyEditedLevelSpecification.selector';
import { selectCurrentlyEditedLodgmentType } from 'store/selectors/specification/currentCase/currentlyEditedLodgmentType.selector';
import EditingStage from './EditingStage';
import { TopAndBasementLevelsCount } from './TopAndBasementLevelsCount';
import HeaderLine from './HeaderLine';
import DistributionLine from './DistributionLine';
import { TopLevelLines } from './LevelLines/TopLevelLines';
import BasementLevelLines from './LevelLines/BasementLevelLines';
import UnitSwitchersLine from './UnitSwitchersLine';
import { TotalTopLevelLines } from './LevelLines/TotalTopLevelLines';
import TotalBasementLevelLines from './LevelLines/TotalBasementLevelLines';
import PositioningCaseCard from './PositioningCaseCard';
import { ButtonGroup } from '../../ui/ButtonGroup';
import { selectIsCaseGranulometryEditFifthFacadeOpened } from '../../../store/selectors/navigation/toolbox/casesPanel/isCaseGranulometryEditFifthFacadeOpened.selector';
import { selectIsCaseGranulometryEditSuperstructureOpened } from '../../../store/selectors/navigation/toolbox/casesPanel/isCaseGranulometryEditSuperstructureOpened.selector';
import { selectIsCaseGranulometryEditEnergyAndNetworksOpened } from '../../../store/selectors/navigation/toolbox/casesPanel/isCaseGranulometryEditEnergyAndNetworksOpened.selector';
import { selectIsCaseGranulometryEditInfrastructureOpened } from '../../../store/selectors/navigation/toolbox/casesPanel/isCaseGranulometryEditInfrastructureOpened.selector';
import { selectIsCaseGranulometryEditGroundAdaptationsOpened } from '../../../store/selectors/navigation/toolbox/casesPanel/isCaseGranulometryEditGroundAdaptationsOpened.selector';
import { selectIsCaseGranulometryEditOutdoorsOpened } from '../../../store/selectors/navigation/toolbox/casesPanel/isCaseGranulometryEditOutdoorsOpened.selector';
import { selectIsCaseGranulometryEditOtherPropertiesOpened } from '../../../store/selectors/navigation/toolbox/casesPanel/isCaseGranulometryEditOtherPropertiesOpened.selector';
import { selectCurrentCaseLabel } from '../../../store/selectors/project/currentCaseLabel.selector';
import { TopLevelSurfaceName } from '../../../domain/granulometry/cases/queries/surfaces/getCaseTopLevelsSurfaces';
import { selectIsCaseGranulometryEditGeometryOpened } from '../../../store/selectors/navigation/toolbox/casesPanel/isCaseGranulometryEditGeometryOpened.selector';
import { SurfacesCalculation } from './SurfacesCalculation/SurfacesCalculation';
import { selectIsCaseGranulometryEditLevelGeometryOpened } from '../../../store/selectors/navigation/toolbox/casesPanel/isCaseGranulometryEditLevelGeometryOpened.selector';
import { selectIsCaseGranulometryEditLevelDetailsOpened } from '../../../store/selectors/navigation/toolbox/casesPanel/isCaseGranulometryEditLevelDetailsOpened.selector';
import { getCaseGranulometryEditOutdoorsTriggerId } from '../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditOutdoorsTriggerId';
import { getCaseGranulometryEditOtherPropertiesTriggerId } from '../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditOtherPropertiesTriggerId';
import { getCaseGranulometryEditGroundAdaptationsTriggerId } from '../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditGroundAdaptationsTriggerId';
import { getCaseGranulometryEditInfrastructureTriggerId } from '../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditInfrastructureTriggerId';
import { getCaseGranulometryEditEnergyAndNetworksTriggerId } from '../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditEnergyAndNetworksTriggerId';
import { getCaseGranulometryEditSuperstructureTriggerId } from '../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditSuperstructureTriggerId';
import { getCaseGranulometryEditFifthFacadeTriggerId } from '../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditFifthFacadeTriggerId';
import { getCaseGranulometryEditLevelGeometryTriggerId } from '../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditLevelGeometryTriggerId';
import { getCaseGranulometryEditLevelDetailsTriggerId } from '../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditLevelDetailsTriggerId';
import { getCaseGranulometryEditGeometryTriggerId } from '../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditGeometryTriggerId';
import { getCaseGranulometryEditLodgmentTypeTriggerId } from '../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditLodgmentTypeTriggerId';
import { goToCase } from '../../../store/actions/navigations/toolbox/casesPanel/goToCase.action';
import { goToCaseGranulometryEditFifthFacade } from '../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditFifthFacade.action';
import { goToCaseGranulometryEditSuperstructureAction } from '../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditSuperstructure.action';
import { goToCaseGranulometryEditEnergyAndNetworks } from '../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditEnergyAndNetworks.action';
import { goToCaseGranulometryEditInfrastructure } from '../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditInfrastructure.action';
import { goToCaseGranulometryEditGroundAdaptations } from '../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditGroundAdaptations.action';
import { goToCaseGranulometryEditOutdoors } from '../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditOutdoors.action';
import { goToCaseGranulometryEditOtherProperties } from '../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditOtherProperties.action';

export const CELL_WIDTH = 34;

interface CardToScrollProps {
  isToolboxEnlarged: boolean;
  setIsToolboxEnlarged: React.Dispatch<React.SetStateAction<boolean>>;
  selectedLevelSurface: TopLevelSurfaceName;
  setSelectedLevelSurface: React.Dispatch<React.SetStateAction<TopLevelSurfaceName>>;
}

export const CardToScroll = ({
  isToolboxEnlarged,
  setIsToolboxEnlarged,
  selectedLevelSurface,
  setSelectedLevelSurface
}: CardToScrollProps) => {
  const { t } = useTranslation();

  const projectId = useSelector(selectProjectId);
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const currentlyEditedLodgmentType = useSelector(selectCurrentlyEditedLodgmentType);
  const currentlyEditedLevel = useSelector(selectCurrentlyEditedLevelSpecification);
  const isCaseGranulometryEditGeometryOpened = useSelector(
    selectIsCaseGranulometryEditGeometryOpened
  );
  const isCaseGranulometryEditLevelDetailsOpened = useSelector(
    selectIsCaseGranulometryEditLevelDetailsOpened
  );
  const isCaseGranulometryEditLevelGeometryOpened = useSelector(
    selectIsCaseGranulometryEditLevelGeometryOpened
  );
  const isCaseGranulometryEditFifthFacadeOpened = useSelector(
    selectIsCaseGranulometryEditFifthFacadeOpened
  );
  const isCaseGranulometryEditSuperstructureOpened = useSelector(
    selectIsCaseGranulometryEditSuperstructureOpened
  );
  const isCaseGranulometryEditEnergyAndNetworksOpened = useSelector(
    selectIsCaseGranulometryEditEnergyAndNetworksOpened
  );
  const isCaseGranulometryEditInfrastructureOpened = useSelector(
    selectIsCaseGranulometryEditInfrastructureOpened
  );
  const isCaseGranulometryEditGroundAdaptationsOpened = useSelector(
    selectIsCaseGranulometryEditGroundAdaptationsOpened
  );
  const isCaseGranulometryEditOutdoorsOpened = useSelector(
    selectIsCaseGranulometryEditOutdoorsOpened
  );
  const isCaseGranulometryEditOtherPropertiesOpened = useSelector(
    selectIsCaseGranulometryEditOtherPropertiesOpened
  );

  const dispatch = useDispatch();

  const handleGoToCase = React.useCallback(() => {
    if (projectId && caseId) dispatch(goToCase(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  if (!caseLabel) return null;

  const isHighlighted =
    Boolean(currentlyEditedLodgmentType) ||
    isCaseGranulometryEditGeometryOpened ||
    (currentlyEditedLevel && isCaseGranulometryEditLevelGeometryOpened) ||
    (currentlyEditedLevel && isCaseGranulometryEditLevelDetailsOpened) ||
    isCaseGranulometryEditFifthFacadeOpened ||
    isCaseGranulometryEditSuperstructureOpened ||
    isCaseGranulometryEditEnergyAndNetworksOpened ||
    isCaseGranulometryEditInfrastructureOpened ||
    isCaseGranulometryEditGroundAdaptationsOpened ||
    isCaseGranulometryEditOutdoorsOpened ||
    isCaseGranulometryEditOtherPropertiesOpened;

  const triggerId = isHighlighted
    ? currentlyEditedLodgmentType
      ? getCaseGranulometryEditLodgmentTypeTriggerId(
          caseLabel,
          currentlyEditedLodgmentType.lodgmentType
        )
      : isCaseGranulometryEditGeometryOpened
      ? getCaseGranulometryEditGeometryTriggerId(caseLabel)
      : currentlyEditedLevel && isCaseGranulometryEditLevelGeometryOpened
      ? getCaseGranulometryEditLevelGeometryTriggerId(currentlyEditedLevel.id)
      : currentlyEditedLevel && isCaseGranulometryEditLevelDetailsOpened
      ? getCaseGranulometryEditLevelDetailsTriggerId(currentlyEditedLevel.id)
      : isCaseGranulometryEditFifthFacadeOpened
      ? getCaseGranulometryEditFifthFacadeTriggerId(caseLabel)
      : isCaseGranulometryEditSuperstructureOpened
      ? getCaseGranulometryEditSuperstructureTriggerId(caseLabel)
      : isCaseGranulometryEditEnergyAndNetworksOpened
      ? getCaseGranulometryEditEnergyAndNetworksTriggerId(caseLabel)
      : isCaseGranulometryEditInfrastructureOpened
      ? getCaseGranulometryEditInfrastructureTriggerId(caseLabel)
      : isCaseGranulometryEditGroundAdaptationsOpened
      ? getCaseGranulometryEditGroundAdaptationsTriggerId(caseLabel)
      : isCaseGranulometryEditOutdoorsOpened
      ? getCaseGranulometryEditOutdoorsTriggerId(caseLabel)
      : isCaseGranulometryEditOtherPropertiesOpened
      ? getCaseGranulometryEditOtherPropertiesTriggerId(caseLabel)
      : undefined
    : undefined;

  const selectedKey = isCaseGranulometryEditFifthFacadeOpened
    ? 0
    : isCaseGranulometryEditSuperstructureOpened
    ? 1
    : isCaseGranulometryEditInfrastructureOpened
    ? 2
    : isCaseGranulometryEditGroundAdaptationsOpened
    ? 3
    : isCaseGranulometryEditEnergyAndNetworksOpened
    ? 4
    : isCaseGranulometryEditOutdoorsOpened
    ? 5
    : isCaseGranulometryEditOtherPropertiesOpened
    ? 6
    : -1;

  const optionAndParamsButtons = [
    {
      id: getCaseGranulometryEditFifthFacadeTriggerId(caseLabel),
      content: t('Fifth facade'),
      details: t('Roofing'),
      handleClick: () => {
        projectId && caseId && dispatch(goToCaseGranulometryEditFifthFacade(projectId, caseId));
      }
    },
    {
      id: getCaseGranulometryEditSuperstructureTriggerId(caseLabel),
      content: t('Superstructure'),
      details: `${t('Stairs')}, ${t('Elevator')}, ${t('Hall')},...`,
      handleClick: () => {
        projectId &&
          caseId &&
          dispatch(goToCaseGranulometryEditSuperstructureAction(projectId, caseId));
      }
    },
    {
      id: getCaseGranulometryEditInfrastructureTriggerId(caseLabel),
      content: t('Infrastructure'),
      details: t('Basements'),
      handleClick: () => {
        projectId && caseId && dispatch(goToCaseGranulometryEditInfrastructure(projectId, caseId));
      }
    },
    {
      id: getCaseGranulometryEditGroundAdaptationsTriggerId(caseLabel),
      content: t('Ground adaptations'),
      details: t('Floors and foundations'),
      handleClick: () => {
        projectId &&
          caseId &&
          dispatch(goToCaseGranulometryEditGroundAdaptations(projectId, caseId));
      }
    },
    {
      id: getCaseGranulometryEditEnergyAndNetworksTriggerId(caseLabel),
      content: t('Energy and networks'),
      details: `${t('Heating')}, ${t('Technical sheaths')},...`,
      handleClick: () => {
        projectId &&
          caseId &&
          dispatch(goToCaseGranulometryEditEnergyAndNetworks(projectId, caseId));
      }
    },
    {
      id: getCaseGranulometryEditOutdoorsTriggerId(caseLabel),
      content: t('Outdoors'),
      handleClick: () => {
        projectId && caseId && dispatch(goToCaseGranulometryEditOutdoors(projectId, caseId));
      }
    },
    {
      id: getCaseGranulometryEditOtherPropertiesTriggerId(caseLabel),
      content: t('Other properties'),
      handleClick: () => {
        projectId && caseId && dispatch(goToCaseGranulometryEditOtherProperties(projectId, caseId));
      }
    }
  ];

  return (
    <HighlightOverlay highlighted={isHighlighted} triggerId={triggerId} onReturn={handleGoToCase}>
      <PositioningCaseCard />
      <EditingStage />
      <Card>
        <SpecialLabel label={t('Surfaces calculation')} />
        <SurfacesCalculation isToolboxEnlarged={isToolboxEnlarged} />
      </Card>
      <Card>
        <SpecialLabel label={t('Levels and basements number')} />
        <TopAndBasementLevelsCount />
      </Card>
      <Card>
        <div className="level-distribution">
          <SpecialLabel label={t('Level distribution')} />
          <div style={{ position: 'relative' }}>
            <ScrollSync>
              <>
                <HeaderLine
                  isToolboxEnlarged={isToolboxEnlarged}
                  setIsToolboxEnlarged={setIsToolboxEnlarged}
                  selectedLevelSurface={selectedLevelSurface}
                  setSelectedLevelSurface={setSelectedLevelSurface}
                />
                <DistributionLine />
                <TopLevelLines
                  isToolboxEnlarged={isToolboxEnlarged}
                  selectedLevelSurface={selectedLevelSurface}
                />
                <TotalTopLevelLines
                  isToolboxEnlarged={isToolboxEnlarged}
                  selectedLevelSurface={selectedLevelSurface}
                />
              </>
            </ScrollSync>
            <BasementLevelLines
              isToolboxEnlarged={isToolboxEnlarged}
              selectedLevelSurface={selectedLevelSurface}
            />
            <TotalBasementLevelLines />
            <div className="column-separator column-separator-1" />
            <div className="column-separator column-separator-2" />
            <div className="column-separator column-separator-3" />
            {isToolboxEnlarged && (
              <>
                {process.env.GB_FEAT_GFS === 'true' && (
                  <div className="column-separator column-separator-4" />
                )}
                <div className="column-separator column-separator-5" />
                <div className="column-separator column-separator-6" />
              </>
            )}
          </div>
          <UnitSwitchersLine />
        </div>
      </Card>
      <Card className="to-scroll">
        <SpecialLabel label={t('Options and settings')} />
        <ButtonGroup
          buttons={optionAndParamsButtons}
          forcedSelectedKey={selectedKey}
          vertical={true}
        />
      </Card>
    </HighlightOverlay>
  );
};
