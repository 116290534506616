import { CaseProjection } from '../../../CaseProjection';
import { getCaseTopLevelsAutoCountFromRealBuiltSurface } from './getCaseTopLevelsAutoCountFromRealBuiltSurface';
import { isTopLevel } from '../../../../../specification/levels/queries/is/isTopLevel';
import { getCaseGroundLevel } from '../getCaseGroundLevel';
import { hasDrawnGeometry } from '../../../../../specification/levels/queries/has/hasDrawnGeometry';
import { isProjectInputModeGfsEff } from '../../../../../specification/project/queries/is/isProjectInputModeGfsEff';

export const setCaseProjectedTopLevelsCount = (caseProjection: CaseProjection): CaseProjection => {
  let projectedTopLevelsCount: number;
  if (isProjectInputModeGfsEff(caseProjection)) {
    projectedTopLevelsCount = caseProjection.levels.reduce(
      (acc, levelSpecification) => (isTopLevel(levelSpecification) ? acc + 1 : acc),
      0
    );
  } else if (caseProjection.topLevelsCount !== undefined) {
    projectedTopLevelsCount = caseProjection.topLevelsCount;
  } else {
    const topLevelsCountFromRbs = getCaseTopLevelsAutoCountFromRealBuiltSurface(
      caseProjection.projectedRealBuiltSurface.value
    );
    projectedTopLevelsCount =
      hasDrawnGeometry(getCaseGroundLevel(caseProjection)) &&
      caseProjection.levels.length > topLevelsCountFromRbs
        ? caseProjection.levels.length
        : topLevelsCountFromRbs;
  }
  return {
    ...caseProjection,
    projectedTopLevelsCount
  };
};
