import * as R from 'ramda';
import { TopLevelSpecification } from '../../../../levels/TopLevelSpecification';

export const getTopLevelsSpecificationsTotalForcedRealBuiltSurface = (
  levels: TopLevelSpecification[]
): number =>
  R.compose(
    R.reduce(R.add, 0),
    R.map<TopLevelSpecification, number>(R.propOr(0, 'realBuiltSurface'))
  )(levels);
