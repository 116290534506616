import { createSelector, Selector } from 'reselect';
import * as R from 'ramda';
import { ifDefined } from '../../../utils/ifDefined';
import { selectWoodCubatureByBuildingAndCase } from './woodCubatureByBuildingAndCase.selector';
import {
  WoodCubatureByBuildingAndCase,
  WoodCubatureByCase
} from '../../../domain/report/queries/getWoodCubatureByBuildingAndCase';
import { State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';

type WoodCubatureByCaseType = Maybe<Record<string, number>>;

export const selectWoodCubatureByCase: Selector<State, WoodCubatureByCaseType> = createSelector(
  [selectWoodCubatureByBuildingAndCase],
  ifDefined(
    R.pipe<[WoodCubatureByBuildingAndCase], WoodCubatureByCase[], WoodCubatureByCase>(
      R.values,
      R.reduce<WoodCubatureByCase, WoodCubatureByCase>(R.mergeRight, {})
    )
  )
);
