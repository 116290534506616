import { CaseGranulometry } from '../../cases/CaseGranulometry';
import {
  getCaseRealTechnicalSheathsCountByLevel,
  SectionsWithSheathsCountByLevel
} from '../../cases/queries/technicalSheaths/getCaseRealTechnicalSheathsCountByLevel';
import { getLevelDistributionFromDistribution } from './getLevelDistributionFromDistribution';
import { getLodgmentCountFromDistribution } from './getLodgmentCountFromDistribution';
import { getCaseLevels } from '../../cases/queries/levels/getCaseLevels';
import { getLevelTechnicalPermiseSections } from '../../levels/queries/sections/getLevelTechnicalPermiseSections';
import { getLevelCommonPremiseSections } from '../../levels/queries/sections/getLevelCommonPremiseSections';
import { getLevelShopSections } from '../../levels/queries/sections/getLevelShopSections';
import { getLevelOfficeSections } from '../../levels/queries/sections/getLevelOfficeSections';

export const updateCaseGranulometryWithCustomDistribution = (
  caseGranulometry: CaseGranulometry
): CaseGranulometry => ({
  ...caseGranulometry,
  technicalSheathCountByLevel: getCaseRealTechnicalSheathsCountByLevel(
    getCaseLevels(caseGranulometry).reduce(
      (acc, levelGranulometry) => [
        ...acc,
        {
          level: levelGranulometry.level,
          lodgmentsCount: getLodgmentCountFromDistribution(
            getLevelDistributionFromDistribution(caseGranulometry, levelGranulometry)
          ),
          technicalPremisesCount: getLevelTechnicalPermiseSections(levelGranulometry).length,
          commonPremisesCount: getLevelCommonPremiseSections(levelGranulometry).length,
          shopsCount: getLevelShopSections(levelGranulometry).length,
          officesCount: getLevelOfficeSections(levelGranulometry).length
        }
      ],
      [] as SectionsWithSheathsCountByLevel[]
    )
  )
});
