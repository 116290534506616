import { CustomPriceList } from '../../../../../../domain/Account';
import { Report } from '../../../../../../domain/report/ReportEntry';

export const getReportWithRecalculatedPrices = (
  report: Report,
  customPriceList: CustomPriceList,
  updateUnitPriceColumn?: boolean
): Report =>
  report.map((reportEntry) => {
    const customPriceResult = customPriceList.customPrices.find(
      (customPrice) => reportEntry.productId === customPrice.productId
    );
    return updateUnitPriceColumn
      ? {
          ...reportEntry,
          price: customPriceResult
            ? reportEntry.quantity * customPriceResult.price
            : reportEntry.price,
          unitPrice: customPriceResult ? customPriceResult.price : reportEntry.unitPrice
        }
      : customPriceResult
      ? { ...reportEntry, price: reportEntry.quantity * customPriceResult.price }
      : reportEntry;
  });
