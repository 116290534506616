import * as R from 'ramda';
import { getLevelSections } from '../../sections/getLevelSections';
import {
  filterLodgments,
  getLodgmentType,
  LodgmentSection
} from '../../../../sections/lodgmentSections/LodgmentSection';
import { Section } from '../../../../sections/Section';
import { LevelGranulometry } from '../../../LevelGranulometry';
import { LodgmentType } from '../../../../../specification/lodgmentTypes/LodgmentType';

export type LodgmentsCountByType = { [key in LodgmentType]: number };
type ReduceByLodgmentType = (lodgment: LodgmentSection[]) => LodgmentsCountByType;

type GetTopLevelLodgmentsCountByType = (level: LevelGranulometry) => LodgmentsCountByType;
export const getLevelLodgmentsCountByType: GetTopLevelLodgmentsCountByType = R.pipe<
  [LevelGranulometry],
  Section[],
  LodgmentSection[],
  LodgmentsCountByType
>(
  getLevelSections,
  filterLodgments,
  R.reduceBy(R.inc, 0, getLodgmentType) as unknown as ReduceByLodgmentType
);
