import { getSectionWallsLinear } from '../../getSectionWallsLinear';
import { SectionWithExposureRate } from '../../../../SectionWithExposureRate';
import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../../../levels/LevelGranulometry';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { getSectionTheoreticalInsideWallsLinear } from './getSectionTheoreticalInsideWallsLinear';
import { getSectionTheoreticalOutsideWallsLinear } from '../outsideWalls/getSectionTheoreticalOutsideWallsLinear';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getSectionTheoreticalInsideWallsLinearDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithBearingRelativeSections | LevelGranulometry,
  section: SectionWithExposureRate
): string => {
  let details: string[] = [];
  details = [
    ...details,
    '<b>' +
      roundWith2Decimal(getSectionWallsLinear(caseGranulometry, levelGranulometry, section)) +
      ' m</b> de linéaire de voiles exposés et non-exposés'
  ];
  details = [
    ...details,
    '<b>- ' +
      roundWith2Decimal(
        getSectionTheoreticalOutsideWallsLinear(caseGranulometry, levelGranulometry, section)
      ) +
      ' m</b> de linéaire théorique de voiles exposés '
  ];
  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(
        getSectionTheoreticalInsideWallsLinear(caseGranulometry, levelGranulometry, section)
      ) +
      ' mL</b>'
  ];
  return details.join('<br />');
};
