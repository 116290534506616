import * as React from 'react';
import { ReportEntry, reportEntryLabels } from '../../../../../../domain/report/ReportEntry';
import { formatThousands } from '../../../../../../utils/formatThousands';
import { Column } from 'react-table';
import { fitterRowsAndPivotsThroughInput } from '../filters/fitterRowsAndPivotsThroughInput';
import * as R from 'ramda';
import { getTotalPrice } from '../totals/getTotalPrice';
import classNames from 'classnames';
import { roundWithNoDecimal } from '../../../../../../utils/round/roundWithNoDecimal';
import { CustomPriceList } from '../../../../../../domain/Account';
import { CustomPriceInput } from '../CustomPriceInput';
import { filterRowsAndPivotsThroughCustomPriceListSelect } from '../filters/filterRowsAndPivotsThroughCustomPriceListSelect';
import { CustomPricesOnlySelect } from '../CustomPricesOnlySelect';
import { CustomPriceListsSelect } from '../CustomPriceListsSelect';
import { Project } from '../../../../../../domain/project/Project';

export const getCostColumns = (
  report: ReportEntry[],
  subRowsIndex: number[],
  foldedColumns: string[],
  currentCustomPriceList: CustomPriceList | undefined,
  handleCustomPriceChange: (productId: string, price: number | null) => void,
  handleCustomPriceListIdChange: (newCustomPriceListId: Project['customPriceListId']) => void,
  customPriceLists: CustomPriceList[]
): Column => {
  return {
    Header: 'Coût',
    columns: [
      {
        Header: reportEntryLabels.price,
        accessor: 'price',
        filterMethod: fitterRowsAndPivotsThroughInput(subRowsIndex),
        aggregate: (values) => formatThousands(roundWithNoDecimal(R.sum(values))),
        Aggregated: (cellInfo) => (
          <b>
            {cellInfo.value !== undefined
              ? formatThousands(roundWithNoDecimal(getTotalPrice(cellInfo)))
              : '?'}{' '}
            €
          </b>
        ),
        Cell: (cellInfo) =>
          cellInfo.value !== undefined ? formatThousands(roundWithNoDecimal(cellInfo.value)) : '?',
        sortable: false,
        resizable: false,
        width: 70,
        className: classNames('right', {
          folded: foldedColumns.indexOf('Prix') > -1
        }),
        headerClassName: classNames('right', {
          folded: foldedColumns.indexOf('Prix') > -1
        })
      },
      {
        Header: reportEntryLabels.unitPrice,
        accessor: 'unitPrice',
        filterMethod: fitterRowsAndPivotsThroughInput(subRowsIndex),
        aggregate: (values) => R.uniq(values),
        Aggregated: (cellInfo) =>
          cellInfo.groupedByPivot && cellInfo.level < 4 ? (
            ''
          ) : currentCustomPriceList?.customPrices.find(
              (customPrice) => customPrice.productId === cellInfo.row.productId[0]
            )?.price !== undefined ? (
            <s>{cellInfo.value + ' €'}</s>
          ) : (
            cellInfo.value + ' €'
          ),
        Cell: (cellInfo) =>
          currentCustomPriceList?.customPrices.find(
            (customPrice) => customPrice.productId === cellInfo.row.productId
          )?.price !== undefined ? (
            <s>{cellInfo.row.unitPrice + ' €'}</s>
          ) : (
            cellInfo.row.unitPrice + ' €'
          ),
        sortable: false,
        resizable: false,
        width: 70,
        className: classNames('separator', 'right', {
          folded: foldedColumns.indexOf('Prix unitaire') > -1
        }),
        headerClassName: classNames('separator', 'right', {
          folded: foldedColumns.indexOf('Prix unitaire') > -1
        })
      },
      {
        Header: reportEntryLabels.customPrice,
        accessor: 'productId',
        filterMethod: filterRowsAndPivotsThroughCustomPriceListSelect(
          currentCustomPriceList?.customPrices || []
        ),
        Filter: ({ filter, onChange }) => {
          return (
            <div className="selectGroup">
              <CustomPricesOnlySelect
                selectedOption={
                  currentCustomPriceList === undefined || filter === undefined
                    ? 'allPrices'
                    : filter.value
                }
                handleCustomPricesFilterChange={onChange}
                disabled={currentCustomPriceList === undefined}
              />
              <CustomPriceListsSelect
                selectedId={currentCustomPriceList?.id || 'none'}
                handleCustomPriceListIdChange={handleCustomPriceListIdChange}
                customPriceLists={customPriceLists}
              />
            </div>
          );
        },
        // filterMethod: fitterRowsAndPivotsThroughInput(subRowsIndex),
        aggregate: (values) => R.uniq(values),
        Aggregated: (cellInfo) =>
          cellInfo.groupedByPivot && cellInfo.level < 4 ? (
            ''
          ) : (
            <CustomPriceInput
              cellInfo={cellInfo}
              price={
                currentCustomPriceList?.customPrices.find(
                  (customPrice) => customPrice.productId === cellInfo.row.productId[0]
                )?.price
              }
              handleCustomPriceChange={handleCustomPriceChange}
              disabled={currentCustomPriceList === undefined}
            />
          ),
        Cell: (cellInfo) => {
          const customPrice = currentCustomPriceList?.customPrices.find(
            (customPrice) => customPrice.productId === cellInfo.row.productId
          )?.price;
          return (customPrice !== undefined ? customPrice : cellInfo.row.unitPrice) + ' €';
        },
        sortable: false,
        resizable: false,
        width: 90,
        className: classNames('separator', 'right', 'lightBlue', {
          folded: foldedColumns.indexOf(reportEntryLabels.customPrice) > -1
        }),
        headerClassName: classNames('separator', 'right', 'lightBlue', {
          folded: foldedColumns.indexOf(reportEntryLabels.customPrice) > -1
        })
      }
    ]
  };
};
