import { DRAW_COLORS } from './colors';
import { notMovingLayer } from './polygon.style';
import mapboxgl from 'mapbox-gl';

const vertexFilter = [
  'all',
  ['==', 'meta', 'vertex'],
  ['==', '$type', 'Point'],
  ['!=', 'mode', 'static']
];
const midPointFilter = [
  'all',
  ['==', 'meta', 'midpoint'],
  ['==', '$type', 'Point'],
  ['!=', 'mode', 'static']
];

export const removeAngleCircles = (featureId: string, setFilter: mapboxgl.Map['setFilter']) => {
  const filter = ['!=', 'parent', featureId];
  setFilter('gl-draw-polygon-and-line-vertex-stroke-inactive.cold', [...vertexFilter, filter]);
  setFilter('gl-draw-polygon-and-line-vertex-stroke-inactive.hot', [...vertexFilter, filter]);
  setFilter('gl-draw-polygon-and-line-vertex-inactive.cold', [...vertexFilter, filter]);
  setFilter('gl-draw-polygon-and-line-vertex-inactive.hot', [...vertexFilter, filter]);
  setFilter('gl-draw-polygon-rotate-point-stroke.cold', [...midPointFilter, filter]);
  setFilter('gl-draw-polygon-rotate-point-stroke.hot', [...midPointFilter, filter]);
  setFilter('gl-draw-polygon-rotate-point.cold', [...midPointFilter, filter]);
  setFilter('gl-draw-polygon-rotate-point.hot', [...midPointFilter, filter]);
};
export const angleCircles = [
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: vertexFilter,
    paint: {
      'circle-radius': 10,
      'circle-color': '#fff'
    }
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: vertexFilter,
    paint: {
      'circle-radius': 8,
      'circle-color': DRAW_COLORS.POLYGON_ACTIVE_COLOR.fill
    }
  },
  {
    id: 'gl-draw-polygon-rotate-point-stroke',
    type: 'circle',
    filter: midPointFilter,
    paint: {
      'circle-radius': 9,
      'circle-color': '#fff'
    }
  },
  {
    id: 'gl-draw-polygon-rotate-point',
    type: 'circle',
    filter: midPointFilter,
    paint: {
      'circle-radius': 7,
      'circle-color': '#4d4d4d'
    }
  }
];

export const circles = [
  ...angleCircles,
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static']
    ],
    paint: {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': '#fff'
    }
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static']
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': '#3bb2d0'
    }
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: [
      'all',
      notMovingLayer,
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint']
    ],
    paint: {
      'circle-radius': 16,
      'circle-color': '#ffffff'
    }
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: [
      'all',
      notMovingLayer,
      ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true']
    ],
    paint: {
      'circle-radius': 14,
      'circle-color': '#75c900',
      'circle-opacity': 0.3
    }
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', notMovingLayer, ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#404040'
    }
  }
];
