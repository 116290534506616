import { LevelGranulometry } from '../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';

export const getBasementLevelRealBuiltSurfaceIsLargeEnoughValidation = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): boolean => {
  const specifiedRealBuiltSurface =
    caseGranulometry.initialSpecifications.projectedBasementLevels.find(
      (l) => l.level === levelGranulometry.level
    )?.realBuiltSurface;
  return (
    levelGranulometry.basementSurfaceBeforeSpecifiedRBS === undefined ||
    specifiedRealBuiltSurface === undefined ||
    specifiedRealBuiltSurface >= levelGranulometry.basementSurfaceBeforeSpecifiedRBS.value
  );
};
