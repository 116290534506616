import { Reducer } from 'redux';
import { Project } from '../../domain/project/Project';
import { PROJECTS_FETCHED, ProjectsFetchedAction } from '../actions/projectsFetched.action';

export const projectsReducer: Reducer<Project[]> = (state = [], action) => {
  if (action.type === PROJECTS_FETCHED) {
    return (action as ProjectsFetchedAction).payload.projects;
  }

  return state;
};
