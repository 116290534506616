import { SectionWithExposureRate } from '../../../SectionWithExposureRate';
import { LevelGranulometry } from '../../../../levels/LevelGranulometry';
import { getSectionInsideWallsThickness } from '../wallsThickness/getSectionInsideWallsThickness';
import { getSectionRealInsideWallsLinear } from './getSectionRealInsideWallsLinear';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';

export const getSectionRealInsideWallsSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry,
  section: SectionWithExposureRate
): number => {
  const realInsideWallsLinear = getSectionRealInsideWallsLinear(
    caseGranulometry,
    levelGranulometry,
    section
  );
  const insideWallsThickness = getSectionInsideWallsThickness(caseGranulometry);
  return realInsideWallsLinear * (insideWallsThickness / 2);
};
