import { createSelector, Selector } from 'reselect';
import { selectCurrentLevelParkingsCountByBasementLevelAndType } from './currentLevelParkingsCountByBasementLevelAndType.selector';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';

export const selectCurrentLevelCarParkInfraCount: Selector<State, Maybe<number>> = createSelector(
  [selectCurrentLevelParkingsCountByBasementLevelAndType],
  (currentLevelParkingsCountByBasementLevelAndType) =>
    currentLevelParkingsCountByBasementLevelAndType?.carParkInfra
);
