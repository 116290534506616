import { LodgmentTypesDistribution } from '../../domain/specification/lodgmentTypes/LodgmentTypesDistribution';

export const SET_CASE_LODGMENT_TYPES_DISTRIBUTION = 'SET_CASE_LODGMENT_TYPES_DISTRIBUTION';

export const setCaseLodgmentsTypesDistribution = (
  caseId: string,
  distribution: LodgmentTypesDistribution
) => ({
  type: SET_CASE_LODGMENT_TYPES_DISTRIBUTION,
  payload: { caseId, distribution }
});

export type SetCaseLodgmentsTypesDistributionAction = Readonly<
  ReturnType<typeof setCaseLodgmentsTypesDistribution>
>;
