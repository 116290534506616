import { getSectionTheoreticalOutsideWallsLinear } from './getSectionTheoreticalOutsideWallsLinear';
import { getSectionOutsideWallsThickness } from '../../wallsThickness/getSectionOutsideWallsThickness';
import {
  LevelGranulometry,
  LevelGranulometryWithLodgments
} from '../../../../../levels/LevelGranulometry';
import { getSectionTheoreticalOutsideWallsSurface } from './getSectionTheoreticalOutsideWallsSurface';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { SectionWithExposureRate } from '../../../../SectionWithExposureRate';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getSectionTheoreticalOutsideWallsSurfaceDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithLodgments | LevelGranulometry,
  lodgmentSection: SectionWithExposureRate
): string => {
  let details: string[] = [];
  details = [
    ...details,
    '<b>' +
      roundWith2Decimal(
        getSectionTheoreticalOutsideWallsLinear(
          caseGranulometry,
          levelGranulometry,
          lodgmentSection
        )
      ) +
      ' mL</b> de linéaire théorique de voiles exposés'
  ];
  details = [
    ...details,
    '<b>* ' +
      roundWith2Decimal(getSectionOutsideWallsThickness(caseGranulometry)) +
      ' m</b> d’épaisseur de voiles exposés'
  ];
  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(
        getSectionTheoreticalOutsideWallsSurface(
          caseGranulometry,
          levelGranulometry,
          lodgmentSection
        )
      ) +
      ' m\u00B2</b>'
  ];
  return details.join('<br />');
};
