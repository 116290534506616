import * as R from 'ramda';
import { LevelGranulometry } from '../../../LevelGranulometry';
import { Surface } from '../../../../../specification/Surface';
import { getLevelSections } from '../../sections/getLevelSections';
import {
  filterParkings,
  ParkingSection
} from '../../../../sections/parkingSections/ParkingSection';
import { Section } from '../../../../sections/Section';

type GetBasementLevelParkingsSurface = (level: LevelGranulometry) => Surface;

export const getLevelParkingsSurface: GetBasementLevelParkingsSurface = R.pipe<
  [LevelGranulometry],
  Section[],
  ParkingSection[],
  number,
  Surface
>(
  getLevelSections,
  filterParkings,
  R.reduce((acc, parking) => acc + parking.displayedSurface, 0),
  Surface.fromNumber
);
