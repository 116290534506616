import { cleanFileName } from '../utils/cleanFileName';
import { ComEthJson } from '../../../../../api/cstb/comEth/makeComEthJson';

export const downloadComEth = async (comEthJson: ComEthJson, projectName: string) => {
  const fileName =
    'gobuild-' +
    cleanFileName(projectName) +
    '-cometh-' +
    new Date().toISOString().slice(0, 10) +
    '.json';
  const json = JSON.stringify(comEthJson);
  const blob = new Blob([json], { type: 'application/json' });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
