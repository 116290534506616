import { connect } from 'react-redux';
import { State } from '../../../../store/reducers';
import { selectProjectId } from '../../../../store/selectors/project';
import { selectFirstBuildingId } from '../../../../store/selectors/specification/firstBuildingId.selector';
import { selectFirstCaseId } from '../../../../store/selectors/specification/firstCaseId.selector';
import { goToProject } from '../../../../store/actions/navigations/toolbox/projectPanel/goToProject.action';
import { goToProjectDetails } from '../../../../store/actions/navigations/toolbox/projectPanel/goToProjectDetails.action';
import { goToBuilding } from '../../../../store/actions/navigations/toolbox/buildingsPanel/goToBuilding.action';
import { goToCase } from '../../../../store/actions/navigations/toolbox/casesPanel/goToCase.action';
import { goToSections } from '../../../../store/actions/navigations/toolbox/sectionsPanel/goToSections.action';
import { goToSmartRequests } from '../../../../store/actions/navigations/toolbox/smartRequestsPanel/goToSmartRequests.action';
import { goToDeliverables } from '../../../../store/actions/navigations/toolbox/deliverablesPanel/goToDeliverables.action';

const mapStateToProps = (state: State) => ({
  currentProjectId: selectProjectId(state),
  firstBuildingId: selectFirstBuildingId(state),
  firstCaseId: selectFirstCaseId(state)
});

const mapDispatchToProps = {
  goToProject,
  goToProjectDetails,
  goToBuilding,
  goToCase,
  goToSections,
  goToSmartRequests,
  goToDeliverables
};

const mergeProps = (
  stateProps: ReturnType<typeof mapStateToProps>,
  dispatchProps: typeof mapDispatchToProps,
  ownProps: object
) => ({
  ...ownProps,
  goToProject: () =>
    stateProps.currentProjectId && dispatchProps.goToProject(stateProps.currentProjectId),
  goToProjectDetails: () =>
    stateProps.currentProjectId && dispatchProps.goToProjectDetails(stateProps.currentProjectId),
  goToFirstBuilding: () =>
    stateProps.currentProjectId &&
    stateProps.firstBuildingId &&
    dispatchProps.goToBuilding(stateProps.currentProjectId, stateProps.firstBuildingId),
  goToFirstCase: () =>
    stateProps.currentProjectId &&
    stateProps.firstCaseId &&
    dispatchProps.goToCase(stateProps.currentProjectId, stateProps.firstCaseId),
  goToSections: () =>
    stateProps.currentProjectId && dispatchProps.goToSections(stateProps.currentProjectId),
  goToSmartRequests: () =>
    stateProps.currentProjectId && dispatchProps.goToSmartRequests(stateProps.currentProjectId),
  goToDeliverables: () =>
    stateProps.currentProjectId && dispatchProps.goToDeliverables(stateProps.currentProjectId)
});

export const withConnect = connect(mapStateToProps, mapDispatchToProps, mergeProps);
