import * as R from 'ramda';
import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelStructuralFacadeExternalLinears } from '../getTopLevelStructuralFacadeExternalLinears';

export const getTopLevelRealOutsideStructuralWallsExternalLinear = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled
): number =>
  R.sum(
    getTopLevelStructuralFacadeExternalLinears(
      caseGranulometry,
      levelGranulometry as LevelGranulometry
    )
  );
