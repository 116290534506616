import { createSelector, Selector } from 'reselect';
import { selectParcels } from '../specification/parcels.selector';
import { FeatureParcel } from '../../../domain/Parcel';
import { State } from '../../reducers';
import { selectBuildings } from '../specification/buildings.selector';
import { selectAllCasesSpecifications } from '../specification/allCasesSpecifications.selector';
import { selectAllLevelsInGranulometrySelector } from '../granulometry/selectAllLevelsInGranulometry.selector';
import { selectAllLevelsSpecifications } from '../specification/allLevelsSpecifications.selector';
import { Layer } from '../../../services/mapBox/layer/layer.model';
import { selectProjectSurfaceInputMode } from '../project/projectSurfaceInputMode.selector';
import { selectInitializeLayersRouting } from '../navigation/toolbox/casesPanel/selectInitializeLayersRouting.selector';
import { initializeLayers } from '../../../services/mapBox/initLayers';

export interface InitializeLayers {
  parcels: FeatureParcel[] | undefined;
  layers: Layer[];
  isMapInitialized: boolean | undefined;
  isLoading: boolean;
}

export const selectInitializeLayers: Selector<State, InitializeLayers> = createSelector(
  [
    selectAllLevelsInGranulometrySelector,
    selectAllLevelsSpecifications,
    selectParcels,
    selectBuildings,
    selectAllCasesSpecifications,
    selectProjectSurfaceInputMode,
    selectInitializeLayersRouting
  ],
  initializeLayers
);
