import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { getTopLevelWallsLinear } from '../getTopLevelWallsLinear';
import { getTopLevelRealOutsideInsulatedWallsInternalLinear } from './getTopLevelRealOutsideInsulatedWallsInternalLinear';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelRealInsideWallsLinear = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled | LevelGranulometry
) =>
  getTopLevelWallsLinear(caseGranulometry, levelGranulometry) -
  getTopLevelRealOutsideInsulatedWallsInternalLinear(caseGranulometry, levelGranulometry);
