import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectProjectIsValid } from 'store/selectors/validation/projectIsValid.selector';
import { selectIsLoadingProject } from 'store/selectors/project/isLoadingProject.selector';
import { selectIsSavingProject } from 'store/selectors/project/isSavingProject.selector';
import { selectProjectId } from 'store/selectors/project/currentProjectId.selector';
import { selectIsProjectDirty } from 'store/selectors/project/isDirty.selector';
import { requestReport } from 'store/actions/report.actions';
import { Button } from 'components/ui/Button';
import NewBackButton from './NewBackButton';
import { Stepper } from '../../ui/Stepper';
import { goToBuildings } from '../../../store/actions/navigations/toolbox/buildingsPanel/goToBuildings.action';

const NewCasesActionButtons: React.FC = () => {
  const projectId = useSelector(selectProjectId) as string;
  const projectValid = useSelector(selectProjectIsValid);
  const isDirty = useSelector(selectIsProjectDirty);
  const isSaving = useSelector(selectIsSavingProject);
  const isFetching = useSelector(selectIsLoadingProject);
  const isWaitingForBackend = isSaving || isFetching;

  const dispatch = useDispatch();

  const backToBuildings = React.useCallback(() => {
    dispatch(goToBuildings(projectId));
  }, [dispatch, projectId]);
  const handleCompute = React.useCallback(() => {
    dispatch(requestReport());
  }, [dispatch]);

  const { t } = useTranslation();

  return (
    <>
      <Stepper steps={[t('Project'), t('Buildings'), t('Cases')]} value={2} />
      <NewBackButton onClick={backToBuildings} />
      <Button
        disabled={!projectValid || isWaitingForBackend}
        content={isWaitingForBackend ? t('Calculating project') : t('Calculate project')}
        withLoader={isDirty}
        handleClick={handleCompute}
      />
    </>
  );
};

export default NewCasesActionButtons;
