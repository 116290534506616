import { LevelGranulometryFullFilled } from '../../../LevelGranulometry';
import { mustHaveSmokeExtractionDuctSections } from '../../mustHave/mustHaveSmokeExtractionDuctSections';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { getSectionDisplayedSurface } from '../../../../sections/queries/surfaces/getSectionDisplayedSurface';
import { mustHaveElevatorShaftSections } from '../../mustHave/mustHaveElevatorShaftSections';
import { mustHaveStairShaftSections } from '../../mustHave/mustHaveStairShaftSections';
import { getLevelFullFilledContent } from '../../content/getLevelFullFilledContent';
import { isSectionASmokeExtractionDuctsInfra } from '../../../../sections/circulationSections/smokeExtractionDuctsInfra';
import { isSectionAElevatorShaftInfra } from '../../../../sections/circulationSections/elevatorShaftInfra';
import { isSectionAStairsShaftInfra } from '../../../../sections/circulationSections/stairsShaftInfra';
import { isSectionARampInfra } from '../../../../sections/circulationSections/rampInfra';

export const getBasementLevelHoppersSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled,
  rampExcluded?: boolean
): number => {
  let hoppersSurface = 0;
  if (mustHaveSmokeExtractionDuctSections(caseGranulometry)) {
    getLevelFullFilledContent(levelGranulometry)
      .filter(isSectionASmokeExtractionDuctsInfra)
      .forEach((section) => {
        hoppersSurface += getSectionDisplayedSurface(section);
      });
  }

  if (mustHaveElevatorShaftSections(caseGranulometry)) {
    getLevelFullFilledContent(levelGranulometry)
      .filter(isSectionAElevatorShaftInfra)
      .forEach((section) => {
        hoppersSurface += getSectionDisplayedSurface(section);
      });
  }

  if (mustHaveStairShaftSections(caseGranulometry)) {
    getLevelFullFilledContent(levelGranulometry)
      .filter(isSectionAStairsShaftInfra)
      .forEach((section) => {
        const stairShaftSurface = getSectionDisplayedSurface(section);
        const stairShaftBearingSurface =
          (section?.bearingWidth || 0) * (section?.bearingLength || 0);
        hoppersSurface += stairShaftSurface - stairShaftBearingSurface;
      });

    if (!rampExcluded) {
      hoppersSurface +=
        getLevelFullFilledContent(levelGranulometry).find(isSectionARampInfra)?.displayedSurface ||
        0;
    }
  }

  return hoppersSurface;
};
