import { get } from './admin.api';
import { ExistingProject } from '../../domain/project/Project';
import { User } from '../../domain/User';
import { AuthToken } from '../../AuthToken';
import { migrateProject } from '../../domain/migrations/project';

export const fetchProjectState = async (
  projectId: string,
  userId?: User['id'],
  userToken?: AuthToken
): Promise<ExistingProject> => {
  const response = (await get(
    `/ProjectsStates/index/${userId || 'noUserId'}/${userToken || 'noUserToken'}/${projectId}`,
    userToken || 'noUserToken'
  )) as unknown as Response;
  const [apiProjectState] = await response.json();
  return migrateProject(JSON.parse(apiProjectState)) as ExistingProject;
};
