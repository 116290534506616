import { shouldThisLevelBeSectionWrapped } from './shouldThisLevelBeSectionWrapped';
import { getLevelAllButVerticalCirculationSectionsSurface } from '../../domain/granulometry/levels/queries/surfaces/getLevelAllButVerticalCirculationSectionsSurface';
import { SQUARE_METER_SCALE } from '../../constants/appConstants';
import { CaseGranulometry } from '../../domain/granulometry/cases/CaseGranulometry';
import {
  LevelGranulometry,
  SectionWrapper
} from '../../domain/granulometry/levels/LevelGranulometry';
import { getLevelFullFilledContent } from '../../domain/granulometry/levels/queries/content/getLevelFullFilledContent';

export const manageLevelSectionWrapper = (
  levelGranulometry: LevelGranulometry,
  caseGranulometry: CaseGranulometry
) => {
  if (shouldThisLevelBeSectionWrapped(levelGranulometry, caseGranulometry)) {
    // Create a new section wrapper
    const sectionWrapper: SectionWrapper = {
      theoricalWidth:
        (getLevelAllButVerticalCirculationSectionsSurface(levelGranulometry) / 2) *
        SQUARE_METER_SCALE,
      firstWrapperInnerWidth: 0,
      secondWrapperInnerWidth: 0,
      realWidth: 0,
      overflow: 0
    };
    let firstWrapperOpened = false;
    let secondWrapperOpened = false;
    let secondWrapperTheoricalWidthReaded = 0;
    // For each section in level content :

    getLevelFullFilledContent(levelGranulometry).forEach((section) => {
      // Check if the current iteration should open the first section wrapper
      // Note : wrapped sections are all the level sections without the grouped sections at the begining.
      if (!firstWrapperOpened && !secondWrapperOpened && !section.group) {
        // form now sections are in the first section wrapper
        firstWrapperOpened = true;
      }
      // If the width readed if over the half of the section wrapper total width
      if (
        firstWrapperOpened &&
        !secondWrapperOpened &&
        secondWrapperTheoricalWidthReaded >= sectionWrapper.theoricalWidth
      ) {
        // Form now sections are not anymore in the first section wrapper
        firstWrapperOpened = false;
        // Form now sections are in the second section wrapper
        secondWrapperOpened = true;
      }
      // If the current section is in the first or the second section wrapper
      if (firstWrapperOpened || secondWrapperOpened) {
        // Get the current section width
        const currentSectionWidth = section.surface * SQUARE_METER_SCALE;
        /* if (section.wallThickness) {
          currentSectionWidth += section.wallThickness * LINEAR_METER_SCALE;
        } */
        // Save the inSectionWrappers property in section params
        // and add the current section width into incrementers
        section.inSectionWrappers = true;
        if (firstWrapperOpened) {
          section.inFirstSectionWrapper = true;
          section.inSecondSectionWrapper = false;
          sectionWrapper.firstWrapperInnerWidth += currentSectionWidth;
        }
        if (secondWrapperOpened) {
          section.inFirstSectionWrapper = false;
          section.inSecondSectionWrapper = true;
          sectionWrapper.secondWrapperInnerWidth += currentSectionWidth;
        }
        secondWrapperTheoricalWidthReaded += currentSectionWidth;
      }
    });

    // Set sectionWrapper real width
    sectionWrapper.realWidth = Math.max(
      sectionWrapper.firstWrapperInnerWidth,
      sectionWrapper.secondWrapperInnerWidth
    );
    // Set sectionWrapper overflow
    sectionWrapper.overflow = Math.abs(sectionWrapper.realWidth - sectionWrapper.theoricalWidth);
    // Save the sectionWrapper into level
    levelGranulometry.sectionWrapper = sectionWrapper;
  }
};
