import { createSelector, Selector } from 'reselect';
import { getUserState, State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';
import { User } from '../../../domain/User';

import { isLoggedIn } from '../../reducers/user.slice';

export const selectUser: Selector<State, Maybe<User>> = createSelector(
  [getUserState],
  (userState) => (isLoggedIn(userState) ? userState.details : undefined)
);
