import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { LodgmentTypeSpecification } from '../../../../../../specification/lodgmentTypes/LodgmentTypeSpecification';
import { getCaseLodgmentDistribution } from '../../../../../../projection/cases/queries/methodsToFillCaseGranulometry/getCaseLodgmentDistribution';
import { LodgmentSection } from '../../../../../sections/lodgmentSections/LodgmentSection';
import { FilledLevelSpecification } from '../../../../../../projection/cases/queries/methodsToFillCaseGranulometry/getFilledLevelSpecifications';

export const createTopLevelLodgmentSections = (
  caseGranulometry: CaseGranulometry,
  filledLevelSpecification: FilledLevelSpecification,
  customLodgments?: (LodgmentTypeSpecification | LodgmentSection)[] // depend of editingStage
): LodgmentSection[] => {
  if (customLodgments) {
    return customLodgments as LodgmentSection[];
  } else {
    const distribution = getCaseLodgmentDistribution(caseGranulometry.initialSpecifications);

    let lodgments: LodgmentSection[] = [];
    distribution.forEach((distrib) => {
      const lodgmentType = distrib.lodgmentType;
      filledLevelSpecification.lodgments[lodgmentType].forEach((lodgmentData) => {
        const lodgment = { ...lodgmentData };
        // TODO : Are we using all this ?
        /* lodgment.insideWallsPartitionRate = getCasePropertyConvertedValue(
          caseGranulometry.initialSpecifications,
          'lodgmentInsideWallsPartitionRate'
        );
        lodgment.concreteSlabConcreteRatio = getCasePropertyConvertedValue(
          caseGranulometry.initialSpecifications,
          'lodgmentConcreteSlabConcreteRatio'
        );
        lodgment.concreteSlabFormworkRate = getCasePropertyConvertedValue(
          caseGranulometry.initialSpecifications,
          'lodgmentConcreteSlabFormworkRate'
        );
        lodgment.outsideWallsConcreteRatio = getCasePropertyConvertedValue(
          caseGranulometry.initialSpecifications,
          'lodgmentOutsideWallsConcreteRatio'
        );
        lodgment.outsideWallsThickness = getCasePropertyConvertedValue(
          caseGranulometry.initialSpecifications,
          'outsideWallsThickness'
        ); */
        lodgments = [...lodgments, lodgment] as LodgmentSection[];
      });
    });
    return lodgments;
  }
};
