import { createSelector, Selector } from 'reselect';
import { selectCurrentCaseIdFromRoute } from '../../navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { selectGranulometryRealBuiltSurfacesByBuildingCaseAndLevel } from '../resultingRealBuiltSurfacesByBuildingCaseAndLevel.selector';
import { selectParentBuildingFromCaseRoute } from '../../navigation/toolbox/casesPanel/parentBuildingFromCaseRoute.selector';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';

type CurrentCaseRealBuiltSurfacesByLevel = Maybe<Record<string, number>>;

export const selectCurrentCaseRealBuiltSurfacesByLevel: Selector<
  State,
  CurrentCaseRealBuiltSurfacesByLevel
> = createSelector(
  [
    selectCurrentCaseIdFromRoute,
    selectParentBuildingFromCaseRoute,
    selectGranulometryRealBuiltSurfacesByBuildingCaseAndLevel
  ],
  (caseId, building, realBuiltSurfaceByBuildingCaseAndLevel) =>
    caseId && building && realBuiltSurfaceByBuildingCaseAndLevel
      ? realBuiltSurfaceByBuildingCaseAndLevel[building.id][caseId]
      : undefined
);
