import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../../../../ui/FormGroup';
import { Input } from '../../../../../ui/input/Input';
import { casePropertyChanged } from '../../../../../../store/actions/casePropertyChanged.action';
import { IconObject } from '../../../../../ui/Icons/iconObject';
import { CaseId } from '../../../../../../domain/specification/cases/CaseSpecification';
import { Property } from '../../../../../../domain/specification/cases/Property';

interface ConcreteOutsideWallTypeRatesProps {
  caseId: CaseId;
  outsideWallConcreteRateProperty: Property;
  outsideWallConcreteRateMaxValue: number;
  outsideWallTypeRatesValidity: boolean;
}

export const ConcreteOutsideWallTypeRates = ({
  caseId,
  outsideWallConcreteRateProperty,
  outsideWallConcreteRateMaxValue,
  outsideWallTypeRatesValidity
}: ConcreteOutsideWallTypeRatesProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Concrete :
  const handleOutsideWallConcreteRateChange = React.useCallback(
    (newOutsideWallConcreteRate: number | null) => {
      if (
        caseId &&
        outsideWallConcreteRateProperty &&
        (typeof newOutsideWallConcreteRate === 'number' || newOutsideWallConcreteRate === null)
      ) {
        dispatch(
          casePropertyChanged(caseId, {
            ...outsideWallConcreteRateProperty,
            value: newOutsideWallConcreteRate ?? undefined
          })
        );
      }
    },
    [dispatch, caseId, outsideWallConcreteRateProperty]
  );

  return (
    <FormGroup horizontal={true} wrap={true}>
      <Input
        style={{ width: '40%', flex: '0 0 40%' }}
        name={`case${caseId}OutsideWallConcreteRate`}
        label={t('outsideWallTypeRates.concreteRate.label')}
        info={t('outsideWallTypeRates.concreteRate.info')}
        type="number"
        min={0}
        max={100}
        minMax={{
          min: outsideWallConcreteRateProperty.min,
          max: outsideWallConcreteRateMaxValue,
          unit: outsideWallConcreteRateProperty.unit
        }}
        color={outsideWallTypeRatesValidity ? undefined : 'orange'}
        placeholder={outsideWallConcreteRateMaxValue}
        value={outsideWallConcreteRateProperty.value}
        handleChange={handleOutsideWallConcreteRateChange}
        suffix={outsideWallConcreteRateProperty.unit}
        icon={<IconObject type="menu" iconName="concrete" clickable={false} />}
      />
    </FormGroup>
  );
};
