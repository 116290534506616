import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../ui/Card';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { Window } from '../../../../ui/Window';
import { Form } from '../../../../ui/Form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { IconObject } from '../../../../ui/Icons/iconObject';
import CaseProperties from './CaseProperties';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { selectCurrentlyEditedProperty } from '../../../../../store/selectors/navigation/toolbox/casesPanel/currentlyEditedProperty.selector';
import { FormGroup } from '../../../../ui/FormGroup';
import { HighlightOverlay } from '../../../../layouts/HighlightOverlay';
import { CaseGranulometryEditOtherPropertiesRouteParams } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditOtherPropertiesRoute';
import { getCaseGranulometryEditOtherPropertiesTriggerId } from '../../../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditOtherPropertiesTriggerId';
import { getCaseGranulometryEditOtherPropertiesPropertyTriggerId } from '../../../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditOtherPropertiesPropertyTriggerId';
import { goToCase } from '../../../../../store/actions/navigations/toolbox/casesPanel/goToCase.action';
import { goToCaseGranulometryEditOutdoors } from '../../../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditOutdoors.action';
import { goToCaseGranulometryEditOtherProperties } from '../../../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditOtherProperties.action';

export const CaseOtherPropertiesWindow = () => {
  const { projectId, caseId } = useParams<CaseGranulometryEditOtherPropertiesRouteParams>();
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const currentlyEditedProperty = useSelector(selectCurrentlyEditedProperty);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickClose = React.useCallback(() => {
    if (caseId && projectId) dispatch(goToCase(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  const handleClickPrev = React.useCallback(() => {
    caseId && projectId && dispatch(goToCaseGranulometryEditOutdoors(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  const handleHighlightReturn = React.useCallback(() => {
    caseId && projectId && dispatch(goToCaseGranulometryEditOtherProperties(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  if (!caseLabel) return null;

  const isHighlighted = !!currentlyEditedProperty;
  const triggerId = currentlyEditedProperty
    ? getCaseGranulometryEditOtherPropertiesPropertyTriggerId(
        caseLabel,
        currentlyEditedProperty.name
      )
    : undefined;

  return (
    <Window
      title={t('Other properties')}
      triggerId={getCaseGranulometryEditOtherPropertiesTriggerId(caseLabel)}
      topButtons={
        <>
          <IconObject iconName="arrowTop" type="menu" onClick={handleClickPrev} />
          <IconObject iconName="arrowBottom" type="menu" disabled={true} />
          <IconObject iconName="close" type="menu" onClick={handleClickClose} />
        </>
      }>
      <HighlightOverlay
        highlighted={isHighlighted}
        triggerId={triggerId}
        onReturn={handleHighlightReturn}>
        <Form>
          <Card>
            <SpecialLabel label={t('Properties')} />
            <FormGroup>
              <CaseProperties currentEditedProperty={currentlyEditedProperty} />
            </FormGroup>
          </Card>
        </Form>
      </HighlightOverlay>
    </Window>
  );
};

export default CaseOtherPropertiesWindow;
