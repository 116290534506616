import { createSelector, Selector } from 'reselect';
import { selectPath } from '../navigation';
import { matchesProject } from '../../../routes/utils/matches/matchesProject';
import { State } from '../../../store/reducers';
import { Maybe } from '../../../utils/Maybe';

export const selectProjectId: Selector<State, Maybe<string>> = createSelector(
  [selectPath],
  (path) => matchesProject(path).params?.projectId
);
