import { createSelector, Selector } from 'reselect';
import { selectAllCasesInGranulometry } from '../allCasesGranulometries.selector';
import { selectCurrentCaseIdFromRoute } from '../../navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { State } from '../../../reducers';
import { CaseGranulometry } from '../../../../domain/granulometry/cases/CaseGranulometry';
import { hasCaseId } from '../../../../domain/granulometry/cases/queries/has/hasCaseId';
import { Maybe } from '../../../../utils/Maybe';

export const selectCurrentCaseGranulometry: Selector<
  State,
  Maybe<CaseGranulometry>
> = createSelector(
  [selectAllCasesInGranulometry, selectCurrentCaseIdFromRoute],
  (allCases, caseId) => (allCases && caseId ? allCases.find(hasCaseId(caseId)) : undefined)
);
