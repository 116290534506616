import { BasementLevelSpecification } from '../../domain/specification/levels/BasementLevelSpecification';

export const SET_BASEMENT_LEVELS_SPECIFICATIONS = 'SET_BASEMENT_LEVELS_SPECIFICATIONS';

export const setBasementLevelsSpecifications = (
  caseId: string,
  levels: BasementLevelSpecification[]
) => ({
  type: SET_BASEMENT_LEVELS_SPECIFICATIONS,
  payload: { caseId, levels }
});

export type SetBasementLevelsSpecificationsAction = ReturnType<
  typeof setBasementLevelsSpecifications
>;
