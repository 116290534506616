import { createMigrate, PersistedState } from 'redux-persist';
import * as R from 'ramda';
import { migrateProject0To1 } from '../../../domain/migrations/migrateFunctions/migrateProject0To1';
import { migrateProject1To2 } from '../../../domain/migrations/migrateFunctions/migrateProject1To2';
import { migrateProject2To3 } from '../../../domain/migrations/migrateFunctions/migrateProject2To3';
import { migrateProject3To4 } from '../../../domain/migrations/migrateFunctions/migrateProject3To4';
import { migrateProject4To5 } from '../../../domain/migrations/migrateFunctions/migrateProject4To5';
import { migrateProject5To6 } from '../../../domain/migrations/migrateFunctions/migrateProject5To6';
import { migrateProject6To7 } from '../../../domain/migrations/migrateFunctions/migrateProject6To7';
import { migrateProject15To16 } from '../../../domain/migrations/migrateFunctions/migrateProject15To16';
import { migrateProject14To15 } from '../../../domain/migrations/migrateFunctions/migrateProject14To15';
import { migrateProject13To14 } from '../../../domain/migrations/migrateFunctions/migrateProject13To14';
import { migrateProject12To13 } from '../../../domain/migrations/migrateFunctions/migrateProject12To13';
import { migrateProject11To12 } from '../../../domain/migrations/migrateFunctions/migrateProject11To12';
import { migrateProject10To11 } from '../../../domain/migrations/migrateFunctions/migrateProject10To11';
import { migrateProject9To10 } from '../../../domain/migrations/migrateFunctions/migrateProject9To10';
import { migrateProject8To9 } from '../../../domain/migrations/migrateFunctions/migrateProject8To9';
import { migrateProject7To8 } from '../../../domain/migrations/migrateFunctions/migrateProject7To8';
import { migrateProject16To17 } from '../../../domain/migrations/migrateFunctions/migrateProject16To17';
import { ProjectState } from '../project.reducer';
import { migrateProject17To19 } from '../../../domain/migrations/migrateFunctions/migrateProject17To19';
import { migrateProject19To20 } from '../../../domain/migrations/migrateFunctions/migrateProject19To20';
import { migrateProject20To21 } from '../../../domain/migrations/migrateFunctions/migrateProject20To21';
import { migrateProject21To22 } from '../../../domain/migrations/migrateFunctions/migrateProject21To22';
import { migrateProject22To23 } from '../../../domain/migrations/migrateFunctions/migrateProject22To23';
import { migrateProject23To24 } from '../../../domain/migrations/migrateFunctions/migrateProject23To24';
import { migrateProject24To25 } from '../../../domain/migrations/migrateFunctions/migrateProject24To25';
import { migrateProject25To26 } from '../../../domain/migrations/migrateFunctions/migrateProject25To26';
import { migrateProject26To27 } from '../../../domain/migrations/migrateFunctions/migrateProject26To27';
import { migrateProject27To28 } from '../../../domain/migrations/migrateFunctions/migrateProject27To28';
import { migrateProject28To29 } from '../../../domain/migrations/migrateFunctions/migrateProject28To29';
import { migrateProject29To30 } from '../../../domain/migrations/migrateFunctions/migrateProject29To30';
import { migrateProject30To31 } from '../../../domain/migrations/migrateFunctions/migrateProject30To31';
import { Project } from '../../../domain/project/Project';
import { migrateProject31To32 } from '../../../domain/migrations/migrateFunctions/migrateProject31To32';
import { migrateProject32To33 } from '../../../domain/migrations/migrateFunctions/migrateProject32To33';
import { migrateProject33To34 } from '../../../domain/migrations/migrateFunctions/migrateProject33To34';
import { migrateProject34To35 } from '../../../domain/migrations/migrateFunctions/migrateProject34To35';
import { migrateProject35To36 } from '../../../domain/migrations/migrateFunctions/migrateProject35To36';
import { migrateProject36To37 } from '../../../domain/migrations/migrateFunctions/migrateProject37To38';

const migrations = {
  0: (state: PersistedState & any): PersistedState & any => {
    const draftProjectSurfaceRaw = state?.draft?.project?.land?.surfaces?.constructibleArea || 850;
    const savedProjectSurfaceRaw = state?.saved?.project?.land?.surfaces?.constructibleArea || 850;

    return R.pipe(
      R.assocPath(['saved', 'surface', 'value'], savedProjectSurfaceRaw),
      R.assocPath(['draft', 'surface', 'value'], draftProjectSurfaceRaw),
      R.dissocPath(['saved', 'land']),
      R.dissocPath(['draft', 'land'])
    )(state) as PersistedState;
  },
  1: (state: PersistedState & any): PersistedState & any =>
    R.pipe(
      R.assoc('draft', migrateProject0To1(state.draft)),
      R.assoc('saved', migrateProject0To1(state.saved))
    )(state),
  2: (state: PersistedState & any): PersistedState & any =>
    R.pipe(
      R.assoc('draft', migrateProject1To2(state.draft)),
      R.assoc('saved', migrateProject1To2(state.saved))
    )(state),
  3: (state: PersistedState & any): PersistedState & any =>
    R.pipe(
      R.assoc('draft', migrateProject2To3(state.draft)),
      R.assoc('saved', migrateProject2To3(state.saved))
    )(state),
  4: (state: PersistedState & any): PersistedState & any =>
    R.pipe(
      R.assoc('draft', migrateProject3To4(state.draft)),
      R.assoc('saved', migrateProject3To4(state.saved))
    )(state),
  5: (state: PersistedState & any): PersistedState & any =>
    R.pipe(
      R.assoc('draft', migrateProject4To5(state.draft)),
      R.assoc('saved', migrateProject4To5(state.saved))
    )(state),
  6: (state: PersistedState & any): PersistedState & any =>
    R.pipe(
      R.assoc('draft', migrateProject5To6(state.draft)),
      R.assoc('saved', migrateProject5To6(state.saved))
    )(state),
  7: (state: PersistedState & any): PersistedState & any =>
    R.pipe(
      R.assoc('draft', migrateProject6To7(state.draft)),
      R.assoc('saved', migrateProject6To7(state.saved))
    )(state),
  8: (state: PersistedState & any): PersistedState & any =>
    R.pipe(
      R.assoc('draft', migrateProject7To8(state.draft)),
      R.assoc('saved', migrateProject7To8(state.saved))
    )(state),
  9: (state: PersistedState & any): PersistedState & any =>
    R.pipe(
      R.assoc('draft', migrateProject8To9(state.draft)),
      R.assoc('saved', migrateProject8To9(state.saved))
    )(state),
  10: (state: PersistedState & any): PersistedState & any =>
    R.pipe(
      R.assoc('draft', migrateProject9To10(state.draft)),
      R.assoc('saved', migrateProject9To10(state.saved))
    )(state),
  11: (state: PersistedState & any): PersistedState & any =>
    R.pipe(
      R.assoc('draft', migrateProject10To11(state.draft)),
      R.assoc('saved', migrateProject10To11(state.saved))
    )(state),
  12: (state: PersistedState & any): PersistedState & any =>
    R.pipe(
      R.assoc('draft', migrateProject11To12(state.draft)),
      R.assoc('saved', migrateProject11To12(state.saved))
    )(state),
  13: (state: PersistedState & any): PersistedState & any =>
    R.pipe(
      R.assoc('draft', migrateProject12To13(state.draft)),
      R.assoc('saved', migrateProject12To13(state.saved))
    )(state),
  14: (state: PersistedState & any): PersistedState & any =>
    R.pipe(
      R.assoc('draft', migrateProject13To14(state.draft)),
      R.assoc('saved', migrateProject13To14(state.saved))
    )(state),
  15: (state: PersistedState & any): PersistedState & any =>
    R.pipe(
      R.assoc('draft', migrateProject14To15(state.draft)),
      R.assoc('saved', migrateProject14To15(state.saved))
    )(state),
  16: (state: PersistedState & any): PersistedState & any =>
    R.pipe(
      R.assoc('draft', migrateProject15To16(state.draft)),
      R.assoc('saved', migrateProject15To16(state.saved))
    )(state),
  17: (state: PersistedState & any): PersistedState & any =>
    R.pipe(
      R.assoc('draft', migrateProject16To17(state.draft)),
      R.assoc('saved', migrateProject16To17(state.saved))
    )(state),
  18: (state: PersistedState & any): ProjectState & any => ({
    current: state.draft || state.saved,
    history: state.draft && state.saved ? [state.saved] : [],
    state: 'IDLE'
  }),
  19: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject17To19(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject17To19))
    )(state),
  20: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject19To20(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject19To20))
    )(state),
  21: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject20To21(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject20To21))
    )(state),
  22: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject21To22(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject21To22))
    )(state),
  23: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject22To23(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject22To23))
    )(state),
  24: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject23To24(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject23To24))
    )(state),
  25: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject24To25(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject24To25))
    )(state),
  26: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject25To26(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject25To26))
    )(state),
  27: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject26To27(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject26To27))
    )(state),
  28: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject27To28(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject27To28))
    )(state),
  29: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject28To29(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject28To29))
    )(state),
  30: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject29To30(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject29To30))
    )(state),
  31: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject30To31(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject30To31))
    )(state),
  32: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject31To32(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject31To32))
    )(state),
  33: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject32To33(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject32To33))
    )(state),
  34: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject33To34(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject33To34))
    )(state),
  35: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject34To35(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject34To35))
    )(state),
  36: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject35To36(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject35To36))
    )(state),
  37: (state: PersistedState & any): ProjectState & any =>
    R.pipe(
      R.assoc('current', migrateProject36To37(state.current)),
      R.over<PersistedState & any, Project[]>(R.lensProp('history'), R.map(migrateProject36To37))
    )(state)
};

export const projectMigrations = createMigrate(migrations);
