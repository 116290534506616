import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Window } from '../../../../ui/Window';
import LodgmentsTypesInner from './LodgmentsTypesInner';
import { IconObject } from '../../../../ui/Icons/iconObject';
import { selectCurrentlyEditedLodgmentType } from '../../../../../store/selectors/specification/currentCase/currentlyEditedLodgmentType.selector';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { CaseGranulometryEditLodgmentTypeRouteParams } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditLodgmentTypeRoute';
import { getCaseGranulometryEditLodgmentTypeTriggerId } from '../../../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditLodgmentTypeTriggerId';
import { goToCase } from '../../../../../store/actions/navigations/toolbox/casesPanel/goToCase.action';
import { goToCaseGranulometryEditLodgmentType } from '../../../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditLodgmentType.action';

export const LodgmentsTypes = () => {
  const { projectId, caseId } = useParams<CaseGranulometryEditLodgmentTypeRouteParams>();
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const lodgmentTypeSpecification = useSelector(selectCurrentlyEditedLodgmentType);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickClose = React.useCallback(() => {
    if (caseId && projectId) dispatch(goToCase(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  const handleClickPrev = React.useCallback(() => {
    if (caseId && projectId && lodgmentTypeSpecification?.prevType)
      dispatch(
        goToCaseGranulometryEditLodgmentType(projectId, caseId, lodgmentTypeSpecification.prevType)
      );
  }, [dispatch, projectId, caseId, lodgmentTypeSpecification]);

  const handleClickNext = React.useCallback(() => {
    if (caseId && projectId && lodgmentTypeSpecification?.nextType)
      dispatch(
        goToCaseGranulometryEditLodgmentType(projectId, caseId, lodgmentTypeSpecification.nextType)
      );
  }, [dispatch, projectId, caseId, lodgmentTypeSpecification]);

  if (!caseLabel || !lodgmentTypeSpecification) return null;

  return (
    <Window
      title={`${t('Lodgment type')} : ${lodgmentTypeSpecification.lodgmentType}`}
      topButtons={
        <>
          <IconObject
            iconName="arrowLeft"
            type="menu"
            disabled={!lodgmentTypeSpecification.prevType}
            onClick={handleClickPrev}
          />
          <IconObject
            iconName="arrowRight"
            type="menu"
            disabled={!lodgmentTypeSpecification.nextType}
            onClick={handleClickNext}
          />
          <IconObject iconName="close" type="menu" onClick={handleClickClose} />
        </>
      }
      triggerId={getCaseGranulometryEditLodgmentTypeTriggerId(
        caseLabel,
        lodgmentTypeSpecification.lodgmentType
      )}>
      <LodgmentsTypesInner />
    </Window>
  );
};

export default LodgmentsTypes;
