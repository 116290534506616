import * as R from 'ramda';
import { CaseGranulometry } from '../../cases/CaseGranulometry';
import { LevelGranulometry } from '../../levels/LevelGranulometry';
import { createTopLevel } from '../../levels/queries/topLevels/createTopLevel';
import { getLevelDistributionFromDistribution } from './getLevelDistributionFromDistribution';
import { getLodgmentCountFromDistribution } from './getLodgmentCountFromDistribution';
import { getDefaultLodgmentSectionsFromGranulometry } from './getDefaultLodgmentSectionsFromGranulometry';
import { getLodgmentSurfaceFromDistributionAndLodgmentSections } from './getLodgmentSurfaceFromDistributionAndLodgmentSections';
import { getAdjustedLodgments } from './getAdjustedLodgments';
import { getLevelCustomDistribution } from './getLevelCustomDistribution';
import { getLevelDisplayedWallThickness } from '../../levels/queries/getLevelDisplayedWallThickness';
import { getTopLevelAlreadyCalculatedSurfaceForSale } from '../../levels/queries/topLevels/surfaces/surfaceForSale/getTopLevelAlreadyCalculatedSurfaceForSale';
import { isRoofingLevel } from '../../levels/queries/is/isRoofingLevel';
import { getSectionsTotalSurface } from '../../sections/queries/surfaces/getSectionsTotalSurface';

export const redistributeCaseTopLevelsWithCustomDistribution = (
  levels: LevelGranulometry[],
  caseGranulometry: CaseGranulometry
): LevelGranulometry[] =>
  R.reduce((acc, levelGranulometry: LevelGranulometry) => {
    if (
      !R.isNil(getLevelCustomDistribution(caseGranulometry, levelGranulometry.level)) &&
      !isRoofingLevel(caseGranulometry, levelGranulometry)
    ) {
      const newLevelDistribution = getLevelDistributionFromDistribution(
        caseGranulometry,
        levelGranulometry
      );

      const newLodgmentCount = getLodgmentCountFromDistribution(newLevelDistribution);
      const defaultLodgmentSections = getDefaultLodgmentSectionsFromGranulometry(caseGranulometry);
      const newLodgmentSurface = getLodgmentSurfaceFromDistributionAndLodgmentSections(
        newLevelDistribution,
        defaultLodgmentSections
      );
      const commonPremiseSectionsSurface = levelGranulometry.commonPremiseSections
        ? getSectionsTotalSurface(levelGranulometry.commonPremiseSections)
        : 0;
      const shopSectionsSurface = levelGranulometry.shopSections
        ? getSectionsTotalSurface(levelGranulometry.shopSections)
        : 0;
      const officeSectionsSurface = levelGranulometry.officeSections
        ? getSectionsTotalSurface(levelGranulometry.officeSections)
        : 0;
      const remainingSurfaceForSale =
        getTopLevelAlreadyCalculatedSurfaceForSale(levelGranulometry).value -
        newLodgmentSurface -
        commonPremiseSectionsSurface -
        shopSectionsSurface -
        officeSectionsSurface;
      const adjustedLodgments = getAdjustedLodgments(
        newLevelDistribution,
        defaultLodgmentSections,
        remainingSurfaceForSale,
        newLodgmentCount
      );
      const newLevel = createTopLevel(
        {
          id: levelGranulometry.id,
          level: levelGranulometry.level,
          realBuiltSurface: levelGranulometry.realBuiltSurface,
          surfaceForSale: levelGranulometry.surfaceForSale,
          grossFloorSurfaceEff: levelGranulometry.grossFloorSurfaceEff,
          ceilingHeight: levelGranulometry.ceilingHeight,
          technicalPremiseSections: levelGranulometry.technicalPremiseSections,
          commonPremiseSections: levelGranulometry.commonPremiseSections,
          shopSections: levelGranulometry.shopSections,
          officeSections: levelGranulometry.officeSections,
          waterBearingTechnicalSheathEnabled: levelGranulometry.waterBearingTechnicalSheathEnabled,
          generalBearingTechnicalSheathEnabled:
            levelGranulometry.generalBearingTechnicalSheathEnabled,
          gasBearingTechnicalSheathEnabled: levelGranulometry.gasBearingTechnicalSheathEnabled,
          electricityBearingTechnicalSheathEnabled:
            levelGranulometry.electricityBearingTechnicalSheathEnabled,
          communicationBearingTechnicalSheathEnabled:
            levelGranulometry.communicationBearingTechnicalSheathEnabled,
          geometry: levelGranulometry.geometry,
          remainingSurfaceForSale, // just for TS but not used after
          lodgmentCount: newLodgmentCount, // just for TS but not used after
          lodgments: { t1: [], t2c: [], t2: [], t3c: [], t3: [], t4c: [], t4: [], t5: [] } // just for TS but not used after
        },
        caseGranulometry,
        true,
        adjustedLodgments
      );

      return [
        ...acc,
        {
          ...newLevel,
          roofing: levelGranulometry.roofing,
          displayedWallThickness: getLevelDisplayedWallThickness(newLevel)
        }
      ];
    }

    return [...acc, levelGranulometry];
  }, [] as LevelGranulometry[])(levels);
