import { createSelector } from 'reselect';
import * as R from 'ramda';
import { CostByCase } from '../../../domain/report/queries/getCostByBuildingAndCase';
import { BuildingId } from '../../../domain/specification/buildings/BuildingSpecification';
import { CaseId } from '../../../domain/specification/cases/CaseSpecification';
import { ifDefined } from '../../../utils/ifDefined';
import { selectLodgmentsCountByBuildingAndCase } from './lodgmentsCountByBuildingAndCase.selector';

export const selectLodgmentsCountByCase = createSelector(
  [selectLodgmentsCountByBuildingAndCase],
  ifDefined(
    R.pipe<
      [Record<BuildingId, Record<CaseId, number>>],
      Record<CaseId, number>[],
      Record<CaseId, number>
    >(R.values, R.reduce<CostByCase, CostByCase>(R.mergeRight, {}))
  )
);
