import { Surface } from '../../../../specification/Surface';
import { CaseGranulometry } from '../../CaseGranulometry';
import { getCaseTopLevels } from '../levels/getCaseTopLevels';
import { getSectionDisplayedSurface } from '../../../sections/queries/surfaces/getSectionDisplayedSurface';
import { isSectionIncludedInSurfaceForSale } from '../../../sections/SectionIncludedInSurfaceForSale';
import { getLevelFullFilledContent } from '../../../levels/queries/content/getLevelFullFilledContent';

export const getCaseFilledSurfaceForSale = (caseGranulometry: CaseGranulometry): Surface =>
  new Surface(
    getCaseTopLevels(caseGranulometry).reduce(
      (acc, levelGranulometry) =>
        getLevelFullFilledContent(levelGranulometry).reduce(
          (acc2, section) =>
            isSectionIncludedInSurfaceForSale(section)
              ? acc2 + getSectionDisplayedSurface(section)
              : acc2,
          acc
        ),
      0
    )
  );
