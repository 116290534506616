import { ReportEntry } from '../ReportEntry';
import { BuildingId } from '../../specification/buildings/BuildingSpecification';
import { CaseId } from '../../specification/cases/CaseSpecification';

export type WoodCubatureByCase = Record<CaseId, number>;
export type WoodCubatureByBuilding = Record<BuildingId, number>;
export type WoodCubatureByBuildingAndCase = Record<BuildingId, WoodCubatureByCase>;

export const getWoodCubatureByBuildingAndCase = (
  report: ReportEntry[]
): WoodCubatureByBuildingAndCase =>
  report.reduce(
    (counted, entry) => ({
      ...counted,
      [entry.buildingId]: {
        ...(counted[entry.buildingId] || {}),
        [entry.caseId]:
          ((counted[entry.buildingId] || {})[entry.caseId] || 0) + entry.compositionValue
      }
    }),
    {} as WoodCubatureByBuildingAndCase
  );
