import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import { LevelGranulometry } from '../../LevelGranulometry';
import { getHigherLevel } from '../getHigherLevel';
import { isRoofingLevel } from '../is/isRoofingLevel';

export const hasHigherLevel = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): boolean =>
  getHigherLevel(caseGranulometry, levelGranulometry) !== undefined &&
  !isRoofingLevel(
    caseGranulometry,
    getHigherLevel(caseGranulometry, levelGranulometry) as LevelGranulometry
  );
