import React from 'react';
import { LevelGeometry } from '../../../../../../services/mapBox/mapboxDraw/levelGeometry';
import { Input } from '../../../../../ui/input/Input';
import { FormGroup } from '../../../../../ui/FormGroup';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';
import { useTranslation } from 'react-i18next';
import { roundForSurfacesInputs } from '../../../../../../utils/round/roundForSurfacesInputs';

interface LevelGeometryFormProps {
  levelGeometry: LevelGeometry;
}

export const LevelGeometryForm = ({ levelGeometry }: LevelGeometryFormProps) => {
  const { t } = useTranslation();

  return (
    <FormGroup horizontal={true}>
      <div>
        <Input
          label={t('geometryWindow.featureForm.gfsEff.label')}
          name={`levelGeometry${levelGeometry.properties?.name || 'UNKNOWN'}GfsEff`}
          type="number"
          suffix="m&#178;"
          value={
            levelGeometry.properties?.area
              ? roundForSurfacesInputs(levelGeometry.properties.area)
              : undefined
          }
          className="gb-input--highlighted"
          asCustomValue={true}
          disabled={true}
        />
      </div>
      <div>
        <Input
          label={t('geometryWindow.featureForm.perimeter.label')}
          name={`levelGeometry${levelGeometry.properties?.name || 'UNKNOWN'}Perimeter`}
          type="number"
          suffix="mL"
          value={
            levelGeometry.properties?.perimeter
              ? roundWith2Decimal(levelGeometry.properties?.perimeter)
              : undefined
          }
          disabled={true}
        />
      </div>
    </FormGroup>
  );
};
