import { LevelGranulometry } from '../../../../LevelGranulometry';
import {
  getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface,
  LODGMENT_TECHNICAL_SHEATH_DEPTH,
  LODGMENT_TECHNICAL_SHEATH_WIDTH
} from './getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface';
import { getTopLevelLodgmentsTechnicalSheathsCount } from '../../counts/getTopLevelLodgmentsTechnicalSheathsCount';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelSectionsWithTechnicalSheathsCount } from '../../counts/getTopLeveSectionWithTechnicalSheathsCount';
import { getCaseTechnicalSheathsDistributionByTopLevel } from '../../../../../cases/queries/technicalSheaths/distributeTechnicalSheaths/getCaseTechnicalSheathsDistributionByTopLevel';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { getTopLevelSectionsWithTechnicalSheaths } from '../../sections/getTopLevelSectionsWithTechnicalSheaths';

export const getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurfaceDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): string => {
  const technicalSheathCount = getTopLevelLodgmentsTechnicalSheathsCount(
    caseGranulometry,
    levelGranulometry
  );
  const sectionsWithTechnicalSheathsCount =
    getTopLevelSectionsWithTechnicalSheathsCount(levelGranulometry);
  const technicalSheathSDistribution =
    getCaseTechnicalSheathsDistributionByTopLevel(caseGranulometry)
      .find((count) => count.levelIndex === levelGranulometry.level)
      ?.sheathsDistribution.reduce(
        (acc, sD, index) => [
          ...acc,
          '<br />&nbsp;&nbsp;&nbsp;&nbsp;-> ' +
            (levelGranulometry.content
              ? getTopLevelSectionsWithTechnicalSheaths(levelGranulometry)[index].title + ' : '
              : '') +
            sD
        ],
        [] as string[]
      )
      .join(' gaines, ') || 'ERR!';
  return (
    '<b>' +
    LODGMENT_TECHNICAL_SHEATH_WIDTH +
    ' m</b> de largeur de gaine' +
    '<br /><b>* ' +
    LODGMENT_TECHNICAL_SHEATH_DEPTH +
    ' m</b> de profondeur de gaine' +
    '<br /><b>* ' +
    technicalSheathCount +
    ' gaines</b><br />&nbsp;&nbsp;&nbsp;&nbsp;<i>distribuée' +
    (technicalSheathCount > 1 ? 's' : '') +
    ' dans ' +
    sectionsWithTechnicalSheathsCount +
    ' section' +
    (sectionsWithTechnicalSheathsCount > 1 ? 's' : '') +
    ' dans le niveau :' +
    technicalSheathSDistribution +
    ' gaines</i><br /><b>= ' +
    roundWith2Decimal(
      getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface(
        caseGranulometry,
        levelGranulometry
      ).value
    ) +
    ' m\u00B2</b>'
  );
};
