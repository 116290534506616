import * as R from 'ramda';
import {
  LevelGranulometry,
  SectionInLevelGranulometryFullFilledContent
} from '../../LevelGranulometry';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import { getSectionId } from '../../../sections/queries/getSectionId';
import * as RA from 'ramda-adjunct';
import { FundationsSection } from '../../../sections/fundationsSections/FundationsSection';
import { RoofingSection } from '../../../sections/roofingSections/RoofingSection';

export const setLevelSectionsIds = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): LevelGranulometry =>
  R.pipe<[LevelGranulometry], LevelGranulometry, LevelGranulometry, LevelGranulometry>(
    R.ifElse(
      R.has('content'),
      R.over<LevelGranulometry, SectionInLevelGranulometryFullFilledContent[]>(
        R.lensProp('content'),
        RA.reduceIndexed(
          (
            acc: SectionInLevelGranulometryFullFilledContent[],
            section: SectionInLevelGranulometryFullFilledContent,
            index
          ) => [
            ...acc,
            { ...section, id: getSectionId(caseGranulometry, levelGranulometry, index) }
          ],
          []
        )
      ),
      R.identity
    ),
    R.ifElse(
      R.has('roofing'),
      R.over<LevelGranulometry, RoofingSection[]>(
        R.lensProp('roofing'),
        RA.reduceIndexed(
          (acc: RoofingSection[], section: RoofingSection, index) => [
            ...acc,
            { ...section, id: getSectionId(caseGranulometry, levelGranulometry, index) }
          ],
          []
        )
      ),
      R.identity
    ),
    R.ifElse(
      R.has('fundations'),
      R.over<LevelGranulometry, FundationsSection[]>(
        R.lensProp('fundations'),
        RA.reduceIndexed(
          (acc: FundationsSection[], section: FundationsSection, index) => [
            ...acc,
            { ...section, id: getSectionId(caseGranulometry, levelGranulometry, index) }
          ],
          []
        )
      ),
      R.identity
    )
  )(levelGranulometry);
