import { CircleLayer, FillLayer, LineLayer, SymbolLayer } from 'mapbox-gl';
import {
  DRAW_HELPER_MOVING_FEATURE,
  DRAW_HELPER_MOVING_FEATURE_STYLE_LAYER
} from '../../drawHelper/drawHelper.model';
import { ROTATE_LEVEL_MODE } from '../../mode/RotateLevelMode';
import { INFO_COLOR, VALID_COLOR } from '../../../../constants/appConstants';

const forMovingLayer = ['==', 'user_type', DRAW_HELPER_MOVING_FEATURE];
export const movingLayerFilterActive = [
  'all',
  forMovingLayer,
  ['==', 'active', 'true'],
  ['==', '$type', 'Polygon'],
  ['!=', 'user_type', 'overlay']
];
export const movingLayerFilterInActive = [
  'all',
  forMovingLayer,
  ['==', 'active', 'false'],
  ['==', '$type', 'Polygon'],
  ['!=', 'user_type', 'overlay'],
  ['!=', 'mode', 'static']
];
export const drawHelperMovingLayerStyle: (FillLayer | LineLayer | SymbolLayer | CircleLayer)[] = [
  {
    id: DRAW_HELPER_MOVING_FEATURE_STYLE_LAYER + '-inactive',
    type: 'fill',
    filter: movingLayerFilterInActive,
    paint: {
      'fill-color': ['case', ['==', ['get', 'mode'], ROTATE_LEVEL_MODE], VALID_COLOR, '#fff'],
      'fill-outline-color': [
        'case',
        ['==', ['get', 'mode'], ROTATE_LEVEL_MODE],
        VALID_COLOR,
        INFO_COLOR
      ],
      'fill-opacity': 0.4
    }
  },
  {
    id: DRAW_HELPER_MOVING_FEATURE_STYLE_LAYER + '-active',
    type: 'fill',
    filter: movingLayerFilterActive,
    paint: {
      'fill-color': INFO_COLOR,
      'fill-outline-color': INFO_COLOR,
      'fill-opacity': 0.1
    }
  }
];
