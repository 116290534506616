import * as R from 'ramda';
import { createSelector, Selector } from 'reselect';
import { selectPath } from '../../getPath.selector';
import { selectCurrentCaseProjection } from '../../../projection/currentCase/projection.selector';
import { LevelSpecification } from '../../../../../domain/specification/levels/LevelSpecification';
import { State } from '../../../../reducers';
import { Maybe } from '../../../../../utils/Maybe';
import { caseGranulometryEditLevelGeometryRoute } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditLevelGeometryRoute';
import { caseGranulometryEditLevelDetailsRoute } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditLevelDetailsRoute';

export const selectCurrentlyEditedLevelSpecification: Selector<
  State,
  Maybe<LevelSpecification>
> = createSelector([selectCurrentCaseProjection, selectPath], (caseProjection, path) => {
  if (
    caseProjection === undefined ||
    caseProjection.projectedTopLevels === undefined ||
    caseProjection.projectedBasementLevels === undefined
  ) {
    return undefined;
  } else {
    return (
      [...caseProjection.projectedTopLevels, ...caseProjection.projectedBasementLevels].find(
        R.propEq('id', caseGranulometryEditLevelDetailsRoute.matchesPath(path).params?.levelId)
      ) ||
      [...caseProjection.projectedTopLevels, ...caseProjection.projectedBasementLevels].find(
        R.propEq('id', caseGranulometryEditLevelGeometryRoute.matchesPath(path).params?.levelId)
      ) ||
      undefined
    );
  }
});
