import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../LevelGranulometry';
import { getTopLevelRealBuiltSurfaceEff } from '../surfaces/realBuiltSurface/getTopLevelRealBuiltSurfaceEff';
import { hasCaseGranulometryGroundLevelDrawnGeometry } from '../../../../cases/queries/has/hasCaseGranulometryGroundLevelDrawnGeometry';
import { getTopLevelGrossFloorSurfaceEff } from '../surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurfaceEff';

export const LARGE_TOP_LEVEL_DEFAULT_WIDTH = 13.5; // m
export const SMALL_TOP_LEVEL_DEFAULT_WIDTH = 9; // m

export const getTopLevelDefaultWidth = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled | LevelGranulometry
): number => {
  if (hasCaseGranulometryGroundLevelDrawnGeometry(caseGranulometry)) {
    return getTopLevelGrossFloorSurfaceEff(caseGranulometry, levelGranulometry) <= 200
      ? SMALL_TOP_LEVEL_DEFAULT_WIDTH
      : LARGE_TOP_LEVEL_DEFAULT_WIDTH;
  } else {
    return getTopLevelRealBuiltSurfaceEff(caseGranulometry, levelGranulometry) <= 200
      ? SMALL_TOP_LEVEL_DEFAULT_WIDTH
      : LARGE_TOP_LEVEL_DEFAULT_WIDTH;
  }
};
