import { createSelector, Selector } from 'reselect';
import * as R from 'ramda';
import { CostByCase } from '../../../../domain/report/queries/getCostByBuildingAndCase';
import { BuildingId } from '../../../../domain/specification/buildings/BuildingSpecification';
import { CaseId } from '../../../../domain/specification/cases/CaseSpecification';
import { ifDefined } from '../../../../utils/ifDefined';
import { selectParkingsCountByBuildingAndCase } from './parkingsCountByBuildingAndCase.selector';
import { State } from '../../../../store/reducers';
import { Maybe } from '../../../../utils/Maybe';

type ParkingsCountByCase = Maybe<Record<string, number>>;

export const selectParkingsCountByCase: Selector<State, ParkingsCountByCase> = createSelector(
  [selectParkingsCountByBuildingAndCase],
  ifDefined(
    R.pipe<
      [Record<BuildingId, Record<CaseId, number>>],
      Record<CaseId, number>[],
      Record<CaseId, number>
    >(R.values, R.reduce<CostByCase, CostByCase>(R.mergeRight, {}))
  )
);
