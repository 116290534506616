import * as React from 'react';
import { FormEvent } from 'react';
import { push } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/ui/Button';
import { ExistingProject } from '../../../domain/project/Project';
import { FormGroup } from '../../../components/ui/FormGroup';
import { Input } from '../../ui/input/Input';
import { Option, Select } from '../../../components/ui/Select';
import { ProjectDialog } from './ProjectDialog';
import { SpecialLabel } from '../../../components/ui/SpecialLabel';
import { Form } from '../../../components/ui/Form';
import { selectProjects } from '../../../store/selectors/project/projects.selector';
import { copyProject } from '../../../store/actions/copyProject.action';

const projectToOption = (project: ExistingProject): Option => ({
  label: project.name,
  value: project.id
});

const DuplicateProjectView: React.FC = () => {
  const projects = useSelector(selectProjects);

  const dispatch = useDispatch();

  const backToProjects = React.useCallback(() => {
    dispatch(push('/projects'));
  }, [dispatch]);

  const [source, setSource] = React.useState<ExistingProject | undefined>(undefined);
  const [newName, setNewName] = React.useState<string | undefined>(undefined);

  const { t } = useTranslation();

  const titleOption: Option = { value: '', label: t('Select project') };

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (source) {
      const saveAsName = newName || `${t('Copy of')} ${source.name}`;
      dispatch(copyProject(source.id, saveAsName));
    }
  };

  const selectOption = React.useCallback(
    (option: Option) => {
      setSource(projects.find((p) => p.id === option.value));
    },
    [setSource, projects]
  );

  const handleChangeName = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => setNewName(event.target.value),
    [setNewName]
  );

  return (
    <ProjectDialog className="duplicate-project-form">
      <SpecialLabel label={t('Duplicate a project')} />
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <label htmlFor="selected">{t('Project')}</label>
          <Select
            id={'selected'}
            options={[titleOption, ...projects.map(projectToOption)]}
            handleChange={selectOption}
            value={source ? projectToOption(source) : titleOption}
            disabled={!projects.length}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="newName">{t('New name')}</label>
          <Input name="newName" type="text" handleChange={handleChangeName} />
        </FormGroup>
        <FormGroup spaceBetween={true} horizontal={true}>
          <Button handleClick={backToProjects} content={t('Back')} />
          <Button isSubmit={true} content={t('Duplicate this project')} disabled={!source?.id} />
        </FormGroup>
      </Form>
    </ProjectDialog>
  );
};

export default DuplicateProjectView;
