import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { getTopLevelRealBuiltSurfaceEff } from '../realBuiltSurface/getTopLevelRealBuiltSurfaceEff';
import { getTopLevelRealOutsideWallsSurface } from '../../walls/realWalls/getTopLevelRealOutsideWallsSurface';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { isProjectInputModeCbs } from '../../../../../../specification/project/queries/is/isProjectInputModeCbs';

export const getTopLevelGrossFloorSurfaceEff = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled | LevelGranulometry
): number => {
  if (isProjectInputModeCbs(caseGranulometry.initialSpecifications)) {
    return (
      getTopLevelRealBuiltSurfaceEff(caseGranulometry, levelGranulometry) +
      getTopLevelRealOutsideWallsSurface(caseGranulometry, levelGranulometry)
    );
  } else {
    return levelGranulometry.grossFloorSurfaceEff || 0;
  }
};
