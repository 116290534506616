import { createLevelFundationsSection } from '../sections/create/createLevelFundationsSection';
import { LevelGranulometry } from '../../LevelGranulometry';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import { getLevelId } from '../getLevelId';
import { setLevelSectionsIds } from '../sections/setLevelSectionIds';
export const createFundationsLevel = (
  caseGranulometry: CaseGranulometry,
  levelAbove: LevelGranulometry
): LevelGranulometry =>
  setLevelSectionsIds(caseGranulometry, {
    id: getLevelId(caseGranulometry, levelAbove.level - 1),
    level: levelAbove.level - 1,
    surface: levelAbove.displayedSurface,
    displayedSurface: levelAbove.displayedSurface,
    ceilingHeight: 1.5,
    floorThickness: 0.3,
    wallThickness: 0,
    fundations: [createLevelFundationsSection(levelAbove.displayedSurface, 'superficial')]
  } as LevelGranulometry);
