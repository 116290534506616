import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormGroup } from 'components/ui/FormGroup';
import { MAXIMUM_PROJECT_CBS, MINIMUM_PROJECT_CBS } from '../../../../../../domain/project/Project';
import { Input } from '../../../../../ui/input/Input';
import { Surface } from '../../../../../../domain/specification/Surface';
import { projectCbsIsValid } from '../../../../../../domain/checks/project/projectCbsIsValid';
import { selectProjectCbs } from '../../../../../../store/selectors/project/projectCbs.selector';
import { projectSurfaceChanged } from '../../../../../../store/actions/projectSurfaceChanged.action';

export const ProjectCbsForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projectCuttedBuiltSurface = useSelector(selectProjectCbs);

  const handleProjectCuttedBuiltSurfaceChange = (cuttedBuiltSurface: Surface | undefined) => {
    if (cuttedBuiltSurface !== undefined && cuttedBuiltSurface.value < MINIMUM_PROJECT_CBS) {
      return false;
    }
    dispatch(projectSurfaceChanged(cuttedBuiltSurface));
  };

  return (
    <FormGroup horizontal={true} wrap={true}>
      <Input
        label={t('projectSurface.cbs.inputLabel')}
        style={{ width: '40%', flex: '0 0 40%' }}
        name="projectSurface"
        type="number"
        value={projectCuttedBuiltSurface ? projectCuttedBuiltSurface.value : undefined}
        step={10}
        handleChange={(value) => {
          handleProjectCuttedBuiltSurfaceChange(
            value === null || value === 0 ? undefined : new Surface(value)
          );
        }}
        color={projectCbsIsValid(projectCuttedBuiltSurface) ? undefined : 'orange'}
        suffix="m<sup>2</sup>"
        minMax={{
          min: MINIMUM_PROJECT_CBS,
          max: MAXIMUM_PROJECT_CBS,
          unit: 'm\u00B2'
        }}
      />
    </FormGroup>
  );
};
