import * as React from 'react';
import * as R from 'ramda';
import { ReportEntry, reportEntryLabels } from '../../../../../../domain/report/ReportEntry';
import { Column } from 'react-table';
import { createFilter } from '../createFilter';
import { reportBuildingList } from '../reportEntries/reportBuildingList';
import { reportCaseList } from '../reportEntries/reportCaseList';
import { reportLevelList } from '../reportEntries/reportLevelList';
import { reportSectionList } from '../reportEntries/reportSectionList';
import { filterRowsAndPivotsThroughSelect } from '../filters/filterRowsAndPivotsThroughSelect';
import classNames from 'classnames';

export const getPositionColumns = (report: ReportEntry[], foldedColumns: string[]): Column => ({
  Header: 'Emplacement',
  columns: [
    {
      Header: reportEntryLabels.building,
      accessor: 'building',
      filterMethod: filterRowsAndPivotsThroughSelect,
      Filter: createFilter(reportBuildingList(report)),
      aggregate: (values) => R.uniq(values).join(', '),
      Aggregated: () => '',
      sortable: false,
      resizable: false,
      width: 80,
      className: classNames({
        folded: foldedColumns.indexOf('Bâtiment') > -1
      }),
      headerClassName: classNames({
        folded: foldedColumns.indexOf('Bâtiment') > -1
      })
    },
    {
      Header: reportEntryLabels.case,
      accessor: 'case',
      filterMethod: filterRowsAndPivotsThroughSelect,
      Filter: createFilter(reportCaseList(report)),
      aggregate: (values) => R.uniq(values).join(', '),
      Aggregated: () => '',
      sortable: false,
      resizable: false,
      width: 80,
      className: classNames({
        folded: foldedColumns.indexOf('Cage') > -1
      }),
      headerClassName: classNames({
        folded: foldedColumns.indexOf('Cage') > -1
      })
    },
    {
      Header: reportEntryLabels.level,
      accessor: 'level',
      filterMethod: filterRowsAndPivotsThroughSelect,
      Filter: createFilter(reportLevelList(report)),
      aggregate: (values) => R.uniq(values).join(', '),
      Aggregated: () => '',
      sortable: false,
      resizable: false,
      width: 80,
      className: classNames({
        folded: foldedColumns.indexOf('Niveau') > -1
      }),
      headerClassName: classNames({
        folded: foldedColumns.indexOf('Niveau') > -1
      })
    },
    {
      Header: reportEntryLabels.section,
      accessor: 'section',
      filterMethod: filterRowsAndPivotsThroughSelect,
      Filter: createFilter(reportSectionList(report)),
      aggregate: (values) => R.uniq(values).join(', '),
      Aggregated: () => '',
      sortable: false,
      resizable: false,
      width: 100,
      className: classNames({
        folded: foldedColumns.indexOf('Section') > -1
      }),
      headerClassName: classNames({
        folded: foldedColumns.indexOf('Section') > -1
      })
    }
  ]
});
