import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../../../../ui/FormGroup';
import { Input } from '../../../../../ui/input/Input';
import { casePropertyChanged } from '../../../../../../store/actions/casePropertyChanged.action';
import { IconObject } from '../../../../../ui/Icons/iconObject';
import { CaseId } from '../../../../../../domain/specification/cases/CaseSpecification';
import { Property } from '../../../../../../domain/specification/cases/Property';

interface MasonryOutsideWallTypeRatesProps {
  caseId: CaseId;
  outsideWallMasonryRateProperty: Property;
  outsideWallMasonryRateMaxValue: number;
  outsideWallTypeRatesValidity: boolean;
}

export const MasonryOutsideWallTypeRates = ({
  caseId,
  outsideWallMasonryRateProperty,
  outsideWallMasonryRateMaxValue,
  outsideWallTypeRatesValidity
}: MasonryOutsideWallTypeRatesProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Masonry :
  const handleOutsideWallMasonryRateChange = React.useCallback(
    (newOutsideWallMasonryRate: number | null) => {
      if (
        caseId &&
        outsideWallMasonryRateProperty &&
        (typeof newOutsideWallMasonryRate === 'number' || newOutsideWallMasonryRate === null)
      ) {
        dispatch(
          casePropertyChanged(caseId, {
            ...outsideWallMasonryRateProperty,
            value: newOutsideWallMasonryRate ?? undefined
          })
        );
      }
    },
    [dispatch, caseId, outsideWallMasonryRateProperty]
  );

  return (
    <FormGroup horizontal={true} wrap={true}>
      <Input
        style={{ width: '40%', flex: '0 0 40%' }}
        name={`case${caseId}OutsideWallMasonryRate`}
        label={t('outsideWallTypeRates.masonryRate.label')}
        info={t('outsideWallTypeRates.masonryRate.info')}
        type="number"
        min={0}
        max={100}
        minMax={{
          min: outsideWallMasonryRateProperty.min,
          max: outsideWallMasonryRateMaxValue,
          unit: outsideWallMasonryRateProperty.unit
        }}
        color={outsideWallTypeRatesValidity ? undefined : 'orange'}
        placeholder={outsideWallMasonryRateProperty.defaultValue}
        value={outsideWallMasonryRateProperty.value}
        handleChange={handleOutsideWallMasonryRateChange}
        suffix={outsideWallMasonryRateProperty.unit}
        icon={<IconObject type="menu" iconName="chipboard" clickable={false} />}
      />
    </FormGroup>
  );
};
