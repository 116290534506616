import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelLodgmentsTechnicalSheathsCount } from '../../counts/getTopLevelLodgmentsTechnicalSheathsCount';
import { Surface } from '../../../../../../specification/Surface';

export const LODGMENT_TECHNICAL_SHEATH_WIDTH = 0.6;
export const LODGMENT_TECHNICAL_SHEATH_DEPTH = 0.8;

export const getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry | LevelGranulometryWithBearingRelativeSections
): Surface =>
  new Surface(
    LODGMENT_TECHNICAL_SHEATH_WIDTH *
      LODGMENT_TECHNICAL_SHEATH_DEPTH *
      getTopLevelLodgmentsTechnicalSheathsCount(caseGranulometry, levelGranulometry)
  );
