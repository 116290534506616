import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { LevelGeometry } from './levelGeometry';
import * as R from 'ramda';
import mapboxgl, { SymbolLayer } from 'mapbox-gl';
import { Feature, Polygon } from 'geojson';
import { normalizeSourceCoordinates } from '../utils/geometry/coordinates';
import { getMarkerFeatures } from './utils/getMarkerFeatures';
import { FACADE_DRAW_LAYER, FACADE_DRAW_SOURCE } from './draw.model';
import path from 'path';
import { OTHERS_COLOR } from '../../../constants/appConstants';

type AnyProperties<T = any> = { [name: string]: any } & T;

export interface FeatureTarget extends Feature {
  layer: AnyProperties<{ id: string }>;
  _geometry: Polygon;
  properties: AnyProperties<{
    id: string;
    mode: string;
    user_area: number;
    user_deactivate: boolean;
    user_basement: boolean;
    user_perimeter: number;
    active: boolean;
    user_name: string;
  }>;
  source: string;
}

export type FeatureWithId = Feature & { id: string; properties: AnyProperties };
export type FeaturePolygonWithId = Feature<Polygon> & {
  id: string;
  properties: AnyProperties;
};

export class DrawRepo {
  map: mapboxgl.Map;
  draw: MapboxDraw;
  constructor(map: mapboxgl.Map, draw: MapboxDraw) {
    this.map = map;
    this.draw = draw;
  }

  updateProperties(feature: LevelGeometry) {
    R.mapObjIndexed((value, key) => {
      this.draw.setFeatureProperty(feature.id, key, value);
    })(feature?.properties as unknown as Record<string, unknown>);
    this.updateMarkerFacades(feature);
  }

  reDraw(feature: FeaturePolygonWithId) {
    this.draw.delete(feature.id);
    feature.geometry.coordinates[0] = normalizeSourceCoordinates(feature.geometry.coordinates[0]);
    this.saveOrUpdate(feature);
  }

  saveOrUpdate(geojson: LevelGeometry) {
    this.draw.add(geojson);
    this.updateMarkerFacades(geojson);
  }

  removeFacade(floorSpaces: FeatureWithId[]) {
    floorSpaces.forEach(
      (floorSpace) =>
        floorSpace.id &&
        this.map.getSource('facades_markers_' + floorSpace.id) &&
        this.map.getLayer('facades_markers_layer_' + floorSpace.id) &&
        this.map.removeLayer('facades_markers_layer_' + floorSpace.id) &&
        this.map.removeSource('facades_markers_' + floorSpace.id)
    );
  }

  findAll() {
    return this.draw.getAll() as unknown as { features: LevelGeometry[] };
  }

  updateMarkerFacades(floorSpace: LevelGeometry) {
    const features = getMarkerFeatures(floorSpace);

    const sourceId = FACADE_DRAW_SOURCE + floorSpace.id;
    const layerId = FACADE_DRAW_LAYER + floorSpace.id;
    if (!this.map.getSource(sourceId)) {
      this.map.loadImage(path.resolve('facadeMarker.png'), (error, image) => {
        if (error) {
          console.error('Erreur update marker facade : ', error);
        } else {
          if (!this.map.listImages()?.find((name) => name === 'facadeMarker')) {
            // TODO to refactor do not loadImage if image already exist
            this.map.addImage('facadeMarker', image as HTMLImageElement | ImageBitmap);
          }
        }

        this.map.addSource(sourceId, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features
          }
        });

        this.map.addLayer({
          id: layerId,
          type: 'symbol',
          source: sourceId,
          layout: {
            'text-field': ['get', 'name'],
            'text-size': ['match', ['get', 'type'], 'facade', 12, 20],
            'icon-image': 'facadeMarker',
            'icon-size': 0.25,
            'text-radial-offset': 0.5
          },
          paint: {
            'text-color': ['match', ['get', 'type'], 'facade', OTHERS_COLOR, '#fff'],
            'icon-opacity': ['match', ['get', 'type'], 'facade', 1, 0]
          }
        } as SymbolLayer);
      });
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.map.getSource(sourceId).setData({
        type: 'FeatureCollection',
        features
      });
    }
  }

  /* findMovingFeatures() {
    return this.draw
      .getAll()
      ?.features.filter((feature) => feature.properties?.type === DRAW_HELPER_MOVING_FEATURE);
  } */
}
