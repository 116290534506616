import { Nomenclature } from '../Nomenclature';

export const getNomenclatureElementTitleFromRef = (
  nomenclature: Nomenclature,
  reference: string
) => {
  const nomenclatureElement = nomenclature.elements.find(
    (element) => element.reference === reference
  );
  return nomenclatureElement ? nomenclatureElement.title : '';
};
