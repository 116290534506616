import { createSelector, Selector } from 'reselect';
import { State } from '../../../../store/reducers';

import { selectCarParksCount } from './carParksCount.selector';
import { selectCarBoxesCount } from './carBoxesCount.selector';

export const selectTotalParkingsCount: Selector<State, number> = createSelector(
  [selectCarBoxesCount, selectCarParksCount],
  (carBoxesCount, carParksCount) => (carBoxesCount || 0) + (carParksCount || 0)
);
