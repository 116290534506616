import { getSectionName, Section, SectionDrawn, SectionWithMinimumSurface } from '../Section';
import { LodgmentType } from '../../../specification/lodgmentTypes/LodgmentType';
import * as R from 'ramda';
import { SectionWithTheoreticalSurface } from '../SectionWithTheoreticalSurface';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { RoomSpecification } from '../../../specification/rooms/RoomSpecification';
import { t1 } from './t1';
import { t2c } from './t2c';
import { t2 } from './t2';
import { t3c } from './t3c';
import { t3 } from './t3';
import { t4c } from './t4c';
import { t4 } from './t4';
import { t5 } from './t5';
import { HeatingSystem } from '../../../specification/lodgmentTypes/HeatingSystem';
import { SectionWithTechnicalSheaths } from '../SectionWithTechnicalSheaths';

export interface LodgmentSection
  extends Section,
    SectionDrawn,
    SectionWithTheoreticalSurface,
    SectionWithMinimumSurface,
    SectionWithExposureRate,
    SectionWithTechnicalSheaths {
  name: LodgmentType;
  rooms: RoomSpecification[];
  lodgmentType: LodgmentType;
  heatingSystem?: HeatingSystem;
  defaultHeatingSystem: HeatingSystem;
}

type GetLodgmentType = (lodgment: LodgmentSection) => LodgmentType;
export const getLodgmentType: GetLodgmentType = R.prop('name');

type GetLodgmentSurface = (lodgment: LodgmentSection) => number;
export const getLodgmentSurface: GetLodgmentSurface = R.prop('displayedSurface');

export const isSectionALodgment = (section: Section): section is LodgmentSection =>
  (R.values(LodgmentType) as string[]).includes(getSectionName(section));

export const filterLodgments = (sections: Section[]): LodgmentSection[] =>
  sections.filter(isSectionALodgment);

export const defaultLodgmentsSections: { [key in LodgmentType]: LodgmentSection } = {
  t1: t1(),
  t2c: t2c(),
  t2: t2(),
  t3c: t3c(),
  t3: t3(),
  t4c: t4c(),
  t4: t4(),
  t5: t5()
};
