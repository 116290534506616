import { SectionWithTheoreticalSurface } from '../../SectionWithTheoreticalSurface';
import { getSectionTitle } from '../../Section';

export const getSectionTheoreticalSurfaceDetails = (
  section: SectionWithTheoreticalSurface
): string =>
  'Surface théorique<b>' +
  (section.theoreticalSurface
    ? ' saisie'
    : section.defaultTheoreticalSurface
    ? ' par défaut'
    : ' ERR!') +
  '</b> pour le type de section <i>' +
  getSectionTitle(section) +
  '</i>';
