import { getCaseLodgmentDistribution } from './getCaseLodgmentDistribution';
import { getCaseLodgmentData } from './getCaseLodgmentData';
import { getSectionTheoreticalSurface } from '../../../../granulometry/sections/queries/surfaces/getSectionTheoreticalSurface';
import { CaseProjection } from '../../CaseProjection';

export const getCaseAverageLodgmentSurface = (caseProjection: CaseProjection) => {
  const distribution = getCaseLodgmentDistribution(caseProjection);
  const lodgmentData = getCaseLodgmentData(caseProjection);
  return distribution.reduce(
    (acc, distrib) =>
      acc + getSectionTheoreticalSurface(lodgmentData[distrib.lodgmentType]) * (distrib.rate / 100),
    0
  );
};
