import * as R from 'ramda';
import { DataTypeValue } from '../../../../../domain/DataType';
import { ReportEntry } from '../../../../../domain/report/ReportEntry';
import { reportComEthIc } from './reportEntries/reportComEthIc';
import { reportCompositionValue } from './reportEntries/reportCompositionValue';
import { hasQuantityColumns } from './columns/hasQuantityColumns';
import { hasWoodCubatureColumns } from './columns/hasWoodCubatureColumns';
import { hasCostColumns } from './columns/hasCostColumns';
import { hasCo2Columns } from './columns/hasCo2Columns';

const hasToBeRemoved = (
  reportEntry: ReportEntry,
  dataTypeValue: DataTypeValue | undefined,
  apiVersion: 1 | 2
): boolean =>
  (dataTypeValue === 'co2' && reportComEthIc(reportEntry) === 0) ||
  (dataTypeValue === 'wood-cubature' && reportCompositionValue(reportEntry) === 0) ||
  reportEntry.apiVersion !== apiVersion;

const getOptimisedReportEntry = (
  reportEntry: ReportEntry,
  dataTypeValue: DataTypeValue | undefined
): ReportEntry => {
  return R.pipe<[ReportEntry], ReportEntry, ReportEntry, ReportEntry, ReportEntry, ReportEntry>(
    (rE) => R.omit(['id', 'refN1', 'refN2', 'caseId', 'buildingId'], rE) as ReportEntry,
    R.when(
      () => !(hasQuantityColumns(dataTypeValue) || hasWoodCubatureColumns(dataTypeValue)),
      (rE) => R.omit(['quantity', 'unit'], rE) as ReportEntry
    ),
    R.when(
      () => !hasCostColumns(dataTypeValue),
      (rE) => R.omit(['price', 'unitPrice', 'productId'], rE) as ReportEntry
    ),
    R.when(
      () => !hasCo2Columns(dataTypeValue),
      (rE) =>
        R.omit(
          ['co2Id', 'co2Type', 'co2Title', 'co2Quantity', 'co2QuantityUnit', 'comEthIc'],
          rE
        ) as ReportEntry
    ),
    R.when(
      () => !hasWoodCubatureColumns(dataTypeValue),
      (rE) => R.omit(['compositionValue'], rE) as ReportEntry
    )
  )(reportEntry);
};

export const getOptimisedReportForTable = (
  report: ReportEntry[],
  dataTypeValue: DataTypeValue | undefined,
  apiVersion: 1 | 2
): ReportEntry[] =>
  R.reduce<ReportEntry, ReportEntry[]>(
    (acc, reportEntry) =>
      hasToBeRemoved(reportEntry, dataTypeValue, apiVersion)
        ? acc
        : [...acc, getOptimisedReportEntry(reportEntry, dataTypeValue)],
    []
  )(report);
