import { createSelector, Selector } from 'reselect';
import { State } from '../../../store/reducers';
import { selectUser } from './user.selector';
import { ifDefined } from '../../../utils/ifDefined';

type UserLicenceType = 'Access' | 'Advanced' | undefined;

export const selectUserLicenceType: Selector<State, UserLicenceType> = createSelector(
  [selectUser],
  ifDefined((user) => user.account.licence.type)
);
