import { getCaseLodgmentDistribution } from './getCaseLodgmentDistribution';
import { getTopLevelRealBuiltSurface } from '../../../../granulometry/levels/queries/topLevels/surfaces/realBuiltSurface/getTopLevelRealBuiltSurface';
import { getTopLevelSurfaceForSale } from '../../../../granulometry/levels/queries/topLevels/surfaces/surfaceForSale/getTopLevelSurfaceForSale';
import { LevelSFSAdjusment } from './fillCaseLevels';
import { LodgmentType } from '../../../../specification/lodgmentTypes/LodgmentType';
import { getSectionsTotalSurface } from '../../../../granulometry/sections/queries/surfaces/getSectionsTotalSurface';
import { LevelGranulometryInitialEntries } from '../../../../granulometry/levels/LevelGranulometry';
import { BasementLevelSpecification } from '../../../../specification/levels/BasementLevelSpecification';
import { CaseProjection } from '../../CaseProjection';
import { TopLevelSpecification } from '../../../../specification/levels/TopLevelSpecification';
import { getBasementLevelRealBuiltSurface } from '../../../../granulometry/levels/queries/basementLevels/surfaces/getBasementLevelRealBuiltSurface';

export interface InitialFilledLevelsSpecification {
  level: number;
  realBuiltSurface: number;
  surfaceForSale: number;
  remainingSurfaceForSale: number;
  // filledSurface: number;
  lodgmentCount: number;
  lodgments: { [key in LodgmentType]: [] } | object;
}

/* Get the initial levels data in the given case */
export const getInitialFilledLevelSpecifications = (
  caseProjection: CaseProjection,
  levelsSFSAdjustments?: LevelSFSAdjusment[]
): (InitialFilledLevelsSpecification | BasementLevelSpecification)[] => {
  const distribution = getCaseLodgmentDistribution(caseProjection);
  const initialLevelsData = [];

  // For each top level
  for (let i = 0; i < caseProjection.projectedTopLevels.length; i++) {
    const levelIndex = caseProjection.projectedTopLevels[i].level;
    const technicalPremiseSections = caseProjection.projectedTopLevels[i].technicalPremiseSections;
    const initialFilledLevelsSpecification: InitialFilledLevelsSpecification = {
      ...caseProjection.projectedTopLevels[i],
      realBuiltSurface: getTopLevelRealBuiltSurface(
        { level: levelIndex } as TopLevelSpecification,
        caseProjection
      ), // TODO : To type for both contexts : before and after granulo
      surfaceForSale: getTopLevelSurfaceForSale(caseProjection, {
        level: levelIndex,
        technicalPremiseSections
      } as LevelGranulometryInitialEntries),
      remainingSurfaceForSale: 0,
      // filledSurface: 0,
      lodgmentCount: 0,
      lodgments: {}
    };
    // SFS adjustment due of minimum bearing surface overriding (cf. fillCaseLevels.ts)
    if (levelsSFSAdjustments !== undefined) {
      const adjustment = levelsSFSAdjustments.find(
        (levelsSFSAdjustment) =>
          levelsSFSAdjustment.level === initialFilledLevelsSpecification.level
      )?.adjustment;
      initialFilledLevelsSpecification.surfaceForSale += adjustment || 0;
    }
    const commonPremiseSectionsSurface = getSectionsTotalSurface(
      caseProjection.projectedTopLevels[i].commonPremiseSections || []
    );
    const shopSectionsSurface = getSectionsTotalSurface(
      caseProjection.projectedTopLevels[i].shopSections || []
    );
    const officeSectionsSurface = getSectionsTotalSurface(
      caseProjection.projectedTopLevels[i].officeSections || []
    );
    initialFilledLevelsSpecification.remainingSurfaceForSale =
      initialFilledLevelsSpecification.surfaceForSale -
      commonPremiseSectionsSurface -
      shopSectionsSurface -
      officeSectionsSurface;
    // levelData.filledSurface = 0;
    initialFilledLevelsSpecification.lodgmentCount = 0;
    initialFilledLevelsSpecification.lodgments = distribution.reduce(
      (acc, distributedLodgmentSection) => ({
        ...acc,
        [distributedLodgmentSection.lodgmentType]: []
      }),
      {}
    );
    initialLevelsData[i] = initialFilledLevelsSpecification;
  }

  // For each basement level
  for (let i = 0; i < caseProjection.projectedBasementLevels.length; i++) {
    initialLevelsData.push({
      ...caseProjection.projectedBasementLevels[i],
      realBuiltSurface:
        caseProjection.projectSurfaceInputMode === 'gfsEff'
          ? getBasementLevelRealBuiltSurface(
              caseProjection.projectedBasementLevels[i],
              caseProjection
            )
          : undefined
    });
  }

  return initialLevelsData;
};
