import elevatorShaftInfra, {
  ElevatorShaftInfraSection
} from '../../../../../sections/circulationSections/elevatorShaftInfra';
import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createBasementLevelElevatorShaftInfra = (
  caseGranulometry: CaseGranulometry,
  group: string
): ElevatorShaftInfraSection => {
  const length = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'elevatorShaftLength'
  ) as number;
  const width = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'elevatorShaftWidth'
  ) as number;
  const surface = length * width;
  const doorHeight = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'elevatorDoorHeight'
  ) as number;
  const doorWidth = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'elevatorDoorWidth'
  ) as number;
  return elevatorShaftInfra({
    surface,
    length,
    width,
    doorHeight,
    doorWidth,
    group
  });
};
