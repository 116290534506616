import * as R from 'ramda';
import { NonEmptyArray } from '../../utils/NonEmptyArray';
import {
  BuildingId,
  BuildingSpecification,
  hasId
} from '../specification/buildings/BuildingSpecification';
import { checkThatBuildingExists } from '../checks/buildings/buildingExists';
import { checkThatCanRemoveABuilding } from '../checks/buildings/buildingCanBeRemoved';
import { Project } from '../project/Project';
import { overBuildings } from '../project/over/overBuildings';

export const removeABuilding =
  (buildingId: BuildingId) =>
  (project: Project): Project => {
    checkThatBuildingExists(buildingId)(project);
    checkThatCanRemoveABuilding(project);

    return overBuildings(
      (buildings) => R.reject(hasId(buildingId), buildings) as NonEmptyArray<BuildingSpecification>
    )(project);
  };
