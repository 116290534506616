import * as R from 'ramda';
import { Granulometry } from '../Granulometry';
import { BuildingGranulometry } from '../buildings/BuildingGranulometry';
import { CaseGranulometry } from '../cases/CaseGranulometry';
import { getBuildingId } from '../buildings/queries/getBuildingId';
import { getCaseId } from '../cases/queries/get/getCaseId';
import {
  getLodgmentsSurfaceByLevelAndType,
  LodgmentsSurfaceByLevelAndType
} from '../cases/queries/sections/surfaces/getCaseLodgmentsSurfaceByLevelAndType';
import { BuildingId } from '../../specification/buildings/BuildingSpecification';
import { CaseId } from '../../specification/cases/CaseSpecification';

const extractFromCases = (
  cases: Record<string, CaseGranulometry>
): Record<string, LodgmentsSurfaceByLevelAndType> =>
  R.mapObjIndexed(getLodgmentsSurfaceByLevelAndType, cases);

const indexCases = (cases: CaseGranulometry[]): Record<CaseId, CaseGranulometry> =>
  R.indexBy(getCaseId, cases);

export type LodgmentsSurfaceByCaseLevelAndType = Record<CaseId, LodgmentsSurfaceByLevelAndType>;

export type LodgmentsSurfaceByBuildingCaseLevelAndType = Record<
  BuildingId,
  LodgmentsSurfaceByCaseLevelAndType
>;

const extractFromBuildings = (
  buildings: Record<BuildingId, BuildingGranulometry>
): LodgmentsSurfaceByBuildingCaseLevelAndType =>
  R.mapObjIndexed(({ cases }) => R.pipe(indexCases, extractFromCases)(cases))(buildings);

const indexBuildings = R.indexBy(getBuildingId);

export const getLodgmentsSurfaceByBuildingCaseLevelAndType = (
  granulometry: Granulometry
): LodgmentsSurfaceByBuildingCaseLevelAndType =>
  R.pipe(indexBuildings, extractFromBuildings)(granulometry);
