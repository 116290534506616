import { LevelPeripheralReinforcementsSpecifications } from '../../LevelGranulometry';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';

export const getLevelContiguousUnderBuiltLinearMaxValue = ({
  outsideWallsLinear,
  contiguousAgainstBuiltLinear,
  contiguousNotBuiltLinear
}: LevelPeripheralReinforcementsSpecifications): number =>
  Math.max(
    0,
    roundWith2Decimal(
      (outsideWallsLinear || 0) -
        (contiguousAgainstBuiltLinear || 0) -
        (contiguousNotBuiltLinear || 0)
    )
  );
