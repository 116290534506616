import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/ui/Button';
import { IconObject } from 'components/ui/Icons/iconObject';
import { undo } from '../../../store/actions/undo.action';
import { selectProjectIsValid } from '../../../store/selectors/validation/projectIsValid.selector';
import { selectIsSavingProject } from '../../../store/selectors/project/isSavingProject.selector';
import { selectIsLoadingProject } from '../../../store/selectors/project/isLoadingProject.selector';
import { selectIsProjectDirty } from '../../../store/selectors/project/isDirty.selector';
import { requestReport } from '../../../store/actions/report.actions';
import { selectIsReportOutdated } from '../../../store/selectors/report/isReportOutdated.selector';
import { selectProjectHasHistory } from '../../../store/selectors/project/projectHasHistory.selector';

const UndoOrSaveButtons: React.FC = () => {
  const projectValid = useSelector(selectProjectIsValid);
  const isDirty = useSelector(selectIsProjectDirty);
  const isSaving = useSelector(selectIsSavingProject);
  const isFetching = useSelector(selectIsLoadingProject);
  const isReportOutdated = useSelector(selectIsReportOutdated);
  const hasHistory = useSelector(selectProjectHasHistory);
  const isWaitingForBackend = isSaving || isFetching;

  const dispatch = useDispatch();

  const handleUndo = React.useCallback(() => {
    dispatch(undo());
  }, [dispatch]);
  const handleCompute = React.useCallback(() => {
    dispatch(requestReport());
  }, [dispatch]);

  const { t } = useTranslation();

  return (
    <>
      <Button
        disabled={isWaitingForBackend || !hasHistory}
        color="grey"
        handleClick={handleUndo}
        icon={<IconObject type="menu" iconName="undo" color="white" />}
      />
      <Button
        disabled={isWaitingForBackend || !projectValid}
        content={
          isWaitingForBackend ? t('Recalculating the project') : t('Recalculate the project')
        }
        icon={<IconObject type="menu" iconName="calculate" color="white" />}
        withLoader={isDirty || isReportOutdated}
        handleClick={handleCompute}
      />
    </>
  );
};

export default UndoOrSaveButtons;
