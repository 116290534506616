import { isBefore, subDays } from 'date-fns/fp';
import { pipe } from 'fp-ts/function';
import { getUserState, State } from '../../reducers';

export const selectIsSessionExpiredOrExpiringSoon = (state: State) => {
  const session = getUserState(state);

  return (
    (session.sessionState === 'AUTHENTICATED' || session.sessionState === 'EXPIRED') &&
    pipe(new Date(session.auth.expiration), subDays(1), isBefore(new Date()))
  );
};
