import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../ui/Card';
import { Window } from '../../../../ui/Window';
import { Form } from '../../../../ui/Form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { IconObject } from '../../../../ui/Icons/iconObject';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { Note } from '../../../../ui/Note';
import { CaseGranulometryEditOutdoorsRouteParams } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditOutdoorsRoute';
import { getCaseGranulometryEditOutdoorsTriggerId } from '../../../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditOutdoorsTriggerId';
import { goToCase } from '../../../../../store/actions/navigations/toolbox/casesPanel/goToCase.action';
import { goToCaseGranulometryEditEnergyAndNetworks } from '../../../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditEnergyAndNetworks.action';
import { goToCaseGranulometryEditOtherProperties } from '../../../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditOtherProperties.action';

export const CaseOutdoorsWindow = () => {
  const { projectId, caseId } = useParams<CaseGranulometryEditOutdoorsRouteParams>();
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickClose = React.useCallback(() => {
    if (caseId && projectId) dispatch(goToCase(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  const handleClickPrev = React.useCallback(() => {
    caseId && projectId && dispatch(goToCaseGranulometryEditEnergyAndNetworks(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  const handleClickNext = React.useCallback(() => {
    caseId && projectId && dispatch(goToCaseGranulometryEditOtherProperties(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  if (!caseLabel) return null;

  return (
    <Window
      title={t('Outdoors')}
      triggerId={getCaseGranulometryEditOutdoorsTriggerId(caseLabel)}
      topButtons={
        <>
          <IconObject iconName="arrowTop" type="menu" onClick={handleClickPrev} />
          <IconObject iconName="arrowBottom" type="menu" onClick={handleClickNext} />
          <IconObject iconName="close" type="menu" onClick={handleClickClose} />
        </>
      }>
      <Form>
        <Card>
          <Note>
            Ce panneau permettra de gérer l’administration de l’ensemble des ouvrages en lien avec
            l’aménagement de la parcelle et en dehors de la constitution du bâti. Il s’agira ici de
            piloter les aménagements en surface (voies de circulation, cheminement, stationnement de
            véhicules, vélo,...) ou en périphérie de la parcelle (clôture, portail, portillon,...)
          </Note>
        </Card>
      </Form>
    </Window>
  );
};

export default CaseOutdoorsWindow;
