import { createSelector, Selector } from 'reselect';
import { State } from '../../../reducers';
import { getBreadcrumbs } from '../../../../routes/breadcrumbs/getBreadcrumbs';
import { selectPath } from '../getPath.selector';
import { selectCurrentCaseLabel } from '../../project/currentCaseLabel.selector';
import { selectCurrentBuildingLabel } from '../../project/currentBuildingLabel.selector';
import { selectCurrentCaseProjection } from '../../projection/currentCase/projection.selector';

export const selectCurrentBreadcrumb: Selector<State, string[]> = createSelector(
  [selectPath, selectCurrentBuildingLabel, selectCurrentCaseLabel, selectCurrentCaseProjection],
  getBreadcrumbs
);
