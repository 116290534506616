import { CardTitle } from '../../../../../../ui/CardTitle';
import React from 'react';
import { Card } from '../../../../../../ui/Card';
import { FormGroup } from '../../../../../../ui/FormGroup';
import { Button } from '../../../../../../ui/Button';
import { Note } from '../../../../../../ui/Note';
import { StepState } from './SideDrawMode';
import { SpecialLabel } from '../../../../../../ui/SpecialLabel';

interface DrawCardProps {
  drawPolygon: () => void;
  removePolygon: () => void;
  step: StepState;
  goStepBack: () => void;
  goStepForward: () => void;
}
export const DrawCard = ({
  drawPolygon,
  removePolygon,
  step,
  goStepBack,
  goStepForward
}: DrawCardProps) => {
  return (
    <Card>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <CardTitle>2/ Tracez la forme à l'aide de votre PDF</CardTitle>
      <FormGroup horizontal={true}>
        {step.step2.displayDrawButton && (
          <div>
            <Button
              size={'small'}
              content={'Dessiner la forme'}
              handleClick={drawPolygon}
              appearance="outline"
            />
          </div>
        )}
        {!step.step2.displayDrawButton && (
          <div>
            <Button
              size={'small'}
              content={'Supprimer la forme'}
              handleClick={removePolygon}
              appearance="outline"
            />
          </div>
        )}
      </FormGroup>
      <FormGroup horizontal={true}>
        {step.step2.draw && (
          <div>
            <Button content={'Valider'} handleClick={goStepForward} appearance="plain" />
          </div>
        )}
        <div>
          <Button content={'Retour'} handleClick={goStepBack} appearance="outline" />
        </div>
      </FormGroup>
      {step.step2.draw && (
        <SpecialLabel label={`Nom de la forme : ${step.step2.draw.properties.name}`} />
      )}
      <Note>Appuyez vous de votre fichier pour décalquer au mieux la forme</Note>
    </Card>
  );
};
