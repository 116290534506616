import * as R from 'ramda';
import { Project } from '../../../../project/Project';
import { Surface } from '../../../Surface';
import { CaseSpecification } from '../../../cases/CaseSpecification';
import { MINIMUM_CASE_CBS } from '../../../../../constants/appConstants';
import { getProjectCasesSpecifications } from './getProjectCasesSpecifications';

export const getProjectCasesReservedCuttedBuiltSurface = (project: Project): Surface =>
  R.pipe(
    getProjectCasesSpecifications,
    R.map(
      R.pipe(
        (caseSpecification: CaseSpecification) => caseSpecification.surfaces.cuttedBuiltSurface,
        R.defaultTo(new Surface(MINIMUM_CASE_CBS))
      )
    ),
    Surface.sum
  )(project);
