import * as React from 'react';
import { Column4Inner } from './Column4';
import { BasementLevelSpecification } from '../../../../../domain/specification/levels/BasementLevelSpecification';

interface Column4EnlargedProps {
  basementLevelSpecification: BasementLevelSpecification;
  handleRealBuildSurfaceChange: (value: number | null) => void;
}

export const Column4Enlarged = ({
  basementLevelSpecification,
  handleRealBuildSurfaceChange
}: Column4EnlargedProps) => {
  return (
    <div className="column-4">
      <div className="cell">
        {process.env.GB_FEAT_GFS === 'true' && (
          <Column4Inner
            basementLevelSpecification={basementLevelSpecification}
            handleRealBuildSurfaceChange={handleRealBuildSurfaceChange}
            selectedLevelSurface={'grossFloorSurfaceEff'}
          />
        )}
      </div>
      <div className="cell">
        {process.env.GB_FEAT_GFS === 'true' && (
          <Column4Inner
            basementLevelSpecification={basementLevelSpecification}
            handleRealBuildSurfaceChange={handleRealBuildSurfaceChange}
            selectedLevelSurface={'grossFloorSurface'}
          />
        )}
      </div>
      <div className="cell">
        <Column4Inner
          basementLevelSpecification={basementLevelSpecification}
          handleRealBuildSurfaceChange={handleRealBuildSurfaceChange}
          selectedLevelSurface={'realBuiltSurface'}
        />
      </div>
      <div className="cell">
        <Column4Inner
          basementLevelSpecification={basementLevelSpecification}
          handleRealBuildSurfaceChange={handleRealBuildSurfaceChange}
          selectedLevelSurface={'surfaceForSale'}
        />
      </div>
    </div>
  );
};
