import { Epic, ofType } from 'redux-observable';
import { Action } from 'redux';
import { EMPTY, of } from 'rxjs';
import { pipe } from 'fp-ts/function';
import * as OE from 'fp-ts-rxjs/ObservableEither';
import { generateReport } from '../../api/v1/generateReport';
import { Report } from '../../domain/report/ReportEntry';
import { addBreadcrumb, BREADCRUMB_CATEGORY, captureException } from '../../infra/sentry';
import { ExistingProject } from '../../domain/project/Project';
import { State } from '../reducers';
import { GRANULOMETRY_UPDATED } from '../actions/granulometryUpdated.action';
import { filter, startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { selectLotsReport } from '../selectors/lotsReport.selector';
import { selectIsSavingProject } from '../selectors/project/isSavingProject.selector';
import { selectIsExistingProject } from '../selectors/project/isExistingProject.selector';
import { selectUserToken } from '../selectors/user/userToken.selector';
import { selectUserId } from '../selectors/user/userId.selector';
import { selectProject, selectProjectId } from '../selectors/project';
import { selectGranulometry } from '../selectors/granulometry/current.selector';
import {
  reportFailed,
  ReportFailedAction,
  reportFetched,
  ReportFetchedAction,
  reportFetching
} from '../actions/report.actions';
import { selectIsStoreRehydrated } from '../selectors/isStoreRehydrated.selector';
import { selectHasBeenCalculatedOnce } from '../selectors/project/hasBeenCalculatedOnce.selector';

export const fetchReportAfterLoadEpic: Epic<Action, Action, State> = (actions$, state$) =>
  actions$.pipe(
    ofType(GRANULOMETRY_UPDATED),
    withLatestFrom(state$),
    filter(([, state]: [Action, State]) => selectIsStoreRehydrated(state)),
    switchMap(([, state]: [Action, State]) => {
      const report = selectLotsReport(state);
      const isSaving = selectIsSavingProject(state);
      const project = selectProject(state);
      const existingProject = selectIsExistingProject(state);
      const projectId = selectProjectId(state);
      const granulometry = selectGranulometry(state);
      const userId = selectUserId(state);
      const userToken = selectUserToken(state);
      const calculatedOnce = selectHasBeenCalculatedOnce(state);

      if (
        !!project &&
        projectId &&
        existingProject &&
        !!granulometry &&
        !isSaving &&
        !report &&
        calculatedOnce
      ) {
        return pipe(
          generateReport(project as ExistingProject, granulometry, userId, userToken, true),
          OE.fromTaskEither,
          OE.map((report: Report) => {
            addBreadcrumb(
              `Project ${(project as ExistingProject).id} saved`,
              'log',
              BREADCRUMB_CATEGORY.API
            );

            return reportFetched({ report, projectRevision: project.reportRevision });
          }),
          OE.getOrElse<Error, ReportFailedAction | ReportFetchedAction>((error) => {
            captureException(error);
            alert('Erreur lors du rafraichissement du projet. ');
            return of(reportFailed(error));
          }),
          startWith(reportFetching())
        );
      }

      return EMPTY;
    })
  );
