import * as R from 'ramda';
import { Granulometry } from '../Granulometry';
import { BuildingGranulometry } from '../buildings/BuildingGranulometry';
import { CaseGranulometry } from '../cases/CaseGranulometry';
import { getBuildingId } from '../buildings/queries/getBuildingId';
import { getCaseId } from '../cases/queries/get/getCaseId';
import {
  getCaseParkingsCountByLevelAndType,
  ParkingsCountByLevelAndType
} from '../cases/queries/sections/counts/getCaseParkingSectionsCountByLevelAndType';
import { CaseId } from '../../specification/cases/CaseSpecification';
import { BuildingId } from '../../specification/buildings/BuildingSpecification';

const extractFromCases = (
  cases: Record<string, CaseGranulometry>
): Record<string, ParkingsCountByLevelAndType> =>
  R.mapObjIndexed(getCaseParkingsCountByLevelAndType, cases);

const indexCases = (cases: CaseGranulometry[]): Record<CaseId, CaseGranulometry> =>
  R.indexBy(getCaseId, cases);

export type ParkingsCountByCaseLevelAndType = Record<CaseId, ParkingsCountByLevelAndType>;

export type ParkingsCountByBuildingCaseLevelAndType = Record<
  BuildingId,
  ParkingsCountByCaseLevelAndType
>;

const extractFromBuildings = (
  buildings: Record<BuildingId, { cases: CaseGranulometry[] }>
): ParkingsCountByBuildingCaseLevelAndType =>
  R.mapObjIndexed(({ cases }) =>
    R.pipe<
      [CaseGranulometry[]],
      Record<CaseId, CaseGranulometry>,
      Record<string, ParkingsCountByLevelAndType>
    >(
      indexCases,
      extractFromCases
    )(cases)
  )(buildings);

const indexBuildings = R.indexBy(getBuildingId);

export const getParkingsCountByBuildingCaseLevelAndType = (
  granulometry: Granulometry
): ParkingsCountByBuildingCaseLevelAndType =>
  R.pipe<
    [Granulometry],
    { [x: string]: BuildingGranulometry },
    ParkingsCountByBuildingCaseLevelAndType
  >(
    indexBuildings,
    extractFromBuildings
  )(granulometry);
