import * as R from 'ramda';
import { CaseGranulometry } from '../../cases/CaseGranulometry';
import { LevelGranulometry } from '../../levels/LevelGranulometry';
import { removeCarBoxesInBasementLevelGranulometry } from './removeCarBoxesInBasementLevel';
import { addCarBoxesInBasementLevelGranulometry } from './addCarBoxesInBasementLevel';
import { updateCirculationLaneInBasementLevel } from './updateCirculationLaneInBasementLevel';
import { updateBasementLevelSurface } from './updateBasementLevelSurface';
import { updateBasementLevelOutsideWallsLinear } from './updateBasementLevelOutsideWallsLinear';
import { updateBasementLevelOutsideSlab } from './updateBasementLevelOutsideSlab';
import { isBasementLevel } from '../../levels/queries/is/isBasementLevel';

const redistributeLevelParkingsWithCustomDistribution = (
  levelGranulometry: LevelGranulometry,
  caseGranulometry: CaseGranulometry
) =>
  R.pipe<
    [LevelGranulometry],
    LevelGranulometry,
    LevelGranulometry,
    LevelGranulometry,
    LevelGranulometry,
    LevelGranulometry,
    LevelGranulometry
  >(
    removeCarBoxesInBasementLevelGranulometry,
    (levelGranulometry) =>
      addCarBoxesInBasementLevelGranulometry(levelGranulometry, caseGranulometry),
    (levelGranulometry) =>
      updateCirculationLaneInBasementLevel(levelGranulometry, caseGranulometry),
    updateBasementLevelSurface,
    (levelGranulometry) =>
      updateBasementLevelOutsideWallsLinear(levelGranulometry, caseGranulometry),
    (levelGranulometry) => updateBasementLevelOutsideSlab(levelGranulometry, caseGranulometry)
  )(levelGranulometry);

export const redistributeCaseBasementLevelsWithCustomDistribution = (
  levels: LevelGranulometry[],
  caseGranulometry: CaseGranulometry
): LevelGranulometry[] =>
  R.map((levelGranulometry: LevelGranulometry) =>
    isBasementLevel(caseGranulometry, levelGranulometry)
      ? redistributeLevelParkingsWithCustomDistribution(levelGranulometry, caseGranulometry)
      : levelGranulometry
  )(levels);
