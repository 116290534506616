import { ReportEntry } from '../../../../../../domain/report/ReportEntry';
import * as React from 'react';
import { Column } from 'react-table';
import * as R from 'ramda';
import { roundWith1Decimal } from '../../../../../../utils/round/roundWith1Decimal';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';
import classNames from 'classnames';

export const getCo2PercentsColumns = (report: ReportEntry[], foldedColumns: string[]): Column => ({
  Header: '%',
  columns: [
    /* {
      Header: 'Ss-Total',
      accessor: 'co2Weight',
      Filter: () => <></>,
      aggregate: (values, rows) => {
        return R.sum(values);
      },
      Aggregated: cellInfo =>
        roundWith1Decimal(
          cellInfo.row._parentCo2Weight
            ? (cellInfo.value / cellInfo.row._parentCo2Weight) * 100
            : 100
        ) + ' %',
      Cell: cellInfo =>
        roundWith2Decimal(
          cellInfo.row._parentCo2Weight
            ? (cellInfo.value / cellInfo.row._parentCo2Weight) * 100
            : 100
        ) + ' %',
      sortable: false,
      resizable: false,
      width: 48,
      className: classNames('right', {
        folded: foldedColumns.indexOf('Ss-Total') > -1
      }),
      headerClassName: classNames('right', {
        folded: foldedColumns.indexOf('Ss-Total') > -1
      })
    },
    {
      Header: 'Total',
      accessor: 'co2Weight',
      Filter: () => <></>,
      aggregate: (values, rows) => {
        return R.sum(values);
      },
      Aggregated: cellInfo =>
        roundWith1Decimal(
          cellInfo.row._totalCo2Weight ? (cellInfo.value / cellInfo.row._totalCo2Weight) * 100 : 100
        ) + ' %',
      Cell: cellInfo =>
        roundWith2Decimal(
          cellInfo.row._totalCo2Weight ? (cellInfo.value / cellInfo.row._totalCo2Weight) * 100 : 100
        ) + ' %',
      sortable: false,
      resizable: false,
      width: 48,
      className: classNames('separator', 'right', {
        folded: foldedColumns.indexOf('Total') > -1
      }),
      headerClassName: classNames('separator', 'right', {
        folded: foldedColumns.indexOf('Total') > -1
      })
    }, */
    {
      Header: 'Ss-Total',
      accessor: 'comEthIc',
      Filter: () => <></>,
      aggregate: (values) => {
        return R.sum(values);
      },
      Aggregated: (cellInfo) =>
        roundWith1Decimal(
          cellInfo.row._parentComEthIc ? (cellInfo.value / cellInfo.row._parentComEthIc) * 100 : 100
        ) + ' %',
      Cell: (cellInfo) =>
        roundWith2Decimal(
          cellInfo.row._parentComEthIc ? (cellInfo.value / cellInfo.row._parentComEthIc) * 100 : 100
        ) + ' %',
      sortable: false,
      resizable: false,
      width: 48,
      className: classNames('right', {
        folded: foldedColumns.indexOf('Ss-Total') > -1
      }),
      headerClassName: classNames('right', {
        folded: foldedColumns.indexOf('Ss-Total') > -1
      })
    },
    {
      Header: 'Total',
      accessor: 'comEthIc',
      Filter: () => <></>,
      aggregate: (values) => {
        return R.sum(values);
      },
      Aggregated: (cellInfo) =>
        roundWith1Decimal(
          cellInfo.row._totalComEthIc ? (cellInfo.value / cellInfo.row._totalComEthIc) * 100 : 100
        ) + ' %',
      Cell: (cellInfo) =>
        roundWith2Decimal(
          cellInfo.row._totalComEthIc ? (cellInfo.value / cellInfo.row._totalComEthIc) * 100 : 100
        ) + ' %',
      sortable: false,
      resizable: false,
      width: 48,
      className: classNames('separator', 'right', {
        folded: foldedColumns.indexOf('Total') > -1
      }),
      headerClassName: classNames('separator', 'right', {
        folded: foldedColumns.indexOf('Total') > -1
      })
    }
  ]
});
