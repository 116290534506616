import { CaseGranulometry } from '../../../../domain/granulometry/cases/CaseGranulometry';
import { getCaseAverageInsulationPosition } from '../../../../domain/granulometry/cases/queries/get/getCaseAverageInsulationPosition';

export interface ComEthJsonFeatureSuperstructureLowFloor {
  id_typy_low_floor: string | null; // ID TyPy (set null for now)
  average_U_value_floor: number | null; // TODO: Antoine à remplir (c'est mieux de votre coté: 0.2?)
  insulation_position_floor: 'NI' | 'ITI' | 'ETI' | 'DTI' | null; // NI = Pas d'isolatio ; ITI = Intérieure (ITI) ; ETI = Extérieure (ITE) ; DTI = Répartie (ITR)
  inertia_level_floor: 'low' | 'mid' | 'high' | null; // 'mid' by default // TODO: Antoine à remplir
  low_floor_type: 'girder-slab' | '...' | null; // set null for now
  low_floor_constructive_system: 'système poutrelles-hourdis en béton' | '...' | null; // set false for now
}

export const getComEthJsonFeatureSuperstructureLowFloor = (
  caseGranulometry: CaseGranulometry
): ComEthJsonFeatureSuperstructureLowFloor => ({
  id_typy_low_floor: null,
  average_U_value_floor: null,
  insulation_position_floor: getCaseAverageInsulationPosition(caseGranulometry),
  inertia_level_floor: 'mid',
  low_floor_type: null,
  low_floor_constructive_system: null
});
