import * as R from 'ramda';
import { LevelGranulometry } from '../../../../LevelGranulometry';
import { Surface } from '../../../../../../specification/Surface';
import { getLevelSections } from '../../../sections/getLevelSections';
import {
  filterLodgments,
  getLodgmentType,
  LodgmentSection
} from '../../../../../sections/lodgmentSections/LodgmentSection';
import { Section } from '../../../../../sections/Section';
import { LodgmentType } from '../../../../../../specification/lodgmentTypes/LodgmentType';

export type LodgmentsSurfaceByType = { [lodgmentType in keyof typeof LodgmentType]: Surface };
type ReduceByLodgmentType = (lodgment: LodgmentSection[]) => LodgmentsSurfaceByType;

type GetTopLevelLodgmentsSurfaceByType = (level: LevelGranulometry) => LodgmentsSurfaceByType;
export const getLevelLodgmentsSurfaceByType: GetTopLevelLodgmentsSurfaceByType = R.pipe<
  [LevelGranulometry],
  Section[],
  LodgmentSection[],
  LodgmentsSurfaceByType
>(
  getLevelSections,
  filterLodgments,
  R.reduceBy(
    (acc, lodgment) => Surface.sum([acc, new Surface(lodgment.displayedSurface)]),
    Surface.EMPTY,
    getLodgmentType
  ) as unknown as ReduceByLodgmentType
);
