import { DRAW_COLORS } from './colors';
import { notMovingLayer } from './polygon.style';

export const lineStyle = [
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'active', 'false'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static']
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#3bb2d0',
      'line-width': 2
    }
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', notMovingLayer, ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': DRAW_COLORS.POLYGON_ACTIVE_COLOR.fill,
      'line-dasharray': [0.2, 2],
      'line-width': 2
    }
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', notMovingLayer, ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': '#404040',
      'line-width': 2
    }
  },
  {
    id: 'gl-draw-line-rotate-point',
    type: 'line',
    filter: [
      'all',
      notMovingLayer,
      ['==', 'meta', 'midpoint'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static']
      // ['==', 'active', 'true']
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-color': DRAW_COLORS.POLYGON_ACTIVE_COLOR.fill,
      'line-dasharray': [0.2, 2],
      'line-width': 2
    }
  }
];
