import { createSelector, Selector } from 'reselect';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';
import { MasonryType } from '../../../../domain/specification/cases/MasonryType';
import { selectCurrentCaseProjection } from '../../../../store/selectors/projection/currentCase/projection.selector';

export const selectCurrentCaseMasonryType: Selector<State, Maybe<MasonryType>> = createSelector(
  [selectCurrentCaseProjection],
  (caseSpecification) => caseSpecification?.masonryType
);
