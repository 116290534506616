import { Section } from './Section';
import { isSectionACirculation } from './circulationSections/CirculationSection';
import { isSectionAParking } from './parkingSections/ParkingSection';
import { isSectionALodgment } from './lodgmentSections/LodgmentSection';
import { isSectionACirculationLaneInfra } from './circulationSections/circulationLaneInfra';
import { isSectionAElevatorShaft } from './circulationSections/elevatorShaft';
import { isSectionAElevatorShaftInfra } from './circulationSections/elevatorShaftInfra';
import { isSectionAStairsShaft } from './circulationSections/stairsShaft';
import { isSectionAStairsShaftInfra } from './circulationSections/stairsShaftInfra';
import { isSectionAVentilationGroupInfra } from './circulationSections/ventilationGroupInfra';
import { isSectionASmokeExtractionDucts } from './circulationSections/smokeExtractionDucts';
import { isSectionASmokeExtractionDuctsInfra } from './circulationSections/smokeExtractionDuctsInfra';
import { isSectionAnTechnicalPremise } from './technicalPremiseSections/TechnicalPremiseSection';
import { isSectionACommonPremise } from './commonPremiseSections/CommonPremiseSection';
import { isSectionAShop } from './shopSection/shopSection';
import { isSectionAnOffice } from './officeSection/officeSection';

export interface SectionWithExposureRate extends Section {
  exposureRate?: number;
  defaultExposureRate: number;
}

export const isSectionWithExposureRate = (section: Section): section is SectionWithExposureRate =>
  isSectionALodgment(section) ||
  isSectionAParking(section) ||
  (isSectionACirculation(section) &&
    !isSectionACirculationLaneInfra(section) &&
    !isSectionAElevatorShaft(section) &&
    !isSectionAElevatorShaftInfra(section) &&
    !isSectionASmokeExtractionDucts(section) &&
    !isSectionASmokeExtractionDuctsInfra(section) &&
    !isSectionAStairsShaft(section) &&
    !isSectionAStairsShaftInfra(section) &&
    !isSectionAVentilationGroupInfra(section)) ||
  isSectionAnTechnicalPremise(section) ||
  isSectionACommonPremise(section) ||
  isSectionAShop(section) ||
  isSectionAnOffice(section);
