import { createSelector, Selector } from 'reselect';
import { selectProject } from './project.selector';
import { State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';
import { RE2020Threshold } from '../../../domain/project/Project';

export const selectProjectRE2020Threshold: Selector<State, Maybe<RE2020Threshold>> = createSelector(
  [selectProject],
  (project) => project?.RE2020Threshold
);
