import { LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { getTopLevelInsideWallsThickness } from '../wallsThickness/getTopLevelInsideWallsThickness';
import { getTopLevelRealInsideWallsLinear } from './getTopLevelRealInsideWallsLinear';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelRealInsideWallsSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled
): number =>
  (getTopLevelRealInsideWallsLinear(caseGranulometry, levelGranulometry) *
    getTopLevelInsideWallsThickness(caseGranulometry)) /
  2;
