import { createSelector, Selector } from 'reselect';
import { selectLotsReport } from '../lotsReport.selector';
import { getCostByBuildingAndCase } from '../../../domain/report/queries/getCostByBuildingAndCase';
import { State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';
import { getReportWithRecalculatedPrices } from '../../../components/business/panels/deliverables/ReportTable/customPricesLists/getReportWithRecalculatedPrices';
import { selectProjectCurrentCustomPriceList } from '../project/projectCurrentCustomPriceList.selector';

type CostByBuildingAndCase = Record<string, Record<string, number>>;

export const selectCostByBuildingAndCase: Selector<
  State,
  Maybe<CostByBuildingAndCase>
> = createSelector(
  [selectLotsReport, selectProjectCurrentCustomPriceList],
  (report, projectCurrentCustomPriceList): CostByBuildingAndCase | undefined =>
    report
      ? getCostByBuildingAndCase(
          projectCurrentCustomPriceList
            ? getReportWithRecalculatedPrices(report, projectCurrentCustomPriceList)
            : report
        )
      : undefined
);
