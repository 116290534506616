import { TechnicalPremiseSection } from '../../../../technicalPremiseSections/TechnicalPremiseSection';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { getTechnicalPremiseSectionTheoreticalWallsLinear } from './getTechnicalPremiseSectionTheoreticalWallsLinear';
import { EXPONENT, MULTIPLIER_COEFFICIENT } from '../../getSectionWallsLinear';

export const getTechnicalPremiseSectionTheoreticalWallsLinearDetails = (
  technicalPermise: TechnicalPremiseSection
): string => {
  let details: string[] = [];
  details = [
    ...details,
    '<b>' +
      roundWith2Decimal(technicalPermise.surface || technicalPermise.defaultSurface) +
      ' m\u00B2</b> de surface du ' +
      technicalPermise.title.toLowerCase()
  ];
  const hideNumbers = !(process.env.GB_ENV === 'dev' || process.env.GB_ENV === 'recette');
  details = [
    ...details,
    '<b>* ' +
      (hideNumbers
        ? 'coefficient multiplicateur'
        : MULTIPLIER_COEFFICIENT + '<sup>' + EXPONENT + '</sup>') +
      '</b>'
  ];
  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(getTechnicalPremiseSectionTheoreticalWallsLinear(technicalPermise)) +
      ' mL</b>'
  ];
  return details.join('<br />');
};
