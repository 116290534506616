import { CaseSpecification } from '../../../CaseSpecification';
import { getCasePropertyConvertedValue } from '../properties/getCasePropertyConvertedValue';
import { isTopLevel } from '../../../../levels/queries/is/isTopLevel';
import { getLevelLabel } from '../../../../../granulometry/levels/LevelGranulometry';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';
import { getCaseTopLevelsHoppersSurface } from './getCaseTopLevelsHoppersSurface';
import { getCaseBasementLevels } from '../levels/getCaseBasementLevels';

export const getCaseTopLevelsHoppersSurfaceDetails = (
  caseSpecification: CaseSpecification
): string => {
  let details: string[] = [];
  const topLevelsCount = caseSpecification.levels.reduce(
    (acc, levelSpecification) => (isTopLevel(levelSpecification) ? acc + 1 : acc),
    0
  );
  for (let i = 0; i < topLevelsCount; i++) {
    let levelDetails: string[] = [];
    // Smoke extraction ducts
    const smokeExtractionDuctsCount = getCasePropertyConvertedValue(
      caseSpecification,
      'smokeExtractionDuctsCount'
    ) as number;
    if (smokeExtractionDuctsCount !== 0) {
      const smokeExtractionDuctsDepth = getCasePropertyConvertedValue(
        caseSpecification,
        'smokeExtractionDuctsDepth'
      ) as number;
      const smokeExtractionDuctsWidth = getCasePropertyConvertedValue(
        caseSpecification,
        'smokeExtractionDuctsWidth'
      ) as number;
      const smokeExtractionDuctsSurface =
        smokeExtractionDuctsCount * smokeExtractionDuctsDepth * smokeExtractionDuctsWidth;
      levelDetails = [
        ...levelDetails,
        'GdD : ' + roundWith2Decimal(smokeExtractionDuctsSurface) + ' m\u00B2'
      ];
    }

    // Elevator shaft sections
    const elevatorShaftCount = getCasePropertyConvertedValue(
      caseSpecification,
      'elevatorShaftCount'
    ) as number;
    if (elevatorShaftCount !== 0) {
      const elevatorShaftLength = getCasePropertyConvertedValue(
        caseSpecification,
        'elevatorShaftLength'
      ) as number;
      const elevatorShaftWidth = getCasePropertyConvertedValue(
        caseSpecification,
        'elevatorShaftWidth'
      ) as number;
      const elevatorShaftSurface = elevatorShaftCount * elevatorShaftLength * elevatorShaftWidth;
      levelDetails = [
        ...levelDetails,
        'Asc : ' + roundWith2Decimal(elevatorShaftSurface) + ' m\u00B2'
      ];
    }

    // Stairs shaft sections
    const stairsShaftCount = getCasePropertyConvertedValue(
      caseSpecification,
      'stairsShaftCount'
    ) as number;
    if (stairsShaftCount !== 0) {
      const stairsShaftLength = getCasePropertyConvertedValue(
        caseSpecification,
        'stairsShaftLength'
      ) as number;
      const stairsShaftWidth = getCasePropertyConvertedValue(
        caseSpecification,
        'stairsShaftWidth'
      ) as number;
      const stairsShaftBearingLength = getCasePropertyConvertedValue(
        caseSpecification,
        'stairsShaftBearingLength'
      ) as number;
      const stairsShaftBearingWidth = getCasePropertyConvertedValue(
        caseSpecification,
        'stairsShaftBearingWidth'
      ) as number;
      const stairShaftBearingSurface = stairsShaftBearingWidth * stairsShaftBearingLength;
      const stairsShaftSurface =
        stairsShaftCount * stairsShaftLength * stairsShaftWidth - stairShaftBearingSurface;
      levelDetails = [
        ...levelDetails,
        'Esc : ' + roundWith2Decimal(stairsShaftSurface) + ' m\u00B2'
      ];
    }

    // Ramp
    if (
      getCaseBasementLevels(caseSpecification).length !== 0 &&
      caseSpecification.builtInRamp &&
      i === 0
    ) {
      const rampLength = getCasePropertyConvertedValue(caseSpecification, 'rampLength') as number;
      const rampWidth = getCasePropertyConvertedValue(caseSpecification, 'rampWidth') as number;
      const rampSurface = rampLength * rampWidth;
      levelDetails = [...levelDetails, 'Rmp : ' + roundWith2Decimal(rampSurface) + ' m\u00B2'];
    }
    details = [...details, '- Trémies ' + getLevelLabel(i) + ' : ' + levelDetails.join(' + ')];
  }

  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(getCaseTopLevelsHoppersSurface(caseSpecification)) +
      ' m\u00B2</b> de trémies'
  ];

  return details.join('<br />');
};
