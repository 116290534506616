import * as React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { selectCurrentlyEditedLevelSpecification } from '../../../../../store/selectors/navigation/toolbox/casesPanel/currentlyEditedLevelSpecification.selector';
import { Card } from '../../../../ui/Card';
import { selectCurrentCaseProjection } from '../../../../../store/selectors/projection/currentCase/projection.selector';
import { IconObject } from '../../../../ui/Icons/iconObject';
import {
  getTechnicalPremiseInfraSectionsList,
  getTechnicalPremiseSectionsList,
  TechnicalPremiseSection,
  TechnicalPremiseSectionName
} from '../../../../../domain/granulometry/sections/technicalPremiseSections/TechnicalPremiseSection';
import { FormGroup } from '../../../../ui/FormGroup';
import { Input } from '../../../../ui/input/Input';
import { Option, Select } from '../../../../ui/Select';
import { setCaseLevelSpecification } from '../../../../../store/actions/setCaseLevelSpecification.action';
import { Button } from '../../../../ui/Button';
import { getLevelTechnicalPermiseSections } from '../../../../../domain/specification/levels/queries/get/getLevelTechnicalPermiseSections';
import { secureInputChange } from '../../../../../utils/secureInputChange';
import { LevelSpecification } from '../../../../../domain/specification/levels/LevelSpecification';
import { isBasementLevel } from '../../../../../domain/specification/levels/queries/is/isBasementLevel';

export const TechnicalPremiseSections = () => {
  const currentlyEditedCase = useSelector(selectCurrentCaseProjection);
  const currentlyEditedLevel = useSelector(
    selectCurrentlyEditedLevelSpecification
  ) as LevelSpecification;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  //const technicalPremiseSectionsList = getTechnicalPremiseSectionsList();
  const technicalPremiseSectionsList = isBasementLevel(currentlyEditedLevel)
    ? getTechnicalPremiseInfraSectionsList()
    : getTechnicalPremiseSectionsList();


  const technicalPremiseSectionsOptions: Option[] = technicalPremiseSectionsList.reduce(
    (acc, technicalPremiseSection) => [
      ...acc,
      {
        label: <IconObject {...technicalPremiseSection.icon} clickable={false} />,
        value: technicalPremiseSection.name
      }
    ],
    [] as Option[]
  );

  const [localCurrentLevelTechnicalPremiseSections, setLocalCurrentLevelTechnicalPremiseSections] =
    React.useState<TechnicalPremiseSection[]>(
      getLevelTechnicalPermiseSections(currentlyEditedLevel)
    );

  React.useEffect(() => {
    setLocalCurrentLevelTechnicalPremiseSections(
      getLevelTechnicalPermiseSections(currentlyEditedLevel)
    );
  }, [currentlyEditedLevel]);

  const handleTechnicalPremiseSectionsSpecificationsChange = React.useCallback(
    (value: number | string | null, index: number, input: string) => {
      const getCleanTechnicalPremiseSectionFromName = (
        name: TechnicalPremiseSectionName
      ): TechnicalPremiseSection =>
        technicalPremiseSectionsList.find((tPS) => tPS.name === name) as TechnicalPremiseSection;

      const updateTechnicalPremiseSectionInput = (
        technicalPremiseSection: TechnicalPremiseSection,
        input: string
      ): TechnicalPremiseSection =>
        R.assoc(
          input,
          value === null ? undefined : value
        )(technicalPremiseSection) as unknown as TechnicalPremiseSection;

      const newTechnicalPremiseSections = R.over(
        R.lensIndex<TechnicalPremiseSection>(index),
        R.compose((technicalPremiseSection) => {
          if (input === 'name') {
            return getCleanTechnicalPremiseSectionFromName(value as TechnicalPremiseSectionName);
          } else {
            if (input === 'exposureRate' && typeof value === 'number') {
              value = (secureInputChange(value, 0, 100) * 4) / 100;
            }
            if (input === 'surface') {
              technicalPremiseSection = updateTechnicalPremiseSectionInput(
                technicalPremiseSection,
                'displayedSurface'
              );
            }
            return updateTechnicalPremiseSectionInput(technicalPremiseSection, input);
          }
        })
      )(localCurrentLevelTechnicalPremiseSections);

      if (currentlyEditedCase && currentlyEditedLevel) {
        setLocalCurrentLevelTechnicalPremiseSections(newTechnicalPremiseSections);
        dispatch(
          setCaseLevelSpecification(currentlyEditedCase.id, {
            ...currentlyEditedLevel,
            technicalPremiseSections: newTechnicalPremiseSections ?? []
          })
        );
      }
    },
    [dispatch, currentlyEditedCase, currentlyEditedLevel]
  );

  const handleRemoveTechnicalPremiseSection = React.useCallback(
    (index: number) => {
      const newTechnicalPremiseSections = R.remove(
        index,
        1,
        localCurrentLevelTechnicalPremiseSections
      );
      if (currentlyEditedCase && currentlyEditedLevel) {
        setLocalCurrentLevelTechnicalPremiseSections(newTechnicalPremiseSections);
        dispatch(
          setCaseLevelSpecification(currentlyEditedCase.id, {
            ...currentlyEditedLevel,
            technicalPremiseSections: newTechnicalPremiseSections ?? []
          })
        );
      }
    },
    [dispatch, currentlyEditedCase, currentlyEditedLevel]
  );

  const handleDuplicateTechnicalPremiseSection = React.useCallback(
    (index: number) => {
      const newTechnicalPremiseSections = R.insert(
        index,
        R.view(
          R.lensIndex<TechnicalPremiseSection>(index),
          localCurrentLevelTechnicalPremiseSections
        ),
        localCurrentLevelTechnicalPremiseSections
      );
      if (currentlyEditedCase && currentlyEditedLevel) {
        setLocalCurrentLevelTechnicalPremiseSections(newTechnicalPremiseSections);
        dispatch(
          setCaseLevelSpecification(currentlyEditedCase.id, {
            ...currentlyEditedLevel,
            technicalPremiseSections: newTechnicalPremiseSections ?? []
          })
        );
      }
    },
    [dispatch, currentlyEditedCase, currentlyEditedLevel]
  );

  const handleAddFirstTechnicalPremiseSection = React.useCallback(() => {
    const newTechnicalPremiseSections = [
      R.view(R.lensIndex<TechnicalPremiseSection>(0), technicalPremiseSectionsList)
    ];
    if (currentlyEditedCase && currentlyEditedLevel) {
      setLocalCurrentLevelTechnicalPremiseSections(newTechnicalPremiseSections);
      dispatch(
        setCaseLevelSpecification(currentlyEditedCase.id, {
          ...currentlyEditedLevel,
          technicalPremiseSections: newTechnicalPremiseSections
        })
      );
    }
  }, [dispatch, currentlyEditedCase, currentlyEditedLevel]);

  if (!currentlyEditedCase || !currentlyEditedLevel) return null;

  return (
    <Card className="technicalPremiseSections">
      <SpecialLabel label={t('technicalPremiseSections.specialLabel')} />
      {localCurrentLevelTechnicalPremiseSections.length > 0 ? (
        localCurrentLevelTechnicalPremiseSections.map((technicalPremiseSection, index) => {
          const prefix =
            'level' + currentlyEditedLevel.level + 'TechnicalPremiseSectionsSpecification' + index;
          return (
            <React.Fragment key={index}>
              <SpecialLabel label={t(technicalPremiseSection.name + '.title')} small />
              <FormGroup key={index} horizontal={true} wrap={false}>
                <div style={{ width: '53px', flex: '0 0 53px' }}>
                  <label htmlFor={prefix + 'Type'}>
                    {t('technicalPremiseSections.typeSelect.label')}
                  </label>
                  <Select
                    id={prefix + 'Type'}
                    className={'iconObjectSelector'}
                    options={technicalPremiseSectionsOptions}
                    handleChange={(option: Option) =>
                      handleTechnicalPremiseSectionsSpecificationsChange(
                        option.value,
                        index,
                        'name'
                      )
                    }
                    value={technicalPremiseSectionsOptions.find(
                      (option) => option.value === technicalPremiseSection.name
                    )}
                  />
                </div>
                <div style={{ width: '21.5%', flex: '0 0 21.5%' }}>
                  <Input
                    name={prefix + 'Surface'}
                    label={t('technicalPremiseSections.surfaceInput.label')}
                    type="number"
                    min={technicalPremiseSection.minSurface}
                    max={technicalPremiseSection.maxSurface}
                    minMax={{
                      min: technicalPremiseSection.minSurface,
                      max: technicalPremiseSection.maxSurface,
                      unit: ''
                    }}
                    value={
                      technicalPremiseSection.surface !== technicalPremiseSection.defaultSurface
                        ? technicalPremiseSection.surface
                        : undefined
                    }
                    handleChange={(value) =>
                      handleTechnicalPremiseSectionsSpecificationsChange(
                        secureInputChange(
                          value,
                          technicalPremiseSection.minSurface,
                          technicalPremiseSection.maxSurface
                        ),
                        index,
                        'surface'
                      )
                    }
                    suffix="m2"
                    placeholder={technicalPremiseSection.defaultSurface}
                  />
                </div>
                <div style={{ width: '21.5%', flex: '0 0 21.5%' }}>
                  <Input
                    name={prefix + 'Width'}
                    label={t('technicalPremiseSections.widthInput.label')}
                    type="number"
                    min={technicalPremiseSection.minWidth}
                    max={technicalPremiseSection.maxWidth}
                    minMax={{
                      min: technicalPremiseSection.minWidth,
                      max: technicalPremiseSection.maxWidth,
                      unit: ''
                    }}
                    value={
                      technicalPremiseSection.width !== technicalPremiseSection.defaultWidth
                        ? technicalPremiseSection.width
                        : undefined
                    }
                    handleChange={(value) =>
                      handleTechnicalPremiseSectionsSpecificationsChange(
                        secureInputChange(
                          value,
                          technicalPremiseSection.minWidth,
                          technicalPremiseSection.maxWidth
                        ),
                        index,
                        'width'
                      )
                    }
                    suffix="m"
                    placeholder={technicalPremiseSection.defaultWidth}
                  />
                </div>
                <div style={{ width: '21.5%', flex: '0 0 21.5%' }}>
                  <Input
                    name={prefix + 'ExposureRate'}
                    label={t('technicalPremiseSections.exposureRateInput.label')}
                    type="number"
                    min={0}
                    max={100}
                    minMax={{
                      min: 0,
                      max: 100,
                      unit: ''
                    }}
                    value={
                      technicalPremiseSection.exposureRate !== undefined &&
                      technicalPremiseSection.exposureRate !==
                        technicalPremiseSection.defaultExposureRate
                        ? (technicalPremiseSection.exposureRate / 4) * 100
                        : undefined
                    }
                    handleChange={(value) =>
                      handleTechnicalPremiseSectionsSpecificationsChange(
                        secureInputChange(value, 0, 100),
                        index,
                        'exposureRate'
                      )
                    }
                    suffix="%"
                    placeholder={(technicalPremiseSection.defaultExposureRate / 4) * 100}
                  />
                </div>
                <div className="add-remove-buttons">
                  <IconObject
                    iconName="remove"
                    type="menu"
                    onClick={() => {
                      handleRemoveTechnicalPremiseSection(index);
                    }}
                    // title={t('Remove case {{caseLabel}}', { caseLabel: selectedCaseLabel })}
                  />
                  <IconObject
                    iconName="add"
                    type="menu"
                    onClick={() => {
                      handleDuplicateTechnicalPremiseSection(index);
                    }}
                  />
                </div>
              </FormGroup>
            </React.Fragment>
          );
        })
      ) : (
        <FormGroup horizontal={true} wrap={false}>
          <Button
            content={t('technicalPremiseSections.addButton.content') as string}
            handleClick={() => {
              handleAddFirstTechnicalPremiseSection();
            }}
            appearance="outline"
            size="small"
            style={{ margin: '10px auto' }}
          />
        </FormGroup>
      )}
    </Card>
  );
};
