import {
  ComEthJsonFeatureGeometry,
  getComEthJsonFeatureGeometry
} from './getComEthJsonFeatureGeometry';
import { Report } from '../../../../domain/report/ReportEntry';
import {
  ComEthJsonFeatureProduct,
  getComEthJsonFeatureProducts
} from './getComEthJsonFeatureProducts';
import { CaseGranulometry } from '../../../../domain/granulometry/cases/CaseGranulometry';
import { BuildingGranulometry } from '../../../../domain/granulometry/buildings/BuildingGranulometry';
import {
  ComEthJsonFeatureProperties,
  getComEthJsonFeatureProperties
} from './getComEthJsonFeatureProperties';
import { Nomenclature } from '../../../../domain/Nomenclature';

export interface ComEthJsonFeature {
  type: 'Feature'; // leave as it is
  properties: ComEthJsonFeatureProperties;
  geometry: ComEthJsonFeatureGeometry | null; // TODO : @Felix + @Philippe : Géometrie du bâtiment (à discuter) (GeoJson)
  gobuildProducts: ComEthJsonFeatureProduct[];
}

export const getComEthJsonFeature = (
  buildingGranulometry: BuildingGranulometry,
  caseGranulometry: CaseGranulometry,
  report: Report,
  nomenclature: Nomenclature
): ComEthJsonFeature => ({
  type: 'Feature',
  properties: getComEthJsonFeatureProperties(buildingGranulometry, caseGranulometry),
  geometry: getComEthJsonFeatureGeometry(caseGranulometry),
  gobuildProducts: getComEthJsonFeatureProducts(caseGranulometry, report, nomenclature)
});
