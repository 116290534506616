import { getBuildingIndexGivenBuildingId } from './getBuildingIndexGivenBuildingId';
import { BuildingId } from '../../specification/buildings/BuildingSpecification';
import { Project } from '../../project/Project';

export const getBuildingLabelGivenBuildingId = (
  project: Project,
  buildingId: BuildingId
): string => {
  const buildingIndex = getBuildingIndexGivenBuildingId(project, buildingId);

  if (typeof buildingIndex !== 'number' || buildingIndex < 0) return 'NC';

  return `${buildingIndex + 1}`;
};
