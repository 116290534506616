import { Position } from '@turf/helpers/dist/js/lib/geojson';
import { getCenter } from '../geometry/getCenter';

export const applyTranslation = (point1: Position, point2: Position): Position => {
  return point1.map((c, i) => c - point2[i]);
};

export const addMarginToPoint = (point1: Position, margin: Position): Position => {
  return point1.map((coo, i) => coo + margin[i]);
};

export const translateCoordinatesToNewCenter = (
  polygonCoordinates: Position[],
  newCenter: Position
): Position[] => {
  const oldCenter = getCenter(polygonCoordinates);
  const distanceWithNewCenter = applyTranslation(oldCenter, newCenter);
  return polygonCoordinates.map((coord) => applyTranslation(coord, distanceWithNewCenter));
};
