import {
  ComEthJsonFeatureSuperstructureLowFloor,
  getComEthJsonFeatureSuperstructureLowFloor
} from './getComEthJsonFeatureSuperstructureLowFloor';
import {
  ComEthJsonFeatureSuperstructureRoof,
  getComEthJsonFeatureSuperstructureRoof
} from './getComEthJsonFeatureSuperstructureRoof';
import {
  ComEthJsonFeatureSuperstructureFacade,
  getComEthJsonFeatureSuperstructureFacade
} from './getComEthJsonFeatureSuperstructureFacade';
import {
  ComEthJsonFeatureSuperstructureIntermediateFloor,
  getComEthJsonFeatureSuperstructureIntermediateFloor
} from './getComEthJsonFeatureSuperstructureIntermediateFloor';
import { CaseGranulometry } from '../../../../domain/granulometry/cases/CaseGranulometry';
import { getCaseGranulometryTopLevelsCount } from '../../../../domain/granulometry/cases/queries/levels/counts/getCaseGranulometryTopLevelsCount';
import {
  CaseAverageOutsideWallsType,
  getCaseAverageOutsideWallsType
} from '../../../../domain/granulometry/cases/queries/getCaseAverageOutsideWallsType';

export type ComEthVerticalStructureConstructiveSystem =
  | 'bloc en béton de granulat'
  | 'bloc en béton de granulat à isolation intégrée'
  | 'bloc coffrant en béton - avec béton de remplissage'
  | 'voile de béton armé'
  | 'mur à coffrage intégré (MCI)'
  | 'mur à coffrage et isolation intégrés (MCII)'
  | 'maçonnerie en briques creuses'
  | 'maçonnerie en briques monomur'
  | 'ossature en bois massif'
  | 'structure mixte ossature en bois massif et voile béton'
  | null;

const getVerticalStructureConstructiveSystemFromCaseAverageOutsideWallsType = (
  caseAverageOutsideWallsType: CaseAverageOutsideWallsType
): ComEthVerticalStructureConstructiveSystem => {
  if (caseAverageOutsideWallsType === 'concrete') {
    return 'voile de béton armé';
  } else if (caseAverageOutsideWallsType === 'masonryWithConcreteBlock') {
    return 'bloc en béton de granulat';
  } else if (caseAverageOutsideWallsType === 'masonryWithBrickBlock') {
    return 'maçonnerie en briques creuses';
  } else if (caseAverageOutsideWallsType === 'woodEnvelopeWithCltSolidPanel') {
    return 'ossature en bois massif';
  } else if (caseAverageOutsideWallsType === 'woodEnvelopeWithTimberFrame') {
    return 'ossature en bois massif';
  } else {
    return null;
  }
};

export interface ComEthJsonFeatureSuperstructure {
  storeys_above_ground: number; // top levels count (ground level included)
  // Permet de déterminer le R (=résistance thermique) U(= conductivité thermique)
  // 'Standart' => 'RT 2012'
  // 'High' => 'E1 et E2'
  // 'Very_high' => 'E3 et E4 (RE2020)'
  thermal_performance_of_the_building_envelop: 'Standard' | 'High' | 'Very_high' | null;
  main_material: 'Classic_materials' | '...' | null; // set null for now
  structure_type: 'concrete_and_masonry' | '...' | null; // set null for now
  facade: ComEthJsonFeatureSuperstructureFacade;
  low_floor: ComEthJsonFeatureSuperstructureLowFloor;
  roof: ComEthJsonFeatureSuperstructureRoof;
  intermediate_floor: ComEthJsonFeatureSuperstructureIntermediateFloor; // Ce sont les planchers intermédiaires (on laisse à null mais pas utilisé pour l'instant)
  vertical_structure_constructive_system: ComEthVerticalStructureConstructiveSystem;
}

export const getComEthJsonFeatureSuperstructure = (
  caseGranulometry: CaseGranulometry
): ComEthJsonFeatureSuperstructure => ({
  storeys_above_ground: getCaseGranulometryTopLevelsCount(caseGranulometry),
  thermal_performance_of_the_building_envelop: 'Very_high',
  main_material: null,
  structure_type: null,
  facade: getComEthJsonFeatureSuperstructureFacade(caseGranulometry),
  low_floor: getComEthJsonFeatureSuperstructureLowFloor(caseGranulometry),
  roof: getComEthJsonFeatureSuperstructureRoof(caseGranulometry),
  intermediate_floor: getComEthJsonFeatureSuperstructureIntermediateFloor(),
  vertical_structure_constructive_system:
    getVerticalStructureConstructiveSystemFromCaseAverageOutsideWallsType(
      getCaseAverageOutsideWallsType(caseGranulometry)
    )
});
