import { isRoomSpecificationAnyLivingRoomKitchen } from '../../LivingRoomKitchenSpecification';
import { isRoomSpecificationABalcony } from '../../BalconySpecification';
import { RoomSpecification } from '../../RoomSpecification';
import { getLivingRoomKitchenSpecificationWidth } from './getLivingRoomKitchenSpecificationWidth';
import { getBasicRoomSpecificationWidth } from './getBasicRoomSpecificationWidth';

type GetRoomSpecificationWidth = (roomSpecification: RoomSpecification) => number;
export const getRoomSpecificationWidth: GetRoomSpecificationWidth = (roomSpecification) =>
  isRoomSpecificationAnyLivingRoomKitchen(roomSpecification)
    ? getLivingRoomKitchenSpecificationWidth(roomSpecification)
    : isRoomSpecificationABalcony(roomSpecification)
    ? 0
    : getBasicRoomSpecificationWidth(roomSpecification);
