import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { getTopLevelOutsideInsulatedWallsThickness } from '../wallsThickness/getTopLevelOutsideInsulatedWallsThickness';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelInsulatedFacadeInternalLinears } from '../getTopLevelInsulatedFacadeInternalLinears';
import { getTopLevelRealOutsideWallsSurface } from './getTopLevelRealOutsideWallsSurface';

export const getTopLevelRealOutsideWallsSurfaceDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled
): string => {
  let details: string[] = [];
  const topLevelOutsideWallsThickness = getTopLevelOutsideInsulatedWallsThickness(caseGranulometry);
  getTopLevelInsulatedFacadeInternalLinears(
    caseGranulometry,
    levelGranulometry as LevelGranulometry
  ).forEach((facadeLinear, index) => {
    details = [
      ...details,
      (index !== 0 ? '<b>+</b> ' : '') +
        '<i>Pour la façade ' +
        (index + 1) +
        ' </i>: <b>(' +
        roundWith2Decimal(facadeLinear) +
        ' m</b> de linéaire intérieur ' +
        '<b> + ' +
        roundWith2Decimal(topLevelOutsideWallsThickness) +
        ' m</b> d’ép. de voiles exposés<b>)</b>' +
        '<b> * ' +
        roundWith2Decimal(topLevelOutsideWallsThickness) +
        ' m</b> d’ép. de voiles exposés'
    ];
  });
  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(getTopLevelRealOutsideWallsSurface(caseGranulometry, levelGranulometry)) +
      ' m\u00B2</b>'
  ];
  return details.join('<br />');
};
