import { LevelGranulometry } from '../../../../LevelGranulometry';
import { getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface } from './getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface';
import { getTopLevelRealTheoreticalInsideWallsSurfaceDiff } from '../../walls/getTopLevelRealTheoreticalInsideWallsSurfaceDiff';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';

export const getTopLevelSectionsWithTSTechnicalSheathRealSurfaceDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): string =>
  '<b>' +
  roundWith2Decimal(
    getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface(caseGranulometry, levelGranulometry)
      .value
  ) +
  ' m\u00B2</b> d’emprise théorique de gaines tech. de logements' +
  '<br /><b>+ ' +
  roundWith2Decimal(
    getTopLevelRealTheoreticalInsideWallsSurfaceDiff(caseGranulometry, levelGranulometry)
  ) +
  ' m\u00B2</b> de diff. emprise réelle/théorique voiles non-exposés';
