import { UnsavedProject } from '../../domain/project/Project';
import { post } from './admin.api';
import { ApiServerError } from './errors/server.error';
import { User } from '../../domain/User';
import { AuthToken } from '../../AuthToken';

export interface APIProjectAddInput {
  id?: string;
  projectGroup?: string;
  projectName: string;
  projectVersion?: string;
  address?: string;
  city?: string;
  locationName?: string;
  postalCode?: string;
  country?: string;
  title?: string;
  totalArea?: number;
  constructibleArea?: number;
}

export const createProject = async (
  newProject: UnsavedProject,
  userId: User['id'],
  userToken: AuthToken
): Promise<string> => {
  const projectAPIInput: APIProjectAddInput = {
    projectName: newProject.name
  };

  const url = `/Projects/add/${userId}/${userToken}`;

  const response = await post(url, userToken, projectAPIInput);

  const projectId = await response.text();
  if (!projectId || !/^[\d\w]+$/.test(projectId)) {
    throw new ApiServerError(url, response.status, 'Could not obtain a projectId');
  }

  return projectId;
};
