import classNames from 'classnames';
import * as React from 'react';
import { IconFont } from '../../../../components/ui/Icons/IconFont';
import { LINEAR_METER_SCALE } from '../../../../constants/appConstants';
import { BuildingMapLevelViewController } from '../level/BuildingMapLevelViewController';
import { BuildingMapOutSideLevelView } from './BuildingMapOutSideLevelView';
import { CaseGranulometry } from '../../../../domain/granulometry/cases/CaseGranulometry';
import { LevelGranulometry } from '../../../../domain/granulometry/levels/LevelGranulometry';
import { useDispatch, useSelector } from 'react-redux';
import { selectCaseLabelGivenCaseId } from '../../../../store/selectors/core/caseLabelGivenCaseId.selector';
import { State } from '../../../../store/reducers';
import { buildingMapFocused } from '../../../../store/actions/buildingMap/buildingMapFocused.action';
import { CaseId } from '../../../../domain/specification/cases/CaseSpecification';

export interface BuildingMapCaseProps {
  buildingId: string;
  /** the direction of the section distribution */
  direction?: 'ltr' | 'rtl';
  /** the thickness of the walls separating the sections in meters */
  wallThickness?: number;
  /** Unique identifier for this Case */
  id: string;
  /** Has a previous case */
  prev?: BuildingMapCaseProps;
  /** Has a next case */
  next?: BuildingMapCaseProps;
  /** Case title */
  title: string;
  /** Case label index */
  /** the levels to display in the top and parkings case part */
  levels?: LevelGranulometry[];
  /** levels to display in the outside case part */
  outsideLevels?: LevelGranulometry[];
  /** icons to display in the outside case part. */
  outsideAmeneties?: LevelGranulometry[];
  levelsRefs?: Map<number, React.Ref<HTMLDivElement>>;
  allBasementsHeight?: number;
}

export const BuildingMapCaseView = (props: CaseGranulometry & BuildingMapCaseProps) => {
  const {
    buildingId,
    id,
    prev,
    next,
    direction = 'ltr',
    levels = [],
    outsideLevels = [],
    outsideAmeneties = [],
    levelsRefs,
    allBasementsHeight
  } = props;

  const labelIndex = useSelector((state: State) => selectCaseLabelGivenCaseId(state, id));

  let topLevelsHeight = 0;
  const basementLevels: LevelGranulometry[] = [];
  const topLevels: LevelGranulometry[] = [];

  levels.forEach((level, index: number) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    level.parent = props;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    level.direction = direction;
    if (levels[index - 1]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      level.prev = levels[index - 1];
    }
    if (levels[index + 1]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      level.next = levels[index + 1];
    }
    if (level.level < 0) {
      basementLevels.push(level);
    } else {
      topLevels.push(level);
      topLevelsHeight += level.ceilingHeight * LINEAR_METER_SCALE;
      if (level.level !== 0) {
        topLevelsHeight += level.floorThickness * LINEAR_METER_SCALE;
      }
    }
  });

  let hasOutsideLevels = false;
  outsideLevels.forEach((outsideLevel, index) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    outsideLevel.parent = props;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    outsideLevel.direction = direction;
    if (outsideLevels[index - 1]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      outsideLevel.prev = outsideLevels[index - 1];
    }
    if (outsideLevels[index + 1]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      outsideLevel.next = outsideLevels[index + 1];
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    outsideLevel.outsideLevel = true;
    if (outsideLevel.level >= 0) {
      hasOutsideLevels = true;
      outsideLevel.id = id + 'OL' + outsideLevel.level;
    }
  });

  const labelClasses = classNames('case', {
    ltr: direction === 'ltr',
    rtl: direction === 'rtl',
    first: !prev,
    last: !next,
    hasPrev: prev,
    hasNext: next,
    hasOutsideLevels
  });

  const topLevelClasses = classNames('topLevels', 'levels');
  const basementLevelClasses = classNames('basementLevels', 'levels');

  const mouseEnterCaseLabel = (caseId: string) => {
    const buildingMap = document.querySelector('.buildingMap');
    if (buildingMap) {
      buildingMap.classList.add('caseHighlighted');
      const goodCase = buildingMap.querySelector('#case' + caseId);
      if (goodCase) {
        goodCase.classList.add('highlighted');
      }
    }
  };

  const mouseLeaveCaseLabel = (caseId: string) => {
    const buildingMap = document.querySelector('.buildingMap');
    if (buildingMap) {
      buildingMap.classList.remove('caseHighlighted');
      const goodCase = buildingMap.querySelector('#case' + caseId);
      if (goodCase) {
        goodCase.classList.remove('highlighted');
      }
    }
  };

  const dispatch = useDispatch();
  const focusOnCase = (caseId: CaseId) => dispatch(buildingMapFocused({ case: caseId }));

  return (
    <div
      id={'case' + id}
      className={labelClasses}
      style={{ marginBottom: `-${allBasementsHeight ?? 0}px` }}>
      <div className="inner">
        <div
          className="label"
          onMouseEnter={() => mouseEnterCaseLabel(id)}
          onMouseLeave={() => mouseLeaveCaseLabel(id)}
          onClick={() => focusOnCase(id)}>
          <IconFont id={'case-label' + id} returnString={labelIndex} />
        </div>
        <div className={topLevelClasses}>
          {direction === 'rtl' && (outsideLevels.length !== 0 || outsideAmeneties.length !== 0) && (
            <BuildingMapOutSideLevelView
              outsideLevels={outsideLevels}
              outsideAmeneties={outsideAmeneties}
              topLevelsHeight={topLevelsHeight}
            />
          )}
          <div className="levelsContent">
            <div className="levelsBackground" />
            <div className="levelsBorders" />
            {topLevels.map((topLevel, key) => {
              return (
                <BuildingMapLevelViewController
                  buildingId={buildingId}
                  caseId={id}
                  key={key}
                  forwardRef={levelsRefs && levelsRefs.get(topLevel.level)}
                  {...topLevel}
                />
              );
            })}
          </div>
          {direction === 'ltr' && (outsideLevels.length !== 0 || outsideAmeneties.length !== 0) && (
            <BuildingMapOutSideLevelView
              outsideLevels={outsideLevels}
              outsideAmeneties={outsideAmeneties}
              topLevelsHeight={topLevelsHeight}
            />
          )}
        </div>
        <div className={basementLevelClasses}>
          <div className="levelsContent">
            <div className="levelsBackground" />
            <div className="levelsBorders" />
            {basementLevels.map((basementLevel, key: number) => (
              <BuildingMapLevelViewController
                key={key}
                forwardRef={levelsRefs && levelsRefs.get(basementLevel.level)}
                {...basementLevel}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
