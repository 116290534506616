import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { gingerMapState } from '../reducers/gingerMap.slice';
import { selectProject } from '../selectors/project';
import { ExistingProject } from '../../domain/project/Project';
import { selectUser } from '../selectors/user';
import { User } from '../../domain/User';
import { postGingerMaps } from '../../api/v2/postGingerMaps.api';
import { ProjectWithLocation } from '../../domain/ProjectLocation';

export const generateGingerMapsEpic: Epic = (actions$, state$) =>
  actions$.pipe(
    ofType(gingerMapState.actions.getGingerMaps.type),
    switchMap(() => {
      const state = state$.value;
      const project = selectProject(state) as ExistingProject;
      const user = selectUser(state) as User;

      return from(postGingerMaps(project as ExistingProject & ProjectWithLocation, user)).pipe(
        map(() => gingerMapState.actions.gotGingerMaps())
      );
    })
  );
