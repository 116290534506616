import * as React from 'react';
import SmartRequestsPanelView from './SmartRequestsPanelView';

export class SmartRequestsPanelViewController extends React.Component<any, any> {
  /**
   * Constructor
   * @param props React props
   */
  constructor(props: any) {
    super(props);

    this.state = {
      queryValue: ''
    };

    this.updateQueryValue = this.updateQueryValue.bind(this);
  }

  private updateQueryValue(pEvent: React.FormEvent<HTMLInputElement>) {
    const value: string = pEvent.currentTarget.value;
    this.setState({
      queryValue: value
    });
  }

  /**
   * Rendering method
   */
  public render() {
    return (
      <SmartRequestsPanelView
        queryValue={this.state.queryValue}
        updateQueryValue={this.updateQueryValue}
        onSubmit={(e: any) => {
          e.preventDefault();
          console.log('submit');
        }}
      />
    );
  }
}
