import * as React from 'react';
import { PaginationTop } from './PaginationTop';
import PaginationBottom from 'components/ui/DataTable/PaginationBottom';

interface Props {
  page: number;
  pages: number;
  PageButtonComponent: any;
  onPageChange: (page: number) => void;
  previousText: string;
  nextText: string;
  isTop?: boolean;
}

export default (props: Props) =>
  props.isTop ? <PaginationTop {...props} /> : <PaginationBottom {...props} />;
