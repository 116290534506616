import { Action } from 'redux';
import { ExistingProject } from '../../domain/project/Project';

export const PROJECT_FETCHED = 'PROJECT_FETCHED';

export interface ProjectFetchedAction extends Action {
  type: typeof PROJECT_FETCHED;
  payload: {
    project: ExistingProject;
  };
}

export const projectFetched = (project: ExistingProject): ProjectFetchedAction => ({
  type: PROJECT_FETCHED,
  payload: { project }
});
