import { createSelector, Selector } from 'reselect';
import { selectPath } from './getPath.selector';
import { buildingsRoute } from '../../../routes/toolbox/buildingsPanel/buildingsRoute';
import { deliverablesArchivesRoute } from '../../../routes/toolbox/deliverablesPanel/deliverablesArchivesRoute';
import { projectDetailsEditGeometryRoute } from '../../../routes/toolbox/projectPanel/projectDetailsEditGeometryRoute';
import { sectionsRoute } from '../../../routes/toolbox/sectionsPanel/sectionsRoute';
import { State } from '../../reducers';
import { matchesProject } from '../../../routes/utils/matches/matchesProject';
import { appMenuRoute } from '../../../routes/appMenu/appMenuRoute';
import { caseGranulometryEditGeometryRoute } from '../../../routes/toolbox/casesPanel/caseGranulometryEditGeometryRoute';
import { caseGranulometryRoute } from '../../../routes/toolbox/casesPanel/caseGranulometryRoute';
import { caseRoute } from '../../../routes/toolbox/casesPanel/caseRoute';
import { casesRoute } from '../../../routes/toolbox/casesPanel/casesRoute';
import { buildingRoute } from '../../../routes/toolbox/buildingsPanel/buildingRoute';
import { deliverablesExportRoute } from '../../../routes/toolbox/deliverablesPanel/deliverablesExportRoute';
import { deliverablesExportReportRoute } from '../../../routes/toolbox/deliverablesPanel/deliverablesExportReportRoute';
import { deliverablesRoute } from '../../../routes/toolbox/deliverablesPanel/deliverablesRoute';
import { projectDetailsRoute } from '../../../routes/toolbox/projectPanel/projectDetailsRoute';
import { appMenuEditUserRoute } from '../../../routes/appMenu/appMenuEditUserRoute';
import { sectionsEditRoute } from '../../../routes/toolbox/sectionsPanel/sectionsEditRoute';
import { sectionsIndividualRoute } from '../../../routes/toolbox/sectionsPanel/sectionsIndividualRoute';
import { smartRequestsRoute } from '../../../routes/toolbox/smartRequestsPanel/smartRequestsRoute';

export interface RouteDescriptor {
  isProject: boolean;
  isPanel: boolean;
  isProjectDetails: boolean;
  isProjectDetailsFloorSpace: boolean;
  isBuildings: boolean;
  isBuilding: boolean;
  isCases: boolean;
  isCase: boolean;
  isCaseGranulometry: boolean;
  isSections: boolean;
  isSectionsEdit: boolean;
  isSectionsIndividual: boolean;
  isSmartRequests: boolean;
  isDeliverables: boolean;
  isDeliverablesExport: boolean;
  isDeliverablesExportReport: boolean;
  isDeliverablesArchives: boolean;
  isAppMenu: boolean;
  isAppMenuEditUser: boolean;
  isCaseFloorSpace: boolean;
}

export const selectRoute: Selector<State, RouteDescriptor> = createSelector(
  [selectPath],
  (path) => {
    const isProjectDetails = projectDetailsRoute.matchesPath(path).matches;
    const isBuildings = buildingsRoute.matchesPath(path).matches;
    const isCases = casesRoute.matchesPath(path).matches;
    const isSections = sectionsRoute.matchesPath(path).matches;
    const isSmartRequests = smartRequestsRoute.matchesPath(path).matches;
    const isDeliverables = deliverablesRoute.matchesPath(path).matches;

    const isPanel =
      isProjectDetails || isBuildings || isCases || isSections || isSmartRequests || isDeliverables;

    return {
      isProject: matchesProject(path).matches,
      isPanel,
      isProjectDetails,
      isProjectDetailsFloorSpace: projectDetailsEditGeometryRoute.matchesPath(path).matches,
      isBuildings,
      isBuilding: buildingRoute.matchesPath(path).matches,
      isCases,
      isCase: caseRoute.matchesPath(path).matches,
      isCaseGranulometry: caseGranulometryRoute.matchesPath(path).matches,
      isCaseFloorSpace: caseGranulometryEditGeometryRoute.matchesPath(path).matches, // TODO
      isSections,
      isSectionsEdit: sectionsEditRoute.matchesPath(path).matches,
      isSectionsIndividual: sectionsIndividualRoute.matchesPath(path).matches,
      isSmartRequests,
      isDeliverables,
      isDeliverablesExport: deliverablesExportRoute.matchesPath(path).matches,
      isDeliverablesExportReport: deliverablesExportReportRoute.matchesPath(path).matches,
      isDeliverablesArchives: deliverablesArchivesRoute.matchesPath(path).matches,
      isAppMenu: appMenuRoute.matchesPath(path).matches,
      isAppMenuEditUser: appMenuEditUserRoute.matchesPath(path).matches
    };
  }
);
