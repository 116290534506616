import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { getTopLevelWallsLinear } from '../getTopLevelWallsLinear';
import { getTopLevelTheoreticalOutsideWallsLinear } from './getTopLevelTheoreticalOutsideWallsLinear';

export const getTopLevelTheoreticalInsideWallsLinear = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled | LevelGranulometry
) =>
  getTopLevelWallsLinear(caseGranulometry, levelGranulometry) -
  getTopLevelTheoreticalOutsideWallsLinear(caseGranulometry, levelGranulometry);
