import { Action } from 'redux';

export const PROJECT_CREATION_FAILED = 'PROJECT_CREATION_FAILED';

export interface ProjectCreationFailedAction extends Action {
  type: typeof PROJECT_CREATION_FAILED;
}

export const projectCreationFailed = (): ProjectCreationFailedAction => ({
  type: PROJECT_CREATION_FAILED
});
