import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { CaseGranulometry } from '../../../CaseGranulometry';
import { LevelGranulometry } from '../../../../levels/LevelGranulometry';
import {
  getLevelLodgmentsCountByType,
  LodgmentsCountByType
} from '../../../../levels/queries/topLevels/counts/getTopLevelLodgmentsCountByType';

export type LodgmentsCountByTopLevelAndType = {
  [level: string]: LodgmentsCountByType;
};

type GetCaseLodgmentSectionsCountByTopLevelAndType = (
  caseGranulometry: CaseGranulometry
) => LodgmentsCountByTopLevelAndType;

export const getCaseLodgmentsCountByTopLevelAndType: GetCaseLodgmentSectionsCountByTopLevelAndType =
  R.when(
    RA.isNotNil,
    R.pipe<
      [CaseGranulometry],
      LevelGranulometry[],
      { [l: string]: LevelGranulometry },
      LodgmentsCountByTopLevelAndType
    >(
      R.prop('levels'),
      R.indexBy(R.compose(R.toString, R.prop('level'))),
      R.mapObjIndexed(getLevelLodgmentsCountByType)
    )
  );
