import { ProjectProjection } from '../../ProjectProjection';
import { getProjectCasesProjections } from './getProjectCasesProjections';

export const getProjectSumOfCasesRealBuiltSurfaces = (
  projectProjection: ProjectProjection
): number =>
  getProjectCasesProjections(projectProjection).reduce(
    (acc, eachCase) => acc + (eachCase.projectedRealBuiltSurface?.value || 0),
    0
  );
