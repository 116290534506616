import * as React from 'react';

const ScrollGradient = () => (
  <div
    className="scroll-gradient"
    onClick={() => {
      const scroller = document.querySelector('.granulometry > div > div');
      if (scroller !== null) {
        scroller.scrollTop += scroller.clientHeight;
      }
    }}
    onWheel={(event) => {
      const scroller = document.querySelector('.granulometry > div > div');
      if (scroller !== null) {
        if (event.deltaY < 0) {
          // up
          scroller.scrollTop -= scroller.clientHeight;
        } else if (event.deltaY > 0) {
          // down
          scroller.scrollTop += scroller.clientHeight;
        }
      }
    }}
  />
);

export default ScrollGradient;
