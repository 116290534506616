import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelOutsideStructuralWallsThickness = (
  caseGranulometry: CaseGranulometry
): number =>
  getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'outsideWallsThickness'
  ) as number;
