import { matchesPath } from '../utils/matchesPath';

import { RouteWithMakePath } from '../RouteWithMakePath';

export type AppMenuRouteParams = { projectId: string };
export const appMenuRoute: RouteWithMakePath<AppMenuRouteParams> = {
  path: `/project/:projectId/appMenu`,
  makePath: ({ projectId }) => `/project/${projectId}/appMenu`,
  matchesPath: matchesPath<AppMenuRouteParams>(/\/project\/([^/]+)\/appMenu/, {
    projectId: 1
  })
};
