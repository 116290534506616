import * as R from 'ramda';
import { CaseGranulometry } from '../../../CaseGranulometry';
import { LevelGranulometry } from '../../../../levels/LevelGranulometry';
import { Section } from '../../../../sections/Section';
import { ParkingName } from '../../../../sections/parkingSections/ParkingSection';
import { getLevelSections } from '../../../../levels/queries/sections/getLevelSections';

export const getCaseCarBoxInfraSectionsCount = (caseGranulometry: CaseGranulometry): number =>
  R.pipe<
    [CaseGranulometry],
    LevelGranulometry[],
    LevelGranulometry[],
    Section[],
    Section[],
    number
  >(
    R.prop('levels'),
    R.filter((level: LevelGranulometry) => level.level < 0),
    R.chain(getLevelSections),
    R.filter((section: Section) => section.name === ParkingName.CarBoxInfra),
    R.length
  )(caseGranulometry);
