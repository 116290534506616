import { RoomSpecification } from './RoomSpecification';
import { IconObjectProps } from '../../../components/ui/Icons/iconObject';

export enum BodyguardType {
  Steel = 'steel',
  Glazed = 'glazed'
}

export const BodyguardTypeInArray = [BodyguardType.Steel, BodyguardType.Glazed];

export type BalconyName = 'balcony';

export interface BalconySpecification {
  name: BalconyName;
  title: string; // label :-(
  depth?: number;
  defaultDepth: number;
  surface?: number;
  bodyguardType: BodyguardType;
  icon: IconObjectProps; // DURTY
}

export const isRoomSpecificationABalcony = (
  roomSpecification: RoomSpecification
): roomSpecification is BalconySpecification => roomSpecification.name === 'balcony';
