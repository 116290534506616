import * as R from 'ramda';
import { CountByLodgmentType } from './redistributeCaseWithCustomDistribution';
import { LodgmentSection } from '../../sections/lodgmentSections/LodgmentSection';

export const getLodgmentSurfaceFromDistributionAndLodgmentSections = (
  distribution: CountByLodgmentType,
  lodgmentSections: LodgmentSection[]
): number =>
  R.pipe<[CountByLodgmentType], [string, number][], number>(
    R.toPairs,
    R.reduce(
      (acc, countByType) =>
        acc +
        (lodgmentSections.find((l) => l.lodgmentType === (countByType as [string, number])[0])
          ?.minimumDisplayedSurface || 0) *
          (countByType as [string, number])[1],
      0
    )
  )(distribution);
