import { APP_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { getCasePropertyDefaultValue } from '../../../specification/cases/queries/get/properties/getCasePropertyDefaultValue';
import { CirculationName } from './CirculationSection';
import { SectionWithTheoreticalSurface } from '../SectionWithTheoreticalSurface';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';
import { SectionWithWidth } from '../SectionWithWidth';
import { SectionWithDepth } from '../isSectionWithDepth';

interface SmokeExtractionDuctsProps {
  surface: number;
  depth: number;
  width: number;
  group: string;
}

export interface SmokeExtractionDuctsSection
  extends Section,
    SectionDrawn,
    SectionWithWidth,
    SectionWithDepth,
    SectionWithTheoreticalSurface {
  name: CirculationName.SmokeExtractionDucts;
}

export const SMOCK_EXTRACTION_DUCTS_ICON = {
  type: 'object',
  iconName: 'smokeExhaustDust',
  color: APP_COLOR
} as IconObjectProps;

const smokeExtractionDucts = ({
  surface,
  depth,
  width,
  group
}: SmokeExtractionDuctsProps): SmokeExtractionDuctsSection => {
  return {
    // Section
    id: 'NOID',
    name: CirculationName.SmokeExtractionDucts,
    title: i18next.t('smokeExtractionDucts.title'),
    type: 'circulation',
    // SectionDrawn
    surface,
    displayedSurface: surface,
    color: APP_COLOR,
    icon: SMOCK_EXTRACTION_DUCTS_ICON,
    group,
    // SectionWithTheoreticalSurface
    theoreticalSurface: surface,
    defaultTheoreticalSurface:
      getCasePropertyDefaultValue('smokeExtractionDuctsDepth') *
      getCasePropertyDefaultValue('smokeExtractionDuctsWidth'),
    // SmokeExtractionDuctsSection
    depth,
    width
  };
};

export default smokeExtractionDucts;

export const isSectionASmokeExtractionDucts = (
  section: Section
): section is SmokeExtractionDuctsSection =>
  getSectionName(section) === CirculationName.SmokeExtractionDucts;
