import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import privateRoutes from 'routes/privateRoutes';
import publicRoutes from 'routes/publicRoutes';
import { DefaultFallback } from 'DefaultFallback';
import { selectIsUserStateRehydrated } from 'store/selectors/user/isUserStateRehydrated.selector';
import { selectIsValidSession } from 'store/selectors/user/isValidSession.selector';
import SimpleOpenProject from 'components/business/projects/SimpleOpenProject';
import DuplicateProjectView from 'components/business/projects/DuplicateProjectView';
import ProjectsView from 'components/business/projects/ProjectsView';
import { ProjectScreen } from 'ui/project/ProjectScreen';
import { Login as LoginUI } from 'ui/login';
import { RenewUserSession } from './components/layouts/renewUserSession/RenewUserSession';
import { selectSessionState } from './store/selectors/user/sessionState.selector';
import { selectUser } from './store/selectors/user';

export const App = () => {
  const isUserStateRehydrated = useSelector(selectIsUserStateRehydrated);
  const isValidSession = useSelector(selectIsValidSession);
  const sessionState = useSelector(selectSessionState);
  const user = useSelector(selectUser);

  const { Login } = publicRoutes;
  const { Root, OpenProject, DuplicateProject, Projects, Project } = privateRoutes;

  return isUserStateRehydrated ? (
    <Sentry.ErrorBoundary fallback={DefaultFallback} showDialog>
      <Switch>
        <Route exact path={Login.path} component={LoginUI} />
        <Route>
          <Switch>
            <Redirect exact from={Root.path} to={isValidSession ? Projects.path : Login.path} />
            {!!user && (
              <>
                <Route exact path={OpenProject.path} component={SimpleOpenProject} />
                <Route exact path={DuplicateProject.path} component={DuplicateProjectView} />
                <Route exact path={Projects.path} component={ProjectsView} />
                <Route path={Project.path} component={ProjectScreen} />
              </>
            )}
          </Switch>
          {(sessionState === 'EXPIRED' ||
            sessionState === 'UNAUTHENTICATED' ||
            sessionState === 'RENEWING' ||
            sessionState === 'AUTHENTICATING' ||
            sessionState === 'DENIED') && <RenewUserSession />}
        </Route>
      </Switch>
    </Sentry.ErrorBoundary>
  ) : null;
};
