import { CaseGranulometry } from '../../CaseGranulometry';
import { HeatingSystem } from '../../../../specification/lodgmentTypes/HeatingSystem';
import { hasCaseLodgmentsWithDistrictHeating } from '../has/hasCaseLodgmentsWithDistrictHeating';
import { hasCaseLodgmentsWithCollectiveGasHeating } from '../has/hasCaseLodgmentsWithCollectiveGasHeating';
import { hasCaseLodgmentsWithCollectiveHeatPumpOnGroundWaterHeating } from '../has/hasCaseLodgmentsWithCollectiveHeatPumpOnGroundWaterHeating';
import { hasCaseLodgmentsWithCollectiveWoodHeating } from '../has/hasCaseLodgmentsWithCollectiveWoodHeating';
import { getCaseLodgmentSectionsWithIndividualGasHeatingCount } from '../sections/counts/getCaseLodgmentSectionsWithIndividualGasHeatingCount';
import { getCaseLodgmentSectionsWithIndividualElectricHeatingCount } from '../sections/counts/getCaseLodgmentSectionsWithIndividualElectricHeatingCount';

export const getCaseAverageHeatingSystem = (caseGranulometry: CaseGranulometry): HeatingSystem =>
  hasCaseLodgmentsWithDistrictHeating(caseGranulometry)
    ? HeatingSystem.DistrictHeating
    : hasCaseLodgmentsWithCollectiveGasHeating(caseGranulometry)
    ? HeatingSystem.CollectiveGasHeating
    : hasCaseLodgmentsWithCollectiveHeatPumpOnGroundWaterHeating(caseGranulometry)
    ? HeatingSystem.CollectiveHeatPumpOnGroundWaterHeating
    : hasCaseLodgmentsWithCollectiveWoodHeating(caseGranulometry)
    ? HeatingSystem.CollectiveWoodHeating
    : getCaseLodgmentSectionsWithIndividualElectricHeatingCount(caseGranulometry) >
      getCaseLodgmentSectionsWithIndividualGasHeatingCount(caseGranulometry)
    ? HeatingSystem.IndividualElectricHeating
    : HeatingSystem.IndividualGasHeating;
