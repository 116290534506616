import { LivingRoomKitchenSpecification } from '../../LivingRoomKitchenSpecification';
import { getLivingRoomKitchenSpecificationWidth } from './getLivingRoomKitchenSpecificationWidth';
import { getLivingRoomKitchenSpecificationCalculatedSurface } from './getLivingRoomKitchenSpecificationCalculatedSurface';
import { RoomSpecification } from '../../RoomSpecification';

type GetLivingRoomKitchenSpecificationCalculatedLength = (
  lodgmentRooms: RoomSpecification[],
  lodgmentSurface: number,
  roomSpecification: LivingRoomKitchenSpecification
) => number;
export const getLivingRoomKitchenSpecificationCalculatedLength: GetLivingRoomKitchenSpecificationCalculatedLength =
  (lodgmentRooms, lodgmentSurface, roomSpecification) =>
    getLivingRoomKitchenSpecificationCalculatedSurface(lodgmentRooms, lodgmentSurface) /
    getLivingRoomKitchenSpecificationWidth(roomSpecification);
