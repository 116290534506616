import { createSelector, Selector } from 'reselect';
import { getUserState, State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';
import { AuthToken } from '../../../AuthToken';
import { isLoggedIn } from '../../reducers/user.slice';

export const selectUserToken: Selector<State, Maybe<AuthToken>> = createSelector(
  [getUserState],
  (user) => (isLoggedIn(user) ? user.auth?.token : undefined) // auth? to prevent error when admin is down
);
