export const LOTS_NOMENCLATURE_ID = '1';
export const CONTRIBUTEURS_NOMENCLATURE_ID = '2';
export const UNTEC_NOMENCLATURE_ID = '3';

export interface NomenclatureElement {
  id:
    | typeof LOTS_NOMENCLATURE_ID
    | typeof CONTRIBUTEURS_NOMENCLATURE_ID
    | typeof UNTEC_NOMENCLATURE_ID;
  nomenclatureId: string;
  reference: string;
  title: string;
  description?: string;
}

export interface Nomenclature {
  id:
    | typeof LOTS_NOMENCLATURE_ID
    | typeof CONTRIBUTEURS_NOMENCLATURE_ID
    | typeof UNTEC_NOMENCLATURE_ID;
  reference?: string;
  title: string;
  description: string;
  elements: NomenclatureElement[];
}
