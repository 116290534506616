import { getHeight } from './getHeight';
import { getWidth } from './getWidth';
import { getHighestChildCase } from './getHighestChildCase';
import { buildingMapFocused } from '../../store/actions/buildingMap/buildingMapFocused.action';
import { Dispatch } from 'react';
import { BuildingMapFocus } from '../../domain/buildingMap/BuildingMapFocus';
import { Maybe } from '../Maybe';

export const addElementOnMask = (
  element: Element,
  isGroupElement: boolean,
  zoomValue: number,
  elementOffsetForTop: DOMRect,
  elementOffsetForLeft: DOMRect,
  buildingMapOffset: DOMRect,
  calculatedOnce?: Maybe<boolean>,
  dispatch?: Dispatch<{ payload: { focus: BuildingMapFocus } }>
) => {
  const buildingMap = document.querySelector('.buildingMap');
  const mask = document.querySelector('.mask');
  if (mask && buildingMap) {
    const maskTop = (elementOffsetForTop.top - buildingMapOffset.top) * (1 / zoomValue);
    const maskLeft = (elementOffsetForLeft.left - buildingMapOffset.left) * (1 / zoomValue);
    const clone = element.cloneNode(true);
    mask.appendChild(clone);
    const clonedElement = mask.querySelector('#' + element.id);
    if (clonedElement) {
      // Re-init the click event on sections
      if (calculatedOnce && dispatch) {
        clonedElement.querySelectorAll('.section').forEach((section) => {
          section.addEventListener('click', () =>
            dispatch(buildingMapFocused({ section: section.id.replace(/section/, '') }))
          );
          const sectionGroup = section.attributes.getNamedItem('data-group')?.nodeValue;
          if (sectionGroup) {
            section.addEventListener('mouseenter', () => {
              const groupElements = document.querySelectorAll(
                '.section[data-group="' + sectionGroup + '"]'
              );
              for (let i = 0; i < groupElements.length; i = i + 1) {
                groupElements[i].classList.add('hovered');
              }
            });
            section.addEventListener('mouseleave', () => {
              const groupElements = document.querySelectorAll(
                '.section[data-group="' + sectionGroup + '"]'
              );
              for (let i = 0; i < groupElements.length; i = i + 1) {
                groupElements[i].classList.remove('hovered');
              }
            });
          }
        });
      }
      clonedElement.setAttribute(
        'style',
        'position:absolute;top:' +
          maskTop +
          'px;left:' +
          maskLeft +
          'px;height:' +
          getHeight(element, isGroupElement) +
          'px;width:' +
          getWidth(element, isGroupElement) +
          'px'
      );
      if (clonedElement.attributes.getNamedItem('onclick')) {
        clonedElement.attributes.removeNamedItem('onclick');
      }
    }

    if (element.classList.contains('section')) {
      mask.classList.add('sectionFocused');
    } else if (element.classList.contains('iconObject')) {
      mask.classList.add('iconObjectFocused');
    } else if (element.classList.contains('case')) {
      mask.classList.add('caseFocused');
    } else if (element.classList.contains('building')) {
      mask.classList.add('buildingFocused');
      const highestChildCase = getHighestChildCase(element) as HTMLElement;
      if (highestChildCase !== undefined && clonedElement) {
        const cases: NodeListOf<HTMLElement> = clonedElement.querySelectorAll('.case');
        const maxMarginBottom = highestChildCase.style.marginBottom;
        for (let i = 0; i < cases.length; ++i) {
          cases[i].style.top = maxMarginBottom;
        }
      }
    }
    mask.classList.add('displayed-mask');
  }
};
