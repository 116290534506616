import { CaseSpecification } from '../../../specification/cases/CaseSpecification';
import { CaseProjection } from '../CaseProjection';
import { Surface } from '../../../specification/Surface';
import { getCaseProjectedMinRbs } from './caseSurfaceProjection/getCaseProjectedMinRbs';
import { getRatioCbsToSfs } from './caseSurfaceProjection/getRatioCbsToSfs';
import { calculateMaxSurfaceForSale } from './caseSurfaceProjection/calculateMaxSurfaceForSale';
import { setCaseProjectedDistribution } from './setCaseProjectedDistribution';
import { setCaseProjectedProperties } from './setCaseProjectedProperties';
import { setCaseProjectedTopLevelsCount } from './levels/topLevelsCount/setCaseProjectedTopLevelsCount';
import { getCaseTechnicalPremiseSectionsSurface } from '../../../specification/cases/queries/get/surfaces/getCaseTechnicalPremiseSectionsSurface';
import { getCaseProjectedCbsFromSpecifications } from './caseSurfaceProjection/getCaseProjectedCbsFromSpecifications';
import { getCaseProjectedSfsFromSpecifications } from './caseSurfaceProjection/getCaseProjectedSfsFromSpecifications';
import { getCaseProjectedRbsFromSpecifications } from './caseSurfaceProjection/getCaseProjectedRbsFromSpecifications';
import { getCaseProjectedHallSurface } from './caseSurfaceProjection/getCaseProjectedHallSurface';
import { getCaseProjectedBasementLevelCount } from './caseSurfaceProjection/getCaseProjectedBasementLevelCount';
import { getCaseProjectedTheoreticalRbs } from './caseSurfaceProjection/getCaseProjectedTheoreticalRbs';
import { getRatioRbsToCbs } from './caseSurfaceProjection/getRatioRbsToCbs';
import { getRatioRbsToSfs } from './caseSurfaceProjection/getRatioRbsToSfs';
import { setCaseProjectedLevels } from './setCaseProjectedLevels';

/* Note : Case lifecycle = CaseSpecification -> CaseProjection -> CaseFormDataLegacy -> CaseGranulometry */

export const getCaseProjectionFromCaseSpecification =
  (transmitedCaseCuttedBuiltSurface: Surface, withMaxSfs?: boolean, forcedMinRbs?: Surface) =>
  (caseSpecification: CaseSpecification): CaseProjection => {
    const projectedCuttedBuiltSurface = getCaseProjectedCbsFromSpecifications(
      caseSpecification,
      transmitedCaseCuttedBuiltSurface
    );
    const projectedSurfaceForSale = getCaseProjectedSfsFromSpecifications(
      caseSpecification,
      projectedCuttedBuiltSurface
    );
    const ratioCbsToSfs = getRatioCbsToSfs(projectedSurfaceForSale, projectedCuttedBuiltSurface);
    const projectedHallSurface = getCaseProjectedHallSurface(caseSpecification);
    const projectedTechnicalPremiseSectionsSurface =
      getCaseTechnicalPremiseSectionsSurface(caseSpecification);
    const projectedTheoreticalRealBuiltSurface = getCaseProjectedTheoreticalRbs(
      projectedCuttedBuiltSurface
    );
    const projectedMinRealBuiltSurface =
      forcedMinRbs ||
      getCaseProjectedMinRbs(
        projectedTheoreticalRealBuiltSurface,
        projectedTechnicalPremiseSectionsSurface,
        ratioCbsToSfs
      );
    const projectedRealBuiltSurface = getCaseProjectedRbsFromSpecifications(
      caseSpecification,
      projectedMinRealBuiltSurface
    );
    const projectedBasementLevelsCount = getCaseProjectedBasementLevelCount(
      caseSpecification,
      projectedRealBuiltSurface
    );
    const tempEmptySurface = new Surface(0);

    // It will be calculated after a granulometry run

    let caseProjection: CaseProjection = {
      ...caseSpecification,
      projectedDistribution: [], // It will be calculated after in setCaseProjectedDistribution
      projectedProperties: [], // It will be calculated after in setCaseProjectedProperties
      projectedTopLevelsCount: 0, // It will be calculated after in setCaseProjectedTopLevelsCount
      projectedBasementLevelsCount,
      projectedTheoreticalRealBuiltSurface,
      projectedRealBuiltSurface,
      projectedCuttedBuiltSurface,
      projectedSurfaceForSale,
      projectedMinRealBuiltSurface,
      projectedHallSurface,
      projectedTechnicalPremiseSectionsSurface,
      projectedMaxSurfaceForSale: tempEmptySurface, // It will be calculated after in calculateMaxSurfaceForSale
      projectedMaxSurfaceForSaleHasBeenForced: false,
      projectedMaxSurfaceForSaleByLevels: [],
      projectedSumOfMinimumBearingSurface: tempEmptySurface, // It will be calculated after in calculateMaxSurfaceForSale
      projectedRatioRbsToCbs: getRatioRbsToCbs(
        projectedCuttedBuiltSurface,
        projectedRealBuiltSurface
      ),
      projectedRatioCbsToSfs: ratioCbsToSfs,
      projectedRatioRbsToSfs: getRatioRbsToSfs(projectedSurfaceForSale, projectedRealBuiltSurface),
      projectedTopLevels: [], // It will be calculated after in setCaseProjectedLevel
      projectedBasementLevels: [] // It will be calculated after in setCaseProjectedLevel
    };

    caseProjection = setCaseProjectedDistribution(caseProjection);

    caseProjection = setCaseProjectedProperties(caseProjection);

    caseProjection = setCaseProjectedTopLevelsCount(caseProjection);

    caseProjection = setCaseProjectedLevels(caseProjection);

    // Calculate Max SFS
    if (withMaxSfs) {
      caseProjection = calculateMaxSurfaceForSale(caseProjection);
    }

    return caseProjection;
  };
