import * as React from 'react';
import { ReactNode } from 'react';
import classNames from 'classnames';

interface DataBarSegmentProps {
  children: ReactNode | ReactNode[];
  className: string;
}

export const DataBarSegment = ({ children, className }: DataBarSegmentProps) => (
  <div className={classNames('data-bar-segment', className)}>{children}</div>
);
