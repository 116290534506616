import * as R from 'ramda';
import { ReportEntry } from '../../domain/report/ReportEntry';
import { roundWith2Decimal } from '../../utils/round/roundWith2Decimal';
import { sumCo2QuantitiesInReportBy } from '../utils/sumCo2QuantitiesInReportBy';
import { sortReportByRefSsLot2 } from '../utils/sortReportByRefSsLot2';

export interface CypeJsonEntry {
  case: string;
  product: string;
  co2Quantity: number;
  co2QuantityUnit: string;
  co2Id: string;
  refLot: string;
  refSsLot1: string;
}

export type CypeJson = CypeJsonEntry[];

export const makeCypeJson = (reportEntries: ReportEntry[]): CypeJson =>
  R.pipe<[ReportEntry[]], ReportEntry[], ReportEntry[], CypeJson>(
    sumCo2QuantitiesInReportBy(['case', 'product']),
    sortReportByRefSsLot2,
    R.reduce((acc, reportEntry) => {
      const hasToBeSkipped =
        reportEntry.co2Quantity === 0 ||
        isNaN(reportEntry.co2Quantity) ||
        !!reportEntry.co2Id.match(/[a-zA-Z]/);
      return !hasToBeSkipped
        ? [
            ...acc,
            {
              case: reportEntry.case,
              product: reportEntry.product,
              co2Quantity: roundWith2Decimal(reportEntry.co2Quantity),
              co2QuantityUnit: reportEntry.co2QuantityUnit,
              co2Id: reportEntry.co2Id,
              refLot: reportEntry.refLot,
              refSsLot1: reportEntry.refSsLot1
            }
          ]
        : acc;
    }, [] as CypeJson)
  )(reportEntries);
