import { Section } from './Section';
import { isSectionABearing } from './circulationSections/bearing';
import { isSectionARamp } from './circulationSections/ramp';
import { isSectionARampInfra } from './circulationSections/rampInfra';
import { isSectionABearingInfra } from './circulationSections/bearingInfra';
import { isSectionACarBoxInfra } from './parkingSections/carBoxInfra';
import { isSectionACarParkInfra } from './parkingSections/carParkInfra';
import { isSectionACirculationLaneInfra } from './circulationSections/circulationLaneInfra';
import { isSectionAElevatorShaft } from './circulationSections/elevatorShaft';
import { isSectionAElevatorShaftInfra } from './circulationSections/elevatorShaftInfra';
import { isSectionAHall } from './circulationSections/hall';
import { isSectionASasInfra } from './circulationSections/sasInfra';
import { isSectionAStairsShaft } from './circulationSections/stairsShaft';
import { isSectionAStairsShaftInfra } from './circulationSections/stairsShaftInfra';
import { isSectionAVentilationGroupInfra } from './circulationSections/ventilationGroupInfra';
import { isSectionASmokeExtractionDucts } from './circulationSections/smokeExtractionDucts';
import { isSectionAnTechnicalPremise } from './technicalPremiseSections/TechnicalPremiseSection';
import { isSectionACommonPremise } from './commonPremiseSections/CommonPremiseSection';
import { isSectionAShop } from './shopSection/shopSection';
import { isSectionAnOffice } from './officeSection/officeSection';
import { isSectionAnOutsideStairsShaft } from './outsideSections/outsideStairShaft';
import { isSectionAnOutsideStairsShaftInfra } from './outsideSections/outsideStairShaftInfra';
import { isSectionAParking } from './parkingSections/ParkingSection';

export interface SectionWithWidth extends Section {
  width: number;
}

export const isSectionWithWidth = (section: Section): section is SectionWithWidth =>
  isSectionABearing(section) ||
  isSectionABearingInfra(section) ||
  isSectionARamp(section) ||
  isSectionARampInfra(section) ||
  isSectionACarBoxInfra(section) ||
  isSectionACarParkInfra(section) ||
  isSectionACirculationLaneInfra(section) ||
  isSectionAElevatorShaft(section) ||
  isSectionAElevatorShaftInfra(section) ||
  isSectionAStairsShaft(section) ||
  isSectionAStairsShaftInfra(section) ||
  isSectionAnOutsideStairsShaft(section) ||
  isSectionAnOutsideStairsShaftInfra(section) ||
  isSectionASmokeExtractionDucts(section) ||
  isSectionAStairsShaft(section) ||
  isSectionAStairsShaftInfra(section) ||
  isSectionAHall(section) ||
  isSectionAnTechnicalPremise(section) ||
  isSectionACommonPremise(section) ||
  isSectionAShop(section) ||
  isSectionAnOffice(section) ||
  isSectionASasInfra(section) ||
  isSectionAVentilationGroupInfra(section) ||
  isSectionAParking(section);
