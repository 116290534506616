import { RoomStaticElement } from './RoomStaticElement';

export const balconyElements: RoomStaticElement[] = [
  { e1: 'garde-corps', e2: '', e3: '', quantity: 1 },
  { e1: 'dalette sur plot', e2: '', e3: '', quantity: 1 },
  { e1: 'peinture minérale extérieure pour support béton', e2: '', e3: '', quantity: 1 },
  { e1: 'prise de courant étanche', e2: '', e3: '', quantity: 1 },
  { e1: 'éclairage applique', e2: '', e3: '', quantity: 1 },
  { e1: 'lustrerie', e2: '', e3: '', quantity: 1 },
  { e1: 'interrupteur', e2: '', e3: '', quantity: 1 },
  { e1: 'dalle intérieure', e2: '', e3: '', quantity: 1 },
  { e1: 'dalle intérieure', e2: 'coffrage dalle', e3: '', quantity: 1 },
  { e1: 'dalle intérieure', e2: 'béton dalle superstructure', e3: '', quantity: 1 },
  { e1: 'dalle intérieure', e2: 'acier haute adhérence dalle béton', e3: '', quantity: 1 },
  { e1: 'dalle intérieure', e2: 'acier treillis soudé dalle béton', e3: '', quantity: 1 },
  { e1: 'dalle intérieure', e2: 'surfaçage soignée avec forme de pente', e3: '', quantity: 1 },
  { e1: 'relevé béton', e2: '', e3: '', quantity: 1 },
  { e1: 'relevé béton', e2: 'coffrage voile exposé', e3: '', quantity: 1 },
  { e1: 'relevé béton', e2: 'béton voile exposé', e3: '', quantity: 1 },
  { e1: 'relevé béton', e2: 'acier haute adhérence voile exposé', e3: '', quantity: 1 },
  { e1: 'glacis', e2: '', e3: '', quantity: 1 }
];
