import { createSelector, Selector } from 'reselect';
import { selectComEthReport } from './comEthReport.selector';
import { State } from '../../reducers';
import { ComEthReportBuilding } from '../../../api/cstb/comEth/comEthReport/ComEthReportBuilding';
import { Maybe } from '../../../utils/Maybe';
import { selectBuildingMapFocus } from '../buildingMap/buildingMapFocus.selector';
import { BuildingFocus, CaseFocus } from '../../../domain/buildingMap/BuildingMapFocus';
import { getBuildingIdFromCaseId } from '../../../domain/core/queries/getBuildingIdFromCaseId';
import { selectProject } from '../project';
import { ExistingProject } from '../../../domain/project/Project';

export const selectCurrentBuildingInComEthReport: Selector<
  State,
  Maybe<ComEthReportBuilding>
> = createSelector(
  [selectProject, selectComEthReport, selectBuildingMapFocus],
  (project, cstbReport, buildingMapFocus) => {
    const buildingId =
      (buildingMapFocus as BuildingFocus).building ||
      (project &&
        getBuildingIdFromCaseId(project as ExistingProject, (buildingMapFocus as CaseFocus).case));
    return cstbReport?.Children?.find((child) => child.gobuildBuildingId === buildingId);
  }
);
