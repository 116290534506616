import { createSelector, Selector } from 'reselect';
import { ComEthJson, makeComEthJson } from '../../../api/cstb/comEth/makeComEthJson';
import { Maybe } from '../../../utils/Maybe';
import { State } from '../../reducers';
import { selectProject } from '../project';
import { selectGranulometry } from '../granulometry/current.selector';
import { selectContributeursReport } from '../contributeursReport.selector';
import { selectContributeursNomenclature } from '../contributeursNomenclature.selector';

export const selectComEthJson: Selector<State, Maybe<ComEthJson>> = createSelector(
  [selectProject, selectContributeursReport, selectGranulometry, selectContributeursNomenclature],
  (project, report, granulometry, nomenclature) =>
    project && report && granulometry && nomenclature
      ? makeComEthJson(project, report, granulometry, nomenclature)
      : undefined
);
