import i18next from 'i18next';
import { BasicRoomSpecification } from '../../specification/rooms/BasicRoomSpecification';

const DEFAULT_BEDROOM_4_LENGTH = 3.8;
const DEFAULT_BEDROOM_4_WIDTH = 3;

const bedroom4 = (): BasicRoomSpecification =>
  ({
    name: 'bedroom4',
    title: i18next.t('Bedroom 4'),
    defaultLength: DEFAULT_BEDROOM_4_LENGTH,
    width: undefined,
    defaultWidth: DEFAULT_BEDROOM_4_WIDTH,
    surface: undefined,
    icon: {
      type: 'object',
      iconName: 'bedroom',
      strokeStyle: 'opened',
      label: '4'
    }
  } as const);

export default bedroom4;
