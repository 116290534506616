import { cleanFileName } from '../utils/cleanFileName';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { SustainEchoJsonEntry } from '../../../../../api/sustainEcho/makeSustainEchoJson';

export const downloadSustainEcho = async (
  report: Partial<SustainEchoJsonEntry>[],
  projectName: string
) => {
  const fileName =
    'gobuild-' +
    cleanFileName(projectName) +
    '-sustainecho-' +
    new Date().toISOString().slice(0, 10) +
    '.csv';
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(
    projectName.replace(/[*?:\\/[\]]/g, ' ') + ' – SustainEcho'
  );
  worksheet.columns = [
    { header: 'Bâtiment/Parcelle', key: 'building', style: { alignment: { wrapText: true } } },
    { header: 'Zone', key: 'case', style: { alignment: { wrapText: true } } },
    { header: 'Dossier', key: 'projectName', style: { alignment: { wrapText: true } } },
    { header: 'Composant', key: 'element', style: { alignment: { wrapText: true } } },
    { header: 'Lot', key: 'refLot', style: { alignment: { wrapText: true } } },
    { header: 'Sous-lot', key: 'refSsLot1', style: { alignment: { wrapText: true } } },
    {
      header: 'Donnée environnementale (DE)',
      key: 'co2Title',
      style: { alignment: { wrapText: true } }
    },
    {
      header: 'ID-DE',
      key: 'co2Id',
      style: { alignment: { wrapText: true } }
    },
    { header: 'Quantité', key: 'co2Quantity', style: { alignment: { wrapText: true } } },
    { header: 'Unité (de la DE)', key: 'unit', style: { alignment: { wrapText: true } } },
    { header: 'DVE', key: 'dve', style: { alignment: { wrapText: true } } },
    { header: 'Type de DE', key: 'deType', style: { alignment: { wrapText: true } } },
    { header: 'Commentaires', key: 'comments', style: { alignment: { wrapText: true } } }
  ];
  worksheet.addRows(report, 'i');
  workbook.csv
    .writeBuffer({
      formatterOptions: {
        delimiter: ',',
        rowDelimiter: ',\u000D\u000A', // /r/n
        writeBOM: true
      }
    })
    .then(function (buffer) {
      FileSaver.saveAs(
        new Blob([/* new Uint8Array([0xef, 0xbb, 0xbf]), */ buffer], {
          type: 'text/plain;charset=utf-8'
        }),
        fileName
      );
    });
};
