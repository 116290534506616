import * as React from 'react';
import { PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import {
  ArrowFromCBSToSFSEnd,
  ArrowFromCBSToSFSStart,
  ArrowFromRBSToCBSEnd,
  ArrowFromRBSToCBSStart,
  ArrowFromRBSToSFSEnd,
  ArrowFromRBSToSFSStart
} from './Arrows';
import { selectCurrentCaseProjection } from '../../../../store/selectors/projection/currentCase/projection.selector';
import { roundWith2Decimal } from '../../../../utils/round/roundWith2Decimal';
import { Option, Select } from '../../../ui/Select';
import { CaseGrossFloorSurfaceEffInput } from './CaseGrossFloorSurfaceEffInput';
import { CaseRealBuiltSurfaceInput } from './CaseRealBuiltSurfaceInput';
import { CaseGrossFloorSurfaceInput } from './CaseGrossFloorSurfaceInput';
import { CaseCuttedBuiltSurfaceInput } from './CaseCuttedBuiltSurfaceInput';
import { CaseSurfaceForSaleInput } from './CaseSurfaceForSaleInput';
import { selectCurrentCaseGrossFloorSurfaceEff } from '../../../../store/selectors/granulometry/currentCase/grossFloorSurfaceEff.selector';
import { selectCurrentCaseGrossFloorSurface } from '../../../../store/selectors/granulometry/currentCase/grossFloorSurface.selector';
import { CaseSurfacesDetails } from './CaseSurfacesDetails';
import { getCaseGranulometryEditGeometryTriggerId } from '../../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditGeometryTriggerId';
import { selectCurrentCaseLabel } from '../../../../store/selectors/project/currentCaseLabel.selector';

const Rate = ({ children }: PropsWithChildren) => (
  <div
    style={{
      width: '18%',
      maxWidth: '55px',
      padding: '0 2px',
      boxSizing: 'border-box',
      fontSize: '13px',
      textAlign: 'center'
    }}>
    {children}
  </div>
);

const toPercent = (ratio: number) => (ratio ? roundWith2Decimal(ratio * 100) : '?');

interface SurfacesCalculationProps {
  isToolboxEnlarged: boolean;
}

export const SurfacesCalculation = ({ isToolboxEnlarged }: SurfacesCalculationProps) => {
  const caseProjection = useSelector(selectCurrentCaseProjection);
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const grossFloorSurfaceEff = useSelector(selectCurrentCaseGrossFloorSurfaceEff);
  const grossFloorSurface = useSelector(selectCurrentCaseGrossFloorSurface);

  const surfacesForColumn1Options: Option[] = [
    {
      label: 'SHOB VPP',
      value: 'GFSEFF'
    },
    {
      label: 'SHOB',
      value: 'GFS'
    },
    {
      label: 'SdP Réelle',
      value: 'RBS'
    }
  ];

  const [selectedSurfaceForColumn1, setSelectedSurfaceForColumn1] = React.useState<Option>(
    surfacesForColumn1Options[0]
  );

  const isGfsEffSelected = selectedSurfaceForColumn1.value === 'GFSEFF';
  const isGfsSelected = selectedSurfaceForColumn1.value === 'GFS';
  const isRbsSelected = selectedSurfaceForColumn1.value === 'RBS';
  const ratio1 = Math.max(
    0,
    caseProjection.projectedCuttedBuiltSurface.value /
      (isGfsEffSelected
        ? grossFloorSurfaceEff?.value || 1
        : isGfsSelected
        ? grossFloorSurface?.value || 1
        : caseProjection.projectedRealBuiltSurface.value)
  );
  const ratio2 = Math.max(
    caseProjection.projectedSurfaceForSale.value / caseProjection.projectedCuttedBuiltSurface.value
  );
  const ratio3 = Math.max(
    caseProjection.projectedSurfaceForSale.value /
      (isGfsEffSelected
        ? grossFloorSurfaceEff?.value || 1
        : isGfsSelected
        ? grossFloorSurface?.value || 1
        : caseProjection.projectedRealBuiltSurface.value)
  );

  if (!caseProjection || !caseLabel) return null;

  return (
    <>
      <div className="line surface-calculation-line" style={{ justifyContent: 'center' }}>
        <div style={{ width: '16%' }} />
        <ArrowFromRBSToCBSStart />
        <Rate>{toPercent(ratio1)}%</Rate>
        <ArrowFromRBSToCBSEnd />
        <ArrowFromCBSToSFSStart />
        <Rate>{toPercent(ratio2)}%</Rate>
        <ArrowFromCBSToSFSEnd />
        <div style={{ width: '16%' }} />
      </div>
      <div className="line surface-calculation-line">
        <div
          id={isGfsEffSelected ? getCaseGranulometryEditGeometryTriggerId(caseLabel) : undefined}
          style={{
            width: '32%',
            maxWidth: '100px',
            marginLeft: 'auto',
            paddingRight: '8px'
          }}>
          <Select
            id={'surfaceCalculationCol1Selector'}
            options={surfacesForColumn1Options}
            handleChange={(option: Option) => setSelectedSurfaceForColumn1(option)}
            value={selectedSurfaceForColumn1}
          />
          {isGfsEffSelected && <CaseGrossFloorSurfaceEffInput />}
          {isGfsSelected && <CaseGrossFloorSurfaceInput />}
          {isRbsSelected && <CaseRealBuiltSurfaceInput />}
        </div>
        <div style={{ width: '32%', maxWidth: '100px' }}>
          <CaseCuttedBuiltSurfaceInput />
        </div>
        <div style={{ width: '32%', maxWidth: '100px', marginRight: 'auto', paddingLeft: '8px' }}>
          <CaseSurfaceForSaleInput />
        </div>
      </div>
      <div className="line surface-calculation-line" style={{ justifyContent: 'center' }}>
        <div style={{ width: '16%' }} />
        <ArrowFromRBSToSFSStart />
        <Rate>{toPercent(ratio3)}%</Rate>
        <ArrowFromRBSToSFSEnd />
        <div style={{ width: '16%' }} />
      </div>
      <CaseSurfacesDetails isToolboxEnlarged={isToolboxEnlarged} />
    </>
  );
};
