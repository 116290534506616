import classNames from 'classnames';
import * as React from 'react';
import { ReactNode } from 'react';
import ReactSelect from 'react-select';
import { GRAY_COLOR, INFO_COLOR } from '../../constants/appConstants';

export interface Option {
  value: string | number;
  label?: string | ReactNode | ReactNode[];
  disabled?: boolean;
}

export type SelectColor = 'blue' | 'grey' | 'orange' | 'red' | 'green';

interface SelectProps {
  asCustomValue?: boolean;
  className?: string;
  color?: SelectColor;
  disabled?: boolean;
  handleChange: (option: Option) => void;
  // handleChange: (option: ValueType<Option>) => void;
  id: string;
  options: Option[];
  placeholder?: string;
  size?: 'small' | 'large';
  style?: React.CSSProperties;
  value: Option | undefined;
}

export const Select = (props: SelectProps) => {
  const {
    asCustomValue,
    className,
    color,
    disabled,
    handleChange,
    id,
    options,
    placeholder,
    size,
    style,
    value
  } = props;

  const selectColor = color || 'blue'; // default color

  const classes = classNames(
    'gb-select',
    'gb-select--color--' + selectColor,
    {
      disabled: !!disabled,
      'as-custom-value': asCustomValue,
      small: size === 'small'
    },
    className
  );

  return (
    <ReactSelect
      classNamePrefix="gb-select"
      className={classes}
      id={id}
      options={options}
      isOptionDisabled={(option: Option) => !!option.disabled}
      onChange={(option: Option) => handleChange(option)}
      value={value}
      isDisabled={disabled || options.length === 0}
      styles={{
        container: () => style || {},
        option: (provided, state) => ({
          ...provided,
          color: state.isDisabled ? GRAY_COLOR : state.isSelected ? 'white' : INFO_COLOR
        })
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#1894c4', // blue
          primary75: '#56b0d3', // blue 75%
          primary50: '#8dc9e1', // blue 50%
          primary25: '#c6e3f1' // blue 25%
        }
      })}
      placeholder={placeholder}
    />
  );
};
