import * as React from 'react';
import { Button } from '../../../../ui/Button';
import { getLabel } from '../../../../../domain/specification/levels/queries/get/getLabel';
import { getCaseGranulometryEditLevelDetailsTriggerId } from '../../../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditLevelDetailsTriggerId';
import { BasementLevelSpecification } from '../../../../../domain/specification/levels/BasementLevelSpecification';

interface Column1Props {
  basementLevelSpecification: BasementLevelSpecification;
  onEditClick: () => void;
}

export const Column1 = ({ basementLevelSpecification, onEditClick }: Column1Props) => (
  <div className="column-1">
    <div className="cell">
      <Button
        id={getCaseGranulometryEditLevelDetailsTriggerId(basementLevelSpecification.id)}
        fullWidth={true}
        size="small"
        content={getLabel(basementLevelSpecification)}
        appearance="outline"
        handleClick={onEditClick}
        disabled={false}
      />
    </div>
  </div>
);
