import { createSelector, Selector } from 'reselect';
import { ifDefined } from '../../../../utils/ifDefined';
import { selectCurrentCaseGranulometry } from './currentCaseGranulometry.selector';
import { getCaseLodgmentSectionsCount } from '../../../../domain/granulometry/cases/queries/sections/counts/getCaseLodgmentSectionsCount';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';

export const selectCurrentCaseLodgmentsCount: Selector<State, Maybe<number>> = createSelector(
  [selectCurrentCaseGranulometry],
  ifDefined(getCaseLodgmentSectionsCount)
);
