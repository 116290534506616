import { getCaseSurfaceWeightingByType } from './getCaseSurfaceWeightingByType';
import { CaseProjection } from '../../CaseProjection';

/* Get the eligible lodgment real total count in the given case */
export const getCaseTotalSurfaceWeighting = (caseProjection: CaseProjection) => {
  const caseSurfaceWeightingByType = getCaseSurfaceWeightingByType(caseProjection);
  let caseTotalSurfaceWeighting = 0;
  caseSurfaceWeightingByType.forEach((item) => {
    caseTotalSurfaceWeighting += item.surfaceWeighting;
  });
  return caseTotalSurfaceWeighting;
};
