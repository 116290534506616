import { BuildingId } from '../specification/buildings/BuildingSpecification';
import { CaseId } from '../specification/cases/CaseSpecification';

export interface AdminReportEntry {
  apiVersion: 1 | 2;
  id: string; // unique id
  refN1: string;
  refN2: string;
  building: string;
  buildingId: BuildingId;
  case: string;
  caseId: CaseId;
  level: string;
  section: string;
  room: string;
  productId: string;
  product: string;
  unitPrice: number;
  quantity: number;
  unit: string;
  price: number;
  co2Id: string;
  co2Title: string;
  co2Type: string;
  co2Quantity: number;
  co2QuantityUnit: string;
  // co2Weight: number;
  // co2TotalLifeCycleCarbon: number;
  // co2Lifetime: number;
  // co2WastageRate: number;
  // co2ReportingOrganization: string;
  // compositionTitle: string;
  compositionValue: number;
  // compositionUnit: string;
}

export interface ReportEntry extends AdminReportEntry {
  refLot: string;
  lot: string;
  refSsLot1: string;
  ssLot1: string;
  refSsLot2: string;
  ssLot2: string;
  refSsLot3: string;
  ssLot3: string;
  // ComEth
  // comEthLifeTime: number | null;
  comEthIc: number | null;
  // comEthFdesId: string | null;
  // comEthStockC: number | null;
  // comEthUnit: string | null;
}

export const reportEntryLabels: { [key: string]: string } = {
  id: 'ID',
  refLot: 'Ref. Lot',
  lot: 'Lot',
  refSsLot1: 'Ref. Sous-lot 1',
  ssLot1: 'Sous-lot 1',
  refSsLot2: 'Ref. Sous-lot 2',
  ssLot2: 'Sous-lot 2',
  refSsLot3: 'Ref. Sous-lot 3',
  ssLot3: 'Sous-lot 3',
  building: 'Bâtiment',
  buildingId: 'ID Bâtiment',
  case: 'Cage',
  caseId: 'ID Cage',
  level: 'Niveau',
  section: 'Section',
  room: 'Pièce',
  productId: 'ID Produit',
  product: 'Produit',
  unitPrice: 'Prix unitaire',
  customPrice: 'Prix unitaire personnalisé',
  quantity: 'Quantité',
  unit: 'Unité',
  price: 'Prix',
  co2Id: 'ID FDES',
  co2Title: 'Titre',
  co2Type: 'Type',
  co2Quantity: 'Quantité FDES',
  co2QuantityUnit: 'U.F.',
  // co2Weight: 'Poids carbone',
  // co2TotalLifeCycleCarbon: 'CO\u2082 Fiche',
  // co2Lifetime: 'DVR',
  // co2WastageRate: 'Tx Chute',
  // co2ReportingOrganization: 'Organisme',
  // compositionTitle: 'Matériaux',
  compositionValue: 'Total m\u00B3 Bois',
  // compositionUnit: 'Unité',
  // ComEth
  // comEthLifeTime: 'Durée de vie',
  comEthIc: 'IC'
  // comEthFdesId: 'ID FDES',
  // comEthStockC: 'Stock C',
  // comEthUnit: 'U.F.'
};

export type Report = ReportEntry[];
