import * as R from 'ramda';
import { CaseGranulometry } from '../../../CaseGranulometry';
import { LevelGranulometry } from '../../../../levels/LevelGranulometry';
import { Section } from '../../../../sections/Section';
import { RoofingName } from '../../../../sections/roofingSections/RoofingSection';
import { getLevelRoofingSections } from '../../../../levels/queries/sections/getLevelRoofingSections';

export const getCaseTileRoofingSurface = (caseGranulometry: CaseGranulometry): number =>
  R.pipe<
    [CaseGranulometry],
    LevelGranulometry[],
    LevelGranulometry[],
    Section[],
    Section[],
    number
  >(
    R.prop('levels'),
    R.filter((level: LevelGranulometry) => level.level > 0),
    R.chain(getLevelRoofingSections),
    R.filter((section: Section) => section.name === RoofingName.TileRoofing),
    R.reduce((acc, roofing) => acc + roofing.displayedSurface, 0)
  )(caseGranulometry);
