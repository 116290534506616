import { combineReducers } from 'redux';
import { PersistedState, persistReducer } from 'redux-persist';
import { History } from 'history';
import { connectRouter, RouterState } from 'connected-react-router';
import UserSlice, { UserState } from './user.slice';
import { storage } from '../persistence';
import { projectReducer, ProjectState, projectStateReconciler } from './project.reducer';
import { projectsReducer } from './projects.reducer';
import { nomenclaturesReducer } from './nomenclatures.reducer';
import { reportReducer, ReportState, reportStateReconciler } from './report.reducer';
import { buildingMapReducer, BuildingMapState } from './buildingMap.reducer';
import { navigationReducer, NavigationState } from './navigation.reducer';
import { granulometryReducer, GranulometryState } from './granulometry.reducer';
import { projectMigrations } from './migrations/projectMigrations';
import { userMigrations } from './migrations/userMigrations';
import { CURRENT_MIGRATION_VERSION, ExistingProject } from '../../domain/project/Project';
import { Nomenclature } from '../../domain/Nomenclature';
import cstbSlice, { CstbState, cstbStateReconciler } from './cstb.slice';
import { gingerMapState, GingerMapState } from './gingerMap.slice';

export interface StateWithRouter {
  router: RouterState;
}

export interface StateWithProjects {
  projects: ExistingProject[];
}

export interface StateWithProject {
  project: PersistedState & ProjectState;
}

export interface StateWithNomenclatures {
  nomenclatures: Nomenclature[];
}

export interface StateWithReport {
  report: PersistedState & ReportState;
}

export interface StateWithBuildingMap {
  buildingMap: PersistedState & BuildingMapState;
}

export interface StateWithNavigation {
  navigation: NavigationState;
}

export interface StateWithGranulometry {
  granulometry: GranulometryState;
}

export interface StateWithUser {
  user: PersistedState & UserState;
}

export interface StateWithCstb {
  cstb: PersistedState & CstbState;
}

export interface StateWithGingerMap {
  ginger: GingerMapState;
}

export type State = StateWithRouter &
  StateWithProjects &
  StateWithProject &
  StateWithNomenclatures &
  StateWithReport &
  StateWithBuildingMap &
  StateWithNavigation &
  StateWithGranulometry &
  StateWithUser &
  StateWithCstb;

export const rootReducer = (history: History) =>
  combineReducers({
    nomenclatures: nomenclaturesReducer,
    projects: projectsReducer,
    project: persistReducer<ProjectState>(
      {
        key: 'project',
        storage,
        blacklist: ['granulometry'],
        stateReconciler: projectStateReconciler,
        version: CURRENT_MIGRATION_VERSION,
        migrate: projectMigrations
      },
      projectReducer
    ),
    granulometry: granulometryReducer,
    buildingMap: persistReducer({ key: 'buildingMap', storage }, buildingMapReducer),
    report: persistReducer(
      {
        key: 'report',
        storage,
        stateReconciler: reportStateReconciler
      },
      reportReducer
    ),
    router: connectRouter(history),
    user: persistReducer(
      { key: 'user', storage, version: 0, migrate: userMigrations },
      UserSlice.reducer
    ),
    navigation: navigationReducer,
    cstb: persistReducer(
      {
        key: 'cstb',
        storage,
        stateReconciler: cstbStateReconciler
      },
      cstbSlice.reducer
    ),
    ginger: gingerMapState.reducer
  });

export const getUserState = (state: StateWithUser) => state.user;
export const getRouterState = (state: StateWithRouter) => state.router;
export const getNomenclaturesState = (state: StateWithNomenclatures) => state.nomenclatures;
export const getProjectsState = (state: StateWithProjects) => state.projects;
export const getProjectState = (state: StateWithProject) => state.project;
export const getReportState = (state: StateWithReport) => state.report;
export const getBuildingMapState = (state: StateWithBuildingMap) => state.buildingMap;
export const getNavigationState = (state: StateWithNavigation) => state.navigation;
export const getGranulometryState = (state: StateWithGranulometry) => state.granulometry;
export const getCstbState = (state: StateWithCstb) => state.cstb;
export const getGingerState = (state: StateWithGingerMap) => state.ginger;
