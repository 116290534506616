import { CaseId } from '../../../domain/specification/cases/CaseSpecification';
import { matchesPath } from '../../utils/matchesPath';
import { orCreate } from '../../utils/orCreate';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type CaseGranulometryEditOtherPropertiesPropertyRouteParams = {
  projectId: string;
  caseId: CaseId;
  propertyName: string;
};
export const caseGranulometryEditOtherPropertiesPropertyRoute: RouteWithMakePath<CaseGranulometryEditOtherPropertiesPropertyRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/otherProperties/property/:propertyName`,
    makePath: ({ projectId, caseId, propertyName }) =>
      `/project/${orCreate(
        projectId
      )}/cases/${caseId}/granulometry/edit/otherProperties/property/${propertyName}`,
    matchesPath: matchesPath<CaseGranulometryEditOtherPropertiesPropertyRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/otherProperties\/property\/([^/]+)/,
      {
        projectId: 1,
        caseId: 2,
        propertyName: 3
      }
    )
  };
