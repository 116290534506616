import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../LevelGranulometry';
import { getTopLevelRealBuiltSurfaceEff } from '../surfaces/realBuiltSurface/getTopLevelRealBuiltSurfaceEff';
import { getTopLevelDefaultWidth } from './getTopLevelDefaultWidth';
import { getTopLevelGrossFloorSurfaceEff } from '../surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurfaceEff';
import { hasDrawnGeometry } from '../../has/hasDrawnGeometry';
import { isProjectInputModeGfsEff } from '../../../../../specification/project/queries/is/isProjectInputModeGfsEff';

export const getTopLevelDefaultLength = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled | LevelGranulometry
): number =>
  (isProjectInputModeGfsEff(caseGranulometry.initialSpecifications) &&
  hasDrawnGeometry(levelGranulometry as LevelGranulometry)
    ? getTopLevelGrossFloorSurfaceEff(caseGranulometry, levelGranulometry)
    : getTopLevelRealBuiltSurfaceEff(caseGranulometry, levelGranulometry)) /
  getTopLevelDefaultWidth(caseGranulometry, levelGranulometry);
