import { CaseId } from '../../../domain/specification/cases/CaseSpecification';
import { matchesPath } from '../../utils/matchesPath';
import { orCreate } from '../../utils/orCreate';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type CaseGranulometryEditLevelDetailsRouteParams = {
  projectId: string;
  caseId: CaseId;
  levelId: string;
};
export const caseGranulometryEditLevelDetailsRoute: RouteWithMakePath<CaseGranulometryEditLevelDetailsRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/levelDetails/:levelId`,
    makePath: ({ projectId, caseId, levelId }) =>
      `/project/${orCreate(projectId)}/cases/${caseId}/granulometry/edit/levelDetails/${levelId}`,
    matchesPath: matchesPath<CaseGranulometryEditLevelDetailsRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/levelDetails\/([\w]+)/,
      {
        projectId: 1,
        caseId: 2,
        levelId: 3
      }
    )
  };
