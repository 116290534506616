import * as R from 'ramda';
import { SuperficialFundationsSection } from './superficialFundations';
import { DeepFundationsSection } from './deepFundations';
import { PileFundationsSection } from './pileFundations';
import { getSectionName, Section } from '../Section';

export enum FundationsName {
  SuperficialFundations = 'superficialFundations',
  DeepFundations = 'deepFundations',
  PileFundations = 'pileFundations'
}

export type FundationsSection =
  | SuperficialFundationsSection
  | DeepFundationsSection
  | PileFundationsSection;

type GetFundationsName = (fundationsSection: FundationsSection) => FundationsName;
export const getFundationsName: GetFundationsName = R.prop('name');

export const isSectionAFundations = (section: Section): section is FundationsSection =>
  (R.values(FundationsName) as string[]).includes(getSectionName(section));

export const filterFundations = (sections: Section[]): FundationsSection[] =>
  sections.filter(isSectionAFundations);
