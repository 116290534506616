import React from 'react';
import { UiGeometry } from '../Geometry';
import { MapCard } from './MiddlePanel/MapCard';
import { Card } from '../../../../ui/Card';

interface MiddlePanelProps {
  mapContainer: React.MutableRefObject<HTMLDivElement>;
  ui: UiGeometry;
  setMode: () => void;
  drawHelperMode: boolean;
}

export const MiddlePanel = ({ mapContainer, ui, setMode, drawHelperMode }: MiddlePanelProps) => (
  <div className={'middlePanel'}>
    <Card>
      <div className="mapContainer" ref={mapContainer}>
        <MapCard
          mapContainer={mapContainer}
          ui={ui}
          setMode={setMode}
          drawHelperMode={drawHelperMode}
        />
      </div>
    </Card>
  </div>
);
