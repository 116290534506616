import { FUNDATIONS_COLOR } from '../../../../constants/appConstants';
import { Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { FundationsName } from './FundationsSection';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';

interface DeepFundationsProps {
  surface: number;
}

export interface DeepFundationsSection extends Section, SectionDrawn {
  name: FundationsName.DeepFundations;
}

export const DEEP_FUNDATIONS_ICON = {
  type: 'object',
  iconName: FundationsName.DeepFundations,
  color: FUNDATIONS_COLOR
} as IconObjectProps;

const deepFundations = ({ surface }: DeepFundationsProps): DeepFundationsSection => ({
  // Section
  id: 'NOID',
  name: FundationsName.DeepFundations,
  title: i18next.t('deepFundations.title'),
  type: 'fundations',
  displayedSurface: surface,
  // SectionDrawn
  surface,
  color: FUNDATIONS_COLOR,
  icon: DEEP_FUNDATIONS_ICON
});

export default deepFundations;
