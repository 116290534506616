import { OFFICE_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { SectionWithTechnicalSheaths } from '../SectionWithTechnicalSheaths';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';

interface OfficeSectionProps {
  surface?: number;
  exposureRate?: number;
  width?: number;
}

export interface OfficeSection
  extends Section,
    SectionDrawn,
    SectionWithExposureRate,
    SectionWithTechnicalSheaths {
  name: 'office';
  width?: number;
  defaultWidth: number;
  minWidth: number;
  maxWidth: number;
  defaultSurface: number;
  minSurface: number;
  maxSurface: number | undefined;
}

export const OFFICE_SECTION_DEFAULT_SURFACE = 200;
export const OFFICE_SECTION_MIN_SURFACE = 50;
export const OFFICE_SECTION_MAX_SURFACE = undefined; // 95% of the project cutted built surface
export const OFFICE_SECTION_DEFAULT_EXPOSURE_RATE = 3;
export const OFFICE_SECTION_DEFAULT_WITH = 14;
export const OFFICE_SECTION_MIN_WITH = 10;
export const OFFICE_SECTION_MAX_WITH = 20;
export const OFFICE_SECTION_ICON = {
  type: 'object',
  iconName: 'office',
  color: OFFICE_COLOR
} as IconObjectProps;

const officeSection = ({ surface, exposureRate, width }: OfficeSectionProps): OfficeSection => ({
  // Section
  id: 'NOID',
  name: 'office',
  title: i18next.t('office.title'),
  type: 'office',
  displayedSurface: surface || OFFICE_SECTION_DEFAULT_SURFACE,
  // SectionDrawn
  surface: surface || OFFICE_SECTION_DEFAULT_SURFACE,
  color: OFFICE_COLOR,
  icon: OFFICE_SECTION_ICON,
  // SectionWithExposureRate
  exposureRate: exposureRate || undefined,
  defaultExposureRate: OFFICE_SECTION_DEFAULT_EXPOSURE_RATE,
  // SectionWithTechnicalSheaths
  technicalSheathCount: 0,
  // CollectiveKitchenSection
  width: width || undefined,
  defaultWidth: OFFICE_SECTION_DEFAULT_WITH,
  minWidth: OFFICE_SECTION_MIN_WITH,
  maxWidth: OFFICE_SECTION_MAX_WITH,
  defaultSurface: OFFICE_SECTION_DEFAULT_SURFACE,
  minSurface: OFFICE_SECTION_MIN_SURFACE,
  maxSurface: OFFICE_SECTION_MAX_SURFACE
});

export default officeSection;

export const isSectionAnOffice = (section: Section): section is OfficeSection =>
  getSectionName(section) === 'office';

export const filterOfficeSections = (sections: Section[]): OfficeSection[] =>
  sections.filter(isSectionAnOffice);
