import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import * as R from 'ramda';
import { Property } from '../../specification/cases/Property';

export const removeCaseProperty = (
  caseSpecification: CaseSpecification,
  propertyName: string
): CaseSpecification =>
  R.over(
    R.lensPath(['properties']),
    R.reject((property: Property) => property.name === propertyName),
    caseSpecification
  );
