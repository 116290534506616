import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelInternalInsulationThickness } from './getTopLevelInternalInsulationThickness';
import { getTopLevelExternalInsulationThickness } from './getTopLevelExternalnalInsulationThickness';
import { getTopLevelOutsideStructuralWallsThickness } from './getTopLevelOutsideStructuralWallsThickness';

export const getTopLevelOutsideInsulatedWallsThickness = (
  caseGranulometry: CaseGranulometry
): number =>
  getTopLevelInternalInsulationThickness(caseGranulometry) +
  getTopLevelOutsideStructuralWallsThickness(caseGranulometry) +
  getTopLevelExternalInsulationThickness(caseGranulometry);
