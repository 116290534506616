import carBoxInfra, {
  CarBoxInfraSection
} from '../../../../../sections/parkingSections/carBoxInfra';
import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createBasementLevelCarBoxInfra = (
  caseGranulometry: CaseGranulometry
): CarBoxInfraSection => {
  const depth = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'carBoxDepth'
  ) as number;
  const width = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'carBoxWidth'
  ) as number;
  const surface = depth * width;
  const outsideWallsConcreteRatio = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'carBoxOutsideWallsConcreteRatio'
  ) as number;
  const beamHeight = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'carBoxBeamHeight'
  ) as number;
  const beamWidth = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'carBoxBeamWidth'
  ) as number;
  const pillarThickness = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'carBoxPillarThickness'
  ) as number;
  const pillarWidth = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'carBoxPillarWidth'
  ) as number;
  const partitionThickness = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'carBoxPartitionThickness'
  ) as number;
  return carBoxInfra({
    surface,
    depth,
    width,
    outsideWallsConcreteRatio,
    beamHeight,
    beamWidth,
    pillarThickness,
    pillarWidth,
    partitionThickness
  });
};
