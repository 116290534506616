import * as React from 'react';
import { LINEAR_METER_SCALE } from '../../../constants/appConstants';

interface GroundsLinesProps {
  hardRocksDepth: number;
  softRocksDepth: number;
  waterDepth: number;
  hardRockRef?: React.Ref<HTMLDivElement | undefined>;
  softRockRef?: React.Ref<HTMLDivElement | undefined>;
  waterLineRef?: React.Ref<HTMLDivElement | undefined>;
}

export const GroundLines: React.FC<GroundsLinesProps> = ({
  hardRocksDepth,
  softRocksDepth,
  waterDepth,
  hardRockRef,
  softRockRef,
  waterLineRef
}) => {
  const scaledHardRocksDepth = hardRocksDepth * LINEAR_METER_SCALE;
  const scaledSoftRocksDepth = softRocksDepth * LINEAR_METER_SCALE;
  const scaledWaterDepth = waterDepth * LINEAR_METER_SCALE;
  return (
    <div className="groundLines">
      {hardRocksDepth && (
        <div
          ref={hardRockRef}
          className="line hardRocksLine"
          style={{ bottom: 'calc(50% - ' + scaledHardRocksDepth + 'px)' }}
        />
      )}
      {softRocksDepth && (
        <div
          ref={softRockRef}
          className="line softRocksLine"
          style={{ bottom: 'calc(50% -  ' + scaledSoftRocksDepth + 'px)' }}
        />
      )}
      {waterDepth && (
        <div
          ref={waterLineRef}
          className="line waterLine"
          style={{ bottom: 'calc(50% - ' + scaledWaterDepth + 'px)' }}
        />
      )}
    </div>
  );
};
