import * as R from 'ramda';
import { LevelGranulometryInitialEntries } from '../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { isUpperLevel } from '../../is/isUpperLevel';
import { mustHaveSmokeExtractionDuctSections } from '../../mustHave/mustHaveSmokeExtractionDuctSections';
import { SmokeExtractionDuctsSection } from '../../../../sections/circulationSections/smokeExtractionDucts';
import { getCaseSmokeExtractionDuctSectionsCount } from '../../../../cases/queries/sections/counts/getCaseSmokeExtractionDuctSectionsCount';
import { createTopLevelSmokeExtractionDuctsSection } from './create/createTopLevelSmokeExtractionDuctsSection';
import { isHighestLevel } from '../../is/isHighestLevel';

export const getTopLevelSmokeExtractionDuctsSections = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryInitialEntries
): SmokeExtractionDuctsSection[] | [] => {
  if (
    process.env.GB_FEAT_SMOKE_EXTRACTION !== 'false' &&
    mustHaveSmokeExtractionDuctSections(caseGranulometry)
  ) {
    let smokeExtractionDuctsSections: SmokeExtractionDuctsSection[] = [];
    R.times((i) => {
      const smokeExtractionDucts = createTopLevelSmokeExtractionDuctsSection(
        caseGranulometry,
        'smokeExtractionDucts' + i + caseGranulometry.labelIndex
      );
      smokeExtractionDucts.openedAbove = !isHighestLevel(caseGranulometry, levelGranulometry);
      if (isUpperLevel(caseGranulometry, levelGranulometry)) {
        smokeExtractionDucts.openedBelow = true;
      }
      smokeExtractionDuctsSections = [...smokeExtractionDuctsSections, smokeExtractionDucts];
    })(getCaseSmokeExtractionDuctSectionsCount(caseGranulometry));
    return smokeExtractionDuctsSections;
  }
  return [];
};
