import { Granulometry } from '../Granulometry';
import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { NonEmptyArray } from '../../../utils/NonEmptyArray';
import { renderBuilding } from '../buildings/queries/renderBuilding';
import { BuildingGranulometry } from '../buildings/BuildingGranulometry';

export const renderGranulometry = (project: ProjectProjection): Granulometry => {
  try {
    return project.buildings.map((buildingProjection, buildingIndex) => {
      return renderBuilding(buildingProjection, buildingIndex, buildingProjection.cases);
    }) as NonEmptyArray<BuildingGranulometry>;
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return error;
  }
};
