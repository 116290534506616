import { getSectionName, Section } from '../Section';
import i18next from 'i18next';
import { MetaSectionName } from './MetaSection';

interface GroundLevelMetaSectionProps {
  extendedSurface: number;
  lodgmentSurfaceForSale: number;
  ceilingNetHeight: number;
  elevatorShaftCount: number;
  stairsShaftCount: number;
  smokeExtractionDuctsCount: number;
}

export interface GroundLevelMetaSection extends Section {
  name: MetaSectionName.GroundLevelMetaSection;
  extendedSurface: number;
  lodgmentSurfaceForSale: number;
  ceilingNetHeight: number;
  elevatorShaftCount: number;
  stairsShaftCount: number;
  smokeExtractionDuctsCount: number;
}

const groundLevelMetaSection = ({
  extendedSurface,
  lodgmentSurfaceForSale,
  ceilingNetHeight,
  elevatorShaftCount,
  stairsShaftCount,
  smokeExtractionDuctsCount
}: GroundLevelMetaSectionProps): GroundLevelMetaSection => ({
  // Section
  id: 'NOID',
  name: MetaSectionName.GroundLevelMetaSection,
  title: i18next.t('groundLevelMetaSection.title'),
  type: 'meta',
  displayedSurface: 0,
  extendedSurface,
  lodgmentSurfaceForSale,
  ceilingNetHeight,
  elevatorShaftCount,
  stairsShaftCount,
  smokeExtractionDuctsCount
});

export default groundLevelMetaSection;

export const isSectionAGroundLevelMetaSection = (
  section: Section
): section is GroundLevelMetaSection =>
  getSectionName(section) === MetaSectionName.GroundLevelMetaSection;
