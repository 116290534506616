import classNames from 'classnames';
import * as React from 'react';
import { ReactNode } from 'react';

interface CardsListProps {
  children: ReactNode | ReactNode[];
  className?: string;
  style?: React.CSSProperties;
}

export const CardsList = ({ children, className, style }: CardsListProps) => (
  <div className={classNames('cards-list', className)} style={style}>
    {children}
  </div>
);
