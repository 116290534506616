import { post } from './admin.api';
import { User } from '../../domain/User';
import { AuthToken } from '../../AuthToken';
import { Account, CustomPriceList } from '../../domain/Account';

export const saveCustomPriceLists = async (
  userId: User['id'],
  userToken: AuthToken,
  accountId: Account['id'],
  newCustomPriceLists: CustomPriceList[]
) => {
  const postCustomPriceLists = await Promise.all([
    post(
      `/CustomPriceLists/save/${userId}/${userToken}/${accountId}/`,
      userToken,
      newCustomPriceLists
    )
  ]);
  if ((await postCustomPriceLists[0].text()) !== 'OK') {
    throw new Error('Could not save custom price lists');
  }
};
