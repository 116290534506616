import i18next from 'i18next';
import { getSectionName, Section } from '../Section';
import { MetaSectionName } from './MetaSection';

export interface ProjectMetaSection extends Section {
  name: MetaSectionName.ProjectMetaSection;
}

const projectMetaSection = (): ProjectMetaSection => ({
  // Section
  id: 'NOID',
  name: MetaSectionName.ProjectMetaSection,
  title: i18next.t('Section unique par projet (meta section)'),
  type: 'meta',
  displayedSurface: 0
});

export default projectMetaSection;

export const isSectionAProjectMetaSection = (section: Section): section is ProjectMetaSection =>
  getSectionName(section) === MetaSectionName.ProjectMetaSection;
