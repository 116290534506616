import { Column1 } from './Column1';
import { ScrollSyncPane } from 'react-scroll-sync';
import { Column2 } from './Column2';
import { Column3 } from './Column3';
import { Column4Enlarged } from './Column4Enlarged';
import { Column4 } from './Column4';
import * as React from 'react';
import { TopLevelSpecification } from '../../../../../domain/specification/levels/TopLevelSpecification';
import { TopLevelSurfaceName } from '../../../../../domain/granulometry/cases/queries/surfaces/getCaseTopLevelsSurfaces';

interface TopLevelLineProps {
  topLevelSpecification: TopLevelSpecification;
  isToolboxEnlarged: boolean;
  selectedLevelSurface: TopLevelSurfaceName;
}
export const TopLevelLine = ({
  topLevelSpecification,
  isToolboxEnlarged,
  selectedLevelSurface
}: TopLevelLineProps) => (
  <div className="line top-level-line">
    <Column1 topLevelSpecification={topLevelSpecification} />
    <ScrollSyncPane>
      <Column2 topLevelSpecification={topLevelSpecification} />
    </ScrollSyncPane>
    <Column3 topLevelSpecification={topLevelSpecification} />
    {isToolboxEnlarged ? (
      <Column4Enlarged topLevelSpecification={topLevelSpecification} />
    ) : (
      <Column4
        topLevelSpecification={topLevelSpecification}
        selectedLevelSurface={selectedLevelSurface}
      />
    )}
  </div>
);
