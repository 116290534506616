import classNames from 'classnames';
import * as React from 'react';
import { IconFont } from '../../ui/Icons/IconFont';
import { IconObject } from '../../ui/Icons/iconObject';
import { useSelector } from 'react-redux';
import { selectRoute } from '../../../store/selectors/navigation/getRoute.selector';
import { selectIsNewProject } from '../../../store/selectors/project';
import { selectIsDataBarDisplayed } from '../../../store/selectors/dataBar/isDataBarDisplayed';

export const NavBarView = (props: any) => {
  const currentRoute = useSelector(selectRoute);
  const isNewProject = useSelector(selectIsNewProject);
  const isDataBarDisplayed = useSelector(selectIsDataBarDisplayed);
  const displayNavBar = (isNewProject && currentRoute.isPanel) || !isNewProject;

  return displayNavBar ? (
    <div
      className={classNames('bar', 'nav-bar', {
        disabled: props.iconsDisabled,
        dataBarDisplayed: isDataBarDisplayed
      })}>
      {!props.isFull && (
        <IconObject
          iconName="fullscreenOn"
          type="menu"
          disabled={props.iconsDisabled}
          onClick={() => {
            props.toggleFullscreen();
          }}
        />
      )}
      {props.isFull && (
        <IconObject
          iconName="fullscreenOff"
          type="menu"
          disabled={props.iconsDisabled}
          onClick={() => {
            props.toggleFullscreen();
          }}
        />
      )}
      <IconObject
        iconName="zoomIn"
        type="menu"
        disabled={props.iconsDisabled}
        onClick={() => props.zoomAdd(0.1)}
      />
      <IconObject
        iconName="zoomOut"
        type="menu"
        disabled={props.iconsDisabled}
        onClick={() => props.zoomRemove(0.1)}
      />
      <IconObject
        iconName="zoomAjusted"
        type="menu"
        disabled={props.iconsDisabled}
        onClick={() => props.refreshFocus()}
      />
      <IconFont
        id="nav-bar-percentage"
        returnString={(props.zoom * 100).toFixed(0) + '%'}
        color={props.iconsDisabled ? '#ccc' : '#1c3444'}
      />
    </div>
  ) : null;
};
