import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../../ui/Button';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { selectCurrentCaseIdFromRoute } from '../../../../../store/selectors/navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { selectProjectId } from '../../../../../store/selectors/project';
import { getLabel } from '../../../../../domain/specification/levels/queries/get/getLabel';
import { selectCurrentlyEditedLevelSpecification } from '../../../../../store/selectors/navigation/toolbox/casesPanel/currentlyEditedLevelSpecification.selector';
import { TopLevelSpecification } from '../../../../../domain/specification/levels/TopLevelSpecification';
import { selectIsCaseGranulometryEditLevelDetailsOpened } from '../../../../../store/selectors/navigation/toolbox/casesPanel/isCaseGranulometryEditLevelDetailsOpened.selector';
import { getCaseGranulometryEditLevelDetailsTriggerId } from '../../../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditLevelDetailsTriggerId';
import { goToCaseGranulometryEditLevelDetails } from '../../../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditLevelDetails.action';

interface Column1Props {
  topLevelSpecification: TopLevelSpecification;
}

export const Column1 = ({ topLevelSpecification }: Column1Props) => {
  const dispatch = useDispatch();

  const projectId = useSelector(selectProjectId);
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const currentlyEditedLevel = useSelector(selectCurrentlyEditedLevelSpecification);
  const isCaseLevelDetailsOpened = useSelector(selectIsCaseGranulometryEditLevelDetailsOpened);

  if (!projectId || !caseId || !caseLabel) return null;

  return (
    <div className="column-1">
      <div className="cell">
        <Button
          id={getCaseGranulometryEditLevelDetailsTriggerId(topLevelSpecification.id)}
          selected={
            isCaseLevelDetailsOpened && currentlyEditedLevel?.id === topLevelSpecification.id
          }
          fullWidth
          size="small"
          content={getLabel(topLevelSpecification)}
          appearance="outline"
          handleClick={() => {
            dispatch(
              goToCaseGranulometryEditLevelDetails(projectId, caseId, topLevelSpecification.id)
            );
          }}
        />
      </div>
    </div>
  );
};
