import i18next from 'i18next';
import { BasicRoomSpecification } from '../../specification/rooms/BasicRoomSpecification';

const DEFAULT_BEDROOM_3_LENGTH = 3.8;
const DEFAULT_BEDROOM_3_WIDTH = 3.2;

const bedroom3 = (): BasicRoomSpecification =>
  ({
    name: 'bedroom3',
    title: i18next.t('Bedroom 3'),
    defaultLength: DEFAULT_BEDROOM_3_LENGTH,
    width: undefined,
    defaultWidth: DEFAULT_BEDROOM_3_WIDTH,
    surface: undefined,
    icon: {
      type: 'object',
      iconName: 'bedroom',
      strokeStyle: 'opened',
      label: '3'
    }
  } as const);

export default bedroom3;
