import { isSectionALodgment } from '../../sections/lodgmentSections/LodgmentSection';
import { isSectionABearing } from '../../sections/circulationSections/bearing';
import { isSectionAHall } from '../../sections/circulationSections/hall';
import { LevelGranulometryFullFilled } from '../LevelGranulometry';
import { getLevelAlreadyCalculatedRealBuiltSurface } from './surfaces/getLevelAlreadyCalculatedRealBuiltSurface';
import { getSectionsTotalDrawnSurface } from '../../sections/queries/surfaces/getSectionsTotalDrawnSurface';
import { isSectionAnTechnicalPremise } from '../../sections/technicalPremiseSections/TechnicalPremiseSection';
import { isSectionACommonPremise } from '../../sections/commonPremiseSections/CommonPremiseSection';
import { isSectionAShop } from '../../sections/shopSection/shopSection';
import { isSectionAnOffice } from '../../sections/officeSection/officeSection';
import { getLevelFullFilledContent } from './content/getLevelFullFilledContent';

export const getLevelDisplayedWallThickness = (
  levelGranulometry: LevelGranulometryFullFilled
): number => {
  const realBuiltSurface = getLevelAlreadyCalculatedRealBuiltSurface(levelGranulometry).value;

  const relativeSections = getLevelFullFilledContent(levelGranulometry).filter(
    (section) =>
      isSectionABearing(section) ||
      isSectionALodgment(section) ||
      isSectionACommonPremise(section) ||
      isSectionAShop(section) ||
      isSectionAnOffice(section) ||
      isSectionAnTechnicalPremise(section) ||
      isSectionAHall(section)
  );

  const relativeSectionsDrawnSurface = getSectionsTotalDrawnSurface(relativeSections);

  return Math.max(
    (realBuiltSurface - relativeSectionsDrawnSurface) /
      Math.max(1, relativeSections?.length - 1) /
      10,
    0
  );
};
