import { Epic } from 'redux-observable';
import userState from '../reducers/user.slice';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { postChangePassword } from '../../api/v1/changePassword.api';
import { selectUserId } from '../selectors/user/userId.selector';
import { User } from '../../domain/User';
import { selectUserToken } from '../selectors/user/userToken.selector';
import { AuthToken } from '../../AuthToken';
import { defer, EMPTY } from 'rxjs';

export const changePasswordEpic: Epic = (actions$, state$) =>
  actions$.pipe(
    filter(userState.actions.changePassword.match),
    withLatestFrom(state$),
    switchMap(
      ([
        {
          payload: { newPassword }
        },
        state
      ]) => {
        const userId = selectUserId(state) as User['id'];
        const authToken = selectUserToken(state) as AuthToken;

        return defer(() => postChangePassword(userId, authToken, newPassword)).pipe(
          map(() => userState.actions.passwordChanged()),
          catchError((err) => {
            console.warn(err);
            return EMPTY;
          })
        );
      }
    )
  );
