import * as R from 'ramda';
import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import { Property } from '../../specification/cases/Property';

export const migrateProject31To32 = (project: Project): Project =>
  mapCases((caseSpecification) =>
    R.pipe<[CaseSpecification], CaseSpecification>((cS) =>
      R.over(
        R.lensPath(['properties']),
        R.map((property: Property) =>
          R.pipe<[Property], Property>((p) =>
            p.name === 'elevatorShaftLength'
              ? {
                  ...p,
                  defaultValue: 1.6,
                  min: 1.6
                }
              : p
          )(property)
        ),
        cS
      )
    )(caseSpecification)
  )(project);
