import { getSectionWallsLinear } from '../walls/getSectionWallsLinear';
import { SectionWithExposureRate } from '../../SectionWithExposureRate';
import { LevelGranulometry } from '../../../levels/LevelGranulometry';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { getSectionRealExposureRate } from './getSectionRealExposureRate';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import { getSectionRealOutsideWallsLinear } from '../walls/realWalls/getSectionRealOutsideWallsLinear';
import { roundWith4Decimal } from '../../../../../utils/round/roundWith4Decimal';

export const getSectionRealExposureRateDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry,
  section: SectionWithExposureRate
): string => {
  let details: string[] = [];
  details = [
    ...details,
    '<b>' +
      roundWith2Decimal(
        getSectionRealOutsideWallsLinear(caseGranulometry, levelGranulometry, section)
      ) +
      ' mL</b> de linéaire réel de voiles exposés'
  ];
  details = [
    ...details,
    '<b>/ ' +
      roundWith2Decimal(getSectionWallsLinear(caseGranulometry, levelGranulometry, section)) +
      ' mL</b> de linéaire de voiles exposés et non-exposés'
  ];
  details = [
    ...details,
    '<b>= ' +
      roundWith4Decimal(getSectionRealExposureRate(caseGranulometry, levelGranulometry, section)) +
      '</b>'
  ];
  return details.join('<br />');
};
