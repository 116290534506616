import * as React from 'react';

interface Props {
  showPageSizeOptions: {};
  pageSizeOptions: [];
  page: number;
  pages: number;
  pageSize: number;
  onPageSizeChange: (p: number) => void;
  onPageChange: (p: number) => void;
  renderPageSizeOptions: {};
  data: [];
  rowsText: string;
  rowsSelectorText: string;
}

export const PaginationTop: React.FC<Props> = ({
  showPageSizeOptions,
  pageSizeOptions,
  pageSize,
  onPageSizeChange,
  data,
  rowsSelectorText,
  rowsText
}) => {
  const handlePageChange = React.useCallback(
    (e) => onPageSizeChange(Number(e.target.value)),
    [onPageSizeChange]
  );

  return (
    <div className="gb-rt-top">
      <div className="gb-rt-top-left">
        {showPageSizeOptions && (
          <span className="select-wrap -pageSizeOptions">
            <select aria-label={rowsSelectorText} onChange={handlePageChange} value={pageSize}>
              {pageSizeOptions.map((option, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <option key={i} value={option}>
                  {`${option} ${rowsText}`}
                </option>
              ))}
            </select>
          </span>
        )}
      </div>
      <div className="gb-rt-top-right">
        <span className="gb-rt-total">Total : {data.length}</span>
      </div>
    </div>
  );
};
