import * as R from 'ramda';
import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelInsulatedFacadeInternalLinears } from '../getTopLevelInsulatedFacadeInternalLinears';
import { getTopLevelOutsideInsulatedWallsThickness } from '../wallsThickness/getTopLevelOutsideInsulatedWallsThickness';

export const getTopLevelRealOutsideWallsSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled | LevelGranulometry
): number => {
  const topLevelOutsideWallsThickness = getTopLevelOutsideInsulatedWallsThickness(caseGranulometry);
  return R.sum(
    R.map<number, number>(
      (facadeLinear) =>
        (facadeLinear + topLevelOutsideWallsThickness) * topLevelOutsideWallsThickness
    )(
      getTopLevelInsulatedFacadeInternalLinears(
        caseGranulometry,
        levelGranulometry as LevelGranulometry
      )
    )
  );
};
