import { Surface } from '../../specification/Surface';
import { MAXIMUM_PROJECT_CBS, MINIMUM_PROJECT_CBS } from '../../project/Project';

export const projectCbsIsUndefined = (projectCbs?: Surface): boolean => projectCbs === undefined;
export const projectCbsIsEmpty = (projectCbs: Surface): boolean => projectCbs === Surface.EMPTY;
export const projectCbsIsSmallerThanTheMinimum = (projectCbs: Surface): boolean =>
  projectCbs.value < MINIMUM_PROJECT_CBS;
export const projectCbsIsLargerThanTheMaximum = (projectCbs: Surface): boolean =>
  projectCbs.value > MAXIMUM_PROJECT_CBS;

export const projectCbsIsValid = (projectCbs?: Surface): boolean =>
  !projectCbsIsUndefined(projectCbs) &&
  !projectCbsIsEmpty(projectCbs as Surface) &&
  !projectCbsIsSmallerThanTheMinimum(projectCbs as Surface) &&
  !projectCbsIsLargerThanTheMaximum(projectCbs as Surface);
