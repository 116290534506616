import carParkInfra, {
  CarParkInfraSection
} from '../../../../../sections/parkingSections/carParkInfra';
import { getCasePropertyValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyValue';
import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createBasementLevelCarParkInfra = (
  caseGranulometry: CaseGranulometry
): CarParkInfraSection => {
  const depth = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'carParkDepth'
  ) as number;
  const width = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'carParkWidth'
  ) as number;
  const surface = depth * width;
  const outsideWallsConcreteRatio = getCasePropertyValue(
    caseGranulometry.initialSpecifications,
    'carParkOutsideWallsConcreteRatio'
  ) as number;
  const beamHeight = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'carParkBeamHeight'
  ) as number;
  const beamWidth = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'carParkBeamWidth'
  ) as number;
  const pillarThickness = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'carParkPillarThickness'
  ) as number;
  const pillarWidth = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'carParkPillarWidth'
  ) as number;

  return carParkInfra({
    surface,
    depth,
    width,
    outsideWallsConcreteRatio,
    beamHeight,
    beamWidth,
    pillarThickness,
    pillarWidth
  });
};
