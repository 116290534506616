import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import { Feature, Polygon } from 'geojson';
import { MapSource } from '../../types';
import { FeatureParcel, ParcelProperties } from '../../../../domain/Parcel';
import { getAltitude } from '../../utils/geometry/getAltitude';
import { MapServices } from '../../map/map.services';

export const selectParcel = (
  map: mapboxgl.Map,
  parcelPolygon: Feature<Polygon>,
  mapServices: MapServices
): FeatureParcel => {
  const center = turf.center({ type: 'FeatureCollection', features: [parcelPolygon] });

  const properties: ParcelProperties = {
    ...parcelPolygon.properties,
    name: 'selected_parcels_' + parcelPolygon.properties?.id,
    area: turf.area(parcelPolygon),
    center,
    altitude: getAltitude(center.geometry.coordinates, map)
  };

  const parcel: FeatureParcel = {
    geometry: parcelPolygon.geometry,
    type: 'Feature',
    id: parcelPolygon.id as string,
    properties
  };

  if (!map.getSource('selected_parcels')) {
    return mapServices.loadParcels([parcel])[0];
  } else {
    const allParcels = map.getSource('selected_parcels') as MapSource<Polygon, ParcelProperties>;
    if (!allParcels._data.features) {
      allParcels.setData({
        type: 'FeatureCollection',
        features: [parcel]
      });
      return parcel;
    }

    const isUnique = !allParcels._data.features?.some(
      (p) => p.properties.name === parcel.properties.name
    );
    if (isUnique) {
      allParcels.setData({
        type: 'FeatureCollection',
        features: [...allParcels._data.features, parcel]
      });
    }
  }

  return parcel;
};
