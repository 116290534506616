import { ValidationRule } from '../ValidationsRule';
import { caseHallSurfaceIsAdjusted } from '../../checks/cases/caseHallSurfaceIsAdjusted';
import { getProjectCasesProjections } from '../../projection/project/queries/cases/getProjectCasesProjections';
import { Notification } from '../Notification';
import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { Granulometry } from '../../granulometry/Granulometry';
import { getProjectCasesGranulometries } from '../../specification/project/queries/cases/getProjectCasesGranulometries';
import { letterFromIndex } from '../../../utils/letterFromIndex';

export const adjustedHallMustBeWarned: ValidationRule = (
  projectProjection: ProjectProjection,
  isNewProject?: boolean,
  granulometry?: Granulometry
) => {
  if (granulometry) {
    const casesGranulometries = getProjectCasesGranulometries(granulometry);
    const casesProjections = getProjectCasesProjections(projectProjection);
    const notifications: Notification[] = casesProjections.reduce(
      (accNotifications, caseProjection) => {
        const caseGranulometry = casesGranulometries.find(
          (eachCase) => caseProjection.id === eachCase.id
        );
        if (caseGranulometry && caseHallSurfaceIsAdjusted(caseProjection, caseGranulometry)) {
          const caseLabel =
            caseProjection.buildingIndex + 1 + letterFromIndex(caseProjection.indexInBuilding);
          return [
            {
              title:
                'Ajustement automatique de la surface' +
                (caseProjection.hall ? ' du hall' : '') +
                ' de la cage ' +
                caseLabel +
                ' opéré pour préserver la SHab et la surface minimum de palier.',
              explanation:
                'Votre modélisation présente une surface globale ne permettant pas la juxtaposition de l’ensemble des surfaces particulières (SHab, locaux techniques, partie communes,...). Pour préserver vos choix de surfaces, la surface habitable (SHab) et la surface minimum de palier nécessaire à la modélisation sont préservées tout en réduisant la surface ' +
                (caseProjection.hall ? ' du hall d‘entrée' : '') +
                '.',
              type: 'warning'
            },
            ...accNotifications
          ];
        }
        return accNotifications;
      },
      [] as Notification[]
    );
    return notifications.length !== 0 ? notifications : true;
  }
  return true;
};
