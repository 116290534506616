import { LevelSpecification } from '../../../../levels/LevelSpecification';
import * as R from 'ramda';
import { CaseSpecification } from '../../../CaseSpecification';

export const setCaseLevelSpecification =
  (level: LevelSpecification) =>
  (caseSpecification: CaseSpecification): CaseSpecification => {
    return R.over<CaseSpecification, LevelSpecification[]>(
      R.lensProp('levels'),
      R.pipe<
        [LevelSpecification[]],
        LevelSpecification[],
        LevelSpecification[],
        LevelSpecification[]
      >(
        R.reject<LevelSpecification>((l) => l.level === level.level),
        R.append(level),
        R.sortWith<LevelSpecification>([R.descend(R.prop('level'))])
      )
    )(caseSpecification);
  };
