import * as React from 'react';
import * as R from 'ramda';
import { Column } from 'react-table';
import { ReportEntry, reportEntryLabels } from '../../../../../../domain/report/ReportEntry';
import { fitterRowsAndPivotsThroughInput } from '../filters/fitterRowsAndPivotsThroughInput';
import { formatThousands } from '../../../../../../utils/formatThousands';
import { getTotalCompostionValue } from '../totals/getTotalCompositionValue';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';

export const getWoodCubatureColumns = (report: ReportEntry[], subRowsIndex: number[]): Column => ({
  Header: 'Cubature Bois',
  columns: [
    /* {
      Header: reportEntryLabels.compositionTitle,
      accessor: 'compositionTitle',
      filterMethod: filterRowsAndPivotsThroughSelect,
      Filter: createFilter(reportCompositionTitleList(report)),
      aggregate: values => values[0],
      Aggregated: cellInfo => (cellInfo.groupedByPivot && cellInfo.level < 4 ? '' : cellInfo.value),
      sortable: false,
      resizable: false,
      width: 100
    }, */
    {
      Header: reportEntryLabels.compositionValue,
      accessor: 'compositionValue',
      filterMethod: fitterRowsAndPivotsThroughInput(subRowsIndex),
      aggregate: (values) => formatThousands(Math.round(R.sum(values))),
      Aggregated: (cellInfo) => (
        <b>
          {cellInfo.value
            ? formatThousands(roundWith2Decimal(getTotalCompostionValue(cellInfo)))
            : 0}{' '}
          {'m\u00B3'}
        </b>
      ),
      Cell: (cellInfo) => (
        <b>{cellInfo.value ? <>{formatThousands(roundWith2Decimal(cellInfo.value))} m3</> : '-'}</b>
      ),
      sortable: false,
      resizable: false,
      className: 'separator right',
      headerClassName: 'separator right',
      minWidth: 100
    }
    /* {
      Header: reportEntryLabels.compositionUnit,
      accessor: 'compositionUnit',
      filterMethod: filterRowsAndPivotsThroughSelect,
      Filter: createFilter(reportCompositionUnitList(report)),
      aggregate: values => values[0],
      Aggregated: cellInfo => (cellInfo.groupedByPivot && cellInfo.level < 4 ? '' : cellInfo.value),
      className: 'separator right',
      headerClassName: 'separator right',
      sortable: false,
      resizable: false,
      width: 40
    } */
  ]
});
