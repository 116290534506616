import * as R from 'ramda';
import * as React from 'react';
import { ReportEntry, reportEntryLabels } from '../../../../../../domain/report/ReportEntry';
import { formatThousands } from '../../../../../../utils/formatThousands';
import { Column } from 'react-table';
import { fitterRowsAndPivotsThroughInput } from '../filters/fitterRowsAndPivotsThroughInput';
import { filterRowsAndPivotsThroughSelect } from '../filters/filterRowsAndPivotsThroughSelect';
import { createFilter } from '../createFilter';
import { reportCo2TitleList } from '../reportEntries/reportCo2TitleList';
import { reportCo2IdList } from '../reportEntries/reportCo2IdList';
import { reportCo2TypeList } from '../reportEntries/reportCo2TypeList';
import { reportCo2QuantityUnitList } from '../reportEntries/reportCo2QuantityUnitList';
import { formatUnits } from '../../../../../../utils/formatUnits';
import classNames from 'classnames';
import { getTotalCo2Quantity } from '../totals/getTotalCo2Quantity';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';

export const getCo2Columns = (
  report: ReportEntry[],
  subRowsIndex: number[],
  foldedColumns: string[]
): Column => ({
  Header: 'CO\u2082',
  columns: [
    {
      Header: reportEntryLabels.co2Id,
      accessor: 'co2Id',
      filterMethod: filterRowsAndPivotsThroughSelect,
      Filter: createFilter(reportCo2IdList(report)),
      Cell: (props) =>
        props.row.co2Id ? (
          R.test(/[a-zA-Z]/g, props.row.co2Id) ? (
            <i>{props.row.co2Id}</i>
          ) : (
            <a
              href={
                'https://www.base-inies.fr/iniesV4/dist/consultation.html?id=' + props.row.co2Id
              }
              title="Afficher la fiche FDES"
              target="_blank"
              rel="noreferrer">
              {props.row.co2Id}
            </a>
          )
        ) : (
          '-'
        ),
      aggregate: (values) =>
        values[0] ? (
          R.test(/[a-zA-Z]/g, values[0]) ? (
            <i>{values[0]}</i>
          ) : (
            <b title={values[0]}>
              <a
                href={'https://www.base-inies.fr/iniesV4/dist/consultation.html?id=' + values[0]}
                title="Afficher la fiche FDES"
                target="_blank"
                rel="noreferrer">
                {values[0]}
              </a>
            </b>
          )
        ) : (
          '-'
        ),
      Aggregated: (cellInfo) =>
        cellInfo.groupedByPivot && cellInfo.level < 4 ? '-' : cellInfo.value,
      sortable: false,
      resizable: false,
      className: classNames('center', {
        folded: foldedColumns.indexOf('ID FDES') > -1
      }),
      headerClassName: classNames('center', {
        folded: foldedColumns.indexOf('ID FDES') > -1
      }),
      width: 65
    },
    {
      Header: reportEntryLabels.co2Title,
      accessor: 'co2Title',
      filterMethod: filterRowsAndPivotsThroughSelect,
      Filter: createFilter(reportCo2TitleList(report)),
      Cell: (props) =>
        props.row.co2Title ? <span title={props.row.co2Title}>{props.row.co2Title}</span> : '-',
      aggregate: (values) => (values[0] ? <b title={values[0]}>{values[0]}</b> : '-'),
      Aggregated: (cellInfo) =>
        cellInfo.groupedByPivot && cellInfo.level < 4 ? '-' : cellInfo.value,
      sortable: false,
      resizable: false,
      minWidth: 250,
      maxWidth: 500,
      headerClassName: classNames('noFold')
    },
    {
      Header: reportEntryLabels.co2Type,
      accessor: 'co2Type',
      filterMethod: filterRowsAndPivotsThroughSelect,
      Filter: createFilter(reportCo2TypeList(report)),
      Cell: (props) =>
        props.row.co2Type ? <span title={props.row.co2Type}>{props.row.co2Type}</span> : '-',
      aggregate: (values) => (values[0] ? <b title={values[0]}>{values[0]}</b> : '-'),
      Aggregated: (cellInfo) =>
        cellInfo.groupedByPivot && cellInfo.level < 4 ? '-' : cellInfo.value,
      sortable: false,
      resizable: false,
      width: 60,
      className: classNames('center', {
        folded: foldedColumns.indexOf('Type') > -1
      }),
      headerClassName: classNames('center', {
        folded: foldedColumns.indexOf('Type') > -1
      })
    },
    /* {
      Header: reportEntryLabels.co2TotalLifeCycleCarbon,
      accessor: 'co2TotalLifeCycleCarbon',
      filterMethod: fitterRowsAndPivotsThroughInput(subRowsIndex),
      aggregate: values => formatThousands(values),
      Aggregated: cellInfo =>
        cellInfo.groupedByPivot && cellInfo.level < 4 ? (
          <>&nbsp;</>
        ) : (
          <>
            {cellInfo.value
              ? formatThousands(roundWith2Decimal(getTotalCo2TotalLifeCycleCarbon(cellInfo)))
              : 0}
          </>
        ),
      Cell: cellInfo => (cellInfo.value ? formatThousands(roundWith2Decimal(cellInfo.value)) : 0),
      sortable: false,
      resizable: false,
      width: 55,
      className: classNames('right', {
        folded: foldedColumns.indexOf('CO\u2082 Fiche') > -1
      }),
      headerClassName: classNames('right', {
        folded: foldedColumns.indexOf('CO\u2082 Fiche') > -1
      })
    }, */
    /* {
      Header: reportEntryLabels.co2Lifetime,
      accessor: 'co2Lifetime',
      filterMethod: fitterRowsAndPivotsThroughInput(subRowsIndex),
      aggregate: values => (values[0] ? values[0] : '-'),
      Aggregated: cellInfo =>
        (cellInfo.groupedByPivot && cellInfo.level < 4) || cellInfo.value === '-'
          ? '-'
          : cellInfo.value + ' ans',
      Cell: cellInfo => (cellInfo.value ? cellInfo.value + ' ans' : '-'),
      sortable: false,
      resizable: false,
      width: 55,
      className: classNames('right', {
        folded: foldedColumns.indexOf('DVR') > -1
      }),
      headerClassName: classNames('right', {
        folded: foldedColumns.indexOf('DVR') > -1
      })
    }, */
    /* {
      Header: reportEntryLabels.co2WastageRate,
      accessor: 'co2WastageRate',
      filterMethod: fitterRowsAndPivotsThroughInput(subRowsIndex),
      aggregate: values => (values[0] ? values[0] : '-'),
      Aggregated: cellInfo =>
        cellInfo.groupedByPivot && cellInfo.level < 4
          ? '-'
          : cellInfo.value !== '-'
          ? parseFloat(cellInfo.value) * 100 + '%'
          : '-',
      Cell: cellInfo => (cellInfo.value ? parseFloat(cellInfo.value) * 100 + '%' : '-'),
      sortable: false,
      resizable: false,
      width: 50,
      className: classNames('right', {
        folded: foldedColumns.indexOf('Tx Chute') > -1
      }),
      headerClassName: classNames('right', {
        folded: foldedColumns.indexOf('Tx Chute') > -1
      })
    }, */
    {
      Header: reportEntryLabels.co2Quantity,
      accessor: 'co2Quantity',
      filterMethod: fitterRowsAndPivotsThroughInput(subRowsIndex),
      aggregate: (values) => formatThousands(values), // roundWith2Decimal(R.sum(values)),
      Aggregated: (cellInfo) =>
        cellInfo.groupedByPivot && cellInfo.level < 4 ? (
          <b>&nbsp;</b>
        ) : (
          <b>
            {cellInfo.value ? formatThousands(roundWith2Decimal(getTotalCo2Quantity(cellInfo))) : 0}
          </b>
        ),
      Cell: (cellInfo) =>
        cellInfo.value ? <b>{formatThousands(roundWith2Decimal(cellInfo.value))}</b> : 0,
      sortable: false,
      resizable: false,
      width: 75,
      className: classNames('right', {
        folded: foldedColumns.indexOf('Quantité') > -1
      }),
      headerClassName: classNames('right', {
        folded: foldedColumns.indexOf('Quantité') > -1
      })
    },
    {
      Header: reportEntryLabels.co2QuantityUnit,
      accessor: 'co2QuantityUnit',
      filterMethod: filterRowsAndPivotsThroughSelect,
      Filter: createFilter(reportCo2QuantityUnitList(report)),
      aggregate: (values) => values[0],
      Aggregated: (cellInfo) =>
        cellInfo.groupedByPivot && cellInfo.level < 4 ? '' : formatUnits(cellInfo.value),
      Cell: (cellInfo) => (cellInfo.value ? formatUnits(cellInfo.value) : '-'),
      sortable: false,
      resizable: false,
      width: 30,
      className: classNames('right', {
        folded: foldedColumns.indexOf('U.F.') > -1
      }),
      headerClassName: classNames('right', {
        folded: foldedColumns.indexOf('U.F.') > -1
      })
    },
    /* {
      Header: reportEntryLabels.co2Weight,
      accessor: 'co2Weight',
      filterMethod: fitterRowsAndPivotsThroughInput(subRowsIndex),
      aggregate: values => R.sum(values),
      Aggregated: cellInfo =>
        cellInfo.value !== 0 ? (
          <b>
            {formatThousands(roundWith2Decimal(getTotalCo2Weight(cellInfo)))}
            {'\u00A0'}
            {'kg CO\u2082'}
          </b>
        ) : (
          '-'
        ),
      Cell: cellInfo =>
        cellInfo.value ? (
          <b>
            {formatThousands(roundWith2Decimal(cellInfo.value))}
            {'\u00A0'}
            {'kg CO\u2082'}
          </b>
        ) : (
          '-'
        ),
      sortable: false,
      resizable: false,
      className: 'separator right',
      headerClassName: 'separator right noFold',
      width: 100
    }, */
    {
      Header: reportEntryLabels.comEthIc,
      accessor: 'comEthIc',
      filterMethod: fitterRowsAndPivotsThroughInput(subRowsIndex),
      aggregate: (values) => formatThousands(roundWith2Decimal(R.sum(values))),
      Aggregated: (cellInfo) =>
        cellInfo.groupedByPivot && cellInfo.level < 4 ? (
          '-'
        ) : (
          <b>{roundWith2Decimal(cellInfo.value)}</b>
        ),
      Cell: (cellInfo) => (cellInfo.value ? <b>{roundWith2Decimal(cellInfo.value)}</b> : '-'),
      sortable: false,
      resizable: false,
      width: 80,
      className: classNames('right', {
        folded: foldedColumns.indexOf(reportEntryLabels.comEthIc) > -1
      }),
      headerClassName: classNames('right', {
        folded: foldedColumns.indexOf(reportEntryLabels.comEthIc) > -1
      })
    }
  ]
});
