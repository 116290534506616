import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { isGroundLevel } from '../../../is/isGroundLevel';
import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../../LevelGranulometry';
import { getLevelAlreadyCalculatedRealBuiltSurface } from '../../../surfaces/getLevelAlreadyCalculatedRealBuiltSurface';
import { getTopLevelAlreadyCalculatedSurfaceForSale } from '../../surfaces/surfaceForSale/getTopLevelAlreadyCalculatedSurfaceForSale';
import { mustHaveAHallSection } from '../../../mustHave/mustHaveAHallSection';
import { HallSection, isSectionAHall } from '../../../../../sections/circulationSections/hall';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { getSectionDisplayedSurface } from '../../../../../sections/queries/surfaces/getSectionDisplayedSurface';
import { getTopLevelStairsShafWallsSurface } from '../../walls/stairsShaftWalls/getTopLevelStairsShafWallsSurface';
import { mustHaveStairShaftSections } from '../../../mustHave/mustHaveStairShaftSections';
import { mustHaveElevatorShaftSections } from '../../../mustHave/mustHaveElevatorShaftSections';
import { getTopLevelElevatorShafWallsSurface } from '../../walls/elevatorShaftWalls/getTopLevelElevatorShafWallsSurface';
import { ceilWith2Decimal } from '../../../../../../../utils/round/ceilWith2Decimal';
import { getCasePropertyValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyValue';
import { getTopLevelInsideWallsThickness } from '../../walls/wallsThickness/getTopLevelInsideWallsThickness';
import { getLevelTechnicalPermiseSections } from '../../../sections/getLevelTechnicalPermiseSections';
import { getSectionTheoreticalInsideWallsSurface } from '../../../../../sections/queries/walls/theoreticalWalls/insideWalls/getSectionTheoreticalInsideWallsSurface';
import { getBearingTechnicalSheathsSurface } from '../../../../../sections/queries/bearingTechnicalSheaths/getBearingTechnicalSheathsSurface';
import { getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface } from '../../surfaces/sectionsWithTechnicalSheaths/getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface';
import { getTopLevelMinimumBearingSurface } from '../../surfaces/getTopLevelMinimumBearingSurface';
import { BEARING_EMERGENCY_STAIRS_SURFACE } from '../../../../../../../constants/appConstants';
import { getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface } from '../../walls/includedInSurfaceForSaleSectionWalls/getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface';
import { getTopLevelLodgmentsPartitionWallsSurface } from '../../walls/lodgmentWalls/getTopLevelLodgmentsPartitionWallsSurface';
import { getLevelFullFilledContent } from '../../../content/getLevelFullFilledContent';
import { getTopLevelIncludedInSurfaceForSaleSectionsCount } from '../../counts/getTopLevelIncludedInSurfaceForSaleSectionsCount';

export const getTopLevelBearingSurfaceDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithBearingRelativeSections | LevelGranulometry,
  withMinimumBearingSurface: boolean
): string => {
  let details = '';

  const levelRealBuiltSurface = getLevelAlreadyCalculatedRealBuiltSurface(levelGranulometry).value;
  let bearingSurface = levelRealBuiltSurface;
  details += '<b>' + roundWith2Decimal(levelRealBuiltSurface) + ' m\u00B2</b> de SdP réelle';

  if (getTopLevelIncludedInSurfaceForSaleSectionsCount(levelGranulometry) !== 0) {
    const levelSurfaceForSale = getTopLevelAlreadyCalculatedSurfaceForSale(levelGranulometry).value;
    bearingSurface -= levelSurfaceForSale;
    details += '<br /><b>- ' + roundWith2Decimal(levelSurfaceForSale) + ' m\u00B2</b> de Shab';
    const topLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface =
      getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface(
        caseGranulometry,
        levelGranulometry
      ).value;
    bearingSurface -= topLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface;
    details +=
      '<br /><b>- ' +
      roundWith2Decimal(topLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface) +
      ' m\u00B2</b> d’emprise de voiles non-exposés de <i>Sections utiles</i>';
    const topLevelLodgmentsPartitionWallsSurface = getTopLevelLodgmentsPartitionWallsSurface(
      caseGranulometry,
      levelGranulometry
    ).value;
    bearingSurface -= topLevelLodgmentsPartitionWallsSurface;
    details +=
      '<br /><b>- ' +
      roundWith2Decimal(topLevelLodgmentsPartitionWallsSurface) +
      ' m\u00B2</b> d’emprise de cloisons de <i>Logements</i>';
  }

  bearingSurface -= getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface(
    caseGranulometry,
    levelGranulometry
  ).value;
  details +=
    '<br /><b>- ' +
    roundWith2Decimal(
      getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface(
        caseGranulometry,
        levelGranulometry
      ).value
    ) +
    ' m\u00B2</b> d’emprise théorique de gaines techniques de <i>Sections utiles</i> et <i>Locaux techniques</i> <i>– GTL</i>';

  bearingSurface -= BEARING_EMERGENCY_STAIRS_SURFACE;
  details +=
    '<br /><b>- ' + BEARING_EMERGENCY_STAIRS_SURFACE + ' m\u00B2</b> d‘escalier de secours';

  bearingSurface -= getBearingTechnicalSheathsSurface(caseGranulometry, levelGranulometry).value;
  details +=
    '<br /><b>- ' +
    roundWith2Decimal(
      getBearingTechnicalSheathsSurface(caseGranulometry, levelGranulometry).value
    ) +
    ' m\u00B2</b> de gaines technnique palières <i>– GTP</i>';

  // For ground level only
  if (isGroundLevel(levelGranulometry)) {
    if (mustHaveAHallSection(caseGranulometry, levelGranulometry)) {
      const hallSection = getLevelFullFilledContent(levelGranulometry).filter(
        isSectionAHall
      )[0] as HallSection;
      bearingSurface -= getSectionDisplayedSurface(hallSection);
      details +=
        '<br /><b>- ' +
        roundWith2Decimal(getSectionDisplayedSurface(hallSection)) +
        ' m\u00B2</b> de surface du <i>Hall</i>';
      bearingSurface -= getSectionTheoreticalInsideWallsSurface(
        caseGranulometry,
        levelGranulometry,
        hallSection
      );
      details +=
        '<br /><b>- ' +
        roundWith2Decimal(
          getSectionTheoreticalInsideWallsSurface(caseGranulometry, levelGranulometry, hallSection)
        ) +
        ' m\u00B2</b> d’emprise théorique de voiles non-exposés du <i>Hall</i>';
    }
  }
  // Technical permises
  details += getLevelTechnicalPermiseSections(levelGranulometry).reduce((acc, technicalPermise) => {
    bearingSurface -= getSectionDisplayedSurface(technicalPermise);
    return (
      acc +
      '<br /><b>- ' +
      roundWith2Decimal(getSectionDisplayedSurface(technicalPermise)) +
      ' m\u00B2</b> de <i>' +
      technicalPermise.title +
      '</i>'
    );
  }, '');
  if (mustHaveStairShaftSections(caseGranulometry)) {
    bearingSurface -= getTopLevelStairsShafWallsSurface(caseGranulometry, levelGranulometry) / 2;
    details +=
      '<br /><b>- ' +
      getTopLevelStairsShafWallsSurface(caseGranulometry, levelGranulometry) +
      '  m\u00B2</b> d’emprise de voiles de <i>Cage d’escalier</i> <b>* 1/2</b> ';
  }

  if (mustHaveElevatorShaftSections(caseGranulometry)) {
    bearingSurface -= getTopLevelElevatorShafWallsSurface(caseGranulometry, levelGranulometry) / 2;
    details +=
      '<br /><b>- ' +
      getTopLevelElevatorShafWallsSurface(caseGranulometry, levelGranulometry) +
      ' m\u00B2</b> d’emprise de voiles de <i>Cage d’ascenseur</i> <b>* 1/2</b> ';
  }

  // Remove bearing walls surface
  const bearingWidth = getCasePropertyValue(
    caseGranulometry.initialSpecifications,
    'bearingWidth'
  ) as number;
  const bearingLength = bearingSurface / bearingWidth;

  const insideWallsThickness = getTopLevelInsideWallsThickness(caseGranulometry);
  const bearingInsideWallsSurface = insideWallsThickness * (bearingLength + bearingWidth) * 2 * 0.5;
  bearingSurface -= bearingInsideWallsSurface;
  details +=
    '<br /><b>- ' +
    roundWith2Decimal(bearingInsideWallsSurface) +
    ' m\u00B2</b> d’emprise de voiles non-exposés du <i>Palier</i> <b>* 1/2</b><br />&nbsp;&nbsp;&nbsp;<i>[' +
    insideWallsThickness +
    ' m\u00B2 * (' +
    roundWith2Decimal(bearingLength) +
    ' m +' +
    bearingWidth +
    ' m) * 2]</i>';

  bearingSurface = ceilWith2Decimal(bearingSurface);

  details += '<br /><b>= ' + bearingSurface + ' m\u00B2</b>';

  const minimumBearingSurface = getTopLevelMinimumBearingSurface(
    caseGranulometry,
    levelGranulometry
  ).value;

  if (withMinimumBearingSurface && bearingSurface < minimumBearingSurface) {
    details +=
      '<br /><br /><b>-> Surface minimale de pallier appliquée = ' +
      roundWith2Decimal(minimumBearingSurface) +
      '</b>';
  }

  return details;
};
