import { OTHERS_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { getCasePropertyDefaultValue } from '../../../specification/cases/queries/get/properties/getCasePropertyDefaultValue';
import { ParkingName } from './ParkingSection';
import { SectionWithTheoreticalSurface } from '../SectionWithTheoreticalSurface';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';
import { SectionWithWidth } from '../SectionWithWidth';
import { SectionWithDepth } from '../isSectionWithDepth';

interface CarBoxInfraProps {
  surface: number;
  depth: number;
  width: number;
  outsideWallsConcreteRatio: number;
  beamHeight: number;
  beamWidth: number;
  pillarThickness: number;
  pillarWidth: number;
  partitionThickness: number;
}

export interface CarBoxInfraSection
  extends Section,
    SectionDrawn,
    SectionWithWidth,
    SectionWithDepth,
    SectionWithTheoreticalSurface {
  name: ParkingName.CarBoxInfra;
  outsideWallsConcreteRatio: number;
  beamHeight: number;
  beamWidth: number;
  pillarThickness: number;
  pillarWidth: number;
  partitionThickness: number;
}

export const CAR_BOX_ICON = {
  type: 'object',
  iconName: 'carBox',
  color: OTHERS_COLOR
} as IconObjectProps;

const carBoxInfra = ({
  surface,
  depth,
  width,
  outsideWallsConcreteRatio,
  beamHeight,
  beamWidth,
  pillarThickness,
  pillarWidth,
  partitionThickness
}: CarBoxInfraProps): CarBoxInfraSection => ({
  // Section
  id: 'NOID',
  name: ParkingName.CarBoxInfra,
  title: i18next.t('carBoxInfra.title'),
  type: 'parking',
  displayedSurface: surface,
  // SectionDrawn
  surface,
  color: OTHERS_COLOR,
  icon: CAR_BOX_ICON,
  // SectionWithTheoreticalSurface
  theoreticalSurface: surface,
  defaultTheoreticalSurface:
    getCasePropertyDefaultValue('carBoxWidth') * getCasePropertyDefaultValue('carBoxDepth'),
  // CarBoxInfraSection
  depth,
  width,
  outsideWallsConcreteRatio,
  beamHeight,
  beamWidth,
  pillarThickness,
  pillarWidth,
  partitionThickness
});

export default carBoxInfra;

export const isSectionACarBoxInfra = (section: Section): section is CarBoxInfraSection =>
  getSectionName(section) === ParkingName.CarBoxInfra;
