import { CaseSpecification } from '../../../CaseSpecification';
import { LevelSpecification } from '../../../../levels/LevelSpecification';

export const getLevelSpecificationFromCaseSpecificationWithGivenLevelIndex = (
  caseSpecification: CaseSpecification,
  levelIndex: number
): LevelSpecification | undefined =>
  caseSpecification.levels.filter(
    (levelSpecification) => levelSpecification.level === levelIndex
  )[0];
