import * as React from 'react';
import { useSelector, useDispatch} from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  ERROR_COLOR,
  GRAY_COLOR,
  VALID_COLOR,
  WARNING_COLOR
} from '../../../constants/appConstants';
import { selectIsComEthReportFetching } from '../../../store/selectors/cstb/isComEthReportFetching.selector';
import { selectBuildingMapFocus } from '../../../store/selectors/buildingMap/buildingMapFocus.selector';
import { selectIsReportOutdated } from '../../../store/selectors/report/isReportOutdated.selector';
import { selectFocusedEnvironmentalIndicators } from '../../../store/selectors/cstb/focusedEnvironmentalIndicators.selector';
import { DataBarSegment } from './DataBarSegment';
import { DataBarItem } from './DataBarItem';
import { selectHasProjectValidLocation } from '../../../store/selectors/navigation/toolbox/projectPanel/hasProjectValidLocation.selector';
import { selectIsFetchingReport } from '../../../store/selectors/report/isFetchingReport.selector';
import classNames from 'classnames';
import { selectProjectRE2020Threshold } from '../../../store/selectors/project/projectRE2020ThresholdMode';
import {
  COM_ETH_IC_CONSTRUCTION_DEFAULT_TOLERANCE_RATE
} from '../../../domain/project/ProjectCstbIndicatorsParameters';
import {
  selectProjectComEthIcConstructionToleranceRate
} from '../../../store/selectors/project/projectComEthIcConstructionToleranceRate.selector';
import {
  activatedValueOfRE2020
} from '../../business/panels/project/ProjectPanelView/ProjectToleranceRate/ProjectRE2020Thresold';
import { projectRE2020ThresholdChanged } from '../../../store/actions/projectRE2020TypeChanged.action';


const getIndicatorColor = (
  dirty: boolean,
  value: number,
  maxValue: number,
  toleratedMaxValue?: number
): string => {
  if (dirty) {
    return GRAY_COLOR;
  } else if (
    toleratedMaxValue &&
    ((toleratedMaxValue > maxValue && value > maxValue && value < toleratedMaxValue) ||
      (toleratedMaxValue < maxValue && value < maxValue && value > toleratedMaxValue))
  ) {
    return WARNING_COLOR;
  } else if (value > maxValue) {
    return ERROR_COLOR;
  } else {
    return VALID_COLOR;
  }
};

export const DataBarComEthIndicatorsSegment = () => {
  const { t } = useTranslation();

  const isReportFetching = useSelector(selectIsFetchingReport);
  const isComEthReportFetching = useSelector(selectIsComEthReportFetching);
  const isReportOutdated = useSelector(selectIsReportOutdated);
  const comEthIndicators = useSelector(selectFocusedEnvironmentalIndicators);
  const hasValidLocation = useSelector(selectHasProjectValidLocation);
  const focus = useSelector(selectBuildingMapFocus);
  let projectRE2020ThresholdMode = useSelector(selectProjectRE2020Threshold);
  const comEthIcConstructionToleranceRate = useSelector(
    selectProjectComEthIcConstructionToleranceRate
  );


  if (isReportFetching || isComEthReportFetching) {
    return (
      <DataBarSegment className="environmental">
        <div
          className={classNames('loaderGray', {
            isReportFetching
          })}
        />
      </DataBarSegment>
    );
  }

  if (!comEthIndicators) {
    return null;
  }

  const {
    icConstruction,
    icEnergy,
    cep,
    cepMax,
    cepNr,
    cepNrMax,
    bBio,
    bBioMax,
    dh,
    dhMax
  } = comEthIndicators;

  let icConstructionMax = comEthIndicators.icConstruction;
  let icEnergyMax = comEthIndicators.icEnergyMax;

  let RE2020SelectedValue;

  //Patch pour les projets ne contenant pas de referentiel RE2020
  if(projectRE2020ThresholdMode === undefined){
    projectRE2020ThresholdMode= {
      threshold:activatedValueOfRE2020()
    };
  }

  if(projectRE2020ThresholdMode.threshold !== null || projectRE2020ThresholdMode.threshold !== undefined){
    if(projectRE2020ThresholdMode.threshold ==="2020") RE2020SelectedValue = "2022";
    RE2020SelectedValue = projectRE2020ThresholdMode.threshold;
    const paramConstruction: string = "icConstructionMax";
    const dynamicConstructionRE2020 : string = paramConstruction + RE2020SelectedValue;
    icConstructionMax = comEthIndicators[dynamicConstructionRE2020];
    if(RE2020SelectedValue !== '2031'){
      const paramEnergy: string = "icEnergyMax";
      const dynamicEnergyRE2020 : string = paramEnergy + RE2020SelectedValue;
      icEnergyMax = comEthIndicators[dynamicEnergyRE2020];
    }
  }

  let icConstructionToleratedMax = icConstructionMax *
      (1 +
        (comEthIcConstructionToleranceRate ||
          COM_ETH_IC_CONSTRUCTION_DEFAULT_TOLERANCE_RATE));

  return (
    <DataBarSegment className="environmental">
      <div className="itemGroup">
        {process.env.GB_FEAT_CSTB_INDICATOR_IC_CONSTRUCTION === 'true' && (
          <DataBarItem
            title={t('comEthIndicators.IcConstruction.title')}
            shortenedTitle={t('comEthIndicators.IcConstruction.shortenedTitle')}
            description={t('comEthIndicators.IcConstruction.description')}
            iconName="pellet"
            iconColor={getIndicatorColor(
              isReportOutdated,
              icConstruction,
              icConstructionMax,
              icConstructionToleratedMax
            )}
            iconColor2={getIndicatorColor(
              isReportOutdated,
              icConstruction,
              icConstructionMax,
              icConstructionToleratedMax
            )}
            value={icConstruction}
            maxValue={icConstructionMax || '?'}
            toleratedMaxValue={icConstructionToleratedMax}
            hiddenValue={focus === 'project'}
            unit={t('comEthIndicators.IcConstruction.unit')}
            shortenedUnit={t('comEthIndicators.IcConstruction.shortenedUnit')}
          />
        )}
        {process.env.GB_FEAT_CSTB_INDICATOR_IC_ENERGY === 'true' && (
          <DataBarItem
            title={t('comEthIndicators.IcEnergy.title')}
            shortenedTitle={t('comEthIndicators.IcEnergy.shortenedTitle')}
            description={t('comEthIndicators.IcEnergy.description')}
            iconName="pellet"
            iconColor={getIndicatorColor(
              isReportOutdated || !hasValidLocation,
              icEnergy,
              icEnergyMax
            )}
            iconColor2={getIndicatorColor(
              isReportOutdated || !hasValidLocation,
              icEnergy,
              icEnergyMax
            )}
            value={hasValidLocation ? icEnergy : undefined}
            maxValue={icEnergyMax}
            hiddenValue={focus === 'project'}
            unit={t('comEthIndicators.IcEnergy.unit')}
            shortenedUnit={t('comEthIndicators.IcEnergy.shortenedUnit')}
            addressIsNeeded={true}
          />
        )}
        {process.env.GB_FEAT_CSTB_INDICATOR_BBIO === 'true' && (
          <DataBarItem
            title={t('comEthIndicators.bBio.title')}
            shortenedTitle={t('comEthIndicators.bBio.shortenedTitle')}
            description={t('comEthIndicators.bBio.description')}
            iconName="pellet"
            iconColor={getIndicatorColor(isReportOutdated || !hasValidLocation, bBio, bBioMax)}
            iconColor2={getIndicatorColor(isReportOutdated || !hasValidLocation, bBio, bBioMax)}
            value={hasValidLocation ? bBio : undefined}
            maxValue={bBioMax}
            hiddenValue={focus === 'project'}
            unit={t('comEthIndicators.bBio.unit')}
            shortenedUnit={t('comEthIndicators.bBio.shortenedUnit')}
            addressIsNeeded={true}
          />
        )}
      </div>
      <>
        {(process.env.GB_FEAT_CSTB_INDICATOR_CEP === 'true' ||
          process.env.GB_FEAT_CSTB_INDICATOR_CEP_NR === 'true' ||
          process.env.GB_FEAT_CSTB_INDICATOR_DH === 'true') && (
          <>
            <div className="lineBreak" />
            <div className="itemGroup">
              {process.env.GB_FEAT_CSTB_INDICATOR_CEP === 'true' && (
                <DataBarItem
                  title={t('comEthIndicators.cep.title')}
                  shortenedTitle={t('comEthIndicators.cep.shortenedTitle')}
                  description={t('comEthIndicators.cep.description')}
                  iconName="pellet"
                  iconColor={getIndicatorColor(isReportOutdated || !hasValidLocation, cep, cepMax)}
                  iconColor2={getIndicatorColor(isReportOutdated || !hasValidLocation, cep, cepMax)}
                  value={hasValidLocation ? cep : undefined}
                  maxValue={cepMax}
                  hiddenValue={focus === 'project'}
                  unit={t('comEthIndicators.cep.unit')}
                  shortenedUnit={t('comEthIndicators.cep.shortenedUnit')}
                  addressIsNeeded={true}
                />
              )}
              {process.env.GB_FEAT_CSTB_INDICATOR_CEP_NR === 'true' && (
                <DataBarItem
                  title={t('comEthIndicators.cepNr.title')}
                  shortenedTitle={t('comEthIndicators.cepNr.shortenedTitle')}
                  description={t('comEthIndicators.cepNr.description')}
                  iconName="pellet"
                  iconColor={getIndicatorColor(
                    isReportOutdated || !hasValidLocation,
                    cepNr,
                    cepNrMax
                  )}
                  iconColor2={getIndicatorColor(
                    isReportOutdated || !hasValidLocation,
                    cepNr,
                    cepNrMax
                  )}
                  value={hasValidLocation ? cepNr : undefined}
                  maxValue={cepNrMax}
                  hiddenValue={focus === 'project'}
                  unit={t('comEthIndicators.cepNr.unit')}
                  shortenedUnit={t('comEthIndicators.cepNr.shortenedUnit')}
                  addressIsNeeded={true}
                />
              )}
              {process.env.GB_FEAT_CSTB_INDICATOR_DH === 'true' && (
                <DataBarItem
                  title={t('comEthIndicators.dh.title')}
                  shortenedTitle=""
                  description={t('comEthIndicators.dh.description')}
                  iconName="pellet"
                  iconColor={getIndicatorColor(isReportOutdated || !hasValidLocation, dh, dhMax)}
                  iconColor2={getIndicatorColor(isReportOutdated || !hasValidLocation, dh, dhMax)}
                  value={hasValidLocation ? dh : undefined}
                  maxValue={dhMax}
                  hiddenValue={focus === 'project'}
                  unit={t('comEthIndicators.dh.unit')}
                  addressIsNeeded={true}
                />
              )}
            </div>
          </>
        )}
      </>
    </DataBarSegment>
  );
};
