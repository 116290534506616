import * as React from 'react';
import { IconObject } from '../../ui/Icons/iconObject';
import { notificationsHasError } from '../../../domain/notifications/notificationsHasError';
import { Notification } from '../../../domain/notifications/Notification';
import { ERROR_COLOR, INFO_COLOR, WARNING_COLOR } from '../../../constants/appConstants';
import { notificationsHasWarning } from '../../../domain/notifications/notificationsHasWarning';

interface HeadIconProps {
  notifications: Notification[];
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
}

const HeadIcon = ({ notifications, setIsOpen, isOpen }: HeadIconProps) => {
  const hasError = notificationsHasError(notifications);
  const hasWarning = notificationsHasWarning(notifications);
  return (
    <div className="headIcon">
      {notifications.length === 0 && (
        <IconObject
          color="#009900"
          color2="#009900"
          iconName="notificationsChecked"
          title="Notifications checked"
          type="menu"
          clickable={false}
        />
      )}
      {notifications.length > 0 && (
        <IconObject
          color={hasError ? ERROR_COLOR : hasWarning ? WARNING_COLOR : INFO_COLOR}
          color2={hasError ? ERROR_COLOR : hasWarning ? WARNING_COLOR : INFO_COLOR}
          iconName={
            hasError
              ? 'notificationsError'
              : hasWarning
              ? 'notificationsWarning'
              : 'notificationsInfo'
          }
          label={notifications.length.toString()}
          title={
            hasError
              ? 'Notifications error'
              : hasWarning
              ? 'Notifications warning'
              : 'notificationsInfo'
          }
          type="menu"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />
      )}
    </div>
  );
};

export default HeadIcon;
