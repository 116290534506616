import { CaseId } from '../../../domain/specification/cases/CaseSpecification';
import { matchesPath } from '../../utils/matchesPath';
import { orCreate } from '../../utils/orCreate';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type CaseGranulometryEditGeometryRouteParams = {
  projectId: string;
  caseId: CaseId;
};
export const caseGranulometryEditGeometryRoute: RouteWithMakePath<CaseGranulometryEditGeometryRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/geometry`,
    makePath: ({ projectId, caseId }) =>
      `/project/${orCreate(projectId)}/cases/${caseId}/granulometry/edit/geometry`,
    matchesPath: matchesPath<CaseGranulometryEditGeometryRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/geometry/,
      {
        projectId: 1,
        caseId: 2
      }
    )
  };
