import * as React from 'react';
import { LINEAR_METER_SCALE } from '../../../constants/appConstants';

interface GroundsProps {
  hardRocksDepth: number;
  softRocksDepth: number;
}

export const Grounds: React.FC<GroundsProps> = ({ hardRocksDepth, softRocksDepth }) => {
  const scaledHardRocksDepth = hardRocksDepth * LINEAR_METER_SCALE;
  const scaledSoftRocksDepth = softRocksDepth * LINEAR_METER_SCALE;
  return (
    <div className="grounds">
      <div
        className="hardRocksGround"
        style={{
          top: `${scaledHardRocksDepth}px`,
          height: `calc(100% - ${scaledHardRocksDepth}px)`
        }}
      />
      <div
        className="softRocksGround"
        style={{
          top: scaledSoftRocksDepth + 'px',
          height: 'calc(100% -  ' + scaledSoftRocksDepth + 'px)'
        }}
      />
    </div>
  );
};
