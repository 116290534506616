import { CaseProjection, MaxSurfaceForSaleByLevel } from '../../CaseProjection';
import { Surface } from '../../../../specification/Surface';
import { isTopLevel } from '../../../../granulometry/levels/queries/is/isTopLevel';
import { getCaseProjectionFromCaseSpecification } from '../getCaseProjectionFromCaseSpecification';
import { runFakeGranulo } from './runFakeGranulo';
import { getCaseMaxSurfaceForSale } from './getCaseMaxSurfaceForSale';
import { isSectionABearing } from '../../../../granulometry/sections/circulationSections/bearing';
import { getCaseMaxSurfaceForSaleByTopLevels } from './getCaseMaxSurfaceForSaleByTopLevels';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { getLevelFullFilledContent } from '../../../../granulometry/levels/queries/content/getLevelFullFilledContent';

const MAX_SFS_CALCULATION_ITERATION_COUNT = 4;

export const calculateMaxSurfaceForSale = (caseProjection: CaseProjection): CaseProjection => {
  // Get the useful surface relatives entries :
  const projectedCbs = caseProjection.projectedCuttedBuiltSurface;
  const isSfsFilledByUser = caseProjection.surfaces.surfaceForSale !== undefined;
  const defaultCalculatedSfs = (caseProjection.projectedSurfaceForSale as Surface).value;
  // Initiate calculation variables (without initial surface relatives entries)
  let updatedCaseProjection: CaseProjection = {
    ...caseProjection,
    surfaces: {
      ...caseProjection.surfaces,
      surfaceForSale: undefined,
      realBuiltSurface: undefined
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    projectedSurfaceForSale: undefined,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    projectedRealBuiltSurface: undefined
  }; // evolving case projection
  const maxSfsByLevelsByIteration: MaxSurfaceForSaleByLevel[][] = []; // iteration recorder
  // Start running a series of calculation :
  for (let i = 1; i <= MAX_SFS_CALCULATION_ITERATION_COUNT; i++) {
    // Inject the previous iteration result into the case projection
    updatedCaseProjection = getCaseProjectionFromCaseSpecification(
      projectedCbs,
      false
    )({
      ...updatedCaseProjection,
      surfaces: {
        ...updatedCaseProjection.surfaces,
        realBuiltSurface: updatedCaseProjection.projectedRealBuiltSurface,
        cuttedBuiltSurface: updatedCaseProjection.projectedCuttedBuiltSurface,
        surfaceForSale: updatedCaseProjection.projectedSurfaceForSale
      }, // it's like if projection are forced
      customDistribution: [] // maxSfs is only on granulometry stage
    });
    // Run fake granulo
    const fakeGranulo = runFakeGranulo(updatedCaseProjection);
    // Get the case in the resulted building granulometry
    const caseGranulometryFromFakeGranulo = fakeGranulo.cases[0];
    // Get the sum of all top levels bearing surface
    const sumOfMinimumBearingSurface = caseGranulometryFromFakeGranulo.levels.reduce(
      (acc, levelGranulometry) => {
        if (isTopLevel(caseGranulometryFromFakeGranulo, levelGranulometry)) {
          const bearing = getLevelFullFilledContent(levelGranulometry).find(isSectionABearing);
          return bearing ? acc + bearing.displayedSurface : acc;
        }
        return acc;
      },
      0
    );
    // Save it in the updated projection
    updatedCaseProjection.projectedSumOfMinimumBearingSurface = sumOfMinimumBearingSurface
      ? new Surface(sumOfMinimumBearingSurface)
      : Surface.EMPTY;
    // Calculate the max SFS by top levels
    const maxSfsByLevels = getCaseMaxSurfaceForSaleByTopLevels(caseGranulometryFromFakeGranulo);
    // Push the result in the recorder
    maxSfsByLevelsByIteration.push(maxSfsByLevels);
    // If there is other iteration to run :
    if (i !== MAX_SFS_CALCULATION_ITERATION_COUNT) {
      // Re-inject the max SFS calculation value
      // updatedCaseProjection.projectedRealBuiltSurface = undefined; // remove value for case spec
      // updatedCaseProjection.projectedCuttedBuiltSurface = undefined; // remove value for case spec
      updatedCaseProjection.projectedSurfaceForSale = new Surface(
        getCaseMaxSurfaceForSale(maxSfsByLevels)
      );
    } else {
      // Sometimes the lodgment count / shab max is oscillates each two iteration, so we pick the higher one
      const theMaxOne = Math.max(
        getCaseMaxSurfaceForSale(maxSfsByLevelsByIteration[0]),
        getCaseMaxSurfaceForSale(maxSfsByLevelsByIteration[1]),
        getCaseMaxSurfaceForSale(maxSfsByLevelsByIteration[2]),
        getCaseMaxSurfaceForSale(maxSfsByLevelsByIteration[3])
      );
      // Then we get the max sfs by levels corresponding to the max one
      const correspondingMaxSfsByLevels = maxSfsByLevelsByIteration.find(
        (maxSfsByLevels) => getCaseMaxSurfaceForSale(maxSfsByLevels) === theMaxOne
      ) as unknown as MaxSurfaceForSaleByLevel[];
      // Check if the default calculated sfs is higher than the calculated max one
      const isCalculatedSFSHigherThanMaxSFS = defaultCalculatedSfs >= theMaxOne;
      // Check if the SFS filled by user is equal to max
      const isSFSFilledByUserEqualToMaxSFS =
        isSfsFilledByUser &&
        roundWith2Decimal((caseProjection.surfaces.surfaceForSale as Surface).value) ===
          roundWith2Decimal(theMaxOne);
      // Check if the max SFS must be forced
      const maxSurfaceForSaleMustBeenForced =
        (!isSfsFilledByUser && isCalculatedSFSHigherThanMaxSFS) || isSFSFilledByUserEqualToMaxSFS;
      // If true and there is no SFS filled by user : we automaticaly use the max one
      const sfs = maxSurfaceForSaleMustBeenForced ? theMaxOne : defaultCalculatedSfs;
      // We built the new resulting case projection
      updatedCaseProjection = {
        ...caseProjection,
        projectedSurfaceForSale: new Surface(sfs),
        projectedSumOfMinimumBearingSurface: new Surface(sumOfMinimumBearingSurface),
        projectedMaxSurfaceForSale: new Surface(theMaxOne),
        projectedMaxSurfaceForSaleHasBeenForced: maxSurfaceForSaleMustBeenForced, // note : there if several consequences in the final granulo
        projectedMaxSurfaceForSaleByLevels: correspondingMaxSfsByLevels
      };
    }
  }
  return updatedCaseProjection;
};
