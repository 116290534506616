import { LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { getTopLevelGrossFloorSurfaceEff } from './getTopLevelGrossFloorSurfaceEff';
import { getTopLevelHoppersSurface } from '../getTopLevelHoppersSurface';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelGrossFloorSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled
): number =>
  getTopLevelGrossFloorSurfaceEff(caseGranulometry, levelGranulometry) -
  getTopLevelHoppersSurface(caseGranulometry, levelGranulometry);
