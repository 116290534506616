import { createSelector, Selector } from 'reselect';
import * as R from 'ramda';
import { ifDefined } from '../../../../utils/ifDefined';
import { selectParkingsCountByBuilding } from './parkingsCountByBuilding.selector';
import { State } from '../../../../store/reducers';
import { Maybe } from '../../../../utils/Maybe';

export const selectParkingsCount: Selector<State, Maybe<number>> = createSelector(
  [selectParkingsCountByBuilding],
  ifDefined(R.pipe(R.values, R.sum))
);
