import { ExistingProjectBasicInfo } from '../../domain/project/Project';
import { get } from './admin.api';
import { projectFromApiAdapter } from './adapters/projectFromApi.adapter';
import { User } from '../../domain/User';
import { AuthToken } from '../../AuthToken';

export interface APIProject {
  id: string;
  reference: unknown;
  stage_id: unknown;
  owner_id: string;
  title: string;
  description: string;
  remark: unknown;
  created: string;
  created_by: string;
  modified: string;
  modified_by: string;
}

type APIProjectListResponse = APIProject[];

export const fetchProjects = async (
  userId?: User['id'],
  userToken?: AuthToken
): Promise<ExistingProjectBasicInfo[]> => {
  const response = (await get(
    `/Projects/index/JSON/${userId || 'noUserId'}/${userToken || 'noUserToken'}`,
    userToken || 'noUserToken'
  )) as unknown as Response;
  const list = (await response.json()) as APIProjectListResponse;
  return list.map(projectFromApiAdapter);
};
