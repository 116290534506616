import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SpecialLabel } from 'components/ui/SpecialLabel';
import { FormGroup } from 'components/ui/FormGroup';
import { Card } from '../../../../ui/Card';
import { Button } from '../../../../ui/Button';
import { selectProjectId } from '../../../../../store/selectors/project';
import { selectIsProjectDetailsGingerOpened } from '../../../../../store/selectors/navigation/toolbox/projectPanel/isProjectDetailsGingerOpened.selector';
import { selectIsProjectDetailsGingerAvailable } from '../../../../../store/selectors/navigation/toolbox/projectPanel/isProjectDetailsGingerAvailable.selector';
import { Note } from '../../../../ui/Note';
import { useTranslation } from 'react-i18next';
import { getProjectDetailsGingerTriggerId } from '../../../../../routes/toolbox/projectPanel/triggers/getProjectDetailsGingerTriggerId';
import { goToProjectDetailsEditGinger } from '../../../../../store/actions/navigations/toolbox/projectPanel/goToProjectDetailsEditGinger.action';

export const ProjectSoilPreStudyCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isProjectDetailsGingerOpened = useSelector(selectIsProjectDetailsGingerOpened);
  const projectId = useSelector(selectProjectId);
  const isEnabled = useSelector(selectIsProjectDetailsGingerAvailable);

  return (
    <Card>
      <SpecialLabel label={t('projectSoilPreStudyCard.label')} />
      {!isEnabled && <Note>{t('projectSoilPreStudyCard.note')}</Note>}
      <FormGroup flexEnd>
        <Button
          id={getProjectDetailsGingerTriggerId()}
          size="small"
          selected={isProjectDetailsGingerOpened}
          content={t('projectSoilPreStudyCard.buttonContent')}
          appearance="outline"
          handleClick={() => {
            if (projectId) {
              dispatch(goToProjectDetailsEditGinger(projectId));
            }
          }}
          disabled={!isEnabled}
        />
      </FormGroup>
    </Card>
  );
};
