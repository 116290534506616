import classNames from 'classnames';
import * as React from 'react';
import { BuildingMapCaseProps, BuildingMapCaseView } from '../case/BuildingMapCaseView';

export interface BuildingMapBuildingProps {
  cases: BuildingMapCaseProps[];
  /** Unique identifier for this Building */
  id: string;
  /** Has a previous building */
  prev?: BuildingMapBuildingProps;
  /** Has a next building */
  next?: BuildingMapBuildingProps;
  firstCaseLevelsRefs?: Map<number, React.Ref<HTMLDivElement>>;
  highestCaseLevelsRefs?: Map<number, React.Ref<HTMLDivElement>>;
}

export const BuildingMapBuildingView: React.SFC<BuildingMapBuildingProps> = (props) => {
  const { cases, id } = props;
  const classes = classNames('building', {
    first: !props.prev,
    last: !props.next
  });

  return (
    <div id={'building' + id} className={classes}>
      {cases.map((element: BuildingMapCaseProps, index: number) => {
        if (cases[index - 1]) {
          cases[index].prev = cases[index - 1];
        }
        if (cases[index + 1]) {
          cases[index].next = cases[index + 1];
        }
        return (
          <BuildingMapCaseView
            buildingId={id}
            key={index}
            {...cases[index]}
            levelsRefs={index === 0 ? props.firstCaseLevelsRefs : undefined}
          />
        );
      })}
    </div>
  );
};
