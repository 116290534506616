import * as R from 'ramda';
import {
  LevelGranulometryFullFilled,
  LevelGranulometryWithBearingRelativeSections,
  LevelGranulometryWithLodgments
} from '../../../LevelGranulometry';
import { getLevelSections } from '../../sections/getLevelSections';
import {
  filterLodgments,
  LodgmentSection
} from '../../../../sections/lodgmentSections/LodgmentSection';

export const getTopLevelLodgmentSections = (
  levelGranulometry:
    | LevelGranulometryWithLodgments
    | LevelGranulometryWithBearingRelativeSections
    | LevelGranulometryFullFilled
): LodgmentSection[] => R.pipe(getLevelSections, filterLodgments)(levelGranulometry);
