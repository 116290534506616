import * as R from 'ramda';
import { getSectionName, Section } from '../Section';
import restaurantSection, { RestaurantSection } from './restaurantSection';
import balneaSection, { BalneaSection } from './balneaSection';
import cafeteriaSection, { CafeteriaSection } from './cafeteriaSection';
import collectiveKitchenSection, { CollectiveKitchenSection } from './collectiveKitchenSection';
import meetingSection, { MeetingSection } from './meetingSection';
import receptionHallSection, { ReceptionHallSection } from './receptionHallSection';

export enum CommonPremiseSectionName {
  BalneaSection = 'balneaSection',
  CafeteriaSection = 'cafeteriaSection',
  CollectiveKitchenSection = 'collectiveKitchenSection',
  MeetingSection = 'meetingSection',
  ReceptionHallSection = 'receptionHallSection',
  RestaurantSection = 'restaurantSection'
}

export type CommonPremiseSection =
  | BalneaSection
  | CafeteriaSection
  | CollectiveKitchenSection
  | MeetingSection
  | ReceptionHallSection
  | RestaurantSection;

type GetCommonPremiseName = (commonSection: CommonPremiseSection) => CommonPremiseSectionName;
export const getCommonPremiseName: GetCommonPremiseName = R.prop('name');

export const isSectionACommonPremise = (section: Section): section is CommonPremiseSection =>
  (R.values(CommonPremiseSectionName) as string[]).includes(getSectionName(section));

export const filterCommonPremiseSections = (sections: Section[]): CommonPremiseSection[] =>
  sections.filter(isSectionACommonPremise);

export const getCommonPremiseSectionsList = (): CommonPremiseSection[] => [
  balneaSection({}),
  cafeteriaSection({}),
  collectiveKitchenSection({}),
  restaurantSection({}),
  receptionHallSection({}),
  meetingSection({})
];
