import { createSelector, Selector } from 'reselect';
import { selectPath } from '../../getPath.selector';
import { State } from '../../../../reducers';
import { caseGranulometryEditEnergyAndNetworksRoute } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditEnergyAndNetworksRoute';

export const selectIsCaseGranulometryEditEnergyAndNetworksOpened: Selector<State, boolean> =
  createSelector(
    [selectPath],
    (path) => caseGranulometryEditEnergyAndNetworksRoute.matchesPath(path).matches
  );
