import { Property } from '../../../Property';
import * as R from 'ramda';
import { CaseSpecification } from '../../../CaseSpecification';

export const setCaseProperty =
  (property: Property) =>
  (caseSpecification: CaseSpecification): CaseSpecification =>
    R.over(
      R.lensPath([
        'properties',
        caseSpecification.properties.findIndex((p) => p.name === property.name)
      ]),
      R.pipe(
        R.assoc('value', property.value),
        property.min === undefined ? R.identity : R.assoc('min', property.min),
        property.max === undefined ? R.identity : R.assoc('max', property.max)
      ),
      caseSpecification
    );
