import * as React from 'react';
import { CaseEditingStageName } from '../../../../../domain/specification/cases/CaseSpecification';
import { Input } from '../../../../ui/input/Input';
import { TopLevelSurfaceName } from '../../../../../domain/granulometry/cases/queries/surfaces/getCaseTopLevelsSurfaces';
import { LocalLevelsSurfaceRepartition } from './Column4';
import { roundForSurfacesInputs } from '../../../../../utils/round/roundForSurfacesInputs';

interface TopLevelSurfaceInputProps {
  isRbsSelected: boolean;
  isGfsEffSelected: boolean;
  handleLevelSurfaceChange: (
    level: number | string,
    surfaceName: TopLevelSurfaceName,
    value: number | null
  ) => void;
  caseLabel: string;
  levelIndex: number;
  selectedLevelSurface: TopLevelSurfaceName;
  localLevelsSpecification: LocalLevelsSurfaceRepartition;
  levelSurfaceValue: number | undefined;
  levelSurfacePlaceholder: number | undefined;
  editingStage: CaseEditingStageName;
  isDisabled: boolean;
  asCustomValue: boolean;
}

export const TopLevelSurfaceInput = ({
  isRbsSelected,
  isGfsEffSelected,
  handleLevelSurfaceChange,
  caseLabel,
  levelIndex,
  selectedLevelSurface,
  localLevelsSpecification,
  levelSurfaceValue,
  levelSurfacePlaceholder,
  editingStage,
  isDisabled,
  asCustomValue
}: TopLevelSurfaceInputProps) => (
  <Input
    handleChange={(value: number | null) =>
      isRbsSelected ? handleLevelSurfaceChange(levelIndex, selectedLevelSurface, value) : null
    }
    name={`${caseLabel}TopLevel${levelIndex}${selectedLevelSurface}`}
    type="number"
    size="small"
    min={0}
    color={isRbsSelected && !localLevelsSpecification.isValid ? 'orange' : undefined}
    value={levelSurfaceValue !== undefined ? roundForSurfacesInputs(levelSurfaceValue) : undefined}
    placeholder={
      (isRbsSelected || isGfsEffSelected) && levelSurfacePlaceholder !== undefined
        ? roundForSurfacesInputs(levelSurfacePlaceholder)
        : undefined
    }
    disabled={editingStage === CaseEditingStageName.Distribution || isDisabled}
    asCustomValue={asCustomValue}
  />
);
