import * as R from 'ramda';
import { FlatRoofingSection } from './flatRoofing';
import { TileRoofingSection } from './tileRoofing';
import { getSectionName, Section } from '../Section';

export enum RoofingName {
  FlatRoofing = 'flatRoofing',
  TileRoofing = 'tileRoofing'
}

export type RoofingSection = FlatRoofingSection | TileRoofingSection;

type GetRoofingName = (roofing: RoofingSection) => RoofingName;
export const getRoofingName: GetRoofingName = R.prop('name');

export const isSectionARoofing = (section: Section): section is RoofingSection =>
  (R.values(RoofingName) as string[]).includes(getSectionName(section));

export const filterRoofing = (sections: Section[]): RoofingSection[] =>
  sections.filter(isSectionARoofing);
