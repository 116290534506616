import { CardTitle } from '../../../../../../ui/CardTitle';
import React, { ChangeEvent, useState } from 'react';
import { Card } from '../../../../../../ui/Card';
import { NewDistanceForLine } from '../../../../../../../services/mapBox/utils/polygon/resizePolygon';
import { FormGroup } from '../../../../../../ui/FormGroup';
import { Input } from '../../../../../../ui/input/Input';
import { Button } from '../../../../../../ui/Button';
import { Note } from '../../../../../../ui/Note';
import { StepState } from './SideDrawMode';

interface ResizeCardProps {
  resize: (size: NewDistanceForLine) => void;
  setStep: React.Dispatch<React.SetStateAction<StepState>>;
  step: StepState;
  goStepBack: () => void;
  facadeCount: number;
}
export const ResizeCard = ({ resize, setStep, step, goStepBack, facadeCount }: ResizeCardProps) => {
  const [size, setSize] = useState<NewDistanceForLine>({ distance: 30, lineIndex: 0 });
  const [isValid, setIsValid] = useState<boolean>(false);

  const setDistance = (value: number & ChangeEvent<HTMLInputElement>) => {
    setSize((state) => ({ ...state, distance: value }));
  };
  const setFacadeNumber = (value: number & ChangeEvent<HTMLInputElement>) => {
    setSize((state) => ({ ...state, lineIndex: value - 1 }));
  };

  const validate = () => {
    setStep({ ...step, resize: size });
  };

  const setResize = () => {
    resize(size);
    setIsValid(true);
  };

  return (
    <Card>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <CardTitle>3/ Mettez la forme à l'échelle</CardTitle>
      <FormGroup>
        <div>
          <Input
            label={'Longueur de la façade'}
            placeholder={'Longueur de la façade'}
            name={'longueur'}
            type="number"
            suffix="m"
            value={size.distance}
            handleChange={setDistance}
            useDebounce={false}
            disabled={false}
            min={0}
          />
        </div>
        <div>
          <Input
            label={'Numéro de la façade de référence'}
            placeholder={'Numéro de façade'}
            name={'facade'}
            type="number"
            suffix="façade"
            value={size.lineIndex + 1}
            handleChange={setFacadeNumber}
            useDebounce={false}
            disabled={false}
            min={1}
            max={facadeCount}
          />
        </div>

        <div>
          <Button
            size={'small'}
            content={"Mettre à l'échelle"}
            handleClick={setResize}
            appearance="outline"
          />
        </div>
      </FormGroup>
      <FormGroup horizontal={true}>
        {isValid && (
          <div>
            <Button content={'Valider'} handleClick={validate} appearance="plain" />
          </div>
        )}
        <div>
          <Button content={'Retour'} handleClick={goStepBack} appearance="outline" />
        </div>
      </FormGroup>
      <Note>
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Précisez la taille de la facçade 1 de votre batiment pour mettre à l'échelle votre dessin au
        fond de carte cadastral
      </Note>
    </Card>
  );
};
