import { BuildingId } from '../../domain/specification/buildings/BuildingSpecification';

export const BUILDING_MOVED = 'core/buildingMoved';

export const buildingMoved = (buildingId: BuildingId, newBuildingIndex: number) => ({
  type: BUILDING_MOVED,
  payload: {
    buildingId,
    newBuildingIndex
  }
});

export type BuildingMovedAction = ReturnType<typeof buildingMoved>;
