import { CaseGranulometry } from '../../cases/CaseGranulometry';
import { LevelGranulometry } from '../../levels/LevelGranulometry';
import { getLevelAccronym } from '../../levels/queries/getLevelAccronym';

export const getSectionId = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry,
  index: number
): string =>
  'C' +
  caseGranulometry.labelIndex +
  getLevelAccronym(caseGranulometry, levelGranulometry) +
  'S' +
  index;
