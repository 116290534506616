import { matchesPath } from '../../utils/matchesPath';
import { ProjectDetailsRouteParams } from './projectDetailsRoute';
import { orCreate } from '../../utils/orCreate';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type ProjectInitRouteParams = { projectId: string };
export const projectInitRoute: RouteWithMakePath<ProjectInitRouteParams> = {
  path: `/project/:projectId`,
  makePath: ({ projectId }) => `/project/${orCreate(projectId)}`,
  matchesPath: matchesPath<ProjectDetailsRouteParams>(/\/project\/([^/]+)/, {
    projectId: 1
  })
};
