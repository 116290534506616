import * as React from 'react';
import { CustomPriceList } from '../../../../../domain/Account';
import { Project } from '../../../../../domain/project/Project';

interface CustomPriceListsSelectProps {
  selectedId: Project['customPriceListId'];
  handleCustomPriceListIdChange: (newCustomPriceListId: Project['customPriceListId']) => void;
  customPriceLists: CustomPriceList[];
}

export const CustomPriceListsSelect = ({
  selectedId,
  handleCustomPriceListIdChange,
  customPriceLists
}: CustomPriceListsSelectProps) => (
  <select
    onChange={(value) => {
      handleCustomPriceListIdChange(
        value.target.selectedOptions[0].value as Project['customPriceListId']
      );
    }}
    value={selectedId}>
    <option value={'none'}>Aucune</option>
    {customPriceLists.map((customPriceList, index) => (
      <option key={index} value={customPriceList.id}>
        {customPriceList.title}
      </option>
    ))}
    <option disabled={true}>Gérer les listes de prix (à venir)</option>
  </select>
);
