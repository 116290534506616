import { ExistingProject } from '../../domain/project/Project';
import { post } from './admin.api';
import { APIProject } from './fetchProjects.api';
import { BuildingGranulometry } from '../../domain/granulometry/buildings/BuildingGranulometry';
import { buildingGranulometryToBackendGranulometry } from './adapters/buildingGranulometryToBackendGranulometry.adapter';
import { User } from '../../domain/User';
import { AuthToken } from '../../AuthToken';

export const sendGranulometry = async (
  project: ExistingProject,
  buildingGranulometry: BuildingGranulometry,
  index: number,
  userId?: User['id'],
  userToken?: AuthToken
): Promise<void> => {
  const backendGranulometry = buildingGranulometryToBackendGranulometry(buildingGranulometry);
  if (process.env.GB_ENV === 'dev' || process.env.GB_ENV === 'recette') {
    // eslint-disable-next-line no-console
    console.info('DATA SENDED TO ADMIN', backendGranulometry);
  }

  const postGranuloResponse = await post(
    `/ProjectObjects/import_granulo/JSON/${index}/${project.id}/${buildingGranulometry.id}`,
    userToken || 'noUserToken',
    [backendGranulometry]
  );

  const [apiProject] = await postGranuloResponse.json();
  if (!/^[\d\w]+$/.test((apiProject as APIProject).id)) {
    throw new Error('Error when sending granulo');
  }
};
