import { isRoomSpecificationAnyLivingRoomKitchen } from '../../LivingRoomKitchenSpecification';
import { LodgmentTypeSpecification } from '../../../lodgmentTypes/LodgmentTypeSpecification';
import { isRoomSpecificationABalcony } from '../../BalconySpecification';
import { getLivingRoomKitchenSpecificationCalculatedSurface } from './getLivingRoomKitchenSpecificationCalculatedSurface';
import { getBalconySpecificationSurface } from './getBalconySpecificationSurface';
import { getBasicRoomSpecificationSurface } from './getBasicRoomSpecificationSurface';
import { RoomSpecification } from '../../RoomSpecification';

type GetRoomSpecificationSurface = (
  lodgmentRooms: RoomSpecification[],
  lodgmentSurface: number,
  balconySurfaceOnRealLivingSurfaceRate: number,
  roomSpecification: RoomSpecification
) => number;
export const getRoomSpecificationSurface: GetRoomSpecificationSurface = (
  lodgmentRooms,
  lodgmentSurface,
  balconySurfaceOnRealLivingSurfaceRate,
  roomSpecification
) =>
  isRoomSpecificationAnyLivingRoomKitchen(roomSpecification)
    ? getLivingRoomKitchenSpecificationCalculatedSurface(lodgmentRooms, lodgmentSurface)
    : isRoomSpecificationABalcony(roomSpecification)
    ? getBalconySpecificationSurface(
        lodgmentSurface,
        balconySurfaceOnRealLivingSurfaceRate,
        roomSpecification
      )
    : getBasicRoomSpecificationSurface(roomSpecification);
