import { CaseProjection } from '../../../../projection/cases/CaseProjection';
import { getCasePropertyConvertedValue } from '../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';

export const getBasementLevelOutsideWallsLinear = (
  parentCaseProjection: CaseProjection,
  carBoxCount: number,
  carParkCount: number,
  currentCaseBasementLevelsCount: number
) => {
  let levelOutsideWallInfraLinear = 0;

  // Ventilation group
  if (currentCaseBasementLevelsCount > 1) {
    const ventilationGroupInfraWidth = getCasePropertyConvertedValue(
      parentCaseProjection,
      'ventilationGroupInfraWidth'
    ) as number;
    const ventilationGroupInfraLength = getCasePropertyConvertedValue(
      parentCaseProjection,
      'ventilationGroupInfraLength'
    ) as number;
    levelOutsideWallInfraLinear += ventilationGroupInfraWidth + ventilationGroupInfraLength;
  }

  // Ramp
  if (parentCaseProjection.builtInRamp) {
    const rampInfraNumberOfSides = 2;
    const rampLength = getCasePropertyConvertedValue(parentCaseProjection, 'rampLength') as number;
    const rampWidth = getCasePropertyConvertedValue(parentCaseProjection, 'rampWidth') as number;
    const rampExposureRate = getCasePropertyConvertedValue(
      parentCaseProjection,
      'rampExposureRate'
    ) as number;
    levelOutsideWallInfraLinear +=
      rampInfraNumberOfSides * (rampLength + rampWidth) * rampExposureRate;
  }

  // Bearing infra
  const bearingInfraNumberOfSides = 2;
  const bearingInfraSurface = getCasePropertyConvertedValue(
    parentCaseProjection,
    'bearingInfraSurface'
  ) as number;
  const bearingInfraWidth = getCasePropertyConvertedValue(
    parentCaseProjection,
    'bearingInfraWidth'
  ) as number;
  const bearingInfraExposureRate = getCasePropertyConvertedValue(
    parentCaseProjection,
    'bearingInfraExposureRate'
  ) as number;
  levelOutsideWallInfraLinear +=
    bearingInfraNumberOfSides *
    (bearingInfraSurface / bearingInfraWidth + bearingInfraWidth) *
    bearingInfraExposureRate;

  // Circulation lane infra
  const circulationLaneInfraAdditionalExposureRateRatio = 6; // TODO : Should we add it in case properties
  const circulationLaneInfraWidth = getCasePropertyConvertedValue(
    parentCaseProjection,
    'circulationLaneWidth'
  ) as number;
  levelOutsideWallInfraLinear +=
    circulationLaneInfraAdditionalExposureRateRatio * circulationLaneInfraWidth;

  // Car box infra
  const carBoxAdditionalExposureRateRatio = 1.2; // TODO : Should we add it in case properties
  const carBoxWidth = getCasePropertyConvertedValue(parentCaseProjection, 'carBoxWidth') as number;
  levelOutsideWallInfraLinear += carBoxCount * carBoxAdditionalExposureRateRatio * carBoxWidth;

  // Car park infra
  const carParkAdditionalExposureRateRatio = 1.2; // TODO : Should we add it in case properties
  const carParkWidth = getCasePropertyConvertedValue(
    parentCaseProjection,
    'carParkWidth'
  ) as number;
  levelOutsideWallInfraLinear += carParkCount * carParkAdditionalExposureRateRatio * carParkWidth;

  return levelOutsideWallInfraLinear;
};
