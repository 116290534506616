import { letterFromIndex } from '../../../utils/letterFromIndex';
import {
  CaseLayer,
  CreateLayerParams,
  createNewLayer,
  createNewLevelLayer,
  Layer,
  LayerId,
  LayerType,
  LayerWithLevelGeometry,
  LevelLayer
} from './layer.model';
import { isLayerWithLevelGeometry, LevelGeometry } from '../mapboxDraw/levelGeometry';
import { ROTATE_LEVEL_MODE } from '../mode/RotateLevelMode';

interface FindParams {
  filter?: { lock?: boolean; type?: LayerType; withGeometry?: boolean };
}

export class LayerRepo {
  layers: Layer[];
  observers: Array<() => void>;

  constructor(layers: Layer[]) {
    this.layers = layers || [];
    this.observers = [];
  }

  addObserver(fn: () => void) {
    this.observers.push(fn);
  }

  notifyAll() {
    this.observers.forEach((fn) => fn());
  }

  removeObserver(fn: () => void) {
    this.observers = this.observers.filter((observer) => observer !== fn);
  }

  find(): Layer[] {
    return this.layers;
  }

  findByType(type: LayerType): Layer[] {
    return this.layers.filter((layer) => layer.type === type);
  }

  findById(id: LayerId): Layer | undefined {
    return this.layers.find((l) => l.id === id);
  }

  findByIds(ids: LayerId[], params?: FindParams): Layer[] {
    let layers = this.layers.filter((l) => ids.includes(l.id));
    if (params) {
      if (params.filter) {
        layers = layers.filter((layer) => {
          let conditions = true;
          if (params.filter?.type) {
            conditions = conditions && layer.type === params.filter.type;
          }
          if (params.filter?.lock) {
            conditions = conditions && layer.draw?.properties?.shape?.lock === params.filter?.lock;
          }
          if (params.filter?.withGeometry) {
            conditions = conditions && !!layer.draw === params.filter?.withGeometry;
          }
          return conditions;
        });
      }
    }
    return layers;
  }

  findLevelWithGeometry(ids?: LayerId[]): LayerWithLevelGeometry[] | any[] {
    let levelWithGeometry: LayerWithLevelGeometry | any[] = this.findByType('level').filter(
      (l) => l.draw
    ) as LayerWithLevelGeometry[];
    if (ids && ids?.length > 0 && levelWithGeometry.length > 0) {
      levelWithGeometry =
        levelWithGeometry.filter((levelWithGeometry) => ids?.includes(levelWithGeometry.id)) ?? [];
    }
    return levelWithGeometry;
  }

  findLevelWithoutGeometry() {
    return this.findByType('level').filter((l) => !l.draw?.geometry) as LevelLayer[];
  }

  private findChildren(parentId: LayerId): Layer[] {
    return this.layers.filter((layer) => layer.parent === parentId);
  }

  findBrothers(parentId: LayerId) {
    return this.layers.filter((b) => b.parent === parentId) || [];
  }

  private createLevelWithDraw(draw: LevelGeometry, isSelected = true) {
    const levelLayerWithGeometry = this.formatLevelGeometryToLayer(
      draw,
      isSelected
    ) as LayerWithLevelGeometry;

    if (draw.properties.floor === 0) {
      const levelToRenew = this.findLevelWithoutGeometry()?.find(
        (level) => level.parent === levelLayerWithGeometry.parent && level.properties.floor === 0
      ); // level without geometry and first level, to avoid issue with case without floor 0

      if (levelToRenew) {
        return this.update(levelToRenew.id, levelLayerWithGeometry);
      }
    }
    this.layers.push(levelLayerWithGeometry);
    return levelLayerWithGeometry;
  }

  private createLevelWithoutDraw(parentId: LayerId, isSelected: boolean) {
    const parent = this.findById(parentId);
    if (parent) {
      const newLayer = createNewLevelLayer({
        parent,
        isSelected,
        title: this.generateTitle({ type: 'level', parent, floor: 0 })
      });
      return this.layers.push(newLayer);
    }
  }

  private createCase(parent: Layer, isSelected = true): Layer {
    const newLayer: Layer = createNewLayer('case', isSelected);
    newLayer.parent = parent.id;
    newLayer.title = this.generateTitle({ type: 'case', parent });

    this.layers.push(newLayer);
    this.createLevelWithoutDraw(newLayer.id, isSelected);
    return newLayer;
  }

  private createBuilding(isSelected = true): Layer {
    const newLayer: Layer = createNewLayer('building', isSelected);
    newLayer.title = this.generateTitle({ type: 'building' });

    this.layers.push(newLayer);
    return newLayer;
  }

  create(params: CreateLayerParams, isSelected = true): Layer {
    if (params.type === 'level') {
      if (params.draw) {
        return this.createLevelWithDraw(params.draw, isSelected) as Layer;
      } else if (params.parentId) {
        return this.createLevelWithoutDraw(params.parentId, isSelected) as unknown as Layer;
      }
    }

    if (params.type === 'case') {
      return this.createCase(params.parentLayer, isSelected);
    }
    if (params.type === 'building') {
      return this.createBuilding(isSelected);
    }
  }

  generateTitle(params: { type: LayerType; parent?: Layer; floor?: number }) {
    if (params.type === 'building') {
      const brothersCount = this.findByType('building')?.length;
      return 'Bâtiment ' + (brothersCount + 1).toString();
    }
    if (params.type === 'case' && params.parent) {
      const brothersCount = this.findBrothers(params.parent.id).length;
      return 'C' + params.parent.title + letterFromIndex(brothersCount);
    }
    if (params.type === 'level' && params.parent && params.floor) {
      return params.parent.title + 'L' + params.floor;
    }

    return 'Calque';
  }

  delete(id: LayerId): Layer {
    const toDelete = this.layers.find((layer) => layer.id === id) as Layer;
    if (toDelete.type === 'level' && (toDelete as LevelLayer).properties.floor === 0) {
      if (toDelete.draw?.geometry) {
        delete toDelete.draw;
        return this.update(toDelete.id, toDelete);
      }
    }
    if (!toDelete) throw new Error("L'id n'existe pas");
    this.layers = this.layers.filter((layer) => layer.id !== id);
    return toDelete;
  }

  deleteInCascade(parentId: LayerId) {
    const childrenBrothers = this.findBrothers(parentId);
    childrenBrothers.forEach((child) => {
      this.delete(child.id);
    });
    this.delete(parentId);
  }

  update(id: LayerId, newLayer: Partial<Layer>): Layer {
    const index = this.layers.findIndex((l) => l.id === id);
    if (index !== -1) {
      this.layers[index] = { ...this.layers[index], ...newLayer };
    }
    return this.layers[index];
  }

  private select(id: LayerId) {
    const layer = this.findById(id);
    if (layer?.draw?.properties?.shape?.lock) return null;
    this.update(id, { selected: true });
  }

  selectMultiple(ids: LayerId[]) {
    ids.forEach((id) => this.select(id));
  }

  selectTree(id: LayerId): LayerId[] {
    let ids = [] as LayerId[];
    const layer = this.findById(id);
    if (!layer) return [];
    if (layer.type === 'level') {
      ids = this.getLevelLayerIdsTree(layer);
    }
    if (layer.type === 'case') {
      ids = this.getCaseLayerIdsTree(layer);
    }
    if (layer.type === 'building') {
      ids = this.getBuildingLayerIdsTree(layer);
    }
    this.selectMultiple(ids);
    return ids;
  }

  selectWithParent(id: LayerId): LayerId[] {
    let ids: LayerId[] = [id];
    const layer = this.findById(id);
    if (layer?.parent) {
      ids = [...ids, layer.parent];
    }
    this.selectMultiple(ids);
    return ids;
  }

  private getBuildingLayerIdsTree(buildingLayer: Layer): LayerId[] {
    let ids = [buildingLayer.id];
    const caseChildren = this.findChildren(buildingLayer.id);
    if (caseChildren && caseChildren.length > 0) {
      ids = [
        ...ids,
        ...caseChildren.reduce(
          (p: LayerId[], caseChild) => [...p, ...this.getCaseLayerIdsTree(caseChild)],
          []
        )
      ] as LayerId[];
    }
    return ids;
  }

  private getCaseLayerIdsTree(caseLayer: Layer): LayerId[] {
    let ids = [caseLayer.id];
    const children = this.findChildren(caseLayer.id);
    if (caseLayer.parent) ids = [...ids, caseLayer.parent];
    if (children) ids = [...ids, ...children.map((c) => c.id)].filter(Boolean);
    return ids;
  }

  private getLevelLayerIdsTree(levelLayer: Layer): string[] {
    let ids = [levelLayer.id];
    if (levelLayer.parent) {
      ids = [levelLayer.id, levelLayer.parent];
      const caseParent = this.findById(levelLayer.parent);
      if (caseParent && caseParent.parent) {
        ids = [...ids, caseParent.parent];
      }
    }
    return ids;
  }

  getSelectedByType(type: LayerType) {
    return this.findByType(type).filter((c) => c.selected);
  }

  getSelectedLayerWithLevelGeometry() {
    return (
      (this.getSelectedByType('level')?.filter((level) =>
        isLayerWithLevelGeometry(level)
      ) as LayerWithLevelGeometry[]) || []
    );
  }

  private unselect(id: LayerId) {
    this.update(id, { selected: false });
  }

  unselectAll() {
    this.layers.forEach((layer) => this.unselect(layer.id));
  }

  lock(level: LevelGeometry): LevelGeometry {
    const layer = this.findById(level.id) as LayerWithLevelGeometry;
    const newLayer: LayerWithLevelGeometry = {
      ...layer,
      draw: {
        ...layer.draw,
        properties: {
          ...layer.properties,
          shape: {
            lock: true
          }
        }
      }
    };
    this.update(level.id, newLayer);
    return newLayer.draw;
  }

  getBasementLevels(caseId?: LayerId) {
    let currentCase;
    if (caseId) {
      currentCase = [this.findById(caseId)];
    } else {
      currentCase = this.getSelectedByType('case');
    }
    if (currentCase.length > 0) {
      const levels = this.findBrothers(currentCase[0].id) as LevelLayer[];
      return levels.filter((level) => level.properties.floor < 0);
    }
  }

  getModeNameByCurrentDrawMode(currentDrawMode?: string): string {
    return currentDrawMode === 'direct_select'
      ? 'Mode: Edition'
      : currentDrawMode === 'draw_polygon'
      ? 'Mode: Dessin'
      : currentDrawMode === ROTATE_LEVEL_MODE
      ? 'Mode: Rotation'
      : this.getSelectedLayerWithLevelGeometry()?.length > 0
      ? 'Mode: Déplacement'
      : 'Mode: Sélection';
  }

  getMiddleCardActionStatus(currentDrawMode?: string) {
    return {
      remove: {
        disabled: this.getSelectedLayerWithLevelGeometry()?.length === 0
      },
      drawTopLevel: {
        disabled:
          (currentDrawMode !== 'static' && currentDrawMode !== 'simple_select') ||
          this.getSelectedByType('case')?.length !== 1
      },
      drawBasementLevel: {
        disabled:
          (currentDrawMode !== 'static' && currentDrawMode !== 'simple_select') ||
          this.getBasementLevels()?.length >= 2 ||
          this.getSelectedByType('case')?.length !== 1
      },
      rotate: {
        disabled:
          this.getSelectedLayerWithLevelGeometry()?.length === 0 ||
          currentDrawMode === ROTATE_LEVEL_MODE
      }
    };
  }

  getProjectTreeCardActionStatus() {
    return {
      duplicate: {
        disabled: (caseId: LayerId, floor: number) =>
          this.getBasementLevels(caseId)?.length >= 2 && floor < 0
      }
    };
  }

  isSelected(id: LayerId) {
    return !!this.findById(id)?.selected;
  }

  formatLevelGeometryToLayer(level: LevelGeometry, isSelected = true): LevelLayer {
    return {
      draw: level,
      type: 'level',
      parent: level.properties.parentCaseId,
      id: level.id,
      title: level.properties.name,
      selected: isSelected,
      properties: {
        floor: level.properties.floor,
        buildingId: level.properties.parentCaseId
      }
    };
  }

  needRedirecting(layer: Layer) {
    if (layer.type === 'case' || layer.type === 'level') {
      if ((layer as CaseLayer | LevelLayer).isCurrentIdInRoute) {
        return true;
      }
    }
  }
}
