import { createSelector, Selector } from 'reselect';
import { State } from '../../../store/reducers';
import { selectProjectProjection } from '../project/projectProjection.selector';
import { projectGfsEffIsValid } from '../../../domain/checks/project/projectGfsEffIsValid';
import { getProjectGfsEffFromLevelGeometries } from '../../../domain/specification/project/queries/getProjectGfsEffFromLevelGeometries';

export const selectProjectGfsEffIsValid: Selector<State, boolean> = createSelector(
  [selectProjectProjection],
  (project) =>
    project !== undefined && projectGfsEffIsValid(getProjectGfsEffFromLevelGeometries(project))
);
