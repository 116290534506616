import { getHeight } from './getHeight';

export const getSectionGroupHeight = (group: string) => {
  let height = 0;
  const elementsInGroup: NodeListOf<HTMLElement> = document.querySelectorAll(
    '.buildingMap .buildings [data-group="' + group + '"]'
  );
  for (let i = 0; i < elementsInGroup.length; i++) {
    height += getHeight(elementsInGroup[i], true);
  }
  return height;
};
