import { t1 } from '../../../../granulometry/sections/lodgmentSections/t1';
import { t2c } from '../../../../granulometry/sections/lodgmentSections/t2c';
import { t2 } from '../../../../granulometry/sections/lodgmentSections/t2';
import { t3c } from '../../../../granulometry/sections/lodgmentSections/t3c';
import { t3 } from '../../../../granulometry/sections/lodgmentSections/t3';
import { t4c } from '../../../../granulometry/sections/lodgmentSections/t4c';
import { t4 } from '../../../../granulometry/sections/lodgmentSections/t4';
import { t5 } from '../../../../granulometry/sections/lodgmentSections/t5';
import { LodgmentType } from '../../../../specification/lodgmentTypes/LodgmentType';
import { LodgmentSection } from '../../../../granulometry/sections/lodgmentSections/LodgmentSection';
import { LodgmentTypeSpecification } from '../../../../specification/lodgmentTypes/LodgmentTypeSpecification';
import { CaseProjection } from '../../CaseProjection';

export type LodgmentTypeData = {
  [key in LodgmentType]: LodgmentSection & LodgmentTypeSpecification;
};

export const getCaseLodgmentData = (caseProjection: CaseProjection): LodgmentTypeData => {
  if (caseProjection.projectedDistribution[0].displayedSurface !== undefined) {
    return caseProjection.projectedDistribution.reduce(
      (acc, distributedLodgmentSection) => ({
        ...acc,
        [distributedLodgmentSection.lodgmentType]: {
          ...distributedLodgmentSection,
          name: distributedLodgmentSection.lodgmentType
        }
      }),
      {} as LodgmentTypeData
    );
  } else {
    return {
      t1: t1(),
      t2c: t2c(),
      t2: t2(),
      t3c: t3c(),
      t3: t3(),
      t4c: t4c(),
      t4: t4(),
      t5: t5()
    };
  }
};
