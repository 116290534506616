import * as R from 'ramda';
import { LevelGranulometry } from '../../../../LevelGranulometry';
import { isSectionALodgment } from '../../../../../sections/lodgmentSections/LodgmentSection';
import { Surface } from '../../../../../../specification/Surface';
import { getLevelFullFilledContent } from '../../../content/getLevelFullFilledContent';

// TODO : To replace
export const getTopLevelLodgmentsSurfaceForSale = (levelGranulometry: LevelGranulometry): Surface =>
  levelGranulometry.content
    ? new Surface(
        R.sum(
          getLevelFullFilledContent(levelGranulometry)
            .filter(isSectionALodgment)
            .map((lodgmentSection) => lodgmentSection.displayedSurface)
        )
      )
    : Surface.EMPTY;
