import { createSelector, Selector } from 'reselect';
import { selectCurrentCaseProjection } from '../../projection/currentCase/projection.selector';
import { getCaseBasementLevels } from '../../../../domain/specification/cases/queries/get/levels/getCaseBasementLevels';
import { BasementLevelSpecification } from '../../../../domain/specification/levels/BasementLevelSpecification';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';
import { ifDefined } from '../../../../utils/ifDefined';

export const selectCurrentCaseBasementLevelsSpecifications: Selector<
  State,
  Maybe<BasementLevelSpecification[]>
> = createSelector([selectCurrentCaseProjection], ifDefined(getCaseBasementLevels));
