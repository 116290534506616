import i18next from 'i18next';
import { OTHER_SFS_SECTIONS_COLOR } from '../../../../constants/appConstants';
import { Section, SectionDrawn } from '../Section';
import { CommonPremiseSectionName } from './CommonPremiseSection';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { SectionWithTechnicalSheaths } from '../SectionWithTechnicalSheaths';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';
import { SectionWithGlazedSurface } from '../SectionWithGlazedSurface';

interface RestaurantSectionProps {
  surface?: number;
  exposureRate?: number;
  width?: number;
}

export interface RestaurantSection
  extends Section,
    SectionDrawn,
    SectionWithExposureRate,
    SectionWithTechnicalSheaths,
    SectionWithGlazedSurface {
  name: CommonPremiseSectionName.RestaurantSection;
  width?: number;
  defaultWidth: number;
  minWidth: number;
  maxWidth: number;
  defaultSurface: number;
  minSurface: number;
  maxSurface: number;
}

export const RESTAURANT_SECTION_DEFAULT_SURFACE = 100;
export const RESTAURANT_SECTION_MIN_SURFACE = 80;
export const RESTAURANT_SECTION_MAX_SURFACE = 200;
export const RESTAURANT_SECTION_DEFAULT_EXPOSURE_RATE = 1.5;
export const RESTAURANT_SECTION_DEFAULT_WITH = 7;
export const RESTAURANT_SECTION_MIN_WITH = 6;
export const RESTAURANT_SECTION_MAX_WITH = 10;
export const RESTAURANT_SECTION_ICON = {
  type: 'object',
  iconName: 'restaurant',
  color: OTHER_SFS_SECTIONS_COLOR
} as IconObjectProps;

const restaurantSection = ({
  surface,
  exposureRate,
  width
}: RestaurantSectionProps): RestaurantSection => ({
  // Section
  id: 'NOID',
  name: CommonPremiseSectionName.RestaurantSection,
  title: i18next.t('restaurantSection.title'),
  type: 'others',
  displayedSurface: surface || RESTAURANT_SECTION_DEFAULT_SURFACE,
  // SectionDrawn
  surface: surface || RESTAURANT_SECTION_DEFAULT_SURFACE,
  color: OTHER_SFS_SECTIONS_COLOR,
  icon: RESTAURANT_SECTION_ICON,
  // SectionWithExposureRate
  exposureRate: exposureRate || undefined,
  defaultExposureRate: RESTAURANT_SECTION_DEFAULT_EXPOSURE_RATE,
  // SectionWithTechnicalSheaths
  technicalSheathCount: 0,
  // SectionWithGlazedSurface
  glazedLinear: undefined,
  defaultGlazedRate: 0.5,
  // RestaurantSection
  width: width || undefined,
  defaultWidth: RESTAURANT_SECTION_DEFAULT_WITH,
  minWidth: RESTAURANT_SECTION_MIN_WITH,
  maxWidth: RESTAURANT_SECTION_MAX_WITH,
  defaultSurface: RESTAURANT_SECTION_DEFAULT_SURFACE,
  minSurface: RESTAURANT_SECTION_MIN_SURFACE,
  maxSurface: RESTAURANT_SECTION_MAX_SURFACE
});

export default restaurantSection;
