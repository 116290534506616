import {
  LevelGranulometry,
  LevelGranulometryFullFilled,
  LevelGranulometryInitialEntries,
  LevelGranulometryWithBearingRelativeSections,
  LevelGranulometryWithLodgments
} from '../../LevelGranulometry';
import { TechnicalPremiseSection } from '../../../sections/technicalPremiseSections/TechnicalPremiseSection';

export const getLevelTechnicalPermiseSections = (
  levelGranulometry:
    | LevelGranulometryInitialEntries
    | LevelGranulometryWithLodgments
    | LevelGranulometryWithBearingRelativeSections
    | LevelGranulometryFullFilled
    | LevelGranulometry
): TechnicalPremiseSection[] =>
  levelGranulometry.technicalPremiseSections?.map((technicalPremiseSection) => ({
    ...technicalPremiseSection,
    surface: technicalPremiseSection.displayedSurface || technicalPremiseSection.defaultSurface,
    displayedSurface:
      technicalPremiseSection.displayedSurface || technicalPremiseSection.defaultSurface,
    width: technicalPremiseSection.width || technicalPremiseSection.defaultWidth,
    exposureRate:
      technicalPremiseSection.exposureRate || technicalPremiseSection.defaultExposureRate
  })) || [];
