import {
  LevelGranulometryFullFilled,
  LevelGranulometryWithLodgments
} from '../../../../LevelGranulometry';
import { mustHaveARampSection } from '../../../mustHave/mustHaveARampSection';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsLinear } from '../includedInSurfaceForSaleSectionWalls/getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsLinear';
import { mustHaveAHallSection } from '../../../mustHave/mustHaveAHallSection';
import { getHallSectionTheoreticalWallsLinear } from '../../../../../sections/queries/walls/theoreticalWalls/hallSection/getHallSectionTheoreticalWallsLinear';
import { getHallSectionTheoreticalExposureRate } from '../../../../../sections/queries/exposureRate/getHallSectionTheoreticalExposureRate';
import { getRampSectionTheoreticalWallsLinear } from '../../../../../sections/queries/walls/theoreticalWalls/rampSection/getRampSectionTheoreticalWallsLinear';
import { getRampSectionExposureRate } from '../../../../../sections/queries/exposureRate/getRampSectionExposureRate';
import { getTopLevelTheoreticalOutsideWallsLinear } from './getTopLevelTheoreticalOutsideWallsLinear';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { getLevelTechnicalPermiseSections } from '../../../sections/getLevelTechnicalPermiseSections';
import { getSectionTheoreticalOutsideWallsLinear } from '../../../../../sections/queries/walls/theoreticalWalls/outsideWalls/getSectionTheoreticalOutsideWallsLinear';

export const getTopLevelTheoreticalOutsideWallsLinearDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled
): string => {
  let details: string[] = [];

  // Ramp section
  if (mustHaveARampSection(caseGranulometry, levelGranulometry)) {
    details = [
      ...details,
      roundWith2Decimal(
        getRampSectionTheoreticalWallsLinear(caseGranulometry) *
          getRampSectionExposureRate(caseGranulometry)
      ) + ' mL</b> de linéaire exposé de la <i>Rampe</i>'
    ];
  }

  // Included in surface for sale sections
  details = [
    ...details,
    roundWith2Decimal(
      getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsLinear(
        caseGranulometry,
        levelGranulometry as LevelGranulometryWithLodgments
      )
    ) + ' mL</b> de linéaire exposé de <i>Sections utiles</i>'
  ];

  // Technical premise sections
  details = getLevelTechnicalPermiseSections(levelGranulometry).reduce(
    (acc, technicalPermiseSection) => [
      ...acc,
      roundWith2Decimal(
        getSectionTheoreticalOutsideWallsLinear(
          caseGranulometry,
          levelGranulometry,
          technicalPermiseSection
        )
      ) +
        ' mL</b> de linéaire exposé du <i>' +
        technicalPermiseSection.title +
        '</i>'
    ],
    details
  );

  // Hall section
  if (mustHaveAHallSection(caseGranulometry, levelGranulometry)) {
    details = [
      ...details,
      roundWith2Decimal(
        getHallSectionTheoreticalWallsLinear(caseGranulometry) *
          getHallSectionTheoreticalExposureRate(caseGranulometry)
      ) + ' mL</b> de linéaire exposé du <i>Hall</i>'
    ];
  }

  return (
    '<b>' +
    details.join('<br /><b>+ ') +
    '<br /><b>= ' +
    roundWith2Decimal(
      getTopLevelTheoreticalOutsideWallsLinear(caseGranulometry, levelGranulometry)
    ) +
    ' mL</b>'
  );
};
