import { getTopLevelOutsideInsulatedWallsThickness } from './getTopLevelOutsideInsulatedWallsThickness';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelInternalInsulationThickness } from './getTopLevelInternalInsulationThickness';
import { getTopLevelExternalInsulationThickness } from './getTopLevelExternalnalInsulationThickness';
import { getTopLevelOutsideStructuralWallsThickness } from './getTopLevelOutsideStructuralWallsThickness';

export const getTopLevelOutsideInsulatedWallsThicknessDetails = (
  caseGranulometry: CaseGranulometry
): string =>
  '<b>' +
  getTopLevelInternalInsulationThickness(caseGranulometry) * 100 +
  ' cm</b> d’épaisseur d’isolation int. (ITI)<br /><b>+ ' +
  getTopLevelOutsideStructuralWallsThickness(caseGranulometry) * 100 +
  ' cm</b> d’épaisseur de voiles (structurels) exposés<br /><b>+ ' +
  getTopLevelExternalInsulationThickness(caseGranulometry) * 100 +
  ' cm</b> d’épaisseur d’isolation ext. (ITE)<br /><b>= ' +
  roundWith2Decimal(getTopLevelOutsideInsulatedWallsThickness(caseGranulometry) * 100) +
  ' cm</b>';
