import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { FormGroup } from '../../../../ui/FormGroup';
import { Input } from '../../../../ui/input/Input';
import { IconObject } from '../../../../ui/Icons/iconObject';
import {
  DEFAULT_CAR_PARK_COUNT_PER_SHAB,
  DEFAULT_SHAB_PER_CAR_PARK,
  MAXIMUM_CAR_PARK_COUNT_PER_SHAB,
  MINIMUM_SHAB_PER_CAR_PARK,
  OTHERS_COLOR
} from '../../../../../constants/appConstants';
import { selectCurrentCaseIdFromRoute } from '../../../../../store/selectors/navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { selectCarParksCount } from '../../../../../store/selectors/specification/parkings/carParksCount.selector';
import { selectCarParkCountPerShab } from '../../../../../store/selectors/specification/parkings/carParkCountPerShab.selector';
import { selectShabPerCarPark } from '../../../../../store/selectors/specification/parkings/shabPerCarPark.selector';
import { caseParkingSpecificationChanged } from '../../../../../store/actions/setCaseParkingsSpecification.action';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { secureInputChange } from '../../../../../utils/secureInputChange';

export const CarParkFormGroup = () => {
  const dispatch = useDispatch();
  const caseId = useSelector(selectCurrentCaseIdFromRoute);

  // Car park count per shab
  const carParkCountPerShab = useSelector(selectCarParkCountPerShab);
  const [localCarParkCountPerShab, setLocalCarParkCountPerShab] =
    React.useState(carParkCountPerShab);

  const getCarParkCountPerShabValidity = (carParkCountPerShab: number | undefined) =>
    carParkCountPerShab === undefined ||
    (typeof carParkCountPerShab === 'number' && carParkCountPerShab >= 0);

  const dispatchCarParkCountPerShabChanged = React.useCallback(
    (carParkCountPerShab: number | undefined) => {
      setLocalCarParkCountPerShab(carParkCountPerShab);
      if (caseId && getCarParkCountPerShabValidity(carParkCountPerShab)) {
        dispatch(
          caseParkingSpecificationChanged(caseId, {
            carParkCountPerShab
          })
        );
      }
    },
    [dispatch, caseId]
  );

  // Shab per car park
  const shabPerCarPark = useSelector(selectShabPerCarPark);
  const [localShabPerCarPark, setLocalShabPerCarPark] = React.useState(shabPerCarPark);
  const getShabPerCarParkValidity = (shabPerCarPark: number | undefined) =>
    shabPerCarPark === undefined || (typeof shabPerCarPark === 'number' && shabPerCarPark >= 10);
  const dispatchShabPerCarParkChanged = React.useCallback(
    (shabPerCarPark: number | undefined) => {
      setLocalShabPerCarPark(shabPerCarPark);
      if (caseId && getShabPerCarParkValidity(shabPerCarPark)) {
        dispatch(caseParkingSpecificationChanged(caseId, { shabPerCarPark }));
      }
    },
    [dispatch, caseId]
  );

  // Car park count
  const carParksCount = useSelector(selectCarParksCount);

  const { t } = useTranslation();

  return (
    <div className="car-park-distribution" style={{ marginTop: '8px' }}>
      <SpecialLabel label={t('Visitors parking spots')} small={true} />
      <FormGroup horizontal={true}>
        <div style={{ height: '32px', width: '65px', marginRight: '5px' }}>
          <Input
            name="carParkCountPerShab"
            type="number"
            color={
              getCarParkCountPerShabValidity(
                localCarParkCountPerShab === undefined
                  ? DEFAULT_CAR_PARK_COUNT_PER_SHAB
                  : localCarParkCountPerShab
              )
                ? undefined
                : 'orange'
            }
            step={1}
            value={
              localCarParkCountPerShab ? roundWith2Decimal(localCarParkCountPerShab) : undefined
            }
            style={{ margin: '0' }}
            handleChange={(value) =>
              dispatchCarParkCountPerShabChanged(
                value === null
                  ? undefined
                  : secureInputChange(value, undefined, MAXIMUM_CAR_PARK_COUNT_PER_SHAB)
              )
            }
            placeholder={DEFAULT_CAR_PARK_COUNT_PER_SHAB}
          />
        </div>
        <div style={{ height: '32px', width: '32px', marginRight: '5px' }}>
          <IconObject
            color={OTHERS_COLOR}
            iconName="carPark"
            title="Car park"
            type="object"
            clickable={false}
          />
        </div>
        <div
          style={{
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            marginRight: '5px'
          }}>
          {t('for')}
        </div>
        <div
          style={{
            height: '32px',
            marginRight: '5px'
          }}>
          <Input
            name="shabPerCarPark"
            type="number"
            color={
              getShabPerCarParkValidity(
                localShabPerCarPark === undefined ? DEFAULT_SHAB_PER_CAR_PARK : localShabPerCarPark
              )
                ? undefined
                : 'orange'
            }
            value={localShabPerCarPark}
            suffix={'m&#178;' + t(' of ') + t('SFS')}
            style={{ margin: '0' }}
            handleChange={(value) =>
              dispatchShabPerCarParkChanged(
                value === null ? undefined : secureInputChange(value, MINIMUM_SHAB_PER_CAR_PARK)
              )
            }
            placeholder={DEFAULT_SHAB_PER_CAR_PARK}
          />
        </div>
        <div
          style={{
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '12px',
            marginRight: '5px'
          }}>
          =
        </div>
        <div
          style={{
            height: '32px',
            width: '45px'
          }}>
          <Input
            name="totalCarParks"
            type="number"
            disabled
            step={1}
            value={carParksCount}
            style={{ margin: '0' }}
            inputStyle={{ textAlign: 'center' }}
          />
        </div>
      </FormGroup>
    </div>
  );
};
