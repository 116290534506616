import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { FormGroup } from '../../../../ui/FormGroup';
import { selectCurrentlyEditedLevelSpecification } from '../../../../../store/selectors/navigation/toolbox/casesPanel/currentlyEditedLevelSpecification.selector';
import { Card } from '../../../../ui/Card';
import { Note } from '../../../../ui/Note';
import * as R from 'ramda';
import { setCaseLevelSpecification } from '../../../../../store/actions/setCaseLevelSpecification.action';
import { selectCurrentCaseProjection } from '../../../../../store/selectors/projection/currentCase/projection.selector';
import { getCasePropertyConvertedValue } from '../../../../../domain/specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { Input } from '../../../../ui/input/Input';
import { getLevelDefaultCeilingHeight } from '../../../../../domain/specification/levels/queries/get/getLevelDefaultCeilingHeight';
import { isFirstBasementLevel } from '../../../../../domain/specification/levels/queries/is/isFirstBasementLevel';
import { isBasementLevel } from '../../../../../domain/specification/levels/queries/is/isBasementLevel';

export const CeilingHeight = () => {
  const currentlyEditedLevel = useSelector(selectCurrentlyEditedLevelSpecification);
  const currentlyEditedCase = useSelector(selectCurrentCaseProjection);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleCeilingHeightChange = React.useCallback(
    (newCeilingHeight: number | null) => {
      if (currentlyEditedCase && currentlyEditedLevel) {
        dispatch(
          setCaseLevelSpecification(currentlyEditedCase.id, {
            ...currentlyEditedLevel,
            ceilingHeight: newCeilingHeight ?? undefined
          })
        );
      }
    },
    [dispatch, currentlyEditedCase, currentlyEditedLevel]
  );

  if (!currentlyEditedLevel || !currentlyEditedCase) return null;

  let maxBeamHeight;
  let maxBeamParkingName;
  const currentCeilingHeight =
    currentlyEditedLevel.ceilingHeight ||
    getLevelDefaultCeilingHeight(currentlyEditedCase, currentlyEditedLevel);
  if (isFirstBasementLevel(currentlyEditedLevel)) {
    const carBoxBeamHeight = getCasePropertyConvertedValue(
      currentlyEditedCase,
      'carBoxBeamHeight'
    ) as number;
    const carParkBeamHeight = getCasePropertyConvertedValue(
      currentlyEditedCase,
      'carParkBeamHeight'
    ) as number;
    maxBeamHeight = Math.max(carBoxBeamHeight, carParkBeamHeight);
    maxBeamParkingName = carBoxBeamHeight > carParkBeamHeight ? t('Car box') : t('Car park');
  }

  return (
    <Card>
      <SpecialLabel label={t('Ceiling height')} />
      <FormGroup horizontal={true} wrap={true}>
        <Input
          name="ceilingHeight"
          label={t('Ceiling height')}
          info={
            'Si aucune valeur n’est saisie ici, c’est la valeur définie pour la <i> hauteur finie sous plafond d’un niveau en ' +
            (isBasementLevel(currentlyEditedLevel) ? ' infrastructure' : ' superstructure') +
            '</i> (= ' +
            getCasePropertyConvertedValue(
              currentlyEditedCase,
              isBasementLevel(currentlyEditedLevel)
                ? 'basementLevelCeilingHeight'
                : 'topLevelCeilingHeight'
            ) +
            'm) modifiable dans les propriétés de la cage, qui s’applique.'
          }
          style={{ width: '50%', flex: '0 0 50%' }}
          type="number"
          min={1}
          max={20}
          minMax={{ min: 1, max: 20, unit: 'm' }}
          value={currentlyEditedLevel.ceilingHeight}
          handleChange={handleCeilingHeightChange}
          suffix="m"
          placeholder={getLevelDefaultCeilingHeight(currentlyEditedCase, currentlyEditedLevel)}
        />
      </FormGroup>
      {isFirstBasementLevel(currentlyEditedLevel) && currentCeilingHeight && maxBeamHeight && (
        <Note>
          La hauteur sous plafond du premier niveau de sous-sol est automatiquement supplémentée de
          la hauteur de poutre maximale (entre la hauteur de poutre d&apos;une{' '}
          <i>place de stationnement</i> et celle d&apos;un <i>box de stationnement</i>). Ces valeurs
          sont modifiables dans les propriétés de la cage. <br />
          Soit avec les réglages actuels :<br />
          hauteur sous plafond + hauteur de poutre d&apos;une{' '}
          {maxBeamParkingName && R.toLower(maxBeamParkingName)} = {currentCeilingHeight}m +{' '}
          {maxBeamHeight}m = <b>{currentCeilingHeight + maxBeamHeight}m</b>
        </Note>
      )}
    </Card>
  );
};
