import * as React from 'react';
import { ScrollSyncPane } from 'react-scroll-sync';
import { Column1 } from './HeaderLine/Column1';
import { Column2 } from './HeaderLine/Column2';
import { Column3 } from './HeaderLine/Column3';
import { Column4 } from './HeaderLine/Column4';
import { TopLevelSurfaceName } from '../../../domain/granulometry/cases/queries/surfaces/getCaseTopLevelsSurfaces';

interface HeaderLineProps {
  isToolboxEnlarged: boolean;
  setIsToolboxEnlarged: React.Dispatch<React.SetStateAction<boolean>>;
  selectedLevelSurface: TopLevelSurfaceName;
  setSelectedLevelSurface: React.Dispatch<React.SetStateAction<TopLevelSurfaceName>>;
}

const HeaderLine = ({
  isToolboxEnlarged,
  setIsToolboxEnlarged,
  selectedLevelSurface,
  setSelectedLevelSurface
}: HeaderLineProps) => (
  <div className="line header-line">
    <Column1 isToolboxEnlarged={isToolboxEnlarged} setIsToolboxEnlarged={setIsToolboxEnlarged} />
    <ScrollSyncPane>
      <Column2 />
    </ScrollSyncPane>
    <Column3 />
    <Column4
      isToolboxEnlarged={isToolboxEnlarged}
      selectedLevelSurface={selectedLevelSurface}
      setSelectedLevelSurface={setSelectedLevelSurface}
    />
  </div>
);

export default HeaderLine;
