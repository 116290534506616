import { createSelector, Selector } from 'reselect';
import { State } from '../../../../store/reducers';
import { Maybe } from '../../../../utils/Maybe';
import { ifDefined } from '../../../../utils/ifDefined';
import { selectParkingsSpecifications } from './parkingsSpecifications.selector';

export const selectLodgmentCountPerCarBox: Selector<State, Maybe<number>> = createSelector(
  [selectParkingsSpecifications],
  ifDefined(({ lodgmentCountPerCarBox }) => lodgmentCountPerCarBox)
);
