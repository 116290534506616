import { Section } from './Section';
import {
  CommonPremiseSection,
  isSectionACommonPremise
} from './commonPremiseSections/CommonPremiseSection';
import { isSectionALodgment, LodgmentSection } from './lodgmentSections/LodgmentSection';
import { isSectionAShop, ShopSection } from './shopSection/shopSection';
import { isSectionAnOffice, OfficeSection } from './officeSection/officeSection';

export type IncludedInSurfaceForSaleSection =
  | LodgmentSection
  | CommonPremiseSection
  | ShopSection
  | OfficeSection;

export const isSectionIncludedInSurfaceForSale = (
  section: Section
): section is IncludedInSurfaceForSaleSection =>
  isSectionALodgment(section) ||
  isSectionACommonPremise(section) ||
  isSectionAShop(section) ||
  isSectionAnOffice(section);

export const filterIncludedInSurfaceForSaleSections = (
  sections: Section[]
): IncludedInSurfaceForSaleSection[] => sections.filter(isSectionIncludedInSurfaceForSale);
