import { Feature, Point } from 'geojson';
import { LevelGeometry } from '../levelGeometry';

export const getMarkerFeatures = (levelGeometry: LevelGeometry) => {
  const features = [];
  if (levelGeometry.properties?.facades) {
    levelGeometry.properties?.facades.forEach((facade) => {
      features.push({
        type: 'Feature',
        properties: {
          name: facade.index,
          id: facade.id,
          type: 'facade'
        },
        geometry: {
          type: 'Point',
          coordinates: facade.center
        }
      });
    });
  }

  features.push({
    type: 'Feature' as Feature['type'],
    properties: {
      name: levelGeometry.properties?.name,
      id: levelGeometry.id,
      type: 'case'
    },
    geometry: {
      type: 'Point' as Point['type'],
      coordinates: levelGeometry.properties?.center
    }
  });

  return features;
};
