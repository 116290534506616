import { CaseId, CaseSpecification } from '../../domain/specification/cases/CaseSpecification';

export const CASE_SPECIFICATION_CHANGED = 'CASE_SPECIFICATION_CHANGED';

export const caseSpecificationChanged = (
  caseId: CaseId,
  caseSpecification: Partial<CaseSpecification>
) => ({
  type: CASE_SPECIFICATION_CHANGED,
  payload: { caseId, caseSpecification }
});

export type CaseSpecificationChangedAction = ReturnType<typeof caseSpecificationChanged>;
