import { getSectionInsideWallsThickness } from '../wallsThickness/getSectionInsideWallsThickness';
import { LevelGranulometry } from '../../../../levels/LevelGranulometry';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';
import { SectionWithExposureRate } from '../../../SectionWithExposureRate';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { getSectionRealInsideWallsLinear } from './getSectionRealInsideWallsLinear';
import { getSectionRealInsideWallsSurface } from './getSectionRealInsideWallsSurface';

export const getSectionRealInsideWallsSurfaceDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry,
  section: SectionWithExposureRate
): string => {
  let details: string[] = [];
  details = [
    ...details,
    '<b>' +
      roundWith2Decimal(
        getSectionRealInsideWallsLinear(caseGranulometry, levelGranulometry, section)
      ) +
      ' mL</b> de linéaire réel de voiles non-exposés'
  ];
  details = [
    ...details,
    '<b>* (' +
      roundWith2Decimal(getSectionInsideWallsThickness(caseGranulometry)) +
      ' m</b> d’épaisseur de voiles non-exposés <b>/ 2)</b>'
  ];

  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(
        getSectionRealInsideWallsSurface(caseGranulometry, levelGranulometry, section)
      ) +
      ' m\u00B2</b>'
  ];
  return details.join('<br />');
};
