import { createSelector, Selector } from 'reselect';
import { ifDefined } from '../../../utils/ifDefined';
import { selectGranulometry } from './current.selector';
import {
  AllCasesInGranulometry,
  getAllCasesInGranulometry
} from '../../../domain/granulometry/queries/getAllCasesInGranulometry';
import { Maybe } from '../../../utils/Maybe';
import { State } from '../../reducers';

export const selectAllCasesInGranulometry: Selector<
  State,
  Maybe<AllCasesInGranulometry>
> = createSelector([selectGranulometry], ifDefined(getAllCasesInGranulometry));
