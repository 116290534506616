import { Section } from './Section';
import { isSectionAParking } from './parkingSections/ParkingSection';
import { isSectionASmokeExtractionDucts } from './circulationSections/smokeExtractionDucts';

export interface SectionWithDepth extends Section {
  depth: number;
}

export const isSectionWithDepth = (section: Section): section is SectionWithDepth =>
  isSectionAParking(section) || isSectionASmokeExtractionDucts(section);
