import { getSectionTheoreticalInsideWallsLinear } from './getSectionTheoreticalInsideWallsLinear';
import { getSectionInsideWallsThickness } from '../../wallsThickness/getSectionInsideWallsThickness';
import { SectionWithExposureRate } from '../../../../SectionWithExposureRate';
import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../../../levels/LevelGranulometry';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getSectionTheoreticalInsideWallsSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithBearingRelativeSections | LevelGranulometry,
  section: SectionWithExposureRate
): number => {
  const insideWallsLinear = getSectionTheoreticalInsideWallsLinear(
    caseGranulometry,
    levelGranulometry,
    section
  );
  const insideWallsThickness = getSectionInsideWallsThickness(caseGranulometry);
  return (insideWallsLinear / 2) * insideWallsThickness;
};
