import { Route } from '../../Route';
import { matchesPath } from '../../utils/matchesPath';

export type CasesRouteParams = { projectId: string };
export const casesRoute: Route<CasesRouteParams> = {
  path: `/project/:projectId/cases/`,
  matchesPath: matchesPath<CasesRouteParams>(/\/project\/([^/]+)\/cases/, {
    projectId: 1
  })
};
