import { buildingsRoute } from '../toolbox/buildingsPanel/buildingsRoute';
import { deliverablesArchivesRoute } from '../toolbox/deliverablesPanel/deliverablesArchivesRoute';
import { sectionsRoute } from '../toolbox/sectionsPanel/sectionsRoute';
import { smartRequestRoute } from '../toolbox/smartRequestsPanel/smartRequestRoute';
import { getLevelLabel } from '../../domain/granulometry/levels/LevelGranulometry';
import { CaseSpecification } from '../../domain/specification/cases/CaseSpecification';
import { LodgmentTypeSpecification } from '../../domain/specification/lodgmentTypes/LodgmentTypeSpecification';
import { Property } from '../../domain/specification/cases/Property';
import { caseGranulometryEditFifthFacadeRoute } from '../toolbox/casesPanel/caseGranulometryEditFifthFacadeRoute';
import { caseGranulometryEditSuperstructureRoute } from '../toolbox/casesPanel/caseGranulometryEditSuperstructureRoute';
import { caseGranulometryEditEnergyAndNetworksRoute } from '../toolbox/casesPanel/caseGranulometryEditEnergyAndNetworksRoute';
import { caseGranulometryEditInfrastructureRoute } from '../toolbox/casesPanel/caseGranulometryEditInfrastructureRoute';
import { caseGranulometryEditGroundAdaptationsRoute } from '../toolbox/casesPanel/caseGranulometryEditGroundAdaptationsRoute';
import { caseGranulometryEditPeripheralReinforcementsRoute } from '../toolbox/casesPanel/caseGranulometryEditPeripheralReinforcementsRoute';
import { caseGranulometryEditOutdoorsRoute } from '../toolbox/casesPanel/caseGranulometryEditOutdoorsRoute';
import { caseGranulometryEditOtherPropertiesRoute } from '../toolbox/casesPanel/caseGranulometryEditOtherPropertiesRoute';
import { caseGranulometryEditOtherPropertiesPropertyRoute } from '../toolbox/casesPanel/caseGranulometryEditOtherPropertiesPropertyRoute';
import { caseGranulometryEditLodgmentTypeRoomRoute } from '../toolbox/casesPanel/caseGranulometryEditLodgmentTypeRoomRoute';
import { caseGranulometryEditLevelDetailsRoute } from '../toolbox/casesPanel/caseGranulometryEditLevelDetailsRoute';
import { caseGranulometryEditLodgmentTypeRoute } from '../toolbox/casesPanel/caseGranulometryEditLodgmentTypeRoute';
import { caseGranulometryRoute } from '../toolbox/casesPanel/caseGranulometryRoute';
import { caseRoute } from '../toolbox/casesPanel/caseRoute';
import { casesRoute } from '../toolbox/casesPanel/casesRoute';
import { buildingRoute } from '../toolbox/buildingsPanel/buildingRoute';
import { deliverablesExportRoute } from '../toolbox/deliverablesPanel/deliverablesExportRoute';
import { deliverablesRoute } from '../toolbox/deliverablesPanel/deliverablesRoute';
import { projectDetailsRoute } from '../toolbox/projectPanel/projectDetailsRoute';
import { sectionsEditRoute } from '../toolbox/sectionsPanel/sectionsEditRoute';
import { sectionsIndividualRoute } from '../toolbox/sectionsPanel/sectionsIndividualRoute';
import { smartRequestsRoute } from '../toolbox/smartRequestsPanel/smartRequestsRoute';

export const getBreadcrumbs = (
  path: string,
  currentBuildingLabel?: string,
  currentCaseLabel?: string,
  currentCase?: CaseSpecification
): string[] => {
  const segments: string[] = [];
  if (projectDetailsRoute.matchesPath(path).matches) {
    segments.push('Details');
  }

  if (buildingsRoute.matchesPath(path).matches) {
    segments.push('BUILDINGS');

    const buildingMatch = buildingRoute.matchesPath(path);
    if (buildingMatch.matches && currentBuildingLabel) {
      segments.push(currentBuildingLabel);
    }
  }

  if (casesRoute.matchesPath(path).matches) {
    segments.push('CASES');

    const caseMatch = caseRoute.matchesPath(path);
    if (caseMatch.matches && currentCaseLabel) {
      segments.push(currentCaseLabel);

      const granulometryMatch = caseGranulometryRoute.matchesPath(path);
      if (granulometryMatch.matches) {
        segments.push('Granulo');

        const editLodgmentMatch = caseGranulometryEditLodgmentTypeRoute.matchesPath(path);
        if (editLodgmentMatch.matches && editLodgmentMatch.params) {
          segments.push(editLodgmentMatch.params.lodgmentType);

          const lodgmentType = currentCase?.lodgmentsTypesSpecifications.find(
            (lt) => lt.lodgmentType === editLodgmentMatch?.params?.lodgmentType
          ) as LodgmentTypeSpecification;

          const editLodgmentTypeRoomMatch =
            caseGranulometryEditLodgmentTypeRoomRoute.matchesPath(path);
          if (editLodgmentTypeRoomMatch.matches && editLodgmentTypeRoomMatch.params) {
            const { roomIndex } = editLodgmentTypeRoomMatch.params;
            segments.push('Rooms', lodgmentType.rooms[Number(roomIndex)].title);
          }
        }

        const editLevelMatch = caseGranulometryEditLevelDetailsRoute.matchesPath(path);
        if (editLevelMatch.matches && editLevelMatch.params) {
          segments.push(getLevelLabel(parseInt(editLevelMatch.params.levelId.toString())));
        }

        if (caseGranulometryEditFifthFacadeRoute.matchesPath(path).matches) {
          segments.push('Fifth facade');
        } else if (caseGranulometryEditSuperstructureRoute.matchesPath(path).matches) {
          segments.push('Superstructure');
        } else if (caseGranulometryEditEnergyAndNetworksRoute.matchesPath(path).matches) {
          segments.push('Energy and networks');
        } else if (caseGranulometryEditInfrastructureRoute.matchesPath(path).matches) {
          segments.push('Infrastructure');
        } else if (caseGranulometryEditGroundAdaptationsRoute.matchesPath(path).matches) {
          segments.push('Ground adaptations');
        } else if (caseGranulometryEditPeripheralReinforcementsRoute.matchesPath(path).matches) {
          segments.push('Peripheral reinforcements');
        } else if (caseGranulometryEditOutdoorsRoute.matchesPath(path).matches) {
          segments.push('Outdoors');
        } else if (caseGranulometryEditOtherPropertiesRoute.matchesPath(path).matches) {
          segments.push('Other properties');
        }

        const editPropertyMatch =
          caseGranulometryEditOtherPropertiesPropertyRoute.matchesPath(path);
        if (editPropertyMatch.matches && editPropertyMatch.params) {
          const { propertyName } = editPropertyMatch.params;
          const property = currentCase?.properties.find((p) => p.name === propertyName) as Property;
          segments.push(property.title);
        }
      }
    }
  }

  if (sectionsRoute.matchesPath(path).matches) {
    segments.push('SECTIONS');

    if (sectionsEditRoute.matchesPath(path).matches) {
      segments.push('Manage & insert');
    }
    if (sectionsIndividualRoute.matchesPath(path).matches) {
      segments.push('Individual section');
    }
  }

  if (smartRequestsRoute.matchesPath(path).matches) {
    segments.push('SMART REQUEST');

    const smartRequestMatch = smartRequestRoute.matchesPath(path);
    if (smartRequestMatch.matches && smartRequestMatch.params) {
      segments.push(`Results for « ${smartRequestMatch.params.request} »`);
    }
  }

  if (deliverablesRoute.matchesPath(path).matches) {
    segments.push('DELIVERABLES');

    if (deliverablesExportRoute.matchesPath(path).matches) {
      segments.push('Export data');
    }
    if (deliverablesArchivesRoute.matchesPath(path).matches) {
      segments.push('Export archives');
    }
  }

  return segments;
};
