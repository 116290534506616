import * as React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { CardsList } from 'components/ui/CardsList';
import PanelTitle from '../PanelTitle';
import { ProjectNameCard } from './ProjectPanelView/ProjectNameCard';
import { ProjectSurfaceCard } from './ProjectPanelView/ProjectSurfaceCard';
import { ProjectLocationCard } from './ProjectPanelView/ProjectLocationCard';
import { ProjectSoilPreStudyCard } from './ProjectPanelView/ProjectSoilPreStudyCard';
import { Route } from 'react-router';
import { ProjectPanelWindows } from '../../windows/ProjectPanelWindows';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsProjectDetailsGingerOpened } from '../../../../store/selectors/navigation/toolbox/projectPanel/isProjectDetailsGingerOpened.selector';
import { HighlightOverlay } from '../../../layouts/HighlightOverlay';
import { selectProjectId } from '../../../../store/selectors/project';
import { selectIsProjectDetailsFloorSpaceOpened } from '../../../../store/selectors/navigation/toolbox/projectPanel/isProjectDetailsGeometryOpened.selector';
import { projectDetailsEditRoute } from '../../../../routes/toolbox/projectPanel/projectDetailsEditRoute';
import { getProjectDetailsGingerTriggerId } from '../../../../routes/toolbox/projectPanel/triggers/getProjectDetailsGingerTriggerId';
import { getProjectDetailsGeometryTriggerId } from '../../../../routes/toolbox/projectPanel/triggers/getProjectDetailsGeometryTriggerId';
import { goToProjectDetails } from '../../../../store/actions/navigations/toolbox/projectPanel/goToProjectDetails.action';
import { ProjectComEthIcConstructionToleranceRate } from './ProjectPanelView/ProjectComEthIcConstructionToleranceRate';

const ProjectPanelView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projectId = useSelector(selectProjectId);
  const isProjectDetailsGingerOpened = useSelector(selectIsProjectDetailsGingerOpened);
  const isProjectDetailsFloorSpaceOpened = useSelector(selectIsProjectDetailsFloorSpaceOpened);
  const isHighlighted = isProjectDetailsGingerOpened || isProjectDetailsFloorSpaceOpened;
  const triggerId = isHighlighted
    ? isProjectDetailsGingerOpened
      ? getProjectDetailsGingerTriggerId()
      : getProjectDetailsGeometryTriggerId()
    : undefined;
  const handleGoToProjectDetails = React.useCallback(() => {
    if (projectId) dispatch(goToProjectDetails(projectId));
  }, [dispatch, projectId]);

  return (
    <div className="panel">
      <Route path={projectDetailsEditRoute.path} component={ProjectPanelWindows} />
      <CardsList>
        <PanelTitle title={t('Project')} />
        <Scrollbars
          autoHide={true}
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight={true}
          autoHeightMin="100%"
          autoHeightMax="100%"
          style={{ width: '100%', height: 'calc(100vh - 116px)' }}
          renderTrackVertical={(properties) => <div {...properties} className="track-vertical" />}>
          <HighlightOverlay
            highlighted={isHighlighted}
            triggerId={triggerId}
            onReturn={handleGoToProjectDetails}>
            <ProjectNameCard />
            {process.env.GB_FEAT_PROJECT_LOCATION !== 'false' && <ProjectLocationCard />}
            <ProjectSurfaceCard />
            {process.env.GB_FEAT_CSTB_API !== 'false' &&
              process.env.GB_FEAT_CSTB_INDICATOR_IC_CONSTRUCTION !== 'false' && (
                <ProjectComEthIcConstructionToleranceRate />
              )}
            {process.env.GB_FEAT_GINGER_MAP !== 'false' && <ProjectSoilPreStudyCard />}
          </HighlightOverlay>
        </Scrollbars>
      </CardsList>
    </div>
  );
};

export default ProjectPanelView;
