import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import smokeExtractionDucts, {
  SmokeExtractionDuctsSection
} from '../../../../../sections/circulationSections/smokeExtractionDucts';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createTopLevelSmokeExtractionDuctsSection = (
  caseGranulometry: CaseGranulometry,
  group: string
): SmokeExtractionDuctsSection => {
  const depth = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'smokeExtractionDuctsDepth'
  ) as number;
  const width = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'smokeExtractionDuctsWidth'
  ) as number;
  const surface = depth * width;
  return smokeExtractionDucts({
    surface,
    depth,
    width,
    group
  });
};
