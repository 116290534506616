import * as React from 'react';
import { IconObject } from '../../../ui/Icons/iconObject';

interface EnlargeGranulometryButtonProps {
  isToolboxEnlarged: boolean;
  setIsToolboxEnlarged: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EnlargeGranulometryButton = ({
  isToolboxEnlarged,
  setIsToolboxEnlarged
}: EnlargeGranulometryButtonProps) => {
  return isToolboxEnlarged ? (
    <IconObject
      iconName="enlargeOff"
      type="menu"
      onClick={() => {
        setIsToolboxEnlarged(!isToolboxEnlarged);
      }}
    />
  ) : (
    <IconObject
      iconName="enlargeOn"
      type="menu"
      onClick={() => {
        setIsToolboxEnlarged(!isToolboxEnlarged);
      }}
    />
  );
};
