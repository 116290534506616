import React from 'react';
import { LevelGeometry } from '../../../../../../services/mapBox/mapboxDraw/levelGeometry';
import { Card } from '../../../../../ui/Card';
import { SpecialLabel } from '../../../../../ui/SpecialLabel';
import { FacadeForm } from './FacadeForm';
import { LevelGeometryForm } from './LevelGeometryForm';
import { MaybeUiGeometry } from '../../Geometry';
import { useTranslation } from 'react-i18next';
import { CardTitle } from '../../../../../ui/CardTitle';
import { FacadeSpecification } from '../../../../../../services/mapBox/domain/facades/FacadeSpecification';
import { LayerWithLevelGeometry } from '../../../../../../services/mapBox/layer/layer.model';
import { getLevelLabel } from '../../../../../../domain/granulometry/levels/LevelGranulometry';

interface SelectedLevelCardProps {
  ui: MaybeUiGeometry;
  selectedLevel: LayerWithLevelGeometry;
  dispatchChange: (feature: LevelGeometry) => {
    type: string;
    payload: { levelGeometry: LevelGeometry };
  };
}

export const SelectedLevelCard = ({
  ui,
  selectedLevel,
  dispatchChange
}: SelectedLevelCardProps) => {
  const { t } = useTranslation();
  const { draw } = selectedLevel;
  return (
    <Card>
      <CardTitle>
        {`Cage ${draw.properties.name?.substring(1, 3)} : Niveau ${getLevelLabel(
          draw.properties.floor
        )} (${draw.properties.name})`}
      </CardTitle>
      <SpecialLabel label={t('geometryWindow.casesCard.feature.specialLabel')} />
      <LevelGeometryForm levelGeometry={draw} />
      <SpecialLabel label={t('geometryWindow.casesCard.facades.specialLabel')} />
      {draw.properties.facades &&
        draw.properties.facades.map((facade: FacadeSpecification, index: number) => (
          <FacadeForm
            key={index}
            floorSpace={draw}
            facade={facade}
            index={index}
            ui={ui}
            dispatchChange={dispatchChange}
          />
        ))}
    </Card>
  );
};
