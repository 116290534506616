import * as R from 'ramda';
import { CaseEditingStage, CaseSpecification } from './CaseSpecification';
import { Surface } from '../Surface';
import { LodgmentType } from '../lodgmentTypes/LodgmentType';
export interface CaseSurface {
  grossFloorSurface?: Surface;
  grossFloorSurfaceEff?: Surface;
  realBuiltSurface?: Surface;
  cuttedBuiltSurface?: Surface;
  surfaceForSale?: Surface;
}

export interface ProjectedCaseSurface {
  grossFloorSurface: Surface;
  grossFloorSurfaceEff: Surface;
  realBuiltSurface: Surface;
  cuttedBuiltSurface: Surface;
  surfaceForSale: Surface;
}

export type CaseCustomDistribution = { [key: number]: { [key in LodgmentType]: number } };

export type CaseCustomDistributionByType = { [key in LodgmentType]: number };

/**
 * Indique si la surface de cage est spécifiée ou laissée à déterminer automatiquement
 * @param surface de cage (CaseSurface) concernée
 * @return true si au moins une valeur de surface est spécifiée, false sinon
 */
const hasSpecifiedSurface = ({ cuttedBuiltSurface }: CaseSurface): boolean =>
  cuttedBuiltSurface !== undefined;

/**
 * Indique si une cage a une surface spécifiée ou laissée à déterminer automatiquement
 * @param caseSpecification cage concernée (CaseSpecification)
 * @return true si au moins une valeur de surface est spécifiée, false sinon
 */
export const caseHasSpecifiedSurface = (caseSpecification: CaseSpecification): boolean =>
  hasSpecifiedSurface(caseSpecification.surfaces);

/**
 * Donne à la cage spécifiée la surface spécifiée
 * @param caseSurface nouvelle surface de cage (CaseSurface)
 * @return CaseSpecification -> CaseSpecification
 */
export const setCaseSurface =
  (caseSurface: CaseSurface) =>
  (caseSpecification: CaseSpecification): CaseSpecification =>
    R.assoc('surfaces', caseSurface, caseSpecification);

/**
 * Donne à la cage spécifiée la distribution (stade distribution) spécifiée
 * @param customDistribution nouvelle distribution de cage (CaseCustomDistribution)
 * @return CaseSpecification -> CaseSpecification
 */
export const setCaseCustomDistribution =
  (customDistribution: CaseCustomDistribution) =>
  (caseSpecification: CaseSpecification): CaseSpecification =>
    R.assoc('customDistribution', customDistribution, caseSpecification);

/**
 * Donne à la cage spécifiée le stage d'édition (granulometry, distribution, completion) spécifiée
 * @param editingStage nouveau stade d’édition (CaseEditingStage)
 * @return CaseSpecification -> CaseSpecification
 */
export const setCaseEditingStage =
  (editingStage: CaseEditingStage) =>
  (caseSpecification: CaseSpecification): CaseSpecification =>
    R.assoc('editingStage', editingStage, caseSpecification);
