import { Project } from '../../domain/project/Project';

export const PROJECT_CUSTOM_PRICE_LIST_ID_CHANGED = 'PROJECT_CUSTOM_PRICE_LIST_ID_CHANGED';

export const setProjectCustomPriceListId = (
  newCustomPriceListId: Project['customPriceListId']
) => ({
  type: PROJECT_CUSTOM_PRICE_LIST_ID_CHANGED,
  payload: { newCustomPriceListId }
});

export type SetProjectCustomPriceListIdAction = ReturnType<typeof setProjectCustomPriceListId>;
