import { getCaseDataLevelsForcedRealBuiltSurfaces } from '../../../../../../specification/cases/queries/levels/surfaces/getCaseDataLevelsForcedRealBuiltSurfaces';
import { getCaseDataProjectedTopLevelCount } from '../../../../../../specification/cases/queries/levels/counts/getCaseDataProjectedTopLevelCount';
import { getCaseDataGroundLevelForcedSurface } from '../../../../../../specification/cases/queries/levels/surfaces/getCaseDataGroundLevelForcedSurface';
import { LevelGranulometry } from '../../../../LevelGranulometry';
import { isGroundLevel } from '../../../is/isGroundLevel';
import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { getTopLevelRealBuiltSurface } from './getTopLevelRealBuiltSurface';
import { hasCaseGranulometryGroundLevelDrawnGeometry } from '../../../../../cases/queries/has/hasCaseGranulometryGroundLevelDrawnGeometry';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { isTopLevel } from '../../../is/isTopLevel';
import { getTopLevelGrossFloorSurfaceEff } from '../grossFloorSurfaces/getTopLevelGrossFloorSurfaceEff';
import { getTopLevelRealOutsideWallsSurface } from '../../walls/realWalls/getTopLevelRealOutsideWallsSurface';
import { getLevelSpecificationFromCaseProjectionWithGivenLevelIndex } from '../../../../../../projection/cases/queries/levels/getLevelSpecificationFromCaseProjectionWithGivenLevelIndex';
import { getLevelHoppersSurface } from '../../../../../../specification/cases/queries/levels/surfaces/getLevelHoppersSurface';
import { getLevelOutsideWallsSurfaceFromLevelsGeometry } from '../../../../../../specification/cases/queries/levels/surfaces/getLevelOutsideWallsSurfaceFromLevelsGeometry';
import { getLevelSpecificationFromCaseSpecificationWithGivenLevelIndex } from '../../../../../../specification/cases/queries/get/levels/getLevelSpecificationFromCaseSpecificationWithGivenLevelIndex';
import { getTopLevelHoppersSurface } from '../getTopLevelHoppersSurface';

// Note : the details is not folowwing perfectly the getLevelRealBuiltSurface to make it work's
export const getTopLevelRealBuiltSurfaceDetails = (
  levelGranulometry: LevelGranulometry,
  caseGranulometry: CaseGranulometry
): string => {
  let details: string[] = [];

  const levelSpecification = getLevelSpecificationFromCaseSpecificationWithGivenLevelIndex(
    caseGranulometry.initialSpecifications,
    levelGranulometry.level
  );
  const levelProjection = getLevelSpecificationFromCaseProjectionWithGivenLevelIndex(
    caseGranulometry.initialSpecifications,
    levelGranulometry.level
  );
  // 1 : If there is a manually forced real built surface :
  if (levelSpecification !== undefined && levelSpecification.realBuiltSurface) {
    details = [
      ...details,
      '<b>(' +
        levelSpecification.realBuiltSurface +
        ' m\u00B2</b> de Sdp Réelle saisie pour ce niveau par l’opérateur'
    ];
  }
  // 2 : If the level has a filled geometry
  else if (levelProjection !== undefined && levelProjection.geometry?.properties.area) {
    details = [
      ...details,
      '<b>SdP Réelle du niveau calculée à partir de la géométrie saisie :</b>'
    ];

    details = [...details, ''];

    details = [
      ...details,
      '<b>' +
        roundWith2Decimal(levelProjection.geometry.properties.area) +
        ' m\u00B2</b> de SHOB VPP dessinée'
    ];

    details = [
      ...details,
      '<b>- ' +
        roundWith2Decimal(
          getLevelHoppersSurface(caseGranulometry.initialSpecifications, levelProjection)
        ) +
        ' m\u00B2</b> de trémies'
    ];

    details = [
      ...details,
      '<b>- ' +
        roundWith2Decimal(
          getLevelOutsideWallsSurfaceFromLevelsGeometry(
            caseGranulometry.initialSpecifications,
            levelProjection,
            true
          )
        ) +
        ' m\u00B2</b> d’emprise de réelle de voile exposée'
    ];
  }
  // 3 : If there is a filled projectedGfsEffRelativeTopLevelsRbs (saved from a previous pass)
  else if (
    caseGranulometry.initialSpecifications.projectedGfsEffRelativeTopLevelsRbs !== undefined
  ) {
    const topLevelGrossFloorSurfaceEff = getTopLevelGrossFloorSurfaceEff(
      caseGranulometry,
      levelGranulometry
    );
    details = [
      ...details,
      '<b>' + roundWith2Decimal(topLevelGrossFloorSurfaceEff) + ' m\u00B2</b> de SHOB VPP'
    ];
    details = [
      ...details,
      '<b>- ' +
        roundWith2Decimal(getTopLevelRealOutsideWallsSurface(caseGranulometry, levelGranulometry)) +
        ' m\u00B2</b> d’emprise réelle de voiles exposés'
    ];
    details = [
      ...details,
      '<b>- ' +
        getTopLevelHoppersSurface(caseGranulometry, levelGranulometry, true) +
        ' m\u00B2</b> de surface de trémies (rampe exlue)'
    ];
  }
  // 4 : Otherwise :
  else {
    const caseTopLevelCount = getCaseDataProjectedTopLevelCount(
      caseGranulometry.initialSpecifications
    );
    const levelsForcedRealBuiltSurfaces = getCaseDataLevelsForcedRealBuiltSurfaces(
      caseGranulometry.initialSpecifications
    );
    const unforcedLevelCount = caseTopLevelCount - levelsForcedRealBuiltSurfaces.count;
    const geometryMustBeTreated =
      caseGranulometry &&
      hasCaseGranulometryGroundLevelDrawnGeometry(caseGranulometry) &&
      isTopLevel(caseGranulometry, levelGranulometry);

    // 4a : In case of floor space filled :
    if (geometryMustBeTreated) {
      // Details will go to 3
    }
    // 4b : Otherwise :
    else {
      const caseRealBuiltSurface =
        caseGranulometry.initialSpecifications.projectedRealBuiltSurface.value;
      details = [
        ...details,
        '<b>(' +
          roundWith2Decimal(caseRealBuiltSurface) +
          ' m\u00B2</b> de Sdp Réelle à la cage <b>- ' +
          roundWith2Decimal(levelsForcedRealBuiltSurfaces.totalSurface) +
          ' m\u00B2</b> de Sdp Réelle forcée '
      ];
      details = [
        ...details,
        '<b>/ ' +
          unforcedLevelCount +
          '</b> étage' +
          (unforcedLevelCount > 1 ? 's' : '') +
          ' non forcé' +
          (unforcedLevelCount > 1 ? 's' : '')
      ];
    }

    // 4a or 4b : ramp surface distribution
    if (
      caseGranulometry.initialSpecifications.builtInRamp && // If a ramp is built in the case
      caseGranulometry.initialSpecifications.projectedBasementLevelsCount !== 0 && // their is at least one basement level
      !getCaseDataGroundLevelForcedSurface(caseGranulometry.initialSpecifications) // and the ground level real built surface is not forced :
    ) {
      // Get the ramp surface
      const rampLength = getCasePropertyConvertedValue(
        caseGranulometry.initialSpecifications,
        'rampLength'
      ) as number;
      const rampWidth = getCasePropertyConvertedValue(
        caseGranulometry.initialSpecifications,
        'rampWidth'
      ) as number;
      const rampSurface = rampLength * rampWidth;
      if (isGroundLevel(levelGranulometry)) {
        // For the ground level only
        details = [...details, '- ' + rampSurface + ' m\u00B2 de rampe'];
        if (!geometryMustBeTreated) {
          details = [
            ...details,
            '<b>+ ' +
              rampSurface +
              ' m\u00B2</b> de rampe <b>/ ' +
              unforcedLevelCount +
              '</b> étage' +
              (unforcedLevelCount > 1 ? 's' : '') +
              ' non forcé' +
              (unforcedLevelCount > 1 ? 's' : '')
          ];
        }
      } else {
        if (!geometryMustBeTreated) {
          details = [
            ...details,
            '<b>+ ' +
              rampSurface +
              ' m\u00B2</b> de rampe <b>/ ' +
              unforcedLevelCount +
              '</b> étage' +
              (unforcedLevelCount > 1 ? 's' : '') +
              ' non forcé' +
              (unforcedLevelCount > 1 ? 's' : '')
          ];
        }
      }
    }
  }

  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(
        getTopLevelRealBuiltSurface(levelGranulometry, caseGranulometry.initialSpecifications)
      ) +
      'm\u00B2</b>'
  ];

  return details.join('<br />');
};
