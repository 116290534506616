import { Property } from '../../../../specification/cases/Property';
import { getCasePropertyIndex } from './getCasePropertyIndex';
import { CaseGranulometry } from '../../CaseGranulometry';
import { getCaseProperty } from './getCaseProperty';

export const updateCaseProperty =
  (propertyName: string, entriesToUpdate: Partial<Property>) =>
  (caseGranulometry: CaseGranulometry): CaseGranulometry => {
    const updatedCaseProperty = {
      ...(getCaseProperty(caseGranulometry, propertyName) as Property),
      ...entriesToUpdate
    };
    const updatedCaseProperties = [...caseGranulometry.properties];
    updatedCaseProperties[getCasePropertyIndex(caseGranulometry, propertyName)] =
      updatedCaseProperty;
    return { ...caseGranulometry, properties: updatedCaseProperties };
  };
