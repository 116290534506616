import { getSectionWallsLinear } from '../getSectionWallsLinear';
import { getSectionDisplayedSurface } from '../../surfaces/getSectionDisplayedSurface';
import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../../levels/LevelGranulometry';
import { LodgmentSection } from '../../../lodgmentSections/LodgmentSection';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';

export const getSectionPartitionWallsLinear = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithBearingRelativeSections | LevelGranulometry,
  lodgmentSection: LodgmentSection
): number => {
  const wallsLinear = getSectionWallsLinear(caseGranulometry, levelGranulometry, lodgmentSection);
  const surface = getSectionDisplayedSurface(lodgmentSection);
  return wallsLinear * (0.3704 * Math.log(surface) - 0.8829);
};
