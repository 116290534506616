import { LevelGranulometryFullFilled } from '../../LevelGranulometry';
import { getSectionsTotalDrawnSurface } from '../../../sections/queries/surfaces/getSectionsTotalDrawnSurface';
import { isSectionAElevatorShaft } from '../../../sections/circulationSections/elevatorShaft';
import { isSectionAStairsShaft } from '../../../sections/circulationSections/stairsShaft';
import { isSectionARamp } from '../../../sections/circulationSections/ramp';
import { getLevelAlreadyCalculatedRealBuiltSurface } from './getLevelAlreadyCalculatedRealBuiltSurface';
import { isSectionASmokeExtractionDucts } from '../../../sections/circulationSections/smokeExtractionDucts';
import { getLevelFullFilledContent } from '../content/getLevelFullFilledContent';

export const getTopLevelDrawnSurface = (levelGranulometry: LevelGranulometryFullFilled): number =>
  getLevelAlreadyCalculatedRealBuiltSurface(levelGranulometry).value +
  getSectionsTotalDrawnSurface(
    getLevelFullFilledContent(levelGranulometry).filter(
      (section) =>
        isSectionAElevatorShaft(section) ||
        isSectionAStairsShaft(section) ||
        isSectionASmokeExtractionDucts(section) ||
        isSectionARamp(section)
    )
  );
