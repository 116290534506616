import { RoomSpecification } from './RoomSpecification';
import { IconObjectProps } from '../../../components/ui/Icons/iconObject';
import livingRoomKitchen from '../../granulometry/rooms/livingRoomKitchen';
import livingRoomKitchenC from '../../granulometry/rooms/livingRoomKitchenC';
import livingRoomKitchenK from '../../granulometry/rooms/livingRoomKitchenK';

export type LivingRoomKitchenName =
  | 'livingRoomKitchen'
  | 'livingRoomKitchenC'
  | 'livingRoomKitchenK';

export interface LivingRoomKitchenSpecification {
  name: LivingRoomKitchenName;
  title: string; // label :-(
  defaultWidth: number;
  icon: IconObjectProps; // DURTY
}

export const isRoomSpecificationAnyLivingRoomKitchen = (
  roomSpecification: RoomSpecification
): roomSpecification is LivingRoomKitchenSpecification =>
  roomSpecification.name === 'livingRoomKitchen' ||
  roomSpecification.name === 'livingRoomKitchenC' ||
  roomSpecification.name === 'livingRoomKitchenK';

export const defaultLivingRoomKitchenSpecification: {
  [key in LivingRoomKitchenName]: LivingRoomKitchenSpecification;
} = {
  livingRoomKitchen: livingRoomKitchen(),
  livingRoomKitchenC: livingRoomKitchenC(),
  livingRoomKitchenK: livingRoomKitchenK()
};
