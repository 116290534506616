import { getBuildingIndexGivenBuildingId } from './getBuildingIndexGivenBuildingId';
import { getBuildingIdFromCaseId } from './getBuildingIdFromCaseId';
import { getCaseIndexInBuildingFromCaseId } from './getCaseIndexInBuildingGivenCaseId';
import { CaseId } from '../../specification/cases/CaseSpecification';
import { Project } from '../../project/Project';
import { letterFromIndex } from '../../../utils/letterFromIndex';

export const getCaseLabelGivenCaseId = (project: Project, caseId: CaseId): string => {
  const buildingId = getBuildingIdFromCaseId(project, caseId);

  if (!buildingId) return '00';

  const buildingIndex = getBuildingIndexGivenBuildingId(project, buildingId);
  const caseIndexInBuilding = getCaseIndexInBuildingFromCaseId(project, caseId);

  if (typeof buildingIndex !== 'number' || typeof caseIndexInBuilding !== 'number') return 'NC';

  return `${buildingIndex + 1}${letterFromIndex(caseIndexInBuilding)}`;
};
