import { APP_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { getCasePropertyDefaultValue } from '../../../specification/cases/queries/get/properties/getCasePropertyDefaultValue';
import { CirculationName } from './CirculationSection';
import { SectionWithTheoreticalSurface } from '../SectionWithTheoreticalSurface';
import { STAIR_SHAFT_ICON } from './stairsShaft';
import { SectionWithWidth } from '../SectionWithWidth';
import { SectionWithLength } from '../SectionWithLength';

interface StairsShaftInfraProps {
  surface: number;
  length: number;
  width: number;
  bearingLength: number;
  bearingWidth: number;
  group: string;
}

export interface StairsShaftInfraSection
  extends Section,
    SectionDrawn,
    SectionWithLength,
    SectionWithWidth,
    SectionWithTheoreticalSurface {
  name: CirculationName.StairsShaftInfra;
  bearingLength: number;
  bearingWidth: number;
}

const stairsShaftInfra = ({
  surface,
  length,
  width,
  bearingLength,
  bearingWidth,
  group
}: StairsShaftInfraProps): StairsShaftInfraSection => ({
  // Section
  id: 'NOID',
  name: CirculationName.StairsShaftInfra,
  title: i18next.t('stairsShaftInfra.title'),
  type: 'circulation',
  displayedSurface: surface,
  // SectionDrawn
  surface,
  color: APP_COLOR,
  icon: STAIR_SHAFT_ICON,
  group,
  // SectionWithTheoreticalSurface
  theoreticalSurface: undefined,
  defaultTheoreticalSurface:
    getCasePropertyDefaultValue('stairsShaftWidth') *
    getCasePropertyDefaultValue('stairsShaftLength'),
  // StairsShaftInfraSection
  length,
  width,
  bearingLength,
  bearingWidth
});

export default stairsShaftInfra;

export const isSectionAStairsShaftInfra = (section: Section): section is StairsShaftInfraSection =>
  getSectionName(section) === CirculationName.StairsShaftInfra;
