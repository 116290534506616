import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../LevelGranulometry';
import { getSectionWallsLinear } from '../../../../sections/queries/walls/getSectionWallsLinear';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';
import { getTopLevelWallsLinear } from './getTopLevelWallsLinear';
import { isSectionIncludedInSurfaceForSale } from '../../../../sections/SectionIncludedInSurfaceForSale';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { getLevelFullFilledContent } from '../../content/getLevelFullFilledContent';

export const getTopLevelWallsLinearDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled | LevelGranulometry
): string =>
  '<b>' +
  getLevelFullFilledContent(levelGranulometry)
    .reduce(
      (acc, section) =>
        isSectionIncludedInSurfaceForSale(section)
          ? [
              ...acc,
              roundWith2Decimal(
                getSectionWallsLinear(caseGranulometry, levelGranulometry, section)
              ) +
                ' mL</b> de linéaire total voiles pour le ' +
                section.title.replace('Logement ', '')
            ]
          : acc,
      [] as string[]
    )
    .join('<br /><b>+ ') +
  '<br /><b>= ' +
  roundWith2Decimal(getTopLevelWallsLinear(caseGranulometry, levelGranulometry)) +
  ' mL</b>';
