import mapboxgl from 'mapbox-gl';
import { Feature, Polygon } from 'geojson';
import { LoadImageOptions } from '../map/map.repository';

export const DRAW_HELPER_FEATURE = 'drawHelper';
export const DRAW_HELPER_MOVING_FEATURE = 'drawHelperMovingLayer';
export const DRAW_HELPER_MOVING_FEATURE_STYLE_LAYER = 'gl-draw-helper-moving-polygon-fill';

export const createDrawHelperLayerId = () => {
  return DRAW_HELPER_FEATURE + '_layer_' + new Date().getTime();
};

export const createDrawHelperSourceId = () => {
  return DRAW_HELPER_FEATURE + '_source_' + new Date().getTime();
};

export const getLoadImageOptions = (imagePath: string): LoadImageOptions => ({
  imagePath,
  layerName: createDrawHelperLayerId(),
  sourceName: createDrawHelperSourceId()
});

export type DrawHelperMetaData = {
  movingLayerId?: string;
  featureId?: string;
};

export interface DrawHelper extends mapboxgl.RasterLayer {
  metadata: DrawHelperMetaData;
}

export interface MovingLayer
  extends Feature<
    Polygon,
    {
      type: 'drawHelperMovingLayer';
      drawHelperId: string;
    }
  > {
  id: string;
}
