import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { Maybe } from '../../../utils/Maybe';
import { Unit } from '../../../interfaces/Unit';

export interface Property {
  name: string;
  title: string;
  defaultValue: number;
  value?: number;
  asCustomValue?: boolean;
  min?: number;
  max?: number;
  unit: Unit;
  groups?: string[];
  notes?: string;
}

const naturalSort = (a: string, b: string): number => a.localeCompare(b);

export const getProperty = (propertyName: string) =>
  R.find<Property>(R.propEq('name', propertyName));

export const getPropertyValue = (propertyName: string) =>
  R.pipe<[Property[]], Maybe<Property>, number | Property | undefined>(
    getProperty(propertyName),
    R.when(RA.isNotNil, ({ defaultValue, value }) => value ?? defaultValue)
  );

export const propertyGroup = (property: Property): string =>
  R.compose<[Property], string[], string>(R.head, R.propOr([''], 'groups'))(property);

export const propertySubGroup = (property: Property): string =>
  R.compose<[Property], string[], string[], string>(
    R.join(' > '),
    R.tail,
    R.propOr([], 'groups')
  )(property);

export const propertyUnit = (property: Property): string => property.unit;

export const propertiesGroups = (properties: Property[]): string[] =>
  R.compose(R.sort(naturalSort), R.keys, R.indexBy(propertyGroup))(properties);

export const propertiesSubGroups = (properties: Property[]): string[] =>
  R.compose(R.sort(naturalSort), R.keys, R.indexBy(propertySubGroup))(properties);

export const propertiesUnits = (properties: Property[]): string[] =>
  R.compose(R.sort(naturalSort), R.keys, R.indexBy(propertyUnit))(properties);

export const getPropertyIndex = (propertySpec: { name: string }, properties: Property[]): number =>
  properties.findIndex((property) => property.name === propertySpec.name);

export const getPreviousProperty = (
  propertySpec: { name: string },
  properties: Property[]
): Property => properties[getPropertyIndex(propertySpec, properties) - 1];

export const hasPreviousProperty = (
  propertySpec: { name: string },
  properties: Property[]
): boolean => getPreviousProperty(propertySpec, properties) !== undefined;

export const getNextProperty = (propertySpec: { name: string }, properties: Property[]): Property =>
  properties[getPropertyIndex(propertySpec, properties) + 1];

export const hasNextProperty = (propertySpec: { name: string }, properties: Property[]): boolean =>
  getNextProperty(propertySpec, properties) !== undefined;
