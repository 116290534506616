import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../../ui/Card';
import { FormGroup } from '../../../../../ui/FormGroup';
import { SpecialLabel } from '../../../../../ui/SpecialLabel';
import { Input } from '../../../../../ui/input/Input';
import { Form } from '../../../../../ui/Form';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';
import RoomElementsTable from '../RoomElementsTable';
import { RoomSpecification } from '../../../../../../domain/specification/rooms/RoomSpecification';
import { isRoomSpecificationAnyLivingRoomKitchen } from '../../../../../../domain/specification/rooms/LivingRoomKitchenSpecification';
import {
  BodyguardType,
  isRoomSpecificationABalcony
} from '../../../../../../domain/specification/rooms/BalconySpecification';
import { getRoomSpecificationSurface } from '../../../../../../domain/specification/rooms/queries/get/getRoomSpecificationSurface';
import { useSelector } from 'react-redux';
import { LodgmentTypeSpecification } from '../../../../../../domain/specification/lodgmentTypes/LodgmentTypeSpecification';
import { getLivingRoomKitchenSpecificationCalculatedLength } from '../../../../../../domain/specification/rooms/queries/get/getLivingRoomKitchenSpecificationCalculatedLength';
import { getSectionTheoreticalSurface } from '../../../../../../domain/granulometry/sections/queries/surfaces/getSectionTheoreticalSurface';
import { BodyguardTypeCard } from './BodyguardTypeCard';
import { selectCurrentCaseGranulometry } from '../../../../../../store/selectors/granulometry/currentCase/currentCaseGranulometry.selector';
import { getCasePropertyConvertedValue } from '../../../../../../domain/specification/cases/queries/get/properties/getCasePropertyConvertedValue';

interface RoomWindowInnerProps {
  handleSetLodgmentTypeRoom: (
    value: number | BodyguardType | null,
    fieldName: 'length' | 'width' | 'depth' | 'surface' | 'bodyguardType'
  ) => void;
  lodgmentTypeSpecification: LodgmentTypeSpecification;
  roomSpecification: RoomSpecification;
}

const RoomWindowInner = ({
  roomSpecification,
  lodgmentTypeSpecification,
  handleSetLodgmentTypeRoom
}: RoomWindowInnerProps) => {
  const { t } = useTranslation();
  const caseGranulometry = useSelector(selectCurrentCaseGranulometry);

  if (!caseGranulometry) return null;

  const balconySurfaceOnRealLivingSurfaceRate = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'balconySurfaceOnRealLivingSurfaceRate'
  ) as number;

  return (
    <Form>
      <Card>
        <SpecialLabel label={t('Dimensions')} />
        <FormGroup horizontal={true} className="areas">
          {!isRoomSpecificationABalcony(roomSpecification) && (
            <div>
              <label htmlFor="length">{t('Length')}</label>
              <Input
                disabled
                name="length"
                type="number"
                value={undefined}
                placeholder={roundWith2Decimal(
                  isRoomSpecificationAnyLivingRoomKitchen(roomSpecification)
                    ? getLivingRoomKitchenSpecificationCalculatedLength(
                        lodgmentTypeSpecification.rooms,
                        getSectionTheoreticalSurface(lodgmentTypeSpecification),
                        roomSpecification
                      )
                    : roomSpecification.defaultLength
                )}
                suffix="m"
              />
            </div>
          )}
          {!isRoomSpecificationABalcony(roomSpecification) && (
            <div>
              <label htmlFor="width">{t('Width')}</label>
              <Input
                name="width"
                type="number"
                value={
                  isRoomSpecificationAnyLivingRoomKitchen(roomSpecification)
                    ? undefined
                    : roomSpecification.width
                }
                placeholder={roomSpecification.defaultWidth}
                suffix="m"
                handleChange={(value: number | null) => handleSetLodgmentTypeRoom(value, 'width')}
                disabled={isRoomSpecificationAnyLivingRoomKitchen(roomSpecification)}
              />
            </div>
          )}
          {isRoomSpecificationABalcony(roomSpecification) && (
            <>
              <div>
                <label htmlFor="depth">{t('Depth')}</label>
                <Input
                  name="depth"
                  type="number"
                  value={roomSpecification.depth}
                  placeholder={roomSpecification.defaultDepth}
                  suffix="m"
                  handleChange={(value: number | null) => handleSetLodgmentTypeRoom(value, 'depth')}
                />
              </div>
              <div style={{ width: '50%', marginLeft: '10px' }}>&nbsp;</div>
            </>
          )}
        </FormGroup>
      </Card>
      <Card>
        <SpecialLabel label={t('Areas')} />
        <FormGroup horizontal={true} className="area">
          <div>
            <label htmlFor="surface">{t('Surface')}</label>
            <Input
              name="surface"
              type="number"
              value={
                isRoomSpecificationAnyLivingRoomKitchen(roomSpecification)
                  ? undefined
                  : roomSpecification.surface
              }
              suffix="m<sup>2</sup>"
              handleChange={(value: number | null) => handleSetLodgmentTypeRoom(value, 'surface')}
              placeholder={roundWith2Decimal(
                getRoomSpecificationSurface(
                  lodgmentTypeSpecification.rooms,
                  getSectionTheoreticalSurface(lodgmentTypeSpecification),
                  balconySurfaceOnRealLivingSurfaceRate,
                  roomSpecification
                )
              )}
              disabled={isRoomSpecificationAnyLivingRoomKitchen(roomSpecification)}
            />
          </div>
          <div style={{ width: '50%', marginLeft: '10px' }}>&nbsp;</div>
        </FormGroup>
      </Card>
      <BodyguardTypeCard
        lodgmentTypeSpecification={lodgmentTypeSpecification}
        roomSpecification={roomSpecification}
        handleSetLodgmentTypeRoom={handleSetLodgmentTypeRoom}
      />
      <Card>
        <SpecialLabel label={t('Elements')} />
        <RoomElementsTable
          roomName={roomSpecification.name}
          lodgmentType={lodgmentTypeSpecification.lodgmentType}
        />
      </Card>
    </Form>
  );
};

export default RoomWindowInner;
