export class ApiError extends Error {
  public readonly requestedUrl: string;
  public readonly statusCode?: number;
  protected readonly _statusText?: string;

  constructor(message: string, requestedUrl: string, statusCode?: number, statusText?: string) {
    super(message);
    this.requestedUrl = requestedUrl;
    this.statusCode = statusCode;
    this._statusText = statusText;
  }

  get statusText(): string {
    return this._statusText || this.message;
  }

  public isApiError = true;
}
