import { OTHER_RBS_SECTIONS_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { SectionWithTechnicalSheaths } from '../SectionWithTechnicalSheaths';
import { TechnicalInfraPremiseSectionName } from './TechnicalPremiseSection';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';

interface ElectricalTransformerInfraSectionProps {
  surface?: number;
  exposureRate?: number;
  width?: number;
}

export interface ElectricalTransformerInfraSection
  extends Section,
    SectionDrawn,
    SectionWithExposureRate,
    SectionWithTechnicalSheaths {
  name: TechnicalInfraPremiseSectionName.ElectricalTransformerInfraSection;
  width?: number;
  defaultWidth: number;
  minWidth: number;
  maxWidth: number;
  defaultSurface: number;
  minSurface: number;
  maxSurface: number;
}

export const ELECTRICAL_TRANSFORMER_INFRA_SECTION_DEFAULT_SURFACE = 20;
export const ELECTRICAL_TRANSFORMER_INFRA_SECTION_MIN_SURFACE = 5;
export const ELECTRICAL_TRANSFORMER_INFRA_SECTION_MAX_SURFACE = 50;
export const ELECTRICAL_TRANSFORMER_INFRA_SECTION_DEFAULT_EXPOSURE_RATE = 1;
export const ELECTRICAL_TRANSFORMER_INFRA_SECTION_DEFAULT_WITH = 5;
export const ELECTRICAL_TRANSFORMER_INFRA_SECTION_MIN_WITH = 5;
export const ELECTRICAL_TRANSFORMER_INFRA_SECTION_MAX_WITH = 5;
export const ELECTRICAL_TRANSFORMER_INFRA_SECTION_ICON = {
  type: 'object',
  iconName: 'electricalTransformer',
  color: OTHER_RBS_SECTIONS_COLOR
} as IconObjectProps;

const electricalTransformerInfraSection = ({
                                             surface,
                                             exposureRate,
                                             width
                                           }: ElectricalTransformerInfraSectionProps): ElectricalTransformerInfraSection => ({
  // Section
  id: 'NOID',
  name: TechnicalInfraPremiseSectionName.ElectricalTransformerInfraSection,
  title: i18next.t('electricalTransformerSection.title'),
  type: 'others',
  displayedSurface: surface || ELECTRICAL_TRANSFORMER_INFRA_SECTION_DEFAULT_SURFACE,
  // SectionDrawn
  surface: surface || ELECTRICAL_TRANSFORMER_INFRA_SECTION_DEFAULT_SURFACE,
  color: OTHER_RBS_SECTIONS_COLOR,
  icon: ELECTRICAL_TRANSFORMER_INFRA_SECTION_ICON,
  // SectionWithExposureRate
  exposureRate: exposureRate || ELECTRICAL_TRANSFORMER_INFRA_SECTION_DEFAULT_EXPOSURE_RATE,
  defaultExposureRate: ELECTRICAL_TRANSFORMER_INFRA_SECTION_DEFAULT_EXPOSURE_RATE,
  // SectionWithTechnicalSheaths
  technicalSheathCount: 0,
  // ElectricalTransformerSection
  width: width || undefined,
  defaultWidth: ELECTRICAL_TRANSFORMER_INFRA_SECTION_DEFAULT_WITH,
  minWidth: ELECTRICAL_TRANSFORMER_INFRA_SECTION_MIN_WITH,
  maxWidth: ELECTRICAL_TRANSFORMER_INFRA_SECTION_MAX_WITH,
  defaultSurface: ELECTRICAL_TRANSFORMER_INFRA_SECTION_DEFAULT_SURFACE,
  minSurface: ELECTRICAL_TRANSFORMER_INFRA_SECTION_MIN_SURFACE,
  maxSurface: ELECTRICAL_TRANSFORMER_INFRA_SECTION_MAX_SURFACE
});

export default electricalTransformerInfraSection;

export const isSectionAElectricalTransformerInfraSection = (
  section: Section
): section is ElectricalTransformerInfraSection =>
  getSectionName(section) === TechnicalInfraPremiseSectionName.ElectricalTransformerInfraSection;

export const filterElectricalTransformerInfraSections = (
  sections: Section[]
): ElectricalTransformerInfraSection[] =>
  sections.filter(isSectionAElectricalTransformerInfraSection);
