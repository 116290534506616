import { ValidationRule } from '../ValidationsRule';
import { Notification } from '../Notification';
import { getProjectCasesProjections } from '../../projection/project/queries/cases/getProjectCasesProjections';
import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { getCaseOutsideWallTypeRatesValidity } from '../../specification/cases/queries/get/outsideWallTypeRates/getCaseOutsideWallTypeRatesValidity';
import { letterFromIndex } from '../../../utils/letterFromIndex';
import { getCaseProperty } from '../../specification/cases/queries/get/properties/getCaseProperty';

export const projectCasesOutsideWallTypeRatesMustBeValid: ValidationRule = (
  projectProjection: ProjectProjection
) => {
  const notifications: Notification[] = getProjectCasesProjections(projectProjection).reduce(
    (accNotifications, caseProjection) => {
      const outsideWallConcreteRateProperty = getCaseProperty(
        caseProjection,
        'outsideWallConcreteRate'
      );
      const outsideWallMasonryRateProperty = getCaseProperty(
        caseProjection,
        'outsideWallMasonryRate'
      );
      const outsideWallWoodRateProperty = getCaseProperty(caseProjection, 'outsideWallWoodRate');

      if (
        !getCaseOutsideWallTypeRatesValidity(
          outsideWallConcreteRateProperty?.value,
          outsideWallMasonryRateProperty?.value,
          outsideWallWoodRateProperty?.value
        )
      ) {
        const caseLabel =
          caseProjection.buildingIndex + 1 + letterFromIndex(caseProjection.indexInBuilding);
        return [
          ...accNotifications,
          {
            title:
              'La répartition des types d’enveloppes structurelles verticales ' +
              caseLabel +
              ' n’est pas valide.',
            explanation:
              'La somme des pourcentages des types d’enveloppes structurelles verticales, saisis et calculés, doit être égal à 100.',
            type: 'error'
          }
        ];
      }
      return accNotifications;
    },
    [] as Notification[]
  );

  return notifications.length !== 0 ? notifications : true;
};
