import { LevelGranulometry } from '../../LevelGranulometry';
import { isSectionAVerticalCirculation } from '../../../sections/VerticalCirculationSection';
import { isSectionAVentilationGroupInfra } from '../../../sections/circulationSections/ventilationGroupInfra';
import { getLevelFullFilledContent } from '../content/getLevelFullFilledContent';

export const getLevelAllButVerticalCirculationSectionsSurface = (
  levelGranulometry: LevelGranulometry
) =>
  getLevelFullFilledContent(levelGranulometry).reduce(
    (acc, section) =>
      !isSectionAVerticalCirculation(section) || isSectionAVentilationGroupInfra(section)
        ? acc + section.surface
        : acc,
    0
  ) || 0;
