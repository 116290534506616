import * as React from 'react';
import { Route } from 'react-router';
import { ProjectDetailsGingerWindow } from './ProjectDetailsGingerWindow/ProjectDetailsGingerWindow';
import { ToolboxWindows } from './ToolboxWindows';
import { projectDetailsEditGeometryRoute } from '../../../routes/toolbox/projectPanel/projectDetailsEditGeometryRoute';
import { GeometryWindow } from './GeometryWindow/GeometryWindow';
import { projectDetailsEditGingerRoute } from '../../../routes/toolbox/projectPanel/projectDetailsEditGingerRoute';

export const ProjectPanelWindows = () => (
  <ToolboxWindows>
    <Route path={projectDetailsEditGingerRoute.path} component={ProjectDetailsGingerWindow} />
    <Route path={projectDetailsEditGeometryRoute.path} component={GeometryWindow} />
  </ToolboxWindows>
);
