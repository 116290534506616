import * as R from 'ramda';
import { createSelector, Selector } from 'reselect';
import { getReportState, State } from '../reducers';
import { addNomenclatureTitlesInReport } from '../../domain/report/addNomenclatureTitlesInReport';
import { sortReportByRef } from '../../domain/report/sortReportByRef';
import { selectLotsNomenclature } from './lotsNomenclature.selector';
import { addComEthProductIndicatorsInReport } from '../../domain/report/addComEthProductIndicatorsInReport';
import { selectComEthReportProducts } from './cstb/comEthReportProducts.selector';
import { ReportState } from '../reducers/report.reducer';
import { AdminReportEntry, ReportEntry } from '../../domain/report/ReportEntry';

export const selectLotsReport: Selector<State, ReportEntry[] | undefined> = createSelector(
  [getReportState, selectLotsNomenclature, selectComEthReportProducts],
  (reportState, nomenclature, comEthProducts) =>
    R.pipe<
      [ReportState],
      AdminReportEntry[] | undefined,
      ReportEntry[] | undefined,
      ReportEntry[] | undefined
    >(
      (report) => sortReportByRef(report.report, nomenclature),
      (report) => addNomenclatureTitlesInReport(report, nomenclature),
      (report) => addComEthProductIndicatorsInReport(report, comEthProducts)
    )(reportState)
);
