import rhumbBearing from '@turf/rhumb-bearing';
import { Position } from '@turf/helpers/dist/js/lib/geojson';
import { Angle } from '../../domain/facades/FacadeSpecification';
import { roundWith2Decimal } from '../../../../utils/round/roundWith2Decimal';
import { bearingToAzimuth } from '@turf/turf';

export const getAngle = (point1: Position, point2: Position): Angle => {
  return roundWith2Decimal(bearingToAzimuth(rhumbBearing(point1, point2)));
  // number - bearing from north in decimal degrees, between -180 and 180 degrees from north (positive clockwise)
  // https://en.wikipedia.org/wiki/Decimal_degrees
  // bearingToAzimuth : Converts any bearing angle from the north line direction (positive clockwise)
  // and returns an angle between 0-360 degrees (positive clockwise), 0 being the north line
};
