import { createSelector, Selector } from 'reselect';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';
import { selectCurrentCaseProjection } from '../../projection/currentCase/projection.selector';
import { CarpentryOccultationsType } from '../../../../domain/specification/cases/CarpentryOccultationsType';

export const selectCurrentCaseCarpentryDayTimeOccultationsType: Selector<
  State,
  Maybe<CarpentryOccultationsType>
> = createSelector(
  [selectCurrentCaseProjection],
  (caseSpecification) => caseSpecification?.carpentryDayTimeOccultationsType
);
