import { CaseSpecification } from '../../../CaseSpecification';
import { getCaseOutsideWallsThickness } from '../walls/getCaseOutsideWallsThickness';
import { isTopLevel } from '../../../../levels/queries/is/isTopLevel';
import { getLevelLabel } from '../../../../../granulometry/levels/LevelGranulometry';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';

export const getCaseTopLevelsOutsideWallsSurfaceFromLevelsGeometryDetails = (
  caseSpecification: CaseSpecification
): string => {
  let details: string[] = [];

  const outsideWallsThickness = getCaseOutsideWallsThickness(caseSpecification, true);

  details = [
    ...details,
    [...caseSpecification.levels]
      .reverse()
      .reduce((acc, levelSpecification) => {
        if (isTopLevel(levelSpecification) && levelSpecification.geometry) {
          return [
            ...acc,
            '' +
              getLevelLabel(levelSpecification.level) +
              ' : ' +
              levelSpecification.geometry.properties.facades
                .reduce(
                  (acc, facade) => [
                    ...acc,
                    'F' +
                      facade.index +
                      ' : ' +
                      roundWith2Decimal(
                        Math.max(0, facade.distance || 0 - outsideWallsThickness) *
                          outsideWallsThickness
                      ) +
                      ' m\u00B2'
                  ],
                  [] as string[]
                )
                .join(' + ')
          ];
        } else {
          return acc;
        }
      }, [] as string[])
      .join('<br />+ ')
  ];

  return details.join('<br />');
};
