import { pipe } from 'fp-ts/function';
import { filterOrElse, mapLeft, ObservableEither, tryCatch } from 'fp-ts-rxjs/ObservableEither';
import { fromFetch } from 'rxjs/fetch';
import { not } from 'fp-ts/Predicate';
import stringify from 'json-stringify-safe';
import { ApiServerError } from './errors/server.error';
import { ApiAuthError } from './errors/auth.error';
import { ApiRedirectError } from './errors/redirect.error';
import { ApiError } from './errors/apiError';

const endpoint = (path: string) => `${process.env.GB_ADMIN_URL}${path}`;

const buildHeaders = (
  userToken?: string,
  additionalHeaders: Record<string, string> = {}
): HeadersInit => {
  const headers: HeadersInit = {
    'Access-Control-Allow-Origin': '*',
    Accept: 'application/json',
    ...additionalHeaders
  };
  if (userToken) headers['X-CSRF-Token'] = userToken;
  return headers;
};

const isAccessDeniedResponse = ({ status }: Response) => status === 401 || status === 403;
const isServerErrorResponse = (response: Response) =>
  not(isAccessDeniedResponse)(response) && response.status >= 400;
const isRedirectResponse = (response: Response) =>
  not(isServerErrorResponse)(response) && response.status >= 300;

const fetchObservable = (
  path: string,
  method: string,
  userToken?: string,
  body?: object,
  additionalHeaders: Record<string, string> = {}
): ObservableEither<Error, Response> =>
  pipe(
    fromFetch(endpoint(path), {
      method,
      headers: buildHeaders(userToken, additionalHeaders),
      mode: 'cors',
      credentials: 'include',
      body: body ? stringify(body, null, 2) : undefined
    }),
    tryCatch,
    mapLeft(
      (error: unknown): Error =>
        error instanceof Error ? error : new ApiError(JSON.stringify(error, null, 2), path)
    ),
    filterOrElse<Error, Response>(not(isAccessDeniedResponse), ApiAuthError.fromResponse(path)),
    filterOrElse<Error, Response>(not(isServerErrorResponse), ApiServerError.fromResponse(path)),
    filterOrElse<Error, Response>(not(isRedirectResponse), ApiRedirectError.fromResponse(path))
  );

export const getObservable = (path: string, userToken?: string) =>
  fetchObservable(path, 'GET', userToken);

const fetchAndHandle = async (
  path: string,
  method: string,
  userToken?: string,
  body?: object,
  additionalHeaders: Record<string, string> = {}
): Promise<Response> => {
  const response = await fetch(endpoint(path), {
    method,
    headers: buildHeaders(userToken, additionalHeaders),
    mode: 'cors',
    credentials: 'include',
    body: body ? stringify(body, null, 2) : undefined
  });

  if (isAccessDeniedResponse(response))
    throw new ApiAuthError(path, response.status, response.statusText);
  if (isServerErrorResponse(response))
    throw new ApiServerError(path, response.status, response.statusText);
  if (isRedirectResponse(response))
    throw new ApiRedirectError(
      path,
      response.status,
      response.statusText,
      response.headers.get('location') || 'other URL'
    );

  return response;
};

export const get = (path: string, userToken?: string) => fetchAndHandle(path, 'GET', userToken);

export const post = (path: string, userToken: string, body: object): Promise<Response> =>
  fetchAndHandle(path, 'POST', userToken, body, { 'Content-Type': 'application/json' });

export const put = (path: string, userToken: string, body: object): Promise<Response> =>
  fetchAndHandle(path, 'PUT', userToken, body, { 'Content-Type': 'application/json' });

export const patch = (path: string, userToken: string, body: object): Promise<Response> =>
  fetchAndHandle(path, 'PATCH', userToken, body, {
    'Content-Type': 'application/merge-patch+json'
  });
