import { uniqId } from '../../infra/uniqId';
import { Surface } from '../specification/Surface';
import { createBuildingSpecification } from '../specification/buildings/queries/createBuildingSpecification';
import {
  CURRENT_MIGRATION_VERSION,
  DEFAULT_PROJECT_CBS,
  DEFAULT_PROJECT_SURFACE_INPUT_MODE, RE2020_THRESHOLD,
  UnsavedProject
} from './Project';

export const createProject = (): UnsavedProject => ({
  name: '',
  surfaceInputMode: DEFAULT_PROJECT_SURFACE_INPUT_MODE,
  cuttedBuiltSurface: undefined,
  projectedCuttedBuiltSurface: new Surface(DEFAULT_PROJECT_CBS),
  buildings: [
    createBuildingSpecification({
      projectSurfaceInputMode: DEFAULT_PROJECT_SURFACE_INPUT_MODE
    })
  ],
  street: undefined,
  postalCode: undefined,
  city: undefined,
  country: undefined,
  longitude: undefined,
  latitude: undefined,
  revision: uniqId(),
  migrationVersion: CURRENT_MIGRATION_VERSION,
  reportRevision: '',
  customPriceListId: 'none',
  RE2020Threshold: RE2020_THRESHOLD
});
