import * as React from 'react';
import { useTranslation } from 'react-i18next';

export const Column3 = () => {
  const { t } = useTranslation();
  return (
    <div className="column-3">
      <div className="cell">
        <label>{t('Total')}</label>
      </div>
    </div>
  );
};
