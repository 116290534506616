import { ComEthJson } from './makeComEthJson';

export const postComEthToCstbApi = (comEthJson: ComEthJson): Promise<string> =>
  fetch(process.env.GB_CSTB_API_URL as RequestInfo, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    mode: 'cors',
    body: JSON.stringify(comEthJson)
  }).then((response) => response.json().then((data) => data.task_id));
