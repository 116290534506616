import classNames from 'classnames';
import * as React from 'react';
import { ReactNode } from 'react';

interface FormGroupProps {
  children: ReactNode | ReactNode[];
  className?: string;
  horizontal?: boolean;
  noFlex?: boolean;
  spaceBetween?: boolean;
  wrap?: boolean;
  flexEnd?: boolean;
  style?: React.CSSProperties;
}

export const FormGroup = ({
  children,
  className,
  horizontal,
  noFlex,
  spaceBetween,
  wrap,
  flexEnd,
  style
}: FormGroupProps) => (
  <div
    className={classNames(
      'form-group',
      {
        'form-group--horizontal': horizontal,
        'no-flex': noFlex,
        'form-group--space-between': spaceBetween,
        'form-group--wrap': wrap,
        'form-group--flex-end': flexEnd
      },
      className
    )}
    style={style}>
    {children}
  </div>
);
