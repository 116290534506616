import { createSelector, Selector } from 'reselect';
import { selectRealBuiltSurfacesByCages } from '../../project/realBuiltSurfacesByCages.selector';
import { selectCurrentCaseIdFromRoute } from '../../navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';
import { Surface } from '../../../../domain/specification/Surface';

export const selectCurrentCaseRealBuiltSurface: Selector<State, Maybe<Surface>> = createSelector(
  [selectRealBuiltSurfacesByCages, selectCurrentCaseIdFromRoute],
  (casesSurfaces, caseId) => (casesSurfaces && caseId ? casesSurfaces[caseId] : undefined)
);
