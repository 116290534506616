import { EMPTY, Observable, of } from 'rxjs';
import { Action } from 'redux';
import { startWith, switchMap, withLatestFrom } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { pipe } from 'fp-ts/function';
import * as OE from 'fp-ts-rxjs/ObservableEither';
import { addBreadcrumb, BREADCRUMB_CATEGORY, captureException } from '../../infra/sentry';
import { ExistingProject, isNewProject } from '../../domain/project/Project';
import { Report } from '../../domain/report/ReportEntry';
import { generateReport } from '../../api/v1/generateReport';
import { State } from '../reducers';
import { selectUserId } from '../selectors/user/userId.selector';
import { selectUserToken } from '../selectors/user/userToken.selector';
import { selectGranulometry } from '../selectors/granulometry/current.selector';
import {
  reportFailed,
  ReportFailedAction,
  reportFetched,
  ReportFetchedAction,
  reportFetching,
  ReportFetchingAction,
  requestReport
} from '../actions/report.actions';
import { selectProject } from '../selectors/project';

type ActionWithState = [Action, State];

export const computeProjectEpic: Epic<Action, Action, State> = (
  actions$,
  state$
): Observable<ReportFetchedAction | ReportFailedAction | ReportFetchingAction> =>
  actions$.pipe(
    ofType(requestReport.type),
    withLatestFrom(state$),
    switchMap(([, state]: ActionWithState) => {
      const project = selectProject(state);
      const granulometry = selectGranulometry(state);
      const userId = selectUserId(state);
      const userToken = selectUserToken(state);

      if (!project || isNewProject(project) || !granulometry) {
        return EMPTY;
      } else {
        return pipe(
          generateReport(project as ExistingProject, granulometry, userId, userToken, false),
          OE.fromTaskEither,
          OE.map((report: Report) => {
            addBreadcrumb(
              `Project ${(project as ExistingProject).id} computed`,
              'log',
              BREADCRUMB_CATEGORY.API
            );

            return reportFetched({
              report,
              projectRevision: (project as ExistingProject).revision
            });
          }),
          OE.getOrElse<Error, ReportFailedAction | ReportFetchedAction>((error) => {
            captureException(error);
            console.error(error);
            alert('Erreur lors du calcul du projet.');
            return of(reportFailed(error));
          }),
          startWith(reportFetching())
        );
      }
    })
  );
