import { isTopLevel } from '../../../../granulometry/levels/queries/is/isTopLevel';
import { getLevelMaxSurfaceForSale } from '../../../../granulometry/levels/queries/topLevels/surfaces/surfaceForSale/getLevelMaxSurfaceForSale';
import { CaseGranulometry } from '../../../../granulometry/cases/CaseGranulometry';
import { getCaseLevels } from '../../../../granulometry/cases/queries/levels/getCaseLevels';
import { MaxSurfaceForSaleByLevel } from '../../CaseProjection';

export const getCaseMaxSurfaceForSaleByTopLevels = (
  caseGranulometry: CaseGranulometry
): MaxSurfaceForSaleByLevel[] =>
  getCaseLevels(caseGranulometry).reduce(
    (acc, levelGranulometry) =>
      isTopLevel(caseGranulometry, levelGranulometry)
        ? [
            ...acc,
            {
              levelIndex: levelGranulometry.level,
              maxSurfaceForSale: getLevelMaxSurfaceForSale(caseGranulometry, levelGranulometry)
            }
          ]
        : acc,
    [] as MaxSurfaceForSaleByLevel[]
  );
