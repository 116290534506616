import { DerivedDataObject, Filter } from 'react-table';

export const fitterRowsAndPivotsThroughInput =
  (subRowsIndex: number[]) => (filter: Filter, row: DerivedDataObject) => {
    if (
      row._subRows !== undefined &&
      (typeof row[filter.id] === 'string' || typeof row[filter.id] === 'number') &&
      (row[filter.id] === parseInt(filter.value) ||
        (typeof row[filter.id] === 'number' && row[filter.id].toFixed(0) === filter.value))
    ) {
      let subRowsIndexList: number[] = [];
      row._subRows.forEach((subRow: DerivedDataObject['_subRows']) => {
        if (subRow._subRows !== undefined) {
          subRow._subRows.forEach((subSubRow: DerivedDataObject['_subRows']) => {
            if (subSubRow._subRows !== undefined) {
              subSubRow._subRows.forEach((subSubSubRow: DerivedDataObject['_subRows']) => {
                if (subSubSubRow._subRows !== undefined) {
                  subSubSubRow._subRows.forEach(
                    (subSubSubSubRow: DerivedDataObject['_subRows']) => {
                      if (subSubSubSubRow._subRows !== undefined) {
                        subSubSubSubRow._subRows.forEach(
                          (subSubSubSubSubRow: DerivedDataObject['_subRows']) => {
                            subRowsIndexList = [...subRowsIndexList, subSubSubSubSubRow._index];
                          }
                        );
                      } else {
                        subRowsIndexList = [...subRowsIndexList, subSubSubSubRow._index];
                      }
                    }
                  );
                } else {
                  subRowsIndexList = [...subRowsIndexList, subSubSubRow._index];
                }
              });
            } else {
              subRowsIndexList = [...subRowsIndexList, subSubRow._index];
            }
          });
        } else {
          subRowsIndexList = [...subRowsIndexList, subRow._index];
        }
      });
      subRowsIndex = subRowsIndexList;
    }
    if (row._subRows === undefined) {
      return (
        (typeof row._original[filter.id] === 'string' ||
          typeof row._original[filter.id] === 'number') &&
        (row._original[filter.id] === parseInt(filter.value) ||
          row._original[filter.id].toFixed(0) === filter.value ||
          subRowsIndex.includes(row._index))
      );
    } else {
      return true;
    }
  };
