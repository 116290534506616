import { getCaseDataLevelsForcedRealBuiltSurfaces } from '../../../../../../specification/cases/queries/levels/surfaces/getCaseDataLevelsForcedRealBuiltSurfaces';
import { getCaseDataProjectedTopLevelCount } from '../../../../../../specification/cases/queries/levels/counts/getCaseDataProjectedTopLevelCount';
import { getCaseDataGroundLevelForcedSurface } from '../../../../../../specification/cases/queries/levels/surfaces/getCaseDataGroundLevelForcedSurface';
import {
  LevelGranulometry,
  LevelGranulometryFullFilled,
  LevelGranulometryInitialEntries
} from '../../../../LevelGranulometry';
import { isGroundLevel } from '../../../is/isGroundLevel';
import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { getTopLevelGrossFloorSurfaceEff } from '../grossFloorSurfaces/getTopLevelGrossFloorSurfaceEff';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { isTopLevel } from '../../../is/isTopLevel';
import { hasCaseGranulometryGroundLevelDrawnGeometry } from '../../../../../cases/queries/has/hasCaseGranulometryGroundLevelDrawnGeometry';
import { getTopLevelRealOutsideWallsSurface } from '../../walls/realWalls/getTopLevelRealOutsideWallsSurface';
import { TopLevelSpecification } from '../../../../../../specification/levels/TopLevelSpecification';
import { CaseProjection } from '../../../../../../projection/cases/CaseProjection';
import { getLevelHoppersSurface } from '../../../../../../specification/cases/queries/levels/surfaces/getLevelHoppersSurface';
import { getLevelOutsideWallsSurfaceFromLevelsGeometry } from '../../../../../../specification/cases/queries/levels/surfaces/getLevelOutsideWallsSurfaceFromLevelsGeometry';
import { getLevelSpecificationFromCaseProjectionWithGivenLevelIndex } from '../../../../../../projection/cases/queries/levels/getLevelSpecificationFromCaseProjectionWithGivenLevelIndex';
import { getLevelSpecificationFromCaseSpecificationWithGivenLevelIndex } from '../../../../../../specification/cases/queries/get/levels/getLevelSpecificationFromCaseSpecificationWithGivenLevelIndex';
import { getTopLevelHoppersSurface } from '../getTopLevelHoppersSurface';

export const getTopLevelRealBuiltSurface = (
  levelGranulometry:
    | LevelGranulometryInitialEntries
    | TopLevelSpecification
    | LevelGranulometryFullFilled
    | LevelGranulometry,
  caseProjection: CaseProjection,
  caseGranulometry?: CaseGranulometry
): number => {
  const levelSpecification = getLevelSpecificationFromCaseSpecificationWithGivenLevelIndex(
    caseProjection,
    levelGranulometry.level
  );
  const levelProjection = getLevelSpecificationFromCaseProjectionWithGivenLevelIndex(
    caseProjection,
    levelGranulometry.level
  );
  // 1 : If there is a manually forced real built surface :
  if (levelSpecification !== undefined && levelSpecification.realBuiltSurface) {
    return levelSpecification.realBuiltSurface;
  }
  // 2 : If the level has a filled geometry
  else if (levelProjection !== undefined && levelProjection.geometry?.properties.area) {
    // TODO : Remove the "?" of geometry?
    return (
      levelProjection.geometry.properties.area -
      getLevelHoppersSurface(caseProjection, levelProjection) -
      getLevelOutsideWallsSurfaceFromLevelsGeometry(caseProjection, levelProjection, true)
    );
  }
  // 3 : If there is a filled projectedGfsEffRelativeTopLevelsRbs (see : addGfsEffRelativeTopLevelsRbs)
  else if (caseProjection.projectedGfsEffRelativeTopLevelsRbs !== undefined) {
    return (
      caseProjection.projectedGfsEffRelativeTopLevelsRbs.find(
        (l) => l.level === levelGranulometry.level
      )?.realBuiltSurface || 0
    );
  }
  // 4 : Otherwise :
  else {
    let levelRealBuiltSurface;
    const caseTopLevelCount = getCaseDataProjectedTopLevelCount(caseProjection);
    const levelsForcedRealBuiltSurfaces = getCaseDataLevelsForcedRealBuiltSurfaces(caseProjection);
    const unforcedLevelCount = caseTopLevelCount - levelsForcedRealBuiltSurfaces.count;

    const geometryMustBeTreated =
      !!caseGranulometry &&
      !!levelGranulometry &&
      hasCaseGranulometryGroundLevelDrawnGeometry(caseGranulometry) &&
      isTopLevel(caseGranulometry, levelGranulometry as LevelGranulometry);
    // 4a : If there is a filled geometry
    if (geometryMustBeTreated) {
      const topLevelGrossFloorSurfaceEff = getTopLevelGrossFloorSurfaceEff(
        caseGranulometry,
        levelGranulometry as LevelGranulometryFullFilled
      );
      levelRealBuiltSurface =
        topLevelGrossFloorSurfaceEff -
        getTopLevelRealOutsideWallsSurface(
          caseGranulometry,
          levelGranulometry as LevelGranulometryFullFilled
        ) -
        getTopLevelHoppersSurface(
          caseGranulometry,
          levelGranulometry as LevelGranulometryFullFilled,
          true
        );
    }
    // 4b : Otherwise :
    else {
      const caseRealBuiltSurface = caseProjection.projectedRealBuiltSurface.value;
      // Calculate the redistribued real built surface relatively to the forced ones
      levelRealBuiltSurface =
        (caseRealBuiltSurface - levelsForcedRealBuiltSurfaces.totalSurface) / unforcedLevelCount;
    }

    // 4a or 4b : ramp surface distribution
    if (
      caseProjection.builtInRamp && // If a ramp is built in the case
      caseProjection.projectedBasementLevelsCount !== 0 && // there is at least one basement level
      !getCaseDataGroundLevelForcedSurface(caseProjection) // and the ground level real built surface is not forced :
    ) {
      // Get the ramp surface
      const rampLength = getCasePropertyConvertedValue(caseProjection, 'rampLength') as number;
      const rampWidth = getCasePropertyConvertedValue(caseProjection, 'rampWidth') as number;
      const rampSurface = rampLength * rampWidth;
      if (isGroundLevel(levelGranulometry)) {
        levelRealBuiltSurface -= rampSurface;
        if (!geometryMustBeTreated) {
          levelRealBuiltSurface += rampSurface / unforcedLevelCount;
        }
      } else {
        // For any top level but the ground level :
        if (!geometryMustBeTreated) {
          levelRealBuiltSurface += rampSurface / unforcedLevelCount;
        }
      }
    }

    return Math.max(levelRealBuiltSurface, 0);
  }
};
