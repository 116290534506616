import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { Card } from '../../../../ui/Card';
import { useParams } from 'react-router';
import { CarpentryTypes } from './carpentrySpecifications/CarpentryTypes';
import {
  CarpentryOccultations,
  carpentryOccultationsTypeOptions
} from './carpentrySpecifications/CarpentryOccultations';
import {
  CarpentryType,
  isCarpentryTypeUsePVC
} from '../../../../../domain/specification/cases/CarpentryType';
import {
  CarpentryOccultationsType,
  isCarpentryOccultationsTypeUsePVC
} from '../../../../../domain/specification/cases/CarpentryOccultationsType';
import { useSelector } from 'react-redux';
import { selectCurrentCaseCarpentryType } from '../../../../../store/selectors/specification/currentCase/carpentryType.selector';
import { selectCurrentCaseCarpentryDayTimeOccultationsType } from '../../../../../store/selectors/specification/currentCase/carpentryDayTimeOccultationsType.selector';
import { selectCurrentCaseCarpentryNightTimeOccultationsType } from '../../../../../store/selectors/specification/currentCase/carpentryNightTimeOccultationsType.selector';
import { Option } from '../../../../ui/Select';
import { CaseGranulometryEditSuperstructureRouteParams } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditSuperstructureRoute';

export const CarpentrySpecifications = () => {
  const { caseId } = useParams<CaseGranulometryEditSuperstructureRouteParams>();
  const carpentryType = useSelector(selectCurrentCaseCarpentryType) as CarpentryType;

  const { t } = useTranslation();

  const [isSelectedCarpentryTypeUsePVC, setIsSelectedCarpentryTypeUsePVC] = React.useState<boolean>(
    isCarpentryTypeUsePVC(carpentryType)
  );

  // Day time occultations
  const carpentryDayTimeOccultationsType = useSelector(
    selectCurrentCaseCarpentryDayTimeOccultationsType
  ) as CarpentryOccultationsType;
  const [
    selectedCarpentryDayTimeOccultationTypeOption,
    setSelectedCarpentryDayTimeOccultationTypeOption
  ] = React.useState<Option | undefined>(
    carpentryOccultationsTypeOptions.find(
      (option) => option.value === carpentryDayTimeOccultationsType
    )
  );
  const [isDayTimeMonoblocDisplayed, setIsDayTimeMonoblocDisplayed] = React.useState<boolean>(
    isSelectedCarpentryTypeUsePVC &&
      !!selectedCarpentryDayTimeOccultationTypeOption &&
      isCarpentryOccultationsTypeUsePVC(
        selectedCarpentryDayTimeOccultationTypeOption.value as CarpentryOccultationsType
      )
  );

  // Night time occultations
  const carpentryNightTimeOccultationsType = useSelector(
    selectCurrentCaseCarpentryNightTimeOccultationsType
  ) as CarpentryOccultationsType;
  const [
    selectedCarpentryNightTimeOccultationTypeOption,
    setSelectedCarpentryNightTimeOccultationTypeOption
  ] = React.useState<Option | undefined>(
    carpentryOccultationsTypeOptions.find(
      (option) => option.value === carpentryNightTimeOccultationsType
    )
  );
  const [isNightTimeMonoblocDisplayed, setIsNightTimeMonoblocDisplayed] = React.useState<boolean>(
    isSelectedCarpentryTypeUsePVC &&
      !!selectedCarpentryNightTimeOccultationTypeOption &&
      isCarpentryOccultationsTypeUsePVC(
        selectedCarpentryNightTimeOccultationTypeOption.value as CarpentryOccultationsType
      )
  );

  if (process.env.GB_FEAT_CARPENTRY_TYPES === 'false' || !caseId) return null;

  return (
    <Card>
      <SpecialLabel label={t('carpentrySpecifications.specialLabel')} />
      <CarpentryTypes
        caseId={caseId}
        setIsSelectedCarpentryTypeUsePVC={setIsSelectedCarpentryTypeUsePVC}
        selectedCarpentryDayTimeOccultationTypeOption={
          selectedCarpentryDayTimeOccultationTypeOption
        }
        setIsDayTimeMonoblocDisplayed={setIsDayTimeMonoblocDisplayed}
        selectedCarpentryNightTimeOccultationTypeOption={
          selectedCarpentryNightTimeOccultationTypeOption
        }
        setIsNightTimeMonoblocDisplayed={setIsNightTimeMonoblocDisplayed}
      />
      <CarpentryOccultations
        caseId={caseId}
        isSelectedCarpentryTypeUsePVC={isSelectedCarpentryTypeUsePVC}
        selectedCarpentryDayTimeOccultationTypeOption={
          selectedCarpentryDayTimeOccultationTypeOption
        }
        setSelectedCarpentryDayTimeOccultationTypeOption={
          setSelectedCarpentryDayTimeOccultationTypeOption
        }
        isDayTimeMonoblocDisplayed={isDayTimeMonoblocDisplayed}
        setIsDayTimeMonoblocDisplayed={setIsDayTimeMonoblocDisplayed}
        selectedCarpentryNightTimeOccultationTypeOption={
          selectedCarpentryNightTimeOccultationTypeOption
        }
        setSelectedCarpentryNightTimeOccultationTypeOption={
          setSelectedCarpentryNightTimeOccultationTypeOption
        }
        isNightTimeMonoblocDisplayed={isNightTimeMonoblocDisplayed}
        setIsNightTimeMonoblocDisplayed={setIsNightTimeMonoblocDisplayed}
      />
    </Card>
  );
};
