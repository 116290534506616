import mapboxgl from 'mapbox-gl';
import { LoadImageOptions } from '../map/map.repository';
import { DrawHelperServices } from './drawHelper.services';

export const drawHelperEvents = (map: mapboxgl.Map, drawHelperServices: DrawHelperServices) => {
  map.on('drawHelper.add', (options) => drawHelperServices.create(options));
  map.on('drawHelper.validate', (options: { featureId: string }) =>
    drawHelperServices.validateCreation(options.featureId)
  );
  map.on('drawHelper.remove', (options: { layerName: string }) =>
    drawHelperServices.delete(options.layerName)
  );
  map.on('drawHelper.hide', () => drawHelperServices.hideAll());
};

export const fireDrawHelperEvents = (map: mapboxgl.Map) => ({
  addDrawHelper: (payload: LoadImageOptions) => {
    map.fire('drawHelper.add', payload);
  },
  validate: (payload: { featureId: string }) => {
    map.fire('drawHelper.validate', payload);
  },
  remove: (payload: { layerName: string }) => {
    map.fire('drawHelper.remove', payload);
  },
  hide: () => {
    map.fire('drawHelper.hide');
  }
});
