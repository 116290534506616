import { CaseGranulometry } from '../../../../domain/granulometry/cases/CaseGranulometry';
import { getCaseAverageInsulationPosition } from '../../../../domain/granulometry/cases/queries/get/getCaseAverageInsulationPosition';
import { hasCaseCarpentryDayAndNightTimeOccultations } from '../../../../domain/granulometry/cases/queries/has/hasCaseCarpentryDayAndNightTimeOccultations';
import {
  CarpentryType,
  CarpentryTypeName
} from '../../../../domain/specification/cases/CarpentryType';

export interface ComEthJsonFeatureSuperstructureFacade {
  window_share: number | null; // TODO ANTOINE /!\ IMPORTANT: (ex: 0.15) pourcentage de surface vitrée toute façade confondue (moyenne) (si non connue : null)
  balcony: boolean; // If majority with balcony = true
  window_and_occultations: ComEthJsonFeatureSuperstructureFacadeWindowsAndOccultations;
  exterior_wall: ComEthJsonFeatureSuperstructureFacadeExteriorWall;
}

type ComEthJsonWindowFrameMaterial = 'pvc' | 'steel' | 'wood' | 'aluminium' | 'mixt' | null;

export interface ComEthJsonFeatureSuperstructureFacadeWindowsAndOccultations {
  id_typy_window: string | null; // ID TyPy (set null for now)
  glazing_type: 'single_glazing' | 'double_glazing' | 'triple_glazing' | null; // TODO: Antoine à saisir /!\ IMPORTANT
  exterior_occultation: boolean | null;
  window_frame_material: ComEthJsonWindowFrameMaterial;
  window_distribution_per_orientation: { south: number; north: number; east: number; west: number }; // By default { south: 0.4, north: 0.2, east: 0.2, west: 0.2 } // TODO AVEC VOS VALEURS
}

export interface ComEthJsonFeatureSuperstructureFacadeExteriorWall {
  id_typy_exterior_wall: string | null; // ID TyPy (set null for now)
  average_U_value_wall: number | null; // TODO: Antoine à remplir (c'est mieux de votre coté: 0.16?)
  insulation_position_wall: 'NI' | 'ITI' | 'ETI' | 'DTI' | null; // NI = Pas d'isolatio ; ITI = Intérieure (ITI) ; ETI = Extérieure (ITE) ; DTI = Répartie (ITR)
  inertia_level_wall: 'low' | 'mid' | 'high' | null; // 'mid' by default // TODO: Antoine à remplir (a discuter, par defaut on peut mettre mid)
  exterior_wall_constructive_system: 'bloc en béton de granulat' | '...' | null; // laisser null (GoBuild : concrete + masonry + wood rate)
}

const getWindowFrameMaterialFromCaseCarpentryType = (
  caseCarpentryType: CarpentryType
): ComEthJsonWindowFrameMaterial => {
  if (
    caseCarpentryType === CarpentryTypeName.PVC ||
    caseCarpentryType === CarpentryTypeName.PlaxedPVC
  ) {
    return 'pvc';
  } else if (caseCarpentryType === CarpentryTypeName.Aluminium) {
    return 'aluminium';
  } else if (caseCarpentryType === CarpentryTypeName.AluminiumAndWood) {
    return 'mixt';
  } else if (caseCarpentryType === CarpentryTypeName.Wood) {
    return 'wood';
  } else {
    return null;
  }
};

export const getComEthJsonFeatureSuperstructureFacade = (
  caseGranulometry: CaseGranulometry
): ComEthJsonFeatureSuperstructureFacade => ({
  window_share: null,
  balcony: false,
  window_and_occultations: {
    id_typy_window: null,
    glazing_type: 'double_glazing',
    exterior_occultation: hasCaseCarpentryDayAndNightTimeOccultations(caseGranulometry),
    window_frame_material: getWindowFrameMaterialFromCaseCarpentryType(
      caseGranulometry.carpentryType
    ),
    window_distribution_per_orientation: { south: 0.4, north: 0.2, east: 0.2, west: 0.2 }
  },
  exterior_wall: {
    id_typy_exterior_wall: null,
    average_U_value_wall: null,
    insulation_position_wall: getCaseAverageInsulationPosition(caseGranulometry),
    inertia_level_wall: 'mid',
    exterior_wall_constructive_system: null
  }
});
