import { CaseSpecification } from '../../../CaseSpecification';
import { CaseProjection } from '../../../../../projection/cases/CaseProjection';
import { CaseGranulometry } from '../../../../../granulometry/cases/CaseGranulometry';
import { getCaseProperty } from './getCaseProperty';
import { getPropertyValue } from './getPropertyValue';

export const getCasePropertyValue = (
  anyTypeOfCase: CaseSpecification | CaseProjection | CaseGranulometry,
  propertyName: string
): number | undefined => {
  const property = getCaseProperty(anyTypeOfCase, propertyName);
  return property !== undefined ? getPropertyValue(property) : undefined;
};
