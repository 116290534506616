import { createSelector, Selector } from 'reselect';
import * as R from 'ramda';
import { Maybe } from '../../../utils/Maybe';
import { State } from '../../../store/reducers';
import { selectFirstCase } from './firstCase.selector';
import { ifDefined } from '../../../utils/ifDefined';
import { CaseId } from '../../../domain/specification/cases/CaseSpecification';

export const selectFirstCaseId: Selector<State, Maybe<CaseId>> = createSelector(
  [selectFirstCase],
  ifDefined(R.prop('id'))
);
