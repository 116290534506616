import { createSelector, Selector } from 'reselect';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';
import { WoodEnvelopeType } from '../../../../domain/specification/cases/WoodEnvelopeType';
import { selectCurrentCaseProjection } from '../../../../store/selectors/projection/currentCase/projection.selector';

export const selectCurrentCaseWoodEnvelopeType: Selector<
  State,
  Maybe<WoodEnvelopeType>
> = createSelector(
  [selectCurrentCaseProjection],
  (caseProjection) => caseProjection?.woodEnvelopeType
);
