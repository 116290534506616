import { ComEthReport } from '../../../api/cstb/comEth/comEthReport/ComEthReport';
import { createSelector, Selector } from 'reselect';
import { State } from '../../reducers';
import { selectComEthReport } from './comEthReport.selector';
import { Maybe } from '../../../utils/Maybe';

export interface ComEthProduct {
  id: string;
  lifetime: number | null;
  ic: number | null;
  fdesId: string | null;
  stockC: number | null;
  unit: string | null;
}

const selectComEthProducts = (comEthReport: Maybe<ComEthReport>): ComEthProduct[] =>
  comEthReport
    ? comEthReport.Children?.reduce(
        (accB, building) => [
          ...accB,
          ...building.Children.reduce(
            (accC, cage) => [
              ...accC,
              ...cage.ComponentContributor.Children.reduce(
                (accL, lot) => [
                  ...accL,
                  ...lot.Children.reduce(
                    (accSL, subLot) => [
                      ...accSL,
                      ...subLot.Children.reduce(
                        (accP, product) => [
                          ...accP,
                          {
                            id: product.gobuildProductInstanceId,
                            lifetime: product.LifeTime,
                            unit: product.unit,
                            fdesId: product.IdEDP,
                            stockC: product.StockC_SurfaceReference,
                            ic: product.CO2DynamicImpacts.Ic
                          }
                        ],
                        [] as ComEthProduct[]
                      )
                    ],
                    [] as ComEthProduct[]
                  )
                ],
                [] as ComEthProduct[]
              )
            ],
            [] as ComEthProduct[]
          )
        ],
        [] as ComEthProduct[]
      )
    : [];

export const selectComEthReportProducts: Selector<State, ComEthProduct[]> = createSelector(
  [selectComEthReport],
  (comEthReport) => selectComEthProducts(comEthReport)
);
