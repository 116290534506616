import * as React from 'react';
import { FallbackRender } from '@sentry/react/dist/errorboundary';
import { Button } from './components/ui/Button';

export const DefaultFallback: FallbackRender = ({ error, componentStack, resetError }) => (
  <div className="defaultFallback">
    <h1>Une erreur est survenue</h1>
    <p>
      Veuillez nous excuser pour la gène occasionné. Une notification à été automatiquement envoyée
      à notre équipe de développement afin de solutionner ce problème.
    </p>
    <code>
      <b>{error.toString()}</b>
      {componentStack}
    </code>
    {resetError && (
      <Button
        content="Relancer"
        handleClick={() => resetError()}
        appearance="outline"
        color="white"
      />
    )}
  </div>
);
