import * as React from 'react';
import { FilterRender } from 'react-table';

export const createFilter =
  (options: (number | string)[]): FilterRender =>
  ({ filter, onChange }) =>
    (
      <select
        onChange={(event) => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter?.value || ''}>
        <option value="" />
        {options.map((group: number | string) => {
          return (
            <option key={group} value={group}>
              {group}
            </option>
          );
        })}
      </select>
    );
