import { matchesPath } from '../utils/matchesPath';
import { RouteWithMakePath } from '../RouteWithMakePath';

export type AppMenuEditUserRouteParams = { projectId: string };
export const appMenuEditUserRoute: RouteWithMakePath<AppMenuEditUserRouteParams> = {
  path: `/project/:projectId/appMenu/editUser`,
  makePath: ({ projectId }) => `/project/${projectId}/appMenu/editUser`,
  matchesPath: matchesPath<AppMenuEditUserRouteParams>(/\/project\/([^/]+)\/appMenu\/editUser/, {
    projectId: 1
  })
};
