import { OTHER_RBS_SECTIONS_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { SectionWithTechnicalSheaths } from '../SectionWithTechnicalSheaths';
import { TechnicalPremiseSectionName } from './TechnicalPremiseSection';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';

interface ElectricalTransformerSectionProps {
  surface?: number;
  exposureRate?: number;
  width?: number;
}

export interface ElectricalTransformerSection
  extends Section,
    SectionDrawn,
    SectionWithExposureRate,
    SectionWithTechnicalSheaths {
  name: TechnicalPremiseSectionName.ElectricalTransformerSection;
  width?: number;
  defaultWidth: number;
  minWidth: number;
  maxWidth: number;
  defaultSurface: number;
  minSurface: number;
  maxSurface: number;
}

export const ELECTRICAL_TRANSFORMER_SECTION_DEFAULT_SURFACE = 20;
export const ELECTRICAL_TRANSFORMER_SECTION_MIN_SURFACE = 5;
export const ELECTRICAL_TRANSFORMER_SECTION_MAX_SURFACE = 50;
export const ELECTRICAL_TRANSFORMER_SECTION_DEFAULT_EXPOSURE_RATE = 1;
export const ELECTRICAL_TRANSFORMER_SECTION_DEFAULT_WITH = 5;
export const ELECTRICAL_TRANSFORMER_SECTION_MIN_WITH = 5;
export const ELECTRICAL_TRANSFORMER_SECTION_MAX_WITH = 5;
export const ELECTRICAL_TRANSFORMER_SECTION_ICON = {
  type: 'object',
  iconName: 'electricalTransformer',
  color: OTHER_RBS_SECTIONS_COLOR
} as IconObjectProps;

const electricalTransformerSection = ({
  surface,
  exposureRate,
  width
}: ElectricalTransformerSectionProps): ElectricalTransformerSection => ({
  // Section
  id: 'NOID',
  name: TechnicalPremiseSectionName.ElectricalTransformerSection,
  title: i18next.t('electricalTransformerSection.title'),
  type: 'others',
  displayedSurface: surface || ELECTRICAL_TRANSFORMER_SECTION_DEFAULT_SURFACE,
  // SectionDrawn
  surface: surface || ELECTRICAL_TRANSFORMER_SECTION_DEFAULT_SURFACE,
  color: OTHER_RBS_SECTIONS_COLOR,
  icon: ELECTRICAL_TRANSFORMER_SECTION_ICON,
  // SectionWithExposureRate
  exposureRate: exposureRate || ELECTRICAL_TRANSFORMER_SECTION_DEFAULT_EXPOSURE_RATE,
  defaultExposureRate: ELECTRICAL_TRANSFORMER_SECTION_DEFAULT_EXPOSURE_RATE,
  // SectionWithTechnicalSheaths
  technicalSheathCount: 0,
  // ElectricalTransformerSection
  width: width || undefined,
  defaultWidth: ELECTRICAL_TRANSFORMER_SECTION_DEFAULT_WITH,
  minWidth: ELECTRICAL_TRANSFORMER_SECTION_MIN_WITH,
  maxWidth: ELECTRICAL_TRANSFORMER_SECTION_MAX_WITH,
  defaultSurface: ELECTRICAL_TRANSFORMER_SECTION_DEFAULT_SURFACE,
  minSurface: ELECTRICAL_TRANSFORMER_SECTION_MIN_SURFACE,
  maxSurface: ELECTRICAL_TRANSFORMER_SECTION_MAX_SURFACE
});

export default electricalTransformerSection;

export const isSectionAElectricalTransformerSection = (
  section: Section
): section is ElectricalTransformerSection =>
  getSectionName(section) === TechnicalPremiseSectionName.ElectricalTransformerSection;

export const filterElectricalTransformerSections = (
  sections: Section[]
): ElectricalTransformerSection[] => sections.filter(isSectionAElectricalTransformerSection);
