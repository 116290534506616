import * as React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { selectCurrentlyEditedLevelSpecification } from '../../../../../store/selectors/navigation/toolbox/casesPanel/currentlyEditedLevelSpecification.selector';
import { Card } from '../../../../ui/Card';
import { selectCurrentCaseProjection } from '../../../../../store/selectors/projection/currentCase/projection.selector';
import { IconObject } from '../../../../ui/Icons/iconObject';
import { FormGroup } from '../../../../ui/FormGroup';
import { Input } from '../../../../ui/input/Input';
import { setCaseLevelSpecification } from '../../../../../store/actions/setCaseLevelSpecification.action';
import { Button } from '../../../../ui/Button';
import { secureInputChange } from '../../../../../utils/secureInputChange';
import { LevelSpecification } from '../../../../../domain/specification/levels/LevelSpecification';
import officeSection, {
  OfficeSection
} from '../../../../../domain/granulometry/sections/officeSection/officeSection';
import { getLevelOfficeSections } from '../../../../../domain/specification/levels/queries/get/getLevelOfficeSections';
import { selectCurrentLevelGranulometry } from '../../../../../store/selectors/granulometry/currentLevel/granulometry.selector';
import { LevelGranulometry } from '../../../../../domain/granulometry/levels/LevelGranulometry';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';

export const OfficeSections = () => {
  const currentlyEditedCase = useSelector(selectCurrentCaseProjection);
  const currentlyEditedLevel = useSelector(
    selectCurrentlyEditedLevelSpecification
  ) as LevelSpecification;
  const levelGranulometry = useSelector(selectCurrentLevelGranulometry) as LevelGranulometry;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [localCurrentLevelOffices, setLocalCurrentLevelOffices] = React.useState<OfficeSection[]>(
    getLevelOfficeSections(currentlyEditedLevel)
  );

  React.useEffect(() => {
    setLocalCurrentLevelOffices(getLevelOfficeSections(currentlyEditedLevel));
  }, [currentlyEditedLevel]);

  const handleOfficesSpecificationsChange = React.useCallback(
    (value: number | string | null, index: number, input: string) => {
      const updateOfficeInput = (office: OfficeSection, input: string): OfficeSection =>
        R.assoc(input, value === null ? undefined : value)(office) as unknown as OfficeSection;

      const newOffices = R.over(
        R.lensIndex<OfficeSection>(index),
        R.compose((office) => {
          if (input === 'exposureRate' && typeof value === 'number') {
            value = (secureInputChange(value, 0, 100) * 4) / 100;
          }
          if (input === 'surface') {
            office = updateOfficeInput(office, 'displayedSurface');
          }
          return updateOfficeInput(office, input);
        })
      )(localCurrentLevelOffices);

      if (currentlyEditedCase && currentlyEditedLevel) {
        setLocalCurrentLevelOffices(newOffices);
        dispatch(
          setCaseLevelSpecification(currentlyEditedCase.id, {
            ...currentlyEditedLevel,
            officeSections: newOffices ?? []
          })
        );
      }
    },
    [dispatch, currentlyEditedCase, currentlyEditedLevel]
  );

  const handleRemoveOffice = React.useCallback(
    (index: number) => {
      const newOffices = R.remove(index, 1, localCurrentLevelOffices);
      if (currentlyEditedCase && currentlyEditedLevel) {
        setLocalCurrentLevelOffices(newOffices);
        dispatch(
          setCaseLevelSpecification(currentlyEditedCase.id, {
            ...currentlyEditedLevel,
            officeSections: newOffices ?? []
          })
        );
      }
    },
    [dispatch, currentlyEditedCase, currentlyEditedLevel]
  );

  const handleDuplicateOffice = React.useCallback(
    (index: number) => {
      const newOffices = R.insert(
        index,
        R.view(R.lensIndex<OfficeSection>(index), localCurrentLevelOffices),
        localCurrentLevelOffices
      );
      if (currentlyEditedCase && currentlyEditedLevel) {
        setLocalCurrentLevelOffices(newOffices);
        dispatch(
          setCaseLevelSpecification(currentlyEditedCase.id, {
            ...currentlyEditedLevel,
            officeSections: newOffices ?? []
          })
        );
      }
    },
    [dispatch, currentlyEditedCase, currentlyEditedLevel]
  );

  const handleAddFirstOffice = React.useCallback(() => {
    const newOffices = [officeSection({})];
    if (currentlyEditedCase && currentlyEditedLevel) {
      setLocalCurrentLevelOffices(newOffices);
      dispatch(
        setCaseLevelSpecification(currentlyEditedCase.id, {
          ...currentlyEditedLevel,
          officeSections: newOffices
        })
      );
    }
  }, [dispatch, currentlyEditedCase, currentlyEditedLevel]);

  if (!currentlyEditedCase || !currentlyEditedLevel) return null;

  return (
    <Card className="officeSections">
      <SpecialLabel label={t('officeSections.specialLabel')} />
      {localCurrentLevelOffices.length > 0 ? (
        localCurrentLevelOffices.map((office, index) => {
          const prefix = 'level' + currentlyEditedLevel.level + 'OfficesSpecification' + index;
          return (
            <React.Fragment key={index}>
              <SpecialLabel label={t(office.name + '.title')} small />
              <FormGroup horizontal={true} wrap={false}>
                <div style={{ width: '53px', flex: '0 0 53px', padding: '17px 0 0 0' }}>
                  <IconObject {...office.icon} clickable={false} />
                </div>
                <div style={{ width: '21.5%', flex: '0 0 21.5%' }}>
                  <Input
                    name={prefix + 'Surface'}
                    label={t('officeSections.surfaceInput.label')}
                    type="number"
                    min={office.minSurface}
                    max={roundWith2Decimal(levelGranulometry.surfaceForSale || 0)}
                    minMax={{
                      min: office.minSurface,
                      max: roundWith2Decimal(levelGranulometry.surfaceForSale || 0),
                      unit: ''
                    }}
                    value={office.surface !== office.defaultSurface ? office.surface : undefined}
                    handleChange={(value) =>
                      handleOfficesSpecificationsChange(
                        secureInputChange(value, office.minSurface, office.maxSurface),
                        index,
                        'surface'
                      )
                    }
                    suffix="m2"
                    placeholder={office.defaultSurface}
                  />
                </div>
                <div style={{ width: '21.5%', flex: '0 0 21.5%' }}>
                  <Input
                    name={prefix + 'Width'}
                    label={t('officeSections.widthInput.label')}
                    type="number"
                    min={office.minWidth}
                    max={office.maxWidth}
                    minMax={{
                      min: office.minWidth,
                      max: office.maxWidth,
                      unit: ''
                    }}
                    value={office.width !== office.defaultWidth ? office.width : undefined}
                    handleChange={(value) =>
                      handleOfficesSpecificationsChange(
                        secureInputChange(value, office.minWidth, office.maxWidth),
                        index,
                        'width'
                      )
                    }
                    suffix="m"
                    placeholder={office.defaultWidth}
                  />
                </div>
                <div style={{ width: '21.5%', flex: '0 0 21.5%' }}>
                  <Input
                    name={prefix + 'ExposureRate'}
                    label={t('officeSections.exposureRateInput.label')}
                    type="number"
                    min={0}
                    max={100}
                    minMax={{
                      min: 0,
                      max: 100,
                      unit: ''
                    }}
                    value={
                      office.exposureRate !== undefined &&
                      office.exposureRate !== office.defaultExposureRate
                        ? (office.exposureRate / 4) * 100
                        : undefined
                    }
                    handleChange={(value) =>
                      handleOfficesSpecificationsChange(
                        secureInputChange(value, 0, 100),
                        index,
                        'exposureRate'
                      )
                    }
                    suffix="%"
                    placeholder={(office.defaultExposureRate / 4) * 100}
                  />
                </div>
                <div className="add-remove-buttons">
                  <IconObject
                    iconName="remove"
                    type="menu"
                    onClick={() => {
                      handleRemoveOffice(index);
                    }}
                    // title={t('Remove case {{caseLabel}}', { caseLabel: selectedCaseLabel })}
                  />
                  <IconObject
                    iconName="add"
                    type="menu"
                    onClick={() => {
                      handleDuplicateOffice(index);
                    }}
                  />
                </div>
              </FormGroup>
            </React.Fragment>
          );
        })
      ) : (
        <FormGroup horizontal={true} wrap={false}>
          <Button
            content={t('officeSections.addButton.content') as string}
            handleClick={() => {
              handleAddFirstOffice();
            }}
            appearance="outline"
            size="small"
            style={{ margin: '10px auto' }}
          />
        </FormGroup>
      )}
    </Card>
  );
};
