import { createSelector, Selector } from 'reselect';
import { selectCurrentCaseBasementLevelsSpecifications } from './basementLevelsSpecifications.selector';
import { selectCurrentCaseBasementLevelsCount } from '../../granulometry/currentCase/basementLevelsCount.selector';
import { BasementLevelSpecification } from '../../../../domain/specification/levels/BasementLevelSpecification';
import { makeBasementLevels } from '../../../../domain/specification/levels/queries/make/makeBasementLevels';
import { State } from '../../../reducers';

export const selectCurrentCaseSpecifiedBasementLevels: Selector<
  State,
  BasementLevelSpecification[]
> = createSelector(
  [selectCurrentCaseBasementLevelsSpecifications, selectCurrentCaseBasementLevelsCount],
  (specs, count) =>
    specs && count !== undefined
      ? (makeBasementLevels(count, specs) as BasementLevelSpecification[])
      : []
);
