import { LodgmentType } from '../../../../lodgmentTypes/LodgmentType';
import { BalconySpecification } from '../../../../rooms/BalconySpecification';
import { LivingRoomKitchenSpecification } from '../../../../rooms/LivingRoomKitchenSpecification';
import { BasicRoomSpecification } from '../../../../rooms/BasicRoomSpecification';
import * as R from 'ramda';
import { RoomSpecification } from '../../../../rooms/RoomSpecification';
import { CaseSpecification } from '../../../CaseSpecification';
import { setLodgmentTypeSpecificationRoom } from '../../../../rooms/queries/set/setLodgmentTypeSpecificationRoom';

export const setCaseLodgmentTypeSpecificationRoom =
  (
    lodgmentType: LodgmentType,
    roomIndex: number,
    roomSpecification:
      | Partial<BalconySpecification>
      | Partial<LivingRoomKitchenSpecification>
      | Partial<BasicRoomSpecification>
  ) =>
  (caseSpecification: CaseSpecification) => {
    const lodgmentTypeIndex = caseSpecification.lodgmentsTypesSpecifications.findIndex(
      R.propEq('lodgmentType', lodgmentType)
    );
    return R.over(
      R.lensPath(['lodgmentsTypesSpecifications', lodgmentTypeIndex]),
      setLodgmentTypeSpecificationRoom()(roomIndex, roomSpecification as RoomSpecification),
      caseSpecification
    );
  };
