import { ComponentPropsGetterR, DerivedDataObject } from 'react-table';

export const getTrProps: ComponentPropsGetterR = (state, rowInfo) => {
  if (rowInfo && rowInfo.row) {
    // see : getCostPercentsColumns.tsx and getCo2PercentsColumns.tsx
    if (rowInfo.row._subRows) {
      if (rowInfo.row._nestingLevel === 0) {
        rowInfo.row._subRows.forEach((row: DerivedDataObject) => {
          row._totalPrice = rowInfo.row.price;
          row._parentPrice = rowInfo.row.price;
          // row._totalCo2Weight = rowInfo.row.co2Weight;
          // row._parentCo2Weight = rowInfo.row.co2Weight;
          row._totalComEthIc = rowInfo.row.comEthIc;
          row._parentComEthIc = rowInfo.row.comEthIc;
        });
      } else {
        rowInfo.row._subRows.forEach((row: DerivedDataObject) => {
          row._totalPrice = rowInfo.row._totalPrice;
          row._parentPrice = rowInfo.row.price;
          // row._totalCo2Weight = rowInfo.row._totalCo2Weight;
          // row._parentCo2Weight = rowInfo.row.co2Weight;
          row._totalComEthIc = rowInfo.row._totalComEthIc;
          row._parentComEthIc = rowInfo.row.comEthIc;
        });
      }
    }
    // Add special css class to lot pivot rows
    let className = '';
    if (rowInfo.groupedByPivot && rowInfo.row._nestingLevel !== 4) {
      className += ' tr-pivotLot tr-pivotLotLevel' + rowInfo.row._nestingLevel;
    }
    return { className };
  }
  return {};
};
