import { ReportEntry } from '../ReportEntry';
import { BuildingId } from '../../specification/buildings/BuildingSpecification';
import { CaseId } from '../../specification/cases/CaseSpecification';

export type CostByCase = Record<CaseId, number>;
export type CostByBuilding = Record<BuildingId, number>;
export type CostByBuildingAndCase = Record<BuildingId, CostByCase>;

export const getCostByBuildingAndCase = (report: ReportEntry[]): CostByBuildingAndCase =>
  report.reduce(
    (counted, entry) => ({
      ...counted,
      [entry.buildingId]: {
        ...(counted[entry.buildingId] || {}),
        [entry.caseId]: ((counted[entry.buildingId] || {})[entry.caseId] || 0) + (entry.price || 0)
      }
    }),
    {} as CostByBuildingAndCase
  );
