import { MapServices } from '../map/map.services';
import { LoadImageOptions } from '../map/map.repository';
import { DRAW_HELPER_FEATURE, DrawHelper, MovingLayer } from './drawHelper.model';
import { RasterLayer } from 'mapbox-gl';
import { Position } from 'geojson';
import { translateCoordinatesToNewCenter } from '../utils/polygon/translatePolygonOnPoint';
import { DrawRepo, FeaturePolygonWithId } from '../mapboxDraw/draw.repo';
import { getCenter } from '../utils/geometry/getCenter';
import { growPolygonAsResizedPolygon } from '../utils/polygon/resizePolygon';
import { LevelGeometry } from '../mapboxDraw/levelGeometry';
import Decimal from 'decimal.js';
import { removeAngleCircles } from '../styles/draw/circles.style';

export class DrawHelperServices {
  mapServices: MapServices;
  drawRepo: DrawRepo;
  constructor(mapServices: MapServices, drawRepo: DrawRepo) {
    this.mapServices = mapServices;
    this.drawRepo = drawRepo;
  }

  async create(options: LoadImageOptions): Promise<RasterLayer> {
    options.metadata = {};
    return await this.mapServices.addRasterOnCurrentPosition(options);
  }

  findAll(): DrawHelper[] {
    return this.mapServices.findLayersByPrefix(DRAW_HELPER_FEATURE) as DrawHelper[];
  }

  findOne(id: string): DrawHelper {
    const layers = this.findAll();
    const drawHelper = layers.find((l) => l.id === id);
    if (!drawHelper) throw new Error('Image non trouvée');
    return drawHelper;
  }

  findPendingDrawHelper(): DrawHelper | undefined {
    const drawHelpers = this.findAll();
    return drawHelpers.find((d) => !d.metadata.featureId);
  }

  findDrawHelperByFeatureId(featureId: string) {
    const drawHelpers = this.findAll();
    const drawHelper = drawHelpers.find((d) => d.metadata.featureId === featureId);
    if (!drawHelper) throw new Error("L'identifiant de l'image n'est pas reconnu");
    return drawHelper;
  }

  update(polygon: MovingLayer) {
    return this.moveDrawHelper(polygon.id, polygon.geometry.coordinates[0]);
  }

  delete(layerName: string) {
    try {
      const drawHelper = this.findOne(layerName) as DrawHelper;
      this.mapServices.removeRaster(drawHelper.source as string, drawHelper.id);
    } catch (e) {
      console.error(e);
    }
  }

  unLinkFeature(featureId: string) {
    try {
      const drawHelper = this.findDrawHelperByFeatureId(featureId) as DrawHelper;
      const drawHelperCoordinates = this.mapServices.getLayerCoordinates(
        drawHelper.source as string
      );

      drawHelper.metadata.featureId = undefined;
      this.mapServices.recreateRaster(drawHelper, drawHelperCoordinates);
    } catch (e) {
      console.error(e);
    }
  }

  deleteByFeatureId(featureId: string) {
    try {
      const drawHelper = this.findDrawHelperByFeatureId(featureId) as DrawHelper;
      this.mapServices.removeRaster(drawHelper.source as string, drawHelper.id);
    } catch (e) {
      console.error(e);
    }
  }

  validateCreation(featureId: string) {
    this.hideAll();
    this.lockFeature(featureId);
  }

  hideAll() {
    const layers = this.mapServices.findLayersByPrefix(DRAW_HELPER_FEATURE);
    if (layers && layers.length > 0) {
      layers.forEach((layer) => {
        this.mapServices.repo.hideLayout(layer.id);
      });
    }
  }

  linkWithFeature(drawHelper: DrawHelper, feature: FeaturePolygonWithId): { id: string } {
    const oldLayer = this.mapServices.findOneLayer(drawHelper.id) as DrawHelper;

    const newLayer: Omit<LoadImageOptions, 'imagePath'> = {
      layerName: oldLayer.id,
      sourceName: oldLayer.source as string,
      metadata: {
        ...oldLayer.metadata,
        featureId: feature.id
      }
    };
    this.mapServices.updateRasterLayer(drawHelper.id, newLayer);

    return { id: drawHelper.id };
  }

  isLinkedFeature(feature: FeaturePolygonWithId): boolean {
    if (!feature) return false;
    try {
      return !!feature.properties.drawHelper?.id;
    } catch (e) {
      return false;
    }
  }

  moveDrawHelper(featureId: string, polygonCoordinates: Position[]) {
    const drawHelper = this.findDrawHelperByFeatureId(featureId) as DrawHelper;

    this.mapServices.moveImageSource(drawHelper.source as string, polygonCoordinates);
  }

  resize(ratio: Decimal, feature: LevelGeometry) {
    const drawHelper = this.findOne(feature.properties.drawHelper?.id as string);
    const drawHelperCoordinates = this.mapServices.getLayerCoordinates(drawHelper.source as string);
    const newCenter = getCenter(feature.geometry.coordinates[0]);

    const resizedDrawHelper = translateCoordinatesToNewCenter(
      growPolygonAsResizedPolygon(drawHelperCoordinates, ratio),
      newCenter
    );

    this.mapServices.moveImageSource(drawHelper.source as string, resizedDrawHelper);
  }

  lockFeature(featureId: string) {
    removeAngleCircles(featureId, (layer: string, filter?: any[]) =>
      this.drawRepo.map.setFilter(layer, filter)
    );
  }

  /* addDrawHelperMovingLayer(id: string, drawHelperLayerName: string) {
    const drawHelper = this.findOne(drawHelperLayerName);
    const coords = this.mapServices.getLayerCoordinates(drawHelper.source as string);
    const movingHelper = this.drawRepo.create({
      type: 'Feature',
      id: createDrawHelperMovingLayerId(drawHelperLayerName),
      geometry: { coordinates: [[...coords, coords[0]]], type: 'Polygon' },
      properties: {
        type: DRAW_HELPER_MOVING_FEATURE,
        drawHelperId: drawHelperLayerName
      }
    });

    removeAngleCircles(id, (layer: string, filter?: any[]) =>
      this.mapServices.repo.map.setFilter(layer, filter)
    );

    return movingHelper;
  }

  isMovingLayer(polygon: MovingLayer | FeaturePolygonWithId): boolean {
    if (!polygon) return false;
    try {
      return polygon.properties.type === DRAW_HELPER_MOVING_FEATURE;
    } catch (e) {
      return false;
    }
  }

  toggleHideMovingLayer(layer: mapboxgl.AnyLayer) {
    const movingLayer = this.drawRepo.findMovingFeatures();
    if (movingLayer && movingLayer.length > 0) {
      this.mapServices.toggleHideLayer(
        DRAW_HELPER_MOVING_FEATURE_STYLE_LAYER,
        this.mapServices.repo.isHide(layer.id as string)
      );
    }
  }

  resizeMovingLayer(drawHelper: DrawHelper, newCenter: Position, ratio: Decimal) {
    const movingLayer = this.drawRepo.findOne(
      drawHelper.metadata.movingLayerId as string
    ) as MovingLayer;
    movingLayer.geometry.coordinates[0] = translateCoordinatesToNewCenter(
      growPolygonAsResizedPolygon(movingLayer.geometry.coordinates[0], ratio),
      newCenter
    );
    this.drawRepo.reCreate(movingLayer);
  } */

  /*
  rotate({ feature, lastMouseDown }: { feature: MaybeFloorSpaceFeature; lastMouseDown: Position }) {
    if (!this.isDrawHelper(feature.id)) return;
    const drawHelper = this.getDrawHelperByMovingLayerId(feature.id) as DrawHelper;
    const drawHelperCoordinates = this.mapServices.getLayerCoordinates(drawHelper.source as string);

    const polygonCenter = getCenter(feature.geometry?.coordinates[0] as Position[]);
    const draggedBearing = bearing(polygonCenter, lastMouseDown);

    const rotatedCoordinates = rotatePolygon(drawHelperCoordinates, draggedBearing, polygonCenter);
    this.mapServices.recreateRaster(drawHelper, rotatedCoordinates);
  }
*/
}
