import { Reducer } from 'redux';
import {
  SET_CURRENTLY_DISPLAYED_CASE_PROPERTIES_NAMES,
  SetCurrentlyDisplayedCasePropertiesNamesAction
} from '../actions/setDisplayedCasePropertiesNames.action';

export interface NavigationState {
  currentlyDisplayedCasePropertiesNames: string[];
}

const initialState: NavigationState = { currentlyDisplayedCasePropertiesNames: [] };

export const navigationReducer: Reducer<NavigationState> = (state = initialState, action) => {
  if (action.type === SET_CURRENTLY_DISPLAYED_CASE_PROPERTIES_NAMES) {
    return {
      ...state,
      currentlyDisplayedCasePropertiesNames: (
        action as SetCurrentlyDisplayedCasePropertiesNamesAction
      ).payload.currentlyDisplayedCasePropertiesNames
    };
  }

  return state;
};
