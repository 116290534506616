import * as R from 'ramda';
import {
  LevelGranulometryFullFilled,
  LevelGranulometryWithBearingRelativeSections,
  LevelGranulometryWithLodgments
} from '../../../LevelGranulometry';
import { getLevelSections } from '../../sections/getLevelSections';
import {
  filterIncludedInSurfaceForSaleSections,
  IncludedInSurfaceForSaleSection
} from '../../../../sections/SectionIncludedInSurfaceForSale';

export const getTopLevelIncludedInSurfaceForSaleSections = (
  levelGranulometry:
    | LevelGranulometryWithLodgments
    | LevelGranulometryWithBearingRelativeSections
    | LevelGranulometryFullFilled
): IncludedInSurfaceForSaleSection[] =>
  R.pipe(getLevelSections, filterIncludedInSurfaceForSaleSections)(levelGranulometry);
