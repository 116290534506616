import {
  BuildingId,
  BuildingSpecification
} from '../../specification/buildings/BuildingSpecification';
import * as R from 'ramda';
import { Project } from '../Project';

const lensBuilding = (buildingId: BuildingId) =>
  R.lens<Project, BuildingSpecification>(
    (project) =>
      R.pipe<[Project], BuildingSpecification[], BuildingSpecification | undefined>(
        (p: Project) => R.prop<'buildings', Project>('buildings', p),
        (bs: BuildingSpecification[]) =>
          R.find<BuildingSpecification>(R.propEq('id', buildingId), bs)
      )(project as Project) as BuildingSpecification,
    (newValue, project) => {
      const buildingIndex = project.buildings.findIndex(R.propEq('id', buildingId));
      return R.over(R.lensProp('buildings'), R.update(buildingIndex, newValue), project);
    }
  );

export const overBuilding = R.curry(
  <P extends Project>(
    buildingId: BuildingId,
    operation: (building: BuildingSpecification) => BuildingSpecification,
    project: P
  ) => R.over(lensBuilding(buildingId), operation, project)
);
