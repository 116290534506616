import pileFundations, {
  PileFundationsSection
} from '../../../../sections/fundationsSections/pileFundations';
import deepFundations, {
  DeepFundationsSection
} from '../../../../sections/fundationsSections/deepFundations';
import superficialFundations, {
  SuperficialFundationsSection
} from '../../../../sections/fundationsSections/superficialFundations';

export const createLevelFundationsSection = (
  fundationSurface: number,
  fundationType: 'superficial' | 'deep' | 'pile'
): SuperficialFundationsSection | DeepFundationsSection | PileFundationsSection =>
  fundationType === 'superficial'
    ? superficialFundations({ surface: fundationSurface })
    : fundationType === 'deep'
    ? deepFundations({ surface: fundationSurface })
    : pileFundations({ surface: fundationSurface });
