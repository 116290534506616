import { LodgmentSection } from '../../sections/lodgmentSections/LodgmentSection';
import * as R from 'ramda';
import { CountByLodgmentType } from './redistributeCaseWithCustomDistribution';
import { getSectionTheoreticalSurface } from '../../sections/queries/surfaces/getSectionTheoreticalSurface';

const increasePropertionalyLodgmentSurface = (
  distribution: CountByLodgmentType,
  lodgmentSections: LodgmentSection[],
  remainingSurfaceForSale: number,
  remainingLodgmentCount: number
): LodgmentSection[] =>
  R.pipe<[CountByLodgmentType], [string, number][], LodgmentSection[]>(
    R.toPairs,
    R.reduce(
      (acc, countByType) => [
        ...acc,
        ...R.times(() => {
          const newLodgment = lodgmentSections.find(
            (l) => l.lodgmentType === countByType[0]
          ) as LodgmentSection;
          const addedSurface =
            remainingSurfaceForSale > 0 ? remainingSurfaceForSale / remainingLodgmentCount-- : 0;
          const adjustedSurface = getSectionTheoreticalSurface(newLodgment) + addedSurface;
          remainingSurfaceForSale -= addedSurface;
          return {
            ...newLodgment,
            surface: adjustedSurface,
            displayedSurface: adjustedSurface
          };
        }, countByType[1])
      ],
      [] as LodgmentSection[]
    )
  )(distribution);

/* const decreasePropertionalyLodgmentSurface = (
  distribution: CountByLodgmentType,
  lodgmentSections: LodgmentSection[],
  remainingSurfaceForSale: number,
  remainingLodgmentCount: number
): LodgmentSection[] =>
  R.pipe<[CountByLodgmentType], [string, number][], LodgmentSection[]>(
    R.toPairs,
    R.reduce(
      (acc, countByType) => [
        ...acc,
        ...R.times(() => {
          const newLodgment = lodgmentSections.find(
            l => l.lodgmentType === countByType[0]
          ) as LodgmentSection;
          const removedSurface =
            remainingSurfaceForSale > 0 ? remainingSurfaceForSale / remainingLodgmentCount-- : 0;

          const adjustedSurface = Math.max(
            newLodgment.minimumDisplayedSurface,
            newLodgment.displayedSurface + removedSurface
          );
          remainingSurfaceForSale -= removedSurface;
          return {
            ...newLodgment,
            surface: adjustedSurface,
            displayedSurface: adjustedSurface
          };
        }, countByType[1])
      ],
      [] as LodgmentSection[]
    )
  )(distribution); */

export const getAdjustedLodgments = (
  distribution: CountByLodgmentType,
  lodgmentSections: LodgmentSection[],
  remainingSurfaceForSale: number,
  remainingLodgmentCount: number
): LodgmentSection[] => {
  // If there is enough surface for sale
  return increasePropertionalyLodgmentSurface(
    distribution,
    lodgmentSections,
    remainingSurfaceForSale,
    remainingLodgmentCount
  );
};
