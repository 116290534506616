import i18next from 'i18next';
import { Section } from '../Section';
import { SlabSectionName } from './SlabSection';

interface OutsideSlabProps {
  surface: number;
}

export interface OutsideSlabSection extends Section {
  name: SlabSectionName.OutsideSlab;
}

const outsideSlab = ({ surface }: OutsideSlabProps): OutsideSlabSection => ({
  // Section
  id: 'NOID',
  name: SlabSectionName.OutsideSlab,
  title: i18next.t('outsideSlab.title'),
  type: 'slab',
  displayedSurface: surface
});

export default outsideSlab;
