import { LevelGranulometryInitialEntries } from '../../../../LevelGranulometry';
import { CaseProjection } from '../../../../../../projection/cases/CaseProjection';

export const getLevelMaxSurfaceForSaleFromCaseProjection = (
  caseProjection: CaseProjection,
  levelGranulometry: LevelGranulometryInitialEntries
): number =>
  caseProjection.projectedMaxSurfaceForSaleByLevels.find(
    (maxSurfaceForSaleByLevel) => levelGranulometry.level === maxSurfaceForSaleByLevel.levelIndex
  )?.maxSurfaceForSale as number;
