import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { push } from 'connected-react-router';
import { Button } from '../../../components/ui/Button';
import { FormGroup } from '../../../components/ui/FormGroup';
import { Option, Select } from '../../../components/ui/Select';
import { SpecialLabel } from '../../../components/ui/SpecialLabel';
import { Form } from '../../../components/ui/Form';
import { selectProjectId } from '../../../store/selectors/project/currentProjectId.selector';
import { selectProjects } from '../../../store/selectors/project/projects.selector';
import { ProjectDialog } from './ProjectDialog';
import { ExistingProject } from '../../../domain/project/Project';

const projectToOption = (project: ExistingProject): Option => ({
  label: project.name,
  value: project.id
});

const SimpleOpenProject: React.FC = () => {
  const projectId = useSelector(selectProjectId);
  const projects = useSelector(selectProjects);

  const dispatch = useDispatch();

  const [selected, setSelected] = React.useState<ExistingProject | undefined>(undefined);

  const { t } = useTranslation();

  const backToOpenProjects = React.useCallback(() => {
    dispatch(push('/projects'));
  }, [dispatch]);

  const titleOption: Option = { value: '', label: t('Select project') };

  const onSubmit = React.useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();

      if (selected) {
        dispatch(push('/project/' + selected.id));
      }
    },
    [dispatch, projectId, selected]
  );

  const selectOption = React.useCallback(
    (option: Option) => {
      setSelected(projects.find((p) => p.id === option.value) as ExistingProject);
    },
    [setSelected, projects]
  );

  return (
    <ProjectDialog className="open-project-form">
      <SpecialLabel label={t('Open project')} />
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <label htmlFor="selected">{t('Project')}</label>
          <Select
            id={'selected'}
            options={[titleOption, ...projects.map(projectToOption)]}
            handleChange={selectOption}
            value={selected ? projectToOption(selected) : titleOption}
            disabled={!projects}
          />
        </FormGroup>
        <FormGroup spaceBetween={true} horizontal={true}>
          <Button handleClick={backToOpenProjects} content={t('Back')} />
          <Button isSubmit={true} content={t('Open project')} disabled={!selected} />
        </FormGroup>
      </Form>
    </ProjectDialog>
  );
};

export default SimpleOpenProject;
