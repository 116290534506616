import { LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { getTopLevelGrossFloorSurfaceEff } from './getTopLevelGrossFloorSurfaceEff';
import { getTopLevelHoppersSurface } from '../getTopLevelHoppersSurface';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { getTopLevelGrossFloorSurface } from './getTopLevelGrossFloorSurface';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelGrossFloorSurfaceDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled
): string =>
  '<b>' +
  roundWith2Decimal(getTopLevelGrossFloorSurfaceEff(caseGranulometry, levelGranulometry)) +
  ' m\u00B2</b> de SHOB VPP<br /><b>- ' +
  roundWith2Decimal(getTopLevelHoppersSurface(caseGranulometry, levelGranulometry)) +
  ' m\u00B2</b> de <i>Trémies</i><br /><b>= ' +
  roundWith2Decimal(getTopLevelGrossFloorSurface(caseGranulometry, levelGranulometry)) +
  ' m\u00B2</b>';
