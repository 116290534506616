import { LodgmentSection } from '../../../../../sections/lodgmentSections/LodgmentSection';
import { getSectionPartitionWallsLinear } from '../../../../../sections/queries/walls/partitionWalls/getSectionPartitionWallsLinear';
import { LevelGranulometry, LevelGranulometryWithLodgments } from '../../../../LevelGranulometry';
import { getTopLevelLodgmentSections } from '../../sections/getTopLevelLodgmentSections';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelLodgmentsPartitionWallsLinear = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithLodgments | LevelGranulometry
): number =>
  getTopLevelLodgmentSections(levelGranulometry).reduce(
    (acc, lodgmentSection: LodgmentSection) =>
      acc + getSectionPartitionWallsLinear(caseGranulometry, levelGranulometry, lodgmentSection),
    0
  );
