import { createSelector, Selector } from 'reselect';
import { selectBuildingMapFocus } from '../buildingMap/buildingMapFocus.selector';
import { selectComEthReport } from './comEthReport.selector';
import { selectCurrentCaseInComEthReport } from './currentCaseInComEthReport.selector';
import { selectCurrentBuildingInComEthReport } from './currentBuildingInComEthReport.selector';
import { isFocusBuilding, isFocusProject } from '../../../domain/buildingMap/BuildingMapFocus';
import { State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';
import { ComEthReportCase } from '../../../api/cstb/comEth/comEthReport/ComEthReportCase';
import { ComEthReportBuilding } from '../../../api/cstb/comEth/comEthReport/ComEthReportBuilding';
import { ComEthReport } from '../../../api/cstb/comEth/comEthReport/ComEthReport';

export const selectFocusedInComEthReport: Selector<
  State,
  Maybe<ComEthReport | ComEthReportBuilding | ComEthReportCase>
> = createSelector(
  [
    selectBuildingMapFocus,
    selectComEthReport,
    selectCurrentBuildingInComEthReport,
    selectCurrentCaseInComEthReport
  ],
  (buildingMapFocus, comEthReport, currentBuildingComEthReport, currentCaseComEthReport) =>
    isFocusProject(buildingMapFocus)
      ? comEthReport
      : isFocusBuilding(buildingMapFocus)
      ? currentBuildingComEthReport
      : currentCaseComEthReport
);
