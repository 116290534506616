import { Project } from '../../../domain/project/Project';
import { Report } from '../../../domain/report/ReportEntry';
import { Granulometry } from '../../../domain/granulometry/Granulometry';
import { ComEthJsonFeature, getComEthJsonFeature } from './comEthJson/getComEthJsonFeature';
import { Nomenclature } from '../../../domain/Nomenclature';
import { getProjectClimateZone } from '../../../domain/specification/project/queries/getProjectClimateZone';
import { getProjectIrisCode } from '../../../domain/specification/project/queries/getProjectIrisCode';
import {
  PROJECT_LOCATION_DEFAULT_CITY,
  PROJECT_LOCATION_DEFAULT_LATITUDE,
  PROJECT_LOCATION_DEFAULT_LONGITUDE,
  PROJECT_LOCATION_DEFAULT_POSTAL_CODE,
  PROJECT_LOCATION_DEFAULT_STREET
} from '../../../domain/ProjectLocation';

export interface ComEthJson {
  type: 'FeatureCollection'; // leave as it is
  version: 'v1'; // leave as it is
  scheme_version: 24; // leave as it is
  simulation_mode: 'project_only'; // leave as it is
  district_name: string;
  postal_code: string;
  localisation: string;
  iris_code: string | null;
  city: string;
  default_energy_choice: 'gas' | 'electricity';
  IAU_code: string | null; // TODO : @Felix méthode ?
  // see : https://pyris.datajazz.io/api/search/?geojson=true&q=297%20chemin%20de%20trincou%2081120%20poulan-pouzols
  // see : https://api-adresse.data.gouv.fr/search/?q=297+chemin+de+trincou+81120+poulan-pouzols
  climate_zone: ClimateZone | null;
  longitude: number;
  latitude: number;
  // LCA_impactsMatrixCodes: ComEthJsonLCAImpactsMatrixCodes; // Matrice/Dictionnaire des résultats ACV (liste de data souhaitées)
  features: ComEthJsonFeature[]; // Plusieurs bâtiments ou aménagements extérieurs
  outdoor_spaces: null; // useless : leave as it is
  crs: { properties: { name: string }; type: string }; // To set we are using the GPS system
}

export type ClimateZone = 'H1a' | 'H1b' | 'H1c' | 'H2a' | 'H2b' | 'H2c' | 'H2d' | 'H3';

export const makeComEthJson = (
  project: Project,
  report: Report,
  granulometry: Granulometry,
  nomenclature: Nomenclature
): ComEthJson => ({
  type: 'FeatureCollection',
  version: 'v1',
  scheme_version: 24,
  simulation_mode: 'project_only',
  district_name: project.name,
  default_energy_choice: 'gas', // TODO
  postal_code: project.postalCode || PROJECT_LOCATION_DEFAULT_POSTAL_CODE,
  localisation: project.street || PROJECT_LOCATION_DEFAULT_STREET,
  iris_code: getProjectIrisCode(project),
  city: project.city || PROJECT_LOCATION_DEFAULT_CITY,
  IAU_code: null,
  climate_zone: getProjectClimateZone(project),
  longitude: project.longitude || PROJECT_LOCATION_DEFAULT_LONGITUDE,
  latitude: project.latitude || PROJECT_LOCATION_DEFAULT_LATITUDE,
  // LCA_impactsMatrixCodes: getComEthJsonLCAImpactsMatrixCodes(),
  features: granulometry.reduce((features, buildingGranulometry) => {
    return features.concat(
      buildingGranulometry.cases.reduce(
        (acc, caseGranulometry) => [
          ...acc,
          getComEthJsonFeature(buildingGranulometry, caseGranulometry, report, nomenclature)
        ],
        [] as ComEthJsonFeature[]
      )
    );
  }, [] as ComEthJsonFeature[]),
  outdoor_spaces: null,
  crs: {
    properties: {
      name: 'urn:ogc:def:crs:EPSG::4326'
    },
    type: 'name'
  }
});
