import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { LodgmentTypeSpecification } from '../../../../../domain/specification/lodgmentTypes/LodgmentTypeSpecification';
import { CaseEditingStageName } from '../../../../../domain/specification/cases/CaseSpecification';
import { LodgmentType } from '../../../../../domain/specification/lodgmentTypes/LodgmentType';
import { CaseCustomDistribution } from '../../../../../domain/specification/cases/CaseSurfaceSpecification';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { selectCurrentCaseIdFromRoute } from '../../../../../store/selectors/navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { selectCurrentCaseCustomDistribution } from '../../../../../store/selectors/specification/currentCase/customDistribution.selector';
import { selectCurrentCaseEditingStage } from '../../../../../store/selectors/specification/currentCase/editingStage.selector';
import { selectCurrentCaseLodgmentsCountByLevelAndType } from '../../../../../store/selectors/granulometry/currentCase/lodgmentsCountByTopLevelAndType.selector';
import { selectCurrentCaseLodgmentsTypesSpecifications } from '../../../../../store/selectors/specification/currentCase/lodgementsTypesSpecifications.selector';
import { caseCustomDistributionChanged } from '../../../../../store/actions/caseCustomDistributionChanged.action';
import { CELL_WIDTH } from '../../CardToScroll';
import { Input } from '../../../../ui/input/Input';
import { secureInputChange } from '../../../../../utils/secureInputChange';
import { roundWithNoDecimal } from '../../../../../utils/round/roundWithNoDecimal';
import { TopLevelSpecification } from '../../../../../domain/specification/levels/TopLevelSpecification';

interface Column2Props {
  topLevelSpecification: TopLevelSpecification;
}

export const Column2 = ({ topLevelSpecification }: Column2Props) => {
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const lodgmentsCountByTopLevelAndType = useSelector(
    selectCurrentCaseLodgmentsCountByLevelAndType
  );
  const lodgmentsTypesSpecifications = useSelector(selectCurrentCaseLodgmentsTypesSpecifications);
  const customDistribution = useSelector(selectCurrentCaseCustomDistribution);
  const editingStage = useSelector(selectCurrentCaseEditingStage);
  const dispatch = useDispatch();

  const handleSetCustomDistribution = React.useCallback(
    (levelIndex: number, lodgmentType: LodgmentType, count: number | null) => {
      if (caseId && customDistribution) {
        const hasEmptyLevel = (d: CaseCustomDistribution) => R.isEmpty(d[levelIndex]);
        const cleanLevel = R.ifElse(hasEmptyLevel, R.dissoc(levelIndex.toString()), R.identity);

        const removeCount = R.pipe<
          [CaseCustomDistribution],
          CaseCustomDistribution,
          CaseCustomDistribution
        >(R.dissocPath([levelIndex, lodgmentType]), cleanLevel);

        const addCount = R.assocPath<number, CaseCustomDistribution>(
          [levelIndex, lodgmentType],
          secureInputChange(count as number, 0, 99)
        );

        const getNewDistribution = (count: number | null) =>
          count === null ? removeCount(customDistribution) : addCount(customDistribution);

        dispatch(caseCustomDistributionChanged(caseId, getNewDistribution(count)));
      }
    },
    [dispatch, caseId, customDistribution]
  );

  if (!caseLabel || !lodgmentsCountByTopLevelAndType || !lodgmentsTypesSpecifications) return null;

  const lodgmentsCountForLevelByType =
    process.env.GB_FEAT_CUSTOM_DISTRIBUTION !== 'false' &&
    editingStage === CaseEditingStageName.Distribution
      ? R.mergeRight(
          lodgmentsCountByTopLevelAndType[topLevelSpecification.level],
          customDistribution[topLevelSpecification.level]
        )
      : lodgmentsCountByTopLevelAndType[topLevelSpecification.level];

  return (
    <div className="column-2" tabIndex={-1}>
      <div style={{ width: `${CELL_WIDTH * lodgmentsTypesSpecifications.length}px` }}>
        {lodgmentsTypesSpecifications.map((lodgmentType: LodgmentTypeSpecification) => (
          <div className="cell" key={lodgmentType.lodgmentType}>
            {process.env.GB_FEAT_CUSTOM_DISTRIBUTION !== 'false' ? (
              <Input
                name={`topLevel${topLevelSpecification.level}${lodgmentType.lodgmentType}Count`}
                type="number"
                size="small"
                value={
                  editingStage === CaseEditingStageName.Distribution
                    ? R.path(
                        [topLevelSpecification.level, lodgmentType.lodgmentType],
                        customDistribution
                      )
                    : lodgmentsCountForLevelByType &&
                      lodgmentsCountForLevelByType[lodgmentType.lodgmentType]
                    ? lodgmentsCountForLevelByType[lodgmentType.lodgmentType]
                    : 0
                }
                placeholder={
                  editingStage === CaseEditingStageName.Distribution
                    ? lodgmentsCountForLevelByType &&
                      lodgmentsCountForLevelByType[lodgmentType.lodgmentType]
                      ? lodgmentsCountForLevelByType[lodgmentType.lodgmentType]
                      : 0
                    : undefined
                }
                handleChange={(count: number) => {
                  handleSetCustomDistribution(
                    topLevelSpecification.level,
                    lodgmentType.lodgmentType,
                    count !== null ? roundWithNoDecimal(count) : null
                  );
                }}
                disabled={editingStage === CaseEditingStageName.Granulation}
              />
            ) : (
              <Input
                name={`topLevel${topLevelSpecification.level}${lodgmentType.lodgmentType}Count`}
                type="number"
                size="small"
                disabled
                value={
                  lodgmentsCountForLevelByType &&
                  lodgmentsCountForLevelByType[lodgmentType.lodgmentType]
                    ? lodgmentsCountForLevelByType[lodgmentType.lodgmentType]
                    : 0
                }
              />
            )}
          </div>
        ))}
        <div className="clear" />
      </div>
    </div>
  );
};
