import { CaseGranulometry } from '../../CaseGranulometry';
import { CaseProjection } from '../../../../projection/cases/CaseProjection';
import { getCaseAlreadyProjectedSurfaceForSale } from '../../../../projection/cases/queries/surfaces/getCaseAlreadyProjectedSurfaceForSale';
import { getCaseFilledSurfaceForSale } from './getCaseFilledSurfaceForSale';

export const getCaseSurfaceForSafeIsFullyFilledValidation = (
  caseProjection: CaseProjection,
  caseGranulometry: CaseGranulometry
): boolean =>
  Math.abs(
    getCaseAlreadyProjectedSurfaceForSale(caseProjection).value -
      getCaseFilledSurfaceForSale(caseGranulometry).value
  ) < 1;
