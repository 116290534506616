import * as React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { gingerMapState } from 'store/reducers/gingerMap.slice';
import { getGingerState } from 'store/reducers';
import { SpecialLabel } from '../../../ui/SpecialLabel';
import { Note } from '../../../ui/Note';
import { FormGroup } from '../../../ui/FormGroup';
import { Button } from '../../../ui/Button';
import { selectIsProjectDetailsGingerAvailable } from '../../../../store/selectors/navigation/toolbox/projectPanel/isProjectDetailsGingerAvailable.selector';

export const GeographicalInterestMaps = () => {
  const { t } = useTranslation();

  const [areDetailsDisplayed, setAreDetailsDisplayed] = React.useState<boolean>(false);

  const isEnabled = useSelector(selectIsProjectDetailsGingerAvailable);
  const isLoading = useSelector(getGingerState).loading;

  const dispatch = useDispatch();

  return (
    <>
      <SpecialLabel label={t('geographicalInterestMaps.label')} />
      <Note strong={true}>
        Ce service permet l’extraction de{' '}
        <b className="clickable" onClick={() => setAreDetailsDisplayed(!areDetailsDisplayed)}>
          16 couches d’intérêt géographiques
        </b>{' '}
        publiques à l’archivage géolocalisé sur les coordonnées saisies pour ce projet
        {areDetailsDisplayed ? ' :' : '.'}
        <ul className={classNames({ displayed: areDetailsDisplayed })}>
          <li>Topographie ;</li>
          <li>Image aérienne ;</li>
          <li>Parcellaire ;</li>
          <li>Géologie ;</li>
          <li>Eau souterraine ;</li>
          <li>Aléa inondation par remontée de nappe ;</li>
          <li>Aléa mouvements de terrain ;</li>
          <li>Aléa sismique ;</li>
          <li>Aléa retrait/gonflement d&apos;argiles ;</li>
          <li>Aléa cavités souterraines ;</li>
          <li>Potentiel radon par commune ;</li>
          <li>Sites Basol ;</li>
          <li>Sites ICPE ;</li>
          <li>Sites Basias ;</li>
          <li>Secteurs d’information sur les sols (SIS).</li>
        </ul>
        <FormGroup flexEnd>
          <Button
            size="small"
            withLoader={isLoading}
            disabled={!isEnabled || isLoading}
            content={
              isLoading
                ? t('geographicalInterestMaps.buttonContent2')
                : t('geographicalInterestMaps.buttonContent1')
            }
            handleClick={() => {
              dispatch(gingerMapState.actions.getGingerMaps());
            }}
          />
        </FormGroup>
        {isLoading && <i>Le temps d’une requête peut prendre plusieurs minutes.</i>}
      </Note>
    </>
  );
};
