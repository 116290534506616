import { createSelector, Selector } from 'reselect';
import { selectCurrentCaseCustomDistribution } from './customDistribution.selector';
import { selectCurrentCaseLodgmentsCountByTypeWithCustomDistribution } from '../../granulometry/currentCase/lodgmentsCountByTypeWithCustomDistribution.selector';
import { selectCurrentCaseLodgmentsTypesDistributionWithSpecifications } from './lodgmentsTypesDistributionWithSpecifications.selector';
import * as R from 'ramda';
import { selectLodgmentsCountWithCustomDistribution } from './lodgmentsCountWithCustomDistribution.selector';
import { CaseCustomDistributionByType } from '../../../../domain/specification/cases/CaseSurfaceSpecification';
import { LodgmentType } from '../../../../domain/specification/lodgmentTypes/LodgmentType';
import { LodgmentTypeSpecification } from '../../../../domain/specification/lodgmentTypes/LodgmentTypeSpecification';
import { LodgmentTypeRate } from '../../../../domain/specification/lodgmentTypes/LodgmentTypesDistribution';
import { State } from '../../../../store/reducers';

type CurrentCaseLodgmentsTypesDistributionWithCustomDistribution = (LodgmentTypeRate &
  LodgmentTypeSpecification)[];

export const selectCurrentCaseLodgmentsTypesDistributionWithCustomDistribution: Selector<
  State,
  CurrentCaseLodgmentsTypesDistributionWithCustomDistribution
> = createSelector(
  [
    selectCurrentCaseCustomDistribution,
    selectLodgmentsCountWithCustomDistribution,
    selectCurrentCaseLodgmentsCountByTypeWithCustomDistribution,
    selectCurrentCaseLodgmentsTypesDistributionWithSpecifications
  ],
  (
    customDistribution,
    lodgmentCount,
    lodgmentsCountByTypeWithCustomDistribution,
    lodgmentsCountByTypeWithSpecifications
  ) => {
    if (!R.isEmpty(customDistribution)) {
      const newRatesByLodgmentType = R.pipe<
        [CaseCustomDistributionByType | {}],
        [string, number][],
        { [key in LodgmentType]: number }
      >(
        R.toPairs,
        R.reduce(
          (acc, count) => ({
            ...acc,
            [count[0]]: (count[1] / lodgmentCount) * 100
          }),
          {} as { [key in LodgmentType]: number }
        )
      )(lodgmentsCountByTypeWithCustomDistribution);
      return R.reduce(
        (acc, lodgmentType: LodgmentTypeRate & LodgmentTypeSpecification) => [
          ...acc,
          { ...lodgmentType, rate: newRatesByLodgmentType[lodgmentType.lodgmentType] || 0 }
        ],
        [] as (LodgmentTypeRate & LodgmentTypeSpecification)[]
      )(lodgmentsCountByTypeWithSpecifications);
    } else {
      return lodgmentsCountByTypeWithSpecifications;
    }
  }
);
