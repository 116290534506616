import { DrawModes, DrawUpdateEvent } from '@mapbox/mapbox-gl-draw';
import mapboxgl from 'mapbox-gl';
import { DrawServices } from './mapboxDraw/draw.services';
import { FeatureCollection } from 'geojson';
import { NewDistanceForLine } from './utils/polygon/resizePolygon';
import { Dispatch } from 'redux';
import { buildingAdded } from '../../store/actions/buildingAdded.action';
import { caseAdded } from '../../store/actions/caseAdded.action';
import { createCaseSpecification } from '../../domain/specification/cases/queries/create/createCaseSpecification';
import { setCaseLevelSpecification } from '../../store/actions/setCaseLevelSpecification.action';
import { updateCaseLevelSpecificationGeometry } from '../../store/actions/updateCaseLevelSpecificationGeometry.action';
import { LevelGeometry } from './mapboxDraw/levelGeometry';
import { buildingRemoved } from '../../store/actions/buildingRemoved.action';
import { caseRemoved } from '../../store/actions/caseRemoved.action';
import { LevelId } from '../../domain/granulometry/levels/LevelGranulometry';
import { LayerRepo } from './layer/layer.repo';
import { CaseLayer, Layer, LayerId, LayerType } from './layer/layer.model';
import { setCaseLevelSpecificationWithLevelGeometry } from '../../domain/specification/cases/queries/set/levels/setCaseLevelSpecificationWithLevelGeometry';
import { goToProjectDetailsEditGeometry } from '../../store/actions/navigations/toolbox/projectPanel/goToProjectDetailsEditGeometry.action';
import { removeCaseLevelSpecificationAction } from '../../store/actions/removeCaseLevelSpecification.action';
import { CaseId } from '../../domain/specification/cases/CaseSpecification';

export interface DeleteDrawPayload {
  features: LevelGeometry[];
  drawHelperMode?: boolean;
}

export const events = (
  map: mapboxgl.Map,
  drawServices: DrawServices,
  dispatch: Dispatch,
  layerRepo: LayerRepo,
  projectId: string
) => {
  // DRAW --------------------------------
  map.on('draw.create', (e) => {
    const levelGeometry = drawServices.create(e);
    layerRepo.create({ type: 'level', draw: levelGeometry });
    layerRepo.unselectAll();
    layerRepo.selectTree(levelGeometry.id);
    layerRepo.notifyAll();
    dispatch(
      setCaseLevelSpecification(
        levelGeometry.properties.parentCaseId,
        setCaseLevelSpecificationWithLevelGeometry(levelGeometry)
      )
    );
  });
  map.on('draw.update', (e: DrawUpdateEvent) => {
    if (!e.features || e.features.length === 0) return;
    const levelsGeometries = drawServices.update(e.features as LevelGeometry[]);
    levelsGeometries?.forEach((levelGeometry) =>
      dispatch(updateCaseLevelSpecificationGeometry({ levelGeometry }))
    );
    layerRepo.notifyAll();
  });
  map.on('draw.delete', (e: DeleteDrawPayload) => {
    drawServices.delete(e);
    e.features?.forEach((feature) => {
      const toDelete = layerRepo.delete(feature.id);
      dispatch(
        removeCaseLevelSpecificationAction({
          caseId: feature.properties.parentCaseId,
          levelId: feature.id
        })
      );
      if (layerRepo.needRedirecting(toDelete)) {
        dispatch(goToProjectDetailsEditGeometry(projectId));
      }
    });
  });
  map.on('draw.resize', (e) => {
    const levelGeometry = drawServices.resizeDraw(e);
    dispatch(updateCaseLevelSpecificationGeometry({ levelGeometry }));
  });
  map.on('draw.updateAll', () => drawServices.updateAllDraw());
  map.on('draw.duplicate', ({ id }: { id: LevelId }) => {
    const levelGeometry = drawServices.duplicate(id);
    const newLayer = layerRepo.create({ type: 'level', draw: levelGeometry });
    layerRepo.unselectAll();
    layerRepo.selectTree(newLayer.id);

    dispatch(
      setCaseLevelSpecification(
        levelGeometry.properties.parentCaseId,
        setCaseLevelSpecificationWithLevelGeometry(levelGeometry)
      )
    );
    layerRepo.notifyAll();
  });
  // LAYER --------------------------------
  map.on('layer.delete', ({ id }: { id: LayerId }) => {
    const layer = layerRepo.findById(id);
    if (!layer) throw new Error("Le calque n'existe pas");

    if (layer.type === 'building') {
      const buildings = layerRepo.findByType('building');
      if (buildings?.length === 1) return;
      layerRepo.deleteInCascade(id);
      dispatch(buildingRemoved(id, false));
    }
    if (layer.type === 'case' && layer.parent) {
      const broCases = layerRepo.findBrothers(layer.parent);
      if (broCases?.length === 1) return;
      layerRepo.deleteInCascade(id);
      dispatch(caseRemoved(id, false));
    }
    if (layer.type === 'level') {
      layerRepo.delete(layer.id);
      dispatch(
        removeCaseLevelSpecificationAction({
          caseId: layer.parent as CaseId,
          levelId: layer.id
        })
      );
    }

    layerRepo.notifyAll();
  });
  map.on('layer.create', (payload: { layerType: LayerType; parentLayer: Layer }) => {
    layerRepo.unselectAll();
    if (payload.layerType === 'building') {
      const building = layerRepo.create({ type: 'building' });
      console.log('LALALA building', building);
      const newCase = layerRepo.create({ type: 'case', parentLayer: building });
      dispatch(
        buildingAdded({
          building: {
            id: building.id,
            title: building.title,
            cases: [createCaseSpecification({ id: newCase.id, projectSurfaceInputMode: 'gfsEff' })]
          }
        })
      );
    }
    if (payload.layerType === 'case') {
      const newCase = layerRepo.create({
        type: payload.layerType,
        parentLayer: payload.parentLayer
      }) as CaseLayer;
      layerRepo.selectTree(newCase.id);
      dispatch(
        caseAdded(
          payload.parentLayer.id,
          createCaseSpecification({ id: newCase.id, projectSurfaceInputMode: 'gfsEff' })
        )
      );
    }
    layerRepo.notifyAll();
  });
  map.on('layer.selectTree', ({ ids }: { ids: LayerId[] }) => {
    layerRepo.unselectAll();
    drawServices.unselectAll();
    const selectedIds = layerRepo.selectTree(ids[0]);
    const notLockedlevelWithGeometry = layerRepo.findByIds(selectedIds, {
      filter: { lock: false, type: 'level', withGeometry: true }
    });
    if (notLockedlevelWithGeometry.length > 0) {
      drawServices.select(notLockedlevelWithGeometry?.map((l) => l.id));
    }
    layerRepo.notifyAll();
  });
  map.on('layer.selectWithParent', ({ ids }: { ids: LayerId[] }) => {
    layerRepo.unselectAll();
    drawServices.unselectAll();
    layerRepo.selectWithParent(ids[0]);
    layerRepo.notifyAll();
  });
  map.on('layer.unSelectAll', () => {
    layerRepo.unselectAll();
    drawServices.unselectAll();
    layerRepo.notifyAll();
  });
  map.on('layer.lock', ({ levelGeometry }: { levelGeometry: LevelGeometry }) => {
    layerRepo.lock(levelGeometry);
    layerRepo.notifyAll();
  });
  // MODE --------------------------------
  map.on('mode.change', ({ mode }: { mode: DrawModes }) => {
    drawServices.drawRepo.draw.changeMode(mode);
    layerRepo.notifyAll();
  });
};
export interface ResizeEventPayload {
  selectedFeatures: FeatureCollection;
  size: NewDistanceForLine;
}

export const fireDrawEvents = (map: mapboxgl.Map) => {
  return {
    resize: (payload: ResizeEventPayload) => map.fire('draw.resize', { payload }),
    updateAll: () => map.fire('draw.updateAll'),
    draw: {
      delete: (payload: DeleteDrawPayload) => map.fire('draw.delete', payload)
    },
    duplicate: (payload: { id: LevelId }) => map.fire('draw.duplicate', payload),
    mode: {
      change: (payload: { mode: DrawModes }) => map.fire('mode.change', payload)
    },
    layer: {
      selectTree: (ids: LayerId[]) => map.fire('layer.selectTree', { ids }),
      selectWithParent: (ids: LayerId[]) => map.fire('layer.selectWithParent', { ids }),
      lock: (levelGeometry: LevelGeometry) => map.fire('layer.lock', { levelGeometry }),
      unSelectAll: () => map.fire('layer.unSelectAll'),
      delete: (payload: { id: LayerId }) => map.fire('layer.delete', payload),
      create: (payload: { layerType: LayerType; parentLayer?: Layer }) =>
        map.fire('layer.create', payload)
    }
  };
};
