import * as React from 'react';
import TruncateMarkup from 'react-truncate-markup';
import { IconObject } from '../../ui/Icons/iconObject';
import { Notification } from '../../../domain/notifications/Notification';
import { ERROR_COLOR, INFO_COLOR, WARNING_COLOR } from '../../../constants/appConstants';

interface LineProps {
  notification: Notification;
  explanation?: boolean;
  truncate?: boolean;
  icon?: boolean;
}

const Line = ({ notification, explanation, truncate, icon }: LineProps) => (
  <div className="notification">
    {icon && (
      <div className="icon">
        <IconObject
          color={
            notification.type === 'error'
              ? ERROR_COLOR
              : notification.type === 'warning'
              ? WARNING_COLOR
              : INFO_COLOR
          }
          color2={
            notification.type === 'error'
              ? ERROR_COLOR
              : notification.type === 'warning'
              ? WARNING_COLOR
              : INFO_COLOR
          }
          iconName={
            notification.type === 'error'
              ? 'notificationsError'
              : notification.type === 'warning'
              ? 'notificationsWarning'
              : 'notificationsInfo'
          }
          title={
            notification.type === 'error'
              ? 'Notifications error'
              : notification.type === 'warning'
              ? 'Notifications warning'
              : 'Notifications info'
          }
          type="menu"
        />
      </div>
    )}
    <div className="text">
      <TruncateMarkup lines={truncate ? 4 : 999}>
        <span>
          <b>
            {notification.title}
            {explanation && ' :'}
          </b>{' '}
          {explanation && notification.explanation}
        </span>
      </TruncateMarkup>
    </div>
  </div>
);

export default Line;
