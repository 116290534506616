// Results matrix :
export interface ComEthJsonFeatureOutputs {
  nonLCA_outputs: {
    construction_product: {
      Heating_needs: null;
      Cooling_needs: null;
      DHW_needs: null;
      Lighting_needs: null;
      Parking_Lighting_needs: null;
    };
    water: {
      water_volume_asked_to_network: null;
      DHW_annual: null;
      rainwater_consumed: null;
      rainwater_recovery_volume: null;
      rainwater_tank: null;
    };
    mobility: {
      kilometrage: {};
      modal_share: {};
    };
    energy: {
      backup_share_heating: null;
      backup_share_cooling: null;
      backup_share_dhw: 0;
      elec_building_autoconsumption_share: 0;
      elec_building_autoproduction_share: 0;
      building_elec_autoconsumption_share: 0;
      building_elec_autoproduction_share: 0;
      building_PV_annual_production: 0;
      probable_label: null;
    };
    waste: {
      total_waste_sorting: 0;
      total_biowaste_management: 0;
      total_non_recyclable_management: 0;
      total_waste_collect: 0;
    };
  };
  urban_services: {
    LCA_impacts: null;
    construction_product: {
      // Same results post calculation organised in contributor batch
      LCA_impacts: null; // Total
      'lot-1': {
        LCA_impacts: null;
      };
      'lot-2': {
        LCA_impacts: null;
      };
      'lot-3': {
        LCA_impacts: null;
      };
      'lot-4': {
        LCA_impacts: null;
      };
      'lot-5': {
        LCA_impacts: null;
      };
      'lot-6': {
        LCA_impacts: null;
      };
      'lot-7': {
        LCA_impacts: null;
      };
      'lot-8': {
        LCA_impacts: null;
      };
      'lot-9': {
        LCA_impacts: null;
      };
      'lot-10': {
        LCA_impacts: null;
      };
      'lot-11': {
        LCA_impacts: null;
      };
      'lot-12': {
        LCA_impacts: null;
      };
      'lot-13': {
        LCA_impacts: null;
      };
      'lot-14': {
        LCA_impacts: null;
      };
    };
    energy: {
      // Résultats post calcul
      LCA_impacts: null;
      heating: {
        LCA_impacts: null;
        quantitatives: [];
      };
      dhw: {
        LCA_impacts: null;
        quantitatives: [];
      };
      cooling: {
        LCA_impacts: null;
        quantitatives: [];
      };
      lighting: {
        LCA_impacts: null;
        quantitatives: [];
      };
      ventilation: {
        LCA_impacts: null;
        quantitatives: [];
      };
      other_electricity_uses: {
        LCA_impacts: null;
        quantitatives: [];
      };
    };
    waste: {
      LCA_impacts: null;
      biowaste_management: {
        LCA_impacts: null;
        quantitatives: [];
      };
      non_recyclable_management: {
        LCA_impacts: null;
        quantitatives: [];
      };
      waste_sorting: {
        LCA_impacts: null;
        quantitatives: [];
      };
      waste_collect: {
        LCA_impacts: null;
        quantitatives: [];
      };
    };
    water: {
      LCA_impacts: null;
      drinking_water_grid: {
        LCA_impacts: null;
        quantitatives: [];
      };
      rainwater_recovery: {
        LCA_impacts: null;
        quantitatives: [];
      };
      sewage: {
        LCA_impacts: null;
        quantitatives: [];
      };
    };
    mobility: {
      LCA_impacts: null;
      home_drop_off_trip: {
        LCA_impacts: null;
        particular_vehicule: {
          LCA_impacts: null;
          quantitatives: [];
        };
        bus: {
          LCA_impacts: null;
          quantitatives: [];
        };
        rail_services: {
          LCA_impacts: null;
          quantitatives: [];
        };
        soft_mobility: {
          LCA_impacts: null;
          quantitatives: [];
        };
      };
      home_shopping_trip: {
        LCA_impacts: null;
        particular_vehicule: {
          LCA_impacts: null;
          quantitatives: [];
        };
        bus: {
          LCA_impacts: null;
          quantitatives: [];
        };
        rail_services: {
          LCA_impacts: null;
          quantitatives: [];
        };
        soft_mobility: {
          LCA_impacts: null;
          quantitatives: [];
        };
      };
      home_leisure_trip: {
        LCA_impacts: null;
        particular_vehicule: {
          LCA_impacts: null;
          quantitatives: [];
        };
        bus: {
          LCA_impacts: null;
          quantitatives: [];
        };
        rail_services: {
          LCA_impacts: null;
          quantitatives: [];
        };
        soft_mobility: {
          LCA_impacts: null;
          quantitatives: [];
        };
      };
      home_work_trip: {
        LCA_impacts: null;
        particular_vehicule: {
          LCA_impacts: null;
          quantitatives: [];
        };
        bus: {
          LCA_impacts: null;
          quantitatives: [];
        };
        rail_services: {
          LCA_impacts: null;
          quantitatives: [];
        };
        soft_mobility: {
          LCA_impacts: null;
          quantitatives: [];
        };
      };
      home_study_trip: {
        LCA_impacts: null;
        particular_vehicule: {
          LCA_impacts: null;
          quantitatives: [];
        };
        bus: {
          LCA_impacts: null;
          quantitatives: [];
        };
        rail_services: {
          LCA_impacts: null;
          quantitatives: [];
        };
        soft_mobility: {
          LCA_impacts: null;
          quantitatives: [];
        };
      };
      home_personal_business_trip: {
        LCA_impacts: null;
        particular_vehicule: {
          LCA_impacts: null;
          quantitatives: [];
        };
        bus: {
          LCA_impacts: null;
          quantitatives: [];
        };
        rail_services: {
          LCA_impacts: null;
          quantitatives: [];
        };
        soft_mobility: {
          LCA_impacts: null;
          quantitatives: [];
        };
      };
      home_other_trip: {
        LCA_impacts: null;
        particular_vehicule: {
          LCA_impacts: null;
          quantitatives: [];
        };
        bus: {
          LCA_impacts: null;
          quantitatives: [];
        };
        rail_services: {
          LCA_impacts: null;
          quantitatives: [];
        };
        soft_mobility: {
          LCA_impacts: null;
          quantitatives: [];
        };
      };
      work_related_to_work_trip: {
        LCA_impacts: null;
        particular_vehicule: {
          LCA_impacts: null;
          quantitatives: [];
        };
        bus: {
          LCA_impacts: null;
          quantitatives: [];
        };
        rail_services: {
          LCA_impacts: null;
          quantitatives: [];
        };
        soft_mobility: {
          LCA_impacts: null;
          quantitatives: [];
        };
      };
      other_trip: {
        LCA_impacts: null;
        particular_vehicule: {
          LCA_impacts: null;
          quantitatives: [];
        };
        bus: {
          LCA_impacts: null;
          quantitatives: [];
        };
        rail_services: {
          LCA_impacts: null;
          quantitatives: [];
        };
        soft_mobility: {
          LCA_impacts: null;
          quantitatives: [];
        };
      };
      local_road: {
        LCA_impacts: null;
        quantitatives: [];
      };
    };
  };
}

export const getComEthJsonFeatureOutputs = (): ComEthJsonFeatureOutputs => ({
  nonLCA_outputs: {
    construction_product: {
      Heating_needs: null,
      Cooling_needs: null,
      DHW_needs: null,
      Lighting_needs: null,
      Parking_Lighting_needs: null
    },
    water: {
      water_volume_asked_to_network: null,
      DHW_annual: null,
      rainwater_consumed: null,
      rainwater_recovery_volume: null,
      rainwater_tank: null
    },
    mobility: {
      kilometrage: {},
      modal_share: {}
    },
    energy: {
      backup_share_heating: null,
      backup_share_cooling: null,
      backup_share_dhw: 0,
      elec_building_autoconsumption_share: 0,
      elec_building_autoproduction_share: 0,
      building_elec_autoconsumption_share: 0,
      building_elec_autoproduction_share: 0,
      building_PV_annual_production: 0,
      probable_label: null
    },
    waste: {
      total_waste_sorting: 0,
      total_biowaste_management: 0,
      total_non_recyclable_management: 0,
      total_waste_collect: 0
    }
  },
  urban_services: {
    LCA_impacts: null,
    construction_product: {
      LCA_impacts: null,
      'lot-1': {
        LCA_impacts: null
      },
      'lot-2': {
        LCA_impacts: null
      },
      'lot-3': {
        LCA_impacts: null
      },
      'lot-4': {
        LCA_impacts: null
      },
      'lot-5': {
        LCA_impacts: null
      },
      'lot-6': {
        LCA_impacts: null
      },
      'lot-7': {
        LCA_impacts: null
      },
      'lot-8': {
        LCA_impacts: null
      },
      'lot-9': {
        LCA_impacts: null
      },
      'lot-10': {
        LCA_impacts: null
      },
      'lot-11': {
        LCA_impacts: null
      },
      'lot-12': {
        LCA_impacts: null
      },
      'lot-13': {
        LCA_impacts: null
      },
      'lot-14': {
        LCA_impacts: null
      }
    },
    energy: {
      LCA_impacts: null,
      heating: {
        LCA_impacts: null,
        quantitatives: []
      },
      dhw: {
        LCA_impacts: null,
        quantitatives: []
      },
      cooling: {
        LCA_impacts: null,
        quantitatives: []
      },
      lighting: {
        LCA_impacts: null,
        quantitatives: []
      },
      ventilation: {
        LCA_impacts: null,
        quantitatives: []
      },
      other_electricity_uses: {
        LCA_impacts: null,
        quantitatives: []
      }
    },
    waste: {
      LCA_impacts: null,
      biowaste_management: {
        LCA_impacts: null,
        quantitatives: []
      },
      non_recyclable_management: {
        LCA_impacts: null,
        quantitatives: []
      },
      waste_sorting: {
        LCA_impacts: null,
        quantitatives: []
      },
      waste_collect: {
        LCA_impacts: null,
        quantitatives: []
      }
    },
    water: {
      LCA_impacts: null,
      drinking_water_grid: {
        LCA_impacts: null,
        quantitatives: []
      },
      rainwater_recovery: {
        LCA_impacts: null,
        quantitatives: []
      },
      sewage: {
        LCA_impacts: null,
        quantitatives: []
      }
    },
    mobility: {
      LCA_impacts: null,
      home_drop_off_trip: {
        LCA_impacts: null,
        particular_vehicule: {
          LCA_impacts: null,
          quantitatives: []
        },
        bus: {
          LCA_impacts: null,
          quantitatives: []
        },
        rail_services: {
          LCA_impacts: null,
          quantitatives: []
        },
        soft_mobility: {
          LCA_impacts: null,
          quantitatives: []
        }
      },
      home_shopping_trip: {
        LCA_impacts: null,
        particular_vehicule: {
          LCA_impacts: null,
          quantitatives: []
        },
        bus: {
          LCA_impacts: null,
          quantitatives: []
        },
        rail_services: {
          LCA_impacts: null,
          quantitatives: []
        },
        soft_mobility: {
          LCA_impacts: null,
          quantitatives: []
        }
      },
      home_leisure_trip: {
        LCA_impacts: null,
        particular_vehicule: {
          LCA_impacts: null,
          quantitatives: []
        },
        bus: {
          LCA_impacts: null,
          quantitatives: []
        },
        rail_services: {
          LCA_impacts: null,
          quantitatives: []
        },
        soft_mobility: {
          LCA_impacts: null,
          quantitatives: []
        }
      },
      home_work_trip: {
        LCA_impacts: null,
        particular_vehicule: {
          LCA_impacts: null,
          quantitatives: []
        },
        bus: {
          LCA_impacts: null,
          quantitatives: []
        },
        rail_services: {
          LCA_impacts: null,
          quantitatives: []
        },
        soft_mobility: {
          LCA_impacts: null,
          quantitatives: []
        }
      },
      home_study_trip: {
        LCA_impacts: null,
        particular_vehicule: {
          LCA_impacts: null,
          quantitatives: []
        },
        bus: {
          LCA_impacts: null,
          quantitatives: []
        },
        rail_services: {
          LCA_impacts: null,
          quantitatives: []
        },
        soft_mobility: {
          LCA_impacts: null,
          quantitatives: []
        }
      },
      home_personal_business_trip: {
        LCA_impacts: null,
        particular_vehicule: {
          LCA_impacts: null,
          quantitatives: []
        },
        bus: {
          LCA_impacts: null,
          quantitatives: []
        },
        rail_services: {
          LCA_impacts: null,
          quantitatives: []
        },
        soft_mobility: {
          LCA_impacts: null,
          quantitatives: []
        }
      },
      home_other_trip: {
        LCA_impacts: null,
        particular_vehicule: {
          LCA_impacts: null,
          quantitatives: []
        },
        bus: {
          LCA_impacts: null,
          quantitatives: []
        },
        rail_services: {
          LCA_impacts: null,
          quantitatives: []
        },
        soft_mobility: {
          LCA_impacts: null,
          quantitatives: []
        }
      },
      work_related_to_work_trip: {
        LCA_impacts: null,
        particular_vehicule: {
          LCA_impacts: null,
          quantitatives: []
        },
        bus: {
          LCA_impacts: null,
          quantitatives: []
        },
        rail_services: {
          LCA_impacts: null,
          quantitatives: []
        },
        soft_mobility: {
          LCA_impacts: null,
          quantitatives: []
        }
      },
      other_trip: {
        LCA_impacts: null,
        particular_vehicule: {
          LCA_impacts: null,
          quantitatives: []
        },
        bus: {
          LCA_impacts: null,
          quantitatives: []
        },
        rail_services: {
          LCA_impacts: null,
          quantitatives: []
        },
        soft_mobility: {
          LCA_impacts: null,
          quantitatives: []
        }
      },
      local_road: {
        LCA_impacts: null,
        quantitatives: []
      }
    }
  }
});
