import { createSelector, Selector } from 'reselect';
import * as R from 'ramda';
import { ifDefined } from '../../../utils/ifDefined';
import { selectWoodCubatureByBuildingAndCase } from './woodCubatureByBuildingAndCase.selector';
import { State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';

type WoodCubatureByBuilding = Maybe<Record<string, number>>;

export const selectWoodCubatureByBuilding: Selector<State, WoodCubatureByBuilding> = createSelector(
  [selectWoodCubatureByBuildingAndCase],
  ifDefined(R.mapObjIndexed(R.pipe(R.values, R.sum)))
);
