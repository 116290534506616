import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { CaseGranulometry } from '../../../../granulometry/cases/CaseGranulometry';
import { DEFAULT_CBS_TO_SFS_RATIO } from '../../../../../constants/appConstants';

export const getCaseSurfaceForSaleDetails = (caseGranulometry: CaseGranulometry): string => {
  let details: string[] = [];

  if (caseGranulometry.initialSpecifications.surfaces.surfaceForSale) {
    details = [...details, '<b>SHab saisie pour la cage :</b>'];
  } else {
    details = [...details, '<b>Calcul de la SHab :</b>'];

    details = [...details, '---'];

    details = [
      ...details,
      '<b>' +
        roundWith2Decimal(
          caseGranulometry.initialSpecifications.projectedCuttedBuiltSurface.value
        ) +
        ' m\u00B2</b> de SdP PC'
    ];

    details = [
      ...details,
      '<b>* ' + DEFAULT_CBS_TO_SFS_RATIO + '</b> de ratio Sdp PC/SHab (par défaut)'
    ];
  }

  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(caseGranulometry.initialSpecifications.projectedSurfaceForSale.value) +
      ' m\u00B2</b> de SHab'
  ];

  return details.join('<br />');
};
