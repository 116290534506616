import { createSelector, Selector } from 'reselect';
import * as R from 'ramda';
import { ifDefined } from '../../../utils/ifDefined';
import { selectCostByBuildingAndCase } from './costByBuildingAndCase.selector';
import {
  CostByBuildingAndCase,
  CostByCase
} from '../../../domain/report/queries/getCostByBuildingAndCase';
import { State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';

type CostByCaseType = Record<string, number>;

export const selectCostByCase: Selector<State, Maybe<CostByCaseType>> = createSelector(
  [selectCostByBuildingAndCase],
  ifDefined(
    R.pipe<[CostByBuildingAndCase], CostByCase[], CostByCase>(
      R.values,
      R.reduce<CostByCase, CostByCase>(R.mergeRight, {})
    )
  )
);
