import { createSelector, Selector } from 'reselect';
import { selectParkingsSpecifications } from './parkingsSpecifications.selector';
import { selectCurrentCaseLodgmentsCount } from '../../../../store/selectors/granulometry/currentCase/lodgmentsCount.selector';
import { getTotalCarBoxes } from '../../../../domain/specification/lodgmentTypes/ParkingsSpecification';
import { Maybe } from '../../../../utils/Maybe';
import { State } from '../../../reducers';

export const selectCarBoxesCount: Selector<State, Maybe<number>> = createSelector(
  [selectParkingsSpecifications, selectCurrentCaseLodgmentsCount],
  (parkingsSpecification, currentCaseLodgmentsCount) =>
    parkingsSpecification && currentCaseLodgmentsCount
      ? getTotalCarBoxes(parkingsSpecification, currentCaseLodgmentsCount)
      : undefined
);
