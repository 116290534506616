import { EMPTY, of } from 'rxjs';
import { Action } from 'redux';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { Epic } from 'redux-observable';
import * as OE from 'fp-ts-rxjs/ObservableEither';
import * as TE from 'fp-ts/TaskEither';
import { flow, pipe } from 'fp-ts/function';
import { addBreadcrumb, BREADCRUMB_CATEGORY, captureException } from '../../infra/sentry';
import { ExistingProject, isExistingProject, Project } from '../../domain/project/Project';
import { saveProjectState } from '../../api/v1/saveProjectState.api';
import { selectProject } from '../selectors/project';
import { getProjectState, State } from '../reducers';
import { selectUserId } from '../selectors/user/userId.selector';
import { selectUserToken } from '../selectors/user/userToken.selector';
import { projectSaved, ProjectSavedAction } from '../actions/projectSaved.action';
import { ApiAuthError } from '../../api/v1/errors/auth.error';
import { ApiRedirectError } from '../../api/v1/errors/redirect.error';
import userState, { SessionExpiredAction } from '../reducers/user.slice';
import { projectSaveFailed, ProjectSaveFailedAction } from '../actions/projectSaveFailed.action';
import { handleUnknownError } from '../../utils/handleUnknownError';
import { selectIsStoreRehydrated } from '../selectors/isStoreRehydrated.selector';

const DEBOUNCE_TIME = 1000;

export const saveProjectEpic: Epic<
  Action,
  ProjectSavedAction | ProjectSaveFailedAction | SessionExpiredAction,
  State
> = (actions$, state$) =>
  state$.pipe(
    filter<State>(selectIsStoreRehydrated),
    filter<State>(
      flow(
        getProjectState,
        (projectState) => projectState.current,
        (project: Project | null) => !!project && isExistingProject(project)
      )
    ),
    distinctUntilChanged(
      (previous: State, current: State) =>
        getProjectState(previous).current?.revision === getProjectState(current).current?.revision
    ),
    debounceTime(DEBOUNCE_TIME),
    switchMap((state) => {
      const project = selectProject(state) as ExistingProject;
      const userId = selectUserId(state);
      const userToken = selectUserToken(state);

      if (project && isExistingProject(project) && userId && userToken) {
        return pipe(
          TE.tryCatch(() => saveProjectState(project, userId, userToken), handleUnknownError),
          OE.fromTaskEither,
          OE.map(() => {
            addBreadcrumb(
              `Project ${(project as ExistingProject).id} saved`,
              'log',
              BREADCRUMB_CATEGORY.API
            );

            return projectSaved();
          }),
          OE.getOrElse<Error, ProjectSavedAction | ProjectSaveFailedAction | SessionExpiredAction>(
            (error) => {
              if (error instanceof ApiAuthError || error instanceof ApiRedirectError)
                return of(userState.actions.sessionExpired());

              captureException(error);

              alert(
                'Votre projet n’a pas pu être enregistré. Notre équipe a été informée du problème. Veuillez réessayer ultérieurement.'
              );

              return of(projectSaveFailed(error));
            }
          )
        );
      }

      return EMPTY;
    })
  );
