import { getHighestChildCase } from './getHighestChildCase';
import { getSectionGroupHeight } from './getSectionGroupHeight';

export const getHeight = (element: Element, fromGroup?: boolean) => {
  let height = 0;
  // If the element is a buildings or the buildings wrapper the higher child case height is used
  if (element) {
    const group = element.attributes.getNamedItem('data-group');
    if (element.classList.contains('buildings') || element.classList.contains('building')) {
      const highestChildCase = getHighestChildCase(element);
      if (highestChildCase !== undefined) {
        height = getHeight(getHighestChildCase(element));
      }
    } else if (group && group.nodeValue && fromGroup !== true) {
      // If the element is part of a group :
      height = getSectionGroupHeight(group.nodeValue);
    } else {
      const goodHeight = getComputedStyle(element).height;
      if (goodHeight) {
        height = parseFloat(goodHeight);
      }
    }
  }
  return height;
};
