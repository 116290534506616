import { createSelector, Selector } from 'reselect';
import { State } from '../../../store/reducers';
import { Maybe } from '../../../utils/Maybe';
import { ifDefined } from '../../../utils/ifDefined';
import { selectGranulometry } from './current.selector';
import {
  getLodgmentsCountByBuildingAndCase,
  LodgmentsCountByBuildingAndCase
} from '../../../domain/granulometry/queries/getLodgmentsCountByBuildingAndCase';

export const selectLodgmentsCountByBuildingAndCase: Selector<
  State,
  Maybe<LodgmentsCountByBuildingAndCase>
> = createSelector([selectGranulometry], ifDefined(getLodgmentsCountByBuildingAndCase));
