import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  CaseId,
  CaseSpecification
} from '../../../../../../../domain/specification/cases/CaseSpecification';
import { Option, Select } from '../../../../../../ui/Select';
import { caseSpecificationChanged } from '../../../../../../../store/actions/changeCaseSpecification.action';
import {
  CarpentryOccultationsType,
  CarpentryOccultationsTypeName,
  isCarpentryOccultationsTypeUsePVC,
  isCarpentryOccultationsTypeUseWoodOrMetal
} from '../../../../../../../domain/specification/cases/CarpentryOccultationsType';
import { Checkbox } from '../../../../../../ui/Checkbox';
import { selectCurrentCaseCarpentryDayTimeOccultationsTypeIsMonobloc } from '../../../../../../../store/selectors/specification/currentCase/carpentryDayTimeOccultationsTypeIsMonobloc.selector';
import { FormGroup } from '../../../../../../ui/FormGroup';
import { carpentryOccultationsTypeOptions } from '../CarpentryOccultations';
import { CarpentryOccultationsMotorisationTypeName } from '../../../../../../../domain/specification/cases/CarpentryOccultationsMotorisationType';
import { refreshCarpentryOccultationsMotorisationTypeOptionsState } from './CarpentryOccultationsMotorisationTypeForm';

interface CarpentryDayTimeOccultationsTypeProps {
  caseId: CaseId;
  isSelectedCarpentryTypeUsePVC: boolean;
  selectedCarpentryDayTimeOccultationTypeOption?: Option;
  setSelectedCarpentryDayTimeOccultationTypeOption: React.Dispatch<
    React.SetStateAction<Option | undefined>
  >;
  selectedCarpentryNightTimeOccultationTypeOption?: Option;
  isDayTimeMonoblocDisplayed: boolean;
  setIsDayTimeMonoblocDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
  isSelectedCarpentryOccultationsMotorisationTypeSetForNight: boolean;
  setCarpentryOccultationsMotorisationTypeOptionsState: React.Dispatch<
    React.SetStateAction<Option[]>
  >;
}

export const CarpentryDayTimeOccultationsTypeForm = ({
  caseId,
  isSelectedCarpentryTypeUsePVC,
  selectedCarpentryDayTimeOccultationTypeOption,
  setSelectedCarpentryDayTimeOccultationTypeOption,
  selectedCarpentryNightTimeOccultationTypeOption,
  isDayTimeMonoblocDisplayed,
  setIsDayTimeMonoblocDisplayed,
  isSelectedCarpentryOccultationsMotorisationTypeSetForNight,
  setCarpentryOccultationsMotorisationTypeOptionsState
}: CarpentryDayTimeOccultationsTypeProps) => {
  const carpentryDayTimeOccultationsTypeIsMonobloc = useSelector(
    selectCurrentCaseCarpentryDayTimeOccultationsTypeIsMonobloc
  ) as boolean;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChange = React.useCallback(
    (option: Option) => {
      setSelectedCarpentryDayTimeOccultationTypeOption(option);
      const isDayTimeMonoblocDisplayed =
        isSelectedCarpentryTypeUsePVC &&
        isCarpentryOccultationsTypeUsePVC(option.value as CarpentryOccultationsType);
      setIsDayTimeMonoblocDisplayed(isDayTimeMonoblocDisplayed);
      setCarpentryOccultationsMotorisationTypeOptionsState(
        refreshCarpentryOccultationsMotorisationTypeOptionsState(
          option,
          selectedCarpentryNightTimeOccultationTypeOption
        )
      );
      let caseSpecificationsToChange: Partial<CaseSpecification> = {
        carpentryDayTimeOccultationsType: option.value as CarpentryOccultationsType
      };
      if (!isDayTimeMonoblocDisplayed) {
        caseSpecificationsToChange = {
          ...caseSpecificationsToChange,
          carpentryDayTimeOccultationsTypeIsMonobloc: false
        };
      }
      if (isCarpentryOccultationsTypeUseWoodOrMetal(option.value as CarpentryOccultationsType)) {
        caseSpecificationsToChange = {
          ...caseSpecificationsToChange,
          carpentryOccultationsMotorisationType:
            isSelectedCarpentryOccultationsMotorisationTypeSetForNight &&
            !isCarpentryOccultationsTypeUseWoodOrMetal(
              selectedCarpentryNightTimeOccultationTypeOption?.value as CarpentryOccultationsTypeName
            )
              ? CarpentryOccultationsMotorisationTypeName.NightTime
              : CarpentryOccultationsMotorisationTypeName.None
        };
      }
      dispatch(caseSpecificationChanged(caseId, caseSpecificationsToChange));
    },
    [
      dispatch,
      caseId,
      isSelectedCarpentryTypeUsePVC,
      isSelectedCarpentryOccultationsMotorisationTypeSetForNight,
      selectedCarpentryNightTimeOccultationTypeOption,
      setSelectedCarpentryDayTimeOccultationTypeOption,
      setIsDayTimeMonoblocDisplayed,
      setCarpentryOccultationsMotorisationTypeOptionsState
    ]
  );

  if (!selectedCarpentryDayTimeOccultationTypeOption) return null;

  return (
    <FormGroup>
      <label htmlFor={`case${caseId}CarpentryDayTimeOccultationType`}>
        {t('carpentrySpecifications.carpentryOccultationsType.dayTimeLabel')}
      </label>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyItems: 'center',
          marginTop: '4px'
        }}>
        <Select
          id={`case${caseId}CarpentryDayTimeOccultationType`}
          options={carpentryOccultationsTypeOptions}
          handleChange={handleChange}
          value={selectedCarpentryDayTimeOccultationTypeOption}
          style={{ paddingRight: '8px', width: '148px' }}
        />
        {isDayTimeMonoblocDisplayed && (
          <Checkbox
            label={t('carpentrySpecifications.carpentryOccultationsType.isMonoblocLabel')}
            selected={carpentryDayTimeOccultationsTypeIsMonobloc}
            checkboxName={`case${caseId}CarpentryDayTimeOccultationsTypeIsMonobloc`}
            handleCheck={() =>
              dispatch(
                caseSpecificationChanged(caseId, {
                  carpentryDayTimeOccultationsTypeIsMonobloc:
                    !carpentryDayTimeOccultationsTypeIsMonobloc
                })
              )
            }
            style={{ marginTop: '-4px', width: '140px' }}
          />
        )}
      </div>
    </FormGroup>
  );
};
