import { LodgmentType } from '../../domain/specification/lodgmentTypes/LodgmentType';

export const SET_LODGMENT_TYPE_THEORETICAL_SURFACE = 'SET_LODGMENT_TYPE_THEORETICAL_SURFACE';

export const setLodgmentTypeTheoreticalSurface = (
  caseId: string,
  lodgmentType: LodgmentType,
  surface: number | undefined
) => ({
  type: SET_LODGMENT_TYPE_THEORETICAL_SURFACE,
  payload: { caseId, lodgmentType, surface }
});

export type SetLodgmentTypeSurfaceAction = ReturnType<typeof setLodgmentTypeTheoreticalSurface>;
