import { CaseSpecification } from '../../../CaseSpecification';
import * as R from 'ramda';
import { LevelSpecification } from '../../../../levels/LevelSpecification';
import { isGroundLevel } from '../../../../levels/queries/is/isGroundLevel';
import { isBasementLevel } from '../../../../levels/queries/is/isBasementLevel';
import { getCasePropertyConvertedValue } from '../../get/properties/getCasePropertyConvertedValue';
import { getCaseBasementLevels } from '../../get/levels/getCaseBasementLevels';

export const getLevelHoppersSurface = (
  caseSpecification: CaseSpecification,
  levelSpecification: LevelSpecification
): number => {
  const hoppersSurfaces = [];
  // Smoke extraction ducts
  const smokeExtractionDuctsCount = getCasePropertyConvertedValue(
    caseSpecification,
    'smokeExtractionDuctsCount'
  ) as number;
  if (smokeExtractionDuctsCount !== 0) {
    const smokeExtractionDuctsDepth = getCasePropertyConvertedValue(
      caseSpecification,
      'smokeExtractionDuctsDepth'
    ) as number;
    const smokeExtractionDuctsWidth = getCasePropertyConvertedValue(
      caseSpecification,
      'smokeExtractionDuctsWidth'
    ) as number;
    const smokeExtractionDuctsSurface =
      smokeExtractionDuctsCount * smokeExtractionDuctsDepth * smokeExtractionDuctsWidth;
    hoppersSurfaces.push(smokeExtractionDuctsSurface);
  }

  // Elevator shaft sections
  const elevatorShaftCount = getCasePropertyConvertedValue(
    caseSpecification,
    'elevatorShaftCount'
  ) as number;
  if (elevatorShaftCount !== 0) {
    const elevatorShaftLength = getCasePropertyConvertedValue(
      caseSpecification,
      'elevatorShaftLength'
    ) as number;
    const elevatorShaftWidth = getCasePropertyConvertedValue(
      caseSpecification,
      'elevatorShaftWidth'
    ) as number;
    const elevatorShaftSurface = elevatorShaftCount * elevatorShaftLength * elevatorShaftWidth;
    hoppersSurfaces.push(elevatorShaftSurface);
  }

  // Stairs shaft sections
  const stairsShaftCount = getCasePropertyConvertedValue(
    caseSpecification,
    'stairsShaftCount'
  ) as number;
  if (stairsShaftCount !== 0) {
    const stairsShaftLength = getCasePropertyConvertedValue(
      caseSpecification,
      'stairsShaftLength'
    ) as number;
    const stairsShaftWidth = getCasePropertyConvertedValue(
      caseSpecification,
      'stairsShaftWidth'
    ) as number;
    const stairsShaftBearingLength = getCasePropertyConvertedValue(
      caseSpecification,
      'stairsShaftBearingLength'
    ) as number;
    const stairsShaftBearingWidth = getCasePropertyConvertedValue(
      caseSpecification,
      'stairsShaftBearingWidth'
    ) as number;
    const stairShaftBearingSurface = stairsShaftBearingWidth * stairsShaftBearingLength;
    const stairsShaftSurface =
      stairsShaftCount * stairsShaftLength * stairsShaftWidth - stairShaftBearingSurface;
    hoppersSurfaces.push(stairsShaftSurface);
  }

  // Ramp
  if (
    getCaseBasementLevels(caseSpecification).length !== 0 &&
    caseSpecification.builtInRamp &&
    (isGroundLevel(levelSpecification) || isBasementLevel(levelSpecification))
  ) {
    const rampLength = getCasePropertyConvertedValue(caseSpecification, 'rampLength') as number;
    const rampWidth = getCasePropertyConvertedValue(caseSpecification, 'rampWidth') as number;
    const rampSurface = rampLength * rampWidth;
    hoppersSurfaces.push(rampSurface);
  }
  return R.sum(hoppersSurfaces);
};
