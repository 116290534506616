import * as React from 'react';
import { ReactNode } from 'react';
import { ValidationRule } from '../ValidationsRule';
import { Notification } from '../Notification';
import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { Granulometry } from '../../granulometry/Granulometry';
import { getProjectCasesGranulometries } from '../../specification/project/queries/cases/getProjectCasesGranulometries';
import { getCaseSurfaceForSafeIsFullyFilledValidation } from '../../granulometry/cases/queries/surfaces/getCaseSurfaceForSafeIsFullyFilledValidation';
import { getProjectCasesProjections } from '../../projection/project/queries/cases/getProjectCasesProjections';
import { getCaseLabel } from '../../projection/cases/queries/get/getCaseLabel';
import { getCaseAlreadyProjectedSurfaceForSale } from '../../projection/cases/queries/surfaces/getCaseAlreadyProjectedSurfaceForSale';
import { getCaseFilledSurfaceForSale } from '../../granulometry/cases/queries/surfaces/getCaseFilledSurfaceForSale';
import { roundWith2Decimal } from '../../../utils/round/roundWith2Decimal';
import { getCaseTopLevels } from '../../granulometry/cases/queries/levels/getCaseTopLevels';
import { getLevelFullFilledContent } from '../../granulometry/levels/queries/content/getLevelFullFilledContent';
import { isSectionIncludedInSurfaceForSale } from '../../granulometry/sections/SectionIncludedInSurfaceForSale';
import { getSectionDisplayedSurface } from '../../granulometry/sections/queries/surfaces/getSectionDisplayedSurface';
import { getTopLevelSurfaceForSale } from '../../granulometry/levels/queries/topLevels/surfaces/surfaceForSale/getTopLevelSurfaceForSale';
import { getLevelLabel } from '../../granulometry/levels/LevelGranulometry';

export const caseSurfaceForSaleMustBeExactlyFilled: ValidationRule = (
  projectProjection: ProjectProjection,
  isNewProject?: boolean,
  granulometry?: Granulometry
) => {
  if (granulometry) {
    const caseProjectionsWithIndexes = getProjectCasesProjections(projectProjection);
    const notifications: Notification[] = getProjectCasesGranulometries(granulometry).reduce(
      (accNotifications, caseGranulometry) => {
        const caseProjectionWithIndexes = caseProjectionsWithIndexes.find(
          (caseProjection) => caseProjection.id === caseGranulometry.id
        );
        if (caseProjectionWithIndexes) {
          if (
            !getCaseSurfaceForSafeIsFullyFilledValidation(
              caseProjectionWithIndexes,
              caseGranulometry
            )
          ) {
            const caseProjectedSurfaceForSale =
              getCaseAlreadyProjectedSurfaceForSale(caseProjectionWithIndexes);
            const caseFilledSurfaceForSale = getCaseFilledSurfaceForSale(caseGranulometry);
            const diff = caseProjectedSurfaceForSale.value - caseFilledSurfaceForSale.value;
            const caseLabel = getCaseLabel(caseProjectionWithIndexes);
            const title =
              diff > 0
                ? 'remplir entièrement la surface habitable (SHab)'
                : 'contenir l’ensemble des sections dans la surface habitable (SHab) disponible';
            const explanation =
              diff > 0
                ? 'laisse une partie de la SHab de la cage ' +
                  caseLabel +
                  ' vide sur ' +
                  roundWith2Decimal(diff) +
                  'm\u00B2'
                : 'dépasse la capacité de la SHab de la cage ' +
                  caseLabel +
                  ' de ' +
                  roundWith2Decimal(diff * -1) +
                  'm\u00B2';
            const advise = diff > 0 ? 'de réduire' : 'd’augmenter ';
            const details = getCaseTopLevels(caseGranulometry).reduce(
              (detailsAcc, levelGranulometry, currentIndex) => {
                const levelSfs = getTopLevelSurfaceForSale(
                  caseGranulometry.initialSpecifications,
                  levelGranulometry
                );
                const sectionIncludedInSfs = getLevelFullFilledContent(levelGranulometry).filter(
                  isSectionIncludedInSurfaceForSale
                );
                const filledSfs = sectionIncludedInSfs.reduce(
                  (accFilledSfs, section) => accFilledSfs + getSectionDisplayedSurface(section),
                  0
                );
                return filledSfs > levelSfs
                  ? [
                      ...detailsAcc,
                      <li key={currentIndex}>
                        <b>{getLevelLabel(levelGranulometry.level)} :</b> La somme des surfaces des{' '}
                        {sectionIncludedInSfs.length} sections utiles (={' '}
                        {roundWith2Decimal(filledSfs) + ' m\u00B2'}) contenues dans ce niveau est
                        supérieure de {roundWith2Decimal(filledSfs - levelSfs) + ' m\u00B2'} à la
                        SHab du niveau (= {roundWith2Decimal(levelSfs) + ' m\u00B2'})
                        <br />
                      </li>
                    ]
                  : filledSfs < levelSfs
                  ? [
                      ...detailsAcc,
                      <li key={currentIndex}>
                        <b>{getLevelLabel(levelGranulometry.level)} :</b> La somme des surfaces des{' '}
                        {sectionIncludedInSfs.length} sections utiles (={' '}
                        {roundWith2Decimal(filledSfs) + ' m\u00B2'}) contenues dans ce niveau est
                        inférieure de {roundWith2Decimal(levelSfs - filledSfs) + ' m\u00B2'} à la
                        SHab du niveau (= {roundWith2Decimal(levelSfs) + ' m\u00B2'})
                        <br />
                      </li>
                    ]
                  : detailsAcc;
              },
              [] as ReactNode[]
            );
            return [
              ...accNotifications,
              {
                title:
                  'La configuration actuelle du projet ne permet pas de ' +
                  title +
                  ' pour la cage ' +
                  caseLabel,
                explanation: (
                  <>
                    {'Votre modélisation présente une surface globale et/ou un agencement qui ' +
                      explanation +
                      '. Vous pouvez essayer ' +
                      advise +
                      ' la SHab de la cage ' +
                      caseLabel +
                      ' ou de modifier sa distribution.'}
                    <ul>{details}</ul>
                  </>
                ),
                type: 'warning'
              }
            ];
          }
          return accNotifications;
        }
        return accNotifications;
      },
      [] as Notification[]
    );
    return notifications.length !== 0 ? notifications : true;
  }
  return true;
};
