import { Position } from 'geojson';
import { getRatioToResizeLine, resizeLines } from '../geometry/lineDistance';
import { getCoordinatesFromLines } from '../geometry/coordinates';
import Decimal from 'decimal.js';

export interface NewDistanceForLine {
  lineIndex: number;
  distance: number;
}

export const resizePolygon = (
  coordinates: Position[],
  { distance, lineIndex }: NewDistanceForLine
): { coordinates: Position[]; ratio: Decimal } => {
  const ratio = getRatioToResizeLine(distance, coordinates, lineIndex);
  const lines = resizeLines(coordinates, ratio);

  return { coordinates: getCoordinatesFromLines(coordinates[0], lines), ratio };
};

export const growPolygonAsResizedPolygon = (
  coordinates: Position[],
  ratio: Decimal
): Position[] => {
  const lines = resizeLines(coordinates, ratio);
  return getCoordinatesFromLines(coordinates[0], lines);
};
