import Pagination from './Pagination';
import * as React from 'react';
import ReactTable, { ComponentPropsGetterR, ReactTableDefaults, TableProps } from 'react-table';
import 'react-table/react-table.css';
import classNames from 'classnames';

// ReactTable
// https://github.com/tannerlinsley/react-table/tree/v6#installation

interface DataTableProps<D = any> extends Partial<TableProps<D>> {
  id?: string;
  rowId?: (item: D) => string;
  onTrGroupClickCallback?: (current: D) => void;
  current?: D;
  gobuildCustomProps?: any; // !!! DURTY
}

export const DataTable: React.FC<DataTableProps> = (props) => {
  const getTrGroupPropsValues: ComponentPropsGetterR = (state, rowInfo) => {
    if (rowInfo && rowInfo.row) {
      return {
        onClick: () => {
          if (props.onTrGroupClickCallback) {
            props.onTrGroupClickCallback(rowInfo.original);
          }
        },
        className: classNames({
          selected: props.current && rowInfo.original === props.current,
          'as-custom-value': props.current && props.current.asCustomValue,
          'first-row': rowInfo.index === 0,
          'last-row': rowInfo.index === rowInfo.pageSize - 1
        }),
        id: props.rowId ? props.rowId(rowInfo.original) : ''
      };
    } else {
      return {};
    }
  };

  // Common params
  Object.assign(ReactTableDefaults, {
    defaultPageSize: 5,
    minRows: 5,
    showPaginationTop: true,
    showPaginationBottom: true,
    PaginationComponent: Pagination,
    previousText: '<',
    nextText: '>',
    className: '-striped -highlight',
    filterable: true,
    getTrGroupProps: getTrGroupPropsValues,
    rowsText: 'lignes',
    noDataText: props.loading ? '' : 'Pas de résultat',
    loadingText: 'Mise à jour...'
  });
  ReactTableDefaults.column.minWidth = 40;

  return <ReactTable {...props} getTableProps={() => ({ id: props.id })} />;
};
