import { ValidationRule } from '../ValidationsRule';
import { Notification } from '../Notification';
import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { getProjectCasesGranulometries } from '../../specification/project/queries/cases/getProjectCasesGranulometries';
import { getLevelLabel } from '../../granulometry/levels/LevelGranulometry';
import { getTopLevelRealBuiltSurfaceIsLargeEnoughValidation } from '../../granulometry/levels/queries/topLevels/surfaces/realBuiltSurface/getTopLevelRealBuiltSurfaceIsLargeEnoughValidation';
import { Granulometry } from '../../granulometry/Granulometry';
import { getCaseTopLevels } from '../../granulometry/cases/queries/levels/getCaseTopLevels';

export const caseTopLevelRealBuiltSurfacesMustBeLargeEnough: ValidationRule = (
  projectProjection: ProjectProjection,
  isNewProject?: boolean,
  granulometry?: Granulometry
) => {
  if (granulometry) {
    const notifications: Notification[] = getProjectCasesGranulometries(granulometry).reduce(
      (accNotifications, caseGranulometry) => [
        ...accNotifications,
        ...getCaseTopLevels(caseGranulometry).reduce((accL, levelGranulometry) => {
          const validation = getTopLevelRealBuiltSurfaceIsLargeEnoughValidation(levelGranulometry);
          if (!validation) {
            const caseLabel = caseGranulometry.labelIndex;
            const levelLabel = getLevelLabel(levelGranulometry.level);
            const notification: Notification = {
              title:
                'La configuration actuelle du projet ne permet pas l’intégration de l’ensemble des sections pour le niveau ' +
                levelLabel +
                ' de la cage ' +
                caseLabel,
              explanation:
                'Votre modélisation présente une surface globale ne permettant pas la juxtaposition de l’ensemble des surfaces particulières (SHab, locaux techniques, partie communes,...). Vous pouvez essayer d’augmenter la Sdp réelle du projet, d’augmenter la SHab ou de modifier la distribution.',
              type: 'error'
            };
            return [notification, ...accL];
          }
          return accL;
        }, [] as Notification[])
      ],
      [] as Notification[]
    );
    return notifications.length !== 0 ? notifications : true;
  }
  return true;
};
