import { RE2020Threshold } from '../../domain/project/Project';

export const PROJECT_RE2020_THRESHOLD_CHANGED = 'project/RE2020_type_changed';

export const projectRE2020ThresholdChanged = (threshold: RE2020Threshold) => ({
  type: PROJECT_RE2020_THRESHOLD_CHANGED,
  payload: { threshold }
});

export type ProjectRE2020ThresholdChangedAction = ReturnType<typeof projectRE2020ThresholdChanged>;
