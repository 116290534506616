import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../levels/LevelGranulometry';
import { getBearingTechnicalSheathsWidth } from './getBearingTechnicalSheathsWidth';
import {
  getBearingTechnicalSheathsSurface,
  TECHNICAL_SHEATHS_DEPTH
} from './getBearingTechnicalSheathsSurface';
import { getBearingTechnicalSheathsWidthDetails } from './getBearingTechnicalSheathsWidth.details';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';

export const getBearingTechnicalSheathsSurfaceDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry | LevelGranulometryWithBearingRelativeSections
): string => {
  let details: string[] = [];

  const bearingTechnicalSheathsWidth = getBearingTechnicalSheathsWidth(
    caseGranulometry,
    levelGranulometry
  );

  details = [
    ...details,
    '<b>= ' +
      bearingTechnicalSheathsWidth +
      '</b> m de largeur de gaines :<br /><i>' +
      getBearingTechnicalSheathsWidthDetails(caseGranulometry, levelGranulometry) +
      '</i>'
  ];

  details = [...details, '<b>* ' + TECHNICAL_SHEATHS_DEPTH + '</b> m de profondeur de gaines'];

  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(
        getBearingTechnicalSheathsSurface(caseGranulometry, levelGranulometry).value
      ) +
      ' m\u00B2</b>'
  ];

  return details.join('<br />');
};
