import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from 'components/ui/SpecialLabel';
import { Card } from '../../../../ui/Card';
import { ProjectRE2020Threshold } from './ProjectToleranceRate/ProjectRE2020Thresold';
import { ProjectCstbIndicatorsParametersCard } from './ProjectToleranceRate/ProjectCstbIndicatorsParametersCard';

export const ProjectComEthIcConstructionToleranceRate= () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Card>
      <SpecialLabel label={t('projectCstbIndicatorsParametersCard.label')} />
      {process.env.GB_FEAT_RE2020_THRESHOLD !== 'false' && <ProjectRE2020Threshold />}
      <ProjectCstbIndicatorsParametersCard />
    </Card>
  );
};
