import i18next from 'i18next';
import { BasicRoomSpecification } from '../../specification/rooms/BasicRoomSpecification';

const DEFAULT_BEDROOM_2_LENGTH = 3.2;
const DEFAULT_BEDROOM_2_WIDTH = 3;

const bedroom2 = (): BasicRoomSpecification =>
  ({
    name: 'bedroom2',
    title: i18next.t('Bedroom 2'),
    defaultLength: DEFAULT_BEDROOM_2_LENGTH,
    width: undefined,
    defaultWidth: DEFAULT_BEDROOM_2_WIDTH,
    surface: undefined,
    icon: {
      type: 'object',
      iconName: 'bedroom',
      strokeStyle: 'opened',
      label: '2'
    }
  } as const);

export default bedroom2;
