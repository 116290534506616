import * as R from 'ramda';
import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import { Property } from '../../specification/cases/Property';
import { addCaseProperty } from '../utils/addCaseProperty';
import { defaultCaseProperties } from '../../../resources/defaultCaseProperties';

export const migrateProject33To34 = (project: Project): Project =>
  mapCases((caseSpecification) =>
    R.pipe<[CaseSpecification], CaseSpecification, CaseSpecification>(
      (cS) =>
        addCaseProperty(
          cS,
          defaultCaseProperties.find(
            (property) => property.name === 'currentInsulatingBreakRate'
          ) as Property,
          cS.properties.findIndex(
            (property) => property.name === 'hasDoubledLodgmentInsideInsulating'
          )
        ),
      (cS) =>
        R.over(
          R.lensPath(['properties']),
          R.map((property: Property) =>
            R.pipe<[Property], Property>((p) =>
              p.name === 'hallSurface'
                ? {
                    ...p,
                    min: 1
                  }
                : p
            )(property)
          ),
          cS
        )
    )(caseSpecification)
  )(project);
