import { MaxSurfaceForSaleByLevel } from '../../CaseProjection';

export const getCaseMaxSurfaceForSale = (
  maxSurfaceForSaleByLevels: MaxSurfaceForSaleByLevel[]
): number =>
  maxSurfaceForSaleByLevels.reduce(
    (acc, maxSurfaceForSaleByLevel) =>
      isNaN(maxSurfaceForSaleByLevel.maxSurfaceForSale) ||
      !isFinite(maxSurfaceForSaleByLevel.maxSurfaceForSale)
        ? acc
        : acc + maxSurfaceForSaleByLevel.maxSurfaceForSale,
    0
  );
