import { OTHER_RBS_SECTIONS_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { SectionWithTechnicalSheaths } from '../SectionWithTechnicalSheaths';
import { TechnicalInfraPremiseSectionName } from './TechnicalPremiseSection';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';

interface OpticalFiberInfraSectionProps {
  surface?: number;
  exposureRate?: number;
  width?: number;
}

export interface OpticalFiberInfraSection
  extends Section,
    SectionDrawn,
    SectionWithExposureRate,
    SectionWithTechnicalSheaths {
  name: TechnicalInfraPremiseSectionName.OpticalFiberInfraSection;
  width?: number;
  defaultWidth: number;
  minWidth: number;
  maxWidth: undefined;
  defaultSurface: number;
  minSurface: number;
  maxSurface: undefined;
}

export const OPTICAL_FIBER_INFRA_SECTION_DEFAULT_SURFACE = 6;
export const OPTICAL_FIBER_INFRA_SECTION_MIN_SURFACE = 1;
export const OPTICAL_FIBER_INFRA_SECTION_DEFAULT_EXPOSURE_RATE = 0;
export const OPTICAL_FIBER_INFRA_SECTION_DEFAULT_WITH = 2;
export const OPTICAL_FIBER_INFRA_SECTION_MIN_WITH = 2;
export const OPTICAL_FIBER_INFRA_SECTION_ICON = {
  type: 'object',
  iconName: 'opticalFiber',
  color: OTHER_RBS_SECTIONS_COLOR
} as IconObjectProps;

const opticalFiberInfraSection = ({
                                    surface,
                                    exposureRate,
                                    width
                                  }: OpticalFiberInfraSectionProps): OpticalFiberInfraSection => ({
  // Section
  id: 'NOID',
  name: TechnicalInfraPremiseSectionName.OpticalFiberInfraSection,
  title: i18next.t('opticalFiberSection.title'),
  type: 'others',
  displayedSurface: surface || OPTICAL_FIBER_INFRA_SECTION_DEFAULT_SURFACE,
  // SectionDrawn
  surface: surface || OPTICAL_FIBER_INFRA_SECTION_DEFAULT_SURFACE,
  color: OTHER_RBS_SECTIONS_COLOR,
  icon: OPTICAL_FIBER_INFRA_SECTION_ICON,
  // SectionWithExposureRate
  exposureRate,
  defaultExposureRate: OPTICAL_FIBER_INFRA_SECTION_DEFAULT_EXPOSURE_RATE,
  // SectionWithTechnicalSheaths
  technicalSheathCount: 0,
  // OpticalFiberSection
  width: width || undefined,
  defaultWidth: OPTICAL_FIBER_INFRA_SECTION_DEFAULT_WITH,
  minWidth: OPTICAL_FIBER_INFRA_SECTION_MIN_WITH,
  maxWidth: undefined,
  defaultSurface: OPTICAL_FIBER_INFRA_SECTION_DEFAULT_SURFACE,
  minSurface: OPTICAL_FIBER_INFRA_SECTION_MIN_SURFACE,
  maxSurface: undefined
});

export default opticalFiberInfraSection;

export const isSectionAOpticalFiberInfraSection = (
  section: Section
): section is OpticalFiberInfraSection =>
  getSectionName(section) === TechnicalInfraPremiseSectionName.OpticalFiberInfraSection;

export const filterOpticalFiberInfraSections = (sections: Section[]): OpticalFiberInfraSection[] =>
  sections.filter(isSectionAOpticalFiberInfraSection);
