import { LevelGranulometry } from '../LevelGranulometry';
import { getLevelCeilingHeight } from './getLevelCeilingHeight';

export const LINTEL_OVERHANG = 0.4; // m (de retombé en linteau)
export const SILL_UNDER_GLAZED_SURFACE = 0.2; // = (de seuil sous la partie vitrée)

export const getLevelGlazedHeight = (levelGranulometry: LevelGranulometry): number =>
  Math.max(
    getLevelCeilingHeight(levelGranulometry) - LINTEL_OVERHANG - SILL_UNDER_GLAZED_SURFACE,
    0
  );
