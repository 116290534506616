import { createSelector, Selector } from 'reselect';
import { selectCurrentCaseGranulometry } from './currentCaseGranulometry.selector';
import { State } from '../../../reducers';
import { Surface } from '../../../../domain/specification/Surface';
import { getCaseGrossFloorSurfaceEff } from '../../../../domain/granulometry/cases/queries/surfaces/getCaseGrossFloorSurfaceEff';
import { Maybe } from '../../../../utils/Maybe';

export const selectCurrentCaseGrossFloorSurfaceEff: Selector<
  State,
  Maybe<Surface>
> = createSelector([selectCurrentCaseGranulometry], (caseGranulometry) =>
  caseGranulometry ? getCaseGrossFloorSurfaceEff(caseGranulometry) : undefined
);
