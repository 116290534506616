import i18next from 'i18next';
import { BasicRoomSpecification } from '../../specification/rooms/BasicRoomSpecification';

const DEFAULT_BEDROOM_PMR_C_LENGTH = 3.5;
const DEFAULT_BEDROOM_PMR_C_WIDTH = 3.57;

const bedroomPMRC = (): BasicRoomSpecification =>
  ({
    name: 'bedroomPMRC',
    title: i18next.t('Bedroom PMR (C)'),
    defaultLength: DEFAULT_BEDROOM_PMR_C_LENGTH,
    width: undefined,
    defaultWidth: DEFAULT_BEDROOM_PMR_C_WIDTH,
    surface: undefined,
    icon: {
      type: 'object',
      iconName: 'bedroom',
      strokeStyle: 'opened',
      label: 'PMRC'
    }
  } as const);

export default bedroomPMRC;
