import { CaseGranulometry } from '../../../CaseGranulometry';
import { getCaseGroundLevel } from '../getCaseGroundLevel';
import { Surface } from '../../../../../specification/Surface';
import { getTopLevelGrossFloorSurfaceEff } from '../../../../levels/queries/topLevels/surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurfaceEff';

export const getCaseGroundLevelGrossFloorSurfaceEff = (
  caseGranulometry: CaseGranulometry
): Surface =>
  new Surface(
    getTopLevelGrossFloorSurfaceEff(caseGranulometry, getCaseGroundLevel(caseGranulometry))
  );
