import { createAction } from '@reduxjs/toolkit';
import { Report } from '../../domain/report/ReportEntry';

export const requestReport = createAction('report/request');
export type RequestReportAction = ReturnType<typeof requestReport>;

export const reportFetching = createAction('report/fetching');
export type ReportFetchingAction = ReturnType<typeof reportFetching>;

export const reportFetched = createAction<{
  report: Report;
  projectRevision: string;
}>('report/fetched');
export type ReportFetchedAction = ReturnType<typeof reportFetched>;

export const reportFailed = createAction('report/failed', (error: Error) => ({
  error: true,
  payload: error
}));
export type ReportFailedAction = ReturnType<typeof reportFailed>;
