import { getSectionTitle } from '../../Section';
import { SectionWithExposureRate } from '../../SectionWithExposureRate';

export const getSectionTheoreticalExposureRateDetails = (
  section: SectionWithExposureRate
): string =>
  'Taux d’exposition théorique<b>' +
  (section.exposureRate ? ' saisie' : section.defaultExposureRate ? ' par défaut' : ' ERR!') +
  '</b> pour le type de section <i>' +
  getSectionTitle(section) +
  '</i>';
