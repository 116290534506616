import classNames from 'classnames';
import * as React from 'react';
import { ReactNode } from 'react';

interface CardTitleProps {
  children: ReactNode | ReactNode[];
  className?: string;
}

export const CardTitle = ({ children, className }: CardTitleProps) => (
  <div className={classNames('card-title', className)}>{children}</div>
);
