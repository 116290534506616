import React from 'react';
import { IconObject } from '../../../../../ui/Icons/iconObject';
import { UiGeometry } from '../../Geometry';
import MapboxDraw, { DrawModes } from '@mapbox/mapbox-gl-draw';
import { Note } from '../../../../../ui/Note';
import { useTranslation } from 'react-i18next';
import { isDrawHelperFeatureEnabled } from '../../../../../../services/mapBox/mapboxDraw/draw.model';
import mapboxgl from 'mapbox-gl';
import {
  getRotateLevelMode,
  ROTATE_LEVEL_MODE
} from '../../../../../../services/mapBox/mode/RotateLevelMode';
import classNames from 'classnames';
import { useLayerObserver } from '../../../../../../services/mapBox/hook/useLayerObserver';
import { fireDrawEvents } from '../../../../../../services/mapBox/events';

interface MapCardProps {
  mapContainer: React.MutableRefObject<HTMLDivElement>;
  ui: UiGeometry;
  setMode: () => void;
  drawHelperMode: boolean;
}

export const MapCard = ({ ui, setMode, drawHelperMode }: MapCardProps) => {
  const { t } = useTranslation();
  useLayerObserver(ui.layerRepo);
  const currentDrawMode = (ui.draw as MapboxDraw)?.getMode();
  const actionStatus = ui.layerRepo?.getMiddleCardActionStatus(currentDrawMode);

  const changeMode = (mode: string) =>
    fireDrawEvents(ui.map).mode.change({ mode: mode as unknown as DrawModes });

  return (
    <>
      {ui.map && ui.draw && (
        <>
          <div className="mapControls">
            <IconObject
              iconName="add"
              type="menu"
              className="add"
              onClick={() => (ui.map as mapboxgl.Map).zoomIn()}
              title={t('geometryWindow.floorSpaceMapCard.button.zoom.in')}
            />
            <IconObject
              iconName="minus"
              type="menu"
              className="minus"
              onClick={() => (ui.map as mapboxgl.Map).zoomOut()}
              title={t('geometryWindow.floorSpaceMapCard.button.zoom.out')}
            />
            <IconObject
              key={'1'}
              iconName="drawTopLevel"
              type="menu"
              className="drawTopLevel"
              onClick={() => {
                ui.drawServices.currentMode = 'top';
                changeMode('draw_polygon');
              }}
              disabled={actionStatus.drawTopLevel.disabled}
              title={t('geometryWindow.floorSpaceMapCard.button.draw.top')}
            />
            <IconObject
              key={'0'}
              iconName="drawBasementLevel"
              type="menu"
              className="drawBasementLevel"
              onClick={() => {
                ui.drawServices.currentMode = 'basement';
                changeMode('draw_polygon');
              }}
              disabled={actionStatus.drawBasementLevel.disabled}
              title={t('geometryWindow.floorSpaceMapCard.button.draw.basement')}
            />
            <IconObject
              key={'2'}
              iconName="rotate"
              type="menu"
              className={classNames('rotate', {
                active: currentDrawMode === ROTATE_LEVEL_MODE
              })}
              disabled={actionStatus.rotate.disabled}
              onClick={getRotateLevelMode(ui.draw, ui.layerRepo)}
              title={t('geometryWindow.floorSpaceMapCard.button.rotate')}
            />
            <IconObject
              key={'3'}
              iconName="remove"
              type="menu"
              className="remove"
              onClick={() => (ui.drawServices.drawRepo.draw as MapboxDraw).trash()}
              disabled={actionStatus.remove.disabled}
              title={t('geometryWindow.floorSpaceMapCard.button.trash')}
            />
            {isDrawHelperFeatureEnabled() && (
              <IconObject
                iconName="pdf"
                type="menu"
                className={classNames('pdf', { active: drawHelperMode })}
                onClick={setMode}
                disabled={currentDrawMode === 'direct_select'}
                title={t('geometryWindow.floorSpaceMapCard.button.pdf')}
              />
            )}
            <Note>{ui.layerRepo.getModeNameByCurrentDrawMode(currentDrawMode)}</Note>
          </div>
          {(currentDrawMode === 'draw_polygon' ||
            currentDrawMode === ROTATE_LEVEL_MODE ||
            currentDrawMode === 'direct_select') && (
            <div className="mapNotes">
              <Note>{t('geometryWindow.drawInstructions')}</Note>
            </div>
          )}
          {/*
            (currentDrawMode === 'static' || currentDrawMode === 'simple_select') && (
            <div className="mapNotes">
              <Note>{t('geometryWindow.startingInstructions')}</Note>
            </div>
          )
          */}
        </>
      )}
    </>
  );
};
