import { createSelector, Selector } from 'reselect';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';
import { isBasementLevel } from '../../../../domain/specification/levels/queries/is/isBasementLevel';
import { selectCurrentCaseSpecification } from './specification.selector';
import { isProjectInputModeGfsEff } from '../../../../domain/specification/project/queries/is/isProjectInputModeGfsEff';

export const selectCurrentCaseSpecifiedBasementLevelsCount: Selector<
  State,
  Maybe<number>
> = createSelector([selectCurrentCaseSpecification], (caseSpecification) =>
  isProjectInputModeGfsEff(caseSpecification)
    ? caseSpecification.levels.reduce(
        (acc, levelSpecification) => (isBasementLevel(levelSpecification) ? acc + 1 : acc),
        0
      )
    : caseSpecification.basementLevelsCount
);
