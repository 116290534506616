import { LevelGranulometry } from '../../levels/LevelGranulometry';
import { CaseGranulometry } from '../../cases/CaseGranulometry';
import { createBasementLevelOutsideSlabSection } from '../../levels/queries/basementLevels/sections/create/createBasementLevelOutsideSlabSection';
import { getLevelAbove } from '../../levels/queries/getLevelAbove';

export const updateBasementLevelOutsideSlab = (
  levelGranulometry: LevelGranulometry,
  caseGranulometry: CaseGranulometry
): LevelGranulometry => {
  const levelAbove = getLevelAbove(caseGranulometry, levelGranulometry);
  if (levelAbove && levelGranulometry.surface > levelAbove.surface) {
    // Add outside slab section (for backend only)
    levelGranulometry.outsideSlab = createBasementLevelOutsideSlabSection(
      levelGranulometry.surface - levelAbove.surface
    );
  } else {
    levelGranulometry.outsideSlab = undefined;
  }
  return levelGranulometry;
};
