import * as R from 'ramda';
import { TechnicalPremiseSection } from '../../granulometry/sections/technicalPremiseSections/TechnicalPremiseSection';
import { CommonPremiseSection } from '../../granulometry/sections/commonPremiseSections/CommonPremiseSection';
import { ShopSection } from '../../granulometry/sections/shopSection/shopSection';
import { OfficeSection } from '../../granulometry/sections/officeSection/officeSection';
import { LevelSpecification } from './LevelSpecification';

export interface BasementLevelSpecification extends LevelSpecification {
  parkingRate?: number;
  ceilingHeight?: number;
  outsideWallsLinear?: number;
  formworkBenchLength?: number;
  contiguousAgainstBuiltLinear?: number;
  contiguousUnderBuiltLinear?: number;
  contiguousNotBuiltLinear?: number;
  technicalPremiseSections?: TechnicalPremiseSection[];
  commonPremiseSections?: CommonPremiseSection[];
  shopSections?: ShopSection[];
  officeSections?: OfficeSection[];
}

export interface FirstBasementLevelSpecification extends BasementLevelSpecification {
  level: -1;
}

type GetBasementLevelsTotalParkingRate = (levels: BasementLevelSpecification[]) => number;
export const getLevelsTotalParkingRate: GetBasementLevelsTotalParkingRate = R.compose(
  R.reduce(R.add, 0),
  R.map<BasementLevelSpecification, number>(R.propOr(0, 'parkingRate'))
);

type HasAnyUnsetLevelRealParkingRate = (levels: BasementLevelSpecification[]) => boolean;
export const hasAnyUnsetLevelParkingRate: HasAnyUnsetLevelRealParkingRate = R.any(
  (level: BasementLevelSpecification) => R.isNil(level.parkingRate)
);

export const isBasementLevelsParkingRateRepartitionValid = (
  basementLevelsSpecifications: BasementLevelSpecification[]
) => {
  if (!basementLevelsSpecifications) {
    return false;
  }

  if (basementLevelsSpecifications.length < 2) {
    return true;
  }

  if (R.any((l) => Boolean(l.parkingRate && l.parkingRate < 0), basementLevelsSpecifications)) {
    return false;
  }

  const hasAnyUnset = hasAnyUnsetLevelParkingRate(basementLevelsSpecifications);
  const totalSet = getLevelsTotalParkingRate(basementLevelsSpecifications);

  if (hasAnyUnset) {
    return totalSet <= 100;
  }

  return totalSet === 100;
};
