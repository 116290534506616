import * as React from 'react';

interface ToolboxWindowsProps {
  children: React.ReactNode;
}

export const ToolboxWindows = ({ children }: ToolboxWindowsProps) => {
  // TODO : DOM manipulation = durty
  React.useEffect(() => {
    const toolboxMenu = document.querySelector('.toolbox .icon-menu');
    if (toolboxMenu !== null) {
      toolboxMenu.classList.add('windows-open');
      const cleanup = () => {
        toolboxMenu.classList.remove('windows-open');
      };
      return cleanup;
    }
  });

  return <div className="toolboxWindows">{children}</div>;
};
