import { SectionWithGlazedSurface } from '../../SectionWithGlazedSurface';
import { LevelGranulometry } from '../../../levels/LevelGranulometry';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import { getSectionGlazedLinear } from '../getSectionGlazedLinear';
import { SectionWithExposureRate } from '../../SectionWithExposureRate';
import { getLevelGlazedHeight } from '../../../levels/queries/getLevelGlazedHeight';

export const getSectionGlazedSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry,
  section: SectionWithGlazedSurface & SectionWithExposureRate
): number =>
  getSectionGlazedLinear(caseGranulometry, levelGranulometry, section) *
  getLevelGlazedHeight(levelGranulometry);
