import { LevelGranulometryFullFilled } from '../../../LevelGranulometry';
import { isSectionAElevatorShaft } from '../../../../sections/circulationSections/elevatorShaft';
import { isSectionARamp } from '../../../../sections/circulationSections/ramp';
import { isSectionAStairsShaft } from '../../../../sections/circulationSections/stairsShaft';
import { isSectionASmokeExtractionDucts } from '../../../../sections/circulationSections/smokeExtractionDucts';
import { mustHaveSmokeExtractionDuctSections } from '../../mustHave/mustHaveSmokeExtractionDuctSections';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { getSectionDisplayedSurface } from '../../../../sections/queries/surfaces/getSectionDisplayedSurface';
import { mustHaveElevatorShaftSections } from '../../mustHave/mustHaveElevatorShaftSections';
import { mustHaveStairShaftSections } from '../../mustHave/mustHaveStairShaftSections';
import { getLevelFullFilledContent } from '../../content/getLevelFullFilledContent';

export const getTopLevelHoppersSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled,
  rampExcluded?: boolean
): number => {
  let hoppersSurface = 0;
  if (mustHaveSmokeExtractionDuctSections(caseGranulometry)) {
    getLevelFullFilledContent(levelGranulometry)
      .filter(isSectionASmokeExtractionDucts)
      .forEach((section) => {
        hoppersSurface += getSectionDisplayedSurface(section);
      });
  }

  if (mustHaveElevatorShaftSections(caseGranulometry)) {
    getLevelFullFilledContent(levelGranulometry)
      .filter(isSectionAElevatorShaft)
      .forEach((section) => {
        hoppersSurface += getSectionDisplayedSurface(section);
      });
  }

  if (mustHaveStairShaftSections(caseGranulometry)) {
    getLevelFullFilledContent(levelGranulometry)
      .filter(isSectionAStairsShaft)
      .forEach((section) => {
        const stairShaftSurface = getSectionDisplayedSurface(section);
        const stairShaftBearingSurface =
          (section?.bearingWidth || 0) * (section?.bearingLength || 0);
        hoppersSurface += stairShaftSurface - stairShaftBearingSurface;
      });

    if (!rampExcluded) {
      hoppersSurface +=
        getLevelFullFilledContent(levelGranulometry).find(isSectionARamp)?.displayedSurface || 0;
    }
  }

  return hoppersSurface;
};
