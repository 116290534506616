import { Action } from 'redux';

export const LOAD_PROJECT = 'LOAD_PROJECT';

export interface LoadProjectAction extends Action {
  type: typeof LOAD_PROJECT;
  payload: { projectId: string };
}

export const loadProject = (projectId: string): LoadProjectAction => ({
  type: LOAD_PROJECT,
  payload: { projectId }
});
