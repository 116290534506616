import { matchesPath } from '../../utils/matchesPath';
import { orCreate } from '../../utils/orCreate';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type ProjectDetailsRouteParams = { projectId: string };
export const projectDetailsRoute: RouteWithMakePath<ProjectDetailsRouteParams> = {
  path: `/project/:projectId/details`,
  makePath: ({ projectId }) => `/project/${orCreate(projectId)}/details`,
  matchesPath: matchesPath<ProjectDetailsRouteParams>(/\/project\/([^/]+)\/details/, {
    projectId: 1
  })
};
