import classNames from 'classnames';
import * as React from 'react';

interface CheckboxProps {
  label?: string | React.ReactNode;
  checkboxName: string;
  disabled?: boolean;
  selected?: boolean;
  defaultSelected?: boolean;
  className?: string;
  handleCheck?: (name: string) => void;
  style?: React.CSSProperties;
  icon?: React.ReactNode;
}

export const Checkbox = ({
  checkboxName,
  className,
  disabled,
  handleCheck,
  label,
  selected,
  defaultSelected,
  style,
  icon
}: CheckboxProps) => {
  const changeHandler = handleCheck ? () => handleCheck(checkboxName) : undefined;

  return (
    <div
      className={classNames(
        'gb-checkbox',
        { 'gb-checkbox--disabled': disabled },
        { 'gb-checkbox--withIcon': icon },
        { 'gb-checkbox--default': defaultSelected },
        { 'gb-checkbox--selected': selected },
        className
      )}
      style={style}
      onClick={changeHandler}>
      {icon && <div onClick={changeHandler}>{icon}</div>}
      {label && <label htmlFor={checkboxName}>{label}</label>}
      <input
        type="checkbox"
        name={checkboxName}
        onChange={changeHandler}
        checked={selected}
        disabled={disabled}
      />
      <span className="checkmark" />
    </div>
  );
};
