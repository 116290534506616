import { cleanFileName } from '../utils/cleanFileName';
import { ComEnvJson } from '../../../../../api/cstb/comEnv/makeComEnvJson';

export const downloadComEnv = async (comEnvJson: ComEnvJson, projectName: string) => {
  const fileName =
    'gobuild-' +
    cleanFileName(projectName) +
    '-comenv-' +
    new Date().toISOString().slice(0, 10) +
    '.json';
  const json = JSON.stringify(comEnvJson);
  const blob = new Blob([json], { type: 'application/json' });
  const href = await URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
