import { CaseEditingStage } from '../../domain/specification/cases/CaseSpecification';

export const CASE_EDITING_STAGE_CHANGED = 'CASE_EDITING_STAGE_CHANGED';

export const caseEditingStageChanged = (caseId: string, editingStage: CaseEditingStage) => ({
  type: CASE_EDITING_STAGE_CHANGED,
  payload: { caseId, editingStage }
});

export type CaseEditingStageChangedAction = ReturnType<typeof caseEditingStageChanged>;
