import { Maybe } from '../../../utils/Maybe';
import { ReportEntry } from '../../../domain/report/ReportEntry';

export interface ComEnvJson {
  calculResource: {
    ForceMasterStandard: true;
    CalculMode: 1;
    FullResultsMode: true;
    ComEnvReferential: 'RE2020';
    EpdList: [];
  };
  projet: ComEnvJsonProject;
}

export interface ComEnvJsonProject {
  type: 'Project';
  ObjectId: number;
  DVP: 50;
  Children: ComEnvJsonBuilding[] | [] | { [index: string]: ComEnvJsonBuilding[] };
}

export interface ComEnvJsonBuilding {
  type: 'Building';
  ObjectId: number;
  Children: ComEnvJsonCage[] | [] | { [index: string]: ComEnvJsonCage[] };
}

export interface ComEnvJsonCage {
  type: 'ZoneCore';
  ObjectId: number;
  ComponentContributor: {
    ObjectId: number;
    Children: ComEnvJsonLevel[] | [] | { [index: string]: ComEnvJsonLevel[] };
  };
}

export interface ComEnvJsonLevel {
  ObjectId: number;
  Children: ComEnvJsonSection[] | [] | { [index: string]: ComEnvJsonSection[] };
}

export interface ComEnvJsonSection {
  ObjectId: number;
  Children: ComEnvJsonProduct[] | [] | { [index: string]: ComEnvJsonProduct[] };
}

export interface ComEnvJsonProduct {
  type: 'Component';
  ObjectId: number;
  Quantity: number;
  LifeTime: number;
  IdEPD: string;
  IdBase: 0;
}

export const makeComEnvJson = (report: Maybe<ReportEntry[]>): ComEnvJson => {
  let uniqId = 1;

  // STEP 1 : Fill the json structure
  const projectChildrenWithKeys: { [index: string]: any } = {};
  report?.forEach((reportEntry) => {
    // A product must be fully filled to get inserted in the json
    if (
      reportEntry.building !== '' &&
      reportEntry.case !== '' &&
      reportEntry.level !== '' &&
      reportEntry.section !== '' &&
      reportEntry.product !== '' &&
      reportEntry.co2Quantity !== 0 &&
      reportEntry.co2Id !== '' &&
      reportEntry.co2Id.match(/[A-Za-z]/) === null // ex : HRE, FDES?
    ) {
      // Building
      if (reportEntry.building !== '' && !(reportEntry.building in projectChildrenWithKeys)) {
        const newBuilding: ComEnvJsonBuilding = {
          type: 'Building',
          ObjectId: uniqId++,
          Children: {}
        };
        projectChildrenWithKeys[reportEntry.building] = newBuilding;
      }
      // Case
      if (
        reportEntry.case !== '' &&
        !(reportEntry.case in projectChildrenWithKeys[reportEntry.building].Children)
      ) {
        const newCage: ComEnvJsonCage = {
          type: 'ZoneCore',
          ObjectId: uniqId++,
          ComponentContributor: {
            ObjectId: uniqId++,
            Children: {}
          }
        };
        projectChildrenWithKeys[reportEntry.building].Children[reportEntry.case] = newCage;
      }
      // Level
      if (
        !(
          reportEntry.level in
          projectChildrenWithKeys[reportEntry.building].Children[reportEntry.case]
            .ComponentContributor.Children
        )
      ) {
        const newLevel: ComEnvJsonLevel = {
          ObjectId: uniqId++,
          Children: {}
        };
        projectChildrenWithKeys[reportEntry.building].Children[
          reportEntry.case
        ].ComponentContributor.Children[reportEntry.level] = newLevel;
      }
      // Section
      if (
        !(
          reportEntry.section in
          projectChildrenWithKeys[reportEntry.building].Children[reportEntry.case]
            .ComponentContributor.Children[reportEntry.level].Children
        )
      ) {
        const newSection: ComEnvJsonSection = {
          ObjectId: uniqId++,
          Children: {}
        };
        projectChildrenWithKeys[reportEntry.building].Children[
          reportEntry.case
        ].ComponentContributor.Children[reportEntry.level].Children[reportEntry.section] =
          newSection;
      }
      // Product
      if (
        !(
          reportEntry.product in
          projectChildrenWithKeys[reportEntry.building].Children[reportEntry.case]
            .ComponentContributor.Children[reportEntry.level].Children[reportEntry.section].Children
        )
      ) {
        const newProduct: ComEnvJsonProduct = {
          type: 'Component',
          ObjectId: uniqId++,
          Quantity: reportEntry.co2Quantity,
          LifeTime: reportEntry.co2Lifetime,
          IdEPD: reportEntry.co2Id,
          IdBase: 0
        };
        projectChildrenWithKeys[reportEntry.building].Children[
          reportEntry.case
        ].ComponentContributor.Children[reportEntry.level].Children[reportEntry.section].Children[
          reportEntry.product
        ] = newProduct;
      }
    }
  });
  // console.log('Project children with keys', projectChildrenWithKeys);

  // STEP 2 : Cleaning the unwanted keys
  const cleanedProjectChildren: ComEnvJsonBuilding[] | [] = [];
  // Building
  const buildingValues: ComEnvJsonBuilding[] = Object.values(projectChildrenWithKeys);
  for (let i = 0; i < buildingValues.length; i++) {
    const building = buildingValues[i];
    const cleanedBuilding: ComEnvJsonBuilding = { ...building, Children: [] };
    // Cage
    const cageValues: ComEnvJsonCage[] = Object.values(building.Children);
    for (let j = 0; j < cageValues.length; j++) {
      const cage = cageValues[j];
      const cleanedCage: ComEnvJsonCage = {
        ...cage,
        ComponentContributor: { ...cage.ComponentContributor, Children: [] }
      };
      // Level
      const levelValues: ComEnvJsonLevel[] = Object.values(cage.ComponentContributor.Children);
      for (let k = 0; k < levelValues.length; k++) {
        const level = levelValues[k];
        const cleanedLevel: ComEnvJsonLevel = { ...level, Children: [] };
        // Section
        const sectionValues: ComEnvJsonSection[] = Object.values(level.Children);
        for (let l = 0; l < sectionValues.length; l++) {
          const section = sectionValues[l];
          const cleanedSection: ComEnvJsonSection = {
            ...section,
            Children: Object.values(section.Children)
          };
          cleanedLevel.Children.push(cleanedSection);
        }
        cleanedCage.ComponentContributor.Children.push(cleanedLevel);
      }
      cleanedBuilding.Children.push(cleanedCage);
    }
    cleanedProjectChildren.push(cleanedBuilding);
  }
  // console.log('Project children without keys', cleanedProjectChildren);

  const json = {
    calculResource: {
      ForceMasterStandard: true,
      CalculMode: 1,
      FullResultsMode: true,
      ComEnvReferential: 'RE2020',
      EpdList: []
    },
    projet: {
      type: 'Project',
      ObjectId: 0,
      DVP: 50,
      Children: cleanedProjectChildren
    }
  };

  return json;
};
