import * as React from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { TopLevelSurfaceName } from '../../../../domain/granulometry/cases/queries/surfaces/getCaseTopLevelsSurfaces';
import { Option, Select } from '../../../ui/Select';
import { useSelector } from 'react-redux';
import { selectCurrentCaseLabel } from '../../../../store/selectors/project/currentCaseLabel.selector';

interface Column4Props {
  isToolboxEnlarged: boolean;
  selectedLevelSurface: TopLevelSurfaceName;
  setSelectedLevelSurface: React.Dispatch<React.SetStateAction<TopLevelSurfaceName>>;
}

export const Column4 = ({
  isToolboxEnlarged,
  selectedLevelSurface,
  setSelectedLevelSurface
}: Column4Props) => {
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const { t } = useTranslation();

  const selectLevelSurfaceOptions: Option[] = [
    { label: t('GFS EFF'), value: 'grossFloorSurfaceEff' },
    { label: t('GFS'), value: 'grossFloorSurface' },
    { label: t('RBS'), value: 'realBuiltSurface' },
    { label: t('SFS'), value: 'surfaceForSale' }
  ];

  return (
    <div className="column-4">
      {isToolboxEnlarged ? (
        selectLevelSurfaceOptions.reduce(
          (acc, surfaceOption, index) => [
            ...acc,
            <div key={index} className="cell">
              {process.env.GB_FEAT_GFS !== 'true' &&
              (surfaceOption.label === t('GFS') || surfaceOption.label === t('GFS EFF'))
                ? ''
                : surfaceOption.label}
            </div>
          ],
          [] as React.ReactNode[]
        )
      ) : (
        <div className="cell">
          <Select
            id={`case${caseLabel}SelectedLevelSurface`}
            size="small"
            options={
              process.env.GB_FEAT_GFS === 'true'
                ? selectLevelSurfaceOptions
                : R.filter<Option>((o) => o.label !== t('GFS') && o.label !== t('GFS EFF'))(
                    selectLevelSurfaceOptions
                  )
            }
            handleChange={(props: Option) => {
              setSelectedLevelSurface(props.value as TopLevelSurfaceName);
            }}
            value={selectLevelSurfaceOptions.find((o) => o.value === selectedLevelSurface)}
            style={{ marginLeft: '6px', flexBasis: '150px' }}
          />
        </div>
      )}
    </div>
  );
};
