import i18next from 'i18next';
import { LodgmentSection } from './LodgmentSection';
import { LodgmentTypeSpecification } from '../../../specification/lodgmentTypes/LodgmentTypeSpecification';
import { LodgmentType } from '../../../specification/lodgmentTypes/LodgmentType';
import { T1_COLOR } from '../../../../constants/appConstants';
import kitchen from '../../rooms/kitchen';
import livingRoom from '../../rooms/livingRoom';
import bathroom from '../../rooms/bathroom';
import balcony from '../../rooms/balcony';
import livingRoomKitchenK from '../../rooms/livingRoomKitchenK';
import { HeatingSystem } from '../../../specification/lodgmentTypes/HeatingSystem';

export const t1 = (): LodgmentSection & LodgmentTypeSpecification => ({
  // Section
  id: 'NOID',
  name: LodgmentType.T1,
  title: i18next.t('t1.title'),
  type: 'lodgment',
  // SectionDrawn
  surface: 35,
  displayedSurface: 35,
  color: T1_COLOR,
  icon: {
    type: 'object',
    customText: 'T1',
    customTextPosition: 'bottom right',
    iconName: 'lodgment',
    color: T1_COLOR
  },
  // SectionWithTheoreticalSurface
  theoreticalSurface: undefined,
  defaultTheoreticalSurface: 35,
  // SectionWithMinimumSurface
  minimumDisplayedSurface: 20,
  // SectionWithExposureRate
  exposureRate: undefined,
  defaultExposureRate: 1,
  // LodgmentSection
  lodgmentType: LodgmentType.T1,
  rooms: [
    { ...livingRoomKitchenK(), icon: { ...livingRoomKitchenK().icon, color: T1_COLOR } },
    { ...kitchen(), icon: { ...kitchen().icon, color: T1_COLOR } },
    { ...livingRoom(), icon: { ...livingRoom().icon, color: T1_COLOR } },
    { ...bathroom(), icon: { ...bathroom().icon, color: T1_COLOR } },
    { ...balcony(), icon: { ...balcony().icon, color: T1_COLOR } }
  ],
  heatingSystem: undefined,
  defaultHeatingSystem: HeatingSystem.CollectiveHeatPumpOnGroundWaterHeating,
  // SectionWithTechnicalSheaths
  technicalSheathCount: 0,
  // LodgmentTypeSpecification
  nextType: LodgmentType.T2C
});
