import { CaseId } from '../../../../../domain/specification/cases/CaseSpecification';
import { LevelId } from '../../../../../domain/granulometry/levels/LevelGranulometry';
import { Action } from 'redux';
import { push } from 'connected-react-router';
import { caseGranulometryEditLevelGeometryRoute } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditLevelGeometryRoute';

export const goToCaseGranulometryEditLevelGeometry = (
  projectId: string,
  caseId: CaseId,
  levelId: LevelId
): Action => push(caseGranulometryEditLevelGeometryRoute.makePath({ projectId, caseId, levelId }));
