import { getSectionPartitionWallsSurface } from '../../../../../sections/queries/walls/partitionWalls/getSectionPartitionWallsSurface';
import { LodgmentSection } from '../../../../../sections/lodgmentSections/LodgmentSection';
import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../../LevelGranulometry';
import { getTopLevelLodgmentSections } from '../../sections/getTopLevelLodgmentSections';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { Surface } from '../../../../../../specification/Surface';

export const getTopLevelLodgmentsPartitionWallsSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithBearingRelativeSections | LevelGranulometry
): Surface =>
  new Surface(
    getTopLevelLodgmentSections(levelGranulometry).reduce(
      (acc, lodgmentSection: LodgmentSection) =>
        acc + getSectionPartitionWallsSurface(caseGranulometry, levelGranulometry, lodgmentSection),
      0
    )
  );
