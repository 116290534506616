import { ReportEntry } from '../../domain/report/ReportEntry';

export const sortReportByRefSsLot2 = (reportEntries: ReportEntry[]): ReportEntry[] =>
  reportEntries.sort(function (a, b) {
    const aInt = parseInt(a.refSsLot2.toString().replace(/\./gm, ''));
    const bInt = parseInt(b.refSsLot2.toString().replace(/\./gm, ''));
    if (typeof aInt === 'number' && typeof bInt === 'number') {
      if (aInt < bInt) {
        return -1;
      }
      if (aInt > bInt) {
        return 1;
      }
    }
    return 0;
  });
