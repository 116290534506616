import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from 'components/ui/SpecialLabel';
import { Card } from '../../../../ui/Card';
import { ProjectSurfaceTypeForm } from './ProjectSurface/ProjectSurfaceTypeForm';
import { ProjectCbsForm } from './ProjectSurface/ProjectCbsForm';
import { useSelector } from 'react-redux';
import { selectProjectSurfaceInputMode } from '../../../../../store/selectors/project/projectSurfaceInputMode.selector';
import {
  DEFAULT_PROJECT_SURFACE_INPUT_MODE,
  ProjectSurfaceInputMode
} from '../../../../../domain/project/Project';
import { ProjectGfsEffForm } from './ProjectSurface/ProjectGfsEffForm';

export const ProjectSurfaceCard = () => {
  const { t } = useTranslation();

  const projectSurfaceInputMode = useSelector(selectProjectSurfaceInputMode);

  const [selectedProjectSurfaceInputMode, setSelectedProjectSurfaceInputMode] =
    React.useState<ProjectSurfaceInputMode>(
      projectSurfaceInputMode || DEFAULT_PROJECT_SURFACE_INPUT_MODE
    );

  return (
    <Card>
      <SpecialLabel label={t('projectSurface.label')} />
      <ProjectSurfaceTypeForm
        selectedProjectSurfaceInputMode={selectedProjectSurfaceInputMode}
        setSelectedProjectSurfaceInputMode={setSelectedProjectSurfaceInputMode}
      />
      {selectedProjectSurfaceInputMode === 'cbs' ? <ProjectCbsForm /> : <ProjectGfsEffForm />}
    </Card>
  );
};
