import i18next from 'i18next';
import { OTHER_SFS_SECTIONS_COLOR } from '../../../../constants/appConstants';
import { Section, SectionDrawn } from '../Section';
import { CommonPremiseSectionName } from './CommonPremiseSection';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { SectionWithTechnicalSheaths } from '../SectionWithTechnicalSheaths';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';
import { SectionWithGlazedSurface } from '../SectionWithGlazedSurface';

interface ReceptionHallSectionProps {
  surface?: number;
  exposureRate?: number;
  width?: number;
}

export interface ReceptionHallSection
  extends Section,
    SectionDrawn,
    SectionWithExposureRate,
    SectionWithTechnicalSheaths,
    SectionWithGlazedSurface {
  name: CommonPremiseSectionName.ReceptionHallSection;
  width?: number;
  defaultWidth: number;
  minWidth: number;
  maxWidth: number;
  defaultSurface: number;
  minSurface: number;
  maxSurface: number;
}

export const RECEPTION_HALL_SECTION_DEFAULT_SURFACE = 80;
export const RECEPTION_HALL_SECTION_MIN_SURFACE = 10;
export const RECEPTION_HALL_SECTION_MAX_SURFACE = 120;
export const RECEPTION_HALL_SECTION_DEFAULT_EXPOSURE_RATE = 1.5;
export const RECEPTION_HALL_SECTION_DEFAULT_WITH = 6;
export const RECEPTION_HALL_SECTION_MIN_WITH = 5;
export const RECEPTION_HALL_SECTION_MAX_WITH = 10;
export const RECEPTION_HALL_SECTION_ICON = {
  type: 'object',
  iconName: 'reception',
  color: OTHER_SFS_SECTIONS_COLOR
} as IconObjectProps;

const receptionHallSection = ({
  surface,
  exposureRate,
  width
}: ReceptionHallSectionProps): ReceptionHallSection => ({
  // Section
  id: 'NOID',
  name: CommonPremiseSectionName.ReceptionHallSection,
  title: i18next.t('receptionHallSection.title'),
  type: 'others',
  displayedSurface: surface || RECEPTION_HALL_SECTION_DEFAULT_SURFACE,
  // SectionDrawn
  surface: surface || RECEPTION_HALL_SECTION_DEFAULT_SURFACE,
  color: OTHER_SFS_SECTIONS_COLOR,
  icon: RECEPTION_HALL_SECTION_ICON,
  // SectionWithExposureRate
  exposureRate: exposureRate || undefined,
  defaultExposureRate: RECEPTION_HALL_SECTION_DEFAULT_EXPOSURE_RATE,
  // SectionWithTechnicalSheaths
  technicalSheathCount: 0,
  // SectionWithGlazedSurface
  glazedLinear: undefined,
  defaultGlazedRate: 0.5,
  // ReceptionHallSection
  width: width || undefined,
  defaultWidth: RECEPTION_HALL_SECTION_DEFAULT_WITH,
  minWidth: RECEPTION_HALL_SECTION_MIN_WITH,
  maxWidth: RECEPTION_HALL_SECTION_MAX_WITH,
  defaultSurface: RECEPTION_HALL_SECTION_DEFAULT_SURFACE,
  minSurface: RECEPTION_HALL_SECTION_MIN_SURFACE,
  maxSurface: RECEPTION_HALL_SECTION_MAX_SURFACE
});

export default receptionHallSection;
