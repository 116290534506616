import { CardsList } from '../../../../ui/CardsList';
import { WindowScroll } from '../../../../ui/WindowScroll';
import { SelectedLevelCard } from './RightPanel/SelectedLevelCard';
import { SideDrawMode } from './RightPanel/SideDrawMode/SideDrawMode';
import React from 'react';
import { UiGeometry } from '../Geometry';
import {
  isLayerWithLevelGeometry,
  LevelGeometry
} from '../../../../../services/mapBox/mapboxDraw/levelGeometry';
import { updateCaseLevelSpecificationGeometry } from '../../../../../store/actions/updateCaseLevelSpecificationGeometry.action';
import { useDispatch } from 'react-redux';
import {
  LayerWithLevelGeometry,
  LevelLayer
} from '../../../../../services/mapBox/layer/layer.model';
import { useLayerObserver } from '../../../../../services/mapBox/hook/useLayerObserver';

interface RightPanelProps {
  drawHelperMode: boolean;
  toggleSetDrawHelperMode: () => void;
  ui: UiGeometry;
}

export const RightPanel = ({ drawHelperMode, toggleSetDrawHelperMode, ui }: RightPanelProps) => {
  const dispatch = useDispatch();

  useLayerObserver(ui.layerRepo);
  const currentlyEditedLevel = ui.layerRepo
    ?.getSelectedByType('level')
    ?.filter((level: LevelLayer) => isLayerWithLevelGeometry(level))?.[0];

  // TODO : To fix when isCaseGranulometryEditLevelGeometryOpened

  const dispatchChange = (levelGeometry: LevelGeometry) =>
    dispatch(updateCaseLevelSpecificationGeometry({ levelGeometry }));

  if (!drawHelperMode && !currentlyEditedLevel) return null;

  return (
    <div className={'rightPanel'}>
      <CardsList>
        <WindowScroll>
          {drawHelperMode ? (
            <SideDrawMode ui={ui as UiGeometry} toggleSetDrawHelperMode={toggleSetDrawHelperMode} />
          ) : (
            <SelectedLevelCard
              ui={ui}
              selectedLevel={currentlyEditedLevel as LayerWithLevelGeometry}
              dispatchChange={dispatchChange}
            />
          )}
        </WindowScroll>
      </CardsList>
    </div>
  );
};
