import { Reducer } from 'redux';
import { GRANULOMETRY_OUTDATED } from '../actions/granulometryOutdated.action';
import {
  GRANULOMETRY_UPDATED,
  GranulometryUpdatedAction
} from '../actions/granulometryUpdated.action';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { GRANULOMETRY_RENDERING } from '../actions/granulometryRendering.action';
import { CLOSE_PROJECT } from '../actions/closeProject.action';
import privateRoutes from '../../routes/privateRoutes';
import { Granulometry } from '../../domain/granulometry/Granulometry';
import { PROJECT_SURFACE_INPUT_MODE_CHANGED } from '../actions/projectSurfaceInputModeChanged.action';

export interface GranulometryState {
  isFresh: boolean;
  isRendering: boolean;
  current?: Granulometry;
}

const initialState = { isFresh: false, isRendering: false, current: undefined };

export const granulometryReducer: Reducer<GranulometryState> = (state = initialState, action) => {
  if (action.type === GRANULOMETRY_OUTDATED) {
    return {
      ...state,
      isFresh: false
    };
  }

  if (action.type === GRANULOMETRY_RENDERING) {
    return {
      ...state,
      isFresh: false,
      isRendering: true
    };
  }

  if (action.type === GRANULOMETRY_UPDATED) {
    return {
      isFresh: true,
      isRendering: false,
      current: (action as GranulometryUpdatedAction).payload.granulometry || state.current
    };
  }

  if (action.type === CLOSE_PROJECT) {
    return initialState;
  }

  if (action.type === LOCATION_CHANGE) {
    const {
      payload: {
        location: { pathname }
      }
    } = action as LocationChangeAction;
    if (pathname === privateRoutes.Projects.path) {
      return initialState;
    }
  }

  if (action.type === PROJECT_SURFACE_INPUT_MODE_CHANGED) {
    return initialState;
  }

  return state;
};
