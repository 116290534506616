import { createSelector, Selector } from 'reselect';
import { ifDefined } from '../../../utils/ifDefined';
import { selectGranulometry } from './current.selector';
import {
  getLodgmentsCountByBuildingCaseTopLevelAndType,
  LodgmentsCountByBuildingCaseLevelAndType
} from '../../../domain/granulometry/queries/getLodgmentsCountByBuildingCaseTopLevelAndType';
import { State } from '../../../store/reducers';
import { Maybe } from '../../../utils/Maybe';

export const selectLodgmentsCountByBuildingCaseTopLevelAndType: Selector<
  State,
  Maybe<LodgmentsCountByBuildingCaseLevelAndType>
> = createSelector([selectGranulometry], ifDefined(getLodgmentsCountByBuildingCaseTopLevelAndType));
