import * as R from 'ramda';
import { getSectionName, Section } from '../Section';
import { BearingSection } from './bearing';
import { BearingInfraSection } from './bearingInfra';
import { CirculationLaneInfraSection } from './circulationLaneInfra';
import { ElevatorShaftSection } from './elevatorShaft';
import { HallSection } from './hall';
import { RampSection } from './ramp';
import { SasInfraSection } from './sasInfra';
import { StairsShaftSection } from './stairsShaft';
import { VentilationGroupInfraSection } from './ventilationGroupInfra';
import { RampInfraSection } from './rampInfra';
import { ElevatorShaftInfraSection } from './elevatorShaftInfra';
import { StairsShaftInfraSection } from './stairsShaftInfra';
import { SmokeExtractionDuctsSection } from './smokeExtractionDucts';
import { SmokeExtractionDuctsInfraSection } from './smokeExtractionDuctsInfra';

export enum CirculationName {
  Bearing = 'bearing',
  BearingInfra = 'bearingInfra',
  CirculationLaneInfra = 'circulationLaneInfra',
  ElevatorShaft = 'elevatorShaft',
  ElevatorShaftInfra = 'elevatorShaftInfra',
  Hall = 'hall',
  Ramp = 'ramp',
  RampInfra = 'rampInfra',
  SasInfra = 'sasInfra',
  SmokeExtractionDucts = 'smokeExtractionDucts',
  SmokeExtractionDuctsInfra = 'smokeExtractionDuctsInfra',
  StairsShaft = 'stairsShaft',
  StairsShaftInfra = 'stairsShaftInfra',
  VentilationGroupInfra = 'ventilationGroupInfra'
}

export type CirculationSection =
  | BearingSection
  | BearingInfraSection
  | CirculationLaneInfraSection
  | ElevatorShaftSection
  | ElevatorShaftInfraSection
  | HallSection
  | RampSection
  | RampInfraSection
  | SasInfraSection
  | SmokeExtractionDuctsSection
  | SmokeExtractionDuctsInfraSection
  | StairsShaftSection
  | StairsShaftInfraSection
  | VentilationGroupInfraSection;

type GetCirculationName = (activitySection: CirculationSection) => CirculationName;
export const getCirculationName: GetCirculationName = R.prop('name');

export const isSectionACirculation = (section: Section): section is CirculationSection =>
  (R.values(CirculationName) as string[]).includes(getSectionName(section));

export const filterCirculation = (sections: Section[]): CirculationSection[] =>
  sections.filter(isSectionACirculation);
