import { LevelSpecification } from '../../../../specification/levels/LevelSpecification';
import { CaseProjection } from '../../CaseProjection';

export const getLevelSpecificationFromCaseProjectionWithGivenLevelIndex = (
  caseProjection: CaseProjection,
  levelIndex: number
): LevelSpecification | undefined =>
  [...caseProjection.projectedTopLevels, ...caseProjection.projectedBasementLevels].filter(
    (levelSpecification) => levelSpecification.level === levelIndex
  )[0];
