import { ACCESS_TOKEN } from '../config/mapboxgl';
import { Feature, Point } from 'geojson';
import { Position } from '@turf/helpers/dist/js/lib/geojson';
import { ProjectLocationChangedAction } from '../../../store/actions/projectLocationChanged.action';

const options = {
  lang: 'fr',
  types: 'address,postcode,locality',
  country: 'fr'
};

const getRequestURL = (address: string) =>
  'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
  encodeURI(address) +
  '.json?country=' +
  options.country +
  '&types=' +
  options.types +
  '&language=' +
  options.lang +
  '&access_token=' +
  ACCESS_TOKEN;

export interface ExtendedFeatureWithGeoCoding {
  place_name: string;
  center: Position;
}

export interface GeoCodingFeature extends Feature<Point>, ExtendedFeatureWithGeoCoding {}

const getAddressCoordinates = async (
  geoCoding: ProjectLocationChangedAction['payload']
): Promise<GeoCodingFeature[]> => {
  const address = geoCoding.street + ' ' + geoCoding.city + ' ' + geoCoding.postalCode;
  const res = await fetch(getRequestURL(address));
  const featuresCollection = await res.json();
  return featuresCollection.features.filter((f) =>
    f.context.find((context) => context.text === geoCoding.postalCode)
  );
};

export default getAddressCoordinates;
