import flatRoofing from '../../../../sections/roofingSections/flatRoofing';
import tileRoofing from '../../../../sections/roofingSections/tileRoofing';
import { RoofingType } from '../../../../../specification/cases/RoofingType';
import { RoofingName, RoofingSection } from '../../../../sections/roofingSections/RoofingSection';

export const createLevelRoofingSection = (
  displayedSurface: number,
  drawnSurface: number,
  roofingType: RoofingType
): RoofingSection =>
  roofingType === RoofingName.FlatRoofing
    ? flatRoofing({ displayedSurface, drawnSurface })
    : tileRoofing({ displayedSurface, drawnSurface });
