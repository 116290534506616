import { createSelector, Selector } from 'reselect';
import { selectParkingsSpecifications } from './parkingsSpecifications.selector';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';
import { ifDefined } from '../../../../utils/ifDefined';

export const selectCarParkCountPerShab: Selector<State, Maybe<number>> = createSelector(
  [selectParkingsSpecifications],
  ifDefined(({ carParkCountPerShab }) => carParkCountPerShab)
);
