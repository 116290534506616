import { CaseId } from '../../domain/specification/cases/CaseSpecification';
import { TopLevelSpecification } from '../../domain/specification/levels/TopLevelSpecification';
import { BasementLevelSpecification } from '../../domain/specification/levels/BasementLevelSpecification';

export const SET_CASE_LEVEL_SPECIFICATION = 'SET_CASE_LEVEL_SPECIFICATION';

export const setCaseLevelSpecification = (
  caseId: CaseId,
  level: TopLevelSpecification | BasementLevelSpecification
) => ({
  type: SET_CASE_LEVEL_SPECIFICATION,
  payload: { caseId, level }
});

export type SetCaseLevelSpecificationAction = ReturnType<typeof setCaseLevelSpecification>;
