import { CaseProjection } from '../CaseProjection';
import { getCaseProperty } from '../../../specification/cases/queries/get/properties/getCaseProperty';
import { getCaseOutsideWallConcreteRateMaxValue } from '../../../specification/cases/queries/get/outsideWallTypeRates/getCaseOutsideWallConcreteRateMaxValue';

export const setCaseProjectedProperties = (caseProjection: CaseProjection): CaseProjection => {
  const projectedProperties = [...caseProjection.properties].map((property) => {
    if (property.name === 'outsideWallConcreteRate' && property.value === undefined) {
      return {
        ...property,
        value: getCaseOutsideWallConcreteRateMaxValue({
          outsideWallMasonryRate: getCaseProperty(caseProjection, 'outsideWallMasonryRate')?.value,
          outsideWallWoodRate: getCaseProperty(caseProjection, 'outsideWallWoodRate')?.value
        })
      };
    } else {
      return property;
    }
  });

  return {
    ...caseProjection,
    projectedProperties
  };
};
