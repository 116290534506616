import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../ui/Card';
import { Window } from '../../../../ui/Window';
import { Form } from '../../../../ui/Form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { IconObject } from '../../../../ui/Icons/iconObject';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { Note } from '../../../../ui/Note';
import { CaseGranulometryEditGroundAdaptationsRouteParams } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditGroundAdaptationsRoute';
import { getCaseGranulometryEditGroundAdaptationsTriggerId } from '../../../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditGroundAdaptationsTriggerId';
import { goToCase } from '../../../../../store/actions/navigations/toolbox/casesPanel/goToCase.action';
import { goToCaseGranulometryEditEnergyAndNetworks } from '../../../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditEnergyAndNetworks.action';
import { goToCaseGranulometryEditInfrastructure } from '../../../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditInfrastructure.action';

export const CaseGroundAdaptationsWindow = () => {
  const { projectId, caseId } = useParams<CaseGranulometryEditGroundAdaptationsRouteParams>();
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickClose = React.useCallback(() => {
    if (caseId && projectId) dispatch(goToCase(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  const handleClickPrev = React.useCallback(() => {
    caseId && projectId && dispatch(goToCaseGranulometryEditInfrastructure(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  const handleClickNext = React.useCallback(() => {
    caseId && projectId && dispatch(goToCaseGranulometryEditEnergyAndNetworks(projectId, caseId));
  }, [dispatch, projectId, caseId]);

  if (!caseLabel) return null;

  return (
    <Window
      title={t('Ground adaptations')}
      triggerId={getCaseGranulometryEditGroundAdaptationsTriggerId(caseLabel)}
      topButtons={
        <>
          <IconObject iconName="arrowTop" type="menu" onClick={handleClickPrev} />
          <IconObject iconName="arrowBottom" type="menu" onClick={handleClickNext} />
          <IconObject iconName="close" type="menu" onClick={handleClickClose} />
        </>
      }>
      <Form>
        <Card>
          <Note>
            Ce panneau permettra de gérer l’administration des solutions d’assise de projet (en
            infrastructure comme en superstructure) tant pour les fondations ponctuelles ou filantes
            que pour le dernier plancher sur fond de fouille du projet. Ici se choisiront les
            solutions de fondations superficielles semi-profondes ou très profondes mis également
            les plancher sous forme de dallage sur terre-plein, dallage porté ou radier à la
            soupression.
          </Note>
        </Card>
      </Form>
    </Window>
  );
};

export default CaseGroundAdaptationsWindow;
