import { createSelector } from 'reselect';
import { selectGranulometry } from './current.selector';
import { getAllCasesInGranulometry } from '../../../domain/granulometry/queries/getAllCasesInGranulometry';
import { getAllLevelsInGranulometry } from '../../../domain/granulometry/queries/getAllLevelsInGranulometry';
import * as R from 'ramda';

export const selectAllLevelsInGranulometrySelector = createSelector(
  [selectGranulometry],
  (granulometry) => {
    if (!granulometry) return [];
    return R.pipe(getAllCasesInGranulometry, getAllLevelsInGranulometry)(granulometry);
  }
);
