import * as React from 'react';
import { ReactNode } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { Card } from '../../../../ui/Card';
import { DetailsTable } from './DetailsTable';
import { selectCurrentLevelGranulometry } from '../../../../../store/selectors/granulometry/currentLevel/granulometry.selector';
import { getTopLevelHoppersSurface } from '../../../../../domain/granulometry/levels/queries/topLevels/surfaces/getTopLevelHoppersSurface';
import { selectCurrentCaseGranulometry } from '../../../../../store/selectors/granulometry/currentCase/currentCaseGranulometry.selector';
import { getTopLevelRealOutsideInsulatedWallsInternalLinear } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealOutsideInsulatedWallsInternalLinear';
import { getTopLevelRealOutsideWallsSurface } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealOutsideWallsSurface';
import { getTopLevelRealBuiltSurfaceEff } from '../../../../../domain/granulometry/levels/queries/topLevels/surfaces/realBuiltSurface/getTopLevelRealBuiltSurfaceEff';
import { getTopLevelOutsideInsulatedWallsThickness } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/wallsThickness/getTopLevelOutsideInsulatedWallsThickness';
import { getTopLevelGrossFloorSurfaceEff } from '../../../../../domain/granulometry/levels/queries/topLevels/surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurfaceEff';
import { getTopLevelBearingSurfaceDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/sections/surfaces/getTopLevelBearingSurface.details';
import { selectCurrentCaseProjection } from '../../../../../store/selectors/projection/currentCase/projection.selector';
import { getTopLevelWallsLinear } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/getTopLevelWallsLinear';
import { getTopLevelTheoreticalOutsideWallsLinear } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/theoreticalWalls/getTopLevelTheoreticalOutsideWallsLinear';
import { getTopLevelTheoreticalOutsideWallsSurface } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/theoreticalWalls/getTopLevelTheoreticalOutsideWallsSurface';
import { getTopLevelTheoreticalInsideWallsLinear } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/theoreticalWalls/getTopLevelTheoreticalInsideWallsLinear';
import { getTopLevelTheoreticalInsideWallsSurface } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/theoreticalWalls/getTopLevelTheoreticalInsideWallsSurface';
import { getTopLevelWallsLinearDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/getTopLevelWallsLinear.details';
import { getTopLevelInsideWallsThicknessDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/wallsThickness/getTopLevelInsideWallsThickness.details';
import { getTopLevelInsideWallsThickness } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/wallsThickness/getTopLevelInsideWallsThickness';
import { getTopLevelOutsideInsulatedWallsThicknessDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/wallsThickness/getTopLevelOutsideInsulatedWallsThickness.details';
import { getTopLevelTheoreticalOutsideWallsLinearDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/theoreticalWalls/getTopLevelTheoreticalOutsideWallsLinear.details';
import { getTopLevelTheoreticalInsideWallsLinearDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/theoreticalWalls/getTopLevelTheoreticalInsideWallsLinear.details';
import { getTopLevelTheoreticalInsideWallsSurfaceDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/theoreticalWalls/getTopLevelTheoreticalInsideWallsSurface.details';
import { getTopLevelTheoreticalOutsideWallsSurfaceDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/theoreticalWalls/getTopLevelTheoreticalOutsideWallsSurface.details';
import { getTopLevelRealBuiltSurfaceEffDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/surfaces/realBuiltSurface/getTopLevelRealBuiltSurfaceEff.details';
import { getTopLevelRealOutsideInsulatedWallsInternalLinearDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealOutsideInsulatedWallsInternalLinear.details';
import { getTopLevelRealOutsideWallsSurfaceDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealOutsideWallsSurface.details';
import { getTopLevelRealInsideWallsLinear } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealInsideWallsLinear';
import { getTopLevelRealInsideWallsLinearDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealInsideWallsLinear.details';
import { getTopLevelRealInsideWallsSurface } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealInsideWallsSurface';
import { getTopLevelRealInsideWallsSurfaceDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealInsideWallsSurface.details';
import { getTopLevelRealTheoreticalInsideWallsSurfaceDiff } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/getTopLevelRealTheoreticalInsideWallsSurfaceDiff';
import { getTopLevelRealTheoreticalInsideWallsSurfaceDiffDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/getTopLevelRealTheoreticalInsideWallsSurfaceDiff.details';
import { getTopLevelGrossFloorSurfaceEffDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurfaceEff.details';
import { getTopLevelGrossFloorSurface } from '../../../../../domain/granulometry/levels/queries/topLevels/surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurface';
import { getTopLevelGrossFloorSurfaceDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurface.details';
import { getTopLevelHoppersSurfaceDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/surfaces/getTopLevelHoppersSurface.details';
import { getTopLevelLodgmentsPartitionWallsSurface } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/lodgmentWalls/getTopLevelLodgmentsPartitionWallsSurface';
import { getTopLevelLodgmentsPartitionWallsSurfaceDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/lodgmentWalls/getTopLevelLodgmentsPartitionWallsSurface.details';
import { getTopLevelLodgmentsPartitionWallsLinear } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/lodgmentWalls/getTopLevelLodgmentsPartitionWallsLinear';
import { getTopLevelLodgmentsPartitionWallsLinearDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/lodgmentWalls/getTopLevelLodgmentsPartitionWallsLinear.details';
import { getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsLinearDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/includedInSurfaceForSaleSectionWalls/getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsLinear.details';
import { getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsLinear } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/includedInSurfaceForSaleSectionWalls/getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsLinear';
import { getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/includedInSurfaceForSaleSectionWalls/getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface';
import { getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurfaceDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/includedInSurfaceForSaleSectionWalls/getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface.details';
import { getTopLeveIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsSurface } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/includedInSurfaceForSaleSectionWalls/getTopLeveIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsSurface';
import { getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsLinear } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/includedInSurfaceForSaleSectionWalls/getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsLinear';
import { getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsLinearDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/includedInSurfaceForSaleSectionWalls/getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsLinear.details';
import { getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsSurfaceDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/includedInSurfaceForSaleSectionWalls/getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsSurface.details';
import { getTopLevelStairsShafWallsSurface } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/stairsShaftWalls/getTopLevelStairsShafWallsSurface';
import { getTopLevelStairsShaftWallsSurfaceDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/stairsShaftWalls/getTopLevelStairsShaftWallsSurface.details';
import { getTopLevelElevatorShafWallsSurface } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/elevatorShaftWalls/getTopLevelElevatorShafWallsSurface';
import { getTopLevelElevatorShaftWallsSurfaceDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/elevatorShaftWalls/getTopLevelElevatorShaftWallsSurface.details';
import { getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurfaceDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/surfaces/sectionsWithTechnicalSheaths/getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface.details';
import { getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface } from '../../../../../domain/granulometry/levels/queries/topLevels/surfaces/sectionsWithTechnicalSheaths/getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface';
import { getTopLevelSectionsWithTSTechnicalSheathRealSurface } from '../../../../../domain/granulometry/levels/queries/topLevels/surfaces/sectionsWithTechnicalSheaths/getTopLevelSectionsWithTSTechnicalSheathRealSurface';
import { getTopLevelSectionsWithTSTechnicalSheathRealSurfaceDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/surfaces/sectionsWithTechnicalSheaths/getTopLevelSectionsWithTSTechnicalSheathRealSurface.details';
import { getTopLevelSurfaceForSaleDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/surfaces/surfaceForSale/getTopLevelSurfaceForSale.details';
import { getTopLevelRealBuiltSurfaceDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/surfaces/realBuiltSurface/getTopLevelRealBuiltSurface.details';
import { getLevelMaxSurfaceForSale } from '../../../../../domain/granulometry/levels/queries/topLevels/surfaces/surfaceForSale/getLevelMaxSurfaceForSale';
import { getLevelMaxSurfaceForSaleDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/surfaces/surfaceForSale/getLevelMaxSurfaceForSale.details';
import { getTopLevelMinimumBearingSurface } from '../../../../../domain/granulometry/levels/queries/topLevels/surfaces/getTopLevelMinimumBearingSurface';
import { getTopLevelMinimumBearingSurfaceDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/surfaces/getTopLevelMinimumBearingSurface.details';
import { getBearingTechnicalSheathsSurface } from '../../../../../domain/granulometry/sections/queries/bearingTechnicalSheaths/getBearingTechnicalSheathsSurface';
import { getBearingTechnicalSheathsSurfaceDetails } from '../../../../../domain/granulometry/sections/queries/bearingTechnicalSheaths/getBearingTechnicalSheathsSurface.details';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { getTopLevelBearingSurface } from '../../../../../domain/granulometry/levels/queries/topLevels/sections/surfaces/getTopLevelBearingSurface';
import { getTopLevelOutsideStructuralWallsThickness } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/wallsThickness/getTopLevelOutsideStructuralWallsThickness';
import { getTopLevelOutsideStructuralWallsThicknessDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/wallsThickness/getTopLevelOutsideStructuralWallsThickness.details';
import { getTopLevelInternalInsulationThickness } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/wallsThickness/getTopLevelInternalInsulationThickness';
import { getTopLevelInternalInsulationThicknessDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/wallsThickness/getTopLevelInternalInsulationThickness.details';
import { getTopLevelExternalInsulationThickness } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/wallsThickness/getTopLevelExternalnalInsulationThickness';
import { getTopLevelExternalInsulationThicknessDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/wallsThickness/getTopLevelExternalInsulationThickness.details';
import { getTopLevelRealOutsideStructuralWallsInternalLinear } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealOutsideStructuralWallsInternalLinear';
import { getTopLevelRealOutsideStructuralWallsInternalLinearDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealOutsideStructuralWallsInternalLinear.details';
import { getTopLevelRealOutsideStructuralWallsExternalLinear } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealOutsideStructuralWallsExternalLinear';
import { getTopLevelRealOutsideStructuralWallsExternalLinearDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealOutsideStructuralWallsExternalLinear.details';
import { getTopLevelRealOutsideInsulatedWallsExternalLinear } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealOutsideInsulatedWallsExternalLinear';
import { getTopLevelRealOutsideInsulatedWallsExternalLinearDetails } from '../../../../../domain/granulometry/levels/queries/topLevels/walls/realWalls/getTopLevelRealOutsideInsulatedWallsExternalLinear.details';
import { hasDrawnGeometry } from '../../../../../domain/granulometry/levels/queries/has/hasDrawnGeometry';

export interface LevelCalculationDetail {
  label: string | ReactNode | ReactNode[];
  value?: number;
  unit?: string;
  separator?: boolean;
  details?: string;
  bold?: boolean;
}

export const TopLevelCalculationDetails = () => {
  const levelGranulometry = useSelector(selectCurrentLevelGranulometry);
  const caseGranulometry = useSelector(selectCurrentCaseGranulometry);
  const caseProjection = useSelector(selectCurrentCaseProjection);

  const { t } = useTranslation();

  if (!levelGranulometry || !caseGranulometry || !caseProjection) return null;

  const isGbEnvDevOrRecette = process.env.GB_ENV === 'dev' || process.env.GB_ENV === 'recette';

  const infos: (LevelCalculationDetail | null)[] = [
    {
      label: t('RBS'),
      value: levelGranulometry.realBuiltSurface,
      unit: 'm\u00B2',
      details: isGbEnvDevOrRecette
        ? getTopLevelRealBuiltSurfaceDetails(levelGranulometry, caseGranulometry)
        : undefined
    },
    {
      label: t('SFS'),
      value: levelGranulometry.surfaceForSale,
      unit: 'm\u00B2',
      details: isGbEnvDevOrRecette
        ? getTopLevelSurfaceForSaleDetails(caseGranulometry, levelGranulometry)
        : undefined,
      separator: true
    },
    {
      label: t('SFS') + ' maximale',
      value: getLevelMaxSurfaceForSale(caseGranulometry, levelGranulometry),
      unit: 'm\u00B2',
      details: getLevelMaxSurfaceForSaleDetails(caseGranulometry, levelGranulometry)
    },
    // Walls thickness
    {
      label: t('outsideStructuralWallsThickness.label'),
      value: getTopLevelOutsideStructuralWallsThickness(caseGranulometry) * 100,
      unit: 'cm',
      separator: true,
      details: getTopLevelOutsideStructuralWallsThicknessDetails(caseGranulometry)
    },
    {
      label: t('internalInsulationThickness.label'),
      value: getTopLevelInternalInsulationThickness(caseGranulometry) * 100,
      unit: 'cm',
      details: getTopLevelInternalInsulationThicknessDetails(caseGranulometry)
    },
    {
      label: t('externalInsulationThickness.label'),
      value: getTopLevelExternalInsulationThickness(caseGranulometry) * 100,
      unit: 'cm',
      details: getTopLevelExternalInsulationThicknessDetails(caseGranulometry)
    },
    {
      label: t('outsideInsulatedWallsThickness.label'),
      value: getTopLevelOutsideInsulatedWallsThickness(caseGranulometry) * 100,
      unit: 'cm',
      details: getTopLevelOutsideInsulatedWallsThicknessDetails(caseGranulometry)
    },
    {
      label: t('insideWallsThickness.label'),
      value: getTopLevelInsideWallsThickness(caseGranulometry) * 100,
      unit: 'cm',
      details: getTopLevelInsideWallsThicknessDetails(caseGranulometry)
    },
    // Included in surface for sale sections walls
    isGbEnvDevOrRecette
      ? {
          label: t('includedInSurfaceForSaleSectionTheoreticalOutsideWallsLinear.label'),
          value: getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsLinear(
            caseGranulometry,
            levelGranulometry
          ),
          unit: 'mL',
          separator: true,
          details: getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsLinearDetails(
            caseGranulometry,
            levelGranulometry
          )
        }
      : null,
    isGbEnvDevOrRecette
      ? {
          label: t('includedInSurfaceForSaleSectionTheoreticalOutsideWallsSurface.label'),
          value: getTopLeveIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsSurface(
            caseGranulometry,
            levelGranulometry
          ),
          unit: 'm\u00B2',
          details: getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsSurfaceDetails(
            caseGranulometry,
            levelGranulometry
          )
        }
      : null,
    isGbEnvDevOrRecette
      ? {
          label: t('includedInSurfaceForSaleSectionTheoreticalInsideWallsLinear.label'),
          value: getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsLinear(
            caseGranulometry,
            levelGranulometry
          ),
          unit: 'mL',
          details: getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsLinearDetails(
            caseGranulometry,
            levelGranulometry
          )
        }
      : null,
    isGbEnvDevOrRecette
      ? {
          label: t('includedInSurfaceForSaleSectionTheoreticalInsideWallsSurface.label'),
          value: getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface(
            caseGranulometry,
            levelGranulometry
          ).value,
          unit: 'm\u00B2',
          details: getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurfaceDetails(
            caseGranulometry,
            levelGranulometry
          )
        }
      : null,
    {
      label: t('partitionWallsLinear.label'),
      value: getTopLevelLodgmentsPartitionWallsLinear(caseGranulometry, levelGranulometry),
      unit: 'mL',
      details: getTopLevelLodgmentsPartitionWallsLinearDetails(caseGranulometry, levelGranulometry)
    },
    {
      label: t('partitionWallsSurface.label'),
      value: getTopLevelLodgmentsPartitionWallsSurface(caseGranulometry, levelGranulometry).value,
      unit: 'm\u00B2',
      details: getTopLevelLodgmentsPartitionWallsSurfaceDetails(caseGranulometry, levelGranulometry)
    },
    // Stairs shaft and elevator shaft walls surface
    {
      label: t('stairsShaftWallsSurface.label'),
      value: getTopLevelStairsShafWallsSurface(caseGranulometry, levelGranulometry),
      unit: 'm\u00B2',
      details: getTopLevelStairsShaftWallsSurfaceDetails(caseGranulometry, levelGranulometry),
      separator: true
    },
    {
      label: t('elevatorShaftWallsSurface.label'),
      value: getTopLevelElevatorShafWallsSurface(caseGranulometry, levelGranulometry),
      unit: 'm\u00B2',
      details: getTopLevelElevatorShaftWallsSurfaceDetails(caseGranulometry, levelGranulometry)
    },
    // Lodgment technical sheaths theoretical surface
    isGbEnvDevOrRecette
      ? {
          label: (
            <>
              {t('lodgmentsTechnicalSheathTheoreticalSurface.label')}
              <br /> {t('lodgmentsTechnicalSheathTheoreticalSurface.label2')}
              <i> – GTL</i>
            </>
          ),
          value: getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface(
            caseGranulometry,
            levelGranulometry
          ).value,
          unit: 'm\u00B2',
          details: getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurfaceDetails(
            caseGranulometry,
            levelGranulometry
          ),
          separator: true
        }
      : null,
    // Bearing technical sheaths theoretical surface
    {
      label: (
        <>
          {t('bearingTechnicalSheathsTheoreticalSurface.label')} <i>– GTP</i>
        </>
      ),
      value: roundWith2Decimal(
        getBearingTechnicalSheathsSurface(caseGranulometry, levelGranulometry).value
      ),
      unit: 'm\u00B2',
      details: getBearingTechnicalSheathsSurfaceDetails(caseGranulometry, levelGranulometry),
      separator: true
    },
    // Minimum bearing surface
    {
      label: t('minimumBearingSurface.label'),
      value: getTopLevelMinimumBearingSurface(caseGranulometry, levelGranulometry).value,
      unit: 'm\u00B2',
      details: getTopLevelMinimumBearingSurfaceDetails(caseGranulometry, levelGranulometry)
    },
    // Bearing surface
    {
      label: t('bearingSurface.label'),
      value: getTopLevelBearingSurface(caseGranulometry, levelGranulometry, true),
      unit: 'm\u00B2',
      details: getTopLevelBearingSurfaceDetails(caseGranulometry, levelGranulometry, true)
    },
    // Walls linear
    {
      label: t('wallsLinear.label'),
      value: getTopLevelWallsLinear(caseGranulometry, levelGranulometry),
      unit: 'mL',
      separator: true,
      details: getTopLevelWallsLinearDetails(caseGranulometry, levelGranulometry)
    },
    // Theoretical walls
    isGbEnvDevOrRecette
      ? {
          label: t('theoreticalOutsideWallsLinear.label'),
          value: getTopLevelTheoreticalOutsideWallsLinear(caseGranulometry, levelGranulometry),
          unit: 'mL',
          separator: true,
          details: getTopLevelTheoreticalOutsideWallsLinearDetails(
            caseGranulometry,
            levelGranulometry
          )
        }
      : null,
    isGbEnvDevOrRecette
      ? {
          label: t('theoreticalOutsideWallsSurface.label'),
          value: getTopLevelTheoreticalOutsideWallsSurface(caseGranulometry, levelGranulometry),
          unit: 'm\u00B2',
          details: getTopLevelTheoreticalOutsideWallsSurfaceDetails(
            caseGranulometry,
            levelGranulometry
          )
        }
      : null,
    isGbEnvDevOrRecette
      ? {
          label: t('theoreticalInsideWallsLinear.label'),
          value: getTopLevelTheoreticalInsideWallsLinear(caseGranulometry, levelGranulometry),
          unit: 'mL',
          details: getTopLevelTheoreticalInsideWallsLinearDetails(
            caseGranulometry,
            levelGranulometry
          )
        }
      : null,
    isGbEnvDevOrRecette
      ? {
          label: t('theoreticalInsideWallsSurface.label'),
          value: getTopLevelTheoreticalInsideWallsSurface(caseGranulometry, levelGranulometry),
          unit: 'm\u00B2',
          details: getTopLevelTheoreticalInsideWallsSurfaceDetails(
            caseGranulometry,
            levelGranulometry
          )
        }
      : null,
    // Hoppers
    {
      label: t('Hoppers'),
      value: getTopLevelHoppersSurface(caseGranulometry, levelGranulometry),
      unit: 'm\u00B2',
      separator: true,
      details: getTopLevelHoppersSurfaceDetails(caseGranulometry, levelGranulometry)
    },
    // Real built surface EFF
    {
      label: t('realBuiltSurfaceEff.label'),
      value: getTopLevelRealBuiltSurfaceEff(caseGranulometry, levelGranulometry),
      unit: 'm\u00B2',
      separator: true,
      details: getTopLevelRealBuiltSurfaceEffDetails(caseGranulometry, levelGranulometry)
    },
    // Real walls
    {
      label: (
        <>
          {t('realOutsideInsulatedWallsInternalLinear.label')}
          <br />
          <i>{t('realOutsideInsulatedWallsInternalLinear.label2')}</i>
        </>
      ),
      value: getTopLevelRealOutsideInsulatedWallsInternalLinear(
        caseGranulometry,
        levelGranulometry
      ),
      unit: 'mL',
      separator: true,
      details: getTopLevelRealOutsideInsulatedWallsInternalLinearDetails(
        caseGranulometry,
        levelGranulometry
      )
    },
    {
      label: (
        <>
          {t('realOutsideStructuralWallsInternalLinear.label')}
          <br />
          <i>{t('realOutsideStructuralWallsInternalLinear.label2')}</i>
        </>
      ),
      value: getTopLevelRealOutsideStructuralWallsInternalLinear(
        caseGranulometry,
        levelGranulometry
      ),
      unit: 'mL',
      details: getTopLevelRealOutsideStructuralWallsInternalLinearDetails(
        caseGranulometry,
        levelGranulometry
      )
    },
    {
      label: (
        <>
          {t('realOutsideStructuralWallsExternalLinear.label')}
          <br />
          <i>{t('realOutsideStructuralWallsExternalLinear.label2')}</i>
        </>
      ),
      value: getTopLevelRealOutsideStructuralWallsExternalLinear(
        caseGranulometry,
        levelGranulometry
      ),
      unit: 'mL',
      details: getTopLevelRealOutsideStructuralWallsExternalLinearDetails(
        caseGranulometry,
        levelGranulometry
      )
    },
    {
      label: (
        <>
          {t('realOutsideInsulatedWallsExternalLinear.label')}
          <br />
          <i>{t('realOutsideInsulatedWallsExternalLinear.label2')}</i>
        </>
      ),
      value: getTopLevelRealOutsideInsulatedWallsExternalLinear(
        caseGranulometry,
        levelGranulometry
      ),
      unit: 'mL',
      details: getTopLevelRealOutsideInsulatedWallsExternalLinearDetails(
        caseGranulometry,
        levelGranulometry
      )
    },
    {
      label: t('realOutsideWallsSurface.label'),
      value: getTopLevelRealOutsideWallsSurface(caseGranulometry, levelGranulometry),
      unit: 'm\u00B2',
      details: getTopLevelRealOutsideWallsSurfaceDetails(caseGranulometry, levelGranulometry)
    },
    {
      label: t('realInsideWallsLinear.label'),
      value: getTopLevelRealInsideWallsLinear(caseGranulometry, levelGranulometry),
      unit: 'mL',
      details: getTopLevelRealInsideWallsLinearDetails(caseGranulometry, levelGranulometry)
    },
    {
      label: t('realInsideWallsSurface.label'),
      value: getTopLevelRealInsideWallsSurface(caseGranulometry, levelGranulometry),
      unit: 'm\u00B2',
      details: getTopLevelRealInsideWallsSurfaceDetails(caseGranulometry, levelGranulometry)
    },
    isGbEnvDevOrRecette
      ? {
          label: t('realTheoreticalInsideWallsSurfaceDiff.label'),
          value: getTopLevelRealTheoreticalInsideWallsSurfaceDiff(
            caseGranulometry,
            levelGranulometry
          ),
          unit: 'm\u00B2',
          separator: true,
          details: getTopLevelRealTheoreticalInsideWallsSurfaceDiffDetails(
            caseGranulometry,
            levelGranulometry
          )
        }
      : null,
    // Lodgment technical sheath real surface
    {
      label: t('lodgmentsTechnicalSheathRealSurface.label'),
      value: getTopLevelSectionsWithTSTechnicalSheathRealSurface(
        caseGranulometry,
        levelGranulometry
      ),
      unit: 'm\u00B2',
      details: getTopLevelSectionsWithTSTechnicalSheathRealSurfaceDetails(
        caseGranulometry,
        levelGranulometry
      ),
      separator: true
    },
    // Gross floor surfaces
    {
      label: (
        <>
          {t('grossFloorSurfaceEff.label')}
          <br /> {' (' + t(hasDrawnGeometry(levelGranulometry) ? 'specified' : 'calculated') + ')'}
        </>
      ),
      value: getTopLevelGrossFloorSurfaceEff(caseGranulometry, levelGranulometry),
      unit: 'm\u00B2',
      separator: true,
      details: getTopLevelGrossFloorSurfaceEffDetails(caseGranulometry, levelGranulometry)
    },
    {
      label: t('grossFloorSurface.label'),
      value: getTopLevelGrossFloorSurface(caseGranulometry, levelGranulometry),
      unit: 'm\u00B2',
      details: getTopLevelGrossFloorSurfaceDetails(caseGranulometry, levelGranulometry)
    }
  ];

  return (
    <Card>
      <SpecialLabel label={t('Level details')} />
      <DetailsTable infos={R.reject(R.isNil)(infos)} />
    </Card>
  );
};
