import { SectionWithExposureRate } from '../../../SectionWithExposureRate';
import { getLevelLabel, LevelGranulometry } from '../../../../levels/LevelGranulometry';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';
import { getSectionRealOutsideWallsLinear } from './getSectionRealOutsideWallsLinear';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { getLodgmentSectionOutsideWallsLinearLevelRatio } from '../lodgmentWalls/getLodgmentSectionOutsideWallsLinearLevelRatio';
import { getTopLevelRealOutsideInsulatedWallsInternalLinear } from '../../../../levels/queries/topLevels/walls/realWalls/getTopLevelRealOutsideInsulatedWallsInternalLinear';

export const getSectionRealOutsideWallsLinearDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry,
  section: SectionWithExposureRate
): string => {
  let details: string[] = [];
  details = [
    ...details,
    '<b>' +
      roundWith2Decimal(
        getLodgmentSectionOutsideWallsLinearLevelRatio(
          caseGranulometry,
          levelGranulometry,
          section
        ) * 100
      ) +
      ' %</b> de ratio de linéaire théorique voiles exposés'
  ];
  details = [
    ...details,
    '<b>* ' +
      roundWith2Decimal(
        getTopLevelRealOutsideInsulatedWallsInternalLinear(caseGranulometry, levelGranulometry)
      ) +
      ' mL</b> de linéair réelle de voiles exposés au niveau ' +
      getLevelLabel(levelGranulometry.level)
  ];
  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(
        getSectionRealOutsideWallsLinear(caseGranulometry, levelGranulometry, section)
      ) +
      ' mL</b>'
  ];
  return details.join('<br />');
};
