import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import bearingTechnicalSheathsFR from './locales/fr/bearingTechnicalSheaths.json';
import buildingMapFR from './locales/fr/buildingMap.json';
import carpentrySpecificationsFR from './locales/fr/carpentrySpecifications.json';
import comEthIndicatorsFR from './locales/fr/comEthIndicators.json';
import commonPremiseSectionsFR from './locales/fr/commonPremiseSections.json';
import fieldsFR from './locales/fr/fields.json';
import floorSpaceFR from './locales/fr/floorSpace.json';
import glazedSurfaceInputFR from './locales/fr/glazedSurfaceInput.json';
import loginFormFR from './locales/fr/loginForm.json';
import officeSectionsFR from './locales/fr/officeSections.json';
import outsideWallTypeRatesFR from './locales/fr/outsideWallTypeRates.json';
import projectCstbIndicatorParametersFR from './locales/fr/projectCstbIndicatorParameters.json';
import projectLocationFR from './locales/fr/projectLocation.json';
import projectSoilPreStudyFR from './locales/fr/projectSoilPreStudy.json';
import projectSurfaceFR from './locales/fr/projectSurface.json';
import roomsFR from './locales/fr/rooms.json';
import sectionsFR from './locales/fr/sections.json';
import translationFR from './locales/fr/translation.json';
import shopSectionsFR from './locales/fr/shopSections.json';
import startUpWizardFR from './locales/fr/startUpWizard.json';
import technicalPremiseSectionsFR from './locales/fr/technicalPremiseSections.json';
import userFR from './locales/fr/user.json';
import projectRE2020ThresholdFR from './locales/fr/projectRE2020Threshold.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: {
      ...bearingTechnicalSheathsFR,
      ...buildingMapFR,
      ...carpentrySpecificationsFR,
      ...comEthIndicatorsFR,
      ...commonPremiseSectionsFR,
      ...fieldsFR,
      ...floorSpaceFR,
      ...glazedSurfaceInputFR,
      ...loginFormFR,
      ...officeSectionsFR,
      ...outsideWallTypeRatesFR,
      ...projectCstbIndicatorParametersFR,
      ...projectLocationFR,
      ...projectSoilPreStudyFR,
      ...projectSurfaceFR,
      ...roomsFR,
      ...sectionsFR,
      ...shopSectionsFR,
      ...startUpWizardFR,
      ...technicalPremiseSectionsFR,
      ...translationFR,
      ...userFR,
      ...projectRE2020ThresholdFR,
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'fr',

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
