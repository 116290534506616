import * as React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { ParkingsCountByType } from '../../../../../domain/granulometry/levels/queries/basementLevels/counts/getBasementLevelParkingsCountByType';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { isFirstBasementLevel } from '../../../../../domain/specification/levels/queries/is/isFirstBasementLevel';
import { Column1 } from './Column1';
import { BasementLevelSpecification } from '../../../../../domain/specification/levels/BasementLevelSpecification';
import { Column2 } from './Column2';
import { Column3 } from './Column3';
import { Column4 } from './Column4';
import { Column4Enlarged } from './Column4Enlarged';
import { BasementLevelSurfaceName } from '../../../../../domain/granulometry/cases/queries/surfaces/getCaseBasementLevelsSurfaces';

interface BasementLevelLineProps {
  basementLevelSpecification: BasementLevelSpecification;
  onEditClick: () => void;
  handleParkingRateChange: (value: number | null) => void;
  handleRealBuildSurfaceChange: (value: number | null) => void;
  parkingRate: number | undefined;
  isValid: boolean;
  parkingRatePH: number;
  parkingsCountByType: ParkingsCountByType;
  isToolboxEnlarged: boolean;
  selectedLevelSurface: BasementLevelSurfaceName;
}

export const BasementLevelLine = ({
  basementLevelSpecification,
  onEditClick,
  handleParkingRateChange,
  handleRealBuildSurfaceChange,
  parkingRate,
  isValid,
  parkingRatePH,
  parkingsCountByType,
  isToolboxEnlarged,
  selectedLevelSurface
}: BasementLevelLineProps) => {
  const caseLabel = useSelector(selectCurrentCaseLabel);

  if (!caseLabel || !basementLevelSpecification.level) return null;

  return (
    <div
      className={classNames('line', 'basement-level-line', {
        first: isFirstBasementLevel(basementLevelSpecification)
      })}>
      <Column1 basementLevelSpecification={basementLevelSpecification} onEditClick={onEditClick} />
      <Column2
        basementLevelSpecification={basementLevelSpecification}
        handleParkingRateChange={handleParkingRateChange}
        parkingRate={parkingRate}
        isValid={isValid}
        parkingRatePH={parkingRatePH}
        parkingsCountByType={parkingsCountByType}
      />
      <Column3
        basementLevelSpecification={basementLevelSpecification}
        parkingsCountByType={parkingsCountByType}
      />
      {isToolboxEnlarged ? (
        <Column4Enlarged
          basementLevelSpecification={basementLevelSpecification}
          handleRealBuildSurfaceChange={handleRealBuildSurfaceChange}
        />
      ) : (
        <Column4
          basementLevelSpecification={basementLevelSpecification}
          handleRealBuildSurfaceChange={handleRealBuildSurfaceChange}
          selectedLevelSurface={selectedLevelSurface}
        />
      )}
    </div>
  );
};
