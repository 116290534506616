import { createSelector, Selector } from 'reselect';
import { State } from '../../../store/reducers';
import { selectIsLoggedIn } from './isLoggedIn.selector';
import { selectSessionExpirationDate } from './sessionExpirationDate.selector';
import { DateString } from '../../../DateString';

export const selectIsValidSession: Selector<State, boolean> = createSelector(
  [selectIsLoggedIn, selectSessionExpirationDate],
  (isLoggedIn, sessionExpirationDate) =>
    isLoggedIn && new Date() < new Date(sessionExpirationDate as DateString)
);
