import * as R from 'ramda';
import { LevelGranulometry } from '../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { getTopLevelInsulatedFacadeInternalLinears } from './getTopLevelInsulatedFacadeInternalLinears';
import { getTopLevelInternalInsulationThickness } from './wallsThickness/getTopLevelInternalInsulationThickness';

export const getTopLevelStructuralFacadeInternalLinears = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): number[] =>
  R.reduce<number, number[]>(
    (acc, insultatedFacadeInternalLinear) => [
      ...acc,
      insultatedFacadeInternalLinear + getTopLevelInternalInsulationThickness(caseGranulometry)
    ],
    [] as number[]
  )(getTopLevelInsulatedFacadeInternalLinears(caseGranulometry, levelGranulometry));
