import * as R from 'ramda';
import { NonEmptyArray } from '../../utils/NonEmptyArray';
import { Project } from '../project/Project';
import { BuildingSpecification } from '../specification/buildings/BuildingSpecification';
import { checkThatProjectHasSufficientRemainingSurfaceForANewBuilding } from '../checks/project/projectHasSufficientRemainingSurfaceForANewBuilding';
import { createBuildingSpecification } from '../specification/buildings/queries/createBuildingSpecification';

export const addABuilding = <P extends Project>(project: P): P => {
  checkThatProjectHasSufficientRemainingSurfaceForANewBuilding(project);

  return R.evolve(
    {
      buildings: (b) =>
        R.append(
          createBuildingSpecification({
            projectSurfaceInputMode: project.surfaceInputMode
          }),
          b
        ) as NonEmptyArray<BuildingSpecification>
    },
    project
  ) as P;
};

export const addABuildingWithSpec =
  (buildingSpec: BuildingSpecification) =>
  <P extends Project>(project: P): P => {
    checkThatProjectHasSufficientRemainingSurfaceForANewBuilding(project);

    return R.evolve(
      {
        buildings: (b) => R.append(buildingSpec, b) as NonEmptyArray<BuildingSpecification>
      },
      project
    ) as P;
  };
