import { ValidationRule } from '../ValidationsRule';
import {
  projectCbsIsEmpty,
  projectCbsIsUndefined,
  projectCbsIsLargerThanTheMaximum,
  projectCbsIsSmallerThanTheMinimum
} from '../../checks/project/projectCbsIsValid';
import { MAXIMUM_PROJECT_CBS, MINIMUM_PROJECT_CBS } from '../../project/Project';

export const projectCbsMustBeValid: ValidationRule = (project, isNewProject) => {
  if (project.surfaceInputMode === 'cbs') {
    const explanation =
      'En mode "surface saisie", il est nécessaire de renseigner une SdP PC pour le projet. Celle-ci doit être comprise entre ' +
      MINIMUM_PROJECT_CBS +
      ' m\u00B2 et ' +
      MAXIMUM_PROJECT_CBS +
      ' m\u00B2.';

    if (projectCbsIsUndefined(project.cuttedBuiltSurface)) {
      if (isNewProject) {
        return {
          title: 'Merci de renseigner une surface du projet',
          explanation,
          type: 'info'
        };
      } else {
        return {
          title: 'La SdP PC du projet n’est pas définie',
          explanation,
          type: 'error'
        };
      }
    }
    if (project.cuttedBuiltSurface && projectCbsIsEmpty(project.cuttedBuiltSurface)) {
      return {
        title: 'La SdP PC du projet ne peut être égale à zéro',
        explanation,
        type: 'error'
      };
    }
    if (
      project.cuttedBuiltSurface &&
      projectCbsIsSmallerThanTheMinimum(project.cuttedBuiltSurface)
    ) {
      return {
        title: 'La SdP PC du projet est trop petite',
        explanation,
        type: 'error'
      };
    }
    if (
      project.cuttedBuiltSurface &&
      projectCbsIsLargerThanTheMaximum(project.cuttedBuiltSurface)
    ) {
      return {
        title: 'La SdP PC du projet est trop grande',
        explanation,
        type: 'error'
      };
    }
  }
  return true;
};
