import * as R from 'ramda';
import { getSectionName, Section } from '../Section';
import { FirstBasementLevelMetaSection } from './firstBasementLevelMetaSection';
import { LowestLevelMetaSection } from './lowestLevelMetaSection';
import { GroundLevelMetaSection } from './groundLevelMetaSection';
import { HighestLevelMetaSection } from './highestLevelMetaSection';
import { ProjectMetaSection } from './projectMetaSection';

export enum MetaSectionName {
  FirstBasementLevelMetaSection = 'firstBasementLevelMetaSection',
  LowestLevelMetaSection = 'lowestLevelMetaSection',
  GroundLevelMetaSection = 'groundLevelMetaSection',
  HighestLevelMetaSection = 'highestLevelMetaSection',
  ProjectMetaSection = 'projectMetaSection'
}

export type MetaSection =
  | FirstBasementLevelMetaSection
  | LowestLevelMetaSection
  | GroundLevelMetaSection
  | HighestLevelMetaSection
  | ProjectMetaSection;

type GetMetaSectionName = (outsideSection: MetaSection) => MetaSectionName;
export const getMetaSectionName: GetMetaSectionName = R.prop('name');

export const isSectionAMetaSection = (section: Section): section is MetaSection =>
  (R.values(MetaSectionName) as string[]).includes(getSectionName(section));

export const filterMetaSection = (sections: Section[]): MetaSection[] =>
  sections.filter(isSectionAMetaSection);
