import { Password, User } from '../../domain/User';
import { AuthToken } from '../../AuthToken';
import { put } from './admin.api';
import { ApiServerError } from './errors/server.error';

export const postChangePassword = async (
  userId: User['id'],
  authToken: AuthToken,
  newPassword: Password
) => {
  const url = `/users/${userId}`;
  const response = await put(url, authToken, { password: newPassword });

  if (response.status >= 300) throw new ApiServerError(url, response.status, response.statusText);
};
