import { from } from 'rxjs';
import { Action } from 'redux';
import { map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { Epic, ofType } from 'redux-observable';
import { State } from '../reducers';
import { selectUserId } from '../selectors/user/userId.selector';
import { selectUserToken } from '../selectors/user/userToken.selector';
import { COPY_PROJECT, CopyProjectAction } from '../actions/copyProject.action';
import { fetchProjectState } from '../../api/v1/fetchProjectState.api';
import { projectCopied } from '../actions/projectCopied.action';
import { copyProject } from '../../domain/project/copyProject';
import { goToProjectDetails } from '../actions/navigations/toolbox/projectPanel/goToProjectDetails.action';

type CopyProjectActionWithState = [CopyProjectAction, State];

export const copyProjectEpic: Epic<Action, Action, State> = (actions$, state$) =>
  actions$.pipe(
    ofType(COPY_PROJECT),
    withLatestFrom<CopyProjectAction, CopyProjectActionWithState>(state$),
    switchMap(
      ([
        {
          payload: { sourceProjectId, saveAsName }
        },
        state
      ]: CopyProjectActionWithState) => {
        const userId = selectUserId(state);
        const userToken = selectUserToken(state);

        return from(fetchProjectState(sourceProjectId, userId, userToken)).pipe(
          map(copyProject(saveAsName)),
          mergeMap((project) => from([projectCopied(project), goToProjectDetails()]))
        );
      }
    )
  );
