import { isSectionWithTheoreticalSurface } from '../../SectionWithTheoreticalSurface';
import {
  LevelGranulometry,
  LevelGranulometryFullFilled,
  LevelGranulometryWithBearingRelativeSections
} from '../../../levels/LevelGranulometry';
import { SectionWithExposureRate } from '../../SectionWithExposureRate';
import { isSectionAHall } from '../../circulationSections/hall';
import { isSectionARamp } from '../../circulationSections/ramp';
import { isSectionAnTechnicalPremise } from '../../technicalPremiseSections/TechnicalPremiseSection';
import { getHallSectionTheoreticalWallsLinearDetails } from './theoreticalWalls/hallSection/getHallSectionTheoreticalWallsLinearDetails';
import { getTechnicalPremiseSectionTheoreticalWallsLinearDetails } from './theoreticalWalls/technicalPremiseSection/getTechnicalPremiseSectionTheoreticalWallsLinearDetails';
import { getRampSectionTheoreticalWallsLinearDetails } from './theoreticalWalls/rampSection/getRampSectionTheoreticalWallsLinearDetails';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { EXPONENT, getSectionWallsLinear, MULTIPLIER_COEFFICIENT } from './getSectionWallsLinear';
import { isSectionACommonPremise } from '../../commonPremiseSections/CommonPremiseSection';
import { getCommonPremiseSectionTheoreticalWallsLinearDetails } from './theoreticalWalls/commonPremiseSection/getCommonPremiseSectionTheoreticalWallsLinearDetails';
import { isSectionAShop } from '../../shopSection/shopSection';
import { getShopSectionTheoreticalWallsLinearDetails } from './theoreticalWalls/shopSection/getShopSectionTheoreticalWallsLinearDetails';
import { isSectionAnOffice } from '../../officeSection/officeSection';
import { getOfficeSectionTheoreticalWallsLinearDetails } from './theoreticalWalls/officeSection/getOfficeSectionTheoreticalWallsLinearDetails';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';

export const getSectionWallsLinearDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry:
    | LevelGranulometryWithBearingRelativeSections
    | LevelGranulometryFullFilled
    | LevelGranulometry,
  section: SectionWithExposureRate
): string => {
  if (isSectionAHall(section)) {
    return getHallSectionTheoreticalWallsLinearDetails(caseGranulometry);
  } else if (isSectionAnTechnicalPremise(section)) {
    return getTechnicalPremiseSectionTheoreticalWallsLinearDetails(section);
  } else if (isSectionACommonPremise(section)) {
    return getCommonPremiseSectionTheoreticalWallsLinearDetails(section);
  } else if (isSectionAShop(section)) {
    return getShopSectionTheoreticalWallsLinearDetails(section);
  } else if (isSectionAnOffice(section)) {
    return getOfficeSectionTheoreticalWallsLinearDetails(section);
  } else if (isSectionARamp(section)) {
    return getRampSectionTheoreticalWallsLinearDetails(caseGranulometry);
  } else if (isSectionWithTheoreticalSurface(section)) {
    let details: string[] = [];
    details = [
      ...details,
      '<b>' +
        roundWith2Decimal(section.displayedSurface) +
        ' m\u00B2</b> de surface réelle de ' +
        section.title
    ];
    const hideNumbers = !(process.env.GB_ENV === 'dev' || process.env.GB_ENV === 'recette');
    details = [
      ...details,
      '<b>* ' +
        (hideNumbers
          ? 'coefficient multiplicateur'
          : MULTIPLIER_COEFFICIENT + '<sup>' + EXPONENT + '</sup>') +
        '</b>'
    ];
    details = [
      ...details,
      '<b>= ' +
        roundWith2Decimal(getSectionWallsLinear(caseGranulometry, levelGranulometry, section)) +
        ' mL</b>'
    ];
    return details.join('<br />');
  } else {
    return '<b>ERR !</b>';
  }
};
