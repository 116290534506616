import { LevelGranulometry } from '../../../../LevelGranulometry';
import { isGroundLevel } from '../../../is/isGroundLevel';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { mustHaveAHallSection } from '../../../mustHave/mustHaveAHallSection';
import { getSectionDisplayedSurface } from '../../../../../sections/queries/surfaces/getSectionDisplayedSurface';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { getLevelMaxSurfaceForSale } from './getLevelMaxSurfaceForSale';
import { getLevelTechnicalPermiseSections } from '../../../sections/getLevelTechnicalPermiseSections';
import { getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface } from '../sectionsWithTechnicalSheaths/getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface';
import { getTopLevelMinimumBearingSurface } from '../getTopLevelMinimumBearingSurface';
import { BEARING_EMERGENCY_STAIRS_SURFACE } from '../../../../../../../constants/appConstants';
import { getInFullFilledTopLevelHallSectionSurface } from '../../sections/surfaces/getInFullFilledTopLevelHallSectionSurface';
import { getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface } from '../../walls/includedInSurfaceForSaleSectionWalls/getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface';
import { getTopLevelLodgmentsPartitionWallsSurface } from '../../walls/lodgmentWalls/getTopLevelLodgmentsPartitionWallsSurface';

export const getLevelMaxSurfaceForSaleDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): string => {
  let details: string[] = [];
  details = [
    ...details,
    '<b>' +
      roundWith2Decimal(levelGranulometry.realBuiltSurface as number) +
      ' m\u00B2</b> de Sdp Réelle'
  ];
  details = [
    ...details,
    '<b>- ' +
      roundWith2Decimal(
        getTopLevelLodgmentsPartitionWallsSurface(caseGranulometry, levelGranulometry).value
      ) +
      ' m\u00B2</b> d’emprise de cloisons logements'
  ];
  details = [
    ...details,
    '<b>- ' +
      roundWith2Decimal(
        getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalInsideWallsSurface(
          caseGranulometry,
          levelGranulometry
        ).value
      ) +
      ' m\u00B2</b> d’emprise de voiles non-exposés de <i>Sections utiles</i>'
  ];
  details = [
    ...details,
    '<b>- ' +
      roundWith2Decimal(
        getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface(
          caseGranulometry,
          levelGranulometry
        ).value
      ) +
      ' m\u00B2</b> d’emprise théorique de gaines technique de <i>Sections utiles</i> et <i>Locaux Techniques</i>'
  ];
  details = [
    ...details,
    '<b>- ' + BEARING_EMERGENCY_STAIRS_SURFACE + ' m\u00B2</b> d‘escalier de secours'
  ];
  details = [
    ...details,
    '<b>- ' +
      roundWith2Decimal(
        getTopLevelMinimumBearingSurface(caseGranulometry, levelGranulometry).value
      ) +
      ' m\u00B2</b> de surface minimale de pallier (GTP incluses)'
  ];
  // Technical premises
  details = getLevelTechnicalPermiseSections(levelGranulometry).reduce(
    (acc, technicalPermise) => [
      ...acc,
      '<b>- ' +
        roundWith2Decimal(getSectionDisplayedSurface(technicalPermise)) +
        ' m\u00B2</b> de surface de ' +
        technicalPermise.title.toLowerCase()
    ],
    details
  );
  if (isGroundLevel(levelGranulometry)) {
    if (mustHaveAHallSection(caseGranulometry, levelGranulometry)) {
      details = [
        ...details,
        '<b>- ' +
          roundWith2Decimal(getInFullFilledTopLevelHallSectionSurface(levelGranulometry).value) +
          ' m\u00B2</b> de surface de hall'
      ];
    }
  }
  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(getLevelMaxSurfaceForSale(caseGranulometry, levelGranulometry)) +
      ' m\u00B2</b>'
  ];
  return details.join('<br />');
};
