import { createSelector, Selector } from 'reselect';
import { ifDefined } from '../../../utils/ifDefined';
import { selectUser } from './user.selector';
import { State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';
import { User } from '../../../domain/User';

export const selectUserId: Selector<State, Maybe<User['id']>> = createSelector(
  [selectUser],
  ifDefined((user) => user.id)
);
