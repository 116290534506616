import { from, of } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { Action } from 'redux';
import { Epic } from 'redux-observable';
import { pipe } from 'fp-ts/function';
import * as OB from 'fp-ts-rxjs/Observable';
import * as OE from 'fp-ts-rxjs/ObservableEither';
import { isNewProject, UnsavedProject } from '../../domain/project/Project';
import { User } from '../../domain/User';
import { createProject } from '../../api/v1/createProject.api';
import { ApiAuthError } from '../../api/v1/errors/auth.error';
import { ApiRedirectError } from '../../api/v1/errors/redirect.error';
import { addBreadcrumb, BREADCRUMB_CATEGORY } from '../../infra/sentry';
import { State } from '../reducers';
import userState from '../reducers/user.slice';
import { projectCreated } from '../actions/projectCreated.action';
import { projectCreationFailed } from '../actions/projectCreationFailed.action';
import { selectIsSavingProject } from '../selectors/project/isSavingProject.selector';
import { selectUserId } from '../selectors/user/userId.selector';
import { selectUserToken } from '../selectors/user/userToken.selector';
import { selectIsLoggedIn } from '../selectors/user/isLoggedIn.selector';
import { selectProject } from '../selectors/project'; // Should be import { allPass } from 'fp-ts-std/Predicate'; but does not work
import { selectIsStoreRehydrated } from '../selectors/isStoreRehydrated.selector';
import { AuthToken } from '../../AuthToken';
import { predicate } from 'fp-ts-std';
import { goToProjectDetails } from '../actions/navigations/toolbox/projectPanel/goToProjectDetails.action';

const hasSaveableNewProject = (state: State): boolean => {
  const project = selectProject(state);
  return !selectIsSavingProject(state) && !!project && isNewProject(project) && !!project.name;
};

export const createNewProjectWhenNamedEpic: Epic = (actions$, state$) => {
  return pipe(
    state$,
    filter<State>(predicate.allPass<State>([selectIsStoreRehydrated, selectIsLoggedIn])),
    distinctUntilChanged((s1, s2) => hasSaveableNewProject(s1) === hasSaveableNewProject(s2)),
    filter(hasSaveableNewProject),
    OB.chain((state) => {
      const project = selectProject(state) as UnsavedProject;
      const userId = selectUserId(state) as User['id'];
      const userToken = selectUserToken(state) as AuthToken;

      return pipe(
        from(createProject(project, userId, userToken)),
        OE.tryCatch,
        OE.chain((projectId: string) => {
          addBreadcrumb(`Project ${projectId} created`, 'info', BREADCRUMB_CATEGORY.API);

          return OE.fromObservable(
            from([projectCreated(projectId), goToProjectDetails(projectId)])
          );
        }),
        OE.getOrElse<unknown, Action>((error) => {
          if (error instanceof ApiAuthError || error instanceof ApiRedirectError) {
            return of(userState.actions.sessionExpired());
          }

          alert(
            'Votre projet n’a pas pu être créé. Notre équipe a été informée du problème. Veuillez réessayer ultérieurement.'
          );
          return of(projectCreationFailed());
        })
      );
    })
  );
};
