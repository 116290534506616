import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import * as R from 'ramda';
import { Project } from '../Project';
import { mapBuildings } from './mapBuildings';
import { BuildingSpecification } from '../../specification/buildings/BuildingSpecification';

export const mapCases =
  (fn: (caseSpecification: CaseSpecification) => CaseSpecification) => (project: Project) =>
    mapBuildings(
      R.over<BuildingSpecification, CaseSpecification[]>(R.lensProp('cases'), R.map(fn))
    )(project);
