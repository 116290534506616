import { ValidationRule } from '../ValidationsRule';
import { Notification } from '../Notification';
import { getProjectCasesProjections } from '../../projection/project/queries/cases/getProjectCasesProjections';
import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { isLodgmentsTypesDistributionValid } from '../../specification/lodgmentTypes/LodgmentTypesDistribution';
import { getCaseLabel } from '../../projection/cases/queries/get/getCaseLabel';

export const projectCasesDistributionMustBeValid: ValidationRule = (
  projectProjection: ProjectProjection
) => {
  const notifications: Notification[] = getProjectCasesProjections(projectProjection).reduce(
    (accNotifications, caseProjectionWithIndexes) => {
      if (!isLodgmentsTypesDistributionValid(caseProjectionWithIndexes.distribution)) {
        return [
          ...accNotifications,
          {
            title:
              'La distribution des types de logement pour la cage ' +
              getCaseLabel(caseProjectionWithIndexes) +
              ' n’est pas valide.',
            explanation:
              'La somme des pourcentages de type de logement, saisis et calculés, doit être égal à 100.',
            type: 'error'
          }
        ];
      }
      return accNotifications;
    },
    [] as Notification[]
  );

  return notifications.length !== 0 ? notifications : true;
};
