import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../ui/SpecialLabel';
import { FormGroup } from '../../../ui/FormGroup';
import { Card } from '../../../ui/Card';
import { Maybe } from '../../../../utils/Maybe';
import { BuildingSpecification } from '../../../../domain/specification/buildings/BuildingSpecification';
import { Option, Select } from '../../../ui/Select';
import { useDispatch } from 'react-redux';
import { buildingMoved } from '../../../../store/actions/buildingMoved.action';

interface PositioningBuildingCardProps {
  buildings: Maybe<BuildingSpecification[]>;
  building: Maybe<BuildingSpecification>;
  buildingIndex: number;
}

const PositioningBuildingCard = ({
  buildings,
  building,
  buildingIndex
}: PositioningBuildingCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (!buildings) return null;

  const positions = Array.from(Array(buildings.length).keys());
  const positionToOption = (position: number): Option => ({
    label: (position + 1).toString(),
    value: position
  });
  const positionsOptions = positions.map(positionToOption);
  const selectedPosition = positionsOptions.find((option) => option.value === buildingIndex);

  if (!buildings || !building) return null;

  return (
    <Card>
      <SpecialLabel label={t('Positioning')} />
      <FormGroup>
        <div style={{ width: '116px' }}>
          <label htmlFor={'building' + building.id + 'Position'}>{t('Building position')}</label>
          <Select
            id={'building' + building.id + 'Position'}
            options={positionsOptions}
            handleChange={({ value }: Option) => {
              dispatch(buildingMoved(building.id, value as number));
            }}
            value={selectedPosition}
            disabled={buildings.length === 1}
            style={{ marginTop: '3px' }}
          />
        </div>
      </FormGroup>
    </Card>
  );
};

export default PositioningBuildingCard;
