import { CaseProjection, DistributedLodgmentSection } from '../CaseProjection';
import { getDraftLodgmentsTypesDistributionRateDefaultValue } from '../../../specification/lodgmentTypes/LodgmentTypesDistribution';
import { LodgmentSection } from '../../../granulometry/sections/lodgmentSections/LodgmentSection';

export const setCaseProjectedDistribution = (caseProjection: CaseProjection): CaseProjection => {
  const defaultRateValue = getDraftLodgmentsTypesDistributionRateDefaultValue(
    caseProjection.distribution
  );

  const projectedDistribution: DistributedLodgmentSection[] = [...caseProjection.distribution].map(
    (lodgmentTypeRate) => {
      return {
        rate: lodgmentTypeRate.rate !== 0 ? lodgmentTypeRate.rate : defaultRateValue,
        ...(caseProjection.lodgmentsTypesSpecifications.find(
          (lt) => lt.lodgmentType === lodgmentTypeRate.lodgmentType
        ) as LodgmentSection)
      };
    }
  );

  return {
    ...caseProjection,
    projectedDistribution
  };
};
