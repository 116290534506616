import { createSelector, Selector } from 'reselect';
import { State } from '../../reducers';
import { selectIsNewProject } from '../project';
import { selectBuildingMapFocus } from '../buildingMap/buildingMapFocus.selector';
import { isFocusSection } from '../../../domain/buildingMap/BuildingMapFocus';

export const selectIsDataBarDisplayed: Selector<State, boolean> = createSelector(
  [selectIsNewProject, selectBuildingMapFocus],
  (isNewProject, buildingMapFocus) => !isNewProject && !isFocusSection(buildingMapFocus)
);
