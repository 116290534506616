import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import { LevelGranulometry } from '../../LevelGranulometry';
import { getLowerLevel } from '../getLowerLevel';
import { isFoundationLevel } from '../is/isFoundationLevel';

export const hasLowerLevel = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): boolean =>
  getLowerLevel(caseGranulometry, levelGranulometry) !== undefined &&
  !isFoundationLevel(
    caseGranulometry,
    getLowerLevel(caseGranulometry, levelGranulometry) as LevelGranulometry
  );
