import { LevelGranulometryFullFilled } from '../../../LevelGranulometry';
import { SQUARE_METER_SCALE } from '../../../../../../constants/appConstants';
import { isSectionAHall } from '../../../../sections/circulationSections/hall';
import { getSectionsTotalDrawnSurface } from '../../../../sections/queries/surfaces/getSectionsTotalDrawnSurface';
import { getLevelDisplayedWallThickness } from '../../getLevelDisplayedWallThickness';
import { isSectionACirculation } from '../../../../sections/circulationSections/CirculationSection';
import { isSectionABearing } from '../../../../sections/circulationSections/bearing';
import { getLevelFullFilledContent } from '../../content/getLevelFullFilledContent';

export const getTopLevelSfsRulerLeft = (levelGranulometry: LevelGranulometryFullFilled): number => {
  const circulationsSurface = getSectionsTotalDrawnSurface(
    getLevelFullFilledContent(levelGranulometry).filter(
      (section) => !isSectionAHall(section) && isSectionACirculation(section)
    )
  );
  const displayedWallThickness = getLevelDisplayedWallThickness(levelGranulometry);
  const hasBearing =
    !!getLevelFullFilledContent(levelGranulometry).filter(isSectionABearing).length;
  return (
    (circulationsSurface + (hasBearing ? displayedWallThickness * 10 : 0)) * SQUARE_METER_SCALE
  );
};
