import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ScrollSyncPane } from 'react-scroll-sync';
import * as R from 'ramda';
import {
  getDraftLodgmentsTypesDistributionRateDefaultValue,
  getDraftLodgmentsTypesDistributionTotalRate,
  isLodgmentsTypesDistributionValid,
  LodgmentTypesDistribution,
  LodgmentTypeRate,
  updateLodgmentsTypesDistribution
} from '../../../domain/specification/lodgmentTypes/LodgmentTypesDistribution';
import { LodgmentType } from '../../../domain/specification/lodgmentTypes/LodgmentType';
import { BracketHorizontal } from 'components/ui/BracketHorizontal';
import { setCaseLodgmentsTypesDistribution } from 'store/actions/setCaseLodgmentsTypesDistribution.action';
import { selectCurrentCaseIdFromRoute } from 'store/selectors/navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { selectCurrentCaseLodgmentsTypesDistributionWithCustomDistribution } from '../../../store/selectors/specification/currentCase/lodgmentsTypesDistributionWithCustomDistribution.selector';
import { selectCurrentCaseHasCustomDistribution } from '../../../store/selectors/specification/currentCase/hasCustomDistribution.selector';
import { roundWith1Decimal } from '../../../utils/round/roundWith1Decimal';
import { selectCurrentCaseEditingStage } from '../../../store/selectors/specification/currentCase/editingStage.selector';
import { CaseEditingStageName } from '../../../domain/specification/cases/CaseSpecification';
import { selectCurrentCaseLodgmentsTypesDistributionWithSpecifications } from '../../../store/selectors/specification/currentCase/lodgmentsTypesDistributionWithSpecifications.selector';
import { CELL_WIDTH } from './CardToScroll';
import { Input } from '../../ui/input/Input';

const DistributionLine = () => {
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const hasCustomDistribution = useSelector(selectCurrentCaseHasCustomDistribution);
  const editingStage = useSelector(selectCurrentCaseEditingStage);
  const distributionFromGranulometryStage = useSelector(
    selectCurrentCaseLodgmentsTypesDistributionWithSpecifications
  );
  const distributionFromDistributionStage = useSelector(
    selectCurrentCaseLodgmentsTypesDistributionWithCustomDistribution
  );

  const distribution =
    editingStage === CaseEditingStageName.Granulation
      ? distributionFromGranulometryStage
      : distributionFromDistributionStage;

  const dispatch = useDispatch();

  const [distributionDraft, setDistributionDraft] =
    React.useState<LodgmentTypesDistribution>(distribution);

  React.useEffect(() => {
    setDistributionDraft(distribution);
  }, [distribution]);

  if (!distribution || !distributionDraft) return null;

  const totalRate =
    editingStage === CaseEditingStageName.Distribution
      ? 100
      : getDraftLodgmentsTypesDistributionTotalRate(distributionDraft);

  const isValid =
    editingStage === CaseEditingStageName.Distribution
      ? true
      : isLodgmentsTypesDistributionValid(distributionDraft);

  const defaultRateValue = roundWith1Decimal(
    getDraftLodgmentsTypesDistributionRateDefaultValue(distributionDraft)
  );

  const handleInputChange = (lodgmentType: LodgmentType) => (rate: number) => {
    if (distributionDraft) {
      const resultingDistribution: LodgmentTypesDistribution = updateLodgmentsTypesDistribution(
        lodgmentType,
        rate || 0,
        distributionDraft
      );
      setDistributionDraft(resultingDistribution);
      if (caseId) {
        dispatch(
          setCaseLodgmentsTypesDistribution(
            caseId,
            R.map(R.pick(['lodgmentType', 'rate']), resultingDistribution)
          )
        );
      }
    }
  };

  const width = `${CELL_WIDTH * distributionDraft.length}px`;

  return (
    <div className="line distribution-line">
      <div className="column-1">
        <div className="cell">
          <span className="label">%</span>
          <BracketHorizontal />
        </div>
      </div>
      <ScrollSyncPane>
        <div className="column-2" tabIndex={-1}>
          <div style={{ width }}>
            {distributionDraft?.map(({ lodgmentType, rate }: LodgmentTypeRate) => {
              return (
                <div className="cell" key={lodgmentType}>
                  <Input
                    handleChange={handleInputChange(lodgmentType)}
                    name={`topLevel${lodgmentType}Rate`}
                    type="number"
                    size="small"
                    min={0}
                    max={100}
                    disabled={editingStage === CaseEditingStageName.Distribution}
                    asCustomValue={
                      editingStage === CaseEditingStageName.Distribution && hasCustomDistribution
                        ? false
                        : undefined
                    }
                    value={
                      (editingStage === CaseEditingStageName.Distribution &&
                        hasCustomDistribution) ||
                      rate === 0
                        ? editingStage === CaseEditingStageName.Distribution &&
                          hasCustomDistribution
                          ? roundWith1Decimal(rate)
                          : undefined
                        : roundWith1Decimal(rate)
                    }
                    placeholder={rate === 0 ? defaultRateValue.toString() : '0'}
                  />
                </div>
              );
            })}
            <div className="clear" />
          </div>
        </div>
      </ScrollSyncPane>
      <div className="column-3">
        <Input
          name="totalRate"
          type="number"
          size="small"
          disabled={isValid}
          value={totalRate}
          color={isValid ? undefined : 'orange'}
          tabIndex={-1}
          style={{ pointerEvents: 'none' }}
        />
      </div>
      <div className="column-4">
        <div className="cell">&nbsp;</div>
      </div>
    </div>
  );
};

export default DistributionLine;
