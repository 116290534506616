import { RoomSpecification } from '../../RoomSpecification';
import * as R from 'ramda';
import { isRoomSpecificationABasicRoom } from '../../BasicRoomSpecification';
import { getBasicRoomSpecificationSurface } from './getBasicRoomSpecificationSurface';

type GetLivingRoomKitchenSpecificationCalculatedSurface = (
  rooms: RoomSpecification[],
  lodgmentSurface: number
) => number;
export const getLivingRoomKitchenSpecificationCalculatedSurface: GetLivingRoomKitchenSpecificationCalculatedSurface =
  (rooms, lodgmentSurface) =>
    R.pipe<RoomSpecification[], number, number>(
      R.reduce(
        (acc, roomSpecification) =>
          isRoomSpecificationABasicRoom(roomSpecification)
            ? acc + getBasicRoomSpecificationSurface(roomSpecification)
            : acc,
        0
      ),
      R.subtract(lodgmentSurface)
    )(rooms);
