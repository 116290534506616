import { createSelector, Selector } from 'reselect';
import { State } from '../../../../store/reducers';
import { selectCurrentCaseProjection } from '../../projection/currentCase/projection.selector';
import { selectCurrentLevelCarBoxInfraCount } from './currentLevelCarBoxInfraCount.selector';
import { selectCurrentLevelCarParkInfraCount } from './currentLevelCarParkInfraCount.selector';
import { selectCurrentCaseBasementLevelsCount } from '../../granulometry/currentCase/basementLevelsCount.selector';
import { getBasementLevelOutsideWallsLinear } from '../../../../domain/granulometry/levels/queries/basementLevels/getBasementLevelOutsideWallsLinear';

export const selectCurrentLevelOutsideWallInfraLinear: Selector<State, number> = createSelector(
  [
    selectCurrentCaseProjection,
    selectCurrentLevelCarBoxInfraCount,
    selectCurrentLevelCarParkInfraCount,
    selectCurrentCaseBasementLevelsCount
  ],
  (
    currentCase,
    currentLevelCarBoxInfraCount,
    currentLevelCarParkInfraCount,
    currentCaseBasementLevelsCount
  ): number => {
    if (currentCase) {
      return getBasementLevelOutsideWallsLinear(
        currentCase,
        currentLevelCarBoxInfraCount || 0,
        currentLevelCarParkInfraCount || 0,
        currentCaseBasementLevelsCount || 0
      );
    } else {
      return 0;
    }
  }
);
