import * as R from 'ramda';
import { CaseGranulometry } from '../../CaseGranulometry';
import {
  LevelGranulometry,
  SectionInLevelGranulometryFullFilledContent
} from '../../../levels/LevelGranulometry';
import { isSectionWithTechnicalSheaths } from '../../../sections/SectionWithTechnicalSheaths';
import { getCaseTechnicalSheathsDistributionByTopLevel } from './distributeTechnicalSheaths/getCaseTechnicalSheathsDistributionByTopLevel';

export const distributeTechnicalSheathsInSectionsWithTS = (
  caseGranulometry: CaseGranulometry
): CaseGranulometry => {
  const countsByTopLevel = getCaseTechnicalSheathsDistributionByTopLevel(caseGranulometry);
  return R.over<CaseGranulometry, LevelGranulometry[]>(
    R.lensProp('levels'),
    R.map((level) => {
      const levelSheathsDistribution = countsByTopLevel.find(
        (count) => count.levelIndex === level.level
      )?.sheathsDistribution;
      let i = -1;
      return levelSheathsDistribution
        ? R.over<LevelGranulometry, SectionInLevelGranulometryFullFilledContent[] | undefined>(
            R.lensProp('content'),
            (content) =>
              R.map((section: SectionInLevelGranulometryFullFilledContent) => {
                if (isSectionWithTechnicalSheaths(section)) {
                  i = i + 1;
                  return {
                    ...section,
                    technicalSheathCount: levelSheathsDistribution[i]
                  };
                } else {
                  return section;
                }
              })(content || [])
          )(level)
        : level;
    })
  )(caseGranulometry);
};
