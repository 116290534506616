import { Surface } from '../../Surface';
import { getCaseGrossFloorSurfaceEff } from '../../../granulometry/cases/queries/surfaces/getCaseGrossFloorSurfaceEff';
import { CaseGranulometry } from '../../../granulometry/cases/CaseGranulometry';

export const getProjectGfsEffFromCaseGranulometries = (
  caseGranulometries: CaseGranulometry[]
): Surface =>
  new Surface(
    caseGranulometries.reduce(
      (acc, caseGranulometry) => acc + getCaseGrossFloorSurfaceEff(caseGranulometry).value,
      0
    )
  );
