import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CardTitle } from 'components/ui/CardTitle';
import { IconObject } from 'components/ui/Icons/iconObject';
import { Card } from 'components/ui/Card';
import { selectIsNewProject, selectProjectId } from 'store/selectors/project';

import { goToProject } from '../../../store/actions/navigations/toolbox/projectPanel/goToProject.action';

interface PanelTitleProps {
  title: string;
}

const PanelTitle = ({ title }: PanelTitleProps) => {
  const isNewProject = useSelector(selectIsNewProject);
  const projectId = useSelector(selectProjectId) as string;
  const dispatch = useDispatch();
  const handleClick = React.useCallback(
    () => dispatch(goToProject(projectId)),
    [dispatch, projectId]
  );

  return (
    <Card>
      <CardTitle>
        {title}
        <IconObject iconName="close" type="menu" onClick={handleClick} disabled={isNewProject} />
      </CardTitle>
    </Card>
  );
};

export default PanelTitle;
