import * as React from 'react';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { FormGroup } from 'components/ui/FormGroup';
import { ProjectSurfaceInputMode } from '../../../../../../domain/project/Project';
import { Option, Select } from '../../../../../ui/Select';
import { projectSurfaceInputModeChanged } from '../../../../../../store/actions/projectSurfaceInputModeChanged.action';
import { useState } from 'react';

interface ProjectSurfaceTypeFormProps {
  selectedProjectSurfaceInputMode: ProjectSurfaceInputMode;
  setSelectedProjectSurfaceInputMode: React.Dispatch<React.SetStateAction<ProjectSurfaceInputMode>>;
}

export const ProjectSurfaceTypeForm = ({
  selectedProjectSurfaceInputMode,
  setSelectedProjectSurfaceInputMode
}: ProjectSurfaceTypeFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projectSurfaceInputModeOptions: Option[] = [
    {
      label: i18next.t('projectSurface.surfaceInputMode.cbs'),
      value: 'cbs'
    },
    {
      label: i18next.t('projectSurface.surfaceInputMode.gfs'),
      value: 'gfsEff'
    }
  ];

  const handleProjectSurfaceTypeChange = (surfaceInputModeOption: Option) => {
    const surfaceInputMode = surfaceInputModeOption.value as ProjectSurfaceInputMode;
    setSelectedProjectSurfaceInputMode(surfaceInputMode);
    dispatch(projectSurfaceInputModeChanged(surfaceInputMode));
  };

  return (
    <FormGroup>
      <label htmlFor={`projectSurfaceType`}>{t('projectSurface.surfaceInputMode.label')}</label>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyItems: 'center',
          marginTop: '4px',
          marginBottom: '4px'
        }}>
        <Select
          id={`projectSurfaceType`}
          options={projectSurfaceInputModeOptions}
          handleChange={handleProjectSurfaceTypeChange}
          value={projectSurfaceInputModeOptions.find(
            (option) => option.value === selectedProjectSurfaceInputMode
          )}
          style={{ flexBasis: '200px' }}
        />
      </div>
    </FormGroup>
  );
};
