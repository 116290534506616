import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Card } from 'components/ui/Card';
import { Logo } from '../../ui/Logo';
import { selectUser } from '../../../store/selectors/user';
import { User } from '../../../domain/User';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const ProjectDialog = ({ className, children }: Props) => {
  const user = useSelector(selectUser);

  const { t } = useTranslation();

  return (
    <Card
      className={className}
      header={
        <>
          <Logo />
          {t('hiUsername', { username: (user as User).userName })}
        </>
      }>
      {children}
    </Card>
  );
};
