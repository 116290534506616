import { LodgmentType } from '../../../../specification/lodgmentTypes/LodgmentType';
import { CaseProjection } from '../../CaseProjection';

export const getCaseLodgmentDistribution = (caseProjection: CaseProjection) => {
  const clonedLodgmentDistribution = [...caseProjection.projectedDistribution];
  const needToBeReversed =
    clonedLodgmentDistribution[0].lodgmentType.toLowerCase() === LodgmentType.T1;
  if (needToBeReversed) {
    clonedLodgmentDistribution.reverse();
  }

  return clonedLodgmentDistribution;
};
