import { LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { getTopLevelRealInsideWallsLinear } from './getTopLevelRealInsideWallsLinear';
import { getTopLevelInsideWallsThickness } from '../wallsThickness/getTopLevelInsideWallsThickness';
import { getTopLevelRealInsideWallsSurface } from './getTopLevelRealInsideWallsSurface';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelRealInsideWallsSurfaceDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled
): string =>
  '<b>' +
  roundWith2Decimal(getTopLevelRealInsideWallsLinear(caseGranulometry, levelGranulometry)) +
  ' mL</b> de linéaire réel de voiles non-exposés<br /><b>* ' +
  roundWith2Decimal(getTopLevelInsideWallsThickness(caseGranulometry)) +
  ' m</b> d’épaisseur de voiles non-exposés / 2<br /><b>= ' +
  roundWith2Decimal(getTopLevelRealInsideWallsSurface(caseGranulometry, levelGranulometry)) +
  ' m\u00B2</b>';
