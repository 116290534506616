import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { asEmail, asPassword, Email, Password } from '../../domain/User';
import { getUserState } from '../../store/reducers';
import session from '../../store/reducers/user.slice';
import LoginForm from './LoginForm';

export const Login: React.FC = () => {
  const [password, setPassword] = React.useState<Password>(asPassword(''));
  const [email, setUserEmail] = React.useState<Email>(asEmail(''));

  const dispatch = useDispatch();

  const userState = useSelector(getUserState);

  const handleEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setUserEmail(asEmail(e.currentTarget.value));
  };
  const handlePasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setPassword(asPassword(e.currentTarget.value));
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (email && password) {
      dispatch(session.actions.authenticate({ email, password, auto: false }));
    }
  };

  return (
    <LoginForm
      error={userState.sessionState === 'DENIED' ? userState.auth.errorMessage : undefined}
      password={password}
      email={email}
      onUserEmailChange={handleEmailChange}
      onPasswordChange={handlePasswordChange}
      onSubmit={handleSubmit}
    />
  );
};
