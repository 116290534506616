import { createSelector, Selector } from 'reselect';
import { State } from '../../../reducers';
import { Section } from '../../../../domain/granulometry/sections/Section';
import { selectGranulometry } from '../current.selector';
import { Maybe } from '../../../../utils/Maybe';
import { Granulometry } from '../../../../domain/granulometry/Granulometry';

export const selectAllSectionsInGranulometry: Selector<State, Maybe<Section[]>> = createSelector(
  [selectGranulometry],
  (granulometry: Maybe<Granulometry>) =>
    granulometry
      ? granulometry.reduce(
          (accB, buildingGranulometry) => [
            ...accB,
            ...buildingGranulometry.cases.reduce(
              (accC, caseGranulometry) => [
                ...accC,
                ...caseGranulometry.levels.reduce(
                  (accL, level) => (level.content ? [...accL, ...level.content] : accL),
                  [] as Section[]
                )
              ],
              [] as Section[]
            )
          ],
          [] as Section[]
        )
      : undefined
);
