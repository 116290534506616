export const PROJECT_COM_ETH_IC_CONSTRUCTION_DEFAULT_TOLERANCE_RATE_CHANGED =
  'project/comEthIcConstructionToleranceRate_changed';

export const projectComEthIcConstructionToleranceRateChanged = (
  comEthIcConstructionToleranceRate: number | undefined
) => ({
  type: PROJECT_COM_ETH_IC_CONSTRUCTION_DEFAULT_TOLERANCE_RATE_CHANGED,
  payload: { comEthIcConstructionToleranceRate }
});

export type ProjectComEthIcConstructionToleranceRateChangedAction = ReturnType<
  typeof projectComEthIcConstructionToleranceRateChanged
>;
