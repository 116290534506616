import { getCasePropertyValue } from '../../../cases/queries/get/properties/getCasePropertyValue';
import { LevelSpecification } from '../../LevelSpecification';
import { isTopLevel } from '../is/isTopLevel';
import { CaseProjection } from '../../../../projection/cases/CaseProjection';

export const getLevelDefaultCeilingHeight = (
  currentlyEditedCase: CaseProjection,
  levelSpecification: LevelSpecification
): number =>
  isTopLevel(levelSpecification)
    ? (getCasePropertyValue(currentlyEditedCase, 'topLevelCeilingHeight') as number)
    : (getCasePropertyValue(currentlyEditedCase, 'basementLevelCeilingHeight') as number);
