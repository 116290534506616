import { Property } from '../../../Property';
import { CaseSpecification } from '../../../CaseSpecification';
import { CaseProjection } from '../../../../../projection/cases/CaseProjection';
import { CaseGranulometry } from '../../../../../granulometry/cases/CaseGranulometry';
import { getCaseProperties } from './getCaseProperties';

export const getCaseProperty = (
  anyTypeOfCase: CaseSpecification | CaseProjection | CaseGranulometry,
  propertyName: string
): Property | undefined =>
  getCaseProperties(anyTypeOfCase).find((property) => property.name === propertyName);
