import { CaseSpecification } from '../../../CaseSpecification';
import { getLevelOutsideWallsSurfaceFromLevelsGeometry } from '../../levels/surfaces/getLevelOutsideWallsSurfaceFromLevelsGeometry';
import { getCaseTopLevels } from '../levels/getCaseTopLevels';

export const getCaseTopLevelsOutsideWallsSurfaceFromLevelsGeometry = (
  caseSpecification: CaseSpecification
): number =>
  getCaseTopLevels(caseSpecification).reduce(
    (acc, levelSpecification) =>
      acc +
      getLevelOutsideWallsSurfaceFromLevelsGeometry(caseSpecification, levelSpecification, true),
    0
  );
