import { createSelector, Selector } from 'reselect';
import * as R from 'ramda';
import { LodgmentsCountByTopLevelAndType } from '../../../../domain/granulometry/cases/queries/sections/counts/getCaseLodgmentSectionsCountByTopLevelAndType';
import { LodgmentsCountByType } from '../../../../domain/granulometry/levels/queries/topLevels/counts/getTopLevelLodgmentsCountByType';
import { selectCurrentCaseIdFromRoute } from '../../navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { selectLodgmentsCountByBuildingCaseTopLevelAndType } from '../lodgmentsCountByBuildingCaseTopLevelAndType.selector';
import { selectParentBuildingFromCaseRoute } from '../../navigation/toolbox/casesPanel/parentBuildingFromCaseRoute.selector';
import { LodgmentsCountByBuildingCaseLevelAndType } from '../../../../domain/granulometry/queries/getLodgmentsCountByBuildingCaseTopLevelAndType';

import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';

export const selectCurrentCaseLodgmentsCountByType: Selector<
  State,
  Maybe<LodgmentsCountByType>
> = createSelector(
  [
    selectCurrentCaseIdFromRoute,
    selectParentBuildingFromCaseRoute,
    selectLodgmentsCountByBuildingCaseTopLevelAndType
  ],
  (caseId, building, lodgmentsCountByBuildingCaseLevelAndType) =>
    caseId && building && lodgmentsCountByBuildingCaseLevelAndType
      ? (R.pipe<
          [LodgmentsCountByBuildingCaseLevelAndType],
          LodgmentsCountByTopLevelAndType,
          LodgmentsCountByType[],
          LodgmentsCountByType
        >(
          R.pathOr({}, [building.id, caseId]),
          R.values,
          R.reduce(R.mergeWith(R.add), {} as LodgmentsCountByType)
        )(lodgmentsCountByBuildingCaseLevelAndType) as LodgmentsCountByType)
      : undefined
);
