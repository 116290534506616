import { createSelector, Selector } from 'reselect';
import { Maybe } from '../../../utils/Maybe';
import { Project } from '../../../domain/project/Project';
import { State } from '../../../store/reducers';
import { selectProject } from '../project/project.selector';
import { BuildingSpecification } from '../../../domain/specification/buildings/BuildingSpecification';

export const selectBuildings: Selector<State, Maybe<BuildingSpecification[]>> = createSelector(
  [selectProject],
  (p: Project | undefined) => p?.buildings
);
