import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { FormGroup } from '../../../../ui/FormGroup';
import { Checkbox } from '../../../../ui/Checkbox';
import { Card } from '../../../../ui/Card';
import { setCaseLevelSpecification } from '../../../../../store/actions/setCaseLevelSpecification.action';
import { selectCurrentlyEditedLevelSpecification } from '../../../../../store/selectors/navigation/toolbox/casesPanel/currentlyEditedLevelSpecification.selector';
import { selectCurrentCaseProjection } from '../../../../../store/selectors/projection/currentCase/projection.selector';
import { TopLevelSpecification } from '../../../../../domain/specification/levels/TopLevelSpecification';
import { mustHaveGasBearingTechnicalSheaths } from '../../../../../domain/granulometry/sections/queries/bearingTechnicalSheaths/mustHaveGasBearingTechnicalSheaths';
import { selectCurrentCaseGranulometry } from '../../../../../store/selectors/granulometry/currentCase/currentCaseGranulometry.selector';
import { getCaseAverageHeatingSystem } from '../../../../../domain/granulometry/cases/queries/get/getCaseAverageHeatingSystem';
import { HeatingSystem } from '../../../../../domain/specification/lodgmentTypes/HeatingSystem';

type Prefix = 'water' | 'general' | 'gas' | 'electricity' | 'communication';
export const BearingTechnicalSheaths = () => {
  const topLevelSpecification = useSelector(
    selectCurrentlyEditedLevelSpecification
  ) as TopLevelSpecification;
  const caseProjection = useSelector(selectCurrentCaseProjection);
  const caseGranulometry = useSelector(selectCurrentCaseGranulometry);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleBearingTechnicalSheathEnabledChange = React.useCallback(
    (prefix: Prefix | undefined, newEnabledValue: boolean) => {
      if (caseProjection && topLevelSpecification) {
        const newTopLevelSpecification =
          prefix === undefined
            ? {
                ...topLevelSpecification,
                waterBearingTechnicalSheathEnabled: newEnabledValue,
                generalBearingTechnicalSheathEnabled: newEnabledValue,
                gasBearingTechnicalSheathEnabled: newEnabledValue,
                electricityBearingTechnicalSheathEnabled: newEnabledValue,
                communicationBearingTechnicalSheathEnabled: newEnabledValue
              }
            : {
                ...topLevelSpecification,
                [prefix + 'BearingTechnicalSheathEnabled']: newEnabledValue
              };
        dispatch(setCaseLevelSpecification(caseProjection.id, newTopLevelSpecification));
      }
    },
    [dispatch, caseProjection, topLevelSpecification]
  );

  if (!topLevelSpecification || !caseProjection || !caseGranulometry) return null;

  const currentTechnicalSheathsEnabled: {
    water?: boolean;
    general?: boolean;
    gas?: boolean;
    electricity?: boolean;
    communication?: boolean;
  } = {
    water: topLevelSpecification.waterBearingTechnicalSheathEnabled,
    general: topLevelSpecification.generalBearingTechnicalSheathEnabled,
    gas: topLevelSpecification.gasBearingTechnicalSheathEnabled,
    electricity: topLevelSpecification.electricityBearingTechnicalSheathEnabled,
    communication: topLevelSpecification.communicationBearingTechnicalSheathEnabled
  };

  const prefixes: Prefix[] = ['water', 'general', 'gas', 'electricity', 'communication'];

  const allSelected = prefixes.reduce(
    (selected, prefix) => selected && currentTechnicalSheathsEnabled[prefix] !== false,
    true
  );

  return (
    <Card className="bearingTechnicalSheaths">
      <SpecialLabel label={t('bearingTechnicalSheaths.specialLabel')} />
      <FormGroup>
        <Checkbox
          label={t('bearingTechnicalSheaths.all.label')}
          selected={allSelected}
          checkboxName={'allBearingTechnicalSheathEnabled'}
          handleCheck={() => handleBearingTechnicalSheathEnabledChange(undefined, !allSelected)}
        />
      </FormGroup>
      <hr style={{ margin: '8px 0 -2px' }} />
      {prefixes.map((prefix, index) => (
        <FormGroup key={index}>
          <Checkbox
            label={
              prefix === 'gas' && !mustHaveGasBearingTechnicalSheaths(caseGranulometry) ? (
                <s>{t('bearingTechnicalSheaths.' + prefix + '.label')}</s>
              ) : (
                t(
                  'bearingTechnicalSheaths.' +
                    prefix +
                    (prefix === 'gas'
                      ? getCaseAverageHeatingSystem(caseGranulometry) ===
                        HeatingSystem.IndividualGasHeating
                        ? '.individual'
                        : '.other'
                      : '') +
                    '.label'
                )
              )
            }
            selected={
              currentTechnicalSheathsEnabled[prefix] !== false &&
              !(prefix === 'gas' && !mustHaveGasBearingTechnicalSheaths(caseGranulometry))
            }
            checkboxName={prefix + 'BearingTechnicalSheathEnabled'}
            handleCheck={() =>
              handleBearingTechnicalSheathEnabledChange(
                prefix,
                currentTechnicalSheathsEnabled[prefix] === false
              )
            }
            disabled={prefix === 'gas' && !mustHaveGasBearingTechnicalSheaths(caseGranulometry)}
          />
        </FormGroup>
      ))}
    </Card>
  );
};
