import { createSelector, Selector } from 'reselect';
import { selectCurrentCaseSpecifiedBasementLevels } from './basementLevels.selector';
import { isBasementLevelsParkingRateRepartitionValid } from '../../../../domain/specification/levels/BasementLevelSpecification';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';

export const selectCurrentCaseBasementLevelsParkingRateRepartitionIsValid: Selector<
  State,
  Maybe<boolean>
> = createSelector([selectCurrentCaseSpecifiedBasementLevels], (levels) => {
  if (!levels) return undefined;
  return isBasementLevelsParkingRateRepartitionValid(levels);
});
