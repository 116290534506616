import { getCaseLodgmentDistribution } from './getCaseLodgmentDistribution';
import { getCaseLodgmentData } from './getCaseLodgmentData';
import { getCaseEligibleLodgmentRealCountByType } from './getCaseEligibleLodgmentRealCountByType';
import { LodgmentType } from '../../../../specification/lodgmentTypes/LodgmentType';
import { LodgmentSection } from '../../../../granulometry/sections/lodgmentSections/LodgmentSection';
import { LodgmentTypeSpecification } from '../../../../specification/lodgmentTypes/LodgmentTypeSpecification';
import { CaseProjection } from '../../CaseProjection';

export interface InitialLodgmentsData {
  type: LodgmentType;
  count: number;
  remaining: number;
  defaultData: LodgmentSection & LodgmentTypeSpecification;
}

/* Get the initial lodgements data in the given case */
export const getCaseInitialLodgmentsData = (
  caseProjection: CaseProjection
): InitialLodgmentsData[] => {
  const distribution = getCaseLodgmentDistribution(caseProjection);
  const lodgmentData = getCaseLodgmentData(caseProjection);
  const caseEligibleLodgmentRealCountByType =
    getCaseEligibleLodgmentRealCountByType(caseProjection);
  const initialLodgmentsData = [];
  // For each lodgement types
  for (let i = 0; i < distribution.length; i++) {
    const lodgmentType = distribution[i].lodgmentType;
    const realCountByType = { ...caseEligibleLodgmentRealCountByType[i] };
    initialLodgmentsData[i] = {
      type: lodgmentType,
      count: realCountByType.count,
      remaining: realCountByType.count,
      defaultData: lodgmentData[lodgmentType]
    };
  }

  return initialLodgmentsData;
};
