import * as React from 'react';
import { Column4Inner } from '../Column4';

export const Column4Enlarged = () => {
  return (
    <div className="column-4">
      <div className="cell">
        {process.env.GB_FEAT_GFS === 'true' && (
          <Column4Inner selectedLevelSurface={'grossFloorSurfaceEff'} />
        )}
      </div>
      <div className="cell">
        {process.env.GB_FEAT_GFS === 'true' && (
          <Column4Inner selectedLevelSurface={'grossFloorSurface'} />
        )}
      </div>
      <div className="cell">
        <Column4Inner selectedLevelSurface={'realBuiltSurface'} />
      </div>
      <div className="cell">
        <Column4Inner selectedLevelSurface={'surfaceForSale'} />
      </div>
    </div>
  );
};
