import { Surface } from '../../../../specification/Surface';
import { CaseGranulometry } from '../../CaseGranulometry';
import { getTopLevelGrossFloorSurface } from '../../../levels/queries/topLevels/surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurface';
import { isTopLevel } from '../../../levels/queries/is/isTopLevel';
import { getCaseLevels } from '../levels/getCaseLevels';

export const getCaseGrossFloorSurface = (caseGranulometry: CaseGranulometry): Surface =>
  new Surface(
    getCaseLevels(caseGranulometry).reduce(
      (acc, levelGranulometry) =>
        isTopLevel(caseGranulometry, levelGranulometry)
          ? acc + getTopLevelGrossFloorSurface(caseGranulometry, levelGranulometry)
          : acc,
      0
    )
  );
