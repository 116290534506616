import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  getLevelLabel,
  LevelGranulometry
} from '../../../../../domain/granulometry/levels/LevelGranulometry';
import { selectProjectId } from '../../../../../store/selectors/project';
import { selectCurrentlyEditedLevelSpecification } from '../../../../../store/selectors/navigation/toolbox/casesPanel/currentlyEditedLevelSpecification.selector';
import { selectCurrentCaseProjection } from '../../../../../store/selectors/projection/currentCase/projection.selector';
import { Card } from '../../../../ui/Card';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { Window } from '../../../../ui/Window';
import { IconObject } from '../../../../ui/Icons/iconObject';
import { Form } from '../../../../ui/Form';
import { CarBoxFormGroup } from './CarBox';
import { CarParkFormGroup } from './CarPark';
import { TotalCaseParking } from './TotalCaseParking';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { TotalLevelParking } from './TotalLevelParking';
import { PeripheralReinforcements } from './PeripheralReinforcements';
import { CeilingHeight } from './CeilingHeight';
import { TopLevelCalculationDetails } from './TopLevelCalculationDetails';
import { TechnicalPremiseSections } from './TechnicalPremiseSections';
import { CommonPremiseSections } from './CommonPremiseSections';
import { ShopSections } from './ShopSections';
import { OfficeSections } from './OfficeSections';
import { selectCurrentLevelGranulometry } from '../../../../../store/selectors/granulometry/currentLevel/granulometry.selector';
import { selectCurrentCaseGranulometry } from '../../../../../store/selectors/granulometry/currentCase/currentCaseGranulometry.selector';
import { hasHigherLevel } from '../../../../../domain/granulometry/levels/queries/has/hasHigherLevel';
import { hasLowerLevel } from '../../../../../domain/granulometry/levels/queries/has/hasLowerLevel';
import { getHigherLevel } from '../../../../../domain/granulometry/levels/queries/getHigherLevel';
import { getLowerLevel } from '../../../../../domain/granulometry/levels/queries/getLowerLevel';
import { isTopLevel } from '../../../../../domain/granulometry/levels/queries/is/isTopLevel';
import { isBasementLevel } from '../../../../../domain/granulometry/levels/queries/is/isBasementLevel';
import { BearingTechnicalSheaths } from './BearingTechnicalSheaths';
import { getCaseGranulometryEditLevelDetailsTriggerId } from '../../../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditLevelDetailsTriggerId';
import { goToCase } from '../../../../../store/actions/navigations/toolbox/casesPanel/goToCase.action';
import { goToCaseGranulometryEditLevelDetails } from '../../../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditLevelDetails.action';
import { BasementLevelCalculationDetails } from './BasementLevelCalculationDetails';

const LevelDetailsWindow = () => {
  const currentProjectId = useSelector(selectProjectId);
  const currentlyEditedCase = useSelector(selectCurrentCaseProjection);
  const currentlyEditedLevel = useSelector(selectCurrentlyEditedLevelSpecification);
  const levelGranulometry = useSelector(selectCurrentLevelGranulometry);
  const caseGranulometry = useSelector(selectCurrentCaseGranulometry);
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickClose = React.useCallback(() => {
    if (currentProjectId && currentlyEditedCase) {
      dispatch(goToCase(currentProjectId, currentlyEditedCase.id));
    }
  }, [dispatch, currentProjectId, currentlyEditedCase?.id]);

  const handleClickLevelUp = React.useCallback(() => {
    if (
      currentProjectId &&
      currentlyEditedCase &&
      currentlyEditedLevel &&
      caseGranulometry &&
      levelGranulometry
    ) {
      dispatch(
        goToCaseGranulometryEditLevelDetails(
          currentProjectId,
          currentlyEditedCase.id,
          (getHigherLevel(caseGranulometry, levelGranulometry) as LevelGranulometry).id
        )
      );
    }
  }, [dispatch, currentProjectId, currentlyEditedCase, currentlyEditedLevel]);

  const handleClickLevelDown = React.useCallback(() => {
    if (
      currentProjectId &&
      currentlyEditedCase &&
      currentlyEditedLevel &&
      caseGranulometry &&
      levelGranulometry
    ) {
      dispatch(
        goToCaseGranulometryEditLevelDetails(
          currentProjectId,
          currentlyEditedCase.id,
          (getLowerLevel(caseGranulometry, levelGranulometry) as LevelGranulometry).id
        )
      );
    }
  }, [dispatch, currentProjectId, currentlyEditedCase, currentlyEditedLevel]);

  if (
    !caseLabel ||
    !currentlyEditedLevel ||
    !currentlyEditedCase ||
    !caseGranulometry ||
    !levelGranulometry
  ) {
    return null;
  }

  return (
    <Window
      className="levelWindow"
      title={`${t('Level')} : ${getLevelLabel(parseInt(levelGranulometry.level.toString()))}`}
      topButtons={
        <>
          <IconObject
            iconName="arrowTop"
            type="menu"
            disabled={!hasHigherLevel(caseGranulometry, levelGranulometry)}
            onClick={handleClickLevelUp}
          />
          <IconObject
            iconName="arrowBottom"
            type="menu"
            disabled={!hasLowerLevel(caseGranulometry, levelGranulometry)}
            onClick={handleClickLevelDown}
          />
          <IconObject iconName="close" type="menu" onClick={handleClickClose} />
        </>
      }
      triggerId={getCaseGranulometryEditLevelDetailsTriggerId(levelGranulometry.id)}>
      <Form>
        <TechnicalPremiseSections />
        {process.env.GB_FEAT_COMMON_PREMISES === 'true' &&
          isTopLevel(caseGranulometry, levelGranulometry) && <CommonPremiseSections />}
        {process.env.GB_FEAT_SHOP_SECTIONS === 'true' &&
          isTopLevel(caseGranulometry, levelGranulometry) && <ShopSections />}
        {process.env.GB_FEAT_OFFICE_SECTIONS === 'true' &&
          isTopLevel(caseGranulometry, levelGranulometry) && <OfficeSections />}
        <CeilingHeight />
        {isTopLevel(caseGranulometry, levelGranulometry) && <BearingTechnicalSheaths />}
        {isBasementLevel(caseGranulometry, levelGranulometry) && (
          <>
            <Card>
              <SpecialLabel
                label={t('Distribution of parking spaces in the case') + ' ' + caseLabel}
              />
              <CarBoxFormGroup />
              <CarParkFormGroup />
              <TotalCaseParking />
              <TotalLevelParking />
            </Card>
            <PeripheralReinforcements />
          </>
        )}
        {process.env.GB_FEAT_GFS === 'true' &&
          (isTopLevel(caseGranulometry, levelGranulometry) ? (
            <TopLevelCalculationDetails />
          ) : (
            <BasementLevelCalculationDetails />
          ))}
      </Form>
    </Window>
  );
};

export default LevelDetailsWindow;
