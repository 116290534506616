import outsideStairShaft, {
  OutsideStairsShaftSection
} from '../../../../../sections/outsideSections/outsideStairShaft';
import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const createTopLevelOutsideStairsShaftSection = (
  caseGranulometry: CaseGranulometry,
  group: string,
  id: string
): OutsideStairsShaftSection => {
  const length = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'outsideStairShaftLength'
  ) as number;
  const width = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'outsideStairShaftWidth'
  ) as number;
  const surface = length * width;
  const bearingLength = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'outsideStairShaftBearingLength'
  ) as number;
  const bearingWidth = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'outsideStairsShaftBearingWidth'
  ) as number;
  return outsideStairShaft({
    id,
    surface,
    length,
    width,
    bearingLength,
    bearingWidth,
    group
  });
};
