import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../ui/input/Input';
import { roundForSurfacesInputs } from '../../../../utils/round/roundForSurfacesInputs';
import { CaseEditingStageName } from '../../../../domain/specification/cases/CaseSpecification';
import { Note } from '../../../ui/Note';
import { selectCurrentCaseEditingStage } from '../../../../store/selectors/specification/currentCase/editingStage.selector';
import { Surface } from '../../../../domain/specification/Surface';
import { secureInputChange } from '../../../../utils/secureInputChange';
import { selectCurrentCaseProjection } from '../../../../store/selectors/projection/currentCase/projection.selector';
import { selectCurrentBuildingId } from '../../../../store/selectors/project/currentBuildingId.selector';
import { selectCurrentCaseIdFromRoute } from '../../../../store/selectors/navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { selectProjectProjection } from '../../../../store/selectors/project/projectProjection.selector';
import { ProjectProjection } from '../../../../domain/projection/project/ProjectProjection';
import { caseSurfaceChanged } from '../../../../store/actions/caseSurfaceChanged.action';
import { caseSurfaceForSaleIsValid } from '../../../../domain/checks/cases/caseSurfaceForSaleIsValid';
import { caseSurfaceForSaleIsLargerThanTheWarningLimit } from '../../../../domain/checks/cases/caseSurfaceForSaleIsLargerThanTheWarningLimit';
import { getCaseSurfaceForSaleMinimum } from '../../../../domain/projection/cases/queries/surfaces/getCaseSurfaceForSaleMinimum';
import { getCaseSurfaceForSaleWarningLimit } from '../../../../domain/projection/cases/queries/surfaces/getCaseSurfaceForSaleWarningLimit';

export const CaseSurfaceForSaleInput = () => {
  const buildingId = useSelector(selectCurrentBuildingId);
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const projectProjection = useSelector(selectProjectProjection) as ProjectProjection;
  const caseProjection = useSelector(selectCurrentCaseProjection);
  const editingStage = useSelector(selectCurrentCaseEditingStage);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleCaseSFSChange = React.useCallback(
    (sfs: number | null): void => {
      if (buildingId && caseId) {
        dispatch(
          caseSurfaceChanged(buildingId, caseId, {
            ...caseProjection?.surfaces,
            surfaceForSale:
              sfs !== null && sfs !== 0 ? new Surface(secureInputChange(sfs)) : undefined
          })
        );
      }
    },
    [dispatch, caseId, buildingId, caseProjection]
  );

  if (!caseProjection) return null;

  const minSfs = roundForSurfacesInputs(getCaseSurfaceForSaleMinimum());
  const maxSfs = roundForSurfacesInputs(
    Math.min(
      caseProjection.projectedCuttedBuiltSurface.value,
      getCaseSurfaceForSaleWarningLimit(caseProjection)
    )
  );

  return (
    <>
      <Input
        label={t('surfaceForSale.label')}
        info={t('surfaceForSale.infos')}
        handleChange={handleCaseSFSChange}
        color={
          caseSurfaceForSaleIsValid(projectProjection, caseProjection) &&
          !caseSurfaceForSaleIsLargerThanTheWarningLimit(caseProjection)
            ? undefined
            : 'orange'
        }
        name="surfaceForSale"
        suffix="m&#178;"
        style={{ marginTop: '3px' }}
        type="number"
        min={0}
        max={10000}
        step={10}
        value={
          caseProjection.surfaces.surfaceForSale
            ? roundForSurfacesInputs(caseProjection.surfaces.surfaceForSale.value)
            : undefined
        }
        placeholder={roundForSurfacesInputs(caseProjection.projectedSurfaceForSale.value)}
        disabled={editingStage === CaseEditingStageName.Distribution}
      />
      <Note
        style={{
          borderRadius: '0 0 3px 3px',
          marginTop: '-5px',
          padding: '8px 6px 4px'
        }}>
        <div onClick={() => handleCaseSFSChange(minSfs)}>min = {minSfs + 'm\u00B2'}</div>
        <div onClick={() => handleCaseSFSChange(maxSfs)}>max = {maxSfs + 'm\u00B2'}</div>
      </Note>
    </>
  );
};
