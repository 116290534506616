import { BuildingId } from '../specification/buildings/BuildingSpecification';
import { CaseId } from '../specification/cases/CaseSpecification';
import { LevelId } from '../granulometry/levels/LevelGranulometry';
import { SectionId } from '../granulometry/sections/Section';

export type BuildingFocus = { building: BuildingId };
export type CaseFocus = { case: CaseId };
export type LevelFocus = { levelId: LevelId };
export type SectionFocus = { section: SectionId };
export type BuildingMapFocus = 'project' | BuildingFocus | CaseFocus | LevelFocus | SectionFocus;

export const isFocusProject = (focus: BuildingMapFocus): focus is 'project' => focus === 'project';
export const isFocusBuilding = (focus: BuildingMapFocus): focus is BuildingFocus =>
  typeof focus === 'object' && 'building' in focus;
export const isFocusCase = (focus: BuildingMapFocus): focus is CaseFocus =>
  typeof focus === 'object' && 'case' in focus;
export const isFocusLevel = (focus: BuildingMapFocus): focus is LevelFocus =>
  typeof focus === 'object' && 'level' in focus;
export const isFocusSection = (focus: BuildingMapFocus): focus is SectionFocus =>
  typeof focus === 'object' && 'section' in focus;
