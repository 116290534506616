import { createSelector, Selector } from 'reselect';
import { selectCurrentCaseSpecifiedBasementLevelsCount } from '../../specification/currentCase/basementLevelsCount.selector';
import { selectCurrentCaseRealBuiltSurface } from './realBuiltSurface.selector';
import { getCaseBasementLevelsDefaultCount } from '../../../../domain/granulometry/cases/queries/levels/counts/getCaseBasementLevelsDefaultCount';
import { State } from '../../../reducers';

export const selectCurrentCaseBasementLevelsCount: Selector<State, number> = createSelector(
  [selectCurrentCaseSpecifiedBasementLevelsCount, selectCurrentCaseRealBuiltSurface],
  (specifiedBasementLevelsCount, caseRealBuildSurface) =>
    specifiedBasementLevelsCount ??
    (caseRealBuildSurface ? getCaseBasementLevelsDefaultCount(caseRealBuildSurface) : 0)
);
