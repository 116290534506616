import { Route } from '../../Route';
import { matchesPath } from '../../utils/matchesPath';
import { orCreate } from '../../utils/orCreate';

export type ProjectDetailsEditRouteParams = { projectId: string };
export const projectDetailsEditRoute: Route<ProjectDetailsEditRouteParams> = {
  path: `/project/:projectId/details/edit`,
  makePath: ({ projectId }) => `/project/${orCreate(projectId)}/details/edit`,
  matchesPath: matchesPath<ProjectDetailsEditRouteParams>(/\/project\/([^/]+)\/details\/edit/, {
    projectId: 1
  })
};
