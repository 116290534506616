import { CustomPrice, CustomPriceList } from '../../../../../../domain/Account';
import * as R from 'ramda';
import { findCustomPriceFromGivenProductId } from './findCustomPriceFromGivenProductId';
import { findCustomPriceListIndexFromGivenId } from './findCustomPriceListIndexFromGivenId';

export const getUpdatedCustomPriceLists =
  (customPriceLists: CustomPriceList[], customPriceListId: string) =>
  (productId: string, price: number | null): CustomPriceList[] =>
    R.over(
      R.lensPath([
        findCustomPriceListIndexFromGivenId(customPriceListId)(customPriceLists),
        'customPrices'
      ]),
      // () => []
      R.ifElse(
        shouldCustomPriceBeRemoved(price),
        removeCustomPrice(productId),
        addOrUpdateCustomPrice(productId, price as number)
      )
    )(customPriceLists);

const shouldCustomPriceBeRemoved =
  (price: number | null) =>
  (uselessPropForTs: CustomPrice[]): boolean =>
    uselessPropForTs && price === null;

const removeCustomPrice =
  (productId: string) =>
  (customPrices: CustomPrice[]): CustomPrice[] => {
    const index = findCustomPriceFromGivenProductId(productId)(customPrices);
    return index !== -1
      ? R.reject((cP: CustomPrice) => cP.productId === productId, customPrices)
      : customPrices;
  };

const addOrUpdateCustomPrice =
  (productId: string, price: number) =>
  (customPrices: CustomPrice[]): CustomPrice[] => {
    const index = findCustomPriceFromGivenProductId(productId)(customPrices);
    return index !== -1
      ? R.map((cP) => (cP.productId === productId ? { productId, price } : cP), customPrices)
      : [...customPrices, { productId, price }];
  };
