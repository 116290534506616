import { getCaseProperty } from './getCaseProperty';
import { getPropertyValue } from './getPropertyValue';
import { CaseSpecification } from '../../../CaseSpecification';
import { CaseProjection } from '../../../../../projection/cases/CaseProjection';
import { CaseGranulometry } from '../../../../../granulometry/cases/CaseGranulometry';
import { getPropertyConvertedValue } from './getPropertyConvertedValue';

export const getCasePropertyConvertedValue = (
  anyTypeOfCase: CaseSpecification | CaseProjection | CaseGranulometry,
  propertyName: string
): number | undefined => {
  const property = getCaseProperty(anyTypeOfCase, propertyName);
  return property !== undefined
    ? getPropertyConvertedValue(property.unit, getPropertyValue(property))
    : undefined;
};
