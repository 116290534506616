import { createSelector, Selector } from 'reselect';
import * as R from 'ramda';
import { ifDefined } from '../../../../utils/ifDefined';
import { selectParkingsCountByBuildingAndCase } from './parkingsCountByBuildingAndCase.selector';
import { State } from '../../../../store/reducers';
import { Maybe } from '../../../../utils/Maybe';

type ParkingsCountByBuilding = Maybe<Record<string, number>>;

export const selectParkingsCountByBuilding: Selector<State, ParkingsCountByBuilding> =
  createSelector(
    [selectParkingsCountByBuildingAndCase],
    ifDefined(R.mapObjIndexed(R.pipe(R.values, R.sum)))
  );
