import { createSelector, Selector } from 'reselect';
import { ifDefined } from '../../../../utils/ifDefined';
import { selectCurrentCaseGranulometry } from './currentCaseGranulometry.selector';
import { getCaseCarBoxInfraSectionsCount } from '../../../../domain/granulometry/cases/queries/sections/counts/getCaseCarBoxInfraSectionsCount';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';

export const selectCurrentCaseCarBoxInfraCount: Selector<State, Maybe<number>> = createSelector(
  [selectCurrentCaseGranulometry],
  ifDefined(getCaseCarBoxInfraSectionsCount)
);
