import MapboxDraw from '@mapbox/mapbox-gl-draw';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import { getArea } from '../../utils/geometry/getArea';
import { getPerimeter } from '../../utils/geometry/getPerimeter';
import { getCenter } from '../../utils/geometry/getCenter';
import { LevelGeometry } from '../../mapboxDraw/levelGeometry';
import { updateFacadesProperties } from '../facades/updateFacadesProperties';
import { FacadeSpecification } from '../facades/FacadeSpecification';

const setDraw = (levelGeometry: LevelGeometry, draw: MapboxDraw) => {
  const allFeatures = draw.getAll();
  return draw.set({
    type: 'FeatureCollection',
    features: allFeatures.features.map((f) => {
      if (f.id === levelGeometry.id) {
        return levelGeometry;
      }
      return f;
    }) as LevelGeometry[]
  });
};

export const onFormUpdateLevelGeometry = (
  updatedLevelGeometry: LevelGeometry,
  draw: MapboxDraw,
  map: mapboxgl.Map
): LevelGeometry => {
  const facades = updateFacadesProperties(
    updatedLevelGeometry.properties.facades as FacadeSpecification[]
  );
  const origin = facades[0].point1;

  const coords = facades.reduce((p, c) => [...p, c.point2], [facades[0].point1]);
  const levelGeometry = turf.polygon([coords], {
    ...updatedLevelGeometry.properties,
    facades,
    origin,
    perimeter: getPerimeter(facades),
    center: getCenter(coords),
    area: getArea({
      ...updatedLevelGeometry,
      geometry: { type: 'Polygon', coordinates: [coords] }
    })
  }) as LevelGeometry;
  levelGeometry.id = updatedLevelGeometry.id;
  setDraw(levelGeometry, draw);
  map.fire('draw.update', { features: [levelGeometry] });

  return levelGeometry;
};
