import { combineEpics } from 'redux-observable';
import { saveProjectEpic } from './saveProject.epic';
import { fetchCstbWhenReportIsFetchedEpic } from './fetchCstbWhenReportIsFetched.epic';
import { goToProjectAfterInitializationEpic } from './goToProjectAfterInitialization.epic';
import { fetchProjectsEpic } from './fetchProjects.epic';
import { fetchProjectEpic } from './fetchProject.epic';
import { closeProjectOnNavigationAwayEpic } from './closeProjectOnNavigationAway.epic';
import { fetchNomenclaturesOnLoadEpic } from './fetchNomenclaturesOnLoad.epic';
import { renewSessionWhenApproachingExpirationEpic } from './renewSessionWhenApproachingExpiration.epic';
import { redirectToLoginOnLogoutEpic } from './redirectToLoginOnLogout.epic';
import { syncBuildingMapFocusWithNavigationEpic } from './syncBuildingMapFocusWithNavigation.epic';
import { moveToPreviousBuildingAfterDeletionEpic } from './moveToPreviousBuildingAfterDeletion.epic';
import { moveToPreviousCaseAfterDeletionEpic } from './moveToPreviousCaseAfterDeletion.epic';
import { updateGranulometryEpic } from './updateGranulometry.epic';
import { changePasswordEpic } from './changePassword.epic';
import { copyProjectEpic } from './copyProject.epic';
import { getGeoCoding } from './getGeoCoding.epic';
import { generateGingerMapsEpic } from './generateGingerMaps.epic';
import { authentifyEpic } from './authentify.epic';
import { createNewProjectWhenNamedEpic } from './createNewProjectWhenNamed.epic';
import { computeProjectEpic } from './compute.epic';
import { fetchReportAfterLoadEpic } from './fetchReportAfterLoad.epic';
import { saveCustomPriceListsEpic } from './saveCustomPriceLists.epic';

export default combineEpics(
  authentifyEpic,
  renewSessionWhenApproachingExpirationEpic,
  redirectToLoginOnLogoutEpic,
  fetchNomenclaturesOnLoadEpic,
  goToProjectAfterInitializationEpic,
  saveProjectEpic,
  computeProjectEpic,
  fetchCstbWhenReportIsFetchedEpic,
  copyProjectEpic,
  fetchProjectsEpic,
  fetchProjectEpic,
  closeProjectOnNavigationAwayEpic,
  createNewProjectWhenNamedEpic,
  syncBuildingMapFocusWithNavigationEpic,
  moveToPreviousBuildingAfterDeletionEpic,
  moveToPreviousCaseAfterDeletionEpic,
  updateGranulometryEpic,
  fetchReportAfterLoadEpic,
  changePasswordEpic,
  saveCustomPriceListsEpic,
  getGeoCoding,
  generateGingerMapsEpic
);
