import { isRoomSpecificationABalcony } from './BalconySpecification';
import { isRoomSpecificationAnyLivingRoomKitchen } from './LivingRoomKitchenSpecification';
import { RoomSpecification } from './RoomSpecification';
import { IconObjectProps } from '../../../components/ui/Icons/iconObject';
import bathroom from '../../granulometry/rooms/bathroom';
import bedroomPMR from '../../granulometry/rooms/bedroomPMR';
import bedroomPMRC from '../../granulometry/rooms/bedroomPMRC';
import bedroom2 from '../../granulometry/rooms/bedroom2';
import bedroom3 from '../../granulometry/rooms/bedroom3';
import bedroom4 from '../../granulometry/rooms/bedroom4';
import clearance from '../../granulometry/rooms/clearance';
import entrance from '../../granulometry/rooms/entrance';
import kitchen from '../../granulometry/rooms/kitchen';
import livingRoom from '../../granulometry/rooms/livingRoom';
import showerRoom from '../../granulometry/rooms/showerRoom';
import toilet from '../../granulometry/rooms/toilet';

export type BasicRoomName =
  | 'bathroom'
  | 'bedroomPMR'
  | 'bedroomPMRC'
  | 'bedroom2'
  | 'bedroom3'
  | 'bedroom4'
  | 'clearance'
  | 'entrance'
  | 'kitchen'
  | 'livingRoom'
  | 'showerRoom'
  | 'toilet';

export interface BasicRoomSpecification {
  name: BasicRoomName;
  title: string; // label :-(
  defaultLength: number;
  width?: number;
  defaultWidth: number;
  surface?: number;
  icon: IconObjectProps; // DURTY
}

export const isRoomSpecificationABasicRoom = (
  roomSpecification: RoomSpecification
): roomSpecification is BasicRoomSpecification =>
  !isRoomSpecificationABalcony(roomSpecification) &&
  !isRoomSpecificationAnyLivingRoomKitchen(roomSpecification);

export const defaultBasicRoomSpecifications: { [key in BasicRoomName]: BasicRoomSpecification } = {
  bathroom: bathroom(),
  bedroomPMR: bedroomPMR(),
  bedroomPMRC: bedroomPMRC(),
  bedroom2: bedroom2(),
  bedroom3: bedroom3(),
  bedroom4: bedroom4(),
  clearance: clearance(),
  entrance: entrance(),
  kitchen: kitchen(),
  livingRoom: livingRoom(),
  showerRoom: showerRoom(),
  toilet: toilet()
};
