import { connect } from 'react-redux';
import { State } from '../../../store/reducers';
import { selectProject, selectProjectName } from '../../../store/selectors/project';

const mapStateToProps = (state: State) => ({
  projectName: selectProjectName(state),
  currentProject: selectProject(state)
});

export const withConnect = connect(mapStateToProps);
