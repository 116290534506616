import { get } from './admin.api';
import { ReportEntry } from '../../domain/report/ReportEntry';
import { User } from '../../domain/User';
import { AuthToken } from '../../AuthToken';

const STANDARD_REPORT_ID = 1;

export const makeReportUrl = (
  format: 'json',
  report: 'standard',
  projectId: string,
  nomenclatureId: string,
  userId?: User['id'],
  userToken?: AuthToken,
  tryFileDownload?: boolean
): string =>
  `/Calculations/Project/JSONAPP/${userId || 'noUserId'}/${
    userToken || 'noUserToken'
  }/${projectId}?report_id=${STANDARD_REPORT_ID}&nomenclature_id=${nomenclatureId}&tryFileDownload=${
    tryFileDownload ? '1' : '0'
  }`;

export const fetchJSONReport = (
  projectId: string,
  nomenclatureId: string,
  userId?: User['id'],
  userToken?: AuthToken,
  tryFileDownload?: boolean
): Promise<ReportEntry[]> => {
  // const response =
  //   nomenclatureId === LOTS_NOMENCLATURE_ID
  //     ? // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //       // @ts-ignore
  //       import('./mocks/report.json')
  //     : // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  //       // @ts-ignore
  //       import('./mocks/contributorsReport.json');

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  // return response.then(json => json.default);
  return get(
    makeReportUrl(
      'json',
      'standard',
      projectId,
      nomenclatureId,
      userId,
      userToken,
      tryFileDownload
    ),
    userToken || 'noUserToken'
  )
    .then((response) => response.json())
    .then((report) => report.map((entry: ReportEntry) => ({ ...entry, apiVersion: 1 as const })));
};
