import * as R from 'ramda';
import { Granulometry } from '../Granulometry';
import { BuildingGranulometry } from '../buildings/BuildingGranulometry';
import { CaseGranulometry } from '../cases/CaseGranulometry';
import { getBuildingId } from '../buildings/queries/getBuildingId';
import { getCaseId } from '../cases/queries/get/getCaseId';
import {
  getCaseLodgmentsCountByTopLevelAndType,
  LodgmentsCountByTopLevelAndType
} from '../cases/queries/sections/counts/getCaseLodgmentSectionsCountByTopLevelAndType';
import { BuildingId } from '../../specification/buildings/BuildingSpecification';
import { CaseId } from '../../specification/cases/CaseSpecification';

const extractFromCases = (
  cases: Record<string, CaseGranulometry>
): Record<string, LodgmentsCountByTopLevelAndType> =>
  R.mapObjIndexed(getCaseLodgmentsCountByTopLevelAndType, cases);

const indexCases = (cases: CaseGranulometry[]): Record<CaseId, CaseGranulometry> =>
  R.indexBy(getCaseId, cases);

export type LodgmentsCountByCaseLevelAndType = Record<CaseId, LodgmentsCountByTopLevelAndType>;

export type LodgmentsCountByBuildingCaseLevelAndType = Record<
  BuildingId,
  LodgmentsCountByCaseLevelAndType
>;

const extractFromBuildings = (
  buildings: Record<BuildingId, BuildingGranulometry>
): LodgmentsCountByBuildingCaseLevelAndType =>
  R.mapObjIndexed(({ cases }) => R.pipe(indexCases, extractFromCases)(cases))(buildings);

const indexBuildings = R.indexBy(getBuildingId);

export const getLodgmentsCountByBuildingCaseTopLevelAndType = (
  granulometry: Granulometry
): LodgmentsCountByBuildingCaseLevelAndType =>
  R.pipe(indexBuildings, extractFromBuildings)(granulometry);
