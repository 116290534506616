import * as React from 'react';
import { Window } from 'components/ui/Window';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { IconObject } from '../../../ui/Icons/iconObject';
import { useTranslation } from 'react-i18next';
import { Geometry } from './Geometry';
import { selectCurrentCaseLabel } from '../../../../store/selectors/project/currentCaseLabel.selector';
import { selectCurrentCaseIdFromRoute } from '../../../../store/selectors/navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { selectCurrentlyEditedLevelSpecification } from '../../../../store/selectors/navigation/toolbox/casesPanel/currentlyEditedLevelSpecification.selector';
import { CaseGranulometryEditLevelGeometryRouteParams } from '../../../../routes/toolbox/casesPanel/caseGranulometryEditLevelGeometryRoute';
import { getCaseGranulometryEditLevelGeometryTriggerId } from '../../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditLevelGeometryTriggerId';
import { getCaseGranulometryEditGeometryTriggerId } from '../../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditGeometryTriggerId';
import { getProjectDetailsGeometryTriggerId } from '../../../../routes/toolbox/projectPanel/triggers/getProjectDetailsGeometryTriggerId';
import { goToProjectDetails } from '../../../../store/actions/navigations/toolbox/projectPanel/goToProjectDetails.action';
import { goToCase } from '../../../../store/actions/navigations/toolbox/casesPanel/goToCase.action';

export const GeometryWindow = () => {
  const { projectId } = useParams<CaseGranulometryEditLevelGeometryRouteParams>();
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const currentlyEditedLevel = useSelector(selectCurrentlyEditedLevelSpecification);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickClose = React.useCallback(() => {
    if (projectId && caseId) {
      dispatch(goToCase(projectId, caseId));
    } else if (projectId) {
      dispatch(goToProjectDetails(projectId));
    }
  }, [dispatch, projectId, caseId]);

  return (
    <Window
      className="geometryWindow"
      title={`${t('geometryWindow.title')}${
        caseLabel ? ' ' + t('geometryWindow.ofTheCase') + ' ' + caseLabel : ''
      }`}
      triggerId={
        currentlyEditedLevel !== undefined
          ? getCaseGranulometryEditLevelGeometryTriggerId(currentlyEditedLevel.id)
          : caseLabel
          ? getCaseGranulometryEditGeometryTriggerId(caseLabel)
          : getProjectDetailsGeometryTriggerId()
      }
      topButtons={
        <>
          <IconObject iconName="close" type="menu" onClick={handleClickClose} />
        </>
      }
      large={true}
      noScroll={true}>
      <Geometry />
    </Window>
  );
};
