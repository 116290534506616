import classNames from 'classnames';
import * as React from 'react';

interface SpecialLabelProps {
  label: string;
  className?: string;
  small?: boolean;
  closed?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

export const SpecialLabel = ({ label, className, small, closed, onClick }: SpecialLabelProps) => {
  const classes = classNames(
    'gb-special-label',
    {
      'gb-special-label-small': small,
      'gb-special-label-closable': closed !== undefined,
      'gb-special-label-closed': closed === true
    },
    className
  );

  return (
    <label className={classes} onClick={onClick}>
      {label}
    </label>
  );
};
