import { cleanFileName } from '../utils/cleanFileName';
import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import { PerrenoudJsonEntry } from '../../../../../api/perrenoud/makePerrenoudJson';

export const downloadPerrenoud = async (
  report: Partial<PerrenoudJsonEntry>[],
  projectName: string
) => {
  const fileName =
    'gobuild-' +
    cleanFileName(projectName) +
    '-perrenoud-' +
    new Date().toISOString().slice(0, 10) +
    '.csv';
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet(
    projectName.replace(/[*?:\\/[\]]/g, ' ') + ' – Perrenoud'
  );
  worksheet.columns = [
    { header: 'Element', key: 'element', style: { alignment: { wrapText: true } } },
    { header: 'FdesId', key: 'co2Id', style: { alignment: { wrapText: true } } },
    { header: 'FdesDescription', key: 'product', style: { alignment: { wrapText: true } } },
    { header: 'FdesUniteUf', key: 'unit', style: { alignment: { wrapText: true } } },
    { header: 'Quantite', key: 'co2Quantity', style: { alignment: { wrapText: true } } },
    { header: 'LotReglementaire', key: 'refLot', style: { alignment: { wrapText: true } } },
    { header: 'SousLotReglementaire', key: 'refSsLot1', style: { alignment: { wrapText: true } } },
    {
      header: 'CategoriePerrenoud',
      key: 'perrenoudCategory',
      style: { alignment: { wrapText: true } }
    }
  ];
  worksheet.addRows(report, 'i');
  workbook.csv
    .writeBuffer({
      formatterOptions: {
        delimiter: ';',
        rowDelimiter: '\u000D\u000A', // /r/n
        writeBOM: true
      }
    })
    .then(function (buffer) {
      FileSaver.saveAs(
        new Blob([/* new Uint8Array([0xef, 0xbb, 0xbf]), */ buffer], {
          type: 'text/plain;charset=utf-8'
        }),
        fileName
      );
    });
};
