import { BasementLevelSpecification } from '../../../../levels/BasementLevelSpecification';
import * as R from 'ramda';
import { LevelSpecification } from '../../../../levels/LevelSpecification';
import { CaseSpecification } from '../../../CaseSpecification';
import { isBasementLevel } from '../../../../levels/queries/is/isBasementLevel';
import { sortLevels } from '../../../../levels/queries/sortLevels';

export const setCaseBasementLevelsSpecifications =
  (levels: BasementLevelSpecification[]) => (caseSpecification: CaseSpecification) => {
    return R.over(
      R.lensProp('levels'),
      R.pipe<
        [LevelSpecification[]],
        LevelSpecification[],
        LevelSpecification[],
        LevelSpecification[]
      >(R.reject(isBasementLevel), R.concat(levels), sortLevels),
      caseSpecification
    );
  };
