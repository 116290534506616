import * as R from 'ramda';
import { createSelector, Selector } from 'reselect';
import { ifDefined } from '../../../utils/ifDefined';
import { selectAllCasesProjections } from '../projection/allCasesProjections.selector';
import { State } from '../../reducers';
import { Surface } from '../../../domain/specification/Surface';
import { CaseProjection } from '../../../domain/projection/cases/CaseProjection';
import { Maybe } from '../../../utils/Maybe';

export const selectRealBuiltSurfacesByCages: Selector<
  State,
  Maybe<Record<string, Surface | undefined>>
> = createSelector(
  [selectAllCasesProjections],
  ifDefined(
    R.pipe(
      R.indexBy((caseProjection: CaseProjection) => caseProjection.id),
      R.mapObjIndexed((caseProjection) => caseProjection.projectedRealBuiltSurface)
    )
  )
);
