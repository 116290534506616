import { createSelector, Selector } from 'reselect';
import { selectCurrentCaseGranulometry } from './currentCaseGranulometry.selector';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';
import {
  BasementLevelSurfacesWithIndex,
  getCaseBasementLevelsSurfaces
} from '../../../../domain/granulometry/cases/queries/surfaces/getCaseBasementLevelsSurfaces';

export const selectCurrentCaseBasementLevelsSurfaces: Selector<
  State,
  Maybe<BasementLevelSurfacesWithIndex[]>
> = createSelector([selectCurrentCaseGranulometry], (caseGranulometry) =>
  caseGranulometry ? getCaseBasementLevelsSurfaces(caseGranulometry) : undefined
);
