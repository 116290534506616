import { DEFAULT_ZOOM_VALUE } from '../../constants/appConstants';
import { getWindowHeight } from './getWindowHeight';
import { getGroupPositionOnBuildingMap } from './getGroupPositionOnBuildingMap';
import { getElementPositionOnBuildingMap } from './getElementPositionOnBuildingMap';
import { Dispatch } from 'react';
import { Maybe } from '../Maybe';

export const moveOn = (
  elementSelector: string,
  zoomValue: number,
  calculatedOnce: Maybe<boolean>,
  dispatch?: Dispatch<any>
) => {
  const isBuildings = elementSelector === '.buildings';
  const targetElement = document.querySelector(
    (!isBuildings ? '.buildings ' : '') + elementSelector
  );

  // Clean mask
  const mask = document.querySelector('.mask');
  if (mask) {
    Array.from(mask.children).forEach((child) => {
      if (!child.classList.contains('maskBackground') && mask && child) {
        // 👇 This is FORBIDDEN. One does not play with DOM node managed by React !
        mask.removeChild(child);
        //  https://github.com/facebook/react/issues/6802#issuecomment-223179809
      }
    });
    mask.classList.remove('displayed-mask');
    mask.classList.remove('sectionFocused');
    mask.classList.remove('iconObjectFocused');
  }
  if (targetElement) {
    const group = targetElement.attributes.getNamedItem('data-group');
    if (group) {
      // Move on grouped elements
      const groupSections = document.querySelectorAll(
        '.buildingMap .buildings [data-group="' + group.nodeValue + '"]'
      );
      const newTransformValues = getGroupPositionOnBuildingMap(
        groupSections,
        zoomValue,
        !isBuildings
      );
      if (newTransformValues) {
        return {
          translateX: newTransformValues.translateX,
          translateY: newTransformValues.translateY
        };
      }
    } else {
      // Move on ungrouped element
      const newTransformValues = getElementPositionOnBuildingMap(
        targetElement,
        zoomValue,
        !isBuildings,
        calculatedOnce,
        dispatch
      );
      if (newTransformValues) {
        return {
          translateX: newTransformValues.translateX,
          translateY: newTransformValues.translateY
        };
      }
    }
  }
  // If not target element has been found :
  return {
    translateX: 0,
    translateY: getWindowHeight() / 2 / DEFAULT_ZOOM_VALUE
  };
};
