import { ExistingProjectBasicInfo } from '../../domain/project/Project';

export const PROJECTS_FETCHED = 'PROJECTS_FETCHED';

export const projectsFetched = (projects: ExistingProjectBasicInfo[]) => ({
  type: PROJECTS_FETCHED,
  payload: { projects }
});

export type ProjectsFetchedAction = ReturnType<typeof projectsFetched>;
