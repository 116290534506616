import { manageLevelSectionWrapper } from './manageLevelSectionWrapper';
import { manageLevelOutsideRuler } from './manageLevelOutsideRuler';
import { manageLevelSectionGroups } from './manageLevelSectionGroups';
import { setAllBasementBasementHeight } from './setAllBasementBasementHeight';
import { Granulometry } from '../../domain/granulometry/Granulometry';
import { CaseGranulometry } from '../../domain/granulometry/cases/CaseGranulometry';

// Update the buildingMap object with sectionWrappers and groups informations + outside ruler
export const getBuildingMapWithSectionWrapperGroupsAndOutsideRuler = (
  lBuildingMapData: Granulometry
) => {
  lBuildingMapData.forEach((building) => {
    building.cases.forEach((caseGranulometry: CaseGranulometry) => {
      const sectionGroups: string[] = [];
      // For each levels in case :
      caseGranulometry.levels.forEach((levelGranulometry, index: number) => {
        if (levelGranulometry.content) {
          manageLevelSectionWrapper(levelGranulometry, caseGranulometry);
          if (levelGranulometry.level < 0) {
            manageLevelOutsideRuler(levelGranulometry, { ...caseGranulometry.levels[index - 1] });
          }
          manageLevelSectionGroups(caseGranulometry, levelGranulometry, index, sectionGroups);
        }
      });

      caseGranulometry.allBasementsHeight = setAllBasementBasementHeight(caseGranulometry);
    });
  });

  /* lBuildingMapData.map((element: any, index: number) => {
    if (lBuildingMapData[index - 1]) {
      lBuildingMapData[index].prev = lBuildingMapData[index - 1];
    }
    if (lBuildingMapData[index + 1]) {
      lBuildingMapData[index].next = lBuildingMapData[index + 1];
    }
  }); */

  return lBuildingMapData;
};
