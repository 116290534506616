import { getSectionTheoreticalOutsideWallsLinear } from '../../../../../sections/queries/walls/theoreticalWalls/outsideWalls/getSectionTheoreticalOutsideWallsLinear';
import { LevelGranulometry, LevelGranulometryWithLodgments } from '../../../../LevelGranulometry';
import { getTopLevelIncludedInSurfaceForSaleSections } from '../../sections/getTopLevelIncludedInSurfaceForSaleSections';
import { IncludedInSurfaceForSaleSection } from '../../../../../sections/SectionIncludedInSurfaceForSale';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelIncludedInSurfaceForSaleSectionsTheoreticalOutsideWallsLinear = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithLodgments | LevelGranulometry
): number =>
  getTopLevelIncludedInSurfaceForSaleSections(levelGranulometry).reduce(
    (acc, includedInSurfaceForSaleSection: IncludedInSurfaceForSaleSection) =>
      acc +
      getSectionTheoreticalOutsideWallsLinear(
        caseGranulometry,
        levelGranulometry,
        includedInSurfaceForSaleSection
      ),
    0
  );
