import * as R from 'ramda';
import * as RA from 'ramda-adjunct';
import { CaseGranulometry } from '../../../CaseGranulometry';
import { LevelGranulometry } from '../../../../levels/LevelGranulometry';
import {
  getLevelLodgmentsSurfaceByType,
  LodgmentsSurfaceByType
} from '../../../../levels/queries/topLevels/sections/surfaces/getTopLevelLodgmentsSurfaceByType';

export type LodgmentsSurfaceByLevelAndType = {
  [level: string]: LodgmentsSurfaceByType;
};

type GetCaseLodgmentsSurfaceByLevelAndType = (
  caseGranulometry: CaseGranulometry
) => LodgmentsSurfaceByLevelAndType;

export const getLodgmentsSurfaceByLevelAndType: GetCaseLodgmentsSurfaceByLevelAndType = R.when(
  RA.isNotNil,
  R.pipe<
    [CaseGranulometry],
    LevelGranulometry[],
    { [l: string]: LevelGranulometry },
    LodgmentsSurfaceByLevelAndType
  >(
    R.prop('levels'),
    R.indexBy(R.compose(R.toString, R.prop('level'))),
    R.mapObjIndexed(getLevelLodgmentsSurfaceByType)
  )
);
