import { createSelector, ParametricSelector } from 'reselect';
import { getCaseLabelGivenCaseId } from '../../../domain/core/queries/getCaseLabelGivenCaseId';
import { State } from '../../reducers';
import { selectProject } from '../project';
import { CaseId } from '../../../domain/specification/cases/CaseSpecification';
import { Maybe } from '../../../utils/Maybe';

// typage pour un sélecteur paramétrique!  le second sélecteur parent ((_: State, caseId: CaseId) => caseId) n'utilise pas seulement le state (premier argument) mais prend également un paramètre CaseId … il est donc «paramétrique». Et si l'un des sélecteurs parents est paramétrique, alors le sélecteur résultant est paramétrique aussi (il a lui aussi besoin d'un paramètre en plus du state …)

type CaseLabelGivenCaseId = ParametricSelector<State, CaseId, Maybe<string>>;

export const selectCaseLabelGivenCaseId: CaseLabelGivenCaseId = createSelector(
  [selectProject, (_: State, caseId: CaseId) => caseId],
  (project, caseId) => (project && caseId ? getCaseLabelGivenCaseId(project, caseId) : undefined)
);
