import * as R from 'ramda';
import { createSelector, Selector } from 'reselect';
import { ifDefined } from '../../../utils/ifDefined';
import { Surface } from '../../../domain/specification/Surface';
import { BuildingId } from '../../../domain/specification/buildings/BuildingSpecification';
import { CaseId } from '../../../domain/specification/cases/CaseSpecification';
import { selectLodgmentsSurfaceByBuildingAndCase } from './lodgmentsSurfaceByBuildingAndCase.selector';
import { State } from '../../../store/reducers';
import { Maybe } from '../../../utils/Maybe';

type LodgmentsSurfaceByBuilding = Maybe<Record<string, Surface>>;

export const selectLodgmentsSurfaceByBuilding: Selector<State, LodgmentsSurfaceByBuilding> =
  createSelector(
    [selectLodgmentsSurfaceByBuildingAndCase],
    ifDefined(
      (
        byBuildingAndCase: Record<BuildingId, Record<CaseId, Surface>>
      ): Record<BuildingId, Surface> =>
        R.mapObjIndexed(
          R.pipe<[Record<CaseId, Surface>], Surface[], Surface>(R.values, Surface.sum),
          byBuildingAndCase
        )
    )
  );
