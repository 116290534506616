import { createSelector, Selector } from 'reselect';
import { selectPath } from '../../getPath.selector';
import { State } from '../../../../reducers';
import { caseGranulometryEditOtherPropertiesRoute } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditOtherPropertiesRoute';

export const selectIsCaseGranulometryEditOtherPropertiesOpened: Selector<State, boolean> =
  createSelector(
    [selectPath],
    (path) => caseGranulometryEditOtherPropertiesRoute.matchesPath(path).matches
  );
