import { Action } from 'redux';
import { ProjectLocation } from '../../domain/ProjectLocation';

export const GB_PROJECT_LOCATION_CHANGED = 'project/location_changed';

export interface ProjectLocationChangedAction extends Action {
  type: typeof GB_PROJECT_LOCATION_CHANGED;
  payload: ProjectLocation;
}

export const projectLocationChanged = (
  location: ProjectLocation
): ProjectLocationChangedAction => ({
  type: GB_PROJECT_LOCATION_CHANGED,
  payload: location
});
