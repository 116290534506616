import * as R from 'ramda';
import { LodgmentType } from './LodgmentType';

export interface LodgmentTypeRate {
  lodgmentType: LodgmentType;
  rate: number;
}

export type LodgmentTypesDistribution = LodgmentTypeRate[];

export const defaultLodgmentTypesDistribution: LodgmentTypesDistribution = [
  { lodgmentType: LodgmentType.T1, rate: 0 },
  { lodgmentType: LodgmentType.T2C, rate: 0 },
  { lodgmentType: LodgmentType.T2, rate: 0 },
  { lodgmentType: LodgmentType.T3C, rate: 0 },
  { lodgmentType: LodgmentType.T3, rate: 0 },
  { lodgmentType: LodgmentType.T4C, rate: 0 },
  { lodgmentType: LodgmentType.T4, rate: 0 },
  { lodgmentType: LodgmentType.T5, rate: 0 }
];

export const getDraftLodgmentsTypesDistributionTotalFilledRates = (
  distribution: LodgmentTypesDistribution
): number => distribution.reduce((sum, rate) => sum + rate.rate, 0);

export const getDraftLodgmentsTypesDistributionRemainingRate = (
  distribution: LodgmentTypesDistribution
): number => {
  const totalRate = getDraftLodgmentsTypesDistributionTotalFilledRates(distribution);
  return totalRate > 100 ? 0 : 100 - totalRate;
};

export const getDraftLodgmentsTypesDistributionEmptyRateCount = (
  distribution: LodgmentTypesDistribution
): number => distribution.filter((lodgmentType) => lodgmentType.rate === 0).length;

export const getDraftLodgmentsTypesDistributionRateDefaultValue = (
  distribution: LodgmentTypesDistribution
): number =>
  getDraftLodgmentsTypesDistributionRemainingRate(distribution) /
  getDraftLodgmentsTypesDistributionEmptyRateCount(distribution);

export const getDraftLodgmentsTypesDistributionTotalRateDefaultValues = (
  distribution: LodgmentTypesDistribution
): number =>
  getDraftLodgmentsTypesDistributionEmptyRateCount(distribution) *
  getDraftLodgmentsTypesDistributionRateDefaultValue(distribution);

export const getDraftLodgmentsTypesDistributionTotalRate = (
  distribution: LodgmentTypesDistribution
): number =>
  getDraftLodgmentsTypesDistributionTotalFilledRates(distribution) +
  getDraftLodgmentsTypesDistributionTotalRateDefaultValues(distribution);

export const isLodgmentsTypesDistributionValid = (
  distribution: LodgmentTypesDistribution
): boolean => {
  return getDraftLodgmentsTypesDistributionTotalRate(distribution) === 100;
};

export const updateLodgmentsTypesDistribution = (
  lodgmentType: LodgmentType,
  rate: number,
  distribution: LodgmentTypesDistribution
): LodgmentTypesDistribution =>
  R.over(
    R.lensIndex(R.findIndex(R.propEq('lodgmentType', lodgmentType), distribution)),
    R.assoc('rate', rate),
    distribution
  );
