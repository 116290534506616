import { matchesPath } from '../../utils/matchesPath';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type SmartRequestsRouteParams = { projectId: string };
export const smartRequestsRoute: RouteWithMakePath<SmartRequestsRouteParams> = {
  path: `/project/:projectId/smart-requests`,
  makePath: ({ projectId }) => `/project/${projectId}/smart-requests`,
  matchesPath: matchesPath<SmartRequestsRouteParams>(/\/project\/([^/]+)\/smart-requests/, {
    projectId: 1
  })
};
