import * as R from 'ramda';
import { CaseGranulometry } from '../../../CaseGranulometry';
import { LodgmentsCountByType } from '../../../../levels/queries/topLevels/counts/getTopLevelLodgmentsCountByType';
import { getCaseLodgmentsCountByTopLevelAndType } from './getCaseLodgmentSectionsCountByTopLevelAndType';

type GetCaseLodgmentSectionsCountByType = (
  caseGranulometry: CaseGranulometry
) => LodgmentsCountByType;

export const getCaseLodgmentsCountByType: GetCaseLodgmentSectionsCountByType = R.pipe(
  getCaseLodgmentsCountByTopLevelAndType,
  R.values,
  R.reduce(R.mergeWith(R.add), {} as LodgmentsCountByType)
);
