import * as R from 'ramda';
import { getSectionName, Section } from '../Section';
import bicycleParkingSection, { BicycleParkingSection } from './bicycleParkingSection';
import technicalSection, { TechnicalSection } from './/technicalSection';
import trashcanSection, { TrashcanSection } from './/trashcanSection';
import opticalFiberSection, { OpticalFiberSection } from './/opticalFiberSection';
import electricalTransformerSection, {
  ElectricalTransformerSection
} from './/electricalTransformerSection';
import bicycleParkingInfraSection, {
  BicycleParkingInfraSection
} from './bicycleParkingInfraSection';
import electricalTransformerInfraSection, {
  ElectricalTransformerInfraSection
} from './electricalTransformerInfraSection';
import technicalInfraSection, { TechnicalInfraSection } from './technicalInfraSection';
import trashcanInfraSection, { TrashcanInfraSection } from './trashcanInfraSection';
import opticalFiberInfraSection, { OpticalFiberInfraSection } from './opticalFiberInfraSection';


export enum TechnicalPremiseSectionName {
  BicycleParkingSection = 'bicycleParkingSection',
  TechnicalSection = 'technicalSection',
  TrashcanSection = 'trashcanSection',
  OpticalFiberSection = 'opticalFiberSection',
  ElectricalTransformerSection = 'electricalTransformerSection'
}

export enum TechnicalInfraPremiseSectionName {
  BicycleParkingInfraSection = 'bicycleParkingInfraSection',
  TechnicalInfraSection = 'technicalInfraSection',
  TrashcanInfraSection = 'trashcanInfraSection',
  OpticalFiberInfraSection = 'opticalFiberInfraSection',
  ElectricalTransformerInfraSection = 'electricalTransformerInfraSection'
}

export type TechnicalPremiseSection =
  | BicycleParkingSection
  | TechnicalSection
  | TrashcanSection
  | OpticalFiberSection
  | ElectricalTransformerSection;

export type TechnicalInfraPremiseSection =
  | BicycleParkingInfraSection
  | TechnicalInfraSection
  | TrashcanInfraSection
  | OpticalFiberInfraSection
  | ElectricalTransformerInfraSection;

type GetTechnicalPremiseName = (
  otherSection: TechnicalPremiseSection
) => TechnicalPremiseSectionName;
export const getTechnicalPremiseSectionName: GetTechnicalPremiseName = R.prop('name');

export const isSectionAnTechnicalPremise = (section: Section): section is TechnicalPremiseSection =>
  (R.values(TechnicalPremiseSectionName) as string[]).includes(getSectionName(section));

export const filterTechnicalPremiseSections = (sections: Section[]): TechnicalPremiseSection[] =>
  sections.filter(isSectionAnTechnicalPremise);

export const getTechnicalPremiseSectionsList = (): TechnicalPremiseSection[] => [
  technicalSection({}),
  bicycleParkingSection({}),
  opticalFiberSection({}),
  trashcanSection({}),
  electricalTransformerSection({})
];

type GetTechnicalInfraPremiseName = (
  otherSection: TechnicalInfraPremiseSection
) => TechnicalInfraPremiseSectionName;
export const getTechnicalInfraPremiseSectionName: GetTechnicalInfraPremiseName = R.prop('name');

export const isSectionAnTechnicalInfraPremise = (
  section: Section
): section is TechnicalInfraPremiseSection =>
  (R.values(TechnicalInfraPremiseSectionName) as string[]).includes(getSectionName(section));

export const filterTechnicalInfraPremiseSections = (
  sections: Section[]
): TechnicalInfraPremiseSection[] => sections.filter(isSectionAnTechnicalInfraPremise);

export const getTechnicalPremiseInfraSectionsList = (): TechnicalInfraPremiseSection[] => [
  technicalInfraSection({}),
  bicycleParkingInfraSection({}),
  opticalFiberInfraSection({}),
  trashcanInfraSection({}),
  electricalTransformerInfraSection({})
];
