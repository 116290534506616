import React from 'react';
import { CardsList } from '../../../../ui/CardsList';
import { WindowScroll } from '../../../../ui/WindowScroll';
import { isUiLevelGeometry, UiGeometry } from '../Geometry';
import { ProjectTreeCard } from './LeftPanel/ProjectTreeCard';
import { ParcelsCard } from './LeftPanel/ParcelsCard';

interface LeftPanelProps {
  ui: UiGeometry;
}

export const LeftPanel = ({ ui }: LeftPanelProps) => (
  <div className={'leftPanel'}>
    <CardsList>
      <WindowScroll>
        {isUiLevelGeometry(ui) && <ProjectTreeCard ui={ui} />}
        <ParcelsCard />
      </WindowScroll>
    </CardsList>
  </div>
);
