import { reportRefLot } from '../reportEntries/reportRefLot';
import { createFilter } from '../createFilter';
import { reportRefLotList } from '../reportEntries/reportRefLotList';
import { reportRefSsLot1 } from '../reportEntries/reportRefSsLot1';
import { reportRefSsLot1List } from '../reportEntries/reportRefSsLot1List';
import { reportRefSsLot2 } from '../reportEntries/reportRefSsLot2';
import { reportRefSsLot2List } from '../reportEntries/reportRefSsLot2List';
import { reportRefSsLot3 } from '../reportEntries/reportRefSsLot3';
import { reportRefSsLot3List } from '../reportEntries/reportRefSsLot3List';
import { ReportEntry, reportEntryLabels } from '../../../../../../domain/report/ReportEntry';
import { Column } from 'react-table';
import { filterRowsAndPivotsThroughSelect } from '../filters/filterRowsAndPivotsThroughSelect';
import classNames from 'classnames';

export const getNomenclatureColumns = (report: ReportEntry[], foldedColumns: string[]): Column => ({
  Header: 'Nomenclature',
  columns: [
    {
      Header: reportEntryLabels.lot,
      id: 'refLot',
      accessor: (row) => reportRefLot(row),
      filterMethod: filterRowsAndPivotsThroughSelect,
      Filter: createFilter(reportRefLotList(report)),
      sortable: false,
      resizable: false,
      width: 60,
      className: classNames({
        folded: foldedColumns.indexOf('Lot') > -1
      }),
      headerClassName: classNames({
        folded: foldedColumns.indexOf('Lot') > -1
      })
    },
    {
      Header: reportEntryLabels.ssLot1,
      id: 'refSsLot1',
      accessor: (row) => reportRefSsLot1(row),
      filterMethod: filterRowsAndPivotsThroughSelect,
      Filter: createFilter(reportRefSsLot1List(report)),
      Aggregated: () => '',
      sortable: false,
      resizable: false,
      width: 60,
      className: classNames({
        folded: foldedColumns.indexOf('Sous-lot 1') > -1
      }),
      headerClassName: classNames({
        folded: foldedColumns.indexOf('Sous-lot 1') > -1
      })
    },
    {
      Header: reportEntryLabels.ssLot2,
      id: 'refSsLot2',
      accessor: (row) => reportRefSsLot2(row),
      filterMethod: filterRowsAndPivotsThroughSelect,
      Filter: createFilter(reportRefSsLot2List(report)),
      Aggregated: () => '',
      sortable: false,
      resizable: false,
      width: 60,
      className: classNames({
        folded: foldedColumns.indexOf('Sous-lot 2') > -1
      }),
      headerClassName: classNames({
        folded: foldedColumns.indexOf('Sous-lot 2') > -1
      })
    },
    {
      Header: reportEntryLabels.ssLot3,
      id: 'refSsLot3',
      accessor: (row) => reportRefSsLot3(row),
      filterMethod: filterRowsAndPivotsThroughSelect,
      Filter: createFilter(reportRefSsLot3List(report)),
      Aggregated: () => '',
      sortable: false,
      resizable: false,
      width: 60,
      className: classNames('separator', {
        folded: foldedColumns.indexOf('Sous-lot 3') > -1
      }),
      headerClassName: classNames('separator', {
        folded: foldedColumns.indexOf('Sous-lot 3') > -1
      })
    }
  ]
});
