import { LevelGranulometry } from '../../LevelGranulometry';
import { isSectionABearing } from '../../../sections/circulationSections/bearing';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import { getTopLevelMinimumBearingSurface } from '../topLevels/surfaces/getTopLevelMinimumBearingSurface';
import { getSectionDisplayedSurface } from '../../../sections/queries/surfaces/getSectionDisplayedSurface';
import { getLevelFullFilledContent } from '../content/getLevelFullFilledContent';

export const getLevelBearingSurfaceIsLargeEnoughValidation = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): boolean => {
  const bearing = getLevelFullFilledContent(levelGranulometry).find(isSectionABearing);
  if (bearing !== undefined) {
    return (
      getSectionDisplayedSurface(bearing) >=
      getTopLevelMinimumBearingSurface(caseGranulometry, levelGranulometry).value
    );
  }
  return true;
};
