import { createSelector, Selector } from 'reselect';
import { selectProject } from './project.selector';
import { selectCurrentCaseIdFromRoute } from '../navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { getCaseLabelGivenCaseId } from '../../../domain/core/queries/getCaseLabelGivenCaseId';
import { State } from '../../../store/reducers';
import { Maybe } from '../../../utils/Maybe';

export const selectCurrentCaseLabel: Selector<State, Maybe<string>> = createSelector(
  [selectProject, selectCurrentCaseIdFromRoute],
  (project, caseIdFromRoute) =>
    project && caseIdFromRoute ? getCaseLabelGivenCaseId(project, caseIdFromRoute) : undefined
);
