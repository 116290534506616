import { createSelector, Selector } from 'reselect';
import { State } from '../../../reducers';
import { selectCurrentCaseProjection } from '../../projection/currentCase/projection.selector';
import { HeatingDistribution } from '../../../../domain/specification/cases/HeatingDistribution';
import { Maybe } from '../../../../utils/Maybe';

export const selectCurrentCaseHeatingDistribution: Selector<
  State,
  Maybe<HeatingDistribution>
> = createSelector(
  [selectCurrentCaseProjection],
  (caseSpecification) => caseSpecification?.heatingDistribution
);
