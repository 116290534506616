import { getSectionName, Section } from '../Section';
import i18next from 'i18next';
import { MetaSectionName } from './MetaSection';

interface HighestLevelMetaSectionProps {
  ceilingNetHeight: number;
}

export interface HighestLevelMetaSection extends Section {
  name: MetaSectionName.HighestLevelMetaSection;
  ceilingNetHeight: number;
}

const highestLevelMetaSection = ({
  ceilingNetHeight
}: HighestLevelMetaSectionProps): HighestLevelMetaSection => ({
  // Section
  id: 'NOID',
  name: MetaSectionName.HighestLevelMetaSection,
  title: i18next.t('highestLevelMetaSection.title'),
  type: 'meta',
  displayedSurface: 0,
  ceilingNetHeight
});

export default highestLevelMetaSection;

export const isSectionAHighestLevelMetaSection = (
  section: Section
): section is HighestLevelMetaSection =>
  getSectionName(section) === MetaSectionName.HighestLevelMetaSection;
