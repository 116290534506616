import { ReportEntry } from '../../domain/report/ReportEntry';
import { roundWith2Decimal } from '../../utils/round/roundWith2Decimal';
import * as R from 'ramda';
import { sumCo2QuantitiesInReportBy } from '../utils/sumCo2QuantitiesInReportBy';
import { sortReportByRefSsLot2 } from '../utils/sortReportByRefSsLot2';

const getPerrenoudCategory = (reportEntry: ReportEntry): string =>
  reportEntry.refLot === '2'
    ? '1'
    : reportEntry.refLot === '3'
    ? '2'
    : reportEntry.refLot === '5'
    ? '3'
    : reportEntry.refLot === '4'
    ? '6'
    : reportEntry.refSsLot2 === '6.2.1'
    ? '7'
    : reportEntry.refSsLot2 === '6.2.4' || reportEntry.refSsLot2 === '6.2.5'
    ? '8'
    : parseInt(reportEntry.refLot) < 8
    ? '9'
    : '';

export interface PerrenoudJsonEntry {
  element: string;
  co2Id: string;
  product: string;
  unit: string;
  co2Quantity: string;
  refLot: string;
  refSsLot1: string;
  perrenoudCategory: string;
}

export type PerrenoudJson = Partial<PerrenoudJsonEntry>[];

const columns: (keyof PerrenoudJsonEntry)[] = [
  'element',
  'co2Id',
  'product',
  'unit',
  'co2Quantity',
  'refLot',
  'refSsLot1',
  'perrenoudCategory'
];

export const makePerrenoudJson = (reportEntries: ReportEntry[]): PerrenoudJson =>
  R.pipe<[ReportEntry[]], ReportEntry[], ReportEntry[], PerrenoudJson>(
    sumCo2QuantitiesInReportBy(['ssLot2', 'co2Id', 'co2Title']),
    sortReportByRefSsLot2,
    R.reduce<ReportEntry, PerrenoudJson>((acc, reportEntry) => {
      const hasToBeSkipped =
        reportEntry.co2Quantity === 0 ||
        isNaN(reportEntry.co2Quantity) ||
        !!reportEntry.co2Id.match(/[a-zA-Z]/);
      return !hasToBeSkipped
        ? [
            ...acc,
            columns.reduce((obj: Partial<PerrenoudJsonEntry>, key) => {
              let value: string;
              if (key === 'element') {
                value =
                  reportEntry.ssLot2 !== ''
                    ? reportEntry.ssLot2
                    : reportEntry.ssLot1 !== ''
                    ? reportEntry.ssLot1
                    : reportEntry.lot;
              } else if (key === 'unit') {
                value = reportEntry.co2QuantityUnit;
              } else if (key === 'co2Quantity') {
                value = roundWith2Decimal(reportEntry.co2Quantity).toString().replace('.', ',');
              } else if (key === 'refSsLot1') {
                value = reportEntry.refSsLot1 !== '' ? reportEntry.refSsLot1.split('.')[1] : '';
              } else if (key === 'perrenoudCategory') {
                value = getPerrenoudCategory(reportEntry);
              } else {
                value = reportEntry[key];
              }
              // Cleaning string values to preserve csv format
              obj[key] = value.toString().replace(/;|\s\s/gm, ' ');
              return obj;
            }, {})
          ]
        : acc;
    }, [])
  )(reportEntries);
