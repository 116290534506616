import { CaseSpecification } from '../cases/CaseSpecification';
import * as R from 'ramda';

export type BuildingId = string;

export type BuildingTitle = string;

export interface BuildingSpecification {
  id: BuildingId;
  title?: BuildingTitle;
  cases: CaseSpecification[];
}

export const casesFromBuilding = R.prop('cases');

export const overCases =
  (operation: (cases: CaseSpecification[]) => CaseSpecification[]) =>
  (building: BuildingSpecification) =>
    R.over(R.lensProp('cases'), operation, building);

export const hasId = (buildingId: BuildingId) => (building: BuildingSpecification) =>
  building.id === buildingId;
