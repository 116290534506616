import * as R from 'ramda';
import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import { Property } from '../../specification/cases/Property';
import { defaultCaseProperties } from '../../../resources/defaultCaseProperties';
import { addCaseProperty } from '../utils/addCaseProperty';

export const migrateProject13To14 = (project: Project): Project =>
  mapCases((caseSpecification) =>
    R.pipe<[CaseSpecification], CaseSpecification, CaseSpecification, CaseSpecification>(
      (cS) =>
        R.over(
          R.lensPath(['properties']),
          R.map((property: Property) =>
            R.pipe<[Property], Property, Property, Property, Property, Property>(
              (p) => (p.name === 'rampLength' ? { ...p, min: 10, max: 30 } : p),
              (p) => (p.name === 'highAdhesionSteelRateGroundSlab' ? { ...p, max: 70 } : p),
              (p) =>
                p.name === 'insideWallsThickness'
                  ? { ...p, title: 'Épaisseur de voiles non exposés en superstructure', max: 25 }
                  : p,
              (p) =>
                p.name === 'outsideWallsThickness'
                  ? { ...p, title: 'Épaisseur de voiles exposés en superstructure', max: 25 }
                  : p,
              (p) => (p.name === 'balconySurfaceOnRealLivingSurfaceRate' ? { ...p, max: 50 } : p)
            )(property)
          ),
          cS
        ),
      (cS) =>
        addCaseProperty(
          cS,
          defaultCaseProperties.find(
            (property) => property.name === 'insideWallsInfraThickness'
          ) as Property,
          cS.properties.findIndex((property) => property.name === 'insideWallsFormworkRate')
        ),
      (cS) =>
        addCaseProperty(
          cS,
          defaultCaseProperties.find(
            (property) => property.name === 'outsideWallsInfraThickness'
          ) as Property,
          cS.properties.findIndex((property) => property.name === 'insideWallsFormworkRate')
        )
    )(caseSpecification)
  )(project);
