import * as R from 'ramda';
import { Surface } from '../../../../specification/Surface';
import { CaseGranulometry } from '../../CaseGranulometry';
import { LevelGranulometry } from '../../../levels/LevelGranulometry';
import { getTopLevelGrossFloorSurfaceEff } from '../../../levels/queries/topLevels/surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurfaceEff';
import { getTopLevelGrossFloorSurface } from '../../../levels/queries/topLevels/surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurface';
import { getCaseTopLevels } from '../levels/getCaseTopLevels';

export type TopLevelSurfaceName =
  | 'realBuiltSurface'
  | 'surfaceForSale'
  | 'grossFloorSurface'
  | 'grossFloorSurfaceEff';

export interface TopLevelSurfaces {
  realBuiltSurface: Surface;
  surfaceForSale: Surface;
  grossFloorSurface: Surface;
  grossFloorSurfaceEff: Surface;
}

export interface TopLevelSurfacesWithIndex extends TopLevelSurfaces {
  level: number;
}

export const getCaseTopLevelsSurfaces = (
  caseGranulometry: CaseGranulometry
): TopLevelSurfacesWithIndex[] =>
  R.reduce(
    (acc, levelGranulometry: LevelGranulometry) =>
      acc.concat({
        level: levelGranulometry.level,
        realBuiltSurface: new Surface(levelGranulometry.realBuiltSurface || 0),
        surfaceForSale: new Surface(levelGranulometry.surfaceForSale || 0),
        grossFloorSurface: new Surface(
          getTopLevelGrossFloorSurface(caseGranulometry, levelGranulometry)
        ),
        grossFloorSurfaceEff: new Surface(
          getTopLevelGrossFloorSurfaceEff(caseGranulometry, levelGranulometry)
        )
      } as TopLevelSurfacesWithIndex),
    [] as TopLevelSurfacesWithIndex[]
  )(getCaseTopLevels(caseGranulometry));
