import { createSelector, Selector } from 'reselect';
import { selectProject } from './project.selector';
import { State } from '../../reducers';
import { ProjectSurfaceInputMode } from '../../../domain/project/Project';
import { Maybe } from '../../../utils/Maybe';

export const selectProjectSurfaceInputMode: Selector<
  State,
  Maybe<ProjectSurfaceInputMode>
> = createSelector([selectProject], (project) => project?.surfaceInputMode);
