import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/ui/Button';
import { selectProjectId } from 'store/selectors/project';
import NewBackButton from './NewBackButton';
import { Stepper } from '../../ui/Stepper';
import { selectProjectNameIsValid } from '../../../store/selectors/validation/projectNameIsValid.selector';
import { selectProjectCbsIsValid } from '../../../store/selectors/validation/projectCbsIsValid.selector';
import { selectProjectSurfaceInputMode } from '../../../store/selectors/project/projectSurfaceInputMode.selector';
import { selectProjectGfsEffIsValid } from '../../../store/selectors/validation/projectGfsEffIsValid.selector';
import { goToProject } from '../../../store/actions/navigations/toolbox/projectPanel/goToProject.action';
import { goToBuildings } from '../../../store/actions/navigations/toolbox/buildingsPanel/goToBuildings.action';

const NewProjectDetailsActionButtons: React.FC = () => {
  const projectId = useSelector(selectProjectId) as string;
  const projectNameIsValid = useSelector(selectProjectNameIsValid);
  const projectSurfaceInputMode = useSelector(selectProjectSurfaceInputMode);
  const projectCBSIsValid = useSelector(selectProjectCbsIsValid);
  const projectGfsEffIsValid = useSelector(selectProjectGfsEffIsValid);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const canGoNext =
    projectNameIsValid &&
    ((projectSurfaceInputMode === 'cbs' && projectCBSIsValid) ||
      (projectSurfaceInputMode === 'gfsEff' && projectGfsEffIsValid));

  return (
    <>
      <Stepper steps={[t('Project'), t('Buildings'), t('Cases')]} value={0} />
      <NewBackButton onClick={() => dispatch(goToProject(projectId))} />
      <Button
        isSubmit
        content={t('Next')}
        disabled={!canGoNext}
        withLoader={true}
        handleClick={() => dispatch(goToBuildings(projectId))}
      />
    </>
  );
};

export default NewProjectDetailsActionButtons;
