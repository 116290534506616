import { createSelector, Selector } from 'reselect';
import { getUserState, State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';
import { DateString } from '../../../DateString';
import { isLoggedIn } from '../../reducers/user.slice';

export const selectSessionExpirationDate: Selector<State, Maybe<DateString>> = createSelector(
  [getUserState],
  (user) => (isLoggedIn(user) ? user.auth.expiration : undefined)
);
