import { CaseGranulometry } from '../../CaseGranulometry';
import { RoofingName } from '../../../sections/roofingSections/RoofingSection';
import { getCaseFlatRoofingSurface } from '../sections/surfaces/getCaseFlatRoofingSurface';
import { getCaseTileRoofingSurface } from '../sections/surfaces/getCaseTileRoofingSurface';
import { RoofingType } from '../../../../specification/cases/RoofingType';

export const getCaseAverageRoofingType = (caseGranulometry: CaseGranulometry): RoofingType =>
  getCaseTileRoofingSurface(caseGranulometry) >= getCaseFlatRoofingSurface(caseGranulometry)
    ? RoofingName.TileRoofing
    : RoofingName.FlatRoofing;
