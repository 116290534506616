import * as React from 'react';
import { BasementLevelSpecification } from '../../../../../domain/specification/levels/BasementLevelSpecification';
import { Input } from '../../../../ui/input/Input';
import { IconObject } from '../../../../ui/Icons/iconObject';
import { OTHERS_COLOR } from '../../../../../constants/appConstants';
import { useSelector } from 'react-redux';
import { selectCurrentCaseBasementLevelsCount } from '../../../../../store/selectors/granulometry/currentCase/basementLevelsCount.selector';
import { ParkingsCountByType } from '../../../../../domain/granulometry/levels/queries/basementLevels/counts/getBasementLevelParkingsCountByType';

interface Column2Props {
  basementLevelSpecification: BasementLevelSpecification;
  handleParkingRateChange: (value: number | null) => void;
  parkingRate: number | undefined;
  isValid: boolean;
  parkingRatePH: number;
  parkingsCountByType: ParkingsCountByType;
}

export const Column2 = ({
  basementLevelSpecification,
  handleParkingRateChange,
  parkingRate,
  isValid,
  parkingRatePH,
  parkingsCountByType
}: Column2Props) => {
  const basementLevelsCount = useSelector(selectCurrentCaseBasementLevelsCount);
  return (
    <div className="column-2" tabIndex={-1}>
      <div style={{ width: '34px', marginRight: '5px' }}>
        <Input
          name={'basementLevel' + basementLevelSpecification.level + 'ParkingRate'}
          type="number"
          size="small"
          handleChange={handleParkingRateChange}
          value={parkingRate}
          color={isValid ? undefined : 'orange'}
          placeholder={parkingRatePH < 0 ? '0' : parkingRatePH.toString()}
          disabled={basementLevelsCount < 2}
        />
      </div>
      <div style={{ marginTop: '-5px' }}>
        <IconObject
          type="object"
          iconName="carBox"
          color={OTHERS_COLOR}
          customCss=" { transform: scale(0.6875); }"
          clickable={false}
        />
      </div>
      {!parkingsCountByType ? (
        <Input
          disabled={true}
          name={'basementLevel' + basementLevelSpecification.level + 'CarBoxCount'}
          size="small"
          style={{ width: '32px', marginLeft: '-6px' }}
          type="text"
          value="-"
        />
      ) : (
        <Input
          disabled={true}
          min={0}
          name={'basementLevel' + basementLevelSpecification.level + 'CarBoxCount'}
          size="small"
          style={{ width: '32px', marginLeft: '-6px' }}
          type="number"
          value={parkingsCountByType.carBoxInfra || 0}
        />
      )}

      <div style={{ marginTop: '-5px' }}>
        <IconObject
          type="object"
          iconName="carPark"
          color={OTHERS_COLOR}
          customCss=" { transform: scale(0.6875); }"
          clickable={false}
        />
      </div>
      {!parkingsCountByType ? (
        <Input
          disabled={true}
          name={'basementLevel' + basementLevelSpecification.level + 'CarParkCount'}
          size="small"
          style={{ width: '32px', marginLeft: '-6px' }}
          type="text"
          value="-"
        />
      ) : (
        <Input
          disabled={true}
          min={0}
          name={'basementLevel' + basementLevelSpecification.level + 'CarParkCount'}
          size="small"
          style={{ width: '32px', marginLeft: '-6px' }}
          type="number"
          value={parkingsCountByType.carParkInfra || 0}
        />
      )}
    </div>
  );
};
