import { createSelector, Selector } from 'reselect';
import { selectPath } from '../../getPath.selector';
import { State } from '../../../../reducers';
import { caseGranulometryEditLevelGeometryRoute } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditLevelGeometryRoute';

export const selectIsCaseGranulometryEditLevelGeometryOpened: Selector<State, boolean> =
  createSelector(
    [selectPath],
    (path) => caseGranulometryEditLevelGeometryRoute.matchesPath(path).matches
  );
