import { addElementOnMask } from './addElementOnMask';
import { getWindowHeight } from './getWindowHeight';

export const getGroupPositionOnBuildingMap = (
  elements: NodeListOf<Element>,
  zoomValue: number,
  withMask: boolean
): { translateX: number; translateY: number } | false => {
  const buildingMap = document.querySelector('.buildingMap');
  if (buildingMap) {
    let minLeft = 1000000;
    let maxRight = -1000000;
    let minTop = 1000000;
    let maxBottom = -1000000;
    const buildingMapOffset = buildingMap.getBoundingClientRect();

    elements.forEach((element: Element) => {
      const elementOffset = element.getBoundingClientRect();
      if (elementOffset.left < minLeft) {
        minLeft = elementOffset.left;
      }
      if (elementOffset.top < minTop) {
        minTop = elementOffset.top;
      }
      if (elementOffset.right > maxRight) {
        maxRight = elementOffset.right;
      }
      if (elementOffset.bottom > maxBottom) {
        maxBottom = elementOffset.bottom;
      }
      if (withMask) {
        addElementOnMask(element, true, zoomValue, elementOffset, elementOffset, buildingMapOffset);
      }
    });
    return {
      translateX: Math.round(
        ((minLeft + maxRight) / 2 - (buildingMapOffset.left + buildingMapOffset.right) / 2) *
          (1 / zoomValue) *
          -1
      ),
      translateY: Math.round(
        ((minTop + maxBottom) / 2 -
          (buildingMapOffset.top + buildingMapOffset.bottom) / 2 -
          getWindowHeight() / 2) *
          (1 / zoomValue) *
          -1
      )
    };
  }
  return false;
};
