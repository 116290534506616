import * as React from 'react';

export const BracketHorizontal = () => (
  <div className="bracket horizontal">
    <div className="closing">
      <svg version="1.1" viewBox="0 0 85.2 122">
        <path d="M85.2,80h-48c-17.6,0-32-14.3-32-32V0" />
      </svg>
    </div>
    <div className="line">
      <svg version="1.1" viewBox="0 0 85.2 122" preserveAspectRatio="none">
        <line x1="0" y1="80" x2="85.2" y2="80" />
      </svg>
    </div>
    <div className="middle">
      <svg version="1.1" viewBox="0 0 85.2 122">
        <path d="M85.2,80h-8.1l-35.2,35.2L6.6,80H0" />
      </svg>
    </div>
    <div className="line">
      <svg version="1.1" viewBox="0 0 85.2 122" preserveAspectRatio="none">
        <line x1="0" y1="80" x2="85.2" y2="80" />
      </svg>
    </div>
    <div className="closing">
      <svg version="1.1" viewBox="0 0 85.2 122">
        <path d="M80,0v48c0,17.6-14.3,32-32,32H0" />
      </svg>
    </div>
  </div>
);
