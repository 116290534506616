import * as React from 'react';
import { Route } from 'react-router';
import { WindowsGroup } from '../../../../ui/WindowsGroup';
import CaseOtherPropertiesWindow from './CaseOtherPropertiesWindow';
import PropertyWindow from '../PropertyWindow/PropertyWindow';
import { caseGranulometryEditOtherPropertiesPropertyRoute } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditOtherPropertiesPropertyRoute';

export const CaseOtherPropertiesWindowsGroup = () => {
  return (
    <WindowsGroup>
      <CaseOtherPropertiesWindow />
      <Route
        path={caseGranulometryEditOtherPropertiesPropertyRoute.path}
        component={PropertyWindow}
      />
    </WindowsGroup>
  );
};
