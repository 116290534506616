import { createSelector, Selector } from 'reselect';
import { State } from '../../../store/reducers';
import { Maybe } from '../../../utils/Maybe';
import { ifDefined } from '../../../utils/ifDefined';
import { selectGranulometry } from './current.selector';
import { getLodgmentsCount } from '../../../domain/granulometry/queries/getLodgmentsCount';

export const selectLodgmentsCount: Selector<State, Maybe<number>> = createSelector(
  [selectGranulometry],
  ifDefined(getLodgmentsCount)
);
