import { RoomStaticElement } from './RoomStaticElement';

export const entranceElements: RoomStaticElement[] = [
  { e1: 'chape ciment', e2: '', e3: '', quantity: 1 },
  { e1: 'revêtement de sol carrelage', e2: '', e3: '', quantity: 1 },
  { e1: 'résilient acoustique', e2: '', e3: '', quantity: 1 },
  { e1: 'plinthe carrelage', e2: '', e3: '', quantity: 1 },
  { e1: 'finition murale', e2: '', e3: '', quantity: 1 },
  { e1: 'enduit pelliculaire horizontal', e2: '', e3: '', quantity: 1 },
  { e1: 'finition plafond', e2: '', e3: '', quantity: 1 },
  { e1: 'gaine tableau électrique logement', e2: '', e3: '', quantity: 1 },
  { e1: 'porte de distribution', e2: '', e3: '', quantity: 1 },
  { e1: 'porte de distribution', e2: 'peinture sur bois prépeint', e3: '', quantity: 1 },
  { e1: 'porte de distribution', e2: 'huisserie porte de distribution', e3: '', quantity: 1 },
  {
    e1: 'porte de distribution',
    e2: 'huisserie porte de distribution',
    e3: 'peinture sur métal',
    quantity: 1
  },
  { e1: 'porte de distribution', e2: 'seuil bois passage de porte', e3: '', quantity: 1 },
  { e1: 'placard coulissant', e2: '', e3: '', quantity: 1 },
  { e1: 'placard coulissant', e2: 'tapée', e3: '', quantity: 1 },
  { e1: 'placard coulissant', e2: 'tapée', e3: 'peinture sur bois brut', quantity: 1 },
  { e1: 'placard pivotant', e2: '', e3: '', quantity: 1 },
  { e1: 'porte palière', e2: '', e3: '', quantity: 1 },
  { e1: 'porte palière', e2: 'laque sur bois prépeint', e3: '', quantity: 1 },
  { e1: 'porte palière', e2: 'huisserie à bancher', e3: '', quantity: 1 },
  { e1: 'porte palière', e2: 'huisserie à bancher', e3: 'laque sur métal', quantity: 1 },
  { e1: 'point centre', e2: '', e3: '', quantity: 1 },
  { e1: 'interrupteur', e2: '', e3: '', quantity: 1 },
  { e1: 'prise de courant 16A', e2: '', e3: '', quantity: 1 },
  { e1: 'tableau electrique', e2: '', e3: '', quantity: 1 },
  { e1: "thermostat d'ambiance", e2: '', e3: '', quantity: 1 },
  { e1: 'sonnette', e2: '', e3: '', quantity: 1 }
];
