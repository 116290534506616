import { LevelGranulometryInitialEntries } from '../../../LevelGranulometry';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { isGroundLevel } from '../../is/isGroundLevel';
import { mustHaveARampSection } from '../../mustHave/mustHaveARampSection';
import { createTopLevelRampSection } from './create/createTopLevelRampSection';
import { RampSection } from '../../../../sections/circulationSections/ramp';

export const getTopLevelRampSections = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryInitialEntries
): RampSection[] | [] => {
  if (mustHaveARampSection(caseGranulometry, levelGranulometry)) {
    const ramp = createTopLevelRampSection(caseGranulometry, 'ramp' + caseGranulometry.labelIndex);
    ramp.openedAbove = !isGroundLevel(levelGranulometry);
    ramp.openedBelow =
      levelGranulometry.level !==
      caseGranulometry.initialSpecifications.projectedBasementLevelsCount * -1;
    return [ramp];
  }
  return [];
};
