import { BasicRoomSpecification } from '../../BasicRoomSpecification';
import { getBasicRoomSpecificationLength } from './getBasicRoomSpecificationLength';
import { getBasicRoomSpecificationWidth } from './getBasicRoomSpecificationWidth';

type GetBasicRoomSpecificationCalculatedSurface = (
  roomSpecification: BasicRoomSpecification
) => number;
export const getBasicRoomSpecificationCalculatedSurface: GetBasicRoomSpecificationCalculatedSurface =
  (roomSpecification) =>
    getBasicRoomSpecificationLength(roomSpecification) *
    getBasicRoomSpecificationWidth(roomSpecification);
