import * as React from 'react';
import { CaseEditingStageName } from '../../../../../domain/specification/cases/CaseSpecification';
import { Input } from '../../../../ui/input/Input';
import { TopLevelSurfaceName } from '../../../../../domain/granulometry/cases/queries/surfaces/getCaseTopLevelsSurfaces';
import { LocalLevelsSurfaceRepartition } from '../TopLevelLines/Column4';
import { roundForSurfacesInputs } from '../../../../../utils/round/roundForSurfacesInputs';
import { getCaseBasementLevels } from '../../../../../domain/granulometry/cases/queries/levels/getCaseBasementLevels';
import { LevelGranulometry } from '../../../../../domain/granulometry/levels/LevelGranulometry';
import { useSelector } from 'react-redux';
import { selectCurrentCaseGranulometry } from '../../../../../store/selectors/granulometry/currentCase/currentCaseGranulometry.selector';

interface BasementLevelSurfaceInputProps {
  isRbsSelected: boolean;
  isGfsEffSelected: boolean;
  handleLevelSurfaceChange: (
    level: number | string,
    surfaceName: TopLevelSurfaceName,
    value: number | null
  ) => void;
  handleRealBuildSurfaceChange: (value: number | null) => void;
  caseLabel: string;
  levelIndex: number;
  selectedLevelSurface: TopLevelSurfaceName;
  localLevelsSpecification: LocalLevelsSurfaceRepartition;
  levelSurfaceValue: number | undefined;
  levelSurfacePlaceholder: number | undefined;
  editingStage: CaseEditingStageName;
  isDisabled: boolean;
  asCustomValue: boolean;
}

export const BasementLevelSurfaceInput = ({
  isRbsSelected,
  isGfsEffSelected,
  handleLevelSurfaceChange,
  handleRealBuildSurfaceChange,
  caseLabel,
  levelIndex,
  selectedLevelSurface,
  localLevelsSpecification,
  levelSurfaceValue,
  levelSurfacePlaceholder,
  editingStage,
  isDisabled,
  asCustomValue
}: BasementLevelSurfaceInputProps) => {
  const caseGranulometry = useSelector(selectCurrentCaseGranulometry);

  if (!caseGranulometry) return null;

  if (isRbsSelected) {
    const basementSurfaceBeforeSpecifiedRBS = (
      getCaseBasementLevels(caseGranulometry).find(
        (l) => l.level === levelIndex
      ) as LevelGranulometry
    )?.basementSurfaceBeforeSpecifiedRBS;
    return (
      <Input
        handleChange={handleRealBuildSurfaceChange}
        color={
          levelSurfaceValue === undefined ||
          basementSurfaceBeforeSpecifiedRBS === undefined ||
          levelSurfaceValue >= basementSurfaceBeforeSpecifiedRBS.value
            ? undefined
            : 'orange'
        }
        name={`${caseLabel}Level${levelIndex}${selectedLevelSurface}`}
        type="number"
        size="small"
        placeholder={levelSurfacePlaceholder}
        value={levelSurfaceValue}
        disabled={isDisabled}
        asCustomValue={asCustomValue}
      />
    );
  } else {
    return (
      <Input
        handleChange={(value: number | null) =>
          isGfsEffSelected
            ? handleLevelSurfaceChange(levelIndex, selectedLevelSurface, value)
            : null
        }
        name={`${caseLabel}Level${levelIndex}${selectedLevelSurface}`}
        type="number"
        size="small"
        min={0}
        color={!localLevelsSpecification.isValid ? 'orange' : undefined}
        value={
          levelSurfaceValue !== undefined ? roundForSurfacesInputs(levelSurfaceValue) : undefined
        }
        placeholder={
          isGfsEffSelected && levelSurfacePlaceholder !== undefined
            ? roundForSurfacesInputs(levelSurfacePlaceholder)
            : undefined
        }
        disabled={editingStage === CaseEditingStageName.Distribution || isDisabled}
        asCustomValue={asCustomValue}
      />
    );
  }
};
