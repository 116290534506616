import { createSelector, Selector } from 'reselect';
import { State } from '../../../store/reducers';
import { selectProjectCurrentCustomPriceListId } from './projectCustomCustomPriceListId.selector';
import { selectAccountCustomPriceLists } from '../user/customPriceLists.selector';
import { findCustomPriceListIndexFromGivenId } from '../../../components/business/panels/deliverables/ReportTable/customPricesLists/findCustomPriceListIndexFromGivenId';
import { CustomPriceList } from '../../../domain/Account';

export const selectProjectCurrentCustomPriceList: Selector<State, CustomPriceList | undefined> =
  createSelector(
    [selectAccountCustomPriceLists, selectProjectCurrentCustomPriceListId],
    (customPriceLists, projectCustomPriceListId) =>
      projectCustomPriceListId !== 'none'
        ? customPriceLists[
            findCustomPriceListIndexFromGivenId(projectCustomPriceListId)(customPriceLists)
          ]
        : undefined
  );
