import { Action } from 'redux';

export const PROJECT_SAVE_FAILED = 'PROJECT_SAVE_FAILED';

export interface ProjectSaveFailedAction extends Action {
  type: typeof PROJECT_SAVE_FAILED;
  error: true;
  payload?: Error;
}

export const projectSaveFailed = (error?: Error): ProjectSaveFailedAction => ({
  type: PROJECT_SAVE_FAILED,
  error: true,
  payload: error
});
