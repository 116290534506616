import { LevelPeripheralReinforcementsSpecifications } from '../../LevelGranulometry';
import { getLevelFormworkBenchLengthMaxValue } from './getLevelFormworkBenchLengthMaxValue';
import { getLevelFormworkBenchLengthValidity } from './getLevelFormworkBenchLengthValidity';
import { getLevelContiguousAgainstBuiltLinearMaxValue } from './getLevelContiguousAgainstBuiltLinearMaxValue';
import { getLevelContiguousAgainstBuiltLinearValidity } from './getLevelContiguousAgainstBuiltLinearValidity';
import { getLevelContiguousUnderBuiltLinearMaxValue } from './getLevelContiguousUnderBuiltLinearMaxValue';
import { getLevelContiguousUnderBuiltLinearValidity } from './getLevelContiguousUnderBuiltLinearValidity';
import { getLevelContiguousNotBuiltLinearMaxValue } from './getLevelContiguousNotBuiltLinearMaxValue';
import { getLevelContiguousNotBuiltLinearValidity } from './getLevelContiguousNotBuiltLinearValidity';

export interface LevelPeripheralReinforcementValidation {
  formworkBenchLengthIsValid: boolean;
  contiguousAgainstBuiltLinearIsValid: boolean;
  contiguousUnderBuiltLinearIsValid: boolean;
  contiguousNotBuiltLinearIsValid: boolean;
}

export const getLevelPeripheralReinforcementValidation = (
  specifications: LevelPeripheralReinforcementsSpecifications
): LevelPeripheralReinforcementValidation => ({
  formworkBenchLengthIsValid: getLevelFormworkBenchLengthValidity(
    specifications.formworkBenchLength,
    getLevelFormworkBenchLengthMaxValue()
  ),
  contiguousAgainstBuiltLinearIsValid: getLevelContiguousAgainstBuiltLinearValidity(
    specifications.contiguousAgainstBuiltLinear,
    getLevelContiguousAgainstBuiltLinearMaxValue(specifications)
  ),
  contiguousUnderBuiltLinearIsValid: getLevelContiguousUnderBuiltLinearValidity(
    specifications.contiguousUnderBuiltLinear,
    getLevelContiguousUnderBuiltLinearMaxValue(specifications)
  ),
  contiguousNotBuiltLinearIsValid: getLevelContiguousNotBuiltLinearValidity(
    specifications.contiguousNotBuiltLinear,
    getLevelContiguousNotBuiltLinearMaxValue(specifications)
  )
});
