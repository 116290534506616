import { CaseGranulometry } from '../../../CaseGranulometry';
import { Surface } from '../../../../../specification/Surface';
import { getCaseBasementLevels } from '../getCaseBasementLevels';
import { getLevelAlreadyCalculatedRealBuiltSurface } from '../../../../levels/queries/surfaces/getLevelAlreadyCalculatedRealBuiltSurface';

export const getCaseBasementLevelsRealBuiltSurfaces = (
  caseGranulometry: CaseGranulometry
): Surface[] =>
  getCaseBasementLevels(caseGranulometry).reduce(
    (acc, levelGranulometry) => [
      ...acc,
      getLevelAlreadyCalculatedRealBuiltSurface(levelGranulometry)
    ],
    [] as Surface[]
  );
