import { getCaseTheoricalTechnicalSheathsCountByLevel } from './getCaseRealTechnicalSheathsCountByLevel/getCaseTheoricalTechnicalSheathsCountByLevel';
export interface SectionsWithSheathsCountByLevel {
  level: number;
  lodgmentsCount: number;
  technicalPremisesCount: number;
  commonPremisesCount: number;
  shopsCount: number;
  officesCount: number;
}

export type RealTechnicalSheathCountByLevel = {
  levelIndex: number;
  realSheathCount: number;
};

export const getCaseRealTechnicalSheathsCountByLevel = (
  sectionsWithSheathsCountByLevel: SectionsWithSheathsCountByLevel[]
): RealTechnicalSheathCountByLevel[] => {
  const countsByLevel = getCaseTheoricalTechnicalSheathsCountByLevel(
    sectionsWithSheathsCountByLevel
  );
  return countsByLevel.reduce((acc, counts, index) => {
    let previousLevelsTechnicalSheathMaxCount = 0;
    for (let i = index; i >= 0; i--) {
      previousLevelsTechnicalSheathMaxCount = Math.max(
        previousLevelsTechnicalSheathMaxCount,
        countsByLevel[i].theoricalSheathsCount
      );
    }
    const realSheathsCount = Math.max(
      counts.theoricalSheathsCount,
      previousLevelsTechnicalSheathMaxCount
    );
    return [
      ...acc,
      {
        levelIndex: counts.levelIndex,
        realSheathCount: realSheathsCount
      }
    ];
  }, [] as RealTechnicalSheathCountByLevel[]);
};
