import { CaseSpecification } from '../../CaseSpecification';
import { isBasementLevel } from '../../../levels/queries/is/isBasementLevel';

export const getCaseSpecifiedParkingsRate = (caseSpecification: CaseSpecification): number =>
  caseSpecification.levels
    .filter(isBasementLevel)
    .reduce(
      (acc, basementLevelSpecification) => acc + (basementLevelSpecification.parkingRate || 0),
      0
    );
