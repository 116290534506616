import * as R from 'ramda';
import { Granulometry } from '../Granulometry';
import {
  getLodgmentsCountByBuildingCaseTopLevelAndType,
  LodgmentsCountByCaseLevelAndType
} from './getLodgmentsCountByBuildingCaseTopLevelAndType';
import { LodgmentsCountByTopLevelAndType } from '../cases/queries/sections/counts/getCaseLodgmentSectionsCountByTopLevelAndType';
import { LodgmentsCountByType } from '../levels/queries/topLevels/counts/getTopLevelLodgmentsCountByType';

export type GetLodgmentsCount = (granulometry: Granulometry) => number;

export const getLodgmentsCount: GetLodgmentsCount = R.compose(
  R.sum,
  R.chain<LodgmentsCountByCaseLevelAndType, number>(
    R.compose(
      R.chain<LodgmentsCountByTopLevelAndType, number>(
        R.compose(R.chain<LodgmentsCountByType, number>(R.values), R.values)
      ),
      R.values
    )
  ),
  R.values,
  getLodgmentsCountByBuildingCaseTopLevelAndType
);
