import * as React from 'react';
import BuildingMapSectionView from './BuildingMapSectionView';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';
import { LevelGranulometry } from '../../../../domain/granulometry/levels/LevelGranulometry';
import { RoomSpecification } from '../../../../domain/specification/rooms/RoomSpecification';
import { MapPositionContextInterface } from '../../../../contexts/MapPositionContext';

export interface BuildingMapSectionProps {
  clickable: boolean; // if the section clickable.
  color: string; // the characters color
  ceilingHeight: number; // the section ceiling height in meters
  firstInGroup: boolean;
  lastInGroup: boolean;
  group: string; //  a string to group section on several levels.
  id: string; // Unique identifier for this Section
  icon: IconObjectProps;
  rooms: RoomSpecification[];
  iconPosition: string | boolean; // iconPosition set to "top" if the icon should be on top than centered
  inSectionWrappers: boolean;
  prev: boolean; // Has a previous section
  next: boolean; // Has a next section
  parent: LevelGranulometry; // the parent object
  direction: 'ltr' | 'rtl'; //  the direction of the section distribution
  surface: number; // the section surface in square meters used to render the buildingMap
  displayedSurface: number; // the section surface in square meters used to display the value (string)
  wallThickness: number; // the thickness of the walls separating the sections in meters
  type: string; // the type of the section.
  openedAbove: boolean; // the section is opened with the level above
  openedBelow: boolean; // the section is opened with the level below
  openedNext: boolean; // the section is opened with the next section in the same level
  openedPrev: boolean; // the section is opened with the previous section in the same level
  sectionGroupRecorder: string[];
  name: string;
  title: string;
  focusOn?: MapPositionContextInterface['focusOn'];
}

export class BuildingMapSectionViewController extends React.Component<
  BuildingMapSectionProps,
  any
> {
  public render() {
    return (
      <BuildingMapSectionView
        ceilingHeight={this.props.ceilingHeight}
        direction={this.props.direction}
        id={this.props.id}
        openedAbove={this.props.openedAbove}
        openedBelow={this.props.openedBelow}
        openedNext={this.props.openedNext}
        openedPrev={this.props.openedPrev}
        parent={this.props.parent}
        surface={this.props.surface}
        displayedSurface={this.props.displayedSurface}
        wallThickness={this.props.wallThickness}
        clickable={this.props.clickable}
        color={this.props.color}
        inSectionWrappers={this.props.inSectionWrappers}
        next={this.props.next}
        prev={this.props.prev}
        type={this.props.type}
        firstInGroup={this.props.firstInGroup}
        lastInGroup={this.props.lastInGroup}
        group={this.props.group}
        icon={this.props.icon}
        rooms={this.props.rooms}
        name={this.props.name}
        title={this.props.title}
      />
    );
  }
}
