import { ParkingsSpecification } from '../../domain/specification/lodgmentTypes/ParkingsSpecification';

export const CASE_PARKINGS_SPECIFICATION_CHANGED = 'caseSpecification/parkingsChanged';

export const caseParkingSpecificationChanged = (
  caseId: string,
  parkingsSpecification: Partial<ParkingsSpecification>
) => ({
  type: CASE_PARKINGS_SPECIFICATION_CHANGED,
  payload: { caseId, parkingsSpecification }
});

export type SetCaseParkingsSpecificationAction = ReturnType<typeof caseParkingSpecificationChanged>;
