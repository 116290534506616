import { LevelGeometry } from '../../../../../services/mapBox/mapboxDraw/levelGeometry';
import { CaseId } from '../../../cases/CaseSpecification';
import { BuildingId } from '../../../buildings/BuildingSpecification';
import { LevelId } from '../../../../granulometry/levels/LevelGranulometry';

export const getEmptyLevelGeometry = (
  levelId: LevelId,
  name: string | null,
  floor: number,
  parentCaseId: CaseId | null,
  parentBuildingId: BuildingId | null
): LevelGeometry => ({
  id: levelId,
  type: 'Feature',
  properties: {
    name, // Will be filled later in services/mapBox
    floor,
    parentCaseId, // Will be filled later in services/mapBox
    parentBuildingId // Will be filled later in services/mapBox
  },
  geometry: {
    coordinates: [],
    type: 'Polygon'
  }
});
