import { createSelector, Selector } from 'reselect';
import * as R from 'ramda';
import { Maybe } from '../../../utils/Maybe';
import { State } from '../../../store/reducers';
import { selectAllCasesProjections } from '../projection/allCasesProjections.selector';
import { CaseSpecificationWithIndexes } from '../../../domain/specification/cases/CaseSpecification';

export const selectFirstCase: Selector<State, Maybe<CaseSpecificationWithIndexes>> = createSelector(
  [selectAllCasesProjections],
  R.pipe<
    [CaseSpecificationWithIndexes[] | undefined],
    CaseSpecificationWithIndexes[],
    CaseSpecificationWithIndexes
  >(R.defaultTo([]), R.head)
);
