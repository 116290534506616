import { createSlice } from '@reduxjs/toolkit';

export interface GingerMapState {
  loading: boolean;
}

const initialState: GingerMapState = {
  loading: false
};

export const gingerMapState = createSlice({
  name: 'gingerMap',
  initialState,
  reducers: {
    getGingerMaps() {
      return { loading: true };
    },
    gotGingerMaps() {
      return initialState;
    }
  }
});
