import { useEffect, useState } from 'react';
import { LayerRepo } from '../layer/layer.repo';

export const useLayerObserver = (layerRepo: LayerRepo) => {
  const [_state, setState] = useState<boolean>(true);

  useEffect(() => {
    const fn = () => {
      setState((state) => !state);
    };
    layerRepo?.addObserver(fn);

    return () => {
      layerRepo?.removeObserver(fn);
    };
  }, [layerRepo]);
};
