import { Card } from '../../../../../ui/Card';
import { CardTitle } from '../../../../../ui/CardTitle';
import { isLayerWithLevelGeometry } from '../../../../../../services/mapBox/mapboxDraw/levelGeometry';
import React from 'react';
import { UiGeometry } from '../../Geometry';
import { IconObject } from '../../../../../ui/Icons/iconObject';
import { fireDrawEvents } from '../../../../../../services/mapBox/events';
import { LayerObject } from './ProjectTreeCard/LayerObject';
import {
  getLevelLabel,
  LevelId
} from '../../../../../../domain/granulometry/levels/LevelGranulometry';
import {
  Layer,
  LayerId,
  LayerWithLevelGeometry,
  LevelLayer
} from '../../../../../../services/mapBox/layer/layer.model';
import { IconFont } from '../../../../../ui/Icons/IconFont';
import { letterFromIndex } from '../../../../../../utils/letterFromIndex';
import { Button } from '../../../../../ui/Button';
import { BracketVertical } from '../../../../../ui/BracketVertical';
import { useLayerObserver } from '../../../../../../services/mapBox/hook/useLayerObserver';

export const ProjectTreeCard = ({ ui }: { ui: UiGeometry }) => {
  useLayerObserver(ui.layerRepo);

  const buildings = ui.layerRepo?.findByType('building');
  const cases = ui.layerRepo?.findByType('case');
  const levels = ui.layerRepo.findLevelWithGeometry();
  const levelsWithoutGeometry = ui.layerRepo.findLevelWithoutGeometry();
  const treeLayers =
    buildings?.map((b) => ({
      ...b,
      cases:
        cases
          ?.filter((c) => c.parent === b.id)
          .map((c) => ({
            ...c,
            levels:
              ([...levels, ...levelsWithoutGeometry]
                ?.filter((l) => l.parent === c.id)
                .sort(
                  (a, b) => a.properties?.floor - b.properties?.floor
                ) as unknown as Array<LevelLayer>) || []
          })) || []
    })) || [];
  const fire = fireDrawEvents(ui.map);

  const selectLayerTree = (layer: Layer) => {
    fire.layer.selectTree([layer.id]);
  };
  const selectLayerWithParent = (layer: Layer) => {
    fire.layer.selectWithParent([layer.id]);
  };

  const remove = (level: Layer) =>
    isLayerWithLevelGeometry(level)
      ? {
          action: () => removeLevel(level),
          label: 'Supprimer le niveau'
        }
      : undefined;

  const duplicate = (level: Layer) =>
    isLayerWithLevelGeometry(level)
      ? {
          action: () => duplicateLevel(level.id),
          label: "Dupliquer l'étage"
        }
      : undefined;

  const unselectLayers = () => {
    fire.layer.unSelectAll();
  };

  const addCase = (layer: Layer) => fire.layer.create({ layerType: 'case', parentLayer: layer });
  const addBuilding = () => fire.layer.create({ layerType: 'building' });
  const deleteLayer = (id: LayerId) => fire.layer.delete({ id });

  const removeLevel = (level: LayerWithLevelGeometry) =>
    fire.draw.delete({ features: [level.draw], drawHelperMode: false });
  const isSelected = (id: LayerId) => ui.layerRepo?.isSelected(id);
  const duplicateLevel = (id: LevelId) => {
    fire.duplicate({ id });
  };

  return (
    <div>
      <Card>
        <CardTitle>Projet</CardTitle>
        <div className={'geometryWindowLayers'}>
          {treeLayers?.map((building, buildingIndex) => (
            <div className={'buildingLayer'} key={buildingIndex}>
              <LayerObject
                icon={
                  <div className="icon-font-wrapper">
                    <div className="icon-font-wrapper-inner">
                      <IconFont returnString={(buildingIndex + 1).toString()} />
                    </div>
                  </div>
                }
                onIconClick={() => selectLayerWithParent(building)}
                remove={{
                  action: () => deleteLayer(building.id),
                  label: 'Supprimer le bâtiment et ses cages'
                }}
                isActive={isSelected(building.id)}
                select={{
                  action: () => selectLayerTree(building),
                  label: 'Tout sélectionner',
                  unselect: unselectLayers
                }}
              />
              <BracketVertical />
              <div className={'caseLayers'}>
                {building.cases.map((caseLayer, caseIndex) => (
                  <div className={'caseLayer'} key={caseIndex}>
                    <LayerObject
                      icon={
                        <IconObject
                          iconName="case"
                          title={'Cage ' + (buildingIndex + 1) + letterFromIndex(caseIndex)}
                          customText={(buildingIndex + 1).toString() + letterFromIndex(caseIndex)}
                          customTextPosition="center"
                          customCss=".iconObjectCustomText { top:0.5px; }"
                          type="menu"
                          clickable={false}
                        />
                      }
                      onIconClick={() => selectLayerWithParent(caseLayer)}
                      remove={{
                        action: () => deleteLayer(caseLayer.id),
                        label: 'Supprimer la cage et ses niveaux'
                      }}
                      select={{
                        action: () => selectLayerTree(caseLayer),
                        label: 'Tout sélectionner',
                        unselect: unselectLayers
                      }}
                      isActive={isSelected(caseLayer.id)}
                    />
                    <BracketVertical />
                    <div className={'levelLayers'}>
                      {caseLayer.levels.reverse().map((levelLayer, levelIndex) => {
                        return (
                          <div className={'levelLayer'} key={levelIndex}>
                            <LayerObject
                              icon={
                                <Button
                                  selected={isSelected(levelLayer.id)}
                                  fullWidth
                                  size="small"
                                  content={getLevelLabel(levelLayer.properties.floor)}
                                  appearance="outline"
                                />
                              }
                              onIconClick={() => selectLayerTree(levelLayer)}
                              remove={remove(levelLayer)}
                              duplicate={duplicate(levelLayer)}
                              disabledDuplicate={ui.layerRepo
                                .getProjectTreeCardActionStatus()
                                .duplicate.disabled(caseLayer.id, levelLayer.properties.floor)}
                              isActive={isSelected(levelLayer.id)}
                              warn={
                                !isLayerWithLevelGeometry(levelLayer)
                                  ? 'Ce niveau n’a pas encore été dessiné'
                                  : undefined
                              }
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ))}
                <div className={'buttons'}>
                  <IconObject
                    title={'Ajouter une cage dans le bâtiment ' + (buildingIndex + 1).toString()}
                    iconName="add"
                    type="menu"
                    className="add"
                    onClick={() => addCase(building)}
                  />
                </div>
              </div>
            </div>
          ))}
          <div className={'buttons'}>
            <IconObject
              title={'Ajouter un bâtiment'}
              iconName="add"
              type="menu"
              className="add"
              onClick={() => addBuilding()}
            />
          </div>
        </div>
      </Card>
    </div>
  );
};
