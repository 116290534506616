import { APP_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { getCasePropertyDefaultValue } from '../../../specification/cases/queries/get/properties/getCasePropertyDefaultValue';
import { CirculationName } from './CirculationSection';
import { SectionWithTheoreticalSurface } from '../SectionWithTheoreticalSurface';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { RAMP_ICON } from './ramp';
import { SectionWithWidth } from '../SectionWithWidth';
import { SectionWithLength } from '../SectionWithLength';

interface RampInfraProps {
  surface: number;
  length: number;
  width: number;
  exposureRate: number;
  garageDoorHeight: number;
  garageDoorWidth: number;
  group: string;
}

export interface RampInfraSection
  extends Section,
    SectionDrawn,
    SectionWithLength,
    SectionWithWidth,
    SectionWithTheoreticalSurface,
    SectionWithExposureRate {
  name: CirculationName.RampInfra;
  garageDoorHeight: number;
  garageDoorWidth: number;
}

const rampInfra = ({
  surface,
  length,
  width,
  exposureRate,
  garageDoorHeight,
  garageDoorWidth,
  group
}: RampInfraProps): RampInfraSection => ({
  // Section
  id: 'NOID',
  name: CirculationName.RampInfra,
  title: i18next.t('rampInfra.title'),
  type: 'circulation',
  surface,
  // SectionDrawn
  displayedSurface: surface,
  color: APP_COLOR,
  icon: RAMP_ICON,
  group,
  // SectionWithTheoreticalSurface
  theoreticalSurface: surface,
  defaultTheoreticalSurface:
    getCasePropertyDefaultValue('rampWidth') * getCasePropertyDefaultValue('rampLength'),
  // SectionWithExposureRate
  exposureRate,
  defaultExposureRate: getCasePropertyDefaultValue('rampExposureRate'),
  // RampInfraSection
  length,
  width,
  garageDoorHeight,
  garageDoorWidth
});

export default rampInfra;

export const isSectionARampInfra = (section: Section): section is RampInfraSection =>
  getSectionName(section) === CirculationName.RampInfra;
