import { LodgmentType } from '../../../../lodgmentTypes/LodgmentType';
import * as R from 'ramda';
import { CaseSpecification } from '../../../CaseSpecification';
import { setLodgmentTypeSpecificationTheoreticalSurface } from '../../../../lodgmentTypes/queries/set/setLodgmentTypeSpecificationTheoreticalSurface';

export const setCaseLodgmentTypeSpecificationTheoreticalSurface =
  (lodgmentType: LodgmentType, surface: number | undefined) =>
  (caseSpecification: CaseSpecification) => {
    const lodgmentTypeIndex = caseSpecification.lodgmentsTypesSpecifications.findIndex(
      R.propEq('lodgmentType', lodgmentType)
    );
    return R.over(
      R.lensPath(['lodgmentsTypesSpecifications', lodgmentTypeIndex]),
      setLodgmentTypeSpecificationTheoreticalSurface()(surface),
      caseSpecification
    );
  };
