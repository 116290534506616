import { LodgmentType } from '../../../../lodgmentTypes/LodgmentType';
import * as R from 'ramda';
import { CaseSpecification } from '../../../CaseSpecification';
import { HeatingSystem } from '../../../../lodgmentTypes/HeatingSystem';
import { setLodgmentTypeSpecificationHeatingSystem } from '../../../../lodgmentTypes/queries/set/setLodgmentTypeSpecificationHeatingSystem';

export const setCaseLodgmentTypesSpecificationHeatingSystem =
  (lodgmentTypes: LodgmentType[], heatingSystem: HeatingSystem | undefined) =>
  (caseSpecification: CaseSpecification) =>
    R.reduce((caseSpecification, lodgmentType) => {
      return R.over(
        R.lensPath([
          'lodgmentsTypesSpecifications',
          caseSpecification.lodgmentsTypesSpecifications.findIndex(
            R.propEq('lodgmentType', lodgmentType)
          )
        ]),
        setLodgmentTypeSpecificationHeatingSystem(heatingSystem),
        caseSpecification
      );
    }, caseSpecification)(lodgmentTypes);
