import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { getInFullFilledTopLevelBearingSections } from '../getInFullFilledTopLevelBearingSections';
import { getSectionDisplayedSurface } from '../../../../../sections/queries/surfaces/getSectionDisplayedSurface';
import { Surface } from '../../../../../../specification/Surface';

export const getInFullFilledTopLevelBearingSectionSurface = (
  levelGranulometry: LevelGranulometry | LevelGranulometryFullFilled
): Surface => {
  const bearings = getInFullFilledTopLevelBearingSections(levelGranulometry);
  return new Surface(bearings[0] ? getSectionDisplayedSurface(bearings[0]) : 0);
};
