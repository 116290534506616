import * as React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectRoute } from '../../../store/selectors/navigation/getRoute.selector';
import { selectHasBeenCalculatedOnce } from '../../../store/selectors/project/hasBeenCalculatedOnce.selector';
import { selectIsDataBarDisplayed } from '../../../store/selectors/dataBar/isDataBarDisplayed';
import NewProjectDetailsActionButtons from './NewProjectDetailsActionButtons';
import NewBuildingsActionButtons from './NewBuildingsActionButtons';
import NewCasesActionButtons from './NewCasesActionButtons';
import UndoOrSaveButtons from './UndoOrSaveButtons';

const ActionBar = () => {
  const currentRoute = useSelector(selectRoute);
  const calculatedOnce = useSelector(selectHasBeenCalculatedOnce);
  const isDataBarDisplayed = useSelector(selectIsDataBarDisplayed);
  const displayActionBar = (!calculatedOnce && currentRoute.isPanel) || calculatedOnce;

  return displayActionBar ? (
    <div
      className={classNames('bar', 'action-bar', {
        withStepper: !calculatedOnce,
        dataBarDisplayed: isDataBarDisplayed
      })}>
      {!calculatedOnce &&
        ((currentRoute.isProjectDetails && <NewProjectDetailsActionButtons />) ||
          (currentRoute.isBuildings && <NewBuildingsActionButtons />) ||
          (currentRoute.isCases && <NewCasesActionButtons />))}
      {calculatedOnce && <UndoOrSaveButtons />}
    </div>
  ) : null;
};

export default ActionBar;
