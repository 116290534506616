import { ApiError } from './apiError';
import { ApiRedirectError } from './redirect.error';

export class ApiAuthError extends ApiError {
  constructor(requestedUrl: string, statusCode?: number, statusText?: string) {
    super(
      `Auth failed (${statusCode}) when requested ${requestedUrl}`,
      requestedUrl,
      statusCode,
      statusText
    );
  }

  public isApiAuthError = true;

  public static fromResponse(path: string) {
    return (response: Response) =>
      new ApiAuthError(path, response.status, response.status.toString());
  }

  public static fromRedirectError(redirectError: ApiRedirectError): ApiAuthError {
    return new ApiAuthError(
      redirectError.requestedUrl,
      redirectError.statusCode,
      redirectError.statusText
    );
  }
}
