import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CaseId } from '../../../../../../domain/specification/cases/CaseSpecification';
import { Option, Select } from '../../../../../ui/Select';
import { caseSpecificationChanged } from '../../../../../../store/actions/changeCaseSpecification.action';
import {
  MasonryType,
  MasonryTypeName
} from '../../../../../../domain/specification/cases/MasonryType';
import { selectCurrentCaseMasonryType } from '../../../../../../store/selectors/specification/currentCase/masonryType.selector';

interface MasonryTypesProps {
  caseId: CaseId;
}

export const MasonryTypes = ({ caseId }: MasonryTypesProps) => {
  const masonryType = useSelector(selectCurrentCaseMasonryType) as MasonryType;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectMasonryTypeOptions: Option[] = [
    {
      label: t('outsideWallTypeRates.masonryType.concreteBlock.label'),
      value: MasonryTypeName.ConcreteBlock
    },
    {
      label: t('outsideWallTypeRates.masonryType.brickBlock.label'),
      value: MasonryTypeName.BrickBlock
    }
  ];

  const selectedMasonryType = selectMasonryTypeOptions.find(
    (option) => option.value === masonryType
  );

  return (
    <div style={{ marginTop: '-4px', marginLeft: '38px', marginBottom: '8px' }}>
      <label htmlFor={`case${caseId}MasonryType`}>
        {t('outsideWallTypeRates.masonryType.label')}
      </label>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
        <Select
          id={`case${caseId}CarpentryType`}
          size={'small'}
          options={selectMasonryTypeOptions}
          handleChange={(props: Option) => {
            dispatch(caseSpecificationChanged(caseId, { masonryType: props.value as MasonryType }));
          }}
          value={selectedMasonryType}
          style={{ flexBasis: '194px' }}
        />
      </div>
    </div>
  );
};
