import { createSelector, Selector } from 'reselect';
import { State } from '../../../store/reducers';
import { Maybe } from '../../../utils/Maybe';
import { ifDefined } from '../../../utils/ifDefined';
import { projectNameIsValid } from '../../../domain/checks/project/projectNameIsValid';
import { selectProjectName } from '../project';

export const selectProjectNameIsValid: Selector<State, Maybe<boolean>> = createSelector(
  [selectProjectName],
  ifDefined(projectNameIsValid)
);
