import { CaseId, CaseSpecification } from '../../specification/cases/CaseSpecification';
import * as R from 'ramda';
import { getBuildingIdFromCaseId } from '../../core/queries/getBuildingIdFromCaseId';
import { getBuildingIndexGivenBuildingId } from '../../core/queries/getBuildingIndexGivenBuildingId';
import { getCaseIndexInBuildingFromCaseId } from '../../core/queries/getCaseIndexInBuildingGivenCaseId';
import { Project } from '../Project';

export const overCase =
  (buildingIndex: number, caseIndex: number) =>
  (fn: (target: CaseSpecification) => CaseSpecification) =>
    R.over(R.lensPath(['buildings', buildingIndex, 'cases', caseIndex]), fn);

export const overCaseId =
  (caseId: CaseId) =>
  (fn: (target: CaseSpecification) => CaseSpecification) =>
  (project: Project): Project => {
    const buildingId = getBuildingIdFromCaseId(project, caseId);

    if (!buildingId) {
      throw new Error(`No building for case ${caseId}`);
    }

    const buildingIndex = getBuildingIndexGivenBuildingId(project, buildingId);
    const caseIndexInBuilding = getCaseIndexInBuildingFromCaseId(project, caseId);

    if (caseIndexInBuilding < 0) {
      throw new Error(`No case ${caseId}`);
    }

    return overCase(buildingIndex, caseIndexInBuilding)(fn)(project);
  };
