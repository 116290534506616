import { BuildingSpecification } from '../../domain/specification/buildings/BuildingSpecification';

export const BUILDING_ADDED = 'core/buildingAdded';

type BuildingAddedPayload = { building: BuildingSpecification };
export const buildingAdded = (payload?: BuildingAddedPayload) => ({
  type: BUILDING_ADDED,
  payload
});

export type BuildingAddedAction = ReturnType<typeof buildingAdded>;
