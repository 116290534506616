import { createSelector, Selector } from 'reselect';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';
import { selectCurrentCaseProjection } from '../../projection/currentCase/projection.selector';
import { CarpentryType } from '../../../../domain/specification/cases/CarpentryType';

export const selectCurrentCaseCarpentryType: Selector<State, Maybe<CarpentryType>> = createSelector(
  [selectCurrentCaseProjection],
  (caseSpecification) => caseSpecification?.carpentryType
);
