import { createSelector, Selector } from 'reselect';
import { State } from '../../reducers';
import { selectCurrentBuildingInComEthReport } from './currentBuildingInComEthReport.selector';
import { Maybe } from '../../../utils/Maybe';
import { ComEthReportCase } from '../../../api/cstb/comEth/comEthReport/ComEthReportCase';
import { selectBuildingMapFocus } from '../buildingMap/buildingMapFocus.selector';
import { CaseFocus } from '../../../domain/buildingMap/BuildingMapFocus';

export const selectCurrentCaseInComEthReport: Selector<
  State,
  Maybe<ComEthReportCase>
> = createSelector(
  [selectCurrentBuildingInComEthReport, selectBuildingMapFocus],
  (cstbReportBuilding, buildingMapFocus) => {
    const caseId = (buildingMapFocus as CaseFocus).case;
    return cstbReportBuilding?.Children?.find((child) => child.gobuildCaseId === caseId);
  }
);
