import { createSelector, Selector } from 'reselect';
import { selectCurrentCaseGranulometry } from './currentCaseGranulometry.selector';
import {
  getCaseTopLevelsSurfaces,
  TopLevelSurfacesWithIndex
} from '../../../../domain/granulometry/cases/queries/surfaces/getCaseTopLevelsSurfaces';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';

export const selectCurrentCaseTopLevelsSurfaces: Selector<
  State,
  Maybe<TopLevelSurfacesWithIndex[]>
> = createSelector([selectCurrentCaseGranulometry], (caseGranulometry) =>
  caseGranulometry ? getCaseTopLevelsSurfaces(caseGranulometry) : undefined
);
