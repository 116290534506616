import { CaseGranulometry } from '../CaseGranulometry';
import { letterFromIndex } from '../../../../utils/letterFromIndex';
import { CaseProjection } from '../../../projection/cases/CaseProjection';

export const createCaseGranulometry = (
  buildingIndex: number,
  caseProjection: CaseProjection,
  caseIndex: number,
  buildingCaseCount: number
): CaseGranulometry => {
  const labelIndex = '' + (buildingIndex + 1) + letterFromIndex(caseIndex);
  return {
    id: caseProjection.id,
    labelIndex,
    name: 'case' + labelIndex,
    title: 'Cage ' + labelIndex, // !!! Must be traduce with react-i18next
    levels: [],
    allBasementsHeight: 0,
    properties: caseProjection.projectedProperties, // TODO : To remove
    initialSpecifications: caseProjection, // Save the case form data inside the case object
    carpentryType: caseProjection.carpentryType, // TODO : To remove
    carpentryDayTimeOccultationsType: caseProjection.carpentryDayTimeOccultationsType, // TODO : To remove
    carpentryDayTimeOccultationsTypeIsMonobloc:
      caseProjection.carpentryDayTimeOccultationsTypeIsMonobloc, // TODO : To remove
    carpentryNightTimeOccultationsType: caseProjection.carpentryNightTimeOccultationsType, // TODO : To remove
    carpentryNightTimeOccultationsTypeIsMonobloc:
      caseProjection.carpentryNightTimeOccultationsTypeIsMonobloc, // TODO : To remove
    carpentryOccultationsMotorisationType: caseProjection.carpentryOccultationsMotorisationType, // TODO : To remove
    masonryType: caseProjection.masonryType, // TODO : To remove
    woodEnvelopeType: caseProjection.woodEnvelopeType, // TODO : To remove
    heatingDistribution: caseProjection.heatingDistribution, // TODO : To remove
    direction: caseIndex === 0 ? (buildingCaseCount > 1 ? 'rtl' : 'ltr') : 'ltr',
    technicalSheathCountByLevel: []
  };
};
