import { Epic, ofType } from 'redux-observable';
import { Action } from 'redux';
import { REHYDRATE } from 'redux-persist';
import { EMPTY, of } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { LOCATION_CHANGE } from 'connected-react-router';
import { State } from '../reducers';
import { selectHasOpenedProject } from '../selectors/project/hasOpenedProject.selector';
import { selectProjectId } from '../selectors/project';
import { closeProject } from '../actions/closeProject.action';
import { matchesProject } from '../../routes/utils/matches/matchesProject';
import { selectPath } from '../selectors/navigation';
import { matchesProjects } from '../../routes/utils/matches/matchesProjects';

type ActionWithState = [Action, State];

export const closeProjectOnNavigationAwayEpic: Epic<Action, Action, State> = (actions$, state$) =>
  actions$.pipe(
    ofType(LOCATION_CHANGE, REHYDRATE),
    withLatestFrom(state$),
    switchMap(([, state]: ActionWithState) => {
      const path = selectPath(state);
      const hasOpenedProject = selectHasOpenedProject(state);
      const projectId = selectProjectId(state) as string;

      const isOnProjectPath = matchesProject(path).matches && !matchesProjects(path).matches;

      if (!isOnProjectPath && hasOpenedProject) {
        return of(closeProject(projectId));
      }

      return EMPTY;
    })
  );
