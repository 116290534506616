import classNames from 'classnames';
import * as React from 'react';
import { ReactNode } from 'react';

interface CardProps {
  children: ReactNode | ReactNode[];
  header?: ReactNode | ReactNode[];
  className?: string;
  style?: React.CSSProperties;
}

export const Card = ({ header, children, className, style }: CardProps) => (
  <div className={classNames('card', className)} style={style}>
    {header && <div className="card-header">{header}</div>}
    <div className="card-content">{children}</div>
  </div>
);
