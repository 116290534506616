import * as R from 'ramda';
import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import { Property } from '../../specification/cases/Property';

export const migrateProject29To30 = (project: Project): Project =>
  mapCases((caseSpecification) =>
    R.pipe<[CaseSpecification], CaseSpecification>((cS) =>
      R.over(
        R.lensPath(['properties']),
        R.map((property: Property) =>
          R.pipe<[Property], Property, Property>(
            (p) =>
              p.name === 'elevatorShaftLength'
                ? {
                    ...p,
                    title: 'Profondeur de la gaine ascenseur',
                    defaultValue: 1.8,
                    min: 1.8,
                    max: 2
                  }
                : p,
            (p) =>
              p.name === 'elevatorShaftWidth' ? { ...p, defaultValue: 1.6, min: 1.6, max: 2 } : p
          )(property)
        ),
        cS
      )
    )(caseSpecification)
  )(project);
