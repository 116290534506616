import {
  LevelGranulometry,
  LevelGranulometryFullFilled,
  LevelGranulometryWithBearingRelativeSections,
  LevelGranulometryWithLodgments
} from '../../LevelGranulometry';
import { ShopSection } from '../../../sections/shopSection/shopSection';

export const getLevelShopSections = (
  levelGranulometry:
    | LevelGranulometryWithLodgments
    | LevelGranulometryWithBearingRelativeSections
    | LevelGranulometryFullFilled
    | LevelGranulometry
): ShopSection[] =>
  levelGranulometry.shopSections?.map((shopSection) => ({
    ...shopSection,
    surface: shopSection.displayedSurface || shopSection.defaultSurface,
    displayedSurface: shopSection.displayedSurface || shopSection.defaultSurface,
    width: shopSection.width || shopSection.defaultWidth,
    exposureRate: shopSection.exposureRate || shopSection.defaultExposureRate
  })) || [];
