import * as React from 'react';
import { useSelector } from 'react-redux';
import { IconObject } from 'components/ui/Icons/iconObject';
import { OTHERS_COLOR } from 'constants/appConstants';
import { selectCurrentCaseSpecifiedParkingsRate } from 'store/selectors/specification/currentCase/parkingsRate.selector';
import { selectCurrentCaseBasementLevelsParkingRateRepartitionIsValid } from 'store/selectors/specification/currentCase/basementLevelsParkingRateRepartitionIsValid.selector';
import { selectCurrentCaseCarBoxInfraCount } from 'store/selectors/granulometry/currentCase/totalCarBoxInfraCount.selector';
import { selectCurrentCaseCarParkInfraCount } from 'store/selectors/granulometry/currentCase/totalCarParkInfraCount.selector';
import { selectCurrentCaseBasementsRealBuiltSurface } from 'store/selectors/granulometry/currentCase/basementsRealBuiltSurface.selector';
import { selectCurrentCaseBasementLevelsCount } from '../../../../store/selectors/granulometry/currentCase/basementLevelsCount.selector';
import { Input } from '../../../ui/input/Input';
import { roundForSurfacesInputs } from '../../../../utils/round/roundForSurfacesInputs';

const TotalBasementLevelLines: React.FC = () => {
  const specifiedParkingsRate = useSelector(selectCurrentCaseSpecifiedParkingsRate);
  const parkingsRateRepartitionIsValid = useSelector(
    selectCurrentCaseBasementLevelsParkingRateRepartitionIsValid
  );
  const carBoxInfraCount = useSelector(selectCurrentCaseCarBoxInfraCount);
  const carParkInfraCount = useSelector(selectCurrentCaseCarParkInfraCount);
  const basementsRealBuiltSurface = useSelector(selectCurrentCaseBasementsRealBuiltSurface);
  const basementLevelsCount = useSelector(selectCurrentCaseBasementLevelsCount);

  return (
    <div className="line total-basement-level-line">
      <div className="column-1">
        <div className="cell">&nbsp;</div>
      </div>
      <div className="column-2" tabIndex={-1}>
        <div style={{ width: '34px', marginRight: '5px' }}>
          <Input
            name={'totalBasementLevelParkingRate'}
            type="number"
            size="small"
            value={
              parkingsRateRepartitionIsValid || basementLevelsCount < 2
                ? 100
                : specifiedParkingsRate
            }
            color={parkingsRateRepartitionIsValid ? undefined : 'orange'}
            disabled={parkingsRateRepartitionIsValid}
            style={{ pointerEvents: 'none' }}
          />
        </div>
        <div style={{ marginTop: '-5px' }}>
          <IconObject
            type="object"
            iconName="carBox"
            color={OTHERS_COLOR}
            customCss=" { transform: scale(0.6875); }"
            clickable={false}
          />
        </div>
        <Input
          disabled={true}
          name="totalBasementLevelCarBoxCount"
          size="small"
          style={{ width: '32px', marginLeft: '-6px' }}
          type="text"
          value={carBoxInfraCount?.toString()}
        />
        <div style={{ marginTop: '-5px' }}>
          <IconObject
            type="object"
            iconName="carPark"
            color={OTHERS_COLOR}
            customCss=" { transform: scale(0.6875); }"
            clickable={false}
          />
        </div>
        <Input
          disabled
          name="totalBasementLevelCarParkCount"
          size="small"
          style={{ width: '32px', marginLeft: '-6px' }}
          type="text"
          value={carParkInfraCount?.toString()}
        />
      </div>
      <div className="column-3">
        <Input
          disabled
          name="totalBasementLevelParkingSpotCount"
          size="small"
          type="number"
          value={(carParkInfraCount || 0) + (carBoxInfraCount || 0)}
        />
      </div>
      <div className="column-4">
        <div className="cell">
          <Input
            name="totalBasementRBS"
            type="number"
            size="small"
            disabled
            value={
              basementsRealBuiltSurface
                ? roundForSurfacesInputs(basementsRealBuiltSurface.value)
                : 0
            }
          />
        </div>
      </div>
    </div>
  );
};

export default TotalBasementLevelLines;
