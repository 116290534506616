import { Project } from '../../project/Project';
import { checkThat } from '../checkThat';
import { projectHasSufficientRemainingSurfaceForANewCase } from './projectHasSufficientRemainingSurfaceForANewCase';

export const projectHasSufficientRemainingSurfaceForANewBuilding =
  projectHasSufficientRemainingSurfaceForANewCase;

export const checkThatProjectHasSufficientRemainingSurfaceForANewBuilding = (project: Project) =>
  checkThat(
    projectHasSufficientRemainingSurfaceForANewBuilding,
    'project has insufficient remaining surface for a new building',
    project
  );
