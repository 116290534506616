import i18next from 'i18next';
import { BasicRoomSpecification } from '../../specification/rooms/BasicRoomSpecification';

const DEFAULT_ENTRANCE_LENGTH = 2;
const DEFAULT_ENTRANCE_WIDTH = 1.45;

const entrance = (): BasicRoomSpecification =>
  ({
    name: 'entrance',
    title: i18next.t('Entrance (Hall)'),
    defaultLength: DEFAULT_ENTRANCE_LENGTH,
    width: undefined,
    defaultWidth: DEFAULT_ENTRANCE_WIDTH,
    surface: undefined,
    icon: {
      type: 'object',
      iconName: 'entrance',
      strokeStyle: 'opened'
    }
  } as const);

export default entrance;
