import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CaseId } from '../../../../../../domain/specification/cases/CaseSpecification';
import { Option, Select } from '../../../../../ui/Select';
import { caseSpecificationChanged } from '../../../../../../store/actions/changeCaseSpecification.action';
import { selectCurrentCaseCarpentryType } from '../../../../../../store/selectors/specification/currentCase/carpentryType.selector';
import {
  CarpentryType,
  CarpentryTypeName,
  isCarpentryTypeUsePVC
} from '../../../../../../domain/specification/cases/CarpentryType';
import { GoBuildIcons } from '../../../../../../utils/icons/iconObjectIcons';
import { IconObject } from '../../../../../ui/Icons/iconObject';
import {
  CarpentryOccultationsType,
  isCarpentryOccultationsTypeUsePVC
} from '../../../../../../domain/specification/cases/CarpentryOccultationsType';
import i18next from 'i18next';

interface CarpentryTypesProps {
  caseId: CaseId;
  setIsSelectedCarpentryTypeUsePVC: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCarpentryDayTimeOccultationTypeOption?: Option;
  setIsDayTimeMonoblocDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCarpentryNightTimeOccultationTypeOption?: Option;
  setIsNightTimeMonoblocDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CarpentryTypes = ({
  caseId,
  setIsSelectedCarpentryTypeUsePVC,
  selectedCarpentryDayTimeOccultationTypeOption,
  setIsDayTimeMonoblocDisplayed,
  selectedCarpentryNightTimeOccultationTypeOption,
  setIsNightTimeMonoblocDisplayed
}: CarpentryTypesProps) => {
  const carpentryType = useSelector(selectCurrentCaseCarpentryType) as CarpentryType;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedCarpentryType = selectCarpentryTypeOptions.find(
    (option) => option.value === carpentryType
  );

  const handleChange = React.useCallback(
    (option: Option) => {
      const isSelectedCarpentryTypeUsePVC = isCarpentryTypeUsePVC(option.value as CarpentryType);
      setIsSelectedCarpentryTypeUsePVC(isSelectedCarpentryTypeUsePVC);
      setIsDayTimeMonoblocDisplayed(
        isSelectedCarpentryTypeUsePVC &&
          !!selectedCarpentryDayTimeOccultationTypeOption &&
          isCarpentryOccultationsTypeUsePVC(
            selectedCarpentryDayTimeOccultationTypeOption.value as CarpentryOccultationsType
          )
      );
      setIsNightTimeMonoblocDisplayed(
        isSelectedCarpentryTypeUsePVC &&
          !!selectedCarpentryNightTimeOccultationTypeOption &&
          isCarpentryOccultationsTypeUsePVC(
            selectedCarpentryNightTimeOccultationTypeOption.value as CarpentryOccultationsType
          )
      );
      dispatch(caseSpecificationChanged(caseId, { carpentryType: option.value as CarpentryType }));
    },
    [dispatch, caseId, setIsSelectedCarpentryTypeUsePVC]
  );

  if (!selectedCarpentryType) return null;

  return (
    <>
      <label htmlFor={`case${caseId}CarpentryType`}>
        {t('carpentrySpecifications.carpentryType.label')}
      </label>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
        <IconObject
          type="menu"
          iconName={
            selectedCarpentryType.value === CarpentryTypeName.PlaxedPVC
              ? 'pvc'
              : (selectedCarpentryType.value as GoBuildIcons)
          }
          label={selectedCarpentryType.value === CarpentryTypeName.PlaxedPVC ? 'PL' : undefined}
          clickable={false}
        />
        <Select
          id={`case${caseId}CarpentryType`}
          options={selectCarpentryTypeOptions}
          handleChange={handleChange}
          value={selectedCarpentryType}
          style={{ marginLeft: '6px', flexBasis: '150px' }}
        />
      </div>
    </>
  );
};

export const selectCarpentryTypeOptions: Option[] = [
  {
    label: i18next.t('carpentrySpecifications.carpentryType.options.pvc'),
    value: CarpentryTypeName.PVC
  },
  {
    label: i18next.t('carpentrySpecifications.carpentryType.options.plaxedPvc'),
    value: CarpentryTypeName.PlaxedPVC
  },
  {
    label: i18next.t('carpentrySpecifications.carpentryType.options.wood'),
    value: CarpentryTypeName.Wood
  },
  {
    label: i18next.t('carpentrySpecifications.carpentryType.options.aluminium'),
    value: CarpentryTypeName.Aluminium
  },
  {
    label: i18next.t('carpentrySpecifications.carpentryType.options.aluminiumAndWood'),
    value: CarpentryTypeName.AluminiumAndWood
  }
];
