import { DataBarSegment } from './DataBarSegment';
import { DataBarItem } from './DataBarItem';
import * as React from 'react';
import { getFocusLabel, getValueForFocus } from './DataBar';
import { useSelector } from 'react-redux';
import { selectLodgmentsCount } from '../../../store/selectors/granulometry/lodgmentsCount.selector';
import { selectLodgmentsCountByBuilding } from '../../../store/selectors/granulometry/lodgmentsCountByBuilding.selector';
import { selectLodgmentsCountByCase } from '../../../store/selectors/granulometry/lodgmentsCountByCase.selector';
import { selectBuildingMapFocus } from '../../../store/selectors/buildingMap/buildingMapFocus.selector';
import { selectParkingsCount } from '../../../store/selectors/granulometry/parkings/parkingsCount.selector';
import { selectParkingsCountByBuilding } from '../../../store/selectors/granulometry/parkings/parkingsCountByBuilding.selector';
import { selectParkingsCountByCase } from '../../../store/selectors/granulometry/parkings/parkingsCountByCase.selector';
import { selectWoodCubatureByProject } from '../../../store/selectors/dataBar/woodCubatureByProject.selector';
import { selectWoodCubatureByBuilding } from '../../../store/selectors/dataBar/woodCubatureByBuilding.selector';
import { selectWoodCubatureByCase } from '../../../store/selectors/dataBar/woodCubatureByCase.selector';
import { selectBuildingMapFocusLabel } from '../../../store/selectors/buildingMap/buildingMapFocusLabel.selector';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { selectIsFetchingReport } from '../../../store/selectors/report/isFetchingReport.selector';

export const DataBarQuantitativeSegment = () => {
  const isReportFetching = useSelector(selectIsFetchingReport);

  const buildingMapFocus = useSelector(selectBuildingMapFocus);
  const buildingMapLabelledFocus = useSelector(selectBuildingMapFocusLabel);
  const { t } = useTranslation();

  const projectLodgmentsCount = useSelector(selectLodgmentsCount);
  const lodgmentsCountByBuilding = useSelector(selectLodgmentsCountByBuilding);
  const lodgmentsCountByCase = useSelector(selectLodgmentsCountByCase);
  const lodgmentsCount = React.useMemo(
    () =>
      getValueForFocus(
        buildingMapFocus,
        projectLodgmentsCount,
        lodgmentsCountByBuilding,
        lodgmentsCountByCase
      ),
    [buildingMapFocus, projectLodgmentsCount, lodgmentsCountByBuilding, lodgmentsCountByCase]
  );

  const projectParkingsCount = useSelector(selectParkingsCount);
  const parkingsCountByBuilding = useSelector(selectParkingsCountByBuilding);
  const parkingsCountByCase = useSelector(selectParkingsCountByCase);
  const parkingsCount = React.useMemo(
    () =>
      getValueForFocus(
        buildingMapFocus,
        projectParkingsCount,
        parkingsCountByBuilding,
        parkingsCountByCase
      ),
    [buildingMapFocus, projectParkingsCount, parkingsCountByBuilding, parkingsCountByCase]
  );

  const projectWoodCubature = useSelector(selectWoodCubatureByProject);
  const woodCubatureByBuilding = useSelector(selectWoodCubatureByBuilding);
  const woodCubatureByCase = useSelector(selectWoodCubatureByCase);
  const woodCubature = React.useMemo(
    () =>
      getValueForFocus(
        buildingMapFocus,
        projectWoodCubature,
        woodCubatureByBuilding,
        woodCubatureByCase
      ),
    [buildingMapFocus, projectWoodCubature, woodCubatureByBuilding, woodCubatureByCase]
  );

  if (isReportFetching) {
    return null;
  }

  return (
    <DataBarSegment className="quantitative">
      <div className="itemGroup">
        <div className={classNames('item', 'focusLabel')}>
          {getFocusLabel(buildingMapLabelledFocus, t) + '\u00A0:'}
        </div>
        <DataBarItem
          title="Nombre de logements"
          iconName="lodgmentCentered"
          value={lodgmentsCount}
        />
      </div>
      <div className="lineBreak" />
      <div className="itemGroup">
        <DataBarItem title="Nombre de stationnements" iconName="carPark" value={parkingsCount} />
        <DataBarItem
          title="Cubature bois total"
          iconName="woodCubature"
          value={woodCubature}
          unit={'m\u00B3'}
        />
        {/* <DataBarItem
          title="Poids carbone total"
          iconName="co2"
          value={co2Weight}
          unit={'kg\u00A0CO\u2082'}
          shortenedUnit="kg*"
        />
        <div className="item">/{'\u00A0'}</div>
        <DataBarItem
          title={'Poids carbone au m\u00B2 de SHab'}
          value={co2WeightPerSquareMeter}
          unit={'kg\u00A0CO\u2082/m\u00B2'}
          shortenedUnit={'kg/m\u00B2*'}
        /> */}
      </div>
    </DataBarSegment>
  );
};
