import { getSectionName, Section } from './Section';
import { CirculationName } from './circulationSections/CirculationSection';
import { StairsShaftSection } from './circulationSections/stairsShaft';
import { StairsShaftInfraSection } from './circulationSections/stairsShaftInfra';
import { ElevatorShaftSection } from './circulationSections/elevatorShaft';
import { ElevatorShaftInfraSection } from './circulationSections/elevatorShaftInfra';
import { VentilationGroupInfraSection } from './circulationSections/ventilationGroupInfra';
import { RampSection } from './circulationSections/ramp';
import { RampInfraSection } from './circulationSections/rampInfra';
import { SmokeExtractionDuctsSection } from './circulationSections/smokeExtractionDucts';
import { SmokeExtractionDuctsInfraSection } from './circulationSections/smokeExtractionDuctsInfra';

export type GroupedSection =
  | StairsShaftSection
  | StairsShaftInfraSection
  | ElevatorShaftSection
  | ElevatorShaftInfraSection
  | SmokeExtractionDuctsSection
  | SmokeExtractionDuctsInfraSection
  | VentilationGroupInfraSection
  | RampSection
  | RampInfraSection;

export const isSectionAGroupedSection = (section: Section): section is GroupedSection =>
  getSectionName(section) === CirculationName.StairsShaft ||
  getSectionName(section) === CirculationName.StairsShaftInfra ||
  getSectionName(section) === CirculationName.ElevatorShaft ||
  getSectionName(section) === CirculationName.ElevatorShaftInfra ||
  getSectionName(section) === CirculationName.SmokeExtractionDucts ||
  getSectionName(section) === CirculationName.SmokeExtractionDuctsInfra ||
  getSectionName(section) === CirculationName.VentilationGroupInfra ||
  getSectionName(section) === CirculationName.Ramp ||
  getSectionName(section) === CirculationName.RampInfra;
