import { DrawCreateEvent } from '@mapbox/mapbox-gl-draw';
import { resizePolygon } from '../utils/polygon/resizePolygon';
import { FireEvents } from '../types';
import { DeleteDrawPayload, ResizeEventPayload } from '../events';
import { DrawRepo, FeaturePolygonWithId } from './draw.repo';
import { DrawHelperServices } from '../drawHelper/drawHelper.services';
import { translateCoordinatesToNewCenter } from '../utils/polygon/translatePolygonOnPoint';
import { getCenter } from '../utils/geometry/getCenter';
import { createPolygon } from '../utils/polygon/polygon';
import { InitializeLayers } from '../../../store/selectors/map/initializeLayers.selector';
import { LevelGeometry } from './levelGeometry';
import { generateLevelGeometry } from './draw.model';
import { LevelId } from '../../../domain/granulometry/levels/LevelGranulometry';
import { CaseId } from '../../../domain/specification/cases/CaseSpecification';
import { uniqId } from '../../../infra/uniqId';
import { LayerRepo } from '../layer/layer.repo';
import { LevelLayer } from '../layer/layer.model';
import { removeAngleCircles } from '../styles/draw/circles.style';

type DrawServiceMode = 'top' | 'basement';
export class DrawServices {
  drawRepo: DrawRepo;
  drawHelperServices: DrawHelperServices;
  mode: DrawServiceMode;
  layerRepo: LayerRepo;

  constructor(
    drawRepo: DrawRepo,
    drawHelperServices: DrawHelperServices,
    mapElementsToDraw: InitializeLayers,
    layerRepo: LayerRepo
  ) {
    this.drawRepo = drawRepo;
    this.drawHelperServices = drawHelperServices;
    this.mode = 'top';
    this.layerRepo = layerRepo; // TODO: injecter Drawservices dans layerRepo et non l'inverse, donnée toutes les responsabilités à layerRepo et laisser draw services ne gérer que les dessins

    this.load(mapElementsToDraw);
  }

  load(mapElementsToDraw: InitializeLayers) {
    if (!mapElementsToDraw) return;
    if (mapElementsToDraw.layers) {
      const levels = this.layerRepo.findByType('level');
      const levelLayerWithGeometry = levels.filter((l) => !!l.draw?.geometry);
      if (levelLayerWithGeometry.length > 0) {
        this.drawRepo.draw.add({
          type: 'FeatureCollection',
          features: levelLayerWithGeometry.map((l) => l.draw) as LevelGeometry[]
        });
        const selectIds = levelLayerWithGeometry
          ?.filter((level) => level.selected)
          .map((l) => l.id);

        this.layerRepo.selectMultiple(selectIds);
        this.select(selectIds);

        const lockedFeatures = levelLayerWithGeometry.filter(
          (level) => level.draw.properties.shape?.lock
        );
        if (lockedFeatures.length > 0) {
          lockedFeatures.forEach((level) => this.lockFeature(level.id));
        }
        levelLayerWithGeometry.forEach((level) => {
          if (!level?.id) return;
          this.drawRepo.updateMarkerFacades(level.draw);
        });
      }
    }
  }

  get currentMode() {
    return this.mode;
  }

  set currentMode(mode: DrawServiceMode) {
    this.mode = mode;
  }

  unselectAll() {
    this.drawRepo.draw.changeMode('simple_select', { featureIds: [] });
  }

  select(ids: LevelId[]): string[] {
    const selectedLevels = this.drawRepo.draw.getSelectedIds() || [];

    if (selectedLevels.includes(ids[0])) {
      this.unselectAll();
      this.drawRepo.draw.changeMode('direct_select', { featureId: ids[0] });
    } else {
      this.unselectAll();
      this.drawRepo.draw.changeMode('simple_select', { featureIds: ids });
    }

    return ids;
  }

  create(e: DrawCreateEvent): LevelGeometry {
    const pendingDrawHelper = this.drawHelperServices.findPendingDrawHelper();
    const polygon = e.features[0] as FeaturePolygonWithId;
    if (pendingDrawHelper) {
      polygon.properties.drawHelper = this.drawHelperServices.linkWithFeature(
        pendingDrawHelper,
        polygon
      );
    }

    const parent =
      this.layerRepo.getSelectedByType('case')?.[0] ?? this.layerRepo.findByType('case')?.[0];
    if (!parent) throw new Error('Aucun parent sélectionné');

    let levelGeometry;

    const selectedLevel = this.layerRepo.getSelectedByType('level')?.[0] as LevelLayer;
    if (selectedLevel && !selectedLevel.draw) {
      const levelParent = this.layerRepo.findById(selectedLevel.parent as LevelId);
      if (!levelParent) throw new Error('Aucun parent sélectionné');
      levelGeometry = generateLevelGeometry(polygon, {
        parent: levelParent,
        floor: selectedLevel?.properties?.floor
      });
    } else {
      levelGeometry = generateLevelGeometry(polygon, {
        parent,
        floor: this.getFloorNumber(parent.id)
      });
    }
    this.drawRepo.saveOrUpdate(levelGeometry);

    this.mode === 'basement' &&
      this.drawRepo.draw.setFeatureProperty(levelGeometry.id, 'basement', true);

    return levelGeometry;
  }

  getFloorNumber(parentId: CaseId): number {
    const brothers = this.getBrothers(parentId);
    const floors = brothers.map((b) => b.properties.floor);
    if (this.mode === 'basement') {
      const filteredFloors = floors.filter((f) => f < 0).map((f) => Math.abs(f));
      filteredFloors.sort((a, b) => a - b);
      const firstFloor = filteredFloors[0] > 1 ? 1 : filteredFloors[0];
      for (
        let i = { floor: firstFloor, it: 0 };
        i.floor <= filteredFloors.length;
        { floor: i.floor++, it: i.it++ }
      ) {
        if (i.floor !== filteredFloors[i.it]) {
          return -i.floor;
        }
      }
      return -(filteredFloors.length + 1) || -1;
    } else {
      floors.sort((a, b) => a - b);
      const firstFloor = floors[0] > 0 ? 0 : floors[0];
      for (
        let i = { floor: firstFloor, it: 0 };
        i.floor < floors.length;
        { floor: i.floor++, it: i.it++ }
      ) {
        if (i.floor !== floors[i.it]) {
          return i.floor;
        }
      }
      return floors.length || 0;
    }
  }

  duplicate(id: LevelId) {
    const toDuplicate = this.drawRepo.draw.get(id) as LevelGeometry;
    if (toDuplicate.properties.floor < 0) {
      this.mode = 'basement';
    } else {
      this.mode = 'top';
    }
    const parent = this.layerRepo.findById(toDuplicate.properties.parentCaseId);
    if (!parent) throw new Error('Aucun parent trouvé');
    toDuplicate.properties.floor = this.getFloorNumber(parent.id);
    toDuplicate.properties.name = parent.title + 'L' + toDuplicate.properties.floor;
    toDuplicate.id = uniqId();
    this.drawRepo.saveOrUpdate(toDuplicate);
    this.select([toDuplicate.id]);
    return toDuplicate;
  }

  delete({ features, drawHelperMode }: DeleteDrawPayload) {
    const draw = features[0] as LevelGeometry;

    this.drawRepo.removeFacade([draw]);

    this.drawRepo.draw.delete(draw.id);

    if (this.drawHelperServices.isLinkedFeature(draw)) {
      drawHelperMode && this.drawHelperServices.unLinkFeature(draw.id);
      !drawHelperMode && this.drawHelperServices.deleteByFeatureId(draw.id);
    }
    return draw;
  }

  update(features: LevelGeometry[]) {
    if (!features || !features.length) return;
    return (features as LevelGeometry[]).map((levelGeometry) => {
      if (!levelGeometry) {
        throw new Error('No polygon');
      }
      const parentId = levelGeometry.properties.parentCaseId;
      if (!parentId) throw new Error("Le niveau n'est associé à aucune cage");
      const parent = this.layerRepo.findById(parentId);
      if (!parent) throw new Error('Aucun parent trouvé');

      const newLevelGeometry = generateLevelGeometry(levelGeometry, {
        parent,
        floor: levelGeometry.properties.floor
      });

      this.drawRepo.updateProperties(newLevelGeometry);
      this.layerRepo.update(levelGeometry.id, {
        ...this.layerRepo.formatLevelGeometryToLayer(newLevelGeometry),
        selected: true
      });
      return newLevelGeometry;
    });
  }

  lockFeature(featureId: string) {
    removeAngleCircles(featureId, (layer: string, filter?: any[]) =>
      this.drawRepo.map.setFilter(layer, filter)
    );
    this.drawRepo.draw.setFeatureProperty(featureId, 'deactivate', true);
  }

  unLockFeature(featureId: string) {
    this.drawRepo.draw.setFeatureProperty(featureId, 'deactivate', false);
  }

  resizeDraw({ payload }: FireEvents<ResizeEventPayload>) {
    if (!payload.selectedFeatures || payload.selectedFeatures?.features.length === 0)
      throw new Error('Aucun polygon sélectionné');
    const polygon = payload.selectedFeatures.features[0] as FeaturePolygonWithId;
    const coordinates = polygon.geometry?.coordinates[0];
    const { coordinates: resizedCoordinates, ratio } = resizePolygon(coordinates, payload.size);
    const movedPolygon = translateCoordinatesToNewCenter(
      resizedCoordinates,
      getCenter(coordinates)
    );
    const parent = this.layerRepo.findById(polygon.properties.parentCaseId);
    if (!parent) throw new Error('Aucun parent trouvé');
    const feature = generateLevelGeometry(
      {
        ...polygon,
        geometry: createPolygon(movedPolygon)
      },
      { parent, floor: polygon.properties.floor }
    );

    this.drawRepo.reDraw(feature);

    if (this.drawHelperServices.isLinkedFeature(feature)) {
      this.drawHelperServices.resize(ratio, feature);
    }
    return feature;
  }

  updateAllDraw() {
    const featureCollection = this.drawRepo.findAll();
    featureCollection.features.forEach((feature) => {
      if (!feature.id) return;
      this.drawRepo.updateProperties(feature);
    });
  }

  getBrothers(parentId: CaseId) {
    const brothers = this.drawRepo.draw.getAll();
    return (
      (brothers.features as LevelGeometry[]).filter(
        (b) => b.properties?.parentCaseId === parentId
      ) || []
    );
  }
}
