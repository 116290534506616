import { getLevelNotGroupedSectionsSurface } from '../../domain/granulometry/levels/queries/surfaces/getLevelNotGroupedSectionsSurface';
import { MINIMUM_OUTSIDE_RULER_WIDTH, SQUARE_METER_SCALE } from '../../constants/appConstants';
import { getLevelVerticalCirculationSectionsSurface } from '../../domain/granulometry/levels/queries/surfaces/getLevelVerticalCirculationSectionsSurface';
import { isThisLevelSectionWrapped } from './isThisLevelSectionWrapped';
import { LevelGranulometry } from '../../domain/granulometry/levels/LevelGranulometry';

export const manageLevelOutsideRuler = (
  levelGranulometry: LevelGranulometry,
  prevLevel: LevelGranulometry
) => {
  if (levelGranulometry.surface > prevLevel.surface) {
    const outsideSurface = levelGranulometry.surface - prevLevel.surface;
    let rulerWidth;
    if (isThisLevelSectionWrapped(prevLevel)) {
      rulerWidth =
        (levelGranulometry.sectionWrapper ? levelGranulometry.sectionWrapper.realWidth : 0) -
        (prevLevel.sectionWrapper ? prevLevel.sectionWrapper.realWidth : 0);
    } else if (isThisLevelSectionWrapped(levelGranulometry)) {
      rulerWidth =
        (levelGranulometry.surface - getLevelNotGroupedSectionsSurface(levelGranulometry).value) *
          SQUARE_METER_SCALE +
        (levelGranulometry.sectionWrapper ? levelGranulometry.sectionWrapper.realWidth : 0) -
        prevLevel.surface * SQUARE_METER_SCALE;
    } else {
      rulerWidth = outsideSurface * SQUARE_METER_SCALE;
    }
    if (rulerWidth < MINIMUM_OUTSIDE_RULER_WIDTH) {
      rulerWidth = MINIMUM_OUTSIDE_RULER_WIDTH;
    }
    let rulerLeft;
    if (isThisLevelSectionWrapped(prevLevel)) {
      rulerLeft =
        getLevelVerticalCirculationSectionsSurface(prevLevel) * SQUARE_METER_SCALE +
        (prevLevel.sectionWrapper ? prevLevel.sectionWrapper.realWidth : 0);
    } else {
      rulerLeft = prevLevel.surface * SQUARE_METER_SCALE;
    }
    levelGranulometry.outsideRulerWidth = rulerWidth;
    levelGranulometry.outsideRulerLeft = rulerLeft;
  }
};
