import * as React from 'react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { CaseEditingStageName } from '../../../../../domain/specification/cases/CaseSpecification';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { selectCurrentCaseCustomDistribution } from '../../../../../store/selectors/specification/currentCase/customDistribution.selector';
import { selectCurrentCaseEditingStage } from '../../../../../store/selectors/specification/currentCase/editingStage.selector';
import { selectCurrentCaseLodgmentsCountByLevelAndType } from '../../../../../store/selectors/granulometry/currentCase/lodgmentsCountByTopLevelAndType.selector';
import { selectCurrentCaseLodgmentsTypesSpecifications } from '../../../../../store/selectors/specification/currentCase/lodgementsTypesSpecifications.selector';
import { Input } from '../../../../ui/input/Input';
import { TopLevelSpecification } from '../../../../../domain/specification/levels/TopLevelSpecification';

interface Column3Props {
  topLevelSpecification: TopLevelSpecification;
}

export const Column3 = ({ topLevelSpecification }: Column3Props) => {
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const lodgmentsCountByTopLevelAndType = useSelector(
    selectCurrentCaseLodgmentsCountByLevelAndType
  );
  const lodgmentsTypesSpecifications = useSelector(selectCurrentCaseLodgmentsTypesSpecifications);
  const customDistribution = useSelector(selectCurrentCaseCustomDistribution);
  const editingStage = useSelector(selectCurrentCaseEditingStage);

  if (!caseLabel || !lodgmentsCountByTopLevelAndType || !lodgmentsTypesSpecifications) return null;

  const lodgmentsCountForLevelByType =
    process.env.GB_FEAT_CUSTOM_DISTRIBUTION !== 'false' &&
    editingStage === CaseEditingStageName.Distribution
      ? R.mergeRight(
          lodgmentsCountByTopLevelAndType[topLevelSpecification.level],
          customDistribution[topLevelSpecification.level]
        )
      : lodgmentsCountByTopLevelAndType[topLevelSpecification.level];

  const lodgmentsCountForLevel = lodgmentsCountForLevelByType
    ? R.values(lodgmentsCountForLevelByType).reduce(R.add, 0)
    : 0;

  return (
    <div className="column-3">
      <Input
        name={`${caseLabel}TopLevel${topLevelSpecification.level}LodgmentCount`}
        type="number"
        size="small"
        disabled
        value={lodgmentsCountForLevel || 0}
      />
    </div>
  );
};
