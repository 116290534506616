import { CaseId } from '../../../../../domain/specification/cases/CaseSpecification';
import { LodgmentType } from '../../../../../domain/specification/lodgmentTypes/LodgmentType';
import { Action } from 'redux';
import { push } from 'connected-react-router';
import { caseGranulometryEditLodgmentTypeRoute } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditLodgmentTypeRoute';

export const goToCaseGranulometryEditLodgmentType = (
  projectId: string,
  caseId: CaseId,
  lodgmentType: LodgmentType
): Action =>
  push(
    caseGranulometryEditLodgmentTypeRoute.makePath({
      projectId,
      caseId,
      lodgmentType
    })
  );
