import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../../LevelGranulometry';
import {
  isSectionAStairsShaft,
  StairsShaftSection
} from '../../../../../sections/circulationSections/stairsShaft';
import { mustHaveStairShaftSections } from '../../../mustHave/mustHaveStairShaftSections';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelInsideWallsThickness } from '../wallsThickness/getTopLevelInsideWallsThickness';
import { getLevelWithBearingRelativeSectionsContent } from '../../../content/getLevelWithBearingRelativeSectionsContent';

export const getTopLevelStairsShafWallsSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry | LevelGranulometryWithBearingRelativeSections
): number =>
  mustHaveStairShaftSections(caseGranulometry)
    ? getTopLevelInsideWallsThickness(caseGranulometry) *
      (
        getLevelWithBearingRelativeSectionsContent(levelGranulometry).filter(
          isSectionAStairsShaft
        ) as StairsShaftSection[]
      ).reduce(
        (acc, stairsShaftSection) =>
          acc + (stairsShaftSection.length + stairsShaftSection.width) * 2,
        0
      )
    : 0;
