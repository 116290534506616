import { identity, Lazy, pipe } from 'fp-ts/function';
import {
  chain,
  map,
  mapLeft,
  TaskEither,
  traverseSeqArrayWithIndex,
  tryCatch
} from 'fp-ts/TaskEither';
import { ExistingProject } from '../../domain/project/Project';
import { Granulometry } from '../../domain/granulometry/Granulometry';
import { User } from '../../domain/User';
import { Report, ReportEntry } from '../../domain/report/ReportEntry';
import { LOTS_NOMENCLATURE_ID } from '../../domain/Nomenclature';
import { quantify } from '../v2/quantify.api';
import { ApiAuthError } from './errors/auth.error';
import { ApiRedirectError } from './errors/redirect.error';
import { fetchJSONReport } from './fetchReport.api';
import { sendGranulometry } from './sendGranulometry.api';
import { handleUnknownError } from '../../utils/handleUnknownError';
import { AuthToken } from '../../AuthToken';

const makeApiCall = <T>(fn: Lazy<Promise<T>>): TaskEither<Error, T> =>
  pipe(
    tryCatch(fn, handleUnknownError),
    mapLeft((error: Error) =>
      error instanceof ApiRedirectError ? ApiAuthError.fromRedirectError(error) : error
    )
  );

/**
 * La génération du rapport se fait sur l'admin V1 en POSTant d'abord _sequentiellement_ la granulométrie de chaque batiment,
 * puis en récupérant par un GET le rapport au travers du endpoint Calculations.
 *
 * Attention, envoyer les granulométries des différents bâtiments en parallèle provoque des crash ou pire des rapports incomplets.
 *
 * @param project Projet à envoyer
 * @param granulometry Granulométrie
 * @param userId Identifiant utilisateur
 * @param userToken Token utilisateur
 * @param tryFileDownload Activation de la recherche d’un rapport existant
 * @return un TaskEither du rapport (V1 et V2 sont fusionnés)
 */

const doNothing = async (): Promise<void> => {
  await new Promise((resolve) => resolve());
};

export const generateReport = (
  project: ExistingProject,
  granulometry: Granulometry,
  userId?: User['id'],
  userToken?: AuthToken,
  tryFileDownload?: boolean
): TaskEither<Error, Report> =>
  pipe(
    granulometry,
    traverseSeqArrayWithIndex((index, buildingGranulometry) =>
      makeApiCall(() =>
        // TODO : if fetchCalculationFilesExists = true doNothing
        !tryFileDownload
          ? sendGranulometry(project, buildingGranulometry, index, userId, userToken)
          : doNothing()
      )
    ),
    chain(() =>
      makeApiCall(() =>
        fetchJSONReport(project.id, LOTS_NOMENCLATURE_ID, userId, userToken, tryFileDownload)
      )
    ),
    process.env.GB_BACKEND_PREVIEW !== 'false'
      ? chain((reportV1: ReportEntry[]) =>
          pipe(
            makeApiCall(() => quantify(granulometry)),
            map((reportV2) => [...reportV1, ...reportV2])
          )
        )
      : identity
  );
