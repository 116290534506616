import { ComEthReport } from './comEthReport/ComEthReport';

interface JsonReceivedFromApi {
  state: 'PENDING' | 'PROGRESS' | 'SUCCESS';
  info: null | { step: string } | Promise<ComEthReport>;
}

export const getComEthReportFromCstbApi = (taskId: string): Promise<ComEthReport> =>
  fetch(process.env.GB_CSTB_API_URL + '/status/' + taskId, {
    method: 'GET'
  })
    .then((response) => response.json() as Promise<JsonReceivedFromApi>)
    .then(
      (taskId) =>
        new Promise<JsonReceivedFromApi>((resolve) => setTimeout(() => resolve(taskId), 1500))
    )
    .then((json: JsonReceivedFromApi) => {
      if (process.env.GB_ENV === 'dev' || process.env.GB_ENV === 'recette') {
        // eslint-disable-next-line no-console
        console.info('CSTB API – json', json);
        console.info('CSTB API – STATE', json.state);
      }
      if (json.state !== 'SUCCESS') {
        if (
          json.info !== undefined &&
          json.info !== null &&
          (process.env.GB_ENV === 'dev' || process.env.GB_ENV === 'recette')
        ) {
          // eslint-disable-next-line no-console
          console.info('CSTB API – INFO STEP', (json.info as { step: string }).step);
        }
        return getComEthReportFromCstbApi(taskId);
      } else {
        return json.info as Promise<ComEthReport>;
      }
    });
