import mapbox from './config/mapboxgl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Dispatch } from 'redux';
import { initZoom } from './config/handleZoom';
import { InitializeLayers } from '../../store/selectors/map/initializeLayers.selector';
import getCloserFeatureFromPoint from './utils/geometry/getCloserFeatureFromPoint';
import { selectParcel } from './domain/parcel/selectParcel';
import { events } from './events';
import { Feature, Polygon } from 'geojson';
import * as React from 'react';
import { addOrRemoveParcelAction } from '../../store/actions/parcels.actions';
import { getMapboxDrawOptions, getMapboxOptions } from './config/options';
import { Position } from '@turf/helpers/dist/js/lib/geojson';
import { initMapControl } from './control';
import mapboxgl from 'mapbox-gl';
import { drawHelperEvents } from './drawHelper/drawHelper.events';
import { DrawRepo } from './mapboxDraw/draw.repo';
import { MapRepository } from './map/map.repository';
import { MapServices } from './map/map.services';
import { DrawHelperServices } from './drawHelper/drawHelper.services';
import { DrawServices } from './mapboxDraw/draw.services';
import { getCustomModes } from './mode';
import initKeyboardControl from './control/initKeyboardControl';
import { LayerRepo } from './layer/layer.repo';

const initClasses = (
  map: mapboxgl.Map,
  draw: MapboxDraw,
  dispatch: Dispatch,
  mapElementsToDraw: InitializeLayers,
  projectId: string
) => {
  const drawRepo = new DrawRepo(map, draw);
  const layerRepo = new LayerRepo(mapElementsToDraw.layers);

  const mapRepo = new MapRepository(map);
  const mapServices = new MapServices(mapRepo);
  const drawHelperServices = new DrawHelperServices(mapServices, drawRepo);

  const drawServices = new DrawServices(drawRepo, drawHelperServices, mapElementsToDraw, layerRepo);

  drawHelperEvents(map, drawHelperServices);
  events(map, drawServices, dispatch, layerRepo, projectId);
  return { drawServices, mapServices, layerRepo };
};

export const initMapBox = (
  mapContainer: React.MutableRefObject<HTMLDivElement>,
  location: Position,
  dispatch: Dispatch,
  mapElementsToDraw: InitializeLayers,
  caseLabel: string | undefined,
  projectId: string
) => {
  return new Promise<void>((resolve, reject) => {
    try {
      const map =
        location && (new mapbox.Map(getMapboxOptions(mapContainer, location)) as mapboxgl.Map);

      const modes = getCustomModes(MapboxDraw.modes);
      const draw: MapboxDraw = new MapboxDraw(getMapboxDrawOptions(modes, caseLabel));
      initMapControl(map, draw);

      map.once('load', () => {
        const { mapServices, drawServices, layerRepo } = initClasses(
          map,
          draw,
          dispatch,
          mapElementsToDraw,
          projectId
        );
        initKeyboardControl(map, drawServices, dispatch, mapServices);
        initZoom(map);

        if (!mapElementsToDraw.isMapInitialized) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          map.once('sourcedata', 'parcelsFillLayer', (e: { features: Feature<Polygon>[] }) => {
            map.addSource('mapbox-dem', {
              type: 'raster-dem',
              url: 'mapbox://mapbox.terrain-rgb',
              tileSize: 512,
              maxzoom: 14
            });
            map.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
            const closerParcel = getCloserFeatureFromPoint(e.features, location);
            dispatch(addOrRemoveParcelAction(selectParcel(map, closerParcel, mapServices)));
          });
        } else {
          mapElementsToDraw.parcels && mapServices.loadParcels(mapElementsToDraw.parcels);
        }
        resolve({ map, draw, drawServices, layerRepo });
      });
    } catch (e) {
      reject(e);
    }
  });
};
