import { AdminReportEntry } from './ReportEntry';
import { Nomenclature } from '../Nomenclature';

export const sortReportByRef = (
  report: AdminReportEntry[] | undefined,
  nomenclature: Nomenclature | undefined
): AdminReportEntry[] | undefined => {
  if (!report || !nomenclature) {
    return undefined;
  } else {
    const refN = ('refN' + nomenclature.id) as 'refN1' | 'refN2';
    return [...report].sort((a, b) => {
      const aRefN = a[refN]
        .split('.')
        .reduce((acc, segment) => acc + ('0' + segment).slice(-2), '');
      const bRefN = b[refN]
        .split('.')
        .reduce((acc, segment) => acc + ('0' + segment).slice(-2), '');
      if (aRefN < bRefN) {
        return -1;
      }
      if (aRefN > bRefN) {
        return 1;
      }
      return 0;
    });
  }
};
