import * as React from 'react';

interface ActionModalProps {
  title?: string | React.ReactNode;
  content: string | React.ReactNode;
  buttons?: React.ReactNode[];
}
export const ActionModal = ({ title, content, buttons }: ActionModalProps) => (
  <>
    <div className="actionModalMask"></div>
    <div className="actionModal">
      <div className="inner">
        {title && <div className="title">{title}</div>}
        <div className="content">{content}</div>
        {buttons && <div className="buttons">{buttons}</div>}
      </div>
    </div>
  </>
);
