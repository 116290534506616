import { createSelector, Selector } from 'reselect';
import { State } from '../../../store/reducers';
import { Maybe } from '../../../utils/Maybe';

import { getGranulometryState } from '../../reducers';
import { Granulometry } from '../../../domain/granulometry/Granulometry';

export const selectGranulometry: Selector<State, Maybe<Granulometry>> = createSelector(
  [getGranulometryState],
  (granulometryState) => granulometryState.current
);
