import { FeatureParcel } from '../../domain/Parcel';

const prefix = 'project/';

export const ADD_OR_REMOVE_PARCEL = prefix + 'remove_parcel';
export const addOrRemoveParcelAction = (payload: FeatureParcel) => ({
  type: ADD_OR_REMOVE_PARCEL,
  payload
});
export type RemoveParcelAction = ReturnType<typeof addOrRemoveParcelAction>;
