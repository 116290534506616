import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import {
  getBuildingHighestLevel,
  getCaseHighestLevel
} from '../../../domain/buildingMap/BuildingMap';
import { BuildingGranulometry } from '../../../domain/granulometry/buildings/BuildingGranulometry';
import { LevelGranulometry } from '../../../domain/granulometry/levels/LevelGranulometry';
import { Granulometry } from '../../../domain/granulometry/Granulometry';
import { selectGranulometry } from 'store/selectors/granulometry/current.selector';
import { BuildingMapLabelViewController } from './label/BuildingMapLabelViewController';
import { BuildingMapBuildingView } from './building/BuildingMapBuildingView';
import { Grounds } from './Grounds';
import { GroundLines } from './GroundLines';
import { selectIsInitializeProject } from '../../../store/selectors/project/isInitializeProject.selector';
import { buildingMapFocused } from '../../../store/actions/buildingMap/buildingMapFocused.action';
import { getBuildingMapWithSectionWrapperGroupsAndOutsideRuler } from '../../../utils/buildingMap/getBuildingMapWithSectionWrapperGroupsAndOutsideRuler';
import { CaseGranulometry } from '../../../domain/granulometry/cases/CaseGranulometry';

const hardRocksDepth = 9;
const softRocksDepth = 2.5;
const waterDepth = 8;

interface BuildingMapProps {
  refreshFocus: () => void;
  reMoveOn: () => void;
}

export const BuildingMap = ({ refreshFocus, reMoveOn }: BuildingMapProps) => {
  const granulometry = useSelector(selectGranulometry);
  const isInitializedProject = useSelector(selectIsInitializeProject);

  const [buildingsElement, setBuildingsElement] = React.useState<Element | undefined>(undefined);
  const [buildingsWidth, setBuildingsWidth] = React.useState(0);

  const [firstCaseLevelsRefs, setFirstCaseLevelsRefs] = React.useState(
    new Map<number, React.RefObject<HTMLDivElement>>()
  );
  React.useEffect(() => {
    if (granulometry?.length) {
      setFirstCaseLevelsRefs((currentRefs) => {
        const map = new Map<number, React.RefObject<HTMLDivElement>>();
        granulometry[0].cases[0].levels.forEach((l) => {
          map.set(l.level, currentRefs.get(l.level) || React.createRef<HTMLDivElement>());
        });
        return map;
      });
    }
  }, [granulometry]);

  const highestBuilding: BuildingGranulometry | undefined = granulometry
    ? R.pipe<[BuildingGranulometry[]], BuildingGranulometry[], BuildingGranulometry>(
        R.sortBy(getBuildingHighestLevel),
        R.last
      )(granulometry)
    : undefined;

  const highestCase: CaseGranulometry | undefined =
    highestBuilding &&
    (R.pipe<[BuildingGranulometry], CaseGranulometry[], CaseGranulometry[], CaseGranulometry>(
      R.prop('cases'),
      R.sortBy(getCaseHighestLevel),
      R.last
    )(highestBuilding) as CaseGranulometry);

  const [highestCaseLevelsRefs, setHighestCaseLevelsRefs] = React.useState(
    new Map<number, React.RefObject<HTMLDivElement>>()
  );
  React.useEffect(() => {
    if (granulometry?.length) {
      setHighestCaseLevelsRefs((currentRefs) => {
        const map = new Map<number, React.RefObject<HTMLDivElement>>();

        R.pipe<
          [Granulometry],
          BuildingGranulometry[],
          BuildingGranulometry,
          CaseGranulometry[],
          CaseGranulometry[],
          CaseGranulometry,
          LevelGranulometry[],
          void
        >(
          R.sortBy(getBuildingHighestLevel),
          R.last,
          R.prop('cases'),
          R.sortBy(getCaseHighestLevel),
          R.last,
          R.prop('levels'),
          R.forEach<LevelGranulometry>((l) => {
            map.set(l.level, currentRefs.get(l.level) || React.createRef<HTMLDivElement>());
          })
        )(granulometry);
        return map;
      });
    }
  }, [granulometry]);

  const hardRockRef = React.useRef<HTMLDivElement | null>();
  const softRockRef = React.useRef<HTMLDivElement | null>();
  const waterLineRef = React.useRef<HTMLDivElement | null>();

  const buildingsRefCallback = React.useCallback(
    (node?: Element | null) => {
      if (node) {
        setBuildingsElement(node);
      }
    },
    [setBuildingsElement]
  );

  React.useLayoutEffect(() => {
    if (buildingsElement) {
      const updatedBuildingWidth = parseFloat(getComputedStyle(buildingsElement).width || '0');
      if (buildingsWidth !== updatedBuildingWidth) {
        setBuildingsWidth(updatedBuildingWidth);
      }
    }
  });

  React.useLayoutEffect(() => {
    reMoveOn();
    refreshFocus();
  }, [granulometry]);

  const leftLevelLabelsStyle: any = {};
  const rightLevelLabelsStyle: any = {};

  leftLevelLabelsStyle.left = 20000 - buildingsWidth / 2 - 100 + 'px';
  rightLevelLabelsStyle.marginLeft = 20000 + buildingsWidth / 2 + 20 + 'px';

  const groundLevelLabelsStyle: any = {};
  groundLevelLabelsStyle.marginLeft = 20000 + buildingsWidth / 2 + 100 + 'px';

  const dispatch = useDispatch();
  const focusOnProject = React.useCallback(
    () => dispatch(buildingMapFocused('project')),
    [dispatch]
  );
  if (!granulometry?.length || !isInitializedProject) {
    return (
      <>
        <Grounds hardRocksDepth={hardRocksDepth} softRocksDepth={softRocksDepth} />
        <GroundLines
          hardRocksDepth={hardRocksDepth}
          softRocksDepth={softRocksDepth}
          waterDepth={waterDepth}
        />
        <div className="line horizonLine" />
      </>
    );
  }

  const buildings: BuildingGranulometry[] =
    getBuildingMapWithSectionWrapperGroupsAndOutsideRuler(granulometry);

  const highestLevel = buildings.reduce((highest, building) => {
    const buildingHighest = getBuildingHighestLevel(building);
    return buildingHighest > highest ? buildingHighest : highest;
  }, 0);

  const highestBuildingIndex: number = R.findIndex(
    (b: BuildingGranulometry) => getBuildingHighestLevel(b) === highestLevel
  )(buildings);

  const firstCaseIsHighest = granulometry[0].cases[0] === highestCase;

  const rightLabelsRefs = firstCaseIsHighest ? firstCaseLevelsRefs : highestCaseLevelsRefs;

  return (
    <>
      <Grounds hardRocksDepth={hardRocksDepth} softRocksDepth={softRocksDepth} />
      <div className="buildings" ref={buildingsRefCallback}>
        {buildings.map((element, index) => (
          <BuildingMapBuildingView
            key={element.labelIndex}
            {...element}
            firstCaseLevelsRefs={index === 0 ? firstCaseLevelsRefs : undefined}
            highestCaseLevelsRefs={
              index === highestBuildingIndex ? highestCaseLevelsRefs : undefined
            }
          />
        ))}
      </div>
      <GroundLines
        hardRocksDepth={hardRocksDepth}
        softRocksDepth={softRocksDepth}
        waterDepth={waterDepth}
        hardRockRef={hardRockRef}
        softRockRef={softRockRef}
        waterLineRef={waterLineRef}
      />
      <div className="labels rightLevelLabels" style={rightLevelLabelsStyle}>
        {rightLabelsRefs &&
          highestCase &&
          highestCase.levels
            .filter((l) => l.realBuiltSurface)
            .map(
              (level) =>
                rightLabelsRefs.get(level.level) && (
                  <BuildingMapLabelViewController
                    key={level.level}
                    type="level"
                    levelIndex={level.level}
                    levelId={level.id}
                    element={rightLabelsRefs?.get(level.level)?.current}
                    labelsRefs={rightLabelsRefs}
                  />
                )
            )}
      </div>
      <div className="labels leftLevelLabels" style={leftLevelLabelsStyle}>
        {firstCaseLevelsRefs &&
          buildings[0].cases[0].levels
            .filter((l) => l.realBuiltSurface)
            .map(
              (level: LevelGranulometry) =>
                firstCaseLevelsRefs.get(level.level) && (
                  <BuildingMapLabelViewController
                    key={level.level}
                    type="level"
                    levelIndex={level.level}
                    levelId={level.id}
                    element={firstCaseLevelsRefs?.get(level.level)?.current}
                    labelsRefs={firstCaseLevelsRefs}
                  />
                )
            )}
      </div>
      {/* <div className="labels groundLabels" style={groundLevelLabelsStyle}>
        <BuildingMapLabelViewController
          type="ground"
          level={8}
          element={hardRockRef.current}
          bgColor={HARDROCKS_COLOR}
          topPos={hardRocksDepth * LINEAR_METER_SCALE}
        />
        <BuildingMapLabelViewController
          type="ground"
          level={2.5}
          element={softRockRef.current}
          bgColor={SOFTROCKS_COLOR}
          topPos={softRocksDepth * LINEAR_METER_SCALE}
        />
        <BuildingMapLabelViewController
          type="ground"
          level={5}
          element={waterLineRef.current}
          bgColor={WATER_COLOR}
          topPos={waterDepth * LINEAR_METER_SCALE}
        />
      </div> */}
      <div className="line horizonLine" />
      <div className="mask">
        <div className="maskBackground" onClick={focusOnProject} />
      </div>
    </>
  );
};

export default BuildingMap;
