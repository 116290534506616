import { CaseId } from '../../../../../domain/specification/cases/CaseSpecification';
import { Action } from 'redux';
import { push } from 'connected-react-router';
import { caseGranulometryEditOtherPropertiesPropertyRoute } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditOtherPropertiesPropertyRoute';

export const goToCaseGranulometryEditOtherPropertiesProperty = (
  projectId: string,
  caseId: CaseId,
  propertyName: string
): Action =>
  push(
    caseGranulometryEditOtherPropertiesPropertyRoute.makePath({ projectId, caseId, propertyName })
  );
