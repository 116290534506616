import { deliverablesExportRoute } from './deliverablesExportRoute';
import { matchesPath } from '../../utils/matchesPath';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type DeliverablesRouteParams = { projectId: string };
export const deliverablesRoute: RouteWithMakePath<DeliverablesRouteParams> = {
  path: `/project/:projectId/deliverables`,
  makePath: deliverablesExportRoute.makePath,
  matchesPath: matchesPath<DeliverablesRouteParams>(/\/project\/([^/]+)\/deliverables/, {
    projectId: 1
  })
};
