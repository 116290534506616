import { ApiError } from './apiError';

export class ApiAuthError extends ApiError {
  constructor(requestedUrl: string, statusCode: number, statusText: string) {
    super(
      `Auth failed (${statusCode}) when requested ${requestedUrl}`,
      requestedUrl,
      statusCode,
      statusText
    );
  }

  public isApiAuthError = true;
}
