import { LevelGranulometry } from '../../levels/LevelGranulometry';
import { getSectionsTotalSurface } from '../../sections/queries/surfaces/getSectionsTotalSurface';
import { getLevelFullFilledContent } from '../../levels/queries/content/getLevelFullFilledContent';

export const updateBasementLevelSurface = (
  levelGranulometry: LevelGranulometry
): LevelGranulometry => {
  const newSurface = getSectionsTotalSurface(getLevelFullFilledContent(levelGranulometry));
  levelGranulometry.surface = newSurface;
  levelGranulometry.displayedSurface = newSurface;
  levelGranulometry.realBuiltSurface = newSurface;
  return levelGranulometry;
};
