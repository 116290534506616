export const getWindowWidth = () => {
  const toolbox = document.getElementsByClassName('toolbox');
  const toolboxWidth = toolbox[0]?.getBoundingClientRect().width || 0;
  const appMenu = document.getElementsByClassName('app-menu');

  let windowWidth: number = window.innerWidth;

  if (toolbox.length && !toolbox[0].classList.contains('panel-hidden')) {
    windowWidth = windowWidth - toolboxWidth;
  }
  if (appMenu.length && !appMenu[0].classList.contains('panel-hidden')) {
    windowWidth = windowWidth - toolboxWidth;
  }
  return windowWidth;
};
