import { Surface } from '../../domain/specification/Surface';

export const PROJECT_SURFACE_CHANGED = 'project/surface_changed';

export const projectSurfaceChanged = (surface: Surface | undefined) => ({
  type: PROJECT_SURFACE_CHANGED,
  payload: { surface }
});

export type ProjectSurfaceChangedAction = ReturnType<typeof projectSurfaceChanged>;
