import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';
import { roundWith4Decimal } from '../../../../../../utils/round/roundWith4Decimal';

export const getPropertyConvertedValue = (unit: string, value: number) => {
  if (unit === 'cm') {
    return roundWith2Decimal(value / 100);
  } else if (unit === 'mm') {
    return roundWith2Decimal(value / 1000);
  } else if (unit === '%') {
    return roundWith4Decimal(value / 100);
  } else {
    return roundWith2Decimal(value);
  }
};
