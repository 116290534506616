import { Surface } from '../../../../specification/Surface';
import { CaseGranulometry } from '../../CaseGranulometry';
import { getTopLevelGrossFloorSurfaceEff } from '../../../levels/queries/topLevels/surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurfaceEff';
import { getCaseTopLevels } from '../levels/getCaseTopLevels';

export const getCaseGrossFloorSurfaceEff = (
  caseGranulometry: CaseGranulometry,
  forceCalculationBasedOnRBS?: boolean
): Surface =>
  new Surface(
    getCaseTopLevels(caseGranulometry).reduce(
      (acc, levelGranulometry) =>
        acc +
        getTopLevelGrossFloorSurfaceEff(
          caseGranulometry,
          levelGranulometry,
          forceCalculationBasedOnRBS
        ),
      0
    )
  );
