import { RoofingType } from '../../../../specification/cases/RoofingType';
import { createLevelRoofingSection } from '../sections/create/createLevelRoofingSection';
import { LevelGranulometry } from '../../LevelGranulometry';
import { getLevelId } from '../getLevelId';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import { setLevelSectionsIds } from '../sections/setLevelSectionIds';
import { getTopLevelGrossFloorSurfaceEff } from '../topLevels/surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurfaceEff';

export const createRoofingLevel = (
  caseGranulometry: CaseGranulometry,
  highestLevelGranulometry: LevelGranulometry,
  roofingType: RoofingType
): LevelGranulometry => {
  const highestLevelGfsEff = getTopLevelGrossFloorSurfaceEff(
    caseGranulometry,
    highestLevelGranulometry
  );
  return setLevelSectionsIds(caseGranulometry, {
    id: getLevelId(caseGranulometry, highestLevelGranulometry.level + 1),
    level: highestLevelGranulometry.level + 1,
    surface: highestLevelGranulometry.surface,
    displayedSurface: highestLevelGfsEff,
    ceilingHeight: roofingType === 'tileRoofing' ? 2.5 : 1,
    floorThickness: 0.3,
    wallThickness: 0,
    roofing: [
      createLevelRoofingSection(highestLevelGfsEff, highestLevelGranulometry.surface, roofingType)
    ]
  } as LevelGranulometry);
};
