import { getCasePropertyConvertedValue } from '../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { CaseGranulometry } from '../../cases/CaseGranulometry';

export const getLevelFloorThickness = (caseGranulometry: CaseGranulometry): number =>
  ((getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'topLevelConcreteSlabThickness'
  ) as number) || 0) +
  ((getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'topLevelCementScreedThickness'
  ) as number) || 0);
