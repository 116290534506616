import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '../../../../components/ui/Tooltip';

export interface BuildingMapLevelOutsideRulerProps {
  id: string;
  direction: string;
  outsideRulerWidth?: number;
  outsideRulerLeft?: number;
  outsideSurface?: number;
  outsideRulerTop?: number;
}

const BuildingMapLevelOutsideRuler: React.FC<BuildingMapLevelOutsideRulerProps> = ({
  id,
  direction,
  outsideRulerWidth,
  outsideRulerLeft,
  outsideSurface,
  outsideRulerTop
}) => {
  const { t } = useTranslation();

  // Tooltip and styles
  const outsideRulerTooltip: string =
    t('buildingMap.level.outsideRuler.tooltip') + '\u00A0: ' + outsideSurface + '\u00A0m\u00B2';
  const outsideRulerStyle: React.CSSProperties = {
    width: outsideRulerWidth + 'px',
    top: outsideRulerTop + 'px'
  };
  if (direction === 'ltr') outsideRulerStyle.left = outsideRulerLeft + 'px';
  if (direction === 'rtl') outsideRulerStyle.right = outsideRulerLeft + 'px';

  return (
    <div
      id="surfaceRulerOutside"
      className="surfaceRuler surfaceRulerOutside tooltipped"
      style={outsideRulerStyle}>
      <Tooltip>{outsideRulerTooltip}</Tooltip>
    </div>
  );
};

export default BuildingMapLevelOutsideRuler;
