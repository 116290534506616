import { createSelector, Selector } from 'reselect';
import { Maybe } from '../../../../utils/Maybe';
import { Panel } from '../../../../interfaces/Panel';
import { buildingsRoute } from '../../../../routes/toolbox/buildingsPanel/buildingsRoute';
import { sectionsRoute } from '../../../../routes/toolbox/sectionsPanel/sectionsRoute';
import { State } from '../../../reducers';
import { selectPath } from '../getPath.selector';
import { casesRoute } from '../../../../routes/toolbox/casesPanel/casesRoute';
import { deliverablesRoute } from '../../../../routes/toolbox/deliverablesPanel/deliverablesRoute';
import { projectDetailsRoute } from '../../../../routes/toolbox/projectPanel/projectDetailsRoute';
import { smartRequestsRoute } from '../../../../routes/toolbox/smartRequestsPanel/smartRequestsRoute';

export const selectCurrentPanel: Selector<State, Maybe<Panel>> = createSelector(
  [selectPath],
  (path: string) => {
    if (projectDetailsRoute.matchesPath(path).matches) {
      return Panel.ProjectDetails;
    }
    if (buildingsRoute.matchesPath(path).matches) {
      return Panel.Buildings;
    }
    if (casesRoute.matchesPath(path).matches) {
      return Panel.Cases;
    }
    if (sectionsRoute.matchesPath(path).matches) {
      return Panel.Sections;
    }
    if (smartRequestsRoute.matchesPath(path).matches) {
      return Panel.SmartRequests;
    }
    if (deliverablesRoute.matchesPath(path).matches) {
      return Panel.Deliverables;
    }
    return undefined;
  }
);
