import * as React from 'react';
import { Window } from 'components/ui/Window';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { IconObject } from '../../../ui/Icons/iconObject';
import { GeographicalInterestMaps } from './GeographicalInterestMaps';
import { Card } from '../../../ui/Card';
import { GeotechnicalInformationReport } from './GeotechnicalInformationReport';
import { Note } from '../../../ui/Note';
import { useTranslation } from 'react-i18next';
import { getProjectDetailsGingerTriggerId } from '../../../../routes/toolbox/projectPanel/triggers/getProjectDetailsGingerTriggerId';
import { ProjectDetailsEditGingerRouteParams } from '../../../../routes/toolbox/projectPanel/projectDetailsEditGingerRoute';
import { goToProjectDetails } from '../../../../store/actions/navigations/toolbox/projectPanel/goToProjectDetails.action';

export const ProjectDetailsGingerWindow = () => {
  const { projectId } = useParams<ProjectDetailsEditGingerRouteParams>();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickClose = React.useCallback(() => {
    if (projectId) dispatch(goToProjectDetails(projectId));
  }, [dispatch, projectId]);

  return (
    <Window
      className="projectDetailsGingerWindow"
      title={t('projectDetailGingerWindow.title')}
      triggerId={getProjectDetailsGingerTriggerId()}
      topButtons={
        <>
          <IconObject iconName="close" type="menu" onClick={handleClickClose} />
        </>
      }>
      <Card>
        <div className="gingerMapLogo">
          <img alt="Ginger Map" height={15} src="../../../../partners/gingermap.jpg" />
        </div>
        <Note strong={true} style={{ background: 'transparent', padding: 0 }}>
          {t('projectDetailGingerWindow.note')}
        </Note>
        <GeographicalInterestMaps />
        <GeotechnicalInformationReport />
      </Card>
    </Window>
  );
};
