import { createSelector, Selector } from 'reselect';
import { selectProject } from '../project';
import { State } from '../../../store/reducers';
import { Maybe } from '../../../utils/Maybe';
import { FeatureParcel } from '../../../domain/Parcel';

export const selectParcels: Selector<State, Maybe<FeatureParcel[]>> = createSelector(
  [selectProject],
  (project) => project?.parcels
);
