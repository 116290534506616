import { ValidationRule } from '../ValidationsRule';
import { Notification } from '../Notification';
import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { Granulometry } from '../../granulometry/Granulometry';
import { getProjectCasesGranulometries } from '../../specification/project/queries/cases/getProjectCasesGranulometries';
import { getLevelBearingSurfaceIsLargeEnoughValidation } from '../../granulometry/levels/queries/surfaces/getLevelBearingSurfaceIsLargeEnoughValidation';
import { getLevelLabel } from '../../granulometry/levels/LevelGranulometry';
import { getCaseTopLevels } from '../../granulometry/cases/queries/levels/getCaseTopLevels';

export const bearingSurfacesMustBeLargeEnough: ValidationRule = (
  projectProjection: ProjectProjection,
  isNewProject?: boolean,
  granulometry?: Granulometry
) => {
  if (granulometry) {
    const notifications: Notification[] = getProjectCasesGranulometries(granulometry).reduce(
      (accNotifications, caseGranulometry) => [
        ...accNotifications,
        ...getCaseTopLevels(caseGranulometry).reduce((accL, levelGranulometry) => {
          const validation = getLevelBearingSurfaceIsLargeEnoughValidation(
            caseGranulometry,
            levelGranulometry
          );
          if (!validation) {
            const caseLabel = caseGranulometry.labelIndex;
            const levelLabel = getLevelLabel(levelGranulometry.level);
            const notification: Notification = {
              title:
                'La configuration actuelle du projet ne permet pas l’intégration de la surface minimale de palier pour le niveau ' +
                levelLabel +
                ' de la cage ' +
                caseLabel,
              explanation:
                'Votre modélisation présente une surface globale et/ou un agencement ne permettant pas l’intégration des surfaces minimale de palier à chaque étage. Vous pouvez essayer d’augmenter la Sdp réelle du projet ou réduire la SHab.',
              type: 'error'
            };
            return [notification, ...accL];
          }
          return accL;
        }, [] as Notification[])
      ],
      [] as Notification[]
    );
    return notifications.length !== 0 ? notifications : true;
  }
  return true;
};
