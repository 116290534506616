import * as R from 'ramda';
import { Granulometry } from '../Granulometry';
import { CaseGranulometry } from '../cases/CaseGranulometry';
import { BuildingGranulometry } from '../buildings/BuildingGranulometry';
import { Maybe } from '../../../utils/Maybe';

export type AllCasesInGranulometry = CaseGranulometry[];

export const getAllCasesInGranulometry = (granulometry: Granulometry) =>
  R.chain(({ cases }: BuildingGranulometry) => cases, granulometry) as CaseGranulometry[];
