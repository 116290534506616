import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Project } from '../../../../domain/project/Project';
import {
  isFocusBuilding,
  isFocusCase,
  isFocusProject
} from '../../../../domain/buildingMap/BuildingMapFocus';
import { IconObject } from '../../../ui/Icons/iconObject';
import { IconFont } from '../../../ui/Icons/IconFont';
import { selectBuildingMapFocus } from 'store/selectors/buildingMap/buildingMapFocus.selector';
import { buildingMapFocused } from 'store/actions/buildingMap/buildingMapFocused.action';
import { ProjectIcon } from './ProjectIcon';
import { BracketHorizontal } from '../../../ui/BracketHorizontal';
import { BuildingId } from '../../../../domain/specification/buildings/BuildingSpecification';
import { CaseId } from '../../../../domain/specification/cases/CaseSpecification';
import { letterFromIndex } from '../../../../utils/letterFromIndex';
import { getBuildingTitle } from '../../../../domain/specification/buildings/queries/getBuildingTitle';
import { getBuildingDefaultTitle } from '../../../../domain/specification/buildings/queries/getBuildingDefaultTitle';

interface ProjectTreeProps {
  project: Project;
}

const ProjectTree = ({ project }: ProjectTreeProps) => {
  const currentFocus = useSelector(selectBuildingMapFocus);
  const dispatch = useDispatch();
  const onProjectFocus = React.useCallback(
    () => dispatch(buildingMapFocused('project')),
    [dispatch]
  );
  const onBuildingFocus = React.useCallback(
    (buildingId: BuildingId) => () => dispatch(buildingMapFocused({ building: buildingId })),
    [dispatch]
  );
  const onCaseFocus = React.useCallback(
    (caseId: CaseId) => () => dispatch(buildingMapFocused({ case: caseId })),
    [dispatch]
  );

  if (!project) return null;

  return (
    <div className="project-tree">
      <ProjectIcon locked={isFocusProject(currentFocus)} onClick={onProjectFocus} />
      {project.buildings.map((buildingSpecification, buildingIndex) => (
        <div
          className={classNames('building', {
            locked:
              isFocusBuilding(currentFocus) && currentFocus.building === buildingSpecification.id
          })}
          key={buildingSpecification.id}>
          <div
            className="building-title"
            title={
              getBuildingTitle(buildingSpecification) || getBuildingDefaultTitle(buildingIndex)
            }>
            <div className="icon-font-wrapper" onClick={onBuildingFocus(buildingSpecification.id)}>
              <div className="icon-font-wrapper-inner">
                <IconFont returnString={(buildingIndex + 1).toString()} />
              </div>
            </div>
            <div className="building-title-brace">
              <BracketHorizontal />
            </div>
          </div>
          <div className="building-cases">
            {buildingSpecification.cases.map((caseSpecification, caseKey: number) => {
              const caseLetter = letterFromIndex(caseKey);
              const caseClasses = classNames('case', {
                locked: isFocusCase(currentFocus) && currentFocus.case === caseSpecification.id
              });
              return (
                <div
                  className={caseClasses}
                  key={caseSpecification.id}
                  onClick={onCaseFocus(caseSpecification.id)}>
                  <IconObject
                    iconName="case"
                    title={'Cage ' + (buildingIndex + 1) + caseLetter}
                    customText={(buildingIndex + 1).toString() + caseLetter}
                    customTextPosition="center"
                    customCss=".iconObjectCustomText { top:0.5px; }"
                    type="menu"
                  />
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectTree;
