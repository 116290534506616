import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { roundWith2Decimal } from '../../../utils/round/roundWith2Decimal';
import { getProjectSumOfCasesCuttedBuiltSurfaces } from '../../projection/project/queries/cases/getProjectSumOfCasesCuttedBuiltSurfaces';

export const getDifferenceBetweenAllCasesCuttedBuiltSurfacesAndProjectSurface = (
  project: ProjectProjection
): number =>
  roundWith2Decimal(project.cuttedBuiltSurface?.value || 0) -
  roundWith2Decimal(getProjectSumOfCasesCuttedBuiltSurfaces(project));

export const sumOfAllCasesCBSIsLessThanProjectSurface = (project: ProjectProjection): boolean =>
  getDifferenceBetweenAllCasesCuttedBuiltSurfacesAndProjectSurface(project) > 0;

export const sumOfAllCasesCBSIsMoreThanProjectSurface = (project: ProjectProjection): boolean =>
  getDifferenceBetweenAllCasesCuttedBuiltSurfacesAndProjectSurface(project) < 0;

export const sumOfAllCasesCBSAreEqualToProjectSurface = (project: ProjectProjection): boolean =>
  getDifferenceBetweenAllCasesCuttedBuiltSurfacesAndProjectSurface(project) === 0;
