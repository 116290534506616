import * as R from 'ramda';
import { Surface } from '../../../../specification/Surface';
import { CaseGranulometry } from '../../CaseGranulometry';
import { LevelGranulometry } from '../../../levels/LevelGranulometry';
import { getCaseBasementLevels } from '../levels/getCaseBasementLevels';
import { getBasementLevelGrossFloorSurfaceEff } from '../../../levels/queries/basementLevels/surfaces/grossFloorSurfaces/getBasementLevelGrossFloorSurfaceEff';
import { getBasementLevelGrossFloorSurface } from '../../../levels/queries/basementLevels/surfaces/grossFloorSurfaces/getBasementLevelGrossFloorSurface';

export type BasementLevelSurfaceName =
  | 'realBuiltSurface'
  | 'surfaceForSale'
  | 'grossFloorSurface'
  | 'grossFloorSurfaceEff';

export interface BasementLevelSurfaces {
  realBuiltSurface: Surface;
  surfaceForSale: Surface;
  grossFloorSurface: Surface;
  grossFloorSurfaceEff: Surface;
}

export interface BasementLevelSurfacesWithIndex extends BasementLevelSurfaces {
  level: number;
}

export const getCaseBasementLevelsSurfaces = (
  caseGranulometry: CaseGranulometry
): BasementLevelSurfacesWithIndex[] =>
  R.reduce(
    (acc, levelGranulometry: LevelGranulometry) =>
      acc.concat({
        level: levelGranulometry.level,
        realBuiltSurface: new Surface(levelGranulometry.realBuiltSurface || 0),
        surfaceForSale: new Surface(levelGranulometry.surfaceForSale || 0),
        grossFloorSurface: new Surface(
          getBasementLevelGrossFloorSurface(caseGranulometry, levelGranulometry)
        ),
        grossFloorSurfaceEff: new Surface(
          getBasementLevelGrossFloorSurfaceEff(caseGranulometry, levelGranulometry)
        )
      } as BasementLevelSurfacesWithIndex),
    [] as BasementLevelSurfacesWithIndex[]
  )(getCaseBasementLevels(caseGranulometry));
