import { createSelector, Selector } from 'reselect';
import { getReportState } from '../../reducers';
import { selectProject } from '../project';
import { State } from '../../../store/reducers';

export const selectIsReportOutdated: Selector<State, boolean> = createSelector(
  [getReportState, selectProject],
  (reportState, project) =>
    !project || !reportState.report || project.reportRevision !== project.revision
);
