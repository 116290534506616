import i18next from 'i18next';
import { T2_COLOR } from '../../../../constants/appConstants';
import { LodgmentSection } from './LodgmentSection';
import { LodgmentTypeSpecification } from '../../../specification/lodgmentTypes/LodgmentTypeSpecification';
import { LodgmentType } from '../../../specification/lodgmentTypes/LodgmentType';
import livingRoomKitchenC from '../../rooms/livingRoomKitchenC';
import kitchen from '../../rooms/kitchen';
import livingRoom from '../../rooms/livingRoom';
import bedroomPMR from '../../rooms/bedroomPMR';
import bathroom from '../../rooms/bathroom';
import balcony from '../../rooms/balcony';
import { HeatingSystem } from '../../../specification/lodgmentTypes/HeatingSystem';

export const t2c = (): LodgmentSection & LodgmentTypeSpecification => ({
  // Section
  id: 'NOID',
  name: LodgmentType.T2C,
  title: i18next.t('t2c.title'),
  type: 'lodgment',
  displayedSurface: 42,
  // SectionDrawn
  surface: 42,
  color: T2_COLOR,
  icon: {
    type: 'object',
    customText: 'T2C',
    customTextPosition: 'bottom right',
    iconName: 'lodgment',
    color: T2_COLOR
  },
  // SectionWithTheoreticalSurface
  theoreticalSurface: undefined,
  defaultTheoreticalSurface: 42,
  // SectionWithMinimumSurface
  minimumDisplayedSurface: 36,
  // SectionWithExposureRate
  exposureRate: undefined,
  defaultExposureRate: 1,
  // LodgmentSection
  lodgmentType: LodgmentType.T2C,
  rooms: [
    { ...livingRoomKitchenC(), icon: { ...livingRoomKitchenC().icon, color: T2_COLOR } },
    { ...kitchen(), icon: { ...kitchen().icon, color: T2_COLOR } },
    { ...livingRoom(), icon: { ...livingRoom().icon, color: T2_COLOR } },
    { ...bedroomPMR(), icon: { ...bedroomPMR().icon, color: T2_COLOR } },
    { ...bathroom(), icon: { ...bathroom().icon, color: T2_COLOR } },
    { ...balcony(), icon: { ...balcony().icon, color: T2_COLOR } }
  ],
  heatingSystem: undefined,
  defaultHeatingSystem: HeatingSystem.CollectiveHeatPumpOnGroundWaterHeating,
  // SectionWithTechnicalSheaths
  technicalSheathCount: 0,
  // LodgmentTypeSpecification
  prevType: LodgmentType.T1,
  nextType: LodgmentType.T2
});
