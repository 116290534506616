import { CaseSpecification } from '../../../CaseSpecification';
import { Surface } from '../../../../Surface';
import { getCaseTopLevels } from '../levels/getCaseTopLevels';
import { getLevelTechnicalPermiseSections } from '../../../../levels/queries/get/getLevelTechnicalPermiseSections';
import { getSectionDisplayedSurface } from '../../../../../granulometry/sections/queries/surfaces/getSectionDisplayedSurface';

export const getCaseTechnicalPremiseSectionsSurface = (
  caseSpecification: CaseSpecification
): Surface =>
  new Surface(
    getCaseTopLevels(caseSpecification).reduce(
      (accL, levelSpecification) =>
        accL +
        getLevelTechnicalPermiseSections(levelSpecification).reduce(
          (accTPS, technicalPremiseSection) =>
            accTPS + getSectionDisplayedSurface(technicalPremiseSection),
          0
        ),
      0
    )
  );
