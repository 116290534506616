import { CaseGranulometry } from '../../cases/CaseGranulometry';
import * as R from 'ramda';
import { LevelGranulometry } from '../../levels/LevelGranulometry';
import { isFoundationLevel } from '../../levels/queries/is/isFoundationLevel';
import { updateFoundationLevel } from './updateFoundationLevel';
import { createLevelFundationsSection } from '../../levels/queries/sections/create/createLevelFundationsSection';
import { isBasementLevel } from '../../levels/queries/is/isBasementLevel';
import { getLevelAbove } from '../../levels/queries/getLevelAbove';

const updateLevelFoundationSectionWithCustomDistribution = (
  levelGranulometry: LevelGranulometry,
  levelAbove: LevelGranulometry
): LevelGranulometry => ({
  ...levelGranulometry,
  fundations:
    levelAbove.surface > levelGranulometry.surface
      ? [
          createLevelFundationsSection(
            levelAbove.surface - levelGranulometry.surface,
            'superficial'
          )
        ]
      : undefined
});

export const redistributeCaseFoundationsWithCustomDistribution = (
  levels: LevelGranulometry[],
  caseGranulometry: CaseGranulometry
): LevelGranulometry[] =>
  R.map((levelGranulometry: LevelGranulometry) => {
    if (isFoundationLevel(caseGranulometry, levelGranulometry)) {
      return updateFoundationLevel({ ...caseGranulometry, levels }, levelGranulometry);
    } else if (isBasementLevel(caseGranulometry, levelGranulometry)) {
      return updateLevelFoundationSectionWithCustomDistribution(
        levelGranulometry,
        getLevelAbove(
          {
            ...caseGranulometry,
            levels
          },
          levelGranulometry
        ) as LevelGranulometry
      );
    } else {
      return levelGranulometry;
    }
  })(levels);
