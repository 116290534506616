import { CaseGranulometry } from '../../../CaseGranulometry';
import { getCaseBasementLevels } from '../getCaseBasementLevels';
import { getLevelParkingsCount } from '../../../../levels/queries/basementLevels/counts/getBasementLevelParkingsCount';

export const getCaseBasementLevelsWithParkingsCount = (
  caseGranulometry: CaseGranulometry
): number =>
  getCaseBasementLevels(caseGranulometry).reduce(
    (acc, level) => (getLevelParkingsCount(level) > 0 ? acc + 1 : acc),
    0
  );
