import { createSelector, Selector } from 'reselect';
import { State } from '../../../../store/reducers';
import { Maybe } from '../../../../utils/Maybe';
import { ParkingsSpecification } from '../../../../domain/specification/lodgmentTypes/ParkingsSpecification';
import { selectCurrentCaseProjection } from '../../projection/currentCase/projection.selector';

export const selectParkingsSpecifications: Selector<
  State,
  Maybe<ParkingsSpecification>
> = createSelector(
  [selectCurrentCaseProjection],
  (caseSpecification) => caseSpecification?.parking
);
