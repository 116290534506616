import { CaseSpecification } from '../../../CaseSpecification';
import { getCasePropertyConvertedValue } from '../properties/getCasePropertyConvertedValue';

export const getCaseOutsideWallsThickness = (
  caseSpecification: CaseSpecification,
  includeInsulation: boolean
): number => {
  const outsideWallsThickness = getCasePropertyConvertedValue(
    caseSpecification,
    'outsideWallsThickness'
  ) as number;
  if (includeInsulation) {
    const internalInsulationRate = getCasePropertyConvertedValue(
      caseSpecification,
      'internalInsulationRate'
    ) as number;
    const internalInsulationThickness = getCasePropertyConvertedValue(
      caseSpecification,
      'internalInsulationThickness'
    ) as number;
    const externalInsulationRate = getCasePropertyConvertedValue(
      caseSpecification,
      'externalInsulationRate'
    ) as number;
    const externalInsulationThickness = getCasePropertyConvertedValue(
      caseSpecification,
      'externalInsulationThickness'
    ) as number;
    return (
      outsideWallsThickness +
      internalInsulationRate * internalInsulationThickness +
      externalInsulationRate * externalInsulationThickness
    );
  } else {
    return outsideWallsThickness;
  }
};
