import { createSelector, Selector } from 'reselect';
import {
  EnvironmentalIndicators,
  getEnvironmentalIndicators
} from '../../../domain/environmental/getComEthIndicators';
import { State } from '../../../store/reducers';
import { Maybe } from '../../../utils/Maybe';
import { selectProject } from '../project';
import { selectFocusedInComEthReport } from './focusedInComEthReport.selector';
import { selectIsExistingProject } from '../project/isExistingProject.selector';
import { ExistingProject } from '../../../domain/project/Project';

export const selectFocusedEnvironmentalIndicators: Selector<
  State,
  Maybe<EnvironmentalIndicators>
> = createSelector(
  [selectProject, selectIsExistingProject, selectFocusedInComEthReport],
  (project, isExisting, report) =>
    project && isExisting && report
      ? getEnvironmentalIndicators(project as ExistingProject, report)
      : undefined
);
