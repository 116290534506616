import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  CaseId,
  CaseSpecification
} from '../../../../../../../domain/specification/cases/CaseSpecification';
import { Option, Select } from '../../../../../../ui/Select';
import { caseSpecificationChanged } from '../../../../../../../store/actions/changeCaseSpecification.action';
import {
  CarpentryOccultationsType,
  CarpentryOccultationsTypeName,
  isCarpentryOccultationsTypeUsePVC,
  isCarpentryOccultationsTypeUseWoodOrMetal
} from '../../../../../../../domain/specification/cases/CarpentryOccultationsType';
import { Checkbox } from '../../../../../../ui/Checkbox';
import { selectCurrentCaseCarpentryNightTimeOccultationsTypeIsMonobloc } from '../../../../../../../store/selectors/specification/currentCase/carpentryNightTimeOccultationsTypeIsMonobloc.selector';
import { FormGroup } from '../../../../../../ui/FormGroup';
import { carpentryOccultationsTypeOptions } from '../CarpentryOccultations';
import { CarpentryOccultationsMotorisationTypeName } from '../../../../../../../domain/specification/cases/CarpentryOccultationsMotorisationType';
import { refreshCarpentryOccultationsMotorisationTypeOptionsState } from './CarpentryOccultationsMotorisationTypeForm';

interface CarpentryNightTimeOccultationsTypeProps {
  caseId: CaseId;
  isSelectedCarpentryTypeUsePVC: boolean;
  selectedCarpentryNightTimeOccultationTypeOption?: Option;
  setSelectedCarpentryNightTimeOccultationTypeOption: React.Dispatch<
    React.SetStateAction<Option | undefined>
  >;
  selectedCarpentryDayTimeOccultationTypeOption?: Option;
  isNightTimeMonoblocDisplayed: boolean;
  setIsNightTimeMonoblocDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
  isSelectedCarpentryOccultationsMotorisationTypeSetForDay: boolean;
  setCarpentryOccultationsMotorisationTypeOptionsState: React.Dispatch<
    React.SetStateAction<Option[]>
  >;
}

export const CarpentryNightTimeOccultationsTypeForm = ({
  caseId,
  isSelectedCarpentryTypeUsePVC,
  selectedCarpentryNightTimeOccultationTypeOption,
  setSelectedCarpentryNightTimeOccultationTypeOption,
  selectedCarpentryDayTimeOccultationTypeOption,
  isNightTimeMonoblocDisplayed,
  setIsNightTimeMonoblocDisplayed,
  isSelectedCarpentryOccultationsMotorisationTypeSetForDay,
  setCarpentryOccultationsMotorisationTypeOptionsState
}: CarpentryNightTimeOccultationsTypeProps) => {
  const carpentryNightTimeOccultationsTypeIsMonobloc = useSelector(
    selectCurrentCaseCarpentryNightTimeOccultationsTypeIsMonobloc
  ) as boolean;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleChange = React.useCallback(
    (option: Option) => {
      setSelectedCarpentryNightTimeOccultationTypeOption(option);
      const isNightTimeMonoblocDisplayed =
        isSelectedCarpentryTypeUsePVC &&
        isCarpentryOccultationsTypeUsePVC(option.value as CarpentryOccultationsType);
      setIsNightTimeMonoblocDisplayed(isNightTimeMonoblocDisplayed);
      setCarpentryOccultationsMotorisationTypeOptionsState(
        refreshCarpentryOccultationsMotorisationTypeOptionsState(
          selectedCarpentryDayTimeOccultationTypeOption,
          option
        )
      );
      let caseSpecificationsToChange: Partial<CaseSpecification> = {
        carpentryNightTimeOccultationsType: option.value as CarpentryOccultationsType
      };
      if (!isNightTimeMonoblocDisplayed) {
        caseSpecificationsToChange = {
          ...caseSpecificationsToChange,
          carpentryNightTimeOccultationsTypeIsMonobloc: false
        };
      }
      if (isCarpentryOccultationsTypeUseWoodOrMetal(option.value as CarpentryOccultationsType)) {
        caseSpecificationsToChange = {
          ...caseSpecificationsToChange,
          carpentryOccultationsMotorisationType:
            isSelectedCarpentryOccultationsMotorisationTypeSetForDay &&
            !isCarpentryOccultationsTypeUseWoodOrMetal(
              selectedCarpentryDayTimeOccultationTypeOption?.value as CarpentryOccultationsTypeName
            )
              ? CarpentryOccultationsMotorisationTypeName.DayTime
              : CarpentryOccultationsMotorisationTypeName.None
        };
      }
      dispatch(caseSpecificationChanged(caseId, caseSpecificationsToChange));
    },
    [
      dispatch,
      caseId,
      isSelectedCarpentryTypeUsePVC,
      isSelectedCarpentryOccultationsMotorisationTypeSetForDay,
      selectedCarpentryDayTimeOccultationTypeOption,
      setSelectedCarpentryNightTimeOccultationTypeOption,
      setIsNightTimeMonoblocDisplayed,
      setCarpentryOccultationsMotorisationTypeOptionsState
    ]
  );

  if (!selectedCarpentryNightTimeOccultationTypeOption) return null;

  return (
    <FormGroup>
      <label htmlFor={`case${caseId}CarpentryNightTimeOccultationType`}>
        {t('carpentrySpecifications.carpentryOccultationsType.nightTimeLabel')}
      </label>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyItems: 'center',
          marginTop: '4px'
        }}>
        <Select
          id={`case${caseId}CarpentryNightTimeOccultationType`}
          options={carpentryOccultationsTypeOptions}
          handleChange={handleChange}
          value={selectedCarpentryNightTimeOccultationTypeOption}
          style={{ paddingRight: '8px', width: '148px' }}
        />
        {isNightTimeMonoblocDisplayed && (
          <Checkbox
            label={t('carpentrySpecifications.carpentryOccultationsType.isMonoblocLabel')}
            selected={carpentryNightTimeOccultationsTypeIsMonobloc}
            checkboxName={`case${caseId}CarpentryNightTimeOccultationsTypeIsMonobloc`}
            handleCheck={() =>
              dispatch(
                caseSpecificationChanged(caseId, {
                  carpentryNightTimeOccultationsTypeIsMonobloc:
                    !carpentryNightTimeOccultationsTypeIsMonobloc
                })
              )
            }
            style={{ marginTop: '-4px', width: '140px' }}
          />
        )}
      </div>
    </FormGroup>
  );
};
