import { BuildingGranulometry } from '../../../buildings/BuildingGranulometry';
import { CaseGranulometry } from '../../CaseGranulometry';

export const getCaseAdjoiningsCount = (
  buildingGranulometry: BuildingGranulometry,
  caseGranulometry: CaseGranulometry
): 0 | 1 | 2 => {
  const caseCount = buildingGranulometry.cases.length;
  if (caseCount > 1) {
    const index = buildingGranulometry.cases.findIndex(
      (cG) => cG.labelIndex === caseGranulometry.labelIndex
    );
    return index === 0 || index === caseCount - 1 ? 1 : 2;
  } else {
    return 0;
  }
};
