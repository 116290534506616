import { matchesPath } from '../../utils/matchesPath';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type SectionsIndividualRouteParams = { projectId: string; sectionId: string };
export const sectionsIndividualRoute: RouteWithMakePath<SectionsIndividualRouteParams> = {
  path: `/project/:projectId/sections/individual/:sectionId`,
  makePath: ({ projectId, sectionId }) => `/project/${projectId}/sections/individual/${sectionId}`,
  matchesPath: matchesPath<SectionsIndividualRouteParams>(
    /\/project\/([^/]+)\/sections\/individual\/(\w+)/,
    {
      projectId: 1,
      sectionId: 2
    }
  )
};
