import { LevelGranulometry } from '../../../LevelGranulometry';
import { roundWith2Decimal } from '../../../../../../utils/round/roundWith2Decimal';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { getLevelSpecificationFromCaseProjectionWithGivenLevelIndex } from '../../../../../projection/cases/queries/levels/getLevelSpecificationFromCaseProjectionWithGivenLevelIndex';
import { getLevelHoppersSurface } from '../../../../../specification/cases/queries/levels/surfaces/getLevelHoppersSurface';
import { getLevelOutsideWallsSurfaceFromLevelsGeometry } from '../../../../../specification/cases/queries/levels/surfaces/getLevelOutsideWallsSurfaceFromLevelsGeometry';
import { getBasementLevelRealBuiltSurface } from './getBasementLevelRealBuiltSurface';

// Note : the details is not folowwing perfectly the getLevelRealBuiltSurface to make it work's
export const getBasementLevelRealBuiltSurfaceDetails = (
  levelGranulometry: LevelGranulometry,
  caseGranulometry: CaseGranulometry
): string => {
  let details: string[] = [];

  const basementLevelSpecification = getLevelSpecificationFromCaseProjectionWithGivenLevelIndex(
    caseGranulometry.initialSpecifications,
    levelGranulometry.level
  );
  // 1 : If there is a manually forced real built surface :
  if (basementLevelSpecification.realBuiltSurface !== undefined) {
    details = [
      ...details,
      '<b>' +
        basementLevelSpecification.realBuiltSurface +
        ' m\u00B2</b> de Sdp Réelle saisie pour ce niveau par l’opérateur'
    ];
  }
  // 2 : If the level has a filled geometry
  else if (basementLevelSpecification.geometry?.properties?.area) {
    details = [
      ...details,
      '<b>SdP Réelle du niveau calculée à partir de la géométrie saisie :</b>'
    ];

    details = [...details, ''];

    details = [
      ...details,
      '<b>' +
        roundWith2Decimal(basementLevelSpecification.geometry.properties.area) +
        ' m\u00B2</b> de SHOB VPP dessinée'
    ];

    details = [
      ...details,
      '<b>- ' +
        roundWith2Decimal(
          getLevelHoppersSurface(caseGranulometry.initialSpecifications, basementLevelSpecification)
        ) +
        ' m\u00B2</b> de trémies'
    ];

    details = [
      ...details,
      '<b>- ' +
        roundWith2Decimal(
          getLevelOutsideWallsSurfaceFromLevelsGeometry(
            caseGranulometry.initialSpecifications,
            basementLevelSpecification,
            false
          )
        ) +
        ' m\u00B2</b> d’emprise de réelle de voile exposée'
    ];
  }

  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(
        getBasementLevelRealBuiltSurface(
          basementLevelSpecification,
          caseGranulometry.initialSpecifications
        )
      ) +
      'm\u00B2</b>'
  ];

  return details.join('<br />');
};
