import { CaseId } from '../../../domain/specification/cases/CaseSpecification';
import { matchesPath } from '../../utils/matchesPath';
import { orCreate } from '../../utils/orCreate';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type CaseGranulometryEditBasementsRouteParams = {
  projectId: string;
  caseId: CaseId;
};
export const caseGranulometryEditBasementsRoute: RouteWithMakePath<CaseGranulometryEditBasementsRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/basements`,
    makePath: ({ projectId, caseId }) =>
      `/project/${orCreate(projectId)}/cases/${caseId}/granulometry/edit/basements`,
    matchesPath: matchesPath<CaseGranulometryEditBasementsRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/basements/,
      {
        projectId: 1,
        caseId: 2
      }
    )
  };
