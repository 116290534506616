import { CaseGranulometry } from '../CaseGranulometry';
import { getCasePropertyValue } from '../../../specification/cases/queries/get/properties/getCasePropertyValue';
import { MasonryTypeName } from '../../../specification/cases/MasonryType';
import { WoodEnvelopeTypeName } from '../../../specification/cases/WoodEnvelopeType';

export type CaseAverageOutsideWallsType =
  | 'concrete'
  | 'masonryWithConcreteBlock'
  | 'masonryWithBrickBlock'
  | 'woodEnvelopeWithCltSolidPanel'
  | 'woodEnvelopeWithTimberFrame';

export const getCaseAverageOutsideWallsType = (
  caseGranulometry: CaseGranulometry
): CaseAverageOutsideWallsType => {
  const outsideWallConcreteRate = getCasePropertyValue(
    caseGranulometry,
    'outsideWallConcreteRate'
  ) as number;
  const outsideWallMasonryRate = getCasePropertyValue(
    caseGranulometry,
    'outsideWallMasonryRate'
  ) as number;
  const outsideWallWoodRate = getCasePropertyValue(
    caseGranulometry,
    'outsideWallWoodRate'
  ) as number;

  if (
    outsideWallConcreteRate >= outsideWallMasonryRate &&
    outsideWallConcreteRate >= outsideWallWoodRate
  ) {
    return 'concrete';
  } else {
    if (outsideWallMasonryRate >= outsideWallWoodRate) {
      if (caseGranulometry.masonryType === MasonryTypeName.ConcreteBlock) {
        return 'masonryWithConcreteBlock';
      } else {
        return 'masonryWithBrickBlock';
      }
    } else {
      if (caseGranulometry.woodEnvelopeType === WoodEnvelopeTypeName.CLTSolidPanel) {
        return 'woodEnvelopeWithCltSolidPanel';
      } else {
        return 'woodEnvelopeWithTimberFrame';
      }
    }
  }
};
