import * as R from 'ramda';
import { Project } from '../../project/Project';
import {
  BuildingId,
  BuildingSpecification,
  hasId
} from '../../specification/buildings/BuildingSpecification';
import { checkThat } from '../checkThat';
import { Maybe } from '../../../utils/Maybe';

export const buildingExists = <P extends Project>(project: P, buildingId: BuildingId): boolean =>
  R.pipe<[Project], BuildingSpecification[], Maybe<BuildingSpecification>, boolean, boolean>(
    R.prop('buildings'),
    R.find(hasId(buildingId)),
    R.isNil,
    R.not
  )(project);

export const checkThatBuildingExists =
  <P extends Project>(buildingId: BuildingId) =>
  (project: P) =>
    checkThat(buildingExists, `building ${buildingId} does not exist`, project, buildingId);
