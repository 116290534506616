import { LevelGeometry } from '../../../../../../services/mapBox/mapboxDraw/levelGeometry';
import { LevelSpecification } from '../../../../levels/LevelSpecification';

export const setCaseLevelSpecificationWithLevelGeometry = (
  levelGeometry: LevelGeometry
): LevelSpecification => ({
  id: levelGeometry.id,
  level: levelGeometry.properties.floor,
  geometry: levelGeometry,
  grossFloorSurfaceEff: levelGeometry.properties.area
});
