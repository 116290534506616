import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from 'components/ui/SpecialLabel';
import { FormGroup } from 'components/ui/FormGroup';
import { Card } from '../../../../ui/Card';
import { Input } from '../../../../ui/input/Input';
import { projectNameIsValid } from '../../../../../domain/checks/project/projectNameIsValid';
import { selectProjectName } from '../../../../../store/selectors/project';
import { renameProject } from '../../../../../store/actions/renameProject.action';

export const ProjectNameCard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projectName = useSelector(selectProjectName) || '';
  const handleRename = (name: string) => dispatch(renameProject(name));

  return (
    <Card>
      <SpecialLabel label={t('Project informations')} />
      <FormGroup>
        <Input
          label={t('Project Name')}
          name="projectName"
          type="text"
          value={projectName || ''}
          handleChange={(event) => handleRename(event.target.value || '')}
          color={projectNameIsValid(projectName) ? undefined : 'orange'}
        />
      </FormGroup>
    </Card>
  );
};
