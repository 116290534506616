import { OTHER_RBS_SECTIONS_COLOR } from '../../../../constants/appConstants';
import { Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { TechnicalInfraPremiseSectionName } from './TechnicalPremiseSection';
import { SectionWithExposureRate } from '../SectionWithExposureRate';
import { SectionWithTechnicalSheaths } from '../SectionWithTechnicalSheaths';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';

interface TrashcanInfraSectionProps {
  surface?: number;
  exposureRate?: number;
  width?: number;
}

export interface TrashcanInfraSection
  extends Section,
    SectionDrawn,
    SectionWithExposureRate,
    SectionWithTechnicalSheaths {
  name: TechnicalInfraPremiseSectionName.TrashcanInfraSection;
  width?: number;
  defaultWidth: number;
  minWidth: undefined;
  maxWidth: undefined;
  defaultSurface: number;
  minSurface: number;
  maxSurface: undefined;
}

export const TRASHCAN_INFRA_SECTION_DEFAULT_SURFACE = 15;
export const TRASHCAN_INFRA_SECTION_MIN_SURFACE = 1;
export const TRASHCAN_INFRA_SECTION_DEFAULT_EXPOSURE_RATE = 1;
export const TRASHCAN_INFRA_SECTION_DEFAULT_WITH = 3.5;
export const TRASHCAN_INFRA_SECTION_ICON = {
  type: 'object',
  iconName: 'trashcan',
  color: OTHER_RBS_SECTIONS_COLOR
} as IconObjectProps;

const trashcanInfraSection = ({
                                surface,
                                exposureRate,
                                width
                              }: TrashcanInfraSectionProps): TrashcanInfraSection => ({
  // Section
  id: 'NOID',
  name: TechnicalInfraPremiseSectionName.TrashcanInfraSection,
  title: i18next.t('trashcanSection.title'),
  type: 'others',
  displayedSurface: surface || TRASHCAN_INFRA_SECTION_DEFAULT_SURFACE,
  // SectionDrawn
  surface: surface || TRASHCAN_INFRA_SECTION_DEFAULT_SURFACE,
  color: OTHER_RBS_SECTIONS_COLOR,
  icon: TRASHCAN_INFRA_SECTION_ICON,
  // SectionWithExposureRate
  exposureRate: exposureRate || TRASHCAN_INFRA_SECTION_DEFAULT_EXPOSURE_RATE,
  defaultExposureRate: TRASHCAN_INFRA_SECTION_DEFAULT_EXPOSURE_RATE,
  // SectionWithTechnicalSheaths
  technicalSheathCount: 0,
  // BicycleParkingSection
  width: width || undefined,
  defaultWidth: TRASHCAN_INFRA_SECTION_DEFAULT_WITH,
  minWidth: undefined,
  maxWidth: undefined,
  defaultSurface: TRASHCAN_INFRA_SECTION_DEFAULT_SURFACE,
  minSurface: TRASHCAN_INFRA_SECTION_MIN_SURFACE,
  maxSurface: undefined
});

export default trashcanInfraSection;

