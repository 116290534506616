import { RoomSpecification } from '../../RoomSpecification';
import { isRoomSpecificationAnyLivingRoomKitchen } from '../../LivingRoomKitchenSpecification';
import { isRoomSpecificationABalcony } from '../../BalconySpecification';
import { getLivingRoomKitchenSpecificationCalculatedLength } from './getLivingRoomKitchenSpecificationCalculatedLength';
import { getBasicRoomSpecificationLength } from './getBasicRoomSpecificationLength';
import { getBalconySpecificationCalculatedLength } from './getBalconySpecificationCalculatedLength';

type GetRoomSpecificationLength = (
  lodgmentRooms: RoomSpecification[],
  lodgmentSurface: number,
  balconySurfaceOnRealLivingSurfaceRate: number,
  roomSpecification: RoomSpecification
) => number;
export const getRoomSpecificationLength: GetRoomSpecificationLength = (
  lodgmentRooms,
  lodgmentSurface,
  balconySurfaceOnRealLivingSurfaceRate,
  roomSpecification
) =>
  isRoomSpecificationAnyLivingRoomKitchen(roomSpecification)
    ? getLivingRoomKitchenSpecificationCalculatedLength(
        lodgmentRooms,
        lodgmentSurface,
        roomSpecification
      )
    : isRoomSpecificationABalcony(roomSpecification)
    ? getBalconySpecificationCalculatedLength(
        lodgmentSurface,
        balconySurfaceOnRealLivingSurfaceRate,
        roomSpecification
      )
    : getBasicRoomSpecificationLength(roomSpecification);
