import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { LevelGranulometry, LevelGranulometryFullFilled } from '../../../../LevelGranulometry';

export const getBasementLevelGrossFloorSurfaceEff = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled | LevelGranulometry
): number => {
  if (caseGranulometry.initialSpecifications.projectSurfaceInputMode === 'cbs') {
    return 0; // TODO : To calculate
  } else {
    return levelGranulometry.grossFloorSurfaceEff || 0;
  }
};
