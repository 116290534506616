import { getHighestChildCase } from './getHighestChildCase';
import { addElementOnMask } from './addElementOnMask';
import { getWindowHeight } from './getWindowHeight';
import { Dispatch } from 'react';
import { Maybe } from '../Maybe';

export const getElementPositionOnBuildingMap = (
  element: Element,
  zoomValue: number,
  withMask: boolean,
  calculatedOnce: Maybe<boolean>,
  dispatch?: Dispatch<any>
): { translateX: number; translateY: number } | false => {
  const buildingMap = document.querySelector('.buildingMap');
  if (element && buildingMap) {
    const elementOffsetForRightAndLeft = element.getBoundingClientRect();
    let elementOffsetForTopAndBottom = elementOffsetForRightAndLeft;
    if (element.classList.contains('buildings') || element.classList.contains('building')) {
      const highestChildCase = getHighestChildCase(element);
      if (highestChildCase !== undefined) {
        elementOffsetForTopAndBottom = highestChildCase.getBoundingClientRect();
      }
    }
    const buildingMapOffset = buildingMap.getBoundingClientRect();

    if (withMask) {
      addElementOnMask(
        element,
        false,
        zoomValue,
        elementOffsetForTopAndBottom,
        elementOffsetForRightAndLeft,
        buildingMapOffset,
        calculatedOnce,
        dispatch
      );
    }
    return {
      translateX: Math.round(
        ((elementOffsetForRightAndLeft.left + elementOffsetForRightAndLeft.right) / 2 -
          (buildingMapOffset.left + buildingMapOffset.right) / 2) *
          (1 / zoomValue) *
          -1
      ),
      translateY: Math.round(
        ((elementOffsetForTopAndBottom.top + elementOffsetForTopAndBottom.bottom) / 2 -
          (buildingMapOffset.top + buildingMapOffset.bottom) / 2 -
          getWindowHeight() / 2) *
          (1 / zoomValue) *
          -1
      )
    };
  }
  return false;
};
