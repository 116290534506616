import * as React from 'react';
import { Route } from 'react-router';
import { LodgmentsTypesWindow } from './CasesPanelWindows/LodgmentsTypesWindow/LodgmentsTypesWindow';
import LevelDetailsWindow from './CasesPanelWindows/LevelDetailsWindow/LevelDetailsWindow';
import CaseFifthFacadeWindow from './CasesPanelWindows/CaseFifthFacadeWindow/CaseFifthFacadeWindow';
import CaseSuperstructureWindow from './CasesPanelWindows/CaseSuperstructureWindow/CaseSuperstructureWindow';
import { CaseEnergyAndNetworksWindow } from './CasesPanelWindows/CaseEnergyAndNetworksWindow/CaseEnergyAndNetworksWindow';
import CaseInfrastructureWindow from './CasesPanelWindows/CaseInfrastructureWindow/CaseInfrastructureWindow';
import CaseGroundAdaptationsWindow from './CasesPanelWindows/CaseGroundAdaptationsWindow/CaseGroundAdaptationsWindow';
import CaseOutdoorsWindow from './CasesPanelWindows/CaseOutdoorsWindow/CaseOutdoorsWindow';
import { CaseOtherPropertiesWindowsGroup } from './CasesPanelWindows/CaseOtherPropertiesWindowsGroup/CaseOtherPropertiesWindowsGroup';
import { ToolboxWindows } from './ToolboxWindows';
import { GeometryWindow } from './GeometryWindow/GeometryWindow';
import { caseGranulometryEditGeometryRoute } from '../../../routes/toolbox/casesPanel/caseGranulometryEditGeometryRoute';
import { caseGranulometryEditLevelGeometryRoute } from '../../../routes/toolbox/casesPanel/caseGranulometryEditLevelGeometryRoute';
import { caseGranulometryEditFifthFacadeRoute } from '../../../routes/toolbox/casesPanel/caseGranulometryEditFifthFacadeRoute';
import { caseGranulometryEditSuperstructureRoute } from '../../../routes/toolbox/casesPanel/caseGranulometryEditSuperstructureRoute';
import { caseGranulometryEditEnergyAndNetworksRoute } from '../../../routes/toolbox/casesPanel/caseGranulometryEditEnergyAndNetworksRoute';
import { caseGranulometryEditInfrastructureRoute } from '../../../routes/toolbox/casesPanel/caseGranulometryEditInfrastructureRoute';
import { caseGranulometryEditGroundAdaptationsRoute } from '../../../routes/toolbox/casesPanel/caseGranulometryEditGroundAdaptationsRoute';
import { caseGranulometryEditOutdoorsRoute } from '../../../routes/toolbox/casesPanel/caseGranulometryEditOutdoorsRoute';
import { caseGranulometryEditOtherPropertiesRoute } from '../../../routes/toolbox/casesPanel/caseGranulometryEditOtherPropertiesRoute';
import { caseGranulometryEditLevelDetailsRoute } from '../../../routes/toolbox/casesPanel/caseGranulometryEditLevelDetailsRoute';
import { caseGranulometryEditLodgmentTypeRoute } from '../../../routes/toolbox/casesPanel/caseGranulometryEditLodgmentTypeRoute';

export const CasesPanelWindows = () => (
  <ToolboxWindows>
    <Route path={caseGranulometryEditGeometryRoute.path} component={GeometryWindow} />
    <Route path={caseGranulometryEditLevelGeometryRoute.path} component={GeometryWindow} />
    <Route path={caseGranulometryEditFifthFacadeRoute.path} component={CaseFifthFacadeWindow} />
    <Route
      path={caseGranulometryEditSuperstructureRoute.path}
      component={CaseSuperstructureWindow}
    />
    <Route
      path={caseGranulometryEditEnergyAndNetworksRoute.path}
      component={CaseEnergyAndNetworksWindow}
    />
    <Route
      path={caseGranulometryEditInfrastructureRoute.path}
      component={CaseInfrastructureWindow}
    />
    <Route
      path={caseGranulometryEditGroundAdaptationsRoute.path}
      component={CaseGroundAdaptationsWindow}
    />
    <Route path={caseGranulometryEditOutdoorsRoute.path} component={CaseOutdoorsWindow} />
    <Route
      path={caseGranulometryEditOtherPropertiesRoute.path}
      component={CaseOtherPropertiesWindowsGroup}
    />
    <Route path={caseGranulometryEditLodgmentTypeRoute.path} component={LodgmentsTypesWindow} />
    <Route path={caseGranulometryEditLevelDetailsRoute.path} component={LevelDetailsWindow} />
  </ToolboxWindows>
);
