import * as R from 'ramda';
import { LevelGranulometry } from '../../../LevelGranulometry';
import { getTopLevelSectionsWithTechnicalSheaths } from '../sections/getTopLevelSectionsWithTechnicalSheaths';
import { SectionWithTechnicalSheaths } from '../../../../sections/SectionWithTechnicalSheaths';

export const getTopLevelSectionsWithTechnicalSheathsCount = (
  levelGranulometry: LevelGranulometry
): number =>
  R.pipe<[LevelGranulometry], SectionWithTechnicalSheaths[], number>(
    getTopLevelSectionsWithTechnicalSheaths,
    R.reduce(R.inc, 0)
  )(levelGranulometry);
