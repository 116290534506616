import { LevelGranulometry } from '../../../../LevelGranulometry';
import { getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface } from './getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface';
import { getTopLevelRealTheoreticalInsideWallsSurfaceDiff } from '../../walls/getTopLevelRealTheoreticalInsideWallsSurfaceDiff';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelSectionsWithTSTechnicalSheathRealSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): number =>
  getTopLevelSectionsWithTSTechnicalSheathTheoreticalSurface(caseGranulometry, levelGranulometry)
    .value + getTopLevelRealTheoreticalInsideWallsSurfaceDiff(caseGranulometry, levelGranulometry);
