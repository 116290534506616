import { createSelector, Selector } from 'reselect';
import { selectProject } from './project.selector';
import { selectCurrentBuildingId } from './currentBuildingId.selector';
import { getBuildingLabelGivenBuildingId } from '../../../domain/core/queries/getBuildingLabelGivenBuildingId';
import { State } from '../../../store/reducers';
import { Maybe } from '../../../utils/Maybe';

export const selectCurrentBuildingLabel: Selector<State, Maybe<string>> = createSelector(
  [selectProject, selectCurrentBuildingId],
  (project, buildingId) => {
    return project && buildingId ? getBuildingLabelGivenBuildingId(project, buildingId) : undefined;
  }
);
