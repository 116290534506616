import i18next from 'i18next';
import { LivingRoomKitchenSpecification } from '../../specification/rooms/LivingRoomKitchenSpecification';

const LIVING_ROOM_KITCHEN_WIDTH = 4.5;

const livingRoomKitchen = (): LivingRoomKitchenSpecification =>
  ({
    name: 'livingRoomKitchen',
    title: i18next.t('Living room/Kitchen'),
    defaultWidth: LIVING_ROOM_KITCHEN_WIDTH,
    icon: {
      type: 'object',
      strokeStyle: 'opened',
      customText: 'SEJ+CUIS'
    }
  } as const);

export default livingRoomKitchen;
