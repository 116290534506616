import * as R from 'ramda';
import { LevelGranulometry } from '../../levels/LevelGranulometry';
import { isSectionACarBoxInfra } from '../../sections/parkingSections/carBoxInfra';
import { getLevelFullFilledContent } from '../../levels/queries/content/getLevelFullFilledContent';

export const removeCarBoxesInBasementLevelGranulometry = (
  levelGranulometry: LevelGranulometry
): LevelGranulometry => ({
  ...levelGranulometry,
  content: R.reject(isSectionACarBoxInfra)(getLevelFullFilledContent(levelGranulometry))
});
