import { createMigrate, PersistedState } from 'redux-persist';
import { UserState } from '../user.slice';

const migrations = {
  0: (state: PersistedState): PersistedState & UserState =>
    ({
      ...state,
      sessionState: 'UNAUTHENTICATED'
    } as PersistedState & UserState)
};

export const userMigrations = createMigrate(migrations);
