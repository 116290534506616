import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CaseId } from '../../../../../../domain/specification/cases/CaseSpecification';
import { Option, Select } from '../../../../../ui/Select';
import { caseSpecificationChanged } from '../../../../../../store/actions/changeCaseSpecification.action';
import {
  WoodEnvelopeType,
  WoodEnvelopeTypeName
} from '../../../../../../domain/specification/cases/WoodEnvelopeType';

import { selectCurrentCaseWoodEnvelopeType } from '../../../../../../store/selectors/specification/currentCase/woodEnvelopeType.selector';

interface WoodEnvelopeTypesProps {
  caseId: CaseId;
}

export const WoodEnvelopeTypes = ({ caseId }: WoodEnvelopeTypesProps) => {
  const woodEnvelopeType = useSelector(selectCurrentCaseWoodEnvelopeType) as WoodEnvelopeType;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectWoodEnvelopeTypeOptions: Option[] = [
    {
      label: t('outsideWallTypeRates.woodEnvelopeType.cltSolidPanel.label'),
      value: WoodEnvelopeTypeName.CLTSolidPanel
    },
    {
      label: t('outsideWallTypeRates.woodEnvelopeType.timberFrame.label'),
      value: WoodEnvelopeTypeName.TimberFrame
    }
  ];

  const selectedWoodEnvelopeType = selectWoodEnvelopeTypeOptions.find(
    (option) => option.value === woodEnvelopeType
  );

  return (
    <div style={{ marginTop: '-4px', marginLeft: '38px', marginBottom: '8px' }}>
      <label htmlFor={`case${caseId}WoodEnvelopeType`}>
        {t('outsideWallTypeRates.woodEnvelopeType.label')}
      </label>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
        <Select
          id={`case${caseId}WoodEnvelopeType`}
          size={'small'}
          options={selectWoodEnvelopeTypeOptions}
          handleChange={(props: Option) => {
            dispatch(
              caseSpecificationChanged(caseId, {
                woodEnvelopeType: props.value as WoodEnvelopeType
              })
            );
          }}
          value={selectedWoodEnvelopeType}
          style={{ flexBasis: '194px' }}
        />
      </div>
    </div>
  );
};
