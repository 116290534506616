import { LevelGranulometryFullFilled } from '../../../../LevelGranulometry';
import { getTopLevelHoppersSurface } from '../getTopLevelHoppersSurface';
import { getLevelAlreadyCalculatedRealBuiltSurface } from '../../../surfaces/getLevelAlreadyCalculatedRealBuiltSurface';
import { getTopLevelRealBuiltSurfaceEff } from './getTopLevelRealBuiltSurfaceEff';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelRealBuiltSurfaceEffDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryFullFilled
): string =>
  '<b>' +
  roundWith2Decimal(getLevelAlreadyCalculatedRealBuiltSurface(levelGranulometry).value) +
  ' m\u00B2</b> de SdP réelle<br /><b>+ ' +
  roundWith2Decimal(getTopLevelHoppersSurface(caseGranulometry, levelGranulometry)) +
  ' m\u00B2</b> de <i>Trémies</i><br /><b>= ' +
  roundWith2Decimal(getTopLevelRealBuiltSurfaceEff(caseGranulometry, levelGranulometry)) +
  ' m\u00B2</b>';
