import { ValidationRule } from '../ValidationsRule';
import { projectNameIsValid } from '../../checks/project/projectNameIsValid';

export const projectNameMustBeFilled: ValidationRule = (project, isNewProject) => {
  if (project && !projectNameIsValid(project.name)) {
    if (isNewProject) {
      return {
        title: 'Merci de saisir un nom de projet',
        explanation: 'Cette information est nécessaire pour créer le projet.',
        type: 'info'
      };
    } else {
      return {
        title: 'Le nom du projet est vide',
        explanation: `Il est nécessaire de renseigner un nom pour le projet.`,
        type: 'error'
      };
    }
  }
  return true;
};
