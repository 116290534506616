import { getBuildingIndexGivenBuildingId } from './getBuildingIndexGivenBuildingId';
import { getBuildingIdFromCaseId } from './getBuildingIdFromCaseId';
import { Project } from '../../project/Project';
import { hasCaseId } from '../../specification/cases/queries/has/hasCaseId';

export const getCaseIndexInBuildingFromCaseId = (project: Project, caseId: string) => {
  const buildingId = getBuildingIdFromCaseId(project, caseId);

  if (!buildingId) return -1;

  const buildingIndex = getBuildingIndexGivenBuildingId(project, buildingId);

  return project.buildings[buildingIndex].cases.findIndex(hasCaseId(caseId));
};
