import * as React from 'react';
import { ReactNode } from 'react';
import classNames from 'classnames';

interface WindowsGroupProps {
  children: ReactNode[];
}
export const WindowsGroup = ({ children }: WindowsGroupProps) => (
  <div className={classNames('windows-group')}>{children}</div>
);
