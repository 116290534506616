import * as React from 'react';
import { useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { selectNotifications } from '../../../store/selectors/notifications.selector';
import { selectIsInitializeProject } from '../../../store/selectors/project/isInitializeProject.selector';
import HeadIcon from './HeadIcon';
import Line from './Line';
import Arrow from './Arrow';

const NotificationBar = () => {
  const notifications = useSelector(selectNotifications);
  const isInitializeProject = useSelector(selectIsInitializeProject);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  if (!isInitializeProject || !notifications) return null;

  const isEmpty = notifications.length === 0;

  return (
    <div
      className={classNames('notification-bar-wrapper', {
        empty: notifications.length === 0
      })}>
      <div
        className={classNames('bar', 'notification-bar', {
          empty: isEmpty
        })}>
        <div className="head">
          <HeadIcon
            notifications={notifications}
            setIsOpen={setIsOpen}
            isOpen={isOpen && !isEmpty}
          />
          {notifications.length > 0 && (
            <div className="headNotification">
              <Line notification={notifications[0]} truncate />
            </div>
          )}
        </div>
      </div>
      <div
        className={classNames('bar', 'notification-bar-expander', {
          empty: isEmpty,
          open: isOpen && !isEmpty
        })}>
        {notifications.length > 0 && (
          <div className="list">
            {notifications.map((notification, index) => (
              <Line notification={notification} key={index} icon explanation />
            ))}
          </div>
        )}
        <Arrow setIsOpen={setIsOpen} isOpen={isOpen && !isEmpty} />
      </div>
    </div>
  );
};

export default NotificationBar;
