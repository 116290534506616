import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { Card } from '../../../../ui/Card';
import { StairShaftCount } from './verticalDistribution/StairShaftCount';
import { ElevatorShaftCount } from './verticalDistribution/ElevatorShaftCount';
import { SmokeExtractionDuctsCount } from './verticalDistribution/SmokeExtractionDuctsCount';
import { OutsideStairShaftCount } from './verticalDistribution/OutsideStairShaftCount';

export const VerticalDistribution = () => {
  const { t } = useTranslation();

  return (
    <Card>
      <SpecialLabel label={t('Vertical distribution')} />
      <StairShaftCount />
      <ElevatorShaftCount />
      {process.env.GB_FEAT_SMOKE_EXTRACTION !== 'false' && <SmokeExtractionDuctsCount />}
      {process.env.GB_FEAT_OUTSIDE_STAIRS_SHAFT !== 'false' && <OutsideStairShaftCount />}
    </Card>
  );
};
