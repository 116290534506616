import i18next from 'i18next';
import { LivingRoomKitchenSpecification } from '../../specification/rooms/LivingRoomKitchenSpecification';

const DEFAULT_LIVING_ROOM_KITCHEN_K_WIDTH = 4.5;

const livingRoomKitchenK = (): LivingRoomKitchenSpecification =>
  ({
    name: 'livingRoomKitchenK',
    title: i18next.t('Living room/Kitchen (K)'),
    defaultWidth: DEFAULT_LIVING_ROOM_KITCHEN_K_WIDTH,
    icon: {
      type: 'object',
      strokeStyle: 'opened',
      customText: 'SEJ+CUIS',
      label: 'K'
    }
  } as const);

export default livingRoomKitchenK;
