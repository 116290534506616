import { createSelector, Selector } from 'reselect';
import { ifDefined } from '../../../utils/ifDefined';
import * as R from 'ramda';
import { selectWoodCubatureByBuilding } from './woodCubatureByBuilding.selector';
import { State } from '../../reducers';
import { Maybe } from '../../../utils/Maybe';

export const selectWoodCubatureByProject: Selector<State, Maybe<number>> = createSelector(
  [selectWoodCubatureByBuilding],
  ifDefined(R.pipe(R.values, R.sum))
);
