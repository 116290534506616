import * as turf from '@turf/turf';
import { Position } from '@turf/helpers/dist/js/lib/geojson';
import { getAngle } from '../../utils/geometry/getAngle';
import { getCenter } from '../../utils/geometry/getCenter';
import { getAxis } from '../../utils/geometry/getAxis';
import { getOrientation } from '../../utils/geometry/getOrientation';
import { FacadeSpecification } from './FacadeSpecification';
import { getLineDistance } from '../../utils/geometry/lineDistance';

export const getFacadeSpecifications = (points: Position[]): FacadeSpecification[] => {
  const facades: FacadeSpecification[] = [];
  const polygon = turf.polygon([points]);

  points.forEach((point, i) => {
    if (i + 1 === points.length) {
      return;
    }
    let startPoint = points[i];
    let endPoint = points[i + 1] || points[0];
    if (i === points.length) {
      startPoint = points[i - 1];
      endPoint = points[0];
    }

    const angle = getAngle(startPoint, endPoint);
    const distance = getLineDistance(startPoint, endPoint);
    const center = getCenter([startPoint, endPoint]);
    const axis = getAxis(angle);

    facades.push({
      id: 'facade' + (i + 1),
      angle,
      index: i + 1,
      axis,
      orientation: getOrientation(center, axis, polygon),
      center,
      point1: startPoint,
      point2: endPoint,
      distance: distance || 0, // 0 if distance === null
      order: i + 1,
      computed: i + 1 === points.length - 1,
      adjoiningRate: 0
    });
  });
  return facades;
};
