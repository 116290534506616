import * as R from 'ramda';
import { LevelGranulometryInitialEntries } from '../../../LevelGranulometry';
import { createTopLevelStairsShaftSection } from './create/createTopLevelStairsShaftSection';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { isGroundLevel } from '../../is/isGroundLevel';
import { isUpperLevel } from '../../is/isUpperLevel';
import { mustHaveStairShaftSections } from '../../mustHave/mustHaveStairShaftSections';
import { StairsShaftSection } from '../../../../sections/circulationSections/stairsShaft';
import { getCaseStairShaftSectionsCount } from '../../../../cases/queries/sections/counts/getCaseStairShaftSectionsCount';
import { isHighestLevel } from '../../is/isHighestLevel';

export const getTopLevelStairShaftSections = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryInitialEntries
): StairsShaftSection[] | [] => {
  if (mustHaveStairShaftSections(caseGranulometry)) {
    let stairsShafts: StairsShaftSection[] = [];
    R.times((i) => {
      const stairsShaft = createTopLevelStairsShaftSection(
        caseGranulometry,
        'stairs' + i + caseGranulometry.labelIndex
      );
      stairsShaft.openedAbove = !isHighestLevel(caseGranulometry, levelGranulometry);
      if (
        isUpperLevel(caseGranulometry, levelGranulometry) ||
        (isGroundLevel(levelGranulometry) &&
          caseGranulometry.initialSpecifications.projectedBasementLevelsCount > 0)
      ) {
        stairsShaft.openedBelow = true;
      }
      stairsShafts = [...stairsShafts, stairsShaft];
    })(getCaseStairShaftSectionsCount(caseGranulometry));
    return stairsShafts;
  }
  return [];
};
