import { SectionWithExposureRate } from '../../SectionWithExposureRate';
import { LevelGranulometry } from '../../../levels/LevelGranulometry';
import { getSectionRealOutsideWallsLinear } from '../walls/realWalls/getSectionRealOutsideWallsLinear';
import { getSectionWallsLinear } from '../walls/getSectionWallsLinear';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';

export const getSectionRealExposureRate = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry,
  section: SectionWithExposureRate
): number =>
  getSectionRealOutsideWallsLinear(caseGranulometry, levelGranulometry, section) /
  getSectionWallsLinear(caseGranulometry, levelGranulometry, section);
