import { Project } from '../../project/Project';
import { mapCases } from '../../project/map/mapCases';
import * as R from 'ramda';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import { LevelSpecification } from '../../specification/levels/LevelSpecification';
import {
  CommonPremiseSection,
  CommonPremiseSectionName
} from '../../granulometry/sections/commonPremiseSections/CommonPremiseSection';
import { TopLevelSpecification } from '../../specification/levels/TopLevelSpecification';
import { BasementLevelSpecification } from '../../specification/levels/BasementLevelSpecification';

export const migrateProject36To37 = (project: Project): Project =>
  mapCases((caseSpecification) =>
    R.pipe<[CaseSpecification], CaseSpecification>((cS) =>
      R.over(
        R.lensPath<
          CaseSpecification,
          readonly (TopLevelSpecification | BasementLevelSpecification)[]
        >(['levels']),
        R.map((levelSpecification) =>
          R.pipe<[LevelSpecification], LevelSpecification>((lS) =>
            R.over(
              R.lensPath(['commonPremiseSections']),
              R.when(
                R.pipe(R.isNil, R.not),
                R.map((commonPremiseSection: CommonPremiseSection) =>
                  R.pipe<
                    [CommonPremiseSection],
                    CommonPremiseSection,
                    CommonPremiseSection,
                    CommonPremiseSection,
                    CommonPremiseSection,
                    CommonPremiseSection
                  >(
                    (cPS) =>
                      cPS.name === CommonPremiseSectionName.MeetingSection
                        ? {
                            ...cPS,
                            minSurface: 10
                          }
                        : cPS,
                    (cPS) =>
                      cPS.name === CommonPremiseSectionName.RestaurantSection
                        ? {
                            ...cPS,
                            minSurface: 20
                          }
                        : cPS,
                    (cPS) =>
                      cPS.name === CommonPremiseSectionName.CollectiveKitchenSection
                        ? {
                            ...cPS,
                            minSurface: 60
                          }
                        : cPS,
                    (cPS) =>
                      cPS.name === CommonPremiseSectionName.CafeteriaSection
                        ? {
                            ...cPS,
                            minSurface: 10
                          }
                        : cPS,
                    (cPS) =>
                      cPS.name === CommonPremiseSectionName.BalneaSection
                        ? {
                            ...cPS,
                            minSurface: 10
                          }
                        : cPS
                  )(commonPremiseSection)
                )
              )
            )(lS)
          )(levelSpecification)
        ),
        cS
      )
    )(caseSpecification)
  )(project);
