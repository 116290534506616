import { switchMap } from 'rxjs/operators';
import { Epic, ofType, StateObservable } from 'redux-observable';
import { pipe } from 'fp-ts/function';
import * as OE from 'fp-ts-rxjs/ObservableEither';
import { from, of } from 'rxjs';
import { Action } from 'redux';
import { replace } from 'connected-react-router';
import { Md5 } from 'md5-typescript';
import { login } from '../../api/v1/login.api';
import { ApiAuthError } from '../../api/v1/errors/auth.error';
import { User } from '../../domain/User';
import { setUser } from '../../infra/sentry';
import userSlice, { Auth } from '../reducers/user.slice';
import { selectIsLoginRoute } from '../selectors/navigation/isLoginRoute.selector';
import { StateWithRouter } from '../reducers';

export const authentifyEpic: Epic = (actions$, state$) =>
  pipe(
    actions$,
    ofType(userSlice.actions.authenticate.type),
    switchMap(
      ({ payload: { email, password } }: ReturnType<typeof userSlice.actions.authenticate>) => {
        const passwordHash = Md5.init(password);
        return login(email, passwordHash);
      }
    ),
    OE.chain<{ auth: Auth; user: User }, Error, Action>((authentification) => {
      setUser(authentification.user);
      return OE.fromObservable(
        from(
          [
            userSlice.actions.authenticated(authentification),
            redirectLoginToProjects(state$)
          ].filter(Boolean) as Action[]
        )
      );
    }),
    OE.getOrElse<Error, Action>((error: Error) =>
      of(
        userSlice.actions.authenticationFailed({
          errorMessage:
            error instanceof ApiAuthError
              ? 'Mauvais utilisateur ou mot de passe'
              : "Erreur lors de l'authentification"
        })
      )
    )
  );

const redirectLoginToProjects = (state$: StateObservable<StateWithRouter>) =>
  selectIsLoginRoute(state$.value) ? replace('/projects') : undefined;
