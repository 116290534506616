import * as R from 'ramda';
import { createSelector, Selector } from 'reselect';
import { ifDefined } from '../../../utils/ifDefined';
import { LodgmentsSurfaceByBuildingCaseLevelAndType } from '../../../domain/granulometry/queries/getLodgmentsSurfaceByBuildingCaseLevelAndType';
import { LodgmentsSurfaceByLevelAndType } from '../../../domain/granulometry/cases/queries/sections/surfaces/getCaseLodgmentsSurfaceByLevelAndType';
import { Surface } from '../../../domain/specification/Surface';
import { LodgmentsSurfaceByType } from '../../../domain/granulometry/levels/queries/topLevels/sections/surfaces/getTopLevelLodgmentsSurfaceByType';
import { BuildingId } from '../../../domain/specification/buildings/BuildingSpecification';
import { CaseId } from '../../../domain/specification/cases/CaseSpecification';
import { selectLodgmentsSurfaceByBuildingCaseLevelAndType } from './lodgmentsSurfaceByBuildingCaseLevelAndType.selector';
import { State } from '../../../store/reducers';
import { Maybe } from '../../../utils/Maybe';

type LodgmentsSurfaceByBuildingAndCase = Maybe<Record<string, Record<string, Surface>>>;

export const selectLodgmentsSurfaceByBuildingAndCase: Selector<
  State,
  LodgmentsSurfaceByBuildingAndCase
> = createSelector(
  [selectLodgmentsSurfaceByBuildingCaseLevelAndType],
  ifDefined(
    (
      byBuildingCaseLevelAndType: LodgmentsSurfaceByBuildingCaseLevelAndType
    ): Record<BuildingId, Record<CaseId, Surface>> =>
      R.mapObjIndexed(
        R.mapObjIndexed((byLevelAndType: LodgmentsSurfaceByLevelAndType) =>
          R.pipe<[LodgmentsSurfaceByLevelAndType], LodgmentsSurfaceByType[], Surface[], Surface>(
            R.values,
            R.map(R.pipe(R.values, Surface.sum)),
            Surface.sum
          )(byLevelAndType)
        ),
        byBuildingCaseLevelAndType
      )
  )
);
