import { DerivedDataObject, Filter } from 'react-table';
import { findCustomPriceFromGivenProductId } from '../customPricesLists/findCustomPriceFromGivenProductId';
import { CustomPrice } from '../../../../../../domain/Account';

export const filterRowsAndPivotsThroughCustomPriceListSelect =
  (customPrices: CustomPrice[]) => (filter: Filter, row: DerivedDataObject) => {
    if (customPrices.length !== 0) {
      if (row._subRows === undefined) {
        return filter.value === 'customPricesOnly'
          ? findCustomPriceFromGivenProductId(row._original.productId)(customPrices) !== -1
          : true;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };
