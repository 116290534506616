import { uniqId } from '../../../../infra/uniqId';
import { BuildingSpecification } from '../BuildingSpecification';
import { createCaseSpecification } from '../../cases/queries/create/createCaseSpecification';
import { ProjectSurfaceInputMode } from '../../../project/Project';

interface CreateBuildingSpecificationParams {
  projectSurfaceInputMode: ProjectSurfaceInputMode;
}
export const createBuildingSpecification = ({
  projectSurfaceInputMode
}: CreateBuildingSpecificationParams): BuildingSpecification => ({
  id: uniqId(),
  cases: [
    createCaseSpecification({
      projectSurfaceInputMode,
      withRamp: true
    })
  ]
});
