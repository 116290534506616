import * as R from 'ramda';

export interface RouteMatch<P extends Record<string, string>> {
  matches: boolean;
  matchesExact: boolean;
  params?: P;
}

export const matchesPath =
  <P extends Record<string, string>>(
    regex: RegExp,
    paramsPositions: Record<keyof P, number> | {} = {}
  ) =>
  (path: string): RouteMatch<P> => {
    const matches = regex.test(path);

    if (!matches) {
      return { matches, matchesExact: false };
    }

    const matchesExact = new RegExp(regex.source + '$').test(path);
    const match = regex.exec(path);

    if (match === null || !match.length) {
      return { matches, matchesExact };
    }

    return {
      matches,
      matchesExact,
      params: R.mapObjIndexed<number, string>((position) => match[position], paramsPositions) as P
    };
  };
