import { LevelGranulometryFullFilled } from '../../../LevelGranulometry';
import { SQUARE_METER_SCALE } from '../../../../../../constants/appConstants';
import { isSectionAVerticalCirculation } from '../../../../sections/VerticalCirculationSection';
import { getSectionsTotalDrawnSurface } from '../../../../sections/queries/surfaces/getSectionsTotalDrawnSurface';
import { getLevelFullFilledContent } from '../../content/getLevelFullFilledContent';

export const getTopLevelRbsRulerLeft = (levelGranulometry: LevelGranulometryFullFilled): number => {
  const verticalCirculationsSurface = getSectionsTotalDrawnSurface(
    getLevelFullFilledContent(levelGranulometry).filter((section) =>
      isSectionAVerticalCirculation(section)
    )
  );
  return verticalCirculationsSurface * SQUARE_METER_SCALE;
};
