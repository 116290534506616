import { layers } from '../styles/layers';
import * as React from 'react';
import MapboxDraw, { MapboxDrawOptions } from '@mapbox/mapbox-gl-draw';
import { drawStyle } from '../styles/draw/draw.style';
import { Position } from '@turf/helpers/dist/js/lib/geojson';

export const getMapboxOptions = (
  mapContainer: React.MutableRefObject<HTMLDivElement>,
  location: Position
) => ({
  userProperties: true,
  container: mapContainer.current,
  style: {
    version: 8,
    name: 'Cadastre map',
    glyphs: 'mapbox://fonts/mapbox/{fontstack}/{range}.pbf',
    sources: {
      'mapbox-streets': {
        type: 'vector',
        url: 'mapbox://mapbox.mapbox-streets-v6'
      },
      cadastre_layers: {
        type: 'vector',
        tiles: ['https://openmaptiles.geo.data.gouv.fr/data/cadastre/{z}/{x}/{y}.pbf'],
        maxzoom: 14,
        minzoom: 13
      }
    },
    layers
  },
  center: location,
  zoom: 22
});

export const getMapboxDrawOptions = (
  modes: MapboxDrawOptions['modes'],
  caseLabel?: string
): MapboxDraw.MapboxDrawOptions => {
  return {
    userProperties: true,
    displayControlsDefault: false,
    controls: {
      polygon: caseLabel === undefined,
      trash: caseLabel === undefined
    },
    modes,
    styles: drawStyle
  };
};
