import { CaseId } from '../../../../../domain/specification/cases/CaseSpecification';
import { LodgmentType } from '../../../../../domain/specification/lodgmentTypes/LodgmentType';
import { Action } from 'redux';
import { push } from 'connected-react-router';
import { caseGranulometryEditLodgmentTypeRoomRoute } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditLodgmentTypeRoomRoute';

export const goToCaseGranulometryEditLodgmentTypeRoom = (
  projectId: string,
  caseId: CaseId,
  lodgmentType: LodgmentType,
  roomIndex: number
): Action =>
  push(
    caseGranulometryEditLodgmentTypeRoomRoute.makePath({
      projectId,
      caseId,
      lodgmentType,
      roomIndex
    })
  );
