import { SectionWithExposureRate } from '../../../SectionWithExposureRate';
import { LevelGranulometry } from '../../../../levels/LevelGranulometry';
import { getSectionOutsideWallsThickness } from '../wallsThickness/getSectionOutsideWallsThickness';
import { getSectionRealOutsideWallsLinear } from './getSectionRealOutsideWallsLinear';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';

export const getSectionRealOutsideWallsSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry,
  section: SectionWithExposureRate
): number => {
  const realOutsideInsulatedWallsInternalLinear = getSectionRealOutsideWallsLinear(
    caseGranulometry,
    levelGranulometry,
    section
  );
  const outsideWallsThickness = getSectionOutsideWallsThickness(caseGranulometry);
  return realOutsideInsulatedWallsInternalLinear * (outsideWallsThickness / 2);
};
