import { createSelector, Selector } from 'reselect';
import {
  getBuildingMapState,
  getCstbState,
  getProjectState,
  getReportState,
  getUserState
} from '../reducers';
import { PersistedState } from 'redux-persist';
import { State } from '../../store/reducers';

export const selectIsStoreRehydrated: Selector<State, boolean> = createSelector(
  [getProjectState, getBuildingMapState, getReportState, getCstbState, getUserState],
  (...states: PersistedState[]) => states.every((state) => state && state._persist.rehydrated)
);
