import * as R from 'ramda';
import { createSelector, Selector } from 'reselect';
import { selectPath } from '../../getPath.selector';
import { selectCurrentCaseProperties } from '../../../specification/currentCase/properties.selector';
import { Property } from '../../../../../domain/specification/cases/Property';
import { State } from '../../../../reducers';
import { Maybe } from '../../../../../utils/Maybe';
import { caseGranulometryEditOtherPropertiesPropertyRoute } from '../../../../../routes/toolbox/casesPanel/caseGranulometryEditOtherPropertiesPropertyRoute';

export const selectCurrentlyEditedProperty: Selector<State, Maybe<Property>> = createSelector(
  [selectCurrentCaseProperties, selectPath],
  (currentCaseProperties, path) =>
    currentCaseProperties?.find(
      R.propEq(
        'name',
        caseGranulometryEditOtherPropertiesPropertyRoute.matchesPath(path).params?.propertyName
      )
    )
);
