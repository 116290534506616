import { BalconySpecification } from '../../BalconySpecification';
import { getBalconySpecificationCalculatedSurface } from './getBalconySpecificationCalculatedSurface';

type GetBasicRoomSpecificationSurface = (
  lodgmentSurface: number,
  balconySurfaceOnRealLivingSurfaceRate: number,
  roomSpecification: BalconySpecification
) => number;
export const getBalconySpecificationSurface: GetBasicRoomSpecificationSurface = (
  lodgmentSurface,
  balconySurfaceOnRealLivingSurfaceRate,
  roomSpecification
) =>
  roomSpecification.surface ||
  getBalconySpecificationCalculatedSurface(lodgmentSurface, balconySurfaceOnRealLivingSurfaceRate);
