import { post } from './admin.api';
import { ExistingProject } from '../../domain/project/Project';
import { User } from '../../domain/User';
import { AuthToken } from '../../AuthToken';

export const saveProjectState = async (
  project: ExistingProject,
  userId: User['id'],
  userToken: AuthToken
) => {
  const postProjectState = await Promise.all([
    post(`/Projects/update/${project.id}`, userToken, { projectName: project.name }),
    post(`/ProjectsStates/add/${userId}/${userToken}/${project.id}`, userToken, project)
  ]);
  if ((await postProjectState[1].text()) !== '') {
    throw new Error('Could not save granulometry');
  }
};
