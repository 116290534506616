import i18next from 'i18next';
import { BasicRoomSpecification } from '../../specification/rooms/BasicRoomSpecification';
import { RoomSpecification } from '../../specification/rooms/RoomSpecification';

const DEFAULT_CLEARANCE_LENGTH = 1.5;
const DEFAULT_CLEARANCE_WIDTH = 1.3;

const clearance = (): BasicRoomSpecification =>
  ({
    name: 'clearance',
    title: i18next.t('Clearance'),
    defaultLength: DEFAULT_CLEARANCE_LENGTH,
    width: undefined,
    defaultWidth: DEFAULT_CLEARANCE_WIDTH,
    surface: undefined,
    icon: {
      type: 'object',
      iconName: 'technical',
      strokeStyle: 'opened'
    }
  } as const);

export default clearance;

export const isRoomSpecificationAClearance = (
  roomSpecification: RoomSpecification
): roomSpecification is BasicRoomSpecification => roomSpecification.name === 'clearance';
