import { IconObject } from '../../../../../../ui/Icons/iconObject';
import React, { useState } from 'react';
import classNames from 'classnames';
import { WARNING_COLOR } from '../../../../../../../constants/appConstants';

interface LayerObjectProps {
  icon?: React.ReactNode;
  remove?: { action: () => void; label: string };
  select?: { action: () => void; label: string; unselect: () => void };
  duplicate?: { action: () => void; label: string };
  onIconClick?: () => void;
  isActive?: boolean;
  warn?: string;
  disabledDuplicate?: boolean;
}
export const LayerObject = ({
  icon,
  remove,
  select,
  duplicate,
  isActive,
  warn,
  onIconClick,
  disabledDuplicate = false
}: LayerObjectProps) => {
  const [selected, setSelected] = useState(false);
  const selectActions = () => {
    if (!select) return;
    setSelected(!selected);

    if (selected) return select.unselect();
    return select.action();
  };
  return (
    <div className={classNames('layerObject', { active: isActive, warn })} title={warn}>
      <div className={'icon'} onClick={onIconClick}>
        {icon}
      </div>
      <div className={'buttons'}>
        {duplicate && (
          <IconObject
            title={duplicate.label}
            iconName="copy"
            disabled={disabledDuplicate}
            type="menu"
            className="copy"
            onClick={duplicate.action}
          />
        )}
        {select && (
          <IconObject
            title={select.label}
            iconName="selectAll"
            type="menu"
            className="selectAll"
            onClick={selectActions}
          />
        )}
        {remove && (
          <IconObject
            iconName="remove"
            type="menu"
            className="minus"
            onClick={remove.action}
            title={remove.label}
          />
        )}
        {warn && (
          <IconObject
            type={'menu'}
            iconName={'pellet'}
            color={WARNING_COLOR}
            color2={WARNING_COLOR}
            clickable={false}
          />
        )}
      </div>
    </div>
  );
};
