import { getTopLevelRealBuiltSurface } from '../../../../../granulometry/levels/queries/topLevels/surfaces/realBuiltSurface/getTopLevelRealBuiltSurface';
import { isGroundLevel } from '../../../../../granulometry/levels/queries/is/isGroundLevel';
import { CaseProjection } from '../../../../../projection/cases/CaseProjection';

export const getCaseUpperLevelsRealBuiltSurface = (caseProjection: CaseProjection): number =>
  caseProjection.projectedTopLevels.reduce(
    (acc, level) =>
      !isGroundLevel(level) ? acc + getTopLevelRealBuiltSurface(level, caseProjection) : acc,
    0
  );
