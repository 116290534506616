import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../../LevelGranulometry';
import {
  ElevatorShaftSection,
  isSectionAElevatorShaft
} from '../../../../../sections/circulationSections/elevatorShaft';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelInsideWallsThickness } from '../wallsThickness/getTopLevelInsideWallsThickness';
import { mustHaveElevatorShaftSections } from '../../../mustHave/mustHaveElevatorShaftSections';
import { getLevelWithBearingRelativeSectionsContent } from '../../../content/getLevelWithBearingRelativeSectionsContent';

export const getTopLevelElevatorShafWallsSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithBearingRelativeSections | LevelGranulometry
): number =>
  mustHaveElevatorShaftSections(caseGranulometry)
    ? getTopLevelInsideWallsThickness(caseGranulometry) *
      (
        getLevelWithBearingRelativeSectionsContent(levelGranulometry).filter(
          isSectionAElevatorShaft
        ) as ElevatorShaftSection[]
      ).reduce(
        (acc, elevatorShaftSection) =>
          acc + (elevatorShaftSection.length + elevatorShaftSection.width) * 2,
        0
      )
    : 0;
