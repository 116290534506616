import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'components/ui/Button';
import { selectProjectId } from 'store/selectors/project';
import { selectFirstCase } from 'store/selectors/specification/firstCase.selector';
import NewBackButton from './NewBackButton';
import { Stepper } from '../../ui/Stepper';
import { CaseSpecification } from '../../../domain/specification/cases/CaseSpecification';
import { goToProjectDetails } from '../../../store/actions/navigations/toolbox/projectPanel/goToProjectDetails.action';
import { goToCase } from '../../../store/actions/navigations/toolbox/casesPanel/goToCase.action';

const NewBuildingsActionButtons: React.FC = () => {
  const projectId = useSelector(selectProjectId) as string;
  const firstCase = useSelector(selectFirstCase) as CaseSpecification;

  const dispatch = useDispatch();

  const { t } = useTranslation();

  return (
    <>
      <Stepper steps={[t('Project'), t('Buildings'), t('Cases')]} value={1} />
      <NewBackButton onClick={() => dispatch(goToProjectDetails(projectId))} />
      <Button
        isSubmit
        content={t('Next')}
        withLoader
        handleClick={() => dispatch(goToCase(projectId, firstCase.id))}
      />
    </>
  );
};

export default NewBuildingsActionButtons;
