import { Action } from 'redux';

export const CLOSE_PROJECT = 'CLOSE_PROJECT';

export interface CloseProjectAction extends Action {
  type: typeof CLOSE_PROJECT;
  payload: {
    id: string;
  };
}

export const closeProject = (id: string): CloseProjectAction => ({
  type: CLOSE_PROJECT,
  payload: { id }
});
