import { ProjectProjection } from '../../ProjectProjection';
import { CaseProjectionWithIndexes } from '../../../cases/CaseProjection';

export const getProjectCasesProjections = (
  project: ProjectProjection
): CaseProjectionWithIndexes[] =>
  project.buildings.reduce(
    (casesAcc, building, buildingIndex) => [
      ...casesAcc,
      ...building.cases.map(
        (eachCase, indexInBuilding) =>
          ({
            ...eachCase,
            buildingIndex,
            buildingId: building.id,
            indexInBuilding
          } as CaseProjectionWithIndexes)
      )
    ],
    [] as CaseProjectionWithIndexes[]
  ) as CaseProjectionWithIndexes[];
