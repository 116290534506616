const privateRoutes = {
  Root: {
    path: '/'
  },
  OpenProject: {
    path: '/open-project'
  },
  DuplicateProject: {
    path: '/duplicate-project'
  },
  Projects: {
    path: '/projects'
  },
  Project: {
    path: '/project/:projectId'
  }
};

export default privateRoutes;
