import { SectionWithExposureRate } from '../../../SectionWithExposureRate';
import { LevelGranulometry } from '../../../../levels/LevelGranulometry';
import { getLodgmentSectionOutsideWallsLinearLevelRatio } from '../lodgmentWalls/getLodgmentSectionOutsideWallsLinearLevelRatio';
import { CaseGranulometry } from '../../../../cases/CaseGranulometry';
import { getTopLevelRealOutsideInsulatedWallsInternalLinear } from '../../../../levels/queries/topLevels/walls/realWalls/getTopLevelRealOutsideInsulatedWallsInternalLinear';

export const getSectionRealOutsideWallsLinear = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry,
  section: SectionWithExposureRate
): number =>
  getLodgmentSectionOutsideWallsLinearLevelRatio(caseGranulometry, levelGranulometry, section) *
  getTopLevelRealOutsideInsulatedWallsInternalLinear(caseGranulometry, levelGranulometry);
