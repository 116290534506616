import { CaseGranulometry } from '../../CaseGranulometry';
import { getTopLevelGrossFloorSurfaceEff } from '../../../levels/queries/topLevels/surfaces/grossFloorSurfaces/getTopLevelGrossFloorSurfaceEff';
import { getCaseTopLevels } from '../levels/getCaseTopLevels';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { getLevelLabel } from '../../../levels/LevelGranulometry';
import { getCaseGrossFloorSurfaceEff } from './getCaseGrossFloorSurfaceEff';

export const getCaseGrossFloorSurfaceEffDetails = (caseGranulometry: CaseGranulometry): string => {
  let details: string[] = [];

  details = [
    ...details,
    '<b>SHOB VPP de la cage calculée à partir de la somme des SHOB VPP dessinées à chaque niveau :</b>'
  ];

  details = [...details, '---'];

  details = getCaseTopLevels(caseGranulometry).reduce(
    (acc, levelGranulometry, currentIndex) => [
      ...acc,
      '<b>' +
        (currentIndex !== 0 ? '+ ' : '') +
        roundWith2Decimal(getTopLevelGrossFloorSurfaceEff(caseGranulometry, levelGranulometry)) +
        ' m\u00B2</b> de SHOB VPP (déssinée) au ' +
        getLevelLabel(levelGranulometry.level)
    ],
    details
  );

  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(getCaseGrossFloorSurfaceEff(caseGranulometry).value) +
      ' m\u00B2</b>'
  ];

  return details.join('<br />');
};
