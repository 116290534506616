import * as React from 'react';
import { APP_COLOR } from '../../../constants/appConstants';
import { fixedEncodeURIComponent } from '../../../utils/fixedEncodeURIComponent';
import { iconFontCharacters } from '../../../utils/icons/iconFontCharacters';
import { removeDiacritics } from '../../../utils/strings/removeDiacritics';
import { uniqId } from '../../../infra/uniqId';

export interface IconFontProps {
  /** color: the characters color */
  color?: string;
  /** boolean: dev if we need a css colorization of the result. */
  dev?: boolean;
  /** string: id a unique id attributed to the wrapper of the result */
  id?: string;
  /** string: justify the characters text alignment (center|left|right). */
  justify?: 'center' | 'left' | 'right';
  /** float: letterSpacing the space between letters (1 = 1px) */
  letterSpacing?: number;
  /** float: lineSpacing the space between words (default = letterSpacing) */
  lineSpacing?: number;
  /** float: wordSpacing the space between words (default = letterSpacing*3) */
  wordSpacing?: number;
  /** string: the characters to print */
  returnString?: string;

  onClick?: any;
}

export const IconFont = (props: IconFontProps) => {
  let rString = props.returnString && props.returnString !== '' ? props.returnString : 'NO STRING!';
  rString = removeDiacritics(rString);
  const id = props.id ? props.id : uniqId();
  const color = props.color ? props.color : APP_COLOR;
  const letterSpacing = props.letterSpacing ? props.letterSpacing : 1;
  const wordSpacing = props.wordSpacing ? props.wordSpacing : letterSpacing * 3;
  const lineSpacing = props.lineSpacing ? props.lineSpacing : letterSpacing;
  const justify = props.justify ? props.justify : 'left';
  const dev = props.dev ? props.dev : false;
  // CSS
  const customCss =
    '.iconFont' +
    id +
    ' { justify-content:' +
    justify +
    '; }' +
    ' .iconFont' +
    id +
    ' .iconFontSpace { height:' +
    (6 + lineSpacing) +
    'px;width:' +
    (wordSpacing - letterSpacing) +
    'px; }' +
    ' .iconFont' +
    id +
    ' .iconFontCharacter { height:' +
    (6 + lineSpacing) +
    'px;width:' +
    (5 + letterSpacing) +
    'px; }' +
    ' .iconFont' +
    id +
    ' path { fill:' +
    color +
    '; }';
  const css = '<style>' + customCss + '</style>';
  // HTML
  let font = '<div class="iconFont iconFont' + id + (dev ? ' dev' : '') + '">';
  const stringCharacters = rString.split('');
  stringCharacters.forEach((letter: string) => {
    if (letter === ' ') {
      font += '<div class="iconFontSpace"></div>';
    } else {
      let character = fixedEncodeURIComponent(letter.toUpperCase());
      // see : iconFontCharacters.js
      if (!iconFontCharacters[character]) {
        character = 'unknown';
      }
      font += '<div class="iconFontCharacter" data-char="' + letter + '">';
      font += '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 600">';
      font += iconFontCharacters[character];
      font += '</svg>';
      font += '</div>';
    }
  });
  font += '</div>';

  return <div onClick={props.onClick} dangerouslySetInnerHTML={{ __html: css + font }} />;
};
