import { MAX_ALLOWED_NUMBER_INPUT } from '../constants/appConstants';

export const secureInputChange = (value: number, min?: number, max?: number) => {
  if (value !== null && value !== undefined && !Number.isNaN(value)) {
    const minValue = min !== undefined ? min : 0;
    if (value < minValue) {
      return minValue;
    }
    const maxValue = max !== undefined ? max : MAX_ALLOWED_NUMBER_INPUT;
    if (value > maxValue) {
      return maxValue;
    }
  }
  return value;
};
