import { LevelGranulometry } from '../../../../LevelGranulometry';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { getTopLevelStructuralFacadeInternalLinears } from '../getTopLevelStructuralFacadeInternalLinears';
import { getTopLevelOutsideStructuralWallsThickness } from '../wallsThickness/getTopLevelOutsideStructuralWallsThickness';
import { getTopLevelRealOutsideStructuralWallsExternalLinear } from './getTopLevelRealOutsideStructuralWallsExternalLinear';

export const getTopLevelRealOutsideStructuralWallsExternalLinearDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry
): string => {
  let details: string[] = [];
  getTopLevelStructuralFacadeInternalLinears(caseGranulometry, levelGranulometry).forEach(
    (facadeLinear, index) => {
      details = [
        ...details,
        (index !== 0 ? '<b>+</b> ' : '') +
          '<b>' +
          roundWith2Decimal(facadeLinear) +
          ' m</b> de linéaire intérieur structurel pour la façade ' +
          (index + 1) +
          ' <b>+ ' +
          getTopLevelOutsideStructuralWallsThickness(caseGranulometry) +
          ' m</b> d’épaisseur de voile structurel.'
      ];
    }
  );
  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(
        getTopLevelRealOutsideStructuralWallsExternalLinear(caseGranulometry, levelGranulometry)
      ) +
      ' m</b>'
  ];
  return details.join('<br />');
};
