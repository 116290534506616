import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonProps } from '../../components/ui/Button';
import { Card } from '../../components/ui/Card';
import { FormGroup } from '../../components/ui/FormGroup';
import { SpecialLabel } from '../../components/ui/SpecialLabel';
import { Logo } from '../../components/ui/Logo';
import { Form } from '../../components/ui/Form';
import { Input } from '../../components/ui/input/Input';
import { Note } from '../../components/ui/Note';

interface Props {
  email: string;
  password: string;
  error?: string;
  onUserEmailChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onPasswordChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
}

const LoginForm: React.FC<Props> = ({
  email,
  password,
  error,
  onPasswordChange,
  onUserEmailChange,
  onSubmit
}) => {
  const { t } = useTranslation();
  return (
    <Card
      className="loginForm"
      header={
        <>
          <Logo />
          {t('Welcome')}
        </>
      }>
      <section>
        <div>
          <SpecialLabel label={t('Connection')} />
          <Form onSubmit={onSubmit}>
            <FormGroup>
              <label htmlFor="email">{t('Login')}</label>
              <Input name="email" type="text" value={email} handleChange={onUserEmailChange} />
            </FormGroup>
            <FormGroup>
              <label htmlFor="password">{t('Password')}</label>
              <Input
                name="password"
                type="password"
                value={password}
                handleChange={onPasswordChange}
              />
              {error && error.length !== 0 && <span className="error">{error}</span>}
            </FormGroup>
            {/* <span>
            <a target="_blank" href="http://gobuild.fr/forgot">
              {t('Forgot your password ?')}
            </a>
          </span> */}
            <FormGroup noFlex={true}>
              <Button
                content={t('Login !') as string}
                isSubmit={true}
                handleClick={onSubmit as unknown as ButtonProps['handleClick']}
                disabled={password.length < 3 || email.length < 3}
              />
            </FormGroup>
          </Form>
        </div>
        <div>
          <SpecialLabel label={t('Subscription')} />
          <Note>
            <p>
              <b>{t('Choose an offer and create an account !')}</b>
            </p>
            <p>{t('whatIsGobuild')}</p>
            <p>{t('gobuildAccess')}</p>
            <p dangerouslySetInnerHTML={{ __html: t('getInformations') }} />
            <p>{t('See you soon on GoBuild !')}</p>
          </Note>
        </div>
      </section>
      <section>
        <SpecialLabel label={t('Partners')} small={true} />
        <div className="partnersLogos">
          <div>
            <img alt="Perrenoud" title="Perrenoud" height={21} src="partners/perrenoud.jpg" />
            <img alt="VizCab" title="VizCab" height={14} src="partners/vizcab.jpg" />
            <img
              alt="SustainEcho"
              title="SustainEcho"
              height={13}
              style={{ marginTop: '5px' }}
              src="partners/sustainecho.jpg"
            />
            <img alt="BSS Slama" title="BSS Slama" height={18} src="partners/bbsslama.jpg" />
            <img alt="Nooco" title="Nooco" height={14} src="partners/nooco.svg" />
            <img alt="Cype" title="Cype" height={18} src="partners/cype.jpg" />
            <img alt="Izuba" title="Izuba" height={23} src="partners/izuba.jpg" />
          </div>
          <div>
            <img alt="CSTB" title="CSTB" height={16} src="partners/cstb.svg" />
            <img alt="ComEnv" title="CSTB – ComEnv" height={14} src="partners/comenv.jpg" />
            <img alt="ComEth" title="CSTB – ComEth" height={21} src="partners/cometh.jpg" />
            <span />
            <img alt="Ginger" title="Ginger" height={8} src="partners/ginger.jpg" />
            <img
              alt="Ginger Map"
              title="Ginger Map"
              height={12.4}
              style={{ marginTop: '-3.5px' }}
              src="partners/gingermap.jpg"
            />
            <span />
            <img alt="Untec" title="Ginger" height={20} src="partners/untec.jpg" />
          </div>
        </div>
      </section>
    </Card>
  );
};

export default LoginForm;
