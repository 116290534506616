import { getCaseLodgmentDistribution } from './getCaseLodgmentDistribution';
import { getCaseLodgmentData } from './getCaseLodgmentData';
import { getCaseAverageLodgmentSurface } from './getCaseAverageLodgmentSurface';
import { getCaseEligibleLodgmentAverageTotalCount } from './getCaseEligibleLodgmentAverageTotalCount';
import { LodgmentType } from '../../../../specification/lodgmentTypes/LodgmentType';
import { getSectionTheoreticalSurface } from '../../../../granulometry/sections/queries/surfaces/getSectionTheoreticalSurface';
import { CaseProjection } from '../../CaseProjection';

export type CaseEligibleLodgmentRealCountByType = {
  type: LodgmentType;
  count: number;
  distributionRate?: number;
  theoreticalSurface?: number;
  weightedSurface?: number;
  weightedRate?: number;
  deltaRate?: number;
  realAssignableRate?: number;
  realAssignableCount?: number;
  realAssignableRoundedCount?: number;
}[];

export const getCaseEligibleLodgmentRealCountByType = (
  caseProjection: CaseProjection,
  check?: boolean
): CaseEligibleLodgmentRealCountByType => {
  const distribution = getCaseLodgmentDistribution(caseProjection);
  const lodgmentData = getCaseLodgmentData(caseProjection);
  const caseAverageLodgmentSurface = getCaseAverageLodgmentSurface(caseProjection);
  const caseEligibleLodgmentAverageTotalCount =
    getCaseEligibleLodgmentAverageTotalCount(caseProjection);
  let caseEligibleLodgmentRealCountByType: CaseEligibleLodgmentRealCountByType = [];
  distribution.forEach((distrib) => {
    const lodgmentType = distrib.lodgmentType;
    const lodgmentTypeRate = distrib.rate;
    const lodgmentTypeTheoreticalSurface = getSectionTheoreticalSurface(lodgmentData[lodgmentType]);
    const lodgmentTypeWeightedSurface = lodgmentTypeTheoreticalSurface * (lodgmentTypeRate / 100);
    const lodgmentTypeWeightedRate = lodgmentTypeWeightedSurface / caseAverageLodgmentSurface;
    const lodgmentTypeDeltaRate = lodgmentTypeRate / 100 - lodgmentTypeWeightedRate;
    const lodgmentTypeRealAssignableRate = lodgmentTypeRate / 100 + lodgmentTypeDeltaRate;
    const lodgmentTypeRealAssignableCount =
      caseEligibleLodgmentAverageTotalCount * lodgmentTypeRealAssignableRate;
    const lodgmentTypeRealAssignableRoundedCount = Math.round(lodgmentTypeRealAssignableCount);
    if (check) {
      caseEligibleLodgmentRealCountByType = [
        ...caseEligibleLodgmentRealCountByType,
        {
          type: lodgmentType,
          distributionRate: lodgmentTypeRate,
          theoreticalSurface: lodgmentTypeTheoreticalSurface,
          weightedSurface: lodgmentTypeWeightedSurface,
          weightedRate: lodgmentTypeWeightedRate * 100,
          deltaRate: lodgmentTypeDeltaRate * 100,
          realAssignableRate: lodgmentTypeRealAssignableRate * 100,
          realAssignableCount: lodgmentTypeRealAssignableCount,
          realAssignableRoundedCount: lodgmentTypeRealAssignableRoundedCount
        }
      ] as unknown as CaseEligibleLodgmentRealCountByType;
    } else {
      caseEligibleLodgmentRealCountByType = [
        ...caseEligibleLodgmentRealCountByType,
        {
          type: lodgmentType,
          count: lodgmentTypeRealAssignableRoundedCount
        }
      ];
    }
  });

  return caseEligibleLodgmentRealCountByType;
};
