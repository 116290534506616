import { CURRENT_MIGRATION_VERSION, Project } from '../project/Project';
import { migrateProject0To1 } from './migrateFunctions/migrateProject0To1';
import { migrateProject1To2 } from './migrateFunctions/migrateProject1To2';
import { migrateProject2To3 } from './migrateFunctions/migrateProject2To3';
import { migrateProject3To4 } from './migrateFunctions/migrateProject3To4';
import { migrateProject4To5 } from './migrateFunctions/migrateProject4To5';
import { migrateProject5To6 } from './migrateFunctions/migrateProject5To6';
import { migrateProject6To7 } from './migrateFunctions/migrateProject6To7';
import { migrateProject7To8 } from './migrateFunctions/migrateProject7To8';
import { migrateProject8To9 } from './migrateFunctions/migrateProject8To9';
import { migrateProject9To10 } from './migrateFunctions/migrateProject9To10';
import { migrateProject10To11 } from './migrateFunctions/migrateProject10To11';
import { migrateProject11To12 } from './migrateFunctions/migrateProject11To12';
import { migrateProject12To13 } from './migrateFunctions/migrateProject12To13';
import { migrateProject13To14 } from './migrateFunctions/migrateProject13To14';
import { migrateProject14To15 } from './migrateFunctions/migrateProject14To15';
import { migrateProject15To16 } from './migrateFunctions/migrateProject15To16';
import { migrateProject16To17 } from './migrateFunctions/migrateProject16To17';
import { migrateProject17To19 } from './migrateFunctions/migrateProject17To19';
import { migrateProject19To20 } from './migrateFunctions/migrateProject19To20';
import { migrateProject20To21 } from './migrateFunctions/migrateProject20To21';
import { migrateProject21To22 } from './migrateFunctions/migrateProject21To22';
import { migrateProject22To23 } from './migrateFunctions/migrateProject22To23';
import { migrateProject23To24 } from './migrateFunctions/migrateProject23To24';
import { migrateProject24To25 } from './migrateFunctions/migrateProject24To25';
import { migrateProject25To26 } from './migrateFunctions/migrateProject25To26';
import { migrateProject26To27 } from './migrateFunctions/migrateProject26To27';
import { migrateProject27To28 } from './migrateFunctions/migrateProject27To28';
import { migrateProject28To29 } from './migrateFunctions/migrateProject28To29';
import { migrateProject29To30 } from './migrateFunctions/migrateProject29To30';
import { migrateProject30To31 } from './migrateFunctions/migrateProject30To31';
import { migrateProject31To32 } from './migrateFunctions/migrateProject31To32';
import { migrateProject32To33 } from './migrateFunctions/migrateProject32To33';
import { migrateProject33To34 } from './migrateFunctions/migrateProject33To34';
import { migrateProject34To35 } from './migrateFunctions/migrateProject34To35';
import { migrateProject35To36 } from './migrateFunctions/migrateProject35To36';
import { migrateProject36To37 } from './migrateFunctions/migrateProject36To37';
import { migrateProject37To38 } from './migrateFunctions/migrateProject37To38';

const migrations = {
  '1': migrateProject0To1, // Add a building if necessary
  '2': migrateProject1To2, // Rename properties (notExposed->inside, exposed->outside) in each case specification
  '3': migrateProject2To3, // Add installationCostRatio, bearingExposureRate, internalInsulationThickness and balconyInsulatingBreakRate property in each case specification
  '4': migrateProject3To4, // Add projectedSurface in project
  '5': migrateProject4To5, // Add theoreticalSurface, defaultTheoreticalSurface and remove surface in lodgmentsTypesSpecifications in each case specification
  '6': migrateProject5To6, // Update room specifications
  '7': migrateProject6To7, // Add carpentryType, customDistribution, editingStage in each case specification
  '8': migrateProject7To8, // Add stairsShaftCount and elevatorShaftCount properties + remove stairsShaft and elevatorShaft entries in each case specification
  '9': migrateProject8To9, // Add smokeExtractionDuctsCount, smokeExtractionDuctsWidth and smokeExtractionDuctsDepth properties in each case specification
  '10': migrateProject9To10, // Remove title entry from building specification
  '11': migrateProject10To11, // Add hasDoubledLodgmentInsideInsulating properties in each cases
  '12': migrateProject11To12, // Add defaultHeatingSystems in lodgmentsTypesSpecifications in each cases
  '13': migrateProject12To13, // Add bodyguardType in balcony room specifications
  '14': migrateProject13To14, // Update rampLength, highAdhesionSteelRateGroundSlab, insideWallsThickness, outsideWallsThickness and add insideWallsInfraThickness, outsideWallsInfraThickness case properties
  '15': migrateProject14To15, // Remove minimumBearingSurface case properties
  '16': migrateProject15To16, // Add heatingDistribution in each case specification
  '17': migrateProject16To17, // Change defaultHeatingSystems to CollectiveHeatPumpOnGroundWaterHeating for each lodgment types in each cases
  '19': migrateProject17To19, // Rename property outsideWallChipboardRate -> outsideWallMasonryRate and add masonryType + woodEnvelopeType in each case specification
  '20': migrateProject19To20, // Add or rename floorSpaceFeature in each case specification
  '21': migrateProject20To21, // Update groundSlabThickness property
  '22': migrateProject21To22, // Add carpentry occultations entries in each cases
  '23': migrateProject22To23, // Remove technicalSection entry and properties + add technicalPremises specification.
  '24': migrateProject23To24, // Update defaultLength for t4 and t5 clearance room specification
  '25': migrateProject24To25, // Add revision and reportRevision in project if necessary
  '26': migrateProject25To26, // Add customPriceListId in project if necessary
  '27': migrateProject26To27, // Update carParkDepth, carParkWidth, carParkBeamWidth, carBoxDepth, carBoxWidth and carBoxBeamWidth
  '28': migrateProject27To28, // Rename technicalPremises to technicalPremiseSections
  '29': migrateProject28To29, // Remove stairsShaftConcreteStairCount case properties, add 4 outsideStairShaft relative case properties, update 4 stairShaft relative case properties
  '30': migrateProject29To30, // Update elevatorShaftWidth and elevatorShaftLength case properties
  '31': migrateProject30To31, // Remove bearingTechnicalSheathWidth, bearingTechnicalSheathDepth, bearingTechnicalSheathCount, technicalSectionWidth, technicalSectionSurface, technicalSectionExposureRate case properties
  '32': migrateProject31To32, //  Update elevatorShaftLength case property
  '33': migrateProject32To33, //  Update elevatorShaftLength case property
  '34': migrateProject33To34, // Add currentInsulatingBreakRate and update hallSurface case property
  '35': migrateProject34To35, // Empty migration (merge issue)
  '36': migrateProject35To36, // Empty migration (merge issue)
  '37': migrateProject36To37, // Change common premise section surface min values
  '38': migrateProject37To38 // Add surfaceInputMode in Project and CaseSpecification + Rename in projet surface to cuttedBuiltSurface and projectedSurface to projectedCuttedBuiltSurface + add "id" and "geometry" entries in level specifications + remove "floorSpaceFeature" from case specification
};

export const migrateProject = (project: Project) => {
  const version = project.migrationVersion || 0;

  return {
    ...(Object.keys(migrations) as [keyof typeof migrations])
      .filter((key) => parseInt(key) > version)
      .reduce((acc, key) => migrations[key](acc), project),
    migrationVersion: CURRENT_MIGRATION_VERSION
  };
};
