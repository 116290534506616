import { LodgmentTypesDistribution } from '../lodgmentTypes/LodgmentTypesDistribution';
import { LodgmentTypeSpecification } from '../lodgmentTypes/LodgmentTypeSpecification';
import { ParkingsSpecification } from '../lodgmentTypes/ParkingsSpecification';
import { Property } from './Property';
import { CaseCustomDistribution, CaseSurface } from './CaseSurfaceSpecification';
import { BuildingId } from '../buildings/BuildingSpecification';
import { HeatingDistribution } from './HeatingDistribution';
import { CarpentryType } from './CarpentryType';
import { RoofingType } from './RoofingType';
import { MasonryType } from './MasonryType';
import { WoodEnvelopeType } from './WoodEnvelopeType';
import { CarpentryOccultationsType } from './CarpentryOccultationsType';
import { CarpentryOccultationsMotorisationType } from './CarpentryOccultationsMotorisationType';
import { ProjectSurfaceInputMode } from '../../project/Project';
import { TopLevelSpecification } from '../levels/TopLevelSpecification';
import { BasementLevelSpecification } from '../levels/BasementLevelSpecification';

export type CaseId = string;

export enum CaseEditingStageName {
  Granulation = 'granulometry',
  Distribution = 'distribution',
  Completion = 'completion'
}

export type CaseEditingStage =
  | CaseEditingStageName.Granulation
  | CaseEditingStageName.Distribution
  | CaseEditingStageName.Completion;

export interface CaseSpecification {
  id: CaseId;
  name: 'case';
  surfaces: CaseSurface;
  levels: (TopLevelSpecification | BasementLevelSpecification)[];
  topLevelsCount?: number;
  basementLevelsCount?: number;
  distribution: LodgmentTypesDistribution;
  lodgmentsTypesSpecifications: LodgmentTypeSpecification[];
  parking: ParkingsSpecification; // TODO : Rename parking -> parkingSpecifications and ParkingsSpecification -> ParkingSpecifications
  builtInRamp: boolean;
  hall: boolean;
  properties: Property[];
  roofingType: RoofingType;
  carpentryType: CarpentryType;
  carpentryDayTimeOccultationsType: CarpentryOccultationsType;
  carpentryDayTimeOccultationsTypeIsMonobloc: boolean;
  carpentryNightTimeOccultationsType: CarpentryOccultationsType;
  carpentryNightTimeOccultationsTypeIsMonobloc: boolean;
  carpentryOccultationsMotorisationType: CarpentryOccultationsMotorisationType;
  masonryType: MasonryType;
  woodEnvelopeType: WoodEnvelopeType;
  heatingDistribution: HeatingDistribution;
  customDistribution: CaseCustomDistribution;
  editingStage: CaseEditingStage;
  projectSurfaceInputMode: ProjectSurfaceInputMode;
}

export interface CaseSpecificationWithIndexes extends CaseSpecification {
  buildingIndex: number;
  buildingId: BuildingId;
  indexInBuilding: number;
}
