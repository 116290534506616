import { matchesPath } from '../../utils/matchesPath';

import { RouteWithMakePath } from '../../RouteWithMakePath';

export type DeliverablesArchivesRouteParams = { projectId: string };
export const deliverablesArchivesRoute: RouteWithMakePath<DeliverablesArchivesRouteParams> = {
  path: `/project/:projectId/deliverables/archives`,
  makePath: ({ projectId }) => `/project/${projectId}/deliverables/archives`,
  matchesPath: matchesPath<DeliverablesArchivesRouteParams>(
    /\/project\/([^/]+)\/deliverables\/archives/,
    {
      projectId: 1
    }
  )
};
