import { Position } from '@turf/helpers/dist/js/lib/geojson';
import { FacadeSpecification } from '../domain/facades/FacadeSpecification';
import { Feature, FeatureCollection, Polygon } from 'geojson';
import { CaseId } from '../../../domain/specification/cases/CaseSpecification';
import { BuildingId } from '../../../domain/specification/buildings/BuildingSpecification';
import { Layer, LayerWithLevelGeometry } from '../layer/layer.model';

interface DrawLevelGeometryProperties {
  origin?: Position;
  facades?: FacadeSpecification[];
  center?: Position;
  perimeter?: number;
  area?: number;
  shape?: {
    isSelected?: boolean;
    lock?: boolean;
  };
  drawHelper?: {
    id: string;
  };
}
export interface LevelGeometryProperties extends DrawLevelGeometryProperties {
  name: string;
  parentCaseId: CaseId;
  parentBuildingId: BuildingId;
  floor: number;
}

export type FloorSpaceFeatureId = string;

export interface LevelGeometry extends Feature<Polygon, LevelGeometryProperties> {
  id: FloorSpaceFeatureId;
}

export const isLayerWithLevelGeometry = (levelGeo: Layer): levelGeo is LayerWithLevelGeometry => {
  return !!levelGeo.draw;
};

export interface FloorSpaceCollection extends FeatureCollection {
  features: Array<LevelGeometry>;
}

export const isFloorSpaceFeature = (feature?: Feature): feature is LevelGeometry => {
  return !!(feature?.properties?.facades && feature.geometry);
};

export const isFeatureCollectionOfFloorSpaces = (
  featureCollection?: FeatureCollection
): featureCollection is {
  type: 'FeatureCollection';
  features: Array<LevelGeometry>;
} => {
  if (!featureCollection?.features || featureCollection.features.length === 0) return false;
  return isFloorSpaceFeature(featureCollection.features[0]);
};

export const getFloorSpaceByDrawHelperId = (
  drawHelperLayerName: string,
  featureCollection?: FeatureCollection
) => {
  return (
    isFeatureCollectionOfFloorSpaces(featureCollection) &&
    featureCollection.features.find(
      (feature: LevelGeometry) => feature.properties.drawHelper?.id === drawHelperLayerName
    )
  );
};
