import classNames from 'classnames';
import * as React from 'react';
import { ChangeEvent, ChangeEventHandler } from 'react';
import { NumericInput } from './NumericInput';
// import NumericInput from 'react-numeric-input2'; // to see
import { Note } from '../Note';
import { TextInput } from './TextInput';
import { debounce } from 'lodash';

export type InputSize = 'small' | 'large';
export type InputColor = 'blue' | 'grey' | 'orange' | 'red' | 'green';

interface MinMax {
  min?: number;
  max?: number;
  unit?: string;
}

interface BaseProps {
  id?: string;
  label?: string;
  info?: string;
  asCustomValue?: boolean;
  className?: string;
  color?: InputColor;
  disabled?: boolean;
  inputStyle?: React.CSSProperties;
  name: string;
  placeholder?: number | string;
  size?: InputSize;
  style?: React.CSSProperties;
  suffix?: string;
  minMax?: MinMax;
  tabIndex?: number;
  icon?: React.ReactNode;
  handleClick?: React.MouseEventHandler<HTMLInputElement>;
  type: 'text' | 'password' | 'number';
}

interface NumericProps extends BaseProps {
  handleChange?: (
    valueAsNumber: number & ChangeEvent<HTMLInputElement>,
    valueAsString: string,
    input: HTMLInputElement
  ) => void;
  useDebounce?: boolean;
  min?: number;
  max?: number;
  precision?: number;
  step?: number;
  value?: number | string;
}

interface TextProps extends BaseProps {
  handleChange?: ChangeEventHandler<HTMLInputElement>;
  value?: string;
}

type InputProps = NumericProps | TextProps;

const isInputTypeNumber = (inputProps: InputProps): inputProps is NumericProps =>
  inputProps.type === 'number';

const isInputTypeText = (inputProps: InputProps): inputProps is TextProps =>
  inputProps.type === 'text' || inputProps.type === 'password';

export const Input = (props: InputProps) => {
  const {
    id,
    label,
    info,
    asCustomValue,
    className,
    color,
    disabled,
    inputStyle,
    name,
    placeholder,
    size,
    style,
    suffix,
    minMax,
    tabIndex,
    value,
    icon,
    handleClick
  } = props;

  const asCustomValueCalculated =
    placeholder !== undefined &&
    placeholder !== null &&
    placeholder !== '' &&
    value !== undefined &&
    value !== null &&
    value !== '';

  const classes = classNames(
    'gb-input',
    'gb-input--color--' + (color || 'blue'),
    {
      'gb-input--disabled': disabled,
      'gb-input--as-custom-value':
        asCustomValue !== undefined ? asCustomValue : asCustomValueCalculated,
      'gb-input--small': size === 'small',
      'gb-input--with-suffix': !!suffix,
      'gb-input--number': props.type === 'number'
    },
    className
  );

  const cleanedPlaceholder =
    placeholder === 0
      ? '0'
      : typeof placeholder === 'string'
      ? placeholder
      : placeholder !== undefined
      ? placeholder.toString()
      : undefined;

  const [openInfo, setOpenInfo] = React.useState<boolean>(false);

  const debouncedHandleChange = debounce(
    (
      valueAsNumber: number & ChangeEvent<HTMLInputElement>,
      valueAsString: string,
      input: HTMLInputElement
    ) =>
      props.handleChange !== undefined && props.handleChange(valueAsNumber, valueAsString, input),
    1500
  );

  const handleFocus: React.MouseEventHandler<HTMLInputElement> = (event) => {
    // see : https://developer.mozilla.org/en-US/docs/Web/API/HTMLInputElement/select
    // @ts-ignore
    if (event.target && event.target.select !== undefined) event.target.select();
  };

  return (
    <>
      {label && (
        <label htmlFor={name}>
          {label}
          {info && (
            <div
              className={'infoBtn' + (openInfo ? ' open' : '')}
              onClick={() => setOpenInfo(!openInfo)}>
              i
            </div>
          )}
        </label>
      )}
      {icon && <div className="gb-input-icon">{icon}</div>}
      <div id={id} className={classes} style={style}>
        {isInputTypeNumber(props) && (
          <NumericInput
            disabled={disabled}
            name={name}
            type={'number'}
            value={value}
            style={false}
            placeholder={cleanedPlaceholder}
            tabIndex={tabIndex}
            min={props.min}
            max={props.max}
            step={props.step}
            precision={props.precision}
            // TODO fix change when typing 14.06 (it works only with 14,06)
            parse={(valueAsString: string) => parseFloat(valueAsString.replace(',', '.'))}
            onChange={props.useDebounce === false ? props.handleChange : debouncedHandleChange}
            onClick={handleFocus}
            // autoComplete="off"
          />
        )}
        {isInputTypeText(props) && (
          <TextInput
            name={name}
            disabled={disabled}
            handleChange={props.handleChange}
            type={props.type as 'text' | 'password'}
            value={value}
            style={handleClick ? { ...inputStyle, cursor: 'pointer' } : inputStyle}
            placeholder={cleanedPlaceholder}
            tabIndex={tabIndex}
            handleClick={handleClick || handleFocus}
          />
        )}
        {suffix && (
          <span className="gb-input-suffix" dangerouslySetInnerHTML={{ __html: suffix }} />
        )}
        {info && openInfo && <div className="info" dangerouslySetInnerHTML={{ __html: info }} />}
      </div>
      {minMax && !(minMax.min === undefined && minMax.max === undefined) && (
        <div className="gb-input-minMax">
          <Note>
            {minMax.min !== undefined && 'min = ' + minMax.min}
            {minMax.unit !== undefined && ' ' + minMax.unit}
            {minMax.min !== undefined && minMax.max !== undefined && <br />}
            {minMax.max !== undefined && 'max = ' + minMax.max}
            {minMax.unit !== undefined && ' ' + minMax.unit}
          </Note>
        </div>
      )}
    </>
  );
};
