import { createSelector, Selector } from 'reselect';
import { ifDefined } from '../../../../utils/ifDefined';
import { selectCurrentCaseGranulometry } from '../currentCase/currentCaseGranulometry.selector';
import {
  getCaseParkingsCountByLevelAndType,
  ParkingsCountByLevelAndType
} from '../../../../domain/granulometry/cases/queries/sections/counts/getCaseParkingSectionsCountByLevelAndType';
import { State } from '../../../../store/reducers';
import { Maybe } from '../../../../utils/Maybe';

export const selectCurrentCaseParkingsCountByBasementLevelAndType: Selector<
  State,
  Maybe<ParkingsCountByLevelAndType>
> = createSelector([selectCurrentCaseGranulometry], ifDefined(getCaseParkingsCountByLevelAndType));
