import * as React from 'react';

export const ArrowFromRBSToCBSStart = () => (
  <div style={{ width: '24px', padding: '0 2px', boxSizing: 'border-box' }}>
    <div style={{ height: '22px' }}>
      <svg className="arrows" version="1.1" viewBox="0 0 177.5 220">
        <path d="M178,110l-46.6-26.9V104H41c-22.6,0-41,18.4-41,41v75h12v-75c0-16,13-29,29-29h90.4v20.9L178,110z" />
      </svg>
    </div>
  </div>
);
export const ArrowFromRBSToCBSEnd = () => (
  <div style={{ width: '24px', padding: '0 2px', boxSizing: 'border-box' }}>
    <div style={{ height: '22px' }}>
      <svg className="arrows" version="1.1" viewBox="0 0 177.5 220">
        <path d="M156.5,173.4V145c0-22.6-18.4-41-41-41H0v12h115.5c16,0,29,13,29,29v28.4h-20.9l26.9,46.6l26.9-46.6H156.5z" />
      </svg>
    </div>
  </div>
);
export const ArrowFromCBSToSFSStart = () => (
  <div style={{ width: '24px', padding: '0 2px', boxSizing: 'border-box' }}>
    <div style={{ height: '22px' }}>
      <svg className="arrows" version="1.1" viewBox="0 0 177.5 220">
        <path d="M178,110l-46.6-26.9V104H41c-22.6,0-41,18.4-41,41v75h12v-75c0-16,13-29,29-29h90.4v20.9L178,110z" />
      </svg>
    </div>
  </div>
);
export const ArrowFromCBSToSFSEnd = () => (
  <div style={{ width: '24px', padding: '0 2px', boxSizing: 'border-box' }}>
    <div style={{ height: '22px' }}>
      <svg className="arrows" version="1.1" viewBox="0 0 177.5 220">
        <path d="M156.5,173.4V145c0-22.6-18.4-41-41-41H0v12h115.5c16,0,29,13,29,29v28.4h-20.9l26.9,46.6l26.9-46.6H156.5z" />
      </svg>
    </div>
  </div>
);
export const ArrowFromRBSToSFSStart = () => (
  <div style={{ width: '71.5px', padding: '0 2px', boxSizing: 'border-box' }}>
    <div style={{ height: '22px' }}>
      <svg className="arrows" version="1.1" viewBox="0 0 640 220">
        <path d="M640,110l-46.6-26.9V104H40.5c-16,0-29-13-29-29V0h-12v75c0,22.6,18.4,41,41,41h552.9v20.9L640,110z" />
      </svg>
    </div>
  </div>
);
export const ArrowFromRBSToSFSEnd = () => (
  <div style={{ width: '71.5px', padding: '0 2px', boxSizing: 'border-box' }}>
    <div style={{ height: '22px' }}>
      <svg className="arrows" version="1.1" viewBox="0 0 640 220">
        <path d="M612.5,0l-26.9,46.6h20.9V75c0,16-13,29-29,29H0v12h577.5c22.6,0,41-18.4,41-41V46.6h20.9L612.5,0z" />
      </svg>
    </div>
  </div>
);
