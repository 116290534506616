import { FeatureParcel } from './Parcel';
import { Project } from './project/Project';

export const PROJECT_LOCATION_DEFAULT_STREET = '21 Place Bellecour';
export const PROJECT_LOCATION_DEFAULT_POSTAL_CODE = '69002';
export const PROJECT_LOCATION_DEFAULT_CITY = 'Lyon';
export const PROJECT_LOCATION_DEFAULT_COUNTRY = 'France';
export const PROJECT_LOCATION_DEFAULT_LONGITUDE = 45.756461;
export const PROJECT_LOCATION_DEFAULT_LATITUDE = 4.832825;

export interface ProjectLocation {
  street?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  longitude?: number;
  latitude?: number;
  parcels?: FeatureParcel[];
}

export type ProjectWithLocation = Project & {
  street: string;
  postalCode: string;
  city: string;
  country: string;
  longitude: number;
  latitude: number;
};
