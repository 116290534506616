import * as React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../ui/SpecialLabel';
import { selectCurrentCaseGranulometry } from '../../../../store/selectors/granulometry/currentCase/currentCaseGranulometry.selector';
import { selectCurrentCaseProjection } from '../../../../store/selectors/projection/currentCase/projection.selector';
import { DetailsTable } from '../../windows/CasesPanelWindows/LevelDetailsWindow/DetailsTable';
import { getCaseGrossFloorSurfaceEff } from '../../../../domain/granulometry/cases/queries/surfaces/getCaseGrossFloorSurfaceEff';
import { getCaseGrossFloorSurface } from '../../../../domain/granulometry/cases/queries/surfaces/getCaseGrossFloorSurface';
import { LevelCalculationDetail } from '../../windows/CasesPanelWindows/LevelDetailsWindow/TopLevelCalculationDetails';
import { getCaseGrossFloorSurfaceEffDetails } from '../../../../domain/granulometry/cases/queries/surfaces/getCaseGrossFloorSurfaceEff.details';
import { getCaseGrossFloorSurfaceDetails } from '../../../../domain/granulometry/cases/queries/surfaces/getCaseGrossFloorSurface.details';
import { getCaseRealBuiltSurfaceDetails } from '../../../../domain/projection/cases/queries/surfaces/getCaseRealBuiltSurface.details';
import { getCaseCuttedBuiltSurfaceDetails } from '../../../../domain/projection/cases/queries/surfaces/getCaseCuttedBuiltSurface.details';
import { selectProject } from '../../../../store/selectors/project';
import { getCaseSurfaceForSaleDetails } from '../../../../domain/projection/cases/queries/surfaces/getCaseSurfaceForSale.details';

interface CaseSurfacesDetailsProps {
  isToolboxEnlarged: boolean;
}
export const CaseSurfacesDetails = ({ isToolboxEnlarged }: CaseSurfacesDetailsProps) => {
  const project = useSelector(selectProject);
  const caseGranulometry = useSelector(selectCurrentCaseGranulometry);
  const caseProjection = useSelector(selectCurrentCaseProjection);

  const { t } = useTranslation();

  const [areDetailsOpen, setAreDetailsOpen] = React.useState<boolean>(false);

  if (!caseGranulometry || !caseProjection) return null;

  // const isGbEnvDevOrRecette = process.env.GB_ENV === 'dev' || process.env.GB_ENV === 'recette';

  const infos: (LevelCalculationDetail | null)[] = [
    {
      label: t('grossFloorSurfaceEff.label'),
      value: getCaseGrossFloorSurfaceEff(caseGranulometry).value,
      unit: 'm\u00B2',
      details:
        '<div class="note" style="font-size: inherit;margin: 0 0 5px 0">' +
        t('grossFloorSurfaceEff.infos') +
        '</div>' +
        getCaseGrossFloorSurfaceEffDetails(caseGranulometry)
    },
    {
      label: t('grossFloorSurface.label'),
      value: getCaseGrossFloorSurface(caseGranulometry).value,
      unit: 'm\u00B2',
      details:
        '<div class="note" style="font-size: inherit;margin: 0 0 5px 0">' +
        t('grossFloorSurface.infos') +
        '</div>' +
        getCaseGrossFloorSurfaceDetails(caseGranulometry)
    },
    {
      label: t('realBuiltSurface.label'),
      value: caseGranulometry.initialSpecifications.projectedRealBuiltSurface.value,
      unit: 'm\u00B2',
      details:
        '<div class="note" style="font-size: inherit;margin: 0 0 5px 0">' +
        t('realBuiltSurface.infos') +
        '</div>' +
        getCaseRealBuiltSurfaceDetails(caseGranulometry)
    },
    {
      label: t('cuttedBuildSurface.label'),
      value: caseGranulometry.initialSpecifications.projectedCuttedBuiltSurface.value,
      unit: 'm\u00B2',
      details:
        '<div class="note" style="font-size: inherit;margin: 0 0 5px 0">' +
        t('cuttedBuildSurface.infos') +
        '</div>' +
        (project ? getCaseCuttedBuiltSurfaceDetails(project, caseGranulometry) : '')
    },
    {
      label: t('surfaceForSale.label'),
      value: caseGranulometry.initialSpecifications.projectedSurfaceForSale.value,
      unit: 'm\u00B2',
      details:
        '<div class="note" style="font-size: inherit;margin: 0 0 5px 0">' +
        t('surfaceForSale.infos') +
        '</div>' +
        getCaseSurfaceForSaleDetails(caseGranulometry)
    }
  ];

  return (
    <>
      <SpecialLabel
        label={t('Surfaces details')}
        small
        closed={!areDetailsOpen}
        onClick={() => setAreDetailsOpen(!areDetailsOpen)}
      />
      {areDetailsOpen && (
        <DetailsTable infos={R.reject(R.isNil)(infos)} isToolboxEnlarged={isToolboxEnlarged} />
      )}
    </>
  );
};
