import * as React from 'react';
import { ReactNode } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { Card } from '../../../../ui/Card';
import { DetailsTable } from './DetailsTable';
import { selectCurrentLevelGranulometry } from '../../../../../store/selectors/granulometry/currentLevel/granulometry.selector';
import { selectCurrentCaseGranulometry } from '../../../../../store/selectors/granulometry/currentCase/currentCaseGranulometry.selector';
import { selectCurrentCaseProjection } from '../../../../../store/selectors/projection/currentCase/projection.selector';
import { getBasementLevelRealBuiltSurfaceDetails } from '../../../../../domain/granulometry/levels/queries/basementLevels/surfaces/getBasementLevelRealBuiltSurface.details';
import { getBasementLevelGrossFloorSurfaceEff } from '../../../../../domain/granulometry/levels/queries/basementLevels/surfaces/grossFloorSurfaces/getBasementLevelGrossFloorSurfaceEff';
import { getBasementLevelGrossFloorSurface } from '../../../../../domain/granulometry/levels/queries/basementLevels/surfaces/grossFloorSurfaces/getBasementLevelGrossFloorSurface';

export interface LevelCalculationDetail {
  label: string | ReactNode | ReactNode[];
  value?: number;
  unit?: string;
  separator?: boolean;
  details?: string;
  bold?: boolean;
}

export const BasementLevelCalculationDetails = () => {
  const levelGranulometry = useSelector(selectCurrentLevelGranulometry);
  const caseGranulometry = useSelector(selectCurrentCaseGranulometry);
  const caseProjection = useSelector(selectCurrentCaseProjection);

  const { t } = useTranslation();

  if (!levelGranulometry || !caseGranulometry || !caseProjection) return null;

  const isGbEnvDevOrRecette = process.env.GB_ENV === 'dev' || process.env.GB_ENV === 'recette';

  const infos: (LevelCalculationDetail | null)[] = [
    {
      label: t('RBS'),
      value: levelGranulometry.realBuiltSurface,
      unit: 'm\u00B2',
      details: isGbEnvDevOrRecette
        ? getBasementLevelRealBuiltSurfaceDetails(levelGranulometry, caseGranulometry)
        : undefined
    },
    // Gross floor surfaces
    {
      label: (
        <>
          {t('grossFloorSurfaceEff.label')}
          {/* <br />{' '}
          {' (' +
            t(
              hasFloorSpace
                ? isGroundLevel(levelGranulometry) ||
                  getTopLevelForcedGrossFloorSurfaceEff(caseGranulometry, levelGranulometry) !==
                    undefined
                  ? 'specified'
                  : mustTopLevelGfsEffBeAdjusted(caseGranulometry, levelGranulometry)
                  ? 'distributed and adjusted'
                  : 'distributed'
                : 'calculated'
            ) +
            ')'} */}
        </>
      ),
      value: getBasementLevelGrossFloorSurfaceEff(caseGranulometry, levelGranulometry),
      unit: 'm\u00B2',
      separator: true
      /* bold:
        hasFloorSpace &&
        (isGroundLevel(levelGranulometry) ||
          getTopLevelForcedGrossFloorSurfaceEff(caseGranulometry, levelGranulometry) !== undefined), */
      /* details: getTopLevelGrossFloorSurfaceEffDetails(
        caseGranulometry,
        levelGranulometry,
        hasFloorSpace
      ) */
    },
    {
      label: t('grossFloorSurface.label'),
      value: getBasementLevelGrossFloorSurface(caseGranulometry, levelGranulometry),
      unit: 'm\u00B2'
      /* details: getTopLevelGrossFloorSurfaceDetails(caseGranulometry, levelGranulometry) */
    }
  ];

  return (
    <Card>
      <SpecialLabel label={t('Level details')} />
      <DetailsTable infos={R.reject(R.isNil)(infos)} />
    </Card>
  );
};
