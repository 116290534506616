import * as React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CaseId } from '../../../../../../../domain/specification/cases/CaseSpecification';
import { Option, Select } from '../../../../../../ui/Select';
import { caseSpecificationChanged } from '../../../../../../../store/actions/changeCaseSpecification.action';
import { FormGroup } from '../../../../../../ui/FormGroup';
import { selectCurrentCaseCarpentryOccultationsMotorisationType } from '../../../../../../../store/selectors/specification/currentCase/carpentryOccultationsMotorisationType.selector';
import {
  CarpentryOccultationsMotorisationType,
  CarpentryOccultationsMotorisationTypeName,
  isCarpentryOccultationsMotorisationTypeSetForDay,
  isCarpentryOccultationsMotorisationTypeSetForNight
} from '../../../../../../../domain/specification/cases/CarpentryOccultationsMotorisationType';
import i18next from 'i18next';
import {
  CarpentryOccultationsType,
  DEFAULT_CARPENTRY_OCCULTATIONS_TYPE,
  isCarpentryOccultationsTypeUseWoodOrMetal
} from '../../../../../../../domain/specification/cases/CarpentryOccultationsType';

interface CarpentryOccultationsMotorisationTypeProps {
  caseId: CaseId;
  setIsSelectedCarpentryOccultationsMotorisationTypeSetForDay: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setIsSelectedCarpentryOccultationsMotorisationTypeSetForNight: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  carpentryOccultationsMotorisationTypeOptionsState: Option[];
}

export const CarpentryOccultationsMotorisationTypeForm = ({
  caseId,
  setIsSelectedCarpentryOccultationsMotorisationTypeSetForDay,
  setIsSelectedCarpentryOccultationsMotorisationTypeSetForNight,
  carpentryOccultationsMotorisationTypeOptionsState
}: CarpentryOccultationsMotorisationTypeProps) => {
  const carpentryOccultationsMotorisationType = useSelector(
    selectCurrentCaseCarpentryOccultationsMotorisationType
  ) as CarpentryOccultationsMotorisationType;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedCarpentryOccultationsMotorisationType =
    carpentryOccultationsMotorisationTypeOptionsState.find(
      (option) => option.value === carpentryOccultationsMotorisationType
    );

  const handleCarpentryOccultationsMotorisationTypeChange = React.useCallback(
    (option: Option) => {
      setIsSelectedCarpentryOccultationsMotorisationTypeSetForDay(
        isCarpentryOccultationsMotorisationTypeSetForDay(
          option.value as CarpentryOccultationsMotorisationType
        )
      );
      setIsSelectedCarpentryOccultationsMotorisationTypeSetForNight(
        isCarpentryOccultationsMotorisationTypeSetForNight(
          option.value as CarpentryOccultationsMotorisationType
        )
      );
      dispatch(
        caseSpecificationChanged(caseId, {
          carpentryOccultationsMotorisationType:
            option.value as CarpentryOccultationsMotorisationType
        })
      );
    },
    [
      dispatch,
      caseId,
      setIsSelectedCarpentryOccultationsMotorisationTypeSetForDay,
      setIsSelectedCarpentryOccultationsMotorisationTypeSetForNight
    ]
  );

  if (!selectedCarpentryOccultationsMotorisationType) return null;

  return (
    <FormGroup>
      <label htmlFor={`case${caseId}CarpentryOccultationsMotorisationType`}>
        {t('carpentrySpecifications.carpentryOccultationsMotorisationType.label')}
      </label>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyItems: 'center',
          marginTop: '4px'
        }}>
        <Select
          id={`case${caseId}CarpentryOccultationsMotorisationType`}
          options={carpentryOccultationsMotorisationTypeOptionsState}
          handleChange={handleCarpentryOccultationsMotorisationTypeChange}
          value={selectedCarpentryOccultationsMotorisationType}
          style={{ paddingRight: '8px', width: '148px' }}
        />
      </div>
    </FormGroup>
  );
};

export const carpentryOccultationsMotorisationTypeOptions: Option[] = [
  {
    label: i18next.t(
      'carpentrySpecifications.carpentryOccultationsMotorisationType.options.dayTime'
    ),
    value: CarpentryOccultationsMotorisationTypeName.DayTime
  },
  {
    label: i18next.t(
      'carpentrySpecifications.carpentryOccultationsMotorisationType.options.nightTime'
    ),
    value: CarpentryOccultationsMotorisationTypeName.NightTime
  },
  {
    label: i18next.t(
      'carpentrySpecifications.carpentryOccultationsMotorisationType.options.dayAndNightTime'
    ),
    value: CarpentryOccultationsMotorisationTypeName.DayAndNightTime
  },
  {
    label: i18next.t('carpentrySpecifications.carpentryOccultationsMotorisationType.options.none'),
    value: CarpentryOccultationsMotorisationTypeName.None
  }
];

export const refreshCarpentryOccultationsMotorisationTypeOptionsState = (
  selectedCarpentryDayTimeOccultationTypeOption?: Option,
  selectedCarpentryNightTimeOccultationTypeOption?: Option
): Option[] => {
  const isCarpentryDayTimeOccultationsTypeUseWoodOrMetal = (uselessPropForTs: Option[]) =>
    uselessPropForTs &&
    isCarpentryOccultationsTypeUseWoodOrMetal(
      (selectedCarpentryDayTimeOccultationTypeOption?.value as CarpentryOccultationsType) ||
        DEFAULT_CARPENTRY_OCCULTATIONS_TYPE
    );
  const isCarpentryNightTimeOccultationsTypeUseWoodOrMetal = (uselessPropForTs: Option[]) =>
    uselessPropForTs &&
    isCarpentryOccultationsTypeUseWoodOrMetal(
      (selectedCarpentryNightTimeOccultationTypeOption?.value as CarpentryOccultationsType) ||
        DEFAULT_CARPENTRY_OCCULTATIONS_TYPE
    );

  return R.pipe<[Option[]], Option[], Option[]>(
    R.ifElse(
      isCarpentryDayTimeOccultationsTypeUseWoodOrMetal,
      R.map<Option, Option>((option) => ({
        ...option,
        disabled:
          option.value === CarpentryOccultationsMotorisationTypeName.DayTime ||
          option.value === CarpentryOccultationsMotorisationTypeName.DayAndNightTime
      })),
      R.identity
    ),
    R.ifElse(
      isCarpentryNightTimeOccultationsTypeUseWoodOrMetal,
      R.map<Option, Option>((option) => ({
        ...option,
        disabled:
          option.disabled === true ||
          option.value === CarpentryOccultationsMotorisationTypeName.NightTime ||
          option.value === CarpentryOccultationsMotorisationTypeName.DayAndNightTime
      })),
      R.identity
    )
  )(carpentryOccultationsMotorisationTypeOptions);
};
