import * as React from 'react';
import { useMemo } from 'react';
import { DataTable } from '../../../../components/ui/DataTable';
import { getColumns } from './ReportTable/getColumns';
import { getDefaultExpandedRows } from './ReportTable/expands/getDefaultExpandedRows';
import { ThComponent } from './ReportTable/ThComponent';
import { PaginationComponent } from './ReportTable/PaginationComponent';
import { getTdProps } from './ReportTable/getTdProps';
import { getTrProps } from './ReportTable/getTrProps';
import { PivotComponent } from './ReportTable/PivotComponent';
import { useDispatch, useSelector } from 'react-redux';
import { selectReportDataTypeValueFromRoute } from '../../../../store/selectors/deliverables/reportDataTypeValueFromRoute.selector';
import { TdComponent } from './ReportTable/TdComponent';
import userState from '../../../../store/reducers/user.slice';
import { selectAccountCustomPriceLists } from '../../../../store/selectors/user/customPriceLists.selector';
import { getUpdatedCustomPriceLists } from './ReportTable/customPricesLists/getUpdatedCustomPriceLists';
import { getReportWithRecalculatedPrices } from './ReportTable/customPricesLists/getReportWithRecalculatedPrices';
import { setProjectCustomPriceListId } from '../../../../store/actions/setProjectCustomPriceListId.action';
import { Project } from '../../../../domain/project/Project';
import { selectProjectCurrentCustomPriceListId } from '../../../../store/selectors/project/projectCustomCustomPriceListId.selector';
import { selectIsSavingCustomPriceLists } from '../../../../store/selectors/user/isSavingCustomPriceLists.selector';
import { selectIsSavingProject } from '../../../../store/selectors/project/isSavingProject.selector';
import { getOptimisedReportForTable } from './ReportTable/getOptimisedReportForTable';
import { selectReportNomenclatureIdFromRoute } from '../../../../store/selectors/deliverables/reportNomenclatureIdFromRoute.selector';
import { LOTS_NOMENCLATURE_ID } from '../../../../domain/Nomenclature';
import { selectLotsReport } from '../../../../store/selectors/lotsReport.selector';
import { selectContributeursReport } from '../../../../store/selectors/contributeursReport.selector';
import { selectProjectCurrentCustomPriceList } from '../../../../store/selectors/project/projectCurrentCustomPriceList.selector';
import { DataTypeValue } from '../../../../domain/DataType';

interface ReportTableProps {
  apiVersion: 1 | 2;
}

export const ReportTable = ({ apiVersion }: ReportTableProps) => {
  // console.info('RENDER ReportTable !');

  // Manage loading :
  // TODO : Allow custom price lists and the project to be saved in the background without blocking the loading of the table
  const isSavingCustomPriceLists = useSelector(selectIsSavingCustomPriceLists);
  const isSavingProject = useSelector(selectIsSavingProject);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    // console.info('USE EFFECT loading !', isSavingCustomPriceLists, isSavingProject);
    setLoading(isSavingCustomPriceLists || isSavingProject);
  }, [isSavingCustomPriceLists, isSavingProject]);

  // Manage custom price lists :
  const accountCustomPriceLists = useSelector(selectAccountCustomPriceLists);
  const projectCurrentCustomPriceListId = useSelector(selectProjectCurrentCustomPriceListId);
  const projectCurrentCustomPriceList = useSelector(selectProjectCurrentCustomPriceList);

  // Manage report (table data) :
  const nomenclatureId = useSelector(selectReportNomenclatureIdFromRoute, () => true);
  const dataTypeValue = useSelector(
    selectReportDataTypeValueFromRoute,
    () => true
  ) as DataTypeValue;
  const requestedReport = useSelector(
    nomenclatureId === LOTS_NOMENCLATURE_ID ? selectLotsReport : selectContributeursReport
  );
  const optimisedReport = useMemo(() => {
    // console.info('REFRESH optimisedReport !');
    return getOptimisedReportForTable(requestedReport || [], dataTypeValue, apiVersion);
  }, [requestedReport, dataTypeValue, apiVersion]); // Note : All report treatments made by reduce method must be done in getOptimisedReportForTable as much as possible

  const reportWithRecalculatedPrices = useMemo(() => {
    // console.info('REFRESH reportWithRecalculatedPrices !');
    return projectCurrentCustomPriceList
      ? getReportWithRecalculatedPrices(optimisedReport, projectCurrentCustomPriceList)
      : optimisedReport;
  }, [optimisedReport, projectCurrentCustomPriceList]);

  // Manage expanded rows (pivots) :
  const defaultExpandedRows = useMemo(() => {
    // console.info('REFRESH defaultExpandedRows !');
    return getDefaultExpandedRows(optimisedReport);
  }, [optimisedReport]);
  const [expanded, setExpanded] = React.useState<{} | undefined>(defaultExpandedRows);
  const [subRowsIndex, setSubRowsIndex] = React.useState([]);

  // Manage folded columns :
  const [foldedColumns, setFoldedColumns] = React.useState([]);

  const dispatch = useDispatch();

  // Handle change custom price inputs :
  const handleCustomPriceChange = React.useCallback(
    (productId: string, price: number | null) => {
      // console.info('TRIGGER handleCustomPriceChange !');
      if (projectCurrentCustomPriceListId !== 'none') {
        const newCustomPriceLists = getUpdatedCustomPriceLists(
          accountCustomPriceLists,
          projectCurrentCustomPriceListId
        )(productId, price);
        setLoading(true);
        return dispatch(
          userState.actions.saveCustomPriceLists({
            newCustomPriceLists
          })
        );
      }
    },
    [accountCustomPriceLists, projectCurrentCustomPriceListId]
  );

  // Handle change price list id select :
  const handleCustomPriceListIdChange = React.useCallback(
    (newCustomPriceListId: Project['customPriceListId']) => {
      // console.info('TRIGGER handleCustomPriceListIdChange !');
      setLoading(true);
      return dispatch(setProjectCustomPriceListId(newCustomPriceListId));
    },
    []
  );

  // Manage table columns :
  const columns = useMemo(() => {
    // console.info('REFRESH columns !');
    return getColumns(
      reportWithRecalculatedPrices,
      dataTypeValue,
      subRowsIndex,
      foldedColumns,
      projectCurrentCustomPriceList,
      handleCustomPriceChange,
      handleCustomPriceListIdChange,
      accountCustomPriceLists
    );
  }, [subRowsIndex, foldedColumns, reportWithRecalculatedPrices]);

  return (
    <DataTable
      data={reportWithRecalculatedPrices}
      loading={loading}
      columns={columns}
      className={dataTypeValue + '-striped -highlight'}
      pivotBy={['refLot', 'refSsLot1', 'refSsLot2', 'refSsLot3', 'product']}
      expanded={expanded}
      defaultPageSize={100000}
      minRows={10}
      id="ReportTable"
      showPaginationTop={!loading}
      TdComponent={TdComponent}
      ThComponent={({ children, className, style }) => (
        <ThComponent
          className={className}
          style={style}
          foldedColumns={foldedColumns}
          setFoldedColumns={setFoldedColumns}>
          {children}
        </ThComponent>
      )}
      PaginationComponent={loading ? () => <></> : PaginationComponent}
      getTrProps={getTrProps}
      getTdProps={getTdProps}
      getTheadGroupThProps={() => {
        return { style: { display: foldedColumns.length === 0 ? 'block' : 'none' } };
      }}
      sortable={false}
      multiSort={false}
      onExpandedChange={setExpanded}
      onFilteredChange={() => {
        setSubRowsIndex([]);
        return true;
      }}
      PivotComponent={loading ? () => <></> : PivotComponent}
      showPaginationBottom={false}
      style={{
        height: 'calc(100% - 76px)' // This will force the table body to overflow and scroll, since there is not enough room
      }}
      gobuildCustomProps={{
        foldedColumns
      }}
    />
  );
};
