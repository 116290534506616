import { ExistingProject } from '../../domain/project/Project';
import { User } from '../../domain/User';
import { post } from './backend.api';
import fileDownload from 'js-file-download';
import { ProjectWithLocation } from '../../domain/ProjectLocation';

export const postGingerMaps = async (
  project: ExistingProject & ProjectWithLocation,
  user: User
): Promise<void> => {
  const response = await post('/geoReport', {
    projectId: project.id,
    projectName: project.name,
    userId: user.id,
    coordinates: [project.latitude, project.longitude],
    address: {
      street: project.street,
      city: project.city,
      postcode: project.postalCode
    }
  });

  const filename =
    response.headers
      .get('content-disposition')
      ?.split(';')
      .find((n) => n.includes('filename='))
      ?.replace('filename=', '')
      .trim() || 'NA';

  const blob = await response.blob();

  fileDownload(blob, filename);
};
