import {
  BuildingId,
  BuildingTitle
} from '../../domain/specification/buildings/BuildingSpecification';

export const SET_BUILDING_TITLE = 'SET_BUILDING_TITLE';

export const setBuildingTitle = (buildingId: BuildingId, buildingTitle?: BuildingTitle) =>
  <const>{
    type: SET_BUILDING_TITLE,
    payload: { buildingId, buildingTitle }
  };

export type SetBuildingTitleAction = ReturnType<typeof setBuildingTitle>;
