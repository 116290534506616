import { SpecialLabel } from '../../../../../ui/SpecialLabel';
import { IconObject } from '../../../../../ui/Icons/iconObject';
import { ucfirst } from '../../../../../../utils/strings/ucfirst';
import { GoBuildIcons } from '../../../../../../utils/icons/iconObjectIcons';
import { Option, Select } from '../../../../../ui/Select';
import {
  BodyguardType,
  isRoomSpecificationABalcony
} from '../../../../../../domain/specification/rooms/BalconySpecification';
import { Card } from '../../../../../ui/Card';
import * as React from 'react';
import { LodgmentTypeSpecification } from '../../../../../../domain/specification/lodgmentTypes/LodgmentTypeSpecification';
import { RoomSpecification } from '../../../../../../domain/specification/rooms/RoomSpecification';
import { useTranslation } from 'react-i18next';

interface BodyguardTypeCardProps {
  lodgmentTypeSpecification: LodgmentTypeSpecification;
  roomSpecification: RoomSpecification;
  handleSetLodgmentTypeRoom: (
    value: number | BodyguardType | null,
    fieldName: 'length' | 'width' | 'depth' | 'surface' | 'bodyguardType'
  ) => void;
}

export const BodyguardTypeCard = ({
  lodgmentTypeSpecification,
  roomSpecification,
  handleSetLodgmentTypeRoom
}: BodyguardTypeCardProps) => {
  const { t } = useTranslation();

  if (!isRoomSpecificationABalcony(roomSpecification)) return null;

  const selectBodyguardTypeOptions = [
    { label: t('Steel'), value: BodyguardType.Steel },
    { label: t('Glazed'), value: BodyguardType.Glazed }
  ];

  if (process.env.GB_FEAT_BODYGUARD_TYPE === 'false') return null;

  return (
    <Card>
      <SpecialLabel label={t('Bodyguard')} />
      <label htmlFor="naming">{t('bodyguardType.label')}</label>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
        <IconObject
          type="menu"
          iconName={('bodyguard' + ucfirst(roomSpecification.bodyguardType)) as GoBuildIcons}
          clickable={false}
        />
        <Select
          id={lodgmentTypeSpecification.lodgmentType + `BodyguardType`}
          options={selectBodyguardTypeOptions}
          handleChange={(props: Option) => {
            handleSetLodgmentTypeRoom(props.value as BodyguardType, 'bodyguardType');
          }}
          value={selectBodyguardTypeOptions.find(
            (option) => option.value === roomSpecification.bodyguardType
          )}
          style={{ marginLeft: '6px', flexBasis: '150px' }}
        />
      </div>
    </Card>
  );
};
