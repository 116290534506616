import { DEFAULT_PROJECT_SURFACE_INPUT_MODE, Project } from '../../project/Project';
import * as R from 'ramda';
import { mapCases } from '../../project/map/mapCases';
import { CaseSpecification } from '../../specification/cases/CaseSpecification';
import { getEmptyLevelGeometry } from '../../specification/levels/queries/get/getEmptyLevelGeometry';
import { getCaseLabelGivenCaseId } from '../../core/queries/getCaseLabelGivenCaseId';
import { getBuildingIdFromCaseId } from '../../core/queries/getBuildingIdFromCaseId';
import { isGroundLevel } from '../../specification/levels/queries/is/isGroundLevel';
import { TopLevelSpecification } from '../../specification/levels/TopLevelSpecification';
import { BasementLevelSpecification } from '../../specification/levels/BasementLevelSpecification';

export const migrateProject37To38 = (project: Project): Project =>
  R.pipe<[Project], Project, Project, Project, Project, Project, Project>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    R.assoc('cuttedBuiltSurface', project.surface),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    R.assoc('projectedCuttedBuiltSurface', project.projectedSurface),
    R.dissoc('surface'),
    R.dissoc('projectedSurface'),
    R.assoc('surfaceInputMode', DEFAULT_PROJECT_SURFACE_INPUT_MODE),
    mapCases((caseSpecification) =>
      R.pipe<[CaseSpecification], CaseSpecification, CaseSpecification, CaseSpecification>(
        R.assoc('projectSurfaceInputMode', DEFAULT_PROJECT_SURFACE_INPUT_MODE),
        R.over(
          R.lensProp('levels'),
          R.map((levelSpecification) => {
            const levelId =
              'LEVEL_C' +
              getCaseLabelGivenCaseId(project, caseSpecification.id) +
              'L' +
              levelSpecification.level +
              '_ID';
            const levelGeometryName =
              'C' +
              getCaseLabelGivenCaseId(project, caseSpecification.id) +
              'L' +
              levelSpecification.level;
            if (
              isGroundLevel(levelSpecification) &&
              !!(caseSpecification as unknown as any).floorSpaceFeature?.properties?.area
            ) {
              return {
                ...levelSpecification,
                id: levelId,
                geometry: {
                  id: levelId,
                  type: 'Feature',
                  properties: {
                    area: (caseSpecification as unknown as any).floorSpaceFeature?.properties?.area,
                    perimeter: undefined,
                    facades: (caseSpecification as unknown as any).floorSpaceFeature?.properties
                      ?.facades,
                    origin: (caseSpecification as unknown as any).floorSpaceFeature?.properties
                      .origin,
                    center: (caseSpecification as unknown as any).floorSpaceFeature?.properties
                      .center,
                    shape: (caseSpecification as unknown as any).floorSpaceFeature?.properties
                      .shape,
                    drawHelper: (caseSpecification as unknown as any).floorSpaceFeature?.properties
                      .drawHelper,
                    name: levelGeometryName,
                    floor: levelSpecification.level,
                    parentCaseId: caseSpecification.id,
                    parentBuildingId: getBuildingIdFromCaseId(
                      project,
                      caseSpecification.id
                    ) as string
                  }
                }
              } as TopLevelSpecification | BasementLevelSpecification;
            } else {
              return {
                ...levelSpecification,
                id: levelId,
                geometry: getEmptyLevelGeometry(
                  levelId,
                  levelGeometryName,
                  levelSpecification.level,
                  caseSpecification.id,
                  getBuildingIdFromCaseId(project, caseSpecification.id) as string
                )
              } as TopLevelSpecification | BasementLevelSpecification;
            }
          })
        ),
        R.dissoc('floorSpaceFeature')
      )(caseSpecification)
    )
  )(project);
