import { ProjectProjection } from '../../projection/project/ProjectProjection';
import { CaseProjection } from '../../projection/cases/CaseProjection';
import { Surface } from '../../specification/Surface';
import { getCaseSurfaceForSaleMinimum } from '../../projection/cases/queries/surfaces/getCaseSurfaceForSaleMinimum';

export const caseSurfaceForSaleIsSmallerThanTheMinimum = (
  projectProjection: ProjectProjection,
  caseProjection: CaseProjection
): boolean =>
  (caseProjection.projectedSurfaceForSale as Surface).value < getCaseSurfaceForSaleMinimum();
