import { FUNDATIONS_COLOR } from '../../../../constants/appConstants';
import { Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { FundationsName } from './FundationsSection';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';

interface PileFundationsProps {
  surface: number;
}

export interface PileFundationsSection extends Section, SectionDrawn {
  name: FundationsName.PileFundations;
}

export const PILE_FUNDATIONS_ICON = {
  type: 'object',
  iconName: FundationsName.PileFundations,
  color: FUNDATIONS_COLOR
} as IconObjectProps;

const pileFundations = ({ surface }: PileFundationsProps): PileFundationsSection => ({
  // Section
  id: 'NOID',
  name: FundationsName.PileFundations,
  title: i18next.t('pileFundations.title'),
  type: 'fundations',
  displayedSurface: surface,
  // SectionDrawn
  surface,
  color: FUNDATIONS_COLOR,
  icon: PILE_FUNDATIONS_ICON
});

export default pileFundations;
