import i18next from 'i18next';
import { LodgmentSection } from './LodgmentSection';
import { LodgmentTypeSpecification } from '../../../specification/lodgmentTypes/LodgmentTypeSpecification';
import { LodgmentType } from '../../../specification/lodgmentTypes/LodgmentType';
import { T4_COLOR } from '../../../../constants/appConstants';
import livingRoomKitchen from '../../rooms/livingRoomKitchen';
import kitchen from '../../rooms/kitchen';
import livingRoom from '../../rooms/livingRoom';
import bedroom2 from '../../rooms/bedroom2';
import bathroom from '../../rooms/bathroom';
import balcony from '../../rooms/balcony';
import entrance from '../../rooms/entrance';
import clearance from '../../rooms/clearance';
import toilet from '../../rooms/toilet';
import showerRoom from '../../rooms/showerRoom';
import bedroom3 from '../../rooms/bedroom3';
import bedroomPMRC from '../../rooms/bedroomPMRC';
import { HeatingSystem } from '../../../specification/lodgmentTypes/HeatingSystem';

export const t4 = (): LodgmentSection & LodgmentTypeSpecification => ({
  // Section
  id: 'NOID',
  name: LodgmentType.T4,
  title: i18next.t('t4.title'),
  type: 'lodgment',
  displayedSurface: 79,
  // SectionDrawn
  surface: 79,
  color: T4_COLOR,
  icon: {
    type: 'object',
    customText: 'T4',
    customTextPosition: 'bottom right',
    iconName: 'lodgment',
    color: T4_COLOR
  },
  // SectionWithTheoreticalSurface
  theoreticalSurface: undefined,
  defaultTheoreticalSurface: 79,
  // SectionWithMinimumSurface
  minimumDisplayedSurface: 74,
  // SectionWithExposureRate
  exposureRate: undefined,
  defaultExposureRate: 2.5,
  // LodgmentSection
  lodgmentType: LodgmentType.T4,
  rooms: [
    {
      ...entrance(),
      icon: { ...entrance().icon, color: T4_COLOR },
      defaultLength: 3.1,
      defaultWidth: 1.45
    },
    { ...livingRoomKitchen(), icon: { ...livingRoomKitchen().icon, color: T4_COLOR } },
    { ...kitchen(), icon: { ...kitchen().icon, color: T4_COLOR } },
    { ...livingRoom(), icon: { ...livingRoom().icon, color: T4_COLOR } },
    { ...bedroomPMRC(), icon: { ...bedroomPMRC().icon, color: T4_COLOR } },
    { ...showerRoom(), icon: { ...showerRoom().icon, color: T4_COLOR } },
    { ...bedroom2(), icon: { ...bedroom2().icon, color: T4_COLOR } },
    { ...bedroom3(), icon: { ...bedroom3().icon, color: T4_COLOR } },
    { ...bathroom(), icon: { ...bathroom().icon, color: T4_COLOR } },
    { ...toilet(), icon: { ...toilet().icon, color: T4_COLOR } },
    { ...clearance(), defaultLength: 2.25, icon: { ...clearance().icon, color: T4_COLOR } },
    { ...balcony(), icon: { ...balcony().icon, color: T4_COLOR } }
  ],
  heatingSystem: undefined,
  defaultHeatingSystem: HeatingSystem.CollectiveHeatPumpOnGroundWaterHeating,
  // SectionWithTechnicalSheaths
  technicalSheathCount: 0,
  // LodgmentTypeSpecification
  prevType: LodgmentType.T4C,
  nextType: LodgmentType.T5
});
