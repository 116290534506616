import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import {
  LevelGranulometry,
  LevelGranulometryWithBearingRelativeSections
} from '../../../levels/LevelGranulometry';
import { Surface } from '../../../../specification/Surface';
import { getBearingTechnicalSheathsWidth } from './getBearingTechnicalSheathsWidth';

export const TECHNICAL_SHEATHS_DEPTH = 0.35; // m

export const getBearingTechnicalSheathsSurface = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry | LevelGranulometryWithBearingRelativeSections
): Surface => {
  const bearingTechnicalSheathsWidth = getBearingTechnicalSheathsWidth(
    caseGranulometry,
    levelGranulometry
  );
  const bearingTechnicalSheathsSurface = bearingTechnicalSheathsWidth * TECHNICAL_SHEATHS_DEPTH;
  return new Surface(bearingTechnicalSheathsSurface);
};
