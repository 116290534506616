import { ProjectProjection } from '../../../project/ProjectProjection';
import { CaseProjection } from '../../CaseProjection';
import * as R from 'ramda';
import { MINIMUM_CASE_CBS } from '../../../../../constants/appConstants';
import { getProjectCasesReservedCuttedBuiltSurface } from '../../../../specification/project/queries/cases/getProjectCasesReservedCuttedBuiltSurface';
import { MAXIMUM_PROJECT_CBS } from '../../../../project/Project';

export const getCaseCuttedBuiltSurfaceMaximum = (
  projectProjection: ProjectProjection,
  caseProjection: CaseProjection
): number =>
  projectProjection.surfaceInputMode === 'cbs'
    ? R.max(
        MINIMUM_CASE_CBS,
        projectProjection.projectedCuttedBuiltSurface.value -
          getProjectCasesReservedCuttedBuiltSurface(projectProjection).value +
          (caseProjection.surfaces.cuttedBuiltSurface !== undefined
            ? caseProjection.surfaces.cuttedBuiltSurface.value
            : MINIMUM_CASE_CBS)
      )
    : MAXIMUM_PROJECT_CBS;
