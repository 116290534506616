import { ApiError } from './apiError';

export class ApiServerError extends ApiError {
  constructor(requestedUrl: string, statusCode: number, statusText: string) {
    super(
      `Server error (${statusCode}) when requested ${requestedUrl}`,
      requestedUrl,
      statusCode,
      statusText
    );
  }

  public isApiServerError = true;

  public static fromResponse(path: string) {
    return (response: Response) =>
      new ApiServerError(path, response.status, response.status.toString());
  }
}
