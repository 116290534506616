import * as R from 'ramda';
import {
  LevelGranulometry,
  LevelGranulometryFullFilled,
  LevelGranulometryWithBearingRelativeSections,
  LevelGranulometryWithLodgments
} from '../../../LevelGranulometry';
import { getTopLevelIncludedInSurfaceForSaleSections } from '../sections/getTopLevelIncludedInSurfaceForSaleSections';

export const getTopLevelIncludedInSurfaceForSaleSectionsCount = (
  levelGranulometry:
    | LevelGranulometryWithLodgments
    | LevelGranulometryWithBearingRelativeSections
    | LevelGranulometryFullFilled
    | LevelGranulometry
): number =>
  R.pipe(getTopLevelIncludedInSurfaceForSaleSections, R.reduce(R.inc, 0))(levelGranulometry);
