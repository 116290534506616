import * as React from 'react';
import { Dispatch } from 'react';
import { Button } from '../../../ui/Button';
import { CaseId } from '../../../../domain/specification/cases/CaseSpecification';
import { roundForSurfacesInputs } from '../../../../utils/round/roundForSurfacesInputs';
import { LevelId } from '../../../../domain/granulometry/levels/LevelGranulometry';
import { getCaseGranulometryEditLevelGeometryTriggerId } from '../../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditLevelGeometryTriggerId';
import { goToCaseGranulometryEditLevelGeometry } from '../../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditLevelGeometry.action';

interface GroundLevelGrossFloorSurfaceEffButtonProps {
  projectId: string | undefined;
  caseId: CaseId;
  levelId: LevelId;
  isSelected: boolean;
  grossFloorSurfaceEff: number | undefined;
  dispatch: Dispatch<any>;
  isFilled: boolean;
}

export const GrossFloorSurfaceEffButton = ({
  projectId,
  caseId,
  isSelected,
  grossFloorSurfaceEff,
  dispatch,
  isFilled,
  levelId
}: GroundLevelGrossFloorSurfaceEffButtonProps) => (
  <Button
    id={getCaseGranulometryEditLevelGeometryTriggerId(levelId)}
    selected={isSelected}
    fullWidth
    size="small"
    content={grossFloorSurfaceEff ? roundForSurfacesInputs(grossFloorSurfaceEff) : 'ERR!'}
    appearance="outline"
    asCustomValue={isFilled}
    style={!isFilled ? { color: 'rgb(var(--darkGrey))' } : undefined}
    handleClick={() => {
      if (projectId) {
        dispatch(goToCaseGranulometryEditLevelGeometry(projectId, caseId, levelId));
      }
    }}
  />
);
