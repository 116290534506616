import { createSelector, Selector } from 'reselect';
import { State } from '../../../reducers';
import { Maybe } from '../../../../utils/Maybe';
import { selectCurrentCaseProjection } from '../../projection/currentCase/projection.selector';

export const selectCurrentCaseCarpentryDayTimeOccultationsTypeIsMonobloc: Selector<
  State,
  Maybe<boolean>
> = createSelector(
  [selectCurrentCaseProjection],
  (caseSpecification) => caseSpecification?.carpentryDayTimeOccultationsTypeIsMonobloc
);
