import { SectionWithGlazedSurface } from '../../SectionWithGlazedSurface';
import { LevelGranulometry } from '../../../levels/LevelGranulometry';
import { CaseGranulometry } from '../../../cases/CaseGranulometry';
import { getSectionGlazedLinear } from '../getSectionGlazedLinear';
import { SectionWithExposureRate } from '../../SectionWithExposureRate';
import {
  getLevelGlazedHeight,
  LINTEL_OVERHANG,
  SILL_UNDER_GLAZED_SURFACE
} from '../../../levels/queries/getLevelGlazedHeight';
import { roundWith2Decimal } from '../../../../../utils/round/roundWith2Decimal';
import { getSectionGlazedSurface } from './getSectionGlazedSurface';
import { getLevelCeilingHeight } from '../../../levels/queries/getLevelCeilingHeight';

export const getSectionGlazedSurfaceDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry,
  section: SectionWithGlazedSurface & SectionWithExposureRate
): string => {
  let details: string[] = [];
  details = [
    ...details,
    '<b>' +
      roundWith2Decimal(getSectionGlazedLinear(caseGranulometry, levelGranulometry, section)) +
      ' mL</b> de linéaire vitré'
  ];
  details = [
    ...details,
    '<b>* ' +
      roundWith2Decimal(getLevelGlazedHeight(levelGranulometry)) +
      ' m</b> de hauteur vitrée'
  ];
  details = [
    ...details,
    '<i>(: ' +
      roundWith2Decimal(getLevelCeilingHeight(levelGranulometry)) +
      ' m de hauteur sous plafond'
  ];
  details = [...details, '- ' + LINTEL_OVERHANG + ' m de retombé en linteau'];
  details = [
    ...details,
    '- ' + SILL_UNDER_GLAZED_SURFACE + ' m de seuil sous la partie vitrée)</i>'
  ];
  details = [
    ...details,
    '<b>= ' +
      roundWith2Decimal(getSectionGlazedSurface(caseGranulometry, levelGranulometry, section)) +
      ' m\u00B2</b>'
  ];
  return details.join('<br />');
};
