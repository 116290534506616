import * as R from 'ramda';
import { BuildingId, overCases } from '../specification/buildings/BuildingSpecification';
import { getCaseIndexInBuildingFromCaseId } from '../core/queries/getCaseIndexInBuildingGivenCaseId';
import { getBuildingIdFromCaseId } from '../core/queries/getBuildingIdFromCaseId';
import { removeACase } from './removeACase';
import { CaseId } from '../specification/cases/CaseSpecification';
import { getCasesSpecificationsById } from '../specification/project/queries/cases/getProjectCasesById';
import { Project } from '../project/Project';
import { overBuilding } from '../project/over/overBuilding';
export const moveACase =
  (caseId: CaseId, buildingId: BuildingId, newCaseIndex: number) =>
  (project: Project): Project => {
    const currentBuildingId = getBuildingIdFromCaseId(project, caseId) as BuildingId;
    if (currentBuildingId !== buildingId) {
      const caseSpecification = getCasesSpecificationsById(project)[caseId];
      return R.pipe<[Project], Project, Project>(
        removeACase(currentBuildingId)(caseId),
        overBuilding(buildingId)(overCases(R.append(caseSpecification)))
      )(project);
    }
    const currentCaseIndex = getCaseIndexInBuildingFromCaseId(project, caseId);
    return overBuilding(buildingId)(
      overCases((cases) => R.move(currentCaseIndex, newCaseIndex, cases))
    )(project);
    // TODO : Move the current case with the new index at (index + 1) instead just swapping
  };
