import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as R from 'ramda';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { selectCurrentCaseEditingStage } from '../../../../../store/selectors/specification/currentCase/editingStage.selector';
import { setTopLevelsSpecifications } from '../../../../../store/actions/setTopLevelsSpecifications.action';
import { selectCurrentCaseIdFromRoute } from '../../../../../store/selectors/navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { selectCurrentCaseProjection } from '../../../../../store/selectors/projection/currentCase/projection.selector';
import { TopLevelSurfaceName } from '../../../../../domain/granulometry/cases/queries/surfaces/getCaseTopLevelsSurfaces';
import { selectProjectId } from '../../../../../store/selectors/project';
import { caseTopLevelsSpecificationsRepartitionIsValid } from '../../../../../domain/checks/cases/caseTopLevelsSpecificationsRepartitionIsValid';
import { selectCurrentCaseGrossFloorSurfaceEff } from '../../../../../store/selectors/granulometry/currentCase/grossFloorSurfaceEff.selector';
import { selectCurrentCaseGranulometry } from '../../../../../store/selectors/granulometry/currentCase/currentCaseGranulometry.selector';
import { CaseGranulometry } from '../../../../../domain/granulometry/cases/CaseGranulometry';
import { selectIsCaseGranulometryEditLevelGeometryOpened } from '../../../../../store/selectors/navigation/toolbox/casesPanel/isCaseGranulometryEditLevelGeometryOpened.selector';
import { selectCurrentlyEditedLevelSpecification } from '../../../../../store/selectors/navigation/toolbox/casesPanel/currentlyEditedLevelSpecification.selector';
import { selectCurrentCaseBasementLevelsSurfaces } from '../../../../../store/selectors/granulometry/currentCase/basementLevelsSurfaces.selector';
import { BasementLevelSpecification } from '../../../../../domain/specification/levels/BasementLevelSpecification';
import { BasementLevelSurfaceName } from '../../../../../domain/granulometry/cases/queries/surfaces/getCaseBasementLevelsSurfaces';
import { GrossFloorSurfaceEffButton } from '../GrossFloorSurfaceEffButton';
import { BasementLevelSurfaceInput } from './BasementLevelSurfaceInput';
import { roundForSurfacesInputs } from '../../../../../utils/round/roundForSurfacesInputs';
import { isProjectInputModeGfsEff } from '../../../../../domain/specification/project/queries/is/isProjectInputModeGfsEff';
import { isProjectInputModeCbs } from '../../../../../domain/specification/project/queries/is/isProjectInputModeCbs';

export interface LocalLevelsSurfaceRepartition {
  levels: BasementLevelSpecification[];
  isValid: boolean;
}

interface Column4Props {
  basementLevelSpecification: BasementLevelSpecification;
  handleRealBuildSurfaceChange: (value: number | null) => void;
  selectedLevelSurface: BasementLevelSurfaceName;
}

export const Column4Inner = ({
  basementLevelSpecification,
  handleRealBuildSurfaceChange,
  selectedLevelSurface
}: Column4Props) => {
  const projectId = useSelector(selectProjectId);
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const caseProjection = useSelector(selectCurrentCaseProjection);
  const caseRealBuiltSurface = caseProjection.projectedRealBuiltSurface.value;
  const grossFloorSurfaceEff = useSelector(selectCurrentCaseGrossFloorSurfaceEff);
  const caseGranulometry = useSelector(selectCurrentCaseGranulometry) as CaseGranulometry;
  const projectedBasementLevels = caseGranulometry.initialSpecifications.projectedBasementLevels; // useSelector(selectCurrentCaseSpecifiedTopLevels);
  const editingStage = useSelector(selectCurrentCaseEditingStage);
  const basementLevelsSurfaces = useSelector(selectCurrentCaseBasementLevelsSurfaces);
  const currentlyEditedLevel = useSelector(selectCurrentlyEditedLevelSpecification);
  const isCaseGranulometryEditLevelGeometryOpened = useSelector(
    selectIsCaseGranulometryEditLevelGeometryOpened
  );

  const dispatch = useDispatch();

  const [localLevelsSpecification, setLocalLevelsSpecification] = React.useState({
    levels: projectedBasementLevels,
    isValid:
      !!grossFloorSurfaceEff &&
      caseTopLevelsSpecificationsRepartitionIsValid(
        caseRealBuiltSurface,
        grossFloorSurfaceEff.value,
        projectedBasementLevels
      )
  } as LocalLevelsSurfaceRepartition);

  const handleLevelSurfaceChange = React.useCallback(
    (level: number | string, surfaceName: TopLevelSurfaceName, surface: number | null) => {
      if (caseId) {
        // To prevent infinity bug
        if (surface === null || surface > 10) {
          const levelIndex = localLevelsSpecification.levels.findIndex((l) => l.level === level);
          const newRepartition = R.over(
            R.lensIndex(levelIndex),
            R.assoc(surfaceName, surface || undefined),
            localLevelsSpecification.levels
          );
          const isValid =
            !!grossFloorSurfaceEff &&
            caseTopLevelsSpecificationsRepartitionIsValid(
              caseRealBuiltSurface,
              grossFloorSurfaceEff.value,
              newRepartition
            );
          setLocalLevelsSpecification({ levels: newRepartition, isValid });
          dispatch(setTopLevelsSpecifications(caseId, newRepartition));
        }
      }
    },
    [dispatch, localLevelsSpecification, caseRealBuiltSurface, setLocalLevelsSpecification, caseId]
  );

  React.useEffect(() => {
    setLocalLevelsSpecification({
      levels: projectedBasementLevels,
      isValid:
        !!grossFloorSurfaceEff &&
        caseTopLevelsSpecificationsRepartitionIsValid(
          caseRealBuiltSurface,
          grossFloorSurfaceEff.value,
          projectedBasementLevels
        )
    });
  }, [projectedBasementLevels]);

  if (!caseLabel || !basementLevelsSurfaces) return null;

  const levelIndex = basementLevelSpecification.level;

  const isRbsSelected = selectedLevelSurface === 'realBuiltSurface';
  const isGfsEffSelected = selectedLevelSurface === 'grossFloorSurfaceEff';
  /* const isSfsSelected = selectedLevelSurface === 'surfaceForSale'; */

  const levelFromSpec = localLevelsSpecification.levels.find(
    (l) => l.id === basementLevelSpecification.id
  );
  const levelSurfacesFromGranulo = basementLevelsSurfaces.find((l) => l.level === levelIndex);
  const levelSurfacePlaceholder =
    levelSurfacesFromGranulo &&
    Math.max(0, roundForSurfacesInputs(levelSurfacesFromGranulo[selectedLevelSurface].value));
  const levelSurfaceValue = isRbsSelected
    ? levelFromSpec?.realBuiltSurface
      ? levelFromSpec?.realBuiltSurface
      : isProjectInputModeGfsEff(caseProjection)
      ? levelSurfacePlaceholder
      : undefined
    : isGfsEffSelected && isProjectInputModeGfsEff(caseProjection)
    ? levelFromSpec?.grossFloorSurfaceEff
    : levelSurfacePlaceholder;
  return (
    <>
      {levelFromSpec?.id && isGfsEffSelected && caseId ? (
        <GrossFloorSurfaceEffButton
          projectId={projectId}
          caseId={caseId}
          levelId={levelFromSpec.id}
          isSelected={
            isCaseGranulometryEditLevelGeometryOpened &&
            currentlyEditedLevel?.id === levelFromSpec.id
          }
          grossFloorSurfaceEff={levelSurfaceValue}
          dispatch={dispatch}
          isFilled={true}
        />
      ) : (
        <BasementLevelSurfaceInput
          isRbsSelected={isRbsSelected}
          isGfsEffSelected={isGfsEffSelected}
          handleRealBuildSurfaceChange={handleRealBuildSurfaceChange}
          handleLevelSurfaceChange={handleLevelSurfaceChange}
          caseLabel={caseLabel}
          levelIndex={levelIndex}
          selectedLevelSurface={selectedLevelSurface}
          localLevelsSpecification={localLevelsSpecification}
          levelSurfaceValue={levelSurfaceValue}
          levelSurfacePlaceholder={levelSurfacePlaceholder}
          editingStage={editingStage}
          isDisabled={
            !isGfsEffSelected ||
            !isRbsSelected ||
            (isRbsSelected && isProjectInputModeGfsEff(caseProjection))
          }
          asCustomValue={
            (isGfsEffSelected && isProjectInputModeGfsEff(caseProjection) && !!levelSurfaceValue) ||
            (isRbsSelected && isProjectInputModeCbs(caseProjection) && !!levelSurfaceValue)
          }
        />
      )}
    </>
  );
};

export const Column4 = ({
  basementLevelSpecification,
  handleRealBuildSurfaceChange,
  selectedLevelSurface
}: Column4Props) => (
  <div className="column-4">
    <div className="cell">
      <Column4Inner
        basementLevelSpecification={basementLevelSpecification}
        handleRealBuildSurfaceChange={handleRealBuildSurfaceChange}
        selectedLevelSurface={selectedLevelSurface}
      />
    </div>
  </div>
);
