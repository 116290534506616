import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from '../../../../ui/Card';
import { selectCurrentCaseLodgmentsTypesDistributionWithSpecifications } from '../../../../../store/selectors/specification/currentCase/lodgmentsTypesDistributionWithSpecifications.selector';
import { IconObject } from '../../../../ui/Icons/iconObject';
import { selectProjectId } from '../../../../../store/selectors/project';
import { selectCurrentCaseIdFromRoute } from '../../../../../store/selectors/navigation/toolbox/casesPanel/caseIdFromRoute.selector';
import { selectCurrentCaseLabel } from '../../../../../store/selectors/project/currentCaseLabel.selector';
import { Checkbox } from '../../../../ui/Checkbox';
import {
  HeatingSystem,
  HeatingSystemsInArray,
  isHeatingSystemCollective,
  isHeatingSystemIndividual
} from '../../../../../domain/specification/lodgmentTypes/HeatingSystem';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import {
  LodgmentType,
  LodgmentTypesInArray
} from '../../../../../domain/specification/lodgmentTypes/LodgmentType';
import { setLodgmentTypesHeatingSystem } from '../../../../../store/actions/setLodgmentTypesHeatingSystem.action';
import { Tooltip } from '../../../../ui/Tooltip';
import { getCaseGranulometryEditLodgmentTypeTriggerId } from '../../../../../routes/toolbox/casesPanel/triggers/getCaseGranulometryEditLodgmentTypeTriggerId';
import { goToCaseGranulometryEditLodgmentType } from '../../../../../store/actions/navigations/toolbox/casesPanel/goToCaseGranulometryEditLodgmentType.action';

export const HeatingSystemsCard = () => {
  const projectId = useSelector(selectProjectId);
  const caseId = useSelector(selectCurrentCaseIdFromRoute);
  const caseLabel = useSelector(selectCurrentCaseLabel);
  const distribution = useSelector(selectCurrentCaseLodgmentsTypesDistributionWithSpecifications);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSetLodgmentTypeHeatingSystem = React.useCallback(
    (heatingSystem: HeatingSystem | undefined, lodgmentType: LodgmentType | undefined) => {
      if (caseId) {
        let lodgmentTypes: LodgmentType[];
        if (
          lodgmentType === undefined || // Means for all lodgment types
          (heatingSystem && isHeatingSystemCollective(heatingSystem))
        ) {
          lodgmentTypes = LodgmentTypesInArray; // All lodgment types are set with the same collective heating
        } else if (heatingSystem && isHeatingSystemIndividual(heatingSystem)) {
          lodgmentTypes = distribution?.reduce(
            (acc, lT) => {
              // If the lodgment type (not the one currently modified) has a collective heating system defined : undefined
              return !isHeatingSystemIndividual(lT.heatingSystem || lT.defaultHeatingSystem) &&
                lT.lodgmentType !== lodgmentType
                ? [...acc, lT.lodgmentType]
                : acc;
            },
            [lodgmentType]
          );
        } else {
          lodgmentTypes = distribution?.reduce(
            (acc, lT) => {
              // If the lodgment type (not the one currently modified) has a collective heating system defined : undefined
              return lT.heatingSystem &&
                isHeatingSystemCollective(lT.heatingSystem) &&
                lT.lodgmentType !== lodgmentType
                ? [...acc, lT.lodgmentType]
                : acc;
            },
            [lodgmentType]
          );
        }

        dispatch(setLodgmentTypesHeatingSystem(caseId, lodgmentTypes, heatingSystem));
      }
    },
    [dispatch, caseId, distribution]
  );

  if (!caseLabel || process.env.GB_FEAT_HEATING_SYSTEMS === 'false') return null;

  return (
    <Card>
      <SpecialLabel label={t('Heating systems')} />
      <div className="heatingSystemsGrid">
        <div>
          <div>&nbsp;</div>
          {HeatingSystemsInArray.map((heatingSystem, indexH) => (
            <div key={indexH} className="headCell">
              <div>
                <IconObject type="menu" iconName={heatingSystem} clickable={false} />
                <Tooltip>{t(heatingSystem + '.label')}</Tooltip>
              </div>
              <Checkbox
                label=""
                selected={
                  distribution.filter(
                    (lodgmentTypeSpecification) =>
                      lodgmentTypeSpecification.heatingSystem === heatingSystem
                  ).length === distribution.length
                }
                checkboxName={`${heatingSystem}-forAllLodgmentTypes`}
                handleCheck={() => {
                  handleSetLodgmentTypeHeatingSystem(heatingSystem, undefined);
                }}
                className=""
              />
            </div>
          ))}
        </div>
        {distribution?.map((lodgmentTypeSpecification, indexD) => (
          <div key={indexD}>
            <div>
              <IconObject
                id={getCaseGranulometryEditLodgmentTypeTriggerId(
                  caseLabel,
                  lodgmentTypeSpecification.lodgmentType
                )}
                type="object"
                iconName="lodgment"
                color={lodgmentTypeSpecification.color}
                customText={lodgmentTypeSpecification.lodgmentType}
                customTextPosition="bottom right"
                onClick={() =>
                  projectId &&
                  caseId &&
                  dispatch(
                    goToCaseGranulometryEditLodgmentType(
                      projectId,
                      caseId,
                      lodgmentTypeSpecification.lodgmentType
                    )
                  )
                }
              />
            </div>
            {HeatingSystemsInArray.map((heatingSystem, indexH) => (
              <div key={indexH}>
                <Checkbox
                  label=""
                  selected={lodgmentTypeSpecification.heatingSystem === heatingSystem}
                  defaultSelected={lodgmentTypeSpecification.defaultHeatingSystem === heatingSystem}
                  checkboxName={`${heatingSystem}-${lodgmentTypeSpecification.lodgmentType}`}
                  handleCheck={() =>
                    handleSetLodgmentTypeHeatingSystem(
                      lodgmentTypeSpecification.heatingSystem === heatingSystem
                        ? undefined
                        : heatingSystem,
                      lodgmentTypeSpecification.lodgmentType
                    )
                  }
                  className={
                    lodgmentTypeSpecification.defaultHeatingSystem === heatingSystem &&
                    lodgmentTypeSpecification.heatingSystem !== undefined
                      ? 'hasCustomValue'
                      : ''
                  }
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </Card>
  );
};
