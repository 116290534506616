import { ReportEntry } from './ReportEntry';
import { ComEthProduct } from '../../store/selectors/cstb/comEthReportProducts.selector';

export const addComEthProductIndicatorsInReport = (
  report: ReportEntry[] | undefined,
  comEthProducts: ComEthProduct[]
): ReportEntry[] | undefined => {
  if (!report || !comEthProducts) {
    return undefined;
  } else {
    return report.reduce((acc, reportEntry) => {
      const comEthProduct = comEthProducts.find((p) => p.id === reportEntry.id);
      return [
        ...acc,
        {
          ...reportEntry,
          // comEthLifeTime: comEthProduct?.lifetime || null,
          comEthIc: comEthProduct?.ic || null
          // comEthFdesId: comEthProduct?.fdesId || null,
          // comEthStockC: comEthProduct?.stockC || null,
          // comEthUnit: comEthProduct?.unit || null
        }
      ];
    }, [] as ReportEntry[]);
  }
};
