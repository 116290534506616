export enum CarpentryTypeName {
  PVC = 'pvc',
  PlaxedPVC = 'plaxedPvc',
  Wood = 'wood',
  Aluminium = 'aluminium',
  AluminiumAndWood = 'aluminiumAndWood'
}

export type CarpentryType =
  | CarpentryTypeName.PVC
  | CarpentryTypeName.PlaxedPVC
  | CarpentryTypeName.Wood
  | CarpentryTypeName.Aluminium
  | CarpentryTypeName.AluminiumAndWood;

export const isCarpentryTypeUsePVC = (carpentryType: CarpentryType): boolean =>
  carpentryType === CarpentryTypeName.PVC || carpentryType === CarpentryTypeName.PlaxedPVC;
