import { createSelector, Selector } from 'reselect';
import { selectPath } from '../../getPath.selector';
import { SectionId } from '../../../../../domain/granulometry/sections/Section';
import { State } from '../../../../reducers';
import { Maybe } from '../../../../../utils/Maybe';
import { sectionsIndividualRoute } from '../../../../../routes/toolbox/sectionsPanel/sectionsIndividualRoute';

export const selectCurrentSectionIdFromRoute: Selector<State, Maybe<SectionId>> = createSelector(
  [selectPath],
  (path) => sectionsIndividualRoute.matchesPath(path).params?.sectionId
);
