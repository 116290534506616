import { CaseGranulometry } from '../../domain/granulometry/cases/CaseGranulometry';
import { LevelGranulometry } from '../../domain/granulometry/levels/LevelGranulometry';
import { getLevelFullFilledContent } from '../../domain/granulometry/levels/queries/content/getLevelFullFilledContent';

export const manageLevelSectionGroups = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometry,
  index: number,
  sectionGroups: string[]
) =>
  getLevelFullFilledContent(levelGranulometry).forEach((section) => {
    if (section.group) {
      const sectionGroupId = section.group + 'SectionGroupInCase' + caseGranulometry.labelIndex;
      if (sectionGroups.indexOf(sectionGroupId) === -1) {
        sectionGroups.push(sectionGroupId);
        section.firstInGroup = true;
      } else {
        section.firstInGroup = false;
      }
      section.lastInGroup = true;
      if (caseGranulometry.levels[index + 1]) {
        getLevelFullFilledContent(caseGranulometry.levels[index + 1]).forEach((contentItem) => {
          if (contentItem.group && contentItem.group === section.group) {
            section.lastInGroup = false;
          }
        });
      }
    }
  });
