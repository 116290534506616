import { createSelector, Selector } from 'reselect';
import { projectHasSufficientRemainingSurfaceForANewBuilding } from '../../../domain/checks/project/projectHasSufficientRemainingSurfaceForANewBuilding';
import { Maybe } from '../../../utils/Maybe';
import { State } from '../../../store/reducers';
import { selectProject } from '../project/project.selector';
import { Project } from '../../../domain/project/Project';

export const selectCanAddABuilding: Selector<State, Maybe<boolean>> = createSelector(
  [selectProject],
  (p: Project | undefined) =>
    p ? projectHasSufficientRemainingSurfaceForANewBuilding(p) : undefined
);
