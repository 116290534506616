import { getCasePropertyConvertedValue } from '../../../../../../specification/cases/queries/get/properties/getCasePropertyConvertedValue';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';
import { EXPONENT, MULTIPLIER_COEFFICIENT } from '../../getSectionWallsLinear';

export const getRampSectionTheoreticalWallsLinear = (
  caseGranulometry: CaseGranulometry
): number => {
  const length = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'rampLength'
  ) as number;
  const width = getCasePropertyConvertedValue(
    caseGranulometry.initialSpecifications,
    'rampWidth'
  ) as number;
  const surface = length * width;
  return surface !== 0 ? surface * MULTIPLIER_COEFFICIENT * Math.pow(surface, EXPONENT) : 0;
};
