import classNames from 'classnames';
import * as React from 'react';
import { IconFont } from './Icons/IconFont';

interface StepperProps {
  steps: string[];
  className?: string;
  value?: number;
}

export const Stepper: React.SFC<StepperProps> = (props: StepperProps) => {
  const { steps, className, value } = props;

  const classes = classNames('stepper', className);

  return (
    <div className={classes}>
      {steps.map((step: string, key: number) => {
        const stepClasses = classNames('step', {
          current: value === key
        });
        return (
          <div className={stepClasses} key={key}>
            <span>
              <IconFont id={'step-' + key} returnString={(key + 1).toString()} justify="center" />
            </span>
          </div>
        );
      })}
    </div>
  );
};
