import * as React from 'react';
import { MapPositionContextConsumer } from '../../../../contexts/MapPositionContext';
import { Tooltip } from '../../../../components/ui/Tooltip';
import { BuildingMapSectionViewController } from '../section/BuildingMapSectionViewController';
import BuildingMapLevelSurfaceRulers from './BuildingMapLevelSurfaceRulers';
import BuildingMapLevelOutsideRuler from './BuildingMapLevelOutsideRuler';
import { roundWith2Decimal } from '../../../../utils/round/roundWith2Decimal';
import { useTranslation } from 'react-i18next';
import { SQUARE_METER_SCALE } from '../../../../constants/appConstants';

export interface BuildingMapContentInterface {
  buildingId: string;
  caseId: string;
  id: string;
  level: any;
  content: any;
  outsideLevel: any;
  levelContentStyle: any;
  exceededStyle: any;
  exceededSurface: number;
  endlineStyle: any;
  direction: string;
  emptyWidth: any;
  emptyStyle: any;
  realBuiltSurface: number;
  surfaceForSale: number;
  rbsRulerWidth?: number;
  rbsRulerLeft?: number;
  sfsRulerWidth?: number;
  sfsRulerLeft?: number;
  outsideRulerWidth?: number;
  outsideRulerLeft?: number;
  outsideSurface?: number;
  outsideRulerTop: number;
  sectionWrappers?: any;
  parent: any;
}

const BuildingMapLevelContentView: React.FC<BuildingMapContentInterface> = ({
  buildingId,
  caseId,
  id,
  level,
  content,
  direction,
  levelContentStyle,
  endlineStyle,
  exceededStyle,
  exceededSurface,
  outsideLevel,
  emptyStyle,
  emptyWidth,
  realBuiltSurface,
  surfaceForSale,
  rbsRulerWidth,
  rbsRulerLeft,
  sfsRulerWidth,
  sfsRulerLeft,
  outsideRulerWidth,
  outsideRulerLeft,
  outsideSurface,
  outsideRulerTop
}) => {
  const hasContent = content && content.length !== 0;
  const beforeSectionWrapperContent: any[] = [];
  const inFirstSectionWrapperContent: any[] = [];
  const inSecondSectionWrapperContent: any[] = [];
  const afterSectionWrapperContent: any[] = [];

  const { t } = useTranslation();

  if (content) {
    content.forEach((contentElem: any) => {
      if (!contentElem.inSectionWrappers && inFirstSectionWrapperContent.length === 0) {
        // If the content element is BEFORE the section wrappers
        beforeSectionWrapperContent.push(contentElem);
      } else if (!contentElem.inSectionWrappers && inFirstSectionWrapperContent.length !== 0) {
        // If the content element is AFTER the section wrappers
        afterSectionWrapperContent.push(contentElem);
      } else {
        if (contentElem.inFirstSectionWrapper === true) {
          // If the content element is INSIDE the FIRST section wrappers
          inFirstSectionWrapperContent.push(contentElem);
        }
        if (contentElem.inSecondSectionWrapper === true) {
          // If the content element is INSIDE the SECOND section wrappers
          inSecondSectionWrapperContent.push(contentElem);
        }
      }
    });
  }

  return (
    <MapPositionContextConsumer>
      {(mapPositionContext) => (
        <>
          {hasContent && (
            <div className="levelContent" style={levelContentStyle}>
              {!outsideLevel && (
                <>
                  <div className="endLine" style={endlineStyle} />
                  <div className="exceeded tooltipped" style={exceededStyle}>
                    <Tooltip>{roundWith2Decimal(exceededSurface) + '\u00A0m\u00B2'}</Tooltip>
                  </div>
                </>
              )}
              <div className="levelInner">
                <div className="levelBackground" />
                {beforeSectionWrapperContent.map((contentElem: any, key: number) => (
                  <BuildingMapSectionViewController
                    key={key}
                    direction={direction}
                    sectionGroupRecorder={[]}
                    focusOn={mapPositionContext.focusOn}
                    {...contentElem}
                  />
                ))}
                {(inFirstSectionWrapperContent.length !== 0 ||
                  inSecondSectionWrapperContent.length !== 0) && (
                  <div className="sectionWrappers">
                    {inFirstSectionWrapperContent.length !== 0 && (
                      <div className="sectionWrapper firstSectionWrapper">
                        {inFirstSectionWrapperContent.map((contentElem: any, key: number) => (
                          <BuildingMapSectionViewController
                            key={key}
                            direction={direction}
                            sectionGroupRecorder={[]}
                            focusOn={mapPositionContext.focusOn}
                            {...contentElem}
                          />
                        ))}
                      </div>
                    )}
                    {inSecondSectionWrapperContent.length !== 0 && (
                      <div className="sectionWrapper secondSectionWrapper">
                        {inSecondSectionWrapperContent.map((contentElem: any, key: number) => (
                          <BuildingMapSectionViewController
                            key={key}
                            direction={direction}
                            sectionGroupRecorder={[]}
                            focusOn={mapPositionContext.focusOn}
                            {...contentElem}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                )}
                {afterSectionWrapperContent.map((contentElem: any, key: number) => (
                  <BuildingMapSectionViewController
                    key={key}
                    direction={direction}
                    sectionGroupRecorder={[]}
                    focusOn={mapPositionContext.focusOn}
                    {...contentElem}
                  />
                ))}
                {emptyWidth !== 0 && (
                  <div className="empty tooltipped" style={emptyStyle}>
                    <Tooltip>
                      {t('buildingMap.level.contentView.empty.tooltip') +
                        '\u00A0: ' +
                        emptyWidth / SQUARE_METER_SCALE +
                        '\u00A0m\u00B2'}
                    </Tooltip>
                  </div>
                )}
                {level >= 0 && realBuiltSurface + surfaceForSale > 0 && (
                  <BuildingMapLevelSurfaceRulers
                    buildingId={buildingId}
                    caseId={caseId}
                    id={id}
                    level={level}
                    direction={direction}
                    realBuiltSurface={realBuiltSurface}
                    rbsRulerWidth={rbsRulerWidth}
                    rbsRulerLeft={rbsRulerLeft}
                    surfaceForSale={surfaceForSale}
                    sfsRulerWidth={sfsRulerWidth}
                    sfsRulerLeft={sfsRulerLeft}
                  />
                )}
                {outsideRulerWidth && outsideRulerLeft && (
                  <BuildingMapLevelOutsideRuler
                    id={id}
                    direction={direction}
                    outsideRulerWidth={outsideRulerWidth}
                    outsideRulerLeft={outsideRulerLeft}
                    outsideSurface={outsideSurface}
                    outsideRulerTop={outsideRulerTop}
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </MapPositionContextConsumer>
  );
};

export default BuildingMapLevelContentView;
