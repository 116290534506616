import { LevelGeometry } from '../mapboxDraw/levelGeometry';
import {
  BuildingId,
  BuildingSpecification
} from '../../../domain/specification/buildings/BuildingSpecification';
import { CaseSpecificationWithIndexes } from '../../../domain/specification/cases/CaseSpecification';
import { letterFromIndex } from '../../../utils/letterFromIndex';
import { LevelSpecification } from '../../../domain/specification/levels/LevelSpecification';
import { InitializeLayersRouting } from '../../../store/selectors/navigation/toolbox/casesPanel/selectInitializeLayersRouting.selector';
import { uniqId } from '../../../infra/uniqId';

export type LayerId = string;
export type LayerType = 'case' | 'building' | 'level';

interface LevelLayerProperties {
  floor: number;
  buildingId: BuildingId;
}

export interface Layer {
  id: LayerId;
  selected?: boolean;
  draw?: LevelGeometry;
  parent?: LayerId;
  type: LayerType;
  title: string;
}

export interface CaseLayer {
  id: LayerId;
  selected?: boolean;
  parent: LayerId;
  type: 'case';
  title: string;
  isCurrentIdInRoute?: boolean;
}

export interface LevelLayer extends Layer {
  type: 'level';
  properties: LevelLayerProperties;
  parent: LayerId;
  isCurrentIdInRoute?: boolean;
}

export interface LayerWithLevelGeometry extends LevelLayer {
  draw: LevelGeometry;
}

export interface LevelLayerParams {
  type: 'level';
  draw?: LevelGeometry;
  parentId?: LayerId;
}

interface CaseLayerParams {
  type: 'case';
  parentLayer: Layer;
}

interface BuildingLayerParams {
  type: 'building';
}

export type CreateLayerParams = LevelLayerParams | CaseLayerParams | BuildingLayerParams;

/* ----------------- SPEC ------------------- */
export const formatBuildingSpecToLayer = (
  buildings: BuildingSpecification[],
  selectedId?: string
): Layer[] => {
  return buildings.map((b) => {
    const index = buildings.map((bu) => bu.id).indexOf(b.id);
    return {
      id: b.id,
      title: generateBuildingTitle(index),
      type: 'building',
      selected: b.id === selectedId
    };
  });
};
export const formatCaseSpecToLayer = (
  caseSpecificationsWithIndexes: CaseSpecificationWithIndexes[],
  selectedId?: string
): Layer[] => {
  return caseSpecificationsWithIndexes.map((caseSpecificaton) => {
    return {
      id: caseSpecificaton.id,
      title: generateCaseTitle(caseSpecificaton),
      parent: caseSpecificaton.buildingId,
      type: 'case',
      selected: selectedId === caseSpecificaton.id
    };
  });
};
const formatLevelGeometryToLayer = (
  level: LevelGeometry,
  isSelected = true,
  parent: CaseLayer
): LevelLayer => {
  return {
    draw: level,
    type: 'level',
    parent: level.properties.parentCaseId,
    id: level.id,
    title: generateLevelTitle({ floor: level.properties.floor, parentTitle: parent.title }),
    selected: isSelected,
    isCurrentIdInRoute: isSelected,
    properties: {
      floor: level.properties.floor,
      buildingId: level.properties.parentCaseId
    }
  };
};
export const formatLevelSpecToLevelLayersWithGeometry = (
  levelsSpecification: LevelSpecification[],
  initializeLayersRouting: InitializeLayersRouting,
  caseLayers: CaseLayer[]
): LevelLayer[] => {
  const levelWithGeometry = levelsSpecification
    .filter((levelSpecification) => levelSpecification.geometry?.geometry)
    .filter(Boolean);

  const getIsSelected = (level: LevelSpecification) =>
    initializeLayersRouting.type === 'level'
      ? level.id === initializeLayersRouting.routeMatch?.levelId
      : level.geometry?.properties.parentCaseId === initializeLayersRouting.routeMatch?.caseId;

  return levelWithGeometry.map((level): LevelLayer => {
    const parent = caseLayers.find(
      (caseLayer) => caseLayer.id === level.geometry?.properties.parentCaseId
    ) as CaseLayer;
    return formatLevelGeometryToLayer(
      level.geometry as LevelGeometry,
      getIsSelected(level),
      parent
    );
  });
};
export const formatCaseLayersWithoutGroundLevelToLevelLayersWithoutGeometry = (
  caseSpecificationWithIndexes: CaseSpecificationWithIndexes[]
): LevelLayer[] => {
  const caseWithoutGroundLevel = caseSpecificationWithIndexes.filter(
    (c) => c.levels?.length === 0 || c.levels.filter((l) => l.level === 0)?.length === 0
  );
  return caseWithoutGroundLevel.map((caseSpec: CaseSpecificationWithIndexes) =>
    createNewLevelLayer({
      isSelected: false,
      parent: {
        id: caseSpec.id,
        parent: caseSpec.buildingId,
        title: generateCaseTitle(caseSpec)
      }
    })
  );
};

export const createNewLayer = (type: LayerType, isSelected: boolean): Layer => {
  return {
    id: uniqId(),
    title: '',
    type,
    selected: isSelected
  };
};

interface formatLevelLayerParams {
  parent: Pick<Layer, 'id' | 'parent' | 'title'>;
  isSelected?: boolean;
  title?: string;
}
export const createNewLevelLayer = (params: formatLevelLayerParams): LevelLayer => {
  return {
    id: uniqId(),
    selected: params.isSelected ?? true,
    parent: params.parent.id,
    title: params.title || generateLevelTitle({ parentTitle: params.parent.title, floor: 0 }),
    type: 'level',
    properties: {
      floor: 0,
      buildingId: params.parent.parent as BuildingId
    }
  };
};

export const generateLevelTitle = (params: { parentTitle: string; floor?: number }) => {
  return params.parentTitle + 'L' + params.floor;
};
const generateCaseTitle = (caseSpecificaton: CaseSpecificationWithIndexes) => {
  return (
    'C' + (caseSpecificaton.buildingIndex + 1) + letterFromIndex(caseSpecificaton.indexInBuilding)
  );
};
const generateBuildingTitle = (index: number) => {
  return (index + 1).toString();
};
