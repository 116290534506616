import { LodgmentSection } from '../../../../../sections/lodgmentSections/LodgmentSection';
import { getSectionPartitionWallsLinear } from '../../../../../sections/queries/walls/partitionWalls/getSectionPartitionWallsLinear';
import { LevelGranulometry, LevelGranulometryWithLodgments } from '../../../../LevelGranulometry';
import { getTopLevelLodgmentSections } from '../../sections/getTopLevelLodgmentSections';
import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { getTopLevelLodgmentsPartitionWallsLinear } from './getTopLevelLodgmentsPartitionWallsLinear';
import { CaseGranulometry } from '../../../../../cases/CaseGranulometry';

export const getTopLevelLodgmentsPartitionWallsLinearDetails = (
  caseGranulometry: CaseGranulometry,
  levelGranulometry: LevelGranulometryWithLodgments | LevelGranulometry
): string =>
  '<b>' +
  getTopLevelLodgmentSections(levelGranulometry)
    .reduce(
      (acc, lodgmentSection: LodgmentSection) => [
        ...acc,
        roundWith2Decimal(
          getSectionPartitionWallsLinear(caseGranulometry, levelGranulometry, lodgmentSection)
        ) +
          ' m\u00B2</b> de linéaire de cloisons du <i>' +
          lodgmentSection.title.replace('Logement ', '') +
          '</i>'
      ],
      [] as string[]
    )
    .join('<br /><b>+ ') +
  '<br /><b>= ' +
  roundWith2Decimal(getTopLevelLodgmentsPartitionWallsLinear(caseGranulometry, levelGranulometry)) +
  ' m\u00B2</b>';
