import { Epic, ofType } from 'redux-observable';
import { pipe } from 'fp-ts/function';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, interval, merge, of } from 'rxjs';
import * as OB from 'fp-ts-rxjs/Observable';
import userState from '../reducers/user.slice';
import { Email, Password } from '../../domain/User';
import { selectIsSessionExpiredOrExpiringSoon } from '../selectors/user/isSessionExpiredOrExpiringSoon.selector';
import { selectSessionState } from '../selectors/user/sessionState.selector';

type UnknownCredentials = {};
interface KnownCredentials {
  email: Email;
  password: Password;
}
type Credentials = UnknownCredentials | KnownCredentials;

const isKnownCredentials = (credentials: Credentials): credentials is KnownCredentials =>
  'email' in credentials;

const credentials: Credentials = {};

export const renewSessionWhenApproachingExpirationEpic: Epic = (actions$, state$) =>
  merge(
    pipe(
      actions$,
      ofType(userState.actions.authenticate.type),
      OB.chain((authenticateAction: ReturnType<typeof userState.actions.authenticate>) => {
        (credentials as KnownCredentials).email = authenticateAction.payload.email;
        (credentials as KnownCredentials).password = authenticateAction.payload.password;
        return EMPTY;
      })
    ),
    pipe(
      interval(1000),
      withLatestFrom(state$),
      switchMap(([_, state]) => {
        if (selectIsSessionExpiredOrExpiringSoon(state)) {
          if (isKnownCredentials(credentials)) {
            return of(
              userState.actions.authenticate({
                email: credentials.email,
                password: credentials.password,
                auto: false
              })
            );
          }

          if (selectSessionState(state) !== 'EXPIRED') {
            return of(userState.actions.sessionExpired());
          }
        }
        return EMPTY;
      })
    )
  );
