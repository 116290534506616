import { get } from './admin.api';
import { Nomenclature, NomenclatureElement } from '../../domain/Nomenclature';
import { AuthToken } from '../../AuthToken';
import { User } from '../../domain/User';

interface APINomenclatureElement {
  id: string;
  // eslint-disable-next-line camelcase
  nomenclature_id: string;
  reference: string;
  title: string;
  description?: string;
}

interface APINomenclature {
  id: '1' | '2';
  reference?: string;
  title: string;
  description: string;
  elements: APINomenclatureElement[];
}

const nomenclaturesFromApiAdapter = (nomenclatures: APINomenclature[]): Nomenclature[] =>
  nomenclatures.map(
    (nomenclature: APINomenclature): Nomenclature => ({
      ...nomenclature,
      elements: nomenclature.elements.map(
        (element: APINomenclatureElement): NomenclatureElement =>
          ({
            id: element.id,
            nomenclatureId: element.nomenclature_id,
            reference: element.reference,
            title: element.title,
            description: element.description
          } as NomenclatureElement)
      )
    })
  );

export const fetchNomenclatures = async (
  userId: User['id'],
  userToken: AuthToken
): Promise<Nomenclature[]> => {
  const response = await get(`/Nomenclatures/index/JSON/${userId}/${userToken}`, userToken);
  const apiNomenclatures = await response.json();

  return nomenclaturesFromApiAdapter(apiNomenclatures);
};
