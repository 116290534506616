import { getCasesSpecificationsById } from '../specification/project/queries/cases/getProjectCasesById';
import { CaseId } from '../specification/cases/CaseSpecification';
import { Maybe } from '../../utils/Maybe';
import { Surface } from '../specification/Surface';
import * as R from 'ramda';
import { mapCases } from './map/mapCases';
import { getCaseProjectionFromCaseSpecification } from '../projection/cases/queries/getCaseProjectionFromCaseSpecification';
import { DEFAULT_PROJECT_CBS, Project } from './Project';
import { ProjectProjection } from '../projection/project/ProjectProjection';
import { getCaseTopLevelsHoppersSurface } from '../specification/cases/queries/get/surfaces/getCaseTopLevelsHoppersSurface';
import { getCaseTopLevelsOutsideWallsSurfaceFromLevelsGeometry } from '../specification/cases/queries/get/surfaces/getCaseTopLevelsOutsideWallsSurfaceFromLevelsGeometry';
import { defaultLodgmentTypesDistribution } from '../specification/lodgmentTypes/LodgmentTypesDistribution';
import { defaultCaseProperties } from '../../resources/defaultCaseProperties';
import { getCaseTopLevels } from '../specification/cases/queries/get/levels/getCaseTopLevels';
import { getCaseTechnicalPremiseSectionsSurface } from '../specification/cases/queries/get/surfaces/getCaseTechnicalPremiseSectionsSurface';

export const projectCasesSurfaces = (project: Project): ProjectProjection => {
  const casesSpecificationsById = getCasesSpecificationsById(project);

  if (project.surfaceInputMode === 'cbs') {
    const specifiedCaseCuttedBuiltSurfaces: Record<CaseId, Maybe<Surface>> = R.mapObjIndexed(
      (eachCase) => eachCase.surfaces.cuttedBuiltSurface,
      casesSpecificationsById
    );

    const alreadySpecifiedCuttedBuiltSurface: Surface = R.compose<
      [Record<CaseId, Maybe<Surface>>],
      Maybe<Surface>[],
      Surface[],
      Surface
    >(
      Surface.sum,
      (values) => values.filter(Boolean) as Surface[],
      R.values
    )(specifiedCaseCuttedBuiltSurfaces);

    const unspecifiedSurfacesCount = R.compose<
      [Record<CaseId, Maybe<Surface>>],
      Maybe<Surface>[],
      undefined[],
      number
    >(
      R.length,
      (values) => values.filter((s) => s === undefined) as [],
      R.values
    )(specifiedCaseCuttedBuiltSurfaces);

    if (!unspecifiedSurfacesCount) {
      return mapCases((caseSpecification) =>
        getCaseProjectionFromCaseSpecification(
          specifiedCaseCuttedBuiltSurfaces[caseSpecification.id] as Surface,
          true
        )(caseSpecification)
      )(project) as ProjectProjection;
    }

    // After the rehydrate :
    // PATCH : To make the project in production older than the 2021-03-30 works
    // project land.surfaces has been replaced by project.surface
    // TODO : It should be done with a migration script on the database
    if (!project.cuttedBuiltSurface) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (project.land?.surfaces?.totalArea) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        project.cuttedBuiltSurface = new Surface(project.land.surfaces.totalArea);
      }
    }

    project.projectedCuttedBuiltSurface =
      project.cuttedBuiltSurface !== undefined
        ? project.cuttedBuiltSurface
        : new Surface(DEFAULT_PROJECT_CBS);

    const distributableCuttedBuiltSurface: Surface = new Surface(
      Math.max(
        1,
        project.projectedCuttedBuiltSurface.value - alreadySpecifiedCuttedBuiltSurface.value
      )
    );

    const cuttedBuiltSurfaceToDistributeByCase = new Surface(
      distributableCuttedBuiltSurface.value / unspecifiedSurfacesCount
    );

    return mapCases((caseSpecification) =>
      getCaseProjectionFromCaseSpecification(
        specifiedCaseCuttedBuiltSurfaces[caseSpecification.id] ||
          cuttedBuiltSurfaceToDistributeByCase,
        true
      )(caseSpecification)
    )(project) as ProjectProjection;
  } else {
    // project.surfaceInputMode === 'gfsEff'
    return mapCases((caseSpecification) => {
      const caseGfsEff = getCaseTopLevels(caseSpecification).reduce(
        (acc, levelSpecification) => acc + (levelSpecification.grossFloorSurfaceEff || 0),
        0
      );
      if (caseGfsEff !== 0) {
        const caseHoppersSurface = getCaseTopLevelsHoppersSurface(caseSpecification);
        const caseGfs = Math.max(0, caseGfsEff - caseHoppersSurface);
        const caseOutsideWallsSurface =
          getCaseTopLevelsOutsideWallsSurfaceFromLevelsGeometry(caseSpecification);
        const caseRbs = Math.max(0, caseGfs - caseOutsideWallsSurface);
        const caseCbs =
          caseRbs * 0.9 - getCaseTechnicalPremiseSectionsSurface(caseSpecification).value;
        return getCaseProjectionFromCaseSpecification(
          new Surface(caseCbs),
          true,
          new Surface(caseRbs)
        )(caseSpecification);
      } else {
        return {
          ...caseSpecification,
          projectedTopLevelsCount: 0,
          projectedBasementLevelsCount: 0,
          projectedDistribution: defaultLodgmentTypesDistribution,
          projectedProperties: defaultCaseProperties,
          projectedTheoreticalRealBuiltSurface: Surface.EMPTY,
          projectedRealBuiltSurface: Surface.EMPTY,
          projectedCuttedBuiltSurface: Surface.EMPTY,
          projectedSurfaceForSale: Surface.EMPTY,
          projectedMinRealBuiltSurface: Surface.EMPTY,
          projectedHallSurface: Surface.EMPTY,
          projectedTechnicalPremiseSectionsSurface: Surface.EMPTY,
          projectedMaxSurfaceForSale: Surface.EMPTY,
          projectedMaxSurfaceForSaleHasBeenForced: false,
          projectedMaxSurfaceForSaleByLevels: [],
          projectedSumOfMinimumBearingSurface: Surface.EMPTY,
          projectedGfsEffRelativeTopLevelsRbs: [], // Used as a buffer between two CaseGranulometry calculations
          projectedRatioRbsToCbs: 0,
          projectedRatioCbsToSfs: 0,
          projectedRatioRbsToSfs: 0,
          projectedTopLevels: [],
          projectedBasementLevels: []
        };
      }
    })(project) as ProjectProjection;
  }
};
