import { Action } from 'redux';

export const PROJECT_SAVED = 'PROJECT_SAVED';

export interface ProjectSavedAction extends Action {
  type: typeof PROJECT_SAVED;
}

export const projectSaved = (): ProjectSavedAction => ({
  type: PROJECT_SAVED
});
