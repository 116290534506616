import { roundWith2Decimal } from '../../../../../../../utils/round/roundWith2Decimal';
import { getOfficeSectionTheoreticalWallsLinear } from './getOfficeSectionTheoreticalWallsLinear';
import { OfficeSection } from '../../../../officeSection/officeSection';
import { EXPONENT, MULTIPLIER_COEFFICIENT } from '../../getSectionWallsLinear';

export const getOfficeSectionTheoreticalWallsLinearDetails = (
  officeSection: OfficeSection
): string => {
  let details: string[] = [];
  details = [
    ...details,
    '<b>' +
      roundWith2Decimal(officeSection.surface || officeSection.defaultSurface) +
      ' m\u00B2</b> de surface du ' +
      officeSection.title.toLowerCase()
  ];
  const hideNumbers = !(process.env.GB_ENV === 'dev' || process.env.GB_ENV === 'recette');
  details = [
    ...details,
    '<b>* ' +
      (hideNumbers
        ? 'coefficient multiplicateur'
        : MULTIPLIER_COEFFICIENT + '<sup>' + EXPONENT + '</sup>') +
      '</b>'
  ];
  details = [
    ...details,
    '<b>= ' + roundWith2Decimal(getOfficeSectionTheoreticalWallsLinear(officeSection)) + ' mL</b>'
  ];
  return details.join('<br />');
};
