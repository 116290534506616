import { APP_COLOR } from '../../../../constants/appConstants';
import { getSectionName, Section, SectionDrawn } from '../Section';
import i18next from 'i18next';
import { getCasePropertyDefaultValue } from '../../../specification/cases/queries/get/properties/getCasePropertyDefaultValue';
import { CirculationName } from './CirculationSection';
import { SectionWithTheoreticalSurface } from '../SectionWithTheoreticalSurface';
import { IconObjectProps } from '../../../../components/ui/Icons/iconObject';
import { SectionWithWidth } from '../SectionWithWidth';
import { SectionWithLength } from '../SectionWithLength';

interface VentilationGroupInfraProps {
  surface: number;
  length: number;
  width: number;
  group: string;
}

export interface VentilationGroupInfraSection
  extends Section,
    SectionDrawn,
    SectionWithLength,
    SectionWithWidth,
    SectionWithTheoreticalSurface {
  name: CirculationName.VentilationGroupInfra;
}

export const VENTILATION_GROUP_ICON = {
  type: 'object',
  iconName: 'smokeExhaustDust',
  color: APP_COLOR
} as IconObjectProps;

const ventilationGroupInfra = ({
  surface,
  length,
  width,
  group
}: VentilationGroupInfraProps): VentilationGroupInfraSection => {
  return {
    // Section
    id: 'NOID',
    name: CirculationName.VentilationGroupInfra,
    title: i18next.t('ventilationGroupInfra.title'),
    type: 'circulation',
    // SectionDrawn
    surface,
    displayedSurface: surface,
    color: APP_COLOR,
    icon: VENTILATION_GROUP_ICON,
    group,
    // SectionWithTheoreticalSurface
    theoreticalSurface: surface,
    defaultTheoreticalSurface:
      getCasePropertyDefaultValue('ventilationGroupInfraLength') *
      getCasePropertyDefaultValue('ventilationGroupInfraWidth'),
    // VentilationGroupInfraSection
    length,
    width
  };
};

export default ventilationGroupInfra;

export const isSectionAVentilationGroupInfra = (
  section: Section
): section is VentilationGroupInfraSection =>
  getSectionName(section) === CirculationName.VentilationGroupInfra;
