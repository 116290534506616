import * as React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { selectCurrentlyEditedLevelSpecification } from '../../../../../store/selectors/navigation/toolbox/casesPanel/currentlyEditedLevelSpecification.selector';
import { Card } from '../../../../ui/Card';
import { selectCurrentCaseProjection } from '../../../../../store/selectors/projection/currentCase/projection.selector';
import { IconObject } from '../../../../ui/Icons/iconObject';
import { FormGroup } from '../../../../ui/FormGroup';
import { Input } from '../../../../ui/input/Input';
import { setCaseLevelSpecification } from '../../../../../store/actions/setCaseLevelSpecification.action';
import { Button } from '../../../../ui/Button';
import { secureInputChange } from '../../../../../utils/secureInputChange';
import { LevelSpecification } from '../../../../../domain/specification/levels/LevelSpecification';
import shopSection, {
  ShopSection
} from '../../../../../domain/granulometry/sections/shopSection/shopSection';
import { getLevelShopSections } from '../../../../../domain/specification/levels/queries/get/getLevelShopSections';
import { GlazedSurfaceInput } from './GlazedSurfaceInput';

export const ShopSections = () => {
  const currentlyEditedCase = useSelector(selectCurrentCaseProjection);
  const currentlyEditedLevel = useSelector(
    selectCurrentlyEditedLevelSpecification
  ) as LevelSpecification;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [localCurrentLevelShopSections, setLocalCurrentLevelShopSections] = React.useState<
    ShopSection[]
  >(getLevelShopSections(currentlyEditedLevel));

  React.useEffect(() => {
    setLocalCurrentLevelShopSections(getLevelShopSections(currentlyEditedLevel));
  }, [currentlyEditedLevel]);

  const handleShopSectionsSpecificationsChange = React.useCallback(
    (value: number | string | null, index: number, input: string) => {
      const updateShopInput = (shopSection: ShopSection, input: string): ShopSection =>
        R.assoc(input, value === null ? undefined : value)(shopSection) as unknown as ShopSection;

      const newShopSections = R.over(
        R.lensIndex<ShopSection>(index),
        R.compose((shopSection) => {
          if (input === 'exposureRate' && typeof value === 'number') {
            value = (secureInputChange(value, 0, 100) * 4) / 100;
          }
          if (input === 'surface') {
            shopSection = updateShopInput(shopSection, 'displayedSurface');
          }
          return updateShopInput(shopSection, input);
        })
      )(localCurrentLevelShopSections);

      if (currentlyEditedCase && currentlyEditedLevel) {
        setLocalCurrentLevelShopSections(newShopSections);
        dispatch(
          setCaseLevelSpecification(currentlyEditedCase.id, {
            ...currentlyEditedLevel,
            shopSections: newShopSections ?? []
          })
        );
      }
    },
    [dispatch, currentlyEditedCase, currentlyEditedLevel]
  );

  const handleRemoveShopSection = React.useCallback(
    (index: number) => {
      const newShopSections = R.remove(index, 1, localCurrentLevelShopSections);
      if (currentlyEditedCase && currentlyEditedLevel) {
        setLocalCurrentLevelShopSections(newShopSections);
        dispatch(
          setCaseLevelSpecification(currentlyEditedCase.id, {
            ...currentlyEditedLevel,
            shopSections: newShopSections ?? []
          })
        );
      }
    },
    [dispatch, currentlyEditedCase, currentlyEditedLevel]
  );

  const handleDuplicateShopSection = React.useCallback(
    (index: number) => {
      const newShopSections = R.insert(
        index,
        R.view(R.lensIndex<ShopSection>(index), localCurrentLevelShopSections),
        localCurrentLevelShopSections
      );
      if (currentlyEditedCase && currentlyEditedLevel) {
        setLocalCurrentLevelShopSections(newShopSections);
        dispatch(
          setCaseLevelSpecification(currentlyEditedCase.id, {
            ...currentlyEditedLevel,
            shopSections: newShopSections ?? []
          })
        );
      }
    },
    [dispatch, currentlyEditedCase, currentlyEditedLevel]
  );

  const handleAddFirstShopSection = React.useCallback(() => {
    const newShops = [shopSection({})];
    if (currentlyEditedCase && currentlyEditedLevel) {
      setLocalCurrentLevelShopSections(newShops);
      dispatch(
        setCaseLevelSpecification(currentlyEditedCase.id, {
          ...currentlyEditedLevel,
          shopSections: newShops
        })
      );
    }
  }, [dispatch, currentlyEditedCase, currentlyEditedLevel]);

  if (!currentlyEditedCase || !currentlyEditedLevel) return null;

  return (
    <Card className="shopSections">
      <SpecialLabel label={t('shopSections.specialLabel')} />
      {localCurrentLevelShopSections.length > 0 ? (
        localCurrentLevelShopSections.map((shopSection, index) => {
          const prefix = 'level' + currentlyEditedLevel.level + 'ShopsSpecification' + index;
          return (
            <React.Fragment key={index}>
              <SpecialLabel label={t(shopSection.name + '.title')} small />
              <FormGroup horizontal={true} wrap={false}>
                <div style={{ width: '53px', flex: '0 0 53px', padding: '17px 0 0' }}>
                  <IconObject {...shopSection.icon} clickable={false} />
                </div>
                <div style={{ width: '21.5%', flex: '0 0 21.5%' }}>
                  <Input
                    name={prefix + 'Surface'}
                    label={t('shopSections.surfaceInput.label')}
                    type="number"
                    min={shopSection.minSurface}
                    max={shopSection.maxSurface}
                    minMax={{
                      min: shopSection.minSurface,
                      max: shopSection.maxSurface,
                      unit: ''
                    }}
                    value={
                      shopSection.surface !== shopSection.defaultSurface
                        ? shopSection.surface
                        : undefined
                    }
                    handleChange={(value) =>
                      handleShopSectionsSpecificationsChange(
                        secureInputChange(value, shopSection.minSurface, shopSection.maxSurface),
                        index,
                        'surface'
                      )
                    }
                    suffix="m2"
                    placeholder={shopSection.defaultSurface}
                  />
                </div>
                <div style={{ width: '21.5%', flex: '0 0 21.5%' }}>
                  <Input
                    name={prefix + 'Width'}
                    label={t('shopSections.widthInput.label')}
                    type="number"
                    min={shopSection.minWidth}
                    max={shopSection.maxWidth}
                    minMax={{
                      min: shopSection.minWidth,
                      max: shopSection.maxWidth,
                      unit: ''
                    }}
                    value={
                      shopSection.width !== shopSection.defaultWidth ? shopSection.width : undefined
                    }
                    handleChange={(value) =>
                      handleShopSectionsSpecificationsChange(
                        secureInputChange(value, shopSection.minWidth, shopSection.maxWidth),
                        index,
                        'width'
                      )
                    }
                    suffix="m"
                    placeholder={shopSection.defaultWidth}
                  />
                </div>
                <div style={{ width: '21.5%', flex: '0 0 21.5%' }}>
                  <Input
                    name={prefix + 'ExposureRate'}
                    label={t('shopSections.exposureRateInput.label')}
                    type="number"
                    min={0}
                    max={100}
                    minMax={{
                      min: 0,
                      max: 100,
                      unit: ''
                    }}
                    value={
                      shopSection.exposureRate !== undefined &&
                      shopSection.exposureRate !== shopSection.defaultExposureRate
                        ? (shopSection.exposureRate / 4) * 100
                        : undefined
                    }
                    handleChange={(value) =>
                      handleShopSectionsSpecificationsChange(
                        secureInputChange(value, 0, 100),
                        index,
                        'exposureRate'
                      )
                    }
                    suffix="%"
                    placeholder={(shopSection.defaultExposureRate / 4) * 100}
                  />
                </div>
                <div className="add-remove-buttons">
                  <IconObject
                    iconName="remove"
                    type="menu"
                    onClick={() => {
                      handleRemoveShopSection(index);
                    }}
                    // title={t('Remove case {{caseLabel}}', { caseLabel: selectedCaseLabel })}
                  />
                  <IconObject
                    iconName="add"
                    type="menu"
                    onClick={() => {
                      handleDuplicateShopSection(index);
                    }}
                  />
                </div>
              </FormGroup>
              <GlazedSurfaceInput
                prefix={prefix}
                index={index}
                section={shopSection}
                handleChange={handleShopSectionsSpecificationsChange}
              />
            </React.Fragment>
          );
        })
      ) : (
        <FormGroup horizontal={true} wrap={false}>
          <Button
            content={t('shopSections.addButton.content') as string}
            handleClick={() => {
              handleAddFirstShopSection();
            }}
            appearance="outline"
            size="small"
            style={{ margin: '10px auto' }}
          />
        </FormGroup>
      )}
    </Card>
  );
};
