import { CaseGranulometry } from '../../CaseGranulometry';
import { getLevelCeilingHeight } from '../../../levels/queries/getLevelCeilingHeight';
import { getCaseTopLevels } from './getCaseTopLevels';

export const getCaseTopLevelsCeilingHeights = (caseGranulometry: CaseGranulometry): number[] =>
  getCaseTopLevels(caseGranulometry).reduce(
    (acc, levelGranulometry) => [
      ...acc,
      getLevelCeilingHeight(levelGranulometry) +
        (levelGranulometry.cementScreedThickness || 0) +
        (levelGranulometry.concreteSlabThickness || 0)
    ],
    [] as number[]
  );
