import * as React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { selectTotalParkingsCount } from '../../../../../store/selectors/specification/parkings/totalParkingsCount.selector';
import { SpecialLabel } from '../../../../ui/SpecialLabel';
import { FormGroup } from '../../../../ui/FormGroup';
import { Input } from '../../../../ui/input/Input';

export const TotalCaseParking = () => {
  const totalParkingCount = useSelector(selectTotalParkingsCount);
  const { t } = useTranslation();

  return (
    <div className="total" style={{ marginTop: '8px' }}>
      <SpecialLabel label={t('Total parking spots')} small={true} />
      <FormGroup horizontal={true}>
        <div
          style={{
            height: '32px',
            width: '140px'
          }}>
          <Input
            name="total"
            type="number"
            value={totalParkingCount}
            suffix={t('Parking spots')}
            style={{ margin: 0 }}
            disabled
          />
        </div>
        <div
          style={{
            width: '132px'
          }}>
          &nbsp;
        </div>
      </FormGroup>
    </div>
  );
};
