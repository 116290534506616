import { CaseSurface } from '../../domain/specification/cases/CaseSurfaceSpecification';

export const CASE_SURFACE_CHANGED = 'CASE_SURFACE_CHANGED';

export const caseSurfaceChanged = (buildingId: string, caseId: string, surface: CaseSurface) => ({
  type: CASE_SURFACE_CHANGED,
  payload: { buildingId, caseId, surface }
});

export type CaseSurfaceChangedAction = ReturnType<typeof caseSurfaceChanged>;
