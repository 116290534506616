import { CaseId } from '../../../domain/specification/cases/CaseSpecification';
import { LevelId } from '../../../domain/granulometry/levels/LevelGranulometry';
import { matchesPath } from '../../utils/matchesPath';
import { orCreate } from '../../utils/orCreate';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type CaseGranulometryEditLevelGeometryRouteParams = {
  projectId: string;
  caseId: CaseId;
  levelId: LevelId;
};

export const caseGranulometryEditLevelGeometryRoute: RouteWithMakePath<CaseGranulometryEditLevelGeometryRouteParams> =
  {
    path: `/project/:projectId/cases/:caseId/granulometry/edit/levelGeometry/:levelId`,
    makePath: ({ projectId, caseId, levelId }) =>
      `/project/${orCreate(projectId)}/cases/${caseId}/granulometry/edit/levelGeometry/${levelId}`,
    matchesPath: matchesPath<CaseGranulometryEditLevelGeometryRouteParams>(
      /\/project\/([^/]+)\/cases\/([\w]+)\/granulometry\/edit\/levelGeometry\/([^/]+)/,
      {
        projectId: 1,
        caseId: 2,
        levelId: 3
      }
    )
  };
