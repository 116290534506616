export enum HeatingDistribution {
  UnderfloorHeating = 'underfloorHeating',
  IsolatedElementsHeating = 'isolatedElementsHeating'
}

export const HeatingDistributionsInArray = [
  HeatingDistribution.UnderfloorHeating,
  HeatingDistribution.IsolatedElementsHeating
];

export const isHeatingDistributionAUnderfloorHeating = (heatingDistribution: HeatingDistribution) =>
  heatingDistribution === HeatingDistribution.UnderfloorHeating;

export const isHeatingDistributionAIsolatedElementsHeating = (
  heatingDistribution: HeatingDistribution
) => heatingDistribution === HeatingDistribution.IsolatedElementsHeating;

export const DEFAULT_HEATING_DISTRIBUTION = HeatingDistribution.IsolatedElementsHeating;
