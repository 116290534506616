import { matchesPath } from '../../utils/matchesPath';
import { RouteWithMakePath } from '../../RouteWithMakePath';

export type SectionsEditRouteParams = { projectId: string };
export const sectionsEditRoute: RouteWithMakePath<SectionsEditRouteParams> = {
  path: `/project/:projectId/sections/edit`,
  makePath: ({ projectId }) => `/project/${projectId}/sections/edit`,
  matchesPath: matchesPath<SectionsEditRouteParams>(/\/project\/([^/]+)\/sections\/edit/, {
    projectId: 1
  })
};
