import { Project } from '../../../../project/Project';
import { CaseSpecificationWithIndexes } from '../../../cases/CaseSpecification';

export const getProjectCasesSpecifications = (project: Project): CaseSpecificationWithIndexes[] =>
  project.buildings.reduce(
    (casesAcc, building, buildingIndex) => [
      ...casesAcc,
      ...building.cases.map(
        (eachCase, indexInBuilding) =>
          ({
            ...eachCase,
            buildingIndex,
            buildingId: building.id,
            indexInBuilding
          } as CaseSpecificationWithIndexes)
      )
    ],
    [] as CaseSpecificationWithIndexes[]
  ) as CaseSpecificationWithIndexes[];
