import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { capitalize } from '../../../../utils/strings/capitalize';
import { selectCurrentBreadcrumb } from '../../../../store/selectors/navigation';

export const Breadcrumbs = () => {
  const breadcrumb = useSelector(selectCurrentBreadcrumb);
  const { t } = useTranslation();

  const path = React.useMemo(
    () => breadcrumb.map((segment: string) => t(capitalize(segment))).join(' / '),
    [breadcrumb]
  );

  return (
    <div className="breadcrumb">
      <div className="breadcrumb-inner">{path}</div>
    </div>
  );
};
