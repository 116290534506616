import * as R from 'ramda';
import { Granulometry } from '../Granulometry';
import { BuildingGranulometry } from '../buildings/BuildingGranulometry';
import { CaseGranulometry } from '../cases/CaseGranulometry';
import { getBuildingId } from '../buildings/queries/getBuildingId';
import { getCaseId } from '../cases/queries/get/getCaseId';
import { CaseId } from '../../specification/cases/CaseSpecification';
import { BuildingId } from '../../specification/buildings/BuildingSpecification';
import { getLevelLevel, LevelGranulometry } from '../levels/LevelGranulometry';
import { getLevelAlreadyCalculatedRealBuiltSurface } from '../levels/queries/surfaces/getLevelAlreadyCalculatedRealBuiltSurface';

export type SurfaceByLevel = Record<string, number>;
export const getCaseRealBuiltSurfaceByLevel = (
  caseGranulometry: CaseGranulometry
): SurfaceByLevel =>
  R.pipe<
    [CaseGranulometry],
    LevelGranulometry[],
    Record<string, LevelGranulometry>,
    SurfaceByLevel
  >(R.prop('levels'), R.indexBy(R.compose(R.toString, getLevelLevel)), (levels) =>
    R.map(
      (level: LevelGranulometry) => getLevelAlreadyCalculatedRealBuiltSurface(level).value,
      levels
    )
  )(caseGranulometry);

const doCaseGranulometry = (eachCase: CaseGranulometry): SurfaceByLevel =>
  getCaseRealBuiltSurfaceByLevel(eachCase);

const extractFromCases = (
  cases: Record<string, CaseGranulometry>
): Record<string, SurfaceByLevel> => R.mapObjIndexed(doCaseGranulometry, cases);

const indexCases = (cases: CaseGranulometry[]): Record<CaseId, CaseGranulometry> =>
  R.indexBy(getCaseId, cases);

export type SurfaceByBuildingCaseAndLevel = Record<BuildingId, Record<CaseId, SurfaceByLevel>>;

const extractFromBuildings = (
  buildings: Record<BuildingId, BuildingGranulometry>
): SurfaceByBuildingCaseAndLevel =>
  R.mapObjIndexed(({ cases }) =>
    R.pipe<[CaseGranulometry[]], Record<CaseId, CaseGranulometry>, Record<CaseId, SurfaceByLevel>>(
      indexCases,
      extractFromCases
    )(cases)
  )(buildings);

const indexBuildings = R.indexBy(getBuildingId);

export const getRealBuiltSurfaceByBuildingCaseAndLevel = (granulometry: Granulometry) =>
  R.pipe(indexBuildings, extractFromBuildings)(granulometry);
