import { NonEmptyArray } from '../../../utils/NonEmptyArray';
import { BuildingSpecification } from '../../specification/buildings/BuildingSpecification';
import * as R from 'ramda';
import { Project } from '../Project';

export const overBuildings =
  (
    operation: (
      buildings: NonEmptyArray<BuildingSpecification>
    ) => NonEmptyArray<BuildingSpecification>
  ) =>
  <P extends Project>(project: P): P =>
    R.over(R.lensProp('buildings'), operation, project);
