import { RoomStaticElement } from './RoomStaticElement';

export const toiletElements: RoomStaticElement[] = [
  { e1: 'chape ciment', e2: '', e3: '', quantity: 1 },
  { e1: 'revêtement de sol carrelage', e2: '', e3: '', quantity: 1 },
  { e1: 'résilient acoustique', e2: '', e3: '', quantity: 1 },
  { e1: 'plinthe carrelage', e2: '', e3: '', quantity: 1 },
  { e1: 'finition murale', e2: '', e3: '', quantity: 1 },
  { e1: 'enduit pelliculaire horizontal', e2: '', e3: '', quantity: 1 },
  { e1: 'finition plafond', e2: '', e3: '', quantity: 1 },
  { e1: 'gaine technique logement', e2: '', e3: '', quantity: 1 },
  { e1: 'gaine technique logement', e2: 'film polyéthylène sous cloison', e3: '', quantity: 1 },
  { e1: 'gaine technique logement', e2: 'colonne eaux usées', e3: '', quantity: 1 },
  { e1: 'gaine technique logement', e2: 'piquage eaux usées', e3: '', quantity: 1 },
  { e1: 'gaine technique logement', e2: 'colonne ventilation', e3: '', quantity: 1 },
  { e1: 'gaine technique logement', e2: 'raccord de colonne de ventilation', e3: '', quantity: 1 },
  { e1: 'gaine technique logement', e2: "bouche d'extraction VMC", e3: '', quantity: 1 },
  { e1: 'porte de distribution', e2: '', e3: '', quantity: 1 },
  { e1: 'porte de distribution', e2: 'seuil bois passage de porte', e3: '', quantity: 1 },
  { e1: 'porte de distribution', e2: 'peinture sur bois prépeint', e3: '', quantity: 1 },
  { e1: 'porte de distribution', e2: 'huisserie porte de distribution', e3: '', quantity: 1 },
  {
    e1: 'porte de distribution',
    e2: 'huisserie porte de distribution',
    e3: 'peinture sur métal',
    quantity: 1
  },
  { e1: 'point centre', e2: '', e3: '', quantity: 1 },
  { e1: 'interrupteur', e2: '', e3: '', quantity: 1 },
  { e1: 'prise de courant 16A', e2: '', e3: '', quantity: 1 },
  { e1: "wc à l'anglaise", e2: '', e3: '', quantity: 1 }
];
