import * as React from 'react';
import classNames from 'classnames';
import { ReactNode } from 'react';

interface HighlightOverlayProps {
  highlighted: boolean;
  triggerId?: string;
  currentValue?: number;
  onReturn: () => void;
  children: ReactNode | ReactNode[];
}

export const HighlightOverlay = ({
  highlighted,
  triggerId,
  currentValue,
  onReturn,
  children
}: HighlightOverlayProps) => {
  const highlightZoneRef = React.useRef<HTMLDivElement>(null);
  const highlightZoneWrapperRef = React.useRef<HTMLDivElement>(null);
  const [isParentScroller, setParentScroller] = React.useState<boolean>(false);
  const [lastClonedId, setLastClonedId] = React.useState<string | undefined>(undefined);
  const [lastValue, setLastValue] = React.useState<number | undefined>(undefined);

  const insertTriggerClone = () => {
    if (triggerId && highlightZoneRef.current && highlightZoneWrapperRef.current) {
      const trigger = document.getElementById(triggerId) as HTMLElement;
      const triggerClone: HTMLElement = trigger.cloneNode(true) as HTMLElement;
      const highlightZoneBounding = highlightZoneRef.current.getBoundingClientRect();
      const triggerBounding = trigger.getBoundingClientRect();
      const highlightZoneParent = highlightZoneWrapperRef.current.parentElement;

      if (highlightZoneParent) {
        const parentIsScroller = highlightZoneParent.style.overflowY === 'scroll';
        setParentScroller(parentIsScroller);
        if (parentIsScroller) {
          highlightZoneParent.style.overflowY = 'hidden';
          triggerClone.style.top = `${triggerBounding.top - highlightZoneBounding.top}px`;
          triggerClone.style.left = `${triggerBounding.left - highlightZoneBounding.left}px`;
        } else {
          triggerClone.style.top = `${
            triggerBounding.top + highlightZoneBounding.top + window.scrollY * 2
          }px`;
          triggerClone.style.left = `${triggerBounding.left + highlightZoneBounding.left}px`;
        }
      }
      triggerClone.classList.add(`${trigger.id}_triggerClone`);
      triggerClone.style.position = 'absolute';
      triggerClone.style.width = `${triggerBounding.width}px`;
      highlightZoneRef.current.appendChild(triggerClone);
      setLastClonedId(triggerId);
    }
  };

  const destroyTriggerClone = () => {
    if (lastClonedId && highlightZoneRef.current) {
      const trigger = document.getElementById(lastClonedId);
      if (trigger) {
        trigger.classList.remove('highlighted');
      }
      const triggerClone = document.getElementsByClassName(`${lastClonedId}_triggerClone`)[0];
      if (triggerClone) {
        highlightZoneRef.current.removeChild(triggerClone);
      }
      if (isParentScroller && highlightZoneWrapperRef?.current?.parentElement) {
        highlightZoneWrapperRef.current.parentElement.style.overflowY = 'scroll';
      }
      setLastClonedId(undefined);
    }
  };

  const [immediateId, setImmediateId] = React.useState<NodeJS.Immediate>();

  React.useLayoutEffect(() => {
    if (highlighted) {
      if (lastClonedId && (lastClonedId !== triggerId || currentValue !== lastValue)) {
        destroyTriggerClone();
        setLastValue(currentValue);
      }
      if (immediateId) {
        clearImmediate(immediateId);
      }
      setImmediateId(setImmediate(insertTriggerClone));
    } else if (lastClonedId) {
      destroyTriggerClone();
    }
  }, [highlighted, triggerId, currentValue]);

  return (
    <div className="highlight-zone-wrapper" ref={highlightZoneWrapperRef}>
      {children}
      <div
        className={classNames('highlight-zone', {
          active: highlighted
        })}
        onClick={onReturn}
        ref={highlightZoneRef}
      />
    </div>
  );
};
